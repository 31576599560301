import axios from 'axios';

import Config from 'Config';

import { getToken } from 'middleware/auth';
import { getLocalization } from 'middleware/localization';

export function initRestParams () {
  const token = getToken();
  axios.defaults.baseURL = `${Config.api}`;
  axios.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : '';
  axios.defaults.headers.common['Accept-Language'] = getLocalization();
}
