import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';

import { Match } from 'common/types/match';
import type { GlobalState } from 'common/types/global-state';

import {
  resetJobOffer,
  getJobOfferWithSkills,
  markJobOfferAsViewedStats
} from 'services/portal/candidate/jobs/job-offers/job-offers.actions';

import { saveMatchedJobOfferAsViewed } from 'services/portal/candidate/match/job-offers/job-offers.actions';

import './preview-container.scss';
import './preview-container.responsive.scss';

export type Props = {
  match: Match,
  fetchJobOffer: boolean,
  resetJobOffer: () => void,
  markJobOfferAsViewedStats: (jobOfferId: string) => void
};

type State = {
  fetchJobOffer: boolean
}

export class Settings extends Component<Props, State> {

  state: State = {
    updateSkillsSuccess: false
  }

}

export const stateToProps = (state: GlobalState) => ({
  jobOffer: state.portal.candidate.jobs.jobOffers.jobOffer,
  currentCandidate: state.portal.candidate.account.current.candidateData,
  fetchJobOffer:
    state.portal.candidate.skills.updateSkillsSuccess || state.portal.candidate.skills.createSkillSuccess,
  jobOfferSkills: state.portal.candidate.jobs.jobOffers.jobOfferSkills
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetJobOffer,
  getJobOfferWithSkills,
  markJobOfferAsViewedStats,
  saveMatchedJobOfferAsViewed
}, dispatch);
