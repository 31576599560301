export class PaymentRedirectUrlsDto {

  failRedirectUrl: string;
  successRedirectUrl: string;
  timeoutRedirectUrl: string;

  constructor (successRedirectUrl = '/', failRedirectUrl = '/', timeoutRedirectUrl = '/') {
    this.failRedirectUrl = failRedirectUrl;
    this.successRedirectUrl = successRedirectUrl;
    this.timeoutRedirectUrl = timeoutRedirectUrl;
  }

}
