import { Component } from 'react';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

type Props = {
  t: TransProps,
  totalCvData: number[]
}

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  totalCvData: [
    state.portal.candidate.balanceBoard.balanceBoard ? 1 : 0,
    state.portal.candidate.cv.education.content.length > 0 ? 1 : 0,
    state.portal.candidate.cv.experience.content.length > 0 ? 1 : 0,
    state.portal.candidate.skills.content.professionalSkills.length > 0 ? 1 : 0,
    state.portal.candidate.cv.lifestyle.activities.filter(item => !!item.activity).length > 0 ? 1 : 0
  ]
});
