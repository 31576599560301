import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { UsersAlertsResponseDto } from 'rest/alerts/alerts.response.dto';

import {
  getNotifications,
  markNotificationAsRead,
  resetNotificationsList
} from 'services/portal/notifications/notifications.actions';

import { hideModal } from 'services/portal/modal/modal.actions';

import './modal.scss';
import './modal.responsive.scss';

type Props = {
  t?: TransProps,
  open: boolean,
  fetching: boolean,
  hasMoreItems: boolean,
  hideModal: () => void,
  notifications: UsersAlertsResponseDto[],
  getNotifications: (type: string) => void,
  markNotificationAsRead: (alertId: string) => void,
};

export class Settings extends Component<Props> {

  static defaultProps = {
    open: false,
    fetching: false,
    numberOfElements: 1,
    notifications: [],
    hideModal: () => {},
    resetNotificationsList: () => {}
  };

}

export const stateToProps = (state: GlobalState) => ({
  fetching: state.portal.notifications.fetching,
  hasMoreItems: state.portal.notifications.hasMoreItems,
  notifications: state.portal.notifications.notifications
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  hideModal,
  getNotifications,
  resetNotificationsList,
  markNotificationAsRead
}, dispatch);
