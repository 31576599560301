import React from 'react';
import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Settings } from './poster.setting';

@connectTranslation()
export class StatisticsPoster extends Settings {

  render () {
    return (
      <div className="statistics-poster">
        <div className="poster">
          <img
            src="/images/recruiter/statistics/placeholder.png"
            alt={this.props.t('portal.recruiter.statistics.list.title')}/>
        </div>
        <div className="title">
          <Trans i18nKey="portal.recruiter.statistics.list.title">
            Statistics page is under development.
          </Trans>
        </div>
        <div className="description">
          <Trans i18nKey="portal.recruiter.statistics.list.description">
            At the moment, this function is not available, very soon we’ill announce the addition of statistics.
          </Trans>
        </div>
      </div>
    );
  }

}
