// @flow
import React from 'react';

import { Trans } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { Card } from 'components/layouts';
import { LinkButton } from 'components/buttons';
import { Tab, Tabs, TabHeader } from 'components/tabs';

import { SkillsTab } from './../skills/skills';
import { RequirementsTab } from './../requirements/requirements';
import { GeneralInfoTab } from './../general-info/general-info';
import { KnowledgeLevelTab } from './../knowledge-level/knowledge-level';

import type { Props } from './modal.setting';
import { Settings, stateToProps } from './modal.setting';

@connectStore(stateToProps, null, true)
export class CreateBalanceBoardModal extends Settings {

  componentDidMount () {
    localStorage.setItem('skillsBalanceBoard', JSON.stringify(this.props.skills));
  }

  componentDidUpdate (prevProps: Props) {
    if (this.props.skills !== prevProps.skills) {
      localStorage.setItem('skillsBalanceBoard', JSON.stringify(this.props.skills));
    }
  }

  changeActiveTabState (activeTab: number) {
    this.setState({ activeTab });
  }

  render () {
    return (
      <div className="create-balance-board-modal mobile-create-balance-board-modal">
        <Card>
          <PerfectScrollbar className="content">
            <LinkButton
              inline
              className="balance-board-back-link"
              link="/portal/candidate/balance-board">
              <Trans i18nKey="portal.candidate.balance-board.edit.back">
                Back to balance board
              </Trans>
              <div className="modal-balance-board-back">&times;</div>
            </LinkButton>
            <Tabs
              headClassName="primary-tabs"
              activeTabIndex={this.state.activeTab}
              activeTabChange={activeTab => this.changeActiveTabState(activeTab)}>
              <Tab label={this.props.t('portal.candidate.balance-board.edit.tabs.general.header')}>
                <TabHeader
                  title={this.props.t('portal.candidate.balance-board.edit.tabs.general.title')}
                  subTitle={this.props.t('portal.candidate.balance-board.edit.tabs.general.description')}/>
                <GeneralInfoTab
                  match={this.props.match}
                  next={() => this.changeActiveTabState(this.state.activeTab + 1)}/>
              </Tab>
              <Tab label={this.props.t('portal.candidate.balance-board.edit.tabs.skills.header')}>
                <TabHeader
                  title={this.props.t('portal.candidate.balance-board.edit.tabs.skills.title')}
                  subTitle={this.props.t('portal.candidate.balance-board.edit.tabs.skills.description')}/>
                <SkillsTab
                  prev={() => this.changeActiveTabState(this.state.activeTab - 1)}
                  next={() => this.changeActiveTabState(this.state.activeTab + 1)}/>
              </Tab>
              <Tab label={this.props.t('portal.candidate.balance-board.edit.tabs.requirements.header')}>
                <TabHeader
                  title={this.props.t('portal.candidate.balance-board.edit.tabs.requirements.title')}
                  subTitle={this.props.t('portal.candidate.balance-board.edit.tabs.requirements.description')}/>
                <RequirementsTab
                  prev={() => this.changeActiveTabState(this.state.activeTab - 1)}
                  next={() => this.changeActiveTabState(this.state.activeTab + 1)}/>
              </Tab>
              <Tab label={this.props.t('portal.candidate.balance-board.edit.tabs.knowledge.header')}>
                <TabHeader title={this.props.t('portal.candidate.balance-board.edit.tabs.knowledge.title')}/>
                <KnowledgeLevelTab
                  match={this.props.match}
                  prev={() => this.changeActiveTabState(this.state.activeTab - 1)} />
              </Tab>
            </Tabs>
          </PerfectScrollbar>
        </Card>
      </div>
    );
  }

}
