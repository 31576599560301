import { Component } from 'react';

import { TransProps } from 'react-i18next';

import './delete-modal.scss';
import './delete-modal.responsive.scss';

export type Props = {
  t?: TransProps,
  open: boolean,
  hideModal: () => void,
  deleteAction: () => void
}

export class Setting extends Component<Props> {

  static defaultProps = {
    open: false,
    hideModal: () => {},
    deleteAction: () => {}
  };

}
