// @flow
import React from 'react';

import { Container } from 'components/layouts';
import { LinkButton } from 'components/buttons';

import { Setting } from './inside.setting';
import { Trans } from 'react-i18next';

export class Inside extends Setting {

  render () {
    return (
      <div className="inside-container">
        <Container className="inside-content">
          <div className="inside-title">
            <Trans i18nKey="information.about.inside.title">
              Curious what inside?
            </Trans>
          </div>
          <div className="inside-description">
            <Trans i18nKey="information.about.inside.description">
              With help of our service, 1 .000  employers found suitable employees. Let’s work together.
            </Trans>
          </div>
          <div className="inside-control">
            <LinkButton
              link="/auth/recruiter/sign-up"
              recruiter>
              <Trans i18nKey="information.about.inside.button">
                Start Now
              </Trans>
            </LinkButton>
          </div>
        </Container>
      </div>
    );
  }

}
