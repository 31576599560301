// @flow
import * as actions from './search.constants';

import type { SearchCandidatesType } from './search.type';

const initialState: SearchCandidatesType = {
  sortType: 'DEFAULT',
  sortDirection: '',
  close: null,
  unitId: '',
  location: '',
  jobType: '',
  minSalary: null,
  branchId: '',
  jobTitleId: '',
  maxSalary: null,
  workingTime: null,
  availableFrom: new Date(),
  professionalSkills: [],
  readyToRelocation: false
};

export default function (state: SearchCandidatesType = initialState, action) {
  switch (action.type) {
    case actions.SEARCH_CANDIDATES_SKILLS:
      return {
        ...state,
        professionalSkills: action.skills
      };

    case actions.SEARCH_CANDIDATES_BRANCH:
      return {
        ...state,
        branchId: action.branchId
      };

    case actions.SEARCH_CANDIDATES_UNIT:
      return {
        ...state,
        unitId: action.unitId
      };

    case actions.SEARCH_CANDIDATES_JOB_TITLE:
      return {
        ...state,
        jobTitleId: action.jobTitleId
      };

    case actions.SEARCH_CANDIDATES_LOCATION:
      return {
        ...state,
        location: action.location
      };

    case actions.SEARCH_CANDIDATES_SALARY_MIN:
      return {
        ...state,
        minSalary: action.minSalary
      };

    case actions.SEARCH_CANDIDATES_SALARY_MAX:
      return {
        ...state,
        maxSalary: action.maxSalary
      };

    case actions.SEARCH_CANDIDATES_WORKING_TIME:
      return {
        ...state,
        workingTime: action.workingTime
      };

    case actions.SEARCH_CANDIDATES_AVAILABLE_FROM:
      return {
        ...state,
        availableFrom: action.availableFrom
      };

    case actions.SEARCH_CANDIDATES_WORKING_TYPE:
      return {
        ...state,
        jobType: action.jobType
      };

    case actions.SEARCH_CANDIDATES_RELOCATE:
      return {
        ...state,
        readyToRelocation: action.readyToRelocation
      };

    case actions.SEARCH_CANDIDATES_CLOSE:
      return {
        ...state,
        close: action.close
      };

    case actions.SEARCH_CANDIDATES_RESET:
      return {
        ...initialState,
        professionalSkills: state.professionalSkills
      };

    case actions.SEARCH_CANDIDATES_SKILLS_RESET:
      return {
        ...state,
        professionalSkills: []
      };

    default:
      return state;
  }
}
