// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { PaginationDto } from 'middleware/dto/pagination';

import { CandidateDto } from 'rest/candidates/candidate.dto';
import { CurrentRecruiterDto } from 'rest/recruiter/current/current.dto';

import { getAllPurchasedCandidates, resetPurchasedList } from 'services/portal/recruiter/account/account.actions';

type Props = {
  history: History,
  fetching: boolean,
  hasMoreItems: boolean,
  resetPurchasedList: () => {},
  currentUser: CurrentRecruiterDto,
  purchasedCandidates: CandidateDto[],
  getAllPurchasedCandidates: (companyId: string, params: PaginationDto) => void
}

export class Setting extends Component<Props> {

  static defaultProps = {
    currentUser: new CandidateDto()
  }

}

export const stateToProps = (state: GlobalState) => ({
  currentUser: state.portal.recruiter.account.current.currentUser,
  hasMoreItems: state.portal.recruiter.account.purchased.hasMoreItems,
  purchasedCandidates: state.portal.recruiter.account.purchased.candidates
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetPurchasedList,
  getAllPurchasedCandidates
}, dispatch);
