// @flow
import React from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { TabLink, Tabs } from 'components/tabs';
import { StatisticsItem } from 'components/widgets/statistics';

import { JobOffersParams } from 'rest/public/job-offers/job-offers.params';

import { Settings, stateToProps, dispatchToProps } from './follow.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class FollowSidebar extends Settings {

  componentDidMount () {
    this.loadFollowsStatistic(this.props.currentUser.id);
  }

  componentWillUpdate (props) {
    if (!this.props.currentUser.id) {
      this.loadFollowsStatistic(props.currentUser.id);
    }
  }

  componentWillUnmount () {
    this.props.resetFollowsStatistic();
  }

  loadFollowsStatistic (candidateId: string) {
    if (candidateId) {
      this.props.getFollowingStatistic(candidateId);
      this.props.getFollowersStatistic(candidateId);
    }
  }

  changeFollowerFilter (companyId: string) {
    const params: JobOffersParams = new JobOffersParams();
    params.companyId = companyId;
    this.props.changeFollowersFilter(params);
  }

  changeFollowingFilter (companyId: string) {
    const params: JobOffersParams = new JobOffersParams();
    params.companyId = companyId;
    this.props.changeFollowingFilter(params);
  }

  render () {
    return (
      <Tabs candidate>
        <TabLink
          link="/portal/candidate/follow/following"
          counter={this.props.totalFollowing}
          label={this.props.t('portal.candidate.follows.sidebar.following')}
          onClick={() => this.props.changeFollowingFilter()}>
          <PerfectScrollbar className="statistics-items">
            <StatisticsItem
              title={this.props.t('portal.candidate.follows.sidebar.all')}
              active={!this.props.followingCompanyId}
              onClick={() => this.props.changeFollowingFilter()}
              value={this.props.totalFollowing}
              candidate/>
            {
              this.props.followingStats.map((stat, index) => (
                <StatisticsItem
                  key={index}
                  title={`${stat.name.toLowerCase()}`}
                  value={stat.count}
                  active={this.props.followingCompanyId === stat.id}
                  onClick={() => this.changeFollowingFilter(stat.id)}
                  candidate/>
              ))
            }
          </PerfectScrollbar>
        </TabLink>
        <TabLink
          link="/portal/candidate/follow/followers"
          counter={this.props.totalFollowers}
          label={this.props.t('portal.candidate.follows.sidebar.followers')}
          onClick={() => this.props.changeFollowersFilter()}>
          <PerfectScrollbar className="statistics-items">
            <StatisticsItem
              title={this.props.t('portal.candidate.follows.sidebar.all')}
              value={this.props.totalFollowers}
              active={!this.props.followersCompanyId}
              onClick={() => this.props.changeFollowersFilter()}
              candidate/>
            {
              this.props.followersStats.map((stat, index) => (
                <StatisticsItem
                  key={index}
                  title={`${stat.name.toLowerCase()}`}
                  value={stat.count}
                  active={this.props.followersCompanyId === stat.id}
                  onClick={() => this.changeFollowerFilter(stat.id)}
                  candidate/>
              ))
            }
          </PerfectScrollbar>
        </TabLink>
      </Tabs>
    );
  }

}
