import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import { GlobalState } from 'common/types/global-state';

import { ResponseTeamMemberDto } from 'rest/companies/team-members/team-member.response';

import { deleteRecruiterTeamMember } from 'services/portal/recruiter/account/account.actions';

import './delete-member-modal.scss';

export type Props = {
  t?: TransProps,
  companyId: string,
  hideModal: () => void,
  member: ?ResponseTeamMemberDto,
  deleteCompanyMemberSuccess: boolean,
  deleteRecruiterTeamMember: (companyId: string, memberId: string) => void
}

type State = {
  deleteCompanyMemberSuccess: boolean
}

export class Setting extends Component<Props, State> {

  static defaultProps = {
    hideModal: () => {},
    deleteRecruiterTeamMember: () => {}
  };

  state: State = {
    deleteCompanyMemberSuccess: false
  }

}

export const stateToProps = (state: GlobalState) => ({
  deleteCompanyMemberSuccess: state.portal.recruiter.account.teamMembers.deleteCompanyMemberSuccess
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  deleteRecruiterTeamMember
}, dispatch);
