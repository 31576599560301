/* eslint padded-blocks: ["error", "never"] */
import React, { Component } from 'react';
import '../styles/semantic.min.css';
import '../styles/styles.css';

export class GDPRCandidateJobOfferViewed extends Component {
  render () {
    return (
      <div className="gdpr-main">
        <div className="ui container main-content">
          <h1 className="ui header main-title"> Candidate viewed job offer </h1>
          <a href="/information/gdpr">
            <button className="ui button back-link">Back To Main</button>
          </a>
          <h5 className="ui header"> Total Elements:  4</h5>
          <table className="ui single orange line table">
            <thead>
              <tr>
                <th> Job Offer Link </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>    <a href="https://www.excellent-jobs.com/portal/candidate/jobs/5ce41b0646e0fb0001656432/preview">
                    Job Offer Link   </a>
                </td>
              </tr>
              <tr>
                <td>    <a href="https://www.excellent-jobs.com/portal/candidate/jobs/5f18831c59a1190001b78a89/preview">
                    Job Offer Link   </a>
                </td>
              </tr>
              <tr>
                <td>    <a href="https://www.excellent-jobs.com/portal/candidate/jobs/5d495e2046e0fb000191f791/preview">
                    Job Offer Link   </a>
                </td>
              </tr>
              <tr>
                <td>    <a href="https://www.excellent-jobs.com/portal/candidate/jobs/5cffd27246e0fb0001c65f52/preview">
                    Job Offer Link   </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
