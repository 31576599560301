// @flow
import React, { Fragment } from 'react';

import cn from 'classnames';
import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';

import { connectStore } from 'middleware/connect';
import { uploadFile } from 'middleware/upload-file';

import { getData, setData } from 'middleware/job-offer-storage';
import type { DropdownType } from 'components/controls/dropdown/dropdown.type';

import type { RatingType } from 'containers/portal/recruiter/account/settings/company-info/company-info.rating.type';
import { Avatar } from 'components/avatar/avatar';
import { Button, LinkButton } from 'components/buttons';

import { FormGroup, InputControl, Dropdown, MultiSelect, AutosuggestSelect } from 'components/controls';

import { PreviewImage } from 'containers/portal/recruiter/account/settings';

import { RATING_COMPANY_SYSTEM } from 'services/portal/recruiter/account/companies/companies.constants';
import { ImageDto } from 'rest/images/image.dto';
import { AddressDto } from 'rest/address/address.dto';
import { UserAddressDto } from 'rest/address-dto/address';

import { CompanyDto } from 'rest/companies/companies.dto';
import { ImageResponseDto } from 'rest/images/images.response';
import { Settings, dispatchToProps, stateToProps } from './company-info.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class CompanyInfoTab extends Settings {

  componentDidMount () {
    this.props.getAllBrands();
    this.props.getAllBranches();
    const savedCompanyInfo: CompanyDto = getData('ej_company_info');
    if (savedCompanyInfo) {
      this.setState({
        ...savedCompanyInfo,
        ...savedCompanyInfo.address,
        logoPreview: savedCompanyInfo.logo ? savedCompanyInfo.logo.url : ''
      });
    }
  }

  searchBranchItem (id: string): DropdownType {
    return this.props.branches.find(elem => elem.id === id);
  }

  changeCompanyState (field: string, value: string) {
    if (field === 'branchId') {
      this.setState({ branchError: false });
    }
    this.setState({ [field]: value });
  }

  clearLogoState () {
    this.setState({ logoPreview: '' });
  }

  changeLogoState (file: File) {
    uploadFile(file, (base64, error) => {
      this.setState({
        logoPreview: base64,
        logoPreviewError: error,
        imageSizeError: error
      }, () => this.props.uploadFile(file, 'avatar'));
    });
  }

  changePhotosState (file: File) {
    if (file && this.state.photos.length < this.maxPhotosCount) {
      uploadFile(file, (base64, error) => {
        if (error) {
          this.setState({ imageSizeError: error });
          toast.error(this.props.t('portal.guest.notifications.incorrect-size'));
        } else {
          const photos = Array.from(this.state.photos);
          photos.push(file);
          this.setState({
            imageSizeError: false,
            photos
          });
          this.inputUpload.current.value = null;
        }
      });
    }
  }

  changeReadyCompanyPhotosState (imageInfo: ImageResponseDto) {
    const previewPhotos = Array.from(this.state.previewPhotos);
    previewPhotos.push(imageInfo);
    this.setState({ previewPhotos });
  }

  removeCompanyPreviewPhoto (id: number) {
    const photos = Array.from(this.state.photos);
    const newPhotos = photos.filter((item, index) => index !== id);
    this.setState({
      photos: newPhotos
    });
  }

  getCompanyLogo (): ImageDto {
    const logo = new ImageDto(this.state.logo);
    if (this.props.avatarInfo && this.state.logoPreview) {
      logo.primary = true;
      logo.imageId = this.props.avatarInfo.imageId;
      logo.url = this.props.avatarInfo.url;
    }
    return logo;
  }

  setCompanyPhotos (): ImageDto[] {
    if (this.state.photos.length || this.state.previewPhotos.length) {
      const photos = this.state.photos.filter(item => item.imageId);
      const companyPhotos = [...photos, ...this.state.previewPhotos];
      companyPhotos[0].primary = true;
      return companyPhotos;
    }
  }

  getRatingList (): RatingType[] {
    const defaultBranch = this.searchBranchItem(this.state.branchId);
    const branchName = defaultBranch && defaultBranch.name;
    switch (branchName) {
      case 'Hotel':
        return RATING_COMPANY_SYSTEM.hotel;
      case 'Restaurant':
        return RATING_COMPANY_SYSTEM.restaurant;
      default:
        return [];
    }
  }

  suggestCountry (value: string) {
    const query: AddressDto = new AddressDto();
    query.city = '';
    query.street = '';
    query.country = value;
    this.props.searchSuggestedAddress('country', query);
  }

  suggestStreet (value: string) {
    const query: AddressDto = new AddressDto(this.state);
    query.street = value;
    this.props.searchSuggestedAddress('street', query);
  }

  suggestCity (value: string) {
    const query: AddressDto = new AddressDto(this.state);
    query.street = '';
    query.city = value;
    this.props.searchSuggestedAddress('city', query);
  }

  validate (cb: (isValid: boolean) => void) {
    this.setState({
      branchError: !this.state.branchId,
      logoPreviewError: !this.state.logoPreview
    }, () => {
      const isImageError = !this.state.logoPreviewError && !this.props.imageFetching;
      const valid = !this.state.branchError && isImageError;
      cb(valid);
    });
  }

  saveDraft () {
    const company = new CompanyDto(this.state);
    company.address = new UserAddressDto(this.state);
    company.photos = this.setCompanyPhotos();
    company.logo = this.getCompanyLogo();
    setData('ej_company_info', company);
  }

  navigateToNextPage (event: Event) {
    event.preventDefault();
    this.validate(valid => {
      if (valid) {
        this.saveDraft();
        this.props.next();
      }
    });
  }

  render () {
    const ratingList = this.getRatingList();
    return (
      <Fragment>
        <Avatar
          uploadButtonType="accent outlined"
          loading={this.props.imageFetching}
          error={!!this.state.logoPreviewError}
          previewImageUrl={this.state.logoPreview}
          change={this.changeLogoState.bind((this))}
          clearImageState={this.clearLogoState.bind(this)}
          uploadButtonName={this.props.t('portal.recruiter.company-info.avatar.name')}
          errorText={this.state.imageSizeError
            ? this.state.imageSizeError : this.props.t('portal.recruiter.company-info.avatar.error')}
        />
        <form
          onSubmit={this.navigateToNextPage.bind(this)}
          className="company-info-form">
          <FormGroup className="half-width">
            <InputControl
              required
              value={this.state.name}
              change={(name: string): void => this.setState({ name })}
              label={this.props.t('portal.recruiter.company-info.company-name.label')}
              placeholder={this.props.t('portal.recruiter.company-info.company-name.placeholder')}
              type="text"/>
          </FormGroup>
          <FormGroup className="half-width">
            <MultiSelect
              label={this.props.t('portal.recruiter.company-info.brands.label')}
              items={this.props.brands}
              defaultValue={this.state.brandId}
              change={value => this.setState({ brandId: value.id })}
              isMulti={false}
              placeholder={this.props.t('portal.recruiter.company-info.brands.placeholder')}/>
          </FormGroup>
          <FormGroup className="half-width">
            <MultiSelect
              error={this.state.branchError}
              items={this.props.branches}
              defaultValue={this.state.branchId}
              label={this.props.t('portal.recruiter.company-info.branch.label')}
              errorText={this.props.t('portal.recruiter.company-info.branch.error')}
              placeholder={this.props.t('portal.recruiter.company-info.branch.placeholder')}
              change={value => this.changeCompanyState('branchId', value.id)}
              isMulti={false}/>
          </FormGroup>
          <FormGroup className="half-width">
            <Dropdown
              icon
              disabled={ratingList.length === 0}
              label={this.props.t('portal.recruiter.company-info.rating.label')}
              change={value => this.changeCompanyState('stars', value.id)}
              value={ratingList[this.state.stars] && ratingList[this.state.stars].id}
              items={ratingList}/>
          </FormGroup>
          <FormGroup className="half-width">
            <AutosuggestSelect
              required
              items={this.props.address.country}
              defaultValue={this.state.country}
              label={this.props.t('portal.recruiter.company-info.country.label')}
              placeholder={this.props.t('portal.recruiter.company-info.country.placeholder')}
              change={(value: string) => this.setState({ country: value })}
              search={(value: string) => this.suggestCountry(value)}/>
          </FormGroup>
          <FormGroup className="half-width">
            <AutosuggestSelect
              required
              items={this.props.address.city}
              defaultValue={this.state.city}
              label={this.props.t('portal.recruiter.company-info.city.label')}
              placeholder={this.props.t('portal.recruiter.company-info.city.placeholder')}
              change={(value: string) => this.setState({ city: value })}
              search={(value: string) => this.suggestCity(value)}/>
          </FormGroup>
          <FormGroup className="full-width zip-code">
            <InputControl
              required
              value={this.state.zipCode}
              change={zipCode => this.setState({ zipCode })}
              label={this.props.t('portal.recruiter.company-info.zip-code.label')}
              placeholder={this.props.t('portal.recruiter.company-info.zip-code.placeholder')}
              type="text"/>
          </FormGroup>
          <FormGroup className="half-width">
            <AutosuggestSelect
              required
              defaultValue={this.state.street}
              items={this.props.address.street}
              label={this.props.t('portal.recruiter.company-info.street.label')}
              placeholder={this.props.t('portal.recruiter.company-info.street.placeholder')}
              change={(value: string) => this.setState({ street: value })}
              search={(value: string) => this.suggestStreet(value)}/>
          </FormGroup>
          <FormGroup className="half-width billing-number">
            <InputControl
              required
              value={this.state.streetNumber}
              change={(streetNumber: string): void => this.setState({ streetNumber })}
              label={this.props.t('portal.recruiter.company-info.building-number.label')}
              placeholder={this.props.t('portal.recruiter.company-info.building-number.placeholder')}
              type="text"/>
          </FormGroup>
          <FormGroup className="full-width upload-btn-container">
            <label>Company Photos <span>(up to three)</span></label>
            <div className={cn([
              'btn accent outlined full-width big',
              { disabled: this.state.photos.length >= this.maxPhotosCount }
            ])}>
              <img
                src="/images/shared/upload-icon.svg"
                alt="upload"
              />
              <Trans i18nKey="portal.recruiter.company-info.photos.btn-label">
                Choose a file
              </Trans>
              <input
                ref={this.inputUpload}
                onChange={(event: Event) => this.changePhotosState(event.target.files[0])}
                accept="image/png, image/jpeg"
                type="file"
                className="upload-file-input"
              />
            </div>
            {
              this.state.imageSizeError && <span className="image-size-error">{ this.state.imageSizeError }</span>
            }
          </FormGroup>
          <PreviewImage
            removePreviewPhoto={this.removeCompanyPreviewPhoto.bind(this)}
            imageLoad={this.changeReadyCompanyPhotosState.bind(this)}
            files={this.state.photos}/>
          <FormGroup className="full-width">
            <InputControl
              required
              value={this.state.description}
              change={(description: string): void => this.setState({ description })}
              label={this.props.t('portal.recruiter.company-info.description.label')}
              placeholder={this.props.t('portal.recruiter.company-info.description.placeholder')}
              multiline
              maxLength={1000}
            />
          </FormGroup>
          <FormGroup className="form-btn-submit">
            <LinkButton
              grey
              link="/">
              <Trans i18nKey="portal.recruiter.candidate-info.cancel-button">
                Cancel
              </Trans>
            </LinkButton>
            <Button
              medium
              recruiter
              type="submit">
              <Trans i18nKey="portal.recruiter.candidate-info.next-button">
                Next
              </Trans>
            </Button>
          </FormGroup>
        </form>
      </Fragment>
    );
  }

}
