import { Component } from 'react';

import { TransProps } from 'react-i18next';

import './profile-modal.scss';

type Props = {
  t?: TransProps
}

type State = {
  activeNavLink: number
}

export class Settings extends Component<Props, State> {

  state = {
    activeNavLink: 0
  }

}
