// @flow
import React from 'react';

import { connectStore } from 'middleware/connect';

import { CounterWidget } from 'components/widgets';

import { Settings, stateToProps } from './followers.setting';

@connectStore(stateToProps, null, true)
export class FollowersCircle extends Settings {

  render () {
    return (
      <CounterWidget
        title={this.props.t('portal.recruiter.follows.circle.title')}
        value={this.props.total}
        circleTitle="Followers Candidates"/>
    );
  }

}
