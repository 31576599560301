import * as actions from './team-members.constants';

import type { TeamMembersStateType } from './team-members.type';

const initialState: TeamMembersStateType = {
  errorText: '',
  fetching: false,
  teamMembers: null,
  inviteTeamMembersSuccess: false,
  deleteCompanyMemberSuccess: false
};

export default (state = initialState, action): TeamMembersStateType => {
  switch (action.type) {
    case actions.GET_COMPANY_TEAM_MEMBERS_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case actions.GET_COMPANY_TEAM_MEMBERS_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.INVITE_TEAM_MEMBERS_FAILURE:
      return {
        ...state,
        fetching: false,
        errorText: action.error
      };

    case actions.GET_COMPANY_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        fetching: false,
        teamMembers: action.members.content
      };

    case actions.INVITE_TEAM_MEMBERS_REQUEST:
      return {
        ...state,
        fetching: true,
        inviteTeamMembersSuccess: false
      };

    case actions.INVITE_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        inviteTeamMembersSuccess: true,
        fetching: false
      };

    case actions.DELETE_COMPANY_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        teamMembers: state.teamMembers.filter(member => member.id !== action.memberId),
        deleteCompanyMemberSuccess: true,
        fetching: false
      };

    case actions.DELETE_COMPANY_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        deleteCompanyMemberSuccess: false,
        fetching: false
      };

    default:
      return state;
  }
};
