import axios, { AxiosPromise } from 'axios';

import { List } from 'rest/list/list.dto';

import { SkillsContainerDto } from 'rest/skills/skills-container.dto';

import { JobOffersParams } from './job-offers.params';
import { JobOffersResponseDto } from './job-offers.response.dto';
import { JobOfferMatchingRate } from './job-offers-matching.dto';
import { JobOffersStatsResponseDto } from './job-offers-stats.dto';

export async function getAllJobOfferRest (params = new JobOffersParams()): AxiosPromise<List<JobOffersResponseDto>> {
  try {
    return await axios.get('/public/job-offers', { params });
  } catch (error) {
    throw error;
  }
}

export async function createMatchingRate (jobOffer: JobOffersResponseDto): AxiosPromise<JobOfferMatchingRate> {
  try {
    return await axios.post('/public/job-offers', jobOffer);
  } catch (error) {
    throw error;
  }
}

export async function getJobOfferRest (id: string): AxiosPromise<JobOffersResponseDto> {
  try {
    return await axios.get(`/public/job-offers/${id}`);
  } catch (error) {
    throw error;
  }
}

export async function getJobOfferSkillRest (id: string): AxiosPromise<SkillsContainerDto> {
  try {
    return await axios.get(`/public/job-offers/${id}/skills`);
  } catch (error) {
    throw error;
  }
}

export async function getJobOffersStats (): AxiosPromise<JobOffersStatsResponseDto> {
  try {
    return await axios.get('/public/job-offers/stats');
  } catch (error) {
    throw error;
  }
}
