import React from 'react';

import { Setting } from './social-media.setting';

import { Facebook } from './icons/Facebook';
import { LinkedIn } from './icons/LinkedIn';
import { Instagram } from './icons/Instagram';

export class SocialMedia extends Setting {

  render () {
    return (
      <div className="socialmedia-container">
        <a className="socialmedia-items socialmedia-item-facebook"
          href="https://www.facebook.com/excellentjobsteam" target="_blank" rel="noopener noreferrer">
          <Facebook />
        </a>
        <a className="socialmedia-items socialmedia-item-instagram"
          href="https://www.instagram.com/excellent.jobs.app/ " target="_blank" rel="noopener noreferrer">
          <Instagram />
        </a>
        <a className="socialmedia-items socialmedia-item-linkedin"
          href="https://www.linkedin.com/showcase/30758811/admin/" target="_blank" rel="noopener noreferrer">
          <LinkedIn />
        </a>
      </div>
    );
  }

}
