// @flow
import React, { Fragment } from 'react';

import { Trans } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import { connectStore } from 'middleware/connect';
import { PaginationDto } from 'middleware/dto/pagination';

import { ListPlaceholder, Spinner } from 'components/elements';
import { ElementsList, CandidateRow } from 'components/elements-list';

import { CandidateDto } from 'rest/candidates/candidate.dto';

import { stateToProps, dispatchToProps, Setting } from './purchased-list.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class PurchasedCandidate extends Setting {

  componentWillUnmount () {
    this.props.resetPurchasedList();
  }

  loadPurchasedCandidateList (page: number = 0) {
    const query: PaginationDto = new PaginationDto();
    query.size = 10;
    query.page = page;
    this.props.getAllPurchasedCandidates(this.props.currentUser.companyId, query);
  }

  openCandidateProfile (candidateId: string) {
    this.props.history.push(`/portal/recruiter/favorites/purchased/${candidateId}/profile`);
  }

  render () {
    return (
      <Fragment>
        {
          this.props.currentUser.id && (
            <InfiniteScroll
              useWindow={false}
              threshold={100}
              pageStart={-1}
              hasMore={this.props.hasMoreItems}
              loadMore={(page: number) => this.loadPurchasedCandidateList(page)}>
              {
                this.props.purchasedCandidates.length ? (
                  <ElementsList>
                    {
                      this.props.purchasedCandidates.map((candidate: CandidateDto, index: number) => (
                        <CandidateRow
                          key={index}
                          onClick={() => this.openCandidateProfile(candidate.id)}
                          candidate={candidate}>
                        </CandidateRow>
                      ))
                    }
                  </ElementsList>) : <ListPlaceholder
                  title={this.props.t('portal.recruiter.favorites.list.purchased.placeholder.title')}>
                  <Trans i18nKey="portal.recruiter.favorites.list.purchased.placeholder.content">
                    Every time you buy the access to Candidate`s Profile, it will be saved here. When you subscribe
                    for Premium, your purchased tab will remain here too.
                  </Trans>
                </ListPlaceholder>
              }
            </InfiniteScroll>)
        }
        <Spinner show={this.props.fetching}/>
      </Fragment>
    );
  }

}
