import axios, { AxiosPromise } from 'axios';

import { PaginationDto } from 'middleware/dto/pagination';

import { CandidateDto } from 'rest/candidates/candidate.dto';

export async function getPurchasedCandidates (companyId: string, params: PaginationDto):
  AxiosPromise<CandidateDto[]> {
  try {
    return await axios.get(`/companies/${companyId}/purchased-candidates`, { params });
  } catch (error) {
    throw error;
  }
}
