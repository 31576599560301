import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { Router, Switch } from 'react-router-dom';

import { createBrowserHistory } from 'history';

import { store, persistor } from 'middleware/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Route } from 'middleware/route';

import { initRestParams } from 'middleware/rest';
import { initMomentSettings } from 'middleware/moment';

import { Seo } from 'components/seo/seo';
import { ToastifyContainer } from 'components/toastify';

import { AuthPage, PortalPage, LandingPage, InformationPage, NotFoundPage } from './pages';

import { CookiesPolicyModal } from './components/dialogs';
import 'font-awesome/css/font-awesome.min.css';
import 'middleware/refresh-token';

import './localization';

import './index.scss';

const Application = () => (
  <div className="app-container">
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <>
        <Seo/>
        <Router history={createBrowserHistory()}>
          <Switch>
            <Route
              path="/"
              type="guest"
              exact
              component={LandingPage}/>
            <Route
              path="/index.html"
              type="guest"
              exact
              component={LandingPage}/>
            <Route
              path="/information"
              component={InformationPage}/>
            <Route
              path="/auth"
              type="guest"
              component={AuthPage}/>
            <Route
              path="/portal"
              component={PortalPage}/>
            <Route
              path="/**"
              component={NotFoundPage}/>
          </Switch>
        </Router>
        <CookiesPolicyModal/>
      </>
      </PersistGate>
    </Provider>
    <ToastifyContainer/>
  </div>
);

initRestParams();
initMomentSettings();

ReactDOM.render(<Application/>, document.getElementById('root'));
