import { AxiosResponse } from 'axios';

import { PaginationDto } from 'middleware/dto/pagination';

import { List } from 'rest/list/list.dto';

import { MessagesUnreadDto } from 'rest/chats/messages/messages-unread.dto';
import { MessagesRequestDto } from 'rest/chats/messages/messages.request.dto';
import { MessagesResponseDto } from 'rest/chats/messages/messages.response.dto';

import {
  createChatConversations,
  getChatMessages,
  getUnreadChatMessages,
  resetUnreadChatMessages
} from 'rest/chats/messages/messages.rest';

import * as actions from './messages.constants';
import { getUnreadConversation, blockConversation } from 'services/portal/chats/conversations/conversations.actions';

const getChatMessagesRequest = () => ({
  type: actions.GET_CHAT_MESSAGES_REQUEST
});

const getChatMessagesSuccess = data => ({
  type: actions.GET_CHAT_MESSAGES_SUCCESS,
  payload: data
});

const getChatMessagesFailure = (error: string) => ({
  type: actions.GET_CHAT_MESSAGES_FAILURE,
  error
});

const resetChatMessagesSuccess = () => ({
  type: actions.RESET_CHAT_MESSAGES_SUCCESS
});

const createChatMessageRequest = () => ({
  type: actions.CREATE_CHAT_MESSAGES_REQUEST
});

const createChatMessagesSuccess = data => ({
  type: actions.CREATE_CHAT_MESSAGES_SUCCESS,
  payload: data
});

const createChatMessagesFailure = (error: string) => ({
  type: actions.CREATE_CHAT_MESSAGES_FAILURE,
  error
});

const setChatMessagesComing = () => ({
  type: actions.NEW_CHAT_MESSAGES_COMING
});

const setChatMessagesRead = () => ({
  type: actions.NEW_CHAT_MESSAGES_READ
});

const saveChatUnreadMessages = data => ({
  type: actions.GET_CHAT_UNREAD_MESSAGES,
  payload: data
});

const resetChatUnreadMessages = () => ({
  type: actions.RESET_CHAT_UNREAD_MESSAGES
});

function handleErrorChatMessage (dispatch, status: number, conversationId: string) {
  if (status === 400) {
    return dispatch(blockConversation(conversationId));
  }
  return dispatch(createChatMessagesFailure('Something was wrong'));
}

export const resetAllMessages = () => {
  return dispatch => {
    dispatch(resetChatMessagesSuccess());
  };
};

export const resetNewMessage = () => {
  return dispatch => {
    dispatch(setChatMessagesRead());
    dispatch(resetChatUnreadMessages());
  };
};

export const getUnreadMessages = (conversationId: string) => {
  return dispatch => {
    return getUnreadChatMessages(conversationId)
      .then((response: AxiosResponse<MessagesUnreadDto>) => {
        dispatch(saveChatUnreadMessages(response.data));
      });
  };
};

export const resetUnreadMessages = (conversationId: string, messageId: string) => {
  return dispatch => {
    return resetUnreadChatMessages(conversationId, messageId)
      .then(() => {
        getUnreadConversation();
        dispatch(resetChatUnreadMessages());
      });
  };
};

export const getAllMessages = (conversationId: number, page: number) => {
  return dispatch => {
    dispatch(getChatMessagesRequest());
    const params = new PaginationDto();
    params.page = page;
    return getChatMessages(conversationId, params)
      .then((response: AxiosResponse<List<MessagesResponseDto>>) => {
        dispatch(params.page === 0 ? resetAllMessages() : null);
        dispatch(getChatMessagesSuccess(response.data));
        dispatch(params.page === 0 ? setChatMessagesComing() : null);
      })
      .catch(error => dispatch(getChatMessagesFailure(error)));
  };
};

export const createMessage = (conversationId: string, message: MessagesRequestDto) => {
  return dispatch => {
    dispatch(createChatMessageRequest());
    return createChatConversations(conversationId, message)
      .then((response: AxiosResponse<List<MessagesResponseDto>>) => {
        dispatch(createChatMessagesSuccess(response.data));
        dispatch(setChatMessagesComing());
      })
      .catch(error => handleErrorChatMessage(dispatch, error.response.status, conversationId));
  };
};
