// @flow
import React from 'react';

import moment from 'moment';
import { Trans } from 'react-i18next';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { Modal } from 'components/dialogs';
import { Card, Chips } from 'components/layouts';
import { ShowSalaryRange } from 'components/elements';
import { LinkButton, Button } from 'components/buttons';
import { SkillsList } from 'components/elements/skills-list/skills-list';

import type { Props } from './preview.setting';
import { Settings, stateToProps, dispatchToProps } from './preview.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class PreviewBalanceBoardModal extends Settings {

  componentDidMount () {
    this.fetchAllBalanceBoard();
  }

  componentDidUpdate (prevProps: Props) {
    if (prevProps.currentCandidate.id !== this.props.currentCandidate.id) {
      this.fetchAllBalanceBoard();
    }
  }

  fetchAllBalanceBoard () {
    this.props.getCandidateBalanceBoard(this.props.currentCandidate.id);
    this.props.getCandidateSkillsById(this.props.currentCandidate.id);
  }

  openEditBalanceBoardModal () {
    this.props.history.push('/portal/candidate/balance-board/edit');
  }

  render () {
    const jobTitles = this.props.balanceBoard && this.props.balanceBoard.jobTitles;
    return (
      <Modal closePath="/portal/candidate/balance-board">
        {
          this.props.balanceBoard && <div className="preview-balance-board-modal preview-balance-board-modal-mobile">
            <PerfectScrollbar className="content">
              <Card className="modal-header">
                <div className="left-side">
                  <LinkButton
                    inline
                    link="/portal/candidate/balance-board"
                    className="balance-board-back-link">
                    <Trans i18nKey="portal.candidate.balance-board.preview.back">
                      Back to balance board
                    </Trans>
                    <div className="modal-balance-board-back">&times;</div>
                  </LinkButton>
                  <h2 className="mobile-job-title">{(jobTitles && jobTitles.length)
                    ? jobTitles[0].name
                    : this.props.t('portal.candidate.balance-board.preview.no-job-title')}</h2>
                  <div className="branches-container">
                    {
                      this.props.balanceBoard.branches ? this.props.balanceBoard.branches.map((item, index) => (
                        <Chips key={index}>{item.name}</Chips>
                      )) : this.props.t('portal.candidate.balance-board.preview.no-branch')
                    }
                  </div>
                </div>
                <div className="right-action">
                  <Button
                    candidate
                    outlined
                    onClick={() => this.openEditBalanceBoardModal()}>
                    <Trans i18nKey="portal.candidate.balance-board.preview.edit">
                      Edit Balance Board
                    </Trans>
                  </Button>
                </div>
              </Card>
              <Card>
                <div className="competencies-wrapper">
                  <ul className="balance-board-info">
                    <li>
                      <b>
                        <Trans i18nKey="portal.candidate.balance-board.preview.salary">
                          Salary Expectation:
                        </Trans>
                      </b>
                      <ShowSalaryRange salaryRange={this.props.balanceBoard.salary}/>
                    </li>
                    <li>
                      <b>
                        <Trans i18nKey="portal.candidate.balance-board.preview.relocation.label">
                          Relocation:
                        </Trans>
                      </b>
                      <span>{this.props.balanceBoard.relocate
                        ? this.props.t('portal.candidate.balance-board.preview.relocation.able')
                        : this.props.t('portal.candidate.balance-board.preview.relocation.not-able')}
                      </span>
                    </li>
                    <li>
                      <b>
                        <Trans i18nKey="portal.candidate.balance-board.preview.job-type">
                          Job Type:
                        </Trans>
                      </b>
                      <span>{this.props.balanceBoard.jobTypes.join(', ')}</span>
                    </li>
                    <li>
                      <b>
                        <Trans i18nKey="portal.candidate.balance-board.preview.available-from">
                          Available from:
                        </Trans>
                      </b>
                      <span>{moment(this.props.balanceBoard.availableFrom).format('ll')}</span>
                    </li>
                    <li>
                      <b>
                        <Trans i18nKey="portal.candidate.balance-board.preview.working-time">
                          Working Time (hours/week):
                        </Trans>
                      </b>
                      <span>{this.props.balanceBoard.salary.workingHoursPerMonth}</span>
                    </li>
                  </ul>
                  <h4>
                    <Trans i18nKey="portal.candidate.balance-board.preview.competencies">
                      Competencies
                    </Trans>
                  </h4>
                  {
                    this.props.skills && <SkillsList
                      className="mobile-table"
                      skills={this.props.skills}/>
                  }
                </div>
              </Card>
            </PerfectScrollbar>
          </div>
        }
      </Modal>
    );
  }

}
