export const GET_APPLICATIONS_REQUEST = 'GET_APPLICATIONS_REQUEST';
export const GET_APPLICATIONS_SUCCESS = 'GET_APPLICATIONS_SUCCESS';
export const GET_APPLICATIONS_FAILURE = 'GET_APPLICATIONS_FAILURE';

export const CREATE_APPLICATIONS_REQUEST = 'CREATE_APPLICATIONS_REQUEST';
export const CREATE_APPLICATIONS_SUCCESS = 'CREATE_APPLICATIONS_SUCCESS';
export const CREATE_APPLICATIONS_FAILURE = 'CREATE_APPLICATIONS_FAILURE';

export const UPDATE_APPLICATION_REQUEST = 'UPDATE_APPLICATION_REQUEST';
export const UPDATE_APPLICATION_SUCCESS = 'UPDATE_APPLICATION_SUCCESS';
export const UPDATE_APPLICATION_FAILURE = 'UPDATE_APPLICATION_FAILURE';

export const GET_APPLICATION_BY_ID_REQUEST = 'GET_APPLICATION_BY_ID_REQUEST';
export const GET_APPLICATION_BY_ID_SUCCESS = 'GET_APPLICATION_BY_ID_SUCCESS';
export const GET_APPLICATION_BY_ID_FAILURE = 'GET_APPLICATION_BY_ID_FAILURE';

export const DELETE_APPLICATION_REQUEST = 'DELETE_APPLICATION_REQUEST';
export const DELETE_APPLICATION_SUCCESS = 'DELETE_APPLICATION_SUCCESS';
export const DELETE_APPLICATION_FAILURE = 'DELETE_APPLICATION_FAILURE';

export const RESET_APPLICATIONS_SUCCESS = 'RESET_APPLICATIONS_SUCCESS';

export const CHANGE_TEMPLATES_FILTER_SUCCESS = 'CHANGE_TEMPLATES_FILTER_SUCCESS';
