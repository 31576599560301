// @flow
import React from 'react';
import { Link } from 'react-router-dom';

import { Settings } from './cv-section-container.setting';

export class CVSectionContainer extends Settings {

  render () {
    return (
      <div className="cv-section-container mobile-cv-section-container">
        <p className="section-title">{this.props.title}</p>
        <div className="cv-section-content">
          {this.props.children}
          {
            this.props.showEditIcon && <div className="mobile-edit-icon">
              <Link to={this.props.redirectLink}>
                <span className="edit-icon-container"/>
              </Link>
            </div>
          }
        </div>
      </div>
    );
  }

}
