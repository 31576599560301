import { Component } from 'react';

import { TransProps } from 'react-i18next';

import { CompanyDto } from 'rest/companies/companies.dto';

import './company-info.scss';
import './company-info.responsive.scss';

export type Props = {
  t: TransProps,
  company: CompanyDto,
  hideCompanyInfo: boolean,
  toggleCompanyFollow: (follow: boolean) => void
};

export class Settings extends Component<Props> {

  static defaultProps: Props = {
    guestRedirectLink: '',
    applyAction: () => {},
    hideCompanyInfo: false
  }

}
