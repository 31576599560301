// @flow
import React from 'react';

import { Trans } from 'react-i18next';
import Slider from 'react-slick';

import { connectTranslation } from 'middleware/connect';

import { ContactModal } from 'containers/landing';
import { Button, LinkButton } from 'components/buttons';
import { isLoggedIn } from 'middleware/auth';
import { carouselSettings, Setting } from './companies.setting';

@connectTranslation()
export class Companies extends Setting {

  handleContactForm = () => this.setState({ isOpen: !this.state.isOpen });

  render () {
    // This is temporary fix --- Fix it permanently
    let companyJobs = isLoggedIn() ? 'portal/candidate/jobs' : 'portal/guest/jobs';
    return (
      <div className="companies-container">
        <div className="title">
          <Trans i18nKey="landing.companies.title">
            Offers from top companies
          </Trans>
        </div>
        <div className="companies-list">
          <Slider {...carouselSettings}>
            <LinkButton
              className="company company_size_s card mobile-landing-carousel-card"
              link={{
                pathname: companyJobs,
                state: { company: 'RHK Consulting & Services GmbH' }
              }}
              secondary>
              <div className="card-head">
                <span className="image-container">
                  <img
                    src="./images/landing/rheinland.jpg"
                    alt="Rheinland Hotel Kollektion"
                    title="Rheinland Hotel Kollektion"
                    className="logo" />
                </span>
                <span className="offers">
                  <Trans i18nKey="landing.companies.label.hospitality">
                    Hospitality
                  </Trans>
                </span>
              </div>
              <div className="card-body">
                <span className="name">Rheinland Hotel Kollektion</span>
                <i className="icon" />
              </div>
            </LinkButton>
            <LinkButton
              className="company company_size_s card mobile-landing-carousel-card"
              link={{
                pathname: companyJobs,
                state: { company: 'Travel Charme Binz GmbH' }
              }}
              secondary>
              <div className="card-head">
                <span className="image-container">
                  <img
                    src="./images/landing/travelcharme.jpg"
                    alt="Travel Charme"
                    title="Travel Charme"
                    className="logo" />
                </span>
                <span className="offers">
                  <Trans i18nKey="landing.companies.label.hospitality">
                    Hospitality
                  </Trans>
                </span>
              </div>
              <div className="card-body">
                <span className="name">Travel Charme</span>
                <i className="icon" />
              </div>
            </LinkButton>
            <LinkButton
              className="company company_size_s card mobile-landing-carousel-card"
              link={{
                pathname: companyJobs,
                state: { company: 'Restaurant Jellyfish' }
              }}
              secondary>
              <div className="card-head">
                <span className="image-container">
                  <img
                    src="./images/landing/jellyfish.png"
                    alt="Jellyfish Restaurant"
                    title="Jellyfish Restaurant"
                    className="logo" />
                </span>
                <span className="offers">
                  <Trans i18nKey="landing.companies.label.hospitality">
                    Hospitality
                  </Trans>
                </span>
              </div>
              <div className="card-body">
                <span className="name">Jellyfish Restaurant</span>
                <i className="icon" />
              </div>
            </LinkButton>
            <LinkButton
              className="company company_size_s card mobile-landing-carousel-card"
              link={{
                pathname: companyJobs,
                state: { company: 'Engel restaurant' }
              }}
              secondary>
              <div className="card-head">
                <span className="image-container">
                  <img
                    src="./images/landing/engel_logo.jpeg"
                    alt="Engel restaurant"
                    title="Engel restaurant"
                    className="logo" />
                </span>
                <span className="offers">
                  <Trans i18nKey="landing.companies.label.hospitality">
                    Hospitality
                  </Trans>
                </span>
              </div>
              <div className="card-body">
                <span className="name">Engel restaurant</span>
                <i className="icon" />
              </div>
            </LinkButton>
            <LinkButton
              className="company company_size_s card mobile-landing-carousel-card"
              link={{
                pathname: companyJobs,
                state: { company: 'Clipper Elb-Lodge' }
              }} secondary>
              <div className="card-head">
                <span className="image-container">
                  <img
                    src="./images/landing/clipper.jpg"
                    alt="Clipper Elb-Lodge"
                    title="Clipper Elb-Lodge"
                    className="logo" />
                </span>
                <span className="offers">
                  <Trans i18nKey="landing.companies.label.hospitality">
                    Hospitality
                  </Trans>
                </span>
              </div>
              <div className="card-body">
                <span className="name">Clipper Elb-Lodge</span>
                <i className="icon" />
              </div>
            </LinkButton>
            {/* <LinkButton
              className="company company_size_s card mobile-landing-carousel-card"
              link={{
                pathname: companyJobs,
                state: { company: 'German Seasons Hotel' }
              }} secondary>
              <div className="card-head">
                <span className="image-container">
                  <img
                    src="./images/landing/german-seasons-hotel.png"
                    alt="German Seasons hotel"
                    title="German Seasons hotel"
                    className="logo" />
                </span>
                <span className="offers">
                  <Trans i18nKey="landing.companies.label.hospitality">
                    Hospitality
                  </Trans>
                </span>
              </div>
              <div className="card-body">
                <span className="name">German Seasons Hotel</span>
                <i className="icon" />
              </div>
            </LinkButton> */}
            <LinkButton
              className="company company_size_s card mobile-landing-carousel-card"
              link={{
                pathname: companyJobs,
                state: { company: 'invent-solutions' }
              }} secondary>
              <div className="card-head">
                <span className="image-container">
                  <img
                    src="./images/landing/Invent-solutions.png"
                    alt="Invent Solutions"
                    title="Invent Solutions"
                    className="logo" />
                </span>
                <span className="offers">
                  <Trans i18nKey="landing.companies.label.company">
                    Our Company
                  </Trans>
                </span>
              </div>
              <div className="card-body">
                <span className="name">Invent Solutions</span>
                <i className="icon" />
              </div>
            </LinkButton>
            <Button
              className="company company_size_l card mobile-landing-carousel-card"
              onClick={this.handleContactForm}>
              <div className="card-head">
                <span className="image-container">
                  <img
                    src="./images/landing/exsellent-jobs-logo.svg"
                    alt="Exsellent-jobs product"
                    title="Exsellent-jobs product"
                    className="logo logo_size_l" />
                </span>
                <span className="offers">
                  <Trans i18nKey="landing.companies.label.collaboration">
                    Collaboration
                  </Trans>
                </span>
              </div>
              <div className="card-body">
                <span className="name">
                  <Trans i18nKey="landing.companies.list.exJ.title">
                    Want your job offers to be placed here?
                  </Trans>
                </span>
                <span className="description">
                  <Trans i18nKey="landing.companies.list.exJ.subTitle">
                    Contact us for collaboration
                  </Trans>
                </span>
                <i className="icon" />
              </div>
            </Button>
            <LinkButton
              className="company company_size_m card mobile-landing-carousel-card"
              link="https://www.invent-science.com"
              external={true} secondary>
              <div className="card-head">
                <span className="image-container">
                  <img
                    src="./images/landing/invent-science.svg"
                    alt="Invent-science product"
                    title="Invent-science product"
                    className="logo logo_size_m"
                  />
                </span>
                <span className="offers">
                  <Trans i18nKey="landing.companies.label.company">
                    Our Company
                  </Trans>
                </span>
              </div>
              <div className="card-body">
                <span className="name">
                  <Trans i18nKey="landing.companies.list.inventScience.title">
                    Interested in what we do?
                  </Trans>
                </span>
                <span className="description">
                  <Trans i18nKey="landing.companies.list.inventScience.subTitle">
                    Visit our corporate website
                  </Trans>
                </span>
                <i className="icon" />
              </div>
            </LinkButton>
          </Slider>
        </div>
        <ContactModal
          open={this.state.isOpen}
          close={this.handleContactForm} />
      </div>
    );
  }

}
