// @flow
import React from 'react';

import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Container } from 'components/layouts';
import { LinkButton } from 'components/buttons';

import { Setting } from './start-now.setting';

@connectTranslation()
export class StartNow extends Setting {

  render () {
    return (
      <div className="start-now-container">
        <Container className="start-now-content">
          <div className="start-now-title">
            <Trans i18nKey="information.works.startNow.title">
              Looks Interesting?
            </Trans>
          </div>
          <div className="start-now-description">
            <Trans i18nKey="information.works.startNow.description">
              With help of our service, 1 .000  employers found suitable employees. Let’s work together.
            </Trans>
          </div>
          <div className="start-now-control">
            <LinkButton
              link="/auth/recruiter/sign-up"
              recruiter>
              Start Now
            </LinkButton>
          </div>
        </Container>
      </div>
    );
  }

}
