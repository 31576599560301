// @flow
import React from 'react';

import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';

import { IconButton, Button } from 'components/buttons';
import { DateControl, Dropdown } from 'components/controls';

import { Modal, ModalCaption } from 'containers/portal/modal-container';

import { ListDto } from 'middleware/dto/list';
import { connectStore } from 'middleware/connect';
import { generateTimeItems } from 'middleware/times/times';

import { CalendarInviteDto } from 'rest/companies/candidate-invites/candidate-invites-invite.dto';
import { CalendarRequestDto } from 'rest/companies/candidate-invites/candidate-invites-request.dto';

import type { Props } from './invite-modal.setting';
import { Settings, stateToProps, dispatchToProps } from './invite-modal.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class InviteCandidateModal extends Settings {

  componentWillMount () {
    this.setState({ times: generateTimeItems() });
  }

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.open !== this.props.open) {
      this.props.getAllCompanyJobOffers(nextProps.currentUser.companyId);
    }
  }

  createTimeSlot () {
    this.setState({
      slots: [ ...this.state.slots, new CalendarInviteDto() ]
    });
  }

  removeTimeSlot (slot: CalendarInviteDto) {
    this.setState({
      slots: this.state.slots.filter(item => item !== slot)
    });
  }

  initSlots () {
    const invite: CalendarRequestDto = new CalendarRequestDto();
    invite.candidateId = this.props.candidate.id;
    invite.jobOfferId = this.state.jobOfferId;
    invite.variants = this.state.slots.map(slot => slot.dateTime);
    this.props.createInvite(this.props.currentUser.companyId, invite, this.props.candidate.id);
    this.props.candidate.candidateInviteStatus = 'INVITED';
    this.props.close();
    toast.success(this.props.t('portal.recruiter.notifications.invite-add'));
  }

  closeModal () {
    this.props.close();
    this.setState({
      slots: [ new CalendarInviteDto() ],
      jobOfferId: null
    });
  }

  render () {
    return (
      <Modal
        overlay
        animation="fade"
        open={this.props.open}
        className="invite-candidate-modal"
        hideModal={() => this.closeModal()}
        closeOnOverlay={() => this.closeModal()}
        hideModalAvailable={false}>
        <ModalCaption
          hideModal={() => this.closeModal()}
          title={this.props.t('portal.recruiter.candidates.profile.invite.title')}/>
        <div className="invite-candidate-modal-content">
          <div className="description">
            <Trans i18nKey="portal.recruiter.candidates.profile.invite.description">
              Select up to three time slots and Excellent Jobs will send your propositions for interview time directly
              to your candidate&apos;s email.
            </Trans>
          </div>
          <div className="job-offer-list">
            <Dropdown
              icon
              label={this.props.t('portal.recruiter.candidates.profile.invite.job-offer')}
              value={this.state.jobOfferId}
              items={this.props.jobOffers
                .map(item => new ListDto({ id: item.jobOfferId, name: item.jobTitleName }))}
              change={(item: ListDto) => this.setState({ jobOfferId: item.id })}
              error={!this.props.jobOffers.length}
              errorText={'You don\'t have any job offer for create invite'}
              disabled={!this.props.jobOffers.length}/>
          </div>
          <div className="time-slots">
            <div className="slot-header">
              <div className="date">
                <Trans i18nKey="portal.recruiter.candidates.profile.invite.date">
                  Invitation Date
                </Trans>
              </div>
              <div className="time">
                <Trans i18nKey="portal.recruiter.candidates.profile.invite.time">
                  Time
                </Trans>
              </div>
              <div className="action"/>
            </div>
            {
              this.state.slots.map((slot: CalendarInviteDto, index: number) => (
                <div
                  key={index}
                  className="slot">
                  <div className="date">
                    <DateControl
                      recruiter
                      minDate={new Date()}
                      value={slot.date}
                      change={value => { slot.date = value; }}/>
                  </div>
                  <div className="time">
                    <Dropdown
                      icon
                      items={this.state.times}
                      value={slot.time}
                      change={(item: ListDto) => {
                        slot.time = item.name;
                        this.setState({ slots: this.state.slots });
                      }}/>
                  </div>
                  <div className="action">
                    {
                      this.state.slots.length > 1 && <IconButton
                        title={this.props.t('portal.recruiter.candidates.profile.invite.remove')}
                        icon="close"
                        onClick={() => this.removeTimeSlot(slot)}/>
                    }
                  </div>
                </div>
              ))
            }
          </div>
          <div className="more-time-slots">
            <Button
              type="button"
              recruiter
              outlined
              disabled={this.state.slots.length > 2}
              onClick={() => this.createTimeSlot()}
              className="add-more">
              <img src="/images/shared/add-icon-blue.svg"/>
              <Trans i18nKey="portal.recruiter.candidates.profile.invite.buttons.timeslot">
                Add Timeslot
              </Trans>
            </Button>
          </div>
        </div>
        <div className="modal-actions">
          <Button
            type="submit"
            disabled={!this.state.jobOfferId}
            onClick={() => this.initSlots()}
            className="accent medium">
            <Trans i18nKey="portal.recruiter.candidates.profile.invite.buttons.send">
              Send Invitation
            </Trans>
          </Button>
          <Button
            type="button"
            onClick={() => this.closeModal()}
            className="grey medium">
            <Trans i18nKey="portal.recruiter.candidates.profile.invite.buttons.cancel">
              Cancel
            </Trans>
          </Button>
        </div>
      </Modal>
    );
  }

}
