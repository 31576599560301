// @flow
import axios, { AxiosPromise } from 'axios';

import { List } from 'rest/list/list.dto';
import { CandidateDto } from 'rest/candidates/candidate.dto';
import { RecruiterFavoriteParams } from 'rest/recruiter/favorites/favorite.params';

export async function getFavoritesCandidate (params: RecruiterFavoriteParams): AxiosPromise<List<CandidateDto>> {
  try {
    return await axios.get(`/recruiters/${params.recruiterId}/favorites`, { params });
  } catch (error) {
    throw error;
  }
}

export async function addCandidateToFavorite (params: RecruiterFavoriteParams): AxiosPromise {
  try {
    return await axios.post(`/recruiters/${params.recruiterId}/favorites?candidateId=${params.candidateId}`);
  } catch (error) {
    throw error;
  }
}

export async function deleteCandidateFromFavorite (params: RecruiterFavoriteParams): AxiosPromise {
  try {
    return await axios.delete(`/recruiters/${params.recruiterId}/favorites?candidateId=${params.candidateId}`);
  } catch (error) {
    throw error;
  }
}
