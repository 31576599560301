import { Action, Dispatch } from 'redux';

import * as actions from './sign-in.constants';

import { setToken } from 'middleware/auth';
import { setUserType } from 'middleware/user-type';
import { initRestParams } from 'middleware/rest';
import { getLocalization } from 'middleware/localization';
import { getUnauthorizedUserRedirectPage } from 'middleware/unauthorized-user-redirect';
import { resetUnauthorizedUserRedirectPage } from 'middleware/unauthorized-user-redirect';

import { RecruiterDto } from 'rest/recruiter/recruiter.dto';
import { signInRecruiter } from 'rest/recruiter/recruiter.rest';
import { changeCurrentRecruiterLocale } from 'rest/recruiter/current/current.rest';

import { setNameAndLastNameAndEmail } from '../../../../middleware/auth';

const recruiterSinInRequest = () => ({
  type: actions.RECRUITER_SIGN_IN_REQUEST
});

const recruiterSignInSuccess = payload => ({
  type: actions.RECRUITER_SIGN_IN_SUCCESS,
  payload: payload
});

const recruiterSignInFailure = error => ({
  type: actions.RECRUITER_SIGN_IN_FAILURE,
  error
});

const recruiterSignInNeedConfirm = (email: string): Action => ({
  type: actions.RECRUITER_SIGN_IN_NEED_CONFIRM,
  email
});

function signInErrorHandler (dispatch: Dispatch, errorStatus: number, email: string, error: string) {
  if (errorStatus === 403) {
    dispatch(recruiterSignInNeedConfirm(email));
    localStorage.setItem('emailToConfirm', email);
  } else {
    dispatch(recruiterSignInFailure(error));
  }
}

export const recruiterSignIn = (data: RecruiterDto) => {
  return dispatch => {
    dispatch(recruiterSinInRequest());
    return signInRecruiter(data)
      .then(response => {
        setToken(response.data.access_token, response.data.refresh_token);
        setNameAndLastNameAndEmail(response.data.firstName,
          response.data.lastName,
          response.data.email);
        setUserType('recruiter');
        initRestParams();
        changeCurrentRecruiterLocale(getLocalization());
        const payload = { unauthorizedUserUrl: getUnauthorizedUserRedirectPage() };
        dispatch(recruiterSignInSuccess(payload));
        resetUnauthorizedUserRedirectPage();
      })
      .catch(error => signInErrorHandler(dispatch, error.status, data.email, error.data.error_description));
  };
};
