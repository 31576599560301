import * as actions from './sign-in.constants';

import type { SignInState } from './sign-in.type';

import type { CustomAction } from './../../../action.type';

const initialState: SignInState = {
  error: false,
  email: '',
  fetching: false,
  errorText: '',
  needConfirm: false,
  signInSuccess: false,
  unauthorizedUserUrl: ''
};

export default (state = initialState, action: CustomAction): SignInState => {
  switch (action.type) {
    case actions.RECRUITER_SIGN_IN_REQUEST:
      return {
        ...state,
        error: false,
        errorText: '',
        fetching: true
      };

    case actions.RECRUITER_SIGN_IN_SUCCESS:
      return {
        ...state,
        error: false,
        errorText: '',
        fetching: false,
        signInSuccess: true,
        unauthorizedUserUrl: action.payload.unauthorizedUserUrl
      };

    case actions.RECRUITER_SIGN_IN_NEED_CONFIRM:
      return {
        ...state,
        email: action.email,
        needConfirm: true,
        signInSuccess: false
      };

    case actions.RECRUITER_SIGN_IN_FAILURE:
      return {
        ...state,
        error: true,
        fetching: false,
        signInSuccess: false,
        errorText: action.error
      };

    default:
      return state;
  }
};
