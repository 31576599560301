// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { StatsDto } from 'rest/stats/stats.dto';

import { CurrentRecruiterDto } from 'rest/recruiter/current/current.dto';
import {
  getCalendarStatistic,
  resetCalendarInvitesStats
} from 'services/portal/recruiter/calendar/stats/stats.actions';

import { changeInvitesStatus } from 'services/portal/recruiter/calendar/invites/invites.actions';

type Props = {
  t: TransProps,
  total: number,
  stats: StatsDto[],
  currentUser: CurrentRecruiterDto,
  invitesStatus: string,
  changeInvitesStatus: (status?: string) => void,
  getCalendarStatistic: (companyId: string) => void,
  resetCalendarInvitesStats: () => void
}

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  total: state.portal.recruiter.calendar.invitesStats.totalElements,
  stats: state.portal.recruiter.calendar.invitesStats.stats,
  currentUser: state.portal.recruiter.account.current.currentUser,
  invitesStatus: state.portal.recruiter.calendar.invites.status
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  changeInvitesStatus,
  getCalendarStatistic,
  resetCalendarInvitesStats
}, dispatch);
