// @flow
import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import type { Match } from 'common/types/match';

import { Modal } from 'components/dialogs';
import { ProfileContainer } from 'containers/portal/recruiter/candidates';

type Props = {
  match: Match
}

export class ProfilePage extends Component<Props> {

  render () {
    return (
      <Modal closePath={`/portal/recruiter/follow/${this.props.match.params['type']}`}>
        <Route
          path="/portal/recruiter/follow/:type/:candidateId/profile"
          exact
          component={ProfileContainer}/>
      </Modal>
    );
  }

}
