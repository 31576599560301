import axios, { AxiosPromise } from 'axios';

import { RecruiterDto } from './recruiter.dto';
import { RecruiterSignUpDto } from './recruiter.signup.dto';

export async function updateRecruiter (recruiterId: string, data: RecruiterDto): Promise<RecruiterDto> {
  try {
    return await axios.put(`/recruiters/${recruiterId}`, data);
  } catch (error) {
    throw error;
  }
}

export async function activateRecruiter (recruiterId: string): Promise {
  try {
    return await axios.post(`/recruiters/${recruiterId}/activate`);
  } catch (error) {
    throw error;
  }
}

export async function deleteRecruiter (recruiterId: string): AxiosPromise {
  try {
    return await axios.delete(`/recruiters/${recruiterId}`);
  } catch (error) {
    throw error.response;
  }
}

export async function createRecruiter (data: RecruiterDto): Promise<RecruiterSignUpDto> {
  try {
    return await axios.post('/recruiters', data);
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
}

export async function signInRecruiter (data: RecruiterDto): Promise<RecruiterSignUpDto> {
  try {
    const formData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('username', data.email);
    formData.append('user_role', 'recruiter');
    formData.append('password', data.password);
    return await axios({
      method: 'POST',
      url: '/oauth/token',
      data: formData,
      headers: {
        'Authorization': 'Basic aW50ZXJuYWw6OXdzYk4ydW4=',
        'Content-Type': 'multipart/form-data'
      }
    });
  } catch (error) {
    throw error.response;
  }
}
