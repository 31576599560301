// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import type { RouterHistory } from 'react-router-dom';
import type { Location } from 'common/types/location';

import { CompanyDto } from 'rest/companies/companies.dto';
import { RecruiterResponseDto } from 'rest/recruiter/recruiter.response.dto';
import { SubscriptionDto } from 'rest/companies/subscription/subscription.dto';
import { PaymentRedirectUrlsDto } from 'rest/companies/payment-redirect-urls.dto';

import {
  updateRecruiterCompany,
  getRecruiterCompanyById,
  subscribeRecruiterCompany,
  updateCurrentRecruiter,
  getCurrentCompanyRecruiter
} from 'services/portal/recruiter/account/account.actions';
import { createJobOfferFlow } from 'services/portal/recruiter/job-offer/job-offer.actions';
import { getAllCompanyJobOffers } from 'services/portal/recruiter/account/job-offer/job-offer.actions';

import './payment.scss';

export type Props = {
  t?: TransProps,
  prev: () => void,
  redirect: boolean,
  redirectLink: string,
  showJobOfferPreview: boolean,
  location: Location,
  history: RouterHistory,
  currentUser?: RecruiterResponseDto,
  getCurrentCompanyRecruiter: () => void,
  getRecruiterCompanyById: (companyId: string) => void,
  createJobOfferFlow: (publish: boolean, companyId: string) => void,
  updateRecruiterCompany: (companyIdId: string, company: CompanyDto) => void,
  subscribeRecruiterCompany?: (companyId: string, data: SubscriptionDto) => void
};

type State = {
  mode: string,
  company?: CompanyDto,
  redirectLink: string,
  paymentMethod: string,
  isSofortRedirect: boolean,
  isSepaModalOpen: boolean,
  isCompanyInfoLoaded: boolean,
  isCompanyInfoEditable: boolean,
  subscriptionPlan: string,
  confirmPayment: boolean,
  promoCode: string,
  promoCodeIsFailed: boolean,
  promoCodeIsValid: boolean,
  promoCodeIsChange: boolean,
  amount: string,
  months: string,
  isPreviewModalOpen: boolean,
  paymentRedirectUrls: PaymentRedirectUrlsDto
};

export class Settings extends Component<Props, State> {

  static defaultProps = {
    prev: () => {},
    redirectLink: '',
    showJobOfferPreview: false,
    currentUser: new RecruiterResponseDto(),
    subscribeRecruiterCompany: () => {},
    updateCurrentRecruiter: () => {}
  };

  state: State = {
    company: {},
    confirmPayment: false,
    isPreviewModalOpen: false,
    isSepaModalOpen: false,
    isSofortRedirect: false,
    isCompanyInfoLoaded: false,
    isCompanyInfoEditable: false,
    mode: '',
    paymentMethod: 'SOFORT',
    paymentRedirectUrls: new PaymentRedirectUrlsDto('/portal/recruiter/account/payment/success', '/', '/'),
    promoCode: null,
    promoCodeIsFailed: false,
    promoCodeIsValid: false,
    promoCodeIsChange: false,
    amount: 250,
    months: 12,
    redirectLink: '',
    submitFromOutside: false,
    subscriptionPlan: 'FREE',
    vatId: null,
    vatIdError: false
  };

}

export const stateToProps = (state: GlobalState) => ({
  currentUser: state.portal.recruiter.account.current.currentUser,
  redirectLink: state.portal.recruiter.account.payment.redirectUrl,
  jobOfferFetching: state.portal.recruiter.jobOffer.process.fetching
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  createJobOfferFlow,
  updateRecruiterCompany,
  updateCurrentRecruiter,
  getAllCompanyJobOffers,
  getRecruiterCompanyById,
  subscribeRecruiterCompany,
  getCurrentRecruiter: getCurrentCompanyRecruiter
}, dispatch);
