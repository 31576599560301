export const SEARCH_CANDIDATES_SKILLS = 'SEARCH_CANDIDATES_SKILLS';
export const SEARCH_CANDIDATES_BRANCH = 'SEARCH_CANDIDATES_BRANCH';
export const SEARCH_CANDIDATES_UNIT = 'SEARCH_CANDIDATES_UNIT';
export const SEARCH_CANDIDATES_JOB_TITLE = 'SEARCH_CANDIDATES_JOB_TITLE';
export const SEARCH_CANDIDATES_LOCATION = 'SEARCH_CANDIDATES_LOCATION';
export const SEARCH_CANDIDATES_SALARY_MIN = 'SEARCH_CANDIDATES_SALARY_MIN';
export const SEARCH_CANDIDATES_SALARY_MAX = 'SEARCH_CANDIDATES_SALARY_MAX';
export const SEARCH_CANDIDATES_WORKING_TIME = 'SEARCH_CANDIDATES_WORKING_TIME';
export const SEARCH_CANDIDATES_AVAILABLE_FROM = 'SEARCH_CANDIDATES_AVAILABLE_FROM';
export const SEARCH_CANDIDATES_WORKING_TYPE = 'SEARCH_CANDIDATES_WORKING_TYPE';
export const SEARCH_CANDIDATES_RELOCATE = 'SEARCH_CANDIDATES_RELOCATE';
export const SEARCH_CANDIDATES_CLOSE = 'SEARCH_CANDIDATES_CLOSE';
export const SEARCH_CANDIDATES_RESET = 'SEARCH_CANDIDATES_RESET';
export const SEARCH_CANDIDATES_SKILLS_RESET = 'SEARCH_CANDIDATES_SKILLS_RESET';
