import axios, { AxiosPromise } from 'axios';

import { List } from 'rest/list/list.dto';

import { CandidatesParams } from './candidates.params';
import { CandidatesResponseDto } from './candidates.response.dto';
import { CandidatesStatsResponseDto } from './candidates-stats.dto';

export async function getCandidatesList (params = new CandidatesParams()): AxiosPromise<List<CandidatesResponseDto>> {
  try {
    return await axios.get('/public/candidates', { params });
  } catch (error) {
    throw error;
  }
}

export async function getCandidatesStats (): AxiosPromise<CandidatesStatsResponseDto> {
  try {
    return await axios.get('/public/candidates/stats');
  } catch (error) {
    throw error;
  }
}
