import { AxiosResponse } from 'axios';

import { PaginationDto } from 'middleware/dto/pagination';

import { List } from 'rest/list/list.dto';

import { ChatRequestDto } from 'rest/chats/chat.request.dto';
import { ChatResponseDto } from 'rest/chats/chat.response.dto';
import { MessagesUnreadDto } from 'rest/chats/messages/messages-unread.dto';

import {
  createChatConversations,
  getChatConversation,
  getChatConversations,
  getUnreadChatConversation
} from 'rest/chats/chat.rest';

import * as actions from './conversations.constants';

const getConversationsRequest = () => ({
  type: actions.GET_CONVERSATIONS_REQUEST
});

const getConversationsSuccess = data => ({
  type: actions.GET_CONVERSATIONS_SUCCESS,
  payload: data
});

const getConversationsFailure = (error: string) => ({
  type: actions.GET_CONVERSATIONS_FAILURE,
  error
});

const createConversationRequest = () => ({
  type: actions.CREATE_CONVERSATIONS_REQUEST
});

const createConversationSuccess = data => ({
  type: actions.CREATE_CONVERSATIONS_SUCCESS,
  payload: data
});

const resetConversationsSuccess = () => ({
  type: actions.RESET_CONVERSATIONS_SUCCESS
});

const getConversationRequest = () => ({
  type: actions.GET_CONVERSATION_REQUEST
});

const getConversationSuccess = data => ({
  type: actions.GET_CONVERSATION_SUCCESS,
  payload: data
});

const getConversationFailure = (error: string) => ({
  type: actions.GET_CONVERSATION_FAILURE,
  error
});

const saveUnreadConversations = data => ({
  type: actions.GET_UNREAD_CONVERSATION_SUCCESS,
  payload: data
});

const resetUnreadConversationSuccess = (chatId: string) => ({
  type: actions.RESET_UNREAD_CONVERSATION_SUCCESS,
  chatId
});

const blockConversationSuccess = (chatId: string) => ({
  type: actions.BLOCK_CONVERSATION_SUCCESS,
  chatId
});

export const resetUnreadConversation = (chatId: string) => {
  return dispatch => dispatch(resetUnreadConversationSuccess(chatId));
};

export const blockConversation = (chatId: string) => {
  return dispatch => dispatch(blockConversationSuccess(chatId));
};

export const getConversation = (id: string) => {
  return dispatch => {
    dispatch(getConversationRequest());
    return getChatConversation(id)
      .then((response: AxiosResponse<ChatResponseDto>) => dispatch(getConversationSuccess(response.data)))
      .catch(error => dispatch(getConversationFailure(error)));
  };
};

export const getAllConversations = (page: number, search?: string) => {
  return dispatch => {
    dispatch(getConversationsRequest());
    const params = new PaginationDto();
    params.page = page;
    params.name = search;
    return getChatConversations(params)
      .then((response: AxiosResponse<List<ChatResponseDto>>) => dispatch(getConversationsSuccess(response.data)))
      .catch(error => dispatch(getConversationsFailure(error)));
  };
};

export const createChatConversation = (chat: ChatRequestDto) => {
  return dispatch => {
    dispatch(createConversationRequest());
    return createChatConversations(chat)
      .then((response: AxiosResponse<List<ChatResponseDto>>) => dispatch(createConversationSuccess(response.data)));
  };
};

export const resetAllConversation = () => {
  return dispatch => {
    dispatch(resetConversationsSuccess());
  };
};

export const getUnreadConversation = () => {
  return dispatch => {
    return getUnreadChatConversation()
      .then((response: AxiosResponse<MessagesUnreadDto>) => {
        dispatch(saveUnreadConversations(response.data));
      });
  };
};
