// @flow
import React, { Fragment } from 'react';

import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroller';

import { connectStore } from 'middleware/connect';

import { IconButton } from 'components/buttons';
import { ListPlaceholder, Spinner } from 'components/elements';
import { ElementsList, CandidateRow } from 'components/elements-list';

import { CandidateDto } from 'rest/candidates/candidate.dto';
import { RecruiterFavoriteParams } from 'rest/recruiter/favorites/favorite.params';

import { stateToProps, dispatchToProps, Setting } from './favorite.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class FavoritesCandidate extends Setting {

  componentWillUnmount () {
    this.props.resetAllCandidateFavorites();
  }

  unFavoriteCandidate (candidateId: string) {
    const params = new RecruiterFavoriteParams();
    params.recruiterId = this.props.currentRecruiterId;
    params.candidateId = candidateId;
    this.props.deletingCandidateFromFavorite(params);
    toast.success(this.props.t('portal.recruiter.notifications.favorites-delete'));
  }

  loadCandidateList (page: number = 0) {
    const params = new RecruiterFavoriteParams();
    params.page = page;
    params.unitId = this.props.unitId;
    params.jobTitleId = this.props.jobTitleId;
    params.recruiterId = this.props.currentRecruiterId;
    this.props.requestFavoriteCandidates(params);
  }

  openCandidateProfile (selectedCandidate: string) {
    this.props.history.push(`${this.props.match.path}/${selectedCandidate}/profile`);
  }

  render () {
    return (
      <Fragment>
        {this.props.currentRecruiterId && (
          <InfiniteScroll
            useWindow={false}
            threshold={100}
            pageStart={-1}
            hasMore={this.props.hasMoreItems}
            loadMore={() => this.loadCandidateList(this.props.currentPage)}>
            {this.props.candidates.length ? (
              <ElementsList>
                {this.props.candidates.map((candidate: CandidateDto) => (
                  <CandidateRow
                    onClick={() => this.openCandidateProfile(candidate.id)}
                    key={candidate.id}
                    candidate={candidate}>
                    <IconButton
                      recruiter
                      title={<span>Delete from favorite</span>}
                      onClick={() => this.unFavoriteCandidate(candidate.id)}
                      icon="icon-cancel-m"/>
                  </CandidateRow>
                ))}
              </ElementsList>) : <ListPlaceholder
              title={this.props.t('portal.recruiter.favorites.list.placeholder.title')}>
              <Trans i18nKey="portal.recruiter.favorites.list.placeholder.content">
                When you mark a candidate with  ❤️ they land right here.
              </Trans>
            </ListPlaceholder>}
          </InfiniteScroll>)}
        <Spinner show={this.props.fetching}/>
      </Fragment>
    );
  }

}
