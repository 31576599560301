// @flow
import React from 'react';
import { Trans } from 'react-i18next';

import InfiniteScroll from 'react-infinite-scroller';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';
import { uniqueArray } from 'middleware/unique-array';

import { Spinner } from 'components/elements';

import { PaginationDto } from 'middleware/dto/pagination';

import { PostsResponseDto } from 'rest/candidates/posts/posts.response.dto';

import { Setting, stateToProps, dispatchToProps } from './personal-section.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class PersonalSection extends Setting {

  componentWillUnmount () {
    this.props.resetPostList();
  }

  fetchPosts (page: number = 0) {
    const query = new PaginationDto();
    query.page = page;
    query.size = 10;
    query.candidateId = this.props.candidateId;
    this.props.getPosts(query);
  }

  render () {
    return (
      <div className="personal-section-container">
        <h5 className="personal-section-title">
          <Trans i18nKey="portal.recruiter.candidates.profile.personal.posts">
            Blog Posts
          </Trans>
        </h5>
        {
          this.props.candidateId ? <PerfectScrollbar className="overflow-modal-content">
            <InfiniteScroll
              pageStart={-1}
              hasMore={this.props.hasMoreItems}
              loadMore={() => this.fetchPosts(this.props.currentPage)}
              useWindow={false}
              threshold={150}>
              <ul className="personal-post-list">
                {
                  uniqueArray(this.props.posts, 'id').map((post: PostsResponseDto, index: number) => {
                    const postPhotos = post.postPhotos;
                    const primaryPhoto = postPhotos && postPhotos.find(item => item.primary);
                    const isOnlyPrimaryPresent = postPhotos && postPhotos.length === 1;
                    return (
                      <div
                        key={index}
                        className="personal-post-item">
                        <div className="text-content">
                          <div className="gallery">
                            <div className="main-picture">
                              <img
                                className={primaryPhoto ? 'primary' : ''}
                                src={this.state.activePhotoUrl || (primaryPhoto && primaryPhoto.url)}
                                alt="main-photo"/>
                            </div>
                            {
                              !isOnlyPrimaryPresent && <div className="aside-photos">
                                {
                                  postPhotos && postPhotos.map((photo, index) => (
                                    <img
                                      key={index}
                                      onClick={() =>
                                        this.setState({ activePhotoUrl: photo.url, activePhotoIndex: index })}
                                      className={this.state.activePhotoIndex === index ? 'primary' : ''}
                                      src={photo.url}/>
                                  ))
                                }
                              </div>
                            }
                          </div>
                          <h5 className="post-title">{post.title}</h5>
                          <p className="post-body">{post.article}</p>
                        </div>
                      </div>
                    );
                  })
                }
              </ul>
            </InfiniteScroll>
          </PerfectScrollbar> : null
        }
        <Spinner show={this.props.fetching}/>
      </div>
    );
  }

}
