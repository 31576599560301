export const GET_FAVORITE_JOBS_REQUEST = 'GET_FAVORITE_JOBS_REQUEST';
export const GET_FAVORITE_JOBS_FAILURE = 'GET_FAVORITE_JOBS_FAILURE';
export const GET_FAVORITE_JOBS_SUCCESS = 'GET_FAVORITE_JOBS_SUCCESS';

export const ADD_JOBS_TO_FAVORITE_REQUEST = 'ADD_JOBS_TO_FAVORITE_REQUEST';
export const ADD_JOBS_TO_FAVORITE_FAILURE = 'ADD_JOBS_TO_FAVORITE_FAILURE';
export const ADD_JOBS_TO_FAVORITE_SUCCESS = 'ADD_JOBS_TO_FAVORITE_SUCCESS';

export const DELETE_JOBS_FROM_FAVORITE_REQUEST = 'DELETE_JOBS_FROM_FAVORITE_REQUEST';
export const DELETE_JOBS_FROM_FAVORITE_FAILURE = 'DELETE_JOBS_FROM_FAVORITE_FAILURE';
export const DELETE_JOBS_FROM_FAVORITE_SUCCESS = 'DELETE_JOBS_FROM_FAVORITE_SUCCESS';

export const GET_FAVORITE_JOB_OFFERS_LOCATIONS_REQUEST = 'GET_FAVORITE_JOB_OFFERS_LOCATIONS_REQUEST';
export const GET_FAVORITE_JOB_OFFERS_LOCATIONS_FAILURE = 'GET_FAVORITE_JOB_OFFERS_LOCATIONS_FAILURE';
export const GET_FAVORITE_JOB_OFFERS_LOCATIONS_SUCCESS = 'GET_FAVORITE_JOB_OFFERS_LOCATIONS_SUCCESS';

export const RESET_FAVORITES_SUCCESS = 'RESET_FAVORITES_SUCCESS';

export const CHANGE_FAVORITES_FILTERS_SUCCESS = 'CHANGE_FAVORITES_FILTERS_SUCCESS';
