// @flow
import { Component } from 'react';

import { Tab } from './../tab/tab';

import './tabs.scss';
import './tabs.responsive.scss';

export type Props = {
  children?: Tab[],
  candidate?: boolean,
  currentRoute?: string,
  headClassName?: string,
  activeTabIndex?: number,
  activeTabChange?: (index: number) => void
};

export type State = {
  activeTabIndex: number
}

export class Setting extends Component<Props, State> {

  static defaultProps: Props = {
    children: [],
    candidate: false,
    activeTabChange: () => {}
  };

  state = {
    activeTabIndex: 0
  }

}
