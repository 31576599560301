import React from 'react';

import { Settings } from './placeholder.setting';

export class ListPlaceholder extends Settings {

  render () {
    return (
      <div className={`list-placeholder-container ${this.props.className}`}>
        <div className="image">
          <img
            src="/images/shared/mask-2.svg"
            alt="Your list is empty"/>
        </div>
        <div className="title">
          {this.props.title}
        </div>
        <div className="content">
          {this.props.children}
        </div>
      </div>
    );
  }

}
