import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { Match } from 'common/types/match';
import type { GlobalState } from 'common/types/global-state';

import { SkillsContainerDto } from 'rest/skills/skills-container.dto';
import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';
import { JobRequirementsResponseDto } from 'rest/candidates/job-requirements/job-requirements.response.dto';

import { getCandidateSkillsById } from 'services/portal/candidate/skills/skills.actions';
import { getCandidateBalanceBoard } from 'services/portal/candidate/balance-board/balance-board.actions';

import './preview.scss';
import './preview.responsive.scss';

export type Props = {
  t: TransProps,
  match: Match,
  history: History,
  skills: SkillsContainerDto,
  currentCandidate: CurrentCandidateDto,
  balanceBoard: JobRequirementsResponseDto,
  getCandidateSkillsById: (candidateId: string) => void,
  getCandidateBalanceBoard: (candidateId: string) => void
}

export class Settings extends Component<Props> {

  static defaultProps: Props = {
    getCandidateSkillsById: () => {},
    getCandidateBalanceBoard: () => {}
  };

}

export const stateToProps = (state: GlobalState) => ({
  skills: state.portal.candidate.skills.content,
  fetching: state.portal.candidate.balanceBoard.fetching,
  balanceBoard: state.portal.candidate.balanceBoard.balanceBoard,
  currentCandidate: state.portal.candidate.account.current.candidateData
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getCandidateSkillsById,
  getCandidateBalanceBoard
}, dispatch);
