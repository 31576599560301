import React from 'react';
import { connect } from 'react-redux';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { Card } from 'components/layouts';

import { CandidateProfile } from 'containers/portal/recruiter/candidates';

import { Settings, dispatchToProps, stateToProps } from './profile-container.setting';

@connect(stateToProps, dispatchToProps)
export class ProfileContainer extends Settings {

  componentDidMount () {
    this.props.getCandidateById(this.props.match.params.candidateId);
  }

  render () {
    return (
      <div className="profile-candidate-modal">
        <Card className="profile-container profile-details-modal">
          <div className="profile-content">
            <PerfectScrollbar className="profile-preview">
              <CandidateProfile
                restricted={!this.props.candidate.email}
                candidate={this.props.candidate}/>
            </PerfectScrollbar>
          </div>
        </Card>
      </div>
    );
  }

}
