// @flow
import React from 'react';

import ReactPhoneInput from 'react-phone-input-2';

import { countriesDELocalization } from './countries-de.localization';

import type { Props } from './phone-control.setting';
import { Setting } from './phone-control.setting';

export class PhoneControl extends Setting {

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.defaultValue && nextProps.defaultValue !== this.state.phone) {
      this.setState({ phone: this.normilizePhoneNumber(nextProps.defaultValue) });
    }
  }

  normilizePhoneNumber (phone: string): string {
    return !phone.startsWith('+') ? `+${phone}` : phone;
  }

  render () {
    const inputProps = {
      autoFocus: false,
      required: this.props.required,
      pattern: '^\\+(?:[0-9] ?){6,14}[0-9]$',
      title: 'Phone type correct phone number'
    };
    return (
      <div className="form-label phone-input-container">
        <span className="form-label-title">{this.props.label}</span>
        <ReactPhoneInput
          countryCodeEditable={false}
          enableLongNumbers={true}
          value={this.state.phone}
          autoFormat={false}
          inputExtraProps={inputProps}
          dropdownClass="options-list"
          buttonClass="code-action-button"
          inputClass="phone-input-control"
          searchClass="search-code-container"
          placeholder={this.props.placeholder}
          enableSearchField={this.props.searchable}
          localization={this.state.localization === 'de' ? countriesDELocalization : {}}
          onChange={(phone: string) => this.setState({ phone }, () => this.props.change(phone))}/>
        {
          this.props.error && <span className="form-label-error">{this.props.errorText}</span>
        }
      </div>
    );
  }

}
