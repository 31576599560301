// @flow
import * as actions from './contact.constants';

import type { ContactType } from './contact.type';

const initialState: ContactType = {
  content: null,
  fetching: false
};

export default function (state: ContactType = initialState, action) {
  switch (action.type) {
    case actions.SEND_CONTACT_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case actions.SEND_CONTACT_SUCCESS:
      return {
        ...state,
        content: action.payload.content,
        fetching: false
      };

    case actions.SEND_CONTACT_FAILURE:
      return {
        ...state,
        fetching: false
      };

    default:
      return state;
  }
}
