import React, { Component } from 'react';
import { Route } from 'middleware/route';

import { Page } from 'components/layouts';

import { CreateJobOfferGuest } from 'containers/portal/guest/job-offer/job-offer';

export class CreateJobOffersPage extends Component {

  render () {
    return (
      <div className="create-job-offer-page">
        <Page autoHeight>
          <Route
            exact
            path="/portal/new-job-offer"
            type="guest"
            component={CreateJobOfferGuest}/>
        </Page>
      </div>
    );
  }

}
