// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import {
  getRecruiterCompanyById,
  getCurrentCompanyRecruiter
} from 'services/portal/recruiter/account/account.actions';

import { CurrentRecruiterDto } from 'rest/recruiter/current/current.dto';

export type Props = {
  location: Location,
  currentUser?: CurrentRecruiterDto,
  getRecruiterCompanyById?: () => void,
  getCurrentCompanyRecruiter?: () => void
};

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  currentUser: state.portal.recruiter.account.current.currentUser
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getRecruiterCompanyById,
  getCurrentCompanyRecruiter
}, dispatch);
