// @flow
import React from 'react';

import moment from 'moment';
import { toast } from 'react-toastify';
import Slider from 'react-slick/lib';
import { Trans } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { ImageResponseDto } from 'rest/images/images.response';
import { Modal } from 'components/dialogs';
import { Button, LinkButton } from 'components/buttons';

import { Card, Chips } from 'components/layouts';

import { DeletePostModal } from './delete-modal/delete-modal';
import { carouselSettings, dispatchToProps, Settings, stateToProps } from './preview.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class PreviewPostModal extends Settings {

  static getDerivedStateFromProps (nextProps, prevState) {
    const photos = nextProps.previewPost.postPhotos || [];

    if (photos.length) {
      const index = photos.findIndex(photo => photo.primary);

      return {
        activePhotoIndex: prevState.activePhotoIndex === null ? index : prevState.activePhotoIndex
      };
    }

    return null;
  }

  componentDidMount () {
    this.props.getPostById(this.props.match.params.postId);
  }

  deletePost () {
    this.props.deletePost(this.props.match.params.postId);
    this.props.history.push('/portal/candidate/posts');
    toast.success(this.props.t('portal.candidate.notifications.blog-deleted'));
  }

  render () {
    const publishDate: string = this.props.previewPost.createDate;
    const postPhotos: ImageResponseDto[] = this.props.previewPost.postPhotos;
    const primaryPhoto: ImageResponseDto = postPhotos && postPhotos.find(item => item.primary);
    const isOnlyPrimaryPresent: boolean = postPhotos && postPhotos.length === 1;
    return (
      <Modal closePath="/portal/candidate/posts">
        <div className="create-post-modal edit-post-modal-mobile">
          <PerfectScrollbar className="content">
            <Card className="modal-header">
              <div className="left-side">
                <LinkButton
                  className="posts-back-link"
                  inline
                  link="/portal/candidate/posts">
                  <Trans i18nKey="portal.candidate.blog.preview.back">
                    Back to Blog
                  </Trans>
                  <div className="modal-post-back">&times;</div>
                </LinkButton>
                <h2>{this.props.previewPost.title}</h2>
                <Chips>
                  <Trans i18nKey="portal.candidate.blog.preview.published">
                    Published
                  </Trans>
                  &nbsp;
                  {publishDate ? moment(publishDate).format('ll') : moment().format('ll')}
                </Chips>
              </div>
              <div className="right-action">
                <Button
                  medium
                  onClick={() =>
                    this.props.history.push(`/portal/candidate/posts/edit/${this.props.match.params.postId}`)}
                  candidate
                  outlined>
                  <Trans i18nKey="portal.candidate.blog.preview.buttons.edit">
                    Edit
                  </Trans>
                </Button>
                <Button
                  medium
                  candidate
                  outlined
                  onClick={() => this.setState({ isDeleteModalOpen: true })}>
                  <Trans i18nKey="portal.candidate.blog.preview.buttons.delete">
                    Delete
                  </Trans>
                </Button>
              </div>
            </Card>
            <Card>
              <div className="text-content">
                <div className="gallery">
                  <div className="mobile-gallery-carousel">
                    <Slider {...carouselSettings}>
                      {
                        !isOnlyPrimaryPresent && postPhotos ? postPhotos.map((photo, index) => (
                          <div
                            key={photo.imageId}
                            className="mobile-slider-photo">
                            <img
                              alt="Post image"
                              onClick={() => this.setState({
                                activePhotoUrl: photo.url,
                                activePhotoIndex: index
                              })}
                              className={this.state.activePhotoIndex === index ? 'primary' : ''}
                              src={photo.url}/>
                          </div>
                        )) : false
                      }
                    </Slider>
                  </div>
                  <div className={`main-picture ${!isOnlyPrimaryPresent ? 'mobile-main-picture' : ''}`}>
                    <img
                      className={primaryPhoto ? 'primary' : ''}
                      src={this.state.activePhotoUrl || (primaryPhoto && primaryPhoto.url)}
                      alt="main-photo"/>
                  </div>
                  {
                    !isOnlyPrimaryPresent && <div className="aside-photos">
                      {
                        postPhotos && postPhotos.map((photo, index) => (
                          <img
                            key={photo.imageId}
                            onClick={() => this.setState({ activePhotoUrl: photo.url, activePhotoIndex: index })}
                            className={this.state.activePhotoIndex === index ? 'primary' : ''}
                            src={photo.url}
                            alt="Post image"/>
                        ))
                      }
                    </div>
                  }
                </div>
                <p>{this.props.previewPost.article}</p>
              </div>
            </Card>
          </PerfectScrollbar>
          <DeletePostModal
            deleteAction={() => this.deletePost()}
            hideModal={() => this.setState({ isDeleteModalOpen: false })}
            open={this.state.isDeleteModalOpen}/>
        </div>
      </Modal>
    );
  }

}
