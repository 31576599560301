// @flow
import React from 'react';

import { Trans } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Button } from 'components/buttons';
import { Spinner } from 'components/elements';
import { FormGroup, InputControl, CheckboxControl } from 'components/controls';

import { connectStore } from 'middleware/connect';
import { SearchDto, ListDto } from 'middleware/dto';

import { Modal, ModalCaption } from 'containers/portal/modal-container';

import { Settings, dispatchToProps, stateToProps } from './software.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class AddSoftwareModal extends Settings {

  changeSearchState (value: string) {
    this.setState({ searchItem: value }, () => this.fetchSoftware());
  }

  changeSelectedState (checked: boolean, value: ListDto) {
    const selected: ListDto[] = [...this.state.selectedItems];
    if (checked) {
      selected.push(value);
    } else {
      const index: number = selected.findIndex(item => item.id === value.id);
      selected.splice(index, 1);
    }
    this.setState({ selectedItems: selected });
  }

  fetchSoftware (page: number = 0) {
    const search: SearchDto = new SearchDto();
    search.page = page;
    search.size = 10;
    search.q = this.state.searchItem;
    this.props.search(search);
  }

  clearState () {
    this.setState({
      searchItem: '',
      selectedItems: []
    });
  }

  close () {
    this.clearState();
    this.props.close();
    this.props.resetSearchResult();
  }

  apply () {
    this.props.addNewItems(this.state.selectedItems);
    this.close();
  }

  render () {
    return (
      <Modal
        overlay
        animation="fade"
        open={this.props.open}
        hideModalAvailable={false}
        hideModal={() => this.close()}
        closeOnOverlay={() => this.close()}
        className={`add-software-modal ${this.props.className}`}>
        <ModalCaption
          hideModal={() => this.close()}
          title={this.props.t('portal.recruiter.balance-board.job-offer.add-modals.software.title')}/>
        <form className="add-skills-modal-form">
          <FormGroup className="full-width">
            <InputControl
              required
              value={this.state.searchItem}
              change={(value: string): void => this.changeSearchState(value)}
              placeholder={this.props.t('portal.recruiter.balance-board.job-offer.add-modals.software.search')}
              type="text"/>
          </FormGroup>
        </form>
        {
          this.props.open ? <PerfectScrollbar className="overflow-modal-content">
            <InfiniteScroll
              hasMore={this.props.hasMoreItems}
              loadMore={page => this.fetchSoftware(page)}
              useWindow={false}
              threshold={150}>
              <ul className="list-content">
                {
                  this.props.software.map((item, index) => (
                    <li
                      key={index}
                      className="list-content-item">
                      <CheckboxControl
                        value={item.selected}
                        className={this.props.recruiter ? 'accent' : ''}
                        change={(checked: boolean) => this.changeSelectedState(checked, item)}
                        label={item.name}/>
                    </li>
                  ))
                }
                {
                  !this.props.software.length &&
                  <p className="empty">
                    {!this.props.fetching && this.props.t('portal.recruiter.balance-board.job-offer.add-modals.empty')}
                  </p>
                }
                <Spinner show={this.props.fetching}/>
              </ul>
            </InfiniteScroll>
          </PerfectScrollbar> : null
        }
        <div className="add-skills-action">
          <Button
            medium
            disabled={!this.state.selectedItems.length > 0}
            onClick={this.apply.bind(this)}
            candidate={this.props.candidate}
            recruiter={this.props.recruiter}
            type="submit">
            <Trans i18nKey="portal.recruiter.balance-board.job-offer.add-modals.buttons.apply">
              Apply
            </Trans>
          </Button>
          <Button
            medium
            type="button"
            onClick={() => this.close()}
            grey>
            <Trans i18nKey="portal.recruiter.balance-board.job-offer.add-modals.buttons.cancel">
              Cancel
            </Trans>
          </Button>
        </div>
      </Modal>
    );
  }

}
