import * as actions from './stats.constants';

import { StatsDto } from 'rest/stats/stats.dto';

import { getRequirementsStats } from 'rest/recruiter/requirements-stats/requirements-stats.rest';
import { RequirementsStatsParams } from 'rest/recruiter/requirements-stats/requirements-stats.params';

const getFavoritesUnitStatisticRequest = () => ({
  type: actions.GET_FAVORITES_UNIT_STATS_REQUEST
});

const getFavoritesUnitStatisticSuccess = (stats: StatsDto[] = [], total: number = 0) => ({
  type: actions.GET_FAVORITES_UNIT_STATS_SUCCESS,
  total: total,
  stats: stats
});

const getFavoritesUnitStatisticFailure = (error: string) => ({
  type: actions.GET_FAVORITES_UNIT_STATS_FAILURE,
  error
});

const getFavoritesJobTitlesStatisticRequest = () => ({
  type: actions.GET_FAVORITES_JOB_TITLE_STATS_REQUEST
});

const getFavoritesJobTitlesStatisticSuccess = (stats: StatsDto[] = [], total: number = 0) => ({
  type: actions.GET_FAVORITES_JOB_TITLE_STATS_SUCCESS,
  total: total,
  stats: stats
});

const getFavoritesJobTitlesStatisticFailure = (error: string) => ({
  type: actions.GET_FAVORITES_JOB_TITLE_STATS_FAILURE,
  error
});

const resetFavoritesStatisticSuccess = data => ({
  type: actions.RESET_FAVORITES_STATS_SUCCESS,
  payload: data
});

export const resetFavoriteStatistic = () => {
  return dispatch => {
    dispatch(resetFavoritesStatisticSuccess());
  };
};

export const getFavoriteUnitStatistic = (recruiterId: string) => {
  return dispatch => {
    dispatch(getFavoritesUnitStatisticRequest());
    const params = new RequirementsStatsParams();
    params.followingType = 'FAVORITES';
    params.requirementType = 'UNIT';
    return getRequirementsStats(recruiterId, params)
      .then(response => {
        dispatch(getFavoritesUnitStatisticSuccess(response.data.jobRequirementsCount, response.data.total));
      })
      .catch(error => dispatch(getFavoritesUnitStatisticFailure(error)));
  };
};

export const getFavoriteJobTitlesStatistic = (recruiterId: string) => {
  return dispatch => {
    dispatch(getFavoritesJobTitlesStatisticRequest());
    const params = new RequirementsStatsParams();
    params.followingType = 'FAVORITES';
    params.requirementType = 'JOB_TITLE';
    return getRequirementsStats(recruiterId, params)
      .then(response => {
        dispatch(getFavoritesJobTitlesStatisticSuccess(response.data.jobRequirementsCount, response.data.total));
      })
      .catch(error => dispatch(getFavoritesJobTitlesStatisticFailure(error)));
  };
};
