// @flow
import { AxiosResponse } from 'axios';
import { Action, Dispatch } from 'redux';

import { createQuestion } from 'rest/public/ask-question/ask-question.rest';
import { AskQuestionRequestDto } from 'rest/public/ask-question/ask-question.request.dto';
import { AskQuestionResponseDto } from 'rest/public/ask-question/ask-question.response.dto';

import * as actions from './contact.constants';

const sendContactRequest = (): Action => ({
  type: actions.SEND_CONTACT_REQUEST
});

const sendContactMeSuccess = (data: AskQuestionResponseDto): Action => ({
  type: actions.SEND_CONTACT_SUCCESS,
  payload: new AskQuestionResponseDto(data)
});

const sendContactFailure = (): Action => ({
  type: actions.SEND_CONTACT_FAILURE
});

export function sendContactQuestion (question: AskQuestionRequestDto) {
  return (dispatch: Dispatch) => {
    dispatch(sendContactRequest());
    return createQuestion(question)
      .then((response: AxiosResponse<AskQuestionResponseDto>) => dispatch(sendContactMeSuccess(response.data)))
      .catch(() => dispatch(sendContactFailure()));
  };
}
