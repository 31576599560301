import { PaginationDto } from 'middleware/dto';

export class RecruiterFavoriteParams extends PaginationDto {

  unitId: string;
  jobTitleId: string;
  recruiterId: string;
  candidateId: string;
  extraFields: string[] = [];

  constructor (data?: RecruiterFavoriteParams) {
    super(data);
    if (data) {
      this.unitId = data.unitId;
      this.jobTitleId = data.jobTitleId;
      this.extraFields = data.extraFields;
      this.recruiterId = data.recruiterId;
      this.candidateId = data.candidateId;
    }
  }

}
