import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'components/buttons';

import { Setting } from './success-reset.setting';

export class SuccessResetContainer extends Setting {

  render () {
    return (
      <Fragment>
        <p className="success-reset-caption">Your password has been changed successfully.</p>
        <Link to={this.props.redirectUrl}>
          <Button className={`sign-in-button ${this.props.buttonType}`}>
            Sign In
          </Button>
        </Link>
      </Fragment>
    );
  }

}
