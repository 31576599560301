// @flow
import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { List } from 'rest/list/list.dto';
import { CandidateDto } from 'rest/candidates/candidate.dto';
import { RecruiterFollowParams } from 'rest/recruiter/follow/follow.params';
import { JobOffersParams } from 'rest/public/job-offers/job-offers.params';
import { showModal } from 'services/portal/modal/modal.actions';
import { ModalPropsType } from 'services/portal/modal/modal-props-type';

import { getJobOffers, resetJobOffersList } from 'services/portal/candidate/jobs/job-offers/job-offers.actions';
import { addJobToFavorite, deleteJobFromFavorite } from 'services/portal/candidate/jobs/favorite/favorite.actions';

import './list.scss';

type Props = {
  fetching: boolean,
  candidates: List<CandidateDto>,
  currentRecruiterId: string,
  showModal: (id: string, params: ModalPropsType) => void,
  addingCandidateToFollow: (params: RecruiterFollowParams) => void,
  deletingCandidateFromFollow: (params: RecruiterFollowParams) => void,
  resetJobOffersList: (params: JobOffersParams) => void,
};

export class Setting extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  fetching: state.portal.recruiter.follow.fetching,
  jobOffers: state.portal.candidate.jobs.jobOffers.jobOffers,
  currentPage: state.portal.candidate.jobs.jobOffers.currentPage,
  hasMoreItems: state.portal.candidate.jobs.jobOffers.hasMoreItems,
  jobOffersFilter: state.portal.candidate.jobs.jobOffers.jobOffersFilter
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  showModal,
  getJobOffers,
  addJobToFavorite,
  deleteJobFromFavorite,
  resetJobOffersList
}, dispatch);
