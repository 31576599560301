// @flow
import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import type { Match } from 'common/types/match';

import { Modal } from 'components/dialogs';

import { AskMeModal } from 'containers/portal/ask-me';

type Props = {
  match: Match
}

export class AskMePage extends Component<Props> {

  render () {
    return (
      <Modal closePath={`/portal/${this.props.match.params['userType']}/${this.props.match.params['pageKey']}`}>
        <Route
          path={`/portal/:userType/:pageKey/:pageId?/ask-me`}
          exact
          component={AskMeModal}/>
      </Modal>
    );
  }

}
