// @flow
import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import cn from 'classnames';

import { Tab } from './../tab/tab';

import type { Props, State } from './tabs.setting';
import { Setting } from './tabs.setting';

export class Tabs extends Setting {

  static getDerivedStateFromProps (nextProps: Props, prevState: State) {
    const activeIndex: number = nextProps.activeTabIndex;
    if (activeIndex >= 0 && activeIndex !== prevState.activeTabIndex) {
      return { activeTabIndex: nextProps.activeTabIndex };
    }
    return null;
  }

  tabClick (activeTabIndex: number) {
    this.setState({ activeTabIndex });
    this.props.activeTabChange(activeTabIndex);
  }

  getCurrentTabIndex (): number {
    let tabIndex: number = this.state.activeTabIndex;
    React.Children.map(this.props.children, (child, index) => {
      if (this.props.currentRoute && child.props.link === this.props.currentRoute) {
        tabIndex = index;
      }
    });
    return tabIndex;
  }

  render () {
    const activeTabIndex: number = this.getCurrentTabIndex();
    const { headClassName } = this.props;
    const children = Array.isArray(this.props.children) ? this.props.children : [this.props.children];
    return (
      <div className="tabs-container">
        <div className={cn(['tabs-head', headClassName])}>
          <ul className={`tabs-list ${this.props.candidate ? 'primary' : 'accent'}`}>
            {
              children.map((tab: Tab, index: number) => {
                const { counter, disabled, link } = tab.props;
                const tabsListItemClasses = cn({
                  active: index === activeTabIndex,
                  disabled
                });
                return (
                  <Fragment key={index}>
                    {
                      link && <NavLink
                        onClick={() => { this.tabClick(index); tab.props.onClick(); }}
                        to={link}>
                        {tab.props.label} {counter > 0 && `(${counter})`}
                      </NavLink>
                    }
                    {
                      !link && <li
                        onClick={() => this.tabClick(index)}
                        className={tabsListItemClasses}>
                        {tab.props.label} {counter > 0 && `(${counter})`}
                      </li>
                    }
                  </Fragment>
                );
              })
            }
          </ul>
        </div>
        <div className="tabs-body">
          {
            children[activeTabIndex] && children[activeTabIndex].props.children
          }
        </div>
      </div>
    );
  }

}
