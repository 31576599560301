import axios, { AxiosPromise } from 'axios';

import { ConnectionsDto } from './connections.dto';
import { ConnectionsParams } from './connections.params';

export async function getConnectionsStats (recruiterId: string, params: ConnectionsParams)
  : AxiosPromise<ConnectionsDto> {
  try {
    return await axios.get(`/candidates/${recruiterId}/stats/connections`, { params });
  } catch (error) {
    throw error;
  }
}
