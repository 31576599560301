export class VideoDto {

  id: string;
  url: string;
  videoId: string;
  posterImage: string;
  urlReuse: string;
  primary = false;

  constructor (data?: VideoDto) {
    if (data) {
      this.id = data.id;
      this.url = data.url;
      this.videoId = data.imageId;
      this.primary = data.primary || false;
      this.posterImage = data.posterImage;
      this.urlReuse = data.urlReuse;
    }
  }

}
