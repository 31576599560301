import { Component } from 'react';

import './filter.scss';

type Props = {
  items: {},
  change: Function,
  children: Node,
  labelKey: string,
  totalTitle: string,
  counterKey: number
};

type State = {
  selectedItem: any,
  totalCounter: Function
}

export class Setting extends Component<Props, State> {

  state = {
    selectedItem: null,
    totalCounter: this.getTotalCounter()
  };

  static defaultProps = {
    items: {},
    change: () => {},
    totalTitle: ''
  }

}
