import { Component } from 'react';

import type { GlobalState } from 'common/types/global-state';

import { SkillsContainerDto } from 'rest/skills/skills-container.dto';

import './competencies-section.scss';
import './competencies-section.responsive.scss';

type Props = {
  skills: SkillsContainerDto
}

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  skills: state.portal.candidate.skills.content,
  balanceBoard: state.portal.candidate.balanceBoard.balanceBoard
});
