// @flow
import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import { Link, Redirect } from 'react-router-dom';

import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';

import { Button } from 'components/buttons';
import { Spinner } from 'components/elements';
import { FormGroup, InputControl, CheckboxControl } from 'components/controls';

import { RecruiterDto } from 'rest/recruiter/recruiter.dto';

import type { Props } from './sign-in-form.setting';
import { Setting, dispatchToProps, stateToProps } from './sign-in-form.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class SignInForm extends Setting {

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.redirect !== this.state.redirect) {
      this.setState({ redirect: nextProps.redirect });
    }
  }

  componentDidUpdate (prevProps: Props) {
    if (!prevProps.error && this.props.error) {
      toast.error(this.props.t('auth.employer.notifications.incorrect'));
    }
  }

  changeRequiterState (field: string, value: string | boolean, isError: boolean = false) {
    this.setState({ [field]: value });
    this.errorCollector(field, isError);
  }

  errorCollector (errorName: string, collect: boolean) {
    const errorCollection: Set<string> = new Set(this.state.errorCollection);
    collect ? errorCollection.add(errorName) : errorCollection.delete(errorName);
    this.setState({
      errorCollection
    });
  }

  recruiterSignIn (event: Event) {
    event.preventDefault();
    if (!this.state.errorCollection.size) {
      const recruiterData = new RecruiterDto(this.state);
      this.props.recruiterSignIn(recruiterData);
    }
  }

  render () {
    const { t } = this.props;
    const emailError = this.state.errorCollection.has('email');
    return (
      <Fragment>
        {this.state.redirect && !this.props.fetching && <Redirect to={this.state.unauthorizedUserUrl}/>}
        {this.props.needConfirm && <Redirect to="/auth/recruiter/sign-up/confirm"/>}
        <div className="sign-in-container">
          <form onSubmit={this.recruiterSignIn.bind(this)}>
            <FormGroup>
              <InputControl
                required
                value={this.state.email}
                error={emailError}
                change={(value: string, error: boolean): void => this.changeRequiterState('email', value, error)}
                label={t('auth.sign-in.employer.email.label')}
                title={t('auth.sign-in.employer.email.title')}
                placeholder={t('auth.sign-in.employer.email.placeholder')}
                errorText={t('auth.sign-in.employer.email.error')}
                type="email"/>
            </FormGroup>
            <FormGroup>
              <InputControl
                required
                validate={false}
                value={this.state.password}
                change={(value: string, error: boolean): void => this.changeRequiterState('password', value, error)}
                label={t('auth.sign-in.employer.password.label')}
                title={t('auth.sign-in.employer.password.title')}
                placeholder={t('auth.sign-in.employer.password.placeholder')}
                type="password"/>
            </FormGroup>
            <FormGroup>
              <div className="forgot-link-holder">
                <CheckboxControl
                  className="accent"
                  value={this.state.rememberMe}
                  change={(value: boolean): void => this.changeRequiterState('rememberMe', value)}
                  label={t('auth.sign-in.employer.remember')}/>
                <Link
                  to="/auth/recruiter/reset"
                  className="forgot-link">
                  <Trans i18nKey="auth.sign-in.employer.forgotPassword">
                    Forgot your password?
                  </Trans>
                </Link>
              </div>
            </FormGroup>
            <FormGroup>
              <Button
                type="submit"
                recruiter
                fullWidth>
                <Trans i18nKey="auth.sign-in.employer.button">
                  Sign In
                </Trans>
              </Button>
            </FormGroup>
          </form>
        </div>
        <Spinner show={this.props.fetching}/>
      </Fragment>
    );
  }

}
