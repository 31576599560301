import { combineReducers } from 'redux';

import reset from './reset/reset.reducer';
import confirm from './confirm/confirm.reducer';
import candidate from './candidate/auth.reducer';
import recruiter from './recruiter/auth.reducer';

export default combineReducers({
  reset,
  confirm,
  candidate,
  recruiter
});
