import axios from 'axios';

import { PaginationDto } from 'middleware/dto/pagination';

import { MessagesUnreadDto } from './messages-unread.dto';
import { MessagesRequestDto } from './messages.request.dto';
import { MessagesResponseDto } from './messages.response.dto';

export async function getChatMessages (conversationId: number, params: PaginationDto): Promise<MessagesResponseDto[]> {
  try {
    return await axios.get(`/chats/${conversationId}/messages`, { params });
  } catch (error) {
    throw error;
  }
}

export async function getUnreadChatMessages (conversationId: number): Promise<MessagesUnreadDto> {
  try {
    return await axios.get(`/chats/${conversationId}/messages/unread`);
  } catch (error) {
    throw error;
  }
}

export async function resetUnreadChatMessages (conversationId: number, messageId: string): Promise<MessagesUnreadDto> {
  try {
    return await axios.post(`/chats/${conversationId}/messages/read?messageIds=${messageId}`);
  } catch (error) {
    throw error;
  }
}

export async function createChatConversations (conversationId: number, message: MessagesRequestDto)
  : Promise<MessagesResponseDto[]> {
  try {
    const formData = new FormData();
    formData.append('message', message.message);
    formData.append('files', message.files);
    return await axios.post(`/chats/${conversationId}/messages`, formData);
  } catch (error) {
    throw error;
  }
}
