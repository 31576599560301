// @flow
import { Action, Dispatch } from 'redux';

import * as actions from './sign-up.constants';

import { CandidateDto } from 'rest/candidates/candidate.dto';
import { createCandidate } from 'rest/candidates/candidate.rest';

const candidateSinUpRequest = (): Action => ({
  type: actions.CANDIDATE_SIGN_UP_REQUEST
});

const candidateSignUpSuccess = (email: string): Action => ({
  type: actions.CANDIDATE_SIGN_UP_SUCCESS,
  email
});

const candidateSignUpFailure = (error): Action => ({
  type: actions.CANDIDATE_SIGN_UP_FAILURE,
  error
});

export const candidateSignUp = (data: CandidateDto) => {
  return (dispatch: Dispatch) => {
    dispatch(candidateSinUpRequest());
    return createCandidate(data)
      .then(() => {
        localStorage.setItem('emailToConfirm', data.email);
        dispatch(candidateSignUpSuccess(data.email));
      })
      .catch(error => dispatch(candidateSignUpFailure(error.data)));
  };
};
