// @flow
import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';

import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import Popover from 'react-popover';

import moment from 'moment';

import { connectStore } from 'middleware/connect';

import { Button } from 'components/buttons';
import { ShowSalaryRange } from 'components/elements';
import { SelectMenuItem, SelectMenu } from 'components/elements/select-menu';

import { CandidateDto } from 'rest/candidates/candidate.dto';
import { ChatRequestDto } from 'rest/chats/chat.request.dto';
import { RecruiterFollowParams } from 'rest/recruiter/follow/follow.params';
import { RecruiterFavoriteParams } from 'rest/recruiter/favorites/favorite.params';

import { Setting, stateToProps, dispatchToProps } from './search-candidate-row.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class SearchCandidateRow extends Setting {

  toggleMenu (event: Event) {
    event.stopPropagation();
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  }

  closeMenu () {
    this.setState({ isMenuOpen: false });
  }

  blockCandidateToggle (event: MouseEvent, candidate: CandidateDto) {
    event.stopPropagation();
    this.props.blockUserCandidate(this.props.currentUser.id, candidate.id);
    toast.success(this.props.t('portal.recruiter.notifications.candidate-blocked'));
    this.closeMenu();
  }

  favoriteCandidateToggle (event: MouseEvent, candidate: CandidateDto) {
    event.stopPropagation();
    const params: RecruiterFavoriteParams = new RecruiterFavoriteParams();
    params.recruiterId = this.props.currentUser.id;
    params.candidateId = candidate.id;
    candidate.favorite ? this.props.deleteFromFavorite(params) : this.props.addToFavorite(params);
    candidate.favorite = !candidate.favorite;
    this.props.changeCurrentCandidate(candidate);
    toast.success(candidate.favorite
      ? this.props.t('portal.recruiter.notifications.favorites-add')
      : this.props.t('portal.recruiter.notifications.favorites-delete')
    );
    this.closeMenu();
  }

  followCandidateToggle (event: MouseEvent, candidate: CandidateDto) {
    event.stopPropagation();
    const params: RecruiterFollowParams = new RecruiterFollowParams();
    params.recruiterId = this.props.currentUser.id;
    params.candidateId = candidate.id;
    candidate.follow ? this.props.deleteFromFollow(params) : this.props.addToFollow(params);
    candidate.follow = !candidate.follow;
    this.props.changeCurrentCandidate(candidate);
    toast.success(candidate.follow
      ? this.props.t('portal.recruiter.notifications.following-add')
      : this.props.t('portal.recruiter.notifications.following-delete')
    );
    this.closeMenu();
  }

  startCandidateChat (event: MouseEvent, candidateId: string) {
    event.stopPropagation();
    const chat: ChatRequestDto = new ChatRequestDto();
    chat.candidateId = candidateId;
    chat.recruiterId = this.props.currentUser.id;
    this.props.createChatConversation(chat);
    toast.success(this.props.t('portal.recruiter.notifications.chat-created'));
    this.closeMenu();
  }

  renderPopoverMenu () {
    const candidate: CandidateDto = this.props.candidate;
    const restricted: boolean = !candidate.email && !candidate.firstName;

    return (
      <SelectMenu
        className="search-candidate-row-action-menu"
        close={() => this.closeMenu()}
        open={this.state.isMenuOpen}>
        {
          !restricted && <>
            <SelectMenuItem onClick={event => this.favoriteCandidateToggle(event, candidate)}>
              {candidate.favorite
                ? this.props.t('portal.recruiter.candidates.list.favorites.remove')
                : this.props.t('portal.recruiter.candidates.list.favorites.add')}
            </SelectMenuItem>
            <SelectMenuItem
              disabled={!candidate.directContact}
              onClick={event => this.startCandidateChat(event, candidate.id)}>
              Chat
            </SelectMenuItem>
            <SelectMenuItem onClick={event => this.followCandidateToggle(event, candidate)}>
              {candidate.follow
                ? this.props.t('portal.recruiter.candidates.list.follow.remove')
                : this.props.t('portal.recruiter.candidates.list.follow.add')}
            </SelectMenuItem>
          </>
        }
        <SelectMenuItem onClick={event => this.blockCandidateToggle(event, candidate)}>
          <Trans i18nKey="portal.recruiter.candidates.list.menu.block">
            Block User
          </Trans>
        </SelectMenuItem>
      </SelectMenu>
    );
  }

  render () {
    const candidate: CandidateDto = this.props.candidate;
    return (
      <Fragment>
        { this.props.chatId && <Redirect to={`/portal/recruiter/chat/${this.props.chatId}`}/> }
        <tr
          onClick={() => this.props.onClick()}
          className="candidate-row">
          <td className="candidate-avatar">
            <div className="cell">
              <div className="avatar-wrapper">
                <img src={candidate.primaryPhotoUrl}/>
              </div>
            </div>
          </td>
          <td>
            <div className="cell">
              <div><strong>{candidate.fullName}</strong></div>
              {
                candidate.candidateAddress && <div>
                  {candidate.candidateAddress.city}, {candidate.candidateAddress.country}
                </div>
              }
            </div>
          </td>
          <td>
            <div className="cell">
              <div>
                <strong>
                  {candidate.jobTitles.length
                    ? candidate.jobTitles[0].name
                    : this.props.t('portal.recruiter.candidates.list.no-information')}
                </strong>
              </div>
              <div>
                <Trans i18nKey="portal.recruiter.candidates.list.updated">
                  updated
                </Trans>
                &nbsp;
                {moment(candidate.updatedDate).parseZone().fromNow() ||
                this.props.t('portal.recruiter.candidates.list.no-information')}
              </div>
            </div>
          </td>
          <td>
            <div className="cell">
              <div>
                <strong>
                  {candidate.branches.length
                    ? candidate.branches[0].name
                    : this.props.t('portal.recruiter.candidates.list.no-information')}
                </strong>
              </div>
              <ShowSalaryRange salaryRange={candidate.salaryResponse}/>
            </div>
          </td>
          <td className="actions">
            <div className="action-menu-container">
              <Popover
                tipSize={0.01}
                onOuterAction={() => this.setState({ isMenuOpen: false })}
                preferPlace="below"
                isOpen={this.state.isMenuOpen}
                body={this.renderPopoverMenu()}
              >
                <Button
                  onClick={this.toggleMenu.bind(this)}
                  className="menu-button inline">
                  <img src="/images/shared/menu-btn.svg"/>
                </Button>
              </Popover>
            </div>
          </td>
        </tr>
      </Fragment>
    );
  }

}
