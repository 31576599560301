// @flow
import React, { Fragment } from 'react';

import cn from 'classnames';

import { connectTranslation } from 'middleware/connect';

import { SkillDto } from 'rest/skills/skills.dto';

import { Setting } from './skills-list.setting';
import { Trans } from 'react-i18next';

@connectTranslation()
export class SkillsList extends Setting {

  render () {
    const skillsContainer = this.props.skills;
    const sortingView: string[] = [
      'professionalSkills',
      'softSkills',
      'documents',
      'softwareSkills',
      'languageSkills'
    ];
    const classes = cn('competencies-table', {
      'recruiter': this.props.recruiter
    });
    const sectionTitles = [
      'Skills',
      'Soft skills',
      'Documents',
      'Software skills',
      'Language'
    ];
    return (
      <table className={`${classes} ${this.props.className}`}>
        <thead>
          <tr>
            <th>
              <Trans i18nKey="portal.recruiter.candidates.profile.skills.name">
                Competency Name
              </Trans>
            </th>
            {this.props.recruiter && <th/>}
            <th>
              {this.props.recruiter
                ? this.props.t('portal.recruiter.candidates.profile.skills.required')
                : this.props.t('portal.recruiter.candidates.profile.skills.knowledge')}
            </th>
            <th/>
          </tr>
        </thead>
        <tbody>
          {sortingView.map((section, index) => (
            <Fragment key={index}>
              {
                skillsContainer[section] && skillsContainer[section].length ? <tr>
                  <td className="skill-name">
                    <div className="title">{sectionTitles[index]}</div>
                  </td>
                  <td className="skill-progress"/>
                  <td className="skill-level"/>
                </tr> : null
              }
              {
                (skillsContainer[section] || []).map((skill: SkillDto) => (
                  <tr key={skill.skillId}>
                    <td className="skill-name">
                      <div>{skill.name}</div>
                    </td>
                    {
                      this.props.recruiter && <td className="mobile-skill-required">
                        <div>
                          {skill.required && <div className="skill-required"/>}
                        </div>
                      </td>
                    }
                    <td className="skill-progress">
                      {
                        section !== 'documents' && <div className="progress-container">
                          <div className={'progress-point' + (skill.skillLevel === 'BASIC' ? ' active' : '')}/>
                          <div className="join"/>
                          <div className={'progress-point' + (skill.skillLevel === 'AVERAGE' ? ' active' : '')}/>
                          <div className="join"/>
                          <div className={'progress-point' + (skill.skillLevel === 'GOOD' ? ' active' : '')}/>
                          <div className="join"/>
                          <div className={'progress-point' + (skill.skillLevel === 'EXCELLENT' ? ' active' : '')}/>
                        </div>
                      }
                    </td>
                    <td className="skill-level">
                      <div>{skill.skillLevel}</div>
                    </td>
                  </tr>
                ))
              }
            </Fragment>
          ))}
        </tbody>
      </table>
    );
  }

}
