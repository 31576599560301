// @flow
import React from 'react';

import { Trans } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';

import {
  setRegistrationEmail,
  getRegistrationEmail,
  resetRegistrationEmail
} from 'middleware/registration-email';
import { connectStore } from 'middleware/connect';

import { Spinner } from 'components/elements';
import { Button, SocialButtons } from 'components/buttons';
import { InputControl, FormGroup, CheckboxControl } from 'components/controls';

import { CandidateDto } from 'rest/candidates/candidate.dto';
import { RegistrationModal } from 'components/dialogs';
import type { Props } from './sign-up-form.setting';
import { dispatchToProps, Settings, stateToProps } from './sign-up-form.setting';
import { getLocalization } from 'middleware/localization';
const language = getLocalization();
@connectStore(stateToProps, dispatchToProps, true)
export class SignUpForm extends Settings {

  componentDidMount () {
    this.props.getSocialUrl('google');
    this.props.getSocialUrl('facebook');
    this.setState({
      email: getRegistrationEmail()
    });
  }

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.errorText !== this.props.errorText) {
      this.setState({
        errorText: nextProps.errorText,
        emailError: nextProps.error
      });
    }
    if (nextProps.redirect !== this.props.redirect) {
      this.setState({ redirect: nextProps.redirect });
    }
  }

  changeCandidateState (field: string, value: string | boolean, hasError: boolean = false) {
    if (field === 'email') {
      setRegistrationEmail(value);
      this.setState({
        [field]: getRegistrationEmail(),
        errorText: ''
      });
    } else {
      this.setState({
        [field]: value,
        errorText: ''
      });
    }
    this.errorCollector(field, hasError);
  }

  errorCollector (errorName: string, collect: boolean) {
    const errorCollection: Set<string> = new Set(this.state.errorCollection);
    collect ? errorCollection.add(errorName) : errorCollection.delete(errorName);
    this.setState({ errorCollection });
  }

  registerRecruiter (event: Event) {
    event.preventDefault();
    if (!this.state.errorCollection.size) {
      const candidate = new CandidateDto(this.state);
      this.props.candidateSignUp(candidate);
      resetRegistrationEmail();
    }
  }

  render () {
    const { t } = this.props;
    return (
      <div className="sign-up-container">
        {this.state.redirect && <Redirect to="/auth/candidate/sign-up/confirm"/>}
        <form onSubmit={this.registerRecruiter.bind(this)}>
          <SocialButtons
            google={this.props.googleLink}
            facebook={this.props.facebookLink}/>
          <FormGroup>
            <InputControl
              value={this.state.email}
              error={this.state.emailError}
              required
              icon
              change={(value, hasError) => this.changeCandidateState('email', value, hasError)}
              label={t('auth.sign-up.employer.email.label')}
              title={t('auth.sign-up.employer.email.note')}
              placeholder={t('auth.sign-up.employer.email.placeholder')}
              errorText={this.state.errorText || t('auth.sign-up.employer.email.error')}
              type="email"
              className="mobile-sign-up"/>
          </FormGroup>
          <FormGroup>
            <InputControl
              minLength={6}
              maxLength={32}
              required
              icon
              title={t('auth.sign-up.employer.password.note')}
              value={this.state.password}
              change={(value, hasError) => this.changeCandidateState('password', value, hasError)}
              label={t('auth.sign-up.employer.password.label')}
              notes={t('auth.sign-up.employer.password.note')}
              placeholder={t('auth.sign-up.employer.password.placeholder')}
              errorText={t('auth.sign-up.employer.password.error')}
              type="password"/>
          </FormGroup>
          <FormGroup>
            <InputControl
              minLength={6}
              maxLength={32}
              required
              icon
              title={t('auth.sign-up.employer.repeatPassword.note')}
              pattern={this.state.password}
              value={this.state.passwordCheck}
              change={(value, hasError) => this.changeCandidateState('passwordCheck', value, hasError)}
              label={t('auth.sign-up.employer.repeatPassword.label')}
              placeholder={t('auth.sign-up.employer.repeatPassword.placeholder')}
              errorText={t('auth.sign-up.employer.repeatPassword.error')}
              type="password"/>
          </FormGroup>
          <FormGroup>
            <CheckboxControl
              value={this.state.acceptRules}
              change={(acceptRules: boolean): void => this.setState({ acceptRules })}>
              <Trans i18nKey="auth.sign-up.employer.agree-rules.title">
                I agree with ExcellentJobs
              </Trans>
              <Link to="/information/privacy-policy">
                <Trans i18nKey="auth.sign-up.employer.agree-rules.privacy">
                  Privacy Policy
                </Trans>
              </Link>
              <Trans i18nKey="auth.sign-up.employer.agree-rules.and">
                and
              </Trans>
              <Link to="/information/privacy-policy#terms-and-conditions">
                <Trans i18nKey="auth.sign-up.employer.agree-rules.terms">
                  Terms of Use
                </Trans>
              </Link>
              <Trans i18nKey="auth.sign-up.employer.agree-rules.portal">
              </Trans>
            </CheckboxControl>
          </FormGroup>
          <FormGroup>
            <CheckboxControl
              value={this.state.acceptNotification}
              change={(acceptNotification: boolean): void => this.setState({ acceptNotification })}
              label={t('auth.sign-up.employer.email-notification')}/>
          </FormGroup>
          <FormGroup>
            <Button
              type="submit"
              candidate
              fullWidth
              disabled={!this.state.acceptRules}>
              <Trans i18nKey="auth.sign-up.employer.button">
                Sign Up
              </Trans>
            </Button>
          </FormGroup>
        </form>
        <Spinner show={this.props.fetching}/>
        {language === 'de' && <RegistrationModal recruiter={false}/>}
      </div>
    );
  }

}
