import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import {
  removeMatchedCandidate,
  getMatchedCandidatesList,
  getMatchedCandidatesLocationsList
} from 'services/portal/recruiter/match/job-offers/candidates/candidates.actions';

import { saveMatchedJobOfferAsViewed } from 'services/portal/recruiter/match/job-offers/job-offers.actions';

import './candidates-list.scss';

type Props = {
  children: any
};

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  location: state.portal.recruiter.match.jobOfferCandidates.location,
  fetching: state.portal.recruiter.match.jobOfferCandidates.fetching,
  candidates: state.portal.recruiter.match.jobOfferCandidates.candidates,
  currentUser: state.portal.recruiter.account.current.currentUser,
  currentPage: state.portal.recruiter.match.jobOfferCandidates.currentPage,
  hasMoreItems: state.portal.recruiter.match.jobOfferCandidates.hasMoreItems
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getMatchedCandidates: getMatchedCandidatesList,
  deleteMatchedCandidate: removeMatchedCandidate,
  saveMatchedJobOfferAsViewed: saveMatchedJobOfferAsViewed,
  getMatchedCandidatesLocation: getMatchedCandidatesLocationsList
}, dispatch);
