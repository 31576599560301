import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { requestAskMeContent, resetAskMeContent } from 'services/portal/ask-me/ask-me.actions';

import { ImageDto } from 'rest/images/image.dto';
import { AskMeResponseDto } from 'rest/public/askme/askme.response.dto';

import './modal.scss';
import './modal.responsive.scss';

type Props = {
  t: TransProps,
  image: ImageDto,
  content: AskMeResponseDto
}

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => {
  // console.log(state.portal)
  // console.log(state.portal.askMe.video)
  return {
    image: state.portal.askMe.image,
    content: state.portal.askMe.content,
    video: state.portal.askMe.video
  };
};

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetAskMeContent,
  requestAskMeContent
}, dispatch);
