import React, { Component } from 'react';

import { Video } from 'components/video';
import { Container } from 'components/layouts';

import { Pwa, Match, StartNow, BalanceBoard } from 'containers/information';

export class HowItWorksPage extends Component {

  render () {
    return (
      <div className="video-container">
        <Container>
          <Video howItwork={true}/>
        </Container>
        <Container>
          <BalanceBoard/>
          <Match/>
        </Container>
        <StartNow/>
        <Container>
          <Pwa/>
        </Container>
      </div>
    );
  }

}
