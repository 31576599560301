// @flow
import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';
import { getData, setData } from 'middleware/job-offer-storage';
import { setData as setPaymentStepData } from 'middleware/payment-step';

import PromoCode from 'containers/portal/recruiter/promo-code/promo-code';
import { FormGroup, InputControl, RadioControl, RadioGroup } from 'components/controls';
import { Button, LinkButton } from 'components/buttons';
import { PaymentCard } from './payment-card/payment-card';
import { PaymentInvoiceInfo } from 'containers/portal/recruiter/payment-invoice-info/payment-invoice-info';

import { PaymentOptions } from 'containers/portal/recruiter/account/settings/payment/payment-options/payment-options';
import { SepaPaymentModal } from 'containers/portal/recruiter/sepa-payment-modal/sepa-payment-modal';
import { JobOfferPreviewModal } from '../preview-modal/preview-modal';

import { CompanyDto } from 'rest/companies/companies.dto';
import { JobOfferDto } from 'rest/job-offers/job-offers.dto';
import { SubscriptionDto } from 'rest/companies/subscription/subscription.dto';

import type { Props } from './payment.setting';
import { Settings, dispatchToProps, stateToProps } from './payment.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class GuestJobOfferPayment extends Settings {

  componentDidMount () {
    const {
      currentUser: { companyId }, getAllCompanyJobOffers, getCurrentRecruiter, getRecruiterCompanyById
    } = this.props;
    const GET_CURRENT_RECRUITER_SUCCESS = 'GET_CURRENT_RECRUITER_SUCCESS';
    const GET_COMPANY_SUCCESS = 'GET_COMPANY_BY_ID_SUCCESS';

    if (companyId) {
      this.subscribeCompany();
      getAllCompanyJobOffers(companyId);
    }

    getCurrentRecruiter()
      .then(res => {
        if (res.type !== GET_CURRENT_RECRUITER_SUCCESS) return;

        getRecruiterCompanyById(res.recruiter.companyId)
          .then(res => {
            if (res.type !== GET_COMPANY_SUCCESS) return;

            this.setState({
              company: res.company,
              isCompanyInfoLoaded: !!res.company.name
            });
          });
      });
  }

  componentWillReceiveProps (nextProps: Props, nextState) {
    if (nextProps.redirectLink !== this.state.redirectLink) {
      this.setState({ redirectLink: nextProps.redirectLink });
    }
  }

  componentDidUpdate (prevProps: Props, prevState) {
    if (this.props.currentUser.id !== prevProps.currentUser.id) {
      this.subscribeCompany();
      this.props.getAllCompanyJobOffers(this.props.currentUser.companyId);
      this.updateCompany();
    }
  }

  changeSubscriptionPlan (value: string) {
    this.setState({
      subscriptionPlan: value,
      redirectLink: value === 'FREE' ? '' : this.props.redirectLink
    }, () => this.subscribeCompany());
  }

  changePromoCode (value: string) {
    this.setState({
      promoCode: value
    }, () => this.subscribeCompany());
  }

  changeSubscriptionMethod (value: string) {
    this.setState({
      paymentMethod: value,
      subscriptionPlan: 'PREMIUM'
    }, () => this.subscribeCompany());
  }

  subscribeCompany () {
    const subscriptionData: SubscriptionDto = new SubscriptionDto(this.state);
    this.props.subscribeRecruiterCompany(this.props.currentUser.companyId, subscriptionData);
  }

  toggleJobOfferPreviewModal (mode: string = '') {
    this.setState({
      mode,
      isPreviewModalOpen: !this.state.isPreviewModalOpen
    });
  }

  updateCompany (data) {
    const {
      currentUser: { companyId }, updateRecruiterCompany, getRecruiterCompanyById
    } = this.props;
    const company: CompanyDto = getData('ej_company_info');
    const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
    const GET_COMPANY_SUCCESS = 'GET_COMPANY_BY_ID_SUCCESS';

    updateRecruiterCompany(companyId, data || company)
      .then(res => {
        if (res.type !== UPDATE_COMPANY_SUCCESS) return;

        getRecruiterCompanyById(companyId).then(res =>
          this.setState({
            company: res.company || company,
            isCompanyInfoLoaded: res.type === GET_COMPANY_SUCCESS
          }));
      });

    this.createGuestJobOffer();
  }

  createGuestJobOffer () {
    const companyInfo: CompanyDto = getData('ej_company_info');
    const generalInfo: JobOfferDto = getData('ej_general_job_offer');
    if (generalInfo) {
      generalInfo.branchId = companyInfo.branchId;
      generalInfo.recruiterId = this.props.currentUser.id;
      generalInfo.companyId = this.props.currentUser.companyId;
      setData('ej_general_job_offer', generalInfo);
      this.props.createJobOfferFlow(true, this.props.currentUser.companyId);
    }
  }

  updateVatId = () => {
    const { currentUser: { companyId }, updateRecruiterCompany } = this.props;
    const { vatId, company } = this.state;
    const companyInfo: CompanyDto = company || getData('ej_company_info');

    const companyData: CompanyDto = {
      ...companyInfo,
      vatId
    };

    updateRecruiterCompany(companyId, companyData);
  };

  handleSepaPaymentForm = () => {
    this.setState({
      isSepaModalOpen: !this.state.isSepaModalOpen
    });
  };

  handleEditableState = value => {
    this.setState({
      isCompanyInfoEditable: !this.state.isCompanyInfoEditable
    });

    if (value) {
      this.updateCompany(value);
    }
  };

  handleChangePromoCode = value => {
    this.setState({
      promoCode: value,
      promoCodeIsChange: true,
      promoCodeIsFailed: false
    });
  };

  applyPromoCode = () => {
    const { currentUser: { companyId }, subscribeRecruiterCompany } = this.props;
    const { promoCode, paymentMethod, paymentRedirectUrls } = this.state;
    const CompanySubscriptionPlan: SubscriptionDto = new SubscriptionDto({
      promoCode,
      paymentMethod,
      paymentRedirectUrls,
      subscriptionPlan: 'PREMIUM'
    });

    subscribeRecruiterCompany(companyId, CompanySubscriptionPlan)
      .then(res => {
        const { amount, months } = res.link;
        const promoCodeIsValid = months > 0;

        if (!promoCodeIsValid) {
          this.setState({
            promoCodeIsFailed: true
          });
          return;
        }

        if (months === 999) {
          this.setState({
            subscriptionPlan: 'FREE'
          });
          return;
        }

        this.setState({
          amount,
          months,
          promoCodeIsValid,
          promoCodeIsChange: false
        });
      });
  };

  handleOnSubmit = e => {
    if (e) e.preventDefault();

    const { match: { path } } = this.props;
    const { subscriptionPlan, vatId, paymentMethod } = this.state;
    const subPlanIsFree = subscriptionPlan === 'FREE';

    if (subPlanIsFree) {
      this.setState({
        confirmPayment: true
      });
      return;
    }

    if (!vatId) {
      this.setState({
        vatIdError: true
      });
      return;
    }

    this.setState(
      { isSepaModalOpen: paymentMethod === 'SEPA_DIRECT_DEBIT' }
    );

    setPaymentStepData('ej_payment_step_link', { path });
    this.updateVatId();
  };

  render () {
    const {
      subscriptionPlan, paymentMethod, paymentRedirectUrls, isSepaModalOpen, company,
      vatIdError, vatId, isCompanyInfoLoaded, isCompanyInfoEditable, promoCode,
      promoCodeIsValid, promoCodeIsFailed, promoCodeIsChange, months, amount, confirmPayment
    } = this.state;
    const { t, currentUser, prev, redirectLink } = this.props;

    const subPlanIsFree = subscriptionPlan === 'FREE';
    const subscriptionPrice: number = subPlanIsFree ? 0 : amount;
    const paymentMethodDescription: string = paymentMethod === 'SOFORT' ? ' Sofort' : ' Sepa Direct';
    const paymentMethodDetails: string = subPlanIsFree
      ? 'Free/Basic'
      : (promoCodeIsValid
        ? `Premium Plan with special offer for the first ${months} month${months > 1 ? 's' : ''}`
        : 'Premium Plan');

    return (
      <Fragment>
        {confirmPayment && <Redirect to='/portal/recruiter/account/payment/free'/>}
        {(currentUser && currentUser.fullyRegistered) && <Redirect to="/portal/recruiter/balance-board"/>}

        <div className="guest-job-offer-payment-container">
          <h3 className="title">
            <Trans i18nKey="portal.guest.recruiter.job-offer.payment.title">
              Take a look at our options for subscription plans!
            </Trans>
          </h3>
          <div className="tabs-head linear-tabs">
            <ul className="tabs-list accent">
              <li className="disabled">
                <Trans i18nKey="portal.guest.recruiter.job-offer.company-info.header">
                  1. Company Info
                </Trans>
              </li>
              <li className="disabled">
                <Trans i18nKey="portal.guest.recruiter.job-offer.header">
                  2. Job Offer Creation
                </Trans>
              </li>
              <li className="disabled">
                <Trans i18nKey="portal.guest.recruiter.job-offer.personal-info.header">
                  3. Personal Info
                </Trans>
              </li>
              <li className="active">
                <Trans i18nKey="portal.guest.recruiter.job-offer.payment.header">
                  4. Payment
                </Trans>
              </li>
            </ul>
          </div>
          <div className="payment">
            <h3 className="payment-caption">
              <Trans i18nKey="portal.guest.recruiter.job-offer.payment.sub-title">
                Your Perfect Plan
              </Trans>
            </h3>
            <p className="payment-description">
              <Trans i18nKey="portal.guest.recruiter.job-offer.payment.description">
                Find the perfect plan for you — 100% satisfaction guaranteed.
              </Trans>
            </p>
            <RadioGroup
              className="card-container"
              change={this.changeSubscriptionPlan.bind(this)}
              defaultValue={subscriptionPlan}>
              <RadioControl value="PREMIUM" custom>
                <PaymentCard
                  showJobOffer={() => this.toggleJobOfferPreviewModal('PREMIUM')}
                  selected={subscriptionPlan === 'PREMIUM'}
                  price={250}
                  title={t('portal.guest.recruiter.job-offer.payment.premium-card.title')}>
                  <li>{t('portal.guest.recruiter.job-offer.payment.premium-card.items.0')}</li>
                  <li>{t('portal.guest.recruiter.job-offer.payment.premium-card.items.1')}</li>
                  <li>{t('portal.guest.recruiter.job-offer.payment.premium-card.items.2')}</li>
                  <li>{t('portal.guest.recruiter.job-offer.payment.premium-card.items.3')}</li>
                  <li>{t('portal.guest.recruiter.job-offer.payment.premium-card.items.4')}</li>
                  <li>{t('portal.guest.recruiter.job-offer.payment.premium-card.items.5')}</li>
                </PaymentCard>
              </RadioControl>
              <RadioControl value="FREE" custom>
                <PaymentCard
                  showJobOffer={() => this.toggleJobOfferPreviewModal('FREE')}
                  selected={subPlanIsFree}
                  priceCondition="&euro;50 per contact"
                  title={t('portal.guest.recruiter.job-offer.payment.free-card.title')}>
                  <li>{t('portal.guest.recruiter.job-offer.payment.free-card.items.0')}</li>
                  <li>{t('portal.guest.recruiter.job-offer.payment.free-card.items.1')}</li>
                  <li>{t('portal.guest.recruiter.job-offer.payment.free-card.items.2')}</li>
                  <li>{t('portal.guest.recruiter.job-offer.payment.free-card.items.3')}</li>
                  <li>{t('portal.guest.recruiter.job-offer.payment.free-card.items.4')}</li>
                </PaymentCard>
              </RadioControl>
            </RadioGroup>
            <PaymentOptions
              disabled={subPlanIsFree}
              defaultOption={paymentMethod}
              changeOptions={this.changeSubscriptionMethod.bind(this)}
            />
            <SepaPaymentModal
              isOpen={isSepaModalOpen}
              companyId={currentUser.companyId}
              currentUser={currentUser}
              paymentMethod={paymentMethod}
              paymentRedirectUrls={paymentRedirectUrls}
              closeModal={this.handleSepaPaymentForm}
              redirectLink={redirectLink}
            />

            <div className={`payment-invoice-details${subPlanIsFree ? ' disabled' : ''}`}>
              <h4 className="payment-invoice-details-title">Invoice Details</h4>

              <form className="vat-id-form" onSubmit={this.handleOnSubmit}>
                <FormGroup className="half-width">
                  <InputControl
                    type="text"
                    name="vatId"
                    value={vatId}
                    label={t('portal.guest.recruiter.job-offer.payment.invoice-details.vat-id.label')}
                    placeholder={t('portal.guest.recruiter.job-offer.payment.' +
                      'invoice-details.vat-id.placeholder')}
                    required={!subPlanIsFree}
                    error={vatIdError && !vatId}
                    errorText={t('portal.guest.recruiter.job-offer.payment.invoice-details.vat-id.error')}
                    change={vatId => this.setState({ vatId })}/>
                </FormGroup>
              </form>

              {isCompanyInfoLoaded && <PaymentInvoiceInfo
                company={company}
                editable={isCompanyInfoEditable}
                disabled={subPlanIsFree}
                handleEditableState={this.handleEditableState}
              />}

              <PromoCode
                t={t}
                value={promoCode}
                isValid={promoCodeIsValid}
                isFailed={promoCodeIsFailed}
                isChange={promoCodeIsChange}
                isCompanyInfoEditable={isCompanyInfoEditable}
                handleChange={this.handleChangePromoCode}
                applyPromoCode={this.applyPromoCode}
              />
            </div>

            <div className={`payment-footer${isCompanyInfoEditable ? ' disabled' : ''}`}>
              <div className="subscription-plan">
                {t('portal.guest.recruiter.job-offer.payment.subscription')}: {paymentMethodDetails}
                <span>&euro;{subscriptionPrice}</span>
              </div>
              <div className="payment-action half-width">
                <Button
                  className="btn accent grey flex-basis-auto"
                  type="button"
                  onClick={() => prev()}>
                  <Trans i18nKey="portal.guest.recruiter.job-offer.payment.buttons.back">
                    Back
                  </Trans>
                </Button>
                {paymentMethod === 'SOFORT' && vatId
                  ? <LinkButton
                    className="btn accent large"
                    link={redirectLink || '/portal/recruiter/account/payment/free'}
                    external={true}
                    secondary
                    onClick={this.updateVatId}>
                    <Trans i18nKey="portal.guest.recruiter.job-offer.payment.buttons.confirm">
                      Confirm Payment
                    </Trans>
                  </LinkButton>
                  : <Button
                    className="btn accent large"
                    type="submit"
                    onClick={e => this.handleOnSubmit(e)}>
                    <Trans i18nKey="portal.guest.recruiter.job-offer.payment.buttons.confirm">
                      Confirm Payment
                    </Trans>
                  </Button>
                }
              </div>
              <p className="payment-policy">
                <Trans i18nKey="portal.guest.recruiter.job-offer.payment.buttons.confirm">
                  By clicking ‘Confirm Payment’ you’ll be<br/> redirected to the
                </Trans>{paymentMethodDescription} website for payment.
              </p>
            </div>
          </div>
        </div>
        <JobOfferPreviewModal
          mode={this.state.mode}
          open={this.state.isPreviewModalOpen}
          hideModal={() => this.toggleJobOfferPreviewModal()}/>
      </Fragment>
    );
  }

}
