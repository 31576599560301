import { Component } from 'react';
import { TransProps } from 'react-i18next';

import { SalaryRangeDto } from 'rest/job-offers/job-offers.salary-range.dto';

import './salary-range.scss';
import './salary-range.responsive.scss';

type Props = {
  t: TransProps,
  required?: boolean,
  change: () => void,
  maxSalaryRange: number,
  defaultValue?: SalaryRangeDto,
  className?: string
}

type State = {
  salaryRange: SalaryRangeDto
}

export class Settings extends Component<Props> {

  static defaultProps: Props = {
    required: false,
    change: () => {},
    maxSalaryRange: 10000000,
    className: ''
  };

  state: State = {
    salaryRange: new SalaryRangeDto({
      min: null,
      max: null,
      salaryType: 'MONTH'
    })
  }

}
