// @flow
import { AxiosResponse } from 'axios';
import { Action, Dispatch } from 'redux';

import { AskMeParams } from 'rest/public/askme/askme.params';
import { AskMeResponseDto } from 'rest/public/askme/askme.response.dto';

import { getAskMeContent } from 'rest/public/askme/askme.rest';
import videoAskMeJSONRECRUITER from '../../../components/dialogs/menu-video-modal/recruiterVideo.json';
import videoAskMeJSONCANDIDATE from '../../../components/dialogs/menu-video-modal/candidatesVideo.json';
import * as actions from './ask-me.constants';
import { getLocalization } from 'middleware/localization';
const getAskMeRequest = (): Action => ({
  type: actions.GET_ASK_ME_REQUEST
});

const getAskMeSuccess = (data: AskMeResponseDto): Action => ({
  type: actions.GET_ASK_ME_SUCCESS,
  payload: new AskMeResponseDto(data)
});

const getAskMeFailure = (): Action => ({
  type: actions.GET_ASK_ME_FAILURE
});

const resetAskMeSuccess = (): Action => ({
  type: actions.RESET_ASK_ME_SUCCESS
});

export function resetAskMeContent () {
  return (dispatch: Dispatch) => {
    dispatch(resetAskMeSuccess());
  };
}

export function requestAskMeContent (pageKey: string, userType: string) {
  const language = getLocalization();
  return (dispatch: Dispatch) => {
    dispatch(getAskMeRequest());
    const params = new AskMeParams();
    params.userType = userType.toUpperCase();
    return getAskMeContent(pageKey, params)
      .then((response: AxiosResponse<AskMeResponseDto>) => {
        if (language === 'de') {
          if (pageKey === 'balance-board' && params.userType === 'RECRUITER') {
            response.data.video = videoAskMeJSONRECRUITER.balanceBoard;
          } else if (pageKey === 'job-offers' && params.userType === 'RECRUITER') {
            response.data.video = videoAskMeJSONRECRUITER.jobOffers;
          } else if (pageKey === 'match' && params.userType === 'RECRUITER') {
            response.data.video = videoAskMeJSONRECRUITER.match;
          } else if (pageKey === 'chat' && params.userType === 'RECRUITER') {
            response.data.video = videoAskMeJSONRECRUITER.chat;
          } else if (pageKey === 'calendar' && params.userType === 'RECRUITER') {
            response.data.video = videoAskMeJSONRECRUITER.calendar;
          } else if (pageKey === 'favorites' && params.userType === 'RECRUITER') {
            response.data.video = videoAskMeJSONRECRUITER.favorites;
          } else if (pageKey === 'follow' && params.userType === 'RECRUITER') {
            response.data.video = videoAskMeJSONRECRUITER.follow;
          } else if (pageKey === 'jobs' && params.userType === 'CANDIDATE') {
            response.data.video = videoAskMeJSONCANDIDATE.jobs;
          } else if (pageKey === 'balance-board' && params.userType === 'CANDIDATE') {
            response.data.video = videoAskMeJSONCANDIDATE.balanceBoard;
          } else if (pageKey === 'match' && params.userType === 'CANDIDATE') {
            response.data.video = videoAskMeJSONCANDIDATE.match;
          } else if (pageKey === 'applications' && params.userType === 'CANDIDATE') {
            response.data.video = videoAskMeJSONCANDIDATE.applications;
          } else if (pageKey === 'chat' && params.userType === 'CANDIDATE') {
            response.data.video = videoAskMeJSONCANDIDATE.chat;
          } else if (pageKey === 'cv' && params.userType === 'CANDIDATE') {
            response.data.video = videoAskMeJSONCANDIDATE.cv;
          } else if (pageKey === 'favorites' && params.userType === 'CANDIDATE') {
            response.data.video = videoAskMeJSONCANDIDATE.favorites;
          } else if (pageKey === 'follow' && params.userType === 'CANDIDATE') {
            response.data.video = videoAskMeJSONCANDIDATE.follow;
          } else if (pageKey === 'posts' && params.userType === 'CANDIDATE') {
            response.data.video = videoAskMeJSONCANDIDATE.posts;
          }
        }
        dispatch(getAskMeSuccess(response.data));
      })
      .catch(() => dispatch(getAskMeFailure()));
  };
}
