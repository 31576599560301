import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { ListDto } from 'middleware/dto';

import { SkillsDto } from 'rest/skill/skills.dto';

import { getAllSkills } from 'services/portal/skills/skills.actions';
import { getAllSoftware } from 'services/portal/software/software.actions';
import { getAllLanguages } from 'services/portal/languages/languages.actions';
import { getAllDocuments } from 'services/portal/documents/documents.actions';
import {
  getCompanyJobOfferSkills,
  updateCompanyJobOfferSkills
} from 'services/portal/recruiter/job-offer/job-offer.actions';

import './skills-requirements.scss';

export type Props = {
  t: TransProps,
  prev: () => void,
  next: () => void,
  jobOfferId: string,
  skills: ListDto[],
  software: ListDto[],
  languages: ListDto[],
  documents: ListDto[],
  savedSkills: SkillsDto,
  new: boolean
};

type State = {
  skills: ListDto[],
  software: ListDto[],
  languages: ListDto[],
  documents: ListDto[],
  activeElements: number,
  elementsCountError: boolean,
  activeElementsError: boolean,
  isAddSkillsModalOpen: boolean,
  isAddSoftwareModalOpen: boolean,
  isAddLanguagesModalOpen: boolean,
  isAddDocumentsModalOpen: boolean,
  isSuggestNewSkillModalOpen: boolean
}

export class Settings extends Component<Props, State> {

  static defaultProps: Props = {
    prev: () => {},
    next: () => {},
    skills: [],
    software: [],
    languages: [],
    documents: [],
    new: false
  };

  state: State = {
    activeElements: 0,
    skills: this.props.skills,
    elementsCountError: false,
    activeElementsError: false,
    software: this.props.software,
    isAddSkillsModalOpen: false,
    isAddSoftwareModalOpen: false,
    isAddDocumentsModalOpen: false,
    isAddLanguagesModalOpen: false,
    languages: this.props.languages,
    documents: this.props.documents,
    isSuggestNewSkillModalOpen: false
  };

  maxCompetenciesLength: number = 10;

}

export const stateToProps = (state: GlobalState) => ({
  skills: state.portal.skills.skillsList,
  software: state.portal.software.softwareList,
  documents: state.portal.documents.documentsList,
  languages: state.portal.languages.languagesList,
  initialSkills: state.portal.recruiter.jobOffer.skills.content,
  jobTitleId: state.portal.recruiter.jobOffer.process.jobTitleId,
  currentJobOfferId: state.portal.recruiter.jobOffer.process.jobOffer.id,
  jobOffer: state.portal.recruiter.jobOffer.general.currentJobOffer
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getAllSkills,
  getAllSoftware,
  getAllLanguages,
  getAllDocuments,
  getCompanyJobOfferSkills,
  updateSkills: updateCompanyJobOfferSkills
}, dispatch);
