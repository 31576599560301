// @flow
import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import { Modal } from 'components/dialogs';
import { Card } from 'components/layouts';

import { CreateBalanceBoardModal } from 'containers/portal/candidate/balance-board';

export class BalanceBoardModalPage extends Component {

  render () {
    return (
      <Modal closePath="/portal/candidate/balance-board">
        <Card>
          <Route
            path="/portal/candidate/balance-board/:action"
            component={CreateBalanceBoardModal}/>
        </Card>
      </Modal>
    );
  }

}
