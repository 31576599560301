// @flow
import { Component } from 'react';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

type Props = {
  t: TransProps,
  jobOffers: number,
  onDashboard: true
}

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  jobOffers: state.portal.recruiter.account.jobOffer.totalElements
});
