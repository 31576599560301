import React from 'react';
import { Trans } from 'react-i18next';

const BetaIntroCandidateSteps = () => (
  <ol className="tester-steps-list">
    <li className="tester-steps-list-item">
      <Trans i18nKey="landing.beta-intro.step2.recruiter.step1"/>
    </li>
    <li className="tester-steps-list-item">
      <Trans i18nKey="landing.beta-intro.step2.recruiter.step2.lineStart"/>
      <span className="text-bold">
        &nbsp;<Trans i18nKey="landing.beta-intro.step2.recruiter.step2.lineHighlated"/>&nbsp;
      </span>
      <Trans i18nKey="landing.beta-intro.step2.recruiter.step2.lineEnd"/>
    </li>
    <li className="tester-steps-list-item">
      <Trans i18nKey="landing.beta-intro.step2.recruiter.step3.lineStart"/>
      <span className="text-bold">
        &nbsp;<Trans i18nKey="landing.beta-intro.step2.recruiter.step3.lineHighlated"/>&nbsp;
      </span>
      <Trans i18nKey="landing.beta-intro.step2.recruiter.step3.lineEnd"/>
    </li>
    <li className="tester-steps-list-item">
      <Trans i18nKey="landing.beta-intro.step2.recruiter.step4"/>
    </li>
  </ol>
);

export default BetaIntroCandidateSteps;
