// @flow
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Modal } from 'components/dialogs';
import { Card, SearchContainer } from 'components/layouts';
import { SidebarContainer, SidebarItem } from 'components/layouts/sidebar';

import { DefaultFilter, SkillsFilter } from 'containers/portal/recruiter/candidates/search';

@connectTranslation()
export class SearchCandidatesPage extends Component {

  render () {
    return (
      <Modal closePath="/portal/recruiter/candidates">
        <SearchContainer>
          <SidebarContainer closePath="/portal/recruiter/candidates">
            <SidebarItem
              path="/portal/recruiter/candidates/search"
              recruiter>
              <Trans i18nKey="portal.recruiter.candidates.filters.default.title">
                Default Filter
              </Trans>
            </SidebarItem>
            <SidebarItem
              path="/portal/recruiter/candidates/search/skills"
              recruiter>
              <Trans i18nKey="portal.recruiter.candidates.filters.skills.title">
                Skills Filter
              </Trans>
            </SidebarItem>
          </SidebarContainer>
          <Card>
            <div className="search-modal-content">
              <Switch>
                <Route
                  path="/portal/recruiter/candidates/search"
                  exact
                  component={DefaultFilter}/>
                <Route
                  path="/portal/recruiter/candidates/search/skills"
                  component={SkillsFilter}/>
              </Switch>
            </div>
          </Card>
        </SearchContainer>
      </Modal>
    );
  }

}
