// @flow
import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';
import { getData } from 'middleware/payment-step';

import { Tabs, Tab } from 'components/tabs';

import {
  Payment,
  TeamMembers,
  CompanyInfo,
  RecruiterInfo
} from 'containers/portal/recruiter/account/settings';

import type { Props } from './details.setting';
import { Settings, stateToProps, dispatchToProps } from './details.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class SettingsDetails extends Settings {

  componentDidMount () {
    this.setRedirectLink();
  }

  componentWillUnmount () {
    this.props.removeCandidatePreviewLink();
  }

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.updateRecruiterInfoSuccess !== this.props.updateRecruiterInfoSuccess) {
      this.setState({ activeTab: 1 });
    } else if (nextProps.updateCompanyInfoSuccess !== this.props.updateCompanyInfoSuccess &&
      this.state.activeTab === 1) {
      this.setState({ activeTab: 2 });
    } else if (nextProps.inviteTeamMembersSuccess !== this.props.inviteTeamMembersSuccess) {
      this.setState({ activeTab: 3 });
    }
  }

  setRedirectLink () {
    const candidatePreviewLink: string = localStorage.getItem('ej_candidate_preview_link');
    const paymentStepLink = getData('ej_payment_step_link');

    this.setState({
      redirectNextLink: candidatePreviewLink || '',
      activeTab: paymentStepLink ? paymentStepLink.activeTab : 0
    });
  }

  render () {
    return (
      <Fragment>
        {
          (this.props.currentUser && this.props.currentUser.fullyRegistered) &&
          <Redirect to={this.state.redirectLink || '/portal/recruiter/balance-board'}/>
        }
        <div className="setting-container">
          <h3 className="setting-caption">
            <Trans i18nKey="portal.recruiter.account.title">
              Glad to see you with us!
            </Trans>
          </h3>
          <p className="setting-description">
            <Trans i18nKey="portal.recruiter.account.description">
              Before using our service, fill out the information about you and your company.
            </Trans>
          </p>
          <Tabs
            activeTabChange={(activeTab: number) => this.setState({ activeTab })}
            activeTabIndex={this.state.activeTab}
            headClassName="linear-tabs">
            <Tab label={this.props.t('portal.recruiter.account.tabs.personal.title')}>
              <RecruiterInfo
                next={() => this.setState({ activeTab: this.state.activeTab + 1 })}
                deleteAccount={false}/>
            </Tab>
            <Tab
              disabled={!this.props.updateRecruiterInfoSuccess}
              label={this.props.t('portal.recruiter.account.tabs.company.title')}>
              <CompanyInfo prev={() => this.setState({ activeTab: this.state.activeTab - 1 })}/>
            </Tab>
            <Tab
              disabled={!this.props.updateCompanyInfoSuccess}
              label={this.props.t('portal.recruiter.account.tabs.team.title')}>
              <TeamMembers prev={() => this.setState({ activeTab: this.state.activeTab - 1 })}/>
            </Tab>
            <Tab
              disabled={!this.props.inviteTeamMembersSuccess}
              label={this.props.t('portal.recruiter.account.tabs.payment.title')}>
              <Payment prev={() => this.setState({ activeTab: this.state.activeTab - 1 })}
                match={this.props.match}/>
            </Tab>
          </Tabs>
        </div>
      </Fragment>
    );
  }

}
