// @flow
import React, { Fragment } from 'react';
import Sortable from 'react-sortablejs';

import { ImageResponseDto } from 'rest/images/images.response';

import { PreviewImageItem } from './preview-item/preview-item';

import { Settings } from './preview-image.setting';

export class PreviewImage extends Settings {

  static getDerivedStateFromProps (nextProps) {
    return {
      files: nextProps.files
    };
  }

  render () {
    return (
      <Fragment>
        {
          this.state.files.length > 0 && <div className="preview">
            <Sortable>
              {
                this.state.files.map((file: ImageResponseDto, index: number) => {
                  return (
                    <PreviewImageItem
                      finishImageLoad={this.props.imageLoad}
                      image={file}
                      key={file.imageId || index}
                      onClick={this.props.selectImage ? () => this.props.selectImage(file) : null}
                      closeIconClick={this.props.removePreviewPhoto.bind(this, index)}
                    />
                  );
                })
              }
            </Sortable>
          </div>
        }
      </Fragment>
    );
  }

}
