import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/ru';
import 'moment/locale/uk';
import 'moment/locale/es';
import * as moment from 'moment';

import { getLocalization } from 'middleware/localization';

export function initMomentSettings () {
  const language = getLocalization();
  moment.locale(language);
}
