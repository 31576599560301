import * as actions from './pdf.constants';

import { getCvPdfLink } from 'rest/candidates/cv/cv.rest';

import { CvDto } from 'rest/candidates/cv/cv.dto';

const getCvLinkSuccess = (payload: CvDto) => ({
  type: actions.GET_CV_PDF_LINK,
  payload
});

const getCvPreviewLinkSuccess = (payload: CvDto) => ({
  type: actions.GET_PREVIEW_CV_PDF_LINK,
  payload
});

function getCvLink (dispatch, response, preview: boolean) {
  if (preview) {
    dispatch(getCvPreviewLinkSuccess(response.data));
  } else {
    return dispatch(getCvLinkSuccess(response.data));
  }
}

export function getCandidateCvLink (candidateId: string, preview: boolean) {
  return dispatch => {
    return getCvPdfLink(candidateId, preview)
      .then(response => getCvLink(dispatch, response, preview));
  };
}
