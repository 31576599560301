// @flow
import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';

import moment from 'moment';
import { Trans } from 'react-i18next';

import { uploadFile } from 'middleware/upload-file';
import { connectStore } from 'middleware/connect';

import type { Props } from './account.setting';

import { Button } from 'components/buttons';
import { Spinner } from 'components/elements';
import { Avatar } from 'components/avatar/avatar';
import { DateControl } from 'components/controls';
import { FormGroup, InputControl, AutosuggestSelect, PhoneControl } from 'components/controls';

import { ImageDto } from 'rest/images/image.dto';
import { AddressDto } from 'rest/address/address.dto';
import { UserAddressDto } from 'rest/address-dto/address';
import { CandidateDto } from 'rest/candidates/candidate.dto';
import { CandidateSocialDto } from 'rest/candidates/candidate-social.dto';

import { Setting, stateToProps, dispatchToProps } from './account.setting';
import { setNameAndLastNameAndEmail } from '../../../../middleware/auth';

@connectStore(stateToProps, dispatchToProps, true)
export class CandidateAccountSetting extends Setting {

  componentDidMount () {
    this.props.changeCurrentLocalization(this.props.i18n.language, false)
      .then(() => {
        if (this.props.currentCandidate) {
          this.props.getCurrentCandidate();
        }
        if (!this.props.currentCandidate.id) {
          const candidateDetails: string = localStorage.getItem('socialDetails');
          this.props.getCurrentCandidateSuccess(JSON.parse(candidateDetails));
        }
        this.setApplyJobOfferLink();
        this.clearConfirmEmail();
      });
  }

  clearConfirmEmail () {
    localStorage.removeItem('emailToConfirm');
  }

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.error !== '') {
      this.props.history.push('/auth/candidate/sign-up');
    }
    if (nextProps.registerCandidateSuccess !== this.props.registerCandidateSuccess) {
      this.props.activateCurrentCandidate(this.props.currentCandidate.id);
    }
    if (nextProps.currentCandidate.email !== this.state.email) {
      this.setCurrentCandidate(nextProps);
    }
  }

  setCurrentCandidate (nextProps: Props) {
    this.setState({
      email: nextProps.currentCandidate.email,
      firstName: nextProps.currentCandidate.firstName,
      lastName: nextProps.currentCandidate.lastName,
      socialId: nextProps.currentCandidate.socialId,
      socialType: nextProps.currentCandidate.socialType
    });
  }

  setApplyJobOfferLink () {
    const jobOfferLink: string = localStorage.getItem('ej_active_job_offer_link');
    this.setState({ jobOfferLink: jobOfferLink || '' });
  }

  changeAvatarState (file: File) {
    uploadFile(file, (base64, error) => {
      if (error) {
        this.setState({ avatarError: error });
      } else {
        this.setState({
          avatarError: false,
          avatarPreview: base64
        }, () => this.props.uploadFile(file, 'avatar'));
      }
    });
  }

  clearImageState () {
    this.setState({
      candidatePhotos: [],
      avatarPreview: ''
    });
  }

  setCandidateAvatar (): ImageDto {
    const avatar: ImageDto = new ImageDto();
    avatar.primary = true;
    if (this.props.imageInfo && this.state.avatarPreview) {
      avatar.imageId = this.props.imageInfo.imageId;
    } else {
      return null;
    }
    return avatar;
  }

  registerFromSocial () {
    const photos: ImageDto = this.setCandidateAvatar();
    const candidate: CandidateSocialDto = new CandidateSocialDto(this.state);
    candidate.candidatePhotos = photos && [photos];
    candidate.candidateAddress = new UserAddressDto(this.state);
    this.props.registerFromSocial(candidate);
  }

  registerCandidate () {
    const photos: ImageDto = this.setCandidateAvatar();
    const candidate: CandidateDto = new CandidateDto(this.state);
    candidate.candidatePhotos = photos && [photos];
    candidate.candidateAddress = new UserAddressDto(this.state);
    this.props.registerCandidate(this.props.currentCandidate.id, candidate);
  }

  updatePersonalInfo (event: Event) {
    event.preventDefault();
    setNameAndLastNameAndEmail(this.props.currentCandidate.firstName,
      this.props.currentCandidate.lastName,
      this.props.currentCandidate.email);
    if (!this.props.avatarLoading) {
      if (this.state.socialType) {
        this.registerFromSocial();
      } else {
        this.registerCandidate();
      }
    }
  }

  suggestCountry (value: string) {
    const query: AddressDto = new AddressDto();
    query.city = '';
    query.street = '';
    query.country = value;
    this.props.searchSuggestedAddress('country', query);
  }

  suggestStreet (value: string) {
    const query: AddressDto = new AddressDto(this.state);
    query.street = value;
    this.props.searchSuggestedAddress('street', query);
  }

  suggestCity (value: string) {
    const query: AddressDto = new AddressDto(this.state);
    query.street = '';
    query.city = value;
    this.props.searchSuggestedAddress('city', query);
  }

  render () {
    return (
      <Fragment>
        {
          (this.props.redirect || this.props.currentCandidate.fullyRegistered) &&
          <Redirect to={this.state.jobOfferLink || '/portal/candidate/balance-board'}/>
        }
        <div className="setting-container after-registration-page">
          <div className="account-setting-logo">
            <img
              src="/images/shared/logo.svg"
              alt="Excellent Jobs"/>
          </div>
          <h3 className="setting-caption">
            <Trans i18nKey="portal.candidate.account.title">
              Glad to see you with us!
            </Trans>
          </h3>
          <p className="setting-description">
            <Trans i18nKey="portal.candidate.account.description">
              Before using our service, fill out the information about yourself.
            </Trans>
          </p>
          <div className="candidate-content">
            <Avatar
              error={!!this.state.avatarError}
              errorText={this.state.avatarError}
              loading={this.props.avatarLoading}
              change={(file: File) => this.changeAvatarState(file)}
              previewImageUrl={this.state.avatarPreview}
              clearImageState={this.clearImageState.bind(this)}
              uploadButtonType="primary"
              uploadButtonName={this.props.t('portal.candidate.account.avatar')}/>
            <form
              onSubmit={this.updatePersonalInfo.bind(this)}
              className="personal-info-form">
              <FormGroup className="half-width">
                <InputControl
                  maxLength={35}
                  label={this.props.t('portal.candidate.account.first-name.label')}
                  value={this.state.firstName}
                  change={(firstName: string): void => this.setState({ firstName })}
                  placeholder={this.props.t('portal.candidate.account.first-name.placeholder')}
                  required
                  errorText={this.props.t('portal.candidate.account.first-name.error')}
                  type="text"/>
              </FormGroup>
              <FormGroup className="half-width">
                <InputControl
                  maxLength={35}
                  label={this.props.t('portal.candidate.account.last-name.label')}
                  value={this.state.lastName}
                  change={(lastName: string): void => this.setState({ lastName })}
                  placeholder={this.props.t('portal.candidate.account.last-name.placeholder')}
                  required
                  errorText={this.props.t('portal.candidate.account.last-name.error')}
                  type="text"/>
              </FormGroup>
              <FormGroup className="half-width">
                <InputControl
                  required
                  readonly={this.state.email}
                  disabled={this.state.email}
                  label={this.props.t('portal.candidate.account.email.label')}
                  title="some-email@gmail.com"
                  value={this.state.email}
                  change={(email: string): void => this.setState({ email })}
                  placeholder={this.props.t('portal.candidate.account.email.placeholder')}
                  errorText={this.props.t('portal.candidate.account.email.error')}
                  type="email"/>
              </FormGroup>
              <FormGroup className="half-width">
                <PhoneControl
                  required={false}
                  label={this.props.t('portal.candidate.account.phone')}
                  defaultValue={this.state.phoneNumber}
                  change={(phoneNumber: string) => this.setState({ phoneNumber })}/>
              </FormGroup>
              <FormGroup className="half-width birthday">
                <DateControl
                  minDate={moment().subtract(100, 'year').toDate()}
                  maxDate={moment().subtract(18, 'year').toDate()}
                  dateFormat="yyyy/MMM/dd"
                  value={this.state.birthday}
                  change={(birthday: string): void => this.setState({ birthday })}
                  required
                  label={this.props.t('portal.candidate.account.date')}
                  monthsCount={1}/>
              </FormGroup>
              <hr className="divider"/>
              <FormGroup className="full-width zip-code">
                <InputControl
                  required
                  label={this.props.t('portal.candidate.account.zip.label')}
                  value={this.state.zipCode}
                  change={(zipCode: string): void => this.setState({ zipCode })}
                  placeholder={this.props.t('portal.candidate.account.zip.placeholder')}
                  errorText={this.props.t('portal.candidate.account.zip.error')}
                  type="text"/>
              </FormGroup>
              <FormGroup className="half-width">
                <AutosuggestSelect
                  required
                  label={this.props.t('portal.candidate.account.country.label')}
                  errorText={this.props.t('portal.candidate.account.country.error')}
                  items={this.props.address.country}
                  defaultValue={this.state.country}
                  change={(value: string) => this.setState({ country: value })}
                  search={(value: string) => this.suggestCountry(value)}
                  placeholder={this.props.t('portal.candidate.account.country.placeholder')}/>
              </FormGroup>
              <FormGroup className="half-width">
                <AutosuggestSelect
                  required
                  label={this.props.t('portal.candidate.account.city.label')}
                  errorText={this.props.t('portal.candidate.account.city.error')}
                  items={this.props.address.city}
                  defaultValue={this.state.city}
                  change={(value: string) => this.setState({ city: value })}
                  search={(value: string) => this.suggestCity(value)}
                  placeholder={this.props.t('portal.candidate.account.city.placeholder')}/>
              </FormGroup>
              <FormGroup className="half-width">
                <AutosuggestSelect
                  required
                  label={this.props.t('portal.candidate.account.street.label')}
                  defaultValue={this.state.street}
                  items={this.props.address.street}
                  change={(value: string) => this.setState({ street: value })}
                  search={(value: string) => this.suggestStreet(value)}
                  placeholder={this.props.t('portal.candidate.account.street.placeholder')}/>
                <p className="restriction-info">
                  <Trans i18nKey="portal.candidate.account.street.note">
                    We will not show employers your address, only the distance to the potential job
                  </Trans>
                </p>
              </FormGroup>
              <FormGroup className="half-width streetNumber">
                <InputControl
                  required
                  label={this.props.t('portal.candidate.account.building.label')}
                  value={this.state.streetNumber}
                  change={(streetNumber: string): void => this.setState({ streetNumber })}
                  placeholder={this.props.t('portal.candidate.account.building.placeholder')}
                  errorText={this.props.t('portal.candidate.account.building.error')}
                  type="text"/>
              </FormGroup>
              <p className="mobile-restriction-info restriction-info">
                <Trans i18nKey="portal.candidate.profile.user.street.note">
                  We will not show employers your address, only the distance to the potential job
                </Trans>
              </p>
              <FormGroup className="full-width form-btn-submit">
                <Button
                  medium
                  candidate
                  disabled={this.props.avatarLoading}
                  type="submit">
                  <Trans i18nKey="portal.candidate.account.buttons.save">
                    Save
                  </Trans>
                </Button>
              </FormGroup>
            </form>
            <Spinner show={this.props.fetching}/>
          </div>
        </div>
      </Fragment>
    );
  }

}
