export const SEARCH_JOBS_SKILLS = 'SEARCH_JOBS_SKILLS';
export const SEARCH_JOBS_BRANCH = 'SEARCH_JOBS_BRANCH';
export const SEARCH_JOBS_UNIT = 'SEARCH_JOBS_UNIT';
export const SEARCH_JOBS_JOB_TITLE = 'SEARCH_JOBS_JOB_TITLE';
export const SEARCH_JOBS_LOCATION = 'SEARCH_JOBS_LOCATION';
export const SEARCH_JOBS_COMPANY_NAME = 'SEARCH_JOBS_COMPANY_NAME';
export const SEARCH_JOBS_SALARY_MIN = 'SEARCH_JOBS_SALARY_MIN';
export const SEARCH_JOBS_SALARY_MAX = 'SEARCH_JOBS_SALARY_MAX';
export const SEARCH_JOBS_WORKING_TIME = 'SEARCH_JOBS_WORKING_TIME';
export const SEARCH_JOBS_PERIOD_OF_PUBLISH = 'SEARCH_JOBS_PERIOD_OF_PUBLISH';
export const SEARCH_JOBS_AVAILABLE_FROM = 'SEARCH_JOBS_AVAILABLE_FROM';
export const SEARCH_JOBS_JOB_TYPE = 'SEARCH_JOBS_JOB_TYPE';
export const SEARCH_JOBS_CLOSE_TO_ME = 'SEARCH_JOBS_CLOSE_TO_ME';
export const SEARCH_JOBS_RESET = 'SEARCH_JOBS_RESET';
export const SEARCH_JOBS_SKILLS_RESET = 'SEARCH_JOBS_SKILLS_RESET';
