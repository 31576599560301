import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { ListDto } from 'middleware/dto/list';
import { PaginationDto } from 'middleware/dto';

import { getData } from 'middleware/job-offer-storage';

import { getAllSkills } from 'services/portal/skills/skills.actions';
import { getAllSoftware } from 'services/portal/software/software.actions';
import { getAllLanguages } from 'services/portal/languages/languages.actions';
import { getAllDocuments } from 'services/portal/documents/documents.actions';

import './skills-requirements.scss';

export type Props = {
  t?: TransProps,
  fetching: boolean,
  prev: () => void,
  next: () => void,
  skills: ListDto[],
  software: ListDto[],
  languages: ListDto[],
  documents: ListDto[],
  getAllSkills: (params: PaginationDto) => void,
  getAllSoftware: (params: PaginationDto) => void,
  getAllLanguages: (params: PaginationDto) => void,
  getAllDocuments: (params: PaginationDto) => void
};

type State = {
  skills: ListDto[],
  software: ListDto[],
  languages: ListDto[],
  documents: ListDto[],
  jobTitleId: string,
  activeElements: number,
  elementsCountError: boolean,
  activeElementsError: boolean,
  isAddSkillsModalOpen: boolean,
  isAddSoftwareModalOpen: boolean,
  isAddLanguagesModalOpen: boolean,
  isAddDocumentsModalOpen: boolean,
  isSuggestNewSkillModalOpen: boolean
}

export class Settings extends Component<Props, State> {

  static defaultProps: Props = {
    fetching: false,
    prev: () => {},
    next: () => {},
    skills: [],
    software: [],
    languages: [],
    documents: [],
    getAllSkills: () => {},
    getAllSoftware: () => {},
    getAllLanguages: () => {},
    getAllDocuments: () => {}
  };

  state: State = {
    activeElements: 0,
    skills: this.props.skills,
    elementsCountError: false,
    activeElementsError: false,
    software: this.props.software,
    isAddSkillsModalOpen: false,
    isAddSoftwareModalOpen: false,
    isAddDocumentsModalOpen: false,
    isAddLanguagesModalOpen: false,
    languages: this.props.languages,
    documents: this.props.documents,
    isSuggestNewSkillModalOpen: false,
    jobTitleId: getData('ej_general_job_offer').jobTitleId
  };

  maxCompetenciesLength: number = 10;

}

export const stateToProps = (state: GlobalState) => ({
  skills: state.portal.skills.skillsList,
  software: state.portal.software.softwareList,
  documents: state.portal.documents.documentsList,
  languages: state.portal.languages.languagesList
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getAllSkills,
  getAllSoftware,
  getAllLanguages,
  getAllDocuments
}, dispatch);
