import { Component } from 'react';

import './tab-header.scss';

type Props = {
  title: string,
  subTitle?: string
};

export class Settings extends Component<Props> {

  static defaultProps = {
    title: '',
    subTitle: ''
  };

}
