/* eslint padded-blocks: ["error", "never"] */
/* eslint object-curly-spacing: ["off"] */
/* eslint no-unused-vars: ["off"] */
import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import '../styles/semantic.min.css';
import '../styles/styles.css';
import { getUserId } from 'middleware/auth';

export class GDPRCandidateApplicationTemplate extends Component {
  constructor() {
    super();
    this.state = {
      candidate_id: getUserId(),
      templates: []
    };
  }
  componentDidMount() {
    axios(`candidates/${this.state.candidate_id}/applications-template?size=100`)
      .then(({data}) => {
        this.setState({
          templates: data ? (data.content || []) : []
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  render () {
    return (
      <div className="gdpr-main">
        <div className="ui container main-content">
          <h1 className="ui header main-title"> Candidate`s application template </h1>
          <a href="/information/gdpr">
            <button className="ui button back-link">Back To Main</button>
          </a>
          <h5 className="ui header"> Total Elements:  {(this.state.templates || []).length}</h5>
          <table className="ui single blue line table">
            <thead>
              <tr>
                <th> Subject </th>
                <th> Body </th>
                <th> Created Date </th>
                <th> Type </th>
              </tr>
            </thead>
            <tbody>
              {this.state.templates.map(row => (
                <tr key={row.id}>
                  <td>{row.subject}</td>
                  <td>{row.body}</td>
                  <td>{moment(row.date).format('DD.MM.YYYY HH:mm')}</td>
                  <td>{row.templateType}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
