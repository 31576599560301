// @flow
import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';

import { StatisticsPoster } from 'containers/portal/recruiter/statistics';

export class StatisticsPage extends Component {

  render () {
    return (
      <Fragment>
        <Route
          path="/portal/recruiter/statistics"
          component={StatisticsPoster}/>
      </Fragment>
    );
  }

}
