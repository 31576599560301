// @flow
import React from 'react';

import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';

import { connectStore } from 'middleware/connect';

import { Button } from 'components/buttons';

import { Modal, ModalCaption } from 'containers/portal/modal-container';

import { Setting, stateToProps, dispatchToProps } from './delete-modal.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class DeleteJobOfferModal extends Setting {

  deleteJobOffer (event: Event) {
    this.props.deleteCompanyJobOffer(this.props.companyId, this.props.jobOfferId);
    this.props.hideModal(event);
    this.props.submitAction();
    toast.success(this.props.t('portal.recruiter.notifications.job-offer-deleted'));
  }

  render () {
    return (
      <Modal
        overlay
        animation="fade"
        open={this.props.open}
        hideModalAvailable={false}
        className="delete-job-offer-modal"
        hideModal={() => this.props.hideModal()}
        closeOnOverlay={this.props.hideModal}>
        <ModalCaption
          hideModal={event => this.props.hideModal(event)}
          title={this.props.t('portal.recruiter.job-offers.list.delete.title')}/>
        <div className="invites-content">
          <p className="confirm-delete">
            <Trans i18nKey="portal.recruiter.job-offers.list.delete.description">
              Are you sure you want to delete this job offer?
            </Trans>
          </p>
          <Button
            recruiter
            onClick={this.deleteJobOffer.bind(this)}>
            <Trans i18nKey="portal.recruiter.job-offers.list.delete.buttons.yes">
              Yes
            </Trans>
          </Button>
          <Button
            onClick={this.props.hideModal}
            grey>
            <Trans i18nKey="portal.recruiter.job-offers.list.delete.buttons.no">
              No
            </Trans>
          </Button>
        </div>
      </Modal>
    );
  }

}
