export class InteractSkillDto {

  name: string;
  skillId: string;
  required: boolean;
  skillLevel: string;
  candidateSkillLevel: string;

  constructor (data?: InteractSkillDto) {
    if (data) {
      this.name = data.name;
      this.skillId = data.skillId;
      this.required = data.required;
      this.skillLevel = data.skillLevel;
      this.candidateSkillLevel = data.candidateSkillLevel;
    }
  }

}
