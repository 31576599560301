// @flow
import React from 'react';
import { Link } from 'react-router-dom';

import cn from 'classnames';

import { Setting } from './link-item.setting';

export class StatisticsLinkItem extends Setting {

  render () {
    const className = cn({
      'accent': this.props.recruiter,
      'primary': this.props.candidate,
      'statistics-item': true
    });

    return (
      <div className="statistics-link-container">
        <Link to={this.props.to}>
          <div className={className}>
            <div className="left-side">
              <div className="title">{this.props.title}</div>
              {
                this.props.description && <div className="description">{this.props.description}</div>
              }
            </div>
            <div className="right-side">
              <div className="value">{this.props.value}</div>
            </div>
          </div>
        </Link>
      </div>
    );
  }

}
