// @flow
import React from 'react';

import { connectStore } from 'middleware/connect';

import { CounterWidget } from 'components/widgets';

import { Settings, stateToProps } from './following.setting';

@connectStore(stateToProps, null, true)
export class FollowingCircle extends Settings {

  render () {
    return (
      <CounterWidget
        title={this.props.t('portal.candidate.follows.circle.title')}
        value={this.props.total}
        circleTitle={this.props.t('portal.candidate.follows.circle.value')}/>
    );
  }

}
