// @flow
import React from 'react';

import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';

import { connectStore } from 'middleware/connect';

import { Button } from 'components/buttons';
import { FormGroup, InputControl } from 'components/controls';

import { Modal, ModalCaption } from 'containers/portal/modal-container';

import type { Props } from './invite-modal.setting';
import { Setting, stateToProps, dispatchToProps } from './invite-modal.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class InviteRecruiterModal extends Setting {

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.inviteTeamMembersSuccess !== this.state.inviteTeamMembersSuccess && this.props.open) {
      this.getUpdatedTeamMembers();
      this.closeModal();
    }
    if (nextProps.errorText !== this.state.errorText && this.props.open) {
      this.setState({ errorText: nextProps.errorText });
    }
  }

  getUpdatedTeamMembers () {
    if (this.props.currentUser) {
      this.props.getRecruiterTeamMembers(this.props.currentUser.companyId);
    }
  }

  changeInvitesState (value: string, isError: boolean = false) {
    this.setState({
      email: value,
      errorText: ''
    });
    this.errorCollector(isError);
  }

  errorCollector (collect: boolean) {
    const errorCollection: Set<string> = new Set(this.state.errorCollection);
    collect ? errorCollection.add('email') : errorCollection.delete('email');
    this.setState({ errorCollection });
  }

  clearState () {
    this.setState({
      email: '',
      errorText: '',
      inviteTeamMembersSuccess: false
    });
    this.errorCollector(false);
  }

  closeModal () {
    this.clearState();
    this.props.hideModal();
  }

  sendInvitation (event: Event) {
    event.preventDefault();
    if (!this.state.errorCollection.size) {
      this.props.inviteRecruiterTeamMembers(this.props.currentUser.companyId, [{ email: this.state.email }]);
      toast.success(this.props.t('portal.recruiter.notifications.recruiter-invite-send'));
    }
  }

  render () {
    return (
      <Modal
        overlay
        animation="fade"
        open={this.props.open}
        className="invites-modal"
        hideModalAvailable={false}
        hideModal={() => this.closeModal()}
        closeOnOverlay={() => this.closeModal()}>
        <ModalCaption
          hideModal={() => this.closeModal()}
          title={this.props.t('portal.recruiter.profile.team.invite.title')}/>
        <div className="invites-content">
          <p className="invites-description">
            <Trans i18nKey="portal.recruiter.profile.team.invite.description">
              Invite team members you’de like to explore our service with:
            </Trans>
          </p>
          <form className="invites-modal-form">
            <FormGroup>
              <InputControl
                required
                error={this.state.errorText}
                value={this.state.email}
                change={(value: string, error: boolean): void => this.changeInvitesState(value, error)}
                label={this.props.t('portal.recruiter.profile.team.invite.email.label')}
                placeholder={this.props.t('portal.recruiter.profile.team.invite.email.placeholder')}
                errorText={this.state.errorText || this.props.t('portal.recruiter.profile.team.invite.email.error')}
                type="email"/>
            </FormGroup>
            <FormGroup className="invites-action">
              <Button
                type="submit"
                onClick={this.sendInvitation.bind(this)}
                className="btn accent">
                <Trans i18nKey="portal.recruiter.profile.team.invite.button">
                  Send Invitation
                </Trans>
              </Button>
            </FormGroup>
          </form>
        </div>
      </Modal>
    );
  }

}
