// @flow
import React from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { TabLink, Tabs } from 'components/tabs';
import { StatisticsItem } from 'components/widgets/statistics';

import { StatsDto } from 'rest/stats/stats.dto';

import { Settings, stateToProps, dispatchToProps } from './applications.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class ApplicationsSidebar extends Settings {

  componentDidMount () {
    this.loadFavoritesStatistic(this.props.currentUserId);
  }

  componentWillUpdate (props) {
    if (!this.props.currentUserId) {
      this.loadFavoritesStatistic(props.currentUserId);
    }
  }

  componentWillUnmount () {
    this.props.resetApplicationsStatistics();
  }

  loadFavoritesStatistic (candidateId: string) {
    if (candidateId) {
      this.props.getApplicationsStatistics(candidateId);
      this.props.getSentApplicationsStatistics(candidateId);
    }
  }

  render () {
    return (
      <div className="mobile-application-tabs">
        <Tabs candidate>
          <TabLink
            link="/portal/candidate/applications/templates"
            label={this.props.t('portal.candidate.applications.sidebar.tabs.templates.title')}
            counter={this.props.templatesStats.total}
            onClick={() => this.props.changeTemplatesFilter()}>
            <PerfectScrollbar className="statistics-items">
              <StatisticsItem
                title={this.props.t('portal.candidate.applications.sidebar.tabs.templates.all')}
                value={this.props.templatesStats.total}
                active={!this.props.status}
                onClick={() => this.props.changeTemplatesFilter()}
                candidate/>
              {
                this.props.templatesStats.sentTemplateJobCount.map((item: StatsDto, index: number) => (
                  <StatisticsItem
                    key={index}
                    title={item.name === 'EJ'
                      ? this.props.t('portal.candidate.applications.sidebar.tabs.templates.ej')
                      : this.props.t('portal.candidate.applications.sidebar.tabs.templates.custom')}
                    value={item.count}
                    active={this.props.status === item.name}
                    onClick={() => this.props.changeTemplatesFilter(item.name)}
                    candidate/>
                ))
              }
            </PerfectScrollbar>
          </TabLink>
          <TabLink
            link="/portal/candidate/applications/sent"
            label={this.props.t('portal.candidate.applications.sidebar.tabs.letters.title')}
            counter={this.props.sentApplicationsStats.total}
            onClick={() => this.props.changeSentTemplatesFilter()}>
            <PerfectScrollbar className="statistics-items">
              <StatisticsItem
                title={this.props.t('portal.candidate.applications.sidebar.tabs.letters.all')}
                value={this.props.sentApplicationsStats.total}
                active={!this.props.jobTitleId}
                onClick={() => this.props.changeSentTemplatesFilter()}
                candidate/>
              {
                this.props.sentApplicationsStats.sentTemplateJobCount.map((item: StatsDto, index: number) => (
                  <StatisticsItem
                    key={index}
                    title={item.name}
                    value={item.count}
                    active={this.props.jobTitleId === item.id}
                    onClick={() => this.props.changeSentTemplatesFilter(item.id)}
                    candidate/>
                ))
              }
            </PerfectScrollbar>
          </TabLink>
        </Tabs>
      </div>
    );
  }

}
