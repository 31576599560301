import React, { Fragment, Component } from 'react';
import { Route } from 'react-router-dom';

import { PreviewBalanceBoardModal } from 'containers/portal/candidate/balance-board';

import { BalanceBoardListPage } from './list';
import { BalanceBoardModalPage } from './modal';

export class CandidateBalanceBoardPage extends Component {

  render () {
    return (
      <Fragment>
        <Route
          path="/portal/candidate/balance-board"
          component={BalanceBoardListPage}/>
        <Route
          exact
          path="/portal/candidate/balance-board/:action(create|edit)"
          component={BalanceBoardModalPage}/>
        <Route
          path="/portal/candidate/balance-board/:candidateId/preview"
          component={PreviewBalanceBoardModal}/>
      </Fragment>
    );
  }

}
