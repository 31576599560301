// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { PaginationDto } from 'middleware/dto/pagination';
import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';

import {
  StatsMatchedCandidatesDto
} from 'rest/companies/job-offers-matches/matched-candidates/stats/stats-candidates.dto';

import {
  getNewMatchedCandidatesStatistic,
  resetNewMatchedCandidatesStats
} from 'services/portal/recruiter/match/new-candidates/new-candidates.actions';

import './match.scss';

type Props = {
  t: TransProps,
  total: number,
  stats: StatsMatchedCandidatesDto[],
  history: History,
  currentUser: CurrentCandidateDto,
  currentPage: number,
  hasMoreItems: boolean,
  resetNewMatchedCandidatesStats: () => void,
  getNewMatchedCandidatesStatistic: (companyId: string, params?: PaginationDto) => void
}

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  total: state.portal.recruiter.match.jobOffersStats.matchedNewCandidatesCount,
  stats: state.portal.recruiter.match.jobOfferNewCandidates.content,
  currentUser: state.portal.recruiter.account.current.currentUser,
  currentPage: state.portal.recruiter.match.jobOfferNewCandidates.currentPage,
  hasMoreItems: state.portal.recruiter.match.jobOfferNewCandidates.hasMoreItems
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetNewMatchedCandidatesStats,
  getNewMatchedCandidatesStatistic
}, dispatch);
