// @flow
import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';

import { ProfilePage } from './profile';
import { CandidatesListPage } from './list';

export class CandidatesPage extends Component {

  render () {
    return (
      <Fragment>
        <Route
          path="/portal/guest/candidates"
          component={CandidatesListPage}/>
        <Route
          path="/portal/guest/candidates/:id/profile"
          component={ProfilePage}/>
      </Fragment>
    );
  }

}
