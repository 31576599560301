import React from 'react';

import { Setting } from './body.setting';

export class AuthBody extends Setting {

  render () {
    return (
      <div className="auth-form-body">
        <h2 className="auth-form-title">{ this.props.title }</h2>
        { this.props.children }
      </div>
    );
  }

}
