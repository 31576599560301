// @flow
import { Component } from 'react';
import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { ApplicationResponseDto } from 'rest/candidates/applications-template/applications-template.response.dto';
import { SentApplicationsStatsDto } from 'rest/candidates/applications/applications.stats.dto';

import {
  getApplicationsStatistics,
  resetApplicationsStatistics,
  getSentApplicationsStatistics
} from 'services/portal/candidate/applications/stats/stats.actions';

import { changeTemplatesFilter } from 'services/portal/candidate/applications/templates/templates.actions';

import {
  changeSentTemplatesFilter
} from 'services/portal/candidate/applications/sent-templates/sent-templates.actions';

import './application.responsive.scss';

export type Props = {
  t?: TransProps,
  status: string,
  templates: ApplicationResponseDto[],
  jobTitleId: string,
  currentUserId: string,
  templatesStats: SentApplicationsStatsDto,
  sentApplicationsStats: SentApplicationsStatsDto,
  changeTemplatesFilter: (jobTitleId?: string) => void,
  changeSentTemplatesFilter: (status?: string) => void,
  getApplicationsStatistics: (candidateId?: string) => void,
  resetApplicationsStatistics: () => void,
  getSentApplicationsStatistics: (candidateId?: string) => void
};

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  status: state.portal.candidate.applications.templates.status,
  templates: state.portal.candidate.applications.templates.content,
  jobTitleId: state.portal.candidate.applications.sentTemplates.jobTitleId,
  currentUserId: state.portal.candidate.account.current.candidateData.id,
  templatesStats: state.portal.candidate.applications.stats.templates,
  sentApplicationsStats: state.portal.candidate.applications.stats.sentApplications
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  changeTemplatesFilter,
  getApplicationsStatistics,
  changeSentTemplatesFilter,
  resetApplicationsStatistics,
  getSentApplicationsStatistics
}, dispatch);
