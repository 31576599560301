import * as actions from './job-titles.constants';

import { ListDto } from 'middleware/dto/list';
import { JobTitleDto } from 'rest/job-title/job-title.dto';

import { getJobTitles } from 'rest/job-title/job-title.rest';

const getJobTitlesRequest = () => ({
  type: actions.GET_JOB_TITLES_REQUEST
});

const getJobTitlesSuccess = (jobTitles: ListDto[]) => ({
  type: actions.GET_JOB_TITLES_SUCCESS,
  jobTitles
});

const getJobTitlesFailure = (error: string) => ({
  type: actions.GET_JOB_TITLES_FAILURE,
  error
});

export const getAllJobTitles = (params: JobTitleDto) => {
  return dispatch => {
    dispatch(getJobTitlesRequest());
    return getJobTitles(params)
      .then(response => dispatch(getJobTitlesSuccess(response.data.content)))
      .catch(error => dispatch(getJobTitlesFailure(error)));
  };
};
