// @flow
import React from 'react';

import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { Modal } from 'components/dialogs';
import { Tab, Tabs } from 'components/tabs';
import { Chips } from 'components/layouts';
import { DateTime, SkillsList, ShowSalaryRange } from 'components/elements';
import { Button, IconButton, LinkButton } from 'components/buttons';
import { PhotoGallery } from 'components/photo-gallery/photo-gallery';
import { ModalMainColumn, ModalAsideColumn } from 'components/layouts/modal';

import {
  DeleteJobOfferModal
} from 'containers/portal/recruiter/job-offer/list/job-offer-row/delete-modal/delete-modal';

import { SkillsDto } from 'rest/skill/skills.dto';
import { CompanyDto } from 'rest/companies/companies.dto';
import { JobOfferStatus } from 'rest/job-offers/job-offers.status.dto';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

import { stateToProps, dispatchToProps, Settings } from './edit-job-offer-preview.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class EditJobOfferModalPreview extends Settings {

  componentDidMount () {
    this.props.getCompanyJobOfferById(this.props.match.params.jobOfferId);
    this.props.getCompanyJobOfferSkills(this.props.match.params.jobOfferId);
  }

  changeJobOfferStatus (status: string) {
    if (status !== this.props.jobOffer.jobOfferStatus) {
      const jobOfferStatus = new JobOfferStatus();
      jobOfferStatus.status = status;
      this.props.updateJobOfferStatus(this.props.companyId, this.props.jobOffer.id, jobOfferStatus);
      toast.success(
        `${this.props.t('portal.recruiter.notifications.job-offer-status-updated')} "${status.toLowerCase()}"`
      );
    }
  }

  render () {
    const company: CompanyDto = this.props.company;
    const jobOffer: JobOffersResponseDto = this.props.jobOffer;
    const jobOfferSkills: SkillsDto = this.props.jobOfferSkills;
    return (
      <Modal closePath={`/portal/recruiter/${this.props.match.params.path || 'job-offers'}`}>
        <div className="preview-job-offer-modal">
          <PerfectScrollbar className="content">
            <div className="job-offer-modal">
              <div className="modal-columns-container">
                <div className="back-btn-container">
                  <LinkButton
                    inline
                    link="/portal/recruiter/job-offers">
                    <Trans i18nKey="portal.recruiter.balance-board.preview.back">
                      Back to job offer list
                    </Trans>
                  </LinkButton>
                </div>
                <div className="modal-columns">
                  {
                    jobOffer && <ModalMainColumn>
                      <h4 className="job-title">{jobOffer.jobTitleName}</h4>
                      <div className="additional-info">
                        <div className="branches">
                          <Chips>{jobOffer.branchName && jobOffer.branchName}</Chips>
                          {
                            jobOffer.published && <Chips>
                              <Trans i18nKey="portal.recruiter.balance-board.preview.published">
                                Published
                              </Trans>
                              &nbsp;
                              <DateTime value={jobOffer.publishedDate}/>
                            </Chips>
                          }
                          <Chips color="accent">{jobOffer.matchedPoints} %</Chips>
                        </div>
                      </div>
                      <ul className="job-summary-info">
                        {
                          jobOffer.referenceNumber && <li>
                            <b>
                              <Trans i18nKey="portal.recruiter.balance-board.preview.reference">
                                Reference Number:
                              </Trans>
                            </b>
                            <span>{jobOffer.referenceNumber}</span>
                          </li>
                        }
                        <li>
                          <b>
                            <Trans i18nKey="portal.recruiter.balance-board.preview.working-time">
                              Working time:
                            </Trans>
                          </b>
                          {
                            (jobOffer.salaryRange && jobOffer.salaryRange.workingHoursPerMonth)
                              ? <span>
                                {jobOffer.salaryRange.workingHoursPerMonth}
                                &nbsp;
                                <Trans i18nKey="portal.recruiter.balance-board.preview.time">
                                  hours/month
                                </Trans>
                              </span>
                              : <span>
                                <Trans i18nKey="portal.recruiter.balance-board.preview.no-information">
                                  No information
                                </Trans>
                              </span>
                          }
                        </li>
                        <li>
                          <b>
                            <Trans i18nKey="portal.recruiter.balance-board.preview.job-type">
                              Job type:
                            </Trans>
                          </b>
                          <span>
                            {
                              jobOffer.jobType === 'Employee'
                                ? <Trans i18nKey="portal.recruiter.job-offers.list.job-type.full-time">
                                  Full-Time
                                </Trans>
                                : jobOffer.jobType === 'Temporary'
                                  ? <Trans i18nKey="portal.recruiter.job-offers.list.job-type.temporary">
                                    Temporary
                                  </Trans> : <Trans i18nKey="portal.recruiter.job-offers.list.job-type.trainee">
                                    Trainee
                                  </Trans>
                            }
                          </span>
                        </li>
                        <li>
                          <b>
                            <Trans i18nKey="portal.recruiter.balance-board.preview.salary.title">
                              Salary range:
                            </Trans>
                          </b>
                          <ShowSalaryRange salaryRange={jobOffer.salaryRange}/>
                        </li>
                        <li>
                          <b>
                            <Trans i18nKey="portal.recruiter.balance-board.preview.available-from">
                              Available from:
                            </Trans>
                          </b>
                          {jobOffer.availableFrom
                            ? <span><DateTime value={jobOffer.availableFrom}/></span>
                            : <span>
                              <Trans i18nKey="portal.recruiter.balance-board.preview.no-information">
                                No information
                              </Trans>
                            </span>}
                        </li>
                      </ul>
                      <div className="full-info-container">
                        <Tabs>
                          <Tab label={this.props.t('portal.recruiter.balance-board.preview.tabs.competencies')}>
                            <div className="competencies-wrapper">
                              {
                                jobOfferSkills && <SkillsList
                                  recruiter
                                  skills={jobOfferSkills}/>
                              }
                            </div>
                          </Tab>
                          <Tab label={this.props.t('portal.recruiter.balance-board.preview.tabs.description')}>
                            <div>{jobOffer.jobDescription}</div>
                          </Tab>
                        </Tabs>
                      </div>
                    </ModalMainColumn>
                  }
                  {
                    company && <ModalAsideColumn>
                      <div className="actions-buttons edit-actions">
                        <Button
                          recruiter
                          fullWidth
                          className="full-width"
                          onClick={() =>
                            this.props.history.push(`/portal/recruiter/job-offers/${jobOffer.id}/edit`)}>
                          <Trans i18nKey="portal.recruiter.balance-board.preview.buttons.edit">
                            Edit
                          </Trans>
                        </Button>
                        <IconButton
                          outlined
                          recruiter
                          title={<span>
                            <Trans i18nKey="portal.recruiter.balance-board.preview.buttons.delete">
                              Delete Job Offer
                            </Trans>
                          </span>}
                          onClick={() => this.setState({ showDeleteModal: true })}
                          icon="accent-delete"/>
                      </div>
                      <div className="job-address">
                        <address className="address">
                          <h4 className="name">{company.name}</h4>
                          <div>{company.address.street} {company.address.streetNumber}, {company.address.zipCode}</div>
                          <div>{company.address.city}, {company.address.country}</div>
                        </address>
                      </div>
                      <PhotoGallery photos={company.photos}/>
                      {
                        company.description ? <div className="job-employer-description">
                          <h4 className="title">
                            <Trans i18nKey="portal.recruiter.balance-board.preview.description">
                              Description of business
                            </Trans>
                          </h4>
                          <p className="description">{company.description}</p>
                        </div> : ''
                      }
                      <div className="actions">
                        <Button
                          medium
                          outlined
                          onClick={() => this.changeJobOfferStatus('DRAFT')}
                          className="draft-btn"
                          recruiter>
                          <Trans i18nKey="portal.recruiter.balance-board.preview.buttons.draft">
                            Mark as Draft
                          </Trans>
                        </Button>
                        <Button
                          onClick={() => this.changeJobOfferStatus('ARCHIVED')}
                          className="archive-btn"
                          outlined
                          recruiter>
                          <Trans i18nKey="portal.recruiter.balance-board.preview.buttons.archive">
                            Archive
                          </Trans>
                        </Button>
                      </div>
                    </ModalAsideColumn>
                  }
                </div>
              </div>
            </div>
            <DeleteJobOfferModal
              jobOfferId={this.props.jobOffer.id}
              hideModal={() => this.setState({ showDeleteModal: false })}
              submitAction={() => this.props.history.push('portal/recruiter/job-offers')}
              open={this.state.showDeleteModal}/>
          </PerfectScrollbar>
        </div>
      </Modal>
    );
  }

}
