import { ListDto } from 'middleware/dto/list';

import { ImageDto } from 'rest/images/image.dto';
import { UserAddressDto } from 'rest/address-dto/address';
import { SalaryRangeDto } from 'rest/salary-range/salary-range.dto';
import { NotificationSettingDto } from './current/notification-setting.dto';
import { JobRequirementsResponseDto } from 'rest/candidates/job-requirements/job-requirements.response.dto';

export class CandidateDto {

  id: number;
  lang: string;
  email: string;
  active = true;
  imageId: string;
  branches: ListDto[];
  password: string;
  birthday: string;
  lastName: string;
  anonymous = true;
  jobTitles: ListDto[];
  firstName: string;
  rememberMe: string;
  phoneNumber: string;
  updatedDate: string;
  lastActivity: string;
  directContact = true;
  acceptNotification: true;
  fullyRegistered: boolean;
  profileActivity: boolean;
  willingToRelocate: boolean;
  requestedContacts: boolean;
  candidateAddress: UserAddressDto;
  candidateInviteStatus: string;
  salaryResponse: SalaryRangeDto;
  candidatePhotos: ImageDto[] = [];
  blockNotification: NotificationSettingDto;
  candidateJobRequirements: JobRequirementsResponseDto;

  socialId: string;
  socialType: string;

  follow: boolean;
  favorite: boolean;

  showOnBoardingVideo: boolean;

  constructor (data?: CandidateDto) {
    if (data) {
      this.id = data.id;
      this.lang = data.lang;
      this.email = data.email;
      this.active = data.active;
      this.imageId = data.imageId;
      this.lastName = data.lastName;
      this.password = data.password;
      this.birthday = data.birthday;
      this.anonymous = data.anonymous;
      this.firstName = data.firstName;
      this.rememberMe = data.rememberMe;
      this.phoneNumber = data.phoneNumber;
      this.updatedDate = data.updatedDate;
      this.lastActivity = data.lastActivity;
      this.directContact = data.directContact;
      this.profileActivity = data.profileActivity;
      this.fullyRegistered = data.fullyRegistered;
      this.candidateAddress = data.candidateAddress;
      this.blockNotification = data.blockNotification;
      this.willingToRelocate = data.willingToRelocate;
      this.requestedContacts = data.requestedContacts;
      this.acceptNotification = data.acceptNotification;
      this.candidatePhotos = data.candidatePhotos || [];
      this.candidateInviteStatus = data.candidateInviteStatus;
      this.branches = data.branches ? data.branches.map(branch => new ListDto(branch)) : [];
      this.jobTitles = data.jobTitles ? data.jobTitles.map(title => new ListDto(title)) : [];
      this.salaryResponse = data.salaryResponse ? new SalaryRangeDto(data.salaryResponse) : null;
      this.candidateJobRequirements = new JobRequirementsResponseDto(data.candidateJobRequirements);

      this.socialId = data.socialId;
      this.socialType = data.socialType;

      this.follow = data.follow;
      this.favorite = data.favorite;

      this.showOnBoardingVideo = data.showOnBoardingVideo;
    }
  }

  get primaryPhotoUrl (): string {
    return (this.candidatePhotos.find(photo => photo.primary) || new ImageDto()).url || '/images/shared/userpick.jpg';
  }

  get fullName (): string {
    if (this.firstName || this.lastName) {
      return `${this.firstName} ${this.lastName}`;
    }
    if (this.anonymous) {
      return 'Anonymous';
    }
    return 'Restricted';
  }

  get age (): number {
    const today = new Date();
    const birthDate = new Date(this.birthday);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    age = (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) ? age - 1 : age;
    return age;
  }

}
