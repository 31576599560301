// @flow
import axios, { AxiosPromise } from 'axios';

import { CurrentCandidateDto } from './current/candidate.current.dto';

import { CandidateDto } from './candidate.dto';
import { CandidateSocialDto } from './candidate-social.dto';

export async function getCandidateByIdRest (id: string): AxiosPromise<CandidateDto> {
  try {
    return await axios.get(`/candidates/${id}`);
  } catch (error) {
    throw error;
  }
}

export async function updateCandidate (candidateId: string, data: CurrentCandidateDto): Promise<CurrentCandidateDto> {
  try {
    return await axios.put(`/candidates/${candidateId}`, data);
  } catch (error) {
    throw error;
  }
}

export async function createCandidate (data: CandidateDto): AxiosPromise<string> {
  try {
    return await axios.post('/candidates', data);
  } catch (error) {
    throw error.response;
  }
}

export async function deleteCandidate (candidateId: string): AxiosPromise {
  try {
    return await axios.delete(`/candidates/${candidateId}`);
  } catch (error) {
    throw error.response;
  }
}

export async function socialRegister (data: CandidateSocialDto): AxiosPromise<string> {
  try {
    return await axios.post('/candidates/social-signup', data);
  } catch (error) {
    throw error;
  }
}

export async function signInCandidate (data: CandidateDto): AxiosPromise<string> {
  try {
    const formData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('username', data.email);
    formData.append('user_role', 'candidate');
    formData.append('password', data.password);
    return await axios({
      method: 'POST',
      url: '/oauth/token',
      data: formData,
      headers: {
        'Authorization': 'Basic aW50ZXJuYWw6OXdzYk4ydW4=',
        'Content-Type': 'multipart/form-data'
      }
    });
  } catch (error) {
    throw error.response;
  }
}

export async function activateCandidate (candidateId: string): AxiosPromise<string> {
  try {
    return await axios.post(`/candidates/${candidateId}/activate`);
  } catch (error) {
    throw error;
  }
}
