// @flow
import React from 'react';

import moment from 'moment';

import { connectTranslation } from 'middleware/connect';

import { Setting } from './candidate-row.setting';

@connectTranslation()
export class CandidateRow extends Setting {

  deleteCandidateFromList (event: Event) {
    event.stopPropagation();
    this.props.onDelete(this.props.candidate.id);
  }

  render () {
    const { t, candidate } = this.props;
    return (
      <tr
        onClick={() => this.props.onClick(candidate.id)}
        className="candidate-row">
        <td className="candidate-avatar">
          <div className="cell">
            {candidate.isNew ? <div className="new-candidate"/> : null}
            <div className="avatar-wrapper">
              <img
                src={candidate.primaryPhotoUrl}
                alt="Photo"/>
            </div>
          </div>
        </td>
        <td className="full-name">
          <div className="cell">
            <h5 className="item-title">
              {
                candidate.fullName ||
                t(`portal.recruiter.match.preview.candidate.${candidate.anonymous ? 'anonymous' : 'name'}`)
              }
            </h5>
            {
              !candidate.anonymous && <div className="item-description">
                <div>{candidate.address.city}, {candidate.address.country}</div>
              </div>
            }
          </div>
        </td>
        <td className="branch">
          <div className="cell">
            <h5 className="item-title">
              {candidate.branchName || this.props.t('portal.recruiter.match.preview.candidate.branch')}
            </h5>
            <div className="item-description">
              {moment(candidate.updatedDate).parseZone().fromNow()}
            </div>
          </div>
        </td>
        <td className="match-points">
          <div className="cell">
            <div className="item-props">
              <span className="points">
                {candidate.matchedPoints || 0}%
              </span>
            </div>
          </div>
        </td>
        <td className="actions">
          <div className="cell">
            <div className="item-props">
              <span
                onClick={(event: Event) => this.deleteCandidateFromList(event)}
                className="preview-item-close-icon">
                <img
                  src="/images/shared/cancel-s.svg"
                  alt="close"/>
              </span>
            </div>
          </div>
        </td>
      </tr>
    );
  }

}
