// @flow
import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import type { Props } from './confirm-reset-password-form.setting';

import { connectStore } from 'middleware/connect';

import { Button } from 'components/buttons';
import { Spinner } from 'components/elements';
import { FormGroup, InputControl } from 'components/controls';

import { FinishResetPasswordDto } from 'rest/account/reset-password/finish/finish.dto';

import { Settings, stateToProps, dispatchToProps } from './confirm-reset-password-form.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class ConfirmResetPasswordForm extends Settings {

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.errorText !== this.state.errorText || nextProps.redirect !== this.state.redirect) {
      this.setState({
        errorText: nextProps.errorText,
        redirect: nextProps.redirect
      });
    }
  }

  changeResetPasswordState (field: string, value: string, isError: boolean) {
    this.setState({ [field]: value });
    this.errorCollector(field, isError);
  }

  errorCollector (errorName: string, collect: boolean) {
    const errorCollection: Set<string> = new Set(this.state.errorCollection);
    collect ? errorCollection.add(errorName) : errorCollection.delete(errorName);
    this.setState({ errorCollection });
  }

  finishResetPassword (event: Event) {
    event.preventDefault();
    if (!this.state.errorCollection.size) {
      const data = new FinishResetPasswordDto(this.state);
      data.key = this.props.match.params.id;
      this.props.finishResetPassword(data);
    }
  }

  render () {
    return (
      <Fragment>
        {
          this.state.redirect && <Redirect to={this.props.redirectUrl} />
        }
        <form onSubmit={this.finishResetPassword.bind(this)}>
          <p className="reset-form-caption">
            <Trans i18nKey="auth.reset.confirm.description">
              Enter your new password.
            </Trans>
          </p>
          <FormGroup>
            <InputControl
              value={this.state.newPassword}
              error={this.state.errorText}
              errorText={this.state.errorText || 'At least 6 characters'}
              icon
              change={(value, hasError) => this.changeResetPasswordState('newPassword', value, hasError)}
              notes="At least 6 characters"
              title="At least 6 characters"
              required
              label="New Password"
              placeholder="New Password"
              type="password"
            />
          </FormGroup>
          <FormGroup>
            <InputControl
              required
              icon
              pattern={this.state.newPassword}
              value={this.state.confirmNewPassword}
              change={(value, hasError) => this.changeResetPasswordState('confirmNewPassword', value, hasError)}
              label="Confirm New Password"
              title="Confirm New Password"
              placeholder="Confirm Password"
              errorText="Check password match"
              type="password"
            />
          </FormGroup>
          <FormGroup>
            <Button
              type="submit"
              fullWidth
              recruiter={this.props.buttonType === 'recruiter'}
              candidate={this.props.buttonType === 'candidate'}
              className="reset-btn">
              <Trans i18nKey="auth.confirm.button">
                Change my Password
              </Trans>
            </Button>
          </FormGroup>
        </form>
        <Spinner show={this.props.fetching} />
      </Fragment>
    );
  }

}
