// @flow
import { AnyAction } from 'redux';

import * as actions from './current.constants';

import type { CurrentCandidateState } from './current.type';

import { CandidateDto } from 'rest/candidates/candidate.dto';

const initialState: CurrentCandidateState = {
  fetching: false,
  redirect: false,
  errorText: '',
  deleteCandidateSuccess: false,
  registerCandidateSuccess: false,
  candidateData: new CandidateDto()
};

export default (state: CurrentCandidateState = initialState, action: AnyAction): CurrentCandidateState => {
  switch (action.type) {

    case actions.CANDIDATE_REGISTER_REQUEST:
    case actions.GET_CURRENT_CANDIDATE_REQUEST:
    case actions.CANDIDATE_SOCIAL_REGISTER_REQUEST:
    case actions.UPDATE_CURRENT_CANDIDATE_REQUEST:
    case actions.DISABLE_ON_BOARDING_VIDEO_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case actions.GET_CURRENT_CANDIDATE_SUCCESS:
      return {
        ...state,
        fetching: false,
        candidateData: new CandidateDto({ ...state.candidateData, ...action.candidate })
      };

    case actions.GET_CURRENT_CANDIDATE_FAILURE: {
      return {
        ...state,
        errorText: action.error
      };
    }

    case actions.CANDIDATE_REGISTER_FAILURE:
    case actions.UPDATE_CURRENT_CANDIDATE_FAILURE:
    case actions.CANDIDATE_SOCIAL_REGISTER_FAILURE:
      return {
        ...state,
        fetching: false,
        registerCandidateSuccess: false
      };

    case actions.UPDATE_CURRENT_CANDIDATE_SUCCESS:
      return {
        ...state,
        fetching: false,
        candidateData: action.candidate,
        registerCandidateSuccess: true
      };

    case actions.ACTIVATE_CANDIDATE_SUCCESS:
      return {
        ...state,
        redirect: true
      };

    case actions.CANDIDATE_REGISTER_SUCCESS:
      return {
        ...state,
        fetching: false,
        registerCandidateSuccess: true
      };

    case actions.DELETE_CANDIDATE_REQUEST:
      return {
        ...state,
        deleteCandidateSuccess: true
      };

    case actions.CLEAR_CURRENT_CANDIDATE_SUCCESS:
      return { ...initialState };

    case actions.DISABLE_ON_BOARDING_VIDEO_SUCCESS:
      return {
        ...state
      };

    case actions.DISABLE_ON_BOARDING_VIDEO_FAILURE:
      return {
        ...state,
        fetching: false
      };

    default:
      return state;
  }
};
