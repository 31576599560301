import * as actions from './demoMenuVideo.constants';

const disabledBalanceBoardVideoswitch = data => {
  return {
    type: actions.DISABLE_ON_BALANCE_BOARD_VIDEO,
    payload: data
  };
};

const disabledJobSearchVideo = data => {
  return {
    type: actions.DISABLE_ON_JOBSEARCH_VIDEO,
    payload: data
  };
};

const disabledApplicationVideo = data => {
  return {
    type: actions.DISABLE_ON_APPLICATION_VIDEO,
    payload: data
  };
};

const disabledMatchVideo = data => {
  return {
    type: actions.DISABLE_ON_MATCH_VIDEO,
    payload: data
  };
};

const disabledChatVideo = data => {
  return {
    type: actions.DISABLE_ON_CHAT_VIDEO,
    payload: data
  };
};

const disabledCvVideo = data => {
  return {
    type: actions.DISABLE_ON_CV_VIDEO,
    payload: data
  };
};

const disabledFavoritesVideo = data => {
  return {
    type: actions.DISABLE_ON_FAVORITES_VIDEO,
    payload: data
  };
};

const disabledFollowingVideo = data => {
  return {
    type: actions.DISABLE_ON_FOLLOWING_VIDEO,
    payload: data
  };
};

const disabledMyBlogVideo = data => {
  return {
    type: actions.DISABLE_ON_MYBLOGS_VIDEO,
    payload: data
  };
};

const disabledSettingsVideo = data => {
  return {
    type: actions.DISABLE_ON_CANDIDATE_SETTINGS_VIDEO,
    payload: data
  };
};

export const disableCandidateDashboardVideo = page => {
  return dispatch => {
    if (page.indexOf('balance-board') > -1) {
      dispatch(disabledBalanceBoardVideoswitch(true));
    } else if (page.indexOf('jobs') > -1) {
      dispatch(disabledJobSearchVideo(true));
    } else if (page.indexOf('match') > -1) {
      dispatch(disabledMatchVideo(true));
    } else if (page.indexOf('chat') > -1) {
      dispatch(disabledChatVideo(true));
    } else if (page.indexOf('applications') > -1) {
      dispatch(disabledApplicationVideo(true));
    } else if (page.indexOf('favorites') > -1) {
      dispatch(disabledFavoritesVideo(true));
    } else if (page.indexOf('follow') > -1) {
      dispatch(disabledFollowingVideo(true));
    } else if (page.indexOf('cv') > -1) {
      dispatch(disabledCvVideo(true));
    } else if (page.indexOf('posts') > -1) {
      dispatch(disabledMyBlogVideo(true));
    } else if (page.indexOf('settingsVideo') > -1) {
      dispatch(disabledSettingsVideo(true));
    }
  };
};
