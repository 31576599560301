// @flow
import React from 'react';

import { connectStore } from 'middleware/connect';

import { CounterWidget } from 'components/widgets';

import { Settings, stateToProps } from './sent-applications.setting';

@connectStore(stateToProps, null, true)
export class SentApplicationsCircle extends Settings {

  render () {
    return (
      <CounterWidget
        title={this.props.t('portal.candidate.applications.circle.title')}
        value={this.props.stats.total}
        circleTitle={this.props.t('portal.candidate.applications.circle.sent')}/>
    );
  }

}
