import { Component } from 'react';

import './container.scss';
import './container.responsive.scss';

type Props = {
  children?: Node,
  hideLogo?: boolean,
  fullWidth?: boolean,
  welcomeText?: boolean
};

export class Setting extends Component<Props> {

  static defaultProps = {
    fullWidth: false,
    hideLogo: false,
    welcomeText: false
  };

}
