// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { ImageResponseDto } from 'rest/images/images.response';

import { uploadFile } from 'services/portal/images/images.actions';

import './preview-item.scss';

export type Props = {
  image: File | ImageResponseDto,
  size: number,
  onClick: (image: File | ImageResponseDto) => void,
  loading?: boolean,
  imageInfo?: ImageResponseDto,
  closeIconClick: (id: number) => void,
  uploadFile: (file: File, type: string) => void,
  finishImageLoad: (imageInfo: ImageResponseDto) => void
}

type State = {
  imagePreview: string
}

export class Setting extends Component<Props, State> {

  static defaultProps = {
    size: 0,
    imageUrl: '',
    loading: false,
    closeIconClick: (id: number) => {},
    uploadFile: (file: File, type: string) => {},
    finishImageLoad: (imageInfo: ImageResponseDto = new ImageResponseDto()) => {}
  };

  state: State = {
    imagePreview: ''
  }

}

export const stateToProps = (state: GlobalState) => ({
  loading: state.portal.images.imageLoading,
  imageInfo: state.portal.images.imageInfo
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  uploadFile
}, dispatch);
