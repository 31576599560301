// @flow
import React from 'react';

import moment from 'moment';
import { Trans } from 'react-i18next';
import Tooltip from 'rc-tooltip';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { Modal } from 'components/dialogs';
import { LinkButton } from 'components/buttons';
import { Card, Chips } from 'components/layouts';

import type { Props } from './sent-letter-preview.setting';
import { Settings, stateToProps, dispatchToProps } from './sent-letter-preview.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class SentLetterPreviewModal extends Settings {

  componentDidMount () {
    this.fetchCurrentApplication();
  }

  componentDidUpdate (prevProps: Props) {
    if (prevProps.currentCandidate.id !== this.props.currentCandidate.id) {
      this.fetchCurrentApplication();
    }
  }

  fetchCurrentApplication () {
    const candidateId: string = this.props.currentCandidate.id;
    this.props.getSentLetterById(candidateId, this.props.match.params.applicationId);
  }

  render () {
    const backLink: string = `/portal/candidate/applications/sent`;
    const isEJTemplate: boolean = this.props.currentLetter && this.props.currentLetter.templateType === 'EJ';

    return (
      <Modal closePath={backLink}>
        <div className="create-application-modal mobile-application-modal-created">
          {
            this.props.currentLetter && <PerfectScrollbar className="content">
              <Card className="modal-header">
                <div className="left-side">
                  <LinkButton
                    inline
                    link={backLink}
                    className="application-back-link">
                    <Trans i18nKey="portal.candidate.applications.preview.back">
                      Back to Applications List
                    </Trans>
                    <div className="mobile-application-created-back">&times;</div>
                  </LinkButton>
                  <h2 className="application-caption">
                    {this.props.currentLetter.subject}
                    <Tooltip
                      offsetX={20}
                      destroyTooltipOnHide
                      overlayClassName="apply-info"
                      overlay={this.props.t('portal.candidate.applications.preview.information')}
                      placement="right">
                      <span className="info-icon">
                        <img
                          title="Subscription Info"
                          src="/images/shared/tooltip-icon.svg"
                          alt="icon"/>
                      </span>
                    </Tooltip>
                  </h2>
                  <Chips>{moment(this.props.currentLetter.date).format('ll')}</Chips>
                  {
                    isEJTemplate && <Chips color="light-accent">{this.props.currentLetter.templateType}</Chips>
                  }
                </div>
              </Card>
              <Card>
                <pre className="text-content template-body">{this.props.currentLetter.body}</pre>
              </Card>
            </PerfectScrollbar>
          }
        </div>
      </Modal>
    );
  }

}
