import React, { Component } from 'react';

import { Match } from 'common/types/match';

import { Page } from 'components/layouts';

import { InviteRecruiter } from 'containers/portal/recruiter/invite/invite';

type Props = {
  match: Match
}

export class InviteRecruiterPage extends Component<Props> {

  render () {
    return (
      <div className="recruiter-invite-page">
        <Page>
          <div className="setting-container">
            <InviteRecruiter
              title="Glad to see you with us!"
              subTitle="Before using our service, fill out the information about you."
              match={this.props.match}/>
          </div>
        </Page>
      </div>
    );
  }

}
