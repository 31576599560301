import axios from 'axios';

import { JobOffersMatchesDto } from './job-offers-matches.dto';
import { JobOffersMatchesParams } from './job-offers-matches.params';
import { LocationsDto } from 'rest/companies/job-offers-matches/matched-candidates/locations/locations.dto';

export async function getMatchedJobOffers (candidateId: string, params: JobOffersMatchesParams)
  : Promise<JobOffersMatchesDto> {
  try {
    return await axios.get(`/candidates/${candidateId}/matched-joboffers`, { params });
  } catch (error) {
    throw error;
  }
}

export async function viewMatchedJobOffers (candidateId: string, jobOfferId: string): Promise<JobOffersMatchesDto> {
  try {
    return await axios.post(`/candidates/${candidateId}/matched-joboffers/view`, null, {
      params: { jobOfferId }
    });
  } catch (error) {
    throw error;
  }
}

export async function getMatchedJobsLocations (candidateId: string): Promise<LocationsDto[]> {
  try {
    return await axios.get(`/candidates/${candidateId}/matched-joboffers/locations`);
  } catch (error) {
    throw error;
  }
}
