export const GET_ALL_CANDIDATES_REQUEST = 'GET_ALL_CANDIDATES_REQUEST';
export const GET_ALL_CANDIDATES_SUCCESS = 'GET_ALL_CANDIDATES_SUCCESS';
export const GET_ALL_CANDIDATES_FAILURE = 'GET_ALL_CANDIDATES_FAILURE';

export const GET_CANDIDATE_BY_ID_REQUEST = 'GET_CANDIDATE_BY_ID_REQUEST';
export const GET_CANDIDATE_BY_ID_SUCCESS = 'GET_CANDIDATE_BY_ID_SUCCESS';
export const GET_CANDIDATE_BY_ID_FAILURE = 'GET_CANDIDATE_BY_ID_FAILURE';

export const SET_CANDIDATES_FILTER = 'SET_CANDIDATES_FILTER';
export const RESET_CANDIDATES_FILTER = 'RESET_CANDIDATES_FILTER';

export const CHANGE_BY_ID_CANDIDATE = 'CHANGE_CANDIDATE';

export const BUY_CANDIDATE_DETAILS_REQUEST = 'BUY_CANDIDATE_DETAILS_REQUEST';
export const BUY_CANDIDATE_DETAILS_SUCCESS = 'BUY_CANDIDATE_DETAILS_SUCCESS';
export const BUY_CANDIDATE_DETAILS_FAILURE = 'BUY_CANDIDATE_DETAILS_FAILURE';

export const RESET_CANDIDATES_LIST_SUCCESS = 'RESET_CANDIDATES_LIST_SUCCESS';
