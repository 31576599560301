// @flow
import { Component } from 'react';

import { PostsResponseDto } from 'rest/candidates/posts/posts.response.dto';

import './post-item.scss';
import './post-item.responsive.scss';

type Props = {
  post: PostsResponseDto,
  action: () => void
}

export class Setting extends Component<Props> {

  static defaultProps: Props = {
    post: new PostsResponseDto(),
    action: () => {}
  }

}
