import { RecruiterProfilePhotoDto } from './recruiter.profile-photo.dto';

export class RecruiterResponseDto {

  id: string;
  email: string;
  locale: string;
  active: boolean;
  lastName: string;
  firstName: string;
  companyId: string;
  phoneNumber: string;
  fullyRegistered: boolean;
  recruiterPhoto: RecruiterProfilePhotoDto;

  constructor (data?: RecruiterResponseDto) {
    if (data) {
      this.id = data.id;
      this.email = data.email;
      this.active = data.active;
      this.locale = data.locale;
      this.lastName = data.lastName;
      this.companyId = data.companyId;
      this.firstName = data.firstName;
      this.phoneNumber = data.phoneNumber;
      this.fullyRegistered = data.fullyRegistered;
      this.recruiterPhoto = new RecruiterProfilePhotoDto(data);
    }
  }

}
