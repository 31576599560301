// @flow
import React from 'react';

import { connectTranslation } from 'middleware/connect';

import { LinkButton } from 'components/buttons';

import { Setting } from './plans.setting';
import { Trans } from 'react-i18next';

@connectTranslation()
export class Plans extends Setting {

  render () {
    return (
      <div className="plans-container">
        <div className="plans-title">
          <Trans i18nKey="information.pricing.plans.title">
            Perfect plans for recruiters.
          </Trans>
        </div>
        <div className="plans-description">
          <Trans i18nKey="information.pricing.plans.description"/>
        </div>
        <div className="plans-collection">
          <div className="plan premium">
            <div className="plan-header">
              <Trans i18nKey="information.pricing.plans.premium.title">
                Premium Plan
              </Trans>
            </div>
            <div className="plan-content">
              <div className="plan-points">
                <div className="plan-point">
                  <Trans i18nKey="information.pricing.plans.premium.points.0">
                    <strong>Full access</strong> to all features.
                  </Trans>
                </div>
                <div className="plan-point">
                  <Trans i18nKey="information.pricing.plans.premium.points.1">
                    Up to <strong>10 job offers</strong> every day for a whole year.
                  </Trans>
                </div>
                <div className="plan-point">
                  <Trans i18nKey="information.pricing.plans.premium.points.2">
                    Unique matching system for a supreme candidate skill screening
                  </Trans>
                </div>
                <div className="plan-point">
                  <Trans i18nKey="information.pricing.plans.premium.points.3">
                    Chat, favourite and follow lists.
                  </Trans>
                </div>
                <div className="plan-point">
                  <Trans i18nKey="information.pricing.plans.premium.points.4">
                    Open CV and contact information.
                  </Trans>
                </div>
                <div className="plan-point">
                  <Trans i18nKey="information.pricing.plans.premium.points.5">
                    Team collaboration with multi-user access.
                  </Trans>
                </div>
              </div>
              <div className="plan-price">
                <span className="currency">€</span>
                <span className="price">250</span>
                <span className="per">
                  <Trans i18nKey="information.pricing.plans.premium.period">
                    /per year
                  </Trans>
                </span>
              </div>
              <div className="plan-control">
                <LinkButton
                  link="/auth/recruiter/sign-up"
                  recruiter>
                  <Trans i18nKey="information.pricing.plans.premium.button">
                    Start Now
                  </Trans>
                </LinkButton>
              </div>
            </div>
          </div>
          <div className="plan free">
            <div className="plan-header">
              <Trans i18nKey="information.pricing.plans.free.title">
                Free / Basic Plan
              </Trans>
            </div>
            <div className="plan-content">
              <div className="plan-points">
                <div className="plan-point">
                  <Trans i18nKey="information.pricing.plans.free.points.0">
                    Pay only for the candidate you want to contact.
                  </Trans>
                </div>
                <div className="plan-point">
                  <Trans i18nKey="information.pricing.plans.free.points.1">
                    One active job offer.
                  </Trans>
                </div>
                <div className="plan-point">
                  <Trans i18nKey="information.pricing.plans.free.points.2">
                    Limited candidate information.
                  </Trans>
                </div>
                <div className="plan-point disabled">
                  <Trans i18nKey="information.pricing.plans.free.points.3">
                    Blocked access to chat.
                  </Trans>
                </div>
                <div className="plan-point disabled">
                  <Trans i18nKey="information.pricing.plans.free.points.4">
                    Blocked access to favourites and follow.
                  </Trans>
                </div>
                <div className="plan-point disabled">
                  <Trans i18nKey="information.pricing.plans.free.points.5">
                    Blocked access to statistics.
                  </Trans>
                </div>
              </div>
              <div className="plan-price">
                <span className="currency">€</span>
                <span className="price">0</span>
                <span className="per">
                  <Trans i18nKey="information.pricing.plans.free.period">
                    /€50 per contact
                  </Trans>
                </span>
              </div>
              <div className="plan-control">
                <LinkButton
                  link="/auth/recruiter/sign-up"
                  outlined
                  recruiter>
                  <Trans i18nKey="information.pricing.plans.free.button">
                    Start Now
                  </Trans>
                </LinkButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}
