// @flow
import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import { Modal } from 'components/dialogs';
import { ProfileContainer } from 'containers/portal/recruiter/candidates';

export class ProfilePage extends Component<null> {

  render () {
    return (
      <Modal closePath={`/portal/recruiter/chat/${this.props.match.params.id}`}>
        <Route
          path="/portal/recruiter/chat/:id/candidate/:candidateId/profile"
          exact
          component={ProfileContainer}/>
      </Modal>
    );
  }

}
