// @flow
import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';

import { TransProps } from 'react-i18next';
import type { GlobalState } from 'common/types/global-state';

import { confirmSignUp } from 'services/auth/recruiter/auth.action';
import { resendUserConfirmation, resetConfirmationEmail } from 'services/auth/confirm/confirm.actions';

import './confirm-registration-form.scss';
import './confirm-registration-form.responsive.scss';

export type Props = {
  t: TransProps,
  error: boolean,
  userType: string,
  redirect: boolean,
  buttonType: string,
  redirectUrl?: string,
  resendSuccess: boolean,
  resetConfirmationEmail?: () => void,
  resendUserConfirmation?: (email: string) => void
}

type State = {
  error: boolean,
  redirect: boolean,
  activationKey: string,
  resendSuccess: boolean
}

export class Settings extends Component<Props, State> {

  static defaultProps = {
    error: false,
    userType: '',
    redirect: false,
    redirectUrl: '',
    resendSuccess: false,
    buttonType: 'primary'
  };

  state: State = {
    activationKey: '',
    error: this.props.error,
    redirect: this.props.redirect,
    resendSuccess: this.props.resendSuccess
  };

}

export const stateToProps = (state: GlobalState) => ({
  error: state.auth.confirm.error,
  resendSuccess: state.auth.confirm.resendSuccess,
  redirect: state.auth.confirm.confirmRegistrationSuccess
});

export const dispatchToProps = (dispatch: Dispatch): Props => bindActionCreators({
  confirmSignUp,
  resetConfirmationEmail,
  resendUserConfirmation
}, dispatch);
