// @flow
import { Component } from 'react';

import { TransProps } from 'react-i18next';

import { SkillsContainerDto } from 'rest/skills/skills-container.dto';

import './skills-list.scss';
import './skills-list.responsive.scss';

type Props = {
  t: TransProps,
  skills: SkillsContainerDto;
  className?: string;
  recruiter?: boolean;
};

export class Setting extends Component<Props> {

  static defaultProps: Props = {
    skills: new SkillsContainerDto(),
    className: '',
    recruiter: false
  };

}
