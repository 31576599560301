export class PaginationDto {

  page: number;
  size: number;
  sort: string;

  constructor (data?: PaginationDto) {
    if (data) {
      this.page = data.page;
      this.size = data.size;
      this.sort = data.sort;
    }
  }

}
