// @flow
import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';

import moment from 'moment';
import { toast } from 'react-toastify';

import Popover from 'react-popover';

import { connectStore } from 'middleware/connect';
import { Button } from 'components/buttons';
import { SelectMenu, SelectMenuItem } from 'components/elements/select-menu';

import { DeleteJobOfferModal } from './delete-modal/delete-modal';

import { JobOfferStatus } from 'rest/job-offers/job-offers.status.dto';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

import { dispatchToProps, Setting, stateToProps } from './job-offer-row.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class JobOfferRow extends Setting {

  toggleMenu (event: Event) {
    event.stopPropagation();
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  }

  closeMenu () {
    this.setState({ isMenuOpen: false });
  }

  redirectToEdit (event: Event) {
    event.stopPropagation();
    this.props.history.push(`/portal/recruiter/job-offers/${this.props.jobOffer.jobOfferId}/edit`);
    this.closeMenu();
  }

  toggleDeleteModal (event: Event) {
    event.stopPropagation();
    this.setState({ isDeleteModalOpen: !this.state.isDeleteModalOpen });
    this.closeMenu();
  }

  changeJobOfferStatus (event: Event, status: string) {
    event.stopPropagation();
    if (status !== this.props.jobOffer.jobOfferStatus) {
      const jobOfferStatus = new JobOfferStatus();
      jobOfferStatus.status = status;
      this.props.updateJobOfferStatus(this.props.currentUser.companyId, this.props.jobOffer.jobOfferId, jobOfferStatus);
      toast.success(
        `${this.props.t('portal.recruiter.notifications.job-offer-status-updated')} "${status.toLowerCase()}"`
      );
    }
    this.closeMenu();
  }

  renderPopoverMenu () {
    const jobOffer: JobOffersResponseDto = this.props.jobOffer;

    return (
      <SelectMenu
        className="job-offer-action-menu"
        open>
        <SelectMenuItem onClick={event => this.redirectToEdit(event)}>
          <Trans i18nKey="portal.recruiter.job-offers.list.menu.edit">
            Edit
          </Trans>
        </SelectMenuItem>
        {
          jobOffer.jobOfferStatus !== 'DRAFT' && <SelectMenuItem
            onClick={event => this.changeJobOfferStatus(event, 'DRAFT')}>
            <Trans i18nKey="portal.recruiter.job-offers.list.menu.draft">
              Mark as Draft
            </Trans>
          </SelectMenuItem>
        }
        {
          jobOffer.jobOfferStatus !== 'ARCHIVED' && jobOffer.jobOfferStatus !== 'PUBLISHED' && <SelectMenuItem
            onClick={event => this.changeJobOfferStatus(event, 'PUBLISHED')}>
            <Trans i18nKey="portal.recruiter.job-offers.list.menu.publish">
              Publish
            </Trans>
          </SelectMenuItem>
        }
        {
          jobOffer.jobOfferStatus !== 'ARCHIVED' && <SelectMenuItem
            onClick={event => this.changeJobOfferStatus(event, 'ARCHIVED')}>
            <Trans i18nKey="portal.recruiter.job-offers.list.menu.archive">
              Archive
            </Trans>
          </SelectMenuItem>
        }
        <SelectMenuItem onClick={this.toggleDeleteModal.bind(this)}>
          <Trans i18nKey="portal.recruiter.job-offers.list.menu.delete">
            Delete
          </Trans>
        </SelectMenuItem>
      </SelectMenu>
    );
  }

  openPreview () {
    this.closeMenu();
    this.props.onClick(this.props.jobOffer);
  }

  render () {
    const jobOffer: JobOffersResponseDto = this.props.jobOffer;
    return (
      <Fragment>
        <tr
          onClick={() => this.openPreview()}
          className="job-offer-row">
          <td>
            <div className="cell">
              <div>
                <h5 className="item-title">{jobOffer.jobTitleName}</h5>
              </div>
              <div className="item-description">
                <Trans i18nKey="portal.recruiter.candidates.list.updated">
                  updated
                </Trans>
                &nbsp;
                {jobOffer.updatedDate
                  ? moment(jobOffer.updatedDate).parseZone().fromNow()
                  : moment().format('ll')}
              </div>
            </div>
          </td>
          <td>
            <div className="cell">
              <div className="item-props">
                <h5 className="item-title">{jobOffer.unitName}</h5>
              </div>
              <div className="item-description">
                {
                  jobOffer.jobType === 'Employee'
                    ? <Trans i18nKey="portal.recruiter.job-offers.list.job-type.full-time">
                      Full-Time
                    </Trans>
                    : jobOffer.jobType === 'Temporary'
                      ? <Trans i18nKey="portal.recruiter.job-offers.list.job-type.temporary">
                        Temporary
                      </Trans> : <Trans i18nKey="portal.recruiter.job-offers.list.job-type.trainee">
                        Trainee
                      </Trans>
                }
              </div>
            </div>
          </td>
          <td>
            <div className="cell">
              <div className="item-props job-offer-status">
                <span className={jobOffer.jobOfferStatus.toLowerCase()}>
                  {
                    jobOffer.jobOfferStatus === 'PUBLISHED'
                      ? <Trans i18nKey="portal.recruiter.job-offers.list.job-status.published">
                        Published
                      </Trans>
                      : jobOffer.jobOfferStatus === 'DRAFT'
                        ? <Trans i18nKey="portal.recruiter.job-offers.list.job-status.draft">
                          Draft
                        </Trans> : <Trans i18nKey="portal.recruiter.job-offers.list.job-status.archived">
                          Archived
                        </Trans>
                  }
                </span>
              </div>
            </div>
          </td>
          <td>
            <div className="cell action-cell">
              <Popover
                tipSize={0.01}
                onOuterAction={() => this.setState({ isMenuOpen: false })}
                preferPlace="below"
                isOpen={this.state.isMenuOpen}
                body={this.renderPopoverMenu()}
              >
                <Button
                  onClick={this.toggleMenu.bind(this)}
                  className="menu-button inline">
                  <img src="/images/shared/menu-btn.svg"/>
                </Button>
              </Popover>
            </div>
          </td>
        </tr>
        <DeleteJobOfferModal
          jobOfferId={this.props.jobOffer.jobOfferId}
          hideModal={this.toggleDeleteModal.bind(this)}
          open={this.state.isDeleteModalOpen}/>
      </Fragment>
    );
  }

}
