import { Component } from 'react';

import { TransProps } from 'react-i18next';

import './delete-account.scss';
import './delete-account.responsive.scss';

type Props = {
  t?: TransProps,
  open: boolean,
  candidate?: string,
  recruiter?: string,
  close: () => void,
  confirmAction: () => void
}

export class Settings extends Component<Props> {

  static defaultProps: Props = {
    open: false,
    candidate: true,
    recruiter: false,
    confirmAction: () => {}
  };

}
