import { Component } from 'react';
import type { GlobalState } from 'common/types/global-state';
import './poster.scss';

type State = {
  isExistingOffersListModalOpen: boolean
}

export class Settings extends Component<null, State> {

  state: State = {
    isExistingOffersListModalOpen: false
  }

}

export const stateToProps = (state: GlobalState) => {
  return {
    showBalanceBoardReUseVideo: state.demoMenuVideo.demoMenuVideo.onloadBalanaceBoardReUseVideo
  };
};
