// @flow
import React from 'react';

import moment from 'moment';
import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';

import { ShowSalaryRange } from 'components/elements';

import { Setting, stateToProps, dispatchToProps } from './row.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class CandidateRow extends Setting {

  render () {
    const { t, candidate } = this.props;
    return (
      <>
        <tr
          onClick={() => this.props.onClick()}
          className="candidate-row">
          <td className="candidate-avatar">
            <div className="cell">
              <div className="avatar-wrapper">
                <img src={candidate.primaryPhotoUrl}/>
              </div>
            </div>
          </td>
          <td>
            <div className="cell">
              <div><strong>{candidate.fullName}</strong></div>
              {
                candidate.candidateAddress && <div>
                  {candidate.candidateAddress.city}, {candidate.candidateAddress.country}
                </div>
              }
            </div>
          </td>
          <td>
            <div className="cell">
              <div>
                <strong>
                  {candidate.jobTitles.length
                    ? candidate.jobTitles[0].name
                    : t('portal.guest.recruiter.candidates.list.no-information')}
                </strong>
              </div>
              <div>
                <Trans i18nKey="portal.guest.recruiter.candidates.list.updated">
                  updated
                </Trans>
                &nbsp;
                {moment(candidate.updatedDate).parseZone().fromNow() ||
                t('portal.guest.recruiter.candidates.list.no-information')}
              </div>
            </div>
          </td>
          <td>
            <div className="cell">
              <div>
                <strong>
                  {candidate.branches.length
                    ? candidate.branches[0].name
                    : t('portal.guest.recruiter.candidates.list.no-information')}
                </strong>
              </div>
              <ShowSalaryRange salaryRange={candidate.salaryResponse}/>
            </div>
          </td>
        </tr>
      </>
    );
  }

}
