import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { RecruiterResponseDto } from 'rest/recruiter/recruiter.response.dto';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

import { getAllCompanyJobOffers, resetJobOffers } from 'services/portal/recruiter/account/job-offer/job-offer.actions';

import './existing-list-modal.scss';

export type Props = {
  t: TransProps,
  open: boolean,
  fetching: boolean,
  hideModal: () => void,
  currentUser: RecruiterResponseDto,
  jobOffers: JobOffersResponseDto[],
  getAllCompanyJobOffers: (companyId: string) => void
}

type State = {
  activeJobOfferId: boolean
}

export class Setting extends Component<Props, State> {

  static defaultProps = {
    open: false,
    fetching: false,
    hideModal: () => {},
    getAllCompanyJobOffers: () => {},
    currentUser: new RecruiterResponseDto()
  };

  state: State = {
    activeJobOfferId: ''
  }

}

export const stateToProps = (state: GlobalState) => ({
  fetching: state.portal.recruiter.jobOffer.process.fetching,
  jobOffers: state.portal.recruiter.account.jobOffer.jobOffers,
  currentUser: state.portal.recruiter.account.current.currentUser
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetJobOffers,
  getAllCompanyJobOffers
}, dispatch);
