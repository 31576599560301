import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import I18n from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { CurrentRecruiterDto } from 'rest/recruiter/current/current.dto';
import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';

import { showModal } from 'services/portal/modal/modal.actions';

import { getNotificationsCount } from 'services/portal/notifications/notifications.actions';

import {
  clearCurrentRecruiter,
  changeCandidateLocalization
} from 'services/portal/recruiter/account/current/current.actions';

import {
  clearCurrentCandidate,
  changeCurrentLocalization
} from 'services/portal/candidate/account/current/current.actions';

import { portalShowHideNav } from 'services/portal/portal-mobile-nav/portal-mobile-nav.action';

import { hideModal } from 'services/portal/modal/modal.actions';

import './header.scss';
import './header.responsive.scss';

type Props = {
  i18n: I18n,
  fixed: boolean,
  className: string,
  currentUserId: string,
  showModal?: () => void,
  notificationsCount?: number,
  currentUser?: CurrentRecruiterDto,
  getNotificationsCount?: (type: string) => void,
  portalShowHideNav?: (portalNavShow: boolean) => void,
  currentCandidate?: CurrentCandidateDto,
  notificationVisible?: boolean,
  changeRecruiterLocale: (lang: string) => void,
  changeCandidateLocale: (lang: string) => void,
  portalNavShow: boolean,
  unreadMessages: number,
  newJobOfferMatchedCount: number,
  hideModal: () => void,
  onRegister?: boolean,
  onPortal?: boolean,
  onGuestPortal?: boolean
};

type State = {
  localization: string,
  longPollingId: ?number,
  burgerOpen: boolean
}

export class Settings extends Component<Props, State> {

  static defaultProps = {
    fixed: false,
    className: '',
    showModal: () => {},
    notificationsCount: 0,
    getNotificationsCount: () => {},
    notificationVisible: true,
    unreadMessages: 0,
    newJobOfferMatchedCount: 0,
    onRegister: false,
    onPortal: false,
    onGuestPortal: false
  };

  state: State = {
    localization: this.props.i18n.language,
    longPollingId: null,
    burgerOpen: false
  };

}

export const stateToProps = (state: GlobalState) => ({
  currentUser: state.portal.recruiter.account.current.currentUser,
  notificationsCount: state.portal.notifications.notificationsCount,
  currentCandidate: state.portal.candidate.account.current.candidateData,
  currentUserId: state.portal.recruiter.account.current.currentUser.id ||
    state.portal.candidate.account.current.candidateData.id,
  portalNavShow: state.portal.portalNavShow.showNav,
  unreadMessages: state.portal.chats.conversations.unreadMessages,
  newJobOfferMatchedCount: state.portal.candidate.match.newJobOffers.count
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  showModal,
  clearCurrentRecruiter,
  clearCurrentCandidate,
  getNotificationsCount,
  changeRecruiterLocale: changeCurrentLocalization,
  changeCandidateLocale: changeCandidateLocalization,
  portalShowHideNav,
  hideModal
}, dispatch);
