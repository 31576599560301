// @flow
import React, { Fragment } from 'react';

import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';

import { SalaryRange, Spinner } from 'components/elements';
import { DateControl, FormGroup, InputControl, MultiSelect } from 'components/controls';
import { Button, LinkButton } from 'components/buttons';

import { ListDto } from 'middleware/dto/list';
import { UnitDto } from 'rest/unit/unit.dto';
import { JobTitleDto } from 'rest/job-title/job-title.dto';
import { SalaryRangeDto } from 'rest/job-offers/job-offers.salary-range.dto';
import { JobRequirementsDto } from 'rest/candidates/job-requirements/job-requirements.dto';
import { JobRequirementsResponseDto } from 'rest/candidates/job-requirements/job-requirements.response.dto';

import type { Props } from './general-info.setting';
import { dispatchToProps, Settings, stateToProps } from './general-info.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class GeneralInfoTab extends Settings {

  componentDidMount () {
    this.fetchUnits();
    this.fetchJobTitles();
    this.props.getAllBranches();
    this.props.getCandidateBalanceBoard(this.props.currentCandidate.id);
  }

  componentWillReceiveProps (nextProps: Props) {
    const balanceBoard: JobRequirementsResponseDto = nextProps.balanceBoard;
    if (this.props.balanceBoard !== nextProps.balanceBoard) {
      this.setState({
        ...balanceBoard,
        relocation: balanceBoard.relocate,
        workingHoursPerMonth: balanceBoard.salary ? balanceBoard.salary.workingHoursPerMonth : 0,
        jobTypes: this.getSelectedJobTypes(balanceBoard.jobTypes ? balanceBoard.jobTypes : [])
      });
    }
  }

  getSelectedJobTypes (jobTypes: string[]): ListDto[] {
    return this.state.jobTypes.map((item: ListDto) => {
      if (jobTypes.find(elem => elem === item.id)) {
        return {
          ...item,
          selected: true
        };
      }
      return item;
    });
  }

  fetchJobTitles () {
    const query: JobTitleDto = new JobTitleDto();
    const unitIds: string[] = this.state.units.map(item => item.id);
    query.size = 1000;
    query.filterUnitIds = unitIds;
    this.props.getAllJobTitles(query);
  }

  fetchUnits () {
    const query: UnitDto = new UnitDto();
    query.size = 100;
    this.props.getAllUnits(query);
  }

  changeJobTypeState (element: ListDto) {
    const updatedJobTypes: ListDto[] = this.state.jobTypes.map(item => {
      if (item.id === element.id) {
        return {
          ...item,
          selected: !element.selected
        };
      }
      return item;
    });
    this.setState({ jobTypes: updatedJobTypes });
  }

  collectSendData (): JobRequirementsDto {
    const data = new JobRequirementsDto(this.state);
    data.branches = this.state.branches;
    data.jobTitles = this.state.jobTitles;
    data.unitIds = this.state.units.map(item => item.id);
    data.branchIds = this.state.branches.map(item => item.id);
    data.jobTitleIds = this.state.jobTitles.map(item => item.id);
    data.jobTypes = this.state.jobTypes
      .filter(item => item.selected)
      .map(item => item.id);
    return data;
  }

  validate (callBack: (isValid: boolean) => void) {
    callBack(!this.state.salaryError);
  }

  navigateToNextPage (event: Event) {
    event.preventDefault();
    this.validate((valid: boolean) => {
      if (valid) {
        const data: JobRequirementsDto = this.collectSendData();
        if (this.props.match.params.action === 'create' && !this.props.skillsUpdate) {
          this.props.createCandidateBalanceBoard(this.props.currentCandidate.id, data)
            .then(() => this.props.next());
        } else {
          this.props.updateCandidateBalanceBoard(this.props.currentCandidate.id, data)
            .then(() => this.props.next());
        }
      }
    });
  }

  render () {
    return (
      <Fragment>
        <form
          onSubmit={this.navigateToNextPage.bind(this)}
          className="general-info-form mobile-balance-board-general-info-form">
          <div className="general-info-select-container">
            <FormGroup className="half-width">
              <MultiSelect
                maxSelectSize={5}
                change={branches => this.setState({ branches }, () => this.fetchUnits())}
                defaultValue={this.state.branches}
                items={this.props.branches}
                label={this.props.t('portal.candidate.balance-board.edit.tabs.general.branch')}/>
            </FormGroup>
            <FormGroup className="half-width">
              <MultiSelect
                maxSelectSize={5}
                change={units => this.setState({ units }, () => this.fetchJobTitles())}
                defaultValue={this.state.units}
                items={this.props.units}
                label={this.props.t('portal.candidate.balance-board.edit.tabs.general.unit')}/>
            </FormGroup>
            <FormGroup className="full-width">
              <MultiSelect
                required
                maxSelectSize={10}
                change={jobTitles => this.setState({ jobTitles })}
                defaultValue={this.state.jobTitles}
                items={this.props.jobTitles}
                error={!this.state.jobTitles.length}
                label={this.props.t('portal.candidate.balance-board.edit.tabs.general.job-title')}/>
            </FormGroup>
          </div>
          <hr className="divider"/>
          <FormGroup className="full-width board-form-field">
            <label className="form-label">
              <Trans i18nKey="portal.candidate.balance-board.edit.tabs.general.job-type">
                Job Type
              </Trans>
            </label>
            {
              this.state.jobTypes.map((item, index) => (
                <Button
                  key={index}
                  onClick={() => this.changeJobTypeState(item)}
                  className={`default element ${!item.selected ? 'outlined' : ''}`}
                  type="button">
                  {
                    item.id === 'Employee'
                      ? <Trans i18nKey="portal.recruiter.job-offers.list.job-type.full-time">
                        Full-Time
                      </Trans>
                      : item.id === 'Temporary' ? <Trans i18nKey="portal.recruiter.job-offers.list.job-type.temporary">
                        Part-Time
                      </Trans> : <Trans i18nKey="portal.recruiter.job-offers.list.job-type.trainee">
                        Trainee
                      </Trans>
                  }
                </Button>
              ))
            }
          </FormGroup>
          <FormGroup className="full-width board-form-field">
            <label className="form-label">
              <Trans i18nKey="portal.candidate.balance-board.edit.tabs.general.relocation.label">
                Relocation
              </Trans>
            </label>
            <Button
              outlined={!this.state.relocation}
              onClick={() => this.setState({ relocation: true })}
              className="default element"
              type="button">
              <Trans i18nKey="portal.candidate.balance-board.edit.tabs.general.relocation.able">
                Able to relocate
              </Trans>
            </Button>
            <Button
              outlined={this.state.relocation}
              onClick={() => this.setState({ relocation: false })}
              className="default element"
              type="button">
              <Trans i18nKey="portal.candidate.balance-board.edit.tabs.general.relocation.not-able">
                Disable
              </Trans>
            </Button>
          </FormGroup>
          <SalaryRange
            defaultValue={this.state.salary}
            change={(salary: SalaryRangeDto, salaryError: boolean) => this.setState({ salary, salaryError })}
            className="mobile-salary-range-container"/>
          <FormGroup className="full-width available-from">
            <DateControl
              required
              value={this.state.availableFrom}
              change={(availableFrom: string): void => this.setState({ availableFrom })}
              label={this.props.t('portal.candidate.balance-board.edit.tabs.general.available-from')}
              monthsCount={1}/>
          </FormGroup>
          <div className="working-time">
            <FormGroup className="working-time-field-width">
              <InputControl
                required
                maxLength={4}
                minLength={1}
                pattern="^\d*(\.|,)?\d{1}$"
                value={this.state.workingHoursPerMonth}
                errorText={this.props.t('portal.recruiter.candidates.filters.default.working-time.error')}
                change={(workingHoursPerMonth: string): void => this.setState({ workingHoursPerMonth })}
                label={this.props.t('portal.candidate.balance-board.edit.tabs.general.working-time.label')}
                placeholder={this.props.t('portal.candidate.balance-board.edit.tabs.general.working-time.placeholder')}
                type="text"/>
            </FormGroup>
          </div>
          <FormGroup className="full-width form-buttons">
            <LinkButton
              link="/portal/candidate/balance-board"
              grey>
              <Trans i18nKey="portal.candidate.balance-board.edit.buttons.cancel">
                Cancel
              </Trans>
            </LinkButton>
            <Button
              type="submit"
              candidate
              medium>
              Save And Next
            </Button>
          </FormGroup>
        </form>
        <Spinner show={this.props.fetching}/>
      </Fragment>
    );
  }

}
