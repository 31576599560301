// @flow
import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import type { RouterHistory } from 'react-router-dom';

import { PaginationDto } from 'middleware/dto/pagination';

import type { GlobalState } from 'common/types/global-state';

import { CurrentRecruiterDto } from 'rest/recruiter/current/current.dto';
import { CalendarResponseDto } from 'rest/companies/candidate-invites/candidate-invites-response.dto';

import {
  removeCalendarInvite,
  getAllCalendarInvites,
  resetAllCalendarInvites
} from 'services/portal/recruiter/calendar/invites/invites.actions';

import './invites-list.scss';

type Props = {
  invites: CalendarResponseDto[],
  history: RouterHistory,
  fetching: boolean,
  currentUser: CurrentRecruiterDto,
  currentPage: number,
  hasMoreItems: boolean,
  invitesStatus: string,
  removeCalendarInvite: () => void,
  getAllCalendarInvites: (companyId: string, params?: PaginationDto) => void,
  resetAllCalendarInvites: () => void
};

export class Setting extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  fetching: state.portal.recruiter.calendar.invites.fetching,
  invites: state.portal.recruiter.calendar.invites.content,
  currentUser: state.portal.recruiter.account.current.currentUser,
  currentPage: state.portal.recruiter.calendar.invites.currentPage,
  hasMoreItems: state.portal.recruiter.calendar.invites.hasMoreItems,
  invitesStatus: state.portal.recruiter.calendar.invites.status
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  removeCalendarInvite,
  getAllCalendarInvites,
  resetAllCalendarInvites
}, dispatch);
