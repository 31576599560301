import moment from 'moment';

export class CalendarInviteDto {

  date = moment().add(1, 'days').toDate();
  time = '11:30 AM';

  constructor (data?: CalendarInviteDto) {
    if (data) {
      this.date = data.date;
      this.time = data.time;
    }
  }

  get dateTime (): string {
    const date = moment(this.date).format('YYYY-MM-DD');
    const time = moment(this.time, ['HH:mm a']).format('HH:mm:ss');
    const dateTime = moment(`${date}T${time}`);
    return dateTime.toDate();
  }

}
