// @flow
import React, { Component } from 'react';
import type { RouterHistory } from 'react-router-dom';

import type { Location } from 'common/types/location';

import { SuccessPayment } from 'containers/portal/recruiter/account/settings';

type Props = {
  location: Location,
  history: RouterHistory
}

export class SuccessPaymentPage extends Component<Props> {

  render () {
    return (
      <div className="payment-page-container">
        <SuccessPayment
          history={this.props.history}
          location={this.props.location}/>
      </div>
    );
  }

}
