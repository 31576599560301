import axios, { AxiosPromise } from 'axios';

import { JobRequirementsDto } from './job-requirements.dto';
import { JobRequirementsResponseDto } from './job-requirements.response.dto';

export async function createBalanceBoard (candidateId: string, data: JobRequirementsDto):
  AxiosPromise<JobRequirementsDto> {
  try {
    return await axios.post(`/candidates/${candidateId}/occupation-requirements`, data);
  } catch (error) {
    throw error;
  }
}

export async function updateBalanceBoard (candidateId: string, data: JobRequirementsDto): AxiosPromise<string> {
  try {
    return await axios.put(`/candidates/${candidateId}/occupation-requirements`, data);
  } catch (error) {
    throw error;
  }
}

export async function getBalanceBoard (candidateId: string): AxiosPromise<JobRequirementsResponseDto> {
  try {
    return await axios.get(`/candidates/${candidateId}/occupation-requirements`);
  } catch (error) {
    throw error;
  }
}
