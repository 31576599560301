// @flow
import React from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectTranslation } from 'middleware/connect';

import { StatisticsLinkItem, StatisticsContainer } from 'components/widgets/statistics';

import { Settings } from './cv.setting';

@connectTranslation()
export class CVSidebar extends Settings {

  render () {
    return (
      <div className="cv-sidebar-container">
        <StatisticsContainer title={this.props.t('portal.candidate.cv.sidebar.title')}>
          <PerfectScrollbar className="statistics-items">
            <StatisticsLinkItem
              to="/portal/candidate/cv/personal-info"
              title={this.props.t('portal.candidate.cv.sidebar.items.0')}
              candidate />
            <StatisticsLinkItem
              to="/portal/candidate/balance-board/edit"
              title={this.props.t('portal.candidate.cv.sidebar.items.1')}
              candidate/>
            <StatisticsLinkItem
              to="/portal/candidate/cv/experience"
              title={this.props.t('portal.candidate.cv.sidebar.items.2')}
              candidate/>
            <StatisticsLinkItem
              to="/portal/candidate/cv/education"
              title={this.props.t('portal.candidate.cv.sidebar.items.3')}
              candidate/>
            <StatisticsLinkItem
              to="/portal/candidate/cv/daily"
              title={this.props.t('portal.candidate.cv.sidebar.items.4')}
              candidate/>
          </PerfectScrollbar>
        </StatisticsContainer>
      </div>
    );
  }

}
