// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { CandidateDto } from 'rest/candidates/candidate.dto';
import { RecruiterResponseDto } from 'rest/recruiter/recruiter.response.dto';

import {
  unblockUserCandidate,
  getCurrentRecruiterBlackList
} from 'services/portal/recruiter/account/account.actions';

import './black-list.scss';

export type Props = {
  fetching: boolean,
  blackList: CandidateDto[],
  currentUser: RecruiterResponseDto,
  getCurrentRecruiter: () => void,
  getCurrentRecruiterBlackList: (recruiterId: string) => void,
  unblockUserCandidate: (recruiterId: string, candidateId: string) => void
}

export class Setting extends Component<Props> {

  static defaultProps = {
    blackList: [],
    unblockUserCandidate: () => {},
    getCurrentRecruiterBlackList: () => {}
  };

}

export const stateToProps = (state: GlobalState) => ({
  fetching: state.portal.recruiter.account.blacklist.blackList,
  blackList: state.portal.recruiter.account.blacklist.blackList,
  currentUser: state.portal.recruiter.account.current.currentUser
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  unblockUserCandidate,
  getCurrentRecruiterBlackList
}, dispatch);
