// @flow
import React from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { Tabs, TabLink } from 'components/tabs';
import { StatisticsItem } from 'components/widgets/statistics';

import { Settings, stateToProps, dispatchToProps } from './favorites.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class FavoritesSidebar extends Settings {

  componentDidMount () {
    if (this.props.currentUser.id) {
      this.loadFavoritesStatistic(this.props.currentUser.id);
      this.props.getPurchasedCandidateStatistic(this.props.currentUser.companyId);
    }
  }

  componentWillUpdate (props) {
    if (!this.props.currentUser.id) {
      this.loadFavoritesStatistic(props.currentUser.id);
      this.props.getPurchasedCandidateStatistic(props.currentUser.companyId);
    }
  }

  componentWillUnmount () {
    this.props.resetFavoriteStatistic();
  }

  loadFavoritesStatistic (recruiterId: string) {
    if (recruiterId) {
      this.props.getFavoriteUnitStatistic(recruiterId);
      this.props.getFavoriteJobTitlesStatistic(recruiterId);
    }
  }

  render () {
    return (
      <Tabs currentRoute={this.props.location.pathname}>
        <TabLink
          link="/portal/recruiter/favorites/unit"
          label={this.props.t('portal.recruiter.favorites.sidebar.units.title')}
          counter={this.props.totalUnits}
          onClick={() => this.props.changeCandidateFavoritesFilter()}>
          <PerfectScrollbar className="statistics-items">
            <StatisticsItem
              title={this.props.t('portal.recruiter.favorites.sidebar.units.all')}
              value={this.props.totalUnits}
              active={!this.props.unitId}
              onClick={() => this.props.changeCandidateFavoritesFilter()}
              recruiter/>
            {
              this.props.unitStats.map((stat, index) => (
                <StatisticsItem
                  key={index}
                  title={`${stat.name.toLowerCase()}`}
                  value={stat.count}
                  active={this.props.unitId === stat.id}
                  onClick={() => this.props.changeCandidateFavoritesFilter(stat.id)}
                  recruiter/>
              ))
            }
          </PerfectScrollbar>
        </TabLink>
        <TabLink
          link="/portal/recruiter/favorites/job-title"
          label={this.props.t('portal.recruiter.favorites.sidebar.job-title.title')}
          counter={this.props.totalJobTitles}
          onClick={() => this.props.changeCandidateFavoritesFilter()}>
          <PerfectScrollbar className="statistics-items">
            <StatisticsItem
              title={this.props.t('portal.recruiter.favorites.sidebar.job-title.all')}
              value={this.props.totalJobTitles}
              active={!this.props.jobTitleId}
              onClick={() => this.props.changeCandidateFavoritesFilter()}
              recruiter/>
            {
              this.props.jobTitlesStats.map((stat, index) => (
                <StatisticsItem
                  key={index}
                  title={`${stat.name.toLowerCase()}`}
                  value={stat.count}
                  active={this.props.jobTitleId === stat.id}
                  onClick={() => this.props.changeCandidateFavoritesFilter('', stat.id)}
                  recruiter/>
              ))
            }
          </PerfectScrollbar>
        </TabLink>
        <TabLink
          link="/portal/recruiter/favorites/purchased"
          label={this.props.t('portal.recruiter.favorites.sidebar.purchased.title')}
          counter={this.props.totalPurchasedCandidates}
          onClick={() => this.props.changeCandidateFavoritesFilter()}>
          <PerfectScrollbar className="statistics-items">
            <StatisticsItem
              title={this.props.t('portal.recruiter.favorites.sidebar.purchased.all')}
              value={this.props.totalPurchasedCandidates}
              recruiter/>
          </PerfectScrollbar>
        </TabLink>
      </Tabs>
    );
  }

}
