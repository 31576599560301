import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import {
  updateCandidateApplication,
  getCandidateApplicationById
} from 'services/portal/candidate/applications/templates/templates.actions';

import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';
import { ApplicationDto } from 'rest/candidates/applications-template/applications-template.dto';
import { ApplicationResponseDto } from 'rest/candidates/applications-template/applications-template.response.dto';

import './../modal/modal.scss';
import './../modal/modal.responsive.scss';

export type Props = {
  t: TransProps,
  currentCandidate: CurrentCandidateDto,
  getCandidateApplicationById: (candidateId: string, templateId: string) => void,
  updateCandidateApplication: (candidateId: string, templateId: string, data: ApplicationDto) => void
}

type State = {
  body: string,
  subject: string
}

export class Settings extends Component<Props> {

  static defaultProps: Props = {
    getCandidateApplicationById: () => {},
    updateCandidateApplication: () => {},
    currentTemplate: new ApplicationResponseDto()
  };

  state: State = {
    body: '',
    subject: ''
  };

}

export const stateToProps = (state: GlobalState) => ({
  currentTemplate: state.portal.candidate.applications.templates.currentTemplate,
  currentCandidate: state.portal.candidate.account.current.candidateData
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getCandidateApplicationById,
  updateCandidateApplication
}, dispatch);
