import { Action } from 'redux';

import { PaginationDto } from 'middleware/dto/pagination';

import { getNewMatchedCandidates } from 'rest/companies/job-offers-matches/matched-candidates/stats/stats.rest';

import * as actions from './new-candidates.constants';

const getNewMatchedCandidatesRequest = (): Action => ({
  type: actions.GET_NEW_MATCHED_CANDIDATES_STATS_REQUEST
});

const getNewMatchedCandidatesSuccess = (data): Action => ({
  type: actions.GET_NEW_MATCHED_CANDIDATES_STATS_SUCCESS,
  payload: data
});

const getNewMatchedCandidatesFailure = (): Action => ({
  type: actions.GET_NEW_MATCHED_CANDIDATES_STATS_FAILURE
});

const resetCalendarInvitesSuccess = (): Action => ({
  type: actions.RESET_NEW_MATCHED_CANDIDATES_STATS_SUCCESS
});

export const resetNewMatchedCandidatesStats = () => {
  return dispatch => {
    dispatch(resetCalendarInvitesSuccess());
  };
};

export const getNewMatchedCandidatesStatistic = (companyId: string, params: PaginationDto) => {
  return dispatch => {
    dispatch(getNewMatchedCandidatesRequest());
    return getNewMatchedCandidates(companyId, params)
      .then(response => dispatch(getNewMatchedCandidatesSuccess(response.data)))
      .catch(error => dispatch(getNewMatchedCandidatesFailure(error)));
  };
};
