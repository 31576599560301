import React, { Component } from 'react';

import { Container } from 'components/layouts';

import { Plans } from 'containers/information';
import { VdkPartners } from 'containers/information';

export class PricingPage extends Component {

  render () {
    return (
      <div className="pricing-container">
        <Container>
          <Plans/>
          <VdkPartners/>
        </Container>
      </div>
    );
  }

}
