import * as actions from './personal-data.constants';

import { PersonalDataDto } from 'rest/personal-data/personal-data.dto';

import { createPersonalDataRequest as makePersonalDataRequest } from 'rest/personal-data/personal-data.rest';

const createPersonalDataRequest = () => ({
  type: actions.CREATE_PERSONAL_INFO_REQUEST
});

const createPersonalDataRequestSuccess = (success: boolean) => ({
  type: actions.CREATE_PERSONAL_INFO_SUCCESS,
  success
});

const createPersonalDataRequestFailure = (error: string) => ({
  type: actions.CREATE_PERSONAL_INFO_FAILURE,
  error
});

export const sendPersonalDataRequest = (data: PersonalDataDto) => {
  return dispatch => {
    dispatch(createPersonalDataRequest());
    return makePersonalDataRequest(data)
      .then(response => dispatch(createPersonalDataRequestSuccess(true)))
      .catch(error =>
        dispatch(createPersonalDataRequestFailure(error.response.data ? error.response.status : '')));
  };
};
