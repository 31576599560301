// @flow
import React from 'react';

import { Redirect, Switch } from 'react-router-dom';

import { Route } from 'middleware/route';

import { Card, Page } from 'components/layouts';
import { OnBoardingModal } from 'components/dialogs';

import { Menu } from 'containers/portal/candidate';
import { Conversations } from 'containers/portal/chat';
import { NavigationContainer, NavigationItem } from 'containers/portal/navigation';

import { NotFoundPoster } from 'containers/portal/not-found';
import { CalendarCircle } from 'containers/portal/recruiter/circles';
import { CandidateAuthContainer } from 'containers/auth/candidate/auth-container/auth-container';

import {
  CVSidebar,
  PostsSidebar,
  MatchSidebar,
  FollowSidebar,
  FavoritesSidebar,
  JobOffersSidebar,
  BalanceBoardSidebar,
  ApplicationsSidebar
} from 'containers/portal/candidate/sidebars';

import {
  CVCircle,
  ChatCircle,
  PostsCircle,
  MatchCircle,
  FollowingCircle,
  FollowersCircle,
  JobOffersCircle,
  FavoritesCircle,
  ApplicationsCircle,
  BalanceBoardCircle,
  SentApplicationsCircle
} from 'containers/portal/candidate/circles';

import { ChatPage } from './chat';
import { JobsPage } from './jobs';
import { PostsPage } from './blog';
import { MatchPage } from './match';
import { FollowPage } from './follow';
import { CandidateCVPage } from './cv';
import { FavoritesPage } from './favorites';
import { ApplicationsPage } from './applications';
import { CandidateBalanceBoardPage } from './balance-board';
import { MenuVideoModal } from 'components/dialogs';
import { Setting } from './setting';

export class CandidatePortal extends Setting {

  componentDidMount () {
    this.setRedirectUrl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      return true;
    }
  }

  setRedirectUrl () {
    const candidatePreviewLink: string = localStorage.getItem('ej_active_job_offer_link');
    if (candidatePreviewLink) {
      this.setState({ redirectLink: candidatePreviewLink });
      localStorage.removeItem('ej_active_job_offer_link');
    }
  }

  render () {
    return (
      <div className="candidate-page">
        {
          this.state.redirectLink && <Redirect to={this.state.redirectLink}/>
        }
        <Page>
          <CandidateAuthContainer location={this.props.location}>
            <Card>
              <Switch>
                <Route
                  path="/portal/candidate/jobs"
                  type="candidate"
                  component={JobsPage}/>
                <Route
                  path="/portal/candidate/chat"
                  type="candidate"
                  component={ChatPage}/>
                <Route
                  path="/portal/candidate/match"
                  type="candidate"
                  component={MatchPage}/>
                <Route
                  path="/portal/candidate/follow"
                  type="candidate"
                  component={FollowPage}/>
                <Route
                  path="/portal/candidate/favorites"
                  type="candidate"
                  component={FavoritesPage}/>
                <Route
                  path="/portal/candidate/balance-board"
                  type="candidate"
                  component={CandidateBalanceBoardPage}/>
                <Route
                  path="/portal/candidate/posts"
                  type="candidate"
                  component={PostsPage}/>
                <Route
                  path="/portal/candidate/applications"
                  type="candidate"
                  component={ApplicationsPage}/>
                <Route
                  path="/portal/candidate/cv"
                  type="candidate"
                  component={CandidateCVPage}/>
                <Route
                  path="**"
                  type="candidate"
                  component={NotFoundPoster}/>
              </Switch>
            </Card>
          </CandidateAuthContainer>
        </Page>
        <NavigationContainer>
          <NavigationItem className="navigation-widget">
            <Card>
              <Switch>
                <Route
                  path="/portal/candidate/chat/:id?"
                  type="candidate"
                  component={Conversations}/>
                <Route
                  path="/portal/candidate/match"
                  type="candidate"
                  component={MatchSidebar}/>
                <Route
                  path="/portal/candidate/follow"
                  type="candidate"
                  component={FollowSidebar}/>
                <Route
                  path="/portal/candidate/favorites"
                  type="candidate"
                  component={FavoritesSidebar}/>
                <Route
                  path="/portal/candidate/balance-board"
                  type="candidate"
                  component={BalanceBoardSidebar}/>
                <Route
                  path="/portal/candidate/applications"
                  type="candidate"
                  component={ApplicationsSidebar}/>
                <Route
                  path="/portal/candidate/posts"
                  type="candidate"
                  component={PostsSidebar}/>
                <Route
                  path="/portal/candidate/cv"
                  type="candidate"
                  component={CVSidebar}/>
                <Route
                  path="**"
                  type="candidate"
                  component={JobOffersSidebar}/>
              </Switch>
            </Card>
          </NavigationItem>
          <NavigationItem className="profile-widget">
            <Route
              path="/portal/candidate/:pageKey?"
              type="candidate"
              component={Menu}/>
          </NavigationItem>
          <NavigationItem className="chart-widget">
            <Card>
              <Switch>
                <Route
                  path="/portal/candidate/chat/:id?"
                  type="candidate"
                  component={ChatCircle}/>
                <Route
                  path="/portal/candidate/jobs"
                  type="candidate"
                  component={JobOffersCircle}/>
                <Route
                  path="/portal/candidate/match"
                  type="candidate"
                  component={MatchCircle}/>
                <Route
                  path="/portal/candidate/follow/following"
                  type="candidate"
                  component={FollowingCircle}/>
                <Route
                  path="/portal/candidate/follow/followers"
                  type="candidate"
                  component={FollowersCircle}/>
                <Route
                  path="/portal/candidate/favorites"
                  type="candidate"
                  component={FavoritesCircle}/>
                <Route
                  path="/portal/candidate/applications/sent"
                  type="candidate"
                  component={SentApplicationsCircle}/>
                <Route
                  path="/portal/candidate/applications/templates"
                  type="candidate"
                  component={ApplicationsCircle}/>
                <Route
                  path="/portal/candidate/balance-board"
                  type="candidate"
                  component={BalanceBoardCircle}/>
                <Route
                  path="/portal/candidate/posts"
                  type="candidate"
                  component={PostsCircle}/>
                <Route
                  path="/portal/candidate/cv"
                  type="candidate"
                  component={CVCircle}/>
                <Route
                  path="**"
                  type="candidate"
                  component={CalendarCircle}/>
              </Switch>
            </Card>
          </NavigationItem>
        </NavigationContainer>
        <MenuVideoModal locationPath= {this.props.location.pathname}/>
        <OnBoardingModal/>
      </div>
    );
  }

}
