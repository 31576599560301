// @flow
import React, { Fragment } from 'react';

import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';

import moment from 'moment';
import { Trans } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { Card } from 'components/layouts';
import { Modal } from 'components/dialogs';
import { Button, LinkButton } from 'components/buttons';

import { ModalHeader } from 'containers/portal/modal-container';

import { WorkExperienceDto } from 'rest/candidates/work-experience/work-experience.dto';

import { Workplace } from './workplace/workplace';

import type { Props } from './work-experience.setting';
import { Settings, dispatchToProps, stateToProps } from './work-experience.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class ExperienceCVModal extends Settings {

  componentWillUnmount () {
    this.props.resetWorkExperience();
  }

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.createSuccess !== this.state.createSuccess) {
      this.setState({ createSuccess: nextProps.createSuccess });
    }
  }

  addNewExperienceCard () {
    const experience: WorkExperienceDto = new WorkExperienceDto();
    experience.fromDate = moment();
    experience.toDate = moment();
    experience.description = '';
    this.props.pushExperience(experience);
  }

  deleteExperience (experienceId: string, index: number) {
    if (experienceId) {
      this.props.deleteCandidateWorkExperience(this.props.currentCandidate.id, experienceId);
    }
    this.props.removeExperience(index);
  }

  sendExperiences (element: WorkExperienceDto) {
    const experience: WorkExperienceDto = new WorkExperienceDto(element);
    experience.toDate = experience.experienceDate;
    const candidateId: string = this.props.currentCandidate.id;
    const experiencesIds: string[] = this.props.experiences.map(item => item.id);
    const isUpdateExperience: ?WorkExperienceDto = experiencesIds.find(elem => elem === element.id);
    if (isUpdateExperience) {
      this.props.updateCandidateWorkExperience(candidateId, element.id, experience);
    } else {
      this.props.createCandidateWorkExperience(candidateId, experience);
    }
  }

  saveExperiences (event: Event) {
    event.preventDefault();
    this.props.experiences.map(item => this.sendExperiences(item));
    toast.success(this.props.t('portal.candidate.notifications.experience-updated'));
  }

  render () {
    const backLink: string = `/portal/candidate/cv`;

    return (
      <Fragment>
        {
          this.state.createSuccess && <Redirect to={backLink}/>
        }
        <Modal closePath={backLink}>
          <div className="cv-modal mobile-cv-modal">
            <PerfectScrollbar className="content">
              <ModalHeader
                backLinkUrl={backLink}
                backLinkTitle={this.props.t('portal.candidate.cv.experience.back')}
                title={this.props.t('portal.candidate.cv.experience.title')}
                className="mobile-modal-header mobile-work-experience">
                <Button
                  onClick={() => this.addNewExperienceCard()}
                  candidate
                  outlined>
                  <img
                    className="add-more"
                    src="/images/shared/add-icon-green.svg"/>
                  <Trans i18nKey="portal.candidate.cv.experience.add">
                    Add Work Experience
                  </Trans>
                </Button>
              </ModalHeader>
              <div className="cv-content">
                <form onSubmit={this.saveExperiences.bind(this)}>
                  {
                    this.props.experiences.map((item, index: number) => {
                      return (
                        <Workplace
                          key={index}
                          number={this.props.experiences.length - index}
                          change={value => this.props.changeWorkExperience(index, value)}
                          remove={() => this.deleteExperience(item.id, index)}
                          experience={item}/>
                      );
                    })
                  }
                  <Card>
                    <div className="cv-modal-actions mobile-actions">
                      <Button
                        type="submit"
                        candidate>
                        <Trans i18nKey="portal.candidate.cv.experience.buttons.save">
                          Save Changes
                        </Trans>
                      </Button>
                      <LinkButton
                        link={backLink}
                        grey>
                        <Trans i18nKey="portal.candidate.cv.experience.buttons.cancel">
                          Cancel
                        </Trans>
                      </LinkButton>
                    </div>
                  </Card>
                </form>
              </div>
            </PerfectScrollbar>
          </div>
        </Modal>
      </Fragment>
    );
  }

}
