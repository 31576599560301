import axios from 'axios';

import { MatchedCandidatesDto } from './matched-candidates.dto';
import { MatchedCandidatesParams } from './matched-candidates.params';
import { MatchedCandidatesLocationsDto } from './matched-candidates-locations.dto';

export async function getMatchedCandidates (company: string, jobOffer: string, params: MatchedCandidatesParams):
  Promise<MatchedCandidatesDto> {
  try {
    return await axios.get(`/companies/${company}/job-offers-matches/${jobOffer}/matched-candidates`, { params });
  } catch (error) {
    throw error;
  }
}

export async function getMatchedCandidatesLocations (company: string, jobOffer: string):
  Promise<MatchedCandidatesLocationsDto> {
  try {
    return await axios.get(`/companies/${company}/job-offers-matches/${jobOffer}/matched-candidates/locations`);
  } catch (error) {
    throw error;
  }
}

export async function deleteMatchedCandidate (company: string, jobOffer: string, candidate: string):
  Promise<MatchedCandidatesDto> {
  try {
    return await axios.delete(`/companies/${company}/job-offers-matches/${jobOffer}/matched-candidates/${candidate}`);
  } catch (error) {
    throw error;
  }
}
