// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import moment from 'moment';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { ImageDto } from 'rest/images/image.dto';
import { ImageResponseDto } from 'rest/images/images.response';
import { RecruiterImageDto } from 'rest/recruiter/recruiter-image.dto';
import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';

import {
  getCurrentCandidate,
  updateCurrentCandidate,
  deleteCurrentCandidate
} from 'services/portal/candidate/account/account.actions';
import { uploadFile } from 'services/portal/images/images.actions';
import { searchSuggestedAddress } from 'services/portal/address/address.actions';

import './candidate-info.scss';
import './candidate-info.responsive.scss';

export type Props = {
  t?: TransProps,
  fetching: boolean,
  avatarLoading: boolean,
  imageInfo: ImageResponseDto,
  deleteAccountSuccess: boolean,
  getCurrentCandidate: () => void,
  currentCandidate: CurrentCandidateDto,
  uploadFile: (file: File, type: string) => void,
  deleteCurrentCandidate: (candidateId: string) => void,
  updateCurrentCandidate: (id: string, data: CurrentCandidateDto) => void
}

export type State = {
  birthday: string,
  zipCode: string,
  city: string,
  country: string,
  street: string,
  email: string,
  lastName: string,
  firstName: string,
  anonymous: boolean,
  phoneNumber: string,
  streetNumber: string,
  directContact: boolean,
  avatarError: ?string,
  avatarPreview: string,
  profileActivity: boolean,
  isDeleteModalOpen: boolean,
  errorCollection: Set<string>,
  candidatePhotos: RecruiterImageDto[]
}

export class Settings extends Component<Props, State> {

  static defaultProps = {
    fetching: false,
    avatarLoading: false,
    imageFetching: false,
    imageInfo: new ImageDto(),
    uploadFile: (file: File, type: string) => {},
    getCurrentCandidate: () => {},
    updateCurrentCandidate: () => {},
    currentCandidate: new CurrentCandidateDto()
  };

  state: State = {
    zipCode: '',
    city: '',
    country: '',
    street: '',
    email: '',
    lastName: '',
    firstName: '',
    phoneNumber: '',
    streetNumber: '',
    avatarError: '',
    anonymous: true,
    avatarPreview: '',
    directContact: true,
    candidatePhotos: [],
    profileActivity: true,
    isDeleteModalOpen: false,
    errorCollection: new Set(),
    birthday: moment().subtract(18, 'year')
  };

}

export const stateToProps = (state: GlobalState) => ({
  address: state.portal.address.content,
  imageInfo: state.portal.images.avatarInfo,
  avatarLoading: state.portal.images.avatarLoading,
  fetching: state.portal.candidate.account.current.fetching,
  currentCandidate: state.portal.candidate.account.current.candidateData,
  deleteAccountSuccess: state.portal.candidate.account.current.deleteCandidateSuccess
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  searchSuggestedAddress,
  updateCurrentCandidate,
  deleteCurrentCandidate,
  getCurrentCandidate,
  uploadFile
}, dispatch);
