// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { getCurrentCandidate } from 'services/portal/candidate/account/account.actions';
import { getCandidateSkillsById } from 'services/portal/candidate/skills/skills.actions';
import { getCandidateBalanceBoard } from 'services/portal/candidate/balance-board/balance-board.actions';

import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';

export type Props = {
  location: Location,
  getCurrentCandidate: () => void,
  currentCandidate: CurrentCandidateDto,
  getCandidateSkillsById: (candidateId: string) => void,
  getCandidateBalanceBoard: (candidateId: string) => void
};

export class Settings extends Component<Props> {

  static defaultProps: Props = {
    location,
    getCurrentCandidate: () => {},
    getCandidateSkillsById: () => {},
    currentCandidate: new CurrentCandidateDto(),
    getCandidateBalanceBoard: (candidateId: string) => {}
  }

}

export const stateToProps = (state: GlobalState) => ({
  currentCandidate: state.portal.candidate.account.current.candidateData
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getCurrentCandidate,
  getCandidateSkillsById,
  getCandidateBalanceBoard
}, dispatch);
