export class SelectModel {

  id: string = '';
  name: string = '';
  value: string = '';
  label: string = '';

  constructor (data?: SelectModel) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.value = data.id;
      this.label = data.name;
    }
  }

}
