// @flow
import { Component } from 'react';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { CandidateSkillsDto } from 'rest/candidates/skills/skills.dto';
import { JobRequirementsResponseDto } from 'rest/candidates/job-requirements/job-requirements.response.dto';

type Props = {
  t?: TransProps,
  history: History,
  candidateId?: string,
  skills?: CandidateSkillsDto,
  balanceBoard: JobRequirementsResponseDto
};

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  skills: state.portal.candidate.skills.content,
  balanceBoard: state.portal.candidate.balanceBoard.balanceBoard,
  candidateId: state.portal.candidate.account.current.candidateData.id
});
