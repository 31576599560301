import React from 'react';

import { Trans } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { Dropdown, FormGroup } from 'components/controls';

import { Settings, dispatchToProps, stateToProps } from './job-offer-candidates.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class JobOfferCandidates extends Settings {

  componentWillMount () {
    this.props.resetMatchedCandidates();
  }

  render () {
    return (
      <div className="match-candidates-list">
        <div className="candidates-header">
          <div className="title">
            <Trans i18nKey="portal.recruiter.match.preview.title">
              Candidates List
            </Trans>
            &nbsp;
            ({this.props.candidates.length})
          </div>
          <div className="filter">
            <FormGroup>
              <Dropdown
                title={this.props.t('portal.recruiter.match.preview.filter.title')}
                items={this.props.locations}
                value={this.props.location.id}
                change={value => this.props.changeMatchedCandidatesLocation(value)}
                defaultValue={this.props.t('portal.recruiter.match.preview.filter.default')}
                allowUnSelect/>
            </FormGroup>
          </div>
        </div>
        <PerfectScrollbar className="candidates-items">
          {this.props.children}
        </PerfectScrollbar>
      </div>
    );
  }

}
