import moment from 'moment';
import { TransProps } from 'react-i18next';

export const getDateDuration = (from: string, to: string, t: TransProps): string => {
  const duration: moment = moment.duration(moment(to).diff(moment(from)));
  const years: number = parseFloat(duration.asYears());
  const months: number = parseFloat(duration.asMonths());
  const restOfMonthsNumber: number = Math.floor(months - Math.floor(years) * 12);
  const fullYearsString = `${Math.floor(years)} ${t('portal.candidate.cv.list.experience.years')}`;
  const restOfMonthsString = `${restOfMonthsNumber} ${t('portal.candidate.cv.list.experience.months')}`;
  if (years > 1) {
    return `${fullYearsString} ${!!restOfMonthsNumber > 0 ? restOfMonthsString : ''}`;
  } else {
    return Math.floor(months) > 0 ? `${Math.ceil(months)} ${t('portal.candidate.cv.list.experience.months')}` : '';
  }
};
