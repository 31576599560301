// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import { uploadFile } from 'middleware/upload-file';

import { Button } from 'components/buttons';
import { Spinner } from 'components/elements';
import { Avatar } from 'components/avatar/avatar';
import { FormGroup, InputControl, PhoneControl } from 'components/controls';

import { RecruiterInfoDto } from 'rest/recruiter/recruiter-info.dto';

import { Settings, stateToProps, dispatchToProps } from './invite.setting';

@connect(stateToProps, dispatchToProps)
export class InviteRecruiter extends Settings {

  changeAvatarState (file: File) {
    uploadFile(file, (base64, error) => {
      if (error) {
        return this.setState({ avatarError: error });
      }
      this.setState({
        avatarError: false,
        avatarPreview: base64
      }, () => this.props.uploadFile(file, 'avatar'));
    });
  }

  changeRecruiterState (field: string, value: string, isError: boolean = false) {
    this.setState({ [field]: value });
    this.errorCollector(field, isError);
  }

  errorCollector (errorName: string, collect: boolean) {
    const errorCollection: Set<string> = new Set(this.state.errorCollection);
    collect ? errorCollection.add(errorName) : errorCollection.delete(errorName);
    this.setState({ errorCollection });
  }

  sendPersonalInfo () {
    const recruiterInfo = new RecruiterInfoDto(this.state);
    recruiterInfo.recruiterPhoto.primary = true;
    recruiterInfo.token = this.props.match.params.id;

    if (this.props.image && this.state.avatarPreview) {
      recruiterInfo.recruiterPhoto.imageId = this.props.image.imageId;
    } else {
      recruiterInfo.recruiterPhoto = null;
    }

    this.props.recruiterSignUp(recruiterInfo);
  }

  updatePersonalInfo (event: Event) {
    event.preventDefault();
    if (!this.state.errorCollection.size && !this.props.imageFetching) {
      this.sendPersonalInfo();
    }
  }

  render () {
    return (
      <Fragment>
        {
          this.props.redirect && <Redirect to="/portal/recruiter/balance-board"/>
        }
        <div className="invite-recruiter-container">
          <h3 className="setting-caption">Glad to see you with us!</h3>
          <p className="setting-description">
            Before using our service, fill out the information about you.
          </p>
          <Avatar
            error={!!this.state.avatarError}
            errorText={this.state.avatarError}
            loading={this.props.imageFetching}
            uploadButtonType="accent outlined"
            previewImageUrl={this.state.avatarPreview}
            change={(file: File) => this.changeAvatarState(file)}
            clearImageState={() => this.setState({ avatarPreview: '' })}
            uploadButtonName="Upload New Picture"
          />
          <form
            onSubmit={this.updatePersonalInfo.bind(this)}
            className="personal-info-form invite-recruiter-form">
            <FormGroup className="half-width">
              <InputControl
                required
                pattern=".+"
                maxLength={35}
                label="First Name"
                errorText="Cannot be empty"
                value={this.state.firstName}
                placeholder="Your First Name"
                change={(value: string): void => this.setState({ firstName: value })}
                type="text"/>
            </FormGroup>
            <FormGroup className="half-width">
              <InputControl
                required
                pattern=".+"
                maxLength={35}
                label="Last Name"
                errorText="Cannot be empty"
                value={this.state.lastName}
                placeholder="Your Last Name"
                change={(value: string): void => this.setState({ lastName: value })}
                type="text"/>
            </FormGroup>
            <FormGroup className="half-width recruiter-invite-field">
              <PhoneControl
                required
                label="Phone Number"
                defaultValue={this.state.phoneNumber}
                change={(phoneNumber: string) => this.setState({ phoneNumber })}/>
            </FormGroup>
            <FormGroup className="half-width">
              <InputControl
                icon
                required
                minLength={6}
                maxLength={32}
                label="Choose a Password"
                value={this.state.password}
                notes="At least 6 symbols"
                placeholder="Your New Password"
                errorText="At least 6 characters"
                change={(value: string, error: boolean): void =>
                  this.changeRecruiterState('password', value, error)}
                type="password"/>
            </FormGroup>
            <FormGroup className="half-width recruiter-invite-field">
              <InputControl
                icon
                required
                minLength={6}
                maxLength={32}
                label="Repeat New Password"
                pattern={this.state.password}
                errorText="Check password match"
                placeholder="Repeat New Password"
                value={this.state.confirmPassword}
                change={(value: string, error: boolean): void =>
                  this.changeRecruiterState('confirmPassword', value, error)}
                type="password"/>
            </FormGroup>
            <FormGroup className="form-btn-submit">
              <Button
                recruiter
                medium
                disabled={this.props.imageFetching}
                type="submit">
                Save changes
              </Button>
            </FormGroup>
            <FormGroup>
              <div className="agreement">By clicking ‘Next’ I agree to ExcellentJobs <br/>
                <Link to="/information/privacy-policy"> Privacy Policy </Link> and
                <Link to="/information/privacy-policy"> Terms of Use </Link>
              </div>
            </FormGroup>
          </form>
          <Spinner show={this.props.fetching}/>
        </div>
      </Fragment>
    );
  }

}
