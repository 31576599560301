import React, { Component } from 'react';

import { StartAs } from 'containers/auth/welcome';
import { AuthContainer } from 'containers/auth/layouts';

export class WelcomeAuthPage extends Component {

  render () {
    return (
      <div className="portal-container">
        <main>
          <AuthContainer
            fullWidth={true}
            welcomeText={true}>
            <StartAs/>
          </AuthContainer>
        </main>
      </div>
    );
  }

}
