// @flow
import axios, { AxiosPromise } from 'axios';

import { CvDto } from './cv.dto';
import { JobTitleDto } from './job-title.dto';

export async function getCvPdfLink (candidateId: string, preview: boolean): AxiosPromise<CvDto> {
  try {
    return await axios.get(`/candidates/${candidateId}/cv/pdf`, {
      params: { preview }
    });
  } catch (error) {
    throw error;
  }
}

export async function getCvJobTitle (candidateId: string): AxiosPromise<JobTitleDto> {
  try {
    return await axios.get(`/candidates/${candidateId}/cv`);
  } catch (error) {
    throw error;
  }
}

export async function updateCvJobTitle (candidateId: string, data: JobTitleDto) {
  try {
    return await axios.put(`/candidates/${candidateId}/cv`, data);
  } catch (error) {
    throw error;
  }
}
