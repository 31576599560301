// @flow
import { Component } from 'react';
import type { Node } from 'react';

import './payment-card.scss';

type Props = {
  title: string,
  children?: Node,
  price: number,
  selected: boolean,
  priceCondition: string
}

export class Settings extends Component<Props> {

  static defaultProps = {
    price: 0,
    title: '',
    selected: false,
    priceCondition: 'per year'
  }

}
