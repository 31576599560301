export const GET_ALL_CALENDAR_INVITES_REQUEST = 'GET_ALL_CALENDAR_INVITES_REQUEST';
export const GET_ALL_CALENDAR_INVITES_SUCCESS = 'GET_ALL_CALENDAR_INVITES_SUCCESS';
export const GET_ALL_CALENDAR_INVITES_FAILURE = 'GET_ALL_CALENDAR_INVITES_FAILURE';

export const DELETE_CALENDAR_INVITE_REQUEST = 'DELETE_CALENDAR_INVITE_REQUEST';
export const DELETE_CALENDAR_INVITE_SUCCESS = 'DELETE_CALENDAR_INVITE_SUCCESS';
export const DELETE_CALENDAR_INVITE_FAILURE = 'DELETE_CALENDAR_INVITE_FAILURE';

export const RESET_ALL_CALENDAR_INVITES_SUCCESS = 'RESET_ALL_CALENDAR_INVITES_SUCCESS';

export const CHANGE_CALENDAR_INVITE_REQUEST = 'CHANGE_CALENDAR_INVITE_REQUEST';
export const CHANGE_CALENDAR_INVITE_SUCCESS = 'CHANGE_CALENDAR_INVITE_SUCCESS';
export const CHANGE_CALENDAR_INVITE_FAILURE = 'CHANGE_CALENDAR_INVITE_FAILURE';

export const CHANGE_CALENDAR_INVITES_STATUS_SUCCESS = 'CHANGE_CALENDAR_INVITES_STATUS_SUCCESS';
