// @flow
import React from 'react';

import { connectStore } from 'middleware/connect';

import { StatisticsContainer, StatisticsItem } from 'components/widgets/statistics';

import { Settings, stateToProps } from './balance-board.setting';

@connectStore(stateToProps, null, true)
export class BalanceBoardSidebar extends Settings {

  openBalanceBoard () {
    if (this.props.balanceBoard && this.props.skills.emptySkills) {
      this.props.history.push(`/portal/candidate/balance-board/${this.props.candidateId}/preview`);
    }
  }

  render () {
    return (
      <StatisticsContainer title={this.props.t('portal.candidate.balance-board.sidebar.title')}>
        <StatisticsItem
          title={this.props.t('portal.candidate.balance-board.sidebar.items.skills')}
          value={this.props.skills ? this.props.skills.professionalSkills.length : 0}
          onClick={() => this.openBalanceBoard()}
          candidate/>
        <StatisticsItem
          title={this.props.t('portal.candidate.balance-board.sidebar.items.software')}
          value={this.props.skills ? this.props.skills.softwareSkills.length : 0}
          onClick={() => this.openBalanceBoard()}
          candidate/>
        <StatisticsItem
          title={this.props.t('portal.candidate.balance-board.sidebar.items.languages')}
          value={this.props.skills ? this.props.skills.languageSkills.length : 0}
          onClick={() => this.openBalanceBoard()}
          candidate/>
        <StatisticsItem
          title={this.props.t('portal.candidate.balance-board.sidebar.items.documents')}
          value={this.props.skills ? this.props.skills.documents.length : 0}
          onClick={() => this.openBalanceBoard()}
          candidate/>
      </StatisticsContainer>
    );
  }

}
