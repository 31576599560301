import { Component } from 'react';

import './counter.scss';

type Props = {
  value: string,
  title: string,
  circleTitle: string
};

export class Settings extends Component<Props> {

  static defaultProps: Props = {
    value: '0',
    title: '',
    circleTitle: ''
  }

}
