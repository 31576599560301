import axios, { AxiosPromise } from 'axios';

import { StatsMatchDto } from './stats.dto';
import { StatsMatchedCandidatesDto } from './stats-candidates.dto';
import { PaginationDto } from 'middleware/dto/pagination';

export async function getMatchedJobOffersStats (companyId: string): AxiosPromise<StatsMatchDto> {
  try {
    return await axios.get(`/companies/${companyId}/job-offers-matches/stats`);
  } catch (error) {
    throw error;
  }
}

export async function getNewMatchedCandidates (companyId: string, params: PaginationDto)
  : AxiosPromise<StatsMatchedCandidatesDto> {
  try {
    return await axios.get(`/companies/${companyId}/job-offers-matches/new-matched-candidates`, { params });
  } catch (error) {
    throw error;
  }
}
