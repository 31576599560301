import axios, { AxiosPromise } from 'axios';

import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';

export async function getCurrentCandidate (): Promise<CurrentCandidateDto> {
  try {
    return await axios.get('/candidates/current');
  } catch (error) {
    throw error;
  }
}

export async function changeCurrentCandidateLocale (lang: string): AxiosPromise<CurrentCandidateDto> {
  try {
    return await axios.put(`/candidates/locale?lang=${lang}`);
  } catch (error) {
    throw error;
  }
}

export async function disableOnBoardingVideo (): Promise {
  try {
    return await axios.put('/candidates/current/register/full');
  } catch (error) {
    throw error;
  }
}
