// @flow
import React, { Component } from 'react';
import { TransProps } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { PortletContainer, PortletHeader, PortletBody } from 'components/portlet';

import { InvitesList } from 'containers/portal/recruiter/calendar';

type Props = {
  t: TransProps
}

@connectTranslation()
export class InvitationListPage extends Component<Props> {

  render () {
    return (
      <PortletContainer>
        <PortletHeader title={this.props.t('portal.recruiter.calendar.list.title')}/>
        <PortletBody>
          <InvitesList {...this.props}/>
        </PortletBody>
      </PortletContainer>
    );
  }

}
