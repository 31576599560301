import { Component } from 'react';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'src/common/types/global-state';

import './modal.scss';
import './modal.responsive.scss';

export type Props = {
  t: TransProps,
  open: boolean
};

type State = {
  activeTab: number
}

export class Settings extends Component<Props, State> {

  static defaultProps = {
    open: false
  };

  state = {
    activeTab: 0
  }

}

export const stateToProps = (state: GlobalState) => ({
  skills: state.portal.candidate.skills.content
});
