export class ConfirmationEmailDto {

  email: string;

  constructor (data?: ConfirmationEmailDto) {
    if (data) {
      this.email = data.email;
    }
  }

}
