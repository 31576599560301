import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';

import { getCandidateCvLink } from 'services/portal/candidate/cv/pdf/pdf.actions';
import { getCandidateSkillsById } from 'services/portal/candidate/skills/skills.actions';
import { getCandidateJobTitle } from 'services/portal/candidate/cv/job-title/job-title.actions';
import { getCandidateLifeStyle } from 'services/portal/candidate/cv/lifestyles/lifestyles.actions';
import { getCandidateEducations } from 'services/portal/candidate/cv/education/education.actions';
import { getCandidateBalanceBoard } from 'services/portal/candidate/balance-board/balance-board.actions';
import { getCandidateWorkExperiences } from 'services/portal/candidate/cv/work-experience/work-experience.actions';

import './cv-page.scss';
import './cv-page.responsive.scss';

export type Props = {
  currentCandidate: CurrentCandidateDto,
}

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  skills: state.portal.candidate.skills.content,
  educations: state.portal.candidate.cv.education.content,
  experiences: state.portal.candidate.cv.experience.content,
  activities: state.portal.candidate.cv.lifestyle.filteredActivities,
  currentCandidate: state.portal.candidate.account.current.candidateData
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getCandidateCvLink,
  getCandidateJobTitle,
  getCandidateLifeStyle,
  getCandidateSkillsById,
  getCandidateEducations,
  getCandidateBalanceBoard,
  getCandidateWorkExperiences
}, dispatch);
