// @flow
import { Component } from 'react';
import './video.scss';
import './video.responsive.scss';

type Props = {
  recruiter: boolean,
  onBoarding: boolean
}

type State = {
  videoId: number,
  isRecruiter: boolean
}

interface Role {
  german: string;
  english: string;
}

export class Recruiter implements Role {

  static german = 'qIyqUB0tX8M';
  static english = '1mlV0AXUrXY';

  static getId (language: string) {
    return language === 'de' ? this.german : this.english;
  }

}

export class Candidate implements Role {

  static german = 'OgyroP6x32s';
  static english = 'KVNUYa_XSTA';

  static getId (language: string) {
    return language === 'de' ? this.german : this.english;
  }

}

export class Setting extends Component<Props, State> {

  static defaultProps = {
    recruiter: true,
    onBoarding: false
  };

  state: State = {
    isRecruiter: true,
    src: '',
    index: 0,
    videoList: []
  }

}
