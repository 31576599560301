import React, { Component } from 'react';
import { Route } from 'middleware/route';

import { Page } from 'components/layouts';
import { GuestJobOfferPayment } from 'containers/portal/guest/job-offer/payment/payment';

export class GuestJobOfferPaymentPage extends Component {

  render () {
    return (
      <div className="guest-job-offer-payment-page">
        <Page autoHeight>
          <Route
            path="/portal/new-job-offer/payment"
            type="recruiter"
            component={GuestJobOfferPayment}/>
        </Page>
      </div>
    );
  }

}
