// @flow
import * as actions from './followers.constants';

import { Action, Dispatch } from 'redux';
import { AxiosResponse } from 'axios';

import { CandidateDto } from 'rest/candidates/candidate.dto';
import { RecruiterFollowParams } from 'rest/recruiter/follow/follow.params';

import { getFollowersCandidates } from 'rest/recruiter/follow/follow.rest';

const getFollowersCandidateRequest = (): Action => ({
  type: actions.GET_FOLLOWERS_CANDIDATE_REQUEST
});

const getFollowersCandidateSuccess = (data: CandidateDto[]): Action => ({
  type: actions.GET_FOLLOWERS_CANDIDATE_SUCCESS,
  payload: data
});

const getFollowersCandidateFailure = (): Action => ({
  type: actions.GET_FOLLOWERS_CANDIDATE_FAILURE
});

const resetCandidateFollowsSuccess = (): Action => ({
  type: actions.RESET_FOLLOWERS_CANDIDATE_SUCCESS
});

const changeCandidateFollowersFilterSuccess = (unitId?: string): Action => ({
  type: actions.CHANGE_FOLLOWERS_CANDIDATE_FILTER_SUCCESS,
  unitId
});

export function changeCandidateFollowersFilter (unitId: string = '') {
  return (dispatch: Dispatch) => {
    dispatch(changeCandidateFollowersFilterSuccess(unitId));
  };
}

export function resetAllCandidateFollowers () {
  return (dispatch: Dispatch) => {
    dispatch(resetCandidateFollowsSuccess());
  };
}

export function requestFollowersCandidates (companyId: string, params: RecruiterFollowParams) {
  return (dispatch: Dispatch) => {
    dispatch(getFollowersCandidateRequest());
    return getFollowersCandidates(companyId, params)
      .then((response: AxiosResponse<CandidateDto[]>) => dispatch(getFollowersCandidateSuccess(response.data)))
      .catch(() => dispatch(getFollowersCandidateFailure()));
  };
}
