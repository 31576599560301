import { Component } from 'react';

import './footer.scss';
import './footer.responsive.scss';

type Props = {
  children?: Node
};

export class Setting extends Component<Props> {}
