// @flow
import React, { Fragment } from 'react';

import type { Props } from './menu.setting';
import { Setting } from './menu.setting';

export class SelectMenu extends Setting {

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.open !== this.state.menuOpen) {
      this.setState({ menuOpen: nextProps.open });
    }
  }

  closeMenu (event: Event) {
    event.stopPropagation();
    this.setState({ menuOpen: false });
    this.props.close();
  }

  render () {
    return (
      <Fragment>
        {
          this.state.menuOpen && <div
            className="outside"
            onClick={this.closeMenu.bind(this)}
          />
        }
        <div className="select-menu-container">
          {
            this.state.menuOpen && <div className={`select-menu-options ${this.props.className}`}>
              <ul className="options-list">
                <li>
                  {this.props.children}
                </li>
              </ul>
            </div>
          }
        </div>
      </Fragment>
    );
  }

}
