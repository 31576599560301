// @flow
import React from 'react';

import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { RadioControl, RadioGroup } from 'components/controls';

import { Settings } from './payment-options.setting';

@connectTranslation()
export class PaymentOptions extends Settings {

  render () {
    return (
      <div className={`payment-options ${this.props.disabled ? 'disabled' : ''}`}>
        <p>
          <Trans i18nKey="portal.guest.recruiter.job-offer.payment.payment-method.title">
            Choose Suitable Payment options:
          </Trans>
        </p>
        <RadioGroup
          change={this.props.changeOptions}
          defaultValue={this.props.defaultOption}>
          <RadioControl
            label={this.props.t('portal.guest.recruiter.job-offer.payment.payment-method.methods.0')}
            value="SOFORT"/>
          <RadioControl
            label={this.props.t('portal.guest.recruiter.job-offer.payment.payment-method.methods.1')}
            value="SEPA_DIRECT_DEBIT"/>
        </RadioGroup>
      </div>
    );
  }

}
