// @flow
import type { GlobalState } from 'common/types/global-state';

import './applications.responsive.scss';

export const stateToProps = (state: GlobalState) => ({
  templates: state.portal.candidate.applications.templates.content,
  templatesStats: state.portal.candidate.applications.stats.templates.total,
  sentApplicationsStats: state.portal.candidate.applications.stats.sentApplications.total
});
