// @flow
import { Component } from 'react';

import './companies.scss';
import './companies.responsive.scss';

export class Setting extends Component {

  state = {
    isOpen: false
  };

}

export const carouselSettings = {
  dots: true,
  arrows: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  variableWidth: true,
  infinite: false,
  responsive: [
    {
      breakpoint: 1220,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true
      }
    }
  ]
};
