import { Component } from 'react';

import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { sendContactQuestion } from 'services/landing/contact/contact.actions';

import { CandidateDto } from 'rest/candidates/candidate.dto';
import { CurrentRecruiterDto } from 'rest/recruiter/current/current.dto';
import { AskQuestionRequestDto } from 'rest/public/ask-question/ask-question.request.dto';

import './contact-modal.scss';
import './contact-modal.responsive.scss';

export type Props = {
  t?: TransProps,
  open: boolean,
  close: () => void,
  currentCandidate: CandidateDto,
  currentRecruiter: CurrentRecruiterDto,
  sendContactQuestion?: (request: AskQuestionRequestDto) => void,
}

type State = {
  name: string,
  email: string,
  files: File[],
  question: string,
  initialName: string,
  initialEmail: string
}

export class Settings extends Component<Props, State> {

  static defaultProps: Props = {
    open: false,
    close: () => {},
    currentCandidate: new CandidateDto(),
    currentRecruiter: new CurrentRecruiterDto(),
    sendContactQuestion: (request: AskQuestionRequestDto) => {}
  };

  state = {
    name: '',
    email: '',
    files: [],
    question: '',
    initialName: '',
    initialEmail: ''
  };

}

export const stateToProps = (state: GlobalState) => ({
  content: state.landing.contact.content,
  fetching: state.landing.contact.fetching,
  currentRecruiter: state.portal.recruiter.account.current.currentUser,
  currentCandidate: state.portal.candidate.account.current.candidateData
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  sendContactQuestion
}, dispatch);
