// @flow
import React from 'react';
import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Button } from 'components/buttons/index';

import { Modal, ModalCaption } from 'containers/portal/modal-container/index';

import { Setting } from './delete-modal.setting';

@connectTranslation()
export class DeleteApplicationModal extends Setting {

  deleteApplication () {
    this.props.deleteAction();
    this.props.hideModal();
  }

  render () {
    return (
      <Modal
        overlay
        animation="fade"
        open={this.props.open}
        className="application-delete-modal"
        hideModalAvailable={false}
        hideModal={() => this.props.hideModal()}
        closeOnOverlay={() => this.props.hideModal()}>
        <ModalCaption
          hideModal={() => this.props.hideModal()}
          title={this.props.t('portal.candidate.applications.delete.title')}/>
        <div className="delete-modal-content">
          <p className="confirm-delete">
            <Trans i18nKey="portal.candidate.applications.delete.description">
              Are you sure you want to delete this template?
            </Trans>
          </p>
          <div className="delete-action">
            <Button
              onClick={() => this.deleteApplication()}
              candidate
              className="mobile-delete-confirm">
              <Trans i18nKey="portal.candidate.applications.delete.buttons.yes">
                Yes, Delete
              </Trans>
            </Button>
            <Button
              onClick={() => this.props.hideModal()}
              grey
              className="mobile-delete-confirm">
              <Trans i18nKey="portal.candidate.applications.delete.buttons.no">
                No, Leave
              </Trans>
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

}
