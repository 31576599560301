// @flow
import React from 'react';

import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';

import { connectStore } from 'middleware/connect';

import { Button } from 'components/buttons';
import { FormGroup, InputControl } from 'components/controls';

import { Modal, ModalCaption } from 'containers/portal/modal-container';

import { AskQuestionRequestDto } from 'rest/public/ask-question/ask-question.request.dto';

import type { Props } from './contact-modal.setting';
import { dispatchToProps, Settings, stateToProps } from './contact-modal.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class ContactModal extends Settings {

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.currentRecruiter.id) {
      this.setState({
        initialName: `${nextProps.currentRecruiter.firstName} ${nextProps.currentRecruiter.lastName}`,
        initialEmail: nextProps.currentRecruiter.email
      });
    }
    if (nextProps.currentCandidate.id) {
      this.setState({
        initialName: `${nextProps.currentCandidate.firstName} ${nextProps.currentCandidate.lastName}`,
        initialEmail: nextProps.currentCandidate.email
      });
    }
  }

  initCurrentUser = () => {
    this.setState(state => ({
      name: state.initialName,
      email: state.initialEmail
    }));
  };

  attachFile (file: File) {
    let files = this.state.files;
    files.push(file);
    this.setState({ files: files });
  }

  removeAttachedFile (file: File) {
    let files = this.state.files;
    files = files.filter(attachment => file !== attachment);
    this.setState({ files: files });
  }

  sendQuestion () {
    const request = new AskQuestionRequestDto();
    request.name = this.state.name;
    request.email = this.state.email;
    request.files = this.state.files;
    request.question = this.state.question;
    this.props.sendContactQuestion(request);
    toast.success(this.props.t('landing.contact.notifications.success'));
  }

  closeModal () {
    this.setState({
      name: '',
      email: '',
      files: [],
      question: ''
    });
    this.props.close();
  }

  render () {
    const { t } = this.props;
    return (
      <Modal
        overlay
        animation="fade"
        open={this.props.open}
        onOpen={this.initCurrentUser}
        hideModalAvailable={false}
        hideModal={() => this.closeModal()}
        closeOnOverlay={() => this.closeModal()}
        className="contact-us-modal-container">
        <ModalCaption
          hideModal={() => this.closeModal()}
          title={t('landing.contact.title')}/>
        <form
          onSubmit={() => {
            this.closeModal();
            this.sendQuestion();
          }}
          className="contact-us-modal-form">
          <div className="contact-us-description">
            <Trans i18nKey="landing.contact.description">
              We’ll answer all your questions and listen to all your wishes.
            </Trans>
          </div>
          <div className="contact-information">
            <FormGroup className="half-width">
              <InputControl
                maxLength={35}
                label={t('landing.contact.name.label')}
                value={this.state.name}
                change={(name: string): void => this.setState({ name: name })}
                placeholder={t('landing.contact.name.placeholder')}
                pattern=".+"
                required
                errorText={t('landing.contact.name.error')}
                type="text"/>
            </FormGroup>
            <FormGroup className="half-width">
              <InputControl
                maxLength={35}
                label={t('landing.contact.email.label')}
                value={this.state.email}
                change={(email: string): void => this.setState({ email: email })}
                placeholder={t('landing.contact.email.placeholder')}
                errorText={t('landing.contact.email.error')}
                required
                type="email"/>
            </FormGroup>
          </div>
          <FormGroup>
            <InputControl
              multiline
              maxLength={350}
              label={t('landing.contact.question.label')}
              value={this.state.question}
              change={(question: string): void => this.setState({ question: question })}
              placeholder={t('landing.contact.question.placeholder')}
              required
              type="email"/>
          </FormGroup>
          <FormGroup className="attachment-files-container">
            <label className={`attach-file ${this.state.files.length >= 5 && 'disabled'}`}>
              <Trans i18nKey="landing.contact.file">
                Attach files
              </Trans>
              <input
                type="file"
                onChange={event => this.attachFile(event.target.files[0])}/>
            </label>
            <div className="file-list">
              {
                this.state.files.map((file: File, index: number) => (
                  <div
                    key={index}
                    className="file">
                    <span className="name">{file.name}</span>
                    <span
                      onClick={() => this.removeAttachedFile(file)}
                      className="control"/>
                  </div>
                ))
              }
            </div>
          </FormGroup>
          <div className="contact-us-modal-form-action">
            <Button
              type="submit"
              recruiter
              medium>
              <Trans i18nKey="landing.contact.buttons.send">
                Send
              </Trans>
            </Button>
            <Button
              medium
              type="button"
              onClick={() => this.closeModal()}
              grey>
              <Trans i18nKey="landing.contact.buttons.cancel">
                Cancel
              </Trans>
            </Button>
          </div>
        </form>
      </Modal>
    );
  }

}
