import { Component } from 'react';

import './success-reset.scss';
import './success-reset.responsive.scss';

type Props = {
  buttonType: string,
  redirectUrl: string
}

export class Setting extends Component<Props> {

  static defaultProps = {
    buttonType: 'primary'
  }

}
