// @flow
import React from 'react';

import { Setting } from './modal-main-column.setting';

export class ModalMainColumn extends Setting {

  render () {
    return (
      <div className="general-info">
        {this.props.children}
      </div>
    );
  }

}
