// @flow
import React from 'react';

import { NavLink, Route } from 'react-router-dom';

import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';

import { PortletBody, PortletContainer, PortletHeader } from 'components/portlet';

import { FollowersFilters, FollowersJobs } from 'containers/portal/candidate/jobs';

import { Setting, stateToProps } from './index.setting';

@connectStore(stateToProps, false, true)
export class FollowersListPage extends Setting {

  render () {
    return (
      <PortletContainer>
        <PortletHeader
          title="Followers companies"
          className="mobile-portlet-header-tabs">
          <div className="mobile-md-hide">
            <FollowersFilters/>
          </div>
          <div className="mobile-portlet-navs-tabs mobile-md-show">
            <NavLink
              className="btn mobile-link-btn"
              to="/portal/candidate/follow/following">
              <Trans i18nKey="portal.candidate.follows.sidebar.following">
                Following
              </Trans>
              <span> ({this.props.totalFollowing > 0 ? this.props.totalFollowing : '0'})</span>
            </NavLink>
            <NavLink
              className="btn mobile-link-btn"
              to="/portal/candidate/follow/followers">
              <Trans i18nKey="portal.candidate.follows.sidebar.followers">
                Followers
              </Trans>
              <span> ({this.props.totalFollowers > 0 ? this.props.totalFollowers : '0'})</span>
            </NavLink>
          </div>
        </PortletHeader>
        <PortletBody className="mobile-portlet-poster">
          <Route
            path="/portal/candidate/follow/followers"
            component={FollowersJobs}/>
        </PortletBody>
      </PortletContainer>
    );
  }

}
