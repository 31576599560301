import { combineReducers } from 'redux';

import allCandidates from './all/candidates.reducer';
import candidatesStats from './stats/stats.reducer';
import searchCandidates from './search/search.reducer';

export default combineReducers({
  allCandidates,
  candidatesStats,
  searchCandidates
});
