// @flow
import React, { Fragment } from 'react';

import Tooltip from 'rc-tooltip';
import { Trans } from 'react-i18next';

import { uploadFile } from 'middleware/upload-file';
import { connectStore } from 'middleware/connect';

import type { Props } from './recruiter-info.setting';

import { Button } from 'components/buttons';
import { Avatar } from 'components/avatar/avatar';
import { Spinner } from 'components/elements';
import { DeleteAccount } from 'components/dialogs';
import { FormGroup, InputControl, PhoneControl } from 'components/controls';

import { RecruiterInfoDto } from 'rest/recruiter/recruiter-info.dto';
import { CurrentRecruiterDto } from 'rest/recruiter/current/current.dto';

import { Settings, stateToProps, dispatchToProps } from './recruiter-info.setting';
import { toast } from 'react-toastify';
import { setNameAndLastNameAndEmail } from '../../../../../../middleware/auth';

@connectStore(stateToProps, dispatchToProps, true)
export class RecruiterInfo extends Settings {

  componentDidMount () {
    this.props.getCurrentRecruiter();
    localStorage.removeItem('emailToConfirm');
  }

  componentWillReceiveProps (nextProps: Props) {
    const currentUser: CurrentRecruiterDto = nextProps.currentUser;
    if (currentUser && currentUser.email !== this.state.email) {
      this.setState({
        ...currentUser,
        avatarPreview: currentUser.recruiterPhoto && currentUser.recruiterPhoto.url
      });
    }
  }

  changeAvatarState (file: File) {
    uploadFile(file, (base64, error) => {
      if (error) {
        toast.error(this.props.t('portal.recruiter.notifications.incorrect-size'));
        return this.setState({ avatarError: error });
      }
      this.setState({
        avatarError: false,
        avatarPreview: base64
      }, () => this.props.uploadFile(file, 'avatar'));
    });
  }

  sendPersonalInfo () {
    const recruiterInfo: RecruiterInfoDto = new CurrentRecruiterDto(this.state);
    setNameAndLastNameAndEmail(recruiterInfo.firstName,
      recruiterInfo.lastName,
      recruiterInfo.email);
    const currentUser: CurrentRecruiterDto = this.props.currentUser;
    recruiterInfo.recruiterPhoto.primary = true;
    if (this.props.image && this.state.avatarPreview) {
      recruiterInfo.recruiterPhoto = this.props.image;
    } else if (currentUser.recruiterPhoto && this.state.avatarPreview) {
      recruiterInfo.recruiterPhoto = currentUser.recruiterPhoto;
    } else {
      recruiterInfo.recruiterPhoto = null;
    }
    this.props.updateCurrentRecruiter(currentUser.id, recruiterInfo);
    toast.success(this.props.t('portal.recruiter.notifications.profile-updated'));
  }

  deleteAvatar () {
    this.setState({ avatarPreview: '' });
    toast.success(this.props.t('portal.recruiter.notifications.avatar-deleted'));
  }

  updatePersonalInfo (event: Event) {
    event.preventDefault();
    if (!this.props.imageFetching) {
      this.sendPersonalInfo();
    }
  }

  deleteAccount () {
    this.props.hideModal();
    this.setState({ isDeleteModalOpen: false });
    this.props.deleteCurrentRecruiter(this.props.currentUser.id);
  }

  render () {
    return (
      <Fragment>
        <Avatar
          error={!!this.state.avatarError}
          errorText={this.state.avatarError}
          loading={this.props.imageFetching}
          previewImageUrl={this.state.avatarPreview}
          change={this.changeAvatarState.bind((this))}
          uploadButtonType='accent outlined'
          clearImageState={() => this.deleteAvatar()}
          uploadButtonName={this.props.t('portal.recruiter.profile.user.avatar')}
        />
        <form
          onSubmit={this.updatePersonalInfo.bind(this)}
          className="personal-info-form">
          <FormGroup className="half-width">
            <InputControl
              maxLength={35}
              label={this.props.t('portal.recruiter.profile.user.first-name.label')}
              value={this.state.firstName}
              change={(firstName: string): void => this.setState({ firstName })}
              placeholder={this.props.t('portal.recruiter.profile.user.first-name.placeholder')}
              pattern=".+"
              required
              errorText={this.props.t('portal.recruiter.profile.user.first-name.error')}
              type="text"/>
          </FormGroup>
          <FormGroup className="half-width">
            <InputControl
              maxLength={35}
              label={this.props.t('portal.recruiter.profile.user.last-name.label')}
              value={this.state.lastName}
              change={(lastName: string): void => this.setState({ lastName })}
              placeholder={this.props.t('portal.recruiter.profile.user.last-name.placeholder')}
              required
              errorText={this.props.t('portal.recruiter.profile.user.last-name.error')}
              type="text"/>
          </FormGroup>
          <FormGroup className="half-width">
            <InputControl
              autocomplete={false}
              readonly
              disabled
              label={this.props.t('portal.recruiter.profile.user.email.label')}
              value={this.state.email}
              required
              change={(email: string): void => this.setState({ email })}
              placeholder={this.props.t('portal.recruiter.profile.user.email.placeholder')}
              errorText={this.props.t('portal.recruiter.profile.user.email.error')}
              type="email"/>
          </FormGroup>
          <FormGroup className="half-width">
            <PhoneControl
              required
              label={this.props.t('portal.recruiter.profile.user.phone.label')}
              defaultValue={this.state.phoneNumber}
              change={(phoneNumber: string) => this.setState({ phoneNumber })}/>
          </FormGroup>
          <FormGroup className="form-btn-submit form-actions">
            <Button
              recruiter
              medium
              disabled={this.props.imageFetching}
              type="submit">
              {this.props.actionButtonTitle}
            </Button>
            {
              this.props.deleteAccount && <Tooltip
                trigger={['hover']}
                overlay={this.props.t('portal.recruiter.profile.delete')}
                placement="top"
                overlayClassName={!this.props.currentUser.owner ? 'hidden' : ''}
                destroyTooltipOnHide>
                <span>
                  <Button
                    onClick={() => this.setState({ isDeleteModalOpen: true })}
                    medium
                    disabled={this.props.currentUser.owner}
                    grey>
                    <Trans i18nKey="portal.recruiter.profile.buttons.delete">
                      Delete Account
                    </Trans>
                  </Button>
                </span>
              </Tooltip>
            }
          </FormGroup>
        </form>
        <DeleteAccount
          recruiter
          close={() => this.setState({ isDeleteModalOpen: false })}
          confirmAction={() => this.deleteAccount()}
          open={this.state.isDeleteModalOpen}/>
        <Spinner show={this.props.fetching}/>
        <Spinner show={this.props.fetching}/>
      </Fragment>
    );
  }

}
