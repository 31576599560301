import React from 'react';
import { connect } from 'react-redux';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { getData } from 'middleware/job-offer-storage';

import { Chips } from 'components/layouts';
import { Tab, Tabs } from 'components/tabs';
import { PhotoGallery } from 'components/photo-gallery/photo-gallery';
import { DateTime, ShowSalaryRange, SkillsList } from 'components/elements';
import { ModalMainColumn, ModalAsideColumn } from 'components/layouts/modal';

import { Modal, ModalCaption } from 'containers/portal/modal-container';

import { CompanyDto } from 'rest/companies/companies.dto';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

import type { Props } from './preview-modal.setting';
import { Setting, stateToProps, dispatchToProps } from './preview-modal.setting';
import { Trans } from 'react-i18next';

@connect(stateToProps, dispatchToProps)
export class JobOfferPreviewModal extends Setting {

  componentWillReceiveProps (nextProps: Props) {
    const jobOffer = (nextProps.jobOffers && nextProps.jobOffers.length) ? nextProps.jobOffers[0] : {};
    if (nextProps.open !== this.props.open) {
      this.props.getCompanyJobOfferSkills(jobOffer.jobOfferId || jobOffer.id);
    }
  }

  render () {
    const company: CompanyDto = getData('ej_company_info');
    const jobOffer: JobOffersResponseDto = this.props.jobOffers ? this.props.jobOffers[0] : {};
    return (
      <Modal
        overlay
        animation="fade"
        open={this.props.open}
        hideModalAvailable={false}
        className="guest-offer-preview-modal"
        hideModal={() => this.props.hideModal()}
        closeOnOverlay={() => this.props.hideModal()}>
        <ModalCaption
          hideModal={() => this.props.hideModal()}
          title={`${this.props.mode} Subscription Plan Job Offer Preview`}/>
        <div className="job-offer-modal">
          <div className="modal-columns-container">
            <PerfectScrollbar className="content">
              <div className="modal-columns">
                {
                  jobOffer && <ModalMainColumn>
                    <h4 className="job-title">{ jobOffer.jobTitleName }</h4>
                    <div className="additional-info">
                      <div className="branches">
                        <Chips>{jobOffer.branchName && jobOffer.branchName}</Chips>
                        <Chips>{jobOffer.unitName && jobOffer.unitName}</Chips>
                      </div>
                      <Chips>{ jobOffer.matchedPoints} %</Chips>
                    </div>
                    <ul className="job-summary-info">
                      {
                        jobOffer.referenceNumber && <li>
                          <b>Reference Number </b>
                          <span>{jobOffer.referenceNumber}</span>
                        </li>
                      }
                      <li>
                        <b>Working time: </b>
                        {
                          (jobOffer.salaryRange && jobOffer.salaryRange.workingHoursPerMonth)
                            ? <span>{jobOffer.salaryRange.workingHoursPerMonth} hours/month</span>
                            : <span>No information</span>
                        }
                      </li>
                      <li><b>Job type: </b>
                        <span>
                          {
                            jobOffer.jobType === 'Employee'
                              ? <Trans i18nKey="portal.recruiter.job-offers.list.job-type.full-time">
                                Full-Time
                              </Trans>
                              : jobOffer.jobType === 'Temporary'
                                ? <Trans i18nKey="portal.recruiter.job-offers.list.job-type.temporary">
                                  Temporary
                                </Trans> : <Trans i18nKey="portal.recruiter.job-offers.list.job-type.trainee">
                                  Trainee
                                </Trans>
                          }
                        </span>
                      </li>
                      <li>
                        <b>Salary range: </b>
                        <ShowSalaryRange salaryRange={jobOffer.salaryRange}/>
                      </li>
                      <li><b>Available from: </b>
                        {
                          jobOffer.availableFrom
                            ? <span><DateTime value={jobOffer.availableFrom}/></span>
                            : <span>No information</span>
                        }
                      </li>
                    </ul>
                    <div className="full-info-container">
                      <Tabs>
                        <Tab label="Competencies">
                          <div className="competencies-wrapper">
                            {
                              this.props.jobOfferSkills && <SkillsList
                                recruiter
                                skills={this.props.jobOfferSkills}/>
                            }
                          </div>
                        </Tab>
                        <Tab label="Job description">
                          <div>{jobOffer.jobDescription ? jobOffer.jobDescription : 'No information'}</div>
                        </Tab>
                      </Tabs>
                    </div>
                  </ModalMainColumn>
                }
                <ModalAsideColumn>
                  {
                    company && <div className={this.props.mode === 'FREE' ? 'restricted' : ''}>
                      <div className="job-address">
                        <h4 className="name">
                          {company.name}
                        </h4>
                        <address className="address">
                          <div>{company.address.street} {company.address.streetNumber}, {company.address.zipCode}</div>
                          <div>{company.address.city}, {company.address.country}</div>
                        </address>
                      </div>
                      {
                        company.photos && company.photos.length > 0 ? <PhotoGallery photos={company.photos}/>
                          : <div className="logo-container">
                            <div className="hotel-logo">
                              <div className="logo-wrapper">
                                {company.logo.url ? <img
                                  src={company.logo.url}
                                  alt={company.name}/> : ''}
                              </div>
                            </div>
                          </div>
                      }
                      <div className="job-employer-description">
                        <h4 className="title">Description of business</h4>
                        <p className="description">{company.description}</p>
                      </div>
                    </div>
                  }
                </ModalAsideColumn>
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </Modal>
    );
  }

}
