// @flow
import { Component } from 'react';
import { TransProps } from 'react-i18next';

import { CandidateDto } from 'rest/candidates/candidate.dto';

import './candidate-info.scss';

type Props = {
  t: TransProps,
  cvLink: string,
  restricted: boolean,
  candidate: CandidateDto,
  inviteAction: () => void,
  followToggle: (candidate: CandidateDto) => void,
  startCandidateChat: (candidateID: string) => void,
  favoritesToggle: (candidate: CandidateDto) => void
}

export class Setting extends Component<Props> {

  static defaultProps = {
    restricted: false
  }

}
