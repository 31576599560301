// @flow
import React from 'react';

import cn from 'classnames';
import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Button } from 'components/buttons';

import { Settings } from './payment-card.setting';

@connectTranslation()
export class PaymentCard extends Settings {

  render () {
    const cardClasses = cn(['card', { selected: this.props.selected }]);
    return (
      <article className={cardClasses}>
        <h4 className="card-caption">{this.props.title}</h4>
        <div className="card-content">
          <ul className="card-feature-list">
            {this.props.children}
          </ul>
          <div className="price">
            <p>
              <span className="euro-sign">&#x20AC;</span>
              <span className="price-count">{this.props.price}</span>/ {this.props.priceCondition}
            </p>
          </div>
          <Button
            onClick={() => this.props.showJobOffer()}
            className="card-button"
            recruiter
            outlined>
            <Trans i18nKey="portal.guest.recruiter.job-offer.payment.buttons.job-offer">
              Job Offer Preview
            </Trans>
          </Button>
        </div>
        {
          this.props.selected && <span className="selected-title">
            <Trans i18nKey="portal.guest.recruiter.job-offer.payment.selected">
              (Selected)
            </Trans>
          </span>
        }
      </article>
    );
  }

}
