// @flow
import React from 'react';

import { toast } from 'react-toastify';
import { Trans } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import { connectStore } from 'middleware/connect';

import { ListPlaceholder, Spinner } from 'components/elements';
import { ElementsList, JobOfferRow } from 'components/elements-list';

import { FavoriteParams } from 'rest/candidates/current/favorite/favorite.params';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

import { Setting, stateToProps, dispatchToProps } from './favorite.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class FavoriteJobs extends Setting {

  componentWillUnmount () {
    this.props.resetFavoriteJobs();
  }

  deleteFavoriteJobOffer (id: string) {
    this.props.deleteJobFromFavorite(id, this.props.currentCandidateId);
    toast.success(this.props.t('portal.candidate.notifications.favorite-delete'));
  }

  openJobOffer (selectedJobOfferId: string) {
    this.props.history.push(`/portal/candidate/favorites/${selectedJobOfferId}/preview`);
  }

  loadJobOffersList (page: number = 0) {
    const params = new FavoriteParams(this.props.favoritesFilters);
    params.page = page;
    params.extraFields = ['favorite'];
    this.props.getFavoriteJobs(params);
  }

  render () {
    return (
      <InfiniteScroll
        threshold={100}
        useWindow={false}
        pageStart={-1}
        hasMore={this.props.hasMoreItems}
        loadMore={() => this.loadJobOffersList(this.props.currentPage)}>
        <ElementsList>
          {
            this.props.jobOffers.map((jobOffer: JobOffersResponseDto) => (
              <JobOfferRow
                key={jobOffer.id}
                action={() => this.deleteFavoriteJobOffer(jobOffer.id)}
                onClick={() => this.openJobOffer(jobOffer.id)}
                jobOffer={jobOffer}/>
            ))
          }
        </ElementsList>
        {!this.props.jobOffers.length && <ListPlaceholder
          title={this.props.t('portal.candidate.favorites.list.placeholder.title')}>
          <Trans i18nKey="portal.candidate.favorites.list.placeholder.content">
            When you mark a job offers with ❤️ it lands right here.
          </Trans>
        </ListPlaceholder>}
        <Spinner show={this.props.fetching}/>
      </InfiniteScroll>
    );
  }

}
