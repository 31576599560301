import * as actions from './payment.constant';

import { SubscriptionDto } from 'rest/companies/subscription/subscription.dto';

import { subscribeCompany } from 'rest/companies/subscription/subscription.rest';

const subscribeCompanyRequest = () => ({
  type: actions.SUBSCRIBE_COMPANY_REQUEST
});

const subscribeCompanySuccess = (link: string) => ({
  type: actions.SUBSCRIBE_COMPANY_SUCCESS,
  link
});

const subscribeCompanyFailure = (error: string) => ({
  type: actions.SUBSCRIBE_COMPANY_FAILURE,
  error
});

export const subscribeRecruiterCompany = (companyId: string, data: SubscriptionDto) => {
  return dispatch => {
    dispatch(subscribeCompanyRequest());
    return subscribeCompany(companyId, data)
      .then(response => dispatch(subscribeCompanySuccess(response.data)))
      .catch(error => dispatch(subscribeCompanyFailure(error)));
  };
};
