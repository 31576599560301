export const GET_FAVORITES_CANDIDATE_REQUEST = 'GET_FAVORITES_CANDIDATE_REQUEST';
export const GET_FAVORITES_CANDIDATE_FAILURE = 'GET_FAVORITES_CANDIDATE_FAILURE';
export const GET_FAVORITES_CANDIDATE_SUCCESS = 'GET_FAVORITES_CANDIDATE_SUCCESS';

export const ADD_FAVORITES_CANDIDATE_REQUEST = 'ADD_FAVORITES_CANDIDATE_REQUEST';
export const ADD_FAVORITES_CANDIDATE_FAILURE = 'ADD_FAVORITES_CANDIDATE_FAILURE';
export const ADD_FAVORITES_CANDIDATE_SUCCESS = 'ADD_FAVORITES_CANDIDATE_SUCCESS';

export const RESET_FAVORITES_CANDIDATE_SUCCESS = 'RESET_FAVORITES_CANDIDATE_SUCCESS';

export const DELETE_FAVORITES_CANDIDATE_REQUEST = 'DELETE_FAVORITES_CANDIDATE_REQUEST';
export const DELETE_FAVORITES_CANDIDATE_FAILURE = 'DELETE_FAVORITES_CANDIDATE_FAILURE';
export const DELETE_FAVORITES_CANDIDATE_SUCCESS = 'DELETE_FAVORITES_CANDIDATE_SUCCESS';

export const CHANGE_FAVORITES_CANDIDATE_FILTER_SUCCESS = 'CHANGE_FAVORITES_CANDIDATE_FILTER_SUCCESS';
