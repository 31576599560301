// @flow
import React from 'react';

import { connectStore } from 'middleware/connect';

import InfiniteScroll from 'react-infinite-scroller';

import { PaginationDto } from 'middleware/dto/pagination';

import { StatisticsContainer, StatisticsItem } from 'components/widgets/statistics';

import { Settings, stateToProps, dispatchToProps } from './match.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class MatchSidebar extends Settings {

  componentWillUnmount () {
    this.props.resetNewMatchedCandidatesStats();
  }

  loadNewMatchedCandidatesStatistic (page: number = 0) {
    const params = new PaginationDto();
    params.page = page;
    this.props.getNewMatchedCandidatesStatistic(this.props.currentUser.companyId, params);
  }

  openCandidateProfile (jobOfferId: string) {
    this.props.history.push(`/portal/recruiter/match/${jobOfferId}/candidates/`);
  }

  render () {
    return (
      <StatisticsContainer
        title={`${this.props.t('portal.recruiter.match.sidebar.title')} (${this.props.total})`}
        className="match-candidates-container">
        {
          this.props.currentUser.companyId && <InfiniteScroll
            useWindow={false}
            threshold={100}
            pageStart={0}
            hasMore={this.props.hasMoreItems}
            loadMore={() => this.loadNewMatchedCandidatesStatistic(this.props.currentPage)}>
            {
              this.props.stats.map((stat, index) => (
                <StatisticsItem
                  key={index}
                  title={stat.jobTitleName}
                  value={`${stat.matchedCandidate.matchedPoints} %`}
                  onClick={() => this.openCandidateProfile(stat.jobOfferId)}
                  description={stat.matchedCandidate.fullName}
                  recruiter/>
              ))
            }
          </InfiniteScroll>
        }
      </StatisticsContainer>
    );
  }

}
