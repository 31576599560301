import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';

import InfiniteScroll from 'react-infinite-scroller';

import { connectStore } from 'middleware/connect';

import { ElementsList } from 'components/elements-list';
import { ListPlaceholder, Spinner } from 'components/elements';

import { JobOfferRow } from './job-offer-row/job-offer-row';

import { PaginationDto } from 'middleware/dto/pagination';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

import type { Props } from './list.setting';

import { Setting, stateToProps, dispatchToProps } from './list.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class JobOfferList extends Setting {

  componentDidMount () {
    if (this.props.currentUser.id) {
      this.fetchJobOffers();
    }
  }

  componentDidUpdate (nextProps: Props) {
    if (this.props.currentUser.id !== nextProps.currentUser.id) {
      this.fetchJobOffers();
    }
  }

  componentWillUnmount () {
    this.props.resetJobOffers();
  }

  fetchJobOffers (page: number = 0) {
    const query: PaginationDto = new PaginationDto();
    query.size = 10;
    query.page = page;
    this.props.getAllCompanyJobOffers(this.props.currentUser.companyId, this.props.status, query);
  }

  openJobOffer (selectedJobOffer: JobOffersResponseDto) {
    this.props.history.push(`/portal/recruiter/job-offers/${selectedJobOffer.jobOfferId}/preview`);
  }

  render () {
    return (
      <Fragment>
        {
          this.props.currentUser.id && <Fragment>
            <InfiniteScroll
              pageStart={0}
              threshold={100}
              useWindow={false}
              hasMore={this.props.hasMoreItems}
              loadMore={() => this.fetchJobOffers(this.props.currentPage)}>
              <ElementsList>
                {
                  this.props.jobOffers.map((jobOffer: JobOffersResponseDto, index: number) => (
                    <JobOfferRow
                      key={index}
                      jobOffer={jobOffer}
                      history={this.props.history}
                      onClick={(jobOffer: JobOffersResponseDto) => this.openJobOffer(jobOffer)}/>
                  ))
                }
              </ElementsList>
            </InfiniteScroll>
            {
              !this.props.jobOffers.length && <ListPlaceholder
                title={this.props.t('portal.recruiter.job-offers.list.placeholder.title')}>
                <Trans i18nKey="portal.recruiter.job-offers.list.placeholder.content">
                  Go to [Balance Board] and create your first automated job offer!
                </Trans>
              </ListPlaceholder>
            }
          </Fragment>
        }
        <Spinner show={this.props.fetching}/>
      </Fragment>
    );
  }

}
