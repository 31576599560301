import React, { Component, Fragment } from 'react';

import { Route } from 'react-router-dom';

import {
  DailyCVModal,
  EducationCVModal,
  ExperienceCVModal,
  PersonalInfoCVModal
} from 'containers/portal/candidate/cv';

import { CVPage } from './list';

export class CandidateCVPage extends Component {

  render () {
    return (
      <Fragment>
        <Route
          component={CVPage}
          path="/portal/candidate/cv"/>
        <Route
          component={PersonalInfoCVModal}
          path="/portal/candidate/cv/personal-info"/>
        <Route
          component={EducationCVModal}
          path="/portal/candidate/cv/education"/>
        <Route
          component={DailyCVModal}
          path="/portal/candidate/cv/daily"/>
        <Route
          component={ExperienceCVModal}
          path="/portal/candidate/cv/experience"/>
      </Fragment>
    );
  }

}
