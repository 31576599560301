import React, { Component } from 'react';

import { AuthBody } from 'containers/auth/layouts';
import { AuthImage } from 'containers/auth';
import { SuccessResetContainer } from 'containers/auth/reset';

export class SuccessResetPasswordPage extends Component {

  render () {
    return (
      <div className="success-reset-password-page">
        <AuthImage imageSrc="/images/auth/reset-success.svg"/>
        <AuthBody title="Success!">
          <SuccessResetContainer
            buttonType="accent"
            redirectUrl="/auth/recruiter"/>
        </AuthBody>
      </div>
    );
  }

}
