import axios from 'axios';

import { PaginationDto } from 'middleware/dto/pagination';

import { ChatUnreadDto } from './chat-unread.dto';
import { ChatRequestDto } from './chat.request.dto';
import { ChatResponseDto } from './chat.response.dto';

export async function getChatConversations (params: PaginationDto): Promise<ChatResponseDto[]> {
  try {
    return await axios.get('/chats', { params });
  } catch (error) {
    throw error;
  }
}

export async function getChatConversation (chatId: number): Promise<ChatResponseDto> {
  try {
    return await axios.get(`/chats/${chatId}`);
  } catch (error) {
    throw error;
  }
}

export async function getUnreadChatConversation (): Promise<ChatUnreadDto> {
  try {
    return await axios.get(`/chats/unread`);
  } catch (error) {
    throw error;
  }
}

export async function createChatConversations (chat: ChatRequestDto): Promise<ChatResponseDto> {
  try {
    return await axios.post('/chats', chat);
  } catch (error) {
    throw error;
  }
}
