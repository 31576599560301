export const DISABLE_ON_BALANCE_BOARD_VIDEO = 'DISABLE_ON_BALANCE_BOARD_VIDEO';
export const DISABLE_ON_CANDIDATES_VIDEO = 'DISABLE_ON_CANDIDATES_VIDEO';
export const DISABLE_ON_JOBOFFERS_VIDEO = 'DISABLE_ON_JOBOFFERS_VIDEO';
export const DISABLE_ON_MATCH_VIDEO = 'DISABLE_ON_MATCH_VIDEO';
export const DISABLE_ON_CHAT_VIDEO = 'DISABLE_ON_CHAT_VIDEO';
export const DISABLE_ON_CALENDAR_VIDEO = 'DISABLE_ON_CALENDAR_VIDEO';
export const DISABLE_ON_FAVORITES_VIDEO = 'DISABLE_ON_FAVORITES_VIDEO';
export const DISABLE_ON_FOLLOWING_VIDEO = 'DISABLE_ON_FOLLOWING_VIDEO';
export const DISABLE_ON_STATISTICS_VIDEO = 'DISABLE_ON_STATISTICS_VIDEO';
export const DISABLE_ON_BALANCE_BOARD_REUSE_VIDEO = 'DISABLE_ON_BALANCE_BOARD_REUSE_VIDEO';
export const DISABLE_ON_SETTINGS_VIDEO = 'DISABLE_ON_SETTINGS_VIDEO';
