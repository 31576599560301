// @flow
import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';

import { ProfilePage } from './profile';
import { CandidatesListPage } from './list';
import { SearchCandidatesPage } from './search';

export class CandidatesPage extends Component {

  render () {
    return (
      <Fragment>
        <Route
          path="/portal/recruiter/candidates"
          component={CandidatesListPage}/>
        <Route
          path="/portal/recruiter/candidates/search"
          component={SearchCandidatesPage}/>
        <Route
          path="/portal/recruiter/candidates/:id/profile"
          component={ProfilePage}/>
      </Fragment>
    );
  }

}
