import axios from 'axios';

import { ListDto } from 'middleware/dto';
import { SearchSkillDto } from 'rest/skill/search-skill.dto';

export async function getSkills (params: SearchSkillDto): Promise<ListDto[]> {
  try {
    return await axios.get('/skills', {
      params
    });
  } catch (error) {
    throw error;
  }
}

export async function searchSkills (params: SearchSkillDto): Promise<ListDto[]> {
  try {
    return await axios.get('/skills/search', {
      params
    });
  } catch (error) {
    throw error;
  }
}
