// @flow
import React from 'react';

import { connectStore } from 'middleware/connect';

import { CounterWidget } from 'components/widgets';

import { Settings, stateToProps } from './balance-board.setting';

@connectStore(stateToProps, null, true)
export class BalanceBoardCircle extends Settings {

  render () {
    return (
      <CounterWidget
        title={this.props.t('portal.recruiter.balance-board.circle.title')}
        value={this.props.jobOffers}
        circleTitle={this.props.t('portal.recruiter.balance-board.circle.value')}/>
    );
  }

}
