// @flow
import { Component } from 'react';

import './container.scss';
import './container.responsive.scss';

type Props = {
  children: any,
  closePath: string,
  className?: string
}

export class Setting extends Component<Props> {

  static defaultProps = {
    closePath: '',
    className: ''
  };

}
