// @flow
import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { InitResetPasswordDto } from 'rest/account/reset-password/init/init.dto';

import { initResetPassword } from 'services/auth/recruiter/auth.action';

import './reset-password-form.scss';
import './reset-password-form.responsive.scss';

export type Props = {
  redirect?: boolean,
  buttonType?: string,
  fetching?: boolean,
  errorText?: string,
  redirectUrl: string,
  initResetPassword?: (data: InitResetPasswordDto) => void,
  initResetPasswordSuccess?: boolean,
}

export type State = {
  email: string,
  hasError: boolean,
  errorText: string,
  redirect: boolean
}

export class Setting extends Component<Props, State> {

  static defaultProps: Props = {
    fetching: false,
    redirect: false,
    errorText: '',
    redirectUrl: '',
    buttonType: 'primary',
    initResetPasswordSuccess: false,
    initResetPassword: () => {}
  };

  state: State = {
    email: '',
    hasError: false,
    redirect: false,
    errorText: ''
  };

}

export const stateToProps = (state: GlobalState) => ({
  fetching: state.auth.reset.fetching,
  redirect: state.auth.reset.initResetPasswordSuccess,
  errorText: state.auth.reset.errorText
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  initResetPassword
}, dispatch);
