// @flow
import React from 'react';
import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';

import { LinkButton } from 'components/buttons';

import { SkillItemDto } from 'rest/skill/skill-item.dto';

import { Settings, stateToProps } from './competencies-section.setting';

@connectStore(stateToProps, null, true)
export class CompetenciesSection extends Settings {

  collectAllSkills (): SkillItemDto[] {
    return [
      ...this.props.skills.documents,
      ...this.props.skills.languageSkills,
      ...this.props.skills.softwareSkills,
      ...this.props.skills.professionalSkills
    ];
  }

  render () {
    const skills: SkillItemDto[] = this.collectAllSkills();
    return (
      <div className="competencies-section-container mobile-competencies-section-container">
        {
          skills.length > 0 ? skills.map((skill: SkillItemDto, index: number) => (
            <div
              key={index}
              className="competencies-item">
              {skill.name}
            </div>
          )) : <div className="empty-section">
            <h5 className="empty-section-title">
              <Trans i18nKey="portal.candidate.cv.list.competencies.empty">
                Competencies panel is empty
              </Trans>
            </h5>
            <p className="empty-section-description">
              <Trans i18nKey="portal.candidate.cv.list.competencies.text">
                Fill out information about your skills and knowledge`s on Balance Board
              </Trans>
            </p>
            <LinkButton
              outlined
              link="/portal/candidate/balance-board"
              candidate>
              <Trans i18nKey="portal.candidate.cv.list.competencies.back">
                Go to Balance Board
              </Trans>
            </LinkButton>
          </div>
        }
      </div>
    );
  }

}
