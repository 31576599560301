import { ChatParticipantDto } from 'rest/chats/chat-participant.dto';

export class ChatResponseDto {

  id: string;
  chatId: string;
  blocked: boolean;
  createDate: string;
  lastMessage: string;
  participants: ChatParticipantDto[] = [];
  countUnreadMessage: number;

  constructor (data?: ChatResponseDto) {
    if (data) {
      this.id = data.id;
      this.blocked = data.blocked;
      this.createDate = data.createDate;
      this.lastMessage = data.lastMessage;
      this.participants = data.participants.map(participant => new ChatParticipantDto(participant));
      this.countUnreadMessage = data.countUnreadMessage;
    }
  }

}
