import axios from 'axios';

import { SubscriptionDto } from './subscription.dto';
import { SubscriptionResponseDto } from 'rest/companies/subscription/subscription.reponse.dto';

export async function subscribeCompany (companyId: string, data: SubscriptionDto): Promise<SubscriptionResponseDto> {
  try {
    let action = 'APPLYPROMOCODE';
    if (data.paymentSepaDetails) {
      action = 'UPGRADEPLAN';
    }
    return await axios.post(`/companies/${companyId}/subscriptions?action=${action}`, data);
  } catch (error) {
    throw error;
  }
}
