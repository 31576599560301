import { Component } from 'react';

import './checkbox-control.scss';
import './checkbox-control.responsive.scss';

export type Props = {
  label?: string,
  value?: boolean,
  change?: void,
  children?: Node,
  className?: string,
};

type State = {
  checked: boolean
}

export class Setting extends Component<Props, State> {

  state: State = {
    checked: this.props.value
  };

  static defaultProps = {
    label: '',
    value: false,
    change: (value: string) => value,
    className: ''
  };

}
