import { combineReducers } from 'redux';

import stats from './stats/stats.reducer';
import templates from './templates/templates.reducer';
import sentTemplates from './sent-templates/sent-templates.reducer';

export default combineReducers({
  stats,
  templates,
  sentTemplates
});
