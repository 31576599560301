import { Component } from 'react';

import './description.scss';

export type Props = {
  info?: boolean,
  children?: Node
}

export class Setting extends Component<Props> {

  static defaultProps: Props = {
    info: false
  };

}
