// @flow
import axios, { AxiosPromise } from 'axios';

import { SentApplicationsStatsDto } from 'rest/candidates/applications/applications.stats.dto';

import { ApplicationDto } from './applications-template.dto';
import { ApplicationResponseDto } from './applications-template.response.dto';
import { ApplicationsTemplateParams } from './applications-template.params';

export async function getApplications (candidateId: string, params: ApplicationsTemplateParams):
  AxiosPromise<ApplicationResponseDto[]> {
  try {
    return await axios.get(`/candidates/${candidateId}/applications-template`, {
      params
    });
  } catch (error) {
    throw error;
  }
}

export async function getApplicationById (candidateId: string, templateId: string):
  AxiosPromise<ApplicationResponseDto> {
  try {
    return await axios.get(`/candidates/${candidateId}/applications-template/${templateId}`);
  } catch (error) {
    throw error;
  }
}

export async function updateApplication (candidateId: string, templateId: string, data: ApplicationDto):
  Promise<ApplicationResponseDto> {
  try {
    return await axios.put(`/candidates/${candidateId}/applications-template/${templateId}`, data);
  } catch (error) {
    throw error;
  }
}

export async function createApplication (candidateId: string, data: ApplicationDto): AxiosPromise<string> {
  try {
    return await axios.post(`/candidates/${candidateId}/applications-template`, data);
  } catch (error) {
    throw error.response;
  }
}

export async function deleteApplication (candidateId: string, templateId: string): AxiosPromise<string> {
  try {
    return await axios.delete(`/candidates/${candidateId}/applications-template/${templateId}`);
  } catch (error) {
    throw error.response;
  }
}

export async function getApplicationsStats (candidateId: string): AxiosPromise<SentApplicationsStatsDto> {
  try {
    return await axios.get(`/candidates/${candidateId}/applications-template/stats`);
  } catch (error) {
    throw error;
  }
}
