import { EventDataDto } from './alerts.event-data.dto';

export class UsersAlertsResponseDto {

  date: string;
  read: boolean;
  alertId: string;
  readDate: boolean;
  eventType: string;
  notificationType: string;
  eventData: EventDataDto[];

  constructor (data?: UsersAlertsResponseDto) {
    if (data) {
      this.date = data.date;
      this.read = data.read;
      this.alertId = data.alertId;
      this.readDate = data.readDate;
      this.eventData = data.eventData;
      this.eventType = data.eventType;
      this.notificationType = data.notificationType;
    }
  }

}
