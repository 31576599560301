export class JobOfferMatchedDto {

  matched: boolean;
  jobOfferId: string;
  matchedPoints: number;
  jobOfferMatchPoints: number;

  constructor (data?: JobOfferMatchedDto) {
    if (data) {
      this.matched = data.matched;
      this.jobOfferId = data.jobOfferId;
      this.matchedPoints = data.matchedPoints;
      this.jobOfferMatchPoints = data.jobOfferMatchPoints;
    }
  }

}
