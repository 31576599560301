// @flow
import React from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { LifeStyleSection } from './lifestyle-section/lifestyle-section';
import { EducationSection } from './education-section/education-section';
import { ExperienceSection } from './experience-section/experience-section';
import { CVSectionContainer } from './cv-section-container/cv-section-container';
import { PersonalInfoSection } from './personal-info-section/personal-info-section';
import { CompetenciesSection } from './competencies-section/competencies-section';

import type { Props } from './cv-page.setting';
import { dispatchToProps, Settings, stateToProps } from './cv-page.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class MainCVModal extends Settings {

  componentDidMount () {
    if (this.props.currentCandidate.id) {
      this.fetchCVData(this.props.currentCandidate.id);
    }
  }

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.currentCandidate.id !== this.props.currentCandidate.id) {
      this.fetchCVData(nextProps.currentCandidate.id);
    }
  }

  fetchCVData (candidateId: string) {
    this.props.getCandidateCvLink(candidateId);
    this.props.getCandidateCvLink(candidateId, true);
    this.props.getCandidateSkillsById(candidateId);
    this.props.getCandidateLifeStyle(candidateId);
    this.props.getCandidateJobTitle(candidateId);
    this.props.getCandidateEducations(candidateId);
    this.props.getCandidateBalanceBoard(candidateId);
    this.props.getCandidateWorkExperiences(candidateId);
  }

  render () {
    return (
      <div className="main-cv-container">
        <PerfectScrollbar className="content mobile-content">
          <CVSectionContainer
            showEditIcon
            redirectLink="/portal/candidate/cv/personal-info"
            title={this.props.t('portal.candidate.cv.list.sections.0')}>
            <PersonalInfoSection/>
          </CVSectionContainer>
          <CVSectionContainer
            showEditIcon={this.props.skills.professionalSkills.length > 0}
            redirectLink="/portal/candidate/balance-board/edit"
            title={this.props.t('portal.candidate.cv.list.sections.1')}>
            <CompetenciesSection/>
          </CVSectionContainer>
          <CVSectionContainer
            showEditIcon={this.props.experiences.length > 0}
            redirectLink="/portal/candidate/cv/experience"
            title={this.props.t('portal.candidate.cv.list.sections.2')}>
            <ExperienceSection/>
          </CVSectionContainer>
          <CVSectionContainer
            showEditIcon={this.props.educations.length > 0}
            redirectLink="/portal/candidate/cv/education"
            title={this.props.t('portal.candidate.cv.list.sections.3')}>
            <EducationSection/>
          </CVSectionContainer>
          <CVSectionContainer
            showEditIcon={this.props.activities.length > 0}
            redirectLink="/portal/candidate/cv/daily"
            title={this.props.t('portal.candidate.cv.list.sections.4')}>
            <LifeStyleSection/>
          </CVSectionContainer>
        </PerfectScrollbar>
      </div>
    );
  }

}
