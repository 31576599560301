import React from 'react';

import { Setting } from './table.setting';
import { Trans } from 'react-i18next';
import cn from 'classnames';

export class Table extends Setting {

  render () {
    const boldTitle = cn([ 'table-cell', 'cell-width', {
      'bold': this.props.boldTitle
    } ]);

    return (
      <div className="table">
        {
          [ ...Array(this.props.row) ].map((u, i) => i).map(row => <div
            key={row}
            className="table-row">
            {
              [ ...Array(this.props.cell) ].map((u, i) => i)
                .map(cell => <div
                  key={cell}
                  className={boldTitle}>
                  <Trans i18nKey={`${this.props.translate}.${row}.${cell}`}/>
                </div>)
            }
          </div>)
        }
      </div>
    );
  }

}
