import React from 'react';

import { Trans } from 'react-i18next';

import { LinkButton } from 'components/buttons';

import { Settings } from './poster.setting';

import { connectStore } from 'middleware/connect';
import { stateToProps, dispatchToProps } from './poster.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class BalanceBoardPoster extends Settings {

  componentDidMount () {
    if (this.props.currentCandidate.id) {
      this.props.getCandidateBalanceBoard(this.props.currentCandidate.id);
    }
  }

  componentDidUpdate (prevProps) {
    const currentCandidate = prevProps.currentCandidate;
    if (currentCandidate && this.props.currentCandidate.id !== currentCandidate.id) {
      this.props.getCandidateBalanceBoard(this.props.currentCandidate.id);
    }
  }

  componentWillUnmount () {
    this.props.resetBalanceBoard();
  }

  render () {
    return (
      <div className="balance-board-candidate-poster mobile-balance-board-poster">
        <div className="title">
          <Trans i18nKey="portal.candidate.balance-board.poster.title">
            Here`s your professional profile
          </Trans>
        </div>
        <div className="description">
          {
            this.props.balanceBoard ? <Trans i18nKey="portal.candidate.balance-board.poster.edit-description">
              Fill information about your professional skills and you are one step closer to your dream job.
            </Trans> : <Trans i18nKey="portal.candidate.balance-board.poster.new-description">
              Looking good! Make sure to keep it up to date and you`ll get the  best match-jobs.
            </Trans>
          }
        </div>
        <div className="poster">
          <img
            src="/images/candidate/balance-board/illustration.svg"
            alt=""/>
        </div>
        { this.props.balanceBoard ? <div className="actions">
          <LinkButton
            link={`/portal/candidate/balance-board/${this.props.currentCandidate.id}/preview`}
            candidate>
            <Trans i18nKey="portal.candidate.balance-board.poster.actions.preview">
              Preview
            </Trans>
          </LinkButton>
          <LinkButton
            link={`/portal/candidate/balance-board/edit`}
            outlined
            candidate>
            <Trans i18nKey="portal.candidate.balance-board.poster.actions.edit">
              Edit board
            </Trans>
          </LinkButton>
        </div> : <div className="actions">
          <LinkButton
            link={`/portal/candidate/balance-board/create`}
            candidate>
            <Trans i18nKey="portal.candidate.balance-board.poster.actions.create">
              Create board
            </Trans>
          </LinkButton>
        </div> }
      </div>
    );
  }

}
