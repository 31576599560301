// @flow
import * as actions from './following.constants';

import { Action, Dispatch } from 'redux';
import { AxiosResponse } from 'axios';

import { CandidateDto } from 'rest/candidates/candidate.dto';
import { RecruiterFollowParams } from 'rest/recruiter/follow/follow.params';

import { getFollowingStatistic } from 'services/portal/recruiter/follow/stats/stats.actions';

import {
  getFollowingCandidates,
  addCandidateToFollow,
  deleteCandidateFromFollow
} from 'rest/recruiter/follow/follow.rest';

const getFollowingCandidateRequest = (): Action => ({
  type: actions.GET_FOLLOWING_CANDIDATE_REQUEST
});

const getFollowingCandidateSuccess = (data: CandidateDto[]): Action => ({
  type: actions.GET_FOLLOWING_CANDIDATE_SUCCESS,
  payload: data
});

const getFollowingCandidateFailure = (): Action => ({
  type: actions.GET_FOLLOWING_CANDIDATE_FAILURE
});

const addCandidateToFollowingRequest = (): Action => ({
  type: actions.ADD_FOLLOWING_CANDIDATE_REQUEST
});

const addCandidateToFollowingSuccess = (): Action => ({
  type: actions.ADD_FOLLOWING_CANDIDATE_SUCCESS
});

const addCandidateToFollowingFailure = (): Action => ({
  type: actions.ADD_FOLLOWING_CANDIDATE_FAILURE
});

const deleteCandidateFollowingRequest = (): Action => ({
  type: actions.DELETE_FOLLOWING_CANDIDATE_REQUEST
});

const deleteCandidateFollowingSuccess = (candidateId: string): Action => ({
  type: actions.DELETE_FOLLOWING_CANDIDATE_SUCCESS,
  payload: candidateId
});

const deleteCandidateFollowingFailure = (): Action => ({
  type: actions.DELETE_FOLLOWING_CANDIDATE_FAILURE
});

const resetCandidateFollowsSuccess = (): Action => ({
  type: actions.RESET_FOLLOWING_CANDIDATE_SUCCESS
});

const changeCandidateFollowsFilterSuccess = (unitId?: string): Action => ({
  type: actions.CHANGE_FOLLOWING_CANDIDATE_FILTER_SUCCESS,
  unitId
});

export function changeCandidateFollowingFilter (unitId: string = '') {
  return (dispatch: Dispatch) => {
    dispatch(changeCandidateFollowsFilterSuccess(unitId));
  };
}

export function resetAllCandidateFollowing () {
  return (dispatch: Dispatch) => {
    dispatch(resetCandidateFollowsSuccess());
  };
}

export function requestFollowingCandidates (params: RecruiterFollowParams) {
  return (dispatch: Dispatch) => {
    dispatch(getFollowingCandidateRequest());
    return getFollowingCandidates(params)
      .then((response: AxiosResponse<CandidateDto[]>) => dispatch(getFollowingCandidateSuccess(response.data)))
      .catch(() => dispatch(getFollowingCandidateFailure()));
  };
}

export function addingCandidateToFollowing (params: RecruiterFollowParams) {
  return (dispatch: Dispatch) => {
    dispatch(addCandidateToFollowingRequest());
    return addCandidateToFollow(params)
      .then(() => dispatch(addCandidateToFollowingSuccess()))
      .catch(() => dispatch(addCandidateToFollowingFailure()));
  };
}

export function deletingCandidateFromFollowing (params: RecruiterFollowParams) {
  return (dispatch: Dispatch) => {
    dispatch(deleteCandidateFollowingRequest());
    return deleteCandidateFromFollow(params)
      .then(() => {
        dispatch(deleteCandidateFollowingSuccess(params.candidateId));
        dispatch(getFollowingStatistic(params.recruiterId));
      })
      .catch(() => dispatch(deleteCandidateFollowingFailure()));
  };
}
