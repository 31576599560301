// @flow
import React from 'react';

import { Setting } from './menu-item.setting';

export class SelectMenuItem extends Setting {

  render () {
    return (
      <div
        onClick={event => this.props.onClick(event)}
        className={`menu-item ${this.props.disabled ? 'disabled' : ''}`}>
        {this.props.children}
      </div>
    );
  }

}
