// @flow
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';
import type { RatingType } from 'containers/portal/recruiter/account/settings/company-info/company-info.rating.type';

import { BrandsDto } from 'rest/brand/brand.dto';
import { BranchDto } from 'rest/branch/branch.dto';
import { ImageResponseDto } from 'rest/images/images.response';

import { uploadFile } from 'services/portal/images/images.actions';
import { searchSuggestedAddress } from 'services/portal/address/address.actions';
import { getAllBranches, getAllBrands } from 'services/portal/recruiter/account/account.actions';

import './company-info.scss';

export type Props = {
  t?: TransProps,
  next: () => void,
  imageFetching: boolean,
  brands: BrandsDto[],
  getAllBrands: () => void,
  getAllBranches: () => void,
  branches: BranchDto[],
  imageInfo: ImageResponseDto,
  avatarInfo: ImageResponseDto,
  uploadFile: (file: File, type: string) => void
};

type State = {
  logo: ?File,
  city: string,
  name: string,
  stars: RatingType,
  street: string,
  country: string,
  zipCode: string,
  brandId: string,
  imageSizeError: ?string,
  branchId: BranchDto,
  description: string,
  logoPreview: ?string,
  streetNumber: string,
  branchError: boolean,
  previewPhotos: ImageResponseDto[],
  logoPreviewError: boolean,
  photos: ImageResponseDto[]
}

export class Settings extends Component<Props, State> {

  static defaultProps = {
    next: () => {},
    uploadFile: () => {},
    getAllBrands: () => {},
    getAllBranches: () => {},
    imageFetching: false,
    brands: new BrandsDto(),
    branches: new BranchDto()
  };

  state: State = {
    name: '',
    city: '',
    stars: '',
    logo: null,
    street: '',
    brandId: '',
    zipCode: '',
    country: '',
    photos: [],
    branchId: '',
    description: '',
    imageSizeError: '',
    logoPreview: null,
    streetNumber: '',
    previewPhotos: [],
    logoPreviewError: false,
    branchError: false
  };

  maxPhotosCount: number = 3;
  inputUpload = React.createRef();

}

export const stateToProps = (state: GlobalState) => ({
  address: state.portal.address.content,
  imageInfo: state.portal.images.imageInfo,
  avatarInfo: state.portal.images.avatarInfo,
  imageFetching: state.portal.images.avatarLoading,
  brands: state.portal.recruiter.account.companies.brands,
  branches: state.portal.recruiter.account.companies.branches
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  uploadFile,
  getAllBrands,
  getAllBranches,
  searchSuggestedAddress
}, dispatch);
