// @flow
import { Component } from 'react';

import './link-item.scss';

type Props = {
  to: string,
  title: string,
  value: string,
  children: any,
  candidate?: boolean,
  recruiter?: boolean,
  description?: string,
}

export class Setting extends Component<Props> {

  static defaultProps = {
    title: '',
    value: '',
    candidate: false,
    recruiter: false,
    description: null
  }

}
