import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { connectTranslation } from 'middleware/connect';
import { getLocalization } from 'middleware/localization';

const activeLang = getLocalization();

@connectTranslation()
export class Seo extends Component {

  render () {
    const { t } = this.props;

    return (
      <Helmet
        htmlAttributes={{
          lang: activeLang
        }}
        title={t('seo.title')}
        meta={[
          {
            name: `description`,
            content: t('seo.description')
          },
          {
            property: `og:title`,
            content: t('seo.title')
          },
          {
            property: `og:description`,
            content: t('seo.description')
          },
          {
            property: `og:type`,
            content: t('seo.description')
          },
          {
            name: `twitter:card`,
            content: t('seo.description')
          },
          {
            name: `twitter:creator`,
            content: 'Excellent-Jobs'
          },
          {
            name: `twitter:title`,
            content: t('seo.title')
          },
          {
            name: `twitter:description`,
            content: t('seo.description')
          },
          {
            name: `keywords`,
            content: t('seo.keywords')
          }
        ]}
      />
    );
  }

}

Seo.propTypes = {
  t: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string
};
