// @flow
import { Component } from 'react';

import type { GlobalState } from 'common/types/global-state';

export class Settings extends Component {}

export const stateToProps = (state: GlobalState) => ({
  total: state.portal.candidate.match.jobOffers.totalElements
});
