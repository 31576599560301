export const GET_CHAT_MESSAGES_REQUEST = 'GET_CHAT_MESSAGES_REQUEST';
export const GET_CHAT_MESSAGES_SUCCESS = 'GET_CHAT_MESSAGES_SUCCESS';
export const GET_CHAT_MESSAGES_FAILURE = 'GET_CHAT_MESSAGES_FAILURE';

export const RESET_CHAT_MESSAGES_SUCCESS = 'RESET_CHAT_MESSAGES_SUCCESS';

export const CREATE_CHAT_MESSAGES_REQUEST = 'CREATE_CHAT_MESSAGES_REQUEST';
export const CREATE_CHAT_MESSAGES_SUCCESS = 'CREATE_CHAT_MESSAGES_SUCCESS';
export const CREATE_CHAT_MESSAGES_FAILURE = 'CREATE_CHAT_MESSAGES_FAILURE';

export const NEW_CHAT_MESSAGES_READ = 'NEW_CHAT_MESSAGES_READ';
export const NEW_CHAT_MESSAGES_COMING = 'NEW_CHAT_MESSAGES_COMING';

export const GET_CHAT_UNREAD_MESSAGES = 'GET_CHAT_UNREAD_MESSAGES';
export const RESET_CHAT_UNREAD_MESSAGES = 'RESET_CHAT_UNREAD_MESSAGES';
