// @flow
import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { uploadFile } from 'middleware/upload-file';
import { connectStore } from 'middleware/connect';

import { Card } from 'components/layouts';
import { Modal } from 'components/dialogs';
import { Avatar } from 'components/avatar/avatar';
import { Button, LinkButton } from 'components/buttons';
import { SalaryRange, Spinner } from 'components/elements';
import { AutosuggestSelect, FormGroup, InputControl, PhoneControl } from 'components/controls';

import { ModalHeader } from 'containers/portal/modal-container';

import { ImageDto } from 'rest/images/image.dto';
import { AddressDto } from 'rest/address/address.dto';
import { UserAddressDto } from 'rest/address-dto/address';
import { JobTitleDto } from 'rest/candidates/cv/job-title.dto';
import { SalaryRangeDto } from 'rest/job-offers/job-offers.salary-range.dto';
import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';
import { JobRequirementsDto } from 'rest/candidates/job-requirements/job-requirements.dto';

import type { Props } from './personal-info.setting';
import { dispatchToProps, Settings, stateToProps } from './personal-info.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class PersonalInfoCVModal extends Settings {

  componentDidMount () {
    this.setPersonalInfoData(this.props);
  }

  componentWillReceiveProps (nextProps: Props) {
    if (this.state.redirect !== nextProps.redirect) {
      this.setState({ redirect: nextProps.redirect });
    }
    this.setPersonalInfoData(nextProps);
  }

  setPersonalInfoData (props: Props) {
    const candidate: CurrentCandidateDto = props.currentCandidate;
    const balanceBoard: JobRequirementsDto = props.balanceBoard;
    const candidatePhotos: ImageDto[] = candidate.candidatePhotos || [];
    const avatar = candidatePhotos.length > 0 && candidatePhotos.find(photo => photo.primary);
    if (candidate && props.jobTitle !== this.state.jobTitle) {
      this.setState({
        ...candidate,
        ...candidate.candidateAddress,
        jobTitle: props.jobTitle,
        salary: balanceBoard ? balanceBoard.salary : this.state.salary,
        avatarPreview: this.state.avatarPreview || (avatar && avatar.url),
        relocation: balanceBoard ? balanceBoard.relocate : this.state.relocation
      });
    }
  }

  changePersonalState (field: string, value: string, isError: boolean = false) {
    this.setState({ [field]: value });
    this.errorCollector(field, isError);
  }

  changeAvatarState (file: File) {
    uploadFile(file, (base64, error) => {
      if (error) {
        this.setState({ avatarError: error });
      } else {
        this.setState({
          avatarError: false,
          avatarPreview: base64
        }, () => this.props.uploadFile(file, 'avatar'));
      }
    });
  }

  errorCollector (errorName: string, collect: boolean) {
    const errorCollection: Set<string> = new Set(this.state.errorCollection);
    collect ? errorCollection.add(errorName) : errorCollection.delete(errorName);
    this.setState({ errorCollection });
  }

  setCandidateAvatar (): ImageDto {
    const avatar: ImageDto = new ImageDto();
    const candidate: CurrentCandidateDto = this.props.currentCandidate;
    const primaryAvatar: ImageDto = (candidate.candidatePhotos || []).find(photo => photo.primary);
    avatar.primary = true;
    if (this.props.imageInfo && this.state.avatarPreview) {
      avatar.imageId = this.props.imageInfo.imageId;
      avatar.url = this.props.imageInfo.url;
    } else if (primaryAvatar && this.state.avatarPreview) {
      avatar.imageId = primaryAvatar.imageId;
      avatar.url = primaryAvatar.url;
    } else {
      return null;
    }
    return avatar;
  }

  sendPersonalInfo () {
    const candidateInfo: CurrentCandidateDto = new CurrentCandidateDto(this.state);
    const photos: ImageDto = this.setCandidateAvatar();
    candidateInfo.candidateAddress = new UserAddressDto(this.state);
    candidateInfo.candidatePhotos = photos && [ photos ];
    this.props.updateCurrentCandidate(this.props.currentCandidate.id, candidateInfo);
  }

  getCollectionIds (collection: []): string[] {
    return collection.map(item => item.id);
  }

  updateCandidateInfo () {
    if (!this.state.errorCollection.size && !this.props.avatarLoading) {
      this.sendPersonalInfo();
    }
  }

  suggestCountry (value: string) {
    const query: AddressDto = new AddressDto();
    query.city = '';
    query.street = '';
    query.country = value;
    this.props.searchSuggestedAddress('country', query);
  }

  suggestCity (value: string) {
    const query: AddressDto = new AddressDto(this.state);
    query.street = '';
    query.city = value;
    this.props.searchSuggestedAddress('city', query);
  }

  updateJobTitle () {
    if (this.state.jobTitle) {
      const data: JobTitleDto = new JobTitleDto(this.state);
      this.props.updateCandidateJobTitle(this.props.currentCandidate.id, data);
    }
  }

  updateBalanceBoard () {
    const candidateId: string = this.props.currentCandidate.id;
    if (this.props.balanceBoard) {
      const data: JobRequirementsDto = new JobRequirementsDto(this.props.balanceBoard);
      data.salary = this.state.salary;
      data.relocation = this.state.relocation;
      data.jobTypes = this.props.balanceBoard.jobTypes;
      data.availableFrom = this.props.balanceBoard.availableFrom;
      data.unitIds = this.getCollectionIds(this.props.balanceBoard.units);
      data.branchIds = this.getCollectionIds(this.props.balanceBoard.branches);
      data.jobTitleIds = this.getCollectionIds(this.props.balanceBoard.jobTitles);
      data.workingHoursPerMonth = this.props.balanceBoard.salary.workingHoursPerMonth;
      this.props.updateCandidateBalanceBoard(candidateId, data);
    } else {
      const data: JobRequirementsDto = new JobRequirementsDto(this.state);
      data.jobTypes = [ 'Employee' ];
      this.props.createCandidateBalanceBoard(candidateId, data);
    }
  }

  savePersonalInfo (event: Event) {
    event.preventDefault();
    if (!this.state.salaryError) {
      this.updateJobTitle();
      this.updateCandidateInfo();
      this.updateBalanceBoard();
      toast.success(this.props.t('portal.candidate.notifications.personal-updated'));
    }
  }

  render () {
    const backLink: string = `/portal/candidate/cv`;

    return (
      <Fragment>
        {this.state.redirect && <Redirect to={backLink}/>}
        <Modal closePath={backLink}>
          <div className="cv-modal mobile-cv-modal">
            <PerfectScrollbar className="content">
              <ModalHeader
                backLinkTitle={this.props.t('portal.candidate.cv.personal.back')}
                backLinkUrl={backLink}
                title={this.props.t('portal.candidate.cv.personal.title')}
                className="mobile-modal-header mobile-no-space"/>
              <form
                onSubmit={this.savePersonalInfo.bind(this)}
                className="candidate-info-form">
                <Card className="personal-info-content">
                  <Avatar
                    change={this.changeAvatarState.bind(this)}
                    clearImageState={() => this.setState({ avatarPreview: '' })}
                    error={!!this.state.avatarError}
                    errorText={this.state.avatarError}
                    loading={this.props.avatarLoading}
                    previewImageUrl={this.state.avatarPreview || '/images/shared/userpick.jpg'}
                    uploadButtonName={this.props.t('portal.candidate.cv.personal.image')}
                    uploadButtonType="primary outlined"
                  />
                  <div className="personal-info-form-fields">
                    <FormGroup className="half-width">
                      <InputControl
                        maxLength={35}
                        label={this.props.t('portal.candidate.cv.personal.first-name.label')}
                        value={this.state.firstName}
                        change={(value: string, error: boolean): void =>
                          this.changePersonalState('firstName', value, error)}
                        placeholder={this.props.t('portal.candidate.cv.personal.first-name.placeholder')}
                        pattern=".+"
                        required
                        errorText={this.props.t('portal.candidate.cv.personal.first-name.error')}
                        type="text"/>
                    </FormGroup>
                    <FormGroup className="half-width">
                      <InputControl
                        maxLength={35}
                        label={this.props.t('portal.candidate.cv.personal.last-name.label')}
                        value={this.state.lastName}
                        change={(value: string, error: boolean): void =>
                          this.changePersonalState('lastName', value, error)}
                        placeholder={this.props.t('portal.candidate.cv.personal.last-name.placeholder')}
                        pattern=".+"
                        required
                        errorText={this.props.t('portal.candidate.cv.personal.last-name.error')}
                        type="text"/>
                    </FormGroup>
                    <FormGroup className="full-width job-title-field">
                      <InputControl
                        label={this.props.t('portal.candidate.cv.personal.job-title.label')}
                        value={this.state.jobTitle}
                        change={(value: string, error: boolean): void =>
                          this.changePersonalState('jobTitle', value, error)}
                        placeholder={this.props.t('portal.candidate.cv.personal.job-title.placeholder')}
                        pattern=".+"
                        errorText={this.props.t('portal.candidate.cv.personal.job-title.error')}
                        type="text"/>
                    </FormGroup>
                    <FormGroup className="half-width">
                      <AutosuggestSelect
                        required
                        label={this.props.t('portal.candidate.cv.personal.country.label')}
                        errorText={this.props.t('portal.candidate.cv.personal.country.error')}
                        items={this.props.address.country}
                        defaultValue={this.state.country}
                        change={(value: string) => this.setState({ country: value })}
                        search={(value: string) => this.suggestCountry(value)}
                        placeholder={this.props.t('portal.candidate.cv.personal.country.placeholder')}/>
                    </FormGroup>
                    <FormGroup className="half-width">
                      <AutosuggestSelect
                        required
                        label={this.props.t('portal.candidate.cv.personal.city.label')}
                        errorText={this.props.t('portal.candidate.cv.personal.city.error')}
                        items={this.props.address.city}
                        defaultValue={this.state.city}
                        change={(value: string) => this.setState({ city: value })}
                        search={(value: string) => this.suggestCity(value)}
                        placeholder={this.props.t('portal.candidate.cv.personal.city.placeholder')}/>
                    </FormGroup>
                    <FormGroup className="half-width">
                      <InputControl
                        disabled
                        readonly
                        title="some-email@gmail.com"
                        label={this.props.t('portal.candidate.cv.personal.email.label')}
                        value={this.state.email}
                        required
                        change={(value: string, error: boolean): void =>
                          this.changePersonalState('email', value, error)}
                        placeholder={this.props.t('portal.candidate.cv.personal.email.placeholder')}
                        errorText={this.props.t('portal.candidate.cv.personal.email.error')}
                        type="email"/>
                    </FormGroup>
                    <FormGroup className="half-width">
                      <PhoneControl
                        required={false}
                        label={this.props.t('portal.candidate.cv.personal.phone.label')}
                        defaultValue={this.state.phoneNumber}
                        change={(phoneNumber: string) => this.setState({ phoneNumber })}/>
                    </FormGroup>
                  </div>
                </Card>
                <Card className="personal-info-content">
                  <h4>
                    <Trans i18nKey="portal.candidate.cv.personal.additional">
                      Additional
                    </Trans>
                  </h4>
                  <FormGroup className="full-width board-form-field">
                    <label className="form-label">
                      <Trans i18nKey="portal.candidate.cv.personal.relocation.label">
                        Relocation
                      </Trans>
                    </label>
                    <Button
                      outlined={!this.state.relocation}
                      onClick={() => this.setState({ relocation: true })}
                      className="default element"
                      type="button">
                      <Trans i18nKey="portal.candidate.cv.personal.relocation.able">
                        Able to relocate
                      </Trans>
                    </Button>
                    <Button
                      outlined={this.state.relocation}
                      onClick={() => this.setState({ relocation: false })}
                      className="default element"
                      type="button">
                      <Trans i18nKey="portal.candidate.cv.personal.relocation.not-able">
                        Disable
                      </Trans>
                    </Button>
                  </FormGroup>
                  <SalaryRange
                    required={false}
                    defaultValue={this.state.salary}
                    change={(salary: SalaryRangeDto, salaryError) => this.setState({ salary, salaryError })}
                    className="mobile-salary-range-container"/>
                </Card>
                <Card className="personal-info-content mobile-actions">
                  <div className="cv-actions">
                    <Button
                      candidate
                      medium
                      disabled={this.props.avatarLoading}
                      type="submit">
                      <Trans i18nKey="portal.candidate.cv.personal.buttons.save">
                        Save Changes
                      </Trans>
                    </Button>
                    <LinkButton
                      grey
                      link={backLink}>
                      <Trans i18nKey="portal.candidate.cv.personal.buttons.cancel">
                        Cancel
                      </Trans>
                    </LinkButton>
                  </div>
                </Card>
              </form>
              <Spinner show={this.props.fetching}/>
            </PerfectScrollbar>
          </div>
        </Modal>
      </Fragment>
    );
  }

}
