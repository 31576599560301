import * as actions from './images.constants';

import type { ImagesState } from './images.type';

const initialState: ImagesState = {
  image: null,
  avatarInfo: null,
  imageInfo: null,
  imageLoading: false,
  avatarLoading: false
};

export default (state = initialState, action): ImagesState => {
  switch (action.type) {

    case actions.UPLOAD_FILE_REQUEST:
      return {
        ...state,
        [action.imageType + 'Loading']: true
      };

    case actions.UPLOAD_FILE_FAILURE:
      return {
        ...state,
        [action.imageType + 'Loading']: false
      };

    case actions.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        [action.imageType + 'Info']: action.fileInfo,
        [action.imageType + 'Loading']: false
      };

    default:
      return state;
  }
};
