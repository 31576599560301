export const GET_FOLLOWING_CANDIDATE_REQUEST = 'GET_FOLLOWING_CANDIDATE_REQUEST';
export const GET_FOLLOWING_CANDIDATE_FAILURE = 'GET_FOLLOWING_CANDIDATE_FAILURE';
export const GET_FOLLOWING_CANDIDATE_SUCCESS = 'GET_FOLLOWING_CANDIDATE_SUCCESS';

export const ADD_FOLLOWING_CANDIDATE_REQUEST = 'ADD_FOLLOWING_CANDIDATE_REQUEST';
export const ADD_FOLLOWING_CANDIDATE_FAILURE = 'ADD_FOLLOWING_CANDIDATE_FAILURE';
export const ADD_FOLLOWING_CANDIDATE_SUCCESS = 'ADD_FOLLOWING_CANDIDATE_SUCCESS';

export const DELETE_FOLLOWING_CANDIDATE_REQUEST = 'DELETE_FOLLOWING_CANDIDATE_REQUEST';
export const DELETE_FOLLOWING_CANDIDATE_FAILURE = 'DELETE_FOLLOWING_CANDIDATE_FAILURE';
export const DELETE_FOLLOWING_CANDIDATE_SUCCESS = 'DELETE_FOLLOWING_CANDIDATE_SUCCESS';

export const RESET_FOLLOWING_CANDIDATE_SUCCESS = 'RESET_FOLLOWING_CANDIDATE_SUCCESS';

export const CHANGE_FOLLOWING_CANDIDATE_FILTER_SUCCESS = 'CHANGE_FOLLOWING_CANDIDATE_FILTER_SUCCESS';
