// @flow
import React, { Fragment } from 'react';

import type { Props } from './languages-switcher.setting';

import { Setting } from './languages-switcher.setting';

export class LanguagesSwitcher extends Setting {

  static getDerivedStateFromProps (nextProps: Props) {
    if (nextProps.value && nextProps.value !== '') {
      return { selectedItem: nextProps.value };
    }
    return null;
  }

  selectItem (selectedItem: string) {
    this.setState({
      selectedItem,
      listOpen: false
    }, () => this.props.change(selectedItem));
  }

  render () {
    return (
      <Fragment>
        <div className="languages-dropdown-container">
          {
            this.state.listOpen && <div
              className="outside"
              onClick={() => this.setState({ listOpen: false })}
            />
          }
          <div className="filter-container">
            <div
              className="languages-dropdown"
              onClick={() => this.setState(prevState => ({ listOpen: !prevState.listOpen }))}>
              <span className="filter-label-value">
                <img
                  className="filter-label-img"
                  src={`/images/shared/${this.state.selectedItem || this.props.value}-flag.svg`}
                  alt={this.state.selectedItem}/>
              </span>
              <i className="filter-label-icon"/>
            </div>

            {
              this.state.listOpen && <div className="languages-options">
                <ul className="languages-list">
                  {
                    this.props.items.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => this.selectItem(item)}>
                        <img
                          src={`/images/shared/${item}-flag.svg`}
                          alt={item}/>
                      </li>
                    ))
                  }
                </ul>
              </div>
            }
          </div>
        </div>
      </Fragment>
    );
  }

}
