// @flow
import * as actions from './followers.constants';

import { CandidateDto } from 'rest/candidates/candidate.dto';

import type { FollowersCandidatesType } from './followers.type';

const initialState: FollowersCandidatesType = {
  unitId: '',
  content: [],
  fetching: false,
  currentPage: 0,
  hasMoreItems: true,
  totalElements: 0
};

export default function (state: FollowersCandidatesType = initialState, action) {
  let content = [];
  switch (action.type) {
    case actions.GET_FOLLOWERS_CANDIDATE_REQUEST:
      return {
        ...state,
        fetching: true,
        hasMoreItems: false
      };

    case actions.GET_FOLLOWERS_CANDIDATE_SUCCESS:
      content = action.payload.content.map(invite => new CandidateDto(invite));
      return {
        ...state,
        fetching: false,
        content: action.payload.number ? [ ...state.content, ...content ] : content,
        currentPage: action.payload.number + 1,
        hasMoreItems: action.payload.content.length > 0,
        totalElements: action.payload.totalElements
      };

    case actions.GET_FOLLOWERS_CANDIDATE_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.RESET_FOLLOWERS_CANDIDATE_SUCCESS:
      return {
        ...state,
        content: [],
        fetching: false,
        currentPage: 0,
        hasMoreItems: true,
        totalElements: 0
      };

    case actions.CHANGE_FOLLOWERS_CANDIDATE_FILTER_SUCCESS:
      return {
        ...initialState,
        unitId: action.unitId
      };

    default:
      return state;
  }
}
