// @flow
import React from 'react';
import { connect } from 'react-redux';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { Card } from 'components/layouts';

import { JobOfferModalPreview } from 'containers/portal/recruiter/job-offer';

import { JobOfferViewedDto } from 'rest/job-offers/viewed/viewed.dto';

import type { Props } from './preview-container.setting';
import { Settings, dispatchToProps, stateToProps } from './preview-container.setting';

@connect(stateToProps, dispatchToProps)
export class PreviewContainer extends Settings {

  componentDidMount () {
    this.props.getJobOfferWithSkills(this.props.match.params.jobOfferId);
    this.markJobOfferAsViewed();
  }

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.fetchJobOffer !== this.state.fetchJobOffer) {
      this.props.getJobOfferWithSkills(this.props.match.params.jobOfferId);
      this.setState({ fetchJobOffer: nextProps.fetchJobOffer });
    }
  }

  componentDidUpdate (prevProps: Props) {
    if (prevProps.currentCandidate.id !== this.props.currentCandidate.id) {
      this.markJobOfferAsViewed();
    }
  }

  componentWillUnmount () {
    this.props.resetJobOffer();
  }

  markJobOfferAsViewed () {
    if (this.props.currentCandidate.id) {
      const data: JobOfferViewedDto = new JobOfferViewedDto();
      data.jobOfferId = this.props.match.params.jobOfferId;
      data.link = document.referrer;
      this.props.markJobOfferAsViewedStats(data.jobOfferId, data);
      this.markMatchedJobOfferAsView();
    }
  }

  markMatchedJobOfferAsView () {
    if (this.props.match.params.url === 'match') {
      this.props.saveMatchedJobOfferAsViewed(this.props.currentCandidate.id, this.props.match.params.jobOfferId);
    }
  }

  render () {
    return (
      <div className="job-offer-preview-modal mobile-job-offer-preview-modal">
        <Card className="job-offer-preview-container job-offer-preview-details-modal">
          <div className="job-offer-preview-content">
            <PerfectScrollbar className="job-offer-preview">
              <JobOfferModalPreview
                jobOffer={this.props.jobOffer}
                backLink={this.props.match.params.url}
                locationPath={this.props.location.pathname}
                jobOfferSkills={this.props.jobOfferSkills}/>
            </PerfectScrollbar>
          </div>
        </Card>
      </div>
    );
  }

}
