// @flow
import React, { Fragment } from 'react';

import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroller';

import { connectStore } from 'middleware/connect';
import { PaginationDto } from 'middleware/dto/pagination';

import { ElementsList } from 'components/elements-list';
import { ListPlaceholder } from 'components/elements';

import { CalendarResponseDto } from 'rest/companies/candidate-invites/candidate-invites-response.dto';

import { InviteRow } from './../invite-row/invite-row';

import { stateToProps, dispatchToProps, Setting } from './invites-list.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class InvitesList extends Setting {

  componentWillUnmount () {
    this.props.resetAllCalendarInvites();
  }

  loadInvitesList (page: number = 0) {
    const params = new PaginationDto();
    params.page = page;
    this.props.getAllCalendarInvites(this.props.currentUser.companyId, this.props.invitesStatus, params);
  }

  deleteInvite (id: string) {
    this.props.removeCalendarInvite(this.props.currentUser.companyId, id);
    toast.success(this.props.t('portal.recruiter.notifications.invite-deleted'));
  }

  initInvitesList (): HTMLElement {
    return this.props.invites.map((invite: CalendarResponseDto, index) => (
      <InviteRow
        key={index}
        onClick={(inviteId: string, candidateId) => this.props.history.push(
          `/portal/recruiter/calendar/${inviteId}/profile/${candidateId}`
        )}
        onDelete={(id: string) => this.deleteInvite(id)}
        invite={invite}>
      </InviteRow>
    ));
  }

  initEmptyList () : HTMLElement {
    return !this.props.invites.length ? (
      <ListPlaceholder
        title={this.props.t('portal.recruiter.calendar.list.placeholder.title')}>
        <Trans i18nKey="portal.recruiter.calendar.list.placeholder.content">
          All candidates you invite for an interview will be listed here.
        </Trans>
      </ListPlaceholder>
    ) : null;
  }

  render () {
    return (
      <Fragment>
        {
          this.props.currentUser.companyId && <InfiniteScroll
            useWindow={false}
            threshold={100}
            pageStart={-1}
            hasMore={this.props.hasMoreItems}
            loadMore={() => this.loadInvitesList(this.props.currentPage)}>
            <ElementsList>{this.initInvitesList()}</ElementsList>
            {this.initEmptyList()}
          </InfiniteScroll>
        }
      </Fragment>
    );
  }

}
