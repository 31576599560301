// @flow
import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';

import type { Props } from './payment.setting';

import { connectStore } from 'middleware/connect';
import { setData } from 'middleware/payment-step';

import { Button, LinkButton } from 'components/buttons';
import { FormGroup, InputControl, RadioControl, RadioGroup } from 'components/controls';

import PromoCode from 'containers/portal/recruiter/promo-code/promo-code';
import { PaymentCard } from './payment-card/payment-card';
import { PaymentOptions } from './payment-options/payment-options';
import { SepaPaymentModal } from 'containers/portal/recruiter/sepa-payment-modal/sepa-payment-modal';
import { PaymentInvoiceInfo } from 'containers/portal/recruiter/payment-invoice-info/payment-invoice-info';

import { SubscriptionDto } from 'rest/companies/subscription/subscription.dto';
import { CompanyDto } from 'rest/companies/companies.dto';

import { Settings, dispatchToProps, stateToProps } from './payment.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class Payment extends Settings {

  componentDidMount () {
    const {
      currentUser: { companyId }, getCompanyPremiumCostRequest, getRecruiterCompanyById
    } = this.props;
    const GET_COMPANY_SUCCESS = 'GET_COMPANY_BY_ID_SUCCESS';

    this.subscribeCompany();
    getCompanyPremiumCostRequest(companyId);
    getRecruiterCompanyById(companyId).then(res =>
      this.setState({
        isCompanyInfoLoaded: res.type === GET_COMPANY_SUCCESS
      }));
  }

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.redirectLink !== this.state.redirectLink) {
      this.setState({ redirectLink: nextProps.redirectLink });
    }
  }

  componentDidUpdate (prevProps: Props, prevState) {
    if (this.props.currentUser.id !== prevProps.currentUser.id) {
      this.subscribeCompany();
      this.updateCompany();
    }
  }

  changeSubscriptionPlan (value: string) {
    this.setState({
      subscriptionPlan: value,
      redirectLink: value === 'FREE' ? '' : this.props.redirectLink
    }, () => this.subscribeCompany());
  }

  changeSubscriptionMethod (value: string) {
    this.setState({
      paymentMethod: value,
      subscriptionPlan: 'PREMIUM'
    });
  }

  subscribeCompany () {
    const subscriptionData: SubscriptionDto = new SubscriptionDto(this.state);
    this.props.subscribeRecruiterCompany(this.props.currentUser.companyId, subscriptionData);
  }

  updateCompany (data) {
    const {
      currentUser: { companyId }, company, updateRecruiterCompany, getRecruiterCompanyById
    } = this.props;
    const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
    const GET_COMPANY_SUCCESS = 'GET_COMPANY_BY_ID_SUCCESS';

    updateRecruiterCompany(companyId, data || company)
      .then(res => {
        if (res.type !== UPDATE_COMPANY_SUCCESS) return;

        getRecruiterCompanyById(companyId).then(res =>
          this.setState({
            isCompanyInfoLoaded: res.type === GET_COMPANY_SUCCESS
          }));
      });
  }

  updateVatId = () => {
    const { currentUser: { companyId }, company, updateRecruiterCompany } = this.props;
    const { vatId } = this.state;

    const companyData: CompanyDto = {
      ...company,
      vatId
    };

    updateRecruiterCompany(companyId, companyData);
  };

  handleSepaPaymentForm = () => {
    this.setState({
      isSepaModalOpen: !this.state.isSepaModalOpen
    });
  };

  handleEditableState = value => {
    this.setState({
      isCompanyInfoEditable: !this.state.isCompanyInfoEditable
    });

    if (value) {
      this.updateCompany(value);
    }
  };

  handleChangePromoCode = value => {
    this.setState({
      promoCode: value,
      promoCodeIsChange: true,
      promoCodeIsFailed: false
    });
  };

  applyPromoCode = () => {
    const { currentUser: { companyId }, subscribeRecruiterCompany } = this.props;
    const { promoCode, paymentMethod, paymentRedirectUrls } = this.state;

    const CompanySubscriptionPlan: SubscriptionDto = new SubscriptionDto({
      promoCode,
      paymentMethod,
      paymentRedirectUrls,
      subscriptionPlan: 'PREMIUM'
    });

    subscribeRecruiterCompany(companyId, CompanySubscriptionPlan)
      .then(res => {
        const { amount, months } = res.link;
        const promoCodeIsValid = months > 0;

        if (!promoCodeIsValid) {
          this.setState({
            promoCodeIsFailed: true
          });
          return;
        }

        if (months === 999) {
          this.setState({
            subscriptionPlan: 'FREE'
          });
          return;
        }

        this.setState({
          amount,
          months,
          promoCodeIsValid,
          promoCodeIsChange: false
        });
      });
  };

  handleOnSubmit = e => {
    if (e) e.preventDefault();

    const { match: { path } } = this.props;
    const { subscriptionPlan, vatId, paymentMethod } = this.state;
    const subPlanIsFree = subscriptionPlan === 'FREE';

    if (subPlanIsFree) {
      this.setState({
        confirmPayment: true
      });
      return;
    }

    if (!vatId) {
      this.setState({
        vatIdError: true
      });
      return;
    }

    this.setState(
      { isSepaModalOpen: paymentMethod === 'SEPA_DIRECT_DEBIT' }
    );

    setData('ej_payment_step_link', {
      path,
      activeTab: 3
    });
    this.updateVatId();
  };

  render () {
    const {
      subscriptionPlan, paymentMethod, paymentRedirectUrls, isCompanyInfoLoaded, isCompanyInfoEditable,
      vatId, vatIdError, promoCode, promoCodeIsValid, promoCodeIsFailed, promoCodeIsChange,
      months, amount, isSepaModalOpen, confirmPayment, redirectLink
    } = this.state;
    const { t, prev, price, currentUser, company } = this.props;

    const subPlanIsFree = subscriptionPlan === 'FREE';
    const subscriptionPrice: number = subPlanIsFree ? 0 : amount;
    const paymentMethodDescription: string = paymentMethod === 'SOFORT' ? ' Sofort' : ' Sepa Direct';
    const paymentMethodDetails: string = subPlanIsFree
      ? 'Free/Basic'
      : (promoCodeIsValid
        ? `Premium Plan with special offer for the first ${months} month${months > 1 ? 's' : ''}`
        : 'Premium Plan');

    return (
      <Fragment>
        {confirmPayment && <Redirect to='/portal/recruiter/account/payment/free'/>}

        <div className="payment">
          <h3 className="payment-caption">
            <Trans i18nKey="portal.recruiter.account.tabs.payment.header">
              Your Perfect Plan
            </Trans>
          </h3>
          <p className="payment-description">
            <Trans i18nKey="portal.recruiter.account.tabs.payment.description">
              Find the perfect plan for you — 100% satisfaction guaranteed.
            </Trans>
          </p>
          <RadioGroup
            className="card-container"
            change={this.changeSubscriptionPlan.bind(this)}
            defaultValue={subscriptionPlan}>
            <RadioControl value="PREMIUM" custom>
              <PaymentCard
                selected={subscriptionPlan === 'PREMIUM'}
                price={price}
                title={t('portal.recruiter.account.tabs.payment.premium.title')}>
                <li>{t('portal.recruiter.account.tabs.payment.premium.points.0')}</li>
                <li>{t('portal.recruiter.account.tabs.payment.premium.points.1')}</li>
                <li>{t('portal.recruiter.account.tabs.payment.premium.points.2')}</li>
                <li>{t('portal.recruiter.account.tabs.payment.premium.points.3')}</li>
                <li>{t('portal.recruiter.account.tabs.payment.premium.points.4')}</li>
                <li>{t('portal.recruiter.account.tabs.payment.premium.points.5')}</li>
              </PaymentCard>
            </RadioControl>
            <RadioControl value="FREE" custom>
              <PaymentCard
                selected={subPlanIsFree}
                priceCondition={t('portal.recruiter.account.tabs.payment.free.price')}
                title={t('portal.recruiter.account.tabs.payment.free.title')}>
                <li>{t('portal.recruiter.account.tabs.payment.free.points.0')}</li>
                <li>{t('portal.recruiter.account.tabs.payment.free.points.1')}</li>
                <li>{t('portal.recruiter.account.tabs.payment.free.points.2')}</li>
                <li>{t('portal.recruiter.account.tabs.payment.free.points.3')}</li>
                <li>{t('portal.recruiter.account.tabs.payment.free.points.4')}</li>
              </PaymentCard>
            </RadioControl>
          </RadioGroup>
          <PaymentOptions
            disabled={subPlanIsFree}
            defaultOption={paymentMethod}
            changeOptions={value => this.changeSubscriptionMethod(value)}/>
          <SepaPaymentModal
            isOpen={isSepaModalOpen}
            companyId={currentUser.companyId}
            currentUser={currentUser}
            paymentMethod={paymentMethod}
            paymentRedirectUrls={paymentRedirectUrls}
            prev={prev}
            closeModal={this.handleSepaPaymentForm}
          />

          <div className={`payment-invoice-details${subPlanIsFree ? ' disabled' : ''}`}>
            <h4 className="payment-invoice-details-title">Invoice Details</h4>

            <form className="vat-id-form" onSubmit={this.handleOnSubmit}>
              <FormGroup className="half-width">
                <InputControl
                  type="text"
                  name="vatId"
                  value={vatId}
                  label={t('portal.guest.recruiter.job-offer.payment.invoice-details.vat-id.label')}
                  placeholder={t('portal.guest.recruiter.job-offer.payment.' +
                    'invoice-details.vat-id.placeholder')}
                  required={!subPlanIsFree}
                  error={vatIdError && !vatId}
                  errorText={t('portal.guest.recruiter.job-offer.payment.invoice-details.vat-id.error')}
                  change={vatId => this.setState({ vatId })}/>
              </FormGroup>
            </form>

            {isCompanyInfoLoaded && <PaymentInvoiceInfo
              company={company}
              editable={isCompanyInfoEditable}
              disabled={subPlanIsFree}
              handleEditableState={this.handleEditableState}
            />}

            <PromoCode
              t={t}
              value={promoCode}
              isValid={promoCodeIsValid}
              isFailed={promoCodeIsFailed}
              isChange={promoCodeIsChange}
              isCompanyInfoEditable={isCompanyInfoEditable}
              handleChange={this.handleChangePromoCode}
              applyPromoCode={this.applyPromoCode}
            />
          </div>

          <div className={`payment-footer${isCompanyInfoEditable ? ' disabled' : ''}`}>
            <div className="subscription-plan">
              {t('portal.guest.recruiter.job-offer.payment.subscription')}: {paymentMethodDetails}
              <span>&euro;{subscriptionPrice}</span>
            </div>
            <div className="payment-action half-width">
              <Button
                className="btn accent grey flex-basis-auto"
                type="button"
                onClick={() => prev()}>
                <Trans i18nKey="portal.guest.recruiter.job-offer.payment.buttons.back">
                  Back
                </Trans>
              </Button>
              {paymentMethod === 'SOFORT' && vatId
                ? <LinkButton
                  className="btn accent large"
                  link={redirectLink || '/portal/recruiter/account/payment/free'}
                  external={true}
                  secondary
                  onClick={this.updateVatId}>
                  <Trans i18nKey="portal.guest.recruiter.job-offer.payment.buttons.confirm">
                    Confirm Payment
                  </Trans>
                </LinkButton>
                : <Button
                  className="btn accent large"
                  type="submit"
                  onClick={e => this.handleOnSubmit(e)}>
                  <Trans i18nKey="portal.guest.recruiter.job-offer.payment.buttons.confirm">
                    Confirm Payment
                  </Trans>
                </Button>
              }
            </div>
            <p className="payment-policy">
              <Trans i18nKey="portal.guest.recruiter.job-offer.payment.buttons.confirm">
                By clicking ‘Confirm Payment’ you’ll be<br/> redirected to the
              </Trans>{ paymentMethodDescription } website for payment.
            </p>
          </div>
        </div>
      </Fragment>
    );
  }

}
