import React, { Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { RegistrationModal } from 'components/dialogs';
import { Trans } from 'react-i18next';
import { getLocalization } from 'middleware/localization';
import {
  setRegistrationEmail,
  getRegistrationEmail,
  resetRegistrationEmail
} from 'middleware/registration-email';
import { connectStore } from 'middleware/connect';

import { Button } from 'components/buttons';
import { Spinner } from 'components/elements';

import { RecruiterDto } from 'rest/recruiter/recruiter.dto';

import { InputControl, FormGroup, CheckboxControl } from 'components/controls';

import type { Props, State } from './sign-up-form.setting';
import { dispatchToProps, Settings, stateToProps } from './sign-up-form.setting';

const language = getLocalization();
@connectStore(stateToProps, dispatchToProps, true)
export class SignUpForm extends Settings {

  componentDidMount () {
    this.setState({
      email: getRegistrationEmail()
    });
  }

  componentWillReceiveProps (nextProps: Props, prevState: State) {
    if (nextProps.errorText !== prevState.errorText) {
      this.setState({
        errorText: nextProps.errorText
      });
    }
  }

  changeRequiterState (field: string, value: string | boolean, isError: boolean = false) {
    if (field === 'email') {
      setRegistrationEmail(value);
      this.setState({
        [field]: getRegistrationEmail(),
        errorText: ''
      });
    } else {
      this.setState({
        [field]: value,
        errorText: ''
      });
    }
    this.errorCollector(field, isError);
  }

  errorCollector (errorName: string, collect: boolean) {
    const errorCollection: Set<string> = new Set(this.state.errorCollection);
    collect ? errorCollection.add(errorName) : errorCollection.delete(errorName);
    this.setState({
      errorCollection
    });
  }

  registerRecruiter (event: Event) {
    event.preventDefault();
    if (!this.state.errorCollection.size) {
      const recruiterData = new RecruiterDto(this.state);
      this.props.recruiterSignUp(recruiterData);
      resetRegistrationEmail();
    }
  }

  render () {
    const { t } = this.props;
    const emailError = this.state.errorText !== '' || this.state.errorCollection.has('email');
    return (
      <Fragment>
        {
          this.props.redirect && <Redirect to="/auth/recruiter/sign-up/confirm"/>
        }
        <div className="sign-up-container">
          <form onSubmit={this.registerRecruiter.bind(this)}>
            <FormGroup>
              <InputControl
                value={this.state.email}
                error={emailError}
                required
                icon
                title={t('auth.sign-up.employer.email.note')}
                change={(value: string, error: boolean): void => this.changeRequiterState('email', value, error)}
                label={t('auth.sign-up.employer.email.label')}
                placeholder={t('auth.sign-up.employer.email.placeholder')}
                errorText={this.state.errorText || t('auth.sign-up.employer.email.error')}
                type="email"
                autoComplete={false}/>
            </FormGroup>
            <FormGroup>
              <InputControl
                minLength={6}
                maxLength={32}
                required
                icon
                value={this.state.password}
                change={(value: string, error: boolean): void => this.changeRequiterState('password', value, error)}
                label={t('auth.sign-up.employer.password.label')}
                notes={t('auth.sign-up.employer.password.note')}
                title={t('auth.sign-up.employer.password.note')}
                placeholder={t('auth.sign-up.employer.password.placeholder')}
                errorText={t('auth.sign-up.employer.password.error')}
                type="password"/>
            </FormGroup>
            <FormGroup>
              <InputControl
                minLength={6}
                maxLength={32}
                required
                icon
                pattern={this.state.password}
                value={this.state.passwordCheck}
                change={(value: string, error: boolean): void =>
                  this.changeRequiterState('passwordCheck', value, error)
                }
                title={t('auth.sign-up.employer.repeatPassword.note')}
                label={t('auth.sign-up.employer.repeatPassword.label')}
                placeholder={t('auth.sign-up.employer.repeatPassword.placeholder')}
                errorText={t('auth.sign-up.employer.repeatPassword.error')}
                type="password"/>
            </FormGroup>
            <FormGroup>
              <CheckboxControl
                className="accent"
                value={this.state.acceptRules}
                change={(acceptRules: boolean): void => this.setState({ acceptRules })}>
                <Trans i18nKey="auth.sign-up.employer.agree-rules.title">
                  I agree with ExcellentJobs
                </Trans>
                <Link to="/information/privacy-policy">
                  <Trans i18nKey="auth.sign-up.employer.agree-rules.privacy">
                    Privacy Policy
                  </Trans>
                </Link>
                <Trans i18nKey="auth.sign-up.employer.agree-rules.and">
                  and
                </Trans>
                <Link to="/information/privacy-policy#terms-and-conditions">
                  <Trans i18nKey="auth.sign-up.employer.agree-rules.terms">
                    Terms of Use
                  </Trans>
                </Link>
                <Trans i18nKey="auth.sign-up.employer.agree-rules.portal">
                </Trans>
              </CheckboxControl>
            </FormGroup>
            <FormGroup>
              <CheckboxControl
                className="accent"
                value={this.state.acceptNotification}
                change={(acceptNotification: boolean): void => this.setState({ acceptNotification })}
                label={t('auth.sign-up.employer.email-notification')}/>
            </FormGroup>
            <FormGroup>
              <Button
                type="submit"
                recruiter
                fullWidth
                disabled={!this.state.acceptRules}>
                <Trans i18nKey="auth.sign-up.employer.button">
                  Sign Up
                </Trans>
              </Button>
            </FormGroup>
          </form>
          <Spinner show={this.props.fetching}/>
        </div>
        {language === 'de' && <RegistrationModal recruiter={true}/>}
      </Fragment>
    );
  }

}
