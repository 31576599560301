// @flow
import { Component } from 'react';

type Props = {
  value: string,
  format: string
};

export class Setting extends Component<Props> {

  static defaultProps: Props = {
    value: '',
    format: 'MMM Do YY'
  };

}
