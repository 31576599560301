import React from 'react';
import { Link } from 'react-router-dom';

import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';

import { Card } from 'components/layouts';
import { Button } from 'components/buttons';

import { Footer } from 'containers/portal';

import { Props } from './free-payment.setting';

import { Settings, stateToProps, dispatchToProps } from './free-payment.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class FreePayment extends Settings {

  componentDidMount () {
    this.props.getCurrentCompanyRecruiter();
    this.props.deleteSavedJobOfferData();
    this.setRedirectLink();
  }

  setRedirectLink () {
    const candidatePreviewLink: string = localStorage.getItem('ej_candidate_preview_link');
    this.setState({ redirectLink: candidatePreviewLink || '' });
  }

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.currentUser !== this.props.currentUser) {
      this.props.activateCompanyRecruiter(nextProps.currentUser.id);
    }
  }

  render () {
    return (
      <div className="free-payment-container">
        <Card>
          <div className="free-payment-content">
            <h2>
              <Trans i18nKey="portal.recruiter.account.payment.free.header">
                Congratulations!
              </Trans>
            </h2>
            <p className="success-text">
              <Trans i18nKey="portal.recruiter.account.payment.free.title">
                Registration was successful
              </Trans>
            </p>
            <p className="payment-description">
              <Trans i18nKey="portal.recruiter.account.payment.free.description">
                Now you can use the limited service functionality, at any time the subscription plan can
                be changed in the settings
              </Trans>
            </p>
            <div className="free-payment-action">
              <Link to={this.state.redirectLink || '/portal/recruiter/balance-board'}>
                <Button
                  className="accent start-btn">
                  <Trans i18nKey="portal.recruiter.account.payment.free.button">
                    Start using
                  </Trans>
                </Button>
              </Link>
              <div className="agreement">
                <Trans i18nKey="portal.recruiter.account.payment.free.footer">
                  By creating company you agree to ExcellentJobs <br/>
                  <Link to="/information/privacy-policy"> Privacy Policy </Link> and
                  <Link to="/information/privacy-policy"> Terms of Use </Link>
                </Trans>
              </div>
            </div>
          </div>
        </Card>
        <Footer className="free-payment-footer"/>
      </div>
    );
  }

}
