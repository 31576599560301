// @flow
import { Action } from 'redux';

import * as actions from './social.constants';

import type { SocialState } from './social.type';

const initialState: SocialState = {
  error: false,
  dataSuccess: false,
  tokenSuccess: false,
  candidateData: null
};

export default function (state: SocialState = initialState, action: Action): SocialState {
  switch (action.type) {
    case actions.SOCIAL_AUTH_REQUEST:
      return {
        ...state
      };
    case actions.SOCIAL_AUTH_SUCCESS_TOKEN:
      return {
        ...state,
        tokenSuccess: true
      };
    case actions.SOCIAL_AUTH_SUCCESS_DATA:
      return {
        ...state,
        dataSuccess: true,
        candidateData: action.candidateData
      };
    case actions.SOCIAL_AUTH_FAILURE:
      return {
        ...state,
        error: true
      };
    default:
      return state;
  }
}
