import { Component } from 'react';

import { bindActionCreators } from 'redux';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import {
  deleteSavedJobOfferData,
  resetJobOfferSkills,
  updateJobOfferStatus
} from 'services/portal/recruiter/job-offer/job-offer.actions';

import { CurrentRecruiterDto } from 'rest/recruiter/current/current.dto';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

import './modal.scss';

export type Props = {
  t: TransProps,
  open: boolean,
  history: History,
  currentUser?: CurrentRecruiterDto,
  currentJobOffer: JobOffersResponseDto,
  updateJobOfferStatus: (companyId: string, jobOfferId: string, status: string) => void
};

type State = {
  activeTab: number
}

export class Settings extends Component<Props, State> {

  static defaultProps = {
    open: false
  };

  state = {
    activeTab: 0
  }

}

export const stateToProps = (state: GlobalState) => ({
  currentUser: state.portal.recruiter.account.current.currentUser,
  currentJobOffer: state.portal.recruiter.jobOffer.general.currentJobOffer
});

export const dispatchToProps = dispatch => bindActionCreators({
  resetJobOfferSkills,
  updateJobOfferStatus,
  deleteSavedJobOfferData
}, dispatch);
