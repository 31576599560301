import React from 'react';

import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';

import { Settings, stateToProps, dispatchToProps } from './active-filter.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class ActiveFilterButton extends Settings {

  resetFilters () {
    this.props.clearJobsSearch();
    this.props.clearJobsSearchSkills();
    this.props.clearJobsFilter();
  }

  render () {
    if (this.props.jobOffersFilter && this.props.jobOffersFilter.filterCount) {
      return <div className="active-jobs-filters">
        <span className="title">
          <Trans i18nKey="portal.candidate.job-offers.applied-filter">
            Applied filters:
          </Trans>
        </span>
        <span className="count">{this.props.jobOffersFilter.filterCount}</span>
        <span
          className="reset icon-cancel-s"
          onClick={() => this.resetFilters()}/>
      </div>;
    } else {
      return null;
    }
  }

}
