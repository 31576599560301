// @flow
import React from 'react';

import cn from 'classnames';
import Tooltip from 'rc-tooltip';

import { Settings } from './icon-button.setting';

export class IconButton extends Settings {

  click (event: MouseEvent) {
    if (!this.props.disabled) {
      event.stopPropagation();
      this.props.onClick(event);
    }
  }

  render () {
    const btnClasses = cn({
      'custom-btn icon': true,
      'outlined': this.props.outlined,
      'accent': this.props.recruiter
    });

    return (
      <Tooltip
        overlayClassName={this.props.title ? '' : 'hidden'}
        destroyTooltipOnHide
        overlay={this.props.title}
        placement="top">
        <button
          type="button"
          onClick={(event: MouseEvent) => this.click(event)}
          className={btnClasses}>
          <span className={this.props.icon}/>
        </button>
      </Tooltip>
    );
  }

}
