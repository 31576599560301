import { Component } from 'react';

import './modal-header.scss';
import './modal-header.responsive.scss';

type Props = {
  title: string,
  backLinkUrl: string,
  backLinkTitle: string,
  className?: string
}

export class Setting extends Component<Props> {

  static defaultProps: Props = {
    title: '',
    backLinkUrl: '',
    backLinkTitle: '',
    className: ''
  }

}
