// @flow
import React from 'react';

import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Setting } from './privacy-policy.setting';

import { Table } from '../../../components/table-json';

@connectTranslation()
export class PrivacyPolicy extends Setting {

  openLink (event: MouseEvent, url: string, target: string = ''): void {
    event.preventDefault();
    window.open(url, target);
  }

  render () {
    return (
      <div className="privacy-container">
        <h1 className="privacy-title">
          <Trans i18nKey="information.privacy.title">
            GDPR / Private Policy / AGB
          </Trans>
        </h1>
        <div className="privacy-content">

          <div className="privacy-description privacy-block">
            <h2 className="privacy-section-title">
              <Trans i18nKey="information.privacy.content.9.title">
                Privacy Policy
              </Trans>
            </h2>
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.9.subtitle">
                We are excellent Jobs
              </Trans>
            </h3>
            <p className="list-value">
              <Trans i18nKey="information.privacy.content.9.list.0">
                a product of invent-solutions e.K.
              </Trans>
            </p>
            <p className="list-value">
              <Trans i18nKey="information.privacy.content.9.list.1">
                Finkenweg 1, D-18211 Admannshagen
              </Trans>
            </p>
            <p className="list-value">
              <Trans i18nKey="information.privacy.content.9.list.4">
                Owner: Joerg Langhof
              </Trans>
            </p>
            <p className="list-value">
              <Trans i18nKey="information.privacy.content.9.list.2">
                VAT-Number: DE 260 778 891
              </Trans>
            </p>
            <p className="list-value">
              <Trans i18nKey="information.privacy.content.9.list.3">
                REG-Number: 079/243/00730
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.0">
                We develop and provide as a service web applications for the tourism industry and we
                are an Estonian company.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.1">
                Excellent Jobs is an online service for job posting and job search, dedicated to creating employment
                and recruitment opportunities on:
              </Trans>
              <a
                href="www.excellent-jobs.com"
                onClick={event => { this.openLink(event, 'https://excellent-jobs.com', '_blank'); }}>
                www.excellent-jobs.com
              </a>
              <Trans i18nKey="information.privacy.content.9.description.30">
                .
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.2">
                We provide a marketplace connecting job seekers/employees to employers for jobs in the
                hospitality industry.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.3">
                We enable posting and searching of personal profiles, job vacancies and other employment
                related opportunities.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.4">
                Hereby we determine and explain the purpose of the processing of the data and the persons
                to whom communication of the data is permitted, the conditions for communicating the data
                to third persons and your rights concerning further processing of your personal data.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.5">
                By joining excellent jobs, creating your account and accepting our Terms and Conditions and
                this Privacy Policy, you are giving us your permission to process your personal data pursuant
                to conditions of this Privacy Policy. Please read this Privacy Policy carefully. In case of any
                questions regarding this Privacy Policy, please kindly contact us
              </Trans>
              <strong> support@excellent-jobs.com</strong>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.6">
                We process (including collect, save, process distribute, compile, use, make available to, etc.)
                personal data submitted by you at the sign up and on your account. We do not collect any personal
                data about you which is made available outside our platform.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.7">
                We collect and process the following personal data: name, date of birth, location, work experience,
                skills, education, contact information, e-mail address and other personal information related to
                your suitability for a job vacancy. Please take into account that some of the personal information
                may be sensitive personal data and therefore we ask you to think twice before you decide to make it
                available.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.8">
                We collect and process job seekers` personal data for the purpose of matching up to employers
                across the globe for short term jobs.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.9">
                Upon your and other job seekers` separate consent we forward data to the employers to enable to decide
                whether the job seeker is the one they are looking for.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.10">
                For making your profile and personal data available for employers and start active job seeking process
                in Balance Board please click the relevant button on your profile.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.11">
                Otherwise your information will not be visible to employers. All the data you make available on your
                account shall be available to potential employers as it is provided by you.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.12">
                We use your personal information to create a profile of you. The profile-making process is not an
                automated decision which results in legal consequences for you or significantly affects the data
                about you.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.13">
                As a job seeker you agree that we may assess your skills, experiences, current location or other
                characteristics and express our preferences to the employers. For providing our services we may,
                at our discretion, evaluate your suitability to a given job vacancy, create opinions and evaluations
                which we provide to interested employers matching list and provide shortlisting services to employers
                which may relate to further processing of your personal data.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.14">
                Upon your separate consent we may provide your profile to an employer even if you are not applying for
                the vacant position and for these purposes you grant us the right to retain your personal data beyond
                any given job vacancy you have applied for. Please note that you may withdraw your consent at any given
                time by communicating such request via support@excellent-jobs.com.
              </Trans>
              <strong> support@excellent-jobs.com.</strong>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.15">
                We could help the employers with pre-selection of possible employees during which we may add to your
                profile some comments on why we feel that you are the best for this position (matching points)
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.16">
                However, the final decision is always made by the employer.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.17">
                Upon your consent we may “interview you automatically online” and conduct
                various testing for the purpose of determining or verifying your suitability for a given job.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.18">
                By consenting to such interviews and tests you acknowledge and agree that we may, in the interests of
                the employer, request and process and verify various personal information for determining your skills,
                experience and personal qualities, including previous jobs, professional qualifications and licenses
                required. You also agree that we may transfer any such information, including our comments, valuations
                and other input, to potential employers and their representatives for the sole purpose of conducting the
                hiring process.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.19">
                We may also use the personal data such as contact details to resolve any service quality
                issues between us.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.20">
                If you make your social media profiles available on your excellent jobs account, we assure
                that we do not store, compile or analyze your social media data, unless you have authorized us
                to do so. We are not processing your social media data and it is your own responsibility to
                clarify any social media data misuse or misinterpretation.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.21">
                Personal data is disclosed only to employers who have joined excellent jobs. Profile and other
                personal information is not available to the public outside excellent jobs platform and it is
                not allowed to index the data by search engines such as Google.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.22">
                For the purpose of improving your user experience on Excellent Jobs job services, we track the
                activities you perform on our website.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.23">
                Web analytic service providers analyze your use of the excellent-jobs platform and services
                so we could improve and amend the way our website and platform function. The information we
                obtain may be disclosed to or collected directly by these providers and other relevant third
                parties who use the information, for example, to evaluate use of the services, help administer
                the services and diagnose technical issues. Applicant tracking system is a software application
                that allows an organization to collect and store candidate and job-related data and track and monitor
                the process of candidates through all stages of the hiring process.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.24">
                By accepting our Terms and Conditions and this Privacy Policy, you also understand and agree that upon
                disclosing your personal data some of your personal data is transferred to and processed outside the
                European Economic Area.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.25">
                Your use of our services could involve the transfer, storage, and processing of your personal data
                to other countries. We will take appropriate measures, in compliance with applicable law, to ensure
                that your personal data remains protected.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.26">
                In case of any question about the transfer of your personal data to third parties, please contact
                our support at
              </Trans>
              <strong> support@excellent-jobs.com.</strong>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.27">
                Only authorized employees of excellent jobs have access to the personal data and they may access the
                data only for the purpose of resolving issues associated with the use of our services.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.28">
                Pursuant to applicable law we may transfer, process and store personal data in reputable and
                sufficient cloud databases that are located outside of Estonia and European Union.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.9.description.29">
                We reserve ourselves the right to change the cloud service provider at any time without prior notice.
              </Trans>
            </p>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.10.title">
                Messages, direct marketing and newsletters.
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.10.description.0">
                Normally we use your e-mail address only to send you certain relevant notifications about our services
                which concern your use of services, including important updates and other changes.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.10.description.1">
                Upon your separate consent we may also use your e-mail address to send you our newsletters and special
                offers, including special offers from our other applications
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.10.description.2">
                You may withdraw your consent to receive such direct marketing e-mails at any given time. If you no
                longer wish to receive these job suggestions, please change it in your profile settings.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.10.description.3">
                Upon sending you our notifications about our services and providing you with our newsletters and offers,
                we are allowed to track your reaction and activity in relation to our notification and messages.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.10.description.4">
                For example, we collect information about when you read our message and whether you also opened any
                attachment. We only do so to understand which information is important to our users in order to help
                us determine what type of information should we provide you in the future and what information does not
                have enough interest.
              </Trans>
            </p>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.11.title">
                Your rights under Privacy Policy and deletion of your account and personal data.
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.11.description.0">
                You may request information about personal data that we process about you (personal data report) and
                submit requests for correcting any of your incorrect personal data.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.11.description.1">
                You may also object to our data processing, if you find that we process your personal data or parts
                of your personal data in violation of your rights. After you have made your profile public in our
                database you have the right to limit access of any employer to your personal account and your personal
                data by using relevant buttons on your account.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.11.description.2">
                Under applicable laws you may withdraw your consent to this Privacy Policy at any given time or object
                to data processing by us under this Privacy Policy. Doing so may, upon our discretion, result
                in termination of services without any of our further obligations under Terms and Conditions nor
                liability for us.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.11.description.3">
                You can independently delete your account in our system at any time.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.11.description.4">
                By a written denial for Terms and Conditions consent, we shall proceed to a complete removal (deletion)
                of your account and all connected data from our application.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.11.description.5">
                You may also submit an individual request to delete your account in which case you are also deemed
                to withdraw your consent to process your personal data.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.11.description.6">
                In these cases, we will contact (via e-mail) you within seven (7) days to clarify your actual intent and
                once this is confirmed we will delete your personal data and account permanently latest within thirty
                (30) days.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.11.description.7">
                As soon as your data is deleted or is requested to be deleted, all connected contacts and informations
                will no longer be available in any form. The deletion from the system consequence that the restoration
                of your data or profile is no longer possible.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.11.description.8">
                For exercising your rights under this paragraph please contact us with any of your requests at
              </Trans>
              <strong> support@excellent-jobs.com</strong>
            </p>
            <p id="Cookies">
              <Trans i18nKey="information.privacy.content.11.description.9">
                If case you feel that we have violated your privacy rights you may also apply to an appropriate data
                protection authority for remedy of your rights.
              </Trans>
            </p>
          </div>
          <div className="privacy-description">
            <h3>
              <Trans i18nKey="information.privacy.content.12.title">
                Cookie Policy
              </Trans>
            </h3>
            <span>
              <Trans i18nKey="information.privacy.content.12.introduction-cookie.0">
                Last Updated: August, 12, 2019
              </Trans>
            </span>
            <p>
              <Trans i18nKey="information.privacy.content.12.introduction-cookie.1">
                By using or accessing invent’s websites (“Site”), you agree to invent’s use of Cookies as outlined
                below. All references to Job Seeker and Employer are defined as provided for in the invent Terms and
                condition For the purposes of this Cookie Policy, “invent” or “we” means invent-solutions e.K., Germany
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.12.introduction-cookie.2">
                For the purposes of this Cookie Policy, “invent” or “we” means invent-solutions e.K., Germany
              </Trans>
            </p>
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.12.introduction-cookie.3">
                1. Introduction: What is a cookie?
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.12.introduction-cookie.4">
                “Cookies” are text-only pieces of information that a website transfers to an individual’s hard drive or
                other website-browsing equipment for record-keeping purposes. Cookies allow the Site to remember
                important information that will make your use of the site more convenient. A cookie will typically
                contain the name of the domain from which the cookie has come, the “lifetime” of the cookie, and a
                randomly generated unique number or other value. Certain cookies will be used on the Site regardless of
                whether you are logged in to your account or not.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.12.introduction-cookie.5">
                This Cookie policy helps to explain the occasions when and why cookies may be sent to visitors to the
                Site (referred to in this policy as “we”, “us” or “our”).
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.12.introduction-cookie.6">
                Session Cookies are temporary cookies that remain in the cookie file of your browser until you leave the
                Site.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.12.introduction-cookie.7">
                Persistent Cookies remain in the cookie file of your browser for much longer (though how long will
                depend on the lifetime of the specific cookie). When we use session cookies to track the total number of
                visitors to our Site, this is done on an anonymous aggregate basis (as cookies do not in themselves
                carry any personal data). We may also employ cookies so that we remember your computer when it is used
                to return to the Site to help customize your invent web experience. We may associate personal
                information with a cookie file in those instances.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.12.introduction-cookie.8">
                We may also employ cookies so that we remember your computer when it is used to return to the Site to
                help customize your invent web experience. We may associate personal information with a cookie file in
                those instances.
              </Trans>
            </p>
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.12.introduction-cookie.9">
                2. How we uses cookies
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.12.introduction-cookie.10">
                We use cookies, web beacons, and other storage technologies from third-party partners such as Google and
                Facebook for measurement services, better targeting ads, and for marketing purposes. This takes place
                from the Site and when you download or install an invent App. These cookies, web beacons, and other
                storage technologies allow us to display invent promotional material to you on other sites you visit
                across the Internet. invent may also share information about your behavior on the Site with third
                parties (including operators of third-party websites and/or social networking sites) in order to show
                you targeted advertisements and other content that has been customized for you. We also use cookies to
                facilitate the invent Targeted Ads Program. In this Program, we use cookies to determine which ads users
                have seen, and how many times users have seen a particular ad.
              </Trans>
            </p>
            <Table
              translate="information.privacy.content.12.introduction-table"
              boldTitle={true}
              row={7}
              cell={2}>
              Table
            </Table>
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.12.managing-cookies.0">
                3. Managing cookies in your browser
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.12.managing-cookies.1">
                Use the options in your web browser if you do not wish to receive a cookie or if you wish to set your
                browser to notify you when you receive a cookie. You can easily delete and manage any cookies that have
                been installed in the cookie folder of your browser by following the instructions provided by your
                particular browser manufacturer.
              </Trans>
            </p>
            <ul>
              <li>
                <a className="link" href="https://support.google.com/chrome/answer/95647?hl=en-GB">Google Chrome</a>
              </li>
              <li>
                <a
                  className="link"
                  href="https://support.microsoft.com/en-us/kb/260971">Internet Explorer
                </a>
              </li>
              <li>
                <a
                  className="link"
                  href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">
                  Mozilla Firefox
                </a>
              </li>
              <li>
                <a
                  className="link"
                  href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
                  Safari (Desktop)
                </a>
              </li>
              <li>
                <a
                  className="link"
                  href="https://support.apple.com/en-us/HT201265">Safari (Mobile)
                </a>
              </li>
              <li>
                <a
                  className="link"
                  href="http://support.google.com/ics/nexus/bin/answer.py?hl=en&answer=2425067">
                  Android Browser
                </a>
              </li>
              <li>
                <a
                  className="link"
                  href="http://www.opera.com/help">
                  Opera
                </a>
              </li>
              <li>
                <a
                  className="link"
                  href="https://www.opera.com/help/tutorials/security/privacy/">
                  Opera Mobile
                </a>
              </li>
            </ul>
            <p>
              <Trans i18nKey="information.privacy.content.12.managing-cookies.2">
                If your browser is not listed here, consult the documentation that your particular browser manufacturer
                provides. You may also consult your mobile device documentation for information on how to disable
                cookies on your mobile device. If you disable all cookies, you may not be able to take advantage of all
                the features of this Site. Please note that if you have not cleared your cookies or cache, the contents
                of which may affect autofill functions on the invent site and you are responsible for any such actions.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.12.managing-cookies.3">
                To opt out of the collection and use of information for ad targeting please feel free to exercise your
                rights as detailed in our Terms and Conditions
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.12.managing-cookies.4">
                In addition, invent may also provide an employer with code for a Conversion
                Tracker to place on its Site that pings the job seeker web beacon and tracks whenever
                a job seeker has applied to a job on that employer’s website. This Conversion Tracker will
                attribute an application to invent any time the job seeker started their search on invent,
                despite any intervening activity. invent does not have access to the cookies in the employer’s domain.
                The Conversion Tracker is simply a counter that notifies invent that a job seeker has applied.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.12.managing-cookies.5">
                If a job seeker has disabled the web beacon, the Conversion Tracker will have no functionality.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.12.managing-cookies.6">
                invent uses the AdWords and Remarketing Lists features of Google Analytics for Display Advertisers.
                invent and Google use first-party cookies (such as the Google Analytics cookie) and third-party cookies
                (such as the DoubleClick cookie) together to inform, optimize, and serve ads based on your past visits
                to our Site.
                This means that vendors including Google will display invent promotional material on other sites you
                visit across the Internet.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.12.managing-cookies.7">
                You may opt-out of Google Analytics for Display Advertisers including
                AdWords and opt-out of customized Google Display Network ads by visiting the Google Ads Preferences
                Manager.
                To provide website visitors more choice on how their data is collected by Google Analytics,
                Google has developed an Opt-out Browser add-on, which is available by visiting,
              </Trans>
              <a href="https://tools.google.com/dlpage/gaoptout">
                <Trans i18nKey="information.privacy.content.12.managing-cookies.8">
                  Google Analytics
                  Opt-out Browser Add-on
                </Trans>
              </a>
              <Trans i18nKey="information.privacy.content.12.managing-cookies.9">
                , to enable you to opt-out of Google’s programs. To opt out of the collection and use of information for
                ad targeting please feel free to exercise your rights as detailed in section 10 of our Privacy Policy,
                or alternatively contact invent as detailed in section 12 of our Privacy Policy.
              </Trans>
            </p>
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.12.third-party-cookies.0">
                4. Third-Party Cookies
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.12.third-party-cookies.1">
                The following cookie table lists a selection of the third party cookies used or could be used on invent.
                Although we may update this list periodically, please be advised that the names of cookies, pixels and
                other technologies are subject to change.
              </Trans>
            </p>
            <div className="table">
              <div className="table-row">
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.0.0">
                    Domain
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.0.1">
                    Cookie Name
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.0.2">
                    Description
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.0.3">
                    Expiration
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.0.4">
                    Opt-out
                  </Trans>
                </div>
              </div>
              <div className="table-row">
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.1.0">
                    Third party
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.1.1">
                    optimizelyBuckets
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.1.2">
                    Used by Optimizely to store page variants assigned to user for A/B performance testing (ensures user
                    gets a consistent experience)
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.1.3">
                    10 years
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <a
                    className="link"
                    href="https://www.optimizely.com/opt_out">
                    <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.1.4">
                      Optimizely opt-out
                    </Trans>
                  </a>
                </div>
              </div>
              <div className="table-row">
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.2.0">
                    Third party
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.2.1">
                    optimizelyEndUserID
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.2.2">
                    Used by Optimizely for A/B performance testing; it is a unique user identifier
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.2.3">
                    10 years
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <a
                    className="link"
                    href="https://www.optimizely.com/opt_out">
                    <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.2.4">
                      Optimizely opt-out
                    </Trans>
                  </a>
                </div>
              </div>
              <div className="table-row">
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.3.0">
                    Third party
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.3.1">
                    _ga
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.3.2">
                    Used by Google Analytics to track user activity over different browsing sessions. For some job
                    postings in Japan, this cookie may share limited demographic information about applicants with
                    employers. You will know if this information is being shared because it will be explicitly called
                    out in the application process.
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.3.3">
                    2 years
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <a
                    className="link"
                    href="https://tools.google.com/dlpage/gaoptout">
                    <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.3.4">
                      Google Analytics opt-out
                    </Trans>
                  </a>
                  <a
                    className="link"
                    href="https://www.google.com/settings/u/0/ads/onweb/">
                    <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.3.5">
                      Google Ads Settings
                    </Trans>
                  </a>
                </div>
              </div>
              <div className="table-row">
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.4.0">
                    Third party
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.4.1">
                    _gali
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.4.2">
                    Used by Google Analytics to record what item on the previous page the user interacted with to get to
                    the current page
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.4.3">
                    immediately
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <a
                    className="link"
                    href="https://tools.google.com/dlpage/gaoptout">
                    <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.4.4">
                      Google Analytics opt-out
                    </Trans>
                  </a>
                  <a
                    className="link"
                    href="https://www.google.com/settings/u/0/ads/onweb/">
                    <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.4.5">
                      Google Ads Settings
                    </Trans>
                  </a>
                </div>
              </div>
              <div className="table-row">
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.5.0">
                    Third party
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.5.1">
                    _utma
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.5.2">
                    Used by Google Analytics to store information on number of visits, first visit, and most recent
                    visit
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.5.3">
                    2 years
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <a
                    className="link"
                    href="https://tools.google.com/dlpage/gaoptout">
                    <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.5.4">
                      Google Analytics opt-out
                    </Trans>
                  </a>
                  <a
                    className="link"
                    href="https://www.google.com/settings/u/0/ads/onweb/">
                    <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.5.5">
                      Google Ads Settings
                    </Trans>
                  </a>
                </div>
              </div>
              <div className="table-row">
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.6.0">
                    Third party
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.6.1">
                    _utmb
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.6.2">
                    Used by Google Analytics to record the timestamp of the moment the user enters the site
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.6.3">
                    1 month
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <a
                    className="link"
                    href="https://tools.google.com/dlpage/gaoptout">
                    <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.6.4">
                      Google Analytics opt-out
                    </Trans>
                  </a>
                  <a
                    className="link"
                    href="https://www.google.com/settings/u/0/ads/onweb/">
                    <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.6.5">
                      Google Ads Settings
                    </Trans>
                  </a>
                </div>
              </div>
              <div className="table-row">
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.7.0">
                    Third party
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.7.1">
                    _utmc
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.7.2">
                    Used by Google Analytics to track when users leave the site
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.7.3">
                    1 year
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <a
                    className="link"
                    href="https://tools.google.com/dlpage/gaoptout">
                    <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.7.4">
                      Google Analytics opt-out
                    </Trans>
                  </a>
                  <a
                    className="link"
                    href="https://www.google.com/settings/u/0/ads/onweb/">
                    <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.7.5">
                      Google Ads Settings
                    </Trans>
                  </a>
                </div>
              </div>
              <div className="table-row">
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.8.0">
                    Third party
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.8.1">
                    _utmz
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.8.2">
                    Used by Google Analytics to track where a visitor came from
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.8.3">
                    1 year
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <a
                    className="link"
                    href="https://tools.google.com/dlpage/gaoptout">
                    <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.8.4">
                      Google Analytics opt-out
                    </Trans>
                  </a>
                  <a
                    className="link"
                    href="https://www.google.com/settings/u/0/ads/onweb/">
                    <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.8.5">
                      Google Ads Settings
                    </Trans>
                  </a>
                </div>
              </div>
              <div className="table-row">
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.9.0">
                    Third party
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.9.1">
                    intercom-session-pn6o23fv
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.9.2">
                    Used by Intercom.io to allow users to ask customer service questions in real time
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.9.3">
                    1 week
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <a
                    className="link"
                    href="https://www.intercom.com/terms-and-policies#privacy">
                    <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.9.4">
                      Intercom.io opt-out
                    </Trans>
                  </a>
                </div>
              </div>
              <div className="table-row">
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.10.0">
                    Third party
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.10.1">
                    UIDR
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.10.2">
                    Used by Scorecardresearch.com to measure user volume
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.10.3">
                    2 years
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <a
                    className="link"
                    href="https://www.scorecardresearch.com/Preferences.aspx">
                    <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.10.4">
                      Scorecardresearch.com opt-out
                    </Trans>
                  </a>
                </div>
              </div>
              <div className="table-row">
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.11.0">
                    Third party
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.11.1">
                    mkto.trk
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.11.2">
                    Used by Marketo to measure ad campaign effectiveness by linking visitor behavior to an email
                    recipient
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.11.3">
                    2 years
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <a
                    className="link"
                    href="https://documents.marketo.com/legal/cookies/">
                    <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.11.4">
                      Marketo opt-out
                    </Trans>
                  </a>
                </div>
              </div>
              <div className="table-row">
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.12.0">
                    Third party
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.12.1">
                    TS01d65e80
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.12.2">
                    Used by f5 networks to maintain the performance, capacity, and reliability of our Website
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.12.3">
                    Current session
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <a
                    className="link"
                    href="https://f5.com/about-us/policies/privacy-policy">
                    <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.12.4">
                      f5 Networks opt-out
                    </Trans>
                  </a>
                </div>
              </div>
              <div className="table-row">
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.13.0">
                    Third party
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.13.1">
                    hjClosedSurveyInvites
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.13.2">
                    This cookie is set once a visitor interacts with a Survey invitation modal pop-up. It is used to
                    ensure that the same invite does not reappear if it has already been shown.
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.13.3">
                    1 year
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <a
                    className="link"
                    href="https://www.hotjar.com/legal/compliance/opt-out">
                    <Trans i18nKey="information.privacy.content.12.third-party-cookies-table.13.4">
                      Hotjar opt-out
                    </Trans>
                  </a>
                </div>
              </div>
            </div>
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.12.changes-to-our-cookie-policy.0">
                5. Changes To Our Cookie Policy
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.12.changes-to-our-cookie-policy.1">
                Please review the “Last Updated” legend at the top of the Cookie Policy to determine when it was last
                amended. Any changes will become effective on the “Last Updated” date indicated above. By using the Site
                or providing information to us following such changes, you will have accepted the amended Cookie Policy.
                If invent is going to use Personal Data collected through the Site in a manner materially different from
                that stated at the time of collection, then invent will notify users via email and/or by posting a
                notice on invent’s Site for 30 days prior to such use or by other means as required by law.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.12.changes-to-our-cookie-policy.2">
                Temporary files installed by
              </Trans>
              <span className="text-bold">
                <Trans i18nKey="information.privacy.content.12.changes-to-our-cookie-policy.3">
                  Yandex.Metrica
                </Trans>
              </span>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.12.changes-to-our-cookie-policy.4">
                Yandex.Metrica provides depersonalized information about your site&apos;s traffic.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.12.changes-to-our-cookie-policy.5">
                The available statistics include the number of users, traffic sources, and user behavior on site pages.
                In order to count the site&apos;s users, Yandex.Metrica uses anonymous browser identifiers that are
                stored in cookies or in localStorage properties. These cookies and properties are set on the top-level
                domain, such as .example.com.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.12.changes-to-our-cookie-policy.6">
                Cookie
              </Trans>
            </p>
            <Table
              translate="information.privacy.content.12.yandex-metrica-table"
              row={14}
              cell={4}>
              Table
            </Table>
            <p>
              <Trans i18nKey="information.privacy.content.12.changes-to-our-cookie-policy.7">
                These properties are set on the domain where the Yandex.Metrica tag is embedded.
              </Trans>
            </p>
            <div className="table">
              <div className="table-row">
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.yandex-metrica-properties-table.0.0">
                    Name
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.yandex-metrica-properties-table.0.1">
                    Description
                  </Trans>
                </div>
              </div>
              <div className="table-row">
                <div className="table-cell cell-width">
                  _ym&lt;tag ID&gt;_lastHit
                </div>
                <div className="table-cell cell-width table-cell-with-link">
                  <Trans i18nKey="information.privacy.content.12.yandex-metrica-properties-table.1.1">
                    Used for specifying the time in order to get an
                  </Trans>
                  <a
                    className="link"
                    href="https://yandex.com/support/metrica/general/glossary.html#glossary__failure">
                    <Trans i18nKey="information.privacy.content.12.yandex-metrica-properties-table.1.2">
                      accurate bounce rate
                    </Trans>
                  </a>
                </div>
              </div>
              <div className="table-row">
                <div className="table-cell cell-width">
                  _ym&lt;tag ID&gt;_lsid
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.yandex-metrica-properties-table.2.1">
                    Used for identifying site users
                  </Trans>
                </div>
              </div>
              <div className="table-row">
                <div className="table-cell cell-width">
                  _ym&lt;tag ID&gt;_reqNum
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.yandex-metrica-properties-table.3.1">
                    Used for determining the ordinal number of the request for the Yandex.Metrica tag in the browser
                  </Trans>
                </div>
              </div>
              <div className="table-row">
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.yandex-metrica-properties-table.4.0">
                    _ym_retryReqs
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.yandex-metrica-properties-table.4.1">
                    Contains requests that the tag couldn&apos;t send to the server
                  </Trans>
                </div>
              </div>
              <div className="table-row">
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.yandex-metrica-properties-table.5.0">
                    zz
                  </Trans>
                </div>
                <div className="table-cell cell-width">
                  <Trans i18nKey="information.privacy.content.12.yandex-metrica-properties-table.5.1">
                    Used for identifying site users
                  </Trans>
                </div>
              </div>
            </div>
            <p>
              <Trans i18nKey="information.privacy.content.12.changes-to-our-cookie-policy.7">
                If you have further questions about the use of cookies, feel free to contact us.
              </Trans>
            </p>
          </div>
          <div className="privacy-description privacy-block">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.13.title">
                Data Protection Officer.
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.13.description.0">
                Our Data Protection Officer responsible for our privacy related obligations is:
              </Trans>
            </p>
            <p className="list-value">
              <Trans i18nKey="information.privacy.content.13.description.1">
                Jörg Langhof, CEO invent-science
              </Trans>
            </p>
            <p className="list-value">
              <Trans i18nKey="information.privacy.content.13.description.2">
                (phone) +372 880 40 20
              </Trans>
            </p>
            <p className="list-value">
              <Trans i18nKey="information.privacy.content.13.description.3">
                (e-mail) support@invent-science.com
              </Trans>
            </p>
          </div>

          <div className="privacy-description">
            <h2 className="privacy-subtitle">
              <Trans i18nKey="information.privacy.content.0.title">
                GDPR
              </Trans>
            </h2>
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.0.subtitle">
                What’s GDPR?
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.0.description.0">
                The General Data Protection Regulation (GDPR) is a legal framework that sets guidelines for the
                collection and processing of personal information of individuals within the European Union (EU).
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.0.description.1">
                This regulation defines the roles and processes organizations must implement to ensure that any personal
                data they possess is secure and processed under a legal basis which could be the consent of the person,
                legitimate interest or other ground under the GDPR.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.0.description.2">
                In short, the EU commission felt that people should have more say about how their personal data is
                processed, especially by tech companies, so they passed a regulation that aims to improve how
                organizations collect, protect and secure personal information, and provide EU and EEA citizens with
                greater control over their data.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.0.description.3">
                It’s not about stopping companies from processing personal data, but ensuring it is looked after
                properly, kept accurate and not abused.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.0.description.4">
                Once GDPR comes into effect on May 25, 2018, it will immediately become law in all EU member states,
                and every organization will have to adhere to its processes. Even companies outside the EU will have
                to comply with the GDPR if they do business within the European Economic Area.
              </Trans>
            </p>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.1.title">
                What is personal data?
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.1.description.0">
                Any piece of information that can identify a living individual is considered personal data. Even a
                single email address is considered personal data.
              </Trans>
            </p>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.2.title">
                What rights do i have?
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.2.description.0">
                The core of GDPR is to provide anyone with rights they can exercise against organizations
                processing their personal data.
              </Trans>
            </p>
            <ul>
              <li>
                <strong>
                  <Trans i18nKey="information.privacy.content.2.list.0.strong">
                    Access.
                  </Trans>
                </strong>
                <Trans i18nKey="information.privacy.content.2.list.0.text">
                  You can request access to your personal data and learn how an organization
                  uses it after they&rsquo;ve obtained it from you.
                </Trans>
              </li>
              <li>
                <strong>
                  <Trans i18nKey="information.privacy.content.2.list.1.strong">
                    Erasure.
                  </Trans>
                </strong>
                <Trans i18nKey="information.privacy.content.2.list.1.text">
                  You have a right to withdraw consent to store and use your personal data and have the
                  information erased.
                </Trans>
              </li>
              <li>
                <strong>
                  <Trans i18nKey="information.privacy.content.2.list.2.strong">
                    Data Portability.
                  </Trans>
                </strong>
                <Trans i18nKey="information.privacy.content.2.list.2.text">
                  If technically possible, you have the right to transfer your data from one service
                  provider to another.
                </Trans>
              </li>
              <li>
                <strong>
                  <Trans i18nKey="information.privacy.content.2.list.3.strong">
                    Rectification.
                  </Trans>
                </strong>
                <Trans i18nKey="information.privacy.content.2.list.3.text">
                  You can require any errors in personal data to be corrected.
                </Trans>
              </li>
              <li>
                <strong>
                  <Trans i18nKey="information.privacy.content.2.list.4.strong">
                    To Be Informed.
                  </Trans>
                </strong>
                <Trans i18nKey="information.privacy.content.2.list.4.text">
                  You can require any errors in personal data to be corrected.
                </Trans>
              </li>
              <li>
                <strong>
                  <Trans i18nKey="information.privacy.content.2.list.5.strong">
                    Restrict Processing.
                  </Trans>
                </strong>
                <Trans i18nKey="information.privacy.content.2.list.5.text">
                  You have the right to block, restrict and suppress processing of your personal data.
                </Trans>
              </li>
              <li>
                <strong>
                  <Trans i18nKey="information.privacy.content.2.list.6.strong">
                    Stop Processing.
                  </Trans>
                </strong>
                <Trans i18nKey="information.privacy.content.2.list.6.text">
                  You can object to using and processing all your personal data. However, stopping and even restricting
                  certain types of personal data could mean that rendering the service to you might turn out impossible.
                  Thus, it could mean the end of the relationship.
                </Trans>
              </li>
            </ul>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.3.title">
                Will companies comply?
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.3.description.0">
                First and foremost, we can claim that neither now nor before the entry into force of this regulation,
                we do not intend nor plan to use personal user data for other purposes.
                Whereas many other companies have simply moved collecting your data to countries
                outside the EU, we stay transparent for you.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.3.description.1">
                In principle, EU companies and companies that process EU citizens’ data are obliged to comply with
                the new regulation and act on any requests from persons wishing to exercise their rights.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.3.description.2">
                If they don’t, GDPR fines can go up to twenty million Euros or 4 percent of annual
                global revenue, whichever is highest.
              </Trans>
            </p>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.4.title">
                What information is tracked?
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.4.description.0">
                When you use excellent jobs and fill up your profile, we record and process the following information.
                We do not collect any personal data about you which is made available outside our platform.
                At any given time, you decide what data or whether your data should be made available to potential
                employers and for how long.
              </Trans>
            </p>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.5.title">
                If you are a candidate.
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.5.description.0">
                As a candidate, we collect all the necessary information to make your job search a seamless and
                effective process.
              </Trans>
            </p>
            <ul>
              <li>
                <Trans i18nKey="information.privacy.content.5.list.0">
                  Profile data, including name, date of birth, location, work experience, skills, education, contact
                  information, e-mail address and other personal information related to your suitability for a job
                  vacancy.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="information.privacy.content.5.list.1">
                  Application data (including your skills), including responses.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="information.privacy.content.5.list.2">
                  Messages sent to and received from employers.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="information.privacy.content.5.list.3">
                  All actions on the site via web analytics tools.
                </Trans>
              </li>
            </ul>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.5.title">
                If you are a candidate.
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.5.description.0">
                As a candidate, we collect all the necessary information to make your job search a seamless and
                effective process.
              </Trans>
            </p>
            <ul>
              <li>
                <Trans i18nKey="information.privacy.content.5.list.0">
                  Profile data, including name, date of birth, location, work experience, skills, education, contact
                  information, e-mail address and other personal information related to your suitability for a job
                  vacancy.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="information.privacy.content.5.list.1">
                  Application data (including your skills), including responses.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="information.privacy.content.5.list.2">
                  Messages sent to and received from employers.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="information.privacy.content.5.list.3">
                  All actions on the site via web analytics tools.
                </Trans>
              </li>
            </ul>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.6.title">
                If you are a business.
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.6.description.0">
                As a business, we collect all the necessary information to make sure you can attract,
                filter and hire the best available candidates.
              </Trans>
            </p>
            <ul>
              <li>
                <Trans i18nKey="information.privacy.content.6.list.0">
                  Status of all applications, including all changes you make.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="information.privacy.content.6.list.1">
                  Messages sent to and received from candidates.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="information.privacy.content.6.list.2">
                  All actions on the site via web analytics tools.
                </Trans>
              </li>
            </ul>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.7.title">
                How does excellent jobs use that information?
              </Trans>
            </h3>
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.7.subtitle">
                If you are a candidate.
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.7.description.0">
                We try to limit our use of that information as much as possible.
                That said, we perform the following actions.
              </Trans>
            </p>
            <ul>
              <li>
                <Trans i18nKey="information.privacy.content.7.list.0">
                  Share your information with employers.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="information.privacy.content.7.list.1">
                  Recommend your profile to an employer even if you didn’t apply, upon your separate consent.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="information.privacy.content.7.list.2">
                  Provide you with job recommendations.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="information.privacy.content.7.list.3">
                  Provide an overall better user experience.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="information.privacy.content.7.list.4">
                  Resolve any service quality issues between us.
                </Trans>
              </li>
            </ul>
            <p>
              <Trans i18nKey="information.privacy.content.7.description.1">
                Personal data is disclosed only to employers who have joined excellent jobs. Profile and other personal
                information is not available to the public and it is not indexed by search engines such as Google.
              </Trans>
            </p>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.8.title">
                If you are a business.
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.8.description.0">
                We try to limit our use of that information as much as possible. That said, we perform the
                following actions for your business.
              </Trans>
            </p>
            <ul>
              <li>
                <Trans i18nKey="information.privacy.content.8.list.0">
                  Internal statistics.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="information.privacy.content.8.list.1">
                  Provide you with candidate recommendations.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="information.privacy.content.8.list.2">
                  Provide an overall better user experience.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="information.privacy.content.8.list.3">
                  Know what information is being recorded and how it will be used.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="information.privacy.content.8.list.4">
                  Access and viewing the information.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="information.privacy.content.8.list.5">
                  Have your information erased.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="information.privacy.content.8.list.6">
                  Correct the information about you.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="information.privacy.content.8.list.7">
                  Get a copy of the information for yourself.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="information.privacy.content.8.list.8">
                  Restrict specific uses or stop altogether the usage of your information.
                </Trans>
              </li>
            </ul>
            <p>
              <Trans i18nKey="information.privacy.content.8.description.1">
                You can request a corresponding list (zip file) from us at any time in the History section of Account
                Settings. Depending on how much data is connected to your account , it can take up to 72 hours
                to complete such list generation.
              </Trans>
            </p>
          </div>

          <div className="privacy-description">
            <h2 className="privacy-section-title" id="terms-and-conditions">
              <Trans i18nKey="information.privacy.content.14.title">
                Terms and Conditions (for Candidates)
              </Trans>
            </h2>
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.14.subtitle">
                Excellent-Jobs account.
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.14.description.0">
                In order to start fully using the excellent-jobs services, you must first sign up and create a
                excellent-jobs account.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.14.description.1">
                During this we request you to accept these Terms and Conditions.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.14.description.2">
                You are allowed to create a excellent-jobs account and use our services only if you are eligible to
                enter into legal contracts independently pursuant to jurisdiction of your residence.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.14.description.3">
                Normally, this means you have to be at least eighteen (18) years old
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.14.description.4">
                If you are a job seeker, you are only allowed to create a excellent job account if you are a natural
                person and if you create the account personally.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.14.description.5">
                We do not accept representation of job seekers via power of attorney or other means.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.14.description.6">
                Upon signing up you will be asked to choose a password for your account. Keeping your password secure
                is paramount for protecting your personal information and avoiding violation of your rights. You agree
                not to share your account name and password with others or do anything that might jeopardize security
                of your account. We bear no liability for third party action in connection with the use of your account
                or password. Regardless, you agree to immediately notify us of any unauthorized access or other use of
                your account or password.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.14.description.7">
                By way of your account we allow you to create an individual profile and make this profile or aspects
                thereof public. A job seeker profile allows interested employers to access relevant information and
                submit employment related offers. An employer profile allows job posting and other recruitment
                activities for the purposes of hiring.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.14.description.8">
                When using your account and excellent-jobs services please acknowledge that we only accept true
                and accurate information of you which does not mislead in terms of your level of education, work
                experience or other personal information.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.14.description.9">
                We reserve the right to refuse your application or any other information which we, based on information
                available to us, determine misleading, inappropriate or unsuitable, including but not limited to
                the case where your previous experience, education or skills do not meet the expectations of the
                job vacancy you apply for. If appropriate, we will notify you of our reasoning for rejection.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.14.description.10">
                Furthermore, incorrect information can lead to your excellent-jobs account being deleted by us or
                claims for damages can be asserted against you.
              </Trans>
            </p>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.15.title">
                Privacy.
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.15.description.0">
                For providing our services we collect personal information about individuals and process it pursuant to
                our Privacy Policy. You agree to respect and follow the terms of the Privacy Policy of excellent jobs.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.15.description.1">
                Pursuant to the terms of our Privacy Policy your personal information may be disclosed to our ;matching
                system or other 3rd. Parties companies for the purpose of providing our service. By accepting
                these Terms, you consent to such data transfer and accept that we do not bear responsibility
                for any non-compliance relating to any third party service provider, even if it has occurred
                in process of provision of our services.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.15.description.2">
                Use of data available in our database.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.15.description.3">
                You are not allowed to make extracts from or copies of the database or use the database for any
                commercial purposes.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.15.description.4">
                A job seeker may use information in the excellent-jobs database (including our website) to contact
                potential employers in good faith to apply for a job vacancy. You are also not allowed to contact
                employers with other intentions than actual interest in job vacancy.
              </Trans>
            </p>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.16.title">
                No hiring clause.
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.16.description.0">
                We do not provide any labour hire services. Thus, we do not sign or mediate any employment contract
                nor bear liability for legal relations created by our customers (job seekers and employers) as a
                result of using our services. We do not take part in negotiating and entering into any employment
                contract between our customers. Complying with applicable labour law and other laws is a responsibility
                of our customers, mainly the employers.
              </Trans>
            </p>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.17.title">
                Intellectual property rights.
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.17.description.0">
                You agree to respect our intellectual property rights to our database, its content (including website)
                and the software used to provide excellent jobs. By use of our services, either by posting or otherwise
                from the moment of creation of any intellectual property with use of our services, you do not acquire
                any intellectual property rights and agree to transfer or grant a license where applicable law does not
                permit a transfer.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.17.description.1">
                You agree not to use our materials for any services similar to excellent jobs.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.17.description.2">
                For avoidance of doubt, we remain the sole owner of the excellent jobs database and by posting
                information on our website you are not becoming a joint owner of the rights.
              </Trans>
            </p>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.18.title">
                Termination and deletion of your account.
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.18.description.0">
                If you no longer want to be connected to excellent-jobs, you can independently delete your account or
                please send an e-mail to support@excellent-jobs.com and we will contact asap to clarify your actual
                intent and once this is confirmed we will delete your personal account permanently within thirty (30)
                days.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.18.description.1">
                With the account deletion we will also delete your personal data.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.18.description.2">
                You can send us a request to delete or use your data at any time. As soon as you have deleted your
                account, you acknowledge that we no longer have any data from you and that we can no longer give you
                data.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.18.description.3">
                When you submit a request about your data usage and history, you will receive a complete list of all
                data related to your account within 72 hours. To make sure no other users can see your data, make your
                account inactive.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.18.description.4">
                We will terminate our legal relationship (effective immediately) and delete your account if we,
                upon our reasonable opinion (including but not limited to assessing viable reports from other
                excellent-jobs users), find that you have (i) provided morally inappropriate, fraudulent,
                misleading or inaccurate information (ii) as a job seeker, contacted any employer for a
                purpose not related to the employment (iii) are not eligible to use our services or
                (iv) have violated any other term of these Terms and Conditions and have not remedied such violation
                (provided a remedy is possible and reasonable according to our opinion) within the term given by us.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.18.description.5">
                In addition to above we also reserve ourselves the right to stop providing services and terminate
                our legal relationship (effective immediately) without existence of circumstances attributable to you.
                In that case we will also store your data in our database for another thirty (30) days before we
                delete your data permanently.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.18.description.6">
                Furthermore, you accept that upon proof of any misuse of any kind, for example for data collection or
                other business actions, you can be indemnified by invent-solutions to a claim for damage and further
                legal actions.
              </Trans>
            </p>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.19.title">
                Limitation of liability.
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.19.description.0">
                To the maximum extent permitted by law, you assume full responsibility for any loss that results
                from your use of our services. We will not be liable for any indirect, punitive, special, or
                consequential damages under any circumstances, even if they’re based on negligence.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.19.description.1">
                We bear no liability for advertisers, linked websites or other users of excellent-jobs.
              </Trans>
            </p>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.20.title">
                No warranty.
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.20.description.0">
                We provide material on our website as it is. We do not provide warranties of any kind, either express
                or implied, including but not limited to warranties of merchantability and fitness for a particular
                purpose.
              </Trans>
            </p>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.21.title">
                Changes.
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.21.description.0">
                We may change any of these Terms and Conditions or any other terms agreed between us by posting
                revised terms on our website excellent-jobs.com and/or by sending an email to the last email
                address you gave us. Unless you delete your account within fourteen (14) days, the new terms
                will be effective immediately and apply to any continued or new use of our services.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.21.description.1">
                Changes may also be made by us though sending our new Terms and Conditions for your acceptance in which
                case these would come into force as of your acceptance of the new terms or a later date specified by us.
              </Trans>
            </p>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.22.title">
                Applicable law and settlement of disputes.
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.22.description.0">
                The law of the Federal Republic of Germany applies to the contract and its interpretation.
                Place of jurisdiction is Rostock.
                German law applies exclusively to all contracts, their implementation and the resulting claims,
                unless otherwise agreed in writing.
              </Trans>
            </p>
          </div>

          <div className="privacy-description">
            <h2 className="privacy-section-title" id="terms-and-conditions-employee">
              <Trans i18nKey="information.privacy.content.23.title">
                Terms and Conditions (for Employees)
              </Trans>
            </h2>
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.23.subtitle">
                Excellent-Jobs account.
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.23.description.0">
                In order to start fully using the excellent-jobs services, you must first sign up and create a
                excellent-jobs account.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.23.description.1">
                During this we request you to accept these Terms and Conditions.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.23.description.2">
                You are allowed to create a excellent-jobsaccount and use our services only if you are eligible to enter
                into legal contracts independently pursuant to jurisdiction of your residence. Normally, this means
                you have to be at least eighteen (18) years old.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.23.description.3">
                If you are creating a Recruiter account, you may only create an account for excellent jobs if you have
                an appropriate power of attorney from your employer.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.23.description.4">
                Upon signing up you will be asked to choose a password for your account. Keeping your password secure is
                paramount for protecting your personal information and avoiding violation of your rights. You agree not
                to share your account name and password with others or do anything that might jeopardize security of
                your account. We bear no liability for third party action in connection with the use of your account or
                password. Regardless, you agree to immediately notify us of any unauthorized access or other use of your
                account or password.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.23.description.5">
                By way of your account we allow you to create an individual profile and make this profile or aspects
                thereof public. A job seeker profile allows interested employers to access relevant information and
                submit employment related offers. An employer profile allows job posting and other recruitment
                activities for the purposes of hiring.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.23.description.6">
                Please note that when using your account and the services for excellent jobs, we will only accept
                accurate and accurate information from you or will not mislead other personal information. We reserve
                the right to refuse your job advertisements or other information that we deem to be misleading,
                inappropriate or inappropriate on the basis of the information we have at our disposal, including,
                but not limited to, content that is not contractually agreed. If necessary, we will inform you of our
                reasons for the refusal.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.23.description.7">
                You accept that depending on the selection of your account, you may not insert any content in your free
                text that contains any inferences about third-party services or contact data. Furthermore, you accept
                that proof of a bogus account is not permitted to obtain data of third parties or non-agreed services
                with recruiting companies or additional information from other hotels or locations of other hotels,
                regardless of their allocation or relationship with you and a subsequent calculation is minimum 5,000
                EUR is authorised by invent-solutions.
              </Trans>
            </p>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.24.title">
                Privacy.
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.24.description.0">
                For providing our services we collect personal information about individuals and process it pursuant to
                our Privacy Policy. You agree to respect and follow the terms of the Privacy Policy of excellent-jobs.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.24.description.1">
                Pursuant to the terms of our Privacy Policy your personal information may be disclosed to our system or
                other service providers for the purpose of providing our service. By accepting these Terms, you consent
                to such data transfer and accept that we do not bear responsibility for any non-compliance relating to
                any third party service provider, even if it has occurred in process of provision of our services.
              </Trans>
            </p>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.25.title">
                Use of data available in our database
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.25.description.0">
                You are not allowed to make extracts from or copies of the database or use the database for any
                commercial (also 3rd parties) purposes.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.25.description.1">
                A job seeker may use information in the excellent-jobs database (including our website) to contact
                potential employers in good faith to apply for a job vacancy. You are also not allowed to contact
                employers with other intentions than actual interest in job vacancy.
              </Trans>
            </p>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.26.title">
                No hiring clause
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.26.description.0">
                We do not provide any labour hire services. Thus, we do not sign or mediate any employment contract nor
                bear liability for legal relations created by our customers (job seekers and employers) as a result of
                using our services. We do not take part in negotiating and entering into any employment contract between
                our customers. Complying with applicable labour law and other laws is a responsibility of our customers,
                mainly the employers.
              </Trans>
            </p>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.27.title">
                Intellectual property rights
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.27.description.0">
                You agree to respect our intellectual property rights to our database, its content (including website)
                and the software used to provide excellent-jobs. By use of our services, either by posting or otherwise,
                you do not acquire any intellectual property rights and agree to transfer (or grant a license where
                applicable law does not permit a transfer) us from the moment of creation any intellectual property
                rights created with use of our services.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.27.description.1">
                You agree not to use our materials for any services similar to excellent-jobs.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.27.description.2">
                For avoidance of doubt, we remain the sole owner of the excellent-jobs database and by posting
                information on our website you are not becoming a joint owner of the rights.
              </Trans>
            </p>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.28.title">
                Termination and deletion of your account
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.28.description.0">
                If you no longer want to be connected to excellent-jobs, you can delete your account by yourself unter
                “profile-settings” or please send an e-mail to support@excellent-jobs.com
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.28.description.1">
                and we will contact you as soon as possible to clarify your actual intent and once this is confirmed we
                will delete your personal account permanently within thirty (30) days. With the account deletion we will
                also delete your personal data.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.28.description.2">
                Provided that within the thirty (30) day period you submit us a request for your information, we will
                send the information to your contacts within a reasonable period.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.28.description.3">
                We will terminate our legal relationship (effective immediately) and delete your account if we, upon our
                reasonable opinion (including but not limited to assessing viable reports from other excellent-
                jobsusers), find that you have (i) provided morally inappropriate, fraudulent, misleading or inaccurate
                information (ii) as a job seeker, contacted any employer for a purpose not related to the employment
                (iii) are not eligible to use our services or (iv) have violated any other term of these Terms and
                Conditions and have not remedied such violation (provided a remedy is possible and reasonable
                according to our opinion) within the term given by us.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.28.description.4">
                In addition to above we also reserve ourselves the right to stop providing services and terminate our
                legal relationship (effective immediately) without existence of circumstances attributable to you. In
                that case we will also store your data in our database for another thirty (30) days before we delete
                your data permanently.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.28.description.5">
                Furthermore, if you use your account improperly and contrary to the agreements made with us, for
                example you use your own commercial interests in relation to the sale or provision of data obtained
                from our system, we are entitled to claim a compensation of at least 5,000 EUR.
              </Trans>
            </p>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.29.title">
                Limitation of liability
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.29.description.0">
                To the maximum extent permitted by law, you assume full responsibility for any loss that results from
                your use of our services. We will not be liable for any indirect, punitive, special, or consequential
                damages under any circumstances, even if they’re based on negligence. We bear no liability for
                advertisers, linked websites or other users of excellent-jobs.
              </Trans>
            </p>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.30.title">
                No warranty
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.30.description.0">
                We provide material on our website as it is. We do not provide warranties of any kind, either express
                or implied, including but not limited to warranties of merchantability and fitness for a particular
                purpose.
              </Trans>
            </p>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.31.title">
                Changes
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.31.description.0">
                We may change any of these Terms and Conditions or any other terms agreed between us by posting revised
                terms on our website excellent-jobs.com and/or by sending an email to the last email address you gave
                us. Unless you delete your account within fourteen (14) days, the new terms will be effective
                immediately and apply to any continued or new use of our services.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.31.description.1">
                Changes may also be made by us though sending our new Terms and Conditions for your acceptance in which
                case these would come into force as of your acceptance of the new terms or a later date specified by us.
              </Trans>
            </p>
          </div>
          <div className="privacy-description">
            <h3 className="privacy-caption">
              <Trans i18nKey="information.privacy.content.32.title">
                Applicable law and settlement of disputes
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="information.privacy.content.32.description.0">
                Should individual provisions of this contract be ineffective or unenforceable or become ineffective or
                unenforceable after the conclusion of the contract, the validity of the rest of the terms shall remain
                unaffected.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="information.privacy.content.32.description.1">
                The law of the Federal Republic of Germany applies to the contract and its interpretation.
                Place of jurisdiction is Rostock.
                German law applies exclusively to all contracts, their implementation and the resulting claims,
                unless otherwise agreed in writing.
              </Trans>
            </p>
          </div>
        </div>
      </div>
    );
  }

}
