export const GET_CANDIDATE_SKILLS_REQUEST = 'GET_CANDIDATE_SKILLS_REQUEST';
export const GET_CANDIDATE_SKILLS_SUCCESS = 'GET_CANDIDATE_SKILLS_SUCCESS';
export const GET_CANDIDATE_SKILLS_FAILURE = 'GET_CANDIDATE_SKILLS_FAILURE';

export const CREATE_CANDIDATE_SKILLS_REQUEST = 'CREATE_CANDIDATE_SKILLS_REQUEST';
export const CREATE_CANDIDATE_SKILLS_SUCCESS = 'CREATE_CANDIDATE_SKILLS_SUCCESS';
export const CREATE_CANDIDATE_SKILLS_FAILURE = 'CREATE_CANDIDATE_SKILLS_FAILURE';

export const UPDATE_CANDIDATE_SKILLS_REQUEST = 'UPDATE_CANDIDATE_SKILLS_REQUEST';
export const UPDATE_CANDIDATE_SKILLS_SUCCESS = 'UPDATE_CANDIDATE_SKILLS_SUCCESS';
export const UPDATE_CANDIDATE_SKILLS_FAILURE = 'UPDATE_CANDIDATE_SKILLS_FAILURE';

export const RESET_CANDIDATE_SKILLS_SUCCESS = 'RESET_CANDIDATE_SKILLS_SUCCESS';
