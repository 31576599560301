export const GET_NEW_JOB_OFFERS_MATCHES_REQUEST = 'GET_NEW_JOB_OFFERS_MATCHES_REQUEST';
export const GET_NEW_JOB_OFFERS_MATCHES_SUCCESS = 'GET_NEW_JOB_OFFERS_MATCHES_SUCCESS';
export const GET_NEW_JOB_OFFERS_MATCHES_FAILURE = 'GET_NEW_JOB_OFFERS_MATCFAILURE';

export const GET_JOB_OFFERS_MATCHES_LOCATIONS_REQUEST = 'GET_JOB_OFFERS_MATCHES_LOCATIONS_REQUEST';
export const GET_JOB_OFFERS_MATCHES_LOCATIONS_SUCCESS = 'GET_JOB_OFFERS_MATCHES_LOCATIONS_SUCCESS';
export const GET_JOB_OFFERS_MATCHES_LOCATIONS_FAILURE = 'GET_JOB_OFFERS_MATCHES_LOCATIONS_FAILURE';

export const GET_NEW_JOB_OFFERS_MATCHES_COUNT_REQUEST = 'GET_NEW_JOB_OFFERS_MATCHES_COUNT_REQUEST';
export const GET_NEW_JOB_OFFERS_MATCHES_COUNT_SUCCESS = 'GET_NEW_JOB_OFFERS_MATCHES_COUNT_SUCCESS';
export const GET_NEW_JOB_OFFERS_MATCHES_COUNT_FAILURE = 'GET_NEW_JOB_OFFERS_MATCHES_COUNT_FAILURE';

export const RESET_NEW_JOB_OFFERS_MATCHES_SUCCESS = 'RESET_NEW_JOB_OFFERS_MATCHES_SUCCESS';

export const RESET_NEW_JOB_OFFERS_BUBBLE_SUCCESS = 'RESET_NEW_JOB_OFFERS_BUBBLE_SUCCESS';
