export class JobOfferViewedDto {

  link: string;
  jobOfferId: string;

  constructor (data?: JobOfferViewedDto) {
    if (data) {
      this.link = data.link;
      this.jobOfferId = data.jobOfferId;
    }
  }

}
