import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import { TransProps } from 'react-i18next';

import type { Match } from 'common/types/match';
import type { GlobalState } from 'common/types/global-state';

import { deletePost, getPostById } from 'services/portal/candidate/blog/blog.actions';

import { PostsResponseDto } from 'rest/candidates/posts/posts.response.dto';

import './preview.scss';
import './preview.responsive.scss';

type Props = {
  t?: TransProps,
  match: Match,
  history: History,
  previewPost: PostsResponseDto,
  deletePost: (postId: string) => void,
  getPostById: (postId: string) => void
}

type State = {
  activePhotoUrl: string,
  activePhotoIndex: number,
  isDeleteModalOpen: boolean
}

export const carouselSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  initialSlide: 0
};

export class Settings extends Component<Props> {

  static defaultProps: Props = {
    deletePost: () => {},
    getPostById: () => {},
    previewPost: new PostsResponseDto()
  };

  state: State = {
    activePhotoUrl: '',
    activePhotoIndex: null,
    isDeleteModalOpen: false
  };

}

export const stateToProps = (state: GlobalState) => ({
  previewPost: state.portal.candidate.blog.previewPost
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  deletePost,
  getPostById
}, dispatch);
