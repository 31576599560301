import React, { Fragment } from 'react';

import moment from 'moment';

import { Settings } from './date.setting';

export class ChatDate extends Settings {

  getPreviousDate () {
    return this.props.previousDate ? moment(this.props.previousDate).format('MMM D, YYYY') : null;
  }

  render () {
    const date = moment(this.props.date).format('MMM D, YYYY');
    return (
      <Fragment>
        {
          this.props.index === 0 && <div className="date-separator mobile-date-separator">{date}</div>
        }
        {
          this.props.index > 0 && date !== this.getPreviousDate() &&
          <div className="date-separator mobile-date-separator">{date}</div>
        }
      </Fragment>
    );
  }

}
