import { ImageDto } from 'rest/images/image.dto';

export class ChatParticipantDto {

  title: string;
  photo: ImageDto;
  online: boolean;
  userId: string;
  userType: string;
  lastName: string;
  firstName: string;
  candidateId: string;
  recruiterId: string;

  constructor (data?: ChatParticipantDto) {
    if (data) {
      this.title = data.title;
      this.photo = new ImageDto(data.photo);
      this.online = data.online;
      this.userId = data.userId;
      this.userType = data.userType;
      this.lastName = data.lastName;
      this.firstName = data.firstName;
      this.recruiterId = data.recruiterId;
      this.candidateId = data.candidateId;
    }
  }

  get fullName (): string {
    return this.firstName && this.lastName ? `${this.firstName} ${this.lastName}` : null;
  }

  get photoUrl (): string {
    return this.photo && this.photo.url ? this.photo.url : '/images/shared/userpick.jpg';
  }

  get userTechnicalId (): string {
    return this.recruiterId ? this.recruiterId : this.candidateId;
  }

}
