import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { CompanyDto } from 'rest/companies/companies.dto';
import { RecruiterDto } from 'rest/recruiter/recruiter.dto';
import { ImageResponseDto } from 'rest/images/images.response';

import { uploadFile } from 'services/portal/images/images.actions';
import { recruiterSignUp } from 'services/auth/recruiter/auth.action';

import './personal-info.scss';

export type Props = {
  next: () => void,
  prev: () => void,
  fetching: boolean,
  redirect: boolean,
  company: CompanyDto,
  imageFetching: boolean,
  confirmationSuccess: boolean,
  avatarInfo: ImageResponseDto,
  recruiterSignUp: (data: RecruiterDto) => void,
  uploadFile: (file: ?File, type: string) => void
}

export type State = {
  avatar: ?File,
  errorText: string,
  firstName: string,
  redirect: boolean,
  password: string,
  lastName: string,
  avatarError: boolean,
  phoneNumber: string,
  confirmPassword: string,
  avatarPreview: ?string,
  errorCollection: Set<string>,
  publishJobOfferSuccess: boolean
}

export class Settings extends Component<Props, State> {

  static defaultProps: Props = {
    next: () => {},
    prev: () => {},
    recruiterSignUp: () => {}
  };

  state: State = {
    avatar: null,
    lastName: '',
    firstName: '',
    phoneNumber: '',
    redirect: false,
    password: '',
    errorText: '',
    avatarError: false,
    avatarPreview: '',
    confirmPassword: '',
    errorCollection: new Set()
  };

}

export const stateToProps = (state: GlobalState) => ({
  image: state.portal.images.avatarInfo,
  fetching: state.auth.recruiter.signUp.fetching,
  errorText: state.auth.recruiter.signUp.errorText,
  imageFetching: state.portal.images.avatarLoading,
  redirect: state.auth.recruiter.signUp.signUpSuccess
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  uploadFile,
  recruiterSignUp
}, dispatch);
