import { Component } from 'react';

import './container.scss';
import './container.responsive.scss';

type Props = {
  children?: Node
};

export class Setting extends Component<Props> {}
