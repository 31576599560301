import { Component } from 'react';

type Props = {
  label?: string,
  counter?: number,
  onClick?: () => void,
  children: Node
};

export class Setting extends Component<Props> {

  static defaultProps = {
    label: '',
    counter: 0,
    onClick: () => {}
  }

}
