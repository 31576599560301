export const CONTACT_ANONYMOUS_REQUEST = 'CONTACT_ANONYMOUS_REQUEST';
export const CONTACT_ANONYMOUS_SUCCESS = 'CONTACT_ANONYMOUS_SUCCESS';
export const CONTACT_ANONYMOUS_FAILURE = 'CONTACT_ANONYMOUS_FAILURE';

export const ACCEPT_CONTACT_REQUEST = 'ACCEPT_CONTACT_REQUEST';
export const ACCEPT_CONTACT_SUCCESS = 'ACCEPT_CONTACT_SUCCESS';
export const ACCEPT_CONTACT_FAILURE = 'ACCEPT_CONTACT_FAILURE';

export const DECLINE_CONTACT_REQUEST = 'DECLINE_CONTACT_REQUEST';
export const DECLINE_CONTACT_SUCCESS = 'DECLINE_CONTACT_SUCCESS';
export const DECLINE_CONTACT_FAILURE = 'DECLINE_CONTACT_FAILURE';
