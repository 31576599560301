import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import {
  pushExperience,
  removeExperience,
  resetWorkExperience,
  changeWorkExperience,
  getCandidateWorkExperiences,
  deleteCandidateWorkExperience,
  createCandidateWorkExperience,
  updateCandidateWorkExperience
} from 'services/portal/candidate/cv/work-experience/work-experience.actions';

import { WorkExperienceDto } from 'rest/candidates/work-experience/work-experience.dto';

import './work-experience.responsive.scss';

export type Props = {
  t?: TransProps,
  experiences: WorkExperienceDto[]
}

type State = {
  createSuccess: boolean
}

export class Settings extends Component<Props, State> {

  state: State = {
    createSuccess: false
  }

}

export const stateToProps = (state: GlobalState) => ({
  experiences: state.portal.candidate.cv.experience.content,
  createSuccess: state.portal.candidate.cv.experience.createSuccess,
  currentCandidate: state.portal.candidate.account.current.candidateData
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  pushExperience,
  removeExperience,
  resetWorkExperience,
  changeWorkExperience,
  getCandidateWorkExperiences,
  deleteCandidateWorkExperience,
  createCandidateWorkExperience,
  updateCandidateWorkExperience
}, dispatch);
