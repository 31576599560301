// @flow
import { Component } from 'react';

import './icon-button.scss';

type Props = {
  icon: string,
  title: string,
  onClick: () => void,
  disabled?: boolean,
  outlined: boolean,
  recruiter: boolean
};

export class Settings extends Component<Props> {

  static defaultProps: Props = {
    icon: '',
    title: '',
    outlined: false,
    disabled: false,
    onClick: () => {},
    recruiter: false
  };

}
