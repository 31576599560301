import axios from 'axios';

import { BrandsDto } from './brand.dto';

export async function getBrands (params: BrandsDto): Promise<BrandsDto> {
  try {
    return await axios.get(`/brands`, {
      params
    });
  } catch (error) {
    throw error;
  }
}
