// @flow
import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';

import I18n, { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import moment from 'moment';

import { uploadFile } from 'services/portal/images/images.actions';
import { getCurrentCandidateSuccess } from 'services/portal/candidate/account/current/current.actions';
import {
  registerCandidate,
  registerFromSocial,
  getCurrentCandidate,
  activateCurrentCandidate
} from 'services/portal/candidate/account/account.actions';
import { searchSuggestedAddress } from 'services/portal/address/address.actions';
import { changeCurrentLocalization } from 'services/portal/candidate/account/current/current.actions';

import { ImageDto } from 'rest/images/image.dto';
import { CandidateDto } from 'rest/candidates/candidate.dto';
import { ImageResponseDto } from 'rest/images/images.response';
import { CandidateSocialDto } from 'rest/candidates/candidate-social.dto';

import './account.scss';
import './account.responsive.scss';

export type Props = {
  i18n: I18n,
  t?: TransProps,
  fetching: boolean,
  redirect: boolean,
  avatarLoading: false,
  imageInfo: ImageResponseDto,
  getCurrentCandidate: () => void,
  registerCandidateSuccess: boolean,
  currentCandidate: CandidateSocialDto,
  getCurrentCandidateSuccess: () => void,
  uploadFile: (file: File, type: string) => void,
  activateCurrentCandidate: (id: string) => void,
  changeCurrentLocalization?: (lang: string) => void,
  registerFromSocial: (candidate: CandidateSocialDto) => void,
  registerCandidate: (candidateId: string, candidate: CandidateDto) => void
};

export type State = {
  email: string,
  birthday: string;
  lastName: string,
  firstName: string,
  phoneNumber: string,
  avatarError: boolean,
  jobOfferLink: string,
  avatarPreview: string,
  candidatePhotos: ImageDto[],

  city: string,
  street: string,
  zipCode: string,
  country: string,
  streetNumber: string,

  socialId: string,
  socialType: string
};

export class Setting extends Component<Props, State> {

  static defaultProps: Props = {
    fetching: false,
    redirect: false,
    avatarLoading: false,
    registerCandidateSuccess: false,
    imageInfo: new ImageResponseDto(),
    currentCandidate: new CandidateSocialDto(),
    uploadFile: () => {},
    registerCandidate: () => {},
    registerFromSocial: () => {},
    getCurrentCandidate: () => {},
    activateCurrentCandidate: () => {},
    changeCurrentLocalization: () => {},
    getCurrentCandidateSuccess: () => {}
  };

  state: State = {
    email: '',
    lastName: '',
    firstName: '',
    phoneNumber: '',
    jobOfferLink: '',
    avatarPreview: '',
    avatarError: false,
    candidatePhotos: [],
    birthday: moment().subtract(18, 'year'),

    city: '',
    street: '',
    zipCode: '',
    country: '',
    streetNumber: '',

    socialId: '',
    socialType: ''
  };

}

export const stateToProps = (state: GlobalState) => ({
  address: state.portal.address.content,
  imageInfo: state.portal.images.avatarInfo,
  avatarLoading: state.portal.images.avatarLoading,
  error: state.portal.candidate.account.current.errorText,
  fetching: state.portal.candidate.account.current.fetching,
  redirect: state.portal.candidate.account.current.redirect,
  currentCandidate: state.portal.candidate.account.current.candidateData,
  registerCandidateSuccess: state.portal.candidate.account.current.registerCandidateSuccess
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  uploadFile,
  registerCandidate,
  registerFromSocial,
  getCurrentCandidate,
  searchSuggestedAddress,
  activateCurrentCandidate,
  changeCurrentLocalization,
  getCurrentCandidateSuccess
}, dispatch);
