import React from 'react';

import Autosuggest from 'react-autosuggest';

import type { Props } from './autosuggest-select.setting';
import { Setting } from './autosuggest-select.setting';

export class AutosuggestSelect extends Setting {

  componentDidMount () {
    this.inputElement.current.input.autocomplete = 'new_password';
    this.inputElement.current.input.required = this.props.required;
    if (this.props.defaultValue !== this.state.inputValue) {
      this.setState({ inputValue: this.props.defaultValue });
    }
  }

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.defaultValue !== this.state.inputValue) {
      this.setState({ inputValue: nextProps.defaultValue });
    }
  }

  changeInputState (event, value) {
    this.setState({ inputValue: value.newValue }, () => this.props.change(value.newValue));
  }

  render () {
    const inputProps = {
      value: this.state.inputValue,
      placeholder: this.props.placeholder,
      onChange: this.changeInputState.bind(this)
    };

    return (
      <label className="autosuggest-label autosuggest-container">
        <div className="autosuggest-label-title-container">
          <span className="autosuggest-label-title">{this.props.label}</span>
          {
            this.props.error && <span className="autosuggest-label-title error">
              {this.props.errorText || 'Field is required'}</span>
          }
        </div>
        <Autosuggest
          ref={this.inputElement}
          inputProps={inputProps}
          suggestions={this.props.items}
          getSuggestionValue={suggestion => suggestion[this.props.bindValue]}
          onSuggestionsClearRequested={() => this.setState({ items: [] })}
          onSuggestionsFetchRequested={props => this.props.search(props.value)}
          renderSuggestion={suggestion => <div>{suggestion.description}</div>}/>
      </label>
    );
  }

}
