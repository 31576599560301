// @flow
import React from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectTranslation } from 'middleware/connect';
import { getData } from 'middleware/payment-step';

import { Trans } from 'react-i18next';

import { TabsContent } from 'components/tabs';
import { Sidebar, Card } from 'components/layouts';

import { Modal } from 'containers/portal/modal-container';
import { RecruiterInfo, CompanyInfo } from 'containers/portal/recruiter/account/settings';

import { RecruiterHistory } from './../history/history';
import { ChangePasswordPage } from 'containers/portal/settings';
import { RecruiterBlackList } from './../black-list/black-list';
import { ProfileTeamMembers } from './../team-members/team-members';
import { PaymentDetails } from './../payment-details/payment-details';
import { RecruiterNotificationSettings } from './../notifications/notifications';

import type { Props } from './profile-modal.setting';
import { Settings } from './profile-modal.setting';

@connectTranslation()
export class RecruiterProfileModal extends Settings {

  componentWillReceiveProps (nextProps: Props) {
    const paymentStepLink = getData('ej_payment_step_link');

    if (this.props.open !== nextProps.open) {
      this.setState({
        activeNavLink: paymentStepLink && paymentStepLink.showModal ? paymentStepLink.activeTab : 0
      });
    }
  }

  render () {
    return (
      <Modal
        open={this.props.open}
        animation="slide"
        overlay>
        <div className="setting-modal-container">
          <Sidebar
            history={this.props.history}
            activeTabIndex={this.state.activeNavLink}>
            <li onClick={() => this.setState({ activeNavLink: 0 })}>
              <Trans i18nKey="portal.recruiter.profile.menu.0">
                User Profile
              </Trans>
            </li>
            <li onClick={() => this.setState({ activeNavLink: 1 })}>
              <Trans i18nKey="portal.recruiter.profile.menu.1">
                Password
              </Trans>
            </li>
            <li onClick={() => this.setState({ activeNavLink: 2 })}>
              <Trans i18nKey="portal.recruiter.profile.menu.2">
                Company Profile
              </Trans>
            </li>
            <li onClick={() => this.setState({ activeNavLink: 3 })}>
              <Trans i18nKey="portal.recruiter.profile.menu.3">
                Team Members
              </Trans>
            </li>
            <li onClick={() => this.setState({ activeNavLink: 4 })}>
              <Trans i18nKey="portal.recruiter.profile.menu.4">
                Payment Details
              </Trans>
            </li>
            <li onClick={() => this.setState({ activeNavLink: 5 })}>
              <Trans i18nKey="portal.recruiter.profile.menu.5">
                Notifications
              </Trans>
            </li>
            <li onClick={() => this.setState({ activeNavLink: 6 })}>
              <Trans i18nKey="portal.recruiter.profile.menu.6">
                History
              </Trans>
            </li>
            <li onClick={() => this.setState({ activeNavLink: 7 })}>
              <Trans i18nKey="portal.recruiter.profile.menu.7">
                Blocked Users
              </Trans>
            </li>
          </Sidebar>
          <Card>
            <div className="profile-modal-content">
              <PerfectScrollbar>
                <TabsContent activeTab={this.state.activeNavLink}>
                  <div>
                    <h2 className="profile-tab-caption">
                      <Trans i18nKey="portal.recruiter.profile.menu.0">
                        User Profile
                      </Trans>
                    </h2>
                    <RecruiterInfo actionButtonTitle={this.props.t('portal.recruiter.profile.buttons.save')}/>
                  </div>
                  <div>
                    <h2 className="profile-tab-caption">
                      <Trans i18nKey="portal.recruiter.profile.menu.1">
                        Password
                      </Trans>
                    </h2>
                    <ChangePasswordPage buttonType="accent"/>
                  </div>
                  <div>
                    <h2 className="profile-tab-caption">
                      <Trans i18nKey="portal.recruiter.profile.menu.2">
                        Company Profile
                      </Trans>
                    </h2>
                    <CompanyInfo
                      showBackButton={false}
                      actionButtonTitle={this.props.t('portal.recruiter.profile.buttons.save')}/>
                  </div>
                  <div>
                    <h2 className="profile-tab-caption">
                      <Trans i18nKey="portal.recruiter.profile.menu.3">
                        Team Members
                      </Trans>
                    </h2>
                    <ProfileTeamMembers/>
                  </div>
                  <div>
                    <h2 className="profile-tab-caption">
                      <Trans i18nKey="portal.recruiter.profile.menu.4">
                        Payment Details
                      </Trans>
                    </h2>
                    <PaymentDetails history={this.props.history}/>
                  </div>
                  <div>
                    <h2 className="profile-tab-caption">
                      <Trans i18nKey="portal.recruiter.profile.menu.5">
                        Notifications
                      </Trans>
                    </h2>
                    <RecruiterNotificationSettings/>
                  </div>
                  <div>
                    <h2 className="profile-tab-caption">
                      <Trans i18nKey="portal.recruiter.profile.menu.6">
                        History
                      </Trans>
                    </h2>
                    <RecruiterHistory/>
                  </div>
                  <div>
                    <h2 className="profile-tab-caption">
                      <Trans i18nKey="portal.recruiter.profile.menu.7">
                        Blocked Users
                      </Trans>
                    </h2>
                    <RecruiterBlackList/>
                  </div>
                </TabsContent>
              </PerfectScrollbar>
            </div>
          </Card>
        </div>
      </Modal>
    );
  }

}
