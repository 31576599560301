import { Component } from 'react';

import type { GlobalState } from 'common/types/global-state';

import { LifeStyleDto } from 'rest/candidates/lifestyles/lifestyles.dto';

import './../competencies-section/competencies-section.scss';
import './../competencies-section/competencies-section.responsive.scss';

type Props = {
  activities: LifeStyleDto
}

export class Settings extends Component<Props> {

  static defaultProps = {
    activities: []
  }

}

export const stateToProps = (state: GlobalState) => ({
  activities: state.portal.candidate.cv.lifestyle.filteredActivities
});
