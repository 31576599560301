// @flow
import axios, { AxiosPromise } from 'axios';

import { JobOfferViewedDto } from './viewed.dto';

export async function markJobOfferAsViewed (jobOfferId: string, data: JobOfferViewedDto): AxiosPromise<string> {
  try {
    return await axios.post(`/job-offers/${jobOfferId}/viewed`, data);
  } catch (error) {
    throw error;
  }
}
