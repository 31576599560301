import * as actions from './software.constants';

import { ListDto, PaginationDto, SearchDto } from 'middleware/dto';

import { getSoftware, searchSoftware as search } from 'rest/software/software.rest';

const getSoftwareRequest = () => ({
  type: actions.GET_SOFTWARE_REQUEST
});

const getSoftwareSuccess = (software: ListDto[]) => ({
  type: actions.GET_SOFTWARE_SUCCESS,
  software
});

const getSoftwareFailure = (error: string) => ({
  type: actions.GET_SOFTWARE_FAILURE,
  error
});

const searchSoftwareRequest = (query: string) => ({
  type: actions.SEARCH_SOFTWARE_REQUEST,
  query
});

const searchSoftwareSuccess = (software: ListDto[]) => ({
  type: actions.SEARCH_SOFTWARE_SUCCESS,
  software
});

const searchSoftwareFailure = (error: string) => ({
  type: actions.SEARCH_SOFTWARE_FAILURE,
  error
});

export const resetSearch = () => ({
  type: actions.SEARCH_SOFTWARE_RESET
});

export const resetSearchResult = () => {
  return dispatch => {
    dispatch(resetSearch());
  };
};

export const getAllSoftware = (params: PaginationDto) => {
  return dispatch => {
    dispatch(getSoftwareRequest());
    return getSoftware(params)
      .then(response => dispatch(getSoftwareSuccess(response.data.content)))
      .catch(error => dispatch(getSoftwareFailure(error)));
  };
};

export const searchSoftware = (params: SearchDto) => {
  return dispatch => {
    dispatch(searchSoftwareRequest(params.q));
    return search(params)
      .then(response => dispatch(searchSoftwareSuccess(response.data.content)))
      .catch(error => dispatch(searchSoftwareFailure(error)));
  };
};
