import React from 'react';

import { IconButton } from 'components/buttons';

import cn from 'classnames';

import { Setting } from './modal-caption.setting';

export class ModalCaption extends Setting {

  render () {
    const captionClasses = cn([ 'modal-caption', {
      transparent: this.props.transparent
    } ]);

    return (
      <div className={captionClasses}>
        <p>{this.props.title}</p>
        <IconButton
          icon="close"
          onClick={event => this.props.hideModal(event)}/>
      </div>
    );
  }

}
