import React from 'react';
import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';

import { Settings, stateToProps } from './cv-button-action.setting';

@connectStore(stateToProps, null, true)
export class CVButtonAction extends Settings {

  render () {
    return (
      <>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={this.props.previewLink}
          className="btn primary outlined">
          <Trans i18nKey="portal.candidate.cv.list.preview">
            Preview
          </Trans>
        </a>
        <a
          href={this.props.link}
          className="btn primary">
          <Trans i18nKey="portal.candidate.cv.list.pdf">
            Generate PDF
          </Trans>
        </a>
      </>
    );
  }

}
