import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Card, Page } from 'components/layouts';

import { CandidateMenu } from 'containers/portal/guest/candidate';

import { RecruiterMenu } from 'containers/portal/guest/recruiter';

import { NavigationContainer, NavigationItem } from 'containers/portal/navigation';

import { NotFoundPoster } from 'containers/portal/not-found';

import { JobOffersCircle } from 'containers/portal/candidate/circles';
import { CandidatesCircle } from 'containers/portal/recruiter/circles';

import { JobOffersSidebar } from 'containers/portal/candidate/sidebars';
import { CandidatesListSidebar } from 'containers/portal/recruiter/sidebars/candidates-list/candidates-list';

import { JobsPage } from './jobs';
import { CandidatesPage } from './candidates';

export class GuestPortal extends Component {

  render () {
    return (
      <div className="guest-page">
        <Page isGuestPage>
          <Card>
            <Switch>
              <Route
                path="/portal/guest/jobs"
                component={JobsPage}/>
              <Route
                path="/portal/guest/candidates"
                component={CandidatesPage}/>
              <Route
                path="**"
                component={NotFoundPoster}/>
            </Switch>
          </Card>
        </Page>
        <NavigationContainer className="mobile-guest-navigation-widgets">
          <NavigationItem className="navigation-widget">
            <Card>
              <Switch>
                <Route
                  path="/portal/guest/jobs"
                  component={JobOffersSidebar}/>
                <Route
                  path="**"
                  component={CandidatesListSidebar}/>
              </Switch>
            </Card>
          </NavigationItem>
          <NavigationItem className="profile-widget">
            <Switch>
              <Route
                path="/portal/guest/jobs"
                component={CandidateMenu}/>
              <Route
                path="**"
                component={RecruiterMenu}/>
            </Switch>
          </NavigationItem>
          <NavigationItem className="chart-widget">
            <Card>
              <Switch>
                <Route
                  path="/portal/guest/jobs"
                  component={JobOffersCircle}/>
                <Route
                  path="**"
                  component={CandidatesCircle}/>
              </Switch>
            </Card>
          </NavigationItem>
        </NavigationContainer>
      </div>
    );
  }

}
