import { SkillItemDto } from './skill-item.dto';

export class SkillsDto {

  matchPoints: number;
  emptySkills: boolean;
  documents: SkillItemDto[] = [];
  languageSkills: SkillItemDto[] = [];
  softwareSkills: SkillItemDto[] = [];
  professionalSkills: SkillItemDto[] = [];

  constructor (data?: SkillsDto) {
    if (data) {
      this.documents = data.documents;
      this.matchPoints = data.matchPoints || 75;
      this.emptySkills = data.emptySkills = true;
      this.languageSkills = data.languageSkills;
      this.softwareSkills = data.softwareSkills;
      this.professionalSkills = data.professionalSkills;
    }
  }

}
