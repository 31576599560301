// @flow
import React from 'react';

import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Button } from 'components/buttons';

import { ContactModal } from 'containers/landing';

import { Setting } from './questions-button.setting';

@connectTranslation()
export class QuestionsButtons extends Setting {

  render () {
    return (
      <div className="que-btn mobile-que-btn">
        <Button
          onClick={() => this.setState({ openContactModal: true })}>
          <Trans i18nKey="landing.questions.button">
            Write to Us
          </Trans>
        </Button>
        <ContactModal
          open={this.state.openContactModal}
          close={() => this.setState({ openContactModal: false })}/>
      </div>
    );
  }

}
