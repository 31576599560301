import * as actions from './skills.constants';

import { SkillsDto } from 'rest/skill/skills.dto';

import { getJobOfferSkills, updateJobOfferSkills, createJobOfferSkills } from 'rest/job-offers/skills/skills.rest';

import { publishNewJobOffer } from 'services/portal/recruiter/job-offer/process/process.actions';
import { getAllCompanyJobOffers } from 'services/portal/recruiter/account/job-offer/job-offer.actions';

const getJobOfferSkillsRequest = () => ({
  type: actions.GET_JOB_OFFER_SKILLS_REQUEST
});

const getJobOfferSkillsSuccess = (skills: SkillsDto) => ({
  type: actions.GET_JOB_OFFER_SKILLS_SUCCESS,
  skills
});

const getJobOfferSkillsFailure = (error: string) => ({
  type: actions.GET_JOB_OFFER_SKILLS_FAILURE,
  error
});

const createJobOfferSkillsRequest = () => ({
  type: actions.CREATE_JOB_OFFER_SKILLS_REQUEST
});

const createJobOfferSkillsSuccess = () => ({
  type: actions.CREATE_JOB_OFFER_SKILLS_SUCCESS
});

const createJobOfferSkillsFailure = (error: string) => ({
  type: actions.CREATE_JOB_OFFER_SKILLS_FAILURE,
  error
});

const updateJobOfferSkillsRequest = () => ({
  type: actions.UPDATE_JOB_OFFER_SKILLS_REQUEST
});

const updateJobOfferSkillsSuccess = (skills: SkillsDto) => ({
  type: actions.UPDATE_JOB_OFFER_SKILLS_SUCCESS,
  skills
});

const updateJobOfferSkillsFailure = (error: string) => ({
  type: actions.UPDATE_JOB_OFFER_SKILLS_FAILURE,
  error
});

const resetJobOfferSkillsSuccess = () => ({
  type: actions.RESET_JOB_OFFER_SKILLS
});

export const resetJobOfferSkills = () => {
  return dispatch => {
    dispatch(resetJobOfferSkillsSuccess());
  };
};

export const getCompanyJobOfferSkills = (jobOfferId: string) => {
  return dispatch => {
    dispatch(getJobOfferSkillsRequest());
    return getJobOfferSkills(jobOfferId)
      .then(response => dispatch(getJobOfferSkillsSuccess(response.data)))
      .catch(error => dispatch(getJobOfferSkillsFailure(error)));
  };
};

export const createNewJobOfferSkills = (jobOfferId: string, data: SkillsDto) => {
  return dispatch => {
    dispatch(createJobOfferSkillsRequest());
    return createJobOfferSkills(jobOfferId, data)
      .then(() => dispatch(createJobOfferSkillsSuccess()))
      .catch(error => dispatch(createJobOfferSkillsFailure(error)));
  };
};

export const updateCompanyJobOfferSkills = (
  id: string,
  data: SkillsDto,
  companyId?: string,
  publish?: boolean,
  resetContent: boolean = false
) => {
  return dispatch => {
    dispatch(updateJobOfferSkillsRequest());
    return updateJobOfferSkills(id, data)
      .then(() => {
        dispatch(updateJobOfferSkillsSuccess(data));
        if (publish) {
          dispatch(publishNewJobOffer(id, companyId));
        }
        if (resetContent) {
          dispatch(resetJobOfferSkills());
        }
        if (companyId && !publish) {
          dispatch(getAllCompanyJobOffers(companyId));
        }
        return id;
      })
      .catch(error => dispatch(updateJobOfferSkillsFailure(error)));
  };
};
