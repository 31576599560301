import React, { Component } from 'react';

import { TransProps } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import type { Match } from 'common/types/match';

import { AuthBody } from 'containers/auth/layouts';
import { ConfirmResetPasswordForm } from 'containers/auth/reset';

type Props = {
  t: TransProps,
  match: Match
}

@connectTranslation()
export class ConfirmResetPasswordPage extends Component<Props> {

  render () {
    return (
      <div className="confirm-reset-password-page">
        <AuthBody title={this.props.t('auth.reset.confirm.title')}>
          <ConfirmResetPasswordForm
            buttonType="recruiter"
            redirectUrl="/auth/recruiter/reset/success"
            match={this.props.match}/>
        </AuthBody>
      </div>
    );
  }

}
