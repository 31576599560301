import { Component } from 'react';
import { TransProps } from 'react-i18next';

import './cv.scss';

type Props = {
  t: TransProps,
}

export class Settings extends Component<Props> {}
