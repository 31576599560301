import { Component } from 'react';

import { ImageResponseDto } from 'rest/images/images.response';

import './preview-image.scss';
import './preview-image.responsive.scss';

type Props = {
  files: File | ImageResponseDto[],
  removePreviewPhoto: (id: number) => void,
  imageLoad: (imageInfo: ImageResponseDto) => void,
  selectImage?: (file: File | ImageResponseDto[]) => void
}

type State = {
  files: File | ImageResponseDto[]
}

export class Settings extends Component<Props, State> {

  static defaultProps = {
    files: [],
    removePreviewPhoto: (id: number) => {},
    imageLoad: (imageInfo: ImageResponseDto = new ImageResponseDto()) => {},
    selectImage: null
  };

  state = {
    files: []
  };

}
