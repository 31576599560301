import * as actions from './stats.constants';

import { StatsMatchDto } from 'rest/companies/job-offers-matches/matched-candidates/stats/stats.dto';
import { getMatchedJobOffersStats } from 'rest/companies/job-offers-matches/matched-candidates/stats/stats.rest';

const getMatchStatisticRequest = () => ({
  type: actions.GET_MATCH_STATS_REQUEST
});

const getMatchStatisticSuccess = (data: StatsMatchDto) => ({
  type: actions.GET_MATCH_STATS_SUCCESS,
  jobOffersCount: data.jobOffersCount,
  matchedCandidatesCount: data.matchedCandidatesCount,
  matchedNewCandidatesCount: data.matchedNewCandidatesCount
});

const getMatchStatisticFailure = (error: string) => ({
  type: actions.GET_MATCH_STATS_FAILURE,
  error
});

const resetMatchStatisticSuccess = () => ({
  type: actions.RESET_MATCH_STATS_SUCCESS
});

export const resetMatchStats = () => {
  return dispatch => {
    dispatch(resetMatchStatisticSuccess());
  };
};

export const getMatchStatistic = (companyId: string) => {
  return dispatch => {
    dispatch(getMatchStatisticRequest());
    return getMatchedJobOffersStats(companyId)
      .then(response => dispatch(getMatchStatisticSuccess(response.data)))
      .catch(error => dispatch(getMatchStatisticFailure(error)));
  };
};
