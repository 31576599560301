import * as actions from './skills.constants';

import { SkillsDto } from 'rest/skill/skills.dto';

import type { JobOfferSkillsState } from './skills.type';

const initialState: JobOfferSkillsState = {
  content: new SkillsDto(),
  fetching: false,
  updateJobOfferSuccess: false,
  publishJobOfferSuccess: false
};

export default (state = initialState, action): JobOfferSkillsState => {
  switch (action.type) {

    case actions.GET_JOB_OFFER_SKILLS_REQUEST:
    case actions.UPDATE_JOB_OFFER_SKILLS_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case actions.GET_JOB_OFFER_SKILLS_SUCCESS:
      return {
        ...state,
        fetching: false,
        content: action.skills
      };

    case actions.GET_JOB_OFFER_SKILLS_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.UPDATE_JOB_OFFER_SKILLS_SUCCESS:
      return {
        ...state,
        content: action.skills,
        updateJobOfferSuccess: true
      };

    case actions.RESET_JOB_OFFER_SKILLS:
      return {
        ...state,
        content: null
      };

    default:
      return state;
  }
};
