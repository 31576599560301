// @flow
import { Component } from 'react';

import { TransProps } from 'react-i18next';

import { CalendarResponseDto } from 'rest/companies/candidate-invites/candidate-invites-response.dto';

import './invite-row.scss';

export type Props = {
  t: TransProps,
  onClick: () => void,
  onDelete: () => void,
  invite: CalendarResponseDto
};

export class Setting extends Component<Props> {

  static defaultProps: Props = {
    onClick: () => {},
    onDelete: () => {},
    invite: new CalendarResponseDto()
  };

}
