import * as actions from './units.constants';

import type { UnitState } from './units.type';

const initialState: UnitState = {
  unitsList: [],
  fetching: false
};

export default (state = initialState, action): UnitState => {
  switch (action.type) {

    case actions.GET_UNITS_REQUEST:
      return {
        fetching: true
      };

    case actions.GET_UNITS_FAILURE:
      return {
        fetching: false
      };

    case actions.GET_UNITS_SUCCESS:
      return {
        ...state,
        fetching: false,
        unitsList: action.units
      };
    default:
      return state;
  }
};
