import * as actions from './favorite.constants';

import { FavoriteParams } from 'rest/candidates/current/favorite/favorite.params';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';
import { LocationsDto } from 'rest/companies/job-offers-matches/matched-candidates/locations/locations.dto';

import type { FavoriteJobsType } from './favorite.type';

const initialState: FavoriteJobsType = {
  content: [],
  locations: [],
  fetching: false,
  currentPage: 0,
  hasMoreItems: true,
  totalElements: 0,
  filters: new FavoriteParams()
};

export default function (state = initialState, action) {
  let content = [];
  switch (action.type) {
    case actions.GET_FAVORITE_JOBS_REQUEST:
      return {
        ...state,
        fetching: true,
        hasMoreItems: false
      };

    case actions.GET_FAVORITE_JOBS_SUCCESS:
      content = action.payload.content.map(jobOffer => new JobOffersResponseDto(jobOffer));
      return {
        ...state,
        fetching: false,
        content: action.payload.number ? [ ...state.content, ...content ] : content,
        currentPage: action.payload.number + 1,
        hasMoreItems: action.payload.content.length > 0,
        totalElements: action.payload.totalElements
      };

    case actions.ADD_JOBS_TO_FAVORITE_SUCCESS:
      return {
        ...state,
        fetching: false
      };

    case actions.DELETE_JOBS_FROM_FAVORITE_SUCCESS:
      return {
        ...state,
        content: state.content.filter((item: JobOffersResponseDto) => item.id !== action.payload),
        fetching: false,
        totalElements: state.totalElements - 1
      };

    case actions.GET_FAVORITE_JOBS_FAILURE:
    case actions.ADD_JOBS_TO_FAVORITE_FAILURE:
    case actions.DELETE_JOBS_FROM_FAVORITE_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.RESET_FAVORITES_SUCCESS:
      return {
        ...initialState,
        locations: state.locations
      };

    case actions.CHANGE_FAVORITES_FILTERS_SUCCESS:
      return {
        ...initialState,
        locations: state.locations,
        filters: new FavoriteParams(action.params)
      };

    case actions.GET_FAVORITE_JOB_OFFERS_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.locations.map(location => new LocationsDto(location))
      };

    default:
      return state;
  }
}
