export const GET_CURRENT_RECRUITER_REQUEST = 'GET_CURRENT_RECRUITER_REQUEST';
export const GET_CURRENT_RECRUITER_SUCCESS = 'GET_CURRENT_RECRUITER_SUCCESS';
export const GET_CURRENT_RECRUITER_FAILURE = 'GET_CURRENT_RECRUITER_FAILURE';

export const ACTIVATE_RECRUITER_REQUEST = 'ACTIVATE_RECRUITER_REQUEST';
export const ACTIVATE_RECRUITER_SUCCESS = 'ACTIVATE_RECRUITER_SUCCESS';
export const ACTIVATE_RECRUITER_FAILURE = 'ACTIVATE_RECRUITER_FAILURE';

export const UPDATE_RECRUITER_REQUEST = 'UPDATE_RECRUITER_REQUEST';
export const UPDATE_RECRUITER_SUCCESS = 'UPDATE_RECRUITER_SUCCESS';
export const UPDATE_RECRUITER_FAILURE = 'UPDATE_RECRUITER_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const CLEAR_CURRENT_RECRUITER_SUCCESS = 'CLEAR_CURRENT_RECRUITER_SUCCESS';
export const CHANGE_CURRENT_RECRUITER_LOCALE = 'CHANGE_CURRENT_RECRUITER_LOCALE';
export const SET_CANDIDATE_PREVIEW_LINK_SUCCESS = 'SET_CANDIDATE_PREVIEW_LINK_SUCCESS';

export const REMOVE_CANDIDATE_PREVIEW_LINK = 'REMOVE_CANDIDATE_PREVIEW_LINK';

export const DELETE_RECRUITER_REQUEST = 'DELETE_RECRUITER_REQUEST';
export const DELETE_RECRUITER_SUCCESS = 'DELETE_RECRUITER_SUCCESS';
export const DELETE_RECRUITER_FAILURE = 'DELETE_RECRUITER_FAILURE';

export const DISABLE_ON_BOARDING_VIDEO_REQUEST = 'DISABLE_ON_BOARDING_VIDEO_REQUEST';
export const DISABLE_ON_BOARDING_VIDEO_SUCCESS = 'DISABLE_ON_BOARDING_VIDEO_SUCCESS';
export const DISABLE_ON_BOARDING_VIDEO_FAILURE = 'DISABLE_ON_BOARDING_VIDEO_FAILURE';
