import React, { Component } from 'react';

import { Modal } from 'components/dialogs';

import { CreateApplicationModal } from 'containers/portal/candidate/applications';

export class CreateApplicationPage extends Component {

  render () {
    return (
      <Modal closePath="/portal/candidate/applications/templates">
        <CreateApplicationModal />
      </Modal>
    );
  }

}
