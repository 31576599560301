import axios from 'axios';

import { UsersAlertsResponseDto } from './alerts.response.dto';
import { AlertsDto } from 'rest/alerts/alerts.dto';

export async function getUserNotificationsCount (filters?: AlertsDto): Promise {
  try {
    return await axios.get(`/alerts/count`, {
      params: filters
    });
  } catch (error) {
    throw error;
  }
}

export async function getAllNotifications (filters?: AlertsDto): Promise<UsersAlertsResponseDto> {
  try {
    return await axios.get(`/alerts`, {
      params: filters
    });
  } catch (error) {
    throw error;
  }
}

export async function readNotification (alertId: string): Promise {
  try {
    return await axios.post(`/alerts/${alertId}/read`);
  } catch (error) {
    throw error;
  }
}
