import React from 'react';
import { Link } from 'react-router-dom';

import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';

import { connectStore } from 'middleware/connect';

import { Chips } from 'components/layouts';
import { Button } from 'components/buttons';

import { Settings, dispatchToProps, stateToProps } from './profile-header.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class ProfileHeader extends Settings {

  componentDidMount () {
    this.props.getCandidateById(this.props.match.params.candidateId);
  }

  changeInviteStatus (status: string) {
    this.props.changeInviteStatus(this.props.currentUser.companyId, this.props.match.params.inviteId, status);
    this.props.resetAllCalendarInvites();
    this.props.getAllCalendarInvites(this.props.currentUser.companyId, '');
    this.props.candidate.candidateInviteStatus = status;
    this.props.changeCurrentCandidate(this.props.candidate);
    toast.success(`${this.props.t('portal.recruiter.notifications.invite-updated')}: ${status.toLowerCase()}`);
  }

  render () {
    return (
      <div className="profile-header-container">
        <div className="information">
          <div className="navigation">
            <Link to="/portal/recruiter/calendar">
              <Trans i18nKey="portal.recruiter.calendar.preview.back">
                Back to Calendar
              </Trans>
            </Link>
          </div>
          <div className="status">
            {
              this.props.candidate.candidateInviteStatus
                ? <Chips color={this.props.candidate.candidateInviteStatus.toLowerCase()}>
                  {
                    this.props.candidate.candidateInviteStatus === 'INVITED' &&
                    <Trans i18nKey="portal.recruiter.calendar.status.invited">
                      Invited
                    </Trans>
                  }
                  {
                    this.props.candidate.candidateInviteStatus === 'INTERVIEW' &&
                    <Trans i18nKey="portal.recruiter.calendar.status.accepted">
                      Accepted
                    </Trans>
                  }
                  {
                    this.props.candidate.candidateInviteStatus === 'INTERVIEWED' &&
                    <Trans i18nKey="portal.recruiter.calendar.status.interviewed">
                      Interviewed
                    </Trans>
                  }
                  {
                    this.props.candidate.candidateInviteStatus === 'EMPLOYED' &&
                    <Trans i18nKey="portal.recruiter.calendar.status.employed">
                      Employed
                    </Trans>
                  }
                </Chips>
                : null
            }
          </div>
        </div>
        <div className="actions">
          <Button
            type="button"
            recruiter
            outlined
            disabled={this.props.candidate.candidateInviteStatus === 'INTERVIEWED'}
            onClick={() => this.changeInviteStatus('INTERVIEWED')}
            className="action">
            <Trans i18nKey="portal.recruiter.calendar.preview.buttons.interviewed">
              Mark as Interviewed
            </Trans>
          </Button>
          <Button
            type="button"
            recruiter
            outlined
            disabled={this.props.candidate.candidateInviteStatus === 'EMPLOYED'}
            onClick={() => this.changeInviteStatus('EMPLOYED')}
            className="action">
            <Trans i18nKey="portal.recruiter.calendar.preview.buttons.hired">
              Mark as Hired
            </Trans>
          </Button>
        </div>
      </div>
    );
  }

}
