import { Component } from 'react';

import './container.scss';
import './container.responsive.scss';

type Props = {
  children?: Node,
  className?: string
};

export class Settings extends Component<Props> {

  static defaultProps = {
    className: ''
  };

}
