// @flow
import React from 'react';

import { connectStore } from 'middleware/connect';

import { Dropdown } from 'components/controls';
import { PortletControl } from 'components/portlet';

import { JobOffersMatchesParams } from 'rest/candidates/job-offers-matches/job-offers-matches.params';

import type { Props } from './filter.setting';
import { Settings, stateToProps, dispatchToProps } from './filter.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class MatchFilters extends Settings {

  componentDidMount () {
    this.props.getBranches();
    if (this.props.candidateId) {
      this.props.getLocations(this.props.candidateId);
    }
  }

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.candidateId !== this.props.candidateId) {
      this.props.getLocations(nextProps.candidateId);
    }
  }

  getFilteredFollowingJobs () {
    const params = new JobOffersMatchesParams();
    params.branchId = this.state.branchId;
    params.placeId = this.state.cityPlaceId;
    this.props.changeMatchedFilter(params);
  }

  render () {
    return (
      <PortletControl>
        <Dropdown
          title="Location"
          allowUnSelect
          defaultValue="All"
          change={value =>
            this.setState({ cityPlaceId: value ? value.id : '' }, () => this.getFilteredFollowingJobs())}
          value={this.state.cityPlaceId}
          items={this.props.locations.map(item => ({ id: item.cityPlaceId, name: item.name }))}
        />
        <Dropdown
          title="Branch"
          allowUnSelect
          defaultValue="All"
          change={value =>
            this.setState({ branchId: value ? value.id : '' }, () => this.getFilteredFollowingJobs())}
          value={this.state.branchId}
          items={this.props.branches}/>
      </PortletControl>
    );
  }

}
