import { UserAddressDto } from 'rest/address-dto/address';
import { SalaryRangeDto } from 'rest/salary-range/salary-range.dto';

export class JobOffersResponseDto {

  id: string;
  isNew: boolean;
  unitId: string;
  jobType: string;
  unitName: string;
  matched: boolean;
  branchId: string;
  companyId: string;
  jobOfferId: string;
  published: boolean;
  jobTitleId: string;
  branchName: string;
  updatedDate: string;
  companyName: string;
  recruiterId: string;
  jobTitleName: string;
  matchedPoints: number;
  publishedDate: string;
  availableFrom: string;
  jobDescription: string;
  jobOfferStatus: string;
  referenceNumber: string;
  candidateApplied: boolean;
  companyAddress: UserAddressDto;
  salaryRange: SalaryRangeDto;
  salaryResponse: SalaryRangeDto;

  favorite = false;

  constructor (data?: JobOffersResponseDto) {
    if (data) {
      this.id = data.id;
      this.isNew = data.isNew;
      this.unitId = data.unitId;
      this.jobType = data.jobType;
      this.matched = data.matched;
      this.unitName = data.unitName;
      this.branchId = data.branchId;
      this.favorite = data.favorite;
      this.companyId = data.companyId;
      this.published = data.published;
      this.branchName = data.branchName;
      this.jobOfferId = data.jobOfferId;
      this.jobTitleId = data.jobTitleId;
      this.companyName = data.companyName;
      this.updatedDate = data.updatedDate;
      this.recruiterId = data.recruiterId;
      this.jobTitleName = data.jobTitleName;
      this.publishedDate = data.publishedDate;
      this.availableFrom = data.availableFrom;
      this.matchedPoints = data.matchedPoints;
      this.jobDescription = data.jobDescription;
      this.jobOfferStatus = data.jobOfferStatus;
      this.companyAddress = data.companyAddress;
      this.referenceNumber = data.referenceNumber;
      this.candidateApplied = data.candidateApplied;
      this.workingHoursPerMonth = data.workingHoursPerMonth;
      this.salaryRange = new SalaryRangeDto(data.salaryRange || data.salaryResponse);
    }
  }

}
