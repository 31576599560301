import React from 'react';
import { connect } from 'react-redux';

import { ChatParticipantDto } from 'rest/chats/chat-participant.dto';

import { ChatAvatar } from './../avatar/avatar';

import { dispatchToProps, Settings, stateToProps } from './user.setting';

@connect(stateToProps, dispatchToProps)
export class ChatUser extends Settings {

  componentWillMount () {
    this.props.initConversation(this.props.match.params.id);
  }

  componentWillReceiveProps (props) {
    if (this.props.match.params.id !== props.match.params.id) {
      this.props.initConversation(props.match.params.id);
    }
  }

  getInterlocutor (participants: ChatParticipantDto[]): ChatParticipantDto {
    return participants.find(participant => participant.userTechnicalId !== this.props.userId);
  }

  openParticipantModalPreview (candidateId: string) {
    if (this.props.location && this.props.location.pathname.includes('recruiter')) {
      const profilePath: string =
        `/portal/recruiter/chat/${this.props.match.params.id}/candidate/${candidateId}/profile`;
      this.props.history.push(profilePath);
    }
  }

  render () {
    const isRecruiter: boolean = this.props.location && this.props.location.pathname.includes('recruiter');
    if (this.props.conversation) {
      const interlocutor: ChatParticipantDto = this.getInterlocutor(this.props.conversation.participants);
      return (
        <div
          onClick={() => this.openParticipantModalPreview(interlocutor.candidateId)}
          className={`chat-user mobile-chat-user ${isRecruiter ? 'overlay' : ''}`}>
          <div className="information">
            <div className="name">{interlocutor.fullName}</div>
            <div className="branch">{interlocutor.title}</div>
          </div>
          <ChatAvatar participant={interlocutor}/>
        </div>
      );
    } else {
      return null;
    }
  }

}
