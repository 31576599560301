import React from 'react';

import { AvatarSetting } from './avatar.setting';

export class Avatar extends AvatarSetting {

  render () {
    return (
      <div className={`avatar ${this.props.loading ? 'loading' : ''}`}>
        {
          this.props.imageUrl && <img
            src={this.props.imageUrl }
            alt="avatar"
          />
        }
      </div>
    );
  }

}
