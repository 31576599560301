import * as actions from './reset.constants';

import type { ResetStateType } from './reset.type';

const initialState: ResetStateType = {
  confirmResetPasswordSuccess: false,
  initResetPasswordSuccess: false,
  fetching: false,
  resetEmail: '',
  errorText: ''
};

export default (state = initialState, action): ResetStateType => {
  switch (action.type) {
    case actions.INIT_RESET_PASSWORD_REQUEST:
    case actions.FINISH_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        errorText: '',
        fetching: true
      };

    case actions.INIT_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        errorText: '',
        fetching: false,
        resetEmail: action.email,
        initResetPasswordSuccess: true,
        confirmResetPasswordSuccess: true
      };

    case actions.FINISH_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        errorText: '',
        fetching: false,
        initResetPasswordSuccess: false,
        confirmResetPasswordSuccess: true
      };

    case actions.INIT_RESET_PASSWORD_FAILURE:
    case actions.FINISH_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        fetching: false,
        errorText: action.error
      };

    default:
      return state;
  }
};
