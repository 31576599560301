// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { PaginationDto } from 'middleware/dto';

import { ApplicationResponseDto } from 'rest/candidates/applications-template/applications-template.response.dto';

import {
  resetApplications,
  getCandidateApplications
} from 'services/portal/candidate/applications/templates/templates.actions';

import './applications-list.scss';

export type Props = {
  status: string,
  history: History,
  fetching: boolean,
  templates: ApplicationResponseDto[],
  currentPage: number,
  candidateId: string,
  hasMoreItems: boolean,
  resetApplications: () => void,
  getCandidateApplications: (candidateId: string, params: PaginationDto) => void
}

export class Setting extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  status: state.portal.candidate.applications.templates.status,
  fetching: state.portal.candidate.applications.templates.fetching,
  templates: state.portal.candidate.applications.templates.content,
  currentPage: state.portal.candidate.applications.templates.currentPage,
  candidateId: state.portal.candidate.account.current.candidateData.id,
  hasMoreItems: state.portal.candidate.applications.templates.hasMoreItems
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetApplications,
  getCandidateApplications
}, dispatch);
