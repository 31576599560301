import { ChatMessageAttachmentDto } from './message-attachment.dto';

export class MessagesResponseDto {

  id: string;
  date: string;
  read: boolean;
  userId: string;
  message: string;
  attachment: ChatMessageAttachmentDto;

  constructor (data?: MessagesResponseDto) {
    if (data) {
      this.id = data.id;
      this.date = data.date;
      this.read = data.read;
      this.userId = data.userId;
      this.message = data.message;
      this.attachment = new ChatMessageAttachmentDto(data.attachment);
    }
  }

}
