export class LifeStyleItemDto {

  points: number;
  activity: string;

  constructor (data?: LifeStyleItemDto) {
    if (data) {
      this.points = data.points;
      this.activity = data.activity;
    }
  }

}
