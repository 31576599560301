// @flow
import { Component } from 'react';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { CandidateSkillsDto } from 'rest/candidates/skills/skills.dto';

type Props = {
  t: TransProps,
  skills: CandidateSkillsDto
};

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  skills: state.portal.candidate.skills.content
});
