import React, { Component } from 'react';

import { SuccessResetContainer } from 'containers/auth/reset';
import { AuthBody } from 'containers/auth/layouts';
import { AuthImage } from 'containers/auth';

export class SuccessResetPasswordPage extends Component {

  render () {
    return (
      <div className="success-reset-password-page">
        <AuthImage imageSrc="/images/auth/reset-success-message-box.svg"/>
        <AuthBody title="Success!">
          <SuccessResetContainer redirectUrl="/auth/candidate"/>
        </AuthBody>
      </div>
    );
  }

}
