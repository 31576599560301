// @flow
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { TransProps } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { PortletContainer, PortletHeader } from 'components/portlet';

import { ChatUser, ChatChannel, ChatPlaceholder } from 'containers/portal/chat';

import { ProfilePage } from './profile';

type Props = {
  t: TransProps
}

@connectTranslation()
export class ChatPage extends Component<Props> {

  render () {
    return (
      <PortletContainer>
        <PortletHeader title={this.props.t('portal.recruiter.chat.list.title')}>
          <Route
            path="/portal/recruiter/chat/:id"
            component={ChatUser}/>
          <Route
            path="/portal/recruiter/chat/:id/candidate/:candidateId/profile"
            component={ProfilePage}/>
        </PortletHeader>
        <Route
          path="/portal/recruiter/chat"
          exact
          component={ChatPlaceholder}/>
        <Route
          path="/portal/recruiter/chat/:id"
          component={ChatChannel}/>
      </PortletContainer>
    );
  }

}
