import * as actions from './stats.constants';

import { StatsDto } from 'rest/stats/stats.dto';

import type { CompanyJobOfferStatsState } from './stats.type';

const initialState: CompanyJobOfferStatsState = {
  stats: [],
  fetching: [],
  totalElements: 0
};

export default (state = initialState, action): CompanyJobOfferStatsState => {
  switch (action.type) {

    case actions.GET_COMPANY_JOB_OFFERS_STATS_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case actions.GET_COMPANY_JOB_OFFERS_STATS_SUCCESS:
      return {
        ...state,
        stats: action.stats.map(stat => new StatsDto(stat)),
        fetching: false,
        totalElements: action.total
      };

    case actions.GET_COMPANY_JOB_OFFERS_STATS_FAILURE:
      return {
        ...state,
        stats: [],
        fetching: false,
        totalElements: action.total
      };

    case actions.RESET_COMPANY_JOB_OFFERS_STATS_SUCCESS:
      return {
        ...state,
        stats: [],
        fetching: false,
        totalElements: 0
      };

    default:
      return state;
  }
};
