// @flow
import React from 'react';
import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';

import { ShowSalaryRange } from 'components/elements';

import { ImageResponseDto } from 'rest/images/images.response';
import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';

import { Settings, stateToProps } from './personal-info-section.setting';

@connectStore(stateToProps, null, true)
export class PersonalInfoSection extends Settings {

  getCandidateAvatarUrl (): string {
    const candidate: CurrentCandidateDto = this.props.currentCandidate;
    const photos = candidate.candidatePhotos;
    const avatars: ImageResponseDto[] = photos && photos.length > 0 ? photos : [];
    const primaryAvatar: ?ImageResponseDto = avatars.find((item: ImageResponseDto) => item.primary);
    return primaryAvatar ? primaryAvatar.url : '/images/shared/userpick.jpg';
  }

  render () {
    const candidate: CurrentCandidateDto = this.props.currentCandidate;

    return (
      <div className="personal-info-section-container mobile-personal-info-section-container">
        <div className="row-section">
          <div className="avatar-info">
            <div className="avatar-wrapper">
              <img
                src={this.getCandidateAvatarUrl()}
                alt="avatar"/>
            </div>
            <div className="person-info">
              <h3 className="person-name">{`${candidate.firstName} ${candidate.lastName}`}</h3>
              {
                <p className="job-title-name">
                  {
                    this.props.jobTitle ||
                    this.props.t('portal.candidate.cv.personal.job-title.placeholder')
                  }
                </p>
              }
              {
                candidate.candidateAddress && <p className="personal-address">
                  {`${candidate.candidateAddress.city}, ${candidate.candidateAddress.country}`}
                </p>
              }
            </div>
          </div>
          <div className="personal-additional-info">
            <div>
              <p className="personal-address">
                <Trans i18nKey="portal.candidate.cv.list.personal.email">
                  Email Address
                </Trans>
              </p>
              <h5 className="info-value">{candidate.email}</h5>
              <p className="personal-address">
                <Trans i18nKey="portal.candidate.cv.list.personal.phone">
                  Phone Number
                </Trans>
              </p>
              <h5 className="info-value">
                {candidate.phoneNumber || this.props.t('portal.candidate.cv.list.personal.no-information')}
              </h5>
            </div>
            <div>
              <p className="personal-address">
                <Trans i18nKey="portal.candidate.cv.list.personal.salary">
                  Salary Expectation
                </Trans>
              </p>
              {
                this.props.balanceBoard ? <h5 className="info-value">
                  <ShowSalaryRange salaryRange={this.props.balanceBoard.salary}/>
                </h5> : <h5 className="info-value">
                  <Trans i18nKey="portal.candidate.cv.list.personal.no-information">
                    No information
                  </Trans>
                </h5>
              }
              <p className="personal-address">
                <Trans i18nKey="portal.candidate.cv.list.personal.relocation.title">
                  Relocation
                </Trans>
              </p>
              <h5 className="info-value">
                {
                  this.props.balanceBoard &&
                  this.props.balanceBoard.relocate
                    ? this.props.t('portal.candidate.cv.list.personal.relocation.able')
                    : this.props.t('portal.candidate.cv.list.personal.relocation.not-able')
                }
              </h5>
            </div>
          </div>
        </div>
      </div>
    );
  }

}
