export const GET_WORK_EXPERIENCE_REQUEST = 'GET_WORK_EXPERIENCE_REQUEST';
export const GET_WORK_EXPERIENCE_SUCCESS = 'GET_WORK_EXPERIENCE_SUCCESS';
export const GET_WORK_EXPERIENCE_FAILURE = 'GET_WORK_EXPERIENCE_FAILURE';

export const CREATE_WORK_EXPERIENCE_REQUEST = 'CREATE_WORK_EXPERIENCE_REQUEST';
export const CREATE_WORK_EXPERIENCE_SUCCESS = 'CREATE_WORK_EXPERIENCE_SUCCESS';
export const CREATE_WORK_EXPERIENCE_FAILURE = 'CREATE_WORK_EXPERIENCE_FAILURE';

export const DELETE_WORK_EXPERIENCE_REQUEST = 'DELETE_WORK_EXPERIENCE_REQUEST';
export const DELETE_WORK_EXPERIENCE_SUCCESS = 'DELETE_WORK_EXPERIENCE_SUCCESS';
export const DELETE_WORK_EXPERIENCE_FAILURE = 'DELETE_WORK_EXPERIENCE_FAILURE';

export const UPDATE_WORK_EXPERIENCE_REQUEST = 'UPDATE_WORK_EXPERIENCE_REQUEST';
export const UPDATE_WORK_EXPERIENCE_SUCCESS = 'UPDATE_WORK_EXPERIENCE_SUCCESS';
export const UPDATE_WORK_EXPERIENCE_FAILURE = 'UPDATE_WORK_EXPERIENCE_FAILURE';

export const PUSH_NEW_EXPERIENCE = 'PUSH_NEW_EXPERIENCE';
export const REMOVE_PUSH_NEW_EXPERIENCE = 'REMOVE_PUSH_NEW_EXPERIENCE';
export const RESET_WORK_EXPERIENCE_SUCCESS = 'RESET_WORK_EXPERIENCE_SUCCESS';
export const CHANGE_WORK_EXPERIENCE_SUCCESS = 'CHANGE_WORK_EXPERIENCE_SUCCESS';
