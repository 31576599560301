import { Action, Dispatch } from 'redux';

import { AxiosResponse } from 'axios';

import { List } from 'rest/list/list.dto';

import { JobOffersMatchesDto } from 'rest/candidates/job-offers-matches/job-offers-matches.dto';
import { JobOffersMatchesParams } from 'rest/candidates/job-offers-matches/job-offers-matches.params';
import { LocationsDto } from 'rest/companies/job-offers-matches/matched-candidates/locations/locations.dto';

import {
  getMatchedJobOffers,
  getMatchedJobsLocations
} from 'rest/candidates/job-offers-matches/job-offers-matches.rest';

import * as actions from './new-job-offers.constants';

const getNewMatchedJobOffersRequest = (): Action => ({
  type: actions.GET_NEW_JOB_OFFERS_MATCHES_REQUEST
});

const getNewMatchedJobOffersSuccess = (data): Action => ({
  type: actions.GET_NEW_JOB_OFFERS_MATCHES_SUCCESS,
  payload: data
});

const getNewMatchedJobOffersFailure = (): Action => ({
  type: actions.GET_NEW_JOB_OFFERS_MATCHES_FAILURE
});

const getMatchedJobsLocationsRequest = (): Action => ({
  type: actions.GET_JOB_OFFERS_MATCHES_LOCATIONS_REQUEST
});

const getMatchedJobsLocationsSuccess = (locations: LocationsDto = []): Action => ({
  type: actions.GET_JOB_OFFERS_MATCHES_LOCATIONS_SUCCESS,
  locations
});

const getMatchedJobsLocationsFailure = (): Action => ({
  type: actions.GET_JOB_OFFERS_MATCHES_LOCATIONS_FAILURE
});

const getNewMatchedJobOffersCountRequest = (): Action => ({
  type: actions.GET_NEW_JOB_OFFERS_MATCHES_COUNT_REQUEST
});

const getNewMatchedJobOffersCountSuccess = (data): Action => ({
  type: actions.GET_NEW_JOB_OFFERS_MATCHES_COUNT_SUCCESS,
  payload: data
});

const getNewMatchedJobOffersCountFailure = (): Action => ({
  type: actions.GET_NEW_JOB_OFFERS_MATCHES_COUNT_FAILURE
});

const resetNewMatchedJobOffersSuccess = () => ({
  type: actions.RESET_NEW_JOB_OFFERS_MATCHES_SUCCESS
});

const resetNewMatchedJobOffersBubbleSuccess = () => ({
  type: actions.RESET_NEW_JOB_OFFERS_BUBBLE_SUCCESS
});

export const resetNewMatchedJobOffers = () => {
  return dispatch => {
    dispatch(resetNewMatchedJobOffersSuccess());
  };
};

export const resetNewMatchedJobOffersBubble = () => {
  return dispatch => {
    dispatch(resetNewMatchedJobOffersBubbleSuccess());
  };
};

export function getNewMatchedJobOffersCount (companyId: string, params = new JobOffersMatchesParams()) {
  return (dispatch: Dispatch) => {
    dispatch(getNewMatchedJobOffersCountRequest());
    params.new = true;
    return getMatchedJobOffers(companyId, params)
      .then((response: AxiosResponse<List<JobOffersMatchesDto>>) =>
        dispatch(getNewMatchedJobOffersCountSuccess(response.data)))
      .catch(() => dispatch(getNewMatchedJobOffersCountFailure()));
  };
}

export function getNewMatchedJobOffersList (companyId: string, params = new JobOffersMatchesParams()) {
  return (dispatch: Dispatch) => {
    dispatch(getNewMatchedJobOffersRequest());
    params.new = true;
    return getMatchedJobOffers(companyId, params)
      .then((response: AxiosResponse<List<JobOffersMatchesDto>>) =>
        dispatch(getNewMatchedJobOffersSuccess(response.data)))
      .catch(() => dispatch(getNewMatchedJobOffersFailure()));
  };
}

export function getMatchedJobOffersLocations (companyId: string) {
  return (dispatch: Dispatch) => {
    dispatch(getMatchedJobsLocationsRequest());
    return getMatchedJobsLocations(companyId)
      .then(response => dispatch(getMatchedJobsLocationsSuccess(response.data.locations)))
      .catch(() => dispatch(getMatchedJobsLocationsFailure()));
  };
}
