// @flow
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';
import { uploadFile } from 'middleware/upload-file';

import { Button } from 'components/buttons';
import { Spinner } from 'components/elements';
import { Avatar } from 'components/avatar/avatar';
import { FormGroup, InputControl, PhoneControl } from 'components/controls';

import { RecruiterDto } from 'rest/recruiter/recruiter.dto';

import type { Props, State } from './personal-info.setting';
import { Settings, stateToProps, dispatchToProps } from './personal-info.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class PersonalInfo extends Settings {

  componentWillReceiveProps (nextProps: Props, prevState: State) {
    if (nextProps.errorText !== prevState.errorText) {
      this.setState({ errorText: nextProps.errorText });
    }
    if (nextProps.redirect !== this.state.redirect && !this.state.errorText) {
      this.props.pushState('/portal/new-job-offer/sign-up/confirm');
    }
  }

  changeAvatarState (file: File) {
    uploadFile(file, (base64, error) => {
      if (error) {
        return this.setState({ avatarError: error });
      }
      this.setState({
        avatarError: false,
        avatarPreview: base64
      }, () => this.props.uploadFile(file, 'avatar'));
    });
  }

  changeRecruiterState (field: string, value: string, isError: boolean = false) {
    this.setState({
      [field]: value,
      errorText: ''
    });
    this.errorCollector(field, isError);
  }

  errorCollector (errorName: string, collect: boolean) {
    const errorCollection: Set<string> = new Set(this.state.errorCollection);
    collect ? errorCollection.add(errorName) : errorCollection.delete(errorName);
    this.setState({ errorCollection });
  }

  clearImageState () {
    this.setState({ avatarPreview: '' });
  }

  registerRecruiter (event: Event) {
    event.preventDefault();
    if (!this.state.errorCollection.size && !this.props.imageFetching) {
      const recruiterData = new RecruiterDto(this.state);
      recruiterData.recruiterPhoto = this.props.image;
      this.props.recruiterSignUp(recruiterData, true);
    }
  }

  render () {
    const emailError: boolean = this.state.errorText !== '' || this.state.errorCollection.has('email');
    return (
      <Fragment>
        <div className="invite-recruiter-container">
          <Avatar
            error={!!this.state.avatarError}
            errorText={this.state.avatarError}
            loading={this.props.imageFetching}
            previewImageUrl={this.state.avatarPreview}
            change={this.changeAvatarState.bind((this))}
            accept="image/png, image/jpeg"
            uploadButtonType="accent outlined"
            clearImageState={this.clearImageState.bind(this)}
            uploadButtonName={this.props.t('portal.guest.recruiter.personal-info.avatar.text')}
          />
          <form
            onSubmit={this.registerRecruiter.bind(this)}
            className="personal-info-form">
            <FormGroup className="half-width">
              <InputControl
                maxLength={35}
                value={this.state.firstName}
                label={this.props.t('portal.guest.recruiter.personal-info.first-name.label')}
                placeholder={this.props.t('portal.guest.recruiter.personal-info.first-name.placeholder')}
                change={(value: string, error: boolean): void => this.changeRecruiterState('firstName', value, error)}
                required
                type="text"/>
            </FormGroup>
            <FormGroup className="half-width">
              <InputControl
                maxLength={35}
                value={this.state.lastName}
                change={(value: string, error: boolean): void => this.changeRecruiterState('lastName', value, error)}
                label={this.props.t('portal.guest.recruiter.personal-info.last-name.label')}
                placeholder={this.props.t('portal.guest.recruiter.personal-info.last-name.placeholder')}
                required
                type="text"/>
            </FormGroup>
            <FormGroup className="half-width">
              <InputControl
                value={this.state.email}
                required
                error={emailError}
                errorText={this.state.errorText || this.props.t('portal.guest.recruiter.personal-info.email.error')}
                change={(value: string, error: boolean): void => this.changeRecruiterState('email', value, error)}
                label={this.props.t('portal.guest.recruiter.personal-info.email.label')}
                placeholder={this.props.t('portal.guest.recruiter.personal-info.email.placeholder')}
                type="email"/>
            </FormGroup>
            <FormGroup className="half-width">
              <PhoneControl
                required
                label={this.props.t('portal.guest.recruiter.personal-info.phone.label')}
                defaultValue={this.state.phoneNumber}
                change={(phoneNumber: string) => this.setState({ phoneNumber })}/>
            </FormGroup>
            <FormGroup className="half-width">
              <InputControl
                required
                minLength={6}
                maxLength={32}
                icon
                value={this.state.password}
                change={(value: string, error: boolean): void => this.changeRecruiterState('password', value, error)}
                label={this.props.t('portal.guest.recruiter.personal-info.password.label')}
                errorText={this.props.t('portal.guest.recruiter.personal-info.password.error')}
                placeholder={this.props.t('portal.guest.recruiter.personal-info.password.label')}
                type="password"/>
            </FormGroup>
            <FormGroup className="half-width recruiter-invite-field">
              <InputControl
                required
                minLength={6}
                maxLength={32}
                icon
                pattern={this.state.password}
                value={this.state.confirmPassword}
                change={(value: string, error: boolean): void =>
                  this.changeRecruiterState('confirmPassword', value, error)}
                label={this.props.t('portal.guest.recruiter.personal-info.repeat-password.label')}
                errorText={this.props.t('portal.guest.recruiter.personal-info.repeat-password.error')}
                placeholder={this.props.t('portal.guest.recruiter.personal-info.repeat-password.label')}
                type="password"/>
            </FormGroup>
            <FormGroup className="form-btn-submit">
              <Button
                onClick={() => this.props.prev()}
                grey>
                <Trans i18nKey="portal.guest.recruiter.personal-info.buttons.back">
                  Back
                </Trans>
              </Button>
              <Button
                medium
                disabled={this.props.imageFetching}
                type="submit"
                recruiter>
                <Trans i18nKey="portal.guest.recruiter.personal-info.buttons.next">
                  Next
                </Trans>
              </Button>
            </FormGroup>
            <FormGroup className="full-width">
              <div className="agreement">
                <Trans i18nKey="auth.sign-up.employer.agreement">
                  By continue you agree to ExcellentJobs
                  <Link to="/information/privacy-policy"> Privacy Policy </Link> and
                  <Link to="/information/privacy-policy"> Terms of Use </Link>
                </Trans>
              </div>
            </FormGroup>
          </form>
          <Spinner show={this.props.fetching || this.props.jobOfferFetching}/>
        </div>
      </Fragment>
    );
  }

}
