// @flow
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';
import type { RatingType } from './company-info.rating.type';
import type { SelectType } from 'components/controls/select/select';

import { BrandsDto } from 'rest/brand/brand.dto';
import { BranchDto } from 'rest/branch/branch.dto';
import { CompanyDto } from 'rest/companies/companies.dto';
import { ImageResponseDto } from 'rest/images/images.response';
import { RecruiterResponseDto } from 'rest/recruiter/recruiter.response.dto';

import {
  getAllBranches,
  getAllBrands,
  updateRecruiterCompany,
  getRecruiterCompanyById,
  getCurrentCompanyRecruiter
} from 'services/portal/recruiter/account/account.actions';
import { uploadFile } from 'services/portal/images/images.actions';
import { searchSuggestedAddress } from 'services/portal/address/address.actions';

import './company-info.scss';

export type Props = {
  t?: TransProps,
  prev: () => void,
  error: string,
  updated: boolean,
  fetching: boolean,
  company: CompanyDto,
  getAllBrands: () => void,
  imageFetching: boolean,
  showBackButton: boolean,
  brands: BrandsDto[],
  getAllBranches: () => void,
  actionButtonTitle: string,
  branches: BranchDto[],
  imageInfo: ImageResponseDto,
  avatarInfo: ImageResponseDto,
  getCurrentRecruiter: () => void,
  uploadFile: (file: File, type: string) => void,
  currentUser: RecruiterResponseDto,
  getRecruiterCompanyById: (id: number) => void,
  updateRecruiterCompany: (id: number, company: CompanyDto) => void,
};

type State = {
  logo: ?File,
  city: SelectType,
  name: string,
  stars: RatingType,
  street: SelectType,
  country: SelectType,
  zipCode: string,
  brandId: string,
  imageSizeError: ?string,
  branchId: BranchDto,
  description: string,
  logoPreview: ?string,
  streetNumber: string,
  branchError: boolean,
  previewPhotos: ImageResponseDto[],
  logoPreviewError: boolean,
  photos: ImageResponseDto[]
}

export class Settings extends Component<Props, State> {

  static defaultProps = {
    prev: () => {},
    showBackButton: true,
    brands: new BrandsDto(),
    company: new CompanyDto(),
    branches: new BranchDto(),
    actionButtonTitle: 'Next',
    currentUser: new RecruiterResponseDto()
  };

  state: State = {
    name: '',
    city: '',
    stars: '',
    logo: null,
    street: '',
    brandId: '',
    zipCode: '',
    country: '',
    photos: [],
    branchId: '',
    description: '',
    imageSizeError: '',
    logoPreview: null,
    streetNumber: '',
    previewPhotos: [],
    logoPreviewError: false,
    branchError: false
  };

  maxPhotosCount: number = 3;
  inputUpload = React.createRef();

}

export const stateToProps = (state: GlobalState) => ({
  address: state.portal.address.content,
  imageInfo: state.portal.images.imageInfo,
  avatarInfo: state.portal.images.avatarInfo,
  imageFetching: state.portal.images.avatarLoading,
  updated: state.portal.recruiter.account.companies.updateCompanyInfoSuccess,
  error: state.portal.recruiter.account.companies.error,
  brands: state.portal.recruiter.account.companies.brands,
  company: state.portal.recruiter.account.companies.company,
  fetching: state.portal.recruiter.account.companies.fetching,
  branches: state.portal.recruiter.account.companies.branches,
  currentUser: state.portal.recruiter.account.current.currentUser
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getAllBranches,
  getAllBrands,
  updateRecruiterCompany,
  searchSuggestedAddress,
  getCurrentRecruiter: getCurrentCompanyRecruiter,
  getRecruiterCompanyById,
  uploadFile
}, dispatch);
