// @flow
import React, { Component, Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { TransProps } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { FollowersCandidate, FollowingCandidate } from 'containers/portal/recruiter/follow';

import { PortletBody, PortletContainer, PortletHeader } from 'components/portlet';

import { ProfilePage } from './profile';

type Props = {
  t: TransProps
}

@connectTranslation()
export class FollowsPage extends Component<Props> {

  render () {
    return (
      <Fragment>
        <PortletContainer>
          <PortletHeader title={this.props.t('portal.recruiter.follows.list.following.title')}/>
          <PortletBody>
            <Switch>
              <Redirect
                exact
                from="/portal/recruiter/follow"
                to="/portal/recruiter/follow/following"/>
            </Switch>
            <Route
              path="/portal/recruiter/follow/following"
              component={FollowingCandidate}/>
            <Route
              path="/portal/recruiter/follow/followers"
              component={FollowersCandidate}/>
            <Route
              path="/portal/recruiter/follow/:type/:id/profile"
              component={ProfilePage}/>
          </PortletBody>
        </PortletContainer>
      </Fragment>
    );
  }

}
