// @flow
import axios, { AxiosPromise } from 'axios';

import { LocationsDto } from 'rest/companies/job-offers-matches/matched-candidates/locations/locations.dto';

export async function getFollowersLocations (candidateId: string): AxiosPromise<LocationsDto[]> {
  try {
    return await axios.get(`/candidates/${candidateId}/followers/locations`);
  } catch (error) {
    throw error;
  }
}
