export class ConnectionsParams {

  followingType: string;

  constructor (data?: ConnectionsParams) {
    if (data) {
      this.followingType = data.followingType;
    }
  }

}
