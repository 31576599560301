import React from 'react';

import { findEvent } from 'middleware/notifications/find-event';

import { AddToSystemSkill } from './add-to-system-skill/add-to-system-skill';
import { AddToSystemSoftware } from './add-to-system-software/add-to-system-software';
import { AddToSystemDocument } from './add-to-system-document/add-to-system-document';

import { Setting } from './add-to-system.setting';
import * as eventNames from './add-to-system.constants';

export class AddToSystemTemplate extends Setting {

  hideModal = () => {
    this.props.hideModal();
  };

  render () {
    const { eventData, publishDate } = this.props;
    switch (findEvent(eventData, eventNames.SKILL_TYPE)) {
      case eventNames.PROFESSIONAL_SKILL:
        return <AddToSystemSkill
          eventData={eventData}
          hideModal={this.hideModal}
          publishDate={publishDate}/>;
      case eventNames.SOFTWARE:
        return <AddToSystemSoftware
          eventData={eventData}
          hideModal={this.hideModal}
          publishDate={publishDate}/>;
      case eventNames.DOCUMENTS:
        return <AddToSystemDocument
          eventData={eventData}
          hideModal={this.hideModal}
          publishDate={publishDate}/>;
      default:
        return null;
    }
  }

}
