import { Component } from 'react';
import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { clearJobsFilter } from 'services/portal/candidate/jobs/job-offers/job-offers.actions';
import { clearJobsSearch, clearJobsSearchSkills } from 'services/portal/candidate/jobs/search/search.actions';

import './active-filter.scss';
import './active-filter.responsive.scss';

export class Settings extends Component {}

export const stateToProps = (state: GlobalState) => ({
  jobOffersFilter: state.portal.candidate.jobs.jobOffers.jobOffersFilter
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  clearJobsFilter,
  clearJobsSearch,
  clearJobsSearchSkills
}, dispatch);
