import * as actions from './request.constants';

import { RequestSkillDto } from 'rest/skill/request/request.dto';

import { requestNewSkill } from 'rest/skill/request/request.rest';

const requestNewSkillRequest = () => ({
  type: actions.REQUEST_NEW_SKILL_REQUEST
});

const requestNewSkillSuccess = () => ({
  type: actions.REQUEST_NEW_SKILL_SUCCESS
});

const requestNewSkillFailure = (statusCode: string) => ({
  type: actions.REQUEST_NEW_SKILL_FAILURE,
  error: statusCode
});

const resetNewSkillSuccess = () => ({
  type: actions.RESET_NEW_SKILL_SUCCESS
});

export const resetSuggestedSkill = () => {
  return dispatch => dispatch(resetNewSkillSuccess());
};

export const requestSkill = (data: RequestSkillDto) => {
  return dispatch => {
    dispatch(requestNewSkillRequest());
    return requestNewSkill(data)
      .then(() => dispatch(requestNewSkillSuccess()))
      .catch(error => dispatch(requestNewSkillFailure(error.response.status)));
  };
};
