import * as actions from './team-members.constants';

import { ResponseTeamMembersDto } from 'rest/companies/team-members/team-members.response.dto';

import { getCompanyTeamMembers, deleteCompanyTeamMember } from 'rest/companies/team-members/team-members.rest';
import { inviteTeamMembers } from 'rest/companies/team-members/team-members.rest';

const inviteTeamMemberRequest = () => ({
  type: actions.INVITE_TEAM_MEMBERS_REQUEST
});

const inviteTeamMemberSuccess = () => ({
  type: actions.INVITE_TEAM_MEMBERS_SUCCESS
});

const inviteTeamMemberFailure = (error: string) => ({
  type: actions.INVITE_TEAM_MEMBERS_FAILURE,
  error
});

const getCompanyTeamMembersRequest = () => ({
  type: actions.GET_COMPANY_TEAM_MEMBERS_REQUEST
});

const getCompanyTeamMembersSuccess = (members: ResponseTeamMembersDto) => ({
  type: actions.GET_COMPANY_TEAM_MEMBERS_SUCCESS,
  members
});

const getCompanyTeamMembersFailure = (error: string) => ({
  type: actions.GET_COMPANY_TEAM_MEMBERS_FAILURE,
  error
});

const deleteCompanyTeamMembersRequest = () => ({
  type: actions.DELETE_COMPANY_TEAM_MEMBER_REQUEST
});

const deleteCompanyTeamMembersSuccess = (memberId: string) => ({
  type: actions.DELETE_COMPANY_TEAM_MEMBER_SUCCESS,
  memberId
});

const deleteCompanyTeamMembersFailure = (error: string) => ({
  type: actions.DELETE_COMPANY_TEAM_MEMBER_FAILURE,
  error
});

export const inviteRecruiterTeamMembers = (companyId: string, data) => {
  return dispatch => {
    dispatch(inviteTeamMemberRequest());
    return inviteTeamMembers(companyId, data)
      .then(response => dispatch(inviteTeamMemberSuccess(response)))
      .catch(error => dispatch(inviteTeamMemberFailure(error)));
  };
};

export const getRecruiterTeamMembers = (companyId: string): ResponseTeamMembersDto => {
  return dispatch => {
    dispatch(getCompanyTeamMembersRequest());
    return getCompanyTeamMembers(companyId)
      .then(response => dispatch(getCompanyTeamMembersSuccess(response.data)))
      .catch(error => dispatch(getCompanyTeamMembersFailure(error)));
  };
};

export const deleteRecruiterTeamMember = (companyId: string, memberId: string) => {
  return dispatch => {
    dispatch(deleteCompanyTeamMembersRequest());
    return deleteCompanyTeamMember(companyId, memberId)
      .then(() => dispatch(deleteCompanyTeamMembersSuccess(memberId)))
      .catch(error => dispatch(deleteCompanyTeamMembersFailure(error)));
  };
};
