/* eslint padded-blocks: ["error", "never"] */
import React, { Component } from 'react';
import '../styles/semantic.min.css';
import '../styles/styles.css';
import axios from 'axios';

export class GDPRCandidateFavCompanies extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      length: 0
    };
  }
  componentDidMount() {
    axios('/candidates/current/job-offer-favorites?page=0&extraFields[]=favorite')
      .then(response => {
        this.setState({
          data: response.data.content,
          length: response.data.content.length
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  render() {
    return (
      <div className="gdpr-main">
        <div className="ui container main-content">
          <h1 className="ui header main-title"> Favorite companies </h1>
          <a href="/information/gdpr">
            <button className="ui button back-link">Back To Main</button>
          </a>
          <h5 className="ui header"> Total Elements:  {this.state.length}</h5>
          <table className="ui single green line table">
            <thead>
              <tr>
                <th> Comany Name </th>
              </tr>
              <tr>
                {this.state.data.map(company => (
                  <p key={company.id}>{company.companyName}</p>
                ))}
              </tr>
            </thead>
            <tbody>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
