// @flow
import React from 'react';

import { Trans } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { ElementsList } from 'components/elements-list';
import { ListPlaceholder, Spinner } from 'components/elements';

import { ApplicationsParams } from 'rest/candidates/applications/applications.params';

import { ApplicationRow } from './../applications-list/application-row/application-row';

import type { Props } from './sent-list.setting';
import { Setting, stateToProps, dispatchToProps } from './sent-list.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class SentApplicationsList extends Setting {

  componentWillUnmount () {
    this.props.resetApplications();
  }

  componentDidMount () {
    if (this.props.currentCandidate.id) {
      this.fetchSentApplications();
    }
  }

  componentDidUpdate (prevProps: Props) {
    if (prevProps.currentCandidate.id !== this.props.currentCandidate.id) {
      this.fetchSentApplications();
    }
  }

  fetchSentApplications (page: number = 0) {
    const query: ApplicationsParams = new ApplicationsParams();
    query.size = 10;
    query.page = page;
    query.jobTitleId = this.props.jobTitleId;
    this.props.getSentCandidateApplications(this.props.currentCandidate.id, query);
  }

  openPreviewApplication (templateId: string) {
    const currentUrl: string = this.props.location.pathname;
    if (currentUrl) {
      this.props.history.push(`${currentUrl}/${templateId}/preview`);
    }
  }

  initEmptyList (): HTMLElement {
    return !this.props.sentApplications.length ? (
      <ListPlaceholder
        title={this.props.t('portal.candidate.applications.list.letters.placeholder.title')}>
        <Trans i18nKey="portal.candidate.applications.list.letters.placeholder.content">
          Here you can keep the track of all your job application on Excellent jobs.
        </Trans>
      </ListPlaceholder>
    ) : null;
  }

  render () {
    return (
      <div>
        {
          this.props.currentCandidate.id ? <PerfectScrollbar className="overflow-modal-content">
            <InfiniteScroll
              pageStart={-1}
              hasMore={this.props.hasMoreItems}
              loadMore={() => this.fetchSentApplications(this.props.currentPage)}
              useWindow={false}
              threshold={150}>
              <ElementsList>
                {
                  this.props.sentApplications.map((item, index: number) => {
                    return (
                      <ApplicationRow
                        fullInfo
                        onClick={() => this.openPreviewApplication(item.id)}
                        template={item}
                        key={index}/>
                    );
                  })
                }
              </ElementsList>
              {this.initEmptyList()}
            </InfiniteScroll>
          </PerfectScrollbar> : null
        }
        <Spinner show={this.props.fetching}/>
      </div>
    );
  }

}
