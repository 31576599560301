// @flow
import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';

import cn from 'classnames';
import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';

import { connectStore } from 'middleware/connect';

import { Button } from 'components/buttons';
import { FormGroup } from 'components/controls';
import { JobOfferSkill } from 'components/job-offer-skill/job-offer-skill';

import { MatchModal } from 'containers/portal/recruiter/job-offer';

import { SkillsDto } from 'rest/skill/skills.dto';
import { SkillItemDto } from 'rest/skill/skill-item.dto';

import type { Props } from './priority-match.setting';
import { Settings, stateToProps, dispatchToProps } from './priority-match.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class PriorityMatchTab extends Settings {

  componentDidMount () {
    this.props.getCompanyJobOfferSkills(this.props.jobOfferId || this.props.jobOffer.id);
  }

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.skills !== this.props.skills) {
      this.setState({ ...nextProps.skills }, () => {
        this.filterRequiredSkills();
        this.calculateSkillPriority();
      });
    }
  }

  componentDidUpdate (prevProps: Props) {
    if (this.props.publishJobOfferSuccess !== prevProps.publishJobOfferSuccess) {
      this.props.getAllCompanyJobOffers(this.props.companyId);
    }
  }

  prepareSkills (nextProps: Props, type: string): SkillItemDto[] {
    let skills = [];
    if (nextProps.savedSkills) {
      skills = nextProps.savedSkills[type];
    } else {
      skills = nextProps.skills[type];
    }
    return skills.map(skill => ({ ...skill, type }));
  }

  calculateSkillPriority () {
    const skills = this.collectAllSkills();
    const count = skills.reduce((prev, curr) => prev + curr.points, 0);
    this.setState({
      count,
      disabled: this.state.count >= 100
    });
  }

  collectAllSkills (): SkillItemDto[] {
    return [
      ...this.state.documents,
      ...this.state.languageSkills,
      ...this.state.softwareSkills,
      ...this.state.professionalSkills
    ];
  }

  filterRequiredSkills () {
    this.setState({
      requiredSkillsCount: this.collectAllSkills().filter(item => item.required).length
    });
  }

  changeSkillLevelState (field: string, value: string, skillId: number) {
    const skills = this.state[field].map(item => {
      if (item.skillId === skillId) {
        return {
          ...item,
          skillLevel: value.toUpperCase()
        };
      }
      return item;
    });
    this.setState({
      [field]: skills
    }, () => this.calculateSkillPriority());
  }

  getSkills (state, field, value, skillId) {
    return state[field].map(item => {
      if (item.skillId === skillId) {
        const count = state.count - item.points + value;
        const different = count < 100 ? 0 : (count - 100);
        return {
          ...item,
          points: value - different
        };
      }
      return item;
    });
  }

  changeSkillPointsState (field: string, value: string, skillId: number) {
    this.setState(state => {
      return {
        [field]: this.getSkills(state, field, value, skillId),
        minPointsError: state.count < this.maxPointsCount
      };
    }, () => this.calculateSkillPriority());
  }

  isRichMaxRequiredSkillsLength (): boolean {
    return this.state.requiredSkillsCount <= this.maxRequiredSkillsCount - 1;
  }

  toggleRequiredSkill (field: string, skill: SkillItemDto) {
    if (this.isRichMaxRequiredSkillsLength() || skill.required) {
      const skills = this.state[field].map((item: SkillItemDto) => {
        if (item.skillId === skill.skillId) {
          return {
            ...item,
            points: 0,
            required: !skill.required
          };
        }
        return item;
      });
      this.setState({
        [field]: skills
      }, () => {
        this.calculateSkillPriority();
        this.filterRequiredSkills();
      });
    }
  }

  validate (cb: (isValid: boolean) => void) {
    this.setState({
      minPointsError: this.state.count < this.maxPointsCount,
      maxPointsError: this.state.count > this.maxPointsCount
    }, () => {
      const valid = !this.state.maxPointsError && !this.state.minPointsError;
      cb(valid);
    });
  }

  publishJobOffer () {
    const skillsData = new SkillsDto(this.state);
    const jobOfferId = this.props.jobOfferId || this.props.processJobOfferId;
    this.props.updateCompanyJobOfferSkills(jobOfferId, skillsData, this.props.companyId)
      .then(() => this.props.publishNewJobOffer(this.props.processJobOfferId, this.props.companyId));
    toast.success(this.props.t('portal.recruiter.notifications.jop-offer-add'));
    this.props.closeJobOffer();
  }

  editJobOffer () {
    const skillsData = new SkillsDto(this.state);
    this.props.updateCompanyJobOfferSkills(this.props.jobOfferId, skillsData, this.props.companyId);
    toast.success(this.props.t('portal.recruiter.notifications.job-offer-updated'));
    this.props.closeJobOffer();
  }

  updateJobOffer (event: Event) {
    event.preventDefault();
    this.validate(valid => {
      if (valid) {
        if (this.props.type === 'edit') {
          this.editJobOffer();
        }
      }
    });
  }

  saveDraft () {
    const skillsData: SkillsDto = new SkillsDto(this.state);
    this.props.updateCompanyJobOfferSkills(this.props.jobOffer.id, skillsData, true);
    this.props.closeJobOffer();
    toast.success(this.props.t('portal.recruiter.notifications.job-offer-add-draft'));
  }

  publishCreatedJobOffer () {
    const skillsData: SkillsDto = new SkillsDto(this.state);
    this.props.updateCompanyJobOfferSkills(this.props.jobOffer.id, skillsData, this.props.companyId, true, true);
    toast.success(this.props.t('portal.recruiter.notifications.jop-offer-add'));
    this.props.closeJobOffer();
  }

  render () {
    const skillBadgeClasses = cn([ 'skill-badge', {
      'error-text': this.state.maxPointsError,
      'success-text': this.state.count === this.maxPointsCount
    } ]);
    const skillCountClasses = cn({
      'required-skills-success': this.state.requiredSkillsCount === this.maxRequiredSkillsCount
    });
    const skillList: string[] = [
      'professionalSkills',
      'languageSkills',
      'softwareSkills',
      'documents',
      'softSkills'
    ];
    return (
      <Fragment>
        {
          this.state.createSkillSuccess &&
          <Redirect to="/portal/recruiter/job-offers"/>
        }
        <form
          onSubmit={this.updateJobOffer.bind(this)}
          className="priority-match-form">
          <div className="row header sticky">
            <div className="column title">
              <Trans i18nKey="portal.recruiter.balance-board.job-offer.priority.skill">
                Skill Name
              </Trans>
            </div>
            <div className="column level">
              <Trans i18nKey="portal.recruiter.balance-board.job-offer.priority.knowledge">
                Knowledge Level
              </Trans>
            </div>
            <div className="column priority">
              <Trans i18nKey="portal.recruiter.balance-board.job-offer.priority.priority">
                Skill Priority
              </Trans>
              <div className={skillBadgeClasses}>
                <span>{this.state.count} </span> / 100
              </div>
            </div>
            <div className="column choice">
              <span className={skillCountClasses}>({this.state.requiredSkillsCount}/3)</span>
            </div>
          </div>
          <div className={`draggable-area ${this.state.requiredSkillsCount > 0 ? '' : 'initial'}`}>
            {
              this.state.requiredSkillsCount > 0 ? skillList.map(section => {
                return (this.state[section] || []).filter(item => item.required).map((item, index) => (
                  <JobOfferSkill
                    required
                    changeSkillLevel={value => this.changeSkillLevelState(section, value, item.skillId)}
                    removeFromRequired={() => this.toggleRequiredSkill(section, item)}
                    key={index}
                    skill={item}
                  />
                ));
              }) : <p>
                <b>
                  <Trans i18nKey="portal.recruiter.balance-board.job-offer.priority.information.press">
                    Press
                  </Trans>
                  &nbsp;
                </b>
                <span className="select-row spaced"/>
                <Trans i18nKey="portal.recruiter.balance-board.job-offer.priority.information.text">
                  <b> to set up your *must have* skills </b>
                  (you will only get candidates that have this skill(s) on the required level)
                </Trans>
              </p>
            }
          </div>
          {
            skillList.map(section => {
              return (this.state[section] || []).filter(item => !item.required).map((item, index) => (
                <JobOfferSkill
                  required={item.required}
                  disabled={this.state.disabled}
                  hideSkillLevel={section === 'documents'}
                  changeSkillLevel={value => this.changeSkillLevelState(section, value, item.skillId)}
                  changeSkillPoints={value => this.changeSkillPointsState(section, value, item.skillId)}
                  addToRequired={() => this.toggleRequiredSkill(section, item)}
                  key={index}
                  skill={item}/>
              ));
            })
          }
          <FormGroup className="matching-rate">
            <h2 className="title">
              <Trans i18nKey="portal.recruiter.balance-board.job-offer.priority.match.title">
                Matching Rate
              </Trans>
            </h2>
            <Trans i18nKey="portal.recruiter.balance-board.job-offer.priority.match.description">
              Current matching rate for this job offer:
            </Trans>
            <strong> {this.state.matchPoints} %</strong>
            <Button
              recruiter
              outlined
              onClick={() => { this.setState({ isMatchModalOpen: true }); }}>
              <Trans i18nKey="portal.recruiter.balance-board.job-offer.priority.match.button">
                Change matching rate
              </Trans>
            </Button>
          </FormGroup>
          <FormGroup className="full-width form-buttons">
            {
              this.state.minPointsError && <p className="error-text">
                <Trans i18nKey="portal.recruiter.balance-board.job-offer.priority.errors.0">
                  Skill priority cannot be less than 100
                </Trans>
              </p>
            }
            {
              this.state.maxPointsError && <p className="error-text">
                <Trans i18nKey="portal.recruiter.balance-board.job-offer.priority.errors.1">
                  You should only have 100 points in total
                </Trans>
              </p>
            }
            <Button
              grey
              type="button"
              onClick={() => this.props.prev()}>
              <Trans i18nKey="portal.recruiter.balance-board.job-offer.buttons.back">
                Back
              </Trans>
            </Button>
            {
              this.props.type === 'create' && <Fragment>
                <Button
                  onClick={() => this.saveDraft()}
                  grey>
                  <Trans i18nKey="portal.recruiter.balance-board.job-offer.buttons.draft">
                    Save Drafts
                  </Trans>
                </Button>
                <Button
                  recruiter
                  medium
                  onClick={() => this.publishCreatedJobOffer()}
                  type="submit">
                  <Trans i18nKey="portal.recruiter.balance-board.job-offer.buttons.publish">
                    Publish
                  </Trans>
                </Button>
              </Fragment>
            }
            {
              this.props.type === 'edit' && <Button
                type="submit"
                recruiter
                medium>
                <Trans i18nKey="portal.recruiter.balance-board.job-offer.buttons.update">
                  Update
                </Trans>
              </Button>
            }
            {
              this.props.type === 're-use' && <Fragment>
                <Button
                  onClick={() => this.editJobOffer()}
                  grey>
                  <Trans i18nKey="portal.recruiter.balance-board.job-offer.buttons.draft">
                    Save Drafts
                  </Trans>
                </Button>
                <Button
                  recruiter
                  medium
                  onClick={() => this.publishJobOffer()}
                  type="submit">
                  <Trans i18nKey="portal.recruiter.balance-board.job-offer.buttons.publish">
                    Publish
                  </Trans>
                </Button>
              </Fragment>
            }
          </FormGroup>
        </form>
        <MatchModal
          open={this.state.isMatchModalOpen}
          apply={(value: number) => this.setState({ matchPoints: value })}
          close={() => this.setState({ isMatchModalOpen: false })}
          skills={this.state}
          current={this.state.matchPoints}/>
      </Fragment>
    );
  }

}
