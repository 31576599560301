// @flow
import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { CandidateDto } from 'rest/candidates/candidate.dto';

import { candidateSignUp, getSocialUrl } from 'services/auth/candidate/auth.action';

export type Props = {
  error?: boolean,
  redirect?: boolean,
  fetching?: boolean,
  errorText?: string,
  googleLink?: string,
  facebookLink?: string,
  getSocialUrl?: (social: string) => void,
  candidateSignUp?: (data: CandidateDto) => void
};

export type State = {
  email?: string,
  password?: string,
  redirect?: boolean,
  errorText?: string,
  emailText?: string,
  emailError?: boolean,
  acceptRules?: boolean,
  passwordCheck?: string,
  errorCollection?: Set<string>,
  acceptNotification?: boolean
}

export class Settings extends Component<Props, State> {

  static defaultProps: Props = {
    error: false,
    fetching: false,
    redirect: false,
    errorText: '',
    googleLink: '',
    facebookLink: '',
    getSocialUrl: () => {},
    candidateSignUp: () => {}
  };

  state: State = {
    email: '',
    password: '',
    redirect: false,
    emailText: '',
    errorText: '',
    emailError: false,
    acceptRules: true,
    passwordCheck: '',
    errorCollection: new Set(),
    acceptNotification: true
  };

}

export const stateToProps = (state: GlobalState) => ({
  error: state.auth.candidate.signUp.error,
  fetching: state.auth.candidate.signUp.fetching,
  errorText: state.auth.candidate.signUp.errorText,
  googleLink: state.auth.candidate.signIn.googleLink,
  redirect: state.auth.candidate.signUp.signUpSuccess,
  facebookLink: state.auth.candidate.signIn.facebookLink
});

export const dispatchToProps = (dispatch: Dispatch): Props => bindActionCreators({
  getSocialUrl,
  candidateSignUp
}, dispatch);
