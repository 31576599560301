// @flow
import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { BranchResponseDto } from 'rest/branch/branch.response.dto';
import { LocationsDto } from 'rest/companies/job-offers-matches/matched-candidates/locations/locations.dto';

import { getAllBranches } from 'services/portal/recruiter/account/account.actions';
import {
  changeFavoriteFilter,
  getFavoriteJobsLocations
} from 'services/portal/candidate/jobs/favorite/favorite.actions';

type Props = {
  branches: BranchResponseDto[],
  locations: LocationsDto[]
};

type State = {
  branchId: string,
  cityPlaceId: string
}

export class Settings extends Component<Props, State> {

  state: State = {
    branchId: '',
    cityPlaceId: ''
  };

}

export const stateToProps = (state: GlobalState) => ({
  locations: state.portal.candidate.jobs.favorite.locations,
  branches: state.portal.recruiter.account.companies.branches
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  changeFavoriteFilter,
  getBranches: getAllBranches,
  getLocations: getFavoriteJobsLocations
}, dispatch);
