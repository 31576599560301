import axios from 'axios';

import { ChangePasswordDto } from './change-password.dto';

export async function changeUserPassword (data: ChangePasswordDto): Promise<ChangePasswordDto> {
  try {
    return await axios.post('/account/change-password', data);
  } catch (error) {
    if (error.response) {
      throw error.response.data.title;
    }
  }
}
