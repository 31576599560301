import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { getConversation } from 'services/portal/chats/conversations/conversations.actions';

import './user.scss';
import './user.responsive.scss';

export class Settings extends Component {}

export const stateToProps = (state: GlobalState) => {
  const recruiter = state.portal.recruiter.account.current.currentUser;
  const candidate = state.portal.candidate.account.current.candidateData;
  return {
    userId: recruiter.id || candidate.id,
    conversation: state.portal.chats.conversations.conversation
  };
};

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  initConversation: getConversation
}, dispatch);
