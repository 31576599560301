import axios from 'axios';
import { AxiosPromise } from 'axios';

import { AskQuestionRequestDto } from './ask-question.request.dto';
import { AskQuestionResponseDto } from './ask-question.response.dto';

export async function createQuestion (question: AskQuestionRequestDto): AxiosPromise<AskQuestionResponseDto> {
  try {
    const formData = new FormData();
    formData.append('name', question.name);
    formData.append('email', question.email);
    formData.append('url', window.location.href);
    formData.append('question', question.question);
    question.files.map(file => formData.append('files[]', file));
    return await axios.post(`/public/ask-question`, formData);
  } catch (error) {
    throw error;
  }
}
