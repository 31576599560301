// @flow
import React from 'react';
import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';

import { Spinner } from 'components/elements';
import { IconButton } from 'components/buttons';
import { Table, TableHeaders, TableBody } from 'components/table';

import { DeleteMemberModal } from './delete-member-modal/delete-member-modal';
import { InviteRecruiterModal } from './invite-modal/invite-modal';

import { ResponseTeamMemberDto } from 'rest/companies/team-members/team-member.response';

import { Setting, stateToProps, dispatchToProps } from './team-members.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class ProfileTeamMembers extends Setting {

  componentDidMount () {
    if (this.props.currentUser) {
      this.props.getRecruiterCompanyById(this.props.currentUser.companyId);
      this.props.getRecruiterTeamMembers(this.props.currentUser.companyId);
    }
  }

  showDeleteTeamMemberModal (currentMember: ResponseTeamMemberDto) {
    this.setState({
      isDeleteModalOpen: true,
      currentMember
    });
  }

  render () {
    let userInitials: string = '';
    const isInvitedRecruiter: boolean = !this.props.currentUser.owner;
    const maxNumberOfMembers: number = 4;
    const companyName: string = this.props.company && this.props.company.name;
    const teamMembers = this.props.teamMembers ? this.props.teamMembers : [];
    return (
      <div className="profile-team-member">
        <Spinner show={this.props.fetching} />
        <Table className="members-table">
          <TableHeaders className="table-header">
            <th>
              <Trans i18nKey="portal.recruiter.profile.team.header.0">
                Member
              </Trans>
            </th>
            <th>
              <Trans i18nKey="portal.recruiter.profile.team.header.1">
                Email Address
              </Trans>
            </th>
            <th>
              <Trans i18nKey="portal.recruiter.profile.team.header.2">
                Status
              </Trans>
            </th>
            <th/>
          </TableHeaders>
          <TableBody className="table-body">
            {
              teamMembers.map((item: ResponseTeamMemberDto) => {
                const isOwner: boolean = item.inviteStatus === 'OWNER';
                const isAvatarUrlPresent: boolean = item.recruiterImage && item.recruiterImage.url;
                const recruiterImage: string =
                  isAvatarUrlPresent ? item.recruiterImage.url : '/images/shared/userpick.jpg';
                userInitials = item.firstName && item.lastName ? `${item.firstName} ${item.lastName}` : companyName;
                return (
                  <tr key={item.id}>
                    <td>
                      <div className="member-info">
                        <div className="avatar">
                          <img
                            src={recruiterImage}
                            alt="avatar"/>
                        </div>
                        <div>
                          <p className="member-name">{userInitials} </p>
                          <p className="member-type">
                            { isOwner
                              ? this.props.t('portal.recruiter.profile.team.type.0')
                              : this.props.t('portal.recruiter.profile.team.type.1') }
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="member-email">{item.email}</span>
                    </td>
                    <td>
                      <span className="member-status">{item.inviteStatus}</span>
                    </td>
                    <td>
                      {
                        isOwner || isInvitedRecruiter ? null : <IconButton
                          onClick={() => this.showDeleteTeamMemberModal(item)}
                          icon="close"/>
                      }
                    </td>
                  </tr>
                );
              })
            }
          </TableBody>
        </Table>
        {
          !isInvitedRecruiter && <div className="invite-btn-container">
            <button
              disabled={teamMembers.length > maxNumberOfMembers}
              onClick={() => this.setState({ isInviteModalVisible: true })}
              className="inline-btn">
              <img
                src="/images/shared/add-icon-blue.svg"
                alt=""/>
              <Trans i18nKey="portal.recruiter.profile.team.invite-button">
                Invite New Member
              </Trans>
            </button>
          </div>
        }
        <InviteRecruiterModal
          hideModal={() => this.setState({ isInviteModalVisible: false })}
          open={this.state.isInviteModalVisible}
        />
        <DeleteMemberModal
          member={{
            ...this.state.currentMember,
            userInitials
          }}
          companyId={this.props.currentUser.companyId}
          hideModal={() => this.setState({ isDeleteModalOpen: false })}
          open={this.state.isDeleteModalOpen}
        />
      </div>
    );
  }

}
