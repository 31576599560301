import axios from 'axios';

import { ListDto } from 'middleware/dto';
import { SearchDto } from 'middleware/dto/search';
import { PaginationDto } from 'middleware/dto';

export async function getLanguages (params: PaginationDto): Promise<ListDto[]> {
  try {
    return await axios.get('/languages', {
      params
    });
  } catch (error) {
    throw error;
  }
}

export async function searchLanguages (params: SearchDto): Promise<ListDto[]> {
  try {
    return await axios.get('/languages/search', {
      params
    });
  } catch (error) {
    throw error;
  }
}
