// @flow
import axios, { AxiosPromise } from 'axios';

import { AnonymousParams } from './anonymous.params';

export async function contactAnonymousById (candidateId: string): AxiosPromise {
  try {
    return await axios.post(`/candidates/${candidateId}/anonymous`);
  } catch (error) {
    throw error;
  }
}

export async function acceptContactById (recruiterId: string, params: AnonymousParams): AxiosPromise {
  try {
    return await axios.put(`/recruiters/${recruiterId}/anonymous/accept`, null, {
      params
    });
  } catch (error) {
    throw error;
  }
}

export async function declineContactById (recruiterId: string, params: AnonymousParams): AxiosPromise {
  try {
    return await axios.put(`/recruiters/${recruiterId}/anonymous/decline`, null, {
      params
    });
  } catch (error) {
    throw error;
  }
}
