// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { StatsDto } from 'rest/stats/stats.dto';
import { CurrentRecruiterDto } from 'rest/recruiter/current/current.dto';

import {
  resetJobOffersStatistic,
  getAllCompanyJobOffersStats
} from 'services/portal/recruiter/account/job-offer/stats/stats.actions';

import {
  resetJobOffers,
  changeJobOffersStatus,
  getAllCompanyJobOffers
} from 'services/portal/recruiter/account/job-offer/job-offer.actions';

type Props = {
  t: TransProps,
  stats: StatsDto[],
  total: number,
  currentUser: CurrentRecruiterDto,
  resetJobOffers: () => void,
  jobOffersStatus: string,
  changeJobOffersStatus: (status?: string) => void,
  getAllCompanyJobOffers: (companyId: string, status?: string) => void,
  resetJobOffersStatistic: () => void,
  getAllCompanyJobOffersStats: () => void
}

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  stats: state.portal.recruiter.account.jobOfferStats.stats,
  currentUser: state.portal.recruiter.account.current.currentUser,
  jobOffersStatus: state.portal.recruiter.account.jobOffer.status,
  total: state.portal.recruiter.account.jobOfferStats.totalElements
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetJobOffers,
  changeJobOffersStatus,
  getAllCompanyJobOffers,
  resetJobOffersStatistic,
  getAllCompanyJobOffersStats
}, dispatch);
