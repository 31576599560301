export const GET_FOLLOWERS_COMPANY_REQUEST = 'GET_FOLLOWERS_COMPANY_REQUEST';
export const GET_FOLLOWERS_COMPANY_SUCCESS = 'GET_FOLLOWERS_COMPANY_SUCCESS';
export const GET_FOLLOWERS_COMPANY_FAILURE = 'GET_FOLLOWERS_COMPANY_FAILURE';

export const GET_FOLLOWERS_JOB_OFFERS_REQUEST = 'GET_FOLLOWERS_JOB_OFFERS_REQUEST';
export const GET_FOLLOWERS_JOB_OFFERS_SUCCESS = 'GET_FOLLOWERS_JOB_OFFERS_SUCCESS';
export const GET_FOLLOWERS_JOB_OFFERS_FAILURE = 'GET_FOLLOWERS_JOB_OFFERS_FAILURE';

export const GET_FOLLOWERS_LOCATIONS_REQUEST = 'GET_FOLLOWERS_LOCATIONS_REQUEST';
export const GET_FOLLOWERS_LOCATIONS_SUCCESS = 'GET_FOLLOWERS_LOCATIONS_SUCCESS';
export const GET_FOLLOWERS_LOCATIONS_FAILURE = 'GET_FOLLOWERS_LOCATIONS_FAILURE';

export const RESET_FOLLOWERS_JOB_OFFER_SUCCESS = 'RESET_FOLLOWERS_JOB_OFFER_SUCCESS';

export const CHANGE_FOLLOWERS_FILTERS_SUCCESS = 'CHANGE_FOLLOWERS_FILTERS_SUCCESS';
