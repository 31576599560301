import * as actions from './stats.constants';

import { StatsDto } from 'rest/stats/stats.dto';

import type { FavoritesStatsType } from './stats.type';

const initialState: FavoritesStatsType = {
  fetching: false,
  unitStats: [],
  jobTitlesStats: [],
  totalUnitElements: 0,
  totalJobTitlesElements: 0
};

export default (state = initialState, action): FavoritesStatsType => {
  switch (action.type) {

    case actions.GET_FAVORITES_UNIT_STATS_REQUEST:
    case actions.GET_FAVORITES_JOB_TITLE_STATS_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case actions.GET_FAVORITES_UNIT_STATS_SUCCESS:
      return {
        ...state,
        fetching: false,
        unitStats: action.stats.map(stat => new StatsDto(stat)),
        totalUnitElements: action.total
      };

    case actions.GET_FAVORITES_JOB_TITLE_STATS_SUCCESS:
      return {
        ...state,
        fetching: false,
        jobTitlesStats: action.stats.map(stat => new StatsDto(stat)),
        totalJobTitlesElements: action.total
      };

    case actions.GET_FAVORITES_UNIT_STATS_FAILURE:
    case actions.GET_FAVORITES_JOB_TITLE_STATS_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.RESET_FAVORITES_STATS_SUCCESS:
      return {
        ...state,
        fetching: false,
        unitStats: [],
        jobTitlesStats: [],
        totalUnitElements: 0,
        totalJobTitlesElements: 0
      };

    default:
      return state;
  }
};
