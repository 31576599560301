import { Component } from 'react';

import './footer.scss';
import './footer.responsive.scss';

type Props = {
  className?: string,
  copyright?: string,
};

export class Settings extends Component<Props> {

  static defaultProps = {
    className: '',
    copyright: ''
  };

}
