import * as actions from './match.constants';

import type { JobOfferMatchState } from './match.type';

import { JobOfferMatchedDto } from 'rest/job-offers/match/match.dto';

const initialState: JobOfferMatchState = {
  fetching: false,
  matched: false
};

export default (state = initialState, action): JobOfferMatchState => {
  switch (action.type) {

    case actions.CHECK_JOB_OFFER_MATCH_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case actions.CHECK_JOB_OFFER_MATCH_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.CHECK_JOB_OFFER_MATCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        matched: new JobOfferMatchedDto(action.payload).matched
      };

    default:
      return state;
  }
};
