export class SkillDto {

  id: string;
  name: string;
  required: boolean;
  description: string;

  skillId: string;
  skillLevel: string;

  constructor (data?: SkillDto) {
    if (data) {
      this.id = data.id || data.skillId;
      this.name = data.name;
      this.skillId = data.id || data.skillId;
      this.required = data.required;
      this.skillLevel = data.skillLevel;
      this.description = data.description;
    }
  }

}
