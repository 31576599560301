import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Trans, TransProps } from 'react-i18next';

import { connectStore } from 'middleware/connect';

import { LinkButton } from 'components/buttons';
import { PortletContainer, PortletHeader, PortletBody } from 'components/portlet';

import { PostsList } from 'containers/portal/candidate/posts';

import { stateToProps, dispatchToProps } from './index.setting';

type Props = {
  t: TransProps,
  total: number
}

@connectStore(stateToProps, dispatchToProps, true)
export class PostsListPage extends Component<Props> {

  render () {
    return (
      <PortletContainer>
        <PortletHeader
          className="mobile-portlet-header-blog"
          title={this.props.t('portal.candidate.blog.list.title')}>
          <div className="mobile-blog-post-count">({this.props.total})</div>
          <LinkButton
            link="/portal/candidate/posts/create"
            className="web-create-post-btn"
            candidate>
            <Trans i18nKey="portal.candidate.blog.list.add">
              Add Post
            </Trans>
          </LinkButton>
        </PortletHeader>
        <PortletBody className="mobile-portlet-body-blog">
          <Route
            path="/portal/candidate/posts"
            component={PostsList}/>
        </PortletBody>
        <div className="mobile-create-post-btn">
          <LinkButton
            link="/portal/candidate/posts/create"
            candidate>
            <Trans i18nKey="portal.candidate.blog.list.add">
              Add Post
            </Trans>
          </LinkButton>
        </div>
      </PortletContainer>
    );
  }

}
