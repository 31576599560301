// @flow
import React from 'react';

import type { Props, State } from './switch-control.setting';

import { connectTranslation } from 'middleware/connect';

import { getLocalization } from 'middleware/localization';
import { Setting } from './switch-control.setting';

@connectTranslation()
export class SwitchControl extends Setting {

  static getDerivedStateFromProps (nextProps: Props, prevState: State) {
    const currentLocale: string = getLocalization();
    if (prevState.jobTypes) {
      const selectedItem = prevState.jobTypes[currentLocale].find(elem => elem.id === nextProps.value);
      return { selectedItem: selectedItem || {} };
    }
  }

  componentDidMount () {
    this.setState({
      jobTypes: {
        en: [
          {
            id: 'Employee',
            name: 'Full-time'
          },
          {
            id: 'Temporary',
            name: 'Part-Time'
          },
          {
            id: 'Trainee',
            name: 'Trainee'
          }
        ],
        de: [
          {
            id: 'Employee',
            name: this.props.t('portal.recruiter.balance-board.job-offer.general.job-type.items.0')
          },
          {
            id: 'Temporary',
            name: this.props.t('portal.recruiter.balance-board.job-offer.general.job-type.items.1')
          },
          {
            id: 'Trainee',
            name: this.props.t('portal.recruiter.balance-board.job-offer.general.job-type.items.2')
          }
        ],
        fr: [
          {
            id: 'Employee',
            name: this.props.t('portal.recruiter.balance-board.job-offer.general.job-type.items.0')
          },
          {
            id: 'Temporary',
            name: this.props.t('portal.recruiter.balance-board.job-offer.general.job-type.items.1')
          },
          {
            id: 'Trainee',
            name: this.props.t('portal.recruiter.balance-board.job-offer.general.job-type.items.2')
          }
        ],
        es: [
          {
            id: 'Employee',
            name: this.props.t('portal.recruiter.balance-board.job-offer.general.job-type.items.0')
          },
          {
            id: 'Temporary',
            name: this.props.t('portal.recruiter.balance-board.job-offer.general.job-type.items.1')
          },
          {
            id: 'Trainee',
            name: this.props.t('portal.recruiter.balance-board.job-offer.general.job-type.items.2')
          }
        ]
      }
    });
  }

  getJobTypeItem (jobType: string) {
    const currentLocale: string = getLocalization();
    return this.state.jobTypes[currentLocale].find(elem => elem.name === jobType);
  }

  changeActiveItem (item: string) {
    this.props.change(this.getJobTypeItem(item));
    this.setState({
      selectedItem: this.getJobTypeItem(item)
    });
  }

  render () {
    return (
      <div className="switch-container">
        <label className="switch-label">
          <span className="form-label-title">{this.props.label ? this.props.label : null}</span>
          <span className="notes">{this.props.notes ? this.props.notes : null}</span>
        </label>
        <div className={`${this.props.className} switch-control`}>
          {this.props.items.map((item, index) => (
            <span
              key={index}
              onClick={() => this.changeActiveItem(item)}
              className={`switch-control-item ${item === this.state.selectedItem.name ? 'active' : ''}`}>
              {item}
            </span>
          ))}
        </div>
      </div>
    );
  }

}
