// @flow
import * as actions from './search.constants';

import { Action, Dispatch } from 'redux';

const brandSkills = (skills: string[]): Action => ({
  type: actions.SEARCH_BRAND_SKILLS,
  skills: skills
});

const brandBranch = (branchId: string): Action => ({
  type: actions.SEARCH_BRAND_BRANCH,
  branchId: branchId
});

const brandUnit = (unitId: string): Action => ({
  type: actions.SEARCH_BRAND_UNIT,
  unitId: unitId
});

const brandJobTitle = (brandTitleId: string): Action => ({
  type: actions.SEARCH_BRAND_JOB_TITLE,
  brandTitleId: brandTitleId
});

const brandLocation = (location: string): Action => ({
  type: actions.SEARCH_BRAND_LOCATION,
  location: location
});

const brandCompanyName = (companyName: string): Action => ({
  type: actions.SEARCH_BRAND_COMPANY_NAME,
  companyName: companyName
});

const brandSalaryMinimum = (minSalary: number): Action => ({
  type: actions.SEARCH_BRAND_SALARY_MIN,
  minSalary
});

const brandSalaryMaximum = (maxSalary: number): Action => ({
  type: actions.SEARCH_BRAND_SALARY_MAX,
  maxSalary
});

const brandWorkingTime = (workingTime: number): Action => ({
  type: actions.SEARCH_BRAND_WORKING_TIME,
  workingTime: workingTime
});

const brandAvailableFrom = (availableFrom: string): Action => ({
  type: actions.SEARCH_BRAND_AVAILABLE_FROM,
  availableFrom: availableFrom
});

const brandJobType = (brandType: string): Action => ({
  type: actions.SEARCH_BRAND_JOB_TYPE,
  brandType: brandType
});

const brandPeriodOfPublish = (publishedFrom: number): Action => ({
  type: actions.SEARCH_BRAND_PERIOD_OF_PUBLISH,
  publishedFrom
});

const brandCloseToMe = (closeToMe: boolean): Action => ({
  type: actions.SEARCH_BRAND_CLOSE_TO_ME,
  closeToMe: closeToMe
});

const resetBrandFilter = (): Action => ({
  type: actions.SEARCH_BRAND_RESET
});

const resetBrandSkillsFilter = (): Action => ({
  type: actions.SEARCH_BRAND_SKILLS_RESET
});

const searchBrandId = (brandId: string): Action => ({
  type: actions.SEARCH_BRAND_ID
});

export function clearBrandSearch () {
  return (dispatch: Dispatch) => {
    dispatch(resetBrandFilter());
  };
}

export function clearBrandSearchSkills () {
  return (dispatch: Dispatch) => {
    dispatch(resetBrandSkillsFilter());
  };
}

export function saveBrandSkillsFilter (skills: string[]) {
  return (dispatch: Dispatch) => {
    dispatch(brandSkills(skills));
  };
}

export function saveSearchBrandBranch (branchId: string) {
  return (dispatch: Dispatch) => {
    dispatch(brandBranch(branchId));
  };
}

export function saveSearchBrandUnit (unitId: string) {
  return (dispatch: Dispatch) => {
    dispatch(brandUnit(unitId));
  };
}

export function saveSearchBrandJobTitle (brandTitleId: string) {
  return (dispatch: Dispatch) => {
    dispatch(brandJobTitle(brandTitleId));
  };
}

export function saveSearchBrandLocation (location: string) {
  return (dispatch: Dispatch) => {
    dispatch(brandLocation(location));
  };
}

export function saveSearchBrandCompanyName (companyName: string) {
  return (dispatch: Dispatch) => {
    dispatch(brandCompanyName(companyName));
  };
}

export function saveSearchBrandSalaryMinimum (salaryMinimum: number) {
  return (dispatch: Dispatch) => {
    dispatch(brandSalaryMinimum(salaryMinimum ? parseFloat(salaryMinimum) : null));
  };
}

export function saveSearchBrandSalaryMaximum (salaryMaximum: number) {
  return (dispatch: Dispatch) => {
    dispatch(brandSalaryMaximum(salaryMaximum ? parseFloat(salaryMaximum) : null));
  };
}

export function saveSearchBrandWorkingTime (workingTime: number) {
  return (dispatch: Dispatch) => {
    dispatch(brandWorkingTime(workingTime));
  };
}

export function saveSearchBrandAvailableFrom (availableFrom: string) {
  return (dispatch: Dispatch) => {
    dispatch(brandAvailableFrom(availableFrom));
  };
}

export function saveSearchBrandJobType (brandType: string) {
  return (dispatch: Dispatch) => {
    dispatch(brandJobType(brandType));
  };
}

export function saveSearchBrandPeriodOfPublish (periodOfPublish: string) {
  return (dispatch: Dispatch) => {
    dispatch(brandPeriodOfPublish(periodOfPublish));
  };
}

export function saveSearchBrandCloseToMe (closeToMe: boolean) {
  return (dispatch: Dispatch) => {
    dispatch(brandCloseToMe(closeToMe));
  };
}

export function saveSearchBrandId (brandId: string) {
  return (dispatch: Dispatch) => {
    dispatch(searchBrandId(brandId));
  };
}
