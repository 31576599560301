export class AskMeParams {

  userType: string;

  constructor (data?: AskMeParams) {
    if (data) {
      this.userType = data.userType;
    }
  }

}
