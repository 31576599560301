import axios from 'axios';

import { ListDto } from 'middleware/dto/list';
import { PaginationDto } from 'middleware/dto/pagination';

export async function getJobTitles (params: PaginationDto): Promise<ListDto[]> {
  try {
    return await axios.get(`/jobtitles`, {
      params
    });
  } catch (error) {
    throw error;
  }
}
