// @flow
import { Component } from 'react';

import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { getAllJobTitles } from 'services/portal/job-titles/job-titles.actions';

import { initJobsFilter } from 'services/portal/candidate/jobs/job-offers/job-offers.actions';
import { initCandidatesFilter } from 'services/portal/recruiter/candidate/all/candidates.actions';

import { saveSearchJobsJobTitle, saveSearchJobsLocation } from 'services/portal/candidate/jobs/search/search.actions';

import {
  saveSearchCandidatesJobTitle,
  saveSearchCandidatesLocation
} from 'services/portal/recruiter/candidate/search/search.actions';

import { saveSearchBrandId, saveSearchBrandJobTitle } from 'services/portal/brand/search/search.actions';

import './jumbotron.scss';
import './jumbotron.responsive.scss';

export type State = {
  activeTab: number
}

export class Setting extends Component<null, State> {

  state = {
    activeTab: 0
  }

}

export const stateToProps = (state: GlobalState) => ({
  jobTitles: state.portal.jobTitles.jobTitlesList,
  searchJobOffers: state.portal.candidate.jobs.searchJobOffers,
  searchCandidates: state.portal.recruiter.candidate.searchCandidates,
  searchBrands: state.portal.brand.searchBrands
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  initJobsFilter,
  getAllJobTitles,
  initCandidatesFilter,
  saveSearchJobsJobTitle,
  saveSearchJobsLocation,
  saveSearchBrandId,
  saveSearchBrandJobTitle,
  saveSearchCandidatesLocation,
  saveSearchCandidatesJobTitle
}, dispatch);
