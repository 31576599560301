import React from 'react';

import { KnowledgeLevel } from 'components/controls';

import { Settings } from './skill-item.setting';

export class SkillItem extends Settings {

  render () {
    return (
      <div
        className="row body skill-name">
        <div className="column title">
          { this.props.skill.name }
        </div>
        <div className="column level">
          {
            !this.props.hideSkillLevel && <KnowledgeLevel
              candidate
              value={this.props.skill.skillLevel}
              change={(value: string) => this.props.change(value)}/>
          }
        </div>
      </div>
    );
  }

}
