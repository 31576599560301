import { JobOffersMatchesDto } from 'rest/candidates/job-offers-matches/job-offers-matches.dto';
import { JobOffersMatchesParams } from 'rest/candidates/job-offers-matches/job-offers-matches.params';

import * as actions from './job-offers.constants';

import type { JobOffersMatchesType } from './job-offers.type';

const initialState: JobOffersMatchesType = {
  content: [],
  fetching: false,
  currentPage: 0,
  hasMoreItems: true,
  totalElements: 0,
  filters: new JobOffersMatchesParams()
};

export default function (state: JobOffersMatchesType = initialState, action): JobOffersMatchesType {
  let content = [];
  switch (action.type) {
    case actions.GET_ALL_JOB_OFFERS_MATCHES_REQUEST:
      return {
        ...state,
        fetching: true,
        hasMoreItems: false
      };

    case actions.GET_ALL_JOB_OFFERS_MATCHES_SUCCESS:
      content = action.payload.content.map(jobOffer => new JobOffersMatchesDto(jobOffer));
      return {
        ...state,
        fetching: false,
        content: action.payload.number ? [ ...state.content, ...content ] : content,
        currentPage: action.payload.number + 1,
        hasMoreItems: action.payload.content.length > 0,
        totalElements: action.payload.totalElements
      };

    case actions.GET_ALL_JOB_OFFERS_MATCHES_FAILURE:
      return {
        ...state,
        fetching: false,
        content: [],
        hasMoreItems: false,
        totalElements: 0
      };

    case actions.RESET_ALL_JOB_OFFERS_MATCHES_SUCCESS:
      return { ...initialState };

    case actions.CHANGE_MATCHED_FILTERS_SUCCESS:
      return {
        ...initialState,
        filters: new JobOffersMatchesParams(action.params)
      };

    case actions.MARK_JOB_OFFERS_AS_VIEWED_SUCCESS: {
      const content: JobOffersMatchesDto[] = [ ...state.content ];
      const jobOfferIndex: number = state.content.findIndex(item => item.jobOfferId === action.jobOfferId);
      if (content[jobOfferIndex]) {
        content[jobOfferIndex].isNew = false;
      }
      return {
        ...state,
        content
      };
    }

    default:
      return state;
  }
}
