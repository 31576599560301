export class ConfirmDto {

  activationKey: string;

  constructor (data?: ConfirmDto) {
    if (data) {
      this.activationKey = data.activationKey;
    }
  }

}
