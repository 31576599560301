// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { getAllCompanyJobOffers, resetJobOffers } from 'services/portal/recruiter/account/job-offer/job-offer.actions';

import { CurrentRecruiterDto } from 'rest/recruiter/current/current.dto';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

type Props = {
  t: TransProps,
  history: History,
  jobOffers: JobOffersResponseDto[],
  currentUser: CurrentRecruiterDto,
  resetJobOffers: () => void,
  getAllCompanyJobOffers: (companyId: string, status?: string) => void
}

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  jobOffers: state.portal.recruiter.account.jobOffer.statsJobOffers,
  currentUser: state.portal.recruiter.account.current.currentUser
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetJobOffers,
  getAllCompanyJobOffers
}, dispatch);
