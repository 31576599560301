import React, { Component } from 'react';

import { TransProps } from 'react-i18next';

import { Tabs, Tab, TabHeader } from 'components/tabs';
import BetaIntroRecruiterSteps from './beta-intro-recruiter-steps';
import BetaIntroCandidateSteps from './beta-intro-candidate-steps';

import './beta-intro-step2.scss';

type Props = {
  className: string,
  t?: TransProps,
  handleActiveTab: () => void
};

type State = {
  activeTab: number
};

export class BetaIntroStep2 extends Component<Props, State> {

  state = {
    activeTab: 0
  };

  changeActiveTab = activeTab => {
    this.props.handleActiveTab(activeTab);
    this.setState({ activeTab });
  };

  render () {
    const { className, t } = this.props;
    const { activeTab } = this.state;

    return (
      <div className={className}>
        <Tabs
          activeTabIndex={activeTab}
          activeTabChange={this.changeActiveTab}>
          <Tab label={t('landing.beta-intro.step2.tabsHeader.recruiter')}>
            <TabHeader title={t('landing.beta-intro.step2.recruiter.title')}/>
            <BetaIntroRecruiterSteps/>
          </Tab>
          <Tab label={t('landing.beta-intro.step2.tabsHeader.candidate')}>
            <TabHeader title={t('landing.beta-intro.step2.candidate.title')}/>
            <BetaIntroCandidateSteps/>
          </Tab>
        </Tabs>
      </div>
    );
  }

}
