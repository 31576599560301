// @flow
import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';

import { TransProps } from 'react-i18next';

import { JobOffersMatchesParams } from 'rest/candidates/job-offers-matches/job-offers-matches.params';

import type { GlobalState } from 'common/types/global-state';

import {
  getMatchedJobOffersList,
  resetAllMatchedJobOffers
} from 'services/portal/candidate/match/job-offers/job-offers.actions';

import { addJobToFavorite, deleteJobFromFavorite } from 'services/portal/candidate/jobs/favorite/favorite.actions';

import './jobs-list.scss';

type Props = {
  t?: TransProps,
  fetching: boolean,
  matchFilters: JobOffersMatchesParams
};

export class Setting extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  fetching: state.portal.candidate.match.jobOffers.fetching,
  jobOffers: state.portal.candidate.match.jobOffers.content,
  matchFilters: state.portal.candidate.match.jobOffers.filters,
  currentPage: state.portal.candidate.match.jobOffers.currentPage,
  hasMoreItems: state.portal.candidate.match.jobOffers.hasMoreItems,
  currentCandidate: state.portal.candidate.account.current.candidateData
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  addJobToFavorite,
  deleteJobFromFavorite,
  getMatchedJobOffersList,
  resetAllMatchedJobOffers
}, dispatch);
