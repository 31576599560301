// @flow
import React from 'react';
import { Trans } from 'react-i18next';

import { TabsContent } from 'components/tabs';
import { Sidebar, Card } from 'components/layouts';

import { connectTranslation } from 'middleware/connect';

import { Modal } from 'containers/portal/modal-container';
import { ChangePasswordPage } from 'containers/portal/settings';

import { CandidateInfo } from './../candidate-info/candidate-info';
import { CandidateHistory } from './../history/history';
import { CandidateNotificationSettings } from './../notifications/notifications';

import type { Props } from './profile-modal.setting';
import { Settings } from './profile-modal.setting';

@connectTranslation()
export class CandidateProfileModal extends Settings {

  componentDidMount () {
    this.listenWindowWidth();
    window.addEventListener('resize', () => this.listenWindowWidth());
  }

  componentWillUnmount () {
    window.removeEventListener('resize', () => this.listenWindowWidth());
  }

  componentWillReceiveProps (nextProps: Props) {
    if (this.props.open !== nextProps.open) {
      if (this.state.mobileView) {
        this.setState({ activeNavLink: -1 });
      } else {
        this.setState({ activeNavLink: 0 });
      }
    }
  }

  listenWindowWidth () {
    this.setState({ mobileView: window.innerWidth < 993 });
  }

  returnToUserNav () {
    this.setState({ activeNavLink: -1 });
  }

  render () {
    return (
      <Modal
        open={this.props.open}
        className="candidate-profile-modal"
        animation="slide"
        overlay>
        <div className="setting-modal-container">
          <Sidebar
            history={this.props.history}
            activeTabIndex={this.state.activeNavLink}>
            <li onClick={() => this.setState({ activeNavLink: 0 })}>
              <Trans i18nKey="portal.candidate.profile.menu.0">
                User Profile
              </Trans>
            </li>
            <li onClick={() => this.setState({ activeNavLink: 1 })}>
              <Trans i18nKey="portal.candidate.profile.menu.1">
                Password
              </Trans>
            </li>
            <li onClick={() => this.setState({ activeNavLink: 2 })}>
              <Trans i18nKey="portal.candidate.profile.menu.2">
                Notifications
              </Trans>
            </li>
            <li onClick={() => this.setState({ activeNavLink: 3 })}>
              <Trans i18nKey="portal.candidate.profile.menu.3">
                History
              </Trans>
            </li>
          </Sidebar>
          <Card className="mobile-sidebar-card">
            <div className={`profile-modal-content ${this.state.activeNavLink > -1 ? 'active' : ''}`}>
              <div
                className="close-profile-modal"
                onClick={() => this.returnToUserNav()}>
                &times;
              </div>
              <TabsContent activeTab={this.state.activeNavLink}>
                <CandidateInfo/>
                <div>
                  <h2 className="profile-tab-caption">
                    <Trans i18nKey="portal.candidate.profile.password.title">
                      Password
                    </Trans>
                  </h2>
                  <ChangePasswordPage/>
                </div>
                <div>
                  <h2 className="profile-tab-caption">
                    <Trans i18nKey="portal.candidate.profile.menu.2">
                      Notifications
                    </Trans>
                  </h2>
                  <CandidateNotificationSettings/>
                </div>
                <div>
                  <h2 className="profile-tab-caption">
                    <Trans i18nKey="portal.candidate.profile.menu.3">
                      History
                    </Trans>
                  </h2>
                  <CandidateHistory/>
                </div>
              </TabsContent>
            </div>
          </Card>
        </div>
      </Modal>
    );
  }

}
