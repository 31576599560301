import React from 'react';

import { Trans } from 'react-i18next';

import { Setting } from './payment-expiration.setting';

export class PaymentExpirationTemplate extends Setting {

  render () {
    return (
      <div className="notification-item-content">
        <div className="logo-container">
          <img src="/images/notifications/userpick.svg" alt="logo"/>
        </div>
        <div className="content">
          <h3>
            <Trans i18nKey="portal.notifications.subscription.title">
              Subscription expires!
            </Trans>
          </h3>
          <p>
            <Trans i18nKey="portal.notifications.subscription.description.0">
              Your subscription expires soon! Click on
            </Trans>
            &nbsp;
            <strong className="accent-text">
              <Trans i18nKey="portal.notifications.subscription.description.1">
                Profile Setting
              </Trans>
            </strong>
            &nbsp;
            <Trans i18nKey="portal.notifications.subscription.description.2">
              tab and choose
            </Trans>
            &nbsp;
            <strong className="accent-text">
              <Trans i18nKey="portal.notifications.subscription.description.3">
                Payment Details
              </Trans>
            </strong>
            &nbsp;
            <Trans i18nKey="portal.notifications.subscription.description.4">
              tab for next steps.
            </Trans>
          </p>
          <span className="publish-date">{this.props.publishDate}</span>
        </div>
      </div>
    );
  }

}
