import React from 'react';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { uploadFile } from 'services/portal/images/images.actions';
import { updatePost, getPostById } from 'services/portal/candidate/blog/blog.actions';

import { ImageDto } from 'rest/images/image.dto';
import { PostDto } from 'rest/candidates/posts/post.dto';
import { ImageResponseDto } from 'rest/images/images.response';
import { PostsResponseDto } from 'rest/candidates/posts/posts.response.dto';

import './../modal/modal.scss';

export type Props = {
  t?: TransProps,
  imageFetching: boolean,
  createPostSuccess: boolean,
  imageInfo: ImageResponseDto,
  previewPost: PostsResponseDto,
  getPostById: (postId: string) => void,
  uploadFile: (file: File, type: string) => void,
  updatePost: (postId: string, data: PostDto) => void
}

type State = {
  title: string,
  article: string,
  postPhotos: ImageDto[],
  createPostSuccess: boolean
}

export class Settings extends Component<Props> {

  static defaultProps: Props = {
    createPost: () => {},
    getPostById: () => {},
    updatePost: () => {},
    previewPost: new PostsResponseDto()
  };

  state: State = {
    title: '',
    article: '',
    postPhotos: [],
    previewPhotos: [],
    imageSizeError: '',
    createPostSuccess: false
  };

  maxPhotosCount: number = 3;
  inputUpload = React.createRef();

}

export const stateToProps = (state: GlobalState) => ({
  imageInfo: state.portal.images.imageInfo,
  imageFetching: state.portal.images.imageLoading,
  previewPost: state.portal.candidate.blog.previewPost,
  createPostSuccess: state.portal.candidate.blog.createPostSuccess
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  updatePost,
  uploadFile,
  getPostById
}, dispatch);
