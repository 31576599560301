// @flow
import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';

import { ListDto, PaginationDto } from 'middleware/dto';
import { uniqueArray } from 'middleware/unique-array';
import { connectStore } from 'middleware/connect';

import { Spinner } from 'components/elements';
import { Button } from 'components/buttons';
import { FormGroup } from 'components/controls';

import {
  AddSoftwareModal,
  AddDocumentsModal,
  AddLanguagesModal,
  SuggestNewSkillModal
} from 'containers/portal/skills-modals';

import { SkillsDto } from 'rest/skill/skills.dto';
import { SkillItemDto } from 'rest/skill/skill-item.dto';

import type { Props } from './requirements.setting';
import { Settings, stateToProps, dispatchToProps } from './requirements.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class RequirementsTab extends Settings {

  componentDidMount () {
    const skillsPagination = new PaginationDto();
    skillsPagination.size = 5;
    skillsPagination.page = 0;
    this.props.getSkills(this.props.currentCandidate.id);
    this.fetchLanguages();
    this.props.getAllSoftware(skillsPagination);
    this.props.getAllDocuments(skillsPagination);
  }

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps !== this.state) {
      this.setState({
        documents: this.setSavedSkills(nextProps, 'documents'),
        software: this.setSavedSkills(nextProps, 'softwareSkills'),
        languages: this.setSavedSkills(nextProps, 'languageSkills').slice(0, 10)
      });
    }
    if (nextProps.redirect !== this.state.redirect) {
      this.setState({ redirect: nextProps.redirect });
      this.props.next();
    }
  }

  setSavedSkills (props: Props, type: string): SkillItemDto[] {
    if (type && props[type]) {
      const skills = [ ...this.getSavedSkills(type) ];
      return this.getCombinedSkills(props[type], skills);
    }
    return [];
  }

  fetchLanguages () {
    const languagePagination = new PaginationDto();
    languagePagination.page = 0;
    languagePagination.size = 400;
    this.props.getAllLanguages(languagePagination);
  }

  getSavedSkills (type: string): SkillItemDto[] {
    return this.props.candidateSkills[type] ? this.props.candidateSkills[type] : [];
  }

  getCombinedSkills (skills: ListDto[], initialSkills: ListDto[]): SkillItemDto[] {
    const selectedSkills = initialSkills.map(item => ({
      ...item,
      id: item.skillId,
      selected: true
    }));
    return uniqueArray([...selectedSkills, ...skills], 'id');
  }

  toggleElement (list: ListDto[], element: ListDto): ListDto[] {
    return list.map(item => {
      if (item.id === element.id) {
        return {
          ...item,
          selected: !item.selected
        };
      }
      return item;
    });
  }

  addNewElements (type: string, list: ListDto[]) {
    const selectedList = list.map(item => ({ ...item, selected: true }));
    const items = [...this.state[type], ...selectedList];
    this.setState({ [type]: uniqueArray(items, 'id') });
  }

  changeSelectElementState (type: string, element: ListDto) {
    const items = [...this.state[type]];
    this.setState({ [type]: this.toggleElement(items, element) });
  }

  filterSelectedSkills (skills: ListDto[], type): SkillItemDto[] {
    return skills.map(item => {
      if (item.selected) {
        const skillItem = new SkillItemDto(item);
        skillItem.type = type;
        skillItem.id = item.id;
        skillItem.skillId = item.id;
        skillItem.name = item.name;
        return skillItem;
      }
    }).filter(item => !!item);
  }

  openSuggestSkillModal (skillType: string) {
    this.setState({
      skillType,
      isSuggestNewSkillModalOpen: true
    });
  }

  saveSkills () {
    const skillsData = new SkillsDto();
    skillsData.professionalSkills = this.getSavedSkills('professionalSkills');
    skillsData.documents = this.filterSelectedSkills(this.state.documents, 'documents');
    skillsData.softwareSkills = this.filterSelectedSkills(this.state.software, 'softwareSkills');
    skillsData.languageSkills = this.filterSelectedSkills(this.state.languages, 'languageSkills');
    this.props.updateSkills(this.props.currentCandidate.id, skillsData);
  }

  updateSkills (event: Event) {
    event.preventDefault();
    this.saveSkills();
  }

  render () {
    return (
      <Fragment>
        <form
          className="skills-requirements-form card-toolbar mobile-skills-requirements-second-b"
          onSubmit={this.updateSkills.bind(this)}>
          <h3 className="section-title">
            <Trans i18nKey="portal.candidate.balance-board.edit.tabs.requirements.items.0">
              Documents
            </Trans>
          </h3>
          <FormGroup className="half-width documents-list">
            {
              this.state.documents.map((item, index) => (
                <Button
                  key={index}
                  onClick={() => this.changeSelectElementState('documents', item)}
                  className={`default element ${!item.selected ? 'outlined' : ''}`}
                  type="button">
                  {item.name}
                </Button>
              ))
            }
          </FormGroup>
          <FormGroup>
            <Button
              candidate
              outlined
              onClick={() => this.setState({ isAddDocumentsModalOpen: true })}
              className="add-more"
              type="button">
              <img src="/images/shared/add-icon-green.svg" alt=""/>
              <Trans i18nKey="portal.candidate.balance-board.edit.tabs.requirements.buttons.0">
                Add Other Documents
              </Trans>
            </Button>
            <Button
              onClick={() => this.openSuggestSkillModal('DOCUMENTS')}
              candidate
              outlined>
              Suggest New Document
            </Button>
          </FormGroup>
          <div className="divider"/>
          <h3 className="section-title">
            <Trans i18nKey="portal.candidate.balance-board.edit.tabs.requirements.items.1">
              Languages
            </Trans>
          </h3>
          <FormGroup className="half-width mobile-skill-list-buttons languages-list">
            {
              this.state.languages.map((item, index) => (
                <Button
                  key={index}
                  onClick={() => this.changeSelectElementState('languages', item)}
                  className={`default element ${!item.selected ? 'outlined' : ''}`}
                  type="button">
                  {item.name}
                </Button>
              ))
            }
          </FormGroup>
          <FormGroup className="half-width">
            <Button
              candidate
              outlined
              onClick={() => this.setState({ isAddLanguagesModalOpen: true })}
              className="add-more"
              type="button">
              <img src="/images/shared/add-icon-green.svg" alt=""/>
              <Trans i18nKey="portal.candidate.balance-board.edit.tabs.requirements.buttons.1">
                Add Other Language
              </Trans>
            </Button>
            <Button
              onClick={() => this.openSuggestSkillModal('LANGUAGE')}
              candidate
              outlined>
              Suggest New Language
            </Button>
          </FormGroup>
          <div className="divider"/>
          <h3 className="section-title">
            <Trans i18nKey="portal.candidate.balance-board.edit.tabs.requirements.items.2">
              Software
            </Trans>
          </h3>
          <FormGroup className="half-width mobile-skill-list-buttons software-list">
            {
              this.state.software.map((item, index) => (
                <Button
                  key={index}
                  onClick={() => this.changeSelectElementState('software', item)}
                  className={`default element ${!item.selected ? 'outlined' : ''}`}
                  type="button">
                  {item.name}
                </Button>
              ))
            }
          </FormGroup>
          <FormGroup>
            <Button
              candidate
              outlined
              onClick={() => this.setState({ isAddSoftwareModalOpen: true })}
              className="add-more"
              type="button">
              <img src="/images/shared/add-icon-green.svg" alt=""/>
              <Trans i18nKey="portal.candidate.balance-board.edit.tabs.requirements.buttons.2">
                Add Other Software
              </Trans>
            </Button>
            <Button
              onClick={() => this.openSuggestSkillModal('SOFTWARE')}
              candidate
              outlined>
              Suggest New Software
            </Button>
          </FormGroup>
          <FormGroup className="full-width form-buttons">
            <Button
              grey
              type="button"
              onClick={() => this.props.prev()}>
              <Trans i18nKey="portal.candidate.balance-board.edit.buttons.back">
                Back
              </Trans>
            </Button>
            <Button
              medium
              candidate
              type="submit">
              Save And Next
            </Button>
          </FormGroup>
        </form>
        <Spinner show={this.props.fetching}/>
        <AddLanguagesModal
          className="mobile-balance-board-skill-modal"
          candidate
          addNewItems={list => this.addNewElements('languages', list)}
          close={() => this.setState({ isAddLanguagesModalOpen: false })}
          open={this.state.isAddLanguagesModalOpen}/>
        <AddSoftwareModal
          className="mobile-balance-board-skill-modal"
          candidate
          addNewItems={list => this.addNewElements('software', list)}
          close={() => this.setState({ isAddSoftwareModalOpen: false })}
          open={this.state.isAddSoftwareModalOpen}/>
        <AddDocumentsModal
          className="mobile-balance-board-skill-modal"
          candidate
          addNewItems={list => this.addNewElements('documents', list)}
          close={() => this.setState({ isAddDocumentsModalOpen: false })}
          open={this.state.isAddDocumentsModalOpen}/>
        <SuggestNewSkillModal
          className="mobile-balance-board-suggest-modal"
          skillType={this.state.skillType}
          candidate
          close={() => this.setState({ isSuggestNewSkillModalOpen: false })}
          open={this.state.isSuggestNewSkillModalOpen}/>
      </Fragment>
    );
  }

}
