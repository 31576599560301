// @flow
import React from 'react';

import { Modal, ModalCaption } from 'containers/portal/modal-container';

import { Settings } from './premium-info-modal.setting';

export class PremiumInfoModal extends Settings {

  render () {
    return (
      <Modal
        animation="fade"
        open={this.props.open}
        className="premium-info-modal"
        hideModal={() => this.props.close()}>
        <ModalCaption
          hideModal={() => this.props.close()}
          title="Chose invitation date/time"/>
        <div className="invites-content">
          <ul className="feature-list">
            <li>Full access to all the features.</li>
            <li>Up to 10 job offers every day for a whole year.</li>
            <li>Get in contact with your favorite in seconds.</li>
            <li>Chat with them.</li>
            <li>See their CV and their job posts.</li>
            <li>Team collaboration with multi-user access.</li>
          </ul>
        </div>
        <div className="price">
          <p>
            <span className="euro-sign">&#x20AC;</span>
            <span className="price-count">250</span>/ per year
          </p>
        </div>
      </Modal>
    );
  }

}
