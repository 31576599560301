import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import './beta-intro.scss';

export type Props = {
  t?: TransProps,
  isOpen: boolean,
  closeModal: () => void
}

type State = {
  activeStep: number,
  activeTab: number
}

export class Settings extends Component<Props, State> {

  static defaultProps: Props = {
    isOpen: false,
    closeModal: () => {}
  };

  state = {
    activeStep: 1,
    activeTab: 0
  }

}

export const stateToProps = (state: GlobalState) => ({
  content: state.landing.contact.content,
  fetching: state.landing.contact.fetching
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);
