import { Component } from 'react';

import './body.scss';
import './body.responsive.scss';

type Props = {
  title: string,
  children?: Node
};

export class Setting extends Component<Props> {

  static defaultProps = {
    title: ''
  };

}
