// @flow
import { Component } from 'react';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

type Props = {
  t: TransProps,
  total: number
}

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  total: state.portal.recruiter.follow.following.totalElements
});
