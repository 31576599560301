import axios, { AxiosPromise } from 'axios';

import { CurrentRecruiterDto } from './current.dto';

export async function getCurrentRecruiter (): AxiosPromise<CurrentRecruiterDto> {
  try {
    return await axios.get('/recruiters/current');
  } catch (error) {
    throw error;
  }
}

export async function changeCurrentRecruiterLocale (lang: string): AxiosPromise<CurrentRecruiterDto> {
  try {
    return await axios.put(`/recruiters/current/locale?lang=${lang}`);
  } catch (error) {
    throw error;
  }
}

export async function disableOnBoardingVideo (): Promise {
  try {
    return await axios.put('/recruiters/current/register/full');
  } catch (error) {
    throw error;
  }
}
