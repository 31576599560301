import { ImageDto } from 'rest/images/image.dto';
import { UserAddressDto } from '../address-dto/address';

export class CandidateSocialDto {

  id: string;
  email: string;
  birthday: string;
  lastName: string;
  socialId: string;
  firstName: string;
  socialType: string;
  phoneNumber: string;
  candidatePhotos: ImageDto[];
  candidateAddress: UserAddressDto;

  constructor (data?: CandidateSocialDto) {
    if (data) {
      this.id = data.id;
      this.email = data.email;
      this.imageId = data.imageId;
      this.birthday = data.birthday;
      this.lastName = data.lastName;
      this.socialId = data.socialId;
      this.firstName = data.firstName;
      this.socialType = data.socialType;
      this.phoneNumber = data.phoneNumber;
      this.candidatePhotos = data.candidatePhotos;
      this.candidateAddress = data.candidateAddress;
    }
  }

}
