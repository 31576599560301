// @flow
import React from 'react';

import moment from 'moment';
import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';
import { getDateDuration } from 'middleware/get-duration';

import { LinkButton } from 'components/buttons';

import { EducationsDto } from 'rest/candidates/educations/educations.dto';

import { Settings, stateToProps } from './education-section.setting';

@connectStore(stateToProps, null, true)
export class EducationSection extends Settings {

  render () {
    return (
      <div className="education-section-container mobile-education-section-container">
        {
          this.props.educations.length > 0 ? this.props.educations.map((item: EducationsDto) => {
            const educationDuration: string = getDateDuration(
              item.fromDate, item.toDate || new Date(), this.props.t
            );
            return (
              <div
                key={item.id}
                className="education-item">
                <div className="left-info">
                  <p>{item.isCurrent
                    ? this.props.t('portal.candidate.cv.list.experience.now')
                    : moment(item.toDate).format('ll')} — </p>
                  <p>{moment(item.fromDate).format('ll')}</p>
                  <p className="duration">
                    {educationDuration}
                  </p>
                </div>
                <div className="right-info">
                  <h4 className="degree">{item.degree}</h4>
                  <p className="school mobile-education-school">{item.school}</p>
                </div>
              </div>
            );
          }) : <div className="empty-section">
            <h5 className="empty-section-title">
              <Trans i18nKey="portal.candidate.cv.list.education.empty">
                Education panel is empty
              </Trans>
            </h5>
            <p className="empty-section-description">
              <Trans i18nKey="portal.candidate.cv.list.education.text">
                Fill out information about your education
              </Trans>
            </p>
            <LinkButton
              outlined
              link="/portal/candidate/cv/education"
              candidate>
              <Trans i18nKey="portal.candidate.cv.list.education.add">
                Add Education
              </Trans>
            </LinkButton>
          </div>
        }
      </div>
    );
  }

}
