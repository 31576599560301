// @flow
import { Component } from 'react';

import './menu.scss';

export type Props = {
  open?: boolean,
  children?: Node,
  className: string,
  close?: () => void
};

type State = {
  menuOpen: boolean
}

export class Setting extends Component<Props, State> {

  static defaultProps = {
    open: false,
    className: '',
    close: () => {}
  };

  state: State = {
    menuOpen: this.props.open || false
  };

}
