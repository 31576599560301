import { ListDto } from 'middleware/dto/list';
import { SalaryRangeDto } from 'rest/salary-range/salary-range.dto';

export class JobRequirementsResponseDto {

  id: string;
  units: ListDto[];
  jobTypes: string[];
  branches: ListDto[];
  relocate: boolean;
  availableFrom: string;
  jobTitles: ListDto[];
  salary: SalaryRangeDto;
  lastUpdateDate: string;
  workingHoursPerMonth: number;

  constructor (data?: JobRequirementsResponseDto) {
    if (data) {
      this.id = data.id;
      this.units = data.units;
      this.salary = data.salary;
      this.jobTypes = data.jobTypes;
      this.branches = data.branches;
      this.relocate = data.relocate;
      this.jobTitles = data.jobTitles;
      this.availableFrom = data.availableFrom;
      this.lastUpdateDate = data.lastUpdateDate;
      this.workingHoursPerMonth = data.workingHoursPerMonth;
    }
  }

}
