import { Component } from 'react';

import './header.scss';
import './header.responsive.scss';

type Props = {
  title: string,
  children?: Node,
  className?: string
};

export class Setting extends Component<Props> {

  static defaultProps = {
    title: '',
    className: ''
  }

}
