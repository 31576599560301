// @flow
import React, { Component } from 'react';

import { Route, Switch } from 'react-router-dom';

import { Trans } from 'react-i18next';

import { Modal } from 'components/dialogs';
import { Card, SearchContainer } from 'components/layouts';
import { SidebarContainer, SidebarItem } from 'components/layouts/sidebar';

import { DefaultFilter, SkillsFilter } from 'containers/portal/candidate/jobs/search';

export class SearchJobsPage extends Component {

  render () {
    return (
      <Modal closePath="/portal/candidate/jobs">
        <SearchContainer className="mobile-search-container">
          <SidebarContainer
            closePath="/portal/candidate/jobs"
            className="mobile-sidebar-container">
            <SidebarItem
              path="/portal/candidate/jobs/search"
              candidate
              className="mobile-sidebar-item">
              <div className="mobile-title-text-hide">
                <Trans i18nKey="portal.candidate.job-offers.filters.default.title">
                  Default Filter
                </Trans>
              </div>
              <div className="mobile-title-text-show">
                <Trans i18nKey="portal.candidate.job-offers.filters.default.mobile.title">
                  Default
                </Trans>
              </div>
            </SidebarItem>
            <SidebarItem
              path="/portal/candidate/jobs/search/skills"
              candidate
              className="mobile-sidebar-item">
              <div className="mobile-title-text-hide">
                <Trans i18nKey="portal.candidate.job-offers.filters.skills.title">
                  Skills Filter
                </Trans>
              </div>
              <div className="mobile-title-text-show">
                <Trans i18nKey="portal.candidate.job-offers.filters.skills.mobile.title">
                  Skills
                </Trans>
              </div>
            </SidebarItem>
          </SidebarContainer>
          <Card>
            <div className="search-modal-content">
              <Switch>
                <Route
                  path="/portal/candidate/jobs/search"
                  exact
                  component={DefaultFilter}/>
                <Route
                  path="/portal/candidate/jobs/search/skills"
                  component={SkillsFilter}/>
              </Switch>
            </div>
          </Card>
        </SearchContainer>
      </Modal>
    );
  }

}
