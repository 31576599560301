// @flow
import { Component } from 'react';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

type Props = {
  t: TransProps,
  total: number
}

export class Setting extends Component<Props> {

  static defaultProps: Props = {
    total: 0
  }

}

export const stateToProps = (state: GlobalState) => ({
  total: state.portal.candidate.jobs.jobOffers.totalElements
});
