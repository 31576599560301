import React from 'react';

import { Settings } from './search-container.setting';

export class SearchContainer extends Settings {

  render () {
    return (
      <div className={`search-modal ${this.props.className}`}>
        {this.props.children}
      </div>
    );
  }

}
