// @flow
import React from 'react';

import { toast } from 'react-toastify';

import { Trans } from 'react-i18next';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { Button } from 'components/buttons';
import { CheckboxControl, FormGroup, InputControl } from 'components/controls';
import { ApplicationListDropdown } from './application-dropdown/application-dropdown';

import { Modal, ModalCaption } from 'containers/portal/modal-container';

import { PaginationDto } from 'middleware/dto/pagination';
import { ApplyJobOfferDto } from 'rest/job-offers/apply/apply.dto';
import { ApplicationResponseDto } from 'rest/candidates/applications-template/applications-template.response.dto';

import type { Props } from './apply-modal.setting';
import { dispatchToProps, Setting, stateToProps } from './apply-modal.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class ApplyModal extends Setting {

  componentWillReceiveProps (nextProps: Props) {
    const defaultTemplate: ApplicationResponseDto = nextProps.templates[0];
    if (defaultTemplate && defaultTemplate.id !== this.state.templateId) {
      this.setState({
        createNewTemplate: false,
        body: defaultTemplate.body,
        templateId: defaultTemplate.id,
        subject: defaultTemplate.subject
      });
    }
  }

  componentDidUpdate (nextProps: Props) {
    if (this.props.open !== nextProps.open) {
      const query = new PaginationDto();
      query.jobOfferId = this.props.jobOfferId;
      this.props.getCandidateApplications(this.props.currentCandidate.id, query);
    }
  }

  changeTemplateSubjectState (value: ApplicationResponseDto) {
    if (value.id !== this.state.templateId) {
      this.setState({
        body: value.body,
        templateId: value.id,
        subject: value.subject,
        createNewTemplate: false
      });
    }
  }

  createNewApplication () {
    this.clearState();
    this.setState({ createNewTemplate: true });
  }

  clearState () {
    this.setState({
      body: '',
      subject: '',
      templateId: '',
      saveNewTemplate: false
    });
  }

  closeModal () {
    this.clearState();
    this.props.hideModal();
  }

  createAndSendNewTemplate (candidateId: string) {
    const data: ApplyJobOfferDto = new ApplyJobOfferDto(this.state);
    data.jobOfferId = this.props.jobOfferId;
    this.props.createCandidateApplication(candidateId, data, true);
  }

  saveExistingTemplate (candidateId: string) {
    const defaultTemplate: ApplicationResponseDto = this.props.templates[0];
    const data: ApplyJobOfferDto = new ApplyJobOfferDto(this.state);
    data.templateId = this.state.createNewTemplate ? defaultTemplate.id : this.state.templateId;
    this.props.applyToJobOffer(candidateId, this.props.jobOfferId, data);
  }

  apply (event: Event) {
    event.preventDefault();
    const candidateId: string = this.props.currentCandidate.id;
    if (this.state.saveNewTemplate && this.state.createNewTemplate) {
      this.createAndSendNewTemplate(candidateId);
    } else {
      this.saveExistingTemplate(candidateId);
    }
    toast.success(this.props.t('portal.candidate.notifications.job-offer-applied'));
    this.closeModal();
  }

  render () {
    return (
      <Modal
        overlay
        animation="fade"
        open={this.props.open}
        className="apply-modal"
        hideModalAvailable={false}
        hideModal={() => this.closeModal()}
        closeOnOverlay={() => this.closeModal()}>
        <ModalCaption
          hideModal={() => this.closeModal()}
          title={this.props.t('portal.candidate.job-offers.apply.title')}/>
        <PerfectScrollbar className="content">
          <div className="apply-content">
            <p className="apply-description">
              <Trans i18nKey="portal.candidate.job-offers.apply.description">
                One step for job offer applying.
                Select one of the application letter options and send a letter to the recruiter.
              </Trans>
            </p>
            <form
              onSubmit={this.apply.bind(this)}
              className="apply-modal-form">
              <FormGroup>
                <ApplicationListDropdown
                  value={this.state.templateId}
                  createNewApplication={() => this.createNewApplication()}
                  label={this.props.t('portal.candidate.job-offers.apply.applications')}
                  change={value => this.changeTemplateSubjectState(value)}
                  items={this.props.templates.map(item => ({ ...item, name: item.subject }))}/>
              </FormGroup>
              <FormGroup>
                <InputControl
                  required
                  label="Subject"
                  placeholder="Add Subject"
                  value={this.state.subject}
                  change={subject => this.setState({ subject })}
                  type="text"/>
              </FormGroup>
              <FormGroup>
                <InputControl
                  label={this.props.t('portal.candidate.job-offers.apply.text.label')}
                  value={this.state.body}
                  change={body => this.setState({ body })}
                  placeholder={this.props.t('portal.candidate.job-offers.apply.text.placeholder')}
                  notes={`${this.state.body.length} / ${this.maxTemplateCharLength}`}
                  maxLength={this.maxTemplateCharLength}
                  required
                  multiline
                  errorText={this.props.t('portal.candidate.job-offers.apply.text.error')}
                  type="text"/>
                {
                  this.state.createNewTemplate && <div className="mobile-new-template-show">
                    <CheckboxControl
                      className="primary"
                      value={this.state.saveNewTemplate}
                      change={(checked: boolean) => this.setState({ saveNewTemplate: checked })}
                      label="Save letter like template"/>
                  </div>
                }
              </FormGroup>
              <FormGroup className="applications-action">
                <Button
                  type="submit"
                  medium
                  candidate>
                  <Trans i18nKey="portal.candidate.job-offers.apply.buttons.send">
                    Send
                  </Trans>
                </Button>
                <Button
                  type="submit"
                  onClick={() => this.closeModal()}
                  grey>
                  <Trans i18nKey="portal.candidate.job-offers.apply.buttons.cancel">
                    Cancel
                  </Trans>
                </Button>
                {
                  this.state.createNewTemplate && <div className="mobile-new-template-hide">
                    <CheckboxControl
                      className="primary"
                      value={this.state.saveNewTemplate}
                      change={(checked: boolean) => this.setState({ saveNewTemplate: checked })}
                      label="Save letter like template"/>
                  </div>
                }
              </FormGroup>
            </form>
          </div>
        </PerfectScrollbar>
      </Modal>
    );
  }

}
