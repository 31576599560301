// @flow
import React from 'react';

import { connectStore } from 'middleware/connect';

import { StatisticsContainer, StatisticsItem } from 'components/widgets/statistics';

import { Settings, stateToProps, dispatchToProps } from './job-offers.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class JobOffersSidebar extends Settings {

  componentDidMount () {
    this.loadJobOffersStatistic(this.props.currentUser.companyId);
  }

  componentWillUpdate (props) {
    if (!this.props.currentUser.companyId) {
      this.loadJobOffersStatistic(props.currentUser.companyId);
    }
  }

  componentWillUnmount () {
    this.props.resetJobOffersStatistic();
  }

  loadJobOffersStatistic (companyId: string) {
    if (companyId) {
      this.props.getAllCompanyJobOffersStats(companyId);
    }
  }

  render () {
    return (
      <StatisticsContainer title={this.props.t('portal.recruiter.job-offers.sidebar.title')}>
        <StatisticsItem
          title={this.props.t('portal.recruiter.job-offers.sidebar.items.all')}
          value={this.props.total}
          active={!this.props.jobOffersStatus}
          onClick={() => this.props.changeJobOffersStatus()}
          recruiter/>
        {
          this.props.stats.map((stat, index) => (
            <StatisticsItem
              key={index}
              title={`${stat.name.toLowerCase()} ${this.props.t('portal.recruiter.job-offers.sidebar.items.offers')}`}
              value={stat.count}
              active={this.props.jobOffersStatus === stat.name}
              onClick={() => this.props.changeJobOffersStatus(stat.name)}
              recruiter/>
          ))
        }
      </StatisticsContainer>
    );
  }

}
