import { Action } from 'redux';

import { JobOffersMatchesDto } from 'rest/companies/job-offers-matches/job-offers-matches.dto';

import * as actions from './job-offers.constants';

import type { JobOffersMatchesType } from './job-offers.type';

const initialState: JobOffersMatchesType = {
  content: [],
  fetching: false,
  currentPage: 0,
  hasMoreItems: true
};

export default function (state: JobOffersMatchesType = initialState, action: Action): JobOffersMatchesType {
  switch (action.type) {
    case actions.GET_ALL_CANDIDATES_MATCHES_REQUEST:
      return {
        ...state,
        fetching: true,
        hasMoreItems: false
      };

    case actions.GET_ALL_CANDIDATES_MATCHES_SUCCESS:
      return {
        ...state,
        fetching: false,
        content: [ ...state.content, ...action.payload.content.map(jobOffer => new JobOffersMatchesDto(jobOffer)) ],
        currentPage: action.payload.number + 1,
        hasMoreItems: action.payload.content.length > 0
      };

    case actions.GET_ALL_CANDIDATES_MATCHES_FAILURE:
      return {
        ...state,
        fetching: false,
        content: [],
        hasMoreItems: false
      };

    case actions.VIEW_MATCHED_CANDIDATES_SUCCESS: {
      const content = [...state.content];
      const selectedjobOfferIndex = state.content.findIndex(item => item.jobOfferId === action.jobOfferId);
      content[selectedjobOfferIndex].newCandidateCount = 0;
      return {
        ...state,
        content
      };
    }

    case actions.RESET_CANDIDATES_MATCHES_SUCCESS:
      return {
        ...initialState
      };

    default:
      return state;
  }
}
