export class UserInvoiceAddressDto {

  name: string;
  city: string;
  street: string;
  zipCode: string;
  country: string;
  streetNumber: string;

  constructor (data?: UserInvoiceAddressDto) {
    if (data) {
      this.name = data.name;
      this.city = data.city;
      this.street = data.street;
      this.zipCode = data.zipCode;
      this.country = data.country;
      this.streetNumber = data.streetNumber;
    }
  }

}
