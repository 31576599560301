import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { SkillsDto } from 'rest/skill/skills.dto';

import { getJobOfferMatchingRate } from 'services/portal/recruiter/job-offer/process/process.actions';

import './match-modal.scss';

type Props = {
  t: TransProps,
  open: boolean,
  total: number,
  className: string,
  close: () => void,
  apply: (value: number) => void,
  skills: SkillsDto,
  current: number
}

type State = {
  currentRate: number
}

export class Settings extends Component<Props, State> {

  static defaultProps: Props = {
    open: false,
    total: 0,
    close: () => {},
    apply: (value: number) => {},
    skills: new SkillsDto(),
    current: 75
  };

  state = {
    currentRate: 75,
    marks: {
      1: '1',
      10: '10',
      20: '20',
      30: '30',
      40: '40',
      50: '50',
      60: '60',
      70: '70',
      80: '80',
      90: '90',
      100: '100'
    }
  }

}

export const stateToProps = (state: GlobalState) => ({
  total: state.portal.recruiter.jobOffer.process.totalMatched,
  jobOffer: state.portal.recruiter.jobOffer.general.currentJobOffer,
  matchingRate: state.portal.recruiter.jobOffer.process.matchingRate
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getJobOfferMatchingRate
}, dispatch);
