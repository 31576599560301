// @flow
import { Component } from 'react';

import './item.scss';

type Props = {
  title?: string,
  value?: string,
  active?: boolean,
  onClick?: () => void,
  children?: any,
  candidate?: boolean,
  recruiter?: boolean,
  description?: string,
}

export class Setting extends Component<Props> {

  static defaultProps = {
    title: '',
    value: '',
    active: false,
    onClick: () => {},
    candidate: false,
    recruiter: false,
    description: null
  }

}
