// @flow
import React from 'react';
import { toast } from 'react-toastify';

import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';

import { Button } from 'components/buttons';
import { Spinner } from 'components/elements';
import { FormGroup, CheckboxControl } from 'components/controls';

import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';
import { NotificationSettingDto } from 'rest/candidates/current/notification-setting.dto';

import { Setting, stateToProps, dispatchToProps } from './notifications.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class CandidateNotificationSettings extends Setting {

  componentDidMount () {
    this.props.getCurrentCandidate();
    this.setState({ ...this.props.currentCandidate.blockNotification });
  }

  getCandidateData (): CurrentCandidateDto {
    const candidate: CurrentCandidateDto = new CurrentCandidateDto(this.props.currentCandidate);
    const currentCandidate: CurrentCandidateDto = this.props.currentCandidate;
    candidate.candidateAddress = currentCandidate.candidateAddress;
    candidate.candidatePhotos = currentCandidate.candidatePhotos;
    return candidate;
  }

  updateNotificationsSettings (event: Event) {
    event.preventDefault();
    const notifications: NotificationSettingDto = new NotificationSettingDto(this.state);
    const candidate: CurrentCandidateDto = this.getCandidateData();
    candidate.blockNotification = notifications;
    this.props.updateCurrentCandidate(this.props.currentCandidate.id, candidate);
    toast.success(this.props.t('portal.candidate.notifications.notifications-settings'));
  }

  render () {
    return (
      <div className="notifications-settings-container">
        <p className="caption">
          <Trans i18nKey="portal.candidate.profile.notifications.title">
            Email me when:
          </Trans>
        </p>
        <form
          onSubmit={(event: Event) => this.updateNotificationsSettings(event)}
          className="notification-settings-form">
          <FormGroup>
            <CheckboxControl
              className="primary"
              value={!this.state.newMessageChat}
              change={(checked: boolean) => this.setState({ newMessageChat: !checked })}
              label={this.props.t('portal.candidate.profile.notifications.chat')}/>
          </FormGroup>
          <FormGroup>
            <CheckboxControl
              className="primary"
              value={!this.state.updateFollowing}
              change={(checked: boolean) => this.setState({ updateFollowing: !checked })}
              label={this.props.t('portal.candidate.profile.notifications.follow')}/>
          </FormGroup>
          <FormGroup>
            <CheckboxControl
              className="primary"
              value={!this.state.systemUpdates}
              change={(checked: boolean) => this.setState({ systemUpdates: !checked })}
              label={this.props.t('portal.candidate.profile.notifications.system')}/>
          </FormGroup>
          <FormGroup className="notification-settings-action">
            <Button
              type="submit"
              candidate>
              <Trans i18nKey="portal.candidate.profile.buttons.save">
                Save Changes
              </Trans>
            </Button>
          </FormGroup>
        </form>
        <Spinner show={this.props.fetching}/>
      </div>
    );
  }

}
