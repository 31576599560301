import React from 'react';
import { Switch } from 'react-router-dom';

import { Route } from 'middleware/route';

import { Header } from 'containers/portal';
import { Container } from 'components/layouts';

import { ModalContainer } from 'containers/portal/modal-container';

import { GuestJobOfferPaymentPage } from './guest/job-offer/payment';
import { GuestConfirmRegistrationPage } from './guest/job-offer/confirm';

import { FreePaymentPage } from './recruiter/account/free';
import { SuccessPaymentPage } from './recruiter/account/payment';
import { FailedPaymentPage } from './recruiter/account/payment/payment-failed';
import { TimeoutPaymentPage } from './recruiter/account/payment/payment-timeout';
import { InviteRecruiterPage } from './recruiter/invite';

import { CandidateAccount } from './candidate/account';
import { RecruiterAccountPage } from './recruiter/account';

import { AskMePage } from './ask-me';
import { GuestPortal } from './guest';
import { NotFoundPage } from './../not-found';
import { CandidatePortal } from './candidate';
import { RecruiterPortal } from './recruiter';
import { Setting } from './setting';
import { CreateJobOffersPage } from './guest/job-offer';
import { SuccessBuyCandidatePage } from './recruiter/payment-per-contact';
import { QuestionsButtons } from '../../containers/landing/questions-button/questions-button';

export class PortalPage extends Setting {

  checkPage (regularExpression: RegExp) {
    return regularExpression.test(this.props.location.pathname);
  }

  render () {
    const onRegisterPage = this.props.location.pathname.includes('/portal/candidate/account');
    const checkPortal = new RegExp('portal\\/(?:candidate|recruiter|guest)\\/');
    const checkGuestPortal = new RegExp('portal\\/guest\\/');
    const onPortalPage = this.checkPage(checkPortal);
    const onGuestPortal = this.checkPage(checkGuestPortal);

    return (
      <div className="portal-container default">
        <QuestionsButtons/>
        <Header
          onRegister={onRegisterPage}
          onPortal={!onPortalPage}
          onGuestPortal={onGuestPortal}/>
        <main>
          <Container>
            <Switch>
              <Route
                path="/portal/guest"
                type="guest"
                component={GuestPortal}/>
              <Route
                exact
                path="/portal/new-job-offer"
                type="guest"
                component={CreateJobOffersPage}/>
              <Route
                exact
                type="recruiter"
                path="/portal/new-job-offer/payment"
                component={GuestJobOfferPaymentPage}/>
              <Route
                exact
                type="recruiter"
                path="/portal/new-job-offer/sign-up/confirm"
                component={GuestConfirmRegistrationPage}/>
              <Route
                exact
                path="/portal/recruiter/contact/payment/success"
                type="recruiter"
                component={SuccessBuyCandidatePage}/>
              <Route
                path="/portal/recruiter/invite/:id"
                exact
                component={InviteRecruiterPage}/>
              <Route
                exact
                path="/portal/recruiter/account/payment/success"
                component={SuccessPaymentPage}/>
              <Route
                exact
                path="/portal/recruiter/account/payment/failed"
                component={FailedPaymentPage}/>
              <Route
                exact
                path="/portal/recruiter/account/payment/timeout"
                component={TimeoutPaymentPage}/>
              <Route
                exact
                path="/portal/recruiter/account/payment/free"
                component={FreePaymentPage}/>
              <Route
                path="/portal/recruiter/account"
                exact
                component={RecruiterAccountPage}/>
              <Route
                path="/portal/candidate/account"
                exact
                component={CandidateAccount}/>
              <Route
                path="/portal/candidate"
                component={CandidatePortal}/>
              <Route
                path="/portal/recruiter"
                component={RecruiterPortal}/>
              <Route
                path="**"
                component={NotFoundPage}/>
            </Switch>
          </Container>
          <ModalContainer history={this.props.history}/>
          <Route
            path="/portal/:userType/:pageKey/ask-me"
            component={AskMePage}/>
        </main>
      </div>
    );
  }

}
