import { CandidateSkillsDto } from 'rest/candidates/skills/skills.dto';
import { JobRequirementsDto } from 'rest/candidates/job-requirements/job-requirements.dto';

export class GuestApplyDto {

  jobOfferLink: string;
  balanceBoard: JobRequirementsDto;
  candidateSkills: CandidateSkillsDto;

  constructor (data?: GuestApplyDto) {
    if (data) {
      this.balanceBoard = data.balanceBoard;
      this.jobOfferLink = data.jobOfferLink;
      this.candidateSkills = data.candidateSkills;
    }
  }

}
