// @flow
import { Component } from 'react';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

type Props = {
  t: TransProps,
  total: number
}

type State = {
  mobileView: boolean
}

export class Settings extends Component<Props, State> {

  state = {
    mobileView: false
  };

}
export const stateToProps = (state: GlobalState) => ({
  total: state.portal.chats.conversations.totalElements
});
