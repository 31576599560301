export class EducationsDto {

  school: string;
  degree: string;
  toDate: string;
  fromDate: string;
  isCurrent: boolean;

  constructor (data?: EducationsDto) {
    if (data) {
      this.school = data.school;
      this.degree = data.degree;
      this.toDate = data.toDate;
      this.fromDate = data.fromDate;
      this.isCurrent = data.isCurrent;
    }
  }

  get educationDate (): string {
    if (this.isCurrent) {
      return null;
    }
    return this.toDate;
  }

}
