// @flow
import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { List } from 'rest/list/list.dto';
import { PaginationDto } from 'middleware/dto/pagination';
import { RecruiterResponseDto } from 'rest/recruiter/recruiter.response.dto';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

import { getAllCompanyJobOffers, resetJobOffers } from 'services/portal/recruiter/account/job-offer/job-offer.actions';

import './list.scss';

export type Props = {
  status: string,
  history: History,
  fetching: boolean,
  currentPage?: number,
  hasMoreItems?: boolean,
  currentUser: RecruiterResponseDto,
  jobOffers: List<JobOffersResponseDto>,
  resetJobOffers: () => void,
  getAllCompanyJobOffers: (companyId: string, params: PaginationDto) => void
};

export class Setting extends Component<Props> {

  static defaultProps: Props = {
    fetching: false,
    getAllCompanyJobOffers: () => {},
    currentUser: new RecruiterResponseDto(),
    jobOffers: new List<JobOffersResponseDto>()
  };

}

export const stateToProps = (state: GlobalState) => ({
  status: state.portal.recruiter.account.jobOffer.status,
  fetching: state.portal.recruiter.jobOffer.process.fetching,
  jobOffers: state.portal.recruiter.account.jobOffer.jobOffers,
  currentUser: state.portal.recruiter.account.current.currentUser,
  currentPage: state.portal.recruiter.account.jobOffer.currentPage,
  hasMoreItems: state.portal.recruiter.account.jobOffer.hasMoreItems

});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetJobOffers,
  getAllCompanyJobOffers
}, dispatch);
