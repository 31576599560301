export function chatConfig (data: number[] = [], current = 1) {
  const index = current - 1;
  return {
    chart: {
      marginLeft: 50,
      marginRight: 50,
      marginTop: 50,
      marginBottom: 0,
      height: 250,
      renderTo: 'container'
    },
    tooltip: {
      useHTML: true,
      shadow: false,
      borderWidth: 0,
      borderRadius: 7,
      backgroundColor: '#535F77',
      style: {
        color: '#fff'
      },
      formatter: function () {
        return `${this.point.y} Candidate(s)`;
      }
    },
    credits: {
      enabled: false
    },
    title: {
      text: null
    },
    xAxis: {
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      labels: {
        enabled: false
      },
      title: {
        text: null
      }
    },
    yAxis: {
      labels: {
        enabled: true,
        style: {
          color: '#e5eefc',
          paddingRight: 2
        },
        formatter: function () {
          if (this.value !== 0) {
            return this.value;
          }
        }
      },
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      title: {
        text: null
      }
    },
    series: [
      {
        type: 'area',
        animation: false,
        lineWidth: 1,
        showInLegend: false,
        marker: {
          enabled: false
        },
        fillColor: {
          linearGradient: {
            x1: 1, y1: 1, x2: 0, y2: 0
          },
          stops: [
            [0, '#C5D8FA'],
            [1, '#ebf1fd']
          ]
        },
        enableMouseTracking: false,
        data: data
      },
      {
        id: 'opacity',
        animation: false,
        type: 'area',
        lineWidth: 0,
        showInLegend: false,
        marker: {
          enabled: false
        },
        color: '#fff',
        fillOpacity: 0.5,
        enableMouseTracking: false,
        data: [
          [0, 0],
          [0, Math.max(...data)], [index, Math.max(...data)], [index, 0]
        ]
      },
      {
        id: 'line',
        animation: false,
        dashStyle: 'Dash',
        lineWidth: 0,
        color: '#B7CBF0',
        type: 'line',
        showInLegend: false,
        enableMouseTracking: false,
        data: [
          [index, data[index]], [index, 0]
        ]
      },
      {
        id: 'marker',
        animation: false,
        marker: {
          enabled: true,
          radius: 3,
          fillColor: '#FFFFFF',
          lineWidth: 2,
          lineColor: '#3b83ff',
          symbol: 'circle'
        },
        showInLegend: false,
        data: [
          data[index] ? [index, data[index]] : []
        ]
      }
    ]
  };
}
