import { Component } from 'react';

import { TransProps } from 'react-i18next';

import { ApplicationResponseDto } from 'rest/candidates/applications-template/applications-template.response.dto';

import './application-row.scss';
import './application-row.responsive.scss';

type Props = {
  t: TransProps,
  fullInfo: boolean,
  onClick: () => void,
  template: ApplicationResponseDto
}
export class Setting extends Component<Props> {

  static defaultProps: Props = {
    fullInfo: false,
    onClick: () => {}
  }

}
