// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { JobOffersMatchesDto } from 'rest/companies/job-offers-matches/job-offers-matches.dto';
import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';
import { JobOffersMatchesParams } from 'rest/candidates/job-offers-matches/job-offers-matches.params';

import {
  resetNewMatchedJobOffers,
  getNewMatchedJobOffersList,
  resetNewMatchedJobOffersBubble
} from 'services/portal/candidate/match/new-job-offers/new-job-offers.actions';

type Props = {
  t?: TransProps,
  total: number,
  history: History,
  jobOffers: JobOffersMatchesDto[],
  currentUser: CurrentCandidateDto,
  resetNewMatchedJobOffers: () => void,
  getNewMatchedJobOffersList: (candidateId: string, params?: JobOffersMatchesParams) => void,
  resetNewMatchedJobOffersBubble: () => void
}

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  jobOffers: state.portal.candidate.match.newJobOffers.content,
  total: state.portal.candidate.match.newJobOffers.totalElements,
  currentUser: state.portal.candidate.account.current.candidateData
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetNewMatchedJobOffers,
  getNewMatchedJobOffersList,
  resetNewMatchedJobOffersBubble
}, dispatch);
