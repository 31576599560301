import React from 'react';

import { Trans } from 'react-i18next';

import { LinkButton } from 'components/buttons';

import { findEvent } from 'middleware/notifications/find-event';
import { connectTranslation } from 'middleware/connect';

import { Setting } from './chat-create.setting';
import * as eventNames from './chat-create.constants';

@connectTranslation()
export class ChatCreateTemplate extends Setting {

  hideModal = () => {
    this.props.hideModal();
  };

  render () {
    const { eventData, publishDate } = this.props;
    return (
      <div className="notification-item-content flexible">
        <div className="logo-container">
          <img src="/images/notifications/userpick.svg" alt="logo"/>
        </div>
        <div className="content">
          <h3>
            <Trans i18nKey="portal.notifications.chat.title">
              You`ve got a new chat
            </Trans>
          </h3>
          {
            eventData.length && <p>
              <Trans i18nKey="portal.notifications.chat.description.0">
                Check out who`s interested in talking to you
              </Trans>
              <LinkButton
                inline
                onClick={this.hideModal}
                link={`/portal/candidate/chat/${findEvent(eventData, eventNames.CHAT_ID)}`}>
                <Trans i18nKey="portal.notifications.chat.description.1">
                  here
                </Trans>
              </LinkButton>
            </p>
          }
          <span className="publish-date">{publishDate}</span>
        </div>
      </div>
    );
  }

}
