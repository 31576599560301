import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import { Trans, TransProps } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { LinkButton } from 'components/buttons';
import { PortletContainer, PortletHeader, PortletBody } from 'components/portlet';

import { JobOfferList } from 'containers/portal/recruiter/job-offer';

type Props = {
  t: TransProps
}
@connectTranslation()
export class JobOffersListPage extends Component<Props> {

  render () {
    return (
      <PortletContainer>
        <PortletHeader title={this.props.t('portal.recruiter.job-offers.list.title')}>
          <LinkButton
            link="/portal/recruiter/balance-board/create"
            recruiter>
            <Trans i18nKey="portal.recruiter.job-offers.list.buttons.create">
              Create New Offer
            </Trans>
          </LinkButton>
        </PortletHeader>
        <PortletBody>
          <Route
            path="/portal/recruiter/job-offers"
            component={JobOfferList}/>
        </PortletBody>
      </PortletContainer>
    );
  }

}
