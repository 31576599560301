// @flow
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { TransProps } from 'react-i18next';

import { connectStore } from 'middleware/connect';

import { PortletContainer, PortletHeader, PortletBody } from 'components/portlet';

import { FavoriteJobs, FavoritesFilters } from 'containers/portal/candidate/jobs';

import { stateToProps } from './index.setting';

type Props = {
  t: TransProps,
  total: number
}

@connectStore(stateToProps, false, true)
export class FavoritesListPage extends Component<Props> {

  render () {
    return (
      <PortletContainer>
        <PortletHeader
          className="mobile-favorite-offers"
          title={this.props.t('portal.candidate.favorites.list.title')}>
          <div className="mobile-favorite-offer-total">({this.props.total})</div>
          <FavoritesFilters/>
        </PortletHeader>
        <PortletBody className="mobile-portlet-list">
          <Route
            path="/portal/candidate/favorites"
            component={FavoriteJobs}/>
        </PortletBody>
      </PortletContainer>
    );
  }

}
