// @flow
import { AxiosResponse } from 'axios';
import { Action, Dispatch } from 'redux';

import { AddressDto } from 'rest/address/address.dto';
import { AddressResponseDto } from 'rest/address/address.response.dto';

import { getSuggestedAddress } from 'rest/address/address.rest';

import * as actions from './address.constants';

const getAddressRequest = (): Action => ({
  type: actions.GET_SUGGEST_ADDRESS_REQUEST
});

const getAddressSuccess = (field: string, address: AddressResponseDto[]): Action => ({
  type: actions.GET_SUGGEST_ADDRESS_SUCCESS,
  address,
  field
});

const getAddressFailure = (): Action => ({
  type: actions.GET_SUGGEST_ADDRESS_FAILURE
});

const resetAddressSuccess = (): Action => ({
  type: actions.RESET_SUGGEST_ADDRESS_SUCCESS
});

export function resetAddressContent () {
  return (dispatch: Dispatch) => {
    dispatch(resetAddressSuccess());
  };
}

export function searchSuggestedAddress (field: string, filters: AddressDto) {
  return (dispatch: Dispatch) => {
    dispatch(getAddressRequest());
    return getSuggestedAddress(filters)
      .then((response: AxiosResponse<AddressResponseDto[]>) => dispatch(getAddressSuccess(field, response.data)))
      .catch(() => dispatch(getAddressFailure()));
  };
}
