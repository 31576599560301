import { UserAddressDto } from 'rest/address-dto/address';
import { SalaryRangeDto } from 'rest/salary-range/salary-range.dto';

export class JobOffersMatchesDto {

  isNew: boolean;
  jobType: string;
  favorite: boolean;
  jobOfferId: number;
  branchName: string;
  updatedDate: string;
  salaryRange: SalaryRangeDto;
  companyName: string;
  jobTitleName: string;
  publishedDate: string;
  companyAddress: UserAddressDto;
  jobOfferMatchPoints: number;
  matchedCandidatesCount: number;

  constructor (data?: JobOffersMatchesDto) {
    if (data) {
      this.isNew = data.isNew;
      this.jobType = data.jobType;
      this.favorite = data.favorite;
      this.jobOfferId = data.jobOfferId;
      this.branchName = data.branchName;
      this.updatedDate = data.updatedDate;
      this.salaryRange = new SalaryRangeDto(data.salaryRange);
      this.companyName = data.companyName;
      this.jobTitleName = data.jobTitleName;
      this.publishedDate = data.publishedDate;
      this.companyAddress = new UserAddressDto(data.companyAddress);
      this.jobOfferMatchPoints = data.jobOfferMatchPoints;
      this.matchedCandidatesCount = data.matchedCandidatesCount;
    }
  }

}
