// @flow
import axios, { AxiosPromise } from 'axios';

import { EducationsDto } from './educations.dto';

export async function getEducations (candidateId: string): AxiosPromise<EducationsDto[]> {
  try {
    return await axios.get(`/candidates/${candidateId}/educations`);
  } catch (error) {
    throw error;
  }
}

export async function updateEducation (candidateId: string, educationId: string, data: EducationsDto) {
  try {
    return await axios.put(`/candidates/${candidateId}/educations/${educationId}`, data);
  } catch (error) {
    throw error;
  }
}

export async function createEducation (candidateId: string, data: EducationsDto) {
  try {
    return await axios.post(`/candidates/${candidateId}/educations`, data);
  } catch (error) {
    throw error;
  }
}

export async function deleteEducation (candidateId: string, educationId: string) {
  try {
    return await axios.delete(`/candidates/${candidateId}/educations/${educationId}`);
  } catch (error) {
    throw error;
  }
}
