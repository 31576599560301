import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { CandidateDto } from 'rest/candidates/candidate.dto';
import { SkillsContainerDto } from 'rest/skills/skills-container.dto';
import { PaymentRedirectUrlsDto } from 'rest/companies/payment-redirect-urls.dto';

import {
  buyCandidateDetails,
  changeCurrentCandidate
} from 'services/portal/recruiter/candidate/all/candidates.actions';

import {
  addingCandidateToFollowing,
  deletingCandidateFromFollowing
} from 'services/portal/recruiter/follow/following/following.actions';

import {
  addingCandidateToFavorite,
  deletingCandidateFromFavorite
} from 'services/portal/recruiter/favorites/favorites.actions';
import { setCandidatePreviewLink } from 'services/portal/recruiter/account/current/current.actions';

import { contactAnonymous } from 'services/portal/candidate/anonymous/anonymous.actions';
import { getCandidateCvLink } from 'services/portal/candidate/cv/pdf/pdf.actions';
import { getCandidateJobTitle } from 'services/portal/candidate/cv/job-title/job-title.actions';
import { getCandidateSkillsById } from 'services/portal/candidate/skills/skills.actions';

import { createChatConversation } from 'services/portal/chats/conversations/conversations.actions';

import './profile.scss';

export type Props = {
  t: TransProps,
  buyLink: string,
  restricted: boolean,
  recruiterId: string,
  candidate: CandidateDto,
  skills: SkillsContainerDto,
  getCandidateSkills: () => void,
  contactAnonymous: (candidateId: string) => void,
  buyCandidate: (candidateId: string, companyId: string, redirectUrls: PaymentRedirectUrlsDto) => void
};

export type State = {
  inviteModal: boolean,
  paymentRedirectUrls: PaymentRedirectUrlsDto
}

export class Setting extends Component<Props, State> {

  static defaultProps = {
    restricted: false,
    candidate: new CandidateDto(),
    getCandidateSkills: () => {},
    contactAnonymous: () => {},
    buyCandidate: () => {}
  };

  state: State = {
    inviteModal: false,
    paymentRedirectUrls: new PaymentRedirectUrlsDto('/portal/recruiter/favorites/purchased')
  };

}

export const stateToProps = (state: GlobalState) => ({
  skills: state.portal.candidate.skills.content,
  cvData: state.portal.candidate.cv.jobTitle.cvData,
  previewLink: state.portal.candidate.cv.pdf.previewLink,
  company: state.portal.recruiter.account.companies.company,
  chatId: state.portal.chats.conversations.createdConversation,
  buyLink: state.portal.recruiter.candidate.allCandidates.buyLink,
  currentUser: state.portal.recruiter.account.current.currentUser,
  recruiterId: state.portal.recruiter.account.current.currentUser.id
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  contactAnonymous,
  getCandidateJobTitle,
  setCandidatePreviewLink,
  getCvLink: getCandidateCvLink,
  buyCandidate: buyCandidateDetails,
  startChat: createChatConversation,
  addToFollow: addingCandidateToFollowing,
  updateCandidate: changeCurrentCandidate,
  addToFavorite: addingCandidateToFavorite,
  getCandidateSkills: getCandidateSkillsById,
  deleteFromFollow: deletingCandidateFromFollowing,
  deleteFromFavorite: deletingCandidateFromFavorite
}, dispatch);
