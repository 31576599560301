import * as actions from './languages.constants';

import { ListDto } from 'middleware/dto/list';
import { PaginationDto } from 'middleware/dto';

import { getLanguages } from 'rest/languages/languages.rest';

const getLanguagesRequest = () => ({
  type: actions.GET_LANGUAGES_REQUEST
});

const getLanguagesSuccess = (languages: ListDto[]) => ({
  type: actions.GET_LANGUAGES_SUCCESS,
  languages
});

const getLanguagesFailure = (error: string) => ({
  type: actions.GET_LANGUAGES_FAILURE,
  error
});

export const getAllLanguages = (params: PaginationDto) => {
  return dispatch => {
    dispatch(getLanguagesRequest());
    return getLanguages(params)
      .then(response => dispatch(getLanguagesSuccess(response.data)))
      .catch(error => dispatch(getLanguagesFailure(error)));
  };
};
