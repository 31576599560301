// @flow
import React from 'react';
import { Trans } from 'react-i18next';

import { Modal } from 'containers/portal/modal-container';
import { IbanForm } from './iban-form';

import { connectStore } from 'middleware/connect';
import { Settings, dispatchToProps, stateToProps } from './sepa-payment-modal.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class SepaPaymentModal extends Settings {

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = e => {
    const sepaModal = document.querySelector('.sepa-payment-modal .modal-content');

    if (sepaModal && !sepaModal.contains(e.target)) {
      this.props.closeModal();
    }
  };

  render () {
    const { isOpen, closeModal } = this.props;

    return (
      <Modal
        overlay
        animation="fade"
        open={isOpen}
        hideModalAvailable={false}
        hideModal={closeModal}
        closeOnOverlay={closeModal}
        className="contact-us-modal-container sepa-payment-modal">

        <h3 className="sepa-payment-modal-title">
          <Trans i18nKey="portal.guest.recruiter.job-offer.payment.payment-method.sepa.title">
            SEPA Direct Debit System
          </Trans>
        </h3>
        <p className="sepa-payment-modal-subtitle">
          <Trans i18nKey="portal.guest.recruiter.job-offer.payment.payment-method.sepa.subtitle">
            During the payment process, your integration collects your customer’s EUR-denominated
            IBAN bank account information.
          </Trans>
        </p>

        <IbanForm {...this.props}/>

        <p className="sepa-payment-modal-policy">
          <Trans i18nKey="portal.guest.recruiter.job-offer.payment.payment-method.sepa.policy">
            By providing your IBAN and confirming this payment, you are authorizing Excellent Jobs yearly fee and
            Stripe, our payment service provider, to send instructions to your bank to debit your account and your bank
            to debit your account in accordance with those instructions.
          </Trans>
        </p>
      </Modal>
    );
  }

}
