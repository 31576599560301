/* eslint padded-blocks: ["error", "never"] */
/* eslint object-curly-spacing: ["off"] */
/* eslint no-unused-vars: ["off"] */
/* eslint no-mixed-operators: ["off"] */
import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import get from 'lodash/get';
import '../styles/semantic.min.css';
import '../styles/styles.css';
import { getUserId } from 'middleware/auth';
export class GDPRCandidateInvite extends Component {
  constructor() {
    super();
    this.state = {
      candidate_id: getUserId(),
      invites: []
    };
  }
  componentDidMount() {
    axios(`/candidates/${this.state.candidate_id}/all-invites`)
      .then(async ({data}) => {
        let jobUrls = data.map(x => `public/job-offers?jobTitleId=${x.jobTitleId}`);
        let jobs = await Promise.all(jobUrls.map(x => axios(x).then(({data}) => data.content[0])));
        data = data.map(x => {
          x.job = jobs.find(j => x.jobTitleId === j.jobTitleId);
          return x;
        });
        this.setState({
          invites: data ? (data || []) : []
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  render () {
    return (
      <div className="gdpr-main">
        <div className="ui container main-content">
          <h1 className="ui header main-title"> Candidate´s invites </h1>
          <a href="/information/gdpr">
            <button className="ui button back-link">Back To Main</button>
          </a>
          <h5 className="ui header"> Total Elements:  {(this.state.invites || []).length}</h5>
          <table className="ui single red line table">
            <thead>
              <tr>
                <th> Job Title Name </th>
                <th> Invite Status </th>
                <th> Selected Date </th>
                <th> Is Archived </th>
                <th> Company Name </th>
              </tr>
            </thead>
            <tbody>
              {this.state.invites.map(row => (
                <tr key={row.id}>
                  <td>{get(row, 'job.jobTitleName')}</td>
                  <td>{get(row, 'candidateInviteStatus')}</td>
                  <td>{moment(row.updatedDate).format('DD.MM.YYYY HH:mm')}</td>
                  <td>{get(row, 'archived') && 'True' || 'False'}</td>
                  <td>{get(row, 'job.companyName')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
