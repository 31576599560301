// @flow

import React from 'react';

import { Setting } from './vdk-partners.settings';

export class VdkPartners extends Setting {

  render() {
    return (
      <div className="container">
        <div className="vdk-container">
          <div className="vdk-information-container">
            <img
              src="./images/plans/VKD_Logo.jpg"
              alt="VDK"
              title="VDK"
              className="logo" />
            <div className="vdk-information">
              <h2>Wir sind Kooperationspartner des VKD</h2>
              <p>Und darum gibt es was zu gewinnen, schau
                Dir unser kleines Video an und mache mit</p>
            </div>
          </div>
          <div className="vdk-video-container">
            <div className="vdk-video">
              <video controls>
                {/* TODO add support for CDN */}
                <source src="https://beta-ej-media.s3.eu-central-1.amazonaws.com/vkd.mp4" type="video/mp4"/>
              </video>
            </div>
          </div>
        </div>
      </div>
    );
  }

}
