import { Component } from 'react';
import { bindActionCreators } from 'redux';

import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { getAllUnits } from 'services/portal/units/units.actions';
import { getAllBranches } from 'services/portal/recruiter/account/companies/companies.actions';
import { initJobsFilter } from 'services/portal/candidate/jobs/job-offers/job-offers.actions';
import { searchSkills, resetSearchResult } from 'services/portal/skills/skills.actions';

import {
  saveJobsSkillsFilter,
  clearJobsSearchSkills
} from 'services/portal/candidate/jobs/search/search.actions';

import './skills-filter.scss';
import './skills-filter.responsive.scss';

type Props = {
  t: TransProps,
  currentPage: number
}

type State = {
  query: string,
  unitId: number,
  typing: boolean,
  branchId: number,
  typingTimeout: number,
  selectedItems: string[]
}

export class Settings extends Component<Props, State> {

  state = {
    query: '',
    unitId: null,
    typing: false,
    branchId: null,
    typingTimeout: 0,
    selectedItems: []
  }

}

export const stateToProps = (state: GlobalState) => ({
  units: state.portal.units.unitsList,
  skills: state.portal.skills.searchSkills,
  hasMoreItems: state.portal.skills.hasMoreItems,
  currentPage: state.portal.skills.currentPage,
  branches: state.portal.recruiter.account.companies.branches,
  searchJobOffers: state.portal.candidate.jobs.searchJobOffers,
  jobOffersFilter: state.portal.candidate.jobs.jobOffers.jobOffersFilter
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  searchSkills,
  getAllUnits,
  getAllBranches,
  resetSearchResult,
  initJobsFilter,
  saveJobsSkillsFilter,
  clearJobsSearchSkills
}, dispatch);
