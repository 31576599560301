// @flow
import { Component } from 'react';

import './container.scss';
import './container.responsive.scss';

type Props = {
  title?: string,
  children: any,
  className?: string
}

export class Setting extends Component<Props> {

  static defaultProps = {
    title: '',
    className: ''
  }

}
