// @flow
import { Component } from 'react';

import './infobox.scss';
import './infobox.responsive.scss';

type State = {
  isOpen: boolean
}

export class Setting extends Component<null, State> {

  state = {
    isOpen: false
  };

}
