// @flow
import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';

import type { Match } from 'common/types/match';
import type { GlobalState } from 'common/types/global-state';

import { finishResetPassword } from 'services/auth/recruiter/auth.action';

import { FinishResetPasswordDto } from 'rest/account/reset-password/finish/finish.dto';

import './confirm-reset-password-form.scss';
import './confirm-reset-password-form.responsive.scss';

export type Props = {
  match?: Match,
  buttonType?: string,
  redirect?: boolean,
  fetching?: boolean,
  errorText?: string,
  redirectUrl?: boolean,
  finishResetPassword?: (data: FinishResetPasswordDto) => void
}

type State = {
  redirect: boolean,
  errorText: string,
  newPassword: string,
  errorCollection: Set<string>,
  confirmNewPassword: string
}

export class Settings extends Component<Props, State> {

  static defaultProps = {
    buttonType: 'primary'
  };

  state: State = {
    hasError: false,
    redirect: this.props.redirect,
    errorText: this.props.errorText,
    newPassword: '',
    errorCollection: new Set(),
    confirmNewPassword: ''
  }

}

export const stateToProps = (state: GlobalState) => ({
  errorText: state.auth.reset.errorText,
  fetching: state.auth.reset.fetching,
  redirect: state.auth.reset.confirmResetPasswordSuccess
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  finishResetPassword
}, dispatch);
