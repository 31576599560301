import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import { EditJobOfferModal } from 'containers/portal/recruiter/job-offer';

export class EditJobOfferModalPage extends Component {

  render () {
    return (
      <Route
        path="/portal/recruiter/:path/:jobOfferId/:type"
        component={EditJobOfferModal}/>
    );
  }

}
