import axios, { AxiosPromise } from 'axios';
import { SkillsDto } from 'rest/skill/skills.dto';

export async function getJobOfferSkills (jobOfferId: string): AxiosPromise<SkillsDto> {
  try {
    return await axios.get(`/job-offers/${jobOfferId}/skills`);
  } catch (error) {
    throw error;
  }
}

export async function updateJobOfferSkills (jobOfferId: string, data: SkillsDto) {
  try {
    return await axios.put(`/job-offers/${jobOfferId}/skills`, data);
  } catch (error) {
    throw error;
  }
}

export async function createJobOfferSkills (jobOfferId: string, data: SkillsDto) {
  try {
    return await axios.post(`/job-offers/${jobOfferId}/skills`, data);
  } catch (error) {
    throw error;
  }
}
