// @flow
import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import type { RouterHistory } from 'react-router-dom';

import type { GlobalState } from 'common/types/global-state';

import { PaginationDto } from 'middleware/dto/pagination';
import { JobOffersMatchesDto } from 'rest/companies/job-offers-matches/job-offers-matches.dto';

import {
  resetMatchedJobOffers,
  getMatchedJobOffersList
} from 'services/portal/recruiter/match/job-offers/job-offers.actions';

import './job-offers-list.scss';

type Props = {
  history: RouterHistory,
  fetching: boolean,
  jobOffers: JobOffersMatchesDto[],
  companyId: string,
  currentPage: number,
  hasMoreItems: boolean,
  getMatchedJobOffers: (params: PaginationDto) => void,
  resetMatchedJobOffers: () => void,
};

export class Setting extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  fetching: state.portal.recruiter.match.jobOffers.fetching,
  jobOffers: state.portal.recruiter.match.jobOffers.content,
  companyId: state.portal.recruiter.account.current.currentUser.companyId,
  currentPage: state.portal.recruiter.match.jobOffers.currentPage,
  hasMoreItems: state.portal.recruiter.match.jobOffers.hasMoreItems
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getMatchedJobOffers: getMatchedJobOffersList,
  resetMatchedJobOffers
}, dispatch);
