// @flow
import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';

import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';

import { connectStore } from 'middleware/connect';

import { Tabs, Tab } from 'components/tabs';
import { SkillsList } from 'components/elements/skills-list/skills-list';
import { IconButton, LinkButton, Button } from 'components/buttons';
import { ModalMainColumn, ModalAsideColumn } from 'components/layouts/modal';

import { WorkExperience } from './work-experience/work-experience';
import { PersonalSection } from './personal-section/personal-section';
import { PreviewCandidateInfo } from './candidate-info/candidate-info';
import { InviteCandidateModal } from 'containers/portal/recruiter/calendar';
import { CandidateBalanceBoardInfo } from './balance-board-info/balance-board-info';

import { CandidateDto } from 'rest/candidates/candidate.dto';
import { ChatRequestDto } from 'rest/chats/chat.request.dto';
import { RecruiterFollowParams } from 'rest/recruiter/follow/follow.params';
import { RecruiterFavoriteParams } from 'rest/recruiter/favorites/favorite.params';

import type { Props } from './profile.setting';
import { Setting, dispatchToProps, stateToProps } from './profile.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class CandidateProfile extends Setting {

  componentDidMount (): void {
    if (this.props.candidate.id && this.props.company) {
      this.getBuyCandidateLink();
    } else {
      setTimeout(() => this.checkBuyCandidateLink(), 1000);
    }
  }

  componentDidUpdate (prevProps: Props) {
    const candidateId: string = this.props.candidate.id;
    if (candidateId && prevProps.candidate.id !== candidateId) {
      this.props.getCandidateSkills(candidateId);
      this.props.getCvLink(candidateId, true);
      this.props.getCandidateJobTitle(candidateId);
      this.getBuyCandidateLink();
    }
  }

  checkBuyCandidateLink () {
    if (this.props.candidate.id && this.props.company) {
      this.getBuyCandidateLink();
    } else {
      setTimeout(() => this.getBuyCandidateLink(), 1000);
    }
  }

  getBuyCandidateLink () {
    const company = this.props.company;
    if (company && !company.isPremiumActive) {
      this.props.buyCandidate(this.props.company.id, this.props.candidate.id, this.state.paymentRedirectUrls);
    }
  }

  favoriteCandidateToggle (candidate: CandidateDto) {
    const params = new RecruiterFavoriteParams();
    params.recruiterId = this.props.recruiterId;
    params.candidateId = candidate.id;
    candidate.favorite ? this.props.deleteFromFavorite(params) : this.props.addToFavorite(params);
    candidate.favorite = !candidate.favorite;
    this.props.updateCandidate(candidate);
    toast.success(candidate.favorite
      ? this.props.t('portal.recruiter.notifications.favorites-add')
      : this.props.t('portal.recruiter.notifications.favorites-delete')
    );
  }

  followCandidateToggle (candidate: CandidateDto) {
    const params: RecruiterFollowParams = new RecruiterFollowParams();
    params.recruiterId = this.props.recruiterId;
    params.candidateId = candidate.id;
    candidate.follow ? this.props.deleteFromFollow(params) : this.props.addToFollow(params);
    candidate.follow = !candidate.follow;
    this.props.updateCandidate(candidate);
    toast.success(candidate.follow
      ? this.props.t('portal.recruiter.notifications.following-add')
      : this.props.t('portal.recruiter.notifications.following-delete')
    );
  }

  startCandidateChat (candidateId: string) {
    const chat: ChatRequestDto = new ChatRequestDto();
    chat.candidateId = candidateId;
    chat.recruiterId = this.props.recruiterId;
    this.props.startChat(chat);
    toast.success(this.props.t('portal.recruiter.notifications.chat-created'));
  }

  setCandidateRedirectProfileLink () {
    const link: string = `/portal/recruiter/candidates/${this.props.candidate.id}/profile`;
    this.props.setCandidatePreviewLink(link);
  }

  contactAnonymous = () => {
    const { candidate, contactAnonymous } = this.props;
    contactAnonymous(candidate.id);
  };

  render () {
    const candidate: CandidateDto = this.props.candidate;
    return (
      <Fragment>
        {this.props.chatId && <Redirect to={`/portal/recruiter/chat/${this.props.chatId}`}/>}
        <ModalMainColumn>
          {
            !candidate.anonymous && <CandidateBalanceBoardInfo
              cv={this.props.cvData}
              restricted={this.props.restricted}
              candidate={candidate}/>
          }
          <div className="full-info-container">
            <Tabs>
              <Tab label={this.props.t('portal.recruiter.candidates.profile.tabs.competencies')}>
                {
                  this.props.skills.emptySkills ? null : <div className="competencies-wrapper">
                    <SkillsList skills={this.props.skills}/>
                  </div>
                }
              </Tab>
              <Tab
                disabled={candidate.anonymous}
                label={this.props.t('portal.recruiter.candidates.profile.tabs.experience')}>
                <WorkExperience candidateId={this.props.candidate.id}/>
              </Tab>
              <Tab
                disabled={candidate.anonymous}
                label={this.props.t('portal.recruiter.candidates.profile.tabs.personal')}>
                <PersonalSection candidateId={this.props.candidate.id}/>
              </Tab>
            </Tabs>
          </div>
        </ModalMainColumn>
        <ModalAsideColumn>
          {
            this.props.restricted && !candidate.anonymous && <div className="actions-buttons">
              {
                this.props.recruiterId ? <LinkButton
                  disabled={!this.props.buyLink}
                  link={this.props.buyLink}
                  className="full-width"
                  recruiter
                  external={true}>
                  <Trans i18nKey="portal.recruiter.candidates.profile.buttons.info">
                    Get Candidate Info
                  </Trans>
                </LinkButton> : <LinkButton
                  link="/auth/recruiter/sign-up"
                  recruiter
                  onClick={() => this.setCandidateRedirectProfileLink()}
                  className="invite-button">
                  <Trans i18nKey="portal.recruiter.candidates.profile.buttons.info">
                    Get Candidate Info
                  </Trans>
                </LinkButton>
              }
              <IconButton
                outlined
                recruiter
                title={<span>
                  <Trans i18nKey="portal.recruiter.candidates.profile.not-available">
                    Not available on Your plan
                  </Trans>
                </span>}
                icon="icon-favorites-off"/>
            </div>
          }
          {
            candidate.anonymous && <div className="actions-buttons">
              <Button
                className="invite-button"
                recruiter
                disabled={candidate.requestedContacts}
                onClick={this.contactAnonymous}>
                <Trans i18nKey={
                  `portal.recruiter.candidates.profile.buttons.${candidate.requestedContacts ? 'requested' : 'request'
                  }`}>
                  Request data
                </Trans>
              </Button>
            </div>
          }
          <PreviewCandidateInfo
            restricted={this.props.restricted}
            inviteAction={() => this.setState({ inviteModal: true })}
            followToggle={candidate => this.followCandidateToggle(candidate)}
            favoritesToggle={candidate => this.favoriteCandidateToggle(candidate)}
            startCandidateChat={(candidateId: string) => this.startCandidateChat(candidateId)}
            cvLink={this.props.previewLink}
            candidate={candidate}/>
        </ModalAsideColumn>
        <InviteCandidateModal
          open={this.state.inviteModal}
          close={() => this.setState({ inviteModal: false })}
          candidate={this.props.candidate}/>
      </Fragment>
    );
  }

}
