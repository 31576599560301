import { Component } from 'react';
import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';
import { JobRequirementsResponseDto } from 'rest/candidates/job-requirements/job-requirements.response.dto';

import {
  resetBalanceBoard,
  getCandidateBalanceBoard
} from 'services/portal/candidate/balance-board/balance-board.actions';

import './poster.scss';
import './poster.responsive.scss';

export type Props = {
  t: TransProps,
  balanceBoard: JobRequirementsResponseDto,
  currentCandidate: CurrentCandidateDto,
  resetBalanceBoard: () => void,
  getCandidateBalanceBoard: (candidateId: string) => void
};

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  balanceBoard: state.portal.candidate.balanceBoard.balanceBoard,
  currentCandidate: state.portal.candidate.account.current.candidateData
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetBalanceBoard,
  getCandidateBalanceBoard
}, dispatch);
