import React from 'react';

import {
  AdminTemplate,
  AskMeTemplate,
  ChatCreateTemplate,
  RequestSkillsTemplate,
  JobOfferCreateTemplate,
  JobOfferUpdateTemplate,
  CandidateInviteTemplate,
  PaymentExpirationTemplate,
  AnonymousCandidateTemplate,
  AnonymousRecruiterTemplate,
  NewMatchedCandidateTemplate, AddToSystemTemplate
} from './../templates';

import * as eventTypes from './list.constants';

import { Setting } from './list.setting';

export class NotificationList extends Setting {

  closeModal = () => {
    this.props.closeModal();
  };

  render () {
    const { notification, publishDate } = this.props;
    switch (notification.eventType) {
      case eventTypes.ADMIN_NOTIFICATION:
        return <AdminTemplate
          eventData={notification.eventData}
          publishDate={publishDate}/>;
      case eventTypes.SUBSCRIPTION_PLAN_EXPIRES:
        return <PaymentExpirationTemplate publishDate={publishDate}/>;
      case eventTypes.ASK_ME_CONTENT:
        return <AskMeTemplate
          hideModal={this.closeModal}
          eventData={notification.eventData}
          publishDate={publishDate}/>;
      case eventTypes.REQUEST_SKILL_DECISION:
        return <RequestSkillsTemplate
          eventData={notification.eventData}
          hideModal={this.closeModal}
          publishDate={publishDate}/>;
      case eventTypes.ANONYMOUS_CANDIDATE_REQUEST:
        return <AnonymousCandidateTemplate
          request
          publishDate={publishDate}
          alertId={notification.alertId}
          eventData={notification.eventData}/>;
      case eventTypes.ANONYMOUS_CANDIDATE_ACCEPTED:
        return <AnonymousCandidateTemplate
          accepted
          publishDate={publishDate}
          eventData={notification.eventData}/>;
      case eventTypes.ANONYMOUS_CANDIDATE_DECLINED:
        return <AnonymousCandidateTemplate
          publishDate={publishDate}
          eventData={notification.eventData}/>;
      case eventTypes.ANONYMOUS_RECRUITER_ACCEPTED:
        return <AnonymousRecruiterTemplate
          accepted
          publishDate={publishDate}
          eventData={notification.eventData}/>;
      case eventTypes.ANONYMOUS_RECRUITER_DECLINED:
        return <AnonymousRecruiterTemplate
          eventData={notification.eventData}
          publishDate={publishDate}/>;
      case eventTypes.CHAT_CREATE:
        return <ChatCreateTemplate
          eventData={notification.eventData}
          hideModal={this.closeModal}
          publishDate={publishDate}/>;
      case eventTypes.CANDIDATE_INVITE:
        return <CandidateInviteTemplate
          eventData={notification.eventData}
          hideModal={this.closeModal}
          publishDate={publishDate}/>;
      case eventTypes.JOB_OFFER_CREATE:
        return <JobOfferCreateTemplate
          eventData={notification.eventData}
          hideModal={this.closeModal}
          publishDate={publishDate}/>;
      case eventTypes.JOB_OFFER_UPDATE:
        return <JobOfferUpdateTemplate
          eventData={notification.eventData}
          publishDate={publishDate}
          hideModal={this.closeModal}/>;
      case eventTypes.NEW_MATCHED_CANDIDATE:
        return <NewMatchedCandidateTemplate
          eventData={notification.eventData}
          hideModal={this.closeModal}
          publishDate={publishDate}/>;
      case eventTypes.ADD_NEW_SKILL_TO_SYSTEM:
        return <AddToSystemTemplate
          eventData={notification.eventData}
          hideModal={this.closeModal}
          publishDate={publishDate}/>;
      default:
        return null;
    }
  }

}
