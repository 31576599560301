import React from 'react';
import { NavLink } from 'react-router-dom';

import moment from 'moment';
import { Trans } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { InputControl } from 'components/controls';

import { ListPlaceholder } from 'components/elements';

import { ChatAvatar } from './../avatar/avatar';

import { ChatResponseDto } from 'rest/chats/chat.response.dto';
import { ChatParticipantDto } from 'rest/chats/chat-participant.dto';

import { Settings, dispatchToProps, stateToProps } from './conversations.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class Conversations extends Settings {

  componentDidMount () {
    this.listenWindowWidth();
    window.addEventListener('resize', () => this.listenWindowWidth());
  }

  componentWillUpdate (props) {
    if (props.chats.length && !props.match.params.id && !this.state.mobileView) {
      this.resetChatCounter(props.chats, props.match.params.id);
      this.props.history.push(
        `/portal/${this.props.isRecruiter ? 'recruiter' : 'candidate'}/chat/${props.chats[0].id}`
      );
    }
  }

  componentWillReceiveProps (props) {
    if (props.chats.length && !props.match.params.id) {
      this.resetChatCounter(props.chats, props.match.params.id);
    }
  }

  componentWillUnmount () {
    this.props.resetConversations();
    window.removeEventListener('resize', () => this.listenWindowWidth());
  }

  changeSearchState (value: string) {
    this.setState({
      searchItem: value
    }, () => this.loadConversation(value));
  }

  loadConversation (value: string) {
    this.props.loadConversations(0, value);
  }

  resetChatCounter (chats: ChatResponseDto[], id: string) {
    const chat = chats.find(chat => chat.chatId === id);
    chat.countUnreadMessage = 0;
  }

  listenWindowWidth () {
    this.setState({ mobileView: window.innerWidth < 993 });
  }

  getInterlocutor (participants: ChatParticipantDto[]): ChatParticipantDto {
    return participants.find(participant => participant.userTechnicalId !== this.props.userId);
  }

  initEmptyList () {
    const isEmptyChats = this.state.mobileView && this.state.searchItem === '' && this.props.chats.length < 1;

    if (isEmptyChats) {
      return (
        <ListPlaceholder
          className="mobile-chat-list-placeholder"
          title={this.props.t('portal.candidate.chat.poster.title')}>
          <Trans i18nKey="portal.candidate.chat.poster.description">
            Because really, how can you skip a chat?
          </Trans>
        </ListPlaceholder>
      );
    }
    if (this.props.chats.length < 1) {
      return (
        <div className="empty-conversation-list">
          <Trans i18nKey="portal.recruiter.chat.sidebar.not-found">
            There are no available chats yet.
          </Trans>
        </div>
      );
    }
  }

  initConversation (chat: ChatResponseDto, index): HTMLElement {
    const interlocutor = this.getInterlocutor(chat.participants);

    return (
      <NavLink
        onClick={() => this.props.resetUnreadConversation(chat.id)}
        to={`/portal/${this.props.isRecruiter ? 'recruiter' : 'candidate'}/chat/${chat.id}`}
        key={index}
        className="conversation"
        activeClassName="active">
        <ChatAvatar participant={interlocutor}/>
        <div className="body">
          <div className="name">{interlocutor.fullName || 'No name'}</div>
          <div className="text">
            {chat.blocked
              ? this.props.t('portal.recruiter.chat.sidebar.blocked')
              : chat.lastMessage || this.props.t('portal.recruiter.chat.sidebar.created')}
          </div>
        </div>
        <div className="date-time">
          <div className="time">{moment(chat.createDate).parseZone().fromNow()}</div>
          {chat.countUnreadMessage > 0 && <div className="counter">{chat.countUnreadMessage}</div>}
        </div>
      </NavLink>
    );
  }

  render () {
    const isSearchEmpty = this.state.mobileView && this.state.searchItem === '' && this.props.chats.length < 1;

    return (
      <div className="conversations">
        <div className={`conversations-search ${isSearchEmpty ? 'mobile-search-empty' : ''}`}>
          <InputControl
            type="text"
            maxLength={1000}
            placeholder={this.props.t('portal.recruiter.chat.sidebar.search')}
            value={this.state.searchItem}
            change={(value: string): void => this.changeSearchState(value)}
            className="input-control-search"/>
        </div>
        <PerfectScrollbar className="conversations-list">
          <InfiniteScroll
            useWindow={false}
            threshold={100}
            pageStart={0}
            hasMore={this.props.hasMoreItems}
            loadMore={() => this.props.loadConversations(this.props.currentPage)}>
            {this.props.chats.map((chat, index) => this.initConversation(chat, index))}
          </InfiniteScroll>
          {this.initEmptyList()}
        </PerfectScrollbar>
      </div>
    );
  }

}
