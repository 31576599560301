import React from 'react';

import { connectStore } from 'middleware/connect';

import { Settings, stateToProps, dispatchToProps } from './active-filter.setting';
import { Trans } from 'react-i18next';

@connectStore(stateToProps, dispatchToProps)
export class ActiveFilterButton extends Settings {

  resetFilters () {
    this.props.clearCandidatesSearch();
    this.props.clearCandidatesSearchSkills();
    this.props.clearCandidatesFilter();
  }

  render () {
    if (this.props.candidatesFilter && this.props.candidatesFilter.filterCount) {
      return <div className="active-candidates-filters">
        <span className="title">
          <Trans i18nKey="portal.recruiter.candidates.list.applied-filter">
            Applied filters:
          </Trans>
        </span>
        <span className="count">{this.props.candidatesFilter.filterCount}</span>
        <span
          className="reset icon-cancel-s"
          onClick={() => this.resetFilters()}/>
      </div>;
    } else {
      return null;
    }
  }

}
