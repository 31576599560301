// @flow
import { Component } from 'react';

import './item.scss';
import './item.responsive.scss';

type Props = {
  path: string,
  children: any,
  candidate?: boolean,
  recruiter?: boolean,
  className?: string
}

export class Setting extends Component<Props> {

  static defaultProps = {
    path: '#',
    candidate: false,
    recruiter: false
  }

}
