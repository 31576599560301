// @flow
import React from 'react';
import { connect } from 'react-redux';

import { CounterWidget } from 'components/widgets/index';

import { Settings, stateToProps } from './purchased-list.setting';

@connect(stateToProps)
export class PurchasedCandidatesCircle extends Settings {

  render () {
    return (
      <CounterWidget
        title="Purchased Candidates"
        value={this.props.totalElements}
        circleTitle="Saved Candidates"/>
    );
  }

}
