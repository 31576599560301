import * as actions from './followers.constants';

import { Action, Dispatch } from 'redux';

import { JobOffersParams } from 'rest/public/job-offers/job-offers.params';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';
import { LocationsDto } from 'rest/companies/job-offers-matches/matched-candidates/locations/locations.dto';

import { getAllJobOfferRest } from 'rest/public/job-offers/job-offers.rest';
import { getFollowersLocations as getLocations } from 'rest/candidates/followers/followers.rest';

const getFollowedJobOffersRequest = (): Action => ({
  type: actions.GET_FOLLOWERS_JOB_OFFERS_REQUEST
});

const getFollowedJobOffersSuccess = (data: JobOffersResponseDto): Action => ({
  type: actions.GET_FOLLOWERS_JOB_OFFERS_SUCCESS,
  payload: data
});

const getFollowedJobOffersFailure = (): Action => ({
  type: actions.GET_FOLLOWERS_JOB_OFFERS_FAILURE
});

const getFollowersLocationsRequest = (): Action => ({
  type: actions.GET_FOLLOWERS_LOCATIONS_REQUEST
});

const getFollowersLocationsSuccess = (locations: LocationsDto[] = []): Action => ({
  type: actions.GET_FOLLOWERS_LOCATIONS_SUCCESS,
  locations
});

const getFollowersLocationsFailure = (): Action => ({
  type: actions.GET_FOLLOWERS_LOCATIONS_FAILURE
});

const resetJobOffersFollowsSuccess = (): Action => ({
  type: actions.RESET_FOLLOWERS_JOB_OFFER_SUCCESS
});

const changeFollowersFilterSuccess = (params: JobOffersParams): Action => ({
  type: actions.CHANGE_FOLLOWERS_FILTERS_SUCCESS,
  params
});

export function changeFollowersFilter (params: JobOffersParams = new JobOffersParams()) {
  return (dispatch: Dispatch) => dispatch(changeFollowersFilterSuccess(params));
}

export function resetAllJobOffersFollowers () {
  return (dispatch: Dispatch) => {
    dispatch(resetJobOffersFollowsSuccess());
  };
}

export function getFollowersJobOffers (params: JobOffersParams = new JobOffersParams()) {
  params.followingType = 'FOLLOWERS';
  params.extraFields = ['favorite'];
  return (dispatch: Dispatch) => {
    dispatch(getFollowedJobOffersRequest());
    return getAllJobOfferRest(params)
      .then(response => dispatch(getFollowedJobOffersSuccess(response.data)))
      .catch(() => dispatch(getFollowedJobOffersFailure()));
  };
}

export function getFollowersLocations (candidateId: string) {
  return (dispatch: Dispatch) => {
    dispatch(getFollowersLocationsRequest());
    return getLocations(candidateId)
      .then(response => dispatch(getFollowersLocationsSuccess(response.data)))
      .catch(() => dispatch(getFollowersLocationsFailure()));
  };
}
