// @flow
import React from 'react';

import { Button } from 'components/buttons';
import { ImageCrop } from 'components/elements';
import { Avatar as AvatarComponent } from 'components/elements';

import { Modal, ModalCaption } from 'containers/portal/modal-container';

import { Settings } from './avatar.setting';

export class Avatar extends Settings {

  change (file: File) {
    if (file) {
      this.setState({ file: URL.createObjectURL(file) });
    }
  }

  close () {
    this.setState({ file: null });
  }

  saveImg (file) {
    this.props.change(file);
    this.inputUpload.current.value = null;
    this.close();
  }

  render () {
    const { file } = this.state;
    return (
      <div className="upload-avatar-container">
        <AvatarComponent
          loading={this.props.loading}
          imageUrl={this.props.previewImageUrl}/>
        <div className={`${this.props.uploadButtonType} btn upload-btn`}>
          {this.props.uploadButtonName}
          <input
            ref={this.inputUpload}
            accept={this.props.accept}
            onChange={(event: Event) => this.change(event.target.files[0])}
            type="file"
            className="upload-file-input"
          />
        </div>
        <Button
          grey
          onClick={this.props.clearImageState}
          className="delete-btn">
          <img
            src="/images/shared/basket.svg"
            alt="delete"/>
        </Button>
        {
          this.props.error && <span className="avatar-error">{this.props.errorText}</span>
        }
        <Modal
          overlay
          animation="fade"
          open={file}
          className="avatar-modal-overlay"
          hideModalAvailable={false}
          hideModal={() => this.close()}
          closeOnOverlay={() => this.close()}>
          <ModalCaption
            hideModal={() => this.close()}
            title="Choose logo"/>
          <ImageCrop
            file={file}
            crop={this.props.crop}
            showRoundIcon={this.props.showRoundIcon}
            showSquareIcon={this.props.showSquareIcon}
            save={(file: File) => this.saveImg(file)}/>
        </Modal>
      </div>
    );
  }

}
