import { PaginationDto } from 'middleware/dto';

export class BranchDto extends PaginationDto {

  id: string;
  lang: string;
  name: string;
  description: string;

  constructor (data?: BranchDto) {
    super(data);
    if (data) {
      this.id = data.id;
      this.lang = data.lang;
      this.name = data.name;
      this.description = data.description;
    }
  }

}
