import React from 'react';
import { Trans } from 'react-i18next';
import { Setting } from './vdk-recommendation.settings';

export class VdkRecommendation extends Setting {

  render () {
    const text = `Das Konzept von excellent-jobs ist frisch und innovativ. Noch zu viele Arbeitgeber suchen mit
            angestaubten und überholten Stellenausschreibungen nach Mitarbeitenden und vergessen dabei, ihre Stärken
            als Betrieb herauszustellen. Das ist in angesichts des Arbeitskräftemangels nicht mehr zeitgemäß. Durch
            die "Skills", die Arbeitgeber und Jobsuchende bei excellent-jobs angeben können, werden der Bedarf und
            die Fertigkeiten beider Parteien berücksichtigt. Das erhöht die Chancen darauf, dass der 
            "maßgeschneiderte" Arbeitgeber auf den "maßgeschneiderten" Arbeitnehmenden trifft und es ein
            "perfect Match" gibt. Ich sehe zwischen excellent-jobs und dem VKD eine Kooperation mit großen
            Erfolgspotential, die sehr gut zu unserem Motto "Viele Küchen, ein Verband" passt und von der
            VKD-Mitglieder nur profitieren können.`;
    return (
      <div className="vdk-recommendation-container card">
        <div className="vdk-recommendation-info">
          <h4 className="title">
            <Trans i18nKey="landing.vdk-recommendations.title">Eine Kooperation mit Erfolgspotenzial</Trans>
          </h4>
          <div className="text">
            <Trans i18nKey="landing.vdk-recommendations.description">{text}</Trans>
          </div>
          <span className="author">
            <Trans i18nKey="landing.vdk-recommendations.author">Daniel Schade, VKD-Präsident</Trans>
          </span>
        </div>
        <div className="vdk-recommendation-photo">
          <img src="./images/landing/Daniel_Schade_VKD.jpeg" alt="Daniel Schade VKD"/>
        </div>
      </div>
    );
  }

}
