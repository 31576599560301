import { Component } from 'react';
import { TransProps } from 'react-i18next';

import { WorkExperienceDto } from 'rest/candidates/work-experience/work-experience.dto';

import './workplace.scss';
import './workplace.responsive.scss';

export type Props = {
  t?: TransProps,
  experience: WorkExperienceDto,
  number: number
};

export class Settings extends Component<Props> {

  static defaultProps: Props = {
    experience: new WorkExperienceDto(),
    number: 1
  };

}
