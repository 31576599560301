import { CalendarResponseDto } from 'rest/companies/candidate-invites/candidate-invites-response.dto';

import * as actions from './invites.constants';

import type { CalendarInvitesType } from './invites.type';

const initialState: CalendarInvitesType = {
  status: '',
  fetching: false,
  content: [],
  currentPage: 0,
  hasMoreItems: true
};

export default function (state: CalendarInvitesType = initialState, action): CalendarInvitesType {
  switch (action.type) {
    case actions.GET_ALL_CALENDAR_INVITES_REQUEST:
      return {
        ...state,
        fetching: true,
        hasMoreItems: false
      };

    case actions.GET_ALL_CALENDAR_INVITES_SUCCESS:
      return {
        ...state,
        fetching: false,
        content: [ ...state.content, ...action.payload.content.map(invite => new CalendarResponseDto(invite)) ],
        currentPage: action.payload.number + 1,
        hasMoreItems: action.payload.content.length > 0
      };

    case actions.GET_ALL_CALENDAR_INVITES_FAILURE:
      return {
        ...state,
        fetching: false,
        content: [],
        hasMoreItems: false
      };

    case actions.DELETE_CALENDAR_INVITE_REQUEST:
      return {
        ...state,
        fetching: true,
        hasMoreItems: false
      };

    case actions.DELETE_CALENDAR_INVITE_SUCCESS:
    case actions.RESET_ALL_CALENDAR_INVITES_SUCCESS:
      return {
        ...state,
        content: [],
        fetching: false,
        currentPage: 0,
        hasMoreItems: true
      };

    case actions.CHANGE_CALENDAR_INVITES_STATUS_SUCCESS:
      return {
        ...initialState,
        status: action.payload
      };

    default:
      return state;
  }
}
