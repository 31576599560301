import axios from 'axios';

import { InvitesMembersDto } from './team-members.dto';
import { ResponseTeamMembersDto } from './team-members.response.dto';

export async function inviteTeamMembers (companyId: string, data: InvitesMembersDto): Promise<InvitesMembersDto> {
  try {
    return await axios.post(`/companies/${companyId}/teammembers`, data);
  } catch (error) {
    if (error.response && error.response.data.status === 400) {
      throw error.response.data.params.param0;
    }
  }
}

export async function getCompanyTeamMembers (companyId: string): Promise<ResponseTeamMembersDto> {
  try {
    return await axios.get(`/companies/${companyId}/teammembers`);
  } catch (error) {
    throw error;
  }
}

export async function deleteCompanyTeamMember (companyId: string, memberId: string): Promise {
  try {
    return await axios.delete(`/companies/${companyId}/teammembers/${memberId}`);
  } catch (error) {
    throw error;
  }
}
