import { Component } from 'react';

import './caption.scss';

type Props = {
  children: Node,
  title: string
};

export class Setting extends Component<Props> {

  static defaultProps = {
    title: ''
  }

}
