import { LifeStyleItemDto } from './lifestyle-item.dto';

export class LifeStyleDto {

  activities: LifeStyleItemDto[] = [new LifeStyleItemDto()];

  constructor (data?: LifeStyleDto) {
    if (data) {
      this.activities = data.activities;
    }
  }

}
