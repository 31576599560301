import * as actions from './notifications.constants';

import { UsersAlertsResponseDto } from 'rest/alerts/alerts.response.dto';

import type { NotificationsState } from './notifications.type';

const initialState: NotificationsState = {
  errorText: '',
  fetching: false,
  notifications: [],
  hasMoreItems: true,
  notificationsCount: 0
};

export default (state = initialState, action): NotificationsState => {
  switch (action.type) {
    case actions.GET_NOTIFICATIONS_COUNT_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case actions.GET_NOTIFICATIONS_FAILURE:
    case actions.GET_NOTIFICATIONS_COUNT_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.GET_NOTIFICATIONS_SUCCESS: {
      const alerts = action.notifications.map(item => new UsersAlertsResponseDto(item));
      return {
        ...state,
        fetching: false,
        notifications: [ ...state.notifications, ...alerts ],
        hasMoreItems: action.notifications.length > 0
      };
    }

    case actions.GET_NOTIFICATIONS_COUNT_SUCCESS:
      return {
        ...state,
        fetching: false,
        notificationsCount: action.count
      };

    case actions.RESET_NOTIFICATIONS_LIST_SUCCESS:
      return { ...initialState };

    default:
      return state;
  }
};
