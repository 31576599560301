// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import type { RouterHistory } from 'react-router-dom';
import type { Location } from 'common/types/location';

import { CompanyDto } from 'rest/companies/companies.dto';
import { SubscriptionDto } from 'rest/companies/subscription/subscription.dto';
import { RecruiterDto } from 'rest/recruiter/recruiter.dto';
import { RecruiterResponseDto } from 'rest/recruiter/recruiter.response.dto';
import { PaymentRedirectUrlsDto } from 'rest/companies/payment-redirect-urls.dto';

import {
  getCompanyPremiumCostRequest,
  getCurrentCompanyRecruiter,
  getRecruiterCompanyById,
  subscribeRecruiterCompany,
  updateRecruiterCompany,
  updateCurrentRecruiter
} from 'services/portal/recruiter/account/account.actions';

import './payment.scss';

export type Props = {
  t?: TransProps,
  price: number,
  prev: () => void,
  location: Location,
  history: RouterHistory,
  redirect: boolean,
  redirectLink: string,
  company?: CompanyDto,
  currentUser?: RecruiterResponseDto,
  getCurrentCompanyRecruiter: () => void,
  updateRecruiterCompany: (companyIdId: string, company: CompanyDto) => void,
  updateCurrentRecruiter: (recruiterId: string, data: RecruiterDto) => void,
  subscribeRecruiterCompany: (companyId: string, data: SubscriptionDto) => void
};

type State = {
  redirectLink: string,
  paymentMethod: string,
  isSepaModalOpen: boolean,
  isSofortRedirect: boolean,
  isCompanyInfoLoaded: boolean,
  subscriptionPlan: string,
  promoCode: string,
  promoCodeIsValid: boolean,
  promoCodeIsFailed: boolean,
  promoCodeIsChange: boolean,
  amount: string,
  months: string,
  paymentRedirectUrls: PaymentRedirectUrlsDto
};

export class Settings extends Component<Props, State> {

  static defaultProps = {
    prev: () => {},
    redirectLink: '',
    subscribeRecruiterCompany: () => {},
    updateCurrentRecruiter: () => {},
    company: new CompanyDto(),
    currentUser: new RecruiterResponseDto()
  };

  state: State = {
    redirectLink: '',
    companyInfoIsEditable: false,
    confirmPayment: false,
    isPreviewModalOpen: false,
    isSepaModalOpen: false,
    isSofortRedirect: false,
    isCompanyInfoLoaded: false,
    paymentMethod: 'SOFORT',
    promoCode: null,
    promoCodeIsValid: false,
    promoCodeIsFailed: false,
    promoCodeIsChange: false,
    amount: 250,
    months: 12,
    subscriptionPlan: 'FREE',
    vatId: null,
    vatIdError: false,
    paymentRedirectUrls: new PaymentRedirectUrlsDto('/portal/recruiter/account/payment/success', '/', '/')
  };

}

export const stateToProps = (state: GlobalState) => ({
  company: state.portal.recruiter.account.companies.company,
  currentUser: state.portal.recruiter.account.current.currentUser,
  redirect: state.portal.recruiter.account.payment.redirect,
  redirectLink: state.portal.recruiter.account.payment.redirectUrl,
  price: state.portal.recruiter.account.companies.premiumSubscriptionPrice
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  subscribeRecruiterCompany,
  updateRecruiterCompany,
  updateCurrentRecruiter,
  getCompanyPremiumCostRequest,
  getCurrentRecruiter: getCurrentCompanyRecruiter,
  getRecruiterCompanyById
}, dispatch);
