// @flow
import { Component } from 'react';

import './date-control.scss';
import './date-control.responsive.scss';

export type Props = {
  label: string,
  notes: string,
  value: string,
  minDate: Date,
  maxDate: Date,
  format: string,
  className: string,
  dateFormat: string,
  recruiter?: boolean,
  monthsCount: number,
  showYearDropdown: boolean,
  change: (value: string) => void
}

type State = {
  value: string,
  mobileView: boolean
}

export class Setting extends Component<Props, State> {

  static defaultProps = {
    label: '',
    notes: '',
    format: '',
    className: '',
    value: new Date().toISOString(),
    change: () => {},
    recruiter: false,
    monthsCount: 2,
    dateFormat: 'MMM dd,yyyy',
    showYearDropdown: false
  };

  state: State = {
    value: this.props.value,
    mobileView: false
  };

}
