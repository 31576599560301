import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';

import { Container } from 'components/layouts';

import { Header, Footer } from 'containers/portal';
import { Jumbotron, Companies, Banners, VdkRecommendation,
  Questions, Infobox, SocialMedia, HogaNetzRecommendation } from 'containers/landing';
import { QuestionsButtons } from 'containers/landing/questions-button/questions-button';
import { BetaIntroModal } from 'containers/landing/beta-intro/beta-intro';

export class LandingPage extends Component {

  state = {
    isBetaIntroModalOpen: false
  };

  componentDidMount () {
    const userIsExists = localStorage.getItem('ej_user_is_exists');
    const isProduction = process.env.NODE_ENV === 'production';
    if (isProduction || userIsExists) return;

    setTimeout(() => this.setState({ isBetaIntroModalOpen: true }), 1000);
    localStorage.setItem('ej_user_is_exists', JSON.stringify('Hi!'));
  }

  handleBetaIntroForm = () => this.setState({
    isBetaIntroModalOpen: !this.state.isBetaIntroModalOpen
  });

  render () {
    const { isBetaIntroModalOpen } = this.state;

    return (
      <Fragment>
        <BetaIntroModal isOpen={isBetaIntroModalOpen} closeModal={this.handleBetaIntroForm}/>

        <div className="landing-container default">
          <QuestionsButtons/>
          <main>
            <Header fixed/>
            <Route component={Jumbotron}/>
            <Container>
              <Companies/>
              <Banners/>
              <VdkRecommendation/>
              <HogaNetzRecommendation/>
              <Questions/>
              <Infobox />
              <SocialMedia />
            </Container>
            <Footer/>
          </main>
        </div>
      </Fragment>
    );
  }

}
