import * as actions from './companies.constants';

import type { CompanyStateType } from './companies.type';

const initialState: CompanyStateType = {
  error: null,
  brands: [],
  branches: [],
  company: null,
  fetching: false,
  premiumSubscriptionPrice: 0,
  updateCompanyInfoSuccess: false
};

export default (state = initialState, action): CompanyStateType => {
  switch (action.type) {
    case actions.UPDATE_COMPANY_REQUEST:
      return {
        ...state,
        error: null,
        fetching: true,
        updateCompanyInfoSuccess: false
      };

    case actions.UPDATE_COMPANY_FAILURE:
      return {
        ...state,
        error: action.error,
        fetching: false,
        updateCompanyInfoSuccess: false
      };

    case actions.GET_COMPANY_BRANDS_FAILURE:
    case actions.GET_COMPANY_BRANCH_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.GET_COMPANY_BY_ID_SUCCESS:
      return {
        ...state,
        fetching: false,
        company: action.company
      };

    case actions.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        error: null,
        fetching: false,
        updated: true,
        updateCompanyInfoSuccess: true
      };

    case actions.GET_COMPANY_BRANDS_SUCCESS:
      return {
        ...state,
        brands: action.brands.content,
        success: true
      };

    case actions.GET_COMPANY_BRANCH_SUCCESS:
      return {
        ...state,
        branches: action.branch.content,
        success: true
      };

    case actions.GET_COMPANY_PREMIUM_COST_SUCCESS:
      return {
        ...state,
        premiumSubscriptionPrice: action.payload.price
      };

    default:
      return state;
  }
};
