import * as actions from './confirm.constants';

import type { ConfirmState } from './confirm.type';

const initialState: ConfirmState = {
  error: false,
  resendSuccess: false,
  confirmRegistrationSuccess: false
};

export default (state = initialState, action): ConfirmState => {
  switch (action.type) {
    case actions.CONFIRM_REGISTRATION_REQUEST:
      return {
        ...state
      };

    case actions.RESEND_CONFIRMATION_EMAIL_SUCCESS:
      return {
        ...state,
        resendSuccess: true
      };

    case actions.RESET_CONFIRMATION_EMAIL_SUCCESS:
      return {
        ...state,
        error: false,
        resendSuccess: false
      };

    case actions.RESEND_CONFIRMATION_EMAIL_FAILURE:
      return {
        ...state,
        resendSuccess: false
      };

    case actions.CONFIRM_REGISTRATION_SUCCESS:
      return {
        ...state,
        error: false,
        confirmRegistrationSuccess: true
      };

    case actions.CONFIRM_REGISTRATION_FAILURE:
      return {
        ...state,
        error: true,
        confirmRegistrationSuccess: false
      };

    default:
      return state;
  }
};
