// @flow
import React from 'react';

import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Setting } from './balance-board.setting';

@connectTranslation()
export class BalanceBoard extends Setting {

  render () {
    return (
      <div className="balance-board-container">
        <div className="balance-board-title">
          <Trans i18nKey="information.works.balanceBoard.title">
            Balance Board
          </Trans>
        </div>
        <div className="balance-board-description">
          <Trans i18nKey="information.works.balanceBoard.description">
            Сhoose the skills you possess, based on your experience, rate the level of skill and find vacancies suitable
            for your experience.
          </Trans>
        </div>
        <div className="balance-board-image">
          <img
            src="/images/information/bb.png"
            alt="This balance-board image"/>
        </div>
      </div>
    );
  }

}
