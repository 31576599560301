import React from 'react';
import { Link } from 'react-router-dom';

import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';
import { setData, getData } from 'middleware/payment-step';

import { Card } from 'components/layouts';
import { Button } from 'components/buttons';
import { Footer } from 'containers/portal';

import { modalTypes } from 'services/portal/modal/modal.constants';

import { Settings, stateToProps, dispatchToProps } from './failed-payment.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class FailedPayment extends Settings {

  componentDidMount () {
    this.props.getCurrentCompanyRecruiter();
    this.setRedirectLinks();
  }

  componentWillUnmount () {
    setTimeout(() => setData('ej_payment_step_link', {}), 0);
  }

  setRedirectLinks () {
    const candidatePreviewLink: string = localStorage.getItem('ej_candidate_preview_link');
    const paymentStepLink = getData('ej_payment_step_link');
    this.setState({
      redirectNextLink: candidatePreviewLink || '',
      redirectBackLink: paymentStepLink ? paymentStepLink.path : '/'
    });
  }

  goBack = () => {
    const { showModal } = this.props;
    const paymentStepLink = getData('ej_payment_step_link');

    paymentStepLink && paymentStepLink.showModal && showModal(modalTypes.COMPANY_PROFILE_MODAL);
  };

  goNext = () => {
    const { currentUser: id, activateCompanyRecruiter, deleteSavedJobOfferData } = this.props;

    deleteSavedJobOfferData();
    activateCompanyRecruiter(id);
  };

  render () {
    const { status } = this.props;
    const { redirectNextLink, redirectBackLink } = this.state;

    return (
      <div className="failed-payment-container">
        <Card>
          <div className="failed-payment-content">
            <h2>
              <Trans i18nKey={`portal.recruiter.account.payment.${status}.header`}>
                Something went wrong.
              </Trans>
            </h2>
            <p className="warning-text">
              <Trans i18nKey={`portal.recruiter.account.payment.${status}.title`}>
                Payment failed
              </Trans>
            </p>
            <p className="payment-description">
              <Trans i18nKey={`portal.recruiter.account.payment.${status}.description`}>
                Seems the data you have entered to make the transaction is not correct.
              </Trans>
            </p>
            <div className="failed-payment-action">
              <div className="payment-buttons">
                <Link to={redirectBackLink}>
                  <Button
                    className="accent outlined"
                    onClick={this.goBack}>
                    <Trans i18nKey={`portal.recruiter.account.payment.${status}.buttons.back`}>
                      Back to Payment
                    </Trans>
                  </Button>
                </Link>
                <Link to={redirectNextLink}>
                  <Button
                    className="accent "
                    onClick={this.goNext}>
                    <Trans i18nKey={`portal.recruiter.account.payment.${status}.buttons.continue`}>
                      Continue on Basic
                    </Trans>
                  </Button>
                </Link>
              </div>
              <div className="agreement">
                <Trans i18nKey={`portal.recruiter.account.payment.${status}.footer`}>
                  By creating company you agree to ExcellentJobs <br/>
                  <Link to="/information/privacy-policy"> Privacy Policy </Link> and
                  <Link to="/information/privacy-policy"> Terms of Use </Link>
                </Trans>
              </div>
            </div>
          </div>
        </Card>
        <Footer className="failed-payment-footer"/>
      </div>
    );
  }

}
