import { Component } from 'react';

import './page.scss';
import './page.responsive.scss';

type Props = {
  children: Node,
  fullHeight: boolean,
  autoHeight?: boolean,
  isMatchPortal?: boolean,
  isGuestPage?: boolean
};

export class Setting extends Component<Props> {

  static defaultProps: Props = {
    autoHeight: false,
    fullHeight: false,
    isMatchPortal: false,
    isGuestPage: false
  }

}
