import { combineReducers } from 'redux';

import stats from './stats/stats.reducer';
import following from './following/following.reducer';
import followers from './followers/followers.reducer';

export default combineReducers({
  stats,
  following,
  followers
});
