// @flow
import React, { Component } from 'react';

import { Card, Page } from 'components/layouts';

import { Footer, Header } from 'containers/portal';
import { NotFoundPoster } from 'containers/portal/not-found';

export class NotFoundPage extends Component<null> {

  render () {
    const isMatchPortal = this.props.location.pathname.includes('portal');

    return (
      <div className="not-found-page">
        {
          !isMatchPortal && <Header className="mobile-not-found-page-header"/>
        }
        <Page
          fullHeight
          isMatchPortal={isMatchPortal}>
          <Card className="mobile-not-found-card">
            <NotFoundPoster redirectPath="/"/>
          </Card>
        </Page>
        <Footer/>
      </div>
    );
  }

}
