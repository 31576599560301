import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';

import { Range } from 'rc-slider';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { Button } from 'components/buttons';
import {
  AutosuggestSelect,
  CheckboxControl,
  DateControl,
  FormGroup,
  InputControl,
  MultiSelect
} from 'components/controls';

import { JobOffersParams } from 'rest/public/job-offers/job-offers.params';

import { UnitDto } from 'rest/unit/unit.dto';
import { AddressDto } from 'rest/address/address.dto';
import { JobTitleDto } from 'rest/job-title/job-title.dto';

import { dispatchToProps, jobTypes, periodOfPublish, Settings, stateToProps } from './default-filter.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class DefaultFilter extends Settings {

  componentDidMount () {
    this.props.getAllBranches();
    this.fetchUnits();
    this.fetchJobTitles();
  }

  fetchUnits (branchId: string = this.props.searchJobOffers.branchId) {
    const query: UnitDto = new UnitDto();
    query.size = 300;
    query.branchId = [ branchId ];
    this.props.getAllUnits(query);
    this.props.saveSearchJobsBranch(branchId);
    this.fetchJobTitles('', branchId);
  }

  fetchJobTitles (unitId: string = this.props.searchJobOffers.unitId, branchId: string) {
    const query: JobTitleDto = new JobTitleDto();
    query.size = 500;
    query.filterUnitIds = [ unitId ];
    query.branchIds = [ this.props.searchJobOffers.branchId || branchId ];
    this.props.getAllJobTitles(query);
    this.props.saveSearchJobsUnit(unitId);
  }

  applyFilters () {
    const params: JobOffersParams = new JobOffersParams();
    params.unitId = this.props.searchJobOffers.unitId;
    params.jobType = this.props.searchJobOffers.jobType;
    params.company = this.props.searchJobOffers.companyName;
    params.branchId = this.props.searchJobOffers.branchId;
    params.location = this.props.searchJobOffers.location;
    params.minSalary = this.props.searchJobOffers.minSalary;
    params.maxSalary = this.props.searchJobOffers.maxSalary;
    params.jobTitleId = this.props.searchJobOffers.jobTitleId;
    params.workingTime = this.props.searchJobOffers.workingTime;
    params.publishedFrom = this.props.searchJobOffers.publishedFrom;
    params.availableFrom = this.props.searchJobOffers.availableFrom;
    params.searchByPlaceId = this.props.searchJobOffers.closeToMe;
    params.professionalSkills = this.props.searchJobOffers.professionalSkills;
    this.props.initJobsFilter(params);
    this.props.history.push('/portal/candidate/jobs');
  }

  resetFilters () {
    const params: JobOffersParams = new JobOffersParams();
    params.professionalSkills = this.props.searchJobOffers.professionalSkills;
    this.props.clearJobsSearch();
    this.props.initJobsFilter(params);
    this.props.history.push('/portal/candidate/jobs');
  }

  suggestLocation (value: string) {
    const query: AddressDto = new AddressDto();
    query.line = value;
    this.props.searchSuggestedAddress('line', query);
  }

  render () {
    return (
      <Fragment>
        <h2 className="default-filter-title mobile-filter-title">
          <Trans i18nKey="portal.candidate.job-offers.filters.default.title">
            Default filter
          </Trans>
        </h2>
        <PerfectScrollbar className="skills-container default-filter mobile-filter">
          <div className="general-filters">
            <FormGroup className="full-width">
              <MultiSelect
                label={this.props.t('portal.candidate.job-offers.filters.default.branch.label')}
                items={this.props.branches}
                defaultValue={this.props.searchJobOffers.branchId}
                change={value => this.fetchUnits(value.id)}
                isMulti={false}
                placeholder={this.props.t('portal.candidate.job-offers.filters.default.branch.placeholder')}/>
            </FormGroup>
            <FormGroup className="full-width">
              <MultiSelect
                label={this.props.t('portal.candidate.job-offers.filters.default.unit.label')}
                items={this.props.units}
                defaultValue={this.props.searchJobOffers.unitId}
                change={value => this.fetchJobTitles(value.id)}
                isMulti={false}
                placeholder={this.props.t('portal.candidate.job-offers.filters.default.unit.placeholder')}/>
            </FormGroup>
            <FormGroup className="full-width">
              <MultiSelect
                label={this.props.t('portal.candidate.job-offers.filters.default.job-title.label')}
                items={this.props.jobTitles}
                defaultValue={this.props.searchJobOffers.jobTitleId}
                change={value => this.props.saveSearchJobsJobTitle(value.id)}
                isMulti={false}
                placeholder={this.props.t('portal.candidate.job-offers.filters.default.job-title.placeholder')}/>
            </FormGroup>
            <FormGroup className="full-width location">
              <AutosuggestSelect
                required
                label={this.props.t('portal.candidate.job-offers.filters.default.location.label')}
                items={this.props.address.line}
                change={(value: string) => this.props.saveSearchJobsLocation(value)}
                search={(value: string) => this.suggestLocation(value)}
                bindValue="description"
                defaultValue={this.props.searchJobOffers.location}
                placeholder={this.props.t('portal.candidate.job-offers.filters.default.location.placeholder')}/>
              <CheckboxControl
                label={this.props.t('portal.candidate.job-offers.filters.default.close-to-me')}
                value={this.props.searchJobOffers.closeToMe}
                change={value => this.props.saveSearchJobsCloseToMe(value)}
                className="primary"/>
            </FormGroup>
            <FormGroup className="full-width location">
              <InputControl
                type="text"
                label={this.props.t('portal.candidate.job-offers.filters.default.company.label')}
                value={this.props.searchJobOffers.companyName}
                change={value => this.props.saveSearchJobsCompanyName(value)}
                maxLength={1000}
                placeholder={this.props.t('portal.candidate.job-offers.filters.default.company.placeholder')}/>
            </FormGroup>
            <div className="salary-range">
              <FormGroup className="salary-field-width min-salary">
                <InputControl
                  step={50}
                  minLength={1}
                  type="number"
                  notes={this.props.t('portal.candidate.job-offers.filters.default.salary.from')}
                  label={this.props.t('portal.candidate.job-offers.filters.default.salary.label')}
                  value={this.props.searchJobOffers.minSalary}
                  change={value => this.props.saveSearchJobsSalaryMinimum(value)}
                  required
                  stickyNotes
                  className="mobile-nowrap-label"/>
              </FormGroup>
              <FormGroup className="salary-field-width max-salary">
                <InputControl
                  step={50}
                  label=" "
                  notes={this.props.t('portal.candidate.job-offers.filters.default.salary.to')}
                  maxLength={8}
                  minLength={1}
                  type="number"
                  title={this.props.t('portal.candidate.job-offers.filters.default.salary.title')}
                  value={this.props.searchJobOffers.maxSalary}
                  change={value => this.props.saveSearchJobsSalaryMaximum(value)}
                  required
                  stickyNotes/>
              </FormGroup>
            </div>
            <FormGroup className="full-width">
              <Range
                min={1}
                max={10000}
                value={[ this.props.searchJobOffers.minSalary, this.props.searchJobOffers.maxSalary ]}
                onChange={value => {
                  this.props.saveSearchJobsSalaryMinimum(value[0]);
                  this.props.saveSearchJobsSalaryMaximum(value[1]);
                }}
                className="salary-slider primary"
                allowCross={false}/>
            </FormGroup>
          </div>
          <div className="additional-filter">
            <FormGroup className="half-width">
              <MultiSelect
                label={this.props.t('portal.candidate.job-offers.filters.default.publication.label')}
                items={periodOfPublish}
                defaultValue={this.props.searchJobOffers.publishedFrom}
                change={value => this.props.saveSearchJobsPeriodOfPublish(value.id)}
                isMulti={false}
                placeholder={this.props.t('portal.candidate.job-offers.filters.default.publication.placeholder')}/>
            </FormGroup>
            <FormGroup className="half-width">
              <InputControl
                type="number"
                label={this.props.t('portal.candidate.job-offers.filters.default.working-time.label')}
                value={this.props.searchJobOffers.workingTime}
                change={value => this.props.saveSearchJobsWorkingTime(value)}
                placeholder={this.props.t('portal.candidate.job-offers.filters.default.working-time.placeholder')}/>
            </FormGroup>
            <FormGroup className="full-width">
              <DateControl
                monthsCount={1}
                value={this.props.searchJobOffers.availableFrom}
                change={value => this.props.saveSearchJobsAvailableFrom(value)}
                label={this.props.t('portal.candidate.job-offers.filters.default.available-from')}/>
            </FormGroup>
            <FormGroup className="full-width">
              <MultiSelect
                label={this.props.t('portal.candidate.job-offers.filters.default.job-type.label')}
                items={jobTypes}
                defaultValue={this.props.searchJobOffers.jobType}
                change={value => this.props.saveSearchJobsJobType(value.id)}
                isMulti={false}
                placeholder={this.props.t('portal.candidate.job-offers.filters.default.job-type.placeholder')}/>
            </FormGroup>
          </div>
        </PerfectScrollbar>
        <FormGroup className="full-width form-buttons default-filter-buttons mobile-filter-buttons">
          <Button
            onClick={() => this.applyFilters()}
            className="primary apply">
            <Trans i18nKey="portal.candidate.job-offers.filters.buttons.apply">
              Apply Filters
            </Trans>
            <span className="mobile-count"> ({this.props.jobOffersFilter &&
              this.props.jobOffersFilter.filterCount > 0 ? this.props.jobOffersFilter.filterCount : '0'})
            </span>
          </Button>
          <Button
            onClick={() => this.resetFilters()}
            className="primary outlined reset">
            <div className="mobile-filter-buttons-hide">
              <Trans i18nKey="portal.candidate.job-offers.filters.buttons.reset">
                Clear Filters
              </Trans>
            </div>
            <div className="mobile-filter-buttons-show">
              <Trans i18nKey="portal.candidate.job-offers.filters.buttons.mobile.reset">
                Clear All
              </Trans>
            </div>
          </Button>
        </FormGroup>
      </Fragment>
    );
  }

}
