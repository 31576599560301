import * as actions from './process.constants';
import { Dispatch } from 'redux';

import { getData } from 'middleware/job-offer-storage';

import { SkillsDto } from 'rest/skill/skills.dto';
import { JobOfferDto } from 'rest/job-offers/job-offers.dto';

import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';
import { JobOfferMatchingRate } from 'rest/public/job-offers/job-offers-matching.dto';

import { createMatchingRate } from 'rest/public/job-offers/job-offers.rest';
import {
  createJobOffer,
  publishJobOffer,
  sendJobOfferEmail,
  createJobOfferSkills
} from 'rest/job-offers/job-offers.rest';

import { resetJobOfferSkills } from '../skills/skills.actions';
import { getJobOfferByIdSuccess } from 'services/portal/recruiter/job-offer/general/general.actions';
import { getAllCompanyJobOffers } from 'services/portal/recruiter/account/job-offer/job-offer.actions';
import { getAllCompanyJobOffersStats } from 'services/portal/recruiter/account/job-offer/stats/stats.actions';

const createJobOfferRequest = () => ({
  type: actions.CREATE_JOB_OFFER_REQUEST
});

const createJobOfferSuccess = (jobOffer: JobOfferDto) => ({
  type: actions.CREATE_JOB_OFFER_SUCCESS,
  jobOffer
});

const createJobOfferFailure = (error: string) => ({
  type: actions.CREATE_JOB_OFFER_FAILURE,
  error
});

const createJobOfferSkillsRequest = () => ({
  type: actions.CREATE_JOB_OFFER_SKILLS_REQUEST
});

const createJobOfferSkillsSuccess = () => ({
  type: actions.CREATE_JOB_OFFER_SKILLS_SUCCESS
});

const createJobOfferSkillsFailure = (error: string) => ({
  type: actions.CREATE_JOB_OFFER_SKILLS_FAILURE,
  error
});

const publishJobOfferRequest = () => ({
  type: actions.PUBLISH_JOB_OFFER_REQUEST
});

const publishJobOfferSuccess = () => ({
  type: actions.PUBLISH_JOB_OFFER_SUCCESS
});

const publishJobOfferFailure = (error: string) => ({
  type: actions.PUBLISH_JOB_OFFER_FAILURE,
  error
});

const sendJobOfferEmailRequest = () => ({
  type: actions.SEND_JOB_OFFER_REQUEST
});

const sendJobOfferEmailSuccess = () => ({
  type: actions.SEND_JOB_OFFER_SUCCESS
});

const sendJobOfferEmailFailure = (error: string) => ({
  type: actions.SEND_JOB_OFFER_FAILURE,
  error
});

const getJobOfferMatchingRateRequest = () => ({
  type: actions.GET_JOB_OFFER_MATCHING_RATE_REQUEST
});

const getJobOfferMatchingRateSuccess = (data: JobOfferMatchingRate) => ({
  type: actions.GET_JOB_OFFER_MATCHING_RATE_SUCCESS,
  payload: data
});

const getJobOfferMatchingRateFailure = (error: string) => ({
  type: actions.GET_JOB_OFFER_MATCHING_RATE_FAILURE,
  error
});

const deleteSavedJobOfferDataSuccess = () => ({
  type: actions.DELETE_SAVED_JOB_OFFER_DATA_SUCCESS
});

const saveCurrentJobTitleSuccess = (jobTitleId: string) => ({
  type: actions.SAVE_CURRENT_JOB_TITLE_SUCCESS,
  jobTitleId
});

export const deleteSavedJobOfferData = () => {
  return dispatch => {
    localStorage.removeItem('ej_general_job_offer');
    localStorage.removeItem('ej_skills_job_offer');
    dispatch(resetJobOfferSkills);
    return dispatch(deleteSavedJobOfferDataSuccess());
  };
};

export const saveCurrentJobTitle = (jobTitleId: string) => {
  return dispatch => dispatch(saveCurrentJobTitleSuccess(jobTitleId));
};

function resolveCreateJobOffer (jobOffer: JobOfferDto, jobOfferId: string) {
  return dispatch => {
    const job: JobOfferDto = new JobOfferDto(jobOffer);
    job.id = jobOfferId;
    dispatch(createJobOfferSuccess(job));
    return dispatch(getJobOfferByIdSuccess(job));
  };
}

export const createNewJobOffer = (data: JobOfferDto) => {
  return dispatch => {
    dispatch(createJobOfferRequest());
    return createJobOffer(data)
      .then(response => {
        dispatch(resolveCreateJobOffer(data, response.data));
        return response.data;
      })
      .catch(error => dispatch(createJobOfferFailure(error)));
  };
};

export const createNewJobOfferSkills = (jobOfferId: string, data: SkillsDto) => {
  return dispatch => {
    dispatch(createJobOfferSkillsRequest());
    return createJobOfferSkills(jobOfferId, data)
      .then(() => dispatch(createJobOfferSkillsSuccess()))
      .catch(error => dispatch(createJobOfferSkillsFailure(error)));
  };
};

export const publishNewJobOffer = (jobOfferId: string, companyId?: string = '') => {
  return dispatch => {
    dispatch(publishJobOfferRequest());
    return publishJobOffer(jobOfferId)
      .then(() => {
        dispatch(publishJobOfferSuccess());
        dispatch(sendNewJobOfferEmail(jobOfferId));
        if (companyId) {
          dispatch(getAllCompanyJobOffers(companyId));
          dispatch(getAllCompanyJobOffersStats(companyId));
        }
        localStorage.removeItem('ej_skills_job_offer');
        localStorage.removeItem('ej_general_job_offer');
      })
      .catch(error => dispatch(publishJobOfferFailure(error)));
  };
};

function connectJobOfferSkill (dispatch: Dispatch, jobOfferId: string, publish: boolean, companyId?: string = '') {
  const data: SkillsDto = getData('ej_skills_job_offer');
  return dispatch(createNewJobOfferSkills(jobOfferId, data))
    .then(() => {
      if (publish) {
        dispatch(publishNewJobOffer(jobOfferId, companyId));
      }
      dispatch(createJobOfferSkillsSuccess());
      return dispatch(deleteSavedJobOfferData());
    })
    .catch(() => dispatch(createJobOfferSkillsFailure()));
}

export const createJobOfferFlow = (publish: boolean, companyId?: string = '') => {
  return dispatch => {
    const savedJobOfferGeneralInfo = getData('ej_general_job_offer');
    dispatch(createNewJobOffer(savedJobOfferGeneralInfo))
      .then(id => dispatch(connectJobOfferSkill(dispatch, id, publish, companyId)))
      .catch(error => dispatch(publishJobOfferFailure(error)));
  };
};

export const getJobOfferMatchingRate = (jobOffer: JobOffersResponseDto) => {
  return dispatch => {
    dispatch(getJobOfferMatchingRateRequest());
    return createMatchingRate(jobOffer)
      .then(response => dispatch(getJobOfferMatchingRateSuccess(response.data)))
      .catch(error => dispatch(getJobOfferMatchingRateFailure(error)));
  };
};

export const sendNewJobOfferEmail = (jobOfferId: string) => {
  return dispatch => {
    dispatch(sendJobOfferEmailRequest());
    return sendJobOfferEmail(jobOfferId)
      .then(() => dispatch(sendJobOfferEmailSuccess()))
      .catch(error => dispatch(sendJobOfferEmailFailure(error)));
  };
};
