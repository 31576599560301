import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { ChatResponseDto } from 'rest/chats/chat.response.dto';

import {
  getAllConversations,
  resetAllConversation,
  resetUnreadConversation
} from 'services/portal/chats/conversations/conversations.actions';

import './conversations.scss';
import './conversations.responsive.scss';

type Props = {
  t: TransProps,
  chats: ChatResponseDto[],
  userId: string,
  history: History,
  fetching: boolean,
  currentPage: number,
  isRecruiter: boolean,
  hasMoreItems: boolean,
  resetUnreadConversation: () => void,
  loadConversations: () => void,
  resetConversations: () => void,
  className?: string
}

type State = {
  searchItem: string,
  mobileView: boolean
}

export class Settings extends Component<Props, State> {

  state: State = {
    searchItem: '',
    mobileView: false
  };

  static defaultProps: Props = {
    className: ''
  };

}

export const stateToProps = (state: GlobalState) => {
  const recruiter = state.portal.recruiter.account.current.currentUser;
  const candidate = state.portal.candidate.account.current.candidateData;
  return {
    chats: state.portal.chats.conversations.content,
    userId: recruiter.id || candidate.id,
    fetching: state.portal.chats.conversations.fetching,
    currentPage: state.portal.chats.conversations.currentPage,
    isRecruiter: !!recruiter.id,
    hasMoreItems: state.portal.chats.conversations.hasMoreItems
  };
};

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetUnreadConversation,
  loadConversations: getAllConversations,
  resetConversations: resetAllConversation
}, dispatch);
