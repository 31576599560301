import { Component } from 'react';

import { TransProps } from 'react-i18next';

import { EducationsDto } from 'rest/candidates/educations/educations.dto';

import './study-place.scss';
import './study-place.responsive.scss';

export type Props = {
  t?: TransProps,
  remove: () => void,
  education: EducationsDto,
  number: number
}

export class Settings extends Component<Props> {

  static defaultProps: Props = {
    remove: () => {},
    education: new EducationsDto(),
    number: 1
  }

}
