import * as actions from './request.constants';

import type { RequestSkillState } from './request.type';

const initialState: RequestSkillState = {
  error: NaN,
  fetching: false,
  requestedSuccess: false
};

export default (state = initialState, action): RequestSkillState => {
  switch (action.type) {

    case actions.REQUEST_NEW_SKILL_REQUEST:
      return {
        ...state,
        fetching: true,
        requestedSuccess: false
      };

    case actions.REQUEST_NEW_SKILL_SUCCESS:
      return {
        ...state,
        fetching: false,
        requestedSuccess: true
      };

    case actions.REQUEST_NEW_SKILL_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        requestedSuccess: false
      };

    case actions.RESET_NEW_SKILL_SUCCESS:
      return { ...initialState };

    default:
      return state;
  }
};
