import React, { Component } from 'react';

import { AuthBody } from 'containers/auth/layouts';
import { AuthImage } from 'containers/auth';
import { InitResetContainer } from 'containers/auth/reset';

export class InitResetPasswordPage extends Component {

  render () {
    return (
      <div className="init-reset-password-page">
        <AuthImage imageSrc="/images/auth/rocket.svg"/>
        <AuthBody title="Check Your Email">
          <InitResetContainer/>
        </AuthBody>
      </div>
    );
  }

}
