import * as actions from './modal.constants';
import { ModalPropsType } from './modal-props-type';

export const showModal = (modalType: string, modalProps = new ModalPropsType()) => ({
  type: actions.SHOW_MODAL,
  modalType,
  modalProps
});

export const hideModal = () => ({
  type: actions.HIDE_MODAL
});
