// @flow
import { Component } from 'react';
import type { Node } from 'react';

import './radio-control.scss';
import './radio-control.responsive.scss';

type Props = {
  label: string,
  value?: string,
  checked: boolean,
  selected: string,
  custom: boolean,
  children?: Node,
  className: string,
  change: (value: string) => void
};

type State = {
  checked: boolean
}

export class Setting extends Component<Props, State> {

  state: State = {
    checked: false
  };

  static defaultProps = {
    label: '',
    value: '',
    custom: false,
    className: '',
    checked: false,
    selected: false,
    change: (value: string) => value
  };

}
