import { combineReducers } from 'redux';

import jobOffers from './job-offers/job-offers.reducer';
import jobOffersStats from './stats/stats.reducer';
import jobOfferCandidates from './job-offers/candidates/candidates.reducer';
import jobOfferNewCandidates from './new-candidates/new-candidates.reducer';

export default combineReducers({
  jobOffers,
  jobOffersStats,
  jobOfferCandidates,
  jobOfferNewCandidates
});
