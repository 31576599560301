// @flow
import { Component } from 'react';

import type { GlobalState } from 'common/types/global-state';

type Props = {
  totalElements: number
}

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  totalElements: state.portal.recruiter.account.purchased.totalElements
});
