import * as actions from './followers.constants';

import type { FollowersCompanyType } from './followers.type';

import { JobOffersParams } from 'rest/public/job-offers/job-offers.params';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';
import { LocationsDto } from 'rest/companies/job-offers-matches/matched-candidates/locations/locations.dto';

const initialState: FollowersCompanyType = {
  content: [],
  locations: [],
  fetching: false,
  currentPage: 0,
  hasMoreItems: true,
  totalElements: 0,
  filters: new JobOffersParams()
};

export default function (state = initialState, action) {
  let content = [];
  switch (action.type) {
    case actions.GET_FOLLOWERS_JOB_OFFERS_REQUEST:
      return {
        ...state,
        fetching: true,
        hasMoreItems: false
      };

    case actions.GET_FOLLOWERS_JOB_OFFERS_SUCCESS:
      content = action.payload.content.map(jobOffer => new JobOffersResponseDto(jobOffer));
      return {
        ...state,
        fetching: false,
        content: action.payload.number ? [ ...state.content, ...content ] : content,
        currentPage: action.payload.number + 1,
        hasMoreItems: action.payload.content.length > 0,
        totalElements: action.payload.totalElements
      };

    case actions.RESET_FOLLOWERS_JOB_OFFER_SUCCESS:
      return {
        ...initialState,
        locations: state.locations
      };

    case actions.GET_FOLLOWERS_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.locations.map(location => new LocationsDto(location))
      };

    case actions.CHANGE_FOLLOWERS_FILTERS_SUCCESS:
      return {
        ...initialState,
        locations: state.locations,
        filters: new JobOffersParams(action.params)
      };

    default:
      return state;
  }
}
