// @flow
import React from 'react';

import moment from 'moment';
import Tooltip from 'rc-tooltip';

import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Setting } from './job-offer-row.setting';

@connectTranslation()
export class JobOfferRow extends Setting {

  render () {
    const { t } = this.props;
    return (
      <tr
        onClick={() => this.props.onClick(this.props.jobOffer.jobOfferId)}
        className="job-offer-row">
        <td className="job-title">
          <div className="cell">
            <h5 className="item-title">
              {this.props.jobOffer.jobTitleName || this.props.t('portal.recruiter.match.list.no-name')}
              {
                this.props.jobOffer.newCandidateCount
                  ? <span className="badge">{this.props.jobOffer.newCandidateCount}</span>
                  : null
              }
            </h5>
            <div className="item-description">
              {moment(this.props.jobOffer.publishedDate).parseZone().fromNow()}
            </div>
          </div>
        </td>
        <td className="branch">
          <div className="cell">
            <h5 className="item-title">
              {this.props.jobOffer.branchName || this.props.t('portal.recruiter.match.list.no-branch')}
            </h5>
            <div className="item-description">
              {(this.props.jobOffer.jobType === 'Employee'
                ? <Trans i18nKey="portal.recruiter.job-offers.list.job-type.full-time">
                  Full-Time
                </Trans>
                : this.props.jobOffer.jobType === 'Temporary'
                  ? <Trans i18nKey="portal.recruiter.job-offers.list.job-type.temporary">
                    Temporary
                  </Trans> : <Trans i18nKey="portal.recruiter.job-offers.list.job-type.trainee">
                    Trainee
                  </Trans>) ||
              this.props.t('portal.recruiter.match.list.no-job-type')}
            </div>
          </div>
        </td>
        <td className="match-points">
          <div className="cell">
            <div className="item-props">
              <span className="points">
                {this.props.jobOffer.jobOfferMatchPoints || 0}%
              </span>
            </div>
          </div>
        </td>
        <td className="candidates-list">
          <div className="cell">
            <div className="candidates">
              {
                this.props.jobOffer.matchedCandidates.map((candidate, index) => (
                  <Tooltip
                    key={index}
                    destroyTooltipOnHide
                    overlay={
                      candidate.fullName ||
                      t(`portal.recruiter.match.preview.candidate.${candidate.anonymous ? 'anonymous' : 'name'}`)
                    }
                    placement="top">
                    <div className="candidate">
                      <img src={candidate.primaryPhotoUrl}/>
                    </div>
                  </Tooltip>
                ))
              }
              {
                this.props.jobOffer.matchedCandidatesCount - this.props.jobOffer.matchedCandidates.length > 0 &&
                <div className="candidate">
                  <span className="more">
                    +{this.props.jobOffer.matchedCandidatesCount - this.props.jobOffer.matchedCandidates.length}
                  </span>
                </div>
              }
            </div>
          </div>
        </td>
      </tr>
    );
  }

}
