// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { ImageResponseDto } from 'rest/images/images.response';
import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';

import { showModal } from 'services/portal/modal/modal.actions';
import { getUnreadConversation } from 'services/portal/chats/conversations/conversations.actions';
import { getNewMatchedJobOffersCount } from 'services/portal/candidate/match/new-job-offers/new-job-offers.actions';
import { portalShowHideNav } from 'services/portal/portal-mobile-nav/portal-mobile-nav.action';

import './menu.scss';
import './menu.responsive.scss';

export type Props = {
  unreadMessages: number,
  newJobOfferMatchedCount: number,
  currentUser: CurrentCandidateDto,
  showModal: (modalType: string) => void,
  getNewMatchedJobOffersCount: (candidateId: number) => void,
  portalShowHideNav?: (portalNavShow: boolean) => void
}

export type State = {
  chatInterval: number,
  matchedInterval: number,
  user: CurrentCandidateDto,
  avatar: ImageResponseDto
}

export class Settings extends Component<Props, State> {

  static defaultProps: Props = {
    showModal: () => {},
    unreadMessages: 0,
    newJobOfferMatchedCount: 0,
    currentUser: new CurrentCandidateDto()
  };

  state: State = {
    chatInterval: 0,
    matchedInterval: 0,
    user: new CurrentCandidateDto(),
    avatar: new ImageResponseDto()
  }

}

export const stateToProps = (state: GlobalState) => ({
  unreadMessages: state.portal.chats.conversations.unreadMessages,
  currentUser: state.portal.candidate.account.current.candidateData,
  newJobOfferMatchedCount: state.portal.candidate.match.newJobOffers.count,
  portalNavShow: state.portal.portalNavShow.showNav
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  showModal,
  getUnreadConversation,
  getNewMatchedJobOffersCount,
  portalShowHideNav
}, dispatch);
