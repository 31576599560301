// @flow
import React from 'react';
import { Route } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';

import { LinkButton } from 'components/buttons';
import { PortletBody, PortletContainer, PortletHeader } from 'components/portlet';

import { JobsList } from 'containers/portal/candidate/jobs';
import { ActiveFilterButton } from 'containers/portal/candidate/jobs/search';

import { Setting, stateToProps } from './index.setting';

@connectStore(stateToProps, null, true)
export class JobsListPage extends Setting {

  render () {
    return (
      <PortletContainer>
        <PortletHeader
          title={this.props.t('portal.candidate.job-offers.list.title')}
          className="mobile-head-count">
          <div className="mobile-title-count">({this.props.total})</div>
          <ActiveFilterButton/>
          <div className="mobile-filter-hide">
            <LinkButton
              link="/portal/candidate/jobs/search"
              candidate>
              <Trans i18nKey="portal.candidate.job-offers.list.filter">
                Filters
              </Trans>
            </LinkButton>
          </div>
        </PortletHeader>
        <PortletBody className="mobile-portlet-list">
          <Route
            path="/portal/candidate/jobs"
            component={JobsList}/>
        </PortletBody>
        <div className="mobile-filter-show">
          <LinkButton
            link="/portal/candidate/jobs/search"
            candidate>
            <Trans i18nKey="portal.candidate.job-offers.list.filter">
              Filters
            </Trans>
          </LinkButton>
        </div>
      </PortletContainer>
    );
  }

}
