import { Component } from 'react';
import { bindActionCreators } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { TransProps } from 'react-i18next';

import { resetCurrentJobOffer } from 'services/portal/recruiter/job-offer/general/general.actions';
import { deleteSavedJobOfferData, resetJobOfferSkills } from 'services/portal/recruiter/job-offer/job-offer.actions';

import './modal.scss';

type Props = {
  t: TransProps,
  open: boolean,
  history: History
};

type State = {
  activeTab: number
}

export class Settings extends Component<Props, State> {

  static defaultProps = {
    open: false
  };

  state = {
    activeTab: 0
  }

}

export const stateToProps = (state: GlobalState) => ({
  skills: state.portal.recruiter.jobOffer.skills.content,
  currentJobOffer: state.portal.recruiter.jobOffer.general.currentJobOffer
});

export const dispatchToProps = dispatch => bindActionCreators({
  resetCurrentJobOffer,
  resetJobOfferSkills,
  deleteSavedJobOfferData
}, dispatch);
