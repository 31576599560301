import { Component } from 'react';

import { TransProps } from 'react-i18next';

import { EventDataDto } from 'rest/alerts/alerts.event-data.dto';

import moment from 'moment';

type Props = {
  publishDate: string,
  eventData: EventDataDto[],
  t?: TransProps,
  accepted?: boolean
}

export class Setting extends Component<Props> {

  static defaultProps: Props = {
    eventData: [],
    publishDate: moment().parseZone().fromNow(),
    accepted: false
  }

}
