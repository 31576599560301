import axios from 'axios';

import { ImageResponseDto } from './images.response';

export async function uploadImage (file: File): Promise<ImageResponseDto> {
  try {
    const formData = new FormData();
    formData.append('files', file);
    return await axios.post('/images', formData);
  } catch (error) {
    throw error;
  }
}
