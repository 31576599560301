// @flow
import * as actions from './balance-board.constants';

import type { CandidateBalanceBoardType } from './balance-board.type';

const initialState: CandidateBalanceBoardType = {
  success: false,
  fetching: false,
  balanceBoard: null
};

export default function (state: CandidateBalanceBoardType = initialState, action): CandidateBalanceBoardType {
  switch (action.type) {
    case actions.CREATE_BALANCE_BOARD_REQUEST:
    case actions.UPDATE_BALANCE_BOARD_REQUEST:
    case actions.GET_BALANCE_BOARD_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case actions.CREATE_BALANCE_BOARD_FAILURE:
    case actions.GET_BALANCE_BOARD_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.GET_BALANCE_BOARD_SUCCESS:
      return {
        ...state,
        success: true,
        fetching: false,
        skillsUpdate: true,
        balanceBoard: action.balanceBoard
      };

    case actions.CREATE_BALANCE_BOARD_SUCCESS:
    case actions.UPDATE_BALANCE_BOARD_SUCCESS:
      return {
        ...state,
        fetching: false,
        balanceBoard: {
          ...state.balanceBoard,
          ...action.balanceBoard
        }
      };

    case actions.RESET_BALANCE_BOARD_SUCCESS:
      return {
        ...initialState
      };

    default:
      return state;
  }
}
