import * as actions from './stats.constants';

import { StatsDto } from 'rest/stats/stats.dto';

import { getConnectionsStats } from 'rest/candidates/stats/connections/connections.rest';
import { ConnectionsParams } from 'rest/candidates/stats/connections/connections.params';

const getFollowingStatisticRequest = () => ({
  type: actions.GET_FOLLOWING_STATS_REQUEST
});

const getFollowingStatisticSuccess = (stats: StatsDto[] = [], total: number = 0) => ({
  type: actions.GET_FOLLOWING_STATS_SUCCESS,
  total: total,
  stats: stats
});

const getFollowingStatisticFailure = (error: string) => ({
  type: actions.GET_FOLLOWING_STATS_FAILURE,
  error
});

const getFollowersStatisticRequest = () => ({
  type: actions.GET_FOLLOWERS_STATS_REQUEST
});

const getFollowersStatisticSuccess = (stats: StatsDto[] = [], total: number = 0) => ({
  type: actions.GET_FOLLOWERS_STATS_SUCCESS,
  total: total,
  stats: stats
});

const getFollowersStatisticFailure = (error: string) => ({
  type: actions.GET_FOLLOWERS_STATS_FAILURE,
  error
});

const resetFollowsStatisticSuccess = data => ({
  type: actions.RESET_FOLLOWERS_STATS_SUCCESS,
  payload: data
});

export const resetFollowsStatistic = () => {
  return dispatch => {
    dispatch(resetFollowsStatisticSuccess());
  };
};

export const getFollowingStatistic = (candidateId: string) => {
  return dispatch => {
    dispatch(getFollowingStatisticRequest());
    const params = new ConnectionsParams();
    params.followingType = 'FOLLOWED';
    return getConnectionsStats(candidateId, params)
      .then(response => {
        dispatch(getFollowingStatisticSuccess(response.data.candidateFollowCount, response.data.total));
      })
      .catch(error => dispatch(getFollowingStatisticFailure(error)));
  };
};

export const getFollowersStatistic = (candidateId: string) => {
  return dispatch => {
    dispatch(getFollowersStatisticRequest());
    const params = new ConnectionsParams();
    params.followingType = 'FOLLOWERS';
    return getConnectionsStats(candidateId, params)
      .then(response => {
        dispatch(getFollowersStatisticSuccess(response.data.candidateFollowCount, response.data.total));
      })
      .catch(error => dispatch(getFollowersStatisticFailure(error)));
  };
};
