import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import {
  resetApplications,
  createCandidateApplication
} from 'services/portal/candidate/applications/templates/templates.actions';

import { ApplicationDto } from 'rest/candidates/applications-template/applications-template.dto';
import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';

import './modal.scss';
import './modal.responsive.scss';

export type Props = {
  t: TransProps,
  redirect: boolean,
  resetApplications: () => void,
  currentCandidate: CurrentCandidateDto,
  createCandidateApplication: (candidateId: string, data: ApplicationDto) => void
}

type State = {
  body: string,
  subject: string,
  redirect: boolean
}

export class Settings extends Component<Props> {

  static defaultProps: Props = {
    resetApplications: () => {},
    createCandidateApplication: () => {}
  };

  state: State = {
    body: '',
    subject: '',
    redirect: false
  };

}

export const stateToProps = (state: GlobalState) => ({
  redirect: state.portal.candidate.applications.templates.createApplicationSuccess,
  currentCandidate: state.portal.candidate.account.current.candidateData
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetApplications,
  createCandidateApplication
}, dispatch);
