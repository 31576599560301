// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';

import {
  getCurrentCandidate,
  updateCurrentCandidate
} from 'services/portal/candidate/account/account.actions';
import './notifications.scss';
import './notifications.responsive.scss';

export type Props = {
  t?: TransProps,
  fetching?: boolean,
  getCurrentCandidate: () => void,
  currentCandidate: CurrentCandidateDto,
  updateCurrentCandidate: (candidateId: string, data: CurrentCandidateDto) => void
}

type State = {
  systemUpdates: boolean,
  newMessageChat: boolean,
  updateFollowing: boolean
}

export class Setting extends Component<Props, State> {

  state: State = {
    systemUpdates: false,
    newMessageChat: false,
    updateFollowing: false
  }

}

export const stateToProps = (state: GlobalState) => ({
  fetching: state.portal.candidate.account.current.fetching,
  currentCandidate: state.portal.candidate.account.current.candidateData
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getCurrentCandidate,
  updateCurrentCandidate
}, dispatch);
