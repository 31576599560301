// @flow
import React, { Fragment } from 'react';
import { Player, BigPlayButton } from 'video-react';

import { Trans } from 'react-i18next';

import cn from 'classnames';

import { connectTranslation } from 'middleware/connect';

import { Button } from 'components/buttons';
import { Container } from 'components/layouts';
import POSTERIMG from './excellentPoster.PNG';
import { Candidate, Recruiter, Setting } from './video.setting';
import './video-react.css';
import { getLocalization } from 'middleware/localization';
let language = getLocalization();
let enuStaus = false;
if (language === 'enu') {
  language = 'en';
  enuStaus = true;
}
@connectTranslation()
export class Video extends Setting {

  constructor() {
    super();
    this.videoRef = React.createRef();
    this._TogglePrev = this._TogglePrev.bind(this);
    this._ToggleNext = this._ToggleNext.bind(this);
  }

  _ToggleNext() {
    if (this.state.index === this.state.videoList.length - 1) {
      return;
    }
    this.setState(prevState => ({
      index: prevState.index + 1
    }));
  }

  _TogglePrev() {
    if (this.state.index === 0) {
      return;
    }
    this.setState(prevState => ({
      index: prevState.index - 1
    }));
  }

  componentDidMount () {
    this.initVideoLink(this.props.recruiter);
    this.videoRef.current.subscribeToStateChange(this.playNextVideo.bind(this));
  }

  componentDidUpdate (prevprops) {
    if (prevprops.recruiter !== this.props.recruiter) {
      this.initVideoLink(this.props.recruiter);
      this.videoRef.current.subscribeToStateChange(this.playNextVideo.bind(this));
    }
  }
  getVideoList(isRecruiter: boolean) {
    if (language === 'de') {
      const s3URL = 'https://ejobs-videos.s3.eu-central-1.amazonaws.com/howItWorkVideo';
      const videoListRecruiter = [`${s3URL}/recruiter/1Onboarding_Recruiters_De.mp4`];
      const videoListCandidate = [`${s3URL}/candidates/Onboarding_candidates_de.mp4`];
      return isRecruiter ? videoListRecruiter : videoListCandidate;
    } if (enuStaus && !isRecruiter) {
      const s3URL = 'https://ejobs-videos.s3.eu-central-1.amazonaws.com/howItWorkVideo';
      const videoListCandidate = [`${s3URL}/candidates/EXJ+UA+.mp4`];
      return videoListCandidate;
    } else {
      const videoListRecruiter = ['videos/Onboarding_Recruiters_Eng.mp4', 'videos/Szene_30.2.mp4',
        'videos/Szene_31.mp4', 'videos/Szene_32.mp4', 'videos/Szene_33.mp4', 'videos/Szene_34.mp4',
        'videos/Szene_35.mp4', 'videos/Szene_36.mp4', 'videos/Szene_37.mp4', 'videos/Szene_38.mp4',
        'videos/Szene_39.mp4', 'videos/Szene_40.mp4', 'videos/Szene_42.1_FEHLER.mp4', 'videos/Szene_43.mp4',
        'videos/Szene_44.1.mp4', 'videos/Szene_44.mp4', 'videos/Szene_45.mp4', 'videos/Szene_47.mp4'];
      const videoListCandidate = ['videos/Onboarding_Candidates_Eng.mp4', 'videos/Szene_1bis4.mp4',
        'videos/Szene_5.mp4', 'videos/Szene_6.mp4', 'videos/Szene_7.mp4', 'videos/Szene_8.mp4',
        'videos/Szene_9.mp4', 'videos/Szene_10.mp4', 'videos/Szene_11.mp4', 'videos/Szene_12.mp4',
        'videos/Szene_13.mp4', 'videos/Szene_14.mp4', 'videos/Szene_15.mp4', 'videos/Szene_16.mp4',
        'videos/Szene_17.mp4', 'videos/Szene_18.mp4', 'videos/Szene_19.mp4', 'videos/Szene_20.mp4',
        'videos/Szene_21.mp4', 'videos/Szene_22.mp4', 'videos/Szene_23.mp4', 'videos/Szene_24.mp4',
        'videos/Szene_25.mp4', 'videos/Szene_26.mp4', 'videos/Szene_27.mp4', 'videos/Szene_28.mp4',
        'videos/Szene_29.mp4'];
      return isRecruiter ? videoListRecruiter : videoListCandidate;
    }
  }

  playNextVideo(state, prevState) {
    if (state.ended && this.state.index < this.state.videoList.length - 1) {
      this.setState({ index: this.state.index + 1 });
    }
  }

  initVideoLink (isRecruiter: boolean) {
    if (isRecruiter) {
      this.setState({ videoId: Recruiter.getId(this.props.i18n.language), videoList: this.getVideoList(isRecruiter) });
    }
    if (!isRecruiter) {
      this.setState({ videoId: Candidate.getId(this.props.i18n.language), videoList: this.getVideoList(isRecruiter) });
    }
    this.setState({ isRecruiter: isRecruiter, src: this.getVideoList(isRecruiter) });
  }

  render () {
    const { isRecruiter } = this.state;
    const videoClasses = cn([ 'video-container', {
      'on-boarding-container': this.props.onBoarding
    }]);
    return (
      <div className={videoClasses} style={ this.props.howItwork ? { marginTop: -50 } : { marginTop: 0 }}>
        <Container className="video-content">
          {
            !this.props.onBoarding && <Fragment>
              <div className="video-spacer">
                <Trans i18nKey="information.works.video.title">
                  Page Spacer
                </Trans>
              </div>
              <div className="video-title">
                <Trans i18nKey="information.works.video.title">
                  Best job and staff search platform for your modern business in the hospitality industry.
                </Trans>
              </div>
              <div className="mobile-video-subtitle">
                <Trans i18nKey="information.works.video.mobile.subtitle">
                  Select role and discover our service
                </Trans>
              </div>
              <div className="mobile-video-switcher">
                <Button
                  onClick={() => this.initVideoLink(true)}
                  className={`btn ${isRecruiter ? 'active' : ''}`}>
                  <Trans i18nKey="information.works.video.mobile.recruiter">
                    I’m Recruiter
                  </Trans>
                </Button>
                <Button
                  onClick={() => this.initVideoLink(false)}
                  className={`btn ${!isRecruiter ? 'active' : ''}`}>
                  <Trans i18nKey="information.works.video.mobile.candidate">
                    I’m Candidate
                  </Trans>
                </Button>
              </div>
            </Fragment>
          }
          <div className="video-player">
            <div className='video-controls-container'>
              <div className='player-wrapper'>
                {/* <ReactPlayer className="video" url={ this.state.videoList[this.state.index] }
                  controls width='100%'
                  height='100%' onEnded = {() => { this.playNextVideo(); } }/> */}
                {!this.props.videoURL && <Player className="video"
                  ref= {this.videoRef}
                  poster={POSTERIMG}
                  src={ this.state.videoList[this.state.index] }
                  onVideoEnd={() => { console.log('oi'); }}
                >
                  <BigPlayButton position="center" />
                </Player>}
                {this.props.videoURL && <Player className="video"
                  ref= {this.videoRef}
                  poster={this.props.videoPoster}
                  src={ this.props.videoURL }
                >
                  <BigPlayButton position="center" />
                </Player>}
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }

}
