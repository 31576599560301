// @flow
import axios, { AxiosPromise } from 'axios';

import { ApplicationsParams } from './applications.params';
import { SentApplicationsStatsDto } from './applications.stats.dto';
import { ApplicationResponseDto } from './../applications-template/applications-template.response.dto';

export async function getSentApplications (candidateId: string, params: ApplicationsParams):
  AxiosPromise<ApplicationResponseDto[]> {
  try {
    return await axios.get(`/candidates/${candidateId}/applications`, {
      params
    });
  } catch (error) {
    throw error;
  }
}

export async function getSentApplicationById (candidateId: string, applicationId: string):
  AxiosPromise<ApplicationResponseDto[]> {
  try {
    return await axios.get(`/candidates/${candidateId}/applications/${applicationId}`);
  } catch (error) {
    throw error;
  }
}

export async function getSentApplicationsStats (candidateId: string): AxiosPromise<SentApplicationsStatsDto> {
  try {
    return await axios.get(`/candidates/${candidateId}/applications/stats`);
  } catch (error) {
    throw error;
  }
}
