import React, { Fragment, Component } from 'react';
import { Route } from 'react-router-dom';

import { PreviewPostModal, EditPostModal } from 'containers/portal/candidate/posts';

import { PostsListPage } from './list';
import { CreatePostPage } from './create';

export class PostsPage extends Component {

  render () {
    return (
      <Fragment>
        <Route
          path="/portal/candidate/posts"
          component={PostsListPage}/>
        <Route
          path="/portal/candidate/posts/create"
          component={CreatePostPage}/>
        <Route
          path="/portal/candidate/posts/preview/:postId"
          component={PreviewPostModal}/>
        <Route
          path="/portal/candidate/posts/edit/:postId"
          component={EditPostModal}/>
      </Fragment>
    );
  }

}
