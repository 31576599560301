// @flow
import axios, { AxiosPromise } from 'axios';

import { List } from 'rest/list/list.dto';
import { CandidateDto } from 'rest/candidates/candidate.dto';
import { RecruiterFollowParams } from 'rest/recruiter/follow/follow.params';

export async function getFollowingCandidates (params: RecruiterFollowParams): AxiosPromise<List<CandidateDto>> {
  try {
    return await axios.get(`/recruiters/${params.recruiterId}/followers`, { params });
  } catch (error) {
    throw error;
  }
}

export async function getFollowersCandidates (companyId: string, params: RecruiterFollowParams)
  : AxiosPromise<List<CandidateDto>> {
  try {
    return await axios.get(`/companies/${companyId}/followers`, { params });
  } catch (error) {
    throw error;
  }
}

export async function addCandidateToFollow (params: RecruiterFollowParams): AxiosPromise {
  try {
    return await axios.post(`/recruiters/${params.recruiterId}/followers?candidateId=${params.candidateId}`);
  } catch (error) {
    throw error;
  }
}

export async function deleteCandidateFromFollow (params: RecruiterFollowParams): AxiosPromise {
  try {
    return await axios.delete(`/recruiters/${params.recruiterId}/followers?candidateId=${params.candidateId}`);
  } catch (error) {
    throw error;
  }
}
