import * as actions from './stats.constants';

import type { PurchasedStatsType } from './stats.type';

const initialState: PurchasedStatsType = {
  fetching: false,
  totalPurchasedCandidates: 0
};

export default (state = initialState, action): PurchasedStatsType => {
  switch (action.type) {

    case actions.GET_PURCHASED_CANDIDATE_STATS_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case actions.GET_PURCHASED_CANDIDATE_STATS_SUCCESS:
      return {
        ...state,
        fetching: false,
        totalPurchasedCandidates: action.total
      };

    case actions.GET_PURCHASED_CANDIDATE_STATS_FAILURE:
      return {
        ...state,
        fetching: false
      };

    default:
      return state;
  }
};
