import React from 'react';

import { connectTranslation } from 'middleware/connect';

import { RangeControl } from 'components/controls';
import { KnowledgeLevel } from 'components/controls';

import { Settings } from './skill-item.setting';

@connectTranslation()
export class SkillItem extends Settings {

  render () {
    const toggleSkill = this.props.required ? this.props.removeFromRequired : this.props.addToRequired;
    return (
      <div
        className={`row body ${this.props.required ? 'required' : ''}`}>
        <div className="column title">
          { this.props.skill.name }
        </div>
        <div className="column level">
          {
            !this.props.hideSkillLevel && <KnowledgeLevel
              value={this.props.skill.skillLevel}
              change={value => this.props.changeSkillLevel(value)}/>
          }
        </div>
        <div className="column priority">
          {
            this.props.required
              ? this.props.t('portal.recruiter.balance-board.job-offer.priority.required')
              : <RangeControl
                value={this.props.skill.points}
                change={value => this.props.changeSkillPoints(value)}
                min={0}
                max={100}/>
          }
        </div>
        <div className="column choice">
          <span
            onClick={() => toggleSkill()}
            className={`select-row ${this.props.required ? 'selected' : ''}`}/>
        </div>
      </div>
    );
  }

}
