import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { ImageResponseDto } from 'rest/images/images.response';
import { SalaryRangeDto } from 'rest/salary-range/salary-range.dto';
import { RecruiterImageDto } from 'rest/recruiter/recruiter-image.dto';
import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';

import { uploadFile } from 'services/portal/images/images.actions';
import { updateCandidateJobTitle } from 'services/portal/candidate/cv/job-title/job-title.actions';
import {
  createCandidateBalanceBoard,
  updateCandidateBalanceBoard
} from 'services/portal/candidate/balance-board/balance-board.actions';
import { updateCurrentCandidate } from 'services/portal/candidate/account/account.actions';
import { searchSuggestedAddress } from 'services/portal/address/address.actions';

import './personal-info.scss';
import './personal-info.responsive.scss';

export type Props = {
  t?: TransProps,
  redirect: boolean,
  fetching: boolean,
  avatarLoading: boolean,
  imageInfo: ImageResponseDto,
  currentCandidate: CurrentCandidateDto
}

export type State = {
  city: string,
  country: string,
  email: string,
  lastName: string,
  redirect: boolean,
  firstName: string,
  jobTitle: string,
  salaryError: boolean,
  relocation: boolean,
  phoneNumber: string,
  avatarError: ?string,
  avatarPreview: string,
  salary: SalaryRangeDto,
  errorCollection: Set<string>,
  candidatePhotos: RecruiterImageDto[]
}

export class Settings extends Component<Props, State> {

  state: State = {
    city: '',
    country: '',
    email: '',
    lastName: '',
    jobTitle: '',
    firstName: '',
    phoneNumber: '',
    salaryError: false,
    relocation: true,
    avatarError: '',
    avatarPreview: '',
    candidatePhotos: [],
    salary: new SalaryRangeDto({
      min: 100,
      max: 1000,
      salaryType: 'MONTH'
    }),
    errorCollection: new Set()
  };

}

export const stateToProps = (state: GlobalState) => ({
  address: state.portal.address.content,
  imageInfo: state.portal.images.avatarInfo,
  avatarLoading: state.portal.images.avatarLoading,
  jobTitle: state.portal.candidate.cv.jobTitle.content,
  fetching: state.portal.candidate.account.current.fetching,
  balanceBoard: state.portal.candidate.balanceBoard.balanceBoard,
  currentCandidate: state.portal.candidate.account.current.candidateData,
  redirect: state.portal.candidate.account.current.registerCandidateSuccess
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  uploadFile,
  updateCurrentCandidate,
  updateCandidateJobTitle,
  searchSuggestedAddress,
  createCandidateBalanceBoard,
  updateCandidateBalanceBoard
}, dispatch);
