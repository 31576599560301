// @flow
import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { JobOfferDto } from 'rest/job-offers/job-offers.dto';
import { JobOffersParams } from 'rest/public/job-offers/job-offers.params';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

import { addJobToFavorite, deleteJobFromFavorite } from 'services/portal/candidate/jobs/favorite/favorite.actions';

import {
  getJobOffers,
  resetJobOffersList
} from 'services/portal/candidate/jobs/job-offers/job-offers.actions';

import './jobs-list.scss';

type Props = {
  t: TransProps,
  history: History,
  fetching: boolean,
  jobOffers: JobOfferDto[],
  currentPage: number,
  hasMoreItems: boolean,
  jobOffersFilter: JobOffersParams,
  currentCandidateId: string,
  getJobOffers: () => void,
  addJobToFavorite: (jobOffer: JobOffersResponseDto) => void,
  resetJobOffersList: (params: JobOffersParams) => void,
  deleteJobFromFavorite: (jobOffer: JobOffersResponseDto) => void,
};

export class Setting extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  fetching: state.portal.candidate.jobs.jobOffers.fetching,
  jobOffers: state.portal.candidate.jobs.jobOffers.jobOffers,
  currentPage: state.portal.candidate.jobs.jobOffers.currentPage,
  hasMoreItems: state.portal.candidate.jobs.jobOffers.hasMoreItems,
  jobOffersFilter: state.portal.candidate.jobs.jobOffers.jobOffersFilter,
  currentCandidateId: state.portal.candidate.account.current.candidateData.id
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getJobOffers,
  addJobToFavorite,
  resetJobOffersList,
  deleteJobFromFavorite
}, dispatch);
