// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';

import type { Dispatch } from 'redux';
import type { GlobalState } from 'common/types/global-state';

import { initResetPassword } from 'services/auth/reset/reset.actions';

import { InitResetPasswordDto } from 'rest/account/reset-password/init/init.dto';

import './init-reset.scss';
import './init-reset.responsive.scss';

type Props = {
  email?: string,
  buttonType?: string,
  initResetPassword?: (data: InitResetPasswordDto) => void
}

export class Setting extends Component<Props> {

  static defaultProps = {
    email: '',
    buttonType: 'primary',
    initResetPassword: () => {}
  };

}

export const stateToProps = (state: GlobalState) => ({
  email: state.auth.reset.resetEmail
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  initResetPassword
}, dispatch);
