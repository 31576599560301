import * as actions from './purchased.constants';

import type { PurchasedCandidatesState } from './purchased.type';

import { CandidateDto } from 'rest/candidates/candidate.dto';

const initialState: PurchasedCandidatesState = {
  fetching: false,
  candidates: [],
  hasMoreItems: true,
  totalElements: 0
};

export default (state = initialState, action): PurchasedCandidatesState => {
  switch (action.type) {

    case actions.GET_COMPANY_PURCHASED_CANDIDATES_REQUEST:
      return {
        ...state,
        fetching: true,
        hasMoreItems: false
      };

    case actions.GET_COMPANY_PURCHASED_CANDIDATES_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.GET_COMPANY_PURCHASED_CANDIDATES_SUCCESS: {
      const list: CandidateDto[] = action.payload.content.map(item => new CandidateDto(item));
      return {
        ...state,
        fetching: false,
        candidates: [ ...state.candidates, ...list ],
        hasMoreItems: action.payload.content.length > 0,
        totalElements: action.payload.totalElements
      };
    }

    case actions.RESET_PURCHASED_LIST_SUCCESS:
      return {
        ...state,
        candidates: [],
        hasMoreItems: true
      };

    default:
      return state;
  }
};
