import * as actions from './stats.constants';

import type { MatchStatsType } from './stats.type';

const initialState: MatchStatsType = {
  fetching: false,
  jobOffersCount: 0,
  matchedCandidatesCount: 0,
  newMatchedCandidateCount: 0,
  matchedNewCandidatesCount: 0
};

export default (state = initialState, action): MatchStatsType => {
  switch (action.type) {

    case actions.GET_MATCH_STATS_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case actions.GET_MATCH_STATS_SUCCESS:
      return {
        ...state,
        fetching: false,
        jobOffersCount: action.jobOffersCount,
        matchedCandidatesCount: action.matchedCandidatesCount,
        matchedNewCandidatesCount: action.matchedNewCandidatesCount,
        newMatchedCandidateCount: action.matchedNewCandidatesCount
      };

    case actions.GET_MATCH_STATS_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.RESET_MATCH_STATS_SUCCESS:
      return {
        ...state,
        fetching: false,
        jobOffersCount: 0,
        matchedCandidatesCount: 0,
        matchedNewCandidatesCount: 0
      };

    default:
      return state;
  }
};
