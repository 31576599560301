// @flow
import { Component } from 'react';

import './image-crop.scss';
import './image-crop.responsive.scss';

type Crop = {
  unit: string,
  width: number,
  height: number,
  locked: boolean,
  aspect: number
}

type Props = {
  crop: Crop,
  file: string,
  showRoundIcon?: boolean,
  showSquareIcon?: boolean,
  clearImageState: () => void,
  change: (file: File) => void,
}

type State = {
  crop: Crop,
  croppedImageUrl: string,
  imageRef: HTMLImageElement
}

export class Settings extends Component<Props> {

  static defaultProps = {
    crop: {
      unit: '%',
      width: 100,
      height: 100,
      locked: true,
      aspect: 1
    },
    file: '',
    showRoundIcon: true,
    showSquareIcon: true,
    clearImageState: () => {},
    save: (file: File) => {}
  };

  state: State = {
    crop: this.props.crop,
    croppedImageUrl: '',
    imageRef: null
  };

}
