import { PaginationDto } from 'middleware/dto/pagination';

export class ApplicationsParams extends PaginationDto {

  jobTitleId: string;

  constructor (data?: ApplicationsParams) {
    super(data);
    if (data) {
      this.jobTitleId = data.jobTitleId;
    }
  }

}
