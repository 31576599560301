import { StatsDto } from 'rest/stats/stats.dto';

export class SentApplicationsStatsDto {

  total: number;
  sentTemplateJobCount: StatsDto[] = [new StatsDto()];

  constructor (data?: SentApplicationsStatsDto) {
    if (data) {
      this.total = data.total;
      this.sentTemplateJobCount = data.sentTemplateJobCount;
    }
  }

}
