import * as actions from './purchased.constants';

import { PaginationDto } from 'middleware/dto/pagination';

import { List } from 'rest/list/list.dto';
import { CandidateDto } from 'rest/candidates/candidate.dto';

import { getPurchasedCandidates } from 'rest/companies/purchased/purchased.rest';

const getPurchasedCandidatesRequest = () => ({
  type: actions.GET_COMPANY_PURCHASED_CANDIDATES_REQUEST
});

const getPurchasedCandidatesSuccess = (payload: List<CandidateDto>) => ({
  type: actions.GET_COMPANY_PURCHASED_CANDIDATES_SUCCESS,
  payload
});

const getPurchasedCandidatesFailure = (error: string) => ({
  type: actions.GET_COMPANY_PURCHASED_CANDIDATES_FAILURE,
  error
});

const resetPurchasedListSuccess = (error: string) => ({
  type: actions.RESET_PURCHASED_LIST_SUCCESS,
  error
});

export const resetPurchasedList = () => {
  return dispatch => dispatch(resetPurchasedListSuccess());
};

export const getAllPurchasedCandidates = (companyId: string, params: PaginationDto) => {
  return dispatch => {
    dispatch(getPurchasedCandidatesRequest());
    return getPurchasedCandidates(companyId, params)
      .then(response => dispatch(getPurchasedCandidatesSuccess(response.data)))
      .catch(error => dispatch(getPurchasedCandidatesFailure(error)));
  };
};
