import { SalaryRangeDto } from 'rest/salary-range/salary-range.dto';

export class JobRequirementsDto {

  unitIds: string[];
  jobTypes: string[];
  branchIds: string[];
  relocation: boolean;
  availableFrom: string;
  jobTitleIds: string[];
  salary: SalaryRangeDto;
  workingHoursPerMonth: number;

  constructor (data?: JobRequirementsDto) {
    if (data) {
      this.salary = data.salary;
      this.unitIds = data.unitIds;
      this.jobTypes = data.jobTypes;
      this.branchIds = data.branchIds;
      this.relocation = data.relocation;
      this.jobTitleIds = data.jobTitleIds;
      this.availableFrom = data.availableFrom;
      this.workingHoursPerMonth = data.workingHoursPerMonth;
    }
  }

}
