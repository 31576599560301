// @flow
import { Component } from 'react';

import './cookies.policy-modal.scss';

type State = {
  cookiesOpen: boolean
}

export class Setting extends Component<State> {

  state: State = {
    cookiesOpen: false
  };

}
