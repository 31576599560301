// @flow
import React, { Fragment } from 'react';

import InfiniteScroll from 'react-infinite-scroller';

import { connectStore } from 'middleware/connect';

import { Spinner } from 'components/elements';
import { ElementsList } from 'components/elements-list';

import { CandidateDto } from 'rest/candidates/candidate.dto';
import { CandidatesParams } from 'rest/public/candidates/candidates.params';

import { CandidateRow } from './../row/row';
import { PlaceholderList } from './../placeholder/placeholder';

import { stateToProps, dispatchToProps, Setting } from './list.setting';

@connectStore(stateToProps, dispatchToProps)
export class CandidatesList extends Setting {

  loadCandidateList (page: number = 0) {
    const params: CandidatesParams = new CandidatesParams(this.props.candidatesFilter);
    params.page = page;
    this.props.getAllCandidates(params);
  }

  openCandidateProfile (selectedCandidateId: string) {
    this.props.history.push(`/portal/guest/candidates/${selectedCandidateId}/profile`);
  }

  initCandidatesList (): HTMLElement {
    return this.props.candidates && this.props.candidates.map((candidate: CandidateDto, index: number) => (
      <CandidateRow
        key={index}
        onClick={() => this.openCandidateProfile(candidate.id)}
        candidate={candidate}/>
    ));
  }

  initEmptyList () : HTMLElement {
    return !this.props.candidates || !this.props.candidates.length ? (
      <PlaceholderList/>
    ) : null;
  }

  render () {
    return (
      <Fragment>
        {
          <InfiniteScroll
            useWindow={false}
            threshold={100}
            pageStart={0}
            hasMore={this.props.hasMoreItems}
            loadMore={() => this.loadCandidateList(this.props.currentPage)}>
            <ElementsList>{this.initCandidatesList()}</ElementsList>
          </InfiniteScroll>
        }
        { this.initEmptyList() }
        <Spinner show={this.props.fetching}/>
      </Fragment>
    );
  }

}
