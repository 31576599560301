export class JobOfferStatus {

  status: string;

  constructor (data?: JobOfferStatus) {
    if (data) {
      this.status = data.status;
    }
  }

}
