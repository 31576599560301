import { JobOffersMatchesDto } from 'rest/candidates/job-offers-matches/job-offers-matches.dto';
import { LocationsDto } from 'rest/companies/job-offers-matches/matched-candidates/locations/locations.dto';
import * as actions from './new-job-offers.constants';

import type { NewJobOffersMatchesType } from './new-job-offers.type';

const initialState: NewJobOffersMatchesType = {
  count: 0,
  content: [],
  locations: [],
  fetching: false,
  numberOfElements: 0,
  totalElements: 0
};

export default function (state: NewJobOffersMatchesType = initialState, action): NewJobOffersMatchesType {
  switch (action.type) {
    case actions.GET_NEW_JOB_OFFERS_MATCHES_REQUEST:
      return {
        ...state,
        fetching: true,
        hasMoreItems: false
      };

    case actions.GET_NEW_JOB_OFFERS_MATCHES_SUCCESS:
      return {
        ...state,
        fetching: false,
        content: action.payload.content.map(jobOffer => new JobOffersMatchesDto(jobOffer)),
        totalElements: action.payload.totalElements,
        numberOfElements: action.payload.numberOfElements
      };

    case actions.GET_NEW_JOB_OFFERS_MATCHES_FAILURE:
      return {
        ...state,
        fetching: false,
        content: [],
        numberOfElements: 0
      };

    case actions.GET_NEW_JOB_OFFERS_MATCHES_COUNT_SUCCESS:
      return {
        ...state,
        count: action.payload.totalElements
      };

    case actions.RESET_NEW_JOB_OFFERS_MATCHES_SUCCESS:
      return {
        ...state,
        fetching: false,
        numberOfElements: 0
      };

    case actions.RESET_NEW_JOB_OFFERS_BUBBLE_SUCCESS:
      return {
        ...state,
        totalElements: state.totalElements - 1
      };

    case actions.GET_JOB_OFFERS_MATCHES_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.locations.map(location => new LocationsDto(location))
      };

    default:
      return state;
  }
}
