/* eslint padded-blocks: ["error", "never"] */
import React, { Component } from 'react';
import '../styles/semantic.min.css';
import '../styles/styles.css';
// import { Container } from 'components/layouts';
// import { Plans, Comment, Customers } from 'containers/information';
export class GDPRMain extends Component {
  // render () {
  //   return (
  //     <div className="pricing-container">
  //       <Container>
  //         <Plans/>
  //       </Container>
  //       <Comment/>
  //       <Container>
  //         <Customers/>
  //       </Container>
  //     </div>
  //   );
  // }
  render () {
    return (
      <div className="gdpr-main">
        <div className="ui container main-content">
          <h1 className="ui header main-title"> Information About You </h1>
          <div className="ui grid">
            <div className="five wide column">
              <div className="ui card chats">
                <div className="content">
                  <div className="logo-container">
                    <span className="icon" />
                  </div>
                  <div className="center aligned header"> Chats </div>
                  <div className="center aligned description">
                    <p> Information about chats between recruiter and candidate. </p>
                  </div>
                </div>
                <div className="extra content">
                  <div className="center aligned author">
                    <a href="/information/gdpr/chat"> View Chats </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="five wide column">
              <div className="ui card cv">
                <div className="content">
                  <div className="logo-container">
                    <span className="icon" />
                  </div>
                  <div className="center aligned header"> CV </div>
                  <div className="center aligned description">
                    <p> All information about you experience, educations, skills, job requirements and so on. </p>
                  </div>
                </div>
                <div className="extra content">
                  <div className="center aligned author">
                    <a href="/information/gdpr/candidate-skills"> View Skills </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="five wide column">
              <div className="ui card invites">
                <div className="content">
                  <div className="logo-container">
                    <span className="icon" />
                  </div>
                  <div className="center aligned header"> Candidate`s invites </div>
                  <div className="center aligned description">
                    <p> List of invites from recruiter. </p>
                  </div>
                </div>
                <div className="extra content">
                  <div className="center aligned author">
                    <a href="/information/gdpr/candidate-invite"> View Invites </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="five wide column">
              <div className="ui card candidate-posts">
                <div className="content">
                  <div className="logo-container">
                    <span className="icon" />
                  </div>
                  <div className="center aligned header"> Candidate`s posts </div>
                  <div className="center aligned description">
                    <p> Information about candidate posts. </p>
                  </div>
                </div>
                <div className="extra content">
                  <div className="center aligned author">
                    <a href="/information/gdpr/candidate-posts"> View Posts </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="five wide column">
              <div className="ui card personal-info">
                <div className="content">
                  <div className="logo-container">
                    <span className="icon" />
                  </div>
                  <div className="center aligned header"> Profile Info </div>
                  <div className="center aligned description">
                    <p> Information about you personal data. </p>
                  </div>
                </div>
                <div className="extra content">
                  <div className="center aligned author">
                    <a href="/information/gdpr/candidate-info"> View Profile Info </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="five wide column">
              <div className="ui card job-offer-viewed">
                <div className="content">
                  <div className="logo-container">
                    <span className="icon" />
                  </div>
                  <div className="center aligned header"> Candidate viewed job offer </div>
                  <div className="center aligned description">
                    <p> Information about Job offers that was viewed. </p>
                  </div>
                </div>
                <div className="extra content">
                  <div className="center aligned author">
                    <a href="/information/gdpr/candidate-job-offer-viewed"> View job offers </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="five wide column">
              <div className="ui card favorite-job-offers">
                <div className="content">
                  <div className="logo-container">
                    <span className="icon" />
                  </div>
                  <div className="center aligned header"> Favorite job offers </div>
                  <div className="center aligned description">
                    <p> List of favorite job offers. </p>
                  </div>
                </div>
                <div className="extra content">
                  <div className="center aligned author">
                    <a href="/information/gdpr/candidate-job-offers"> View Job Offers </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="five wide column">
              <div className="ui card candidate-application">
                <div className="content">
                  <div className="logo-container">
                    <span className="icon" />
                  </div>
                  <div className="center aligned header"> Candidate`s send applications </div>
                  <div className="center aligned description">
                    <p> Information about send applications from apply to job offer. </p>
                  </div>
                </div>
                <div className="extra content">
                  <div className="center aligned author">
                    <a href="/information/gdpr/candidate-send-application"> View send applications </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="five wide column">
              <div className="ui card favorite-companies">
                <div className="content">
                  <div className="logo-container">
                    <span className="icon" />
                  </div>
                  <div className="center aligned header"> Favorite companies </div>
                  <div className="center aligned description">
                    <p> List of favorites companies. </p>
                  </div>
                </div>
                <div className="extra content">
                  <div className="center aligned author">
                    <a href="/information/gdpr/candidate-favorite-companies"> View Companies </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="five wide column">
              <div className="ui card companies-viewed-profile">
                <div className="content">
                  <div className="logo-container">
                    <span className="icon" />
                  </div>
                  <div className="center aligned header"> Companies viewed profile candidate </div>
                  <div className="center aligned description">
                    <p> Information  about companies profile that was viewed by you. </p>
                  </div>
                </div>
                <div className="extra content">
                  <div className="center aligned author">
                    <a href="/information/gdpr/candidate-companies-viewed-profile"> View companies </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="five wide column">
              <div className="ui card companies-following-candidate">
                <div className="content">
                  <div className="logo-container">
                    <span className="icon" />
                  </div>
                  <div className="center aligned header"> Companies following candidate </div>
                  <div className="center aligned description">
                    <p> List of companies following candidate. </p>
                  </div>
                </div>
                <div className="extra content">
                  <div className="center aligned author">
                    <a href="/information/gdpr/candidate-companies-following-candidate"> View Following Candidates </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="five wide column">
              <div className="ui card candidate-application-template">
                <div className="content">
                  <div className="logo-container">
                    <span className="icon" />
                  </div>
                  <div className="center aligned header"> Candidate`s application template </div>
                  <div className="center aligned description">
                    <p> Information about custom and EJ templates. </p>
                  </div>
                </div>
                <div className="extra content">
                  <div className="center aligned author">
                    <a href="/information/gdpr/candidate-application-template"> View Templates </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
