import { Component } from 'react';

import { TransProps } from 'react-i18next';
import { bindActionCreators, Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { getJobOfferWithSkills } from 'services/portal/candidate/jobs/job-offers/job-offers.actions';

import './job-offer-header.scss';

type Props = {
  t: TransProps,
  children: any
};

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  jobOffer: state.portal.candidate.jobs.jobOffers.jobOffer
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getJobOfferWithSkills
}, dispatch);
