export class SalaryRangeDto {

  min: number;
  max: number;
  salaryType: string;
  workingHoursPerMonth: number;

  constructor (data?: SalaryRangeDto) {
    if (data) {
      this.min = data.min;
      this.max = data.max;
      this.salaryType = data.salaryType;
      this.workingHoursPerMonth = data.workingHoursPerMonth;
    }
  }

}
