// @flow
import React, { Children, cloneElement } from 'react';
import cn from 'classnames';

import type { Props } from './radio-group.setting';

import { Setting } from './radio-group.setting';

export class RadioGroup extends Setting {

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.defaultValue !== this.state.selectedItem) {
      this.setState({
        selectedItem: nextProps.defaultValue
      });
    }
  }

  changeRadioGroupState (selectedItem: string) {
    this.props.change(selectedItem);
    this.setState({ selectedItem });
  }

  render () {
    const radioGroupClasess: string = cn([
      [this.props.className],
      'radio-group',
      {
        'radio-inline': this.props.inline
      }
    ]);
    return (
      <div className={radioGroupClasess}>
        {this.props.label ? <span className="radio-label-title">{this.props.label}</span> : null}
        {
          Children.map(this.props.children, child => cloneElement(child, {
            selected: this.state.selectedItem,
            change: this.changeRadioGroupState.bind(this)
          }))
        }
      </div>
    );
  }

}
