import * as actions from './companies.constants';

import { BrandsDto } from 'rest/brand/brand.dto';
import { BranchDto } from 'rest/branch/branch.dto';
import { CompanyDto } from 'rest/companies/companies.dto';
import { PremiumCostDto } from 'rest/companies/premium-cost/premium-cost.dto';

import { getBrands } from 'rest/brand/brand.rest';
import { getBranches } from 'rest/branch/branch.rest';
import { getCompanyPremiumCost } from 'rest/companies/premium-cost/premium-cost.rest';
import { updateCompany, getCompanyById } from 'rest/companies/companies.rest';

const getBrandsRequest = () => ({
  type: actions.GET_COMPANY_BRANDS_REQUEST
});

const getBrandsSuccess = (brands: BrandsDto[]) => ({
  type: actions.GET_COMPANY_BRANDS_SUCCESS,
  brands
});

const getBrandsFailure = (error: string) => ({
  type: actions.GET_COMPANY_BRANDS_FAILURE,
  error
});

const getBranchRequest = () => ({
  type: actions.GET_COMPANY_BRANCH_REQUEST
});

const getBranchSuccess = (branch: BranchDto[]) => ({
  type: actions.GET_COMPANY_BRANCH_SUCCESS,
  branch
});

const getBranchFailure = (error: string) => ({
  type: actions.GET_COMPANY_BRANCH_FAILURE,
  error
});

const updateCompanyRequest = () => ({
  type: actions.UPDATE_COMPANY_REQUEST
});

const updateCompanySuccess = (company: CompanyDto) => ({
  type: actions.UPDATE_COMPANY_SUCCESS,
  company
});

const updateCompanyFailure = (error: string) => ({
  type: actions.UPDATE_COMPANY_FAILURE,
  error
});

const getCompanyByIdRequest = () => ({
  type: actions.GET_COMPANY_BY_ID_REQUEST
});

const getCompanyByIdSuccess = (company: CompanyDto) => ({
  type: actions.GET_COMPANY_BY_ID_SUCCESS,
  company
});

const getCompanyByIdFailure = (error: string) => ({
  type: actions.GET_COMPANY_BY_ID_FAILURE,
  error
});

const getCompanySubscriptionCostRequest = () => ({
  type: actions.GET_COMPANY_PREMIUM_COST_REQUEST
});

const getCompanySubscriptionCostSuccess = (payload: PremiumCostDto) => ({
  type: actions.GET_COMPANY_PREMIUM_COST_SUCCESS,
  payload
});

const getCompanySubscriptionCostFailure = (error: string) => ({
  type: actions.GET_COMPANY_PREMIUM_COST_FAILURE,
  error
});

export const getAllBrands = (data: BrandsDto) => {
  return dispatch => {
    dispatch(getBrandsRequest());
    return getBrands(data)
      .then(response => dispatch(getBrandsSuccess(response.data)))
      .catch(error => dispatch(getBrandsFailure(error)));
  };
};

export const getAllBranches = (data: BranchDto) => {
  return dispatch => {
    dispatch(getBranchRequest());
    return getBranches(data)
      .then(response => dispatch(getBranchSuccess(response.data)))
      .catch(error => dispatch(getBranchFailure(error)));
  };
};

export const updateRecruiterCompany = (companyId: string, data: CompanyDto) => {
  return dispatch => {
    dispatch(updateCompanyRequest());
    return updateCompany(companyId, data)
      .then(response => dispatch(updateCompanySuccess(response)))
      .catch(error => dispatch(updateCompanyFailure(error.response.data.message)));
  };
};

export const getRecruiterCompanyById = (companyId: string): CompanyDto => {
  return dispatch => {
    dispatch(getCompanyByIdRequest());
    return getCompanyById(companyId)
      .then(response => dispatch(getCompanyByIdSuccess(response.data)))
      .catch(error => dispatch(getCompanyByIdFailure(error)));
  };
};

export const getCompanyPremiumCostRequest = (companyId: string): PremiumCostDto => {
  return dispatch => {
    dispatch(getCompanySubscriptionCostRequest());
    return getCompanyPremiumCost(companyId)
      .then(response => dispatch(getCompanySubscriptionCostSuccess(response.data)))
      .catch(error => dispatch(getCompanySubscriptionCostFailure(error)));
  };
};
