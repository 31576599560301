// @flow
import React from 'react';

import { connectStore } from 'middleware/connect';

import { Video } from 'components/video';

import { Modal } from 'containers/portal/modal-container';

import { Setting, stateToProps, dispatchToProps } from './menu-video-modal.setting';

import videoAskMeJSON from './recruiterVideo.json';
import videoAskMeCandidateJSON from './candidatesVideo.json';
import { getLocalization } from 'middleware/localization';
const language = getLocalization();
@connectStore(stateToProps, dispatchToProps)
export class MenuVideoModal extends Setting {

  componentDidMount () {
    let videoURl = '';
    let videoPoster = '';
    let showDemoVideo = false;
    if (language === 'de' && this.props.locationPath.indexOf('recruiter') > -1 &&
     this.props.locationPath.indexOf('ask-me') === -1 &&
     this.props.locationPath.indexOf('/recruiter/balance-board/create') === -1) {
      if (this.props.locationPath.indexOf('balance-board') > -1 && !this.props.isExistingOffersListModalOpen) {
        videoURl = videoAskMeJSON.balanceBoard.url ? videoAskMeJSON.balanceBoard.url : undefined;
        videoPoster = videoAskMeJSON.balanceBoard.posterImage ? videoAskMeJSON.balanceBoard.posterImage : undefined;
        showDemoVideo = this.props.showBalanceBoardVideo;
      } if (this.props.locationPath.indexOf('settingsVideo') > -1 && !this.props.isExistingOffersListModalOpen) {
        videoURl = videoAskMeJSON.settings.url ? videoAskMeJSON.settings.url : undefined;
        videoPoster = videoAskMeJSON.settings.posterImage ? videoAskMeJSON.settings.posterImage : undefined;
        showDemoVideo = this.props.showSettingsVideo;
      } else if (this.props.locationPath.indexOf('balance-board') > -1 && this.props.isExistingOffersListModalOpen) {
        videoURl = videoAskMeJSON.balanceBoard.urlReuse ? videoAskMeJSON.balanceBoard.urlReuse : undefined;
        videoPoster = videoAskMeJSON.balanceBoard.posterImage ? videoAskMeJSON.balanceBoard.posterImage : undefined;
        showDemoVideo = this.props.showBalanceBoardReUseVideo;
        this.props.showOffersPopUpOnVideoClose(this.props.showBalanceBoardReUseVideo);
      } else if (this.props.locationPath.indexOf('job-offers') > -1) {
        videoURl = videoAskMeJSON.jobOffers.url ? videoAskMeJSON.jobOffers.url : undefined;
        videoPoster = videoAskMeJSON.jobOffers.posterImage ? videoAskMeJSON.jobOffers.posterImage : undefined;
        showDemoVideo = this.props.showJobOffersVideo;
      } else if (this.props.locationPath.indexOf('match') > -1) {
        videoURl = videoAskMeJSON.match.url ? videoAskMeJSON.match.url : undefined;
        videoPoster = videoAskMeJSON.match.posterImage ? videoAskMeJSON.match.posterImage : undefined;
        showDemoVideo = this.props.showMatchVideo;
      } else if (this.props.locationPath.indexOf('chat') > -1) {
        videoURl = videoAskMeJSON.chat.url ? videoAskMeJSON.chat.url : undefined;
        videoPoster = videoAskMeJSON.chat.posterImage ? videoAskMeJSON.chat.posterImage : undefined;
        showDemoVideo = this.props.showChatVideo;
      } else if (this.props.locationPath.indexOf('calendar') > -1) {
        videoURl = videoAskMeJSON.calendar.url ? videoAskMeJSON.calendar.url : undefined;
        videoPoster = videoAskMeJSON.calendar.posterImage ? videoAskMeJSON.calendar.posterImage : undefined;
        showDemoVideo = this.props.showCalendarVideo;
      } else if (this.props.locationPath.indexOf('favorites') > -1) {
        videoURl = videoAskMeJSON.favorites.url ? videoAskMeJSON.favorites.url : undefined;
        videoPoster = videoAskMeJSON.favorites.posterImage ? videoAskMeJSON.favorites.posterImage : undefined;
        showDemoVideo = this.props.showFavoritesVideo;
      } else if (this.props.locationPath.indexOf('follow') > -1) {
        videoURl = videoAskMeJSON.follow.url ? videoAskMeJSON.follow.url : undefined;
        videoPoster = videoAskMeJSON.follow.posterImage ? videoAskMeJSON.follow.posterImage : undefined;
        showDemoVideo = this.props.showFollowingVideo;
      } else if (this.props.locationPath.indexOf('candidates') > -1) {
        videoURl = videoAskMeJSON.candidates.url ? videoAskMeJSON.candidates.url : undefined;
        videoPoster = videoAskMeJSON.candidates.posterImage ? videoAskMeJSON.candidates.posterImage : undefined;
        showDemoVideo = this.props.showCandidatesVideo;
      } else if (this.props.locationPath.indexOf('statistics') > -1) {
        videoURl = videoAskMeJSON.statistics.url ? videoAskMeJSON.statistics.url : undefined;
        videoPoster = videoAskMeJSON.statistics.posterImage ? videoAskMeJSON.statistics.posterImage : undefined;
        showDemoVideo = this.props.showStatisticsVideo;
      }
    } else if (language === 'de' && this.props.locationPath.indexOf('candidate') > -1 &&
    this.props.locationPath.indexOf('ask-me') === -1) {
      if (this.props.locationPath.indexOf('balance-board') > -1 && !this.props.isExistingOffersListModalOpen) {
        videoURl = videoAskMeCandidateJSON.balanceBoard.url ? videoAskMeCandidateJSON.balanceBoard.url : undefined;
        videoPoster = videoAskMeCandidateJSON.balanceBoard.posterImage
          ? videoAskMeCandidateJSON.balanceBoard.posterImage
          : undefined;
        showDemoVideo = this.props.showBalanceBoardCandidateVideo;
      } else if (this.props.locationPath.indexOf('balance-board') > -1 && this.props.isExistingOffersListModalOpen) {
        videoURl = videoAskMeCandidateJSON.balanceBoard.urlReuse
          ? videoAskMeCandidateJSON.balanceBoard.urlReuse
          : undefined;
        videoPoster = videoAskMeCandidateJSON.balanceBoard.posterImage
          ? videoAskMeCandidateJSON.balanceBoard.posterImage
          : undefined;
        showDemoVideo = this.props.showBalanceBoardReUseVideo;
        this.props.showOffersPopUpOnVideoClose(this.props.showBalanceBoardReUseVideo);
      } if (this.props.locationPath.indexOf('settingsVideo') > -1 && !this.props.isExistingOffersListModalOpen) {
        videoURl = videoAskMeCandidateJSON.settings.url ? videoAskMeCandidateJSON.settings.url : undefined;
        videoPoster = videoAskMeCandidateJSON.settings.posterImage
          ? videoAskMeCandidateJSON.settings.posterImage
          : undefined;
        showDemoVideo = this.props.showCandidateSettingsVideo;
      } else if (this.props.locationPath.indexOf('jobs') > -1) {
        videoURl = videoAskMeCandidateJSON.jobs.url ? videoAskMeCandidateJSON.jobs.url : undefined;
        videoPoster = videoAskMeCandidateJSON.jobs.posterImage ? videoAskMeCandidateJSON.jobs.posterImage : undefined;
        showDemoVideo = this.props.showJobCandidateVideo;
      } else if (this.props.locationPath.indexOf('match') > -1) {
        videoURl = videoAskMeCandidateJSON.match.url ? videoAskMeCandidateJSON.match.url : undefined;
        videoPoster = videoAskMeCandidateJSON.match.posterImage ? videoAskMeCandidateJSON.match.posterImage : undefined;
        showDemoVideo = this.props.showMatchCandidateVideo;
      } else if (this.props.locationPath.indexOf('chat') > -1) {
        videoURl = videoAskMeCandidateJSON.chat.url ? videoAskMeCandidateJSON.chat.url : undefined;
        videoPoster = videoAskMeCandidateJSON.chat.posterImage ? videoAskMeCandidateJSON.chat.posterImage : undefined;
        showDemoVideo = this.props.showChatCandidateVideo;
      } else if (this.props.locationPath.indexOf('applications') > -1) {
        videoURl = videoAskMeCandidateJSON.applications.url ? videoAskMeCandidateJSON.applications.url : undefined;
        videoPoster = videoAskMeCandidateJSON.applications.posterImage
          ? videoAskMeCandidateJSON.applications.posterImage
          : undefined;
        showDemoVideo = this.props.showApplicationCandidateVideo;
      } else if (this.props.locationPath.indexOf('favorites') > -1) {
        videoURl = videoAskMeCandidateJSON.favorites.url ? videoAskMeCandidateJSON.favorites.url : undefined;
        videoPoster = videoAskMeCandidateJSON.favorites.posterImage
          ? videoAskMeCandidateJSON.favorites.posterImage
          : undefined;
        showDemoVideo = this.props.showFavoritesCandidateVideo;
      } else if (this.props.locationPath.indexOf('follow') > -1) {
        videoURl = videoAskMeCandidateJSON.follow.url ? videoAskMeCandidateJSON.follow.url : undefined;
        videoPoster = videoAskMeCandidateJSON.follow.posterImage
          ? videoAskMeCandidateJSON.follow.posterImage
          : undefined;
        showDemoVideo = this.props.showFollowingCandidateVideo;
      } else if (this.props.locationPath.indexOf('cv') > -1) {
        videoURl = videoAskMeCandidateJSON.cv.url ? videoAskMeCandidateJSON.cv.url : undefined;
        videoPoster = videoAskMeCandidateJSON.cv.posterImage ? videoAskMeCandidateJSON.cv.posterImage : undefined;
        showDemoVideo = this.props.showCvCandidateVideo;
      } else if (this.props.locationPath.indexOf('posts') > -1) {
        videoURl = videoAskMeCandidateJSON.posts.url ? videoAskMeCandidateJSON.posts.url : undefined;
        videoPoster = videoAskMeCandidateJSON.posts.posterImage ? videoAskMeCandidateJSON.posts.posterImage : undefined;
        showDemoVideo = this.props.showMyBlogCandidateVideo;
      }
    }
    this.setState({
      open: (!this.props.showRecruiterVideo && !this.props.showCandidateVideo && videoURl) ? showDemoVideo : false,
      videoUrl: videoURl,
      videoPoster: videoPoster
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.locationPath.indexOf('/recruiter/balance-board/create') > -1 &&
        this.props.locationPath.indexOf('/recruiter/balance-board') > -1) {
      return;
    }
    if (prevProps !== this.props && this.props.locationPath.indexOf(prevProps.locationPath) === -1) {
      let videoURl = '';
      let videoPoster = '';
      let showDemoVideo = false;
      if (language === 'de' && this.props.locationPath.indexOf('recruiter') > -1 &&
      this.props.locationPath.indexOf('ask-me') === -1 &&
      this.props.locationPath.indexOf('/recruiter/balance-board/create') === -1) {
        if (this.props.locationPath.indexOf('balance-board') > -1 && !this.props.isExistingOffersListModalOpen) {
          videoURl = videoAskMeJSON.balanceBoard.url ? videoAskMeJSON.balanceBoard.url : undefined;
          videoPoster = videoAskMeJSON.balanceBoard.posterImage ? videoAskMeJSON.balanceBoard.posterImage : undefined;
          showDemoVideo = this.props.showBalanceBoardVideo;
        } else if (this.props.locationPath.indexOf('balance-board') > -1 && this.props.isExistingOffersListModalOpen) {
          videoURl = videoAskMeJSON.balanceBoard.urlReuse ? videoAskMeJSON.balanceBoard.urlReuse : undefined;
          videoPoster = videoAskMeJSON.balanceBoard.posterImage ? videoAskMeJSON.balanceBoard.posterImage : undefined;
          showDemoVideo = this.props.showBalanceBoardReUseVideo;
          this.props.showOffersPopUpOnVideoClose(this.props.showBalanceBoardReUseVideo);
        } if (this.props.locationPath.indexOf('settingsVideo') > -1 && !this.props.isExistingOffersListModalOpen) {
          videoURl = videoAskMeJSON.settings.url ? videoAskMeJSON.settings.url : undefined;
          videoPoster = videoAskMeJSON.settings.posterImage ? videoAskMeJSON.settings.posterImage : undefined;
          showDemoVideo = this.props.showSettingsVideo;
        } else if (this.props.locationPath.indexOf('job-offers') > -1) {
          videoURl = videoAskMeJSON.jobOffers.url ? videoAskMeJSON.jobOffers.url : undefined;
          videoPoster = videoAskMeJSON.jobOffers.posterImage ? videoAskMeJSON.jobOffers.posterImage : undefined;
          showDemoVideo = this.props.showJobOffersVideo;
        } else if (this.props.locationPath.indexOf('match') > -1) {
          videoURl = videoAskMeJSON.match.url ? videoAskMeJSON.match.url : undefined;
          videoPoster = videoAskMeJSON.match.posterImage ? videoAskMeJSON.match.posterImage : undefined;
          showDemoVideo = this.props.showMatchVideo;
        } else if (this.props.locationPath.indexOf('chat') > -1) {
          videoURl = videoAskMeJSON.chat.url ? videoAskMeJSON.chat.url : undefined;
          videoPoster = videoAskMeJSON.chat.posterImage ? videoAskMeJSON.chat.posterImage : undefined;
          showDemoVideo = this.props.showChatVideo;
        } else if (this.props.locationPath.indexOf('calendar') > -1) {
          videoURl = videoAskMeJSON.calendar.url ? videoAskMeJSON.calendar.url : undefined;
          videoPoster = videoAskMeJSON.calendar.posterImage ? videoAskMeJSON.chat.posterImage : undefined;
          showDemoVideo = this.props.showCalendarVideo;
        } else if (this.props.locationPath.indexOf('favorites') > -1) {
          videoURl = videoAskMeJSON.favorites.url ? videoAskMeJSON.favorites.url : undefined;
          videoPoster = videoAskMeJSON.favorites.posterImage ? videoAskMeJSON.favorites.posterImage : undefined;
          showDemoVideo = this.props.showFavoritesVideo;
        } else if (this.props.locationPath.indexOf('follow') > -1) {
          videoURl = videoAskMeJSON.follow.url ? videoAskMeJSON.follow.url : undefined;
          videoPoster = videoAskMeJSON.follow.posterImage ? videoAskMeJSON.follow.posterImage : undefined;
          showDemoVideo = this.props.showFollowingVideo;
        } else if (this.props.locationPath.indexOf('candidates') > -1) {
          videoURl = videoAskMeJSON.candidates.url ? videoAskMeJSON.candidates.url : undefined;
          videoPoster = videoAskMeJSON.candidates.posterImage ? videoAskMeJSON.candidates.posterImage : undefined;
          showDemoVideo = this.props.showCandidatesVideo;
        } else if (this.props.locationPath.indexOf('statistics') > -1) {
          videoURl = videoAskMeJSON.statistics.url ? videoAskMeJSON.statistics.url : undefined;
          videoPoster = videoAskMeJSON.statistics.posterImage ? videoAskMeJSON.statistics.posterImage : undefined;
          showDemoVideo = this.props.showStatisticsVideo;
        }
      } else if (language === 'de' && this.props.locationPath.indexOf('candidate') > -1 &&
       this.props.locationPath.indexOf('ask-me') === -1) {
        if (this.props.locationPath.indexOf('balance-board') > -1) {
          videoURl = videoAskMeCandidateJSON.balanceBoard.url ? videoAskMeCandidateJSON.balanceBoard.url : undefined;
          videoPoster = videoAskMeCandidateJSON.balanceBoard.posterImage
            ? videoAskMeCandidateJSON.balanceBoard.posterImage
            : undefined;
          showDemoVideo = this.props.showBalanceBoardCandidateVideo;
        } if (this.props.locationPath.indexOf('settingsVideo') > -1 && !this.props.isExistingOffersListModalOpen) {
          videoURl = videoAskMeCandidateJSON.settings.url ? videoAskMeCandidateJSON.settings.url : undefined;
          videoPoster = videoAskMeCandidateJSON.settings.posterImage
            ? videoAskMeCandidateJSON.settings.posterImage
            : undefined;
          showDemoVideo = this.props.showCandidateSettingsVideo;
        } else if (this.props.locationPath.indexOf('jobs') > -1) {
          videoURl = videoAskMeCandidateJSON.jobs.url ? videoAskMeCandidateJSON.jobs.url : undefined;
          videoPoster = videoAskMeCandidateJSON.jobs.posterImage ? videoAskMeCandidateJSON.jobs.posterImage : undefined;
          showDemoVideo = this.props.showJobCandidateVideo;
        } else if (this.props.locationPath.indexOf('match') > -1) {
          videoURl = videoAskMeCandidateJSON.match.url ? videoAskMeCandidateJSON.match.url : undefined;
          videoPoster = videoAskMeCandidateJSON.match.posterImage
            ? videoAskMeCandidateJSON.match.posterImage
            : undefined;
          showDemoVideo = this.props.showMatchCandidateVideo;
        } else if (this.props.locationPath.indexOf('chat') > -1) {
          videoURl = videoAskMeCandidateJSON.chat.url ? videoAskMeCandidateJSON.chat.url : undefined;
          videoPoster = videoAskMeCandidateJSON.chat.posterImage ? videoAskMeCandidateJSON.chat.posterImage : undefined;
          showDemoVideo = this.props.showChatCandidateVideo;
        } else if (this.props.locationPath.indexOf('applications') > -1) {
          videoURl = videoAskMeCandidateJSON.applications.url ? videoAskMeCandidateJSON.applications.url : undefined;
          videoPoster = videoAskMeCandidateJSON.applications.posterImage
            ? videoAskMeCandidateJSON.applications.posterImage
            : undefined;
          showDemoVideo = this.props.showApplicationCandidateVideo;
        } else if (this.props.locationPath.indexOf('favorites') > -1) {
          videoURl = videoAskMeCandidateJSON.favorites.url ? videoAskMeCandidateJSON.favorites.url : undefined;
          videoPoster = videoAskMeCandidateJSON.favorites.posterImage
            ? videoAskMeCandidateJSON.favorites.posterImage
            : undefined;
          showDemoVideo = this.props.showFavoritesCandidateVideo;
        } else if (this.props.locationPath.indexOf('follow') > -1) {
          videoURl = videoAskMeCandidateJSON.follow.url ? videoAskMeCandidateJSON.follow.url : undefined;
          videoPoster = videoAskMeCandidateJSON.follow.posterImage
            ? videoAskMeCandidateJSON.follow.posterImage
            : undefined;
          showDemoVideo = this.props.showFollowingCandidateVideo;
        } else if (this.props.locationPath.indexOf('cv') > -1) {
          videoURl = videoAskMeCandidateJSON.cv.url ? videoAskMeCandidateJSON.cv.url : undefined;
          videoPoster = videoAskMeCandidateJSON.cv.posterImage ? videoAskMeCandidateJSON.cv.posterImage : undefined;
          showDemoVideo = this.props.showCvCandidateVideo;
        } else if (this.props.locationPath.indexOf('posts') > -1) {
          videoURl = videoAskMeCandidateJSON.posts.url ? videoAskMeCandidateJSON.posts.url : undefined;
          videoPoster = videoAskMeCandidateJSON.posts.posterImage
            ? videoAskMeCandidateJSON.posts.posterImage
            : undefined;
          showDemoVideo = this.props.showMyBlogCandidateVideo;
        }
      }
      this.setState({
        open: videoURl ? showDemoVideo : false,
        videoUrl: videoURl,
        videoPoster: videoPoster
      });
    }
  }

  // componentWillReceiveProps (nextProps: Props) {
  //   this.setState({
  //     open: (!nextProps.showRecruiterVideo && !nextProps.showCandidateVideo && language === 'de')
  //       ? nextProps.showBalanceBoardVideo
  //       : false
  //   });
  // }

  handleOnBoardingModal = () => {
    this.setState({
      open: false
    });
    if (this.props.showOffersPopUpOnVideoClose) {
      this.props.showOffersPopUpOnVideoClose(false);
    }
  };

  skipVideoOnLoad = () => {
    alert('Dieses Video findest Du auch im ASK ME Bereich!');
    const { disableBalanceDashboardVideo, disableCandidateDashboardVideo } = this.props;
    this.props.locationPath.indexOf('candidate') > -1
      ? disableCandidateDashboardVideo(this.props.locationPath)
      : disableBalanceDashboardVideo(!this.props.isExistingOffersListModalOpen
        ? this.props.locationPath : '/reuseOffers');
    if (this.props.showOffersPopUpOnVideoClose) {
      this.props.showOffersPopUpOnVideoClose(false);
    }
    this.handleOnBoardingModal();
  }

  render () {
    return (
      <Modal
        className="menu-video-modal"
        overlay
        animation="fade"
        open= {this.state.open}
        hideModalAvailable={false}
        hideModal={this.handleOnBoardingModal}
        closeOnOverlay={this.handleOnBoardingModal}>
        <div style={{ position: 'absolute', zIndex: 500, marginLeft: '70%', marginTop: '-5%', cursor: 'pointer' }}
          onClick={this.skipVideoOnLoad} >
          <a className="dontShowMeagainCSS">Bitte nicht noch einmal zeigen</a></div>
        <Video style={{ position: 'relative' }}
          onBoarding
          videoPoster={this.state.videoPoster}
          videoURL={this.state.videoUrl}/>
      </Modal>
    );
  }

}
