import React, { Fragment, Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { FollowListPage } from './following';
import { FollowersListPage } from './followers';
import { JobOfferPreviewPage } from './preview';

export class FollowPage extends Component {

  render () {
    return (
      <Fragment>
        <Switch>
          <Redirect
            exact
            from="/portal/candidate/follow"
            to="/portal/candidate/follow/following"/>
        </Switch>
        <Route
          path="/portal/candidate/follow/following"
          component={FollowListPage}/>
        <Route
          path="/portal/candidate/follow/followers"
          component={FollowersListPage}/>
        <Route
          path="/portal/candidate/follow/:type/:jobOfferId/preview"
          exact
          component={JobOfferPreviewPage}/>
      </Fragment>
    );
  }

}
