// @flow
import { Component } from 'react';

import { getLocalization } from 'middleware/localization';

import 'react-phone-input-2/dist/style.css';

import './phone-control.scss';
import './phone-control.responsive.scss';

export type Props = {
  label: string,
  error: boolean,
  required: boolean,
  errorText: string,
  searchable: boolean,
  defaultValue: string,
  placeholder: string,
  change: (value: string) => void
}

type State = {
  phone: string,
  localization: string
}

export class Setting extends Component<Props, State> {

  static defaultProps = {
    label: '',
    error: false,
    errorText: '',
    searchable: true,
    required: false,
    defaultValue: '',
    placeholder: 'Your Phone Number'
  };

  state: State = {
    phone: this.props.defaultValue,
    localization: getLocalization()
  };

}
