import { ImageDto } from 'rest/images/image.dto';
import { VideoDto } from '../../videos/video.dto';
export class AskMeResponseDto {

  image: ImageDto;
  content: string;
  description: string;
  video: VideoDto;
  locale: String;

  constructor (data?: AskMeResponseDto) {
    if (data) {
      this.image = new ImageDto(data.image);
      this.video = new VideoDto(data.video);
      this.content = data.content;
      this.description = data.description;
      this.locale = data.locale;
    }
  }

}
