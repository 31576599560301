// @flow
import React from 'react';

import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';

import { Button } from 'components/buttons';
import { Container } from 'components/layouts';
import { InputControl, MultiSelect } from 'components/controls';
import { getUserType } from 'middleware/user-type';
import { JobTitleDto } from 'rest/job-title/job-title.dto';
import { isLoggedIn } from 'middleware/auth';
import { JobOffersParams } from 'rest/public/job-offers/job-offers.params';
import { CandidatesParams } from 'rest/public/candidates/candidates.params';

import { Setting, dispatchToProps, stateToProps } from './jumbotron.setting';
import { getBrands } from '../../../rest/brand/brand.rest';

@connectStore(stateToProps, dispatchToProps, true)
export class Jumbotron extends Setting {

  componentDidMount () {
    const query: JobTitleDto = new JobTitleDto();
    query.size = 500;
    this.props.getAllJobTitles(query);
    getBrands().then(r => {
      const brandList = r.data.content;
      console.log(r.data.content);
      console.log(brandList);
      this.setState({ brands: brandList });
      this.handleChange = this.handleChange.bind(this);
    });
  }

  handleChange(e) {
    this.setState({ brand: e.id });
  }

  applyRecruiterFilters () {
    const userType: string = getUserType();
    const params: CandidatesParams = new CandidatesParams();
    params.location = this.props.searchCandidates.location;
    params.jobTitleId = this.props.searchCandidates.jobTitleId;

    this.props.initCandidatesFilter(params);
    isLoggedIn() ? this.props.history.push(`/portal/${userType}/candidates`)
      : this.props.history.push('/portal/guest/candidates');
  }

  applyCandidateFilters () {
    const userType: string = getUserType();
    const params: JobOffersParams = new JobOffersParams();
    params.location = this.props.searchJobOffers.location;
    params.jobTitleId = this.props.searchJobOffers.jobTitleId;
    params.brandId = this.state.brand;
    this.props.initJobsFilter(params);
    isLoggedIn() ? this.props.history.push(`/portal/${userType}/jobs`)
      : this.props.history.push('/portal/guest/jobs');
  }

  showCandidatesSearch () {
    const { t } = this.props;
    return (
      <form
        key={1}
        onSubmit={() => this.applyRecruiterFilters()}
        className="candidates-search">
        <div className="manage">
          <MultiSelect
            items={this.props.jobTitles}
            defaultValue={this.props.searchCandidates.jobTitleId}
            change={value => this.props.saveSearchCandidatesJobTitle(value.id)}
            isMulti={false}
            placeholder={t('landing.jumbotron.search.jobs.jobTitle')}/>
        </div>
        <div className="country">
          <InputControl
            type="text"
            notes="icon"
            value={this.props.searchCandidates.location}
            change={value => this.props.saveSearchCandidatesLocation(value)}
            maxLength={1000}
            stickyNotes={true}
            placeholder={t('landing.jumbotron.search.jobs.location')}/>
        </div>
        <Button
          onClick={() => this.applyRecruiterFilters()}
          recruiter
          disabled={isLoggedIn() && getUserType() === 'candidate' && true}
        >
          <Trans i18nKey="landing.jumbotron.search.jobs.button">
            Search
          </Trans>
        </Button>
        {isLoggedIn() && getUserType() === 'candidate' &&
          <span className="disable-msg">Only Recruiters can use this functionality</span>}
      </form>
    );
  }

  showJobsSearch () {
    const { t } = this.props;
    return (
      <form
        key={2}
        onSubmit={() => this.applyCandidateFilters()}
        className="job-search">
        <div className="manage">
          <MultiSelect
            items={this.props.jobTitles}
            defaultValue={this.props.searchJobOffers.jobTitleId}
            change={value => this.props.saveSearchJobsJobTitle(value.id)}
            isMulti={false}
            placeholder={t('landing.jumbotron.search.candidates.jobTitle')}/>
        </div>

        {isLoggedIn() && <div className="brand">
          <MultiSelect
            items={this.state.brands}
            defaultValue={this.state.brand}
            change={value => this.handleChange(value)}
            isMulti={false}
            placeholder="Search by brand..."/>
        </div>}

        <div className="country">
          <InputControl
            type="text"
            notes="icon"
            value={this.props.searchJobOffers.location}
            change={value => this.props.saveSearchJobsLocation(value)}
            maxLength={1000}
            stickyNotes={true}
            placeholder={t('landing.jumbotron.search.candidates.location')}/>
        </div>
        <Button
          onClick={() => this.applyCandidateFilters()}
          candidate
          disabled={isLoggedIn() && getUserType() === 'recruiter' && true}
        >
          <Trans i18nKey="landing.jumbotron.search.candidates.button">
            Search
          </Trans>
        </Button>
        {isLoggedIn() && getUserType() === 'recruiter' &&
          <span className="disable-msg">Only Candidates can use this functionality</span>}
      </form>
    );
  }

  render () {
    return (
      <div className="jumbotron-container">
        <Container>
          <div className="information-container">
            <h1 className="title">
              <Trans i18nKey="landing.jumbotron.title">
                Hospitality Jobs.
              </Trans>
            </h1>
            <h2 className="description">
              <Trans i18nKey="landing.jumbotron.description">
                Start your success with a perfect match.
              </Trans>
            </h2>
            <div className="search-container">
              <div className="search-tabs">
                <div
                  className={`tab ${this.state.activeTab === 0 && 'active'}`}
                  onClick={() => this.setState({ activeTab: 0 })}>
                  <Trans i18nKey="landing.jumbotron.search.candidates.title">
                    Employee
                  </Trans>
                </div>
                <div
                  className={`tab ${this.state.activeTab === 1 && 'active'}`}
                  onClick={() => this.setState({ activeTab: 1 })}>
                  <Trans i18nKey="landing.jumbotron.search.jobs.title">
                    Job Offers
                  </Trans>
                </div>
              </div>
              <div className="search-content">
                {
                  this.state.activeTab === 0 ? this.showCandidatesSearch() : this.showJobsSearch()
                }
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }

}
