// @flow
import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { recruiterSignUp } from 'services/auth/recruiter/auth.action';

export type Props = {
  fetching?: boolean,
  redirect?: boolean,
  errorText?: string,
};

export type State = {
  email: string,
  password: string,
  errorText: string,
  acceptRules: boolean,
  passwordCheck: string,
  errorCollection: Set<string>,
  acceptNotification: boolean
}

export class Settings extends Component<Props, State> {

  state: State = {
    email: '',
    password: '',
    errorText: '',
    acceptRules: true,
    passwordCheck: '',
    errorCollection: new Set(),
    acceptNotification: true
  };

}

export const stateToProps = (state: GlobalState) => ({
  fetching: state.auth.recruiter.signUp.fetching,
  errorText: state.auth.recruiter.signUp.errorText,
  redirect: state.auth.recruiter.signUp.signUpSuccess
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  recruiterSignUp
}, dispatch);
