import * as actions from './job-offer.constants';

import { uniqueArray } from 'middleware/unique-array';

import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

import type { CompanyJobOfferState } from './job-offer.type';

const initialState: CompanyJobOfferState = {
  status: '',
  jobOffers: [],
  currentPage: 0,
  fetching: false,
  totalElements: 0,
  statsJobOffers: [],
  hasMoreItems: true
};

export default (state = initialState, action): CompanyJobOfferState => {
  switch (action.type) {

    case actions.GET_COMPANY_JOB_OFFERS_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case actions.GET_COMPANY_JOB_OFFERS_SUCCESS: {
      const content = action.payload.content.map(jobOffer => new JobOffersResponseDto(jobOffer));
      const filteredJobs = content.filter((item: JobOffersResponseDto) => item.jobOfferStatus === 'PUBLISHED');
      return {
        ...state,
        fetching: false,
        statsJobOffers: filteredJobs,
        publishedJobOffers: action.payload.content,
        jobOffers: uniqueArray(action.payload.number
          ? [...state.jobOffers, ...content]
          : content, 'jobOfferId'),
        currentPage: action.payload.number + 1,
        hasMoreItems: action.payload.content.length > 0,
        totalElements: action.payload.totalElements
      };
    }

    case actions.DELETE_JOB_OFFER_SUCCESS:
      return {
        ...state,
        fetching: false,
        jobOffers: state.jobOffers.filter(item => item.jobOfferId !== action.jobOfferId),
        totalElements: state.totalElements - 1
      };

    case actions.CHANGE_JOB_OFFER_STATUS_SUCCESS:
      return {
        ...initialState,
        status: action.payload
      };

    case actions.RESET_JOB_OFFERS_SUCCESS:
      return {
        ...initialState
      };

    default:
      return state;
  }
};
