import React from 'react';
import { Trans } from 'react-i18next';
import { Setting } from './hoga-netz-recommendation.settings';

export class HogaNetzRecommendation extends Setting {

  render () {
    const text = `Mit ExcellentJobs hat das HoGa-Netz einen Partner an seiner Seite und im Netzwerk, 
        welcher Betrieben im Gastgewerbe ganz neue Möglichkeiten bei der Personalfindung bietet. 
        Die digitale Plattform macht es möglich, dass sowohl Kandidaten als auch Betriebe ihre Anforderungen, 
        Stärken und Skills angeben können, damit es zu einem "perfect match" kommt. 
        Somit erhöhen sich für Arbeitgeber die Chancen, genau den passenden Mitarbeiter 
        zu finden, welcher auf die ausgeschriebene Stelle passt. 
        Durch das Matching-System, einer Chatfunktion, einer Follow- und Favoritenfunktion, 
        sowie einer sofortigen Kontaktmöglichkeit ergeben sich 
        in der digitalen Zeit völlig neue Perspektiven für Arbeitgeber und Kandidaten zueinander zu finden. 
        Recruiting effektiv und anders gedacht.`;
    return (
      <div className="hoga-netz-recommendation-container card">
        <div className="hoga-netz-recommendation-info">
          <h4 className="title">
            <Trans i18nKey="landing.hoga-netz-recommendations.title">Eine Partnerschaft für die Zukunft</Trans>
          </h4>
          <div className="text">
            <Trans i18nKey="landing.hoga-netz-recommendations.description">{text}</Trans>
          </div>
          <span className="author">
            <Trans i18nKey="landing.hoga-netz-recommendations.author">Helmut Tranow, Geschäftsführer HoGa-Netz</Trans>
          </span>
        </div>
        <div className="hoga-netz-recommendation-photo">
          <img src="./images/landing/Helmut_Tranow_HOGA_NETZ.jpeg" alt="Helmut Tranow HoGa-Netz"/>
        </div>
      </div>
    );
  }

}
