import React from 'react';
import { Trans } from 'react-i18next';

import './beta-intro-step1.scss';

type Props = {
  className: string
};

export const BetaIntroStep1 = (props: Props) => {
  const { className } = props;

  return (
    <div className={className}>
      <div className="beta-intro-step1-image">
        <img className="img-responsive" src="./images/landing/exsellent-jobs-beta.svg"
          alt="Exsellent-jobs" title="Exsellent-jobs"/>
      </div>
      <h4 className="beta-intro-step1-title">
        <Trans i18nKey="landing.beta-intro.step1.title">
          Glad to see you on our beta test.
        </Trans>
      </h4>
      <p className="beta-intro-step1-subtitle">
        <Trans i18nKey="landing.beta-intro.step1.subtitle">
          Be a part of our new and innovating recruiting system.
        </Trans>
      </p>
    </div>
  );
};
