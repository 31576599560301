// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { ChatRequestDto } from 'rest/chats/chat.request.dto';
import { CandidateDto } from 'rest/candidates/candidate.dto';
import { CurrentRecruiterDto } from 'rest/recruiter/current/current.dto';
import { RecruiterFollowParams } from 'rest/recruiter/follow/follow.params';
import { RecruiterFavoriteParams } from 'rest/recruiter/favorites/favorite.params';

import { blockUserCandidate } from 'services/portal/recruiter/account/account.actions';
import { createChatConversation } from 'services/portal/chats/conversations/conversations.actions';
import { changeCurrentCandidate } from 'services/portal/recruiter/candidate/all/candidates.actions';

import {
  addingCandidateToFollowing,
  deletingCandidateFromFollowing
} from 'services/portal/recruiter/follow/following/following.actions';

import {
  addingCandidateToFavorite,
  deletingCandidateFromFavorite
} from 'services/portal/recruiter/favorites/favorites.actions';

import './row.scss';

type Props = {
  chatId: string,
  onClick: () => void,
  candidate: CandidateDto,
  currentUser: CurrentRecruiterDto,
  addToFollow: (params: RecruiterFollowParams) => void,
  createChatConversation: (chat: ChatRequestDto) => void,
  addToFavorite: (params: RecruiterFavoriteParams) => void,
  addToFavorite: (params: RecruiterFavoriteParams) => void,
  changeCurrentCandidate: (candidate: CandidateDto) => void,
  deleteFromFollow: (params: RecruiterFollowParams) => void,
  deleteFromFavorite: (params: RecruiterFavoriteParams) => void,
  blockUserCandidate: (candidateId: string, recruiterId: string) => void,
};

type State = {
  isMenuOpen: boolean
};

export class Setting extends Component<Props, State> {

  static defaultProps = {
    onClick: () => {},
    blockUserCandidate: () => {},
    candidate: new CandidateDto(),
    currentUser: new CurrentRecruiterDto()
  };

  state: State = {
    isMenuOpen: false
  };

}

export const stateToProps = (state: GlobalState) => ({
  chatId: state.portal.chats.conversations.createdConversation,
  currentUser: state.portal.recruiter.account.current.currentUser
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  blockUserCandidate,
  createChatConversation,
  changeCurrentCandidate,
  addToFollow: addingCandidateToFollowing,
  addToFavorite: addingCandidateToFavorite,
  deleteFromFollow: deletingCandidateFromFollowing,
  deleteFromFavorite: deletingCandidateFromFavorite
}, dispatch);
