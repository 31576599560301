// @flow
import React from 'react';

import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Setting } from './match.setting';

@connectTranslation()
export class Match extends Setting {

  render () {
    return (
      <div className="match-container">
        <div className="match-title">
          <Trans i18nKey="information.works.matching.title">
            Matching
          </Trans>
        </div>
        <div className="match-description">
          <Trans i18nKey="information.works.matching.description">
            Set of skills and their level influences suitable vacancies, the system selects a job according to the
            level of the candidate and offers the best options.
          </Trans>
        </div>
        <div className="match-image">
          <img
            src="/images/information/match.png"
            alt="This match image"/>
        </div>
      </div>
    );
  }

}
