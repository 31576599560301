import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { ListDto, SearchDto } from 'middleware/dto';

import { searchDocuments, resetSearchResult } from 'services/portal/documents/documents.actions';

import './documents.scss';
import './documents.responsive.scss';

export type Props = {
  t: TransProps,
  open: boolean,
  className?: string,
  candidate?: boolean,
  recruiter?: boolean,
  fetching: boolean,
  close: () => void,
  documents?: ListDto[],
  hasMoreItems: boolean,
  resetSearchResult: () => void,
  search: (params: SearchDto) => void,
  addNewItems: (items: ListDto[]) => void
}

type State = {
  searchItem: string,
  selectedItems: ListDto[]
}

export class Settings extends Component<Props, State> {

  static defaultProps: Props = {
    documents: [],
    className: '',
    candidate: false,
    recruiter: false,
    open: false,
    close: () => {},
    search: () => {},
    resetSearchResult: () => {},
    addNewItems: () => {}
  };

  state: State = {
    searchItem: '',
    selectedItems: []
  };

}

export const stateToProps = (state: GlobalState) => ({
  fetching: state.portal.documents.fetching,
  documents: state.portal.documents.searchDocuments,
  hasMoreItems: state.portal.documents.hasMoreItems
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetSearchResult,
  search: searchDocuments
}, dispatch);
