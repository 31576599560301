import React from 'react';
import { NavLink } from 'react-router-dom';

import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Card } from 'components/layouts';
import { LinkButton } from 'components/buttons';

import { Settings } from './menu.setting';

@connectTranslation()
export class CandidateMenu extends Settings {

  render () {
    return (
      <Card>
        <div className="profile-nav-container">
          <div className="profile-nav-header">
            <div className="person-container">
              <div className="avatar-wrapper">
                <img
                  src="/images/shared/userpick.jpg"
                  alt="avatar"/>
              </div>
              <div className="name-wrapper">
                <h3 className="user-name">
                  Excellent Jobs
                </h3>
                <span className="user-role">
                  Employee - Guest
                </span>
              </div>
            </div>
            <div className="register-container">
              <LinkButton
                link="/auth/candidate/sign-up"
                candidate>
                Register
              </LinkButton>
            </div>
          </div>
          <div className="profile-nav-body">
            <div className="profile-nav-links">
              <NavLink
                className="profile-nav-link"
                activeClassName="active"
                to="/portal/guest/jobs">
                <div className="tile">
                  <span className="icon-search"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.guest.candidate.menu.jobSearch">
                    Job Search
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                className="profile-nav-link"
                activeClassName="active"
                to="/auth/candidate/sign-up">
                <div className="tile">
                  <span className="icon-balance-board"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.guest.candidate.menu.balanceBoard">
                    Balance Board
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                className="profile-nav-link"
                activeClassName="active"
                to="/auth/candidate/sign-up">
                <div className="tile">
                  <span className="icon-match"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.guest.candidate.menu.match">
                    Match
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                className="profile-nav-link"
                activeClassName="active"
                to="/auth/candidate/sign-up">
                <div className="tile">
                  <span className="icon-applications"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.guest.candidate.menu.applications">
                    Applications
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                to="/auth/candidate/sign-up"
                className="profile-nav-link"
                activeClassName="active">
                <div className="tile">
                  <span className="icon-chat"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.guest.candidate.menu.chat">
                    Chat
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                className="profile-nav-link"
                activeClassName="active"
                to="/auth/candidate/sign-up">
                <div className="tile">
                  <span className="icon-sv"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.guest.candidate.menu.cv">
                    CV
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                className="profile-nav-link"
                activeClassName="active"
                to="/auth/candidate/sign-up">
                <div className="tile">
                  <span className="icon-favorites"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.guest.candidate.menu.favorites">
                    Favorites
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                className="profile-nav-link"
                activeClassName="active"
                to="/auth/candidate/sign-up">
                <div className="tile">
                  <span className="icon-follow"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.guest.candidate.menu.follow">
                    Follow
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                className="profile-nav-link"
                activeClassName="active"
                to="/auth/candidate/sign-up">
                <div className="tile">
                  <span className="icon-my-posts"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.guest.candidate.menu.blog">
                    My Blog
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                className="profile-nav-link"
                activeClassName="active"
                to="/auth/candidate/sign-up">
                <div className="tile">
                  <span className="icon-ask-me"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.guest.candidate.menu.askMe">
                    Ask Me
                  </Trans>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </Card>
    );
  }

}
