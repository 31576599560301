// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { StatsDto } from 'rest/stats/stats.dto';
import { CandidatesParams } from 'rest/public/candidates/candidates.params';

import type { SearchCandidatesType } from 'services/portal/recruiter/candidate/search/search.type';

import { initCandidatesFilter } from 'services/portal/recruiter/candidate/all/candidates.actions';
import { saveSearchCandidatesBranch } from 'services/portal/recruiter/candidate/search/search.actions';
import { getCandidatesStatistic, resetCandidatesStats } from 'services/portal/recruiter/candidate/stats/stats.actions';

type Props = {
  t: TransProps,
  stats: StatsDto[],
  filters: CandidatesParams,
  searchCandidates: SearchCandidatesType,
  initCandidatesFilter: (filter: CandidatesParams) => void,
  resetCandidatesStats: () => void,
  getCandidatesStatistic: (companyId: string) => void,
  saveSearchCandidatesBranch: (branchId?: string) => void
}

type State = {
  filters: string[]
}

export class Settings extends Component<Props> {

  state: State = {
    filters: {
      'default': 'DEFAULT',
      'location': 'LOCATION',
      'available-from': 'AVAILABLE_FROM',
      'salary-expectations': 'SALARY_EXPECTATION'
    }
  };

}

export const stateToProps = (state: GlobalState) => ({
  stats: state.portal.recruiter.candidate.candidatesStats.stats,
  searchCandidates: state.portal.recruiter.candidate.searchCandidates,
  filters: state.portal.recruiter.candidate.allCandidates.candidatesFilter
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  initCandidatesFilter,
  resetCandidatesStats,
  getCandidatesStatistic,
  saveSearchCandidatesBranch
}, dispatch);
