// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { StatsDto } from 'rest/stats/stats.dto';
import { CandidateDto } from 'rest/candidates/candidate.dto';

import { changeFavoriteFilter } from 'services/portal/candidate/jobs/favorite/favorite.actions';

import {
  getFavoriteStatistic,
  resetFavoriteStatistic
} from 'services/portal/candidate/jobs/favorite/stats/stats.actions';

type Props = {
  t?: TransProps,
  total: number,
  stats: StatsDto[],
  companyId: string,
  currentUser: CandidateDto,
  changeFavoriteFilter: (companyId: string) => void,
  getFavoriteStatistic: (candidateId: string) => void,
  resetFavoriteStatistic: () => void
}

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  stats: state.portal.candidate.jobs.favoriteStats.stats,
  total: state.portal.candidate.jobs.favoriteStats.totalElements,
  companyId: state.portal.candidate.jobs.favorite.filters.companyId,
  currentUser: state.portal.candidate.account.current.candidateData
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  changeFavoriteFilter,
  getFavoriteStatistic,
  resetFavoriteStatistic
}, dispatch);
