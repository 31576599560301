// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';

import type { Props } from './auth-container.setting';
import { Settings, stateToProps, dispatchToProps } from './auth-container.setting';

@connect(stateToProps, dispatchToProps)
export class CandidateAuthContainer extends Settings {

  componentDidMount () {
    const candidate: CurrentCandidateDto = this.props.currentCandidate;
    if (candidate.id) {
      this.fetchCandidateData(candidate.id);
    }
    this.props.getCurrentCandidate();
  }

  componentDidUpdate (prevProps: Props) {
    if (prevProps.currentCandidate.id !== this.props.currentCandidate.id) {
      this.fetchCandidateData(this.props.currentCandidate.id);
    }
  }

  fetchCandidateData (candidateId: string) {
    this.props.getCandidateBalanceBoard(candidateId);
    this.props.getCandidateSkillsById(candidateId);
  }

  checkCandidatePortalRoute (): boolean {
    return this.props.location.pathname === '/portal/candidate';
  }

  render () {
    return (
      <Fragment>
        {
          this.checkCandidatePortalRoute() && <Redirect to="/portal/candidate/balance-board"/>
        }
        { this.props.children }
      </Fragment>
    );
  }

}
