import { Component } from 'react';

import './logo.scss';

type Props = {
  userType: string,
  logOut: () => void,
  candidateRegistered: boolean,
  recruiterRegistered: boolean,
};

export class Settings extends Component<Props> {

  static defaultProps: Props = {
    userType: 'guest',
    logOut: () => {},
    candidateRegistered: false,
    recruiterRegistered: false
  };

}
