// @flow
import { Component } from 'react';
import { TransProps } from 'react-i18next';

import { JobOffersMatchesDto } from 'rest/companies/job-offers-matches/job-offers-matches.dto';

import './job-offer-row.scss';

export type Props = {
  t: TransProps,
  onClick: (id: string) => void,
  jobOffer: JobOffersMatchesDto
};

export class Setting extends Component<Props> {

  static defaultProps: Props = {
    onClick: () => {},
    jobOffer: new JobOffersMatchesDto()
  };

}
