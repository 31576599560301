// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { StatsDto } from 'rest/stats/stats.dto';
import { CurrentRecruiterDto } from 'rest/recruiter/current/current.dto';

import { changeCandidateFollowingFilter } from 'services/portal/recruiter/follow/following/following.actions';
import { changeCandidateFollowersFilter } from 'services/portal/recruiter/follow/followers/followers.actions';

import {
  getFollowersStatistic,
  getFollowingStatistic,
  resetFollowsStatistic
} from 'services/portal/recruiter/follow/stats/stats.actions';

type Props = {
  t: TransProps,
  currentUser: CurrentRecruiterDto,
  followingStats: StatsDto[],
  totalFollowing: number,
  followersStats: StatsDto[],
  totalFollowers: number,
  followingUnitId: string,
  followersUnitId: string,
  resetFollowsStatistic: () => void,
  getFollowingStatistic: (recruiterId: string) => void,
  getFollowersStatistic: (recruiterId: string) => void,
  changeCandidateFollowingFilter: (unitId?: string) => void,
  changeCandidateFollowersFilter: (unitId?: string) => void
}

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  currentUser: state.portal.recruiter.account.current.currentUser,
  followingStats: state.portal.recruiter.follow.stats.followingStats,
  totalFollowing: state.portal.recruiter.follow.stats.totalFollowingElements,
  followersStats: state.portal.recruiter.follow.stats.followersStats,
  totalFollowers: state.portal.recruiter.follow.stats.totalFollowersElements,
  followingUnitId: state.portal.recruiter.follow.following.unitId,
  followersUnitId: state.portal.recruiter.follow.followers.unitId
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetFollowsStatistic,
  getFollowingStatistic,
  getFollowersStatistic,
  changeCandidateFollowingFilter,
  changeCandidateFollowersFilter
}, dispatch);
