import * as actions from './notifications.constants';

import { AlertsDto } from 'rest/alerts/alerts.dto';

import { getAllNotifications, readNotification, getUserNotificationsCount } from 'rest/alerts/alerts.rest';

const getNotificationsSuccess = (notifications = []) => ({
  type: actions.GET_NOTIFICATIONS_SUCCESS,
  notifications
});

const getNotificationsFailure = (error: string) => ({
  type: actions.GET_NOTIFICATIONS_FAILURE,
  error
});

const readNotificationRequest = () => ({
  type: actions.READ_NOTIFICATION_REQUEST
});

const readNotificationSuccess = () => ({
  type: actions.READ_NOTIFICATION_SUCCESS
});

const readNotificationFailure = (error: string) => ({
  type: actions.READ_NOTIFICATION_FAILURE,
  error
});

const getNotificationsCountRequest = () => ({
  type: actions.GET_NOTIFICATIONS_COUNT_REQUEST
});

const getNotificationsCountSuccess = (count: number) => ({
  type: actions.GET_NOTIFICATIONS_COUNT_SUCCESS,
  count
});

const getNotificationsCountFailure = (error: string) => ({
  type: actions.GET_NOTIFICATIONS_COUNT_FAILURE,
  error
});

export const resetNotificationsListSuccess = () => ({
  type: actions.RESET_NOTIFICATIONS_LIST_SUCCESS
});

export const resetNotificationsList = () => {
  return dispatch => {
    dispatch(resetNotificationsListSuccess());
  };
};

export const getNotifications = (filters?: AlertsDto) => {
  return dispatch => {
    return getAllNotifications(filters)
      .then(response => dispatch(getNotificationsSuccess(response.data.content)))
      .catch(error => dispatch(getNotificationsFailure(error)));
  };
};

export const markNotificationAsRead = (alertId: string) => {
  return dispatch => {
    dispatch(readNotificationRequest());
    return readNotification(alertId)
      .then(() => dispatch(readNotificationSuccess()))
      .catch(error => dispatch(readNotificationFailure(error)));
  };
};

export const getNotificationsCount = (filters?: AlertsDto) => {
  return dispatch => {
    dispatch(getNotificationsCountRequest());
    return getUserNotificationsCount(filters)
      .then(response => dispatch(getNotificationsCountSuccess(response.data)))
      .catch(error => dispatch(getNotificationsCountFailure(error)));
  };
};
