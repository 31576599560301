export class InitResetPasswordDto {

  email: string;

  constructor (data?: InitResetPasswordDto) {
    if (data) {
      this.email = data.email;
    }
  }

}
