// @flow
import * as actions from './favorites.constants';

import { AxiosResponse } from 'axios';
import { Action, Dispatch } from 'redux';

import { List } from 'rest/list/list.dto';

import { CandidateDto } from 'rest/candidates/candidate.dto';
import { RecruiterFavoriteParams } from 'rest/recruiter/favorites/favorite.params';

import {
  getFavoritesCandidate,
  addCandidateToFavorite,
  deleteCandidateFromFavorite
} from 'rest/recruiter/favorites/favorites.rest';

import {
  getFavoriteUnitStatistic,
  getFavoriteJobTitlesStatistic
} from 'services/portal/recruiter/favorites/stats/stats.actions';

const getFavoritesCandidateRequest = (): Action => ({
  type: actions.GET_FAVORITES_CANDIDATE_REQUEST
});

const getFavoritesCandidateSuccess = (data: List<CandidateDto>): Action => ({
  type: actions.GET_FAVORITES_CANDIDATE_SUCCESS,
  payload: data
});

const getFavoritesCandidateFailure = (): Action => ({
  type: actions.GET_FAVORITES_CANDIDATE_FAILURE
});

const addCandidateToFavoriteRequest = (): Action => ({
  type: actions.ADD_FAVORITES_CANDIDATE_REQUEST
});

const addCandidateToFavoriteSuccess = (): Action => ({
  type: actions.ADD_FAVORITES_CANDIDATE_SUCCESS
});

const addCandidateToFavoriteFailure = (): Action => ({
  type: actions.ADD_FAVORITES_CANDIDATE_FAILURE
});

const deleteCandidateFavoriteRequest = (): Action => ({
  type: actions.DELETE_FAVORITES_CANDIDATE_REQUEST
});

const deleteCandidateFavoriteSuccess = (candidateId: string): Action => ({
  type: actions.DELETE_FAVORITES_CANDIDATE_SUCCESS,
  payload: candidateId
});

const deleteCandidateFavoriteFailure = (): Action => ({
  type: actions.DELETE_FAVORITES_CANDIDATE_FAILURE
});

const resetCandidateFavoriteSuccess = (): Action => ({
  type: actions.RESET_FAVORITES_CANDIDATE_SUCCESS
});

const changeCandidateFavoritesFilterSuccess = (unitId?: string, jobTitleId?: string): Action => ({
  type: actions.CHANGE_FAVORITES_CANDIDATE_FILTER_SUCCESS,
  unitId,
  jobTitleId
});

export function resetAllCandidateFavorites () {
  return (dispatch: Dispatch) => {
    dispatch(resetCandidateFavoriteSuccess());
  };
}

export function changeCandidateFavoritesFilter (unitId: string = '', jobTitleId: string = '') {
  return (dispatch: Dispatch) => {
    dispatch(changeCandidateFavoritesFilterSuccess(unitId, jobTitleId));
  };
}

export function requestFavoriteCandidates (params: RecruiterFavoriteParams) {
  return (dispatch: Dispatch) => {
    dispatch(getFavoritesCandidateRequest());
    return getFavoritesCandidate(params)
      .then((response: AxiosResponse<List<CandidateDto>>) => dispatch(getFavoritesCandidateSuccess(response.data)))
      .catch(() => dispatch(getFavoritesCandidateFailure()));
  };
}

export function addingCandidateToFavorite (params: RecruiterFavoriteParams) {
  return (dispatch: Dispatch) => {
    dispatch(addCandidateToFavoriteRequest());
    return addCandidateToFavorite(params)
      .then(() => dispatch(addCandidateToFavoriteSuccess()))
      .catch(() => dispatch(addCandidateToFavoriteFailure()));
  };
}

export function deletingCandidateFromFavorite (params: RecruiterFavoriteParams) {
  return (dispatch: Dispatch) => {
    dispatch(deleteCandidateFavoriteRequest());
    return deleteCandidateFromFavorite(params)
      .then(() => {
        dispatch(deleteCandidateFavoriteSuccess(params.candidateId));
        dispatch(getFavoriteUnitStatistic(params.recruiterId));
        dispatch(getFavoriteJobTitlesStatistic(params.recruiterId));
      })
      .catch(() => dispatch(deleteCandidateFavoriteFailure()));
  };
}
