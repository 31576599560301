import * as actions from './confirm.constants';

import { setToken } from 'middleware/auth';
import { setUserType } from 'middleware/user-type';

import { ConfirmDto } from 'rest/account/confirm/confirm.dto';
import { confirmUserRegistration, resendConfirmationEmail } from 'rest/account/confirm/confirm.rest';

const confirmSignUpRequest = () => ({
  type: actions.CONFIRM_REGISTRATION_REQUEST
});

const confirmSignUpSuccess = () => ({
  type: actions.CONFIRM_REGISTRATION_SUCCESS
});

const confirmSignUpFailure = error => ({
  type: actions.CONFIRM_REGISTRATION_FAILURE,
  error
});

const resendConfirmationEmailRequest = () => ({
  type: actions.RESEND_CONFIRMATION_EMAIL_REQUEST
});

const resendConfirmationEmailSuccess = () => ({
  type: actions.RESEND_CONFIRMATION_EMAIL_SUCCESS
});

const resendConfirmationEmailFailure = error => ({
  type: actions.RESEND_CONFIRMATION_EMAIL_FAILURE,
  error
});

const resetConfirmationEmailSuccess = () => ({
  type: actions.RESET_CONFIRMATION_EMAIL_SUCCESS
});

export const resetConfirmationEmail = () => {
  return dispatch => {
    return dispatch(resetConfirmationEmailSuccess());
  };
};

export const confirmSignUp = (data: ConfirmDto, userType) => {
  return dispatch => {
    dispatch(confirmSignUpRequest());
    return confirmUserRegistration(data)
      .then(response => {
        if (response.data) {
          setToken(response.data.accessToken, response.data.refreshToken);
          setUserType(userType);
          dispatch(confirmSignUpSuccess());
        }
      })
      .catch(error => dispatch(confirmSignUpFailure(error)));
  };
};

export const resendUserConfirmation = (data: ConfirmDto) => {
  return dispatch => {
    dispatch(resendConfirmationEmailRequest());
    return resendConfirmationEmail(data)
      .then(() => dispatch(resendConfirmationEmailSuccess()))
      .catch(error => dispatch(resendConfirmationEmailFailure(error)));
  };
};
