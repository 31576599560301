import * as actions from './blacklist.constant';

import type { BlackListState } from './blacklist.type';

const initialState: BlackListState = {
  fetching: false,
  blackList: []
};

export default (state = initialState, action): BlackListState => {
  switch (action.type) {
    case actions.BLOCK_USER_FAILURE:
    case actions.UNBLOCK_USER_FAILURE:
    case actions.GET_BLOCKED_USERS_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.BLOCK_USER_REQUEST:
    case actions.UNBLOCK_USER_REQUEST:
    case actions.GET_BLOCKED_USERS_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case actions.BLOCK_USER_SUCCESS:
      return {
        ...state,
        fetching: false
      };

    case actions.UNBLOCK_USER_SUCCESS:
      return {
        ...state,
        fetching: false,
        blackList: state.blackList.filter(item => item.id !== action.candidateId)
      };

    case actions.GET_BLOCKED_USERS_SUCCESS:
      return {
        ...state,
        fetching: false,
        blackList: action.blackList
      };

    default:
      return state;
  }
};
