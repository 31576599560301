import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { Match } from 'common/types/match';
import type { GlobalState } from 'common/types/global-state';

import { SkillsDto } from 'rest/skill/skills.dto';
import { SkillItemDto } from 'rest/skill/skill-item.dto';
import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';

import {
  getCandidateSkillsById,
  createCandidateSkillsById,
  updateCandidateSkillsById
} from 'services/portal/candidate/skills/skills.actions';

import './knowledge-level.scss';
import './knowledge-level.responsive.scss';

export type Props = {
  t: TransProps,
  match: Match,
  prev: () => void,
  redirect: boolean,
  candidateSkills: SkillsDto,
  currentCandidate: CurrentCandidateDto,
  getCandidateSkillsById: (candidateId: string) => void,
  createCandidateSkillsById: (candidateId: string, data: SkillsDto) => void,
  updateCandidateSkillsById: (candidateId: string, data: SkillsDto) => void
};

type State = {
  redirect: boolean,
  documents: SkillItemDto[],
  languageSkills: SkillItemDto[],
  softwareSkills: SkillItemDto[],
  professionalSkills: SkillItemDto[]
}

export class Settings extends Component<Props, State> {

  static defaultProps: Props = {
    prev: () => {},
    getCandidateSkillsById: () => {},
    createCandidateSkillsById: () => {},
    updateCandidateSkillsById: () => {}
  };

  state: State = {
    documents: [],
    redirect: false,
    languageSkills: [],
    softwareSkills: [],
    professionalSkills: []
  };

}

export const stateToProps = (state: GlobalState) => ({
  candidateSkills: state.portal.candidate.skills.content,
  currentCandidate: state.portal.candidate.account.current.candidateData,
  redirect: state.portal.candidate.skills.createSkillSuccess || state.portal.candidate.skills.updateSkillsSuccess
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getCandidateSkillsById,
  createCandidateSkillsById,
  updateCandidateSkillsById
}, dispatch);
