// @flow
import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { BranchResponseDto } from 'rest/branch/branch.response.dto';
import { LocationsDto } from 'rest/companies/job-offers-matches/matched-candidates/locations/locations.dto';

import { getAllBranches } from 'services/portal/recruiter/account/account.actions';
import {
  changeFollowingFilter,
  getFollowingCompaniesLocations
} from 'services/portal/candidate/jobs/follow/following/following.actions';

type Props = {
  branches: BranchResponseDto[],
  locations: LocationsDto[]
};

type State = {
  branchId: string,
  cityPlaceId: string
}

export class Settings extends Component<Props, State> {

  state: State = {
    branchId: '',
    cityPlaceId: ''
  }

}

export const stateToProps = (state: GlobalState) => ({
  branches: state.portal.recruiter.account.companies.branches,
  locations: state.portal.candidate.jobs.follow.following.locations
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  changeFollowingFilter,
  getBranches: getAllBranches,
  getLocations: getFollowingCompaniesLocations
}, dispatch);
