// @flow
import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import { connectStore } from 'middleware/connect';

import { LinkButton } from 'components/buttons';

import { PortletContainer, PortletHeader } from 'components/portlet';

import { ChatChannel, ChatPlaceholder, ChatUser, Conversations } from 'containers/portal/chat';

import { Settings, stateToProps } from './index.settings';

@connectStore(stateToProps, null, true)
export class ChatPage extends Settings {

  componentDidMount () {
    this.listenWindowWidth();
    window.addEventListener('resize', () => this.listenWindowWidth());
  }

  componentWillUnmount () {
    window.removeEventListener('resize', () => this.listenWindowWidth());
  }

  listenWindowWidth () {
    this.setState({ mobileView: window.innerWidth < 993 });
  }

  render () {
    const channelRoute = /chat\/[0-9a-fA-F]+/;
    const isMobileChannelPage = this.state.mobileView &&
      this.props.location && this.props.location.pathname.search(channelRoute) !== -1;

    return (
      <Fragment>
        <PortletContainer>
          <PortletHeader
            className={`${isMobileChannelPage ? 'mobile-channel-head' : 'mobile-chat-conversations-head'}`}
            title={!isMobileChannelPage && this.props.t('portal.candidate.chat.title')}>
            <Route
              path="/portal/candidate/chat/:id"
              component={ChatUser}/>
            {
              !isMobileChannelPage && <div className="mobile-chat-title-count">({this.props.total})</div>
            }
            {
              isMobileChannelPage && <LinkButton
                link="/portal/candidate/chat"
                inline>
                <div className="mobile-chat-channel-back">&times;</div>
              </LinkButton>
            }
          </PortletHeader>
          {
            this.state.mobileView ? <Route
              path="/portal/candidate/chat"
              exact
              component={Conversations}/> : <Route
              path="/portal/candidate/chat"
              exact
              component={ChatPlaceholder}/>
          }
          <Route
            path="/portal/candidate/chat/:id"
            component={ChatChannel}/>
        </PortletContainer>
      </Fragment>
    );
  }

}
