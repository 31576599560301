import React, { Component } from 'react';

type Props = {
  closeToast?: () => void
}

export class ToastifyClose extends Component<Props> {

  render () {
    return <img
      alt="Close me"
      src="/images/shared/cross.svg"
      onClick={this.props.closeToast}
      className="toastify-image"/>;
  }

}
