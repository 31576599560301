import React, { Fragment } from 'react';
import { createPortal } from 'react-dom';

import { withRouter } from 'react-router-dom';

import { Setting } from './modal.setting';

class Modal extends Setting {

  componentWillUnmount () {
    this.props.closeAction();
  }

  closeModal () {
    this.setState({ closeProcess: true });
    setTimeout(() => this.props.history.push(this.props.closePath), 300);
  }

  render () {
    return createPortal(
      <Fragment>
        <div className={`modal-routing ${this.props.modalClassName} ${this.state.closeProcess ? 'hide' : ''}`}>
          {this.props.children}
        </div>
        <div
          onClick={() => this.closeModal()}
          className={`modal-wrapper-routing ${this.props.wrapperClassName} ${this.state.closeProcess ? 'hide' : ''}`}
        />
      </Fragment>,
      document.getElementById('modal')
    );
  }

}

export default withRouter(Modal);
