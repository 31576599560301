import React from 'react';

import { Setting } from './table-headers.setting';

export class TableHeaders extends Setting {

  render () {
    return (
      <thead className={this.props.className}>
        <tr>
          {this.props.children}
        </tr>
      </thead>
    );
  }

}
