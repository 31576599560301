// @flow
import { Component } from 'react';
import { TransProps } from 'react-i18next';

import { SalaryRangeDto } from 'rest/salary-range/salary-range.dto';

type Props = {
  t: TransProps,
  salaryRange?: SalaryRangeDto,
};

export class Setting extends Component<Props> {

  static defaultProps: Props = {
    salaryRange: new SalaryRangeDto({
      min: null,
      max: null,
      salaryType: ''
    })
  };

}
