// @flow
import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import { TransProps } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { PortletContainer, PortletHeader, PortletBody } from 'components/portlet';

import { CandidatesList } from 'containers/portal/guest/recruiter';
import { ActiveFilterButton } from 'containers/portal/recruiter/candidates/search';

type Props = {
  t: TransProps
}
@connectTranslation()
export class CandidatesListPage extends Component<Props> {

  render () {
    return (
      <PortletContainer>
        <PortletHeader title={this.props.t('portal.guest.recruiter.candidates.list.title')}>
          <ActiveFilterButton/>
        </PortletHeader>
        <PortletBody>
          <Route
            path="/portal/guest/candidates"
            component={CandidatesList}/>
        </PortletBody>
      </PortletContainer>
    );
  }

}
