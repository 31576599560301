import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import {
  pushEducation,
  changeEducation,
  resetEducations,
  removeInternalEducation,
  getCandidateEducations,
  createCandidateEducation,
  updateCandidateEducation,
  deleteCandidateEducation
} from 'services/portal/candidate/cv/education/education.actions';

import { EducationsDto } from 'rest/candidates/educations/educations.dto';

import './education.responsive.scss';

export type Props = {
  t?: TransProps,
  createSuccess: boolean,
  educations: EducationsDto[]
}

type State = {
  createSuccess: boolean
}

export class Settings extends Component<Props, State> {

  state: State = {
    createSuccess: false
  }

}

export const stateToProps = (state: GlobalState) => ({
  educations: state.portal.candidate.cv.education.content,
  createSuccess: state.portal.candidate.cv.education.createSuccess,
  currentCandidate: state.portal.candidate.account.current.candidateData
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  pushEducation,
  changeEducation,
  resetEducations,
  removeInternalEducation,
  getCandidateEducations,
  updateCandidateEducation,
  createCandidateEducation,
  deleteCandidateEducation
}, dispatch);
