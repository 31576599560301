// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import { hideModal } from 'services/portal/modal/modal.actions';
import { clearCurrentRecruiter } from 'services/portal/recruiter/account/current/current.actions';
import { clearCurrentCandidate } from 'services/portal/candidate/account/current/current.actions';

import './sidebar.scss';
import './sidebar.responsive.scss';

type Props = {
  history: History,
  hideModal: () => void,
  activeTabIndex: number,
  clearCurrentRecruiter: () => void,
  clearCurrentCandidate: () => void
}

export class Setting extends Component<Props> {

  static defaultProps = {
    signOut: () => {},
    activeTabIndex: 0,
    onSidebarItemChange: () => {}
  };

  state = {
    activeNavLink: 0
  }

}

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  hideModal,
  clearCurrentRecruiter,
  clearCurrentCandidate
}, dispatch);
