// @flow
import { Component } from 'react';
import type { RouterHistory } from 'react-router-dom';

import type { GlobalState } from 'common/types/global-state';

type Props = {
  modalType?: string,
  history?: RouterHistory
};

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  modalType: state.portal.modal.modalType
});
