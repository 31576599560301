import { Component } from 'react';

import { TransProps } from 'react-i18next';

import moment from 'moment';

import { EventDataDto } from 'rest/alerts/alerts.event-data.dto';

export type Props = {
  eventData: EventDataDto[],
  hideModal: () => void,
  publishDate: string,
  t?: TransProps,
}

export class Setting extends Component<Props> {

  static defaultProps: Props = {
    eventData: [],
    hideModal: () => {},
    publishDate: moment().parseZone().fromNow()
  };

}
