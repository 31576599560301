// @flow
import * as actions from './search.constants';

import type { SearchJobOffersType } from './search.type';

const initialState: SearchJobOffersType = {
  branchId: '',
  unitId: '',
  jobTitleId: '',
  location: '',
  minSalary: null,
  maxSalary: null,
  workingTime: null,
  availableFrom: new Date(),
  jobType: '',
  closeToMe: false,
  companyName: '',
  publishedFrom: '',
  professionalSkills: []
};

export default function (state: SearchJobOffersType = initialState, action) {
  switch (action.type) {
    case actions.SEARCH_JOBS_SKILLS:
      return {
        ...state,
        professionalSkills: action.skills
      };

    case actions.SEARCH_JOBS_BRANCH:
      return {
        ...state,
        branchId: action.branchId
      };

    case actions.SEARCH_JOBS_UNIT:
      return {
        ...state,
        unitId: action.unitId
      };

    case actions.SEARCH_JOBS_JOB_TITLE:
      return {
        ...state,
        jobTitleId: action.jobTitleId
      };

    case actions.SEARCH_JOBS_LOCATION:
      return {
        ...state,
        location: action.location
      };

    case actions.SEARCH_JOBS_COMPANY_NAME:
      return {
        ...state,
        companyName: action.companyName
      };

    case actions.SEARCH_JOBS_SALARY_MIN:
      return {
        ...state,
        minSalary: action.minSalary
      };

    case actions.SEARCH_JOBS_SALARY_MAX:
      return {
        ...state,
        maxSalary: action.maxSalary
      };

    case actions.SEARCH_JOBS_WORKING_TIME:
      return {
        ...state,
        workingTime: action.workingTime
      };

    case actions.SEARCH_JOBS_AVAILABLE_FROM:
      return {
        ...state,
        availableFrom: action.availableFrom
      };

    case actions.SEARCH_JOBS_JOB_TYPE:
      return {
        ...state,
        jobType: action.jobType
      };

    case actions.SEARCH_JOBS_PERIOD_OF_PUBLISH:
      return {
        ...state,
        publishedFrom: action.publishedFrom
      };

    case actions.SEARCH_JOBS_CLOSE_TO_ME:
      return {
        ...state,
        closeToMe: action.closeToMe
      };

    case actions.SEARCH_JOBS_RESET:
      return {
        ...initialState,
        professionalSkills: state.professionalSkills
      };

    case actions.SEARCH_JOBS_SKILLS_RESET:
      return {
        ...state,
        professionalSkills: []
      };

    default:
      return state;
  }
}
