// @flow
import React from 'react';

import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Card } from 'components/layouts';
import { LinkButton } from 'components/buttons';

import { Setting } from './start-as.setting';

@connectTranslation()
export class StartAs extends Setting {

  render () {
    return (
      <div className="start-as-container">
        <Card className="recruiters-container">
          <div className="title">
            <Trans i18nKey="auth.employer.title">
              Start as employer
            </Trans>
          </div>
          <div className="items">
            <div className="item">
              <Trans i18nKey="auth.employer.points.0">
                and work with your team, create job offers, search employees;
              </Trans>
            </div>
            <div className="item">
              <Trans i18nKey="auth.employer.points.1">
                use our incredeble matching system with our innovative skill–analyser.
              </Trans>
            </div>
          </div>
          <div className="banner-information">
            <div className="control-container">
              <LinkButton
                link="/auth/recruiter/sign-up"
                recruiter>
                <Trans i18nKey="auth.employer.button">
                  Get Started
                </Trans>
              </LinkButton>
              <Link
                to="/auth/recruiter"
                className="login-link">
                <Trans i18nKey="auth.employer.link">
                  Join an existing account
                </Trans>
              </Link>
            </div>
          </div>
        </Card>
        <Card className="candidates-container">
          <div className="title">
            <Trans i18nKey="auth.candidate.title">
              Start as candidate
            </Trans>
          </div>
          <div className="items">
            <div className="item">
              <Trans i18nKey="auth.candidate.points.0">
                and create CV, search job, communicate with companies managers;
              </Trans>
            </div>
            <div className="item">
              <Trans i18nKey="auth.candidate.points.1">
                handle all your documents in our system and create the best application.
              </Trans>
            </div>
          </div>
          <div className="banner-information">
            <div className="control-container">
              <LinkButton
                link="/auth/candidate/sign-up"
                candidate>
                <Trans i18nKey="auth.candidate.button">
                  Get Started
                </Trans>
              </LinkButton>
              <Link
                to="/auth/candidate"
                className="login-link">
                <Trans i18nKey="auth.candidate.link">
                  Join an existing account
                </Trans>
              </Link>
            </div>
          </div>
        </Card>
      </div>
    );
  }

}
