// @flow
import React, { Fragment } from 'react';

import cn from 'classnames';

import type { DropdownType } from './dropdown.type';

import type { Props, State } from './dropdown.setting';
import { Settings } from './dropdown.setting';

export class Dropdown extends Settings {

  static getDerivedStateFromProps (nextProps: Props, state: State) {
    if ((nextProps.value || state.selectedItem) && nextProps.value !== '') {
      return {
        selectedItem: nextProps.items.find(elem => elem.id === nextProps.value) || null
      };
    }
    return null;
  }

  closeDropdown () {
    this.setState({ listOpen: false });
  }

  toggleList () {
    this.setState(prevState => ({ listOpen: !prevState.listOpen }));
  }

  selectItem (selectedItem: DropdownType) {
    this.setState({ selectedItem }, () => {
      this.closeDropdown();
      this.props.change(selectedItem);
    });
  }

  render () {
    const filterLabelClasses = cn(['filter-label', {
      'filter-label-icon': this.props.icon,
      'error': this.props.error
    }]);

    const filterOptionClasses = cn(['filter-options', {
      'mobile-right': this.props.right
    }]);

    return (
      <Fragment>
        {
          this.props.label ? <label className="form-label">
            <span className="form-label-title">{this.props.label}</span>
            {
              this.props.error ? <span className="form-label-error">
                {this.props.errorText || 'Field is required'}
              </span> : null
            }

          </label> : null
        }
        <div className={cn({ 'dropdown-inactive': this.props.disabled })}>
          {
            this.state.listOpen && <div
              className="outside"
              onClick={this.closeDropdown.bind(this)}
            />
          }
          <div className="filter-container">
            <div
              className={filterLabelClasses}
              onClick={this.toggleList.bind(this)}>
              {this.props.title ? <span className="filter-label-title">{this.props.title}:</span> : null}
              <span className="filter-label-value">
                {this.state.selectedItem ? this.state.selectedItem.name : this.props.defaultValue}
              </span>
            </div>
            {
              this.state.listOpen && <div className={filterOptionClasses}>
                <ul className="options-list">
                  {
                    this.props.allowUnSelect && <li onClick={() => this.selectItem(null)}>
                      <span>{this.props.defaultValue}</span>
                    </li>
                  }
                  {
                    this.props.items.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => this.selectItem(item)}>
                        <span>{item.name}</span>
                      </li>
                    ))
                  }
                </ul>
              </div>
            }
          </div>
        </div>
      </Fragment>
    );
  }

}
