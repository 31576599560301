// @flow
import React from 'react';

import { Settings } from './tab-header.setting';

export class TabHeader extends Settings {

  render () {
    return (
      <div className="tab-header">
        {
          this.props.title && <h2 className="title">{this.props.title}</h2>
        }
        {this.props.subTitle && <h4 className="sub-title">{this.props.subTitle}</h4>}
      </div>
    );
  }

}
