import { Component } from 'react';

import type { LabelType } from './labels.type';

import './knowledge-level.scss';

export type Props = {
  value: string,
  disabled: boolean,
  candidate?: boolean,
  showSkillName: boolean,
  change: (value: string) => void
}

export type State = {
  value: number,
  labels: LabelType
}

export class Settings extends Component<Props, State> {

  labels = {
    1: 'Basic',
    2: 'Average',
    3: 'Good',
    4: 'Excellent'
  };

  static defaultProps: Props = {
    value: '',
    disabled: false,
    showSkillName: true,
    candidate: false,
    change: () => {}
  };

  state: State = {
    value: 1,
    labels: this.labels
  };

}
