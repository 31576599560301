// @flow
import React from 'react';

import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { IconButton } from 'components/buttons';
import { ShowSalaryRange } from 'components/elements';

import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

import { Setting } from './job-offer-row.setting';

@connectTranslation()
export class JobOfferRow extends Setting {

  toggleFavorite () {
    const jobOffer = new JobOffersResponseDto(this.state.jobOffer);
    jobOffer.favorite = !this.state.jobOffer.favorite;
    this.setState({ jobOffer });
    this.props.action();
  }

  render () {
    const jobOffer = this.state.jobOffer;
    return (
      <tr
        onClick={() => this.props.onClick()}
        className="job-offer-row">
        <td>
          {this.props.isNew && (
            <div className="list-badge">
              <span className="new-job-offer"/>
            </div>
          )}
          <div className="cell">
            <div className="item-title">
              {jobOffer.jobTitleName || this.props.t('portal.candidate.job-offers.list.no-title')}
            </div>
          </div>
        </td>
        <td>
          <div className="cell">
            <div className="item-title">
              {
                (jobOffer.companyAddress && jobOffer.companyAddress.city) && <div>
                  {jobOffer.companyAddress.city}, {jobOffer.companyAddress.country}
                </div>
              }
            </div>
            <div className="item-description">
              {/* {jobOffer.companyName || this.props.t('portal.candidate.job-offers.list.no-information')} */}
              {jobOffer.companyName || this.props.searchedCompany}
            </div>
          </div>
        </td>
        <td>
          <div className="cell">
            <div className="item-title">
              {jobOffer.branchName || this.props.t('portal.candidate.job-offers.list.no-branch')}
            </div>
            <div className="item-description">
              <ShowSalaryRange salaryRange={jobOffer.salaryRange}/>
            </div>
          </div>
        </td>
        <td className="actions">
          {
            this.props.action && <div className="item-action">
              <IconButton
                title={jobOffer.favorite ? <span>
                  <Trans i18nKey="portal.candidate.job-offers.list.favorite.remove">
                    Delete from favorite
                  </Trans>
                </span> : <span>
                  <Trans i18nKey="portal.candidate.job-offers.list.favorite.add">
                    Add to Favorite
                  </Trans>
                </span>}
                onClick={() => this.toggleFavorite()}
                icon={jobOffer.favorite ? 'icon-favorites-on' : 'icon-favorites-off'}/>
            </div>
          }
        </td>
      </tr>
    );
  }

}
