import * as actions from './new-candidates.constants';

import {
  StatsMatchedCandidatesDto
} from 'rest/companies/job-offers-matches/matched-candidates/stats/stats-candidates.dto';

import type { NewCandidatesMatchedType } from './new-candidates.type';

const initialState: NewCandidatesMatchedType = {
  fetching: false,
  content: [],
  currentPage: 0,
  hasMoreItems: true
};

export default (state = initialState, action): NewCandidatesMatchedType => {
  switch (action.type) {
    case actions.GET_NEW_MATCHED_CANDIDATES_STATS_REQUEST:
      return {
        ...state,
        fetching: true,
        hasMoreItems: false
      };

    case actions.GET_NEW_MATCHED_CANDIDATES_STATS_SUCCESS:
      return {
        ...state,
        fetching: false,
        content: [ ...state.content, ...action.payload.content.map(invite => new StatsMatchedCandidatesDto(invite)) ],
        currentPage: action.payload.number + 1,
        hasMoreItems: action.payload.content.length > 0
      };

    case actions.GET_NEW_MATCHED_CANDIDATES_STATS_FAILURE:
      return {
        ...state,
        fetching: false,
        content: [],
        hasMoreItems: false
      };

    case actions.RESET_NEW_MATCHED_CANDIDATES_STATS_SUCCESS:
      return {
        ...state,
        content: [],
        fetching: false,
        currentPage: 0,
        hasMoreItems: true
      };

    default:
      return state;
  }
};
