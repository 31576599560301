import React from 'react';

import { Trans } from 'react-i18next';

import cn from 'classnames';

import { Button } from 'components/buttons';

import { findEvent } from 'middleware/notifications/find-event';
import { connectStore } from 'middleware/connect';

import { Setting, dispatchToProps } from './anonymous-candidate.setting';
import * as eventNames from './anonymous-candidate.constants';

@connectStore(null, dispatchToProps, true)
export class AnonymousCandidateTemplate extends Setting {

  acceptContact = () => {
    this.props.acceptContact(findEvent(this.props.eventData, eventNames.RECRUITER_ID), this.props.alertId);
    this.setState({
      accepted: true,
      requested: true
    });
  };

  declineContact = () => {
    this.props.declineContact(findEvent(this.props.eventData, eventNames.RECRUITER_ID), this.props.alertId);
    this.setState({
      accepted: false,
      requested: true
    });
  };

  render () {
    const { request, eventData, publishDate } = this.props;
    const { accepted, requested } = this.state;
    const decisionClasses = cn([ 'decision', {
      accepted: accepted
    } ]);
    return (
      <div className="notification-item-content flexible">
        <div className="logo-container">
          <img
            src={
              findEvent(eventData, eventNames.RECRUITER_PHOTO_URL)
                ? findEvent(eventData, eventNames.RECRUITER_PHOTO_URL)
                : '/images/shared/userpick.jpg'
            }
            alt="avatar"/>
        </div>
        <div className="content">
          <h3>
            <Trans i18nKey={
              `portal.notifications.anonymous.candidate.title.${
                request && !requested ? 'request' : accepted ? 'accepted' : 'declined'
              }`}>
              Contact Request:
            </Trans>
          </h3>
          {
            eventData.length && <p>
              <Trans i18nKey={`portal.notifications.anonymous.candidate.description.${request && !requested ? 0 : 2}`}>
                Recruiter
              </Trans>
              &nbsp;
              <strong>
                {findEvent(eventData, eventNames.RECRUITER_FIRST_NAME)} &nbsp;
                {findEvent(eventData, eventNames.RECRUITER_LAST_NAME)} ({findEvent(eventData, eventNames.COMPANY_NAME)})
              </strong>
              &nbsp;
              <Trans i18nKey={`portal.notifications.anonymous.candidate.description.${request && !requested ? 1 : 3}`}>
                requested access to your contact details.
              </Trans>
            </p>
          }
          <span className="publish-date">{publishDate}</span>
          {
            request && !requested ? <div className="actions">
              <Button
                outlined
                candidate
                onClick={this.acceptContact}>
                <Trans i18nKey="portal.notifications.anonymous.candidate.actions.accept">
                  Accept
                </Trans>
              </Button>
              <Button
                outlined
                candidate
                onClick={this.declineContact}>
                <Trans i18nKey="portal.notifications.anonymous.candidate.actions.decline">
                  Decline
                </Trans>
              </Button>
            </div> : <div className={decisionClasses}>
              <Trans i18nKey={`portal.notifications.anonymous.candidate.${accepted ? 'accepted' : 'declined'}`}>
                You accepted this request.
              </Trans>
            </div>
          }
        </div>
      </div>
    );
  }

}
