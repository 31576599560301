import * as actions from './stats.constants';

import { StatsDto } from 'rest/stats/stats.dto';
import { getJobOfferFavoritesStats } from 'rest/candidates/job-offer-favorites/job-offer-favorites.rest';

const getFavoritesStatisticRequest = () => ({
  type: actions.GET_FAVORITES_STATS_REQUEST
});

const getFavoritesStatisticSuccess = (stats: StatsDto[] = [], total: number = 0) => ({
  type: actions.GET_FAVORITES_STATS_SUCCESS,
  total: total,
  stats: stats
});

const getFavoritesStatisticFailure = (error: string) => ({
  type: actions.GET_FAVORITES_STATS_FAILURE,
  error
});

const resetFavoritesStatisticSuccess = data => ({
  type: actions.RESET_FAVORITES_STATS_SUCCESS,
  payload: data
});

export const resetFavoriteStatistic = () => {
  return dispatch => {
    dispatch(resetFavoritesStatisticSuccess());
  };
};

export const getFavoriteStatistic = (candidateId: string) => {
  return dispatch => {
    dispatch(getFavoritesStatisticRequest());
    return getJobOfferFavoritesStats(candidateId)
      .then(response => {
        dispatch(getFavoritesStatisticSuccess(response.data.candidateFavoriteJobsCount, response.data.total));
      })
      .catch(error => dispatch(getFavoritesStatisticFailure(error)));
  };
};
