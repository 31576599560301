import { ListDto } from 'middleware/dto/list';
import { ImageDto } from 'rest/images/image.dto';
import { UserAddressDto } from 'rest/address-dto/address';

export class MatchedCandidatesDto {

  id: string;
  isNew: boolean;
  lastName: string;
  firstName: string;
  anonymous: boolean;
  branches: ListDto[];
  updatedDate: string;
  matchedPoints: number;
  profileImage: ImageDto;
  address: UserAddressDto;

  constructor (data?: MatchedCandidatesDto) {
    if (data) {
      this.id = data.id;
      this.isNew = data.isNew;
      this.branches = data.branches;
      this.lastName = data.lastName;
      this.firstName = data.firstName;
      this.anonymous = data.anonymous;
      this.updatedDate = data.updatedDate;
      this.matchedPoints = data.matchedPoints;
      this.address = new UserAddressDto(data.address);
      this.profileImage = new ImageDto(data.profileImage);
    }
  }

  get primaryPhotoUrl (): string {
    return (this.profileImage && this.profileImage.url) ? this.profileImage.url : '/images/shared/userpick.jpg';
  }

  get fullName (): string {
    return this.firstName && this.lastName ? `${this.firstName} ${this.lastName}` : null;
  }

  get branchName (): string {
    return this.branches && this.branches[0] ? `${this.branches[0].name}` : null;
  }

}
