import React, { Component } from 'react';

import { Container } from 'components/layouts';

import { PrivacyPolicy } from 'containers/information';

export class PrivacyPolicyPage extends Component {

  render () {
    return (
      <div className="privacy-policy-container">
        <Container>
          <PrivacyPolicy />
        </Container>
      </div>
    );
  }

}
