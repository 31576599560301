// @flow
import { Component } from 'react';
import { Dispatch, bindActionCreators } from 'redux';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { ModalPropsType } from 'services/portal/modal/modal-props-type';

import {
  getFollowersJobOffers,
  resetAllJobOffersFollowers
} from 'services/portal/candidate/jobs/follow/followers/followers.actions';

import { deleteJobFromFavorite, addJobToFavorite } from 'services/portal/candidate/jobs/favorite/favorite.actions';

import { List } from 'rest/list/list.dto';
import { JobOffersParams } from 'rest/public/job-offers/job-offers.params';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

export type Props = {
  t?: TransProps,
  history: History,
  success: boolean;
  fetching: boolean;
  companyId: string,
  followersFilters: JobOffersParams,
  jobOffers: List<JobOffersResponseDto>;
  showModal: (type: string, modalProps: ModalPropsType) => void;
  addJobToFavorite: (id: string) => void;
  currentCandidateId: string,
  getFollowersJobOffers: (params?: JobOffersParams) => void;
  deleteJobFromFavorite: (id: string) => void;
  resetAllJobOffersFollowers: () => void
};

export class Setting extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  fetching: state.portal.candidate.jobs.follow.followers.fetching,
  jobOffers: state.portal.candidate.jobs.follow.followers.content,
  followersFilters: state.portal.candidate.jobs.follow.followers.filters,
  currentPage: state.portal.candidate.jobs.follow.followers.currentPage,
  hasMoreItems: state.portal.candidate.jobs.follow.followers.hasMoreItems,
  currentCandidateId: state.portal.candidate.account.current.candidateData.id
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  addJobToFavorite,
  getFollowersJobOffers,
  deleteJobFromFavorite,
  resetAllJobOffersFollowers
}, dispatch);
