// @flow
import { Component } from 'react';

import { ImageDto } from 'rest/images/image.dto';

import './photo-gallery.scss';

export type Props = {
  photos: ImageDto[];
};

export type State = {
  activePhotoUrl: string;
  activePhotoIndex: number;
}

export class Setting extends Component<Props, State> {

  static defaultProps: Props = {
    photos: []
  };

  state: State = {
    activePhotoUrl: this.props.photos.length ? this.props.photos[0].url : '',
    activePhotoIndex: 0
  };

}
