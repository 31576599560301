import { MessagesResponseDto } from 'rest/chats/messages/messages.response.dto';

import * as actions from './messages.constants';

import type { MessagesState } from './messages.type';

const initialState: MessagesState = {
  content: [],
  fetching: false,
  currentPage: 0,
  hasMoreItems: true,
  unreadMessages: 0,
  newMessageComing: false
};

export default (state = initialState, action): MessagesState => {
  switch (action.type) {

    case actions.GET_CHAT_MESSAGES_REQUEST:
      return {
        ...state,
        fetching: true,
        hasMoreItems: false
      };

    case actions.GET_CHAT_MESSAGES_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.GET_CHAT_MESSAGES_SUCCESS:
      return {
        ...state,
        fetching: false,
        content: [ ...action.payload.content.reverse().map(item => new MessagesResponseDto(item)), ...state.content ],
        currentPage: action.payload.number + 1,
        hasMoreItems: action.payload.content.length > 0
      };

    case actions.RESET_CHAT_MESSAGES_SUCCESS:
      return {
        ...state,
        content: [],
        currentPage: 0,
        hasMoreItems: true
      };

    case actions.CREATE_CHAT_MESSAGES_SUCCESS:
      return {
        ...state,
        content: [ ...state.content, new MessagesResponseDto(action.payload) ]
      };

    case actions.NEW_CHAT_MESSAGES_COMING:
      return {
        ...state,
        newMessageComing: true
      };

    case actions.NEW_CHAT_MESSAGES_READ:
      return {
        ...state,
        newMessageComing: false
      };

    case actions.GET_CHAT_UNREAD_MESSAGES:
      return {
        ...state,
        unreadMessages: action.payload.unreadCount
      };

    case actions.RESET_CHAT_UNREAD_MESSAGES:
      return {
        ...state,
        unreadMessages: 0
      };

    default:
      return state;
  }
};
