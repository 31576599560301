// @flow
import { Component } from 'react';

import './empty-candidates-list.scss';

type Props = {
  history: History
}

export class Setting extends Component<Props> {}
