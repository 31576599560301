import { combineReducers } from 'redux';

import follow from './follow/follow.reducer';
import favorite from './favorite/favorite.reducer';
import jobOffers from './job-offers/job-offers.reducer';
import followStats from './follow/stats/stats.reducer';
import favoriteStats from './favorite/stats/stats.reducer';
import jobOffersStats from './job-offers/stats/stats.reducer';
import searchJobOffers from './search/search.reducer';

export default combineReducers({
  follow,
  favorite,
  jobOffers,
  followStats,
  favoriteStats,
  jobOffersStats,
  searchJobOffers
});
