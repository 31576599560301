import { PaginationDto } from 'middleware/dto/pagination';

export class FavoriteParams extends PaginationDto {

  placeId: string;
  branchId: string;
  companyId: string;
  extraFields: string[];

  constructor (data?: FavoriteParams) {
    super(data);
    if (data) {
      this.placeId = data.placeId;
      this.branchId = data.branchId;
      this.companyId = data.companyId;
      this.extraFields = data.extraFields;
    }
  }

}
