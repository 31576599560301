// @flow
import React from 'react';

import { Setting } from './date-custom-input.setting';

export class DateCustomInput extends Setting {

  render () {
    return (
      <input
        value={this.props.value}
        onClick={this.props.onClick}
        type="text"
        readOnly={true}
      />
    );
  }

}
