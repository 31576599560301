import React, { Component } from 'react';

import { AuthContainer, AuthFormContainer } from 'containers/auth/layouts';

import { ConfirmRegistrationForm } from 'containers/auth/confirm-registration-form/confirm-registration-form';

export class GuestConfirmRegistrationPage extends Component {

  render () {
    return (
      <AuthContainer hideLogo>
        <AuthFormContainer>
          <div className="confirm-sign-up-page">
            <ConfirmRegistrationForm
              userType="recruiter"
              buttonType="accent"
              redirectUrl="/portal/new-job-offer/payment"/>
          </div>
        </AuthFormContainer>
      </AuthContainer>
    );
  }

}
