import React from 'react';

import { Card } from 'components/layouts';
import { LinkButton } from 'components/buttons';

import { Setting } from './modal-header.setting';

export class ModalHeader extends Setting {

  render () {
    return (
      <Card className={`modal-header-container ${this.props.className}`}>
        {
          this.props.backLinkUrl && <LinkButton
            inline
            className="modal-header-back-link"
            link={this.props.backLinkUrl}>
            {this.props.backLinkTitle}
            <div className="mobile-close-btn">&times;</div>
          </LinkButton>
        }
        <div className="modal-header-caption">
          <h4>{this.props.title}</h4>
          {this.props.children}
        </div>
      </Card>
    );
  }

}
