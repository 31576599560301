// @flow
import React from 'react';

import { Trans } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { ListDto } from 'middleware/dto';
import { connectStore } from 'middleware/connect';

import { Spinner } from 'components/elements';
import { Button } from 'components/buttons';
import { FormGroup, InputControl, CheckboxControl } from 'components/controls';

import { Modal, ModalCaption } from 'containers/portal/modal-container';

import type { Props } from './languages.setting';
import { Settings, dispatchToProps, stateToProps } from './languages.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class AddLanguagesModal extends Settings {

  componentDidMount () {
    this.props.getAllLanguages();
  }

  componentWillReceiveProps (nextProps: Props) {
    const languages: ListDto[] = nextProps.languages;
    this.setState({
      languages,
      fetched: true
    });
  }

  changeSearchState (value: string) {
    this.setState({ searchItem: value }, () => this.search(this.state.searchItem));
  }

  changeSelectedState (checked: boolean, value: ListDto) {
    const selected: ListDto[] = [...this.state.selectedItems];
    if (checked) {
      selected.push(value);
    } else {
      const index: number = selected.findIndex(item => item.id === value.id);
      selected.splice(index, 1);
    }
    this.setState({ selectedItems: selected });
  }

  clearState () {
    this.setState({
      searchItem: '',
      selectedItems: []
    });
  }

  close () {
    this.clearState();
    this.props.close();
  }

  apply () {
    this.props.addNewItems(this.state.selectedItems);
    this.close();
  }

  search (value: string) {
    const copyLanguages: ListDto[] = [...this.props.languages];
    if (value.trim().length > 0) {
      const searchResult: ListDto[] = copyLanguages.filter(item =>
        item.name.toLowerCase().includes(this.state.searchItem.toLowerCase()));
      this.setState({ languages: searchResult });
    } else {
      this.setState({ languages: copyLanguages });
    }
  }

  render () {
    return (
      <Modal
        overlay
        animation="fade"
        open={this.props.open}
        hideModalAvailable={false}
        hideModal={() => this.close()}
        closeOnOverlay={() => this.close()}
        className={`add-languages-modal ${this.props.className}`}>
        <ModalCaption
          hideModal={() => this.close()}
          title={this.props.t('portal.recruiter.balance-board.job-offer.add-modals.language.title')}/>
        <form className="add-skills-modal-form">
          <FormGroup className="full-width">
            <InputControl
              required
              value={this.state.searchItem}
              change={(value: string): void => this.changeSearchState(value)}
              placeholder={this.props.t('portal.recruiter.balance-board.job-offer.add-modals.language.search')}
              type="text"/>
          </FormGroup>
        </form>
        <PerfectScrollbar className="overflow-modal-content">
          <ul className="list-content">
            {
              this.state.languages.length > 0 ? this.state.languages.map(language => (
                <li
                  key={language.id}
                  className="list-content-item">
                  <CheckboxControl
                    value={language.selected}
                    className={this.props.recruiter ? 'accent' : ''}
                    change={(checked: boolean) => this.changeSelectedState(checked, language)}
                    label={language.name}/>
                </li>
              )) : <p className="empty">
                <Trans i18nKey="portal.recruiter.balance-board.job-offer.add-modals.empty">
                  No Search Items
                </Trans>
              </p>
            }
            <Spinner show={this.props.fetching}/>
          </ul>
        </PerfectScrollbar>
        <div className="add-skills-action">
          <Button
            type="submit"
            disabled={!this.state.selectedItems.length > 0}
            onClick={this.apply.bind(this)}
            candidate={this.props.candidate}
            recruiter={this.props.recruiter}
            medium>
            <Trans i18nKey="portal.recruiter.balance-board.job-offer.add-modals.buttons.apply">
              Apply
            </Trans>
          </Button>
          <Button
            type="button"
            onClick={() => this.close()}
            className="grey medium">
            <Trans i18nKey="portal.recruiter.balance-board.job-offer.add-modals.buttons.cancel">
              Cancel
            </Trans>
          </Button>
        </div>
      </Modal>
    );
  }

}
