import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { ListDto } from 'middleware/dto/list';

import { CandidateDto } from 'rest/candidates/candidate.dto';
import { CalendarInviteDto } from 'rest/companies/candidate-invites/candidate-invites-invite.dto';
import { CalendarRequestDto } from 'rest/companies/candidate-invites/candidate-invites-request.dto';

import { CurrentRecruiterDto } from 'rest/recruiter/current/current.dto';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

import { createInvite } from 'services/portal/calendar/invite/invite.actions';
import { getAllCompanyJobOffers } from 'services/portal/recruiter/account/job-offer/job-offer.actions';

import './invite-modal.scss';

export type Props = {
  t: TransProps,
  open: boolean,
  close: () => void,
  jobOffers: JobOffersResponseDto[],
  candidate: CandidateDto,
  currentUser: CurrentRecruiterDto,
  createInvite: (companyId: string) => void,
  getAllCompanyJobOffers: (companyId: string, invite: CalendarRequestDto, candidateId: string) => void
}

type State = {
  slots: CalendarInviteDto[],
  times: ListDto[],
  jobOfferId: number
}

export class Settings extends Component<Props, State> {

  static defaultProps: Props = {
    open: false,
    close: () => {},
    jobOffers: [],
    candidate: null
  };

  state = {
    slots: [ new CalendarInviteDto() ],
    times: [],
    jobOfferId: null
  }

}

export const stateToProps = (state: GlobalState) => ({
  jobOffers: state.portal.recruiter.account.jobOffer.jobOffers,
  currentUser: state.portal.recruiter.account.current.currentUser
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  createInvite,
  getAllCompanyJobOffers
}, dispatch);
