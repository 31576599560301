export class SkillItemDto {

  points: number = 0;
  skillId: string;
  selected: boolean;
  required: boolean = false;
  skillLevel: string = 'BASIC';
  pointsPerLevel: number = 0;

  constructor (data?: SkillItemDto) {
    if (data) {
      this.points = data.points;
      this.skillId = data.skillId;
      this.required = data.required;
      this.selected = data.selected;
      this.skillLevel = data.skillLevel;
      this.pointsPerLevel = data.pointsPerLevel;
    }
  }

}
