import React, { Component } from 'react';

import { Page } from 'components/layouts';

import { CandidateAccountSetting } from 'containers/portal/candidate/account/account';

export class CandidateAccount extends Component<null> {

  render () {
    return (
      <div className="account-page-container">
        <div className="account-settings-page">
          <Page autoHeight>
            <CandidateAccountSetting history={this.props.history}/>
          </Page>
        </div>
      </div>
    );
  }

}
