// @flow
import { AnyAction } from 'redux';

import * as actions from './sign-up.constants';

import type { SignUpState } from './sign-up.type';

const initialState: SignUpState = {
  error: false,
  fetching: false,
  errorText: '',
  signUpSuccess: false
};

export default (state: SignUpState = initialState, action: AnyAction): SignUpState => {
  switch (action.type) {
    case actions.CANDIDATE_SIGN_UP_REQUEST:
      return {
        ...state,
        fetching: true,
        signUpSuccess: false
      };

    case actions.CANDIDATE_SIGN_UP_SUCCESS:
      return {
        ...state,
        fetching: false,
        signUpSuccess: true
      };

    case actions.CANDIDATE_SIGN_UP_FAILURE:
      return {
        ...state,
        error: true,
        fetching: false,
        errorText: action.error.message,
        signUpSuccess: false
      };

    default:
      return state;
  }
};
