import * as actions from './sent-templates.constants';

import { Action } from 'redux';

import { PaginationDto } from 'middleware/dto/pagination';

import { ApplicationResponseDto } from 'rest/candidates/applications-template/applications-template.response.dto';

import {
  getSentApplications,
  getSentApplicationById
} from 'rest/candidates/applications/applications.rest';

const getSentApplicationByIdRequest = (): Action => ({
  type: actions.GET_APPLICATION_TEMPLATE_BY_ID_REQUEST
});

const getSentApplicationByIdSuccess = (letter: ApplicationResponseDto): Action => ({
  type: actions.GET_APPLICATION_TEMPLATE_BY_ID_SUCCESS,
  letter
});

const getSentApplicationByIdFailure = (error: string): Action => ({
  type: actions.GET_APPLICATION_TEMPLATE_BY_ID_FAILURE,
  error
});

const getSentApplicationsRequest = (): Action => ({
  type: actions.GET_SENT_TEMPLATES_REQUEST
});

const getSentApplicationsSuccess = (sentTemplates: ApplicationResponseDto[]): Action => ({
  type: actions.GET_SENT_TEMPLATES_SUCCESS,
  payload: sentTemplates
});

const getSentApplicationsFailure = (): Action => ({
  type: actions.GET_SENT_TEMPLATES_FAILURE
});

const resetSentTemplatesSuccess = (): Action => ({
  type: actions.RESET_SENT_TEMPLATES_SUCCESS
});

const changeSentTemplatesFilterSuccess = (jobTitleId: string): Action => ({
  type: actions.CHANGE_SENT_TEMPLATES_FILTER_SUCCESS,
  jobTitleId
});

export function changeSentTemplatesFilter (jobTitleId?: string = '') {
  return dispatch => dispatch(changeSentTemplatesFilterSuccess(jobTitleId));
}

export function resetApplications () {
  return dispatch => dispatch(resetSentTemplatesSuccess());
}

export function getSentCandidateApplications (candidateId: string, params: PaginationDto) {
  return dispatch => {
    dispatch(getSentApplicationsRequest());
    return getSentApplications(candidateId, params)
      .then(response => dispatch(getSentApplicationsSuccess(response.data)))
      .catch(() => dispatch(getSentApplicationsFailure()));
  };
}

export function getSentLetterById (candidateId: string, applicationId: string) {
  return dispatch => {
    dispatch(getSentApplicationByIdRequest());
    return getSentApplicationById(candidateId, applicationId)
      .then(response => dispatch(getSentApplicationByIdSuccess(response.data)))
      .catch(() => dispatch(getSentApplicationByIdFailure()));
  };
}
