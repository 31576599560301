import { Component } from 'react';

import './avatar.scss';

type Props = {
  imageUrl: string,
  loading: boolean
}

export class AvatarSetting extends Component<Props> {

  static defaulpProps = {
    imageUrl: '',
    loading: false
  }

}
