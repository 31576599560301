export class StatsDto {

  id: string;
  name: string;
  count: string;

  constructor (data?: StatsDto) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.count = data.count;
    }
  }

}
