import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { SearchDto, ListDto } from 'middleware/dto';

import { searchSoftware, resetSearchResult } from 'services/portal/software/software.actions';

import './software.scss';
import './software.responsive.scss';

export type Props = {
  t: TransProps,
  open: boolean,
  fetching: boolean,
  close: () => void,
  className?: string,
  hasMoreItems: boolean,
  candidate?: boolean,
  recruiter?: boolean,
  software?: ListDto[],
  searchSoftware: ListDto[],
  resetSearchResult: () => void,
  search?: (params: SearchDto) => void,
  addNewItems: (items: ListDto[]) => void
}

type State = {
  searchItem: string,
  selectedItems: ListDto[]
}

export class Settings extends Component<Props, State> {

  static defaultProps: Props = {
    open: false,
    className: '',
    software: [],
    candidate: false,
    recruiter: false,
    close: () => {},
    search: () => {},
    addNewItems: () => {},
    resetSearchResult: () => {}
  };

  state: State = {
    searchItem: '',
    selectedItems: []
  };

}

export const stateToProps = (state: GlobalState) => ({
  fetching: state.portal.software.fetching,
  software: state.portal.software.searchSoftware,
  hasMoreItems: state.portal.software.hasMoreItems
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetSearchResult,
  search: searchSoftware
}, dispatch);
