// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { StatsDto } from 'rest/stats/stats.dto';
import { RecruiterDto } from 'rest/recruiter/recruiter.dto';
import { CandidatesParams } from 'rest/public/candidates/candidates.params';

import type { SearchCandidatesType } from 'services/portal/recruiter/candidate/search/search.type';

import { initCandidatesFilter } from 'services/portal/recruiter/candidate/all/candidates.actions';
import { saveSearchCandidatesBranch } from 'services/portal/recruiter/candidate/search/search.actions';
import { getCandidatesStatistic, resetCandidatesStats } from 'services/portal/recruiter/candidate/stats/stats.actions';

type Props = {
  t: TransProps,
  total: number,
  stats: StatsDto[],
  currentUser: RecruiterDto,
  searchCandidates: SearchCandidatesType,
  initCandidatesFilter: (filter: CandidatesParams) => void,
  resetCandidatesStats: () => void,
  getCandidatesStatistic: (companyId: string) => void,
  saveSearchCandidatesBranch: (branchId?: string) => void
}

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  total: state.portal.recruiter.candidate.candidatesStats.totalElements,
  stats: state.portal.recruiter.candidate.candidatesStats.stats,
  currentUser: state.portal.recruiter.account.current.currentUser,
  searchCandidates: state.portal.recruiter.candidate.searchCandidates
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  initCandidatesFilter,
  resetCandidatesStats,
  getCandidatesStatistic,
  saveSearchCandidatesBranch
}, dispatch);
