import { PaginationDto } from 'middleware/dto';

export class ChatRequestDto extends PaginationDto {

  candidateId: string;
  recruiterId: string;

  constructor (data?: ChatRequestDto) {
    super(data);
    if (data) {
      this.candidateId = data.candidateId;
      this.recruiterId = data.recruiterId;
    }
  }

}
