// @flow
import React, { Fragment } from 'react';

import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';

import moment from 'moment';

import { uploadFile } from 'middleware/upload-file';
import { connectStore } from 'middleware/connect';

import { Button } from 'components/buttons';
import { Avatar } from 'components/avatar/avatar';
import { DeleteAccount } from 'components/dialogs';
import { Spinner, Description } from 'components/elements';

import {
  FormGroup,
  InputControl,
  RadioControl,
  RadioGroup,
  DateControl,
  PhoneControl,
  AutosuggestSelect
} from 'components/controls';

import { ImageDto } from 'rest/images/image.dto';
import { AddressDto } from 'rest/address/address.dto';
import { UserAddressDto } from 'rest/address-dto/address';
import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';

import type { Props } from './candidate-info.setting';
import { dispatchToProps, Settings, stateToProps } from './candidate-info.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class CandidateInfo extends Settings {

  componentDidMount () {
    this.props.getCurrentCandidate();
  }

  componentWillReceiveProps (nextProps: Props) {
    const candidate: CurrentCandidateDto = nextProps.currentCandidate;
    const candidatePhotos: ImageDto[] = candidate.candidatePhotos;
    const avatar = candidatePhotos && candidatePhotos.length > 0 && candidatePhotos.find(photo => photo.primary);
    if (candidate && candidate.candidateAddress && this.props.currentCandidate !== candidate) {
      this.setState({
        ...candidate,
        ...candidate.candidateAddress,
        avatarPreview: this.state.avatarPreview || (avatar && avatar.url)
      });
    }
  }

  changeAvatarState (file: File) {
    uploadFile(file, (base64, error) => {
      if (error) {
        this.setState({ avatarError: error });
        toast.error(this.props.t('portal.candidate.notifications.incorrect-size'));
      } else {
        this.setState({
          avatarError: false,
          avatarPreview: base64
        }, () => this.props.uploadFile(file, 'avatar'));
      }
    });
  }

  setCandidateAvatar (): ImageDto {
    const avatar: ImageDto = new ImageDto();
    const currentCandidate: CurrentCandidateDto = this.props.currentCandidate;
    const primaryAvatar: ImageDto = currentCandidate.candidatePhotos.find(photo => photo.primary);
    avatar.primary = true;
    if (this.props.imageInfo && this.state.avatarPreview) {
      avatar.imageId = this.props.imageInfo.imageId;
      avatar.url = this.props.imageInfo.url;
    } else if (primaryAvatar && this.state.avatarPreview) {
      avatar.imageId = primaryAvatar.imageId;
      avatar.url = primaryAvatar.url;
    } else {
      return null;
    }
    return avatar;
  }

  sendPersonalInfo () {
    const candidateInfo: CurrentCandidateDto = new CurrentCandidateDto(this.state);
    const photos: ImageDto = this.setCandidateAvatar();
    candidateInfo.candidateAddress = new UserAddressDto(this.state);
    candidateInfo.candidatePhotos = photos && [ photos ];
    candidateInfo.blockNotification = this.props.currentCandidate.blockNotification;
    this.props.updateCurrentCandidate(this.props.currentCandidate.id, candidateInfo);
  }

  suggestCountry (value: string) {
    const query: AddressDto = new AddressDto();
    query.city = '';
    query.street = '';
    query.country = value;
    this.props.searchSuggestedAddress('country', query);
  }

  suggestStreet (value: string) {
    const query: AddressDto = new AddressDto(this.state);
    query.street = value;
    this.props.searchSuggestedAddress('street', query);
  }

  suggestCity (value: string) {
    const query: AddressDto = new AddressDto(this.state);
    query.street = '';
    query.city = value;
    this.props.searchSuggestedAddress('city', query);
  }

  updateCandidateInfo (event: Event) {
    event.preventDefault();
    if (!this.state.errorCollection.size && !this.props.avatarLoading) {
      this.sendPersonalInfo();
      toast.success(this.props.t('portal.candidate.notifications.profile-updated'));
    }
  }

  errorCollector (errorName: string, collect: boolean) {
    const errorCollection: Set<string> = new Set(this.state.errorCollection);
    collect ? errorCollection.add(errorName) : errorCollection.delete(errorName);
    this.setState({ errorCollection });
  }

  deleteCurrentCandidate () {
    this.props.deleteCurrentCandidate(this.props.currentCandidate.id);
    toast.success(this.props.t('portal.candidate.notifications.account-deleted'));
  }

  render () {
    return (
      <Fragment>
        {
          this.props.deleteAccountSuccess && <Redirect to="/"/>
        }
        <div className="candidate-card-title">
          <Trans i18nKey="portal.candidate.profile.user.mobile.title">
            User Profile
          </Trans>
        </div>
        <div className="candidate-avatar candidate-content">
          <Avatar
            error={!!this.state.avatarError}
            errorText={this.state.avatarError}
            loading={this.props.avatarLoading}
            previewImageUrl={this.state.avatarPreview || '/images/shared/userpick.jpg'}
            change={this.changeAvatarState.bind(this)}
            clearImageState={() => this.setState({ avatarPreview: '' })}
            uploadButtonName={this.props.t('portal.candidate.profile.user.avatar')}
            uploadButtonType="primary outlined"
          />
        </div>
        <form
          onSubmit={this.updateCandidateInfo.bind(this)}
          className="candidate-info-form">
          <FormGroup className="half-width">
            <InputControl
              maxLength={35}
              label={this.props.t('portal.candidate.profile.user.first-name.label')}
              value={this.state.firstName}
              change={(firstName: string): void => this.setState({ firstName })}
              placeholder={this.props.t('portal.candidate.profile.user.first-name.placeholder')}
              pattern=".+"
              required
              errorText={this.props.t('portal.candidate.profile.user.first-name.error')}
              type="text"/>
          </FormGroup>
          <FormGroup className="half-width">
            <InputControl
              required
              maxLength={35}
              label={this.props.t('portal.candidate.profile.user.last-name.label')}
              value={this.state.lastName}
              change={(lastName: string): void => this.setState({ lastName })}
              placeholder={this.props.t('portal.candidate.profile.user.last-name.placeholder')}
              errorText={this.props.t('portal.candidate.profile.user.last-name.error')}
              type="text"/>
          </FormGroup>
          <FormGroup className="half-width">
            <InputControl
              disabled
              readonly
              title="some-email@gmail.com"
              label={this.props.t('portal.candidate.profile.user.email.label')}
              value={this.state.email}
              required
              change={(email: string): void => this.setState({ email })}
              placeholder={this.props.t('portal.candidate.profile.user.email.placeholder')}
              errorText={this.props.t('portal.candidate.profile.user.email.error')}
              type="email"/>
          </FormGroup>
          <FormGroup className="half-width">
            <PhoneControl
              required={false}
              label={this.props.t('portal.candidate.profile.user.phone')}
              defaultValue={this.state.phoneNumber}
              change={(phoneNumber: string) => this.setState({ phoneNumber })}/>
          </FormGroup>
          <FormGroup className="half-width birthday">
            <DateControl
              minDate={moment().subtract(100, 'year').toDate()}
              maxDate={moment().subtract(18, 'year').toDate()}
              value={this.state.birthday}
              dateFormat="yyyy/MMM/dd"
              change={(value: string): void => this.setState({ birthday: value })}
              required
              label={this.props.t('portal.candidate.profile.user.date')}
              monthsCount={1}/>
          </FormGroup>
          <hr className="divider"/>
          <div className="profile-activity-container">
            <RadioGroup
              label={this.props.t('portal.candidate.profile.user.direct.label')}
              change={(value: string): void => this.setState({ directContact: value })}
              inline
              defaultValue={this.state.directContact.toString()}>
              <RadioControl
                className="primary"
                label={this.props.t('portal.candidate.profile.user.direct.yes')}
                value="true"/>
              <RadioControl
                className="primary"
                label={this.props.t('portal.candidate.profile.user.direct.no')}
                value="false"/>
            </RadioGroup>
            <RadioGroup
              label={this.props.t('portal.candidate.profile.user.activity.label')}
              change={(value: string): void => this.setState({ profileActivity: value })}
              inline
              defaultValue={this.state.profileActivity.toString()}>
              <RadioControl
                className="primary"
                label={this.props.t('portal.candidate.profile.user.activity.yes')}
                value="true"/>
              <RadioControl
                className="primary"
                label={this.props.t('portal.candidate.profile.user.activity.no')}
                value="false"/>
            </RadioGroup>
            <RadioGroup
              label={this.props.t('portal.candidate.profile.user.view.label')}
              change={(value: string): void => this.setState({ anonymous: value })}
              inline
              defaultValue={this.state.anonymous.toString()}>
              <RadioControl
                className="primary"
                label={this.props.t('portal.candidate.profile.user.view.yes')}
                value="false"/>
              <RadioControl
                className="primary"
                label={this.props.t('portal.candidate.profile.user.view.no')}
                value="true"/>
            </RadioGroup>
            {
              String(this.state.anonymous) === 'true' && <Description>
                {
                  [ ...new Array(2) ].map((u, i) => i).map(row => <p
                    key={row}
                    className="text">
                    <Trans i18nKey={`portal.candidate.profile.user.view.anonymous.${row}`}>
                      Recruiters won`t see your personal data nor they`ll be able to contact you
                    </Trans>
                  </p>)
                }
              </Description>
            }
          </div>
          <div className="divider"/>
          <FormGroup className="full-width zip-code">
            <InputControl
              value={this.state.zipCode}
              required
              pattern=".+"
              errorText={this.props.t('portal.candidate.profile.user.zip.error')}
              change={zipCode => this.setState({ zipCode })}
              label={this.props.t('portal.candidate.profile.user.zip.label')}
              placeholder={this.props.t('portal.candidate.profile.user.zip.placeholder')}
              type="text"/>
          </FormGroup>
          <FormGroup className="half-width">
            <AutosuggestSelect
              label={this.props.t('portal.candidate.profile.user.country.label')}
              required
              errorText={this.props.t('portal.candidate.profile.user.country.error')}
              items={this.props.address.country}
              defaultValue={this.state.country}
              change={(value: string) => this.setState({ country: value })}
              search={(value: string) => this.suggestCountry(value)}
              placeholder={this.props.t('portal.candidate.profile.user.country.placeholder')}/>
          </FormGroup>
          <FormGroup className="half-width">
            <AutosuggestSelect
              required
              label={this.props.t('portal.candidate.profile.user.city.label')}
              errorText={this.props.t('portal.candidate.profile.user.city.error')}
              items={this.props.address.city}
              defaultValue={this.state.city}
              change={(value: string) => this.setState({ city: value })}
              search={(value: string) => this.suggestCity(value)}
              placeholder={this.props.t('portal.candidate.profile.user.city.placeholder')}/>
          </FormGroup>
          <FormGroup className="half-width">
            <AutosuggestSelect
              required
              label={this.props.t('portal.candidate.profile.user.street.label')}
              defaultValue={this.state.street}
              items={this.props.address.street}
              change={(value: string) => this.setState({ street: value })}
              search={(value: string) => this.suggestStreet(value)}
              placeholder={this.props.t('portal.candidate.profile.user.street.placeholder')}/>
            <p className="restriction-info">
              <Trans i18nKey="portal.candidate.profile.user.street.note">
                We will not show employers your address, only the distance to the potential job
              </Trans>
            </p>
          </FormGroup>
          <FormGroup className="half-width billing-number">
            <InputControl
              value={this.state.streetNumber}
              required
              errorText={this.props.t('portal.candidate.profile.user.building.error')}
              change={(streetNumber: string): void => this.setState({ streetNumber })}
              label={this.props.t('portal.candidate.profile.user.building.label')}
              placeholder={this.props.t('portal.candidate.profile.user.building.placeholder')}
              type="text"/>
          </FormGroup>
          <p className="mobile-restriction-info restriction-info">
            <Trans i18nKey="portal.candidate.profile.user.street.note">
              We will not show employers your address, only the distance to the potential job
            </Trans>
          </p>
          <FormGroup className="form-btn-submit">
            <Button
              candidate
              medium
              disabled={this.props.avatarLoading}
              type="submit">
              <Trans i18nKey="portal.candidate.profile.buttons.save">
                Save Changes
              </Trans>
            </Button>
            <Button
              onClick={() => this.setState({ isDeleteModalOpen: true })}
              medium
              grey>
              <Trans i18nKey="portal.candidate.profile.buttons.delete">
                Delete Account
              </Trans>
            </Button>
          </FormGroup>
        </form>
        <DeleteAccount
          candidate
          close={() => this.setState({ isDeleteModalOpen: false })}
          confirmAction={() => this.deleteCurrentCandidate()}
          open={this.state.isDeleteModalOpen}/>
        <Spinner show={this.props.fetching}/>
      </Fragment>
    );
  }

}
