// @flow
import React from 'react';

import moment from 'moment';
import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';
import { getDateDuration } from 'middleware/get-duration';

import { LinkButton } from 'components/buttons';

import { WorkExperienceDto } from 'rest/candidates/work-experience/work-experience.dto';

import { Settings, stateToProps } from './experience-section.setting';

@connectStore(stateToProps, null, true)
export class ExperienceSection extends Settings {

  render () {
    return (
      <div className="education-section-container mobile-education-section-container">
        {
          this.props.experiences.length > 0 ? this.props.experiences.map((item: WorkExperienceDto, index: number) => (
            <div
              key={index}
              className="education-item">
              <div className="left-info">
                <p>{item.toDate ? moment(item.toDate).format('ll')
                  : this.props.t('portal.candidate.cv.list.experience.now')} — </p>
                <p>{moment(item.fromDate).format('ll')}</p>
                <p className="duration">
                  {getDateDuration(item.fromDate, item.toDate || new Date(), this.props.t)}
                </p>
              </div>
              <div className="right-info">
                <h4 className="degree">{item.jobTitle}</h4>
                <p className="school">{item.description}</p>
                <a
                  href={item.website}
                  className="school link">
                  {item.website}
                </a>
                <p className="school mobile-school">{item.location}</p>
              </div>
            </div>
          )) : <div className="empty-section">
            <h5 className="empty-section-title">
              <Trans i18nKey="portal.candidate.cv.list.experience.empty">
                Work Experience panel is empty
              </Trans>
            </h5>
            <p className="empty-section-description">
              <Trans i18nKey="portal.candidate.cv.list.experience.text">
                Fill out information about your work experience
              </Trans>
            </p>
            <LinkButton
              outlined
              link="/portal/candidate/cv/experience"
              candidate>
              <Trans i18nKey="portal.candidate.cv.list.experience.add">
                Add Experience
              </Trans>
            </LinkButton>
          </div>
        }
      </div>
    );
  }

}
