// @flow
import React from 'react';

import { Setting } from './elements-list.setting';

export class ElementsList extends Setting {

  render () {
    return (
      <table className="ex-list-xl">
        <tbody>
          { this.props.children }
        </tbody>
      </table>
    );
  }

}
