import React from 'react';

import { Settings } from './counter.setting';

export class CounterWidget extends Settings {

  render () {
    return (
      <div className="counter-widget">
        <div className="counter-body">
          <div className="circle-container">
            <div className="circle-outer">
              <div className="circle-inner">
                <div className="counter-value">
                  { this.props.value }
                </div>
                <div className="counter-units">
                  { this.props.circleTitle}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="counter-name">
          { this.props.title }
        </div>
      </div>
    );
  }

}
