export class RequirementsStatsParams {

  followingType: string;
  requirementType: string;

  constructor (data?: RequirementsStatsParams) {
    if (data) {
      this.followingType = data.followingType;
      this.requirementType = data.requirementType;
    }
  }

}
