// @flow
import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import { Modal } from 'components/dialogs';

import { PreviewContainer } from 'containers/portal/recruiter/job-offer';

export class MatchedJobOfferPreviewPage extends Component {

  render () {
    return (
      <Modal closePath="/portal/candidate/match">
        <Route
          path="/portal/candidate/:url/:jobOfferId/preview"
          exact
          component={PreviewContainer}/>
      </Modal>
    );
  }

}
