// @flow
import React from 'react';
import { Trans } from 'react-i18next';

import { Button } from 'components/buttons';
import { MatchChart } from 'components/elements';

import { connectStore } from 'middleware/connect';

import { Modal, ModalCaption } from 'containers/portal/modal-container';

import { SkillsDto } from 'rest/skill/skills.dto';
import { JobOfferDto } from 'rest/job-offers/job-offers.dto';

import { Settings, dispatchToProps, stateToProps } from './match-modal.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class MatchModal extends Settings {

  componentWillReceiveProps (props) {
    if (!this.props.open && props.open) {
      const jobOffer = new JobOfferDto();
      jobOffer.jobOfferSkills = new SkillsDto(this.props.skills);
      jobOffer.jobOfferSkills.emptySkills = false;
      jobOffer.jobOfferSkills.matchPoints = this.state.currentRate;
      this.props.getJobOfferMatchingRate(jobOffer);
      this.setState({ currentRate: props.current });
    }
  }

  apply () {
    this.props.apply(this.state.currentRate);
    this.props.close();
  }

  close () {
    this.props.close();
  }

  render () {
    return (
      <Modal
        overlay
        animation="fade"
        open={this.props.open}
        hideModalAvailable={false}
        hideModal={() => this.close()}
        closeOnOverlay={() => this.close()}
        className={`${this.props.className} match-modal-container`}>
        <ModalCaption
          hideModal={() => this.close()}
          title={this.props.t('portal.recruiter.balance-board.job-offer.matching-rate.header')}/>
        <form className="match-modal-form">
          <div className="matching-rate-title">
            <Trans i18nKey="portal.recruiter.balance-board.job-offer.matching-rate.title">
              Matching Rate
            </Trans>
          </div>
          <div className="matching-rate-description">
            <Trans i18nKey="portal.recruiter.balance-board.job-offer.matching-rate.description">
              You toggle the minimal matching points axis and see the total amount of candidates matched changing
              according to the toggle-set minimal matching points
            </Trans>
          </div>
          {
            this.props.matchingRate.length && <MatchChart
              data={this.props.matchingRate}
              total={this.props.total}
              current={this.state.currentRate}
              changeRate={(value: number) => this.setState({ currentRate: value })}/>
          }
          <div className="matching-rate-total">
            <Trans i18nKey="portal.recruiter.balance-board.job-offer.matching-rate.total">
              Total matched candidates:
            </Trans>
            &nbsp;
            <strong>{this.props.total}</strong>
          </div>
        </form>
        <div className="match-modal-form-action">
          <Button
            onClick={() => this.apply()}
            type="submit"
            recruiter
            medium>
            <Trans i18nKey="portal.recruiter.balance-board.job-offer.matching-rate.buttons.confirm">
              Confirm Rate:
            </Trans>
            &nbsp;
            {this.state.currentRate}%
          </Button>
          <Button
            medium
            type="button"
            onClick={() => this.close()}
            grey>
            <Trans i18nKey="portal.recruiter.balance-board.job-offer.matching-rate.buttons.cancel">
              Cancel
            </Trans>
          </Button>
        </div>
      </Modal>
    );
  }

}
