import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { Match } from 'common/types/match';
import type { GlobalState } from 'common/types/global-state';

import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';
import { ApplicationResponseDto } from 'rest/candidates/applications-template/applications-template.response.dto';

import {
  deleteCandidateApplication,
  getCandidateApplicationById
} from 'services/portal/candidate/applications/templates/templates.actions';

import './preview.scss';
import './preview.responsive.scss';

export type Props = {
  t: TransProps,
  match: Match,
  history: History,
  currentCandidate: CurrentCandidateDto,
  currentTemplate: ApplicationResponseDto,
  deleteCandidateApplication: (candidateId: string) => void,
  getCandidateApplicationById: (candidateId: string, templateId: string) => void
}

type State = {
  isDeleteModalOpen: boolean
}

export class Settings extends Component<Props, State> {

  static defaultProps: Props = {
    deleteCandidateApplication: () => {},
    getCandidateApplicationById: () => {},
    currentTemplate: new ApplicationResponseDto()
  };

  state: State = {
    isDeleteModalOpen: false
  };

}

export const stateToProps = (state: GlobalState) => ({
  currentTemplate: state.portal.candidate.applications.templates.currentTemplate,
  currentCandidate: state.portal.candidate.account.current.candidateData
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  deleteCandidateApplication,
  getCandidateApplicationById
}, dispatch);
