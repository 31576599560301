import React from 'react';

import Slider from 'rc-slider';
import { Trans } from 'react-i18next';
import ReactHighcharts from 'react-highcharts';

import { Settings } from './match-chart.setting';

export class MatchChart extends Settings {

  changeChartLine (value: number) {
    if (this.chart) {
      let chart = this.chart.getChart();
      const index = value - 1;
      const maxElement = Math.max(...this.props.data);
      chart.get('opacity').setData([[0, 0], [0, maxElement], [index, maxElement], [index, 0]]);
      chart.get('line').setData([[index, this.props.data[index]], [index, 0]]);
      chart.get('marker').setData([this.props.data[index] ? [index, this.props.data[index]] : []]);
    }
  }

  render () {
    return (
      <div className="match-chart-container">
        <div className="title-values">
          <Trans i18nKey="portal.recruiter.balance-board.job-offer.matching-rate.chart.axis-y">
            Candidates quantity
          </Trans>
        </div>
        <div className="title-points">
          <Trans i18nKey="portal.recruiter.balance-board.job-offer.matching-rate.chart.axis-x">
            Matching rate
          </Trans>
        </div>
        <div className="chart-container">
          {
            this.props.total ? <ReactHighcharts
              config={this.state.config}
              className="test"
              ref={c => {
                this.chart = c;
              }}/> : <div className="not-found">
              <Trans i18nKey="portal.recruiter.balance-board.job-offer.matching-rate.chart.not-found">
                Sorry, but no candidates matched for your job offer...
              </Trans>
            </div>
          }
        </div>
        <div className="slider-container">
          <Slider
            min={1}
            max={100}
            marks={this.state.marks}
            included={false}
            onChange={value => { this.changeChartLine(value); this.props.changeRate(value); }}
            defaultValue={this.state.current}/>
        </div>
      </div>
    );
  }

}
