// @flow
import React from 'react';

import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';

import { Button } from 'components/buttons';

import { Setting, stateToProps, dispatchToProps } from './history.setting';

import { setUserId } from 'middleware/auth';

@connectStore(stateToProps, dispatchToProps, true)
export class CandidateHistory extends Setting {

  sendPersonalDataRequest () {
    setUserId(this.props.currentCandidate.id);
    window.open('/information/gdpr');
  }

  render () {
    return (
      <div className="candidate-history-container">
        <div className="content">
          <p>
            <Trans i18nKey="portal.candidate.profile.history.information">
              A public administration is subject to the rules of the GDPR when processing personal data relating to an
              individual. It is the responsibility of the national administrations to support regional and local
              administration in preparing for the application of the GDPR.
            </Trans>
          </p>
          <p>
            <Trans i18nKey="portal.candidate.profile.history.personal-data">
              Most of the personal data held by public administrations is usually processed on the basis of a
              legal obligation or insofar as it is necessary to perform tasks carried out in the public interest
              or in the exercise of official authority vested in it.
            </Trans>
          </p>
        </div>
        {
          this.props.errorText && <p className="error">
            <Trans i18nKey="portal.candidate.profile.history.requested">
              Recently you sent request for data generation! Try later.
            </Trans>
          </p>
        }
        {
          !this.props.success ? <div className="history-actions">
            <Button
              medium
              disabled={!!this.props.errorText}
              onClick={() => this.sendPersonalDataRequest()}
              candidate>
              <Trans i18nKey="portal.candidate.profile.history.button">
                Generate Users List
              </Trans>
            </Button>
            <p>
              <Trans i18nKey="portal.candidate.profile.history.text">
                Send me the list of users (PDF) who looked at my profile
              </Trans>
            </p>
          </div> : <p className="success">
            <Trans i18nKey="portal.candidate.profile.history.success">
              You have successfully send request to admin for generating you personal data.
              Data will be generated withing 72 hours!
            </Trans>
          </p>
        }
      </div>
    );
  }

}
