import * as actions from './orders.constant';

import type { OrdersState } from './orders.type';

const initialState: OrdersState = {
  errorText: '',
  fetching: false,
  ordersList: []
};

export default (state = initialState, action): OrdersState => {
  switch (action.type) {
    case actions.GET_COMPANY_ORDERS_FAILURE:
      return {
        ...state,
        fetching: false,
        errorText: action.error
      };

    case actions.GET_COMPANY_ORDERS_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case actions.GET_COMPANY_ORDERS_SUCCESS:
      return {
        ...state,
        fetching: false,
        ordersList: action.orders
      };

    default:
      return state;
  }
};
