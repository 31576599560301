// @flow
import { Component } from 'react';

import './chips.scss';
import './chips.responsive.scss';

type Props = {
  color?: string;
};

export class Setting extends Component<Props> {

  static defaultProps: Props = {
    color: 'mutted'
  };

}
