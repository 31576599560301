// @flow
import { Component } from 'react';
import { TransProps } from 'react-i18next';

import { JobTitleDto } from 'rest/candidates/cv/job-title.dto';
import { CandidateDto } from 'rest/candidates/candidate.dto';

import './balance-board-info.scss';

type Props = {
  t: TransProps,
  cv: JobTitleDto,
  restricted: boolean,
  candidate: CandidateDto
}

export class Setting extends Component<Props> {

  static defaultProps = {
    restricted: false
  }

}
