// @flow
import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';

import { ReUseJobOfferModal } from 'containers/portal/recruiter/job-offer';
import { BalanceBoardPoster } from 'containers/portal/recruiter/balance-board';
import { CreateJobOfferModal, EditJobOfferModalPreview } from 'containers/portal/recruiter/job-offer';

export class BalanceBoardPage extends Component {

  render () {
    return (
      <Fragment>
        <Route
          path="/portal/recruiter/balance-board"
          component={BalanceBoardPoster}/>
        <Route
          path="/portal/recruiter/balance-board/create"
          exact
          component={CreateJobOfferModal}/>
        <Route
          path="/portal/recruiter/:path/:jobOfferId/:type(preview)"
          exact
          component={EditJobOfferModalPreview}/>
        <Route
          path="/portal/recruiter/:path/:jobOfferId/:type(re-use)"
          exact
          component={ReUseJobOfferModal}/>
      </Fragment>
    );
  }

}
