import { PromoCodeDto } from './../promo-code.dto';
import { PaymentSepaDetailsDto } from './../payment-sepa-details.dto';
import { PaymentRedirectUrlsDto } from './../payment-redirect-urls.dto';

export class SubscriptionDto {

  promoCode: PromoCodeDto;
  paymentSepaDetails: PaymentSepaDetailsDto;
  paymentMethod: string;
  subscriptionPlan: string;
  paymentRedirectUrls: PaymentRedirectUrlsDto;

  constructor (data?: SubscriptionDto) {
    if (data) {
      this.promoCode = data.promoCode;
      this.paymentSepaDetails = data.paymentSepaDetails;
      this.paymentMethod = data.paymentMethod;
      this.subscriptionPlan = data.subscriptionPlan;
      this.paymentRedirectUrls = new PaymentRedirectUrlsDto(
        data.paymentRedirectUrls.successRedirectUrl,
        data.paymentRedirectUrls.failRedirectUrl,
        data.paymentRedirectUrls.timeoutRedirectUrl);
    }
  }

}
