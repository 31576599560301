import React, { Component } from 'react';

import { Route } from 'react-router-dom';

import { AuthContainer, AuthFormContainer } from 'containers/auth/layouts';

import { SignInPage } from './sign-in';

import { SignUpPage } from './sign-up';
import { ConfirmRegistrationPage } from './sign-up/confirm';

import { ResetPasswordPage } from './reset';
import { InitResetPasswordPage } from './reset/init';
import { ConfirmResetPasswordPage } from './reset/confirm';
import { SuccessResetPasswordPage } from './reset/confirm/success';

export class RecruiterAuthPage extends Component {

  render () {
    return (
      <AuthContainer>
        <AuthFormContainer>
          <Route
            path="/auth/recruiter"
            exact
            component={SignInPage}/>
          <Route
            path="/auth/recruiter/sign-up"
            exact
            component={SignUpPage}/>
          <Route
            exact
            path="/auth/recruiter/sign-up/confirm"
            component={ConfirmRegistrationPage}/>
          <Route
            path="/auth/recruiter/reset"
            exact
            component={ResetPasswordPage}/>
          <Route
            path="/auth/recruiter/reset/init"
            exact
            component={InitResetPasswordPage}/>
          <Route
            path="/auth/recruiter/reset/confirm/:id"
            exact
            component={ConfirmResetPasswordPage}/>
          <Route
            path="/auth/recruiter/reset/success"
            exact
            component={SuccessResetPasswordPage}/>
        </AuthFormContainer>
      </AuthContainer>
    );
  }

}
