export class AddressResponseDto {

  name: string;
  description: string;

  constructor (data?: AddressResponseDto) {
    if (data) {
      this.name = data.name;
      this.description = data.description;
    }
  }

}
