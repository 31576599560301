export const GET_COMPANY_BRANDS_REQUEST = 'GET_COMPANY_BRANDS_REQUEST';
export const GET_COMPANY_BRANDS_SUCCESS = 'GET_COMPANY_BRANDS_SUCCESS';
export const GET_COMPANY_BRANDS_FAILURE = 'GET_COMPANY_BRANDS_FAILURE';

export const GET_COMPANY_BRANCH_REQUEST = 'GET_COMPANY_BRANCH_REQUEST';
export const GET_COMPANY_BRANCH_SUCCESS = 'GET_COMPANY_BRANCH_SUCCESS';
export const GET_COMPANY_BRANCH_FAILURE = 'GET_COMPANY_BRANCH_FAILURE';

export const UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAILURE = 'UPDATE_COMPANY_FAILURE';

export const GET_COMPANY_BY_ID_REQUEST = 'GET_COMPANY_BY_ID_REQUEST';
export const GET_COMPANY_BY_ID_SUCCESS = 'GET_COMPANY_BY_ID_SUCCESS';
export const GET_COMPANY_BY_ID_FAILURE = 'GET_COMPANY_BY_ID_FAILURE';

export const GET_COMPANY_PREMIUM_COST_REQUEST = 'GET_COMPANY_PREMIUM_COST_REQUEST';
export const GET_COMPANY_PREMIUM_COST_SUCCESS = 'GET_COMPANY_PREMIUM_COST_SUCCESS';
export const GET_COMPANY_PREMIUM_COST_FAILURE = 'GET_COMPANY_PREMIUM_COST_FAILURE';

export const RATING_COMPANY_SYSTEM = {
  restaurant: [
    {
      id: null,
      name: 'Not selected'
    },
    {
      id: 1,
      name: '1-star'
    },
    {
      id: 2,
      name: '2-star'
    },
    {
      id: 3,
      name: '3-star'
    }
  ],
  hotel: [
    {
      id: null,
      name: 'Not selected'
    },
    {
      id: 1,
      name: '1-star'
    },
    {
      id: 2,
      name: '1 star superior'
    },
    {
      id: 3,
      name: '2-star'
    },
    {
      id: 4,
      name: '2 star superior'
    },
    {
      id: 5,
      name: '3-star'
    },
    {
      id: 6,
      name: '3 star superior'
    },
    {
      id: 7,
      name: '4-star'
    },
    {
      id: 8,
      name: '4 star superior'
    },
    {
      id: 9,
      name: '5-star'
    },
    {
      id: 10,
      name: '5 star superior'
    }
  ]
};
