import React from 'react';

import { Trans } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { CandidateProfile } from 'containers/portal/recruiter/candidates';

import { Settings, dispatchToProps, stateToProps } from './candidate-profile.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class CandidateProfileView extends Settings {

  componentDidMount () {
    this.props.getCandidateById(this.props.match.params.candidateId);
  }

  closeProfile () {
    this.props.history.push(`/portal/recruiter/match/${this.props.match.params.jobOfferId}/candidates`);
  }

  render () {
    return (
      <div className="candidate-profile-preview">
        <div className="profile-breadcrumbs">
          <div className="title">
            <Trans i18nKey="portal.recruiter.match.preview.candidate.profile">
              Candidate Profile
            </Trans>
          </div>
          <div className="actions">
            <img
              onClick={() => this.closeProfile()}
              src="/images/shared/cancel-s.svg"x
              alt="close"/>
          </div>
        </div>
        <PerfectScrollbar className="profile-preview">
          <CandidateProfile
            restricted={!this.props.candidate.email}
            candidate={this.props.candidate}/>
        </PerfectScrollbar>
      </div>
    );
  }

}
