import * as actions from './units.constants';

import { UnitDto } from 'rest/unit/unit.dto';
import { ListDto } from 'middleware/dto/list';

import { getUnits } from 'rest/unit/unit.rest';

const getUnitsRequest = () => ({
  type: actions.GET_UNITS_REQUEST
});

const getUnitsSuccess = (units: ListDto[]) => ({
  type: actions.GET_UNITS_SUCCESS,
  units
});

const getUnitsFailure = (error: string) => ({
  type: actions.GET_UNITS_FAILURE,
  error
});

export const getAllUnits = (params: UnitDto) => {
  return dispatch => {
    dispatch(getUnitsRequest());
    return getUnits(params)
      .then(response => dispatch(getUnitsSuccess(response.data.content)))
      .catch(error => dispatch(getUnitsFailure(error)));
  };
};
