// @flow
export class PersonalDataDto {

  userId: string;
  userType: string;

  constructor (data?: PersonalDataDto) {
    if (data) {
      this.userId = data.userId;
      this.userType = data.userType;
    }
  }

}
