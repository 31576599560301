import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { deleteCompanyJobOffer } from 'services/portal/recruiter/account/job-offer/job-offer.actions';

import './delete-modal.scss';

export type Props = {
  t: TransProps,
  open?: boolean,
  jobOfferId?: string,
  hideModal?: () => void,
  companyId?: string,
  submitAction?: () => void,
  deleteCompanyJobOffer?: (companyId: string, jobOfferId: string) => void
}

export class Setting extends Component<Props> {

  static defaultProps = {
    submitAction: () => {}
  }

}

export const stateToProps = (state: GlobalState) => ({
  companyId: state.portal.recruiter.account.current.currentUser.companyId
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  deleteCompanyJobOffer
}, dispatch);
