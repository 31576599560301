export const INVITE_TEAM_MEMBERS_REQUEST = 'INVITE_TEAM_MEMBERS_REQUEST';
export const INVITE_TEAM_MEMBERS_SUCCESS = 'INVITE_TEAM_MEMBERS_SUCCESS';
export const INVITE_TEAM_MEMBERS_FAILURE = 'INVITE_TEAM_MEMBERS_FAILURE';

export const GET_COMPANY_TEAM_MEMBERS_REQUEST = 'GET_COMPANY_TEAM_MEMBERS_REQUEST';
export const GET_COMPANY_TEAM_MEMBERS_SUCCESS = 'GET_COMPANY_TEAM_MEMBERS_SUCCESS';
export const GET_COMPANY_TEAM_MEMBERS_FAILURE = 'GET_COMPANY_TEAM_MEMBERS_FAILURE';

export const DELETE_COMPANY_TEAM_MEMBER_REQUEST = 'DELETE_COMPANY_TEAM_MEMBER_REQUEST';
export const DELETE_COMPANY_TEAM_MEMBER_SUCCESS = 'DELETE_COMPANY_TEAM_MEMBER_SUCCESS';
export const DELETE_COMPANY_TEAM_MEMBER_FAILURE = 'DELETE_COMPANY_TEAM_MEMBER_FAILURE';
