import { combineReducers } from 'redux';

import askMe from './ask-me/ask-me.reducer';
import chats from './chats/chats.reducer';
import units from './units/units.reducer';
import modal from './modal/modal.reducer';
import skills from './skills/skills.reducer';
import images from './images/images.reducer';
import address from './address/address.reducer';
import software from './software/software.reducer';
import languages from './languages/languages.reducer';
import brand from './brand/brand.reducer';
import recruiter from './recruiter/recruiter.reducer';
import candidate from './candidate/candidate.reducer';
import documents from './documents/documents.reducer';
import jobTitles from './job-titles/job-titles.reducer';
import requestSkill from './skills/request/request.reducer';
import personalData from './personal-data/personal-data.reducer';
import notifications from './notifications/notifications.reducer';
import portalNavShow from './portal-mobile-nav/portal-mobile-nav.reducer';

export default combineReducers({
  askMe,
  chats,
  units,
  modal,
  skills,
  images,
  address,
  software,
  documents,
  languages,
  jobTitles,
  brand,
  recruiter,
  candidate,
  personalData,
  requestSkill,
  notifications,
  portalNavShow
});
