// @flow
import React from 'react';

import { Trans } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Button } from 'components/buttons';
import { Spinner } from 'components/elements';
import { FormGroup, InputControl, CheckboxControl } from 'components/controls';

import { ListDto } from 'middleware/dto';
import { connectStore } from 'middleware/connect';
import { SearchSkillDto } from 'rest/skill/search-skill.dto';

import { Modal, ModalCaption } from 'containers/portal/modal-container';

import { Settings, stateToProps, dispatchToProps } from './skills.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class AddSkillsModal extends Settings {

  changeSearchState (value: string) {
    this.setState({ searchItem: value }, () => this.fetchSkills());
  }

  changeSelectedState (checked: boolean, value: ListDto) {
    const selected: ListDto[] = [...this.state.selectedItems];
    if (checked) {
      selected.push(value);
    } else {
      const index: number = selected.findIndex((item: ListDto) => item.id === value.id);
      selected.splice(index, 1);
    }
    this.setState({ selectedItems: selected });
  }

  fetchSkills (page: number = 0) {
    const search: SearchSkillDto = new SearchSkillDto();
    search.page = page;
    search.size = 20;
    search.exclude = true;
    search.q = this.state.searchItem;
    this.props.search(search);
  }

  clearState () {
    this.setState({
      searchItem: '',
      selectedItems: []
    });
  }

  close () {
    this.clearState();
    this.props.close();
    this.props.resetSearchResult();
  }

  apply () {
    this.props.addNewItems(this.state.selectedItems);
    this.close();
  }

  render () {
    return (
      <Modal
        overlay
        animation="fade"
        open={this.props.open}
        hideModalAvailable={false}
        hideModal={() => this.close()}
        closeOnOverlay={() => this.close()}
        className={`add-skills-modal ${this.props.className}`}>
        <ModalCaption
          hideModal={() => this.close()}
          title={this.props.t('portal.recruiter.balance-board.job-offer.add-modals.skills.title')}/>
        <form className="add-skills-modal-form">
          <FormGroup className="full-width">
            <InputControl
              required
              value={this.state.searchItem}
              change={(value: string): void => this.changeSearchState(value)}
              placeholder={this.props.t('portal.recruiter.balance-board.job-offer.add-modals.skills.search')}
              type="text"/>
          </FormGroup>
        </form>
        {
          this.props.open ? <PerfectScrollbar className="overflow-modal-content">
            <InfiniteScroll
              hasMore={this.props.hasMoreItems}
              loadMore={page => this.fetchSkills(page)}
              useWindow={false}
              threshold={150}>
              <ul className="list-content">
                {
                  this.props.skills.map((skill, index) => (
                    <li
                      key={index}
                      className="list-content-item">
                      <CheckboxControl
                        value={skill.selected}
                        className={this.props.recruiter ? 'accent' : ''}
                        change={(checked: boolean) => this.changeSelectedState(checked, skill)}
                        label={skill.name}/>
                    </li>
                  ))
                }
                {
                  !this.props.skills.length &&
                  <p className="empty">
                    {!this.props.fetching && this.props.t('portal.recruiter.balance-board.job-offer.add-modals.empty')}
                  </p>
                }
                <Spinner show={this.props.fetching}/>
              </ul>
            </InfiniteScroll>
          </PerfectScrollbar> : null
        }
        <div className="add-skills-action">
          <Button
            type="submit"
            candidate={this.props.candidate}
            recruiter={this.props.recruiter}
            disabled={!this.state.selectedItems.length > 0}
            onClick={this.apply.bind(this)}
            medium>
            <Trans i18nKey="portal.recruiter.balance-board.job-offer.add-modals.buttons.apply">
              Apply
            </Trans>
          </Button>
          <Button
            medium
            type="button"
            onClick={() => this.close()}
            grey>
            <Trans i18nKey="portal.recruiter.balance-board.job-offer.add-modals.buttons.cancel">
              Cancel
            </Trans>
          </Button>
        </div>
      </Modal>
    );
  }

}
