import React from 'react';

import { Settings } from './candidates-container.setting';

export class CandidatesContainer extends Settings {

  render () {
    return (
      <div className="match-candidates-modal">
        { this.props.children }
      </div>
    );
  }

}
