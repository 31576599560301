import axios, { AxiosPromise } from 'axios';

import { JobOffersFavoritesStatsResponseDto } from 'rest/companies/job-offers/job-offers-stats.dto';

export async function getJobOfferFavoritesStats (candidateId: string)
  : AxiosPromise<JobOffersFavoritesStatsResponseDto> {
  try {
    return await axios.get(`/candidates/${candidateId}/job-offer-favorites/stats`);
  } catch (error) {
    throw error;
  }
}
