import axios from 'axios';

import { ListDto } from 'middleware/dto';
import { UnitDto } from 'rest/unit/unit.dto';

export async function getUnits (params: UnitDto): Promise<ListDto[]> {
  try {
    return await axios.get(`/units`, {
      params
    });
  } catch (error) {
    throw error;
  }
}
