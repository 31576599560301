// @flow
import React from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { TabLink, Tabs } from 'components/tabs';
import { StatisticsItem } from 'components/widgets/statistics';

import { Settings, stateToProps, dispatchToProps } from './follow.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class FollowSidebar extends Settings {

  componentDidMount () {
    this.loadFollowsStatistic(this.props.currentUser.id);
  }

  componentWillUpdate (props) {
    if (!this.props.currentUser.id) {
      this.loadFollowsStatistic(props.currentUser.id);
    }
  }

  componentWillUnmount () {
    this.props.resetFollowsStatistic();
  }

  loadFollowsStatistic (recruiterId: string) {
    if (recruiterId) {
      this.props.getFollowingStatistic(recruiterId);
      this.props.getFollowersStatistic(recruiterId);
    }
  }

  render () {
    return (
      <Tabs>
        <TabLink
          link="/portal/recruiter/follow/following"
          label={this.props.t('portal.recruiter.follows.sidebar.following.title')}
          counter={this.props.totalFollowing}
          onClick={() => this.props.changeCandidateFollowingFilter()}>
          <PerfectScrollbar className="statistics-items">
            <StatisticsItem
              title={this.props.t('portal.recruiter.follows.sidebar.following.all')}
              value={this.props.totalFollowing}
              active={!this.props.followingUnitId}
              onClick={() => this.props.changeCandidateFollowingFilter()}
              recruiter/>
            {
              this.props.followingStats.map((stat, index) => (
                <StatisticsItem
                  key={index}
                  title={`${stat.name.toLowerCase()}`}
                  value={stat.count}
                  active={this.props.followingUnitId === stat.id}
                  onClick={() => this.props.changeCandidateFollowingFilter(stat.id)}
                  recruiter/>
              ))
            }
          </PerfectScrollbar>
        </TabLink>
        <TabLink
          link="/portal/recruiter/follow/followers"
          label={this.props.t('portal.recruiter.follows.sidebar.followers.title')}
          counter={this.props.totalFollowers}
          onClick={() => this.props.changeCandidateFollowersFilter()}>
          <PerfectScrollbar className="statistics-items">
            <StatisticsItem
              title={this.props.t('portal.recruiter.follows.sidebar.followers.all')}
              value={this.props.totalFollowers}
              active={!this.props.followersUnitId}
              onClick={() => this.props.changeCandidateFollowersFilter()}
              recruiter/>
            {
              this.props.followersStats.map((stat, index) => (
                <StatisticsItem
                  key={index}
                  title={`${stat.name.toLowerCase()}`}
                  active={this.props.followersUnitId === stat.id}
                  onClick={() => this.props.changeCandidateFollowersFilter(stat.id)}
                  value={stat.count}
                  recruiter/>
              ))
            }
          </PerfectScrollbar>
        </TabLink>
      </Tabs>
    );
  }

}
