// @flow
import React from 'react';

import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroller';

import { connectStore } from 'middleware/connect';

import { ListPlaceholder, Spinner } from 'components/elements';
import { ElementsList, JobOfferRow } from 'components/elements-list';

import { JobOffersParams } from 'rest/public/job-offers/job-offers.params';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

import { Setting, stateToProps, dispatchToProps } from './following.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class FollowingJobs extends Setting {

  componentWillUnmount () {
    this.props.resetAllJobOffersFollowing();
  }

  toggleFavorite (jobOffer: JobOffersResponseDto) {
    jobOffer.favorite
      ? this.props.deleteJobFromFavorite(jobOffer.jobOfferId, this.props.currentCandidateId)
      : this.props.addJobToFavorite(jobOffer.jobOfferId, this.props.currentCandidateId);
    toast.success(jobOffer.favorite
      ? this.props.t('portal.candidate.notifications.favorite-delete')
      : this.props.t('portal.candidate.notifications.favorite-add')
    );
  }

  openJobOffer (selectedJobOfferId: string) {
    this.props.history.push(`/portal/candidate/follow/following/${selectedJobOfferId}/preview`);
  }

  loadJobOffersList (page: number = 0) {
    const params = new JobOffersParams(this.props.followingFilters);
    params.page = page;
    params.extraFields = ['favorite'];
    this.props.getFollowingJobOffers(params);
  }

  render () {
    return (
      <InfiniteScroll
        threshold={100}
        useWindow={false}
        pageStart={-1}
        hasMore={this.props.hasMoreItems}
        loadMore={() => this.loadJobOffersList(this.props.currentPage)}>
        <ElementsList>
          {this.props.jobOffers.map((jobOffer: JobOffersResponseDto) => (
            <JobOfferRow
              jobOffer={jobOffer}
              action={() => this.toggleFavorite(jobOffer)}
              onClick={() => this.openJobOffer(jobOffer.jobOfferId)}
              key={jobOffer.jobOfferId}/>
          ))}
        </ElementsList>
        {!this.props.jobOffers.length && <ListPlaceholder
          title={this.props.t('portal.candidate.follows.list.following.placeholder.title')}>
          <Trans i18nKey="portal.candidate.follows.list.following.placeholder.content">
            Start following a recruiter to see all their job offers and updates.
          </Trans>
        </ListPlaceholder>}
        <Spinner show={this.props.fetching}/>
      </InfiniteScroll>
    );
  }

}
