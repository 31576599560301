import { PaginationDto } from 'middleware/dto';

export class AlertsDto extends PaginationDto {

  userId: string;
  alertNotificationFilter: string;

  constructor (data?: AlertsDto) {
    super(data);
    if (data) {
      this.userId = data.userId;
      this.alertNotificationFilter = data.alertNotificationFilter;
    }
  }

}
