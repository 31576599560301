import * as actions from './work-experience.constants';

import { Action } from 'redux';

import {
  getWorkExperience,
  createWorkExperience,
  updateWorkExperience,
  deleteWorkExperience
} from 'rest/candidates/work-experience/work-experience.rest';

import { WorkExperienceDto } from 'rest/candidates/work-experience/work-experience.dto';

const createWorkExperienceRequest = (): Action => ({
  type: actions.CREATE_WORK_EXPERIENCE_REQUEST
});

const createWorkExperienceSuccess = (experience: WorkExperienceDto): Action => ({
  type: actions.CREATE_WORK_EXPERIENCE_SUCCESS,
  experience
});

const createWorkExperienceFailure = (): Action => ({
  type: actions.CREATE_WORK_EXPERIENCE_FAILURE
});

const getWorkExperienceRequest = (): Action => ({
  type: actions.GET_WORK_EXPERIENCE_REQUEST
});

const getWorkExperienceSuccess = (experience: WorkExperienceDto[]): Action => ({
  type: actions.GET_WORK_EXPERIENCE_SUCCESS,
  experience
});

const getWorkExperienceFailure = (error: string): Action => ({
  type: actions.GET_WORK_EXPERIENCE_FAILURE,
  error
});

const updateWorkExperienceRequest = (): Action => ({
  type: actions.UPDATE_WORK_EXPERIENCE_REQUEST
});

const updateWorkExperienceSuccess = (experience: WorkExperienceDto): Action => ({
  type: actions.UPDATE_WORK_EXPERIENCE_SUCCESS,
  experience
});

const updateWorkExperienceFailure = (): Action => ({
  type: actions.UPDATE_WORK_EXPERIENCE_FAILURE
});

const deleteWorkExperienceRequest = (): Action => ({
  type: actions.DELETE_WORK_EXPERIENCE_REQUEST
});

const deleteWorkExperienceSuccess = (experienceId: string): Action => ({
  type: actions.DELETE_WORK_EXPERIENCE_SUCCESS,
  experienceId
});

const deleteWorkExperienceFailure = (): Action => ({
  type: actions.DELETE_WORK_EXPERIENCE_FAILURE
});

const pushWorkExperienceSuccess = (experience: WorkExperienceDto): Action => ({
  type: actions.PUSH_NEW_EXPERIENCE,
  experience
});

const removeWorkExperienceSuccess = (index: number): Action => ({
  type: actions.REMOVE_PUSH_NEW_EXPERIENCE,
  index
});

const changeWorkExperienceSuccess = (index, value) => ({
  type: actions.CHANGE_WORK_EXPERIENCE_SUCCESS,
  index,
  value
});

const resetWorkExperienceSuccess = () => ({
  type: actions.RESET_WORK_EXPERIENCE_SUCCESS
});

export function changeWorkExperience (index, experience: WorkExperienceDto) {
  return dispatch => dispatch(changeWorkExperienceSuccess(index, experience));
}

export function pushExperience (experience: WorkExperienceDto) {
  return dispatch => dispatch(pushWorkExperienceSuccess(experience));
}

export function removeExperience (index: number) {
  return dispatch => dispatch(removeWorkExperienceSuccess(index));
}

export function resetWorkExperience () {
  return dispatch => dispatch(resetWorkExperienceSuccess());
}

export function createCandidateWorkExperience (candidateId: string, data: WorkExperienceDto) {
  return dispatch => {
    dispatch(createWorkExperienceRequest());
    return createWorkExperience(candidateId, data)
      .then(() => {
        dispatch(createWorkExperienceSuccess(data));
        return dispatch(getCandidateWorkExperiences(candidateId));
      })
      .catch(() => dispatch(createWorkExperienceFailure()));
  };
}

export function getCandidateWorkExperiences (candidateId: string) {
  return dispatch => {
    dispatch(getWorkExperienceRequest());
    return getWorkExperience(candidateId)
      .then(response => dispatch(getWorkExperienceSuccess(response.data)))
      .catch(() => dispatch(getWorkExperienceFailure()));
  };
}

export function updateCandidateWorkExperience (candidateId: string, experienceId: string, data: WorkExperienceDto) {
  return dispatch => {
    dispatch(updateWorkExperienceRequest());
    return updateWorkExperience(candidateId, experienceId, data)
      .then(() => {
        dispatch(updateWorkExperienceSuccess(data));
        return dispatch(getCandidateWorkExperiences(candidateId));
      })
      .catch(() => dispatch(updateWorkExperienceFailure()));
  };
}

export function deleteCandidateWorkExperience (candidateId: string, experienceId: string) {
  return dispatch => {
    dispatch(deleteWorkExperienceRequest());
    return deleteWorkExperience(candidateId, experienceId)
      .then(() => dispatch(deleteWorkExperienceSuccess(experienceId)))
      .catch(() => dispatch(deleteWorkExperienceFailure()));
  };
}
