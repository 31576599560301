import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { TransProps } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { PortletContainer, PortletHeader, PortletBody } from 'components/portlet';

import { MainCVModal, CVButtonAction } from 'containers/portal/candidate/cv';

type Props = {
  t: TransProps
}

@connectTranslation()
export class CVPage extends Component<Props> {

  render () {
    return (
      <PortletContainer>
        <PortletHeader title={this.props.t('portal.candidate.cv.list.title')}>
          <div className="mobile-sm-hide">
            <CVButtonAction/>
          </div>
        </PortletHeader>
        <PortletBody className="mobile-portlet-cv">
          <Route
            path="/portal/candidate/cv"
            component={MainCVModal}/>
        </PortletBody>
        <div className="mobile-btn-show">
          <CVButtonAction/>
        </div>
      </PortletContainer>
    );
  }

}
