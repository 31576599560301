import * as actions from './lifestyles.constants';

import { Action } from 'redux';

import { LifeStyleDto } from 'rest/candidates/lifestyles/lifestyles.dto';

import { getLifeStyle, createLifeStyle } from 'rest/candidates/lifestyles/lifestyles.rest';

const getCandidateLifeStyleRequest = (): Action => ({
  type: actions.GET_LIFESTYLE_REQUEST
});

const getCandidateLifeStyleSuccess = (lifestyle: LifeStyleDto): Action => ({
  type: actions.GET_LIFESTYLE_SUCCESS,
  lifestyle
});

const getCandidateLifeStyleFailure = (): Action => ({
  type: actions.GET_LIFESTYLE_FAILURE
});

const createCandidateLifeStyleRequest = (): Action => ({
  type: actions.CREATE_LYFESTYLE_REQUEST
});

const createCandidateLifeStyleSuccess = (lifestyle: LifeStyleDto): Action => ({
  type: actions.CREATE_LYFESTYLE_SUCCESS,
  lifestyle
});

const createCandidateLifeStyleFailure = (): Action => ({
  type: actions.CREATE_LYFESTYLE_FAILURE
});

export function getCandidateLifeStyle (candidateId: string): LifeStyleDto {
  return dispatch => {
    dispatch(getCandidateLifeStyleRequest());
    return getLifeStyle(candidateId)
      .then(response => dispatch(getCandidateLifeStyleSuccess(response.data)))
      .catch(() => dispatch(getCandidateLifeStyleFailure()));
  };
}

export function createCandidateLifeStyle (candidateId: string, data: LifeStyleDto) {
  return dispatch => {
    dispatch(createCandidateLifeStyleRequest());
    return createLifeStyle(candidateId, data)
      .then(() => {
        dispatch(createCandidateLifeStyleSuccess(data));
        return dispatch(getCandidateLifeStyle(candidateId));
      })
      .catch(() => dispatch(createCandidateLifeStyleFailure()));
  };
}
