// @flow
import React, { Component } from 'react';
import { Player, BigPlayButton } from 'video-react';
import cn from 'classnames';
import { Container } from 'components/layouts';
import POSTERIMG from './excellentPoster.PNG';
import './video-react.css';
import { getLocalization } from 'middleware/localization';
import PropTypes from 'prop-types';
const language = getLocalization();

export class RegistrationVideo extends Component {

  static get propTypes() {
    return {
      recruiter: PropTypes.bool,
      onBoarding: PropTypes.bool,
      howItwork: PropTypes.bool
    };
  }

  state = {
    isRecruiter: true,
    src: '',
    index: 0,
    videoList: [],
    autoPlay: false
  }

  constructor() {
    super();
    this.videoRef = React.createRef();
    this._TogglePrev = this._TogglePrev.bind(this);
    this._ToggleNext = this._ToggleNext.bind(this);
  }

  _ToggleNext() {
    if (this.state.index === this.state.videoList.length - 1) {
      return;
    }
    this.setState(prevState => ({
      index: prevState.index + 1
    }));
  }

  _TogglePrev() {
    if (this.state.index === 0) {
      return;
    }
    this.setState(prevState => ({
      index: prevState.index - 1
    }));
  }

  componentDidMount () {
    const { recruiter } = this.props;
    this.initVideoLink(recruiter);
    this.videoRef.current.subscribeToStateChange(this.playNextVideo.bind(this));
  }
  getVideoList(isRecruiter: boolean) {
    if (language === 'de') {
      const s3URL = 'https://ejobs-videos.s3.eu-central-1.amazonaws.com/registrationVideoDE';
      const regVideoListRecruiter = [ `${s3URL}/Szene_32+Create+an+account+Konto+erstellen.mp4`,
        `${s3URL}/Szene_33+Create+an+account+Code.mp4`, `${s3URL}/Szene_34+Recruiter+Account.mp4`,
        `${s3URL}/Szene_35+Payment.mp4`, `${s3URL}/Szene_36+Invoice+details.mp4`,
        `${s3URL}/Szene_37+Premium+Plan+Payment.mp4`, `${s3URL}/Szene_38+Basis+Plan.mp4`,
        `${s3URL}/Szene_39+Basis+Plan+Contact.mp4`, `${s3URL}/Szene_40+Buy+Contact+.mp4`];

      const regVideoListCandidates = [`${s3URL}/candidate/Szene_6+Create+an+account.mp4`];
      return isRecruiter ? regVideoListRecruiter : regVideoListCandidates;
    }
  }

  playNextVideo(state, prevState) {
    if (state.ended && this.state.index < this.state.src.length - 1) {
      this.setState({ index: this.state.index + 1, autoPlay: true });
    }
  }

  initVideoLink (isRecruiter: boolean) {
    this.setState({ isRecruiter: isRecruiter, src: this.getVideoList(isRecruiter) });
  }

  render () {
    const videoClasses = cn([ 'video-container', {
      'on-boarding-container': this.props.onBoarding
    }]);
    return (
      <div className={videoClasses} style={ this.props.howItwork ? { marginTop: -50 } : { marginTop: 0 }}>
        <Container className="video-content">
          <div className="video-player">
            <div className='video-controls-container'>
              <div className='player-wrapper'>
                <Player className="video"
                  autoPlay = {this.state.autoPlay}
                  ref= {this.videoRef}
                  poster={POSTERIMG}
                  src={ this.state.src[this.state.index] }
                  onVideoEnd={() => { this.playNextVideo(); }}
                >
                  <BigPlayButton position="center" />
                </Player>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }

}
