export const GET_APPLICATION_TEMPLATE_BY_ID_REQUEST = 'GET_APPLICATION_TEMPLATE_BY_ID_REQUEST';
export const GET_APPLICATION_TEMPLATE_BY_ID_SUCCESS = 'GET_APPLICATION_TEMPLATE_BY_ID_SUCCESS';
export const GET_APPLICATION_TEMPLATE_BY_ID_FAILURE = 'GET_APPLICATION_TEMPLATE_BY_ID_FAILURE';

export const GET_SENT_TEMPLATES_REQUEST = 'GET_SENT_TEMPLATES_REQUEST';
export const GET_SENT_TEMPLATES_SUCCESS = 'GET_SENT_TEMPLATES_SUCCESS';
export const GET_SENT_TEMPLATES_FAILURE = 'GET_SENT_TEMPLATES_FAILURE';

export const RESET_SENT_TEMPLATES_SUCCESS = 'RESET_SENT_TEMPLATES_SUCCESS';

export const CHANGE_SENT_TEMPLATES_FILTER_SUCCESS = 'CHANGE_SENT_TEMPLATES_FILTER_SUCCESS';
