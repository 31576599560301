// @flow
import React from 'react';

import moment from 'moment';

import { connectStore } from 'middleware/connect';

import { StatisticsContainer, StatisticsItem } from 'components/widgets/statistics';

import { Settings, stateToProps, dispatchToProps } from './balance-board.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class BalanceBoardSidebar extends Settings {

  componentDidMount () {
    this.loadActiveJobOffers(this.props.currentUser.companyId);
  }

  componentWillUpdate (props) {
    if (!this.props.currentUser.companyId) {
      this.loadActiveJobOffers(props.currentUser.companyId);
    }
  }

  componentWillUnmount () {
    this.props.resetJobOffers();
  }

  loadActiveJobOffers (companyId: string) {
    if (companyId) {
      this.props.getAllCompanyJobOffers(companyId, 'PUBLISHED');
    }
  }

  openJobOffer (jobOfferId: string) {
    this.props.history.push(`/portal/recruiter/balance-board/${jobOfferId}/preview`);
  }

  render () {
    return (
      <StatisticsContainer
        title={`${this.props.t('portal.recruiter.balance-board.sidebar.title')} (${this.props.jobOffers.length})`}>
        {
          this.props.jobOffers.map((jobOffer, index) => (
            <StatisticsItem
              key={index}
              title={jobOffer.jobTitleName}
              value={`${jobOffer.matchedPoints} %`}
              onClick={() => this.openJobOffer(jobOffer.jobOfferId)}
              recruiter
              description={`${this.props.t('portal.recruiter.balance-board.sidebar.updated')}
              ${moment(jobOffer.publishedDate).parseZone().fromNow()}`}/>
          ))
        }
      </StatisticsContainer>
    );
  }

}
