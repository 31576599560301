// @flow
import { Component, createRef } from 'react';

import { AddressResponseDto } from 'rest/address/address.response.dto';

import './autosuggest-select.scss';

export type Props = {
  label: string,
  error?: boolean,
  required?: boolean,
  errorText?: string,
  bindValue?: string,
  change: () => void,
  placeholder?: string,
  defaultValue: string,
  items: AddressResponseDto[],
  search: (value: string) => void
};

type State = {
  inputValue: string,
  items: AddressResponseDto[]
}

export class Setting extends Component<Props, State> {

  static defaultProps: Props = {
    label: '',
    items: [],
    error: false,
    required: false,
    bindValue: 'name',
    errorText: '',
    change: () => {},
    placeholder: 'Select...',
    defaultValue: '',
    search: () => {}
  };

  state: State = {
    inputValue: '',
    items: []
  };

  inputElement = createRef();

}
