import axios, { AxiosPromise } from 'axios';

import { CompanyOrderDto } from './order.dto';

export async function getCompanyOrders (companyId: string, paymentStatus: string): AxiosPromise<CompanyOrderDto> {
  try {
    return await axios.get(`/companies/${companyId}/orders`, {
      params: {
        paymentStatus
      }
    });
  } catch (error) {
    throw error;
  }
}
