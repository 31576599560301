// @flow
import * as actions from './work-experience.constants';

import { Action } from 'redux';

import type { WorkExperienceStateType } from './work-experience.type';

const initialState: WorkExperienceStateType = {
  fetching: false,
  content: [],
  fetchedContent: [],
  createSuccess: false
};

export default function (state: WorkExperienceStateType = initialState, action: Action): WorkExperienceStateType {
  switch (action.type) {
    case actions.GET_WORK_EXPERIENCE_REQUEST:
    case actions.UPDATE_WORK_EXPERIENCE_REQUEST:
      return {
        ...state,
        createSuccess: false,
        fetching: true
      };

    case actions.CREATE_WORK_EXPERIENCE_SUCCESS:
    case actions.UPDATE_WORK_EXPERIENCE_SUCCESS:
      return {
        ...state,
        createSuccess: true,
        fetching: true
      };

    case actions.GET_WORK_EXPERIENCE_SUCCESS:
      return {
        ...state,
        fetching: false,
        content: action.experience,
        fetchedContent: action.experience
      };

    case actions.PUSH_NEW_EXPERIENCE: {
      const content = [ ...state.content ];
      content.unshift(action.experience);
      return {
        ...state,
        content
      };
    }

    case actions.CHANGE_WORK_EXPERIENCE_SUCCESS:
      return {
        ...state,
        content: state.content.map((item, index) => {
          if (index === action.index) {
            return {
              ...item,
              ...action.value
            };
          }
          return item;
        })
      };

    case actions.REMOVE_PUSH_NEW_EXPERIENCE:
      return {
        ...state,
        content: state.content.filter((item, index: number) => index !== action.index)
      };

    case actions.DELETE_WORK_EXPERIENCE_SUCCESS:
      return {
        ...state,
        fetchedContent: state.fetchedContent.filter(item => item.id !== action.experienceId)
      };

    case actions.RESET_WORK_EXPERIENCE_SUCCESS:
      return {
        ...state,
        content: state.fetchedContent
      };

    default:
      return state;
  }
}
