import { PaginationDto } from 'middleware/dto';

export class JobTitleDto extends PaginationDto {

  unitIds: string[];
  branchIds: string[];

  constructor (data?: JobTitleDto) {
    super(data);
    if (data) {
      this.unitIds = data.unitIds;
      this.branchIds = data.branchIds;
    }
  }

}
