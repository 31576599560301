import * as actions from './skills.constants';

import { ListDto, PaginationDto } from 'middleware/dto';
import { SearchSkillDto } from 'rest/skill/search-skill.dto';

import { getSkills, searchSkills as search } from 'rest/skill/skill.rest';
import { List } from 'rest/list/list.dto';
import { SkillDto } from 'rest/skills/skills.dto';

const getSkillsRequest = () => ({
  type: actions.GET_SKILLS_REQUEST
});

const getSkillsSuccess = (params: PaginationDto, skills: ListDto[]) => ({
  type: actions.GET_SKILLS_SUCCESS,
  skills,
  params
});

const getSkillsFailure = (error: string) => ({
  type: actions.GET_SKILLS_FAILURE,
  error
});

const searchSkillsRequest = (query: string) => ({
  type: actions.SEARCH_SKILLS_REQUEST,
  query
});

const searchSkillsSuccess = (data: List<SkillDto>) => ({
  type: actions.SEARCH_SKILLS_SUCCESS,
  payload: data
});

const searchSkillsFailure = (error: string) => ({
  type: actions.SEARCH_SKILLS_FAILURE,
  error
});

export const resetSearch = () => ({
  type: actions.SEARCH_SKILLS_RESET
});

export const resetSearchResult = () => {
  return dispatch => {
    dispatch(resetSearch());
  };
};

export const getAllSkills = (params: PaginationDto) => {
  return dispatch => {
    dispatch(getSkillsRequest());
    return getSkills(params)
      .then(response => dispatch(getSkillsSuccess(params, response.data.content)))
      .catch(error => dispatch(getSkillsFailure(error)));
  };
};

export const searchSkills = (params: SearchSkillDto) => {
  return dispatch => {
    dispatch(searchSkillsRequest(params.q));
    return search(params)
      .then(response => dispatch(searchSkillsSuccess(response.data)))
      .catch(error => dispatch(searchSkillsFailure(error)));
  };
};
