// @flow
import React from 'react';
import { Link } from 'react-router-dom';

import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Setting } from './container.setting';

@connectTranslation()
export class SidebarContainer extends Setting {

  render () {
    return (
      <aside className={`sidebar-container ${this.props.className}`}>
        <div className="mobile-sidebar-head">
          <div className="mobile-sidebar-container-title">
            <Trans i18nKey="portal.candidate.job-offers.list.filter">
              Filters
            </Trans>
          </div>
          <Link
            to={this.props.closePath}
            className="sidebar-caption inline">
            <div className="mobile-sidebar-caption-text">
              <Trans i18nKey="portal.common.back-to-app">
                Go to Application
              </Trans>
            </div>
            <div className="mobile-sidebar-container-close">&times;</div>
          </Link>
        </div>
        <nav>
          <ul className="sidebar-menu-list">
            {this.props.children}
          </ul>
        </nav>
      </aside>
    );
  }

}
