import { Component } from 'react';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import './placeholder.scss';
import './placeholder.responsive.scss';

type Props = {
  t: TransProps,
  isRecruiter: boolean
}

export class Settings extends Component<Props> {

  static defaultProps = {
    isRecruiter: true
  };

}

export const stateToProps = (state: GlobalState) => {
  const recruiter = state.portal.recruiter.account.current.currentUser;
  return {
    isRecruiter: !!recruiter.id
  };
};
