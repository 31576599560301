import { PaginationDto } from 'middleware/dto/pagination';

export class MatchedCandidatesParams extends PaginationDto {

  city: string;
  country: string;

  constructor (data?: MatchedCandidatesParams) {
    super(data);
    if (data) {
      this.city = data.city;
      this.country = data.country;
    }
  }

}
