// @flow
import * as actions from './favorites.constants';

import { CandidateDto } from 'rest/candidates/candidate.dto';

import type { FavoriteCandidatesType } from './favorites.type';

const initialState: FavoriteCandidatesType = {
  unitId: '',
  content: [],
  fetching: false,
  jobTitleId: '',
  currentPage: 0,
  hasMoreItems: true,
  totalElements: 0
};

export default function (state: FavoriteCandidatesType = initialState, action) {
  let content = [];
  switch (action.type) {
    case actions.GET_FAVORITES_CANDIDATE_REQUEST:
      return {
        ...state,
        fetching: true,
        hasMoreItems: false
      };

    case actions.GET_FAVORITES_CANDIDATE_SUCCESS:
      content = action.payload.content.map(invite => new CandidateDto(invite));
      return {
        ...state,
        fetching: false,
        content: action.payload.number ? [ ...state.content, ...content ] : content,
        currentPage: action.payload.number + 1,
        hasMoreItems: action.payload.content.length > 0,
        totalElements: action.payload.totalElements
      };

    case actions.ADD_FAVORITES_CANDIDATE_SUCCESS:
      return {
        ...state,
        fetching: false
      };

    case actions.DELETE_FAVORITES_CANDIDATE_SUCCESS:
      return {
        ...state,
        content: state.content.filter((item: CandidateDto) => item.id !== action.payload),
        fetching: false,
        totalElements: state.totalElements - 1
      };

    case actions.GET_FAVORITES_CANDIDATE_FAILURE:
    case actions.ADD_FAVORITES_CANDIDATE_FAILURE:
    case actions.DELETE_FAVORITES_CANDIDATE_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.RESET_FAVORITES_CANDIDATE_SUCCESS:
      return {
        ...state,
        content: [],
        fetching: false,
        currentPage: 0,
        hasMoreItems: true,
        totalElements: 0
      };

    case actions.CHANGE_FAVORITES_CANDIDATE_FILTER_SUCCESS:
      return {
        ...initialState,
        unitId: action.unitId,
        jobTitleId: action.jobTitleId
      };

    default:
      return state;
  }
}
