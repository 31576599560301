import React from 'react';

import { Settings } from './card.setting';

export class Card extends Settings {

  render () {
    return (
      <div className={this.props.className ? `${this.props.className} card` : 'card'}>
        {this.props.children}
      </div>
    );
  }

}
