// @flow
import React from 'react';

import cn from 'classnames';
import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';

import { ListDto } from 'middleware/dto/list';
import { connectStore } from 'middleware/connect';
import { uniqueArray } from 'middleware/unique-array';

import { TabHeader } from 'components/tabs';
import { Button } from 'components/buttons';
import { Spinner } from 'components/elements';
import { FormGroup } from 'components/controls';

import {
  AddDocumentsModal,
  AddLanguagesModal,
  AddSkillsModal,
  AddSoftwareModal,
  SuggestNewSkillModal
} from 'containers/portal/skills-modals';

import { Card } from 'components/layouts';

import { PaginationDto } from 'middleware/dto';
import { SkillsDto } from 'rest/skill/skills.dto';
import { SkillItemDto } from 'rest/skill/skill-item.dto';
import { SearchSkillDto } from 'rest/skill/search-skill.dto';

import type { Props } from './skills-requirements.setting';
import { dispatchToProps, Settings, stateToProps } from './skills-requirements.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class SkillsRequirementsTab extends Settings {

  componentDidMount () {
    const skillsPagination = new PaginationDto();
    skillsPagination.size = 5;
    skillsPagination.page = 0;
    this.fetchLanguages();
    this.props.getAllSoftware(skillsPagination);
    this.props.getAllDocuments(skillsPagination);
    this.props.getCompanyJobOfferSkills(this.props.jobOfferId || this.props.jobOffer.id);
    this.fetchSkills(this.props.jobTitleId || this.props.jobOffer.id);
  }

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps && nextProps.initialSkills &&
      (nextProps.initialSkills !== this.props.initialSkills || this.checkIfSkillsPresent(nextProps))) {
      this.setState({
        documents: this.getCombinedSkills(nextProps.documents, nextProps.initialSkills.documents),
        software: this.getCombinedSkills(nextProps.software, nextProps.initialSkills.softwareSkills),
        skills: this.getCombinedSkills(nextProps.skills, nextProps.initialSkills.professionalSkills),
        languages: this.getCombinedSkills(nextProps.languages.slice(0, 10), nextProps.initialSkills.languageSkills)
      }, () => this.getElementsCount());
    }
    if (nextProps.jobTitleId !== this.props.jobTitleId) {
      this.fetchSkills(nextProps.jobTitleId);
    }
  }

  checkIfSkillsPresent (nextProps: Props = this.props) {
    return (nextProps.skills && nextProps.skills.length !== this.props.skills.length);
  }

  openSuggestSkillModal (skillType: string) {
    this.setState({
      skillType,
      isSuggestNewSkillModalOpen: true
    });
  }

  fetchSkills (jobTitleId: string) {
    const query = new SearchSkillDto(this.state);
    query.exclude = false;
    query.size = 10;
    query.page = 0;
    query.unitIds = [ this.props.jobOffer.unitId ];
    query.jobTitleId = jobTitleId;
    this.props.getAllSkills(query);
  }

  fetchLanguages () {
    const languagePagination = new PaginationDto();
    languagePagination.page = 0;
    languagePagination.size = 200;
    this.props.getAllLanguages(languagePagination);
  }

  getCombinedSkills (skills: ListDto[], fetchedSkills: ListDto[]): SkillItemDto[] {
    const savedSkills = fetchedSkills.map(item => ({
      ...item,
      selected: true,
      id: item.skillId
    }));
    return uniqueArray([ ...savedSkills, ...skills ], 'id');
  }

  getElementsCount (callback: ? () => void = () => {
  }): number {
    const collections = [
      ...this.state.documents,
      ...this.state.languages,
      ...this.state.software,
      ...this.state.skills
    ];
    const activeElementsLength: number = collections.filter(item => item.selected).length;
    this.setState({ activeElements: activeElementsLength }, callback);
    return activeElementsLength;
  }

  toggleElement (list: ListDto[], element: ListDto): ListDto[] {
    return list.map(item => {
      if (item.id === element.id) {
        return {
          ...item,
          selected: !item.selected
        };
      }
      return item;
    });
  }

  validateMaxCompetenciesCount () {
    if (!this.isRichMaxCompetenciesLength()) {
      toast.error(this.props.t('portal.recruiter.balance-board.job-offer.skills.max-competencies-error'));
    }
  }

  addNewElements (type: string, list: ListDto[]) {
    const selectedList = list.map(item => ({ ...item, selected: true }));
    const items = [ ...this.state[type], ...selectedList ];
    this.setState({ [type]: uniqueArray(items, 'id') }, () => {
      this.getElementsCount(() => this.validateMaxCompetenciesCount());
    });
  }

  changeSelectElementState (type: string, element: ListDto) {
    const items = [ ...this.state[type] ];
    this.setState({
      elementsCountError: false,
      [type]: this.toggleElement(items, element)
    }, () => this.getElementsCount());
  }

  toggleModal (field: string) {
    this.setState({ [field]: !this.state[field] });
  }

  isRichMaxCompetenciesLength (): boolean {
    return this.state.activeElements <= this.maxCompetenciesLength - 1;
  }

  validate (cb: (valid: boolean) => void) {
    const selectedSkillsCount = this.state.skills.filter(item => item.selected).length;
    const maxCompetenciesLength = this.isRichMaxCompetenciesLength();
    this.setState({ elementsCountError: selectedSkillsCount === 0 || this.state.activeElements < 2 }, () => {
      const valid = maxCompetenciesLength + 1 && !this.state.elementsCountError;
      cb(valid);
    });
  }

  filterSelectedSkills (skills: ListDto[]): SkillItemDto[] {
    return skills.map(item => {
      if (item.selected) {
        const skillItem = new SkillItemDto(item);
        skillItem.points = item.points || 0;
        skillItem.skillLevel = item.skillLevel || 'BASIC';
        skillItem.required = item.required;
        skillItem.name = item.name;
        skillItem.skillId = item.id;
        return skillItem;
      }
    }).filter(item => !!item);
  }

  updateSkills () {
    const skillsData = new SkillsDto();
    skillsData.documents = this.filterSelectedSkills(this.state.documents);
    skillsData.softwareSkills = this.filterSelectedSkills(this.state.software);
    skillsData.languageSkills = this.filterSelectedSkills(this.state.languages);
    skillsData.professionalSkills = this.filterSelectedSkills(this.state.skills);
    skillsData.matchPoints =
      this.props.initialSkills && this.props.initialSkills.matchPoints ? this.props.initialSkills.matchPoints : 75;
    this.props.updateSkills(this.props.currentJobOfferId || this.props.jobOfferId, skillsData);
  }

  saveJobOfferSkills (event: Event) {
    event.preventDefault();
    this.validate(valid => {
      if (valid) {
        this.updateSkills();
        this.props.next();
      }
    });
  }

  render () {
    const skillsCountClasses = cn([ 'competencies-sticky-box',
      {
        'skills-count-error': this.state.activeElements > this.maxCompetenciesLength,
        'skills-count-success': this.state.activeElements === this.maxCompetenciesLength
      }
    ]);
    return (
      <div className="competencies-container">
        <TabHeader
          title={this.props.t('portal.recruiter.balance-board.job-offer.skills.title')}
          subTitle={this.props.t('portal.recruiter.balance-board.job-offer.skills.description')}/>
        <Card className={skillsCountClasses}>
          <p>
            <Trans i18nKey="portal.recruiter.balance-board.job-offer.skills.competencies">
              Selected Competencies:
            </Trans>
            &nbsp;
            <span className="skills-count">
              {this.state.activeElements} / {this.maxCompetenciesLength}
            </span>
          </p>
        </Card>
        <form
          onSubmit={this.saveJobOfferSkills.bind(this)}
          className="skills-requirements-form card-toolbar">
          <h3 className="section-title">
            <Trans i18nKey="portal.recruiter.balance-board.job-offer.skills.sections.0">
              Skills
            </Trans>
          </h3>
          <FormGroup className="half-width skills-list">
            {
              this.state.skills.map((item, index) => (
                <Button
                  key={index}
                  onClick={() => this.changeSelectElementState('skills', item)}
                  className={`default element ${!item.selected ? 'outlined' : ''}`}
                  type="button">
                  {item.name}
                </Button>
              ))
            }
          </FormGroup>
          <FormGroup className="half-width">
            <Button
              recruiter
              outlined
              onClick={() => this.toggleModal('isAddSkillsModalOpen')}
              className="add-more"
              type="button">
              <img src="/images/shared/add-icon-blue.svg" alt=""/>
              <Trans i18nKey="portal.recruiter.balance-board.job-offer.skills.add-other.0">
                Add Other Skill
              </Trans>
            </Button>
            <Button
              onClick={() => this.openSuggestSkillModal('PROFESSIONAL_SKILL')}
              recruiter
              outlined>
              <Trans i18nKey="portal.recruiter.balance-board.job-offer.skills.suggest.0">
                Suggest New Skill
              </Trans>
            </Button>
          </FormGroup>
          <h3 className="section-title">
            <Trans i18nKey="portal.recruiter.balance-board.job-offer.skills.sections.1">
              Documents
            </Trans>
          </h3>
          <FormGroup className="half-width documents-list">
            {
              this.state.documents.map((item, index) => (
                <Button
                  key={index}
                  onClick={() => this.changeSelectElementState('documents', item)}
                  className={`default element ${!item.selected ? 'outlined' : ''}`}
                  type="button">
                  {item.name}
                </Button>
              ))
            }
          </FormGroup>
          <FormGroup className="half-width">
            <Button
              onClick={() => this.toggleModal('isAddDocumentsModalOpen')}
              className="accent outlined add-more"
              type="button">
              <img src="/images/shared/add-icon-blue.svg" alt=""/>
              <Trans i18nKey="portal.recruiter.balance-board.job-offer.skills.add-other.1">
                Add Other Documents
              </Trans>
            </Button>
            <Button
              onClick={() => this.openSuggestSkillModal('DOCUMENTS')}
              recruiter
              outlined>
              <Trans i18nKey="portal.recruiter.balance-board.job-offer.skills.suggest.1">
                Suggest New Document
              </Trans>
            </Button>
          </FormGroup>
          <h3 className="section-title">
            <Trans i18nKey="portal.recruiter.balance-board.job-offer.skills.sections.2">
              Languages
            </Trans>
          </h3>
          <FormGroup className="half-width languages-list">
            {
              this.state.languages.map((item, index) => (
                <Button
                  key={index}
                  onClick={() => this.changeSelectElementState('languages', item)}
                  className={`default element ${!item.selected ? 'outlined' : ''}`}
                  type="button">
                  {item.name}
                </Button>
              ))
            }
          </FormGroup>
          <FormGroup className="half-width">
            <Button
              onClick={() => this.toggleModal('isAddLanguagesModalOpen')}
              className="accent outlined add-more"
              type="button">
              <img src="/images/shared/add-icon-blue.svg" alt=""/>
              <Trans i18nKey="portal.recruiter.balance-board.job-offer.skills.add-other.2">
                Add Other Language
              </Trans>
            </Button>
            <Button
              onClick={() => this.openSuggestSkillModal('LANGUAGE')}
              recruiter
              outlined>
              Suggest New Language
            </Button>
          </FormGroup>
          <h3 className="section-title">
            <Trans i18nKey="portal.recruiter.balance-board.job-offer.skills.sections.3">
              Software
            </Trans>
          </h3>
          <FormGroup className="half-width software-list">
            {
              this.state.software.map((item, index) => (
                <Button
                  key={index}
                  onClick={() => this.changeSelectElementState('software', item)}
                  className={`default element ${!item.selected ? 'outlined' : ''}`}
                  type="button">
                  {item.name}
                </Button>
              ))
            }
          </FormGroup>
          <FormGroup className="half-width">
            <Button
              onClick={() => this.toggleModal('isAddSoftwareModalOpen')}
              className="accent outlined add-more"
              type="button">
              <img src="/images/shared/add-icon-blue.svg" alt=""/>
              <Trans i18nKey="portal.recruiter.balance-board.job-offer.skills.add-other.3">
                Add Other Software
              </Trans>
            </Button>
            <Button
              onClick={() => this.openSuggestSkillModal('SOFTWARE')}
              recruiter
              outlined>
              <Trans i18nKey="portal.recruiter.balance-board.job-offer.skills.suggest.2">
                Suggest New Software
              </Trans>
            </Button>
          </FormGroup>
          <FormGroup className="full-width form-buttons">
            {
              this.state.elementsCountError &&
              <p className="error-text">
                <Trans i18nKey="portal.recruiter.balance-board.job-offer.skills.error">
                  You should select at least 1 professional skill and at least 1 other skill
                </Trans>
              </p>
            }
            <Button
              grey
              type="button"
              onClick={() => this.props.prev()}>
              <Trans i18nKey="portal.recruiter.balance-board.job-offer.buttons.back">
                Back
              </Trans>
            </Button>
            {
              this.props.new && <Button
                grey
                type="button"
                onClick={this.updateSkills}>
                <Trans i18nKey="portal.recruiter.balance-board.job-offer.buttons.draft">
                  Save Drafts
                </Trans>
              </Button>
            }
            <Button
              disabled={this.state.activeElements > this.maxCompetenciesLength}
              recruiter
              medium
              type="submit">
              {
                this.props.new ? 'Save and Next' : 'Next as Save'
              }
            </Button>
          </FormGroup>
        </form>
        <Spinner show={this.props.fetching}/>
        <AddSkillsModal
          recruiter
          addNewItems={list => this.addNewElements('skills', list)}
          close={() => this.toggleModal('isAddSkillsModalOpen')}
          open={this.state.isAddSkillsModalOpen}/>
        <AddLanguagesModal
          recruiter
          addNewItems={list => this.addNewElements('languages', list)}
          close={() => this.toggleModal('isAddLanguagesModalOpen')}
          open={this.state.isAddLanguagesModalOpen}/>
        <AddSoftwareModal
          recruiter
          addNewItems={list => this.addNewElements('software', list)}
          close={() => this.toggleModal('isAddSoftwareModalOpen')}
          open={this.state.isAddSoftwareModalOpen}/>
        <AddDocumentsModal
          recruiter
          addNewItems={list => this.addNewElements('documents', list)}
          close={() => this.toggleModal('isAddDocumentsModalOpen')}
          open={this.state.isAddDocumentsModalOpen}/>
        <SuggestNewSkillModal
          recruiter
          skillType={this.state.skillType}
          close={() => this.setState({ isSuggestNewSkillModalOpen: false })}
          open={this.state.isSuggestNewSkillModalOpen}/>
      </div>
    );
  }

}
