export class CalendarRequestDto {

  variants: string[];
  jobOfferId: number;
  candidateId: number;

  constructor (data?: CalendarRequestDto) {
    if (data) {
      this.variants = data.variants;
      this.jobOfferId = data.jobOfferId;
      this.candidateId = data.candidateId;
    }
  }

}
