import { Component } from 'react';

import I18n from 'react-i18next';

import moment from 'moment';

import type { GlobalState } from 'common/types/global-state';

import { EventDataDto } from 'rest/alerts/alerts.event-data.dto';

type Props = {
  i18n?: I18n,
  hideModal: () => void,
  eventData: EventDataDto[],
  publishDate: string
}

type State = {
  localization: string
}

export class Setting extends Component<Props, State> {

  static defaultProps: Props = {
    eventData: [],
    hideModal: () => {},
    publishDate: moment().parseZone().fromNow()
  };

  state: State = {
    localization: this.props.i18n.language
  };

}

export const stateToProps = (state: GlobalState) => ({
  candidate: state.portal.candidate.account.current.candidateData
});
