// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { CurrentRecruiterDto } from 'rest/recruiter/current/current.dto';

import { updateCurrentRecruiter } from 'services/portal/recruiter/account/account.actions';

import './notifications.scss';

export type Props = {
  t?: TransProps,
  fetching: boolean,
  currentUser: CurrentRecruiterDto,
  updateCurrentRecruiter: (recruiterId: string, data: CurrentRecruiterDto) => void
}

type State = {
  matchJobOffer: boolean,
  newMessageChat: boolean,
  updateFollowing: boolean
}

export class Setting extends Component<Props, State> {

  state: State = {
    matchJobOffer: false,
    newMessageChat: false,
    updateFollowing: false
  }

}

export const stateToProps = (state: GlobalState) => ({
  fetching: state.portal.recruiter.account.current.fetching,
  currentUser: state.portal.recruiter.account.current.currentUser
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  updateCurrentRecruiter
}, dispatch);
