import React, { Component } from 'react';

import { Modal } from 'components/dialogs';
import { Card } from 'components/layouts';

import { CreateJobOfferModal } from 'containers/portal/recruiter/job-offer';

export class JobOfferModalPage extends Component {

  render () {
    return (
      <Modal closePath="/portal/recruiter/job-offers">
        <Card>
          <CreateJobOfferModal/>
        </Card>
      </Modal>
    );
  }

}
