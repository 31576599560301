// @flow
import React from 'react';

import { connectStore } from 'middleware/connect';

import { StatisticsContainer, StatisticsItem } from 'components/widgets/statistics';

import { JobOffersParams } from 'rest/public/job-offers/job-offers.params';

import { Settings, stateToProps, dispatchToProps } from './jobs.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class JobOffersSidebar extends Settings {

  componentDidMount () {
    this.props.getJobOffersStatistic();
  }

  componentWillUpdate () {
    if (!this.props.currentUser) {
      this.props.getJobOffersStatistic();
    }
  }

  componentWillUnmount () {
    this.props.resetJobOffersStats();
    this.props.saveSearchJobsBranch();
  }

  changeBranch (branchId?: string = '') {
    const params: JobOffersParams = new JobOffersParams(this.props.searchJobOffers);
    params.branchId = branchId;
    this.props.saveSearchJobsBranch(branchId);
    this.props.initJobsFilter(params);
  }

  render () {
    return (
      <StatisticsContainer title={this.props.t('portal.candidate.job-offers.sidebar.title')}>
        <StatisticsItem
          title={this.props.t('portal.candidate.job-offers.sidebar.all')}
          active={!this.props.searchJobOffers.branchId}
          onClick={() => this.changeBranch()}
          candidate/>
        {
          this.props.stats.map((stat, index) => (
            <StatisticsItem
              key={index}
              title={`${stat.name.toLowerCase()}`}
              value={stat.count}
              active={this.props.searchJobOffers.branchId === stat.id}
              onClick={() => this.changeBranch(stat.id)}
              candidate/>
          ))
        }
      </StatisticsContainer>
    );
  }

}
