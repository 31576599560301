import React from 'react';
import { NavLink } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Card } from 'components/layouts';
import { LinkButton } from 'components/buttons';

import { Settings } from './menu.setting';

@connectTranslation()
export class RecruiterMenu extends Settings {

  render () {
    return (
      <Card>
        <div className="profile-nav-container">
          <div className="profile-nav-header">
            <div className="person-container">
              <div className="avatar-wrapper">
                <img
                  src="/images/shared/userpick.jpg"
                  alt="avatar"/>
              </div>
              <div className="name-wrapper">
                <h3 className="user-name">
                  Excellent Jobs
                </h3>
                <span className="user-role">
                  <Trans i18nKey="portal.guest.recruiter.company">
                    Recruiter - Guest
                  </Trans>
                </span>
              </div>
            </div>
            <div className="register-container">
              <LinkButton
                link="/auth/recruiter/sign-up"
                recruiter>
                Register
              </LinkButton>
            </div>
          </div>
          <div className="profile-nav-body">
            <div className="profile-nav-links recruiter">
              <NavLink
                to="/portal/guest/candidates"
                className="profile-nav-link"
                activeClassName="active">
                <div className="tile">
                  <span className="icon-search"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.guest.recruiter.menu.candidates">
                    Candidate List
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                to="/auth/recruiter/sign-up"
                className="profile-nav-link"
                activeClassName="active">
                <div className="tile">
                  <span className="icon-balance-board"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.guest.recruiter.menu.balance-board">
                    Balance Board
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                to="/auth/recruiter/sign-up"
                className="profile-nav-link"
                activeClassName="active">
                <div className="tile">
                  <span className="icon-applications"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.guest.recruiter.menu.job-offers">
                    Job Offers
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                to="/auth/recruiter/sign-up"
                className="profile-nav-link"
                activeClassName="active">
                <div className="tile">
                  <span className="icon-match"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.guest.recruiter.menu.match">
                    Match
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                to="/auth/recruiter/sign-up"
                className="profile-nav-link"
                activeClassName="active">
                <div className="tile">
                  <span className="icon-chat"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.guest.recruiter.menu.chat">
                    Chat
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                to="/auth/recruiter/sign-up"
                className="profile-nav-link"
                activeClassName="active">
                <div className="tile">
                  <span className="icon-calendar"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.guest.recruiter.menu.calendar">
                    Calendar
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                to="/auth/recruiter/sign-up"
                className="profile-nav-link"
                activeClassName="active">
                <div className="tile">
                  <span className="icon-favorites"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.guest.recruiter.menu.favorites">
                    Favorites
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                to="/auth/recruiter/sign-up"
                className="profile-nav-link"
                activeClassName="active">
                <div className="tile">
                  <span className="icon-follow"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.guest.recruiter.menu.follow">
                    Follow
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                to="/auth/recruiter/sign-up"
                className="profile-nav-link"
                activeClassName="active">
                <div className="tile">
                  <span className="icon-statistic"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.guest.recruiter.menu.statistics">
                    Statistics
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                to="/auth/recruiter/sign-up"
                className="profile-nav-link"
                activeClassName="active">
                <div className="tile">
                  <span className="icon-ask-me"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.guest.recruiter.menu.ask-me">
                    Ask Me
                  </Trans>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </Card>
    );
  }

}
