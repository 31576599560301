import { Component } from 'react';

import './table.scss';

type Props = {
  translate: string,
  row: number,
  cell: number,
  boldTitle?: boolean;
}

export class Setting extends Component<Props> {}
