import { Action, Dispatch } from 'redux';

import { AxiosResponse } from 'axios';

import { PaginationDto } from 'middleware/dto/pagination';

import { List } from 'rest/list/list.dto';

import { CalendarResponseDto } from 'rest/companies/candidate-invites/candidate-invites-response.dto';

import { getCalendarStatistic } from 'services/portal/recruiter/calendar/stats/stats.actions';

import {
  getCalendarInvites,
  changeCalendarInvite,
  deleteCalendarInvite
} from 'rest/companies/candidate-invites/candidate-invites.rest';

import * as actions from './invites.constants';

const getAllCalendarInvitesRequest = (): Action => ({
  type: actions.GET_ALL_CALENDAR_INVITES_REQUEST
});

const getAllCalendarInvitesSuccess = (data): Action => ({
  type: actions.GET_ALL_CALENDAR_INVITES_SUCCESS,
  payload: data
});

const getAllCalendarInvitesFailure = (): Action => ({
  type: actions.GET_ALL_CALENDAR_INVITES_FAILURE
});

const deleteCalendarInviteRequest = (): Action => ({
  type: actions.DELETE_CALENDAR_INVITE_REQUEST
});

const deleteCalendarInviteSuccess = (data): Action => ({
  type: actions.DELETE_CALENDAR_INVITE_SUCCESS,
  payload: data
});

const deleteCalendarInviteFailure = (): Action => ({
  type: actions.DELETE_CALENDAR_INVITE_FAILURE
});

const resetCalendarInvitesSuccess = (): Action => ({
  type: actions.RESET_ALL_CALENDAR_INVITES_SUCCESS
});

const changeCalendarInviteRequest = (): Action => ({
  type: actions.CHANGE_CALENDAR_INVITE_REQUEST
});

const changeCalendarInviteSuccess = (data): Action => ({
  type: actions.CHANGE_CALENDAR_INVITE_SUCCESS,
  payload: data
});

const changeCalendarInviteFailure = (): Action => ({
  type: actions.CHANGE_CALENDAR_INVITE_FAILURE
});

const changeInvitesStatusSuccess = (status: string) => ({
  type: actions.CHANGE_CALENDAR_INVITES_STATUS_SUCCESS,
  payload: status
});

export const changeInvitesStatus = (status?: string = '') => {
  return dispatch => {
    dispatch(changeInvitesStatusSuccess(status));
  };
};

export function resetAllCalendarInvites () {
  return (dispatch: Dispatch) => {
    dispatch(resetCalendarInvitesSuccess());
  };
}

export function getAllCalendarInvites (companyId: string, status: string, params: PaginationDto) {
  return (dispatch: Dispatch) => {
    dispatch(getAllCalendarInvitesRequest());
    return getCalendarInvites(companyId, status, params)
      .then((response: AxiosResponse<List<CalendarResponseDto>>) =>
        dispatch(getAllCalendarInvitesSuccess(response.data)))
      .catch(() => dispatch(getAllCalendarInvitesFailure()));
  };
}

export function removeCalendarInvite (companyId: string, inviteId: string) {
  return (dispatch: Dispatch) => {
    dispatch(deleteCalendarInviteRequest());
    return deleteCalendarInvite(companyId, inviteId)
      .then((response: AxiosResponse<List<CalendarResponseDto>>) => {
        dispatch(deleteCalendarInviteSuccess(response.data));
        dispatch(getCalendarStatistic(companyId));
      })
      .catch(() => dispatch(deleteCalendarInviteFailure()));
  };
}

export function changeInviteStatus (companyId: string, inviteId: string, status: string) {
  return (dispatch: Dispatch) => {
    dispatch(changeCalendarInviteRequest());
    return changeCalendarInvite(companyId, inviteId, status)
      .then((response: AxiosResponse<List<CalendarResponseDto>>) => {
        dispatch(changeCalendarInviteSuccess(response.data));
        dispatch(getCalendarStatistic(companyId));
      })
      .catch(() => dispatch(changeCalendarInviteFailure()));
  };
}
