// @flow
import React from 'react';

import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';
import { setData } from 'middleware/job-offer-storage';

import { Card } from 'components/layouts';
import { LinkButton } from 'components/buttons';

import { Setting } from './banners.setting';

@connectTranslation()
export class Banners extends Setting {

  resetStorage = () => setData('ej_company_info', {});

  render () {
    return (
      <div className="banners-container">
        <Card className="recruiters-container">
          <div className="title">
            <Trans i18nKey="landing.banners.recruiters.title">
              For Recruiters Test.
            </Trans>
          </div>
          <div className="items">
            <div className="item">
              <p className="item-content">
                <Trans i18nKey="landing.banners.recruiters.points.0">
                  Use our incredeble matching system with our innovative skill–analyser.
                </Trans>
              </p>
            </div>
            <div className="item">
              <p className="item-content">
                <Trans i18nKey="landing.banners.recruiters.points.1">
                  Just pay if there is a match.
                </Trans>
              </p>
            </div>
            <div className="item">
              <p className="item-content">
                <Trans i18nKey="landing.banners.recruiters.points.2">
                  If you are using our premium account chat with your candidates, follow them and see directly their CV.
                </Trans>
              </p>
            </div>
          </div>
          <div className="banner-information">
            <div className="control-container">
              <LinkButton
                link="/portal/new-job-offer"
                onClick={this.resetStorage}
                recruiter>
                <Trans i18nKey="landing.banners.recruiters.button">
                  Start Here
                </Trans>
              </LinkButton>
            </div>
          </div>
        </Card>
        <Card className="candidates-container">
          <div className="title">
            <Trans i18nKey="landing.banners.candidates.title">
              For Candidates.
            </Trans>
          </div>
          <div className="items">
            <div className="item">
              <p className="item-content">
                <Trans i18nKey="landing.banners.candidates.points.0">
                  Find as quick as nobody else your dream job.
                </Trans>
              </p>
            </div>
            <div className="item">
              <p className="item-content">
                <Trans i18nKey="landing.banners.candidates.points.1">
                  Handle all your documents in our system and create the best application.
                </Trans>
              </p>
            </div>
            <div className="item">
              <p className="item-content">
                <Trans i18nKey="landing.banners.candidates.points.2">
                  If you found your dream job just send your application with one click.
                </Trans>
              </p>
            </div>
            <div className="item">
              <p className="item-content">
                <Trans i18nKey="landing.banners.candidates.points.3">
                  Chat with and follow companies or have a look which company likes you.
                </Trans>
              </p>
            </div>
          </div>
          <div className="banner-information">
            <div className="control-container">
              <LinkButton
                link="/auth/candidate/sign-up"
                candidate>
                <Trans i18nKey="landing.banners.candidates.button">
                  Start Here
                </Trans>
              </LinkButton>
            </div>
          </div>
        </Card>
      </div>
    );
  }

}
