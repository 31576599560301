import axios, { AxiosPromise } from 'axios';

import { PremiumCostDto } from './premium-cost.dto';

export async function getCompanyPremiumCost (companyId: string): AxiosPromise<PremiumCostDto> {
  try {
    return await axios.get(`/companies/${companyId}/premium-cost`);
  } catch (error) {
    throw error;
  }
}
