// @flow
import type { Action } from 'redux';

import * as actions from './address.constants';

import type { AddressType } from './address.type';

import { AddressResponseDto } from 'rest/address/address.response.dto';

const initialState: AddressType = {
  content: {},
  fetching: false
};

export default function (state: AddressType = initialState, action: Action) {
  switch (action.type) {
    case actions.GET_SUGGEST_ADDRESS_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case actions.GET_SUGGEST_ADDRESS_SUCCESS: {
      const content = { ...state.content };
      content[action.field] = action.address.map(item => new AddressResponseDto(item));
      return {
        ...state,
        content,
        fetching: false
      };
    }

    case actions.GET_SUGGEST_ADDRESS_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.RESET_SUGGEST_ADDRESS_SUCCESS:
      return {
        ...state,
        image: null,
        content: null,
        fetching: false
      };

    default:
      return state;
  }
}
