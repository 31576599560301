// @flow
import React from 'react';

import { connectStore } from 'middleware/connect';

import { JobOffersParams } from 'rest/public/job-offers/job-offers.params';

import { StatisticsContainer, StatisticsItem } from 'components/widgets/statistics';

import { Settings, stateToProps, dispatchToProps } from './favorites.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class FavoritesSidebar extends Settings {

  componentDidMount () {
    this.loadFavoritesStatistic(this.props.currentUser.id);
  }

  componentWillUpdate (props) {
    if (!this.props.currentUser.id) {
      this.loadFavoritesStatistic(props.currentUser.id);
    }
  }

  componentWillUnmount () {
    this.props.resetFavoriteStatistic();
  }

  loadFavoritesStatistic (candidateId: string) {
    if (candidateId) {
      this.props.getFavoriteStatistic(candidateId);
    }
  }

  changeFavoriteFilter (companyId: string) {
    const params: JobOffersParams = new JobOffersParams();
    params.companyId = companyId;
    this.props.changeFavoriteFilter(params);
  }

  render () {
    return (
      <StatisticsContainer title={this.props.t('portal.candidate.favorites.sidebar.title')}>
        <StatisticsItem
          title={this.props.t('portal.candidate.favorites.sidebar.all')}
          value={this.props.total}
          active={!this.props.companyId}
          onClick={() => this.props.changeFavoriteFilter()}
          candidate/>
        {
          this.props.stats.map((stat, index) => (
            <StatisticsItem
              key={index}
              title={`${stat.name.toLowerCase()}`}
              value={stat.count}
              active={this.props.companyId === stat.id}
              onClick={() => this.changeFavoriteFilter(stat.id)}
              candidate/>
          ))
        }
      </StatisticsContainer>
    );
  }

}
