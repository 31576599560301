import * as actions from './payment.constant';

import type { PaymentState } from './payment.type';

const initialState: PaymentState = {
  errorText: '',
  fetching: false,
  redirectUrl: ''
};

export default (state = initialState, action): PaymentState => {
  switch (action.type) {
    case actions.SUBSCRIBE_COMPANY_FAILURE:
      return {
        ...state,
        fetching: false,
        errorText: action.error
      };

    case actions.SUBSCRIBE_COMPANY_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case actions.SUBSCRIBE_COMPANY_SUCCESS:
      return {
        ...state,
        fetching: false,
        redirectUrl: action.link.paymentRedirectDetails
      };

    default:
      return state;
  }
};
