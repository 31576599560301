// @flow
import { Component } from 'react';

import './button.scss';
import './button.responsive.scss';

type Props = {
  type?: string,
  grey?: boolean,
  medium?: boolean,
  children?: Node,
  fullWidth?: boolean,
  className?: string,
  candidate?: boolean,
  disabled?: boolean,
  outlined?: boolean,
  recruiter?: boolean,
  onClick?: () => void
};

export class Setting extends Component<Props> {

  static defaultProps: Props = {
    grey: false,
    type: 'button',
    disabled: false,
    medium: false,
    candidate: false,
    fullWidth: false,
    onClick: () => {},
    outlined: false,
    recruiter: false,
    className: ''
  };

}
