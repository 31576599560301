// @flow
import React from 'react';

import { connectStore } from 'middleware/connect';

import { CounterWidget } from 'components/widgets';

import { Settings, stateToProps } from './cv.setting';

@connectStore(stateToProps, null, true)
export class CVCircle extends Settings {

  getFilling (): number {
    return this.props.totalCvData.reduce((prev, curr) => prev + curr, 0);
  }

  render () {
    return (
      <CounterWidget
        title={this.props.t('portal.candidate.cv.circle.title')}
        value={`${this.getFilling() * 20} %`}
        circleTitle={this.props.t('portal.candidate.cv.circle.value')}/>
    );
  }

}
