import React, { Component } from 'react';

import { Route } from 'middleware/route';

import { WelcomeAuthPage } from './welcome';
import { CandidateAuthPage } from './candidate';
import { RecruiterAuthPage } from './recruiter';
import { QuestionsButtons } from '../../containers/landing/questions-button/questions-button';

export class AuthPage extends Component {

  render () {
    return (
      <div className="auth-container default">
        <QuestionsButtons/>
        <Route
          path="/auth"
          type="guest"
          exact
          component={WelcomeAuthPage}/>
        <Route
          path="/auth/candidate"
          type="guest"
          component={CandidateAuthPage}/>
        <Route
          path="/auth/recruiter"
          type="guest"
          component={RecruiterAuthPage}/>
      </div>
    );
  }

}
