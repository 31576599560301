// @flow
import React from 'react';

import cn from 'classnames';

import { Setting } from './description.setting';

export class Description extends Setting {

  render () {
    const { info, children } = this.props;
    const tooltipClasses = cn([ 'description-container', {
      'info': info
    } ]);
    return (
      <div className={tooltipClasses}>
        <div className="description-icon">
          <img
            src="/images/shared/tooltip-icon-green.svg"
            alt="icon"/>
        </div>
        <div className="description-text">
          {children}
        </div>
      </div>
    );
  }

}
