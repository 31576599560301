import { Component } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { CompanyDto } from 'rest/companies/companies.dto';
import { SkillsContainerDto } from 'rest/skills/skills-container.dto';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

import {
  updateJobOfferStatus,
  deleteCompanyJobOffer,
  getCompanyJobOfferById,
  getCompanyJobOfferSkills
} from 'services/portal/recruiter/job-offer/job-offer.actions';

import './edit-job-offer-preview.scss';

export type Props = {
  t: TransProps,
  open: boolean,
  jobOffer: JobOffersResponseDto,
  jobOfferSkills: SkillsContainerDto,
};

type State = {
  showDeleteModal: boolean
}

export class Settings extends Component<Props, State> {

  static defaultProps = {
    open: false,
    company: new CompanyDto(),
    jobOffer: new JobOffersResponseDto(),
    jobOfferSkills: new SkillsContainerDto()
  };

  state: State = {
    showDeleteModal: false
  };

}

export const stateToProps = (state: GlobalState) => ({
  company: state.portal.recruiter.account.companies.company,
  jobOfferSkills: state.portal.recruiter.jobOffer.skills.content,
  jobOffer: state.portal.recruiter.jobOffer.general.currentJobOffer,
  companyId: state.portal.recruiter.account.current.currentUser.companyId
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  updateJobOfferStatus,
  deleteCompanyJobOffer,
  getCompanyJobOfferById,
  getCompanyJobOfferSkills
}, dispatch);
