import { Action, Dispatch } from 'redux';

import { AxiosResponse } from 'axios';

import { PaginationDto } from 'middleware/dto/pagination';

import { List } from 'rest/list/list.dto';

import { getMatchStatistic } from 'services/portal/recruiter/match/stats/stats.actions';

import { MatchedCandidatesDto } from 'rest/companies/job-offers-matches/matched-candidates/matched-candidates.dto';

import {
  getMatchedCandidates,
  deleteMatchedCandidate, getMatchedCandidatesLocations
} from 'rest/companies/job-offers-matches/matched-candidates/matched-candidates.rest';

import {
  MatchedCandidatesLocationsDto
} from 'rest/companies/job-offers-matches/matched-candidates/matched-candidates-locations.dto';

import { LocationsDto } from 'rest/companies/job-offers-matches/matched-candidates/locations/locations.dto';

import * as actions from './candidates.constants';

const getAllMatchedCandidatesRequest = (): Action => ({
  type: actions.GET_ALL_MATCHED_CANDIDATES_REQUEST
});

const getAllMatchedCandidatesSuccess = (data): Action => ({
  type: actions.GET_ALL_MATCHED_CANDIDATES_SUCCESS,
  payload: data
});

const getAllMatchedCandidatesFailure = (): Action => ({
  type: actions.GET_ALL_MATCHED_CANDIDATES_FAILURE
});

const getAllMatchedCandidatesReset = (): Action => ({
  type: actions.GET_ALL_MATCHED_CANDIDATES_RESET
});

const removeMatchedCandidatesFromList = (id: string): Action => ({
  id: id,
  type: actions.DELETE_MATCHED_CANDIDATES_SUCCESS
});

const getMatchedCandidatesLocationsSuccess = (data): Action => ({
  type: actions.GET_MATCHED_CANDIDATES_LOCATIONS_SUCCESS,
  locations: data
});

const changeMatchedCandidatesLocationSuccess = (location: LocationsDto): Action => ({
  type: actions.CHANGE_MATCHED_CANDIDATES_LOCATIONS_SUCCESS,
  location: location
});

export function resetMatchedCandidates () {
  return (dispatch: Dispatch) => {
    dispatch(getAllMatchedCandidatesReset());
  };
}

export function changeMatchedCandidatesLocation (location: LocationsDto) {
  return (dispatch: Dispatch) => {
    dispatch(changeMatchedCandidatesLocationSuccess(location));
  };
}

export function getMatchedCandidatesList (companyId: string, jobOfferId: string, params: PaginationDto) {
  return (dispatch: Dispatch) => {
    dispatch(getAllMatchedCandidatesRequest());
    return getMatchedCandidates(companyId, jobOfferId, params)
      .then((response: AxiosResponse<List<MatchedCandidatesDto>>) =>
        dispatch(getAllMatchedCandidatesSuccess(response.data)))
      .catch(() => dispatch(getAllMatchedCandidatesFailure()));
  };
}

export function getMatchedCandidatesLocationsList (companyId: string, jobOfferId: string) {
  return (dispatch: Dispatch) => {
    return getMatchedCandidatesLocations(companyId, jobOfferId)
      .then((response: AxiosResponse<MatchedCandidatesLocationsDto>) =>
        dispatch(getMatchedCandidatesLocationsSuccess(response.data.locations)));
  };
}

export function removeMatchedCandidate (companyId: string, jobOfferId: string, candidateId: string) {
  return (dispatch: Dispatch) => {
    return deleteMatchedCandidate(companyId, jobOfferId, candidateId)
      .then(() => {
        dispatch(removeMatchedCandidatesFromList(candidateId));
        dispatch(getMatchStatistic(companyId));
      });
  };
}
