import * as actions from './stats.constants';

import { StatsDto } from 'rest/stats/stats.dto';
import { getCandidatesStats } from 'rest/public/candidates/candidates.rest';

const getJobOffersStatisticRequest = () => ({
  type: actions.GET_JOB_OFFERS_STATS_REQUEST
});

const getJobOffersStatisticSuccess = (stats: StatsDto[] = [], total: number = 0) => ({
  type: actions.GET_JOB_OFFERS_STATS_SUCCESS,
  stats: stats,
  total: total
});

const getJobOffersStatisticFailure = (error: string) => ({
  type: actions.GET_JOB_OFFERS_STATS_FAILURE,
  error
});

const resetJobOffersStatisticSuccess = data => ({
  type: actions.RESET_JOB_OFFERS_STATS_SUCCESS,
  payload: data
});

export const resetJobOffersStats = () => {
  return dispatch => {
    dispatch(resetJobOffersStatisticSuccess());
  };
};

export const getJobOffersStatistic = (companyId: string) => {
  return dispatch => {
    dispatch(getJobOffersStatisticRequest());
    return getCandidatesStats(companyId)
      .then(response => dispatch(getJobOffersStatisticSuccess(response.data.details, response.data.count)))
      .catch(error => dispatch(getJobOffersStatisticFailure(error)));
  };
};
