import * as actions from './languages.constants';

import type { LanguagesState } from './languages.type';

const initialState: LanguagesState = {
  fetching: false,
  languagesList: []
};

export default (state = initialState, action): LanguagesState => {
  switch (action.type) {

    case actions.GET_LANGUAGES_REQUEST:
      return {
        fetching: true
      };

    case actions.GET_LANGUAGES_FAILURE:
      return {
        fetching: false
      };

    case actions.GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        fetching: false,
        languagesList: action.languages.content
      };

    default:
      return state;
  }
};
