// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';
import type { SelectType } from 'components/controls/select/select';

import { CompanyDto } from 'rest/companies/companies.dto';
import { RecruiterResponseDto } from 'rest/recruiter/recruiter.response.dto';
import { AddressDto } from 'rest/address/address.dto';
import { UserAddressDto } from 'rest/address-dto/address';

import { searchSuggestedAddress } from 'services/portal/address/address.actions';
import { getRecruiterCompanyById, updateRecruiterCompany } from 'services/portal/recruiter/account/account.actions';

import './payment-details-invoice-info.scss';

export type Props = {
  t?: TransProps,
  address: UserAddressDto,
  company: CompanyDto,
  currentUser?: RecruiterResponseDto,
  searchSuggestedAddress: (field: string, filters: AddressDto) => void,
  getRecruiterCompanyById: (companyId: string, company: CompanyDto) => void,
  updateRecruiterCompany: (companyId: string, company: CompanyDto) => void
};

type State = {
  city: SelectType,
  name: string,
  street: SelectType,
  streetNumber: string,
  country: SelectType,
  vatId: string,
  vatIdError: boolean,
  zipCode: string
};

export class Settings extends Component<Props, State> {

  static defaultProps = {
    company: new CompanyDto(),
    currentUser: new RecruiterResponseDto()
  };

  state: State = {
    city: '',
    country: '',
    name: '',
    street: '',
    streetNumber: '',
    vatId: null,
    vatIdError: false,
    zipCode: ''
  };

}

export const stateToProps = (state: GlobalState) => ({
  address: state.portal.address.content,
  company: state.portal.recruiter.account.companies.company,
  currentUser: state.portal.recruiter.account.current.currentUser
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  searchSuggestedAddress,
  getRecruiterCompanyById,
  updateRecruiterCompany
}, dispatch);
