import { combineReducers } from 'redux';

import match from './match/match.reducer';
import follow from './follow/follow.reducer';
import account from './account/account.reducer';
import calendar from './calendar/calendar.reducer';
import jobOffer from './job-offer/job-offer.reducer';
import candidate from './candidate/candidate.reducer';
import favorites from './favorites/favorites.reducer';
import followStats from './follow/stats/stats.reducer';
import favoritesStats from './favorites/stats/stats.reducer';

export default combineReducers({
  match,
  follow,
  account,
  calendar,
  jobOffer,
  candidate,
  favorites,
  followStats,
  favoritesStats
});
