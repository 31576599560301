// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { CompanyDto } from 'rest/companies/companies.dto';
import { CompanyOrderDto } from 'rest/companies/order/order.dto';
import { RecruiterResponseDto } from 'rest/recruiter/recruiter.response.dto';
import { SubscriptionDto } from 'rest/companies/subscription/subscription.dto';
import { PaymentRedirectUrlsDto } from 'rest/companies/payment-redirect-urls.dto';

import {
  fetchCompanyOrders,
  getRecruiterCompanyById,
  subscribeRecruiterCompany,
  getCurrentCompanyRecruiter,
  getCompanyPremiumCostRequest
} from 'services/portal/recruiter/account/account.actions';

import './payment-details.scss';

export type Props = {
  t?: TransProps,
  fetching: boolean,
  company: CompanyDto,
  redirectLink: string,
  ordersList: CompanyOrderDto[],
  currentUser: RecruiterResponseDto,
  getCurrentCompanyRecruiter: () => void,
  getRecruiterCompanyById: (id: string) => void,
  fetchCompanyOrders: (companyId: string, status: string) => void,
  subscribeRecruiterCompany: (companyId: string, data: SubscriptionDto) => void
}

type State = {
  redirectLink: string,
  paymentMethod: string,
  isCompanyInfoEditable: boolean,
  isSepaModalOpen: boolean,
  isPremiumInfoModalOpen: boolean,
  isChangeSubscriptionPlanActive: boolean,
  isChangeSubscriptionMethodActive: boolean,
  promoCode: string,
  promoCodeIsValid: boolean,
  promoCodeIsFailed: boolean,
  promoCodeIsChange: boolean,
  amount: string,
  months: string,
  paymentRedirectUrls: PaymentRedirectUrlsDto,
  vatId: string
}

export class Setting extends Component<Props, State> {

  static defaultProps = {
    redirectLink: '',
    fetchCompanyOrders: () => {},
    getRecruiterCompanyById: () => {},
    subscribeRecruiterCompany: () => {},
    getCurrentCompanyRecruiter: () => {},
    currentUser: new RecruiterResponseDto()
  };

  state: State = {
    redirectLink: '',
    paymentMethod: 'SOFORT',
    isCompanyInfoEditable: false,
    isSepaModalOpen: false,
    isPremiumInfoModalOpen: false,
    isChangeSubscriptionPlanActive: false,
    isChangeSubscriptionMethodActive: false,
    promoCode: null,
    promoCodeIsValid: false,
    promoCodeIsFailed: false,
    promoCodeIsChange: false,
    amount: 250,
    months: 12,
    paymentRedirectUrls: new PaymentRedirectUrlsDto('/portal/recruiter/account/payment/success', '/', '/'),
    vatId: ''
  };

}

export const stateToProps = (state: GlobalState) => {
  console.log('redirectUrl', state.portal.recruiter.account.company);
  return {
    fetching: state.portal.recruiter.account.orders.fetching,
    company: state.portal.recruiter.account.companies.company,
    ordersList: state.portal.recruiter.account.orders.ordersList,
    currentUser: state.portal.recruiter.account.current.currentUser,
    redirectLink: state.portal.recruiter.account.payment.redirectUrl,
    price: state.portal.recruiter.account.companies.premiumSubscriptionPrice
  };
};

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  fetchCompanyOrders,
  getRecruiterCompanyById,
  subscribeRecruiterCompany,
  getCurrentCompanyRecruiter,
  getCompanyPremiumCostRequest
}, dispatch);
