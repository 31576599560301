import * as actions from './general.constants';

import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

import type { GeneralJobOfferState } from './general.type';

const initialState: GeneralJobOfferState = {
  fetching: false,
  publishJobOfferSuccess: false,
  currentJobOffer: new JobOffersResponseDto()
};

export default (state = initialState, action): GeneralJobOfferState => {
  switch (action.type) {

    case actions.GET_JOB_OFFER_BY_ID_SUCCESS:
      return {
        ...state,
        fetching: false,
        currentJobOffer: new JobOffersResponseDto(action.jobOffer)
      };

    case actions.RESET_CURRENT_JOB_OFFER:
      return { ...initialState };

    default:
      return state;
  }
};
