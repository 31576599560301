import React from 'react';
import { NavLink } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';

import { Card } from 'components/layouts';

import { modalTypes } from 'services/portal/modal/modal.constants';

import type { Props } from './menu.setting';
import { Settings, stateToProps, dispatchToProps } from './menu.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class Menu extends Settings {

  fetchBadgeMenuData (candidateId: string) {
    this.initChatCounter();
    this.initNewMatchedCandidateCount();
    this.props.getUnreadConversation();
    this.props.getNewMatchedJobOffersCount(candidateId);
  }

  componentWillUnmount () {
    clearInterval(this.state.chatInterval);
    clearInterval(this.state.matchedInterval);
  }

  componentWillReceiveProps (nextProps: Props) {
    const currentUser = nextProps.currentUser;
    if (currentUser && currentUser !== this.state.user) {
      const avatar = currentUser.candidatePhotos && currentUser.candidatePhotos.find(photo => photo.primary);
      this.setState({
        user: currentUser,
        avatar
      });
      this.fetchBadgeMenuData(nextProps.currentUser.id);
    }
  }

  initNewMatchedCandidateCount () {
    const oneMinuteTimer: number = 60000;
    const matchedInterval: number = setInterval(() =>
      this.props.getNewMatchedJobOffersCount(this.props.currentUser.id), oneMinuteTimer);
    this.setState({ matchedInterval });
  }

  initChatCounter () {
    const timer: number = 30000;
    const chatInterval: number = setInterval(() => this.props.getUnreadConversation(), timer);
    this.setState({ chatInterval });
  }

  handleClosePortalBurger () {
    this.props.portalShowHideNav(!this.props.portalNavShow);
  }

  showProfileModal () {
    this.props.showModal(modalTypes.CANDIDATE_PROFILE_MODAL);
    this.props.portalShowHideNav(!this.props.portalNavShow);
  }

  render () {
    return (
      <Card>
        <div className={`candidate-profile-nav-container ${this.props.portalNavShow ? 'show' : ''}`}>
          <div className="profile-nav-header">
            <div className="person-container"
              onClick={() => this.props.showModal(modalTypes.CANDIDATE_PROFILE_MODAL)}>
              <div className="avatar-wrapper">
                <img
                  src={this.state.avatar ? this.state.avatar.url : '/images/shared/userpick.jpg'}
                  alt="avatar"/>
              </div>
              <div className="name-wrapper">
                <h3 className="user-name">
                  {
                    this.props.currentUser && `${this.props.currentUser.firstName} ${this.props.currentUser.lastName}`
                  }
                </h3>
                <span className="user-role">
                  <Trans i18nKey="portal.candidate.title">
                    Candidate
                  </Trans>
                </span>
              </div>
            </div>
            <div className="setting-container-link">
              <button
                onClick={() => this.showProfileModal()}
                className="icon-settings">
                <span className="setting-mobile-cation">
                  <Trans i18nKey="portal.candidate.menu.mobile-button">
                    Profile Settings
                  </Trans>
                </span>
              </button>
              <div
                className="close-portal-nav"
                onClick={() => this.handleClosePortalBurger()}>&times;</div>
            </div>
          </div>
          <div className="profile-nav-body">
            <div className="profile-nav-links">
              <NavLink
                className="profile-nav-link"
                activeClassName="active"
                to="/portal/candidate/jobs"
                onClick={() => this.handleClosePortalBurger()}>
                <div className="tile">
                  <span className="icon-search"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.candidate.menu.jobs">
                    Job Search
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                className="profile-nav-link"
                activeClassName="active"
                to="/portal/candidate/balance-board"
                onClick={() => this.handleClosePortalBurger()}>
                <div className="tile">
                  <span className="icon-balance-board"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.candidate.menu.balance-board">
                    Balance Board
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                className="profile-nav-link"
                activeClassName="active"
                to="/portal/candidate/match"
                onClick={() => this.handleClosePortalBurger()}>
                <div className="tile">
                  <span className="icon-match"/>
                  {
                    this.props.newJobOfferMatchedCount > 0 &&
                    <span className="badge">{this.props.newJobOfferMatchedCount}</span>
                  }
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.candidate.menu.match">
                    Match
                  </Trans>
                  {
                    this.props.newJobOfferMatchedCount > 0 &&
                    <span className="badge">{this.props.newJobOfferMatchedCount}</span>
                  }
                </div>
              </NavLink>
              <NavLink
                className="profile-nav-link"
                activeClassName="active"
                to="/portal/candidate/applications"
                onClick={() => this.handleClosePortalBurger()}>
                <div className="tile">
                  <span className="icon-applications"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.candidate.menu.applications">
                    Applications
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                to="/portal/candidate/chat"
                className="profile-nav-link"
                activeClassName="active"
                onClick={() => this.handleClosePortalBurger()}>
                <div className="tile">
                  <span className="icon-chat"/>
                  {
                    this.props.unreadMessages > 0 && <span className="badge">{this.props.unreadMessages}</span>
                  }
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.candidate.menu.chat">
                    Chat
                  </Trans>
                  {
                    this.props.unreadMessages > 0 && <span className="badge">{this.props.unreadMessages}</span>
                  }
                </div>
              </NavLink>
              <NavLink
                className="profile-nav-link"
                activeClassName="active"
                to="/portal/candidate/cv"
                onClick={() => this.handleClosePortalBurger()}>
                <div className="tile">
                  <span className="icon-sv"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.candidate.menu.cv">
                    CV
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                className="profile-nav-link"
                activeClassName="active"
                to="/portal/candidate/favorites"
                onClick={() => this.handleClosePortalBurger()}>
                <div className="tile">
                  <span className="icon-favorites"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.candidate.menu.favorites">
                    Favorites
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                className="profile-nav-link"
                activeClassName="active"
                to="/portal/candidate/follow"
                onClick={() => this.handleClosePortalBurger()}>
                <div className="tile">
                  <span className="icon-follow"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.candidate.menu.follow">
                    Follow
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                className="profile-nav-link"
                activeClassName="active"
                to="/portal/candidate/posts"
                onClick={() => this.handleClosePortalBurger()}>
                <div className="tile">
                  <span className="icon-my-posts"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.candidate.menu.blog">
                    My Blog
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                className="profile-nav-link"
                activeClassName="active"
                to={`/portal/candidate/${this.props.match.params.pageKey}/ask-me`}
                onClick={() => this.handleClosePortalBurger()}>
                <div className="tile">
                  <span className="icon-ask-me"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.candidate.menu.ask-me">
                    Ask Me
                  </Trans>
                </div>
              </NavLink>
            </div>
          </div>
          <div
            className="mobile-candidate-profile-nav-overlay"
            onClick={() => this.handleClosePortalBurger()}></div>
        </div>
      </Card>
    );
  }

}
