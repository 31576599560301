// @flow
import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { List } from 'rest/list/list.dto';
import { CandidateDto } from 'rest/candidates/candidate.dto';
import { RecruiterFollowParams } from 'rest/recruiter/follow/follow.params';

import {
  requestFollowingCandidates,
  addingCandidateToFollowing,
  resetAllCandidateFollowing,
  deletingCandidateFromFollowing
} from 'services/portal/recruiter/follow/following/following.actions';

import './following.scss';

type Props = {
  t?: TransProps,
  unitId: string,
  history: History,
  fetching: boolean,
  candidates: List<CandidateDto>,
  currentPage: number,
  hasMoreItems: boolean,
  currentRecruiterId: string,

  requestFollowingCandidates: (params: RecruiterFollowParams) => void,
  addingCandidateToFollowing: (params: RecruiterFollowParams) => void,
  deletingCandidateFromFollowing: (params: RecruiterFollowParams) => void,
  resetAllCandidateFollowing: () => void
};

export class Setting extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  unitId: state.portal.recruiter.follow.following.unitId,
  fetching: state.portal.recruiter.follow.following.fetching,
  candidates: state.portal.recruiter.follow.following.content,
  currentPage: state.portal.recruiter.follow.following.currentPage,
  hasMoreItems: state.portal.recruiter.follow.following.hasMoreItems,
  currentRecruiterId: state.portal.recruiter.account.current.currentUser.id
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  requestFollowingCandidates,
  addingCandidateToFollowing,
  resetAllCandidateFollowing,
  deletingCandidateFromFollowing
}, dispatch);
