// @flow
import { Component } from 'react';

import './questions-button.scss';
import './questions-button.responsive.scss';

type State = {
  openContactModal: boolean
}

export class Setting extends Component<null, State> {

  state = {
    openContactModal: false
  };

}
