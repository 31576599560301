// @flow
import React, { Fragment } from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { Setting } from './container.setting';

export class StatisticsContainer extends Setting {

  render () {
    return (
      <div className={`statistics-container ${this.props.className}`}>
        <div className="statistics-title">
          { this.props.title }
        </div>
        <PerfectScrollbar className="statistics-items">
          <Fragment>{ this.props.children }</Fragment>
        </PerfectScrollbar>
      </div>
    );
  }

}
