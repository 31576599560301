import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { getCandidateById } from 'services/portal/recruiter/candidate/all/candidates.actions';

import { CandidateDto } from 'rest/candidates/candidate.dto';

import './candidate-profile.scss';

type Props = {
  t?: TransProps,
  children: any
};

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  candidate: new CandidateDto(state.portal.recruiter.candidate.allCandidates.candidate)
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getCandidateById
}, dispatch);
