import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg``;

export const LinkedIn = props => (
  <Icon
    width="25"
    height="24"
    viewBox="0 0 12 11"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Symbols" fill="none" fillRule="evenodd">
      <g
        id="Footer"
        transform="translate(-714 -286)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <g id="Group" transform="translate(704 275)">
          <g id="Group-5" transform="translate(10 11)">
            {/* eslint-disable-next-line max-len */}
            <path d="M11.2179487,6.40893037 L11.2179487,10.4533333 L8.81357908,10.4533333 L8.81357908,6.6798001 C8.81357908,5.73185778 8.46581294,5.08492423 7.59551083,5.08492423 C6.93114554,5.08492423 6.53578129,5.52091004 6.36184606,5.94265293 C6.29841669,6.09342436 6.28208988,6.30327846 6.28208988,6.51432794 L6.28208988,10.4533333 L3.87691173,10.4533333 C3.87691173,10.4533333 3.90930452,4.06220661 3.87691173,3.40003822 L6.28185515,3.40003822 L6.28185515,4.39981457 C6.27697798,4.4072921 6.27064025,4.4153546 6.26604997,4.42260323 L6.28185515,4.42260323 L6.28185515,4.39981457 C6.6013754,3.91990462 7.17200502,3.23433716 8.44925141,3.23433716 C10.0316734,3.23431172 11.2179487,4.24243035 11.2179487,6.40893037 Z M1.36099298,0 C0.538184661,0 0,0.526301153 0,1.2184051 C0,1.89542682 0.522614211,2.43770038 1.32906965,2.43770038 L1.34513564,2.43770038 C2.18387954,2.43770038 2.70550267,1.89552855 2.70550267,1.2184051 C2.68969749,0.526301153 2.18387954,0 1.36099298,0 Z M0.142846493,10.4533333 L2.54711181,10.4533333 L2.54711181,3.40003822 L0.142846493,3.40003822 L0.142846493,10.4533333 Z"
              id="LinkedIn"
            />
          </g>
        </g>
      </g>
    </g>
  </Icon>
);

export default LinkedIn;
