// @flow
import { Component } from 'react';
import { TransProps } from 'react-i18next';

import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

import './job-offer-row.scss';
import './job-offer-row.responsive.scss';

export type Props = {
  t?: TransProps,
  isNew?: boolean,
  action?: () => void,
  onClick?: () => void,
  jobOffer?: JobOffersResponseDto,
};

export type State = {
  jobOffer: JobOffersResponseDto
}

export class Setting extends Component<Props, State> {

  static defaultProps: Props = {
    isNew: false,
    action: null,
    onClick: () => {},
    jobOffer: new JobOffersResponseDto()
  };

  state: State = {
    jobOffer: this.props.jobOffer
  };

}
