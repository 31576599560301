// @flow
import React from 'react';

import { Redirect, Switch } from 'react-router-dom';

import { Route } from 'middleware/route';

import { Card, Page } from 'components/layouts';
import { OnBoardingModal } from 'components/dialogs';

import { Menu } from 'containers/portal/recruiter';
import { Conversations } from 'containers/portal/chat';
import { NavigationContainer, NavigationItem } from 'containers/portal/navigation';

import { NotFoundPoster } from 'containers/portal/not-found';
import { RecruiterAuthContainer } from 'containers/auth/recruiter/auth-container/auth-container';
import { CandidatesListSidebar } from 'containers/portal/recruiter/sidebars/candidates-list/candidates-list';

import { MenuVideoModal } from 'components/dialogs';
import {
  MatchSidebar,
  FollowSidebar,
  CalendarSidebar,
  FavoritesSidebar,
  JobOffersSidebar,
  StatisticsSidebar,
  BalanceBoardSidebar
} from 'containers/portal/recruiter/sidebars';

import {
  ChatCircle,
  MatchCircle,
  FollowingCircle,
  CalendarCircle,
  FavoritesCircle,
  FollowersCircle,
  JobOffersCircle,
  CandidatesCircle,
  StatisticsCircle,
  BalanceBoardCircle,
  PurchasedCandidatesCircle
} from 'containers/portal/recruiter/circles';

import { ChatPage } from './chat';
import { FollowsPage } from './follow';
import { CalendarPage } from './calendar';
import { JobOffersPage } from './job-offers';
import { FavoritesPage } from './favorites';
import { CandidatesPage } from './candidates';
import { StatisticsPage } from './statistics';
import { MatchCandidatesPage } from './match';
import { BalanceBoardPage } from './balance-board';

import { Setting } from './setting';
export class RecruiterPortal extends Setting {

  constructor(props) {
    super(props);
    this.state = {
      locationPath: ''
    };
  }
  componentDidMount () {
    this.setRedirectUrl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      return true;
    }
  }

  setRedirectUrl () {
    const candidatePreviewLink: string = localStorage.getItem('ej_candidate_preview_link');
    if (candidatePreviewLink) {
      this.setState({ redirectLink: candidatePreviewLink });
      localStorage.removeItem('ej_candidate_preview_link');
    }
  }

  render () {
    return (
      <div className="recruiter-page">
        {
          this.state.redirectLink && <Redirect to={this.state.redirectLink}/>
        }
        <Page>
          <RecruiterAuthContainer location={this.props.location}>
            <Card>
              <Switch>
                <Route
                  path="/portal/recruiter/chat/:id?"
                  type="recruiter"
                  component={ChatPage}/>
                <Route
                  path="/portal/recruiter/match"
                  type="recruiter"
                  component={MatchCandidatesPage}/>
                <Route
                  path="/portal/recruiter/calendar"
                  type="recruiter"
                  component={CalendarPage}/>
                <Route
                  path="/portal/recruiter/job-offers"
                  type="recruiter"
                  component={JobOffersPage}/>
                <Route
                  path="/portal/recruiter/follow"
                  type="recruiter"
                  component={FollowsPage}/>
                <Route
                  path="/portal/recruiter/favorites"
                  type="recruiter"
                  component={FavoritesPage}/>
                <Route
                  path="/portal/recruiter/candidates"
                  type="recruiter"
                  component={CandidatesPage}/>
                <Route
                  path="/portal/recruiter/balance-board"
                  type="recruiter"
                  component={BalanceBoardPage}/>
                <Route
                  path="/portal/recruiter/statistics"
                  type="recruiter"
                  component={StatisticsPage}/>
                <Route
                  path="**"
                  component={NotFoundPoster}/>
              </Switch>
            </Card>
          </RecruiterAuthContainer>
        </Page>
        <NavigationContainer>
          <NavigationItem className="navigation-widget">
            <Card>
              <Switch>
                <Route
                  path="/portal/recruiter/chat/:id?"
                  type="recruiter"
                  component={Conversations}/>
                <Route
                  path="/portal/recruiter/match"
                  type="recruiter"
                  component={MatchSidebar}/>
                <Route
                  path="/portal/recruiter/calendar"
                  type="recruiter"
                  component={CalendarSidebar}/>
                <Route
                  path="/portal/recruiter/job-offers"
                  type="recruiter"
                  component={JobOffersSidebar}/>
                <Route
                  path="/portal/recruiter/follow"
                  type="recruiter"
                  component={FollowSidebar}/>
                <Route
                  path="/portal/recruiter/favorites"
                  type="recruiter"
                  component={FavoritesSidebar}/>
                <Route
                  path="/portal/recruiter/balance-board"
                  type="recruiter"
                  component={BalanceBoardSidebar}/>
                <Route
                  path="/portal/recruiter/statistics"
                  type="recruiter"
                  component={StatisticsSidebar}/>
                <Route
                  path="**"
                  component={CandidatesListSidebar}/>
              </Switch>
            </Card>
          </NavigationItem>
          <NavigationItem className="profile-widget">
            <Route
              path="/portal/recruiter/:pageKey?"
              component={Menu}/>
          </NavigationItem>
          <NavigationItem className="chart-widget">
            <Card>
              <Switch>
                <Route
                  path="/portal/recruiter/chat"
                  type="recruiter"
                  component={ChatCircle}/>
                <Route
                  path="/portal/recruiter/match"
                  type="recruiter"
                  component={MatchCircle}/>
                <Route
                  path="/portal/recruiter/job-offers"
                  type="recruiter"
                  component={JobOffersCircle}/>
                <Route
                  path="/portal/recruiter/follow/following"
                  type="recruiter"
                  component={FollowingCircle}/>
                <Route
                  path="/portal/recruiter/follow/followers"
                  type="recruiter"
                  component={FollowersCircle}/>
                <Route
                  path="/portal/recruiter/favorites/purchased"
                  type="recruiter"
                  exact
                  component={PurchasedCandidatesCircle}/>
                <Route
                  path="/portal/recruiter/favorites"
                  type="recruiter"
                  component={FavoritesCircle}/>
                <Route
                  path="/portal/recruiter/candidates"
                  type="recruiter"
                  component={CandidatesCircle}/>
                <Route
                  path="/portal/recruiter/balance-board"
                  type="recruiter"
                  component={BalanceBoardCircle}/>
                <Route
                  path="/portal/recruiter/statistics"
                  type="recruiter"
                  component={StatisticsCircle}/>
                <Route
                  path="**"
                  component={CalendarCircle}/>
              </Switch>
            </Card>
          </NavigationItem>
        </NavigationContainer>
        <MenuVideoModal locationPath= {this.props.location.pathname}/>
        <OnBoardingModal/>
      </div>
    );
  }

}
