// @flow`
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { changePassword } from 'services/portal/recruiter/account/account.actions';

import { ChangePasswordDto } from 'rest/account/change-password/change-password.dto';

import './change-password.scss';
import './change-password.responsive.scss';

export type Props = {
  t?: TransProps,
  errorText: string,
  fetching: boolean,
  buttonType: string,
  changePasswordSuccess: boolean,
  changePassword: (data: ChangePasswordDto) => void
}

export type State = {
  errorText: string,
  oldPassword: string,
  newPassword: string,
  confirmNewPassword: string,
  errorCollection: Set<string>
}

export class Settings extends Component<Props, State> {

  static defaultProps = {
    fetching: false,
    buttonType: 'primary',
    changePassword: () => {}
  };

  state: State = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    errorCollection: new Set(),
    errorText: this.props.errorText
  };

}

export const stateToProps = (state: GlobalState) => ({
  fetching: state.portal.recruiter.account.current.fetching,
  errorText: state.portal.recruiter.account.current.errorText,
  changePasswordSuccess: state.portal.recruiter.account.current.changePasswordSuccess
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  changePassword
}, dispatch);
