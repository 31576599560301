import React from 'react';

import { Settings } from './profile-container.setting';

export class ProfileContainer extends Settings {

  render () {
    return (
      <div className="invite-profile-modal">
        { this.props.children }
      </div>
    );
  }

}
