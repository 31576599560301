import React from 'react';

import { Setting } from './table.setting';

export class Table extends Setting {

  render () {
    return (
      <table className={this.props.className}>
        { this.props.children }
      </table>
    );
  }

}
