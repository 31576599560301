// @flow
import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { List } from 'rest/list/list.dto';
import { FavoriteParams } from 'rest/candidates/current/favorite/favorite.params';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

import {
  getFavoriteJobs,
  resetFavoriteJobs,
  deleteJobFromFavorite
} from 'services/portal/candidate/jobs/favorite/favorite.actions';

export type Props = {
  t?: TransProps,
  history: History,
  success: boolean,
  fetching: boolean,
  companyId: string,
  favoritesFilters: FavoriteParams,
  jobOffers: List<JobOffersResponseDto>,
  currentPage: number,
  hasMoreItems: boolean,
  getFavoriteJobs: () => void,
  resetFavoriteJobs: () => void,
  currentCandidateId: string,
  deleteJobFromFavorite: (id: string) => void
};

export class Setting extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  fetching: state.portal.candidate.jobs.favorite.fetching,
  jobOffers: state.portal.candidate.jobs.favorite.content,
  currentPage: state.portal.candidate.jobs.favorite.currentPage,
  favoritesFilters: state.portal.candidate.jobs.favorite.filters,
  hasMoreItems: state.portal.candidate.jobs.favorite.hasMoreItems,
  currentCandidateId: state.portal.candidate.account.current.candidateData.id
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getFavoriteJobs,
  resetFavoriteJobs,
  deleteJobFromFavorite
}, dispatch);
