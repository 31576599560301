import React from 'react';

import { Setting } from './container.setting';

export class PortletContainer extends Setting {

  render () {
    return (
      <div className="portlet">
        { this.props.children }
      </div>
    );
  }

}
