// @flow
import { Component } from 'react';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

type Props = {
  t: TransProps,
  totalFollowing?: number,
  totalFollowers?: number,
}

export class Setting extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  totalFollowing: state.portal.candidate.jobs.follow.stats.totalFollowingElements,
  totalFollowers: state.portal.candidate.jobs.follow.stats.totalFollowersElements
});
