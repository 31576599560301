export const DISABLE_ON_BALANCE_BOARD_VIDEO = 'DISABLE_ON_CANDIDATE_BALANCE_BOARD_VIDEO';
export const DISABLE_ON_JOBSEARCH_VIDEO = 'DISABLE_ON_CANDIDATE_JOBSEARCH_VIDEO';
export const DISABLE_ON_APPLICATION_VIDEO = 'DISABLE_ON_CANDIDATE_APPLICATION_VIDEO';
export const DISABLE_ON_MATCH_VIDEO = 'DISABLE_ON_CANDIDATE_MATCH_VIDEO';
export const DISABLE_ON_CHAT_VIDEO = 'DISABLE_ON_CANDIDATE_CHAT_VIDEO';
export const DISABLE_ON_CV_VIDEO = 'DISABLE_ON_CANDIDATE_CV_VIDEO';
export const DISABLE_ON_FAVORITES_VIDEO = 'DISABLE_ON_CANDIDATE_FAVORITES_VIDEO';
export const DISABLE_ON_FOLLOWING_VIDEO = 'DISABLE_ON_CANDIDATE_FOLLOWING_VIDEO';
export const DISABLE_ON_MYBLOGS_VIDEO = 'DISABLE_ON_CANDIDATE_MYBLOGS_VIDEO';
export const DISABLE_ON_BALANCE_BOARD_REUSE_VIDEO = 'DISABLE_ON_BALANCE_BOARD_REUSE_VIDEO';
export const DISABLE_ON_CANDIDATE_SETTINGS_VIDEO = 'DISABLE_ON_CANDIDATE_SETTINGS_VIDEO';
