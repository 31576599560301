import { ImageDto } from '../../images/image.dto';
import { NotificationSettingDto } from './notification-setting.dto';

export class CurrentRecruiterDto {

  id: string;
  email: string;
  owner: boolean;
  active: boolean;
  lastName: string;
  firstName: string;
  companyId: string;
  siteLocale: string;
  phoneNumber: string;
  fullyRegistered: boolean;
  showOnBoardingVideo: boolean;
  recruiterPhoto = new ImageDto();
  blockNotification: NotificationSettingDto;

  constructor (data?: CurrentRecruiterDto) {
    if (data) {
      this.id = data.id;
      this.owner = data.owner;
      this.email = data.email;
      this.active = data.active;
      this.lastName = data.lastName;
      this.firstName = data.firstName;
      this.companyId = data.companyId;
      this.siteLocale = data.siteLocale;
      this.phoneNumber = data.phoneNumber;
      this.showOnBoardingVideo = data.showOnBoardingVideo;
      this.fullyRegistered = data.fullyRegistered;
      this.recruiterPhoto = new ImageDto(data.recruiterPhoto);
      this.blockNotification = new NotificationSettingDto(data.blockNotification);
    }
  }

}
