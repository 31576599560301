import React from 'react';

import { Trans } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { ListPlaceholder } from 'components/elements';
import { Settings, stateToProps } from './placeholder.setting';

@connectStore(stateToProps, null, true)
export class ChatPlaceholder extends Settings {

  render () {
    return (
      <div className="chat-placeholder mobile-chat-placeholder">
        <PerfectScrollbar>
          {
            this.props.isRecruiter ? <ListPlaceholder
              title={this.props.t('portal.recruiter.chat.poster.title')}>
              <Trans i18nKey="portal.recruiter.chat.poster.description">
                Fast and easy, have your main questions answered before you interview them.
              </Trans>
            </ListPlaceholder> : <ListPlaceholder
              className="chat-list-placeholder mobile-chat-list-placeholder"
              title={this.props.t('portal.candidate.chat.poster.title')}>
              <Trans i18nKey="portal.candidate.chat.poster.description">
                Because really, how can you skip a chat?
              </Trans>
            </ListPlaceholder>
          }
        </PerfectScrollbar>
      </div>
    );
  }

}
