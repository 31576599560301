import * as actions from './job-offer.constants';

import { PaginationDto } from 'middleware/dto/pagination';

import { getAllCompanyJobOffersStats } from 'services/portal/recruiter/account/job-offer/stats/stats.actions';

import { JobOfferStatus } from 'rest/job-offers/job-offers.status.dto';

import { getCompanyJobOffers } from 'rest/companies/job-offers/job-offers.rest';
import { deleteJobOffer, updateCompanyJobOfferStatus } from 'rest/job-offers/job-offers.rest';

const getCompanyJobOffersRequest = () => ({
  type: actions.GET_COMPANY_JOB_OFFERS_REQUEST
});

const getCompanyJobOffersSuccess = data => ({
  type: actions.GET_COMPANY_JOB_OFFERS_SUCCESS,
  payload: data
});

const getCompanyJobOffersFailure = (error: string) => ({
  type: actions.GET_COMPANY_JOB_OFFERS_FAILURE,
  error
});

const deleteJobOfferRequest = () => ({
  type: actions.DELETE_JOB_OFFER_REQUEST
});

const deleteJobOfferSuccess = (jobOfferId: string) => ({
  type: actions.DELETE_JOB_OFFER_SUCCESS,
  jobOfferId
});

const deleteJobOfferFailure = (error: string) => ({
  type: actions.DELETE_JOB_OFFER_FAILURE,
  error
});

const resetJobOffersSuccess = () => ({
  type: actions.RESET_JOB_OFFERS_SUCCESS
});

const changeJobOffersStatusSuccess = (status: string) => ({
  type: actions.CHANGE_JOB_OFFER_STATUS_SUCCESS,
  payload: status
});

export const resetJobOffers = () => {
  return dispatch => {
    dispatch(resetJobOffersSuccess());
  };
};

export const changeJobOffersStatus = (status?: string = '') => {
  return dispatch => {
    dispatch(changeJobOffersStatusSuccess(status));
  };
};

export const getAllCompanyJobOffers = (companyId: string, status?: string, params: PaginationDto) => {
  return dispatch => {
    dispatch(getCompanyJobOffersRequest());
    return getCompanyJobOffers(companyId, status, params)
      .then(response => dispatch(getCompanyJobOffersSuccess(response.data)))
      .catch(error => dispatch(getCompanyJobOffersFailure(error)));
  };
};

export const deleteCompanyJobOffer = (companyId: string, jobOfferId: string) => {
  return dispatch => {
    dispatch(deleteJobOfferRequest());
    return deleteJobOffer(jobOfferId)
      .then(() => {
        dispatch(deleteJobOfferSuccess(jobOfferId));
        dispatch(getAllCompanyJobOffersStats(companyId));
      })
      .catch(error => dispatch(deleteJobOfferFailure(error)));
  };
};

export const updateJobOfferStatus = (companyId: string, jobOfferId: string, status: JobOfferStatus) => {
  return dispatch => {
    return updateCompanyJobOfferStatus(jobOfferId, status)
      .then(() => {
        dispatch(getAllCompanyJobOffers(companyId));
        dispatch(getAllCompanyJobOffersStats(companyId));
      });
  };
};
