// @flow
import * as actions from './education.constants';

import type { Action } from 'redux';

import type { EducationStateType } from './education.type';

const initialState: EducationStateType = {
  fetching: false,
  content: [],
  fetchedContent: [],
  createSuccess: false
};

export default function (state: EducationStateType = initialState, action: Action): EducationStateType {
  switch (action.type) {
    case actions.GET_EDUCATION_REQUEST:
    case actions.UPDATE_EDUCATION_REQUEST:
    case actions.CREATE_EDUCATION_REQUEST:
      return {
        ...state,
        fetching: true,
        createSuccess: false
      };

    case actions.CREATE_EDUCATION_FAILURE:
    case actions.UPDATE_EDUCATION_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.CREATE_EDUCATION_SUCCESS:
    case actions.UPDATE_EDUCATION_SUCCESS:
      return {
        ...state,
        fetching: false,
        createSuccess: true
      };

    case actions.GET_EDUCATION_SUCCESS:
      return {
        ...state,
        fetching: false,
        content: action.educations,
        fetchedContent: action.educations
      };

    case actions.ADD_NEW_EDUCATION_SUCCESS: {
      const content = [ ...state.content ];
      content.unshift(action.education);
      return {
        ...state,
        content
      };
    }

    case actions.CHANGE_WORK_EDUCATION_SUCCESS:
      return {
        ...state,
        content: state.content.map((item, index) => {
          if (index === action.index) {
            return {
              ...item,
              ...action.value
            };
          }
          return item;
        })
      };

    case actions.DELETE_NEW_EDUCATION_SUCCESS:
      return {
        ...state,
        content: state.content.filter((item, index: number) => index !== action.index)
      };

    case actions.DELETE_EDUCATION_SUCCESS:
      return {
        ...state,
        fetchedContent: state.fetchedContent.filter(item => item.id !== action.educationId)
      };

    case actions.RESET_EDUCATIONS_LIST_SUCCESS:
      return {
        ...state,
        content: state.fetchedContent
      };

    default:
      return state;
  }
}
