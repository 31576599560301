// @flow
import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { BranchResponseDto } from 'rest/branch/branch.response.dto';
import { LocationsDto } from 'rest/companies/job-offers-matches/matched-candidates/locations/locations.dto';

import { getAllBranches } from 'services/portal/recruiter/account/account.actions';
import { changeMatchedJobOffersFilter } from 'services/portal/candidate/match/job-offers/job-offers.actions';
import { getMatchedJobOffersLocations } from 'services/portal/candidate/match/new-job-offers/new-job-offers.actions';

export type Props = {
  candidateId: string,
  branches: BranchResponseDto[],
  locations: LocationsDto[]
};

type State = {
  branchId: string,
  cityPlaceId: string
}

export class Settings extends Component<Props, State> {

  state: State = {
    branchId: '',
    cityPlaceId: ''
  };

}

export const stateToProps = (state: GlobalState) => ({
  branches: state.portal.recruiter.account.companies.branches,
  locations: state.portal.candidate.match.newJobOffers.locations,
  candidateId: state.portal.candidate.account.current.candidateData.id
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getBranches: getAllBranches,
  getLocations: getMatchedJobOffersLocations,
  changeMatchedFilter: changeMatchedJobOffersFilter
}, dispatch);
