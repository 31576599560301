import axios, { AxiosPromise } from 'axios';

import { List } from 'rest/list/list.dto';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';
import { LocationsDto } from 'rest/companies/job-offers-matches/matched-candidates/locations/locations.dto';

import { FavoriteParams } from './favorite.params';

export async function getFavoriteJobsRest (params?: FavoriteParams): AxiosPromise<List<JobOffersResponseDto>> {
  try {
    return await axios.get('/candidates/current/job-offer-favorites', { params });
  } catch (error) {
    throw error;
  }
}

export async function addJobToFavoriteRest (jobOfferId: string): AxiosPromise {
  try {
    return await axios.post(`/candidates/current/job-offer-favorites/${jobOfferId}`);
  } catch (error) {
    throw error;
  }
}

export async function deleteJobFromFavoriteRest (jobOfferId: string): AxiosPromise {
  try {
    return await axios.delete(`/candidates/current/job-offer-favorites/${jobOfferId}`);
  } catch (error) {
    throw error;
  }
}

export async function getLocationsFavoritesCompanies (): AxiosPromise<LocationsDto[]> {
  try {
    return await axios.get('candidates/current/job-offer-favorites/locations');
  } catch (error) {
    throw error;
  }
}
