import { Component } from 'react';

import './search-container.scss';
import './search-container.responsive.scss';

type Props = {
  children: Node,
  className?: string
};

export class Settings extends Component<Props> {

  static defaultProps: Props = {
    className: ''
  }

}
