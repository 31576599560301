import React from 'react';
import { Link } from 'react-router-dom';

import { Settings } from './logo.setting';

export class Logo extends Settings {

  render () {
    return (
      <div className="logo">
        {
          this.props.userType === 'recruiter' && this.props.recruiterRegistered
            ? <Link to="/">
              <img
                src="/images/shared/logo.svg"
                alt="Excellent Jobs"/>
            </Link> : null
        }
        {
          this.props.userType === 'candidate' && this.props.candidateRegistered
            ? <Link to="/">
              <img
                src="/images/shared/logo.svg"
                alt="Excellent Jobs"/>
            </Link> : null
        }
        {
          this.props.userType === 'recruiter' && !this.props.recruiterRegistered
            ? <Link to="/" onClick={() => this.props.logOut()}>
              <img
                src="/images/shared/logo.svg"
                alt="Excellent Jobs"/>
            </Link> : null
        }
        {
          this.props.userType === 'candidate' && !this.props.candidateRegistered
            ? <Link to="/" onClick={() => this.props.logOut()}>
              <img
                src="/images/shared/logo.svg"
                alt="Excellent Jobs"/>
            </Link> : null
        }
        {
          this.props.userType === 'guest' && <Link to="/">
            <img
              src="/images/shared/logo.svg"
              alt="Excellent Jobs"/>
          </Link>
        }
      </div>
    );
  }

}
