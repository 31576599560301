import axios, { AxiosPromise } from 'axios';

import { PaginationDto } from 'middleware/dto/pagination';

import { CalendarRequestDto } from './candidate-invites-request.dto';
import { CalendarResponseDto } from './candidate-invites-response.dto';
import { CalendarInvitesStatsDto } from './candidate-invites-stats.dto';

export async function getCalendarInvites (company: string, status: string, params: PaginationDto)
  : AxiosPromise<CalendarResponseDto[]> {
  try {
    return await axios.get(`/companies/${company}/candidate-invites?status=${status}`, { params });
  } catch (error) {
    throw error;
  }
}

export async function getCalendarInvitesStats (company: string): AxiosPromise<CalendarInvitesStatsDto> {
  try {
    return await axios.get(`/companies/${company}/candidate-invites/stats`);
  } catch (error) {
    throw error;
  }
}

export async function createCalendarInvite (company: string, invite: CalendarRequestDto)
  : AxiosPromise<CalendarResponseDto> {
  try {
    return await axios.post(`/companies/${company}/candidate-invites`, invite);
  } catch (error) {
    throw error;
  }
}

export async function deleteCalendarInvite (company: string, invite: string): AxiosPromise<CalendarResponseDto> {
  try {
    return await axios.delete(`/companies/${company}/candidate-invites/${invite}`);
  } catch (error) {
    throw error;
  }
}

export async function changeCalendarInvite (company: string, invite: string, status: string)
  : AxiosPromise<CalendarResponseDto> {
  try {
    return await axios.put(`/companies/${company}/candidate-invites/${invite}/status?status=${status}`);
  } catch (error) {
    throw error;
  }
}
