// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { RecruiterResponseDto } from 'rest/recruiter/recruiter.response.dto';
import { InvitesMembersDto } from 'rest/companies/team-members/team-members.dto';

import {
  getCurrentCompanyRecruiter,
  inviteRecruiterTeamMembers
} from 'services/portal/recruiter/account/account.actions';

import './team-members.scss';
import './team-members.responsive.scss';

export type Props = {
  t?: TransProps,
  fetching: boolean,
  errorText: string,
  prev: () => void,
  currentUser: RecruiterResponseDto,
  getCurrentRecruiter: () => void,
  inviteRecruiterTeamMembers: (id: number, invites: InvitesMembersDto) => void
}

export type State = {
  errorText: string,
  firstField: string,
  secondField: string,
  thirdField: string,
  fourthField: string,
  errorCollection: Set<string>
}

export class Settings extends Component<Props, State> {

  static defaultProps = {
    currentUser: new RecruiterResponseDto(),
    prev: () => {}
  };

  state: State = {
    errorText: '',
    firstField: '',
    secondField: '',
    thirdField: '',
    fourthField: '',
    errorCollection: new Set()
  }

}

export const stateToProps = (state: GlobalState) => ({
  currentUser: state.portal.recruiter.account.current.currentUser,
  fetching: state.portal.recruiter.account.teamMembers.fetching,
  errorText: state.portal.recruiter.account.teamMembers.errorText
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  inviteRecruiterTeamMembers,
  getCurrentRecruiter: getCurrentCompanyRecruiter
}, dispatch);
