import * as actions from './demoMenuVideo.constants';

import type { CurrentRecruiterState } from './demoMenuVideo.type';

const initialState: CurrentRecruiterState = {
  onloadBalanaceBoardVideo: true,
  onloadBalanaceBoardReUseVideo: true,
  onloadJobSearchVideo: true,
  onloadApplicationVideo: true,
  onloadMatchVideo: true,
  onloadChatVideo: true,
  onloadCvVideo: true,
  onloadFavoritesVideo: true,
  onloadFollowingVideo: true,
  onloadMyBlogVideo: true,
  onLoadSettingDemoVideo: true
};

export default (state = initialState, action): CurrentRecruiterState => {
  switch (action.type) {
    case actions.DISABLE_ON_BALANCE_BOARD_VIDEO:
      return {
        ...state,
        onloadBalanaceBoardVideo: false
      };
    case actions.DISABLE_ON_BALANCE_BOARD_REUSE_VIDEO:
      return {
        ...state,
        onloadBalanaceBoardReUseVideo: false
      };
    case actions.DISABLE_ON_JOBSEARCH_VIDEO:
      return {
        ...state,
        onloadJobSearchVideo: false
      };
    case actions.DISABLE_ON_APPLICATION_VIDEO:
      return {
        ...state,
        onloadApplicationVideo: false
      };
    case actions.DISABLE_ON_MATCH_VIDEO:
      return {
        ...state,
        onloadMatchVideo: false
      };
    case actions.DISABLE_ON_CHAT_VIDEO:
      return {
        ...state,
        onloadChatVideo: false
      };
    case actions.DISABLE_ON_CV_VIDEO:
      return {
        ...state,
        onloadCvVideo: false
      };
    case actions.DISABLE_ON_FAVORITES_VIDEO:
      return {
        ...state,
        onloadFavoritesVideo: false
      };
    case actions.DISABLE_ON_FOLLOWING_VIDEO:
      return {
        ...state,
        onloadFollowingVideo: false
      };
    case actions.DISABLE_ON_MYBLOGS_VIDEO:
      return {
        ...state,
        onloadMyBlogVideo: false
      };
    case actions.DISABLE_ON_CANDIDATE_SETTINGS_VIDEO:
      return {
        ...state,
        onLoadSettingDemoVideo: false
      };
    default:
      return state;
  }
};
