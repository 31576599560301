import React from 'react';
import cn from 'classnames';

import { emailPattern, passwordPattern } from 'middleware/validator/patterns';

import { Setting } from './input-control.setting';
import type { Props, State } from './input-control.setting';

export class InputControl extends Setting {

  static getDerivedStateFromProps (nextProps: Props, prevState: State) {
    if (nextProps.value !== prevState.value || nextProps.error !== prevState.error) {
      return {
        value: nextProps.value
      };
    }
    return null;
  }

  changeInputState = (event: Event) => {
    const input: HTMLInputElement = event.currentTarget;
    const error = this.props.validate && input.validity.patternMismatch;
    this.setState({
      error,
      isEdit: true
    });
    this.props.change(input.value, error);
  };

  getPattern = (type: string): string => {
    switch (type) {
      case 'email':
        return emailPattern;
      case 'password':
        return this.props.pattern || passwordPattern;
      default:
        return this.props.pattern;
    }
  };

  togglePasswordVisibility = () => {
    this.setState({
      passwordVisibility: !this.state.passwordVisibility
    });
  };

  render () {
    const labelClasses = cn([ 'form-label', {
      icon: this.state.value && this.props.icon && this.state.isEdit && !this.state.error,
      error: this.state.error || this.props.error,
      disabled: this.props.disabled,
      'mobile-password': this.props.type === 'password'
    } ]);
    const notesClasses = cn([ 'notes', {
      'sticky-notes': this.props.stickyNotes
    } ]);

    const pattern: string = this.getPattern(this.props.type);

    return (
      <label className={`${labelClasses} ${this.props.className}`}>
        <span className="form-label-title">{this.props.label}</span>
        <span className={notesClasses}>{this.props.notes ? this.props.notes : null}</span>
        {
          this.props.multiline ? <textarea
            cols={this.props.cols}
            minLength={this.props.minLength}
            maxLength={this.props.maxLength}
            rows={this.props.rows}
            value={this.state.value}
            required={this.props.required}
            disabled={this.props.disabled}
            onChange={this.changeInputState}
            className="form-control textarea"
            placeholder={this.props.placeholder}
          /> : <input
            step={this.props.step}
            onPaste={this.props.onPaste}
            autoComplete={this.props.autocomplete ? '' : 'new-password'}
            min={this.props.min}
            max={this.props.max}
            type={this.state.passwordVisibility ? 'text' : this.props.type}
            value={this.state.value || ''}
            title={this.props.title}
            pattern={pattern}
            readOnly={this.props.readonly}
            onChange={this.changeInputState}
            required={this.props.required}
            minLength={this.props.minLength}
            maxLength={this.props.maxLength}
            className={`form-control ${this.props.className}`}
            placeholder={this.props.placeholder}/>
        }
        {
          this.props.type === 'password' && <span
            onClick={this.togglePasswordVisibility}
            className="eye-icon">
            <img src={`/images/auth/${this.state.passwordVisibility ? 'eye-open' : 'eye-close'}.svg`} alt="eye"/>
          </span>
        }
        {
          this.props.errorText && <span className="label-error">
            <span>{this.props.errorText}</span>
          </span>
        }
        {
          this.props.className === 'input-control-search' && <span className="icon-search"/>
        }
      </label>
    );
  }

}
