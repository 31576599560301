export function uploadFile (file: File, cb) {
  const maxPhotoSize: number = 26214400;
  if (file && file.size <= maxPhotoSize) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => cb(reader.result.toString(), false);
  } else {
    cb(null, 'File size is too large (max 25MB).');
  }
}
