import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';

import { TransProps } from 'react-i18next';

import type { Match } from 'common/types/match';
import type { GlobalState } from 'common/types/global-state';

import { ChatResponseDto } from 'rest/chats/chat.response.dto';
import { MessagesRequestDto } from 'rest/chats/messages/messages.request.dto';
import { MessagesResponseDto } from 'rest/chats/messages/messages.response.dto';

import { resetUnreadConversation } from 'services/portal/chats/conversations/conversations.actions';

import {
  createMessage,
  getAllMessages,
  resetNewMessage,
  resetAllMessages,
  getUnreadMessages,
  resetUnreadMessages
} from 'services/portal/chats/messages/messages.actions';

import './channel.scss';
import './channel.responsive.scss';

type Props = {
  t: TransProps,
  match: Match,
  messages: MessagesResponseDto[],
  currentPage: number,
  hasMoreItems: boolean,
  conversation: ChatResponseDto,
  messageComing: boolean,
  unreadMessages: number,
  resetUnreadConversation: () => void,
  resetMessage: () => void,
  loadMessages: (chanelId: string, page: number) => void,
  createMessage: (chanelId: string, message: MessagesRequestDto) => void,
  resetMessages: () => void,
  loadUnreadMessages: (chanelId: string) => void,
  resetUnreadMessages: () => void
}

type State = {
  message: string,
  interval: number,
  attachment: File,
  fileSizeError: string,
  lastReadMessage: string
}

export class Settings extends Component<Props, State> {

  state = {
    message: '',
    interval: 0,
    attachment: null,
    fileSizeError: '',
    lastReadMessage: null
  }

}

export const stateToProps = (state: GlobalState) => ({
  messages: state.portal.chats.messages.content,
  currentPage: state.portal.chats.messages.currentPage,
  hasMoreItems: state.portal.chats.messages.hasMoreItems,
  conversation: state.portal.chats.conversations.conversation,
  messageComing: state.portal.chats.messages.newMessageComing,
  unreadMessages: state.portal.chats.messages.unreadMessages
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetUnreadConversation,
  loadMessages: getAllMessages,
  resetMessage: resetNewMessage,
  createMessage: createMessage,
  resetMessages: resetAllMessages,
  loadUnreadMessages: getUnreadMessages,
  resetUnreadMessages: resetUnreadMessages
}, dispatch);
