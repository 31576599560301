// @flow
import axios, { AxiosPromise } from 'axios';

import { oAuthDto } from './oauth.dto';

export async function refreshToken (refreshToken: string): AxiosPromise<oAuthDto> {
  try {
    const formData = new FormData();
    formData.append('grant_type', 'refresh_token');
    formData.append('refresh_token', refreshToken);
    return await axios({
      method: 'POST',
      url: '/oauth/token',
      data: formData,
      headers: {
        'Authorization': 'Basic aW50ZXJuYWw6OXdzYk4ydW4=',
        'Content-Type': 'multipart/form-data'
      }
    });
  } catch (error) {
    throw error ? error.response : '';
  }
}
