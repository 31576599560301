// @flow
import { Component } from 'react';

import type { RouterHistory } from 'react-router-dom';

type Props = {
  history: RouterHistory
}

export class Setting extends Component<Props> {}
