import * as actions from './match.constants';

import { SkillsDto } from 'rest/skill/skills.dto';
import { JobOfferMatchedDto } from 'rest/job-offers/match/match.dto';

import { checkJobOfferMatch } from 'rest/job-offers/match/match.rest';

const checkJobOfferMatchRequest = () => ({
  type: actions.CHECK_JOB_OFFER_MATCH_REQUEST
});

const checkJobOfferMatchSuccess = (payload: JobOfferMatchedDto) => ({
  type: actions.CHECK_JOB_OFFER_MATCH_SUCCESS,
  payload
});

const checkJobOfferMatchFailure = (error: string) => ({
  type: actions.CHECK_JOB_OFFER_MATCH_FAILURE,
  error
});

export const checkJobOfferMatchSkills = (jobOfferId: string, data: SkillsDto) => {
  return dispatch => {
    dispatch(checkJobOfferMatchRequest());
    return checkJobOfferMatch(jobOfferId, data)
      .then(response => dispatch(checkJobOfferMatchSuccess(response.data)))
      .catch(error => dispatch(checkJobOfferMatchFailure(error)));
  };
};
