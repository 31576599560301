// @flow
import React from 'react';

import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Setting } from './platform.setting';

@connectTranslation()
export class Platform extends Setting {

  render () {
    return (
      <div className="platform-container">
        <div className="platform-title">
          <Trans i18nKey="information.about.platform.title">
            People make values, not machines.
          </Trans>
        </div>
        <div className="platform-content">
          <div className="platform-image">
            <img
              src="/images/information/about-us.png"
              alt="Best job image"/>
          </div>
          <div className="platform-description">
            <Trans i18nKey="information.about.platform.description.0">
              At the heart of our success and our future is innovation: We are changing the way people think about work,
              and we`re helping them actively improve their lives and their workforce performance with new technology,
              tools and practices.
            </Trans>
            <br/>
            <br/>
            <Trans i18nKey="information.about.platform.description.1">
              ExcellentJobs builds a platform that match the candidates and recruiters in the hospitality sphere based
              on their skills and minimal match criteris.
            </Trans>
          </div>
        </div>
        <div className="platform-colons">
          <div className="platform-colons-item">
            <div className="platform-colons-title">
              <Trans i18nKey="information.about.platform.colons.0.title">
                Why Estonia?
              </Trans>
            </div>
            <div className="platform-description">
              <Trans i18nKey="information.about.platform.colons.0.description.0">
                Representative for our team of over 100 hospitality and software specialists are <b>Joerg Langhof</b>
                (CEO and founder) and <b>Beata Zubaka</b> (CDO and Co-founder). Joerg has been successfully financially
                refurbishing hotels and restaurants. In addition he has been coaching staff and management. Beata has
                been involved with software development since the begining of her career.
              </Trans>
              <br/>
              <br/>
              <Trans i18nKey="information.about.platform.colons.0.description.1">
                Digitalization is not only a &quot;term&quot; but also a process of improving a daily
                life. One estonian developer said: &quot;When we are too lazy for
                something, we build an app for that&quot;.
              </Trans>
            </div>
          </div>
          <div className="platform-colons-item">
            <div className="platform-colons-title">
              <Trans i18nKey="information.about.platform.colons.1.title">
                Why Estonia?
              </Trans>
            </div>
            <div className="platform-description">
              <Trans i18nKey="information.about.platform.colons.1.description.0">
                With Excellent jobs we take the first step towards digitalization in hospitality industry.
                We are already in the process of developing an e-learning product which is going to serve
                trainees and employees achieve their career goals. Faster and for free.
              </Trans>
            </div>
          </div>
        </div>
      </div>
    );
  }

}
