// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import * as qs from 'qs';

import { Spinner } from 'components/elements';

import { AuthOutDto } from 'rest/auth/auth.out';

import type { Props } from './social.setting';
import { Setting, stateToProps, dispatchToProps } from './social.setting';

@connect(stateToProps, dispatchToProps)
export class SocialAuthRouting extends Setting {

  componentDidMount () {
    this.setApplyJobOfferLink();
    const urlData: AuthOutDto = qs.parse(this.props.locationSearch, { ignoreQueryPrefix: true });
    const auth: AuthOutDto = new AuthOutDto(urlData);
    auth.code = decodeURIComponent(auth.code);
    this.props.trySignIn(auth);
  }

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.dataSuccess !== this.state.dataSuccess) {
      this.setState({ dataSuccess: nextProps.dataSuccess });
    }
  }

  setApplyJobOfferLink () {
    const jobOfferLink: string = localStorage.getItem('ej_active_job_offer_link');
    this.setState({ jobOfferLink: jobOfferLink || '' });
  }

  render () {
    return (
      <Fragment>
        {this.props.error && <Redirect to="/auth/candidate"/>}
        {this.state.dataSuccess && <Redirect to="/portal/candidate/account"/>}
        {(this.state.dataSuccess || this.props.tokenSuccess) && <Redirect to="/portal/candidate/account"/>}
        <Spinner show/>
      </Fragment>
    );
  }

}
