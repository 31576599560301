import React, { Component } from 'react';

import { Trans, TransProps } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { AuthBody, AuthFooter } from 'containers/auth/layouts';
import { ResetPasswordForm } from 'containers/auth/reset';
import { Link } from 'react-router-dom';

type Props = {
  t: TransProps
}
@connectTranslation()
export class ResetPasswordPage extends Component<Props> {

  render () {
    return (
      <div className="reset-password-page">
        <AuthBody title={this.props.t('auth.reset.title')}>
          <ResetPasswordForm
            buttonType="recruiter"
            redirectUrl='/auth/recruiter/reset/init'/>
        </AuthBody>
        <AuthFooter>
          <span>
            <Trans i18nKey="auth.sign-up.footer.text">
              Already have an account?
            </Trans>
          </span>
          <Link
            className="sign-up-link"
            to="/auth/recruiter">
            <Trans i18nKey="auth.sign-up.footer.link">
              Sign In
            </Trans>
          </Link>
        </AuthFooter>
      </div>
    );
  }

}
