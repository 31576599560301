// @flow
import React from 'react';

import moment from 'moment';
import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Chips } from 'components/layouts';
import { ShowSalaryRange } from 'components/elements';

import { CandidateDto } from 'rest/candidates/candidate.dto';

import { Setting } from './balance-board-info.setting';

@connectTranslation()
export class CandidateBalanceBoardInfo extends Setting {

  getWorkExperienceDate (months: number = 0): ?string {
    const exactYear: number = Math.round(months / 12);
    if (exactYear > 0) {
      return exactYear > 1 ? `${exactYear} years` : `${exactYear} year`;
    }
    return null;
  }

  render () {
    const candidate: CandidateDto = this.props.candidate;

    return (
      <div className="candidate-balance-board-info">
        <h4 className="job-title">
          {
            candidate.candidateJobRequirements.jobTitles &&
            candidate.candidateJobRequirements.jobTitles.map(item => item.name).join(', ')
          }
        </h4>
        <div className="additional-info">
          <div className="branches">
            {
              candidate.candidateJobRequirements.branches &&
              candidate.candidateJobRequirements.branches.map((item, index) => (
                <Chips key={index}>{ item.name }</Chips>
              ))
            }
            {
              candidate.candidateJobRequirements.units &&
              candidate.candidateJobRequirements.units.map((item, index) => (
                <Chips key={index}>{ item.name }</Chips>
              ))
            }
            <Chips>
              <Trans i18nKey="portal.recruiter.candidates.profile.balance-board-info.updated">
                Updated
              </Trans>
              &nbsp;
              { moment(candidate.updatedDate).parseZone().fromNow() }
            </Chips>
          </div>
        </div>
        <ul className="job-summary-info">
          {
            (candidate.candidateAddress && this.props.restricted) &&
            <li>
              <b>
                <Trans i18nKey="portal.recruiter.candidates.profile.balance-board-info.location">
                  Location:
                </Trans>
                &nbsp;
              </b>
              <span>{candidate.candidateAddress.country}, {candidate.candidateAddress.city}</span>
            </li>
          }
          <li>
            <b>
              <Trans i18nKey="portal.recruiter.candidates.profile.balance-board-info.experience">
                Work Experience:
              </Trans>
              &nbsp;
            </b>
            <span>
              {this.getWorkExperienceDate(this.props.cv.workExperienceMonths) ||
              this.props.t('portal.recruiter.candidates.profile.balance-board-info.no-experience')}
            </span>
          </li>
          <li>
            <b>
              <Trans i18nKey="portal.recruiter.candidates.profile.balance-board-info.education">
                Education:
              </Trans>
              &nbsp;
            </b>
            <span>
              {this.props.cv.educationDegree ||
              this.props.t('portal.recruiter.candidates.profile.balance-board-info.no-education')}
            </span>
          </li>
          <li>
            <b>
              <Trans i18nKey="portal.recruiter.candidates.profile.balance-board-info.expectation">
                Salary Expectation:
              </Trans>
              &nbsp;
            </b>
            <ShowSalaryRange salaryRange={candidate.candidateJobRequirements.salary}/>
          </li>
          <li>
            <b>
              <Trans i18nKey="portal.recruiter.candidates.profile.balance-board-info.relocation">
                Relocation:
              </Trans>
              &nbsp;
            </b>
            {this.props.cv.readyToRelocation
              ? this.props.t('portal.recruiter.candidates.profile.balance-board-info.relocate.able')
              : this.props.t('portal.recruiter.candidates.profile.balance-board-info.relocate.not-able')}
          </li>
        </ul>
      </div>
    );
  }

}
