import { Component } from 'react';

import './modal-caption.scss';

type Props = {
  title: string,
  hideModal: () => void,
  transparent?: boolean
}

export class Setting extends Component<Props> {

  static defaultProps: Props = {
    title: '',
    hideModal: () => {},
    transparent: false
  }

}
