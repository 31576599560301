import { TeamMemberDto } from './team-member.dto';

export class InvitesMembersDto {

  invites: TeamMemberDto[];

  constructor (data?: InvitesMembersDto) {
    if (data) {
      this.invites = data.invites;
    }
  }

}
