import { Component } from 'react';

import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { getAllBranches } from 'services/portal/recruiter/account/companies/companies.actions';

import { getAllUnits } from 'services/portal/units/units.actions';
import { getAllJobTitles } from 'services/portal/job-titles/job-titles.actions';

import {
  clearJobsSearch,
  saveSearchJobsUnit,
  saveSearchJobsBranch,
  saveSearchJobsJobTitle,
  saveSearchJobsLocation,
  saveSearchJobsCloseToMe,
  saveSearchJobsWorkingTime,
  saveSearchJobsJobType,
  saveSearchJobsCompanyName,
  saveSearchJobsSalaryMaximum,
  saveSearchJobsSalaryMinimum,
  saveSearchJobsAvailableFrom,
  saveSearchJobsPeriodOfPublish
} from 'services/portal/candidate/jobs/search/search.actions';

import {
  initJobsFilter,
  clearJobsFilter
} from 'services/portal/candidate/jobs/job-offers/job-offers.actions';

import { searchSuggestedAddress } from 'services/portal/address/address.actions';

import type { SearchJobOffersType } from 'services/portal/candidate/jobs/search/search.type';

import { UnitDto } from 'rest/unit/unit.dto';
import { BranchDto } from 'rest/branch/branch.dto';
import { JobTitleDto } from 'rest/job-title/job-title.dto';
import { AddressResponseDto } from 'rest/address/address.response.dto';

import './default-filter.scss';
import './default-filter.responsive.scss';

export const jobTypes = [
  {
    id: 'Employee',
    name: 'Employee'
  },
  {
    id: 'Temporary',
    name: 'Part-Time'
  },
  {
    id: 'Trainee',
    name: 'Trainee'
  }
];

export const periodOfPublish = [
  {
    id: '24',
    name: '24 Hours'
  },
  {
    id: '48',
    name: '2 days'
  },
  {
    id: '168',
    name: '1 week'
  }
];

type Props = {
  t: TransProps,
  units: UnitDto[],
  visible: boolean,
  history: History,
  address?: AddressResponseDto[],
  branches: BranchDto[],
  jobTitles: JobTitleDto[],
  getAllUnits?: (query: UnitDto) => void,
  getAllBranches?: () => void,
  initJobsFilter?: () => void,
  clearJobsSearch?: () => void,
  clearJobsFilter?: () => void,
  searchJobOffers?: SearchJobOffersType,
  getAllJobTitles?: (query: JobTitleDto) => void,
  saveSearchJobsUnit?: (unitId: string) => void,
  saveSearchJobsBranch?: (branchId: string) => void,
  saveSearchJobsLocation?: (location: string) => void,
  searchSuggestedAddress?: (location: string) => void,
  saveSearchJobsJobTitle?: (jobTitleId: string) => void,
  saveSearchJobsCloseToMe?: (closeToMe: boolean) => void,
  saveSearchJobsCompanyName?: (companyName: string) => void,
  saveSearchJobsSalaryMinimum?: (minSalary: number) => void,
  saveSearchJobsSalaryMaximum?: (maxSalary: number) => void
};

export class Settings extends Component<Props> {

  static defaultProps = {
    visible: false
  };

}

export const stateToProps = (state: GlobalState) => ({
  units: state.portal.units.unitsList,
  address: state.portal.address.content,
  jobTitles: state.portal.jobTitles.jobTitlesList,
  branches: state.portal.recruiter.account.companies.branches,
  searchJobOffers: state.portal.candidate.jobs.searchJobOffers,
  jobOffersFilter: state.portal.candidate.jobs.jobOffers.jobOffersFilter
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getAllUnits,
  getAllBranches,
  getAllJobTitles,
  initJobsFilter,
  clearJobsSearch,
  clearJobsFilter,
  saveSearchJobsUnit,
  saveSearchJobsBranch,
  searchSuggestedAddress,
  saveSearchJobsCloseToMe,
  saveSearchJobsJobTitle,
  saveSearchJobsLocation,
  saveSearchJobsWorkingTime,
  saveSearchJobsJobType,
  saveSearchJobsCompanyName,
  saveSearchJobsSalaryMinimum,
  saveSearchJobsSalaryMaximum,
  saveSearchJobsAvailableFrom,
  saveSearchJobsPeriodOfPublish
}, dispatch);
