export class ChangePasswordDto {

  oldPassword: string;
  newPassword: string;

  constructor (data?: ChangePasswordDto) {
    if (data) {
      this.newPassword = data.newPassword;
      this.oldPassword = data.oldPassword;
    }
  }

}
