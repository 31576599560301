import React, { Children } from 'react';

import { Setting } from './control.setting';

export class PortletControl extends Setting {

  render () {
    return (
      <div className="portlet-control">
        {
          Children.map(this.props.children, (child, index) => (
            <div
              key={index}
              className="portlet-option">
              { child }
            </div>
          ))
        }
      </div>
    );
  }

}
