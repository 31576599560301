import React from 'react';

import cn from 'classnames';

import { Setting } from './page.setting';

export class Page extends Setting {

  render () {
    const pageClasses = cn({
      page: true,
      auto: this.props.autoHeight,
      'full-height': this.props.fullHeight,
      'mobile-portal-not-found': this.props.isMatchPortal,
      'mobile-guest-page': this.props.isGuestPage
    });

    return (
      <div className={pageClasses}>
        {this.props.children}
      </div>
    );
  }

}
