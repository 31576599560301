import React from 'react';
import { Link } from 'react-router-dom';

import qs from 'qs';
import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';

import { Card } from 'components/layouts';
import { Button } from 'components/buttons';

import { Footer } from 'containers/portal';

import { Props } from './success-payment.setting';
import { Settings, stateToProps, dispatchToProps } from './success-payment.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class SuccessPayment extends Settings {

  componentDidMount () {
    this.props.getCurrentCompanyRecruiter();
    this.props.deleteSavedJobOfferData();
    this.checkSubscriptionCanceled();
    this.setRedirectLink();
  }

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.currentUser !== this.props.currentUser) {
      this.props.activateCompanyRecruiter(nextProps.currentUser.id);
    }
  }

  checkSubscriptionCanceled () {
    const searchLocation = qs.parse(this.props.location.search.slice(1));
    if (searchLocation && searchLocation.authResult === 'CANCELLED') {
      this.props.history.push('/portal/recruiter/account');
    }
  }

  setRedirectLink () {
    const candidatePreviewLink: string = localStorage.getItem('ej_candidate_preview_link');
    this.setState({ redirectLink: candidatePreviewLink || '' });
  }

  render () {
    if (!this.props.activateRecruiterSuccess) {
      return null;
    }

    return (
      <div className="success-payment-container">
        <Card>
          <div className="success-payment-content">
            <h2>
              <Trans i18nKey="portal.recruiter.account.payment.premium.header">
                Congratulations!
              </Trans>
            </h2>
            <p className="success-text">
              <Trans i18nKey="portal.recruiter.account.payment.premium.title">
                Payment was successful
              </Trans>
            </p>
            <p className="payment-description">
              <Trans i18nKey="portal.recruiter.account.payment.premium.description">
                You can finish the process of creating a company profile and use all the benefits of our service.
              </Trans>
            </p>
            <div className="success-payment-action">
              <Link to={this.state.redirectLink || '/portal/recruiter/balance-board'}>
                <Button
                  className="accent start-btn">
                  <Trans i18nKey="portal.recruiter.account.payment.premium.button">
                    Let`s Start
                  </Trans>
                </Button>
              </Link>
              <div className="agreement">
                <Trans i18nKey="portal.recruiter.account.payment.premium.footer">
                  By continue you agree to ExcellentJobs <br/>
                  <Link to="/information/privacy-policy"> Privacy Policy </Link> and
                  <Link to="/information/privacy-policy"> Terms of Use </Link>
                </Trans>
              </div>
            </div>
          </div>
        </Card>
        <Footer className="success-payment-footer"/>
      </div>
    );
  }

}
