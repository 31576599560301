import * as actions from './apply.constants';

import { Action } from 'redux';

import { ApplicationDto } from 'rest/candidates/applications-template/applications-template.dto';

import { apply } from 'rest/job-offers/apply/apply.rest';

import { markJobOfferAsApplied } from 'services/portal/candidate/jobs/job-offers/job-offers.actions';

const applyToJobOfferRequest = (): Action => ({
  type: actions.APPLY_TO_JOB_OFFER_REQUEST
});

export function applyToJobOffer (candidateId: string, jobOfferId: string, data: ApplicationDto) {
  return dispatch => {
    dispatch(applyToJobOfferRequest());
    return apply(candidateId, jobOfferId, data)
      .then(() => dispatch(markJobOfferAsApplied()));
  };
}
