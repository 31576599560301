import axios, { AxiosPromise } from 'axios';

import { CandidatesBuyDto } from './candidates-buy.dto';
import { PaymentRedirectUrlsDto } from './../payment-redirect-urls.dto';

export async function buyCandidateDetailsRest (
  companyId: string,
  candidateId: string,
  redirectUrls: PaymentRedirectUrlsDto
): AxiosPromise<CandidatesBuyDto> {
  try {
    return await axios.post(`/companies/${companyId}/candidates/${candidateId}/buy`, redirectUrls);
  } catch (error) {
    throw error;
  }
}
