import * as actions from './portal-mobile-nav.constants';

import type { PortalMobileNavType } from './portal-mobile-nav.type';

const initialState: PortalMobileNavType = {
  showNav: false
};

export default (state = initialState, action): PortalMobileNavType => {
  switch (action.type) {
    case actions.SHOW_HIDE_MOBILE_NAV:
      return {
        ...state,
        showNav: action.portalNavShow
      };

    default:
      return state;
  }
};
