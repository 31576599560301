// @flow
import { Component } from 'react';

import { TransProps } from 'react-i18next';

import './empty-jobs-list.scss';
import './empty-jobs-list.responsive.scss';

type Props = {
  t: TransProps,
  history: History
}

export class Setting extends Component<Props> {}
