// @flow
import React from 'react';

import { connectStore } from 'middleware/connect';

import { Video } from 'components/video';

import { Modal } from 'containers/portal/modal-container';

import { Props, Setting, stateToProps, dispatchToProps } from './on-boarding-modal.setting';

@connectStore(stateToProps, dispatchToProps)
export class OnBoardingModal extends Setting {

  componentDidMount () {
    this.setState({
      open: this.props.showRecruiterVideo || this.props.showCandidateVideo
    });
  }

  componentWillReceiveProps (nextProps: Props) {
    this.setState({
      open: nextProps.showRecruiterVideo || nextProps.showCandidateVideo
    });
  }

  handleOnBoardingModal = () => {
    const { isRecruiter, disableRecruiterVideo, disableCandidateVideo } = this.props;

    this.setState({
      open: false
    });
    isRecruiter ? disableRecruiterVideo() : disableCandidateVideo();
  };

  render () {
    return (
      <Modal
        className="on-boarding-modal"
        overlay
        animation="fade"
        open= {this.state.open}
        hideModalAvailable={false}
        hideModal={this.handleOnBoardingModal}
        closeOnOverlay={this.handleOnBoardingModal}>
        {/* <ModalCaption
          transparent
          hideModal={this.handleOnBoardingModal}/> */}
        <Video
          onBoarding
          onBoardingVideo= {true}
          recruiter={this.props.isRecruiter}/>
      </Modal>
    );
  }

}
