import React from 'react';
import './list.scss';

import { InvoiceAddressListItem } from '../index';

type Props = {
  children: React.node
};

export const InvoiceAddressList = (props: Props) => {
  const { children } = props;

  return (
    <ul className="invoice-address-list">
      {children.map((item, index) => (
        <InvoiceAddressListItem
          key={index}
          data={item.props}
        />
      ))}
    </ul>
  );
};
