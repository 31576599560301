// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { CandidateDto } from 'rest/candidates/candidate.dto';
import { SkillsContainerDto } from 'rest/skills/skills-container.dto';
import { GuestApplyDto } from 'rest/candidates/skills/guest-apply.dto';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';
import { JobRequirementsResponseDto } from 'rest/candidates/job-requirements/job-requirements.response.dto';

import {
  getCandidateSkillsById,
  updateCandidateSkillsById,
  saveGuestInteractionSkills
} from 'services/portal/candidate/skills/skills.actions';
import { checkJobOfferMatchSkills } from 'services/portal/recruiter/job-offer/job-offer.actions';

import {
  getCandidateBalanceBoard,
  createCandidateBalanceBoard
} from 'services/portal/candidate/balance-board/balance-board.actions';

import './interact-skills-list.scss';
import './interact-skills-list.responsive.scss';

export type Props = {
  t: TransProps,
  jobOfferPath: string,
  cancelAction: () => void,
  jobOffer: JobOffersResponseDto,
  currentCandidate?: CandidateDto,
  jobOfferSkills: SkillsContainerDto,
  candidateSkills?: SkillsContainerDto,
  balanceBoard: JobRequirementsResponseDto,
  getCandidateSkillsById?: (candidateId: string) => void,
  getCandidateBalanceBoard?: (candidateId: string, guestApply: boolean) => void,
  saveGuestInteractionSkills?: (params: GuestApplyDto) => void,
  updateCandidateSkillsById: (candidateId: string, data: SkillsContainerDto) => void,
  createCandidateBalanceBoard?: (candidateId: string, data: JobRequirementsResponseDto) => void,
  className?: string
};

type State = {
  activeIndex: number,
  currentSkillType: string,
  candidateSkills: SkillsContainerDto,
  jobOfferSkills: SkillsContainerDto,
}

export class Setting extends Component<Props, State> {

  static defaultProps: Props = {
    cancelAction: () => {},
    getCandidateSkillsById: () => {},
    currentCandidate: new CandidateDto(),
    updateCandidateSkillsById: () => {},
    className: ''
  };

  state: State= {
    activeIndex: 0,
    currentSkillType: '',
    jobOfferSkills: new SkillsContainerDto(),
    candidateSkills: new SkillsContainerDto()
  };

}

export const stateToProps = (state: GlobalState) => ({
  candidateSkills: state.portal.candidate.skills.content,
  balanceBoard: state.portal.candidate.balanceBoard.balanceBoard,
  currentCandidate: state.portal.candidate.account.current.candidateData
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getCandidateSkillsById,
  getCandidateBalanceBoard,
  checkJobOfferMatchSkills,
  createCandidateBalanceBoard,
  saveGuestInteractionSkills,
  updateCandidateSkillsById
}, dispatch);
