import { SkillsContainerDto } from 'rest/skills/skills-container.dto';

import { SalaryRangeDto } from './job-offers.salary-range.dto';

export class JobOfferDto {

  id: number;
  unitId: string;
  jobType: string;
  branchId: string;
  companyId: string;
  jobOfferId: string;
  jobTitleId: string;
  recruiterId: string;
  availableFrom: string;
  jobDescription: string;
  referenceNumber: string;
  workingHoursPerMonth: number;
  salaryRange: SalaryRangeDto = new SalaryRangeDto();
  jobOfferSkills: SkillsContainerDto = new SkillsContainerDto();

  constructor (data?: JobOfferDto) {
    if (data) {
      this.id = data.id;
      this.unitId = data.unitId;
      this.jobType = data.jobType;
      this.branchId = data.branchId;
      this.companyId = data.companyId;
      this.jobOfferId = data.jobOfferId;
      this.jobTitleId = data.jobTitleId;
      this.recruiterId = data.recruiterId;
      this.availableFrom = data.availableFrom;
      this.jobDescription = data.jobDescription;
      this.referenceNumber = data.referenceNumber;
      this.workingHoursPerMonth = data.workingHoursPerMonth;
      this.salaryRange = new SalaryRangeDto(data.salaryRange);
      this.jobOfferSkills = new SkillsContainerDto(data.jobOfferSkills);
    }
  }

}
