// @flow
import React from 'react';

import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { Card } from 'components/layouts';
import { Modal } from 'components/dialogs';
import { Button, LinkButton } from 'components/buttons';
import { FormGroup, InputControl } from 'components/controls';

import { ApplicationDto } from 'rest/candidates/applications-template/applications-template.dto';
import { ApplicationResponseDto } from 'rest/candidates/applications-template/applications-template.response.dto';

import type { Props } from './edit-application.setting';
import { dispatchToProps, Settings, stateToProps } from './edit-application.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class EditApplicationModal extends Settings {

  componentDidMount () {
    const candidateId: string = this.props.currentCandidate.id;
    this.props.getCandidateApplicationById(candidateId, this.props.match.params.templateId);
  }

  componentWillReceiveProps (nextProps: Props) {
    const currentTemplate: ApplicationResponseDto = nextProps.currentTemplate;
    if (currentTemplate && currentTemplate.subject !== this.state.subject) {
      this.setState({ ...currentTemplate });
    }
  }

  updateApplication (event: Event) {
    event.preventDefault();
    const data: ApplicationDto = new ApplicationDto(this.state);
    const candidateId: string = this.props.currentCandidate.id;
    this.props.updateCandidateApplication(candidateId, this.props.match.params.templateId, data);
    this.props.history.push('/portal/candidate/applications/templates');
    toast.success(this.props.t('portal.candidate.notifications.application-updated'));
  }

  render () {
    const backLink: string = '/portal/candidate/applications/templates';

    return (
      <Modal closePath={backLink}>
        <div className="create-application-modal create-application-modal-mobile">
          <PerfectScrollbar className="content">
            <Card className="mobile-application-modal">
              <div className="mobile-application-modal-title">
                <Trans i18nKey="portal.candidate.applications.mobile.edit">
                  Application Edit
                </Trans>
              </div>
              <LinkButton
                className="application-back-link"
                link={backLink}
                inline>
                <Trans i18nKey="portal.candidate.applications.edit.back">
                  Back to Application List
                </Trans>
                <div className="modal-application-back">&times;</div>
              </LinkButton>
              <form
                onSubmit={this.updateApplication.bind(this)}
                className="create-application-form">
                <div className="form-inputs">
                  <FormGroup className="half-width application-title">
                    <InputControl
                      required
                      value={this.state.subject}
                      change={(value: string): void => this.setState({ subject: value })}
                      label={this.props.t('portal.candidate.applications.edit.name.label')}
                      pattern=".+"
                      maxLength={70}
                      placeholder={this.props.t('portal.candidate.applications.edit.name.placeholder')}
                      type="text"/>
                  </FormGroup>
                  <FormGroup className="full-width">
                    <InputControl
                      value={this.state.body}
                      required
                      pattern=".+"
                      change={(value: string): void => this.setState({ body: value })}
                      label={this.props.t('portal.candidate.applications.edit.description.label')}
                      placeholder={this.props.t('portal.candidate.applications.edit.description.placeholder')}
                      multiline
                      maxLength={1000}
                      notes={`${this.state.body.length} / 1000`}
                    />
                  </FormGroup>
                </div>
                <FormGroup className="form-btn-submit mobile-form-submit">
                  <Button
                    medium
                    candidate
                    type="submit">
                    <Trans i18nKey="portal.candidate.applications.edit.buttons.update">
                      Update
                    </Trans>
                  </Button>
                  <LinkButton
                    grey
                    className="mobile-application-form-cancel"
                    link={backLink}>
                    <Trans i18nKey="portal.candidate.applications.edit.buttons.cancel">
                      Cancel
                    </Trans>
                  </LinkButton>
                </FormGroup>
              </form>
            </Card>
          </PerfectScrollbar>
        </div>
      </Modal>
    );
  }

}
