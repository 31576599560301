// @flow
import React, { Fragment } from 'react';

import moment from 'moment';
import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';
import { PaginationDto } from 'middleware/dto/pagination';

import { JobOfferDto } from 'rest/job-offers/job-offers.dto';
import { SalaryRangeDto } from 'rest/job-offers/job-offers.salary-range.dto';

import { Button, LinkButton } from 'components/buttons';
import { Spinner, SalaryRange } from 'components/elements';

import { DateControl, FormGroup, InputControl, MultiSelect, SwitchControl } from 'components/controls';

import type { Props } from './general-info.setting';
import { Settings, stateToProps, dispatchToProps } from './general-info.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class GeneralInfoTab extends Settings {

  componentDidMount () {
    this.fetchJobTitles();
    this.props.getAllUnits();
    this.props.getCompanyJobOfferById(this.props.jobOfferId);
  }

  componentWillReceiveProps (nextProps: Props) {
    if (this.props.currentJobOffer !== nextProps.currentJobOffer) {
      const jobOffer = nextProps.currentJobOffer;
      this.setState({
        ...jobOffer,
        workingHoursPerMonth: jobOffer.salaryRange ? jobOffer.salaryRange.workingHoursPerMonth : 40
      });
    }
  }

  fetchJobTitles (unitId: string = '') {
    const query: PaginationDto = new PaginationDto();
    query.size = 500;
    query.filterUnitIds = [ unitId ];
    this.setState({ jobTitleId: null });
    this.props.getAllJobTitles(query);
  }

  validate (cb: (isValid: boolean) => void) {
    this.setState({
      unitError: !this.state.unitId,
      jobTitleError: !this.state.jobTitleId
    }, () => {
      const isUnitOrJobTitleError: boolean = !this.state.unitError && !this.state.jobTitleError;
      cb(isUnitOrJobTitleError && !this.state.salaryError);
    });
  }

  saveGeneralInfo () {
    const generalData: JobOfferDto = new JobOfferDto(this.state);
    generalData.branchId = this.props.company.branchId;
    generalData.recruiterId = this.props.currentUser.id;
    generalData.companyId = this.props.currentUser.companyId;
    generalData.jobType = this.state.jobType.id || this.state.jobType;
    if (this.props.type === 're-use' && this.props.initialJobOfferId === this.props.currentJobOffer.id) {
      this.props.createNewJobOffer(generalData)
        .then(id => {
          this.props.next();
          this.props.history.push(`/portal/recruiter/balance-board/${id}/re-use`);
        });
    } else {
      this.props.updateCompanyJobOffer(this.props.currentJobOffer.id, generalData)
        .then(() => this.props.next());
    }
    this.props.saveCurrentJobTitle(generalData.jobTitleId);
  }

  saveJobOffer (event: Event) {
    event.preventDefault();
    this.validate(valid => {
      if (valid) {
        this.saveGeneralInfo();
      }
    });
  }

  render () {
    return (
      <Fragment>
        <form
          onSubmit={this.saveJobOffer.bind(this)}
          className="general-info-form">
          <FormGroup className="half-width">
            <MultiSelect
              label={this.props.t('portal.recruiter.balance-board.job-offer.general.unit.label')}
              items={this.props.units}
              defaultValue={this.state.unitId}
              error={this.state.unitError}
              change={value => this.setState({
                unitError: false,
                unitId: value.id,
                jobTitleId: ''
              }, this.fetchJobTitles(value.id))}
              isMulti={false}
              placeholder={this.props.t('portal.recruiter.balance-board.job-offer.general.unit.placeholder')}/>
          </FormGroup>
          <FormGroup className="half-width">
            <MultiSelect
              label={this.props.t('portal.recruiter.balance-board.job-offer.general.job-title.label')}
              error={this.state.jobTitleError}
              items={this.props.jobTitles}
              defaultValue={this.state.jobTitleId}
              change={value => this.setState({
                jobTitleError: false,
                jobTitleId: value.id
              })}
              isMulti={false}
              placeholder={this.props.t('portal.recruiter.balance-board.job-offer.general.job-title.placeholder')}/>
          </FormGroup>
          <FormGroup className="half-width reference-number">
            <InputControl
              maxLength={100}
              placeholder={this.props.t('portal.recruiter.balance-board.job-offer.general.reference.placeholder')}
              value={this.state.referenceNumber}
              label={this.props.t('portal.recruiter.balance-board.job-offer.general.reference.label')}
              change={(referenceNumber: string): void => this.setState({ referenceNumber })}
              type="text"/>
          </FormGroup>
          <FormGroup className="full-width">
            <InputControl
              multiline
              required
              maxLength={1000}
              label={this.props.t('portal.recruiter.balance-board.job-offer.general.job-description.label')}
              value={this.state.jobDescription}
              change={(jobDescription: string): void => this.setState({ jobDescription })}
              placeholder={this.props.t('portal.recruiter.balance-board.job-offer.general.job-description.placeholder')}
              type="text"/>
          </FormGroup>
          <FormGroup className="full-width">
            <SwitchControl
              label={this.props.t('portal.recruiter.balance-board.job-offer.general.job-type.label')}
              value={(this.state.jobType && this.state.jobType.id) || this.state.jobType}
              change={(jobType: string) => this.setState({ jobType })}
              items={[
                this.props.t('portal.recruiter.balance-board.job-offer.general.job-type.items.0'),
                this.props.t('portal.recruiter.balance-board.job-offer.general.job-type.items.1'),
                this.props.t('portal.recruiter.balance-board.job-offer.general.job-type.items.2')
              ]}/>
          </FormGroup>
          <SalaryRange
            defaultValue={this.state.salaryRange}
            change={(salary: SalaryRangeDto, error: boolean) =>
              this.setState({
                salaryRange: salary,
                salaryError: error
              })}
            className="max-width"/>
          <div className="working-time">
            <FormGroup className="working-time-field-width">
              <InputControl
                required
                maxLength={4}
                minLength={1}
                pattern="^\d*(\.|,)?\d{1}$"
                errorText={this.props.t('portal.recruiter.candidates.filters.default.working-time.error')}
                placeholder={this.props.t('portal.recruiter.balance-board.job-offer.general.working-time.placeholder')}
                label={this.props.t('portal.recruiter.balance-board.job-offer.general.working-time.label')}
                value={this.state.workingHoursPerMonth}
                change={(workingHoursPerMonth: string): void => this.setState({ workingHoursPerMonth })}
                type="text"/>
            </FormGroup>
            <FormGroup className="working-time-field-width">
              <DateControl
                required
                recruiter
                value={this.state.availableFrom}
                change={(availableFrom: moment): void => this.setState({ availableFrom })}
                label={this.props.t('portal.recruiter.balance-board.job-offer.general.available-from')}/>
            </FormGroup>
          </div>
          <FormGroup className="full-width form-buttons">
            <LinkButton
              link="/portal/recruiter/job-offers"
              grey>
              <Trans i18nKey="portal.recruiter.balance-board.job-offer.buttons.cancel">
                Cancel
              </Trans>
            </LinkButton>
            <Button
              type="submit"
              medium
              recruiter>
              Next and Save
            </Button>
          </FormGroup>
        </form>
        <Spinner show={this.props.fetching}/>
      </Fragment>
    );
  }

}
