// @flow
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { TransProps } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { PortletContainer, PortletHeader, PortletBody } from 'components/portlet';

import { BalanceBoardPoster } from 'containers/portal/candidate/balance-board';

import './balance-board.responsive.scss';

type Props = {
  t: TransProps
}

@connectTranslation()
export class BalanceBoardListPage extends Component<Props> {

  render () {
    return (
      <PortletContainer>
        <PortletHeader
          title={this.props.t('portal.candidate.balance-board.list.title')}
          className="balance-board-mobile-title"/>
        <PortletBody className="mobile-portlet-poster">
          <Route
            path="/portal/candidate/balance-board"
            component={BalanceBoardPoster}/>
        </PortletBody>
      </PortletContainer>
    );
  }

}
