// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import type { Match } from 'common/types/match';

import type { GlobalState } from 'common/types/global-state';

import { ImageResponseDto } from 'rest/images/images.response';
import { RecruiterDto } from 'rest/recruiter/recruiter.dto';

import { uploadFile } from 'services/portal/images/images.actions';
import { recruiterSignUp } from 'services/auth/recruiter/sign-up/sign-up.actions';

import './invite.scss';

export type Props = {
  match: Match,
  redirect: boolean,
  fetching: boolean,
  image: ImageResponseDto,
  imageFetching: boolean,
  uploadFile: (file: ?File, type: string) => void,
  recruiterSignUp: (recruiter: RecruiterDto) => void
}

type State = {
  avatar: ?File,
  firstName: string,
  password: string,
  lastName: string,
  phoneNumber: string,
  avatarError: ?string,
  confirmPassword: string,
  avatarPreview: ?string,
  errorCollection: Set<string>
}

export class Settings extends Component<Props, State> {

  static defaultProps = {
    redirect: false,
    recruiterSignUp: () => {}
  };

  state: State = {
    avatar: null,
    lastName: '',
    firstName: '',
    phoneNumber: '',
    password: '',
    avatarError: '',
    avatarPreview: '',
    confirmPassword: '',
    errorCollection: new Set()
  };

}

export const stateToProps = (state: GlobalState) => ({
  image: state.portal.images.avatarInfo,
  imageFetching: state.portal.images.avatarLoading,
  redirect: state.auth.recruiter.signUp.signUpSuccess,
  fetching: state.portal.recruiter.account.current.fetching
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  recruiterSignUp,
  uploadFile
}, dispatch);
