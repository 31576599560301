import { ImageDto } from 'rest/images/image.dto';

export class PostsResponseDto {

  id: number;
  article: string;
  likesCount: number;
  createDate: string;
  candidateId: string;
  postPhotos: ImageDto[];

  constructor (data?: PostsResponseDto) {
    if (data) {
      this.id = data.id;
      this.article = data.article;
      this.likesCount = data.likesCount;
      this.postPhotos = data.postPhotos;
      this.createDate = data.createDate;
      this.candidateId = data.candidateId;
    }
  }

}
