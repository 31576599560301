import { ImageDto } from 'rest/images/image.dto';
import { UserAddressDto } from 'rest/address-dto/address';

export class CalendarResponseDto {

  photo: ImageDto;
  inviteId: string;
  lastName: string;
  firstName: string;
  candidateId: string;
  updatedDate: string;
  jobTitleName: string;
  candidateAddress: UserAddressDto;
  candidateInviteStatus: string;

  constructor (data?: CalendarResponseDto) {
    if (data) {
      this.photo = new ImageDto(data.photo);
      this.inviteId = data.inviteId;
      this.lastName = data.lastName;
      this.firstName = data.firstName;
      this.candidateId = data.candidateId;
      this.updatedDate = data.updatedDate;
      this.jobTitleName = data.jobTitleName;
      this.candidateAddress = new UserAddressDto(data.candidateAddress);
      this.candidateInviteStatus = data.candidateInviteStatus;
    }
  }

  get primaryPhotoUrl (): string {
    return (this.photo && this.photo.url) ? this.photo.url : '/images/shared/userpick.jpg';
  }

  get fullName (): string {
    return this.firstName && this.lastName ? `${this.firstName} ${this.lastName}` : null;
  }

}
