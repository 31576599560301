export class NotificationSettingDto {

  matchJobOffer: boolean;
  newMessageChat: boolean;
  updateFollowing: boolean;

  constructor (data?: NotificationSettingDto) {
    if (data) {
      this.matchJobOffer = data.matchJobOffer;
      this.newMessageChat = data.newMessageChat;
      this.updateFollowing = data.updateFollowing;
    }
  }

}
