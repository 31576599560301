// @flow
import { Component } from 'react';

import { TransProps } from 'react-i18next';

import './payment-options.scss';

export type Props = {
  t?: TransProps,
  disabled: boolean,
  defaultOption: string,
  changeOptions: () => void
}

export class Settings extends Component<Props> {

  static defaultProps = {
    disabled: false,
    defaultOption: '',
    changeOptions: () => {}
  };

}
