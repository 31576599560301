import * as actions from './general.constants';

import { JobOfferDto } from 'rest/job-offers/job-offers.dto';
import { JobOfferStatus } from 'rest/job-offers/job-offers.status.dto';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

import {
  deleteJobOffer,
  getJobOfferById,
  updateJobOffer,
  updateCompanyJobOfferStatus
} from 'rest/job-offers/job-offers.rest';
import { getAllCompanyJobOffers } from 'services/portal/recruiter/account/job-offer/job-offer.actions';

const deleteJobOfferRequest = () => ({
  type: actions.DELETE_JOB_OFFER_REQUEST
});

const deleteJobOfferSuccess = (jobOfferId: string) => ({
  type: actions.DELETE_JOB_OFFER_SUCCESS,
  jobOfferId
});

const deleteJobOfferFailure = (error: string) => ({
  type: actions.DELETE_JOB_OFFER_FAILURE,
  error
});

const changeJobOfferStatusRequest = () => ({
  type: actions.CHANGE_JOB_OFFER_STATUS_REQUEST
});

const changeJobOfferStatusSuccess = (jobOfferId: string, jobOfferStatus: JobOfferStatus) => ({
  type: actions.CHANGE_JOB_OFFER_STATUS_SUCCESS,
  jobOfferStatus,
  jobOfferId
});

const changeJobOfferStatusFailure = (error: string) => ({
  type: actions.CHANGE_JOB_OFFER_STATUS_FAILURE,
  error
});

const getJobOfferByIdRequest = () => ({
  type: actions.GET_JOB_OFFER_BY_ID_REQUEST
});

export const getJobOfferByIdSuccess = (jobOffer: JobOffersResponseDto) => ({
  type: actions.GET_JOB_OFFER_BY_ID_SUCCESS,
  jobOffer
});

const getJobOfferByIdFailure = (error: string) => ({
  type: actions.GET_JOB_OFFER_BY_ID_FAILURE,
  error
});

const updateJobOfferRequest = () => ({
  type: actions.UPDATE_JOB_OFFER_REQUEST
});

const updateJobOfferSuccess = (jobOfferId: string, data: JobOfferDto) => ({
  type: actions.UPDATE_JOB_OFFER_SUCCESS,
  jobOfferId,
  data
});

const resetCurrentJobOfferSuccess = () => ({
  type: actions.RESET_CURRENT_JOB_OFFER
});

const updateJobOfferFailure = (error: string) => ({
  type: actions.UPDATE_JOB_OFFER_FAILURE,
  error
});

export const resetCurrentJobOffer = () => {
  return dispatch => dispatch(resetCurrentJobOfferSuccess());
};

export const getCompanyJobOfferById = (jobOfferId: string) => {
  return dispatch => {
    dispatch(getJobOfferByIdRequest());
    return getJobOfferById(jobOfferId)
      .then(response => dispatch(getJobOfferByIdSuccess(response.data)))
      .catch(error => dispatch(getJobOfferByIdFailure(error)));
  };
};

export const updateCompanyJobOffer = (jobOfferId: string, data: JobOfferDto) => {
  return dispatch => {
    dispatch(updateJobOfferRequest());
    return updateJobOffer(jobOfferId, data)
      .then(() => dispatch(updateJobOfferSuccess(jobOfferId, data)))
      .catch(error => dispatch(updateJobOfferFailure(error)));
  };
};

export const deleteCompanyJobOffer = (jobOfferId: string, companyId: string) => {
  return dispatch => {
    dispatch(deleteJobOfferRequest());
    return deleteJobOffer(jobOfferId)
      .then(() => {
        dispatch(getAllCompanyJobOffers(companyId));
        return dispatch(deleteJobOfferSuccess(jobOfferId));
      })
      .catch(error => dispatch(deleteJobOfferFailure(error)));
  };
};

export const updateJobOfferStatus = (companyId: string, jobOfferId: string, status: JobOfferStatus) => {
  return dispatch => {
    dispatch(changeJobOfferStatusRequest());
    return updateCompanyJobOfferStatus(jobOfferId, status)
      .then(() => {
        dispatch(changeJobOfferStatusSuccess());
        dispatch(getAllCompanyJobOffers(companyId));
      })
      .catch(error => dispatch(changeJobOfferStatusFailure(error)));
  };
};
