// @flow
import React from 'react';
import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';
import { getFormattedHours } from 'middleware/hours-peroid';

import { LinkButton } from 'components/buttons';

import { Settings, stateToProps } from './lifestyle-section.setting';

@connectStore(stateToProps, null, true)
export class LifeStyleSection extends Settings {

  render () {
    return (
      <div className="competencies-section-container mobile-competencies-section-container">
        {
          this.props.activities.length > 0 ? this.props.activities.map((item, index: number) => (
            <div
              key={index}
              className="competencies-item">
              {item.activity}:
              <span className="competencies-item-points">{getFormattedHours(item.points)}</span>
            </div>
          )) : <div className="empty-section">
            <h5 className="empty-section-title">
              <Trans i18nKey="portal.candidate.cv.list.day-of-life.empty">
                Day of My Life panel is empty
              </Trans>
            </h5>
            <p className="empty-section-description">
              <Trans i18nKey="portal.candidate.cv.list.day-of-life.text">
                Fill out information about your daily life
              </Trans>
            </p>
            <LinkButton
              outlined
              link="/portal/candidate/cv/daily"
              candidate>
              <Trans i18nKey="portal.candidate.cv.list.day-of-life.add">
                Add Info
              </Trans>
            </LinkButton>
          </div>
        }
      </div>
    );
  }

}
