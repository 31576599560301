// @flow
import React, { Fragment } from 'react';
import type { ReactElement } from 'react';

import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroller';

import { connectStore } from 'middleware/connect';

import { Spinner } from 'components/elements';
import { ElementsList, JobOfferRow } from 'components/elements-list';

import { EmptyJobsSearchList } from './empty-jobs-list/empty-jobs-list';

import { JobOfferDto } from 'rest/job-offers/job-offers.dto';
import { JobOffersParams } from 'rest/public/job-offers/job-offers.params';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

import { stateToProps, dispatchToProps, Setting } from './jobs-list.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class JobsList extends Setting {

  componentWillUnmount () {
    this.props.resetJobOffersList();
  }

  loadJobOffersList (page: number = 0) {
    const params = new JobOffersParams(this.props.jobOffersFilter);
    if (typeof this.props.history.location.state !== 'undefined' && this.props.history.location.state.company) {
      params.company = this.props.history.location.state.company;
    }
    params.page = page;
    params.size = 10;
    params.extraFields = ['favorite'];
    this.props.getJobOffers(params);
  }

  toggleFavorite (jobOffer: JobOffersResponseDto) {
    if (jobOffer.favorite) {
      this.props.deleteJobFromFavorite(jobOffer.jobOfferId, this.props.currentCandidateId);
      toast.success(this.props.t('portal.candidate.notifications.following-delete'));
    } else {
      this.props.addJobToFavorite(jobOffer.jobOfferId, this.props.currentCandidateId);
      toast.success(this.props.t('portal.candidate.notifications.following-add'));
    }
  }

  openJobOffer (selectedJobOfferId: string) {
    this.props.history.push(`/portal/candidate/jobs/${selectedJobOfferId}/preview`);
  }

  initJobOffersList (): ReactElement[] {
    return this.props.jobOffers.map((jobOffer: JobOfferDto, index) => (
      <JobOfferRow
        key={index}
        action={() => this.toggleFavorite(jobOffer)}
        onClick={() => this.openJobOffer(jobOffer.jobOfferId)}
        jobOffer={jobOffer}>
      </JobOfferRow>
    ));
  }

  initEmptyList (): ReactElement {
    return !this.props.jobOffers.length ? (
      <div className="empty-placeholder">
        <Trans i18nKey="portal.candidate.job-offers.list.empty">
          Jobs list is empty
        </Trans>
      </div>
    ) : null;
  }

  render () {
    return (
      <Fragment>
        {
          this.props.currentCandidateId && this.props.jobOffers && <InfiniteScroll
            useWindow={false}
            threshold={100}
            pagetStart={0}
            hasMore={this.props.hasMoreItems}
            loadMore={() => this.loadJobOffersList(this.props.currentPage)}>
            <ElementsList>{this.initJobOffersList()}</ElementsList>
          </InfiniteScroll>
        }
        {
          !this.props.jobOffers && !this.props.jobOffersFilter &&
          <div className="empty-placeholder">
            <Trans i18nKey="portal.candidate.job-offers.list.empty">
              Jobs list is empty
            </Trans>
          </div>
        }
        {
          this.props.jobOffersFilter
            ? this.initEmptyList()
            : (this.props.jobOffers && this.props.jobOffers.length < 1) &&
            <EmptyJobsSearchList history={this.props.history}/>
        }
        <Spinner show={this.props.fetching}/>
      </Fragment>
    );
  }

}
