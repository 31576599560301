import { Component } from 'react';

import './progress.scss';

type Props = {
  className: string,
  percent: number
}

export class ProgressSetting extends Component<Props> {

  static defaulpProps = {
    className: '',
    percent: 30
  }

}
