import React from 'react';

import { Settings } from './container.setting';

export class Container extends Settings {

  render () {
    return (
      <div className={this.props.className ? `${this.props.className} container` : 'container'}>
        { this.props.children }
      </div>
    );
  }

}
