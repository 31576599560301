import * as actions from './stats.constants';

import { Action } from 'redux';

import { SentApplicationsStatsDto } from 'rest/candidates/applications/applications.stats.dto';

import { getApplicationsStats } from 'rest/candidates/applications-template/applications-template.rest';
import { getSentApplicationsStats } from 'rest/candidates/applications/applications.rest';

const getSentApplicationsStatsSuccess = (stats: SentApplicationsStatsDto): Action => ({
  type: actions.GET_SENT_TEMPLATES_STATS_SUCCESS,
  content: stats
});

const getApplicationsStatsSuccess = (stats: SentApplicationsStatsDto): Action => ({
  type: actions.GET_TEMPLATES_STATS_SUCCESS,
  content: stats
});

const resetApplicationsStatsSuccess = (): Action => ({
  type: actions.RESET_APPLICATION_STATS_SUCCESS
});

export function resetApplicationsStatistics () {
  return dispatch => dispatch(resetApplicationsStatsSuccess());
}

export function getSentApplicationsStatistics (candidateId: string) {
  return dispatch => {
    return getSentApplicationsStats(candidateId)
      .then(response => dispatch(getSentApplicationsStatsSuccess(response.data)));
  };
}

export function getApplicationsStatistics (candidateId: string) {
  return dispatch => {
    return getApplicationsStats(candidateId)
      .then(response => dispatch(getApplicationsStatsSuccess(response.data)));
  };
}
