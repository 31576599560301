export class AskQuestionResponseDto {

  objectId: string;

  constructor (data?: AskQuestionResponseDto) {
    if (data) {
      this.objectId = data.objectId;
    }
  }

}
