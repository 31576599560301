import { Component } from 'react';
import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { clearCandidatesFilter } from 'services/portal/recruiter/candidate/all/candidates.actions';
import {
  clearCandidatesSearch,
  clearCandidatesSearchSkills
} from 'services/portal/recruiter/candidate/search/search.actions';

import './active-filter.scss';

export class Settings extends Component {}

export const stateToProps = (state: GlobalState) => ({
  candidatesFilter: state.portal.recruiter.candidate.allCandidates.candidatesFilter
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  clearCandidatesFilter,
  clearCandidatesSearch,
  clearCandidatesSearchSkills
}, dispatch);
