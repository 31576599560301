// @flow
import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { TransProps } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { PortletBody, PortletContainer, PortletHeader } from 'components/portlet';

import { FavoritesCandidate, PurchasedCandidate } from 'containers/portal/recruiter/candidates';

import { ProfilePage } from './profile';

type Props = {
  t: TransProps
}

@connectTranslation()
export class FavoritesPage extends Component<Props> {

  render () {
    return (
      <PortletContainer>
        <PortletHeader title={this.props.t('portal.recruiter.favorites.list.title')}/>
        <PortletBody>
          <Switch>
            <Redirect
              exact
              from="/portal/recruiter/favorites"
              to="/portal/recruiter/favorites/unit"/>
          </Switch>
          <Route
            path="/portal/recruiter/favorites/unit"
            component={FavoritesCandidate}/>
          <Route
            path="/portal/recruiter/favorites/job-title"
            component={FavoritesCandidate}/>
          <Route
            path="/portal/recruiter/favorites/purchased"
            component={PurchasedCandidate}/>
          <Route
            path="/portal/recruiter/favorites/:url/:id/profile"
            component={ProfilePage}/>
        </PortletBody>
      </PortletContainer>
    );
  }

}
