import React from 'react';

import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Setting } from './admin.setting';

@connectTranslation()
export class AdminTemplate extends Setting {

  findGermanLocale () {
    if (this.props.eventData.length && this.props.eventData[3]) {
      const locale = this.props.eventData[3].value.find(item => item.locale === 'GE');
      return locale && locale.message;
    }
  }

  initEnglishMessage () {
    return this.props.eventData.length && this.props.eventData[1]
      ? this.props.eventData[1].value
      : this.props.t('portal.notifications.admin.empty');
  }

  initGermanMessage () {
    return this.findGermanLocale() || this.initEnglishMessage();
  }

  render () {
    return (
      <div className="notification-item-content flexible">
        <div className="logo-container">
          <img src="/images/notifications/userpick.svg" alt="logo"/>
        </div>
        <div className="content">
          <h3>
            <Trans i18nKey="portal.notifications.admin.title">
              System notification!
            </Trans>
          </h3>
          <p dangerouslySetInnerHTML={{
            __html: this.state.localization === 'en' ? this.initEnglishMessage() : this.initGermanMessage()
          }}>
          </p>
          <span className="publish-date">{this.props.publishDate}</span>
        </div>
      </div>
    );
  }

}
