// @flow
import * as actions from './ask-me.constants';

import { ImageDto } from 'rest/images/image.dto';
import { VideoDto } from 'rest/videos/video.dto';

import type { AskMeType } from './ask-me.type';

const initialState: AskMeType = {
  image: null,
  video: null,
  content: null,
  fetching: false
};

export default function (state: AskMeType = initialState, action) {
  switch (action.type) {
    case actions.GET_ASK_ME_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case actions.GET_ASK_ME_SUCCESS: {
      return {
        ...state,
        image: new ImageDto(action.payload.image),
        video: new VideoDto(action.payload.video),
        content: action.payload.content,
        fetching: false
      };
    }
    case actions.GET_ASK_ME_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.RESET_ASK_ME_SUCCESS:
      return {
        ...state,
        image: null,
        content: null,
        fetching: false,
        video: null
      };

    default:
      return state;
  }
}
