// @flow
import { Component } from 'react';

import './menu-item.scss';

type Props = {
  onClick?: (event: Event) => void,
  children?: Node,
  disabled?: boolean,
};

export class Setting extends Component<Props> {

  static defaultProps = {
    disabled: false,
    onClick: () => {}
  }

}
