import * as actions from './blog.constants';

import { Dispatch, Action } from 'redux';

import {
  getCandidatePosts,
  createCandidatePost,
  deleteCandidatePost,
  updateCandidatePost,
  getCandidatePostById
} from 'rest/candidates/posts/posts.rest';

import { PostDto } from 'rest/candidates/posts/post.dto';
import { PostsResponseDto } from 'rest/candidates/posts/posts.response.dto';

const getCandidatePostsRequest = (): Action => ({
  type: actions.GET_CANDIDATE_POSTS_REQUEST
});

const getCandidatePostsSuccess = (data): Action => ({
  type: actions.GET_CANDIDATE_POSTS_SUCCESS,
  payload: data
});

const getCandidatePostsFailure = (): Action => ({
  type: actions.GET_CANDIDATE_POSTS_FAILURE
});

const getCandidatePostByIdRequest = (): Action => ({
  type: actions.GET_CANDIDATE_POST_BY_ID_REQUEST
});

const getCandidatePostByIdSuccess = (post: PostsResponseDto): Action => ({
  type: actions.GET_CANDIDATE_POST_BY_ID_SUCCESS,
  post
});

const getCandidatePostByIdFailure = (): Action => ({
  type: actions.GET_CANDIDATE_POST_BY_ID_FAILURE
});

const createCandidatePostRequest = (): Action => ({
  type: actions.CREATE_CANDIDATE_POSTS_REQUEST
});

const createCandidatePostSuccess = (postId: string, post: PostDto): Action => ({
  type: actions.CREATE_CANDIDATE_POSTS_SUCCESS,
  postId,
  post
});

const createCandidatePostFailure = (): Action => ({
  type: actions.CREATE_CANDIDATE_POSTS_FAILURE
});

const updateCandidatePostRequest = (): Action => ({
  type: actions.UPDATE_CANDIDATE_POSTS_REQUEST
});

const updateCandidatePostSuccess = (postId: string, post: PostDto): Action => ({
  type: actions.UPDATE_CANDIDATE_POSTS_SUCCESS,
  postId,
  post
});

const updateCandidatePostFailure = (): Action => ({
  type: actions.UPDATE_CANDIDATE_POSTS_FAILURE
});

const deleteCandidatePostRequest = (): Action => ({
  type: actions.DELETE_CANDIDATE_POSTS_REQUEST
});

const deleteCandidatePostSuccess = (postId: string): Action => ({
  type: actions.DELETE_CANDIDATE_POSTS_SUCCESS,
  postId
});

const deleteCandidatePostFailure = (): Action => ({
  type: actions.DELETE_CANDIDATE_POSTS_FAILURE
});

const resetCreationPostSuccess = (): Action => ({
  type: actions.RESET_CREATION_POST_SUCCESS
});

const resetListPostSuccess = (): Action => ({
  type: actions.RESET_POST_LIST_SUCCESS
});

export function resetCreationPost () {
  return dispatch => dispatch(resetCreationPostSuccess());
}

export function resetPostList () {
  return dispatch => dispatch(resetListPostSuccess());
}

export function getPosts (params) {
  return (dispatch: Dispatch) => {
    dispatch(getCandidatePostsRequest());
    return getCandidatePosts(params)
      .then(response => dispatch(getCandidatePostsSuccess(response.data)))
      .catch(() => dispatch(getCandidatePostsFailure()));
  };
}

export function getPostById (postId: string) {
  return (dispatch: Dispatch) => {
    dispatch(getCandidatePostByIdRequest());
    return getCandidatePostById(postId)
      .then(response => dispatch(getCandidatePostByIdSuccess(response.data)))
      .catch(() => dispatch(getCandidatePostByIdFailure()));
  };
}

export function createPost (post: PostDto) {
  return (dispatch: Dispatch) => {
    dispatch(createCandidatePostRequest());
    return createCandidatePost(post)
      .then(response => {
        dispatch(createCandidatePostSuccess(response.data, post));
        dispatch(resetPostList());
      })
      .catch(() => dispatch(createCandidatePostFailure()));
  };
}

export function updatePost (postId: string, post: PostDto) {
  return (dispatch: Dispatch) => {
    dispatch(updateCandidatePostRequest());
    return updateCandidatePost(postId, post)
      .then(() => {
        dispatch(updateCandidatePostSuccess(postId, post));
        dispatch(resetPostList());
      })
      .catch(() => dispatch(updateCandidatePostFailure()));
  };
}

export function deletePost (postId: string) {
  return (dispatch: Dispatch) => {
    dispatch(deleteCandidatePostRequest());
    return deleteCandidatePost(postId)
      .then(() => dispatch(deleteCandidatePostSuccess(postId)))
      .catch(() => dispatch(deleteCandidatePostFailure()));
  };
}
