import { Component } from 'react';

import { bindActionCreators, Dispatch } from 'redux';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { CandidateDto } from 'rest/candidates/candidate.dto';

import {
  changeCurrentCandidate,
  getCandidateById
} from 'services/portal/recruiter/candidate/all/candidates.actions';

import {
  changeInviteStatus,
  getAllCalendarInvites,
  resetAllCalendarInvites
} from 'services/portal/recruiter/calendar/invites/invites.actions';

import './profile-header.scss';

type Props = {
  t?: TransProps,
  children: any
};

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  candidate: new CandidateDto(state.portal.recruiter.candidate.allCandidates.candidate),
  currentUser: state.portal.recruiter.account.current.currentUser
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getCandidateById,
  changeInviteStatus,
  getAllCalendarInvites,
  changeCurrentCandidate,
  resetAllCalendarInvites
}, dispatch);
