// @flow
import React, { Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';

import cn from 'classnames';
import { Trans } from 'react-i18next';

import { signOut } from 'middleware/auth';
import { connectStore } from 'middleware/connect';
import { setLocalization } from 'middleware/localization';
import { getUserType, resetUserType } from 'middleware/user-type';
import { isLoggedIn } from 'middleware/auth';
import { Container, Logo } from 'components/layouts';

import { LinkButton } from 'components/buttons';
import { LanguagesSwitcher } from './languages-switcher/languages-switcher';

import { modalTypes } from 'services/portal/modal/modal.constants';
import { notificationsType } from 'services/portal/notifications/notifications.constants';

import { dispatchToProps, Settings, stateToProps } from './header.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class Header extends Settings {

  componentDidMount () {
    const userType: string = getUserType();
    if (this.props.currentUser && userType) {
      this.fetchNotifications();
      this.startGettingNotificationsCount();
    }
  }

  componentWillUnmount () {
    clearInterval(this.state.longPollingId);
  }

  logOut () {
    resetUserType();
    this.props.clearCurrentRecruiter();
    this.props.clearCurrentCandidate();
    signOut();
  }

  startGettingNotificationsCount () {
    const longPollingTimeout: number = 30000;
    this.setState({
      longPollingId: setInterval(() => this.fetchNotifications(), longPollingTimeout)
    });
  }

  fetchNotifications () {
    if (this.props.currentUserId) {
      this.props.getNotificationsCount({
        alertNotificationFilter: notificationsType.UNREAD
      });
    }
  }

  changeLocalization (localization: string) {
    const { i18n } = this.props;
    i18n.changeLanguage(localization);
    if (this.props.currentUser.id) {
      this.props.changeCandidateLocale(localization);
    } else if (this.props.currentCandidate.id) {
      this.props.changeRecruiterLocale(localization);
    } else {
      setLocalization(localization);
      window.location.reload();
    }
    this.setState({ localization });
  }

  handleBurgerOpen () {
    this.setState({ burgerOpen: !this.state.burgerOpen });
  }

  handlePortalBurger () {
    this.props.portalShowHideNav(!this.props.portalNavShow);
    this.props.hideModal();
  }

  render () {
    const totalCountUnreadMatched = this.props.unreadMessages + this.props.newJobOfferMatchedCount;
    const userType: string = getUserType();
    const headerClasses = cn({
      'main-header': true,
      'mobile-header-hide': this.props.onRegister,
      'mobile-not-found-page-header-portal': this.props.onPortal,
      'mobile-portal-guest': this.props.onGuestPortal,
      fixed: this.props.fixed,
      [this.props.className]: true
    });
    const languages: string[] = [ 'en', 'de', 'fr', 'sp', 'enu' ];
    return (
      <header className={`${headerClasses} ${this.props.fixed ? 'fixed' : ''}`}>
        <Container>
          <div className="header-container">
            {
              userType !== 'guest' && window.location.pathname !== '/' && <div className="burger-button">
                <div
                  className={`mobile-burger ${this.state.burgerOpen ? 'open' : ''}`}
                  onClick={() => this.handlePortalBurger()}>
                  <div className="burger-top"/>
                  <div className="burger-middle"/>
                  <div className="burger-bottom"/>
                  {
                    totalCountUnreadMatched > 0 &&
                    <span className="badge">
                      {
                        totalCountUnreadMatched > 99 ? 99 : totalCountUnreadMatched
                      }
                    </span>
                  }
                </div>
              </div>
            }
            <Logo
              // This is temporary fix
              // logOut={() => this.logOut()}
              candidateRegistered={this.props.currentCandidate.fullyRegistered}
              recruiterRegistered={this.props.currentUser.fullyRegistered}
              userType={userType}/>
            {
              window.location.pathname === '/' ? (
                <div className='header-mobile-nav'>
                  <Link
                    to="/auth"
                    className="mobile-user">
                    <img
                      src="/images/header/profile-icon.svg"
                      alt="Auth"/>
                  </Link>
                  <div
                    className={`mobile-burger ${this.state.burgerOpen ? 'open' : ''}`}
                    onClick={() => this.handleBurgerOpen()}>
                    <div className="burger-top"/>
                    <div className="burger-middle"/>
                    <div className="burger-bottom"/>
                  </div>
                </div>
              ) : (
                <div className="header-portal-nav">
                  <NavLink
                    to="/portal/candidate/chat"
                    className="mobile-chat-header ring-bell"
                    onClick={() => this.props.hideModal()}>
                    <span className="icon-chat "/>
                    {
                      this.props.unreadMessages > 0 && <span className="badge">{this.props.unreadMessages}</span>
                    }
                  </NavLink>
                  <div
                    onClick={() => this.props.showModal(modalTypes.NOTIFICATIONS_MODAL)}
                    className="ring-bell badge-wrapper">
                    {
                      this.props.notificationsCount > 0 &&
                      <span className="badge">
                        {this.props.notificationsCount > 999 ? 999 : this.props.notificationsCount}
                      </span>
                    }
                    <img
                      src="/images/header/notification.svg"
                      alt="Notifications"/>
                  </div>
                </div>
              )
            }
            <div className={`headers-links ${this.state.burgerOpen && window.location.pathname === '/' ? 'open' : ''}`}>
              <nav>
                <ul className="nav-list">
                  {
                    <Fragment>
                      <li>
                        <Link
                          to="/information/pricing"
                          className="nav-link"
                          onClick={() => this.handleBurgerOpen()}>
                          <Trans i18nKey="portal.header.pricing">
                            Pricing
                          </Trans>
                          <span className="mobile-pricing-for-recruiters">
                            <Trans i18nKey="portal.header.mobile.pricing-for-recruiters">
                              (for recruiters)
                            </Trans>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/information/how-it-works"
                          className="nav-link"
                          onClick={() => this.handleBurgerOpen()}>
                          <Trans i18nKey="portal.header.works">
                            How It Works
                          </Trans>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/information/about-us"
                          className="nav-link"
                          onClick={() => this.handleBurgerOpen()}>
                          <Trans i18nKey="portal.header.about">
                            About Us
                          </Trans>
                        </Link>
                      </li>
                    </Fragment>
                  }
                </ul>
              </nav>
              {
                (userType !== 'guest' && this.props.notificationVisible) && <div className="ring-bell">
                  <div
                    onClick={() => this.props.showModal(modalTypes.NOTIFICATIONS_MODAL)}
                    className="badge-wrapper">
                    {
                      this.props.notificationsCount > 0 &&
                      <span className="badge">
                        {this.props.notificationsCount > 999 ? 999 : this.props.notificationsCount}
                      </span>
                    }
                    <img
                      src="/images/header/notification.svg"
                      alt="Notifications"/>
                  </div>
                </div>
              }
              <div className="language-switcher">
                <LanguagesSwitcher
                  value={this.state.localization}
                  items={languages}
                  change={item => this.changeLocalization(item)}/>
              </div>
              <div className="mobile-lang-switcher">
                <span className="mobile-lang-switcher-title">
                  <Trans i18nKey="portal.header.mobile.language-switcher">
                    Select language:
                  </Trans>
                </span>
                <ul className="mobile-lang-switcher-list">{
                  languages.map(lang => {
                    return (
                      <li
                        className={`mobile-lang-switcher-item ${this.state.localization === lang ? 'active' : ''}`}
                        key={lang}
                        onClick={() => this.changeLocalization(lang)}>
                        <img
                          src={`/images/shared/${lang}-flag.svg`}
                          alt={`${lang}`}/>
                      </li>
                    );
                  })
                }</ul>
              </div>
              {
                userType === 'guest' && <div className="control-panel">
                  <div className="mobile-link-button-hide">
                    <LinkButton
                      link="/auth"
                      recruiter
                      outlined>
                      <Trans i18nKey="portal.header.register">
                        Register / Sign In
                      </Trans>
                    </LinkButton>
                  </div>
                  <div className="mobile-link-button">
                    <LinkButton
                      link="/auth"
                      recruiter
                      accent>
                      <Trans i18nKey="portal.header.register">
                        Register / Sign In
                      </Trans>
                    </LinkButton>
                  </div>
                </div>
              }
              {
                isLoggedIn() && <div className="control-panel">
                  <div className="mobile-link-button-hide">
                    <LinkButton
                      link={`/portal/${userType}/balance-board`}
                      recruiter
                      outlined>
                      <Trans i18nKey="portal.header.dashboard">
                        Go To Dashboard
                      </Trans>
                    </LinkButton>
                  </div>
                  <div className="mobile-link-button">
                    <LinkButton
                      link={`/portal/${userType}/balance-board`}
                      recruiter
                      accent>
                      <Trans i18nKey="portal.header.dashboard">
                      Go To Dashboard
                      </Trans>
                    </LinkButton>
                  </div>
                </div>
              }
              <div className="header-copyright">
                <Trans i18nKey="portal.footer.copyright">
                  Test Excellent jobs 2020. All rights reserved.
                </Trans>
              </div>
            </div>
          </div>
        </Container>
      </header>
    );
  }

}
