import { SkillDto } from './skills.dto';

export class SkillsContainerDto {

  matchPoints: number;
  emptySkills: boolean;
  documents: SkillDto[] = [];
  softSkills: SkillDto[] = [];
  languageSkills: SkillDto[] = [];
  softwareSkills: SkillDto[] = [];
  professionalSkills: SkillDto[] = [];

  constructor (data?: SkillsContainerDto) {
    if (data) {
      this.matchPoints = data.matchPoints || 75;
      this.emptySkills = data.emptySkills;
      this.documents = (data.documents || []).map(skill => new SkillDto(skill));
      this.softSkills = (data.softSkills || []).map(skill => new SkillDto(skill));
      this.languageSkills = (data.languageSkills || []).map(skill => new SkillDto(skill));
      this.softwareSkills = (data.softwareSkills || []).map(skill => new SkillDto(skill));
      this.professionalSkills = (data.professionalSkills || []).map(skill => new SkillDto(skill));
    }
  }

  get isSkillNotEmpty (): boolean {
    return Object.keys(this).some(key => this[key].length);
  }

}
