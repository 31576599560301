// @flow
import * as actions from './templates.constants';

import { ApplicationResponseDto } from 'rest/candidates/applications-template/applications-template.response.dto';

import type { TemplatesType } from './templates.type';

const initialState: TemplatesType = {
  status: '',
  content: [],
  fetching: false,
  currentPage: 0,
  hasMoreItems: true,
  currentTemplate: null,
  createApplicationSuccess: false
};

export default function (state: TemplatesType = initialState, action): TemplatesType {
  let content = [];
  switch (action.type) {
    case actions.GET_APPLICATIONS_SUCCESS:
      content = action.payload.content.map(item => new ApplicationResponseDto(item));
      return {
        ...state,
        content: action.payload.number ? [...state.content, ...content] : content,
        fetching: false,
        currentPage: action.payload.number + 1,
        hasMoreItems: action.payload.content.length > 0
      };

    case actions.CREATE_APPLICATIONS_SUCCESS:
      return {
        ...state,
        fetching: false,
        createApplicationSuccess: true
      };

    case actions.GET_APPLICATION_BY_ID_SUCCESS:
      return {
        ...state,
        fetching: false,
        currentTemplate: new ApplicationResponseDto(action.template)
      };

    case actions.DELETE_APPLICATION_SUCCESS:
      return {
        ...state,
        content: state.content.filter(item => item.id !== action.templateId)
      };

    case actions.CHANGE_TEMPLATES_FILTER_SUCCESS:
      return {
        ...initialState,
        status: action.status
      };

    case actions.RESET_APPLICATIONS_SUCCESS:
      return {
        ...initialState
      };

    default:
      return state;
  }
}
