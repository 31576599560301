import React from 'react';
import { Trans, TransProps } from 'react-i18next';

import { Button } from 'components/buttons';
import { FormGroup, InputControl } from 'components/controls';

import './promo-code.scss';

type Props = {
  t?: TransProps,
  value: string,
  isValid: boolean,
  isFailed: boolean,
  isChange: boolean,
  isCompanyInfoEditable: boolean,
  handleChange: () => void,
  applyPromoCode: () => void
};

const PromoCode = (props: Props) => {
  const {
    value, isValid, isFailed, isChange, isCompanyInfoEditable, t, handleChange, applyPromoCode
  } = props;

  return (
    <FormGroup className={`promo-code-group
      ${isCompanyInfoEditable ? ' disabled' : ''}
      ${isFailed ? ' failed' : ''}`}>
      <InputControl
        name="promoCode"
        value={value}
        label={t('portal.guest.recruiter.job-offer.payment.invoice-details.promo.label')}
        notes={t('portal.guest.recruiter.job-offer.payment.invoice-details.promo.notes')}
        placeholder={t('portal.guest.recruiter.job-offer.payment.' +
          'invoice-details.promo.placeholder')}
        change={value => handleChange(value)}
        type="text"/>
      {isValid && !isChange
        ? <i className="icon-success">
          <img className="img-responsive" src="/images/shared/toaster-success.svg" alt="Promocode correct"/>
        </i>
        : <Button
          type="button"
          className=" accent small"
          disabled={!value}
          onClick={applyPromoCode}>
          <Trans i18nKey="portal.guest.recruiter.job-offer.payment.invoice-details.buttons.apply">
            Apply
          </Trans>
        </Button>
      }
    </FormGroup>
  );
};

export default PromoCode;
