// @flow

export class PaymentStepDto {

  id: number;
  path: string;
  activeTab: string;

  constructor (data?: PaymentStepDto) {
    if (data) {
      this.id = data.id;
      this.path = data.path;
      this.activeTab = data.activeTab;
    }
  }

}

export const setData = (field: string, data: PaymentStepDto) => {
  const paymentStepData = JSON.stringify(data);
  sessionStorage.setItem(field, paymentStepData);
};

export const getData = (field: string): PaymentStepDto => JSON.parse(sessionStorage.getItem(field));
