// @flow
import { combineReducers } from 'redux';

import pdf from './pdf/pdf.reducer';
import jobTitle from './job-title/job-title.reducer';
import education from './education/education.reducer';
import lifestyle from './lifestyles/lifestyles.reducer';
import experience from './work-experience/work-experience.reducer';

export default combineReducers({
  pdf,
  jobTitle,
  lifestyle,
  education,
  experience
});
