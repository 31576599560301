import React from 'react';

import cn from 'classnames';

import { Setting } from './button.setting';

export class Button extends Setting {

  render () {
    const className = cn({
      [this.props.className]: this.props.className,
      'grey': this.props.grey,
      'accent': this.props.recruiter,
      'large': this.props.large,
      'medium': this.props.medium,
      'primary': this.props.candidate,
      'outlined': this.props.outlined,
      'full-width': this.props.fullWidth,
      'btn': true
    });

    return (
      <button
        disabled={this.props.disabled}
        type={this.props.type}
        onClick={this.props.onClick}
        className={className}>
        {this.props.children}
      </button>
    );
  }

}
