export class JobTitleResponseDto {

  jobTitle: string;
  maxSalary: number;
  minSalary: number;
  candidateId: string;
  educationDegree: string;
  readyToRelocation: boolean;
  workExperienceMonths: number;
  workingHoursPerMonth: number;

  constructor (data?: JobTitleResponseDto) {
    if (data) {
      this.jobTitle = data.jobTitle;
      this.maxSalary = data.maxSalary;
      this.minSalary = data.minSalary;
      this.candidateId = data.candidateId;
      this.educationDegree = data.educationDegree;
      this.readyToRelocation = data.readyToRelocation;
      this.workExperienceMonths = data.workExperienceMonths;
      this.workingHoursPerMonth = data.workingHoursPerMonth;
    }
  }

}
