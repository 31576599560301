import { combineReducers } from 'redux';

import orders from './orders/orders.reducer';
import current from './current/current.reducer';
import payment from './payment/payment.reducer';
import jobOffer from './job-offer/job-offer.reducer';
import companies from './companies/companies.reducer';
import blacklist from './blacklist/blacklist.reducer';
import purchased from './purchased/purchased.reducer';
import jobOfferStats from './job-offer/stats/stats.reducer';
import teamMembers from './team-members/team-members.reducer';
import purchasedStats from './purchased/stats/stats.reducer';

export default combineReducers({
  orders,
  payment,
  current,
  jobOffer,
  blacklist,
  companies,
  purchased,
  teamMembers,
  jobOfferStats,
  purchasedStats
});
