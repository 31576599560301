// @flow
import { JobOfferDto } from 'rest/job-offers/job-offers.dto';

type JobOfferData = JobOfferDto;

export const setData = (field: string, data: JobOfferData) => {
  const stringifiedData = JSON.stringify(data);
  localStorage.setItem(field, stringifiedData);
};

export const getData = (field: string): JobOfferData => JSON.parse(localStorage.getItem(field));
