import { Component } from 'react';

import './premium-info-modal.scss';

type Props = {
  open: boolean,
  close: () => void
}

export class Settings extends Component<Props> {

  static defaultProps = {
    open: false,
    close: () => {}
  }

}
