// @flow
import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import { Card } from 'components/layouts';
import { Modal } from 'components/dialogs';

import {
  CandidateProfileView,
  CandidatesContainer,
  CandidatesList,
  JobOfferCandidates,
  JobOfferHeader
} from 'containers/portal/recruiter/match';

export class MatchCandidatesListPage extends Component {

  render () {
    return (
      <Modal closePath="/portal/recruiter/match">
        <CandidatesContainer>
          <Card className="job-offer-header">
            <Route
              path="/portal/recruiter/match/:jobOfferId/candidates"
              component={JobOfferHeader}/>
          </Card>
          <Card className="candidates-list profile-details-modal">
            <JobOfferCandidates>
              <Route
                path="/portal/recruiter/match/:jobOfferId/candidates"
                exact
                component={CandidatesList}/>
              <Route
                path="/portal/recruiter/match/:jobOfferId/candidates/:candidateId"
                exact
                component={CandidateProfileView}/>
            </JobOfferCandidates>
          </Card>
        </CandidatesContainer>
      </Modal>
    );
  }

}
