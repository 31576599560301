// @flow
import * as actions from './blog.constants';

import { Action } from 'redux';

import { PostsResponseDto } from 'rest/candidates/posts/posts.response.dto';

import type { CandidatePostsType } from './blog.type';

const initialState: CandidatePostsType = {
  content: [],
  fetching: false,
  previewPost: new PostsResponseDto(),
  currentPage: 0,
  hasMoreItems: true,
  totalElements: 0,
  createPostSuccess: false
};

export default function (state: CandidatePostsType = initialState, action: Action) {
  switch (action.type) {
    case actions.UPDATE_CANDIDATE_POSTS_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case actions.GET_CANDIDATE_POSTS_REQUEST:
      return {
        ...state,
        fetching: true,
        hasMoreItems: false
      };

    case actions.UPDATE_CANDIDATE_POSTS_FAILURE:
    case actions.GET_CANDIDATE_POSTS_FAILURE:
    case actions.CREATE_CANDIDATE_POSTS_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.CREATE_CANDIDATE_POSTS_REQUEST:
      return {
        ...state,
        fetching: true,
        createPostSuccess: false
      };

    case actions.GET_CANDIDATE_POSTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        currentPage: action.payload.number + 1,
        content: [ ...state.content, ...action.payload.content ],
        hasMoreItems: action.payload.content.length > 0,
        totalElements: action.payload.totalElements
      };

    case actions.GET_CANDIDATE_POST_BY_ID_SUCCESS:
      return {
        ...state,
        fetching: false,
        previewPost: action.post
      };

    case actions.CREATE_CANDIDATE_POSTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        createPostSuccess: true,
        totalElements: state.totalElements + 1,
        content: [
          ...state.content,
          {
            ...action.post,
            id: action.postId
          }
        ]
      };

    case actions.UPDATE_CANDIDATE_POSTS_SUCCESS: {
      const content = [...state.content];
      const updatedPostIndex = content.findIndex(item => item.id === action.postId);
      content.splice(updatedPostIndex, 1, {
        ...action.post,
        id: action.postId
      });
      return {
        ...state,
        content,
        fetching: false
      };
    }

    case actions.DELETE_CANDIDATE_POSTS_SUCCESS:
      return {
        ...state,
        totalElements: state.totalElements - 1,
        content: state.content.filter(item => item.id !== action.postId)
      };

    case actions.RESET_CREATION_POST_SUCCESS:
      return {
        ...state,
        createPostSuccess: false
      };

    case actions.RESET_POST_LIST_SUCCESS:
      return { ...initialState };

    default:
      return state;
  }
}
