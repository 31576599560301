// @flow
import { Component } from 'react';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { ApplicationResponseDto } from 'rest/candidates/applications-template/applications-template.response.dto';

type Props = {
  t: TransProps,
  templates: ApplicationResponseDto[]
};

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  templates: state.portal.candidate.applications.templates.content
});
