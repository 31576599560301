import React from 'react';
import { Trans } from 'react-i18next';

import { LinkButton } from 'components/buttons';

import { connectStore } from 'middleware/connect';
import { findEvent } from 'middleware/notifications/find-event';

import * as eventNames from './add-to-system-skill.constants';
import { Setting, stateToProps } from './add-to-system-skill.setting';

@connectStore(stateToProps, null, true)
export class AddToSystemSkill extends Setting {

  hideModal = () => {
    this.props.hideModal();
  };

  render () {
    const { eventData, publishDate, t, candidateId } = this.props;
    const jobTitles = findEvent(eventData, eventNames.JOB_TITLES);
    return (
      <div className="notification-item-content">
        <div className="logo-container">
          <img
            src="/images/notifications/userpick.svg"
            alt="logo"/>
        </div>
        <div className="content">
          <h3>
            <Trans i18nKey="portal.notifications.add-to-system.skill.title">
              New system skill!
            </Trans>
          </h3>
          {
            eventData.length && eventData.length && <p>
              <Trans i18nKey="portal.notifications.add-to-system.skill.description.0">
                We&#39;ve added
              </Trans>
              &nbsp;
              <strong>
                {
                  !findEvent(eventData, eventNames.NAME)
                    ? t('portal.notifications.request-skill.empty')
                    : findEvent(eventData, eventNames.NAME)
                }
              </strong>
              &nbsp;
              <Trans i18nKey="portal.notifications.add-to-system.skill.description.1">
                skill for
              </Trans>
              &nbsp;
              {
                jobTitles.length && jobTitles.map((jobTitle, index) =>
                  <span
                    key={index}
                    className="coma-separated-element">
                    {jobTitle}
                  </span>
                )
              }
              &nbsp;
              <Trans i18nKey="portal.notifications.add-to-system.skill.description.2">
                (all titles connected) to our system. Click
              </Trans>
              {
                candidateId
                  ? <LinkButton
                    inline
                    onClick={this.hideModal}
                    link="/portal/candidate/balance-board/edit">
                    <Trans i18nKey="portal.notifications.add-to-system.skill.description.3">
                      here
                    </Trans>
                  </LinkButton>
                  : <LinkButton
                    inline
                    onClick={this.hideModal}
                    link="/portal/recruiter/job-offers">
                    <Trans i18nKey="portal.notifications.add-to-system.skill.description.3">
                      here
                    </Trans>
                  </LinkButton>
              }
              <Trans i18nKey="portal.notifications.add-to-system.skill.description.4">
                to add to your BB!
              </Trans>
            </p>
          }
          <span className="publish-date">{publishDate}</span>
        </div>
      </div>
    );
  }

}
