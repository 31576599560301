// @flow
import React from 'react';

import cn from 'classnames';
import { Trans } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { uploadFile } from 'middleware/upload-file';
import { connectStore } from 'middleware/connect';

import { Card } from 'components/layouts';
import { Modal } from 'components/dialogs';
import { Button, LinkButton } from 'components/buttons';
import { FormGroup, InputControl } from 'components/controls';

import { PreviewImage } from 'containers/portal/recruiter/account/settings';

import { ImageResponseDto } from 'rest/images/images.response';
import { PostDto } from 'rest/candidates/posts/post.dto';

import type { Props } from './edit-post.setting';
import { Settings, stateToProps, dispatchToProps } from './edit-post.setting';
import { toast } from 'react-toastify';

@connectStore(stateToProps, dispatchToProps, true)
export class EditPostModal extends Settings {

  componentDidMount () {
    this.props.getPostById(this.props.match.params.postId);
  }

  componentWillReceiveProps (nextProps: Props) {
    const previewPost = nextProps.previewPost;
    if (previewPost && previewPost.article !== this.state.article) {
      this.setState({ ...previewPost });
    }
  }

  removePreviewPhoto (id: number) {
    const photos = Array.from(this.state.postPhotos);
    const postPhotos = photos.filter((item, index) => index !== id);
    this.setState({ postPhotos });
  }

  changeReadyPhotosState (imageInfo: ImageResponseDto) {
    const photos = Array.from(this.state.postPhotos).filter(photo => photo.imageId);
    photos.push(new ImageResponseDto(imageInfo));
    this.setState({ postPhotos: photos });
  }

  changePostState (field: string, value: string) {
    this.setState({ [field]: value });
  }

  changePhotosState (file: File) {
    if (file && this.state.postPhotos.length < this.maxPhotosCount) {
      uploadFile(file, (base64, error) => {
        if (error) {
          this.setState({ imageSizeError: error });
          toast.error(this.props.t('portal.candidate.notifications.incorrect-size'));
        } else {
          const photos: ImageResponseDto[] = Array.from(this.state.postPhotos);
          photos.push(file);
          this.setState({
            imageSizeError: false,
            postPhotos: photos
          });
          this.inputUpload.current.value = null;
        }
      });
    }
  }

  setPostPhotos () {
    if (this.state.postPhotos.length) {
      const postPhotos: ImageResponseDto[] = this.state.postPhotos.filter(item => item.imageId);
      const index = postPhotos.findIndex(photo => photo.primary);
      if (index < 0) {
        postPhotos[0].primary = true;
      }
      return postPhotos;
    }
  }

  updatePost (event: Event) {
    event.preventDefault();
    if (!this.props.imageFetching) {
      const data = new PostDto(this.state);
      data.postPhotos = this.setPostPhotos();
      this.props.updatePost(this.props.match.params.postId, data);
      this.props.history.push('/portal/candidate/posts');
      toast.success(this.props.t('portal.candidate.notifications.blog-updated'));
    }
  }

  selectPrimaryImage (file: ImageResponseDto) {
    let photos = Array.from(this.state.postPhotos);
    photos = this.state.postPhotos.map(photo => {
      photo.primary = photo.imageId === file.imageId;
      return new ImageResponseDto(photo);
    });
    this.setState({ postPhotos: photos });
  }

  render () {
    return (
      <Modal closePath="/portal/candidate/posts">
        <div className="create-post-modal">
          <PerfectScrollbar className="content">
            <Card>
              <LinkButton
                className="posts-back-link"
                link="/portal/candidate/posts"
                inline>
                <Trans i18nKey="portal.candidate.blog.edit.back">
                  Back to Blog
                </Trans>
                <div className="modal-post-back">&times;</div>
              </LinkButton>
              <div className="modal-create-blog-title">
                <Trans i18nKey="portal.candidate.blog.mobile.edit-title">
                  Blog Edit
                </Trans>
              </div>
              <form
                onSubmit={this.updatePost.bind(this)}
                className="create-post-form">
                <FormGroup className="half-width post-title">
                  <InputControl
                    required
                    maxLength={70}
                    value={this.state.title}
                    change={(value: string): void => this.changePostState('title', value)}
                    label={this.props.t('portal.candidate.blog.edit.name.label')}
                    pattern=".+"
                    placeholder={this.props.t('portal.candidate.blog.edit.name.placeholder')}
                    type="text"/>
                </FormGroup>
                <FormGroup className="full-width">
                  <InputControl
                    value={this.state.article}
                    required
                    pattern=".+"
                    change={(value: string): void => this.changePostState('article', value)}
                    label={this.props.t('portal.candidate.blog.edit.description.label')}
                    placeholder={this.props.t('portal.candidate.blog.edit.description.placeholder')}
                    multiline
                    maxLength={1000}
                  />
                </FormGroup>
                <FormGroup className="full-width upload-btn-container">
                  <label>
                    <Trans i18nKey="portal.candidate.blog.edit.photos">
                      Photos <span>(up to three)</span>
                    </Trans>
                  </label>
                  <div className={cn([
                    'btn accent outlined full-width big',
                    { disabled: this.state.postPhotos.length >= this.maxPhotosCount }
                  ])}>
                    <img
                      src="/images/shared/upload-icon.svg"
                      alt="upload"/>
                    <Trans i18nKey="portal.candidate.blog.edit.choose-file">
                      Choose a file
                    </Trans>
                    <input
                      ref={this.inputUpload}
                      onChange={(event: Event) => this.changePhotosState(event.target.files[0])}
                      accept="image/png, image/jpeg"
                      type="file"
                      className="upload-file-input"
                    />
                  </div>
                  {
                    this.state.imageSizeError && <span className="image-size-error">{this.state.imageSizeError}</span>
                  }
                </FormGroup>
                <PreviewImage
                  removePreviewPhoto={this.removePreviewPhoto.bind(this)}
                  imageLoad={this.changeReadyPhotosState.bind(this)}
                  files={this.state.postPhotos}
                  selectImage={file => this.selectPrimaryImage(file)}/>
                <FormGroup className="form-btn-submit">
                  <Button
                    medium
                    candidate
                    type="submit">
                    <Trans i18nKey="portal.candidate.blog.edit.buttons.update">
                      Update Post
                    </Trans>
                  </Button>
                  <LinkButton
                    grey
                    link="/portal/candidate/posts">
                    <Trans i18nKey="portal.candidate.blog.edit.buttons.cancel">
                      Cancel
                    </Trans>
                  </LinkButton>
                </FormGroup>
              </form>
            </Card>
          </PerfectScrollbar>
        </div>
      </Modal>
    );
  }

}
