import React from 'react';
import { connect } from 'react-redux';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { CandidateProfile } from 'containers/portal/recruiter/candidates';

import { Settings, dispatchToProps, stateToProps } from './profile.setting';

@connect(stateToProps, dispatchToProps)
export class CandidateProfileView extends Settings {

  render () {
    return (
      <div className="candidate-profile-preview">
        <PerfectScrollbar className="profile-preview">
          <CandidateProfile
            restricted={!this.props.candidate.email}
            candidate={this.props.candidate}/>
        </PerfectScrollbar>
      </div>
    );
  }

}
