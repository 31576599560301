import { Component } from 'react';
import { TransProps } from 'react-i18next';

import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

import './job-offer-info.scss';
import './job-offer-info.responsive.scss';

export type Props = {
  t: TransProps,
  jobOffer: JobOffersResponseDto,
  openDescription?: () => void
};

export class Settings extends Component<Props> {

  static defaultProps: Props = {
    openDescription: () => {}
  }

}
