// @flow
import React from 'react';

import moment from 'moment';
import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Chips } from 'components/layouts';

import { ImageDto } from 'rest/images/image.dto';

import { Setting } from './post-item.setting';

@connectTranslation()
export class PostItem extends Setting {

  render () {
    const postPhotos = this.props.post.postPhotos;
    return (
      <article
        className="post-item"
        onClick={() => this.props.action()}>
        <div className="post-item-gallery">
          {
            postPhotos && postPhotos.map((photo: ImageDto, index: number) => (
              <div
                className="post-gallery-item"
                key={index}>
                <img src={photo.url}/>
              </div>
            ))
          }
        </div>
        <div className="post-content">
          <h3 className="post-title">{this.props.post.title}</h3>
          <p>{this.props.post.article}</p>
        </div>
        <Chips>
          <Trans i18nKey="portal.candidate.blog.list.published">
            Published
          </Trans>
          &nbsp;
          {moment(this.props.post.createDate).parseZone().fromNow()}</Chips>
      </article>
    );
  }

}
