import * as actions from './job-title.constants';

import { getCvJobTitle, updateCvJobTitle } from 'rest/candidates/cv/cv.rest';

import { JobTitleDto } from 'rest/candidates/cv/job-title.dto';

const getJobTitleRequest = () => ({
  type: actions.GET_JOB_TITLE_REQUEST
});

const getJobTitleSuccess = (payload: JobTitleDto) => ({
  type: actions.GET_JOB_TITLE_SUCCESS,
  payload
});

const getJobTitleFailure = (error: string) => ({
  type: actions.GET_JOB_TITLE_FAILURE,
  error
});

const updateJobTitleRequest = (data: JobTitleDto) => ({
  type: actions.UPDATE_JOB_TITLE_REQUEST,
  data
});

const updateJobTitleSuccess = (data: JobTitleDto) => ({
  type: actions.UPDATE_JOB_TITLE_SUCCESS,
  data
});

const updateJobTitleFailure = () => ({
  type: actions.UPDATE_JOB_TITLE_FAILURE
});

export function getCandidateJobTitle (candidateId: string) {
  return dispatch => {
    dispatch(getJobTitleRequest());
    return getCvJobTitle(candidateId)
      .then(response => dispatch(getJobTitleSuccess(response.data)))
      .catch(() => dispatch(getJobTitleFailure()));
  };
}

export function updateCandidateJobTitle (candidateId: string, data: JobTitleDto) {
  return dispatch => {
    dispatch(updateJobTitleRequest(data));
    return updateCvJobTitle(candidateId, data)
      .then(() => {
        dispatch(updateJobTitleSuccess(data));
        return dispatch(getCandidateJobTitle(candidateId));
      })
      .catch(() => dispatch(updateJobTitleFailure()));
  };
}
