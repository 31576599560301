import React, { Fragment } from 'react';

import { Setting } from './caption.setting';

export class NavigationCaption extends Setting {

  render () {
    return (
      <Fragment>
        <p className="navigation-title">{this.props.title}</p>
        {this.props.children}
      </Fragment>
    );
  }

}
