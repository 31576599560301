// @flow
import React, { Fragment } from 'react';

import { Trans } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import { connectStore } from 'middleware/connect';

import type { Props, State } from './reset-password-form.setting';

import { Button } from 'components/buttons';
import { Spinner } from 'components/elements';
import { FormGroup, InputControl } from 'components/controls';

import { InitResetPasswordDto } from 'rest/account/reset-password/init/init.dto';

import { Setting, dispatchToProps, stateToProps } from './reset-password-form.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class ResetPasswordForm extends Setting {

  componentWillReceiveProps (nextProps: Props, prevState: State) {
    if (nextProps.errorText !== prevState.errorText || nextProps.redirect !== this.state.redirect) {
      this.setState({
        errorText: nextProps.errorText,
        redirect: nextProps.redirect
      });
    }
  }

  changeResetPasswordState (value: string, hasError: boolean) {
    this.setState({
      email: value,
      hasError,
      errorText: ''
    });
  }

  initResetPassword (event: Event) {
    event.preventDefault();
    const data = new InitResetPasswordDto(this.state);
    this.props.initResetPassword(data);
  }

  render () {
    const { t } = this.props;
    return (
      <Fragment>
        {
          this.state.redirect && <Redirect to={this.props.redirectUrl}/>
        }
        <form onSubmit={this.initResetPassword.bind(this)}>
          <p className="reset-form-description">
            <Trans i18nKey="auth.reset.description">
              Enter your email address and we’ll send you a link to reset your password.
            </Trans>
          </p>
          <FormGroup>
            <InputControl
              value={this.state.email}
              change={(value, hasError) => this.changeResetPasswordState(value, hasError)}
              label={t('auth.reset.email.label')}
              required
              title={t('auth.reset.email.title')}
              placeholder={t('auth.reset.email.placeholder')}
              error={this.state.errorText}
              errorText={this.state.errorText || t('auth.reset.email.error')}
              type="email"/>
          </FormGroup>
          <FormGroup>
            <Button
              type="submit"
              recruiter={this.props.buttonType === 'recruiter'}
              candidate={this.props.buttonType === 'candidate'}
              fullWidth>
              <Trans i18nKey="auth.reset.button">
                Send Reset Instruction
              </Trans>
            </Button>
          </FormGroup>
        </form>
        <Spinner show={this.props.fetching}/>
      </Fragment>
    );
  }

}
