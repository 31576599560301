import * as actions from './job-titles.constants';

import type { JobTitlesState } from './job-titles.type';

const initialState: JobTitlesState = {
  fetching: false,
  jobTitlesList: []
};

export default (state = initialState, action): JobTitlesState => {
  switch (action.type) {

    case actions.GET_JOB_TITLES_REQUEST:
      return {
        fetching: true
      };

    case actions.GET_JOB_TITLES_FAILURE:
      return {
        fetching: false
      };

    case actions.GET_JOB_TITLES_SUCCESS:
      return {
        ...state,
        fetching: false,
        jobTitlesList: action.jobTitles
      };

    default:
      return state;
  }
};
