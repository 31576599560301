// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { PaginationDto } from 'middleware/dto';

import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';
import { ApplicationResponseDto } from 'rest/candidates/applications-template/applications-template.response.dto';

import {
  resetApplications,
  getSentCandidateApplications
} from 'services/portal/candidate/applications/sent-templates/sent-templates.actions';

import './sent-list.scss';

export type Props = {
  history: History,
  fetching: boolean,
  jobTitleId: string,
  currentPage: number,
  hasMoreItems: boolean,
  resetApplications: () => void,
  getCurrentCandidate: () => void,
  currentCandidate: CurrentCandidateDto,
  sentApplications: ApplicationResponseDto[],
  getSentCandidateApplications: (candidateId: string, params: PaginationDto) => void
}

export class Setting extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  fetching: state.portal.candidate.applications.sentTemplates.fetching,
  jobTitleId: state.portal.candidate.applications.sentTemplates.jobTitleId,
  currentPage: state.portal.candidate.applications.sentTemplates.currentPage,
  hasMoreItems: state.portal.candidate.applications.sentTemplates.hasMoreItems,
  sentApplications: state.portal.candidate.applications.sentTemplates.content,
  currentCandidate: state.portal.candidate.account.current.candidateData
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetApplications,
  getSentCandidateApplications
}, dispatch);
