import { Component } from 'react';

import moment from 'moment';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { EventDataDto } from 'rest/alerts/alerts.event-data.dto';

type Props = {
  eventData: EventDataDto[],
  hideModal: () => void,
  candidateId: string,
  publishDate: string,
  t?: TransProps
}

export class Setting extends Component<Props> {

  static defaultProps: Props = {
    eventData: [],
    hideModal: () => {},
    candidateId: '',
    publishDate: moment().parseZone().fromNow()
  };

}

export const stateToProps = (state: GlobalState) => ({
  candidateId: state.portal.candidate.account.current.candidateData.id
});
