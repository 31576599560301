export class CandidateSkillItem {

  name: string;
  skillId: string;
  skillLevel: string;

  constructor (data?: CandidateSkillItem) {
    if (data) {
      this.name = data.name;
      this.skillId = data.skillId;
      this.skillLevel = data.skillLevel;
    }
  }

}
