// @flow
import React, { Fragment } from 'react';

import cn from 'classnames';
import { toast } from 'react-toastify';
import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';
import { uploadFile } from 'middleware/upload-file';

import type { Props } from './company-info.setting';
import type { RatingType } from './company-info.rating.type';
import type { DropdownType } from 'components/controls/dropdown/dropdown.type';

import { Button } from 'components/buttons';
import { Spinner } from 'components/elements';
import { Avatar } from 'components/avatar/avatar';
import { FormGroup, InputControl, Dropdown, AutosuggestSelect, MultiSelect } from 'components/controls';

import { PreviewImage } from 'containers/portal/recruiter/account/settings';

import { RATING_COMPANY_SYSTEM } from 'services/portal/recruiter/account/companies/companies.constants';

import { Settings, dispatchToProps, stateToProps } from './company-info.setting';

import { ImageDto } from 'rest/images/image.dto';
import { AddressDto } from 'rest/address/address.dto';
import { CompanyDto } from 'rest/companies/companies.dto';
import { UserAddressDto } from 'rest/address-dto/address';
import { ImageResponseDto } from 'rest/images/images.response';

@connectStore(stateToProps, dispatchToProps, true)
export class CompanyInfo extends Settings {

  componentDidMount () {
    if (!this.props.currentUser) {
      this.props.getCurrentRecruiter();
    } else {
      this.props.getRecruiterCompanyById(this.props.currentUser.companyId);
    }
    this.props.getAllBrands();
    this.props.getAllBranches();
  }

  componentWillReceiveProps (nextProps: Props) {
    const company = nextProps.company;
    if (company && company.address && company.name !== this.state.name && company.photos !== this.state.photos) {
      this.setCompanyState(company);
    }
    if (company && this.props.company && company.stars !== this.props.company.stars) {
      this.setState({ stars: company.stars });
    }
  }

  componentDidUpdate (prevProps: Props) {
    if (this.props.error && prevProps.error !== this.props.error) {
      toast.error(this.props.error);
    }
    if (!prevProps.updated && this.props.updated) {
      toast.success(this.props.t('portal.recruiter.notifications.company-updated'));
    }
  }

  setCompanyState (company: CompanyDto) {
    this.setState({
      ...company,
      ...company.address,
      logoPreview: company.logo && company.logo.url
    });
  }

  searchBranchItem (id: string): DropdownType {
    return this.props.branches.find(elem => elem.id === id);
  }

  changeLogoState (file: File) {
    uploadFile(file, (base64, error) => {
      this.setState({
        logoPreview: base64,
        logoPreviewError: error,
        imageSizeError: error
      }, () => this.props.uploadFile(file, 'avatar'));
    });
  }

  changePhotosState (file: File) {
    if (file && this.state.photos.length < this.maxPhotosCount) {
      uploadFile(file, (base64, error) => {
        if (error) {
          this.setState({ imageSizeError: error });
          toast.error(this.props.t('portal.recruiter.notifications.incorrect-size'));
        } else {
          const photos = Array.from(this.state.photos);
          photos.push(file);
          this.setState({
            imageSizeError: false,
            photos
          });
          this.inputUpload.current.value = null;
        }
      });
    }
  }

  changeReadyCompanyPhotosState (imageInfo: ImageResponseDto) {
    const photos = Array.from(this.state.previewPhotos);
    photos.push(imageInfo);
    this.setState({ previewPhotos: photos });
  }

  removeCompanyPreviewPhoto (id: number) {
    const photos = Array.from(this.state.photos);
    const newPhotos = photos.filter((item, index) => index !== id);
    this.setState({ photos: newPhotos });
    toast.success(this.props.t('portal.recruiter.notifications.photo-removed'));
  }

  setCompanyPhotos () {
    if (this.state.photos.length || this.state.previewPhotos.length) {
      const photos = this.state.photos.filter(item => item.imageId);
      return [...photos, ...this.state.previewPhotos];
    }
  }

  setCompanyLogo (): ImageDto {
    const company = this.props.company;
    const logo = new ImageDto();
    logo.primary = true;
    if (this.props.avatarInfo && this.state.logoPreview) {
      logo.imageId = this.props.avatarInfo.imageId;
    } else if (company.logo && this.state.logoPreview) {
      logo.imageId = company.logo.imageId;
    }
    return logo;
  }

  setCompanyStars (): number {
    const ratingList: RatingType[] = this.getRatingList();
    return ratingList.length > 0 ? this.state.stars : NaN;
  }

  sendCompanyInfo () {
    const company = new CompanyDto(this.state);
    company.address = new UserAddressDto(this.state);
    company.logo = this.setCompanyLogo();
    company.stars = this.setCompanyStars();
    company.photos = this.setCompanyPhotos();
    this.props.updateRecruiterCompany(this.props.currentUser.companyId, company);
  }

  getRatingList (): RatingType[] {
    const defaultBranch: DropdownType = this.searchBranchItem(this.state.branchId);
    const branchName: string = defaultBranch && defaultBranch.name;
    switch (branchName) {
      case 'Hotel':
        return RATING_COMPANY_SYSTEM.hotel;
      case 'Restaurant':
        return RATING_COMPANY_SYSTEM.restaurant;
      default:
        return [];
    }
  }

  suggestCountry (value: string) {
    const query: AddressDto = new AddressDto();
    query.city = '';
    query.street = '';
    query.country = value;
    this.props.searchSuggestedAddress('country', query);
  }

  suggestStreet (value: string) {
    const query: AddressDto = new AddressDto(this.state);
    query.street = value;
    this.props.searchSuggestedAddress('street', query);
  }

  suggestCity (value: string) {
    const query: AddressDto = new AddressDto(this.state);
    query.street = '';
    query.city = value;
    this.props.searchSuggestedAddress('city', query);
  }

  validate () {
    this.setState({
      branchError: !this.state.branchId,
      logoPreviewError: !this.state.logoPreview
    });
  }

  updateCompany (event: Event) {
    event.preventDefault();
    this.validate();
    if (!this.props.imageFetching) {
      this.sendCompanyInfo();
    }
  }

  render () {
    const ratingList: RatingType[] = this.getRatingList();
    return (
      <Fragment>
        <Spinner show={this.props.fetching}/>
        <Avatar
          uploadButtonType="accent outlined"
          uploadButtonName={this.props.t('portal.recruiter.profile.company.logo.label')}
          loading={this.props.imageFetching}
          previewImageUrl={this.state.logoPreview}
          change={this.changeLogoState.bind((this))}
          clearImageState={() => this.setState({ logoPreview: '' })}/>
        <form
          onSubmit={this.updateCompany.bind(this)}
          className="company-info-form">
          <FormGroup className="half-width">
            <InputControl
              required
              value={this.state.name}
              label={this.props.t('portal.recruiter.profile.company.name.label')}
              placeholder={this.props.t('portal.recruiter.profile.company.name.placeholder')}
              errorText={this.props.t('portal.recruiter.profile.company.name.error')}
              change={(name: string): void => this.setState({ name })}
              type="text"/>
          </FormGroup>
          <FormGroup className="half-width">
            <MultiSelect
              isMulti={false}
              items={this.props.brands}
              defaultValue={this.state.brandId}
              label={this.props.t('portal.recruiter.profile.company.brand.label')}
              change={value => this.setState({ brandId: value.id })}
              placeholder={this.props.t('portal.recruiter.profile.company.brand.placeholder')}/>
          </FormGroup>
          <FormGroup className="half-width">
            <MultiSelect
              label={this.props.t('portal.recruiter.profile.company.branch.label')}
              items={this.props.branches}
              error={this.state.branchError}
              errorText={this.props.t('portal.recruiter.profile.company.branch.error')}
              defaultValue={this.state.branchId}
              change={value => this.setState({ branchId: value.id, branchError: false })}
              isMulti={false}
              placeholder={this.props.t('portal.recruiter.profile.company.branch.placeholder')}/>
          </FormGroup>
          <FormGroup className="half-width">
            <Dropdown
              icon
              label={this.props.t('portal.recruiter.profile.company.rating')}
              disabled={ratingList.length === 0}
              change={value => this.setState({ stars: value.id })}
              value={ratingList[this.state.stars] && ratingList[this.state.stars].id}
              items={ratingList}/>
          </FormGroup>
          <FormGroup className="half-width">
            <AutosuggestSelect
              required
              label={this.props.t('portal.recruiter.profile.company.country.label')}
              items={this.props.address.country}
              defaultValue={this.state.country}
              change={(value: string) => this.setState({ country: value })}
              search={(value: string) => this.suggestCountry(value)}
              placeholder={this.props.t('portal.recruiter.profile.company.country.placeholder')}/>
          </FormGroup>
          <FormGroup className="half-width">
            <AutosuggestSelect
              label={this.props.t('portal.recruiter.profile.company.city.label')}
              required
              items={this.props.address.city}
              defaultValue={this.state.city}
              change={(value: string) => this.setState({ city: value })}
              search={(value: string) => this.suggestCity(value)}
              placeholder={this.props.t('portal.recruiter.profile.company.city.placeholder')}/>
          </FormGroup>
          <FormGroup className="full-width zip-code">
            <InputControl
              required
              label={this.props.t('portal.recruiter.profile.company.zip.label')}
              value={this.state.zipCode}
              placeholder={this.props.t('portal.recruiter.profile.company.zip.placeholder')}
              errorText={this.props.t('portal.recruiter.profile.company.zip.error')}
              change={(zipCode: string) => this.setState({ zipCode })}
              type="text"/>
          </FormGroup>
          <FormGroup className="half-width">
            <AutosuggestSelect
              required
              label={this.props.t('portal.recruiter.profile.company.street.label')}
              defaultValue={this.state.street}
              items={this.props.address.street}
              change={(value: string) => this.setState({ street: value })}
              search={(value: string) => this.suggestStreet(value)}
              placeholder={this.props.t('portal.recruiter.profile.company.street.placeholder')}/>
          </FormGroup>
          <FormGroup className="half-width billing-number">
            <InputControl
              required
              label={this.props.t('portal.recruiter.profile.company.building.label')}
              value={this.state.streetNumber}
              placeholder={this.props.t('portal.recruiter.profile.company.building.placeholder')}
              errorText={this.props.t('portal.recruiter.profile.company.building.error')}
              change={(streetNumber: string): void => this.setState({ streetNumber })}
              type="text"/>
          </FormGroup>
          <FormGroup className="full-width upload-btn-container">
            <label>Company Photos <span>(up to three)</span></label>
            <div className={cn([
              'btn accent outlined full-width big',
              { disabled: this.state.photos.length >= this.maxPhotosCount }
            ])}>
              <img
                src="/images/shared/upload-icon.svg"
                alt="upload"/>
              <Trans i18nKey="portal.recruiter.profile.company.file">
                Choose a file
              </Trans>
              <input
                type="file"
                ref={this.inputUpload}
                onChange={(event: Event) => this.changePhotosState(event.target.files[0])}
                accept="image/png, image/jpeg"
                className="upload-file-input"
              />
            </div>
            {this.state.imageSizeError && <span className="image-size-error">{ this.state.imageSizeError }</span>}
          </FormGroup>
          <PreviewImage
            removePreviewPhoto={this.removeCompanyPreviewPhoto.bind(this)}
            imageLoad={this.changeReadyCompanyPhotosState.bind(this)}
            files={this.state.photos}/>
          <FormGroup className="full-width">
            <InputControl
              required
              multiline
              maxLength={1000}
              label={this.props.t('portal.recruiter.profile.company.description.label')}
              value={this.state.description}
              errorText={this.props.t('portal.recruiter.profile.company.description.error')}
              placeholder={this.props.t('portal.recruiter.profile.company.description.placeholder')}
              change={(description: string): void => this.setState({ description })}
            />
          </FormGroup>
          <FormGroup className="form-btn-submit">
            {
              this.props.showBackButton &&
              <Button
                onClick={() => this.props.prev()}
                grey>
                <Trans i18nKey="portal.recruiter.profile.buttons.back">
                  Back
                </Trans>
              </Button>
            }
            <Button
              medium
              recruiter
              type="submit">
              {this.props.actionButtonTitle}
            </Button>
          </FormGroup>
        </form>
      </Fragment>
    );
  }

}
