import React from 'react';

import { Setting } from './image.setting';

export class AuthImage extends Setting {

  render () {
    return (
      <div className="auth-image-container">
        <img src={this.props.imageSrc}/>
      </div>
    );
  }

}
