// @flow
import { Component } from 'react';

import type { DropdownType } from 'components/controls/dropdown/dropdown.type';

import './application-dropdown.scss';

export type Props = {
  label: string,
  value: string,
  items: DropdownType[],
  createNewApplication: () => void,
  change: (item: DropdownType) => void
};

type State = {
  listOpen: boolean,
  selectedItem: ?DropdownType
}

export class Settings extends Component<Props, State> {

  static defaultProps = {
    items: [],
    value: '',
    createNewApplication: () => {},
    change: (item: DropdownType) => {}
  };

  state: State = {
    listOpen: false,
    selectedItem: null
  };

}
