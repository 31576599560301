// @flow
import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';

import moment from 'moment';
import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { Card } from 'components/layouts';
import { Modal } from 'components/dialogs';
import { Button, LinkButton } from 'components/buttons';

import { ModalHeader } from 'containers/portal/modal-container';

import { EducationsDto } from 'rest/candidates/educations/educations.dto';

import { StudyPlaceCard } from './study-place/study-place';

import type { Props } from './education.setting';
import { dispatchToProps, Settings, stateToProps } from './education.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class EducationCVModal extends Settings {

  componentWillUnmount () {
    this.props.resetEducations();
  }

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.createSuccess !== this.state.createSuccess) {
      this.setState({ createSuccess: nextProps.createSuccess });
    }
  }

  addNewStudyCard () {
    const newEducation: EducationsDto = new EducationsDto();
    newEducation.fromDate = moment();
    newEducation.toDate = moment();
    this.props.pushEducation(newEducation);
  }

  deleteEducation (educationId: string, index: number) {
    if (educationId) {
      this.props.deleteCandidateEducation(this.props.currentCandidate.id, educationId);
    }
    this.props.removeInternalEducation(index);
  }

  sendEducations (element: EducationsDto) {
    const education: EducationsDto = new EducationsDto(element);
    education.toDate = education.educationDate;
    const candidateId: string = this.props.currentCandidate.id;
    const educationIds: string[] = this.props.educations.map(item => item.id);
    const isUpdateEducation: ?EducationsDto = educationIds.find(elem => elem === element.id);
    if (isUpdateEducation) {
      this.props.updateCandidateEducation(candidateId, element.id, education);
    } else {
      this.props.createCandidateEducation(candidateId, education);
    }
  }

  saveEducations (event: Event) {
    event.preventDefault();
    this.props.educations.map(item => this.sendEducations(item));
    toast.success(this.props.t('portal.candidate.notifications.education-updated'));
  }

  render () {
    const backLink: string = `/portal/candidate/cv`;

    return (
      <Fragment>
        {
          this.state.createSuccess && <Redirect to={backLink}/>
        }
        <Modal closePath={backLink}>
          <div className="cv-modal mobile-cv-modal">
            <PerfectScrollbar className="content">
              <ModalHeader
                title={this.props.t('portal.candidate.cv.education.title')}
                backLinkUrl={backLink}
                backLinkTitle={this.props.t('portal.candidate.cv.education.back')}
                className="mobile-modal-header mobile-work-experience">
                <Button
                  onClick={() => this.addNewStudyCard()}
                  candidate
                  outlined>
                  <img
                    className="add-more"
                    src="/images/shared/add-icon-green.svg"/>
                  <Trans i18nKey="portal.candidate.cv.education.add">
                    Add Education
                  </Trans>
                </Button>
              </ModalHeader>
              <div className="cv-content">
                <form onSubmit={this.saveEducations.bind(this)}>
                  {
                    this.props.educations.map((item: EducationsDto, index: number) => {
                      return (
                        <StudyPlaceCard
                          education={item}
                          remove={() => this.deleteEducation(item.id, index)}
                          change={value => this.props.changeEducation(index, value)}
                          key={index}
                          number={this.props.educations.length - index}/>
                      );
                    })
                  }
                  <Card>
                    <div className="cv-modal-actions mobile-actions">
                      <Button
                        type="submit"
                        candidate>
                        <Trans i18nKey="portal.candidate.cv.education.buttons.save">
                          Save Changes
                        </Trans>
                      </Button>
                      <LinkButton
                        link={backLink}
                        grey>
                        <Trans i18nKey="portal.candidate.cv.education.buttons.cancel">
                          Cancel
                        </Trans>
                      </LinkButton>
                    </div>
                  </Card>
                </form>
              </div>
            </PerfectScrollbar>
          </div>
        </Modal>
      </Fragment>
    );
  }

}
