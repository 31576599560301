import { PaginationDto } from 'middleware/dto';

export class UnitDto extends PaginationDto {

  branchId: string[];

  constructor (data?: UnitDto) {
    super(data);
    if (data) {
      this.branchId = data.branchId;
    }
  }

}
