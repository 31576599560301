/* eslint padded-blocks: ["error", "never"] */
import React, { Component } from 'react';

import { Route } from 'middleware/route';

import { Header, Footer } from 'containers/portal';

import { PricingPage } from './pricing';
import { AboutUsPage } from './about-us';
import { HowItWorksPage } from './how-it-works';
import { PrivacyPolicyPage } from './privacy-policy';
import { QuestionsButtons } from '../../containers/landing/questions-button/questions-button';
import {
  GDPRMain,
  GDPRChat,
  GDPRCandidateInvite,
  GDPRCandidatePosts,
  GDPRCandidateProfileInfo,
  GDPRCandidateJobOfferViewed,
  GDPRCandidateJobOffers,
  GDPRCandidateSendApplication,
  GDPRCandidateFavCompanies,
  GDPRCandidateCompaniesViewedProfile,
  GDPRCandidateCompaniesFollowingCandidate,
  GDPRCandidateApplicationTemplate,
  GDPRCandidateSkills
} from './gdpr';
export class InformationPage extends Component {
  render () {
    return (
      <div className="information-container">
        <QuestionsButtons/>
        <main>
          <Header
            fixed
            notificationVisible={false}/>
          <Route
            path="/information/pricing"
            component={PricingPage}/>
          <Route
            path="/information/about-us"
            component={AboutUsPage}/>
          <Route
            path="/information/how-it-works"
            component={HowItWorksPage}/>
          <Route
            path="/information/privacy-policy"
            component={PrivacyPolicyPage}/>
          {/* For testing GDPR UI */}
          <Route
            path="/information/gdpr"
            exact
            component={GDPRMain}/>
          <Route
            path="/information/gdpr/chat"
            exact
            component={GDPRChat}/>
          <Route
            path="/information/gdpr/candidate-skills"
            exact
            component={GDPRCandidateSkills}/>
          <Route
            path="/information/gdpr/candidate-invite"
            exact
            component={GDPRCandidateInvite}/>
          <Route
            path="/information/gdpr/candidate-posts"
            exact
            component={GDPRCandidatePosts}/>
          <Route
            path="/information/gdpr/candidate-info"
            exact
            component={GDPRCandidateProfileInfo}/>
          <Route
            path="/information/gdpr/candidate-job-offer-viewed"
            exact
            component={GDPRCandidateJobOfferViewed}/>
          <Route
            path="/information/gdpr/candidate-job-offers"
            exact
            component={GDPRCandidateJobOffers}/>
          <Route
            path="/information/gdpr/candidate-send-application"
            exact
            component={GDPRCandidateSendApplication}/>
          <Route
            path="/information/gdpr/candidate-favorite-companies"
            exact
            component={GDPRCandidateFavCompanies}/>
          <Route
            path="/information/gdpr/candidate-companies-viewed-profile"
            exact
            component={GDPRCandidateCompaniesViewedProfile}/>
          <Route
            path="/information/gdpr/candidate-companies-following-candidate"
            exact
            component={GDPRCandidateCompaniesFollowingCandidate}/>
          <Route
            path="/information/gdpr/candidate-application-template"
            exact
            component={GDPRCandidateApplicationTemplate}/>
          <Footer/>
        </main>
      </div>
    );
  }
}
