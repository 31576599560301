export class JobTypeDto {

  id: string;
  name: string;

  constructor (data?: JobTypeDto) {
    if (data) {
      this.id = data.id || 'Employee';
      this.name = data.name || 'Full-time';
    }
  }

}
