// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { InvitesMembersDto } from 'rest/companies/team-members/team-members.dto';
import { RecruiterResponseDto } from 'rest/recruiter/recruiter.response.dto';

import { inviteRecruiterTeamMembers, getRecruiterTeamMembers } from 'services/portal/recruiter/account/account.actions';

import './invite-modal.scss';

export type Props = {
  t?: TransProps,
  open: boolean,
  errorText?: string,
  hideModal: () => void,
  inviteTeamMembersSuccess: boolean,
  currentUser: RecruiterResponseDto,
  getRecruiterTeamMembers: (companyId: string) => void,
  inviteRecruiterTeamMembers: (id: string, invites: InvitesMembersDto) => void
}

type State = {
  email: string,
  errorText: string,
  errorCollection: Set<string>,
  inviteTeamMembersSuccess: boolean
}

export class Setting extends Component<Props, State> {

  static defaultProps = {
    hideModal: () => {},
    inviteTeamMembersSuccess: false,
    getRecruiterTeamMembers: () => {},
    inviteRecruiterTeamMembers: () => {},
    currentUser: new RecruiterResponseDto()
  };

  state: State = {
    email: '',
    errorCollection: new Set(),
    errorText: '',
    inviteTeamMembersSuccess: false
  }

}

export const stateToProps = (state: GlobalState) => ({
  currentUser: state.portal.recruiter.account.current.currentUser,
  errorText: state.portal.recruiter.account.teamMembers.errorText,
  inviteTeamMembersSuccess: state.portal.recruiter.account.teamMembers.inviteTeamMembersSuccess
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getRecruiterTeamMembers,
  inviteRecruiterTeamMembers
}, dispatch);
