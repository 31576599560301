import { Component } from 'react';

import { TransProps } from 'react-i18next';

import { SkillItemDto } from 'rest/skill/skill-item.dto';

import './skill-item.scss';

type Props = {
  t?: TransProps,
  required?: boolean,
  skill: SkillItemDto,
  hideSkillLevel?: boolean,
  addToRequired?: (id: string) => void,
  removeFromRequired?: (id: string) => void,
  changeSkillLevel?: (value: string, id: string) => void,
  changeSkillPoints?: (value: string, id: string) => void,
};

export class Settings extends Component<Props> {

  static defaultProps: Props = {
    required: false,
    hideSkillLevel: false,
    addToRequired: () => {},
    changeSkillLevel: () => {},
    changeSkillPoints: () => {},
    removeFromRequired: () => {}
  };

}
