export const GET_CANDIDATE_POSTS_REQUEST = 'GET_CANDIDATE_POSTS_REQUEST';
export const GET_CANDIDATE_POSTS_SUCCESS = 'GET_CANDIDATE_POSTS_SUCCESS';
export const GET_CANDIDATE_POSTS_FAILURE = 'GET_CANDIDATE_POSTS_FAILURE';

export const GET_CANDIDATE_POST_BY_ID_REQUEST = 'GET_CANDIDATE_POST_BY_ID_REQUEST';
export const GET_CANDIDATE_POST_BY_ID_SUCCESS = 'GET_CANDIDATE_POST_BY_ID_SUCCESS';
export const GET_CANDIDATE_POST_BY_ID_FAILURE = 'GET_CANDIDATE_POST_BY_ID_FAILURE';

export const CREATE_CANDIDATE_POSTS_REQUEST = 'CREATE_CANDIDATE_POSTS_REQUEST';
export const CREATE_CANDIDATE_POSTS_SUCCESS = 'CREATE_CANDIDATE_POSTS_SUCCESS';
export const CREATE_CANDIDATE_POSTS_FAILURE = 'CREATE_CANDIDATE_POSTS_FAILURE';

export const UPDATE_CANDIDATE_POSTS_REQUEST = 'UPDATE_CANDIDATE_POSTS_REQUEST';
export const UPDATE_CANDIDATE_POSTS_SUCCESS = 'UPDATE_CANDIDATE_POSTS_SUCCESS';
export const UPDATE_CANDIDATE_POSTS_FAILURE = 'UPDATE_CANDIDATE_POSTS_FAILURE';

export const DELETE_CANDIDATE_POSTS_REQUEST = 'DELETE_CANDIDATE_POSTS_REQUEST';
export const DELETE_CANDIDATE_POSTS_SUCCESS = 'DELETE_CANDIDATE_POSTS_SUCCESS';
export const DELETE_CANDIDATE_POSTS_FAILURE = 'DELETE_CANDIDATE_POSTS_FAILURE';

export const RESET_POST_LIST_SUCCESS = 'RESET_POST_LIST_SUCCESS';
export const RESET_CREATION_POST_SUCCESS = 'RESET_CREATION_POST_SUCCESS';
