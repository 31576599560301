import React, { Component } from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { Trans, TransProps } from 'react-i18next';

import { connectStore } from 'middleware/connect';
import { getLocalization } from 'middleware/localization';

import { LinkButton } from 'components/buttons';

import { PortletBody, PortletContainer, PortletHeader } from 'components/portlet';

import {
  ApplicationsList,
  EditApplicationModal,
  PreviewApplicationModal,
  SentApplicationsList,
  SentLetterPreviewModal
} from 'containers/portal/candidate/applications';

import { CreateApplicationPage } from './create';

import { stateToProps } from './index.setting';

type Props = {
  t: TransProps,
  templatesStats?: number,
  sentApplicationsStats?: number
}

@connectStore(stateToProps, false, true)
export class ApplicationsPage extends Component<Props> {

  render () {
    return (
      <PortletContainer>
        <PortletHeader
          className="mobile-portlet-header-tabs"
          title={this.props.t('portal.candidate.applications.list.title')}>
          <div className={`mobile-portlet-navs-tabs mobile-md-show ${getLocalization() === 'de' ? 'german' : ''}`}>
            <NavLink
              className="btn mobile-link-btn"
              to="/portal/candidate/applications/templates">
              <Trans i18nKey="portal.candidate.applications.sidebar.tabs.templates.title">
                Templates
              </Trans>
              <span>({this.props.templatesStats > 0 ? this.props.templatesStats : '0'})</span>
            </NavLink>
            <NavLink
              className="btn mobile-link-btn"
              to="/portal/candidate/applications/sent">
              <Trans i18nKey="portal.candidate.applications.sidebar.tabs.letters.title">
                Sent Letters
              </Trans>
              <span>({this.props.sentApplicationsStats > 0 ? this.props.sentApplicationsStats : '0'})</span>
            </NavLink>
          </div>
          <LinkButton
            className="mobile-create-template-hide"
            link="/portal/candidate/applications/templates/create"
            candidate>
            <Trans i18nKey="portal.candidate.applications.list.create">
              Create New Letter
            </Trans>
          </LinkButton>
        </PortletHeader>
        <PortletBody className="mobile-applications-portlet-body">
          <Switch>
            <Route
              path="/portal/candidate/applications/templates"
              component={ApplicationsList}/>
            <Route
              path="/portal/candidate/applications/sent"
              component={SentApplicationsList}/>
            <Redirect
              exact
              from="/portal/candidate/applications"
              to="/portal/candidate/applications/templates"/>
          </Switch>
          <Route
            path="/portal/candidate/applications/templates/:templateId/edit"
            component={EditApplicationModal}/>
          <Route
            path="/portal/candidate/applications/templates/create"
            component={CreateApplicationPage}/>
          <Route
            path="/portal/candidate/applications/templates/:templateId/preview"
            component={PreviewApplicationModal}/>
          <Route
            path="/portal/candidate/applications/sent/:applicationId/preview"
            component={SentLetterPreviewModal}/>
        </PortletBody>
        <div className="mobile-create-letter-application mobile-md-show">
          <LinkButton
            link="/portal/candidate/applications/templates/create"
            candidate
            className="mobile-application-new-letter">
            <Trans i18nKey="portal.candidate.applications.list.create">
              Create New Letter
            </Trans>
          </LinkButton>
        </div>
      </PortletContainer>
    );
  }

}
