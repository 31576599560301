// @flow
import { Component } from 'react';
import typeof { Children } from 'react';

import './radio-group.scss';

export type Props = {
  label: string,
  inline: boolean,
  selected: string,
  className: string,
  defaultValue: string,
  children: Children[],
  change: (value: string) => void
}

type State = {
  selectedItem: string
}

export class Setting extends Component<Props, State> {

  static defaultProps = {
    label: '',
    selected: '',
    inline: false,
    change: () => {}
  };

  state: State = {
    selectedItem: this.props.defaultValue
  };

}
