export class FinishResetPasswordDto {

  key: string;
  newPassword: string;

  constructor (data?: FinishResetPasswordDto) {
    if (data) {
      this.key = data.key;
      this.newPassword = data.newPassword;
    }
  }

}
