// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { ImageResponseDto } from 'rest/images/images.response';
import { RecruiterDto } from 'rest/recruiter/recruiter.dto';
import { RecruiterResponseDto } from 'rest/recruiter/recruiter.response.dto';

import { hideModal } from 'services/portal/modal/modal.actions';

import { uploadFile } from 'services/portal/images/images.actions';
import { deleteCurrentRecruiter } from 'services/portal/recruiter/account/current/current.actions';
import { getCurrentCompanyRecruiter, updateCurrentRecruiter } from 'services/portal/recruiter/account/account.actions';

import './recruiter-info.scss';

export type Props = {
  t?: TransProps,
  hideModal: () => void,
  fetching: boolean,
  deleteAccount: boolean,
  image: ImageResponseDto,
  imageFetching: boolean,
  actionButtonTitle: string,
  getCurrentRecruiter: () => void,
  currentUser: RecruiterResponseDto,
  uploadFile: (file: ?File, type: string) => void,
  deleteCurrentRecruiter: (recruiterId: number) => void,
  updateCurrentRecruiter: (id: number, recruiter: RecruiterDto) => void
}

type State = {
  avatar: ?File,
  email: string,
  firstName: string,
  lastName: string,
  phoneNumber: string,
  avatarError: ?string,
  avatarPreview: ?string,
  phoneNumberError: boolean,
  isDeleteModalOpen: boolean
}

export class Settings extends Component<Props, State> {

  static defaultProps = {
    deleteAccount: true,
    actionButtonTitle: 'Next',
    getCurrentRecruiter: () => {},
    updateCurrentRecruiter: () => {},
    currentUser: new RecruiterResponseDto()
  };

  state: State = {
    email: '',
    avatar: null,
    lastName: '',
    firstName: '',
    phoneNumber: '',
    avatarError: null,
    avatarPreview: '',
    phoneNumberError: false,
    isDeleteModalOpen: false
  };

}

export const stateToProps = (state: GlobalState) => ({
  image: state.portal.images.avatarInfo,
  imageFetching: state.portal.images.avatarLoading,
  fetching: state.portal.recruiter.account.current.fetching,
  currentUser: state.portal.recruiter.account.current.currentUser
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  hideModal,
  getCurrentRecruiter: getCurrentCompanyRecruiter,
  updateCurrentRecruiter,
  uploadFile,
  deleteCurrentRecruiter
}, dispatch);
