// @flow
import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import { Modal } from 'components/dialogs';
import { ProfileContainer } from 'containers/portal/recruiter/candidates';

export class ProfilePage extends Component {

  render () {
    return (
      <Modal closePath="/portal/recruiter/candidates">
        <Route
          path="/portal/recruiter/candidates/:candidateId/profile"
          exact
          component={ProfileContainer}/>
      </Modal>
    );
  }

}
