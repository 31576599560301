// @flow
import * as actions from './sent-templates.constants';

import { ApplicationResponseDto } from 'rest/candidates/applications-template/applications-template.response.dto';

import type { SentTemplatesType } from './sent-templates.type';

const initialState: SentTemplatesType = {
  content: [],
  fetching: false,
  jobTitleId: '',
  currentPage: 0,
  hasMoreItems: true,
  currentLetter: null
};

export default function (state: SentTemplatesType = initialState, action): SentTemplatesType {
  let content = [];
  switch (action.type) {
    case actions.GET_SENT_TEMPLATES_SUCCESS:
      content = action.payload.content;
      return {
        ...state,
        content: action.payload.number ? [...state.content, ...content] : content,
        fetching: false,
        currentPage: action.payload.number + 1,
        hasMoreItems: action.payload.content.length > 0
      };

    case actions.GET_APPLICATION_TEMPLATE_BY_ID_SUCCESS:
      return {
        ...state,
        currentLetter: new ApplicationResponseDto(action.letter)
      };

    case actions.CHANGE_SENT_TEMPLATES_FILTER_SUCCESS:
      return {
        ...initialState,
        jobTitleId: action.jobTitleId
      };

    case actions.RESET_SENT_TEMPLATES_SUCCESS:
      return {
        ...initialState
      };

    default:
      return state;
  }
}
