export const CANDIDATE_REGISTER_REQUEST = 'CANDIDATE_REGISTER_REQUEST';
export const CANDIDATE_REGISTER_SUCCESS = 'CANDIDATE_REGISTER_SUCCESS';
export const CANDIDATE_REGISTER_FAILURE = 'CANDIDATE_REGISTER_FAILURE';

export const GET_CURRENT_CANDIDATE_REQUEST = 'GET_CURRENT_CANDIDATE_REQUEST';
export const GET_CURRENT_CANDIDATE_SUCCESS = 'GET_CURRENT_CANDIDATE_SUCCESS';
export const GET_CURRENT_CANDIDATE_FAILURE = 'GET_CURRENT_CANDIDATE_FAILURE';

export const CANDIDATE_SOCIAL_REGISTER_REQUEST = 'CANDIDATE_SOCIAL_REGISTER_REQUEST';
export const CANDIDATE_SOCIAL_REGISTER_SUCCESS = 'CANDIDATE_SOCIAL_REGISTER_SUCCESS';
export const CANDIDATE_SOCIAL_REGISTER_FAILURE = 'CANDIDATE_SOCIAL_REGISTER_FAILURE';

export const ACTIVATE_CANDIDATE_REQUEST = 'ACTIVATE_CANDIDATE_REQUEST';
export const ACTIVATE_CANDIDATE_SUCCESS = 'ACTIVATE_CANDIDATE_SUCCESS';
export const ACTIVATE_CANDIDATE_FAILURE = 'ACTIVATE_CANDIDATE_FAILURE';

export const DELETE_CANDIDATE_REQUEST = 'DELETE_CANDIDATE_REQUEST';
export const DELETE_CANDIDATE_SUCCESS = 'DELETE_CANDIDATE_SUCCESS';
export const DELETE_CANDIDATE_FAILURE = 'DELETE_CANDIDATE_FAILURE';

export const UPDATE_CURRENT_CANDIDATE_REQUEST = 'UPDATE_CURRENT_CANDIDATE_REQUEST';
export const UPDATE_CURRENT_CANDIDATE_SUCCESS = 'UPDATE_CURRENT_CANDIDATE_SUCCESS';
export const UPDATE_CURRENT_CANDIDATE_FAILURE = 'UPDATE_CURRENT_CANDIDATE_FAILURE';

export const CLEAR_CURRENT_CANDIDATE_SUCCESS = 'CLEAR_CURRENT_CANDIDATE_SUCCESS';

export const CHANGE_CURRENT_CANDIDATE_LOCALE_SUCCESS = 'CHANGE_CURRENT_CANDIDATE_LOCALE_SUCCESS';

export const DISABLE_ON_BOARDING_VIDEO_REQUEST = 'DISABLE_ON_BOARDING_VIDEO_REQUEST';
export const DISABLE_ON_BOARDING_VIDEO_SUCCESS = 'DISABLE_ON_BOARDING_VIDEO_SUCCESS';
export const DISABLE_ON_BOARDING_VIDEO_FAILURE = 'DISABLE_ON_BOARDING_VIDEO_FAILURE';
