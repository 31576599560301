import * as actions from './reset.constants';

import { initResetUserPassword } from 'rest/account/reset-password/init/init.rest';
import { finishResetUserPassword } from 'rest/account/reset-password/finish/finish.rest';

import { InitResetPasswordDto } from 'rest/account/reset-password/init/init.dto';
import { FinishResetPasswordDto } from 'rest/account/reset-password/finish/finish.dto';

const initResetPasswordRequest = () => ({
  type: actions.INIT_RESET_PASSWORD_REQUEST
});

const initResetPasswordSuccess = (email: string) => ({
  type: actions.INIT_RESET_PASSWORD_SUCCESS,
  email
});

const initResetPasswordFailure = (error: string) => ({
  type: actions.INIT_RESET_PASSWORD_FAILURE,
  error
});

const finishResetPasswordRequest = () => ({
  type: actions.FINISH_RESET_PASSWORD_REQUEST
});

const finishResetPasswordSuccess = () => ({
  type: actions.FINISH_RESET_PASSWORD_SUCCESS
});

const finishResetPasswordFailure = (error: string) => ({
  type: actions.FINISH_RESET_PASSWORD_FAILURE,
  error
});

export const initResetPassword = (data: InitResetPasswordDto) => {
  return dispatch => {
    dispatch(initResetPasswordRequest());
    return initResetUserPassword(data)
      .then(() => dispatch(initResetPasswordSuccess(data.email)))
      .catch(error => dispatch(initResetPasswordFailure(error)));
  };
};

export const finishResetPassword = (data: FinishResetPasswordDto) => {
  return dispatch => {
    dispatch(finishResetPasswordRequest());
    return finishResetUserPassword(data)
      .then(() => dispatch(finishResetPasswordSuccess()))
      .catch(error => dispatch(finishResetPasswordFailure(error)));
  };
};
