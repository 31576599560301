import axios from 'axios';

import { RequestSkillDto } from './request.dto';

export async function requestNewSkill (data: RequestSkillDto): Promise {
  try {
    return await axios.post(`/skill/request`, data);
  } catch (error) {
    throw error;
  }
}
