import React from 'react';
import { Trans } from 'react-i18next';

import { LinkButton } from 'components/buttons';

import { findEvent } from 'middleware/notifications/find-event';
import { connectStore } from 'middleware/connect';

import * as eventNames from './request-software-decision.constants';
import { Setting, stateToProps } from './request-software-decision.setting';

@connectStore(stateToProps, null, true)
export class RequestSoftwareDecisionTemplate extends Setting {

  hideModal = () => {
    this.props.hideModal();
  };

  initAcceptedStatus () {
    const { eventData, t, candidateId } = this.props;
    return <>
      <h3>
        <Trans i18nKey="portal.notifications.suggest.software.title.accepted">
          Decision with your suggested software!
        </Trans>
      </h3>
      <p>
        <Trans i18nKey="portal.notifications.suggest.software.description.added.0">
          We&#39;ve added new software:
        </Trans>
        &nbsp;
        <strong>
          {
            !findEvent(eventData, eventNames.NAME)
              ? t('portal.notifications.request-skill.empty')
              : findEvent(eventData, eventNames.NAME)
          }
        </strong>
        &nbsp;
        <Trans i18nKey="portal.notifications.suggest.software.description.added.1">
          for
        </Trans>
        &nbsp;
        <Trans i18nKey="portal.notifications.suggest.software.description.added.2">
          (all titles connected) to our system. Click
        </Trans>
        {
          candidateId
            ? <LinkButton
              inline
              onClick={this.hideModal}
              link="/portal/candidate/balance-board/edit">
              <Trans i18nKey="portal.notifications.suggest.software.description.added.3">
                here
              </Trans>
            </LinkButton>
            : <LinkButton
              inline
              onClick={this.hideModal}
              link="/portal/recruiter/job-offers">
              <Trans i18nKey="portal.notifications.suggest.software.description.added.3">
                here
              </Trans>
            </LinkButton>
        }
        <Trans i18nKey="portal.notifications.suggest.software.description.added.4">
          to add to your BB!
        </Trans>
      </p>
    </>;
  }

  initDeclinedStatus () {
    return <>
      <h3>
        <Trans i18nKey="portal.notifications.suggest.software.title.declined">
          Decision with your suggested software!
        </Trans>
      </h3>
      <p>
        <Trans i18nKey="portal.notifications.suggest.software.description.declined.0">
          Sorry, but your requested software
        </Trans>
      </p>
    </>;
  }

  render () {
    const { eventData, publishDate } = this.props;
    return (
      <div className="notification-item-content flexible">
        <div className="logo-container">
          <img src="/images/notifications/userpick.svg" alt="logo"/>
        </div>
        <div className="content">
          {
            eventData.length && findEvent(eventData, eventNames.REQUEST_SKILL_STATUS) === eventNames.STATUS_ACCEPTED
              ? this.initAcceptedStatus()
              : this.initDeclinedStatus()
          }
          <span className="publish-date">{publishDate}</span>
        </div>
      </div>
    );
  }

}
