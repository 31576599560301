import { CandidateSkillItem } from 'rest/candidates/skills/candidate-skill-item.dto';

export class CandidateSkillsDto {

  emptySkills: boolean;
  documents: CandidateSkillItem[];
  softSkills: CandidateSkillItem[];
  languageSkills: CandidateSkillItem[];
  softwareSkills: CandidateSkillItem[];
  professionalSkills: CandidateSkillItem[];

  constructor (data?: CandidateSkillsDto) {
    if (data) {
      this.documents = data.documents;
      this.softSkills = data.softSkills;
      this.emptySkills = data.emptySkills;
      this.languageSkills = data.languageSkills;
      this.softwareSkills = data.softwareSkills;
      this.professionalSkills = data.professionalSkills;
    }
  }

}
