import React from 'react';

import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { getUserType } from 'middleware/user-type';

import { LinkButton } from 'components/buttons';

import { Settings } from './poster.setting';

@connectTranslation()
export class NotFoundPoster extends Settings {

  render () {
    const userType = getUserType();

    return (
      <div className="not-found-poster mobile-not-found-poster">
        <div className="title">
          <Trans i18nKey="portal.not-found.title">
            Page not found
          </Trans>
        </div>
        <div className="description">
          <Trans i18nKey="portal.not-found.description">
            Sorry, the page couldn`t be found. Try clicking on a &quot;Go back&quot; button or on the logo above.
          </Trans>
        </div>
        <div className="actions">
          {
            userType === 'recruiter' && <LinkButton
              link="/portal/recruiter/balance-board"
              outlined
              recruiter>
              <Trans i18nKey="portal.not-found.action">
                Go back
              </Trans>
            </LinkButton>
          }
          {
            userType === 'candidate' && <LinkButton
              link="/portal/candidate/balance-board"
              outlined
              candidate>
              <Trans i18nKey="portal.not-found.action">
                Go back
              </Trans>
            </LinkButton>
          }
          {
            userType === 'guest' && <LinkButton
              link={this.props.redirectPath ? this.props.redirectPath : '/portal/guest/jobs'}
              outlined
              recruiter>
              <Trans i18nKey="portal.not-found.action">
                Go back
              </Trans>
            </LinkButton>
          }
        </div>
      </div>
    );
  }

}
