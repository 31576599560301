// @flow
import React from 'react';
import { Redirect } from 'react-router-dom';

import { toast } from 'react-toastify';
import { Trans } from 'react-i18next';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { Card } from 'components/layouts';
import { Button, LinkButton } from 'components/buttons';
import { FormGroup, InputControl } from 'components/controls';

import { ApplicationDto } from 'rest/candidates/applications-template/applications-template.dto';

import type { Props } from './modal.setting';
import { Settings, stateToProps, dispatchToProps } from './modal.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class CreateApplicationModal extends Settings {

  componentWillUnmount () {
    this.props.resetApplications();
  }

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.redirect !== this.state.redirect) {
      this.setState({ redirect: nextProps.redirect });
    }
  }

  createApplication (event: Event) {
    event.preventDefault();
    const data: ApplicationDto = new ApplicationDto(this.state);
    this.props.createCandidateApplication(this.props.currentCandidate.id, data);
    toast.success(this.props.t('portal.candidate.notifications.application-created'));
  }

  render () {
    const backLink: string = '/portal/candidate/applications';
    return (
      <div className="create-application-modal create-application-modal-mobile">
        {
          this.state.redirect && <Redirect to={backLink}/>
        }
        <PerfectScrollbar className="content">
          <Card className="mobile-application-modal">
            <div className="mobile-application-modal-title">
              <Trans i18nKey="portal.candidate.applications.mobile.create">
                Application Creation
              </Trans>
            </div>
            <LinkButton
              className="application-back-link"
              link={backLink}
              inline>
              <Trans i18nKey="portal.candidate.applications.edit.back">
                Back to Application List
              </Trans>
              <div className="modal-application-back">&times;</div>
            </LinkButton>
            <form
              onSubmit={this.createApplication.bind(this)}
              className="create-application-form">
              <div className="form-inputs">
                <FormGroup className="half-width application-title">
                  <InputControl
                    required
                    value={this.state.subject}
                    change={(value: string): void => this.setState({ subject: value })}
                    label={this.props.t('portal.candidate.applications.edit.name.label')}
                    pattern=".+"
                    maxLength={70}
                    placeholder={this.props.t('portal.candidate.applications.edit.name.placeholder')}
                    type="text"/>
                </FormGroup>
                <FormGroup className="full-width">
                  <InputControl
                    value={this.state.body}
                    required
                    pattern=".+"
                    change={(value: string): void => this.setState({ body: value })}
                    label={this.props.t('portal.candidate.applications.edit.description.label')}
                    placeholder={this.props.t('portal.candidate.applications.edit.description.placeholder')}
                    multiline
                    maxLength={1000}
                    notes={`${this.state.body.length} / 1000`}
                  />
                </FormGroup>
              </div>
              <FormGroup className="form-btn-submit mobile-form-submit">
                <Button
                  medium
                  candidate
                  type="submit">
                  <Trans i18nKey="portal.candidate.applications.edit.buttons.create">
                    Create Application
                  </Trans>
                </Button>
                <LinkButton
                  grey
                  className="mobile-application-form-cancel"
                  link={backLink}>
                  <Trans i18nKey="portal.candidate.applications.edit.buttons.cancel">
                    Cancel
                  </Trans>
                </LinkButton>
              </FormGroup>
            </form>
          </Card>
        </PerfectScrollbar>
      </div>
    );
  }

}
