import { PaginationDto } from 'middleware/dto';

export class JobOffersParams extends PaginationDto {

  unitId: string;
  placeId: string;
  jobType: string;
  brandId: string;
  company: string;
  branchId: string;
  location: string;
  minSalary: number;
  maxSalary: number;
  companyId: string;
  jobTitleId: string;
  followingType: string;
  availableFrom: string;
  publishedFrom: number;
  extraFields: string[] = [];
  searchByPlaceId: boolean;
  professionalSkills: string[] = [];

  constructor (data?: JobOffersParams) {
    super(data);
    if (data) {
      this.unitId = data.unitId;
      this.placeId = data.placeId;
      this.jobType = data.jobType;
      this.company = data.company;
      this.brandId = data.brandId;
      this.branchId = data.branchId;
      this.location = data.location;
      this.minSalary = data.minSalary;
      this.maxSalary = data.maxSalary;
      this.companyId = data.companyId;
      this.jobTitleId = data.jobTitleId;
      this.workingTime = data.workingTime;
      this.extraFields = data.extraFields || [];
      this.followingType = data.followingType;
      this.availableFrom = data.availableFrom;
      this.publishedFrom = data.publishedFrom;
      this.searchByPlaceId = data.searchByPlaceId;
      this.professionalSkills = data.professionalSkills || [];
    }
  }

  get filterCount (): number {
    let filterCount = [];
    filterCount.push(this.unitId ? 1 : 0);
    filterCount.push(this.company ? 1 : 0);
    filterCount.push(this.brandId ? 1 : 0);
    filterCount.push(this.branchId ? 1 : 0);
    filterCount.push(this.jobType ? 1 : 0);
    filterCount.push(this.location ? 1 : 0);
    filterCount.push(this.jobTitleId ? 1 : 0);
    filterCount.push(this.workingTime ? 1 : 0);
    filterCount.push((this.availableFrom &&
      new Date(this.availableFrom).toDateString() !== new Date().toDateString()) ? 1 : 0);
    filterCount.push(this.publishedFrom ? 1 : 0);
    filterCount.push(this.searchByPlaceId ? 1 : 0);
    filterCount.push((this.professionalSkills.length > 0) ? 1 : 0);
    filterCount.push((this.minSalary > 0 && this.maxSalary > 0) ? 1 : 0);
    return filterCount.reduce((a, b) => a + b, 0);
  }

}
