// @flow
import * as actions from './search.constants';

import { Action, Dispatch } from 'redux';

const jobsSkills = (skills: string[]): Action => ({
  type: actions.SEARCH_JOBS_SKILLS,
  skills: skills
});

const jobsBranch = (branchId: string): Action => ({
  type: actions.SEARCH_JOBS_BRANCH,
  branchId: branchId
});

const jobsUnit = (unitId: string): Action => ({
  type: actions.SEARCH_JOBS_UNIT,
  unitId: unitId
});

const jobsJobTitle = (jobTitleId: string): Action => ({
  type: actions.SEARCH_JOBS_JOB_TITLE,
  jobTitleId: jobTitleId
});

const jobsLocation = (location: string): Action => ({
  type: actions.SEARCH_JOBS_LOCATION,
  location: location
});

const jobsCompanyName = (companyName: string): Action => ({
  type: actions.SEARCH_JOBS_COMPANY_NAME,
  companyName: companyName
});

const jobsSalaryMinimum = (minSalary: number): Action => ({
  type: actions.SEARCH_JOBS_SALARY_MIN,
  minSalary
});

const jobsSalaryMaximum = (maxSalary: number): Action => ({
  type: actions.SEARCH_JOBS_SALARY_MAX,
  maxSalary
});

const jobsWorkingTime = (workingTime: number): Action => ({
  type: actions.SEARCH_JOBS_WORKING_TIME,
  workingTime: workingTime
});

const jobsAvailableFrom = (availableFrom: string): Action => ({
  type: actions.SEARCH_JOBS_AVAILABLE_FROM,
  availableFrom: availableFrom
});

const jobsJobType = (jobType: string): Action => ({
  type: actions.SEARCH_JOBS_JOB_TYPE,
  jobType: jobType
});

const jobsPeriodOfPublish = (publishedFrom: number): Action => ({
  type: actions.SEARCH_JOBS_PERIOD_OF_PUBLISH,
  publishedFrom
});

const jobsCloseToMe = (closeToMe: boolean): Action => ({
  type: actions.SEARCH_JOBS_CLOSE_TO_ME,
  closeToMe: closeToMe
});

const resetJobsFilter = (): Action => ({
  type: actions.SEARCH_JOBS_RESET
});

const resetJobsSkillsFilter = (): Action => ({
  type: actions.SEARCH_JOBS_SKILLS_RESET
});

export function clearJobsSearch () {
  return (dispatch: Dispatch) => {
    dispatch(resetJobsFilter());
  };
}

export function clearJobsSearchSkills () {
  return (dispatch: Dispatch) => {
    dispatch(resetJobsSkillsFilter());
  };
}

export function saveJobsSkillsFilter (skills: string[]) {
  return (dispatch: Dispatch) => {
    dispatch(jobsSkills(skills));
  };
}

export function saveSearchJobsBranch (branchId: string) {
  return (dispatch: Dispatch) => {
    dispatch(jobsBranch(branchId));
  };
}

export function saveSearchJobsUnit (unitId: string) {
  return (dispatch: Dispatch) => {
    dispatch(jobsUnit(unitId));
  };
}

export function saveSearchJobsJobTitle (jobTitleId: string) {
  return (dispatch: Dispatch) => {
    dispatch(jobsJobTitle(jobTitleId));
  };
}

export function saveSearchJobsLocation (location: string) {
  return (dispatch: Dispatch) => {
    dispatch(jobsLocation(location));
  };
}

export function saveSearchJobsCompanyName (companyName: string) {
  return (dispatch: Dispatch) => {
    dispatch(jobsCompanyName(companyName));
  };
}

export function saveSearchJobsSalaryMinimum (salaryMinimum: number) {
  return (dispatch: Dispatch) => {
    dispatch(jobsSalaryMinimum(salaryMinimum ? parseFloat(salaryMinimum) : null));
  };
}

export function saveSearchJobsSalaryMaximum (salaryMaximum: number) {
  return (dispatch: Dispatch) => {
    dispatch(jobsSalaryMaximum(salaryMaximum ? parseFloat(salaryMaximum) : null));
  };
}

export function saveSearchJobsWorkingTime (workingTime: number) {
  return (dispatch: Dispatch) => {
    dispatch(jobsWorkingTime(workingTime));
  };
}

export function saveSearchJobsAvailableFrom (availableFrom: string) {
  return (dispatch: Dispatch) => {
    dispatch(jobsAvailableFrom(availableFrom));
  };
}

export function saveSearchJobsJobType (jobType: string) {
  return (dispatch: Dispatch) => {
    dispatch(jobsJobType(jobType));
  };
}

export function saveSearchJobsPeriodOfPublish (periodOfPublish: string) {
  return (dispatch: Dispatch) => {
    dispatch(jobsPeriodOfPublish(periodOfPublish));
  };
}

export function saveSearchJobsCloseToMe (closeToMe: boolean) {
  return (dispatch: Dispatch) => {
    dispatch(jobsCloseToMe(closeToMe));
  };
}
