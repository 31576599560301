// @flow

import { initRestParams } from 'middleware/rest';

export const setToken = (token: string, refreshToken: string) => {
  localStorage.setItem('ej_token', token);
  localStorage.setItem('ej_refresh_token', refreshToken);
  initRestParams();
};

export const setNameAndLastNameAndEmail = (firstName: string, lastName: string, email: string) => {
  localStorage.setItem('ej_first_name', firstName);
  localStorage.setItem('ej_last_name', lastName);
  localStorage.setItem('ej_email', email);
};

export const setUserId = (userId: string) => {
  localStorage.setItem('ej_user_id', userId);
};

export const getUserId = (): ?string => {
  return localStorage.getItem('ej_user_id');
};

export const getToken = (): ?string => {
  return localStorage.getItem('ej_token') || sessionStorage.getItem('ej_token');
};

export const isLoggedIn = (): Boolean => {
  return (getToken() != null) && (getToken() !== undefined);
};

export const getUserName = (): string => {
  return localStorage.getItem('ej_first_name') + ' ' + localStorage.getItem('ej_last_name');
};

export const getEmail = (): string => {
  return localStorage.getItem('ej_email');
};

const removeToken = () => {
  localStorage.removeItem('ej_token');
  sessionStorage.removeItem('ej_token');
};

export const signOut = () => {
  removeToken();
  initRestParams();
  localStorage.clear();
  localStorage.setItem('ej_user_is_exists', JSON.stringify('Hi!'));
};

export const getUserType = (): string => {
  return localStorage.getItem('ej_type');
};
