// @flow
import { Component } from 'react';

type Props = {
  location: Location
}

export type State = {
  redirectLink: string
}

export class Setting extends Component<Props, State> {

  state: State = {
    redirectLink: ''
  };

}
