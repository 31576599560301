// @flow
import React, { Fragment } from 'react';

import moment from 'moment';
import { Trans } from 'react-i18next';

import { PaginationDto } from 'middleware/dto/pagination';

import { connectStore } from 'middleware/connect';
import { getData, setData } from 'middleware/job-offer-storage';

import { SkillsDto } from 'rest/skill/skills.dto';
import { JobOfferDto } from 'rest/job-offers/job-offers.dto';
import { SalaryRangeDto } from 'rest/job-offers/job-offers.salary-range.dto';

import { Button } from 'components/buttons';
import { Spinner, SalaryRange } from 'components/elements';
import { DateControl, FormGroup, InputControl, MultiSelect, SwitchControl } from 'components/controls';
import { Settings, stateToProps, dispatchToProps } from './general-info.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class GeneralInfoTab extends Settings {

  componentDidMount () {
    this.fetchJobTitles();
    this.props.getAllUnits();
    const savedGeneralInfo = getData('ej_general_job_offer');
    if (this.state !== savedGeneralInfo) {
      this.setState({ ...savedGeneralInfo });
    }
  }

  fetchJobTitles () {
    const query: PaginationDto = new PaginationDto();
    query.size = 500;
    query.filterUnitIds = [ this.state.unitId ];
    this.props.getAllJobTitles(query);
  }

  changeUnitState (unitId: string) {
    this.setState({
      unitId,
      jobTitleId: '',
      unitError: false
    }, () => this.fetchJobTitles());
  }

  changeGeneralInfoState (field: string, value: string) {
    if (field === 'jobTitleId') {
      this.setState({ jobTitleError: false });
    }
    this.setState({ [field]: value });
  }

  validate (cb: (isValid: boolean) => void) {
    this.setState({
      unitError: !this.state.unitId,
      jobTitleError: !this.state.jobTitleId
    }, () => {
      const isUnitOrJobTitleError: boolean = !this.state.unitError && !this.state.jobTitleError;
      const valid: boolean = isUnitOrJobTitleError && !this.state.salaryRangeError && !this.state.salaryError;
      cb(valid);
    });
  }

  handleSkillsUpdate () {
    const jobTitlesDifferences = !!getData('ej_general_job_offer') &&
      (this.state.jobTitleId !== getData('ej_general_job_offer').jobTitleId);
    if (jobTitlesDifferences) {
      const skillsData: SkillsDto = getData('ej_skills_job_offer');
      skillsData.professionalSkills = [];
      setData('ej_skills_job_offer', skillsData);
    }
  }

  saveDraft () {
    const generalData: JobOfferDto = new JobOfferDto(this.state);
    generalData.jobType = this.state.jobType.id || this.state.jobType;
    this.handleSkillsUpdate();
    setData('ej_general_job_offer', generalData);
  }

  navigateToNextPage (event: Event) {
    event.preventDefault();
    this.validate(valid => {
      if (valid && !this.state.errorCollection.size) {
        this.saveDraft();
        this.props.next();
      }
    });
  }

  render () {
    return (
      <Fragment>
        <form
          onSubmit={this.navigateToNextPage.bind(this)}
          className="general-info-form">
          <FormGroup className="half-width">
            <MultiSelect
              label={this.props.t('portal.recruiter.balance-board.job-offer.general.unit.label')}
              error={this.state.unitError}
              items={this.props.units}
              defaultValue={this.state.unitId}
              change={value => this.changeUnitState(value.id)}
              isMulti={false}
              placeholder={this.props.t('portal.recruiter.balance-board.job-offer.general.unit.placeholder')}/>
          </FormGroup>
          <FormGroup className="half-width">
            <MultiSelect
              label={this.props.t('portal.recruiter.balance-board.job-offer.general.job-title.label')}
              error={this.state.jobTitleError}
              items={this.props.jobTitles}
              defaultValue={this.state.jobTitleId}
              change={value => this.changeGeneralInfoState('jobTitleId', value.id)}
              isMulti={false}
              placeholder={this.props.t('portal.recruiter.balance-board.job-offer.general.job-title.placeholder')}/>
          </FormGroup>
          <FormGroup className="half-width reference-number">
            <InputControl
              maxLength={100}
              placeholder={this.props.t('portal.recruiter.balance-board.job-offer.general.reference.placeholder')}
              value={this.state.referenceNumber}
              label={this.props.t('portal.recruiter.balance-board.job-offer.general.reference.label')}
              change={(referenceNumber: string): void => this.setState({ referenceNumber })}
              type="text"/>
          </FormGroup>
          <FormGroup className="full-width">
            <InputControl
              required
              multiline
              maxLength={1000}
              label={this.props.t('portal.recruiter.balance-board.job-offer.general.job-description.label')}
              placeholder={this.props.t('portal.recruiter.balance-board.job-offer.general.job-description.placeholder')}
              value={this.state.jobDescription}
              change={(jobDescription: string): void => this.setState({ jobDescription })}
              type="text"/>
          </FormGroup>
          <FormGroup className="full-width">
            <SwitchControl
              label={this.props.t('portal.recruiter.balance-board.job-offer.general.job-type.label')}
              value={(this.state.jobType && this.state.jobType.id) || this.state.jobType}
              change={jobType => this.setState({ jobType })}
              items={[
                this.props.t('portal.recruiter.balance-board.job-offer.general.job-type.items.0'),
                this.props.t('portal.recruiter.balance-board.job-offer.general.job-type.items.1'),
                this.props.t('portal.recruiter.balance-board.job-offer.general.job-type.items.2')
              ]}/>
          </FormGroup>
          <SalaryRange
            defaultValue={this.state.salaryRange}
            change={(salary: SalaryRangeDto, error: boolean) =>
              this.setState({
                salaryRange: salary,
                salaryError: error
              })}
            className="max-width"/>
          <div className="working-time">
            <FormGroup className="working-time-field-width">
              <InputControl
                required
                maxLength={4}
                minLength={1}
                pattern="^\d*(\.|,)?\d{1}$"
                label={this.props.t('portal.recruiter.balance-board.job-offer.general.working-time.label')}
                value={this.state.workingHoursPerMonth}
                change={(workingHoursPerMonth: string): void => this.setState({ workingHoursPerMonth })}
                placeholder={this.props.t('portal.recruiter.balance-board.job-offer.general.working-time.placeholder')}
                errorText={this.props.t('portal.recruiter.candidates.filters.default.working-time.error')}
                type="text"/>
            </FormGroup>
            <FormGroup className="working-time-field-width">
              <DateControl
                required
                recruiter
                monthsCount={1}
                value={this.state.availableFrom}
                change={(availableFrom: moment): void => this.setState({ availableFrom })}
                label={this.props.t('portal.recruiter.balance-board.job-offer.general.available-from')}/>
            </FormGroup>
          </div>
          <FormGroup className="full-width form-buttons">
            <Button
              onClick={() => this.props.prev()}
              grey>
              <Trans i18nKey="portal.recruiter.balance-board.job-offer.buttons.back">
                Back
              </Trans>
            </Button>
            <Button
              type="submit"
              medium
              recruiter>
              <Trans i18nKey="portal.recruiter.balance-board.job-offer.buttons.next">
                Next
              </Trans>
            </Button>
          </FormGroup>
        </form>
        <Spinner show={this.props.fetching}/>
      </Fragment>
    );
  }

}
