import React from 'react';

import { Trans } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { Card } from 'components/layouts';
import { LinkButton } from 'components/buttons';

import { Settings, dispatchToProps, stateToProps } from './modal.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class AskMeModal extends Settings {

  componentDidMount () {
    this.props.requestAskMeContent(this.props.match.params['pageKey'], this.props.match.params['userType']);
  }

  componentWillUnmount () {
    this.props.resetAskMeContent();
  }

  render () {
    return (
      <div className="ask-me-modal mobile-ask-me-modal">
        <Card className="ask-me-container">
          <div className="ask-me-content">
            <PerfectScrollbar className="ask-me-preview">
              <LinkButton
                link={`/portal/${this.props.match.params['userType']}/${this.props.match.params['pageKey']}`}
                className="inline modal-btn">
                <Trans i18nKey="portal.ask-me.back">
                  Back to Application
                </Trans>
                <div className="modal-ask-me-back">&times;</div>
              </LinkButton>
              <div className="ask-me-image">
                {
                  (this.props.video && this.props.video.url) &&
                  <video height="240" controls poster={this.props.video.posterImage}>
                    <source src={this.props.video.url} type="video/mp4"/>
                  </video>
                }
                {
                  (this.props.video && this.props.video.urlReuse) &&
                  <video height="240" controls poster={this.props.video.posterImage}>
                    <source src={this.props.video.urlReuse} type="video/mp4"/>
                  </video>
                }
              </div>
              <div className="ask-me-image">
                {
                  (this.props.image && this.props.image.url) && <img src={this.props.image.url}/>
                }
              </div>
              <div
                className="ask-me-text"
                dangerouslySetInnerHTML={{ __html: this.props.content }}>
              </div>
              <div className="ask-me-footer">
                <div className="information">
                  <Trans i18nKey="portal.ask-me.questions">
                    If you still have questions, you can write to our great support team.
                  </Trans>
                </div>
                <div className="email">
                  <a href="mailto:support@invent-science.com">support@invent-science.com</a>
                </div>
              </div>
            </PerfectScrollbar>
          </div>
        </Card>
      </div>
    );
  }

}
