import axios from 'axios';

import { AddressDto } from './address.dto';
import { AddressResponseDto } from './address.response.dto';

export async function getSuggestedAddress (filters?: AddressDto): Promise<AddressResponseDto[]> {
  try {
    return await axios.get(`/address/search`, {
      params: filters
    });
  } catch (error) {
    throw error;
  }
}
