import axios from 'axios';

import { InitResetPasswordDto } from './init.dto';

export async function initResetUserPassword (data: InitResetPasswordDto): Promise<InitResetPasswordDto> {
  try {
    return await axios.post('/account/reset-password/init', data);
  } catch (error) {
    if (error.response.data.status === 400) {
      throw error.response.data.title;
    }
  }
}
