// @flow
import React from 'react';

import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Setting } from './social-buttons.setting';

@connectTranslation()
export class SocialButtons extends Setting {

  render () {
    return (
      <div>
        <div className="social-buttons">
          {/* <a
            disabled={!this.props.facebook}
            href={this.props.facebook}
            target="_self"
            className="btn social-login-btn facebook">
            Facebook
          </a> */}
          <a
            disabled={!this.props.google}
            href={this.props.google}
            target="_self"
            className="btn social-login-btn google">
            Google
          </a>
        </div>
        <div className="auth-divider">
          <span>
            <Trans i18nKey="auth.sign-in.divider">
              or
            </Trans>
          </span>
        </div>
      </div>
    );
  }

}
