import { Component } from 'react';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { EducationsDto } from 'rest/candidates/educations/educations.dto';

import './education-section.scss';
import './education-section.responsive.scss';

type Props = {
  t?: TransProps,
  educations: EducationsDto[]
}

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  educations: state.portal.candidate.cv.education.fetchedContent
});
