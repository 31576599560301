import moment from 'moment';

import { ListDto } from 'middleware/dto/list';

const config = {
  nextSlot: 15,
  breakTime: [[]],
  startTime: '00:00',
  endTime: '24:00'
};

export function generateTimeItems (): ListDto[] {
  let i = 0;
  let slotTime = moment(config.startTime, 'HH:mm');
  const endTime = moment(config.endTime, 'HH:mm');

  function isInBreak (slotTime, breakTimes) {
    return breakTimes.some(br => {
      return slotTime >= moment(br[0], 'HH:mm') && slotTime < moment(br[1], 'HH:mm');
    });
  }

  let times = [];
  while (slotTime < endTime) {
    if (!isInBreak(slotTime, config.breakTime)) {
      times[i] = new ListDto();
      times[i].id = slotTime.format('HH:mm');
      times[i].name = times[i].id;
    }
    slotTime = slotTime.add(config.nextSlot, 'minutes');
    i++;
  }

  return times;
}
