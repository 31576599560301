import { PaginationDto } from 'middleware/dto';

export class MessagesRequestDto extends PaginationDto {

  files: File;
  message: string;

  constructor (data?: MessagesRequestDto) {
    super(data);
    if (data) {
      this.files = data.files;
      this.message = data.message;
    }
  }

}
