import { MatchedCandidatesDto } from 'rest/companies/job-offers-matches/matched-candidates/matched-candidates.dto';

export class StatsMatchedCandidatesDto {

  jobOfferId: string;
  jobTitleName: string;
  matchedCandidate: MatchedCandidatesDto;
  jobOfferMatchPoints: number;

  constructor (data?: StatsMatchedCandidatesDto) {
    if (data) {
      this.jobOfferId = data.jobOfferId;
      this.jobTitleName = data.jobTitleName;
      this.matchedCandidate = new MatchedCandidatesDto(data.matchedCandidate);
      this.jobOfferMatchPoints = data.jobOfferMatchPoints;
    }
  }

}
