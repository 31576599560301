import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { sendContactQuestion } from 'services/landing/contact/contact.actions';

import { RecruiterResponseDto } from 'rest/recruiter/recruiter.response.dto';
import { SubscriptionDto } from 'rest/companies/subscription/subscription.dto';

import {
  subscribeRecruiterCompany
} from 'services/portal/recruiter/account/account.actions';
import { showModal } from 'services/portal/modal/modal.actions';

import './sepa-payment-modal.scss';

export type Props = {
  t?: TransProps,
  isOpen: boolean,
  currentUser: {
    firstName: string,
    lastName: string,
    email: string
  },
  companyId: number,
  currentUser?: RecruiterResponseDto,
  paymentMethod: string,
  paymentRedirectUrls: {},
  redirectLink: string,
  closeModal: () => void,
  closeAccountSettingsModal: () => void,
  subscribeRecruiterCompany: (companyId: string, data: SubscriptionDto) => void
}

type State = {}

export class Settings extends Component<Props, State> {

  static defaultProps: Props = {
    isOpen: false,
    close: () => {},
    closeAccountSettingsModal: () => {},
    currentUser: new RecruiterResponseDto(),
    subscribeRecruiterCompany: () => {}
  };

  state = {}

}

export const stateToProps = (state: GlobalState) => ({
  content: state.landing.contact.content,
  fetching: state.landing.contact.fetching,
  currentUser: state.portal.recruiter.account.current.currentUser,
  redirectLink: state.portal.recruiter.account.payment.redirectUrl
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  closeAccountSettingsModal: showModal,
  sendContactQuestion,
  subscribeRecruiterCompany
}, dispatch);
