import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import { refreshToken } from 'rest/oauth/oauth.rest';

const refreshAuthToken = error => {
  return refreshToken(localStorage.getItem('ej_refresh_token'))
    .then(response => {
      localStorage.setItem('ej_token', response.data.access_token);
      error.response.config.headers['Authorization'] = 'Bearer ' + response.data.access_token;
    });
};

createAuthRefreshInterceptor(axios, refreshAuthToken);
