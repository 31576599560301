import * as actions from './orders.constant';

import { CompanyOrderDto } from 'rest/companies/order/order.dto';

import { getCompanyOrders } from 'rest/companies/order/order.rest';

const getCompanyOrdersRequest = () => ({
  type: actions.GET_COMPANY_ORDERS_REQUEST
});

const getCompanyOrdersSuccess = (orders: CompanyOrderDto[]) => ({
  type: actions.GET_COMPANY_ORDERS_SUCCESS,
  orders
});

const getCompanyOrdersFailure = (error: string) => ({
  type: actions.GET_COMPANY_ORDERS_FAILURE,
  error
});

export const fetchCompanyOrders = (companyId: string, status: string) => {
  return dispatch => {
    dispatch(getCompanyOrdersRequest());
    return getCompanyOrders(companyId, status)
      .then(response => dispatch(getCompanyOrdersSuccess(response.data)))
      .catch(error => dispatch(getCompanyOrdersFailure(error)));
  };
};
