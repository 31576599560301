// @flow
import React from 'react';

import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';

import { Button } from 'components/buttons';
import { Spinner } from 'components/elements';
import { FormGroup, CheckboxControl } from 'components/controls';

import { CurrentRecruiterDto } from 'rest/recruiter/current/current.dto';
import { NotificationSettingDto } from 'rest/recruiter/current/notification-setting.dto';

import { Setting, stateToProps, dispatchToProps } from './notifications.setting';
import { toast } from 'react-toastify';

@connectStore(stateToProps, dispatchToProps, true)
export class RecruiterNotificationSettings extends Setting {

  componentDidMount () {
    this.setState({ ...this.props.currentUser.blockNotification });
  }

  updateNotificationsSettings (event: Event) {
    event.preventDefault();
    const notifications: NotificationSettingDto = new NotificationSettingDto(this.state);
    const recruiter: CurrentRecruiterDto = new CurrentRecruiterDto(this.props.currentUser);
    const currentUser = this.props.currentUser;
    recruiter.recruiterPhoto =
      (currentUser.recruiterPhoto && currentUser.recruiterPhoto.imageId) ? this.props.currentUser.recruiterPhoto : null;
    recruiter.blockNotification = notifications;
    this.props.updateCurrentRecruiter(this.props.currentUser.id, recruiter);
    toast.success(this.props.t('portal.recruiter.notifications.notifications-updated'));
  }

  render () {
    return (
      <div className="notifications-settings-container">
        <p className="caption">
          <Trans i18nKey="portal.candidate.profile.notifications.title">
            Email me when:
          </Trans>
        </p>
        <form
          onSubmit={(event: Event) => this.updateNotificationsSettings(event)}
          className="notification-settings-form">
          <FormGroup>
            <CheckboxControl
              className="accent"
              value={!this.state.matchJobOffer}
              change={(checked: boolean) => this.setState({ matchJobOffer: !checked })}
              label={this.props.t('portal.recruiter.profile.notifications.job-offer')}/>
          </FormGroup>
          <FormGroup>
            <CheckboxControl
              className="accent"
              value={!this.state.newMessageChat}
              change={(checked: boolean) => this.setState({ newMessageChat: !checked })}
              label={this.props.t('portal.recruiter.profile.notifications.chat')}/>
          </FormGroup>
          <FormGroup>
            <CheckboxControl
              className="accent"
              value={!this.state.updateFollowing}
              change={(checked: boolean) => this.setState({ updateFollowing: !checked })}
              label={this.props.t('portal.recruiter.profile.notifications.follow')}/>
          </FormGroup>
          <FormGroup className="notification-settings-action">
            <Button
              type="submit"
              recruiter>
              <Trans i18nKey="portal.recruiter.profile.buttons.save">
                Save Changes
              </Trans>
            </Button>
          </FormGroup>
        </form>
        <Spinner show={this.props.fetching}/>
      </div>
    );
  }

}
