import React from 'react';
import { Redirect } from 'react-router-dom';

import { toast } from 'react-toastify';
import { Trans } from 'react-i18next';
import ReactCodeInput from 'react-code-input';

import { connectStore } from 'middleware/connect';

import { FormGroup } from 'components/controls';

import { AuthFooter } from 'containers/auth/layouts';

import { ConfirmDto } from 'rest/account/confirm/confirm.dto';
import { ConfirmationEmailDto } from 'rest/account/confirm/confirmation-email.dto';

import type { Props } from './confirm-registration-form.setting';
import { Settings, dispatchToProps, stateToProps } from './confirm-registration-form.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class ConfirmRegistrationForm extends Settings {

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.redirect !== this.state.redirect) {
      this.setState({ redirect: nextProps.redirect });
    }
    this.resolveErrorToast(nextProps);
    this.resolveSuccessToast(nextProps);
  }

  resolveErrorToast (props: Props) {
    if (props.error !== this.state.error && this.state.activationKey.length === 6) {
      toast.error(this.props.t('portal.guest.recruiter.confirmation-popup.error'));
      this.props.resetConfirmationEmail();
    }
  }

  resolveSuccessToast (props: Props) {
    if (!props.error && props.resendSuccess !== this.state.resendSuccess) {
      toast.success(this.props.t(
        this.props.userType === 'candidate'
          ? 'auth.candidate.notifications.resend-code-success'
          : 'auth.employer.notifications.resend-code-success'
      ));
      this.props.resetConfirmationEmail();
    }
  }

  confirmRegistration (event: Event<HTMLFormElement>) {
    event.preventDefault();
    const activationKey = new ConfirmDto(this.state);
    this.props.confirmSignUp(activationKey, this.props.userType);
  }

  resendCode () {
    const emailDto = new ConfirmationEmailDto();
    emailDto.email = localStorage.getItem('emailToConfirm');
    this.props.resendUserConfirmation(emailDto);
  }

  render () {
    return (
      <div className="confirmation-code-container">
        {
          this.state.redirect && <Redirect to={this.props.redirectUrl}/>
        }
        <form
          className="confirmation-code-form"
          onSubmit={this.confirmRegistration.bind(this)}>
          <img src="/images/auth/rocket.svg"/>
          <h3 className="confirmation-title">
            <Trans i18nKey="portal.guest.recruiter.confirmation-popup.title">
              Check your email
            </Trans>
          </h3>
          <p className="confirmation-caption">
            <Trans i18nKey="portal.guest.recruiter.confirmation-popup.description-start">
              <span>We sent a confirmation code to</span>
            </Trans>
            <span className="email-to-confirm"> {localStorage.getItem('emailToConfirm')}. </span>
            <Trans i18nKey="portal.guest.recruiter.confirmation-popup.description-end">
              <span>Add it below and start exploring ExcellentJobs.</span>
            </Trans>
          </p>
          <FormGroup>
            <ReactCodeInput
              type='text'
              autoFocus={false}
              onChange={(activationKey: string) =>
                this.setState({ activationKey: activationKey.toString().toUpperCase(), error: false })}
              value={this.state.activationKey}
              fields={6}/>
          </FormGroup>
          <FormGroup>
            <button
              type="submit"
              className={`full-width btn ${this.props.buttonType} confirm-btn`}>
              Confirm
            </button>
          </FormGroup>
          <AuthFooter>
            <span>
              <Trans i18nKey="portal.guest.recruiter.confirmation-popup.question">
                Confirmation code didn`t come?
              </Trans>
            </span>
            <a
              className={`${this.props.buttonType} resend-link`}
              onClick={() => this.resendCode()}>
              <Trans i18nKey="portal.guest.recruiter.confirmation-popup.buttons.send-again">
                Send Again
              </Trans>
            </a>
          </AuthFooter>
        </form>
      </div>
    );
  }

}
