// @flow
import { Component } from 'react';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { SentApplicationsStatsDto } from 'rest/candidates/applications/applications.stats.dto';

type Props = {
  t: TransProps,
  stats: SentApplicationsStatsDto
};

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  stats: state.portal.candidate.applications.stats.sentApplications
});
