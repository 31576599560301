import { Component } from 'react';

import { NavigationItem } from '../item/item';

import './container.scss';
import './container.responsive.scss';

type Props = {
  children: NavigationItem[],
  className?: string
};

export class Setting extends Component<Props> {

  static defaultProps = {
    children: [],
    className: ''
  }

}
