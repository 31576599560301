import * as actions from './job-offers.constants';

import { Action, Dispatch } from 'redux';

import { AxiosResponse } from 'axios';

import { List } from 'rest/list/list.dto';
import { JobOffersMatchesDto } from 'rest/candidates/job-offers-matches/job-offers-matches.dto';
import { JobOffersMatchesParams } from 'rest/candidates/job-offers-matches/job-offers-matches.params';

import { getMatchedJobOffers, viewMatchedJobOffers } from 'rest/candidates/job-offers-matches/job-offers-matches.rest';
import { getNewMatchedJobOffersList } from 'services/portal/candidate/match/new-job-offers/new-job-offers.actions';

const getAllMatchedJobOffersRequest = (): Action => ({
  type: actions.GET_ALL_JOB_OFFERS_MATCHES_REQUEST
});

const getAllMatchedJobOffersSuccess = (data): Action => ({
  type: actions.GET_ALL_JOB_OFFERS_MATCHES_SUCCESS,
  payload: data
});

const getAllMatchedJobOffersFailure = (): Action => ({
  type: actions.GET_ALL_JOB_OFFERS_MATCHES_FAILURE
});

const markJobOfferAsViewedSuccess = (jobOfferId: string): Action => ({
  type: actions.MARK_JOB_OFFERS_AS_VIEWED_SUCCESS,
  jobOfferId
});

const resetAllMatchedJobOffersSuccess = () => ({
  type: actions.RESET_ALL_JOB_OFFERS_MATCHES_SUCCESS
});

const changeMatchedJobOffersFilterSuccess = (params: JobOffersMatchesParams) => ({
  type: actions.CHANGE_MATCHED_FILTERS_SUCCESS,
  params
});

export const resetAllMatchedJobOffers = () => {
  return dispatch => {
    dispatch(resetAllMatchedJobOffersSuccess());
  };
};

export const changeMatchedJobOffersFilter = (params: JobOffersMatchesParams) => {
  return dispatch => dispatch(changeMatchedJobOffersFilterSuccess(params));
};

export function getMatchedJobOffersList (candidateId: string, params: JobOffersMatchesParams) {
  return (dispatch: Dispatch) => {
    dispatch(getAllMatchedJobOffersRequest());
    return getMatchedJobOffers(candidateId, params)
      .then((response: AxiosResponse<List<JobOffersMatchesDto>>) =>
        dispatch(getAllMatchedJobOffersSuccess(response.data)))
      .catch(() => dispatch(getAllMatchedJobOffersFailure()));
  };
}

export function saveMatchedJobOfferAsViewed (candidateId: string, jobOfferId: string) {
  return (dispatch: Dispatch) => {
    const timer: number = 600;
    return setTimeout(() => {
      viewMatchedJobOffers(candidateId, jobOfferId);
      dispatch(getNewMatchedJobOffersList(candidateId));
      dispatch(markJobOfferAsViewedSuccess(jobOfferId));
    }, timer);
  };
}
