import * as actions from './templates.constants';

import { Action } from 'redux';

import { PaginationDto } from 'middleware/dto/pagination';

import { ApplyJobOfferDto } from 'rest/job-offers/apply/apply.dto';
import { ApplicationDto } from 'rest/candidates/applications-template/applications-template.dto';
import { ApplicationResponseDto } from 'rest/candidates/applications-template/applications-template.response.dto';

import {
  getApplications,
  updateApplication,
  createApplication,
  deleteApplication,
  getApplicationById
} from 'rest/candidates/applications-template/applications-template.rest';
import { applyToJobOffer } from 'services/portal/candidate/apply/apply.actions';
import { getApplicationsStatistics } from 'services/portal/candidate/applications/stats/stats.actions';

const createApplicationRequest = (): Action => ({
  type: actions.CREATE_APPLICATIONS_REQUEST
});

const createApplicationSuccess = (template: ApplicationDto): Action => ({
  type: actions.CREATE_APPLICATIONS_SUCCESS,
  template
});

const createApplicationFailure = (): Action => ({
  type: actions.CREATE_APPLICATIONS_FAILURE
});

const getApplicationsRequest = (): Action => ({
  type: actions.GET_APPLICATIONS_REQUEST
});

const getApplicationsSuccess = (templates: ApplicationResponseDto[]): Action => ({
  type: actions.GET_APPLICATIONS_SUCCESS,
  payload: templates
});

const getApplicationsFailure = (error: string): Action => ({
  type: actions.GET_APPLICATIONS_FAILURE,
  error
});

const getApplicationByIdRequest = (): Action => ({
  type: actions.GET_APPLICATION_BY_ID_REQUEST
});

const getApplicationByIdSuccess = (template: ApplicationResponseDto): Action => ({
  type: actions.GET_APPLICATION_BY_ID_SUCCESS,
  template
});

const getApplicationByIdFailure = (error: string): Action => ({
  type: actions.GET_APPLICATION_BY_ID_FAILURE,
  error
});

const updateApplicationRequest = (): Action => ({
  type: actions.UPDATE_APPLICATION_REQUEST
});

const updateApplicationSuccess = (): Action => ({
  type: actions.UPDATE_APPLICATION_SUCCESS
});

const updateApplicationFailure = (): Action => ({
  type: actions.UPDATE_APPLICATION_FAILURE
});

const deleteApplicationRequest = (): Action => ({
  type: actions.DELETE_APPLICATION_REQUEST
});

const deleteApplicationSuccess = (templateId: string): Action => ({
  type: actions.DELETE_APPLICATION_SUCCESS,
  templateId
});

const deleteApplicationFailure = (): Action => ({
  type: actions.DELETE_APPLICATION_FAILURE
});

const resetApplicationsSuccess = (): Action => ({
  type: actions.RESET_APPLICATIONS_SUCCESS
});

const changeTemplatesFilterSuccess = (status?: string): Action => ({
  type: actions.CHANGE_TEMPLATES_FILTER_SUCCESS,
  status
});

export function changeTemplatesFilter (status?: string = '') {
  return dispatch => dispatch(changeTemplatesFilterSuccess(status));
}

export function resetApplications () {
  return dispatch => dispatch(resetApplicationsSuccess());
}

function resolveCreateApplicationSuccess (candidateId: string, data: ApplyJobOfferDto, sent: boolean) {
  return dispatch => {
    if (sent) {
      dispatch(applyToJobOffer(candidateId, data.jobOfferId, data));
    }
    dispatch(createApplicationSuccess(data));
    return dispatch(getApplicationsStatistics(candidateId));
  };
}

export function createCandidateApplication (candidateId: string, data: ApplicationDto, sentTemplate: boolean) {
  return dispatch => {
    dispatch(createApplicationRequest());
    return createApplication(candidateId, data)
      .then(response => {
        const template: ApplyJobOfferDto = new ApplyJobOfferDto(data);
        template.templateId = response.data;
        return dispatch(resolveCreateApplicationSuccess(candidateId, template, sentTemplate));
      })
      .catch(() => dispatch(createApplicationFailure()));
  };
}

export function getCandidateApplications (candidateId: string, params: PaginationDto) {
  return dispatch => {
    dispatch(getApplicationsRequest());
    return getApplications(candidateId, params)
      .then(response => dispatch(getApplicationsSuccess(response.data)))
      .catch(() => dispatch(getApplicationsFailure()));
  };
}

export function getCandidateApplicationById (candidateId: string, templateId: string) {
  return dispatch => {
    dispatch(getApplicationByIdRequest());
    return getApplicationById(candidateId, templateId)
      .then(response => dispatch(getApplicationByIdSuccess(response.data)))
      .catch(() => dispatch(getApplicationByIdFailure()));
  };
}

export function updateCandidateApplication (candidateId: string, templateId: string, data: ApplicationDto) {
  return dispatch => {
    dispatch(updateApplicationRequest());
    return updateApplication(candidateId, templateId, data)
      .then(() => {
        dispatch(updateApplicationSuccess());
        dispatch(getCandidateApplications(candidateId));
      })
      .catch(() => dispatch(updateApplicationFailure()));
  };
}

export function deleteCandidateApplication (candidateId: string, templateId: string) {
  return dispatch => {
    dispatch(deleteApplicationRequest());
    return deleteApplication(candidateId, templateId)
      .then(() => {
        dispatch(deleteApplicationSuccess(templateId));
        dispatch(getApplicationsStatistics(candidateId));
      })
      .catch(() => dispatch(deleteApplicationFailure()));
  };
}
