// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { getPosts, resetPostList } from 'services/portal/candidate/blog/blog.actions';

import { PaginationDto } from 'middleware/dto';

import { PostsResponseDto } from 'rest/candidates/posts/posts.response.dto';

import './personal-section.scss';

type Props = {
  fetching?: false,
  history?: History,
  fetching?: boolean,
  candidateId: string,
  hasMoreItems?: boolean,
  posts?: PostsResponseDto[],
  resetPostList?: () => void,
  getPosts?: (query: PaginationDto) => void
}

type State = {
  activePhotoUrl: string,
  activePhotoIndex: number,
}

export class Setting extends Component<Props, State> {

  static defaultProps = {
    posts: [],
    hasMoreItems: true,
    getPosts: () => {}
  };

  state: State = {
    activePhotoUrl: '',
    activePhotoIndex: 0
  };

}

export const stateToProps = (state: GlobalState) => ({
  posts: state.portal.candidate.blog.content,
  fetching: state.portal.candidate.blog.fetching,
  currentPage: state.portal.candidate.blog.currentPage,
  hasMoreItems: state.portal.candidate.blog.hasMoreItems
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getPosts,
  resetPostList
}, dispatch);
