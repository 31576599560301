import React, { Component } from 'react';

import { ConfirmRegistrationForm } from 'containers/auth/confirm-registration-form/confirm-registration-form';

export class ConfirmRegistrationPage extends Component {

  render () {
    return (
      <div className="confirm-sign-up-page">
        <ConfirmRegistrationForm
          userType="candidate"
          redirectUrl="/portal/candidate/account"/>
      </div>
    );
  }

}
