export class RecruiterProfilePhotoDto {

  id: string;
  url: string;
  imageId: string;
  primary: boolean;

  constructor (data?: RecruiterProfilePhotoDto) {
    if (data) {
      this.id = data.id;
      this.url = data.url;
      this.imageId = data.imageId;
      this.primary = data.primary;
    }
  }

}
