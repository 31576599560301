// @flow
import { Action, Dispatch } from 'redux';

import * as action from './social.constants';

import { setToken } from 'middleware/auth';
import { setUserType } from 'middleware/user-type';

import type { ApiData } from 'common/types/api-data';

import { AuthDto } from 'rest/auth/auth.dto';
import { AuthOutDto } from 'rest/auth/auth.out';
import { socialLogin } from 'rest/auth/auth.rest';

const socialAuthRequest = (): Action => ({
  type: action.SOCIAL_AUTH_REQUEST
});

const socialAuthFailure = (): Action => ({
  type: action.SOCIAL_AUTH_FAILURE
});

const socialAuthSuccessToken = (data: AuthDto): Action => ({
  type: action.SOCIAL_AUTH_SUCCESS_TOKEN,
  tokenSuccess: data.token
});

const socialAuthSuccessData = (data: AuthDto): Action => ({
  type: action.SOCIAL_AUTH_SUCCESS_DATA,
  candidateData: data
});

function checkUserRegistration (dispatch: Dispatch, response: AuthDto) {
  if (response.token) {
    setToken(response.token, response.refreshToken);
    setUserType('candidate');
    dispatch(socialAuthSuccessToken(response));
  } else {
    const socialDetails = JSON.stringify(response);
    localStorage.setItem('socialDetails', socialDetails);
    dispatch(socialAuthSuccessData(response));
  }
}

export function trySignIn (auth: AuthOutDto) {
  return (dispatch: Dispatch) => {
    dispatch(socialAuthRequest());
    return socialLogin(auth)
      .then((response: ApiData<AuthDto>) => checkUserRegistration(dispatch, response.data))
      .catch(() => dispatch(socialAuthFailure()));
  };
}
