// @flow
import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';

import type { State, Props } from './team-members.setting';

import { connectStore } from 'middleware/connect';

import { Button } from 'components/buttons';
import { Spinner } from 'components/elements';
import { FormGroup, InputControl } from 'components/controls';

import { Settings, dispatchToProps, stateToProps } from './team-members.setting';

import { InvitesMembersDto } from 'rest/companies/team-members/team-members.dto';

@connectStore(stateToProps, dispatchToProps, true)
export class TeamMembers extends Settings {

  componentDidMount () {
    if (!this.props.currentUser) {
      this.props.getCurrentRecruiter();
    }
  }

  componentWillReceiveProps (nextProps: Props, prevState: State) {
    if (nextProps.errorText !== prevState.errorText) {
      this.setState({ errorText: nextProps.errorText });
    }
  }

  changeUserState (field: string, value: string, isError: boolean = false) {
    this.setState({
      [field]: value,
      errorText: ''
    });
    this.errorCollector(field, isError);
  }

  clearMemberEmail (field: string) {
    this.setState({
      [field]: '',
      errorText: ''
    });
    this.errorCollector(field, false);
  }

  errorCollector (errorName: string, collect: boolean) {
    const errorCollection: Set<string> = new Set(this.state.errorCollection);
    collect ? errorCollection.add(errorName) : errorCollection.delete(errorName);
    this.setState({
      errorCollection
    });
  }

  flatInvites (invitesState: State): InvitesMembersDto {
    const invites: InvitesMembersDto[] = [];
    for (const key in invitesState) {
      if (invitesState.hasOwnProperty(key) && !(invitesState[key] instanceof Object) && invitesState[key]) {
        invites.push({ email: invitesState[key] });
      }
    }
    return invites;
  }

  inviteTeamMembers (event: Event) {
    event.preventDefault();
    if (!this.state.errorCollection.size) {
      const invitesData = this.flatInvites(this.state);
      const { invites } = new InvitesMembersDto({ invites: invitesData });
      this.props.inviteRecruiterTeamMembers(this.props.currentUser.companyId, invites);
    }
  }

  render () {
    return (
      <Fragment>
        <Spinner show={this.props.fetching} />
        <p className="team-members-caption ">
          <Trans i18nKey="portal.recruiter.account.tabs.team.description">
            Invite team members you’de like to explore our service with:
          </Trans>
        </p>
        <form
          onSubmit={this.inviteTeamMembers.bind(this)}
          className="team-members-form">
          <FormGroup className="iconed team-members-form-item">
            <InputControl
              error={this.state.errorText}
              value={this.state.firstField}
              change={(value: string, error: boolean): void => this.changeUserState('firstField', value, error)}
              placeholder={this.props.t('portal.recruiter.account.tabs.team.email.placeholder')}
              errorText={this.state.errorText || this.props.t('portal.recruiter.account.tabs.team.email.error')}
              type="email"/>
            <Button
              onClick={() => this.clearMemberEmail('firstField')}
              className="icon">
              <img
                src="/images/shared/close-icon.svg"
                alt="close"
              />
            </Button>
          </FormGroup>
          <FormGroup className="iconed team-members-form-item">
            <InputControl
              value={this.state.secondField}
              pattern={this.state.firstField}
              change={(value: string, error: boolean): void => this.changeUserState('secondField', value, error)}
              placeholder={this.props.t('portal.recruiter.account.tabs.team.email.placeholder')}
              errorText={this.props.t('portal.recruiter.account.tabs.team.email.error')}
              type="email"/>
            <Button
              onClick={() => this.clearMemberEmail('secondField')}
              className="icon">
              <img
                src="/images/shared/close-icon.svg"
                alt="close"
              />
            </Button>
          </FormGroup>
          <FormGroup className="iconed team-members-form-item">
            <InputControl
              value={this.state.thirdField}
              change={(value: string, error: boolean): void => this.changeUserState('thirdField', value, error)}
              placeholder={this.props.t('portal.recruiter.account.tabs.team.email.placeholder')}
              errorText={this.props.t('portal.recruiter.account.tabs.team.email.error')}
              type="email"/>
            <Button
              onClick={() => this.clearMemberEmail('thirdField')}
              className="icon">
              <img
                src="/images/shared/close-icon.svg"
                alt="close"
              />
            </Button>
          </FormGroup>
          <FormGroup className="iconed team-members-form-item">
            <InputControl
              value={this.state.fourthField}
              change={(value: string, error: boolean): void => this.changeUserState('fourthField', value, error)}
              placeholder={this.props.t('portal.recruiter.account.tabs.team.email.placeholder')}
              errorText={this.props.t('portal.recruiter.account.tabs.team.email.error')}
              type="email"/>
            <Button
              onClick={() => this.clearMemberEmail('fourthField')}
              className="icon">
              <img
                src="/images/shared/close-icon.svg"
                alt="close"
              />
            </Button>
          </FormGroup>
          <FormGroup className="form-btn-submit">
            <Button
              onClick={() => this.props.prev()}
              grey>
              <Trans i18nKey="portal.recruiter.account.tabs.team.buttons.back">
                Back
              </Trans>
            </Button>
            <Button
              recruiter
              medium
              type="submit">
              <Trans i18nKey="portal.recruiter.account.tabs.team.buttons.next">
                Next
              </Trans>
            </Button>
          </FormGroup>
        </form>
      </Fragment>
    );
  }

}
