// @flow
import { Component } from 'react';

import './link-button.scss';

type Props = {
  link: string,
  grey?: boolean,
  default?: boolean,
  children?: Node,
  external?: boolean,
  className?: string,
  inline?: boolean,
  disabled?: boolean,
  outlined?: boolean,
  recruiter?: boolean,
  onClick?: () => void
};

export class Setting extends Component<Props> {

  static defaultProps: Props = {
    link: '',
    grey: false,
    inline: false,
    default: false,
    external: false,
    disabled: false,
    outlined: false,
    recruiter: false,
    className: '',
    onClick: () => {}
  };

}
