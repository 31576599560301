// @flow
import { Component } from 'react';
import { TransProps } from 'react-i18next';

import { MatchedCandidatesDto } from 'rest/companies/job-offers-matches/matched-candidates/matched-candidates.dto';

import './candidate-row.scss';

export type Props = {
  t?: TransProps,
  onClick: (candidateId: string) => void,
  onDelete: (candidateId: string) => void,
  candidate: MatchedCandidatesDto
};

export class Setting extends Component<Props> {

  static defaultProps: Props = {
    onClick: () => {},
    onDelete: () => {},
    candidate: new MatchedCandidatesDto()
  };

}
