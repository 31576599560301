import * as actions from './stats.constants';

import { getPurchasedCandidatesStats } from 'rest/companies/purchased/stats/stats.rest';

const getPurchasedCandidateStatisticRequest = () => ({
  type: actions.GET_PURCHASED_CANDIDATE_STATS_REQUEST
});

const getPurchasedCandidateStatisticSuccess = (total: number = 0) => ({
  type: actions.GET_PURCHASED_CANDIDATE_STATS_SUCCESS,
  total
});

const getPurchasedCandidateStatisticFailure = (error: string) => ({
  type: actions.GET_PURCHASED_CANDIDATE_STATS_FAILURE,
  error
});

export const getPurchasedCandidateStatistic = (recruiterId: string) => {
  return dispatch => {
    dispatch(getPurchasedCandidateStatisticRequest());
    return getPurchasedCandidatesStats(recruiterId)
      .then(response => {
        dispatch(getPurchasedCandidateStatisticSuccess(response.data.totalElement));
      })
      .catch(error => dispatch(getPurchasedCandidateStatisticFailure(error)));
  };
};
