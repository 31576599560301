import { ImageDto } from 'rest/images/image.dto';
import { UserAddressDto } from 'rest/address-dto/address';
import { UserInvoiceAddressDto } from 'rest/invoice-address-dto/address';

export class CompanyDto {

  logo = new ImageDto();
  name: string;
  vatId: string;
  stars: string;
  follow: boolean;
  brandId: string;
  branchId: string;
  photos: ImageDto[];
  restricted: boolean;
  description: string;
  isPremiumActive: boolean;
  subscriptionPlan: string;
  subscriptionExpirationDate: string;
  address: UserAddressDto = new UserAddressDto();
  invoiceAddress: UserInvoiceAddressDto = new UserInvoiceAddressDto();

  constructor (data?: CompanyDto) {
    if (data) {
      this.name = data.name;
      this.vatId = data.vatId;
      this.stars = data.stars;
      this.follow = data.follow;
      this.photos = data.photos;
      this.brandId = data.brandId;
      this.branchId = data.branchId;
      this.restricted = data.restricted;
      this.logo = new ImageDto(data.logo);
      this.description = data.description;
      this.address = new UserAddressDto(data.address);
      this.invoiceAddress = new UserInvoiceAddressDto(data.invoiceAddress);
      this.subscriptionPlan = data.subscriptionPlan;
      this.subscriptionExpirationDate = data.subscriptionExpirationDate;
    }
  }

}
