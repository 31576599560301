// @flow
import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { CompanyDto } from 'rest/companies/companies.dto';
import { ResponseTeamMembersDto } from 'rest/companies/team-members/team-members.response.dto';
import { RecruiterResponseDto } from 'rest/recruiter/recruiter.response.dto';

import {
  getRecruiterTeamMembers,
  getRecruiterCompanyById
} from 'services/portal/recruiter/account/account.actions';

import './team-members.scss';

type Props = {
  t?: TransProps,
  fetching: boolean,
  company: CompanyDto,
  currentUser: RecruiterResponseDto,
  teamMembers: ResponseTeamMembersDto[],
  getRecruiterTeamMembers: (companyId: string) => void,
  getRecruiterCompanyById: (companyId: string) => void
}

type State = {
  isDeleteModalOpen: boolean,
  isInviteModalVisible: boolean,
  currentMember: ?ResponseTeamMembersDto
}

export class Setting extends Component<Props, State> {

  static defaultProps = {
    fetching: false,
    teamMembers: [],
    currentUser: new RecruiterResponseDto()
  };

  state: State = {
    currentMember: null,
    isDeleteModalOpen: false,
    isInviteModalVisible: false
  };

}

export const stateToProps = (state: GlobalState) => ({
  company: state.portal.recruiter.account.companies.company,
  fetching: state.portal.recruiter.account.teamMembers.fetching,
  currentUser: state.portal.recruiter.account.current.currentUser,
  teamMembers: state.portal.recruiter.account.teamMembers.teamMembers
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getRecruiterTeamMembers,
  getRecruiterCompanyById
}, dispatch);
