import { Action, Dispatch } from 'redux';

import { AxiosResponse } from 'axios';

import { PaginationDto } from 'middleware/dto/pagination';

import { List } from 'rest/list/list.dto';
import { JobOffersMatchesDto } from 'rest/companies/job-offers-matches/job-offers-matches.dto';

import { getMatchStatistic } from 'services/portal/recruiter/match/stats/stats.actions';
import { getMatchedJobOffers, viewMatchedJobOffers } from 'rest/companies/job-offers-matches/job-offers-matches.rest';

import {
  resetNewMatchedCandidatesStats,
  getNewMatchedCandidatesStatistic
} from 'services/portal/recruiter/match/new-candidates/new-candidates.actions';

import * as actions from './job-offers.constants';

const getAllMatchedJobOffersRequest = (): Action => ({
  type: actions.GET_ALL_CANDIDATES_MATCHES_REQUEST
});

const getAllMatchedJobOffersSuccess = (data): Action => ({
  type: actions.GET_ALL_CANDIDATES_MATCHES_SUCCESS,
  payload: data
});

const getAllMatchedJobOffersFailure = (): Action => ({
  type: actions.GET_ALL_CANDIDATES_MATCHES_FAILURE
});

const viewMatchedCandidateRequest = (): Action => ({
  type: actions.VIEW_MATCHED_CANDIDATES_REQUEST
});

const viewMatchedCandidateSuccess = (jobOfferId: string): Action => ({
  type: actions.VIEW_MATCHED_CANDIDATES_SUCCESS,
  jobOfferId
});

const viewMatchedCandidateFailure = (): Action => ({
  type: actions.VIEW_MATCHED_CANDIDATES_FAILURE
});

const resetMatchedJobOffersSuccess = (): Action => ({
  type: actions.RESET_CANDIDATES_MATCHES_SUCCESS
});

export function resetMatchedJobOffers () {
  return (dispatch: Dispatch) => {
    dispatch(resetMatchedJobOffersSuccess());
  };
}

export function getMatchedJobOffersList (companyId: string, params: PaginationDto) {
  return (dispatch: Dispatch) => {
    dispatch(getAllMatchedJobOffersRequest());
    return getMatchedJobOffers(companyId, params)
      .then((response: AxiosResponse<List<JobOffersMatchesDto>>) =>
        dispatch(getAllMatchedJobOffersSuccess(response.data)))
      .catch(() => dispatch(getAllMatchedJobOffersFailure()));
  };
}

export function saveMatchedJobOfferAsViewed (companyId: string, jobOfferId: string) {
  return (dispatch: Dispatch) => {
    dispatch(viewMatchedCandidateRequest());
    setTimeout(() => {
      return viewMatchedJobOffers(companyId, jobOfferId)
        .then(() => {
          dispatch(resetNewMatchedCandidatesStats());
          dispatch(getMatchStatistic(companyId));
          dispatch(getNewMatchedCandidatesStatistic(companyId));
          dispatch(viewMatchedCandidateSuccess(jobOfferId));
        })
        .catch(() => dispatch(viewMatchedCandidateFailure()));
    }, 2000);
  };
}
