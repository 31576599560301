import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { CurrentRecruiterDto } from 'rest/recruiter/current/current.dto';
import type { Props } from './auth-container.setting';
import { Settings, stateToProps, dispatchToProps } from './auth-container.setting';

@connect(stateToProps, dispatchToProps)
export class RecruiterAuthContainer extends Settings {

  componentDidMount () {
    const recruiter: CurrentRecruiterDto = this.props.currentUser;
    if (recruiter.id) {
      this.props.getRecruiterCompanyById(recruiter.companyId);
    } else {
      this.props.getCurrentCompanyRecruiter();
    }
  }

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.currentUser.id !== this.props.currentUser.id) {
      this.props.getRecruiterCompanyById(nextProps.currentUser.companyId);
    }
  }

  checkRecruiterPortalRoute (): boolean {
    return this.props.location.pathname === '/portal/recruiter';
  }

  render () {
    return (
      <Fragment>
        {
          this.checkRecruiterPortalRoute() && <Redirect to="/portal/recruiter/balance-board"/>
        }
        { this.props.children }
      </Fragment>
    );
  }

}
