// @flow
import React from 'react';

import cn from 'classnames';

import { Setting } from './chips.setting';

export class Chips extends Setting {

  render () {
    const classes = cn('chip', this.props.color);

    return (
      <div className={classes}>
        {this.props.children}
      </div>
    );
  }

}
