// @flow
import React, { Fragment } from 'react';

import moment from 'moment';
import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';

import { connectStore } from 'middleware/connect';

import { Button } from 'components/buttons';
import { CheckboxControl, KnowledgeLevel } from 'components/controls';

import { SkillDto } from 'rest/skills/skills.dto';
import { SkillsDto } from 'rest/skill/skills.dto';
import { InteractSkillDto } from './interact-skill-dto';
import { CandidateSkillsDto } from 'rest/candidates/skills/skills.dto';
import { GuestApplyDto } from 'rest/candidates/skills/guest-apply.dto';
import { JobRequirementsDto } from 'rest/candidates/job-requirements/job-requirements.dto';

import { Setting, stateToProps, dispatchToProps } from './interact-skills-list.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class InteractSkillsList extends Setting {

  componentDidMount () {
    this.initSkills();
    this.initBalanceBoard();
  }

  initBalanceBoard () {
    if (!this.props.balanceBoard) {
      this.props.getCandidateBalanceBoard(this.props.currentCandidate.id);
    }
  }

  initSkills () {
    this.setState({
      jobOfferSkills: this.props.jobOfferSkills,
      candidateSkills: this.props.candidateSkills
    });
  }

  addJobOfferSkillToCandidateSkills (type: string, skill: SkillDto) {
    const candidateSkill = {
      name: skill.name,
      skillId: skill.skillId,
      skillLevel: skill.skillLevel
    };
    const candidateSkills: SkillsDto = { ...this.state.candidateSkills };
    candidateSkills[type].push(candidateSkill);
    this.setState({ candidateSkills });
  }

  removeJobOfferSkillFromCandidateSkills (type: string, skillId: string) {
    const candidateSkills: SkillsDto = { ...this.state.candidateSkills };
    candidateSkills[type] = candidateSkills[type].filter((item: SkillDto) => item.skillId !== skillId);
    this.setState({ candidateSkills });
  }

  changeSkillLevelState (type: string, value: string, skillId: number) {
    const candidateSkills: SkillsDto = { ...this.state.candidateSkills };
    candidateSkills[type] = this.state.candidateSkills[type].map((item: SkillDto) => {
      if (item.skillId === skillId) {
        return {
          ...item,
          skillLevel: value.toUpperCase()
        };
      }
      return item;
    });
    this.setState({ candidateSkills });
  }

  changeSelectedSkillsState (skill: SkillDto, checked: boolean, type: string) {
    if (checked) {
      this.addJobOfferSkillToCandidateSkills(type, skill);
    } else {
      this.removeJobOfferSkillFromCandidateSkills(type, skill.skillId);
    }
  }

  getJobRequirement (): JobRequirementsDto {
    const data: JobRequirementsDto = new JobRequirementsDto();
    data.jobTypes = ['Employee'];
    data.availableFrom = moment();
    data.workingHoursPerMonth = 160;
    data.unitIds = [this.props.jobOffer.unitId];
    data.branchIds = [this.props.jobOffer.branchId];
    data.jobTitleIds = [this.props.jobOffer.jobTitleId];
    return data;
  }

  setGuestInteractSkills (candidateSkills: CandidateSkillsDto) {
    const jobOfferLink: string = this.props.jobOfferPath.replace('guest', 'candidate');
    const params: GuestApplyDto = new GuestApplyDto();
    params.jobOfferLink = jobOfferLink;
    params.candidateSkills = candidateSkills;
    params.balanceBoard = this.getJobRequirement();
    this.props.saveGuestInteractionSkills(params);
    this.props.checkJobOfferMatchSkills(this.props.jobOffer.jobOfferId, candidateSkills);
  }

  updateCandidateSkills () {
    const candidateId: string = this.props.currentCandidate.id;
    const candidateSkills: SkillsDto = new CandidateSkillsDto(this.state.candidateSkills);
    candidateSkills.emptySkills = false;
    if (candidateId && this.props.balanceBoard) {
      this.props.updateCandidateSkillsById(candidateId, candidateSkills);
      toast.success(this.props.t('portal.recruiter.notifications.skill-saved'));
    } else if (candidateId && !this.props.balanceBoard) {
      this.props.createCandidateBalanceBoard(candidateId, this.getJobRequirement());
      this.props.updateCandidateSkillsById(candidateId, candidateSkills);
      toast.success(this.props.t('portal.recruiter.notifications.skill-saved'));
    } else {
      candidateSkills.matchPoints = this.state.jobOfferSkills.matchPoints;
      this.setGuestInteractSkills(candidateSkills);
    }
    this.props.cancelAction();
  }

  getSortedSkills (jobOfferSkills: SkillDto[] = [], candidateSkills: SkillDto[] = []): SkillDto[] {
    const candidateSkillsIds: string[] = candidateSkills.map((item: SkillDto) => item.skillId);
    return jobOfferSkills.map(jobOfferSkill => {
      if (candidateSkillsIds.includes(jobOfferSkill.skillId)) {
        const skill: SkillDto = candidateSkills.find(item => item.skillId === jobOfferSkill.skillId);
        const data: InteractSkillDto = new InteractSkillDto(jobOfferSkill);
        data.candidateSkillLevel = skill.skillLevel;
        data.selected = true;
        return data;
      } else {
        return jobOfferSkill;
      }
    });
  }

  render () {
    const sortingView: string[] = [
      'softSkills',
      'documents',
      'softwareSkills',
      'languageSkills',
      'professionalSkills'
    ];
    return (
      <Fragment>
        <table className={`competencies-interact-table ${this.props.className}`}>
          <thead>
            <tr>
              <th>
                <Trans i18nKey="portal.candidate.job-offers.preview.interact.competency">
                  Competency Name
                </Trans>
              </th>
              <th/>
              <th>
                <Trans i18nKey="portal.candidate.job-offers.preview.interact.required">
                  Required Level
                </Trans>
              </th>
              <th/>
              <th>
                <Trans i18nKey="portal.candidate.job-offers.preview.interact.knowledge">
                  My knowledge Level
                </Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            {
              sortingView.map(section => {
                const jobOfferSkills: SkillDto[] = this.state.jobOfferSkills[section];
                const candidateSkills: SkillDto[] = this.state.candidateSkills[section];
                return this.getSortedSkills(jobOfferSkills, candidateSkills)
                  .map((skill: InteractSkillDto, index: number) => {
                    return (
                      <tr key={index}>
                        <td className="skill-name">
                          <CheckboxControl
                            className="mobile-job-skills-interactions"
                            value={skill.selected}
                            change={value => this.changeSelectedSkillsState(skill, value, section)}
                            label={skill.name}/>
                        </td>
                        <td className="mobile-skill-required-wrapper">
                          <div>
                            {skill.required && <div className="skill-required"/>}
                          </div>
                        </td>
                        <td className="skill-progress">
                          {
                            section !== 'documents' && <div className="progress-container">
                              <div className={'progress-point' + (skill.skillLevel === 'BASIC' ? ' active' : '')}/>
                              <div className="join"/>
                              <div className={'progress-point' + (skill.skillLevel === 'AVERAGE' ? ' active' : '')}/>
                              <div className="join"/>
                              <div className={
                                'progress-point mobile-point' + (skill.skillLevel === 'GOOD' ? ' active' : '')
                              }/>
                              <div className="join"/>
                              <div className={'progress-point' + (skill.skillLevel === 'EXCELLENT' ? ' active' : '')}/>
                            </div>
                          }
                        </td>
                        <td className="skill-level">
                          <div>{skill.skillLevel}</div>
                        </td>
                        <td className={skill.candidateSkillLevel ? 'mobile-skill-progress' : 'empty-range'}>
                          {
                            section !== 'documents' && <KnowledgeLevel
                              candidate
                              showSkillName={false}
                              value={skill.candidateSkillLevel ? skill.candidateSkillLevel : 'BASIC'}
                              change={value => this.changeSkillLevelState(section, value, skill.skillId)}/>
                          }
                        </td>
                        <td className="skill-level primary">
                          <div>{skill.candidateSkillLevel}</div>
                        </td>
                      </tr>
                    );
                  });
              })
            }
          </tbody>
        </table>
        <div className="table-actions mobile-table-actions">
          <Button
            onClick={() => this.updateCandidateSkills()}
            candidate
            medium>
            <Trans i18nKey="portal.candidate.job-offers.preview.interact.buttons.save">
              Save Changes
            </Trans>
          </Button>
          <Button
            grey
            onClick={() => this.props.cancelAction()}
            medium>
            <Trans i18nKey="portal.candidate.job-offers.preview.interact.buttons.cancel">
              Cancel
            </Trans>
          </Button>
          <p>
            <Trans i18nKey="portal.candidate.job-offers.preview.interact.information">
              The changes go directly into your Balance Board.
            </Trans>
          </p>
        </div>
      </Fragment>
    );
  }

}
