// @flow
import React from 'react';

import { Setting } from './radio-control.setting';

export class RadioControl extends Setting {

  changeRadioControlState (event: Event) {
    this.props.change(event.target.value);
  }

  render () {
    return (
      <label className={`radio accent ${this.props.className}`}>
        <input
          value={this.props.value}
          checked={this.props.selected === this.props.value || this.props.checked}
          type="radio"
          onChange={this.changeRadioControlState.bind(this)}/>
        {
          this.props.custom ? this.props.children : <span>{this.props.label}</span>
        }
      </label>
    );
  }

}
