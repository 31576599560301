import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Setting } from './body.setting';

export class PortletBody extends Setting {

  render () {
    return (
      <div className={`portlet-body ${this.props.className}`}>
        <PerfectScrollbar className="portlet-body-scroll">
          {this.props.children}
        </PerfectScrollbar>
      </div>
    );
  }

}
