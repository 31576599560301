// @flow
import * as actions from './job-offers.constants';

import { CompanyDto } from 'rest/companies/companies.dto';
import { SkillsContainerDto } from 'rest/skills/skills-container.dto';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

import type { JobOffersType } from './job-offers.type';

const initialState: JobOffersType = {
  fetching: false,
  success: false,
  jobOffer: new JobOffersResponseDto(),
  jobOffers: [],
  currentPage: 0,
  hasMoreItems: true,
  totalElements: 0,
  jobOfferSkills: new SkillsContainerDto(),
  jobOfferCompany: {},
  jobOffersFilter: null
};

export default function (state: JobOffersType = initialState, action) {
  switch (action.type) {
    case actions.GET_JOB_OFFERS_REQUEST:
    case actions.GET_JOB_OFFER_REQUEST:
      return {
        ...state,
        fetching: true,
        hasMoreItems: false
      };

    case actions.GET_JOB_OFFERS_FAILURE:
    case actions.GET_JOB_OFFER_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.GET_JOB_OFFERS_SUCCESS:
      return {
        ...state,
        fetching: false,
        currentPage: action.payload.number + 1,
        jobOffers: [ ...state.jobOffers, ...action.payload.content ],
        hasMoreItems: !action.payload.last,
        totalElements: action.payload.totalElements
      };

    case actions.GET_JOB_OFFER_SUCCESS:
      return {
        ...state,
        fetching: false,
        jobOffer: new JobOffersResponseDto(action.payload),
        jobOfferSkills: new SkillsContainerDto(action.jobOfferSkills),
        jobOfferCompany: new CompanyDto(action.company)
      };

    case actions.SET_JOBS_FILTER:
      return {
        ...initialState,
        jobOffersFilter: action.filter
      };

    case actions.MARK_JOB_OFFER_AS_APPLIED_SUCCESS:
      return {
        ...state,
        jobOffer: {
          ...state.jobOffer,
          candidateApplied: true
        }
      };

    case actions.RESET_JOBS_FILTER:
      return {
        ...initialState
      };

    case actions.RESET_JOB_OFFER_SUCCESS:
      return {
        ...state,
        jobOffer: new JobOffersResponseDto(),
        jobOfferSkills: new SkillsContainerDto(),
        jobOfferCompany: {}
      };

    case actions.RESET_JOB_OFFERS_LIST_SUCCESS:
      return {
        ...initialState
      };

    default:
      return state;
  }
}
