// @flow
import React from 'react';

import moment from 'moment';

import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Chips } from 'components/layouts';

import { Setting } from './invite-row.setting';

@connectTranslation()
export class InviteRow extends Setting {

  deleteInviteFromList (event: Event) {
    event.stopPropagation();
    this.props.onDelete(this.props.invite.inviteId);
  }

  render () {
    return (
      <tr
        onClick={() => this.props.onClick(this.props.invite.inviteId, this.props.invite.candidateId)}
        className="candidate-row">
        <td className="candidate-avatar">
          <div className="cell">
            <div className="avatar-wrapper">
              <img
                src={this.props.invite.primaryPhotoUrl}
                alt="Photo"/>
            </div>
          </div>
        </td>
        <td className="full-name">
          <div className="cell">
            <h5 className="item-title">
              {this.props.invite.fullName || this.props.t('portal.recruiter.calendar.list.no-name')}
            </h5>
            <div className="item-description">
              <div>{this.props.invite.candidateAddress.city}, {this.props.invite.candidateAddress.country}</div>
            </div>
          </div>
        </td>
        <td className="branch">
          <div className="cell">
            <h5 className="item-title">
              {this.props.invite.jobTitleName || this.props.t('portal.recruiter.calendar.list.no-title')}
            </h5>
            <div className="item-description">
              { moment(this.props.invite.updatedDate).parseZone().fromNow() }
            </div>
          </div>
        </td>
        <td className="status">
          <div className="cell">
            <div className="item-props">
              <Chips color={this.props.invite.candidateInviteStatus.toLowerCase()}>
                {
                  this.props.invite.candidateInviteStatus === 'INVITED' &&
                  <Trans i18nKey="portal.recruiter.calendar.status.invited">
                    Invited
                  </Trans>
                }
                {
                  this.props.invite.candidateInviteStatus === 'INTERVIEW' &&
                  <Trans i18nKey="portal.recruiter.calendar.status.accepted">
                    Accepted
                  </Trans>
                }
                {
                  this.props.invite.candidateInviteStatus === 'INTERVIEWED' &&
                  <Trans i18nKey="portal.recruiter.calendar.status.interviewed">
                    Interviewed
                  </Trans>
                }
                {
                  this.props.invite.candidateInviteStatus === 'EMPLOYED' &&
                  <Trans i18nKey="portal.recruiter.calendar.status.employed">
                    Employed
                  </Trans>
                }
              </Chips>
            </div>
          </div>
        </td>
        <td className="actions">
          <div className="cell">
            <div className="item-props">
              <span
                onClick={(event: Event) => this.deleteInviteFromList(event)}
                className="preview-item-close-icon">
                <img
                  src="/images/shared/cancel-s.svg"
                  alt="close"/>
              </span>
            </div>
          </div>
        </td>
      </tr>
    );
  }

}
