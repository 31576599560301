import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { SkillsDto } from 'rest/skill/skills.dto';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

import { getCompanyJobOfferSkills } from 'services/portal/recruiter/job-offer/skills/skills.actions';

import './preview-modal.scss';

export type Props = {
  mode: string,
  open: boolean,
  hideModal: () => void,
  jobOfferSkills?: SkillsDto,
  jobOffers?: JobOffersResponseDto[],
  getCompanyJobOfferSkills?: (jobOfferId: string) => void
};

export class Setting extends Component<Props> {

  static defaultProps: Props = {
    open: false,
    mode: '',
    hideModal: () => {},
    getCompanyJobOfferSkills: () => {}
  };

}

export const stateToProps = (state: GlobalState) => ({
  jobOffers: state.portal.recruiter.account.jobOffer.jobOffers,
  jobOfferSkills: state.portal.recruiter.jobOffer.skills.content
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getCompanyJobOfferSkills
}, dispatch);
