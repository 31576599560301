import React from 'react';

import { Trans } from 'react-i18next';

import { Button } from 'components/buttons';

import { connectTranslation } from 'middleware/connect';

import { Setting } from './empty-candidates-list.setting';

@connectTranslation()
export class EmptyCandidatesSearchList extends Setting {

  render () {
    return (
      <div className="empty-search-container">
        <h1 className="header">
          <Trans i18nKey="portal.recruiter.job-offers.list.not-found.title">
            Candidate searching
          </Trans>
        </h1>
        <p>
          <Trans i18nKey="portal.recruiter.job-offers.list.not-found.description">
            Start your search by listing your criteria in Filters.
          </Trans>
        </p>
        <div className="image-content">
          <img
            src="/images/search/woman.png"
            alt="human image"/>
          <Button
            medium
            onClick={() => this.props.history.push('/portal/recruiter/candidates/search')}
            outlined
            recruiter>
            <Trans i18nKey="portal.recruiter.job-offers.list.not-found.button">Use Filters</Trans>
          </Button>
          <img
            src="/images/search/Human.svg"
            alt="human image"/>
        </div>
      </div>
    );
  }

}
