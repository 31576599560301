import * as actions from './pdf.constants';

import type { PDFState } from './pdf.type';

const initialState: PDFState = {
  link: '',
  previewLink: ''
};

export default function (state: PDFState = initialState, action): PDFState {
  switch (action.type) {
    case actions.GET_CV_PDF_LINK:
      return {
        ...state,
        link: action.payload.link
      };

    case actions.GET_PREVIEW_CV_PDF_LINK:
      return {
        ...state,
        previewLink: action.payload.link
      };

    default:
      return state;
  }
}
