import React from 'react';

import { Settings } from './cardBtn.setting';

export class CardBtn extends Settings {

  render () {
    const { className, action, children } = this.props;

    return (
      <button
        className={className ? `${className} card` : 'card'}
        onClick={action}>
        {children}
      </button>
    );
  }

}
