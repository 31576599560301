// @flow
import React from 'react';

import { Setting } from './modal-aside-column.setting';

export class ModalAsideColumn extends Setting {

  render () {
    return (
      <aside className="aside-info">
        {this.props.children}
      </aside>
    );
  }

}
