// @flow
import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';

import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import TimeInput from 'react-simple-timefield';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';
import { getDecimalTime, getFormattedHours, getHoursTime } from 'middleware/hours-peroid';

import { Card } from 'components/layouts';
import { Modal } from 'components/dialogs';
import { Button, LinkButton } from 'components/buttons';
import { FormGroup, InputControl } from 'components/controls';

import { ModalHeader } from 'containers/portal/modal-container';

import { LifeStyleDto } from 'rest/candidates/lifestyles/lifestyles.dto';
import { LifeStyleItemDto } from 'rest/candidates/lifestyles/lifestyle-item.dto';

import type { Props } from './daily.setting';
import { dispatchToProps, Settings, stateToProps } from './daily.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class DailyCVModal extends Settings {

  componentDidMount () {
    this.setState({ activities: this.props.activities.slice(0, 7) });
  }

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.activities !== this.state.activities) {
      this.setState({ activities: nextProps.activities.slice(0, 7) });
    }
    if (nextProps.createSuccess !== this.state.createSuccess) {
      this.setState({ createSuccess: nextProps.createSuccess });
    }
  }

  calculatePercentage (): number {
    return this.state.activities.reduce((prev, curr) => prev + (curr.points || 0), 0);
  }

  changeActivitiesState (value: string, index: number) {
    const activities = this.state.activities.map((item, elemIndex) => {
      if (elemIndex === index) {
        return {
          ...item,
          activity: value
        };
      }
      return item;
    });
    this.setState({ activities });
  }

  changeActivitiesPoints (value: string, index: number) {
    const activities = this.state.activities.map((item, elemIndex) => {
      if (elemIndex === index) {
        return {
          ...item,
          points: value ? getDecimalTime(value) : 0
        };
      }
      return item;
    });
    this.setState({ activities }, () => this.calculatePercentage());
  }

  saveActivities (event: Event) {
    event.preventDefault();
    if (this.state.maxPointsCount) {
      const lifestyle: LifeStyleDto = new LifeStyleDto(this.state);
      const data = lifestyle.activities.filter((item: LifeStyleItemDto) => item.activity);
      this.props.createCandidateLifeStyle(this.props.currentCandidate.id, data);
      toast.success(this.props.t('portal.candidate.notifications.day-of-life-updated'));
    }
  }

  render () {
    const percentage: number = this.calculatePercentage();
    const backLink: string = `/portal/candidate/cv`;
    return (
      <Fragment>
        {
          this.state.createSuccess && <Redirect to={backLink}/>
        }
        <Modal closePath={backLink}>
          <div className="cv-modal mobile-cv-modal daily-modal-container mobile-daily-modal-container">
            <PerfectScrollbar className="content">
              <ModalHeader
                backLinkUrl={backLink}
                backLinkTitle={this.props.t('portal.candidate.cv.day-of-life.back')}
                title={this.props.t('portal.candidate.cv.day-of-life.title')}
                className="mobile-modal-header mobile-no-margin"/>
              <div className="cv-content">
                <Card className="daily-content">
                  <div className="lifestyle-caption">
                    <h4>
                      <Trans i18nKey="portal.candidate.cv.day-of-life.lifestyle">
                        Lifestyle
                      </Trans>
                    </h4>
                    <h4 className={percentage > this.state.maxPointsCount ? 'error-points' : ''}>
                      {getFormattedHours(percentage) || 0} / {this.state.maxPointsCount} hr
                    </h4>
                  </div>
                  <form onSubmit={this.saveActivities.bind(this)}>
                    {
                      this.state.activities.map((item: LifeStyleItemDto, index: number) => {
                        return (
                          <div
                            key={index}
                            className="lifestyle-field">
                            <FormGroup className="full-width">
                              <InputControl
                                label={`${this.props.t('portal.candidate.cv.day-of-life.activity.label')} ${index + 1}`}
                                value={item.activity}
                                change={value => this.changeActivitiesState(value, index)}
                                placeholder={this.props.t('portal.candidate.cv.day-of-life.activity.placeholder')}
                                pattern=".+"
                                errorText={this.props.t('portal.candidate.cv.day-of-life.activity.error')}
                                type="text"/>
                            </FormGroup>
                            <FormGroup className="points">
                              <TimeInput
                                placeholder="00:00"
                                value={getHoursTime(item.points)}
                                className='form-control input-time'
                                onChange={value => this.changeActivitiesPoints(value, index)}
                              />
                            </FormGroup>
                          </div>
                        );
                      })
                    }
                    <FormGroup className="form-btn-submit mobile-actions">
                      <Button
                        disabled={percentage > this.state.maxPointsCount}
                        medium
                        candidate
                        type="submit">
                        <Trans i18nKey="portal.candidate.cv.day-of-life.buttons.save">
                          Save Changes
                        </Trans>
                      </Button>
                      <LinkButton
                        grey
                        link={backLink}>
                        <Trans i18nKey="portal.candidate.cv.day-of-life.buttons.cancel">
                          Cancel
                        </Trans>
                      </LinkButton>
                    </FormGroup>
                  </form>
                </Card>
              </div>
            </PerfectScrollbar>
          </div>
        </Modal>
      </Fragment>
    );
  }

}
