import { Component } from 'react';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

export type Props = {
  t?: TransProps,
  link?: string,
  previewLink?: string
};

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  link: state.portal.candidate.cv.pdf.link,
  previewLink: state.portal.candidate.cv.pdf.previewLink,
  currentCandidate: state.portal.candidate.account.current.candidateData
});
