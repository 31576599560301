import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import moment from 'moment';
import { TransProps } from 'react-i18next';

import { CompanyDto } from 'rest/companies/companies.dto';
import { SalaryRangeDto } from 'rest/job-offers/job-offers.salary-range.dto';
import { RecruiterResponseDto } from 'rest/recruiter/recruiter.response.dto';
import { JobTypeDto } from 'components/controls/switch-control/switch-control.job-type.dto';

import { getAllUnits } from 'services/portal/units/units.actions';
import { getAllJobTitles } from 'services/portal/job-titles/job-titles.actions';
import { saveCurrentJobTitle } from 'services/portal/recruiter/job-offer/process/process.actions';
import { createNewJobOffer } from 'services/portal/recruiter/job-offer/process/process.actions';
import { getCompanyJobOfferById, updateCompanyJobOffer } from 'services/portal/recruiter/job-offer/job-offer.actions';

import './general-info.scss';

export type Props = {
  t: TransProps,
  history: History,
  next: () => void,
  jobOfferId: string,
  initialJobOfferId: number
};

type State = {
  unitId: string,
  jobType: string,
  unitError: boolean,
  jobTitleId: string,
  salaryError: boolean,
  availableFrom: moment,
  jobTitleError: boolean,
  jobDescription: string,
  maxSalaryRange: number,
  referenceNumber: string,
  salaryRange: SalaryRangeDto,
  workingHoursPerMonth: number
}

export class Settings extends Component<Props, State> {

  static defaultProps = {
    next: () => {},
    getAllUnits: () => {},
    getAllJobTitles: () => {},
    company: new CompanyDto(),
    currentUser: new RecruiterResponseDto(),
    initialJobOfferId: 0
  };

  state: State = {
    unitId: '',
    jobTitleId: '',
    unitError: false,
    jobDescription: '',
    salaryError: false,
    referenceNumber: '',
    jobTitleError: false,
    availableFrom: moment(),
    workingHoursPerMonth: 40,
    jobType: new JobTypeDto({}),
    salaryRange: new SalaryRangeDto({
      min: '',
      max: '',
      salaryType: 'MONTH'
    })
  };

}

export const stateToProps = (state: GlobalState) => ({
  units: state.portal.units.unitsList,
  fetching: state.portal.units.fetching,
  jobTitles: state.portal.jobTitles.jobTitlesList,
  company: state.portal.recruiter.account.companies.company,
  currentUser: state.portal.recruiter.account.current.currentUser,
  currentJobOffer: state.portal.recruiter.jobOffer.general.currentJobOffer
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getAllUnits,
  getAllJobTitles,
  createNewJobOffer,
  saveCurrentJobTitle,
  updateCompanyJobOffer,
  getCompanyJobOfferById
}, dispatch);
