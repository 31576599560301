import React from 'react';

import { Trans } from 'react-i18next';

import { findEvent } from 'middleware/notifications/find-event';
import { connectTranslation } from 'middleware/connect';

import * as eventNames from './anonymous-recruiter.constants';
import { Setting } from './anonymous-recruiter.setting';

@connectTranslation()
export class AnonymousRecruiterTemplate extends Setting {

  render () {
    const { accepted, eventData, publishDate } = this.props;
    return (
      <div className="notification-item-content flexible">
        <div className="logo-container">
          <img
            src={
              findEvent(eventData, eventNames.CANDIDATE_PHOTO_URL)
                ? findEvent(eventData, eventNames.CANDIDATE_PHOTO_URL)
                : '/images/shared/anonymous-pick.png'
            }
            alt="avatar"/>
        </div>
        <div className="content">
          <h3>
            <Trans i18nKey={`portal.notifications.anonymous.recruiter.title.${accepted ? 'accepted' : 'declined'}`}>
              Contact Response:
            </Trans>
          </h3>
          <p>
            {
              !!eventData.length && accepted
                ? <a href={`/portal/recruiter/candidates/${findEvent(eventData, eventNames.CANDIDATE_ID)}/profile`}>
                  <strong className="accent-text">
                    {findEvent(eventData, eventNames.CANDIDATE_FIRST_NAME)} &nbsp;
                    {findEvent(eventData, eventNames.CANDIDATE_LAST_NAME)}
                  </strong>
                </a>
                : <strong className="accent-text">
                  <Trans i18nKey="portal.notifications.anonymous.recruiter.description.0">
                    Anonymous Candidate
                  </Trans>
                </strong>
            }
            &nbsp;
            <Trans i18nKey={`portal.notifications.anonymous.recruiter.${accepted ? 'accepted' : 'declined'}`}>
              accepted your request.
            </Trans>
          </p>
          <span className="publish-date">{publishDate}</span>
        </div>
      </div>
    );
  }

}
