// @flow
import axios, { AxiosPromise } from 'axios';

import { ApplyJobOfferDto } from './apply.dto';

export async function apply (candidateId: string, jobOfferId: string, data: ApplyJobOfferDto): AxiosPromise {
  try {
    return await axios.post(`/candidates/${candidateId}/job-offers/${jobOfferId}/apply`, data);
  } catch (error) {
    throw error;
  }
}
