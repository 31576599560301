// @flow
import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';
import Collapse, { Panel } from 'rc-collapse';

import { ListDto } from 'middleware/dto/list';
import { uniqueArray } from 'middleware/unique-array';
import { connectStore } from 'middleware/connect';

import { Spinner } from 'components/elements';
import { Button } from 'components/buttons';
import { FormGroup } from 'components/controls';

import { AddSkillsModal, SuggestNewSkillModal } from 'containers/portal/skills-modals';

import { SkillsDto } from 'rest/skill/skills.dto';
import { SkillItemDto } from 'rest/skill/skill-item.dto';
import { SearchSkillDto } from 'rest/skill/search-skill.dto';

import type { Props } from './skills.setting';
import { Settings, stateToProps, dispatchToProps } from './skills.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class SkillsTab extends Settings {

  componentDidMount () {
    this.props.getCandidateSkillsById(this.props.currentCandidate.id)
      .then(() => this.fetchFilterSkills());
  }

  componentWillReceiveProps (nextProps: Props) {
    if (!this.state.isAddSkillsModalOpen) {
      this.setFilteredSkills(nextProps.skills);
    }
    if (nextProps.redirect !== this.state.redirect) {
      this.setState({ redirect: nextProps.redirect });
      this.props.next();
    }
  }

  setFilteredSkills (skills: SkillsDto) {
    const jobTitles = this.props.balanceBoard ? this.props.balanceBoard.jobTitles : [];
    if (jobTitles && skills) {
      const skillsSet = { ...this.state.skillsSet };
      const profSkills = [ ...this.getSavedSkills('professionalSkills') ];
      jobTitles.forEach(item => {
        skillsSet[item.id] = this.getCombinedSkills(skills[item.id], profSkills);
      });
      this.setState({ skillsSet: skillsSet });
    }
  }

  fetchFilterSkills () {
    this.props.balanceBoard.jobTitles.map(jobTitle => this.fetchProfessionalSkills(jobTitle.id));
  }

  fetchProfessionalSkills (jobTitleId: string) {
    const query: SearchSkillDto = new SearchSkillDto(this.state);
    query.exclude = false;
    query.jobTitleId = jobTitleId;
    query.size = 10;
    query.page = 0;
    this.props.getAllSkills(query);
  }

  getCombinedSkills (skills: ListDto[], initialSkills: ListDto[] = []): SkillItemDto[] {
    const selectedSkills = skills && skills.length && initialSkills
      .map(item => ({
        ...item,
        id: item.skillId,
        selected: true
      }));
    return skills && uniqueArray([ ...selectedSkills, ...(skills || []) ], 'id');
  }

  addNewElements (list: ListDto[]) {
    const skillsSet = { ...this.state.skillsSet };
    const selectedList = list.map(item => ({ ...item, selected: true }));
    const items = [ ...this.state.skillsSet[this.state.activeTabIndex], ...selectedList ];
    skillsSet[this.state.activeTabIndex] = uniqueArray(items, 'id');
    this.setState({ skillsSet });
  }

  toggleElement (list: ListDto[], element: ListDto): ListDto[] {
    return list.map((item: ListDto) => {
      if (item.id === element.id) {
        return {
          ...item,
          selected: !item.selected
        };
      }
      return item;
    });
  }

  changeSelectElementState (id: string, index: number, element: ListDto) {
    const skillsSet = { ...this.state.skillsSet };
    const items = this.state.skillsSet[id];
    skillsSet[id] = this.toggleElement(items, element);
    this.setState({ skillsSet });
  }

  openSuggestSkillModal (skillType: string) {
    this.setState({
      skillType,
      isSuggestNewSkillModalOpen: true
    });
  }

  toggleModal (fieldIndex: number) {
    this.setState({
      activeTabIndex: fieldIndex,
      isAddSkillsModalOpen: !this.state.isAddSkillsModalOpen
    });
  }

  filterSelectedSkills (skills: ListDto[]): SkillItemDto[] {
    return skills.map(item => {
      if (item.selected) {
        const skillItem = new SkillItemDto(item);
        skillItem.id = item.id;
        skillItem.name = item.name;
        skillItem.skillId = item.id;
        skillItem.skillLevel = item.skillLevel || 'BASIC';
        return skillItem;
      }
    }).filter(item => !!item);
  }

  getFlattenSkills (): ListDto[] {
    const jobTitles = this.props.balanceBoard.jobTitles;
    if (jobTitles) {
      return jobTitles.map(item => this.state.skillsSet[item.id]).reduce((acc, val) => acc.concat(val), []);
    }
    return [];
  }

  getSavedSkills (type: string): SkillItemDto[] {
    return this.props.candidateSkills[type] ? this.props.candidateSkills[type] : [];
  }

  navigateToNextPage (event: Event) {
    event.preventDefault();
    const skillsData = new SkillsDto();
    const flattenSkills = this.getFlattenSkills();
    const profSkills = this.filterSelectedSkills(flattenSkills);
    skillsData.documents = this.getSavedSkills('documents');
    skillsData.softwareSkills = this.getSavedSkills('softwareSkills');
    skillsData.languageSkills = this.getSavedSkills('languageSkills');
    skillsData.professionalSkills = uniqueArray(profSkills, 'id');
    this.props.updateSkills(this.props.currentCandidate.id, skillsData);
  }

  render () {
    return (
      <Fragment>
        {
          this.props.balanceBoard && <form
            className="skills-requirements-form card-toolbar candidate-balance-board-form mobile-balance-board-acordeon"
            onSubmit={this.navigateToNextPage.bind(this)}>
            {
              this.state.skillsSet && <Collapse
                onChange={(activeKey: string[]) => this.setState({ activeKey })}
                activeKey={this.state.activeKey}
                accordion>
                {
                  this.props.balanceBoard.jobTitles.map((item, index) => {
                    return (
                      <Panel key={index} header={item.name}>
                        <FormGroup className="half-width skills-list">
                          {
                            this.state.skillsSet[item.id] ? this.state.skillsSet[item.id].map((skill, index) => (
                              <Button
                                key={index}
                                onClick={() => this.changeSelectElementState(item.id, index, skill)}
                                className={`default element ${!skill.selected ? 'outlined' : ''}`}
                                type="button">
                                {skill.name}
                              </Button>
                            )) : null
                          }
                        </FormGroup>
                        <Button
                          candidate
                          outlined
                          onClick={() => this.toggleModal(item.id)}
                          className="add-more"
                          type="button">
                          <img src="/images/shared/add-icon-green.svg"/>
                          <Trans i18nKey="portal.candidate.balance-board.edit.tabs.skills.add-skills">
                            Add Other Skill
                          </Trans>
                        </Button>
                        <Button
                          onClick={() => this.openSuggestSkillModal('PROFESSIONAL_SKILL')}
                          candidate
                          outlined>
                          Suggest New Skill
                        </Button>
                      </Panel>
                    );
                  })
                }
              </Collapse>
            }
            <FormGroup className="full-width form-buttons">
              <Button
                grey
                type="button"
                onClick={() => this.props.prev()}>
                <Trans i18nKey="portal.candidate.balance-board.edit.buttons.back">
                  Back
                </Trans>
              </Button>
              <Button
                medium
                candidate
                type="submit">
                Save And Next
              </Button>
            </FormGroup>
          </form>
        }
        <Spinner show={this.props.fetching}/>
        <AddSkillsModal
          className="mobile-balance-board-skill-modal"
          candidate
          addNewItems={list => this.addNewElements(list)}
          close={() => this.toggleModal()}
          open={this.state.isAddSkillsModalOpen}/>
        <SuggestNewSkillModal
          className="mobile-balance-board-suggest-modal"
          skillType={this.state.skillType}
          candidate
          close={() => this.setState({ isSuggestNewSkillModalOpen: false })}
          open={this.state.isSuggestNewSkillModalOpen}/>
      </Fragment>
    );
  }

}
