import * as actions from './blacklist.constant';

import { CandidateDto } from 'rest/candidates/candidate.dto';

import { getRecruiterBlackList, unblockCandidate, blockCandidate } from 'rest/recruiter/blacklist/blacklist.rest';

const getRecruiterBlockedUsersRequest = () => ({
  type: actions.GET_BLOCKED_USERS_REQUEST
});

const getRecruiterBlockedUsersSuccess = (blackList: CandidateDto[]) => ({
  type: actions.GET_BLOCKED_USERS_SUCCESS,
  blackList
});

const getRecruiterBlockedUsersFailure = (error: string) => ({
  type: actions.GET_BLOCKED_USERS_FAILURE,
  error
});

const blockUserRequest = () => ({
  type: actions.BLOCK_USER_REQUEST
});

const blockUserSuccess = () => ({
  type: actions.BLOCK_USER_SUCCESS
});

const blockUserFailure = (error: string) => ({
  type: actions.BLOCK_USER_FAILURE,
  error
});

const unblockUserRequest = () => ({
  type: actions.UNBLOCK_USER_REQUEST
});

const unblockUserSuccess = (candidateId: string) => ({
  type: actions.UNBLOCK_USER_SUCCESS,
  candidateId
});

const unblockUserFailure = (error: string) => ({
  type: actions.UNBLOCK_USER_FAILURE,
  error
});

export const getCurrentRecruiterBlackList = (recruiterId: string) => {
  return dispatch => {
    dispatch(getRecruiterBlockedUsersRequest(recruiterId));
    return getRecruiterBlackList(recruiterId)
      .then(response => dispatch(getRecruiterBlockedUsersSuccess(response.data)))
      .catch(error => dispatch(getRecruiterBlockedUsersFailure(error)));
  };
};

export const blockUserCandidate = (recruiterId: string, candidateId: string) => {
  return dispatch => {
    dispatch(blockUserRequest(recruiterId));
    return blockCandidate(recruiterId, candidateId)
      .then(response => dispatch(blockUserSuccess(response.data)))
      .catch(error => dispatch(blockUserFailure(error)));
  };
};

export const unblockUserCandidate = (recruiterId: string, candidateId: string) => {
  return dispatch => {
    dispatch(unblockUserRequest(recruiterId));
    return unblockCandidate(recruiterId, candidateId)
      .then(() => dispatch(unblockUserSuccess(candidateId)))
      .catch(error => dispatch(unblockUserFailure(error)));
  };
};
