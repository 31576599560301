import React from 'react';
import { Trans } from 'react-i18next';

import './listItem.scss';

type Props = {
  data: {
    key: string,
    title: string,
    i18nKey: string,
    children: React.node
  }
};

export const InvoiceAddressListItem = (props: Props) => {
  const { key, title, i18nKey, children } = props.data;

  return (
    <li key={key} className="invoice-address-list-item">
      <span className="invoice-address-list-title">
        <Trans i18nKey={`portal.guest.recruiter.job-offer.payment.invoice-address-list.${i18nKey}`}>
          {title}
        </Trans>

        {children}
      </span>
    </li>
  );
};
