// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { StatsDto } from 'rest/stats/stats.dto';
import { CandidateDto } from 'rest/candidates/candidate.dto';

import {
  getFollowersStatistic,
  getFollowingStatistic,
  resetFollowsStatistic
} from 'services/portal/candidate/jobs/follow/stats/stats.actions';

import { changeFollowingFilter } from 'services/portal/candidate/jobs/follow/following/following.actions';
import { changeFollowersFilter } from 'services/portal/candidate/jobs/follow/followers/followers.actions';

type Props = {
  t?: TransProps,
  currentUser: CandidateDto,
  followingStats: StatsDto[],
  totalFollowing: number,
  followersStats: StatsDto[],
  totalFollowers: number,
  followingCompanyId: number,
  followersCompanyId: number,
  changeFollowingFilter: (companyId?: string) => void,
  changeFollowersFilter: (companyId?: string) => void,
  resetFollowsStatistic: () => void,
  getFollowingStatistic: (candidateId: string) => void,
  getFollowersStatistic: (candidateId: string) => void
}

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  currentUser: state.portal.candidate.account.current.candidateData,
  followingStats: state.portal.candidate.jobs.follow.stats.followingStats,
  followersStats: state.portal.candidate.jobs.follow.stats.followersStats,
  totalFollowers: state.portal.candidate.jobs.follow.stats.totalFollowersElements,
  totalFollowing: state.portal.candidate.jobs.follow.stats.totalFollowingElements,
  followingCompanyId: state.portal.candidate.jobs.follow.following.filters.companyId,
  followersCompanyId: state.portal.candidate.jobs.follow.followers.filters.companyId
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  changeFollowingFilter,
  changeFollowersFilter,
  resetFollowsStatistic,
  getFollowingStatistic,
  getFollowersStatistic
}, dispatch);
