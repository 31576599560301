export class SalaryRangeDto {

  max: number;
  min: number;
  salaryType: string;

  constructor (data?: SalaryRangeDto) {
    if (data) {
      this.max = data.max;
      this.min = data.min;
      this.salaryType = data.salaryType;
    }
  }

}
