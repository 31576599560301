import { Component } from 'react';

import { ChatParticipantDto } from 'rest/chats/chat-participant.dto';

import './avatar.scss';

type Props = {
  participant: ChatParticipantDto
};

export class Settings extends Component<Props> {

  static defaultProps = {
    participant: new ChatParticipantDto()
  };

}
