export class ImageDto {

  id: string;
  url: string;
  imageId: string;
  primary = false;

  constructor (data?: ImageDto) {
    if (data) {
      this.id = data.id;
      this.url = data.url;
      this.imageId = data.imageId;
      this.primary = data.primary || false;
    }
  }

}
