// @flow
import React from 'react';

import { connectStore } from 'middleware/connect';

import { StatisticsContainer, StatisticsItem } from 'components/widgets/statistics';

import { CandidatesParams } from 'rest/public/candidates/candidates.params';

import { Settings, stateToProps, dispatchToProps } from './candidates-list.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class CandidatesListSidebar extends Settings {

  changeBranch (sortType?: string = '') {
    const params: CandidatesParams = new CandidatesParams(this.props.searchCandidates);
    params.sortType = sortType;
    this.props.initCandidatesFilter(params);
  }

  render () {
    return (
      <StatisticsContainer title={this.props.t('portal.recruiter.candidates.sidebar.sort.title')}>
        {
          Object.keys(this.state.filters).map((stat, index) => (
            <StatisticsItem
              key={index}
              title={this.props.t(`portal.recruiter.candidates.sidebar.sort.${stat}`)}
              active={this.props.filters && this.state.filters[stat] === this.props.filters.sortType}
              onClick={() => this.changeBranch(this.state.filters[stat])}
              recruiter/>
          ))
        }
      </StatisticsContainer>
    );
  }

}
