import { ChatResponseDto } from 'rest/chats/chat.response.dto';

import * as actions from './conversations.constants';

import type { ConversationsState } from './conversations.type';

const initialState: ConversationsState = {
  content: [],
  fetching: false,
  currentPage: 0,
  hasMoreItems: true,
  conversation: null,
  totalElements: 0,
  unreadMessages: 0,
  createdConversation: null
};

export default (state = initialState, action): ConversationsState => {
  let content = [];
  switch (action.type) {
    case actions.GET_CONVERSATIONS_REQUEST:
      return {
        ...state,
        fetching: true,
        hasMoreItems: false,
        createdConversation: null
      };

    case actions.GET_CONVERSATIONS_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.GET_CONVERSATIONS_SUCCESS:
      content = action.payload.content.map(chat => new ChatResponseDto(chat));
      return {
        ...state,
        fetching: false,
        content: action.payload.number ? [ ...state.content, ...content ] : content,
        currentPage: action.payload.number + 1,
        hasMoreItems: action.payload.content.length > 0,
        totalElements: action.payload.totalElements
      };

    case actions.CREATE_CONVERSATIONS_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case actions.CREATE_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        fetching: false,
        createdConversation: action.payload.chatId
      };

    case actions.RESET_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        content: [],
        currentPage: 0,
        hasMoreItems: true
      };

    case actions.GET_CONVERSATION_SUCCESS:
      return {
        ...state,
        conversation: new ChatResponseDto(action.payload)
      };

    case actions.GET_UNREAD_CONVERSATION_SUCCESS:
      return {
        ...state,
        unreadMessages: action.payload.unreadCount
      };

    case actions.BLOCK_CONVERSATION_SUCCESS: {
      const content = [ ...state.content ];
      const conversation = { ...state.conversation };
      const chatIndex: number = state.content.findIndex(chat => chat.id === action.chatId);
      if (content[chatIndex]) {
        conversation.blocked = true;
        content[chatIndex].blocked = true;
      }
      return {
        ...state,
        content,
        conversation
      };
    }

    case actions.RESET_UNREAD_CONVERSATION_SUCCESS: {
      const content = [ ...state.content ];
      const chatIndex: number = state.content.findIndex(chat => chat.id === action.chatId);
      if (content[chatIndex]) {
        content[chatIndex].countUnreadMessage = 0;
      }
      return {
        ...state,
        content,
        unreadMessages: 0
      };
    }

    default:
      return state;
  }
};
