import React from 'react';

import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Setting } from './candidate-invite.setting';

@connectTranslation()
export class CandidateInviteTemplate extends Setting {

  render () {
    const { eventData, publishDate } = this.props;
    return (
      <div className="notification-item-content flexible">
        <div className="logo-container">
          <img src="/images/notifications/userpick.svg" alt="logo"/>
        </div>
        <div className="content">
          <h3>
            <Trans i18nKey="portal.notifications.invite.title">
              Invitation!
            </Trans>
          </h3>
          {
            eventData.length && <p>
              <Trans i18nKey="portal.notifications.invite.description">
                You have been invited for an interview.
              </Trans>
            </p>
          }
          <span className="publish-date">{publishDate}</span>
        </div>
      </div>
    );
  }

}
