// @flow
import React, { Fragment } from 'react';

import { Trans } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import { connectStore } from 'middleware/connect';
import { PaginationDto } from 'middleware/dto/pagination';

import { ElementsList } from 'components/elements-list';
import { ListPlaceholder, Spinner } from 'components/elements';

import { JobOfferDto } from 'rest/job-offers/job-offers.dto';

import { JobOfferRow } from './../job-offer-row/job-offer-row';

import { stateToProps, dispatchToProps, Setting } from './job-offers-list.setting';

@connectStore(stateToProps, dispatchToProps)
export class JobOffersList extends Setting {

  componentWillUnmount () {
    this.props.resetMatchedJobOffers();
  }

  loadJobOffersList (page: number = 0) {
    const params = new PaginationDto();
    params.page = page;
    this.props.getMatchedJobOffers(this.props.companyId, params);
  }

  initJobOffersList (): HTMLElement {
    return this.props.jobOffers.map((jobOffer: JobOfferDto, index) => (
      <JobOfferRow
        key={index}
        onClick={(id: string) => this.props.history.push(`/portal/recruiter/match/${id}/candidates`)}
        jobOffer={jobOffer}>
      </JobOfferRow>
    ));
  }

  initEmptyList () : HTMLDivElement {
    return !this.props.jobOffers.length ? (
      <ListPlaceholder
        title={this.props.t('portal.recruiter.match.list.placeholder.title')}>
        <Trans i18nKey="portal.recruiter.match.list.placeholder.content">
          You will have a match-list for every published job offer. Your match-list is based on your job requirements
          and the minimal matching rate.
        </Trans>
      </ListPlaceholder>
    ) : null;
  }

  render () {
    return (
      <Fragment>
        {
          this.props.companyId && <InfiniteScroll
            pageStart={-1}
            threshold={100}
            useWindow={false}
            hasMore={this.props.hasMoreItems}
            loadMore={() => this.loadJobOffersList(this.props.currentPage)}>
            <ElementsList>{this.initJobOffersList()}</ElementsList>
            {this.initEmptyList()}
          </InfiniteScroll>
        }
        <Spinner show={this.props.fetching}/>
      </Fragment>
    );
  }

}
