// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { RecruiterResponseDto } from 'rest/recruiter/recruiter.response.dto';

import {
  getCurrentCompanyRecruiter,
  activateCompanyRecruiter
} from 'services/portal/recruiter/account/account.actions';

import { deleteSavedJobOfferData } from 'services/portal/recruiter/job-offer/process/process.actions';

import './free-payment.scss';

export type Props = {
  fetching?: boolean,
  getCurrentRecruiter?: () => void,
  currentUser?: RecruiterResponseDto,
  activateRecruiterSuccess?: boolean,
  deleteSavedJobOfferData?: () => void,
  getCurrentCompanyRecruiter?: () => void,
  activateCompanyRecruiter?: (recruiterId: string) => void
}

type State = {
  redirectLink: string
}

export class Settings extends Component<Props, State> {

  static defaultProps = {
    currentUser: null,
    deleteSavedJobOfferData: () => {},
    activateCompanyRecruiter: () => {},
    getCurrentCompanyRecruiter: () => {}
  };

  state: State = {
    redirectLink: ''
  }

}

export const stateToProps = (state: GlobalState) => ({
  fetching: state.portal.recruiter.account.current.fetching,
  currentUser: state.portal.recruiter.account.current.currentUser,
  activateRecruiterSuccess: state.portal.recruiter.account.current.activateRecruiterSuccess
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  deleteSavedJobOfferData,
  activateCompanyRecruiter,
  getCurrentCompanyRecruiter
}, dispatch);
