import { Component } from 'react';

import I18n, { TransProps } from 'react-i18next';

import { EventDataDto } from 'rest/alerts/alerts.event-data.dto';

import moment from 'moment';

type Props = {
  i18n?: I18n,
  t?: TransProps,
  eventData: EventDataDto[],
  publishDate: string
}

type State = {
  localization: string
}

export class Setting extends Component<Props, State> {

  static defaultProps: Props = {
    eventData: [],
    publishDate: moment().parseZone().fromNow()
  };

  state: State = {
    localization: this.props.i18n.language
  };

}
