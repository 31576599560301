// @flow
import React from 'react';

import { connectStore } from 'middleware/connect';

import { Dropdown } from 'components/controls';
import { PortletControl } from 'components/portlet';

import { JobOffersParams } from 'rest/public/job-offers/job-offers.params';

import { Settings, stateToProps, dispatchToProps } from './filter.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class FollowersFilters extends Settings {

  componentDidMount () {
    this.props.getBranches();
    if (this.props.currentCandidateId) {
      this.props.getLocations(this.props.currentCandidateId);
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.currentCandidateId !== this.props.currentCandidateId) {
      this.props.getLocations(this.props.currentCandidateId);
    }
  }

  getFilteredFollowingJobs () {
    const params = new JobOffersParams();
    params.branchId = this.state.branchId;
    params.placeId = this.state.cityPlaceId;
    this.props.changeFollowersFilter(params);
  }

  render () {
    return (
      <PortletControl>
        <Dropdown
          title="Location"
          allowUnSelect
          defaultValue="All"
          change={value =>
            this.setState({ cityPlaceId: value ? value.id : '' }, () => this.getFilteredFollowingJobs())}
          value={this.state.cityPlaceId}
          items={this.props.locations.map(item => ({ id: item.cityPlaceId, name: item.name }))}
        />
        <Dropdown
          title="Branch"
          allowUnSelect
          defaultValue="All"
          change={value =>
            this.setState({ branchId: value ? value.id : '' }, () => this.getFilteredFollowingJobs())}
          value={this.state.branchId}
          items={this.props.branches}/>
      </PortletControl>
    );
  }

}
