import { Component } from 'react';

type Props = {
  activeTab: number,
  children: Node
};

export class Setting extends Component<Props> {

  static defaultProps = {
    activeTab: 0
  }

}
