// @flow
import { Component } from 'react';

type Props = {
  path: string,
  type?: string,
  exact?: boolean,
  component: Element
}

export class Setting extends Component<Props> {

  static defaultProps = {
    path: '',
    type: '',
    exact: false,
    component: null
  };

}
