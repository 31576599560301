import React, { Fragment } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';

import cn from 'classnames';

import type { Props } from './modal.setting';
import { Setting, stateToProps, dispatchToProps } from './modal.setting';

@connect(stateToProps, dispatchToProps)
export class Modal extends Setting {

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.open !== this.state.isModalOpen) {
      this.setState({ isModalOpen: nextProps.open });
      if (nextProps.open) {
        this.props.onOpen();
      }
    }
  }

  componentWillUnmount () {
    if (this.props.open) {
      this.props.hideModal();
    }
  }

  closeModal (event: Event) {
    setTimeout(() => {
      this.props.hideModal(event);
      this.props.closeOnOverlay(event);
      this.setState({ isModalOpen: false });
    }, 100);
  }

  initModalType (): React.ReactNode {
    if (this.props.animation === 'fade') {
      return (
        <div className="modal-content">
          { this.props.children }
        </div>
      );
    } else {
      return this.props.children;
    }
  }

  render () {
    const modalWrapperClasses: string = cn({
      'modal-overlay': true,
      'open': this.state.isModalOpen,
      'overlayed': !this.props.hideModalAvailable
    });
    const modalClasses: string = cn([
      'modal',
      [this.props.className],
      [this.props.animation],
      { open: this.state.isModalOpen }
    ]);

    return createPortal(
      <Fragment>
        {
          <div className={modalClasses}>
            {
              this.state.isModalOpen && <div className="modal-container">
                { this.initModalType() }
              </div>
            }
          </div>
        }
        {
          this.props.overlay && <div
            onClick={(event: Event) => this.closeModal(event)}
            className={modalWrapperClasses}/>
        }
      </Fragment>,
      document.getElementById('modal')
    );
  }

}
