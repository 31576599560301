// @flow
import React from 'react';

import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';

import { Button } from 'components/buttons';
import { FormGroup, InputControl } from 'components/controls';

import { Modal, ModalCaption } from 'containers/portal/modal-container';

import { RequestSkillDto } from 'rest/skill/request/request.dto';

import type { Props } from './suggest-skill-modal.setting';
import { Settings, dispatchToProps, stateToProps } from './suggest-skill-modal.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class SuggestNewSkillModal extends Settings {

  componentWillReceiveProps (nextProps: Props) {
    if (this.props.open && nextProps.requestedSuccess !== this.state.showSuccessMessage) {
      this.setState({ showSuccessMessage: nextProps.requestedSuccess });
    }
    if (nextProps.errorCode && nextProps.errorCode !== this.state.errorCode) {
      this.resolveErrorToast();
    }
  }

  resolveErrorToast () {
    this.setState({ showSuccessMessage: true });
  }

  confirmRequestedSkill (event: Event) {
    event.preventDefault();
    const skill: RequestSkillDto = new RequestSkillDto(this.state);
    skill.skillType = this.props.skillType;
    this.props.requestSkill(skill);
  }

  getSkillType () {
    switch (this.props.skillType) {
      case 'PROFESSIONAL_SKILL':
        return this.props.t('portal.recruiter.balance-board.job-offer.suggest.types.0');
      case 'DOCUMENTS':
        return this.props.t('portal.recruiter.balance-board.job-offer.suggest.types.1');
      case 'SOFTWARE':
        return this.props.t('portal.recruiter.balance-board.job-offer.suggest.types.2');
      case 'LANGUAGE':
        return 'Language';
    }
  }

  close () {
    this.setState({
      name: '',
      showSuccessMessage: false
    });
    this.props.resetSuggestedSkill();
    this.props.close();
  }

  render () {
    return (
      <Modal
        overlay
        animation="fade"
        open={this.props.open}
        hideModalAvailable={false}
        hideModal={() => this.close()}
        closeOnOverlay={() => this.close()}
        className={`suggest-skill-modal-container ${this.props.className}`}>
        <ModalCaption
          hideModal={() => this.close()}
          title={`${this.props.t('portal.recruiter.balance-board.job-offer.suggest.title')} ${this.getSkillType()}`}/>
        <form
          onSubmit={event => this.confirmRequestedSkill(event)}
          className="suggest-skill-modal-form">
          {
            this.state.showSuccessMessage && <div className="suggest-skill-success-description">
              <img
                className="success-requested-skill-icon"
                src="/images/request-skill/success-icon.svg"
                alt="Success Icon Checked"/>
              <p>
                <Trans i18nKey="portal.recruiter.balance-board.job-offer.suggest.message">
                  Cancel
                </Trans>
              </p>
            </div>
          }
          {
            !this.state.showSuccessMessage && <FormGroup className="half-width">
              <InputControl
                maxLength={35}
                label={`${this.getSkillType()} name`}
                value={this.state.name}
                change={(value: string): void => this.setState({ name: value })}
                placeholder={this.props.t('portal.recruiter.balance-board.job-offer.suggest.name')}
                pattern=".+"
                required
                type="text"/>
            </FormGroup>
          }
          <div className="suggest-skill-modal-form-action">
            {
              !this.state.showSuccessMessage && <Button
                type="submit"
                candidate={this.props.candidate}
                recruiter={this.props.recruiter}
                medium>
                {`${this.props.t('portal.recruiter.balance-board.job-offer.suggest.buttons.apply')}
                ${this.getSkillType()}`}
              </Button>
            }
            <Button
              medium
              type="button"
              onClick={() => this.close()}
              grey>
              <Trans i18nKey="portal.recruiter.balance-board.job-offer.suggest.buttons.cancel">
                Cancel
              </Trans>
            </Button>
          </div>
        </form>
      </Modal>
    );
  }

}
