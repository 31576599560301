import { Component } from 'react';

type Props = {
  children: Node,
  className: string
};

export class Setting extends Component<Props> {

  static defaultProps = {
    className: ''
  }

}
