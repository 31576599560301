import moment from 'moment';

export const getSecondsFromDecimal = (decimalTime: number): number => {
  if (decimalTime) {
    const secondsInMinute: number = 60;
    return decimalTime * secondsInMinute * 60;
  }
};

export const getDecimalTime = (time: string): number => {
  if (time) {
    const secondsInMinute: number = 60;
    const hours: number = parseInt(time.split(':')[0]);
    const minutes: number = parseInt(time.split(':')[1]);
    return parseFloat(hours + (minutes / secondsInMinute));
  }
};

export const getHoursTime = (decimalTime: number): string => {
  if (decimalTime) {
    return moment.utc(getSecondsFromDecimal(decimalTime) * 1000).format('HH:mm');
  }
  return '';
};

export const getFormattedHours = (decimalTime: number): string => {
  if (decimalTime) {
    const seconds: number = getSecondsFromDecimal(decimalTime);
    const duration: moment = moment.duration(seconds, 'seconds');
    const days: number = duration.days();
    const hours: number = duration.hours();
    const minutes: number = duration.minutes();
    return `${days > 0 ? days + ' day' : ''} ${hours > 0 ? hours + ' hr' : ''} ${minutes > 0 ? minutes + ' m' : ''}`;
  }
  return '';
};
