// @flow
import React from 'react';

import cn from 'classnames';

import { Setting } from './item.setting';

export class StatisticsItem extends Setting {

  render () {
    const className = cn({
      'accent': this.props.recruiter,
      'active': this.props.active,
      'primary': this.props.candidate,
      'statistics-item': true
    });

    return (
      <div
        onClick={() => this.props.onClick()}
        className={className}>
        <div className="left-side">
          <div className="title">{ this.props.title }</div>
          {
            this.props.description && <div className="description">{ this.props.description }</div>
          }
        </div>
        <div className="right-side">
          <div className="value">{ this.props.value }</div>
        </div>
      </div>
    );
  }

}
