import { Component } from 'react';

import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';
import { bindActionCreators } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { getAllBranches } from 'services/portal/recruiter/account/companies/companies.actions';

import { getAllUnits } from 'services/portal/units/units.actions';
import { getAllJobTitles } from 'services/portal/job-titles/job-titles.actions';

import {
  clearCandidatesSearch,
  saveSearchCandidatesUnit,
  saveSearchCandidatesClose,
  saveSearchCandidatesBranch,
  saveSearchCandidatesJobTitle,
  saveSearchCandidatesLocation,
  saveSearchCandidatesRelocate,
  saveSearchCandidatesWorkingTime,
  saveSearchCandidatesWorkingType,
  saveSearchCandidatesSalaryMaximum,
  saveSearchCandidatesSalaryMinimum,
  saveSearchCandidatesAvailableFrom
} from 'services/portal/recruiter/candidate/search/search.actions';

import {
  initCandidatesFilter,
  clearCandidatesFilter
} from 'services/portal/recruiter/candidate/all/candidates.actions';

import { searchSuggestedAddress } from 'services/portal/address/address.actions';

import { AddressResponseDto } from 'rest/address/address.response.dto';

import './default-filter.scss';

export const workingTypes = [
  {
    id: 'Employee',
    name: 'Employee'
  },
  {
    id: 'Temporary',
    name: 'Part-Time'
  },
  {
    id: 'Trainee',
    name: 'Trainee'
  }
];

type Props = {
  t: TransProps,
  visible: boolean,
  address?: AddressResponseDto[],
  searchSuggestedAddress?: (location: string) => void
};

export class Settings extends Component<Props> {

  static defaultProps = {
    visible: false
  };

}

export const stateToProps = (state: GlobalState) => ({
  units: state.portal.units.unitsList,
  address: state.portal.address.content,
  jobTitles: state.portal.jobTitles.jobTitlesList,
  branches: state.portal.recruiter.account.companies.branches,
  searchCandidates: state.portal.recruiter.candidate.searchCandidates
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getAllUnits,
  getAllBranches,
  getAllJobTitles,
  initCandidatesFilter,
  clearCandidatesSearch,
  clearCandidatesFilter,
  saveSearchCandidatesUnit,
  saveSearchCandidatesClose,
  saveSearchCandidatesBranch,
  searchSuggestedAddress,
  saveSearchCandidatesRelocate,
  saveSearchCandidatesJobTitle,
  saveSearchCandidatesLocation,
  saveSearchCandidatesWorkingTime,
  saveSearchCandidatesWorkingType,
  saveSearchCandidatesSalaryMinimum,
  saveSearchCandidatesSalaryMaximum,
  saveSearchCandidatesAvailableFrom
}, dispatch);
