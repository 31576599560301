import { Component } from 'react';

import { ChatParticipantDto } from 'rest/chats/chat-participant.dto';
import { MessagesResponseDto } from 'rest/chats/messages/messages.response.dto';

import './message.scss';
import './message.responsive.scss';

type Props = {
  message: MessagesResponseDto,
  participants: ChatParticipantDto[],
  className?: string
};

type State = {
  date: string
}

export class Settings extends Component<Props, State> {

  static defaultProps: Props = {
    message: new MessagesResponseDto(),
    participants: [],
    className: ''
  };

  state = {
    date: null
  }

}
