export class ApplicationDto {

  body: string;
  subject: string;
  templateId: string;

  constructor (data?: ApplicationDto) {
    if (data) {
      this.body = data.body;
      this.subject = data.subject;
      this.templateId = data.templateId;
    }
  }

}
