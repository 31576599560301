import * as actions from './personal-data.constants';

import type { PersonalDataState } from './personal-data.type';

const initialState: PersonalDataState = {
  errorText: '',
  success: false,
  fetching: false
};

export default (state = initialState, action): PersonalDataState => {
  switch (action.type) {
    case actions.CREATE_PERSONAL_INFO_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case actions.CREATE_PERSONAL_INFO_FAILURE:
      return {
        ...state,
        fetching: false,
        errorText: action.error
      };

    case actions.CREATE_PERSONAL_INFO_SUCCESS:
      return {
        ...state,
        fetching: false,
        success: action.success
      };

    default:
      return state;
  }
};
