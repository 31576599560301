export const GET_FOLLOWING_COMPANY_REQUEST = 'GET_FOLLOWING_COMPANY_REQUEST';
export const GET_FOLLOWING_COMPANY_SUCCESS = 'GET_FOLLOWING_COMPANY_SUCCESS';
export const GET_FOLLOWING_COMPANY_FAILURE = 'GET_FOLLOWING_COMPANY_FAILURE';

export const ADD_COMPANY_TO_FOLLOWING_REQUEST = 'ADD_COMPANY_TO_FOLLOWING_REQUEST';
export const ADD_COMPANY_TO_FOLLOWING_SUCCESS = 'ADD_COMPANY_TO_FOLLOWING_SUCCESS';
export const ADD_COMPANY_TO_FOLLOWING_FAILURE = 'ADD_COMPANY_TO_FOLLOWING_FAILURE';

export const DELETE_COMPANY_FROM_FOLLOWING_REQUEST = 'DELETE_COMPANY_FROM_FOLLOWING_REQUEST';
export const DELETE_COMPANY_FROM_FOLLOWING_SUCCESS = 'DELETE_COMPANY_FROM_FOLLOWING_SUCCESS';
export const DELETE_COMPANY_FROM_FOLLOWING_FAILURE = 'DELETE_COMPANY_FROM_FOLLOWING_FAILURE';

export const GET_FOLLOWING_JOB_OFFERS_REQUEST = 'GET_FOLLOWING_JOB_OFFERS_REQUEST';
export const GET_FOLLOWING_JOB_OFFERS_SUCCESS = 'GET_FOLLOWING_JOB_OFFERS_SUCCESS';
export const GET_FOLLOWING_JOB_OFFERS_FAILURE = 'GET_FOLLOWING_JOB_OFFERS_FAILURE';

export const GET_FOLLOWING_COMPANIES_LOCATIONS_REQUEST = 'GET_FOLLOWING_COMPANIES_LOCATIONS_REQUEST';
export const GET_FOLLOWING_COMPANIES_LOCATIONS_SUCCESS = 'GET_FOLLOWING_COMPANIES_LOCATIONS_SUCCESS';
export const GET_FOLLOWING_COMPANIES_LOCATIONS_FAILURE = 'GET_FOLLOWING_COMPANIES_LOCATIONS_FAILURE';

export const RESET_FOLLOWING_JOB_OFFER_SUCCESS = 'RESET_FOLLOWING_JOB_OFFER_SUCCESS';

export const CHANGE_FOLLOWING_FILTERS_SUCCESS = 'CHANGE_FOLLOWING_FILTERS_SUCCESS';
