// @flow
import React from 'react';

import cn from 'classnames';
import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';
import { getData, setData } from 'middleware/job-offer-storage';

import { Button } from 'components/buttons';
import { FormGroup } from 'components/controls';

import { MatchModal } from 'containers/portal/recruiter/job-offer';
import { SkillItem } from './skill-item/skill-item';

import { SkillsDto } from 'rest/skill/skills.dto';
import { SkillItemDto } from 'rest/skill/skill-item.dto';

import { Settings } from './priority-match.setting';

@connectTranslation()
export class PriorityMatchTab extends Settings {

  componentDidMount () {
    const savedSkills = getData('ej_skills_job_offer');
    if (this.state !== savedSkills) {
      this.setState({ ...savedSkills }, () => {
        this.filterRequiredSkills();
        this.calculateSkillPriority();
      });
    }
  }

  calculateSkillPriority () {
    const skills = this.collectAllSkills();
    const count = skills.reduce((prev, curr) => prev + curr.points, 0);
    this.setState({
      count,
      disabled: this.state.count >= 100
    });
  }

  collectAllSkills (): SkillItemDto[] {
    return [
      ...this.state.documents,
      ...this.state.languageSkills,
      ...this.state.softwareSkills,
      ...this.state.professionalSkills
    ];
  }

  filterRequiredSkills (): SkillItemDto[] {
    this.setState({
      requiredSkillsCount: this.getRequiredSkills().length
    });
  }

  getRequiredSkills (): SkillItemDto[] {
    return this.collectAllSkills()
      .filter(item => item.required);
  }

  changeSkillLevelState (field: string, value: string, skillId: number) {
    const skills = this.state[field].map(item => {
      if (item.id === skillId) {
        return {
          ...item,
          skillLevel: value.toUpperCase()
        };
      }
      return item;
    });
    this.setState({
      [field]: skills
    }, () => this.calculateSkillPriority());
  }

  getSkills (state, field, value, skillId) {
    return state[field].map(item => {
      if (item.id === skillId) {
        const count = state.count - item.points + value;
        const different = count < 100 ? 0 : (count - 100);
        return {
          ...item,
          points: value - different
        };
      }
      return item;
    });
  }

  changeSkillPointsState (field: string, value: string, skillId: number) {
    this.setState(state => {
      return {
        [field]: this.getSkills(state, field, value, skillId),
        minPointsError: state.count < this.maxPointsCount
      };
    }, () => this.calculateSkillPriority());
  }

  errorCollector (errorName: string, collect: boolean) {
    const errorCollection: Set<string> = new Set(this.state.errorCollection);
    collect ? errorCollection.add(errorName) : errorCollection.delete(errorName);
    this.setState({ errorCollection });
  }

  isRichMaxRequiredSkillsLength (): boolean {
    return this.state.requiredSkillsCount <= this.maxRequiredSkillsCount - 1;
  }

  toggleRequiredSkill (field: string, skill: SkillItemDto) {
    if (this.isRichMaxRequiredSkillsLength() || skill.required) {
      const skills = this.state[field].map((item: SkillItemDto) => {
        if (item.id === skill.id) {
          return {
            ...item,
            points: 0,
            required: !skill.required
          };
        }
        return item;
      });
      this.setState({
        [field]: skills
      }, () => {
        this.calculateSkillPriority();
        this.filterRequiredSkills();
      });
    }
  }

  validate (cb: (isValid: boolean) => void) {
    this.setState({
      minPointsError: this.state.count < this.maxPointsCount,
      maxPointsError: this.state.count > this.maxPointsCount
    }, () => {
      const valid = !this.state.maxPointsError && !this.state.minPointsError;
      cb(!this.state.errorCollection.size && valid);
    });
  }

  saveDraft () {
    const skillsData = new SkillsDto(this.state);
    skillsData.emptySkills = true;
    skillsData.matchPoints = this.state.matchPoints;
    setData('ej_skills_job_offer', skillsData);
  }

  navigateToNextPage (event: Event) {
    event.preventDefault();
    this.validate(valid => {
      if (valid) {
        this.saveDraft();
        this.props.next();
      }
    });
  }

  render () {
    const skillBadgeClasses = cn(['skill-badge', {
      'error-text': this.state.maxPointsError,
      'success-text': this.state.count === this.maxPointsCount
    }]);
    const skillCountClasses = cn({
      'required-skills-success': this.state.requiredSkillsCount === this.maxRequiredSkillsCount
    });
    const requiredSkills = this.getRequiredSkills();
    const skillList: string[] = [
      'professionalSkills',
      'languageSkills',
      'softwareSkills',
      'documents',
      'softSkills'
    ];
    return (
      <form
        onSubmit={this.navigateToNextPage.bind(this)}
        className="priority-match-form">
        <div className="row header sticky">
          <div className="column title">
            <Trans i18nKey="portal.recruiter.balance-board.job-offer.priority.skill">
              Skill Name
            </Trans>
          </div>
          <div className="column level">
            <Trans i18nKey="portal.recruiter.balance-board.job-offer.priority.knowledge">
              Knowledge Level
            </Trans>
          </div>
          <div className="column priority">
            <Trans i18nKey="portal.recruiter.balance-board.job-offer.priority.priority">
              Skill Priority
            </Trans>
            <div className={skillBadgeClasses}>
              <span>{this.state.count} </span> / 100
            </div>
          </div>
          <div className="column choice">
            <span className={skillCountClasses}>({this.state.requiredSkillsCount}/3)</span>
          </div>
        </div>
        <div className={`draggable-area ${this.state.requiredSkillsCount > 0 ? '' : 'initial'}`}>
          {
            this.state.requiredSkillsCount > 0 ? requiredSkills.map((item: SkillItemDto, index: number) => {
              return (
                <SkillItem
                  required
                  changeSkillLevel={value => this.changeSkillLevelState(item.type, value, item.id)}
                  removeFromRequired={() => this.toggleRequiredSkill(item.type, item)}
                  key={index}
                  skill={item}
                />
              );
            }) : <p>
              <b>
                <Trans i18nKey="portal.recruiter.balance-board.job-offer.priority.information.press">
                  Press
                </Trans>
                &nbsp;
              </b>
              <span className="select-row spaced"/>
              <Trans i18nKey="portal.recruiter.balance-board.job-offer.priority.information.text">
                <b> to set up your *must have* skills </b>
                (you will only get candidates that have this skill(s) on the required level)
              </Trans>
            </p>
          }
        </div>
        {
          skillList.map(section => {
            return (this.state[section] || []).filter(item => !item.required).map((item, index) => (
              <SkillItem
                required={item.required}
                disabled={this.state.disabled}
                hideSkillLevel={section === 'documents'}
                changeSkillLevel={value => this.changeSkillLevelState(section, value, item.id)}
                changeSkillPoints={value => this.changeSkillPointsState(section, value, item.id)}
                addToRequired={() => this.toggleRequiredSkill(section, item)}
                key={index}
                skill={item}/>
            ));
          })
        }
        <FormGroup className="matching-rate">
          <h2 className="title">
            <Trans i18nKey="portal.recruiter.balance-board.job-offer.priority.match.title">
              Matching Rate
            </Trans>
          </h2>
          <Trans i18nKey="portal.recruiter.balance-board.job-offer.priority.match.description">
            Current matching rate for this job offer:
          </Trans>
          <strong> {this.state.matchPoints} %</strong>
          <Button
            recruiter
            outlined
            onClick={() => { this.setState({ isMatchModalOpen: true }); }}>
            <Trans i18nKey="portal.recruiter.balance-board.job-offer.priority.match.button">
              Change matching rate
            </Trans>
          </Button>
        </FormGroup>
        <FormGroup className="full-width form-buttons">
          {
            this.state.minPointsError && <p className="error-text">
              <Trans i18nKey="portal.recruiter.balance-board.job-offer.priority.errors.1">
                Skill priority cannot be less than 100
              </Trans>
            </p>
          }
          {
            this.state.maxPointsError && <p className="error-text">
              <Trans i18nKey="portal.recruiter.balance-board.job-offer.priority.errors.2">
                You should only have 100 points in total
              </Trans>
            </p>
          }
          <Button
            grey
            type="button"
            onClick={() => this.props.prev()}>
            <Trans i18nKey="portal.recruiter.balance-board.job-offer.buttons.back">
              Back
            </Trans>
          </Button>
          <Button
            recruiter
            medium
            type="submit">
            <Trans i18nKey="portal.recruiter.balance-board.job-offer.buttons.next">
              Next
            </Trans>
          </Button>
          <MatchModal
            className="guest-skills-modal-container"
            open={this.state.isMatchModalOpen}
            apply={(value: number) => this.setState({ matchPoints: value })}
            close={() => this.setState({ isMatchModalOpen: false })}
            skills={this.state}
            current={this.state.matchPoints}/>
        </FormGroup>
      </form>
    );
  }

}
