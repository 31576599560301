// @flow
import React from 'react';

import { Trans } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { Card } from 'components/layouts';
import { Modal } from 'components/dialogs';
import { Tab, Tabs, TabHeader } from 'components/tabs';

import { GeneralInfoTab } from './../general-info/general-info';
import { PriorityMatchTab } from './../edit-job-offer/priority-match/priority-match';
import { SkillsRequirementsTab } from './../edit-job-offer/skills-requirements/skills-requirements';

import { Settings, stateToProps, dispatchToProps } from './modal.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class CreateJobOfferModal extends Settings {

  clearJobOfferData () {
    this.props.resetJobOfferSkills();
    this.props.resetCurrentJobOffer();
    this.props.deleteSavedJobOfferData();
  }

  render () {
    const isPriorityTabActive: boolean = this.props.skills ? this.props.skills.professionalSkills.length > 0 : false;
    return (
      <Modal
        closeAction={() => this.clearJobOfferData()}
        closePath="/portal/recruiter/balance-board">
        <div className="create-job-offer-modal">
          <Card>
            <PerfectScrollbar className="content">
              <h3 className="title">
                <Trans i18nKey="portal.recruiter.balance-board.job-offer.title">
                  Let’s create your perfect job offer together!
                </Trans>
              </h3>
              <Tabs
                activeTabIndex={this.state.activeTab}
                activeTabChange={activeTab => this.setState({ activeTab }) }>
                <Tab label={this.props.t('portal.recruiter.balance-board.job-offer.general.header')}>
                  <TabHeader
                    title={this.props.t('portal.recruiter.balance-board.job-offer.general.title')}
                    subTitle={this.props.t('portal.recruiter.balance-board.job-offer.general.description')}/>
                  <GeneralInfoTab
                    next={() => this.setState({ activeTab: this.state.activeTab + 1 })}
                    jobOfferId={this.props.match.params.jobOfferId}/>
                </Tab>
                <Tab
                  disabled={!!this.props.currentJobOffer}
                  label={this.props.t('portal.recruiter.balance-board.job-offer.skills.header')}>
                  <SkillsRequirementsTab
                    new
                    next={() => this.setState({ activeTab: this.state.activeTab + 1 })}
                    prev={() => this.setState({ activeTab: this.state.activeTab - 1 })}/>
                </Tab>
                <Tab
                  disabled={!isPriorityTabActive}
                  label={this.props.t('portal.recruiter.balance-board.job-offer.priority.header')}>
                  <TabHeader title={this.props.t('portal.recruiter.balance-board.job-offer.priority.title')}/>
                  <PriorityMatchTab
                    type={'create'}
                    prev={() => this.setState({ activeTab: this.state.activeTab - 1 })}
                    closeJobOffer={() => this.props.history.push('/portal/recruiter/job-offers')}/>
                </Tab>
              </Tabs>
            </PerfectScrollbar>
          </Card>
        </div>
      </Modal>
    );
  }

}
