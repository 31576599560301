// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { CurrentRecruiterDto } from 'rest/recruiter/current/current.dto';

import { getMatchStatistic, resetMatchStats } from 'services/portal/recruiter/match/stats/stats.actions';

type Props = {
  t: TransProps,
  total?: number,
  currentUser?: CurrentRecruiterDto,
  resetMatchStats: () => void,
  getMatchStatistic: () => void
}

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  total: state.portal.recruiter.match.jobOffersStats.matchedCandidatesCount,
  currentUser: state.portal.recruiter.account.current.currentUser
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetMatchStats,
  getMatchStatistic
}, dispatch);
