import React from 'react';

import { Trans } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import { connectStore } from 'middleware/connect';

import { Spinner } from 'components/elements';
import { ElementsList } from 'components/elements-list';

import { SearchCandidateRow } from './search-candidate-row/search-candidate-row';
import { EmptyCandidatesSearchList } from './empty-candidates-list/empty-candidates-list';

import { CandidateDto } from 'rest/candidates/candidate.dto';
import { CandidatesParams } from 'rest/public/candidates/candidates.params';

import { stateToProps, dispatchToProps, Setting } from './candidates-list.setting';

@connectStore(stateToProps, dispatchToProps)
export class CandidatesList extends Setting {

  componentWillUnmount () {
    this.props.saveSearchCandidatesBranch();
    this.props.resetCandidatesList();
  }

  loadCandidateList (page: number = 0) {
    const params: CandidatesParams = new CandidatesParams(this.props.candidatesFilter);
    params.page = page;
    params.size = 10;
    params.extraFields = ['favorite'];
    this.props.getAllCandidates(params);
  }

  openCandidateProfile (selectedCandidateId: string) {
    this.props.history.push(`/portal/recruiter/candidates/${selectedCandidateId}/profile`);
  }

  initCandidatesList (): HTMLElement {
    return this.props.candidates && this.props.candidates.map((candidate: CandidateDto, index: number) => (
      <SearchCandidateRow
        key={index}
        onClick={() => this.openCandidateProfile(candidate.id)}
        candidate={candidate}/>
    ));
  }

  initEmptyList (): HTMLElement {
    return (this.props.candidates && !this.props.candidates.length) ? (
      <div className="empty-placeholder">
        <Trans i18nKey="portal.recruiter.candidates.list.empty">
          Candidates list is empty
        </Trans>
      </div>
    ) : null;
  }

  render () {
    return (
      <div className="candidates-list">
        {
          this.props.currentRecruiterId && this.props.candidates &&
          <InfiniteScroll
            useWindow={false}
            threshold={100}
            pageStart={0}
            initialLoad={true}
            hasMore={this.props.hasMoreItems}
            loadMore={() => this.loadCandidateList(this.props.currentPage)}>
            <ElementsList>{this.initCandidatesList()}</ElementsList>
          </InfiniteScroll>
        }
        {
          !this.props.candidates && !this.props.candidatesFilter &&
          <div className="empty-placeholder">
            <Trans i18nKey="portal.recruiter.candidates.list.empty">
              Candidates list is empty
            </Trans>
          </div>
        }
        {
          this.props.candidatesFilter
            ? this.initEmptyList()
            : (this.props.candidates && this.props.candidates.length < 1) &&
            <EmptyCandidatesSearchList history={this.props.history}/>
        }
        <Spinner show={this.props.fetching}/>
      </div>
    );
  }

}
