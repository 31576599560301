import { combineReducers } from 'redux';

import authReducer from './auth/auth.reducer';
import portalReducer from './portal/portal.reducer';
import landingReducer from './landing/landing.reducer';
import demoMenuVideo from './demoMenuVideo/demoMenuVideo.reducer';

export default combineReducers({
  auth: authReducer,
  portal: portalReducer,
  landing: landingReducer,
  demoMenuVideo: demoMenuVideo
});
