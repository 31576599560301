import React from 'react';

import cn from 'classnames';

import { Setting } from './form-group.setting';

export class FormGroup extends Setting {

  render () {
    return (
      <div className={cn(['form-group', this.props.className])}>
        { this.props.children }
      </div>
    );
  }

}
