import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { Button } from 'components/buttons';

import { SubscriptionDto } from 'rest/companies/subscription/subscription.dto';

import './styles.scss';

type Props = {
  currentUser: {
    firstName: string,
    lastName: string,
    email: string
  },
  companyId: number,
  promoCode: string,
  paymentMethod: string,
  paymentRedirectUrls: {},
  history: any,
  closeModal: () => void,
  closeAccountSettingsModal: () => void,
  prev: () => void,
  subscribeRecruiterCompany: () => void
};

export class IbanForm extends Component<Props> {

  state = {
    userName: '',
    userEmail: '',
    isError: false,
    paymentIsSuccess: false,
    paymentIsFailed: false
  };

  componentDidMount () {
    const { firstName, lastName, email } = this.props.currentUser;

    this.setState({
      userName: `${firstName} ${lastName}`,
      userEmail: email
    });
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { target } = e;
    const {
      companyId, paymentMethod, paymentRedirectUrls, promoCode,
      closeAccountSettingsModal, subscribeRecruiterCompany
    } = this.props;
    const { userName, userEmail } = this.state;

    const paymentSepaDetails = {
      userName,
      userEmail,
      iban: target.iban.value
    };

    const CompanySubscriptionPlan: SubscriptionDto = new SubscriptionDto({
      subscriptionPlan: 'PREMIUM',
      promoCode,
      paymentMethod,
      paymentRedirectUrls,
      paymentSepaDetails
    });

    subscribeRecruiterCompany(companyId, CompanySubscriptionPlan)
      .then(res => {
        this.setState({
          paymentIsSuccess: res.type === 'SUBSCRIBE_COMPANY_SUCCESS',
          paymentIsFailed: res.type === 'SUBSCRIBE_COMPANY_FAILURE'
        });
      })
      .finally(() => {
        closeAccountSettingsModal();
      });
  };

  render () {
    const { userName, userEmail, paymentIsSuccess, paymentIsFailed } = this.state;

    return (
      <>
        {paymentIsSuccess && <Redirect to='/portal/recruiter/account/payment/success'/>}
        {paymentIsFailed && <Redirect to='/portal/recruiter/account/payment/failed'/>}

        <form className="iban-form" onSubmit={this.handleSubmit}>
          <div className="form-row form-group-row">
            <div className="form-col">
              <label className="form-col-label" htmlFor="userName">
                <Trans i18nKey="portal.guest.recruiter.job-offer.payment.payment-method.sepa.name">
                  Name of the debtor(s)
                </Trans>
              </label>
              <input
                className="form-col-input"
                id="userName"
                name="userName"
                type="text"
                value={userName}
                placeholder="Enter your full name"
                required
                onChange={this.handleChange}
              />
            </div>
            <div className="form-col">
              <label className="form-col-label" htmlFor="userEmail">
                <Trans i18nKey="portal.guest.recruiter.job-offer.payment.payment-method.sepa.email">
                  Email address
                </Trans>
              </label>
              <input
                className="form-col-input"
                id="userEmail"
                name="userEmail"
                type="email"
                value={userEmail}
                placeholder="Enter your email"
                required
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="form-row">
            <label className="form-col-label" htmlFor="iban">
              IBAN
            </label>
            <input
              className="form-col-input"
              id="iban"
              name="iban"
              type="text"
              placeholder="DE00 0000 0000 0000 0000 00"
              required
            />
          </div>

          <Button
            large
            className=" accent"
            type="submit">
            <Trans i18nKey="portal.guest.recruiter.job-offer.payment.payment-method.sepa.confirm">
              Submit Payment
            </Trans>
          </Button>
        </form>
      </>
    );
  }

}
