import { Component } from 'react';

import './spinner.scss';

type Props = {
  show: boolean
}

export class Settings extends Component<Props> {

  static defaultProps = {
    show: false
  }

}
