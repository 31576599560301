import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import {
  resetMatchedCandidates,
  changeMatchedCandidatesLocation
} from 'services/portal/recruiter/match/job-offers/candidates/candidates.actions';

import './job-offer-candidates.scss';

type Props = {
  t?: TransProps,
  children: any
};

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  location: state.portal.recruiter.match.jobOfferCandidates.location,
  locations: state.portal.recruiter.match.jobOfferCandidates.locations,
  candidates: state.portal.recruiter.match.jobOfferCandidates.candidates
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetMatchedCandidates: resetMatchedCandidates,
  changeMatchedCandidatesLocation: changeMatchedCandidatesLocation
}, dispatch);
