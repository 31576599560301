function getBrowserLocalization (): string {
  return navigator.language || navigator.userLanguage;
}

function getCurrentLocalization (): string {
  const locale: string = getBrowserLocalization();
  const localesList = [ 'en', 'de', 'fr', 'es' ];
  return localesList.find(localization => locale.includes(localization)) || 'en';
}

export function getLocalization (): string {
  return localStorage.getItem('ej_localization') || getCurrentLocalization();
}

export function setLocalization (localization: string = getCurrentLocalization()) {
  localStorage.setItem('ej_localization', localization);
}
