// @flow
import React from 'react';
import { NavLink } from 'react-router-dom';

import cn from 'classnames';

import { Setting } from './item.setting';

export class SidebarItem extends Setting {

  render () {
    const className = cn({
      [this.props.className]: this.props.className,
      'accent': this.props.recruiter,
      'primary': !this.props.recruiter,
      'sidebar-item': true
    });

    return (
      <NavLink
        to={this.props.path}
        exact
        className={className}
        activeClassName="active">
        {this.props.children}
      </NavLink>
    );
  }

}
