// @flow
import React, { Fragment } from 'react';

import InfiniteScroll from 'react-infinite-scroller';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { Spinner } from 'components/elements';
import { ElementsList } from 'components/elements-list';

import { ApplicationsTemplateParams } from 'rest/candidates/applications-template/applications-template.params';

import { ApplicationRow } from './application-row/application-row';

import type { Props } from './applications-list.setting';
import { Setting, stateToProps, dispatchToProps } from './applications-list.setting';
import { Trans } from 'react-i18next';

@connectStore(stateToProps, dispatchToProps, true)
export class ApplicationsList extends Setting {

  componentWillUnmount () {
    this.props.resetApplications();
  }

  componentDidMount () {
    if (this.props.candidateId) {
      this.fetchApplications();
    }
  }

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.candidateId !== this.props.candidateId) {
      this.fetchApplications(0, nextProps.candidateId);
    }
  }

  fetchApplications (page: number = 0, candidateId?: string = this.props.candidateId) {
    const query: ApplicationsTemplateParams = new ApplicationsTemplateParams();
    query.size = 10;
    query.page = page;
    query.status = this.props.status;
    this.props.getCandidateApplications(candidateId, query);
  }

  openPreviewApplication (templateId: string) {
    this.props.history.push(`/portal/candidate/applications/templates/${templateId}/preview`);
  }

  initEmptyList (): HTMLElement {
    return !this.props.templates.length ? (
      <div className="empty-placeholder">
        <Trans i18nKey="portal.candidate.applications.list.templates.empty">
          Templates list is empty
        </Trans>
      </div>
    ) : null;
  }

  render () {
    return (
      <Fragment>
        {
          <PerfectScrollbar className="overflow-modal-content">
            {
              this.props.candidateId && <InfiniteScroll
                pageStart={-1}
                hasMore={this.props.hasMoreItems}
                loadMore={() => this.fetchApplications(this.props.currentPage)}
                useWindow={false}
                threshold={150}>
                <ElementsList>
                  {
                    this.props.templates.map((item, index: number) => {
                      return (
                        <ApplicationRow
                          onClick={() => this.openPreviewApplication(item.id)}
                          template={item}
                          key={index}/>
                      );
                    })
                  }
                </ElementsList>
                {this.initEmptyList()}
              </InfiniteScroll>
            }
          </PerfectScrollbar>
        }
        <Spinner show={this.props.fetching}/>
      </Fragment>
    );
  }

}
