import { Component } from 'react';

import './range-control.scss';

type Props = {
  min: number,
  max: number,
  value: number,
  disabled?: boolean,
  change: () => void
}

type State = {
  value: number
}

export class Settings extends Component<Props, State> {

  static defaultProps = {
    min: 0,
    max: 2,
    value: 0,
    disabled: false,
    change: () => {}
  };

  state = {
    value: this.props.value
  }

}
