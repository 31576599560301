// @flow
import React from 'react';

import moment from 'moment';
import { Trans } from 'react-i18next';

import { websitePatter } from 'middleware/validator/patterns';
import { connectTranslation } from 'middleware/connect';

import { Card } from 'components/layouts';
import { Button } from 'components/buttons';
import { CheckboxControl, DateControl, FormGroup, InputControl } from 'components/controls';

import { Settings } from './workplace.setting';

@connectTranslation()
export class Workplace extends Settings {

  render () {
    return (
      <Card className="workplace-container mobile-workspace-container">
        <div className="workplace-caption">
          <h4>
            <Trans i18nKey="portal.candidate.cv.experience.workplace">Workplace</Trans> {this.props.number}
          </h4>
          <div className="mobile-delete-btn-hide">
            <Button
              onClick={() => this.props.remove()}
              outlined
              medium
              candidate>
              <Trans i18nKey="portal.candidate.cv.experience.delete">
                Delete Experience
              </Trans>
            </Button>
          </div>
          <div className="mobile-delete-btn-show">
            <Button
              grey
              onClick={() => this.props.remove()}
              className="delete-btn">
              <img
                src="/images/shared/basket.svg"
                alt="delete"/>
            </Button>
          </div>
        </div>
        <div className="workspace-form-content">
          <FormGroup className="full-width job-title-field">
            <InputControl
              label={this.props.t('portal.candidate.cv.experience.job-title.label')}
              maxLength={70}
              value={this.props.experience.jobTitle}
              change={value => this.props.change({ jobTitle: value })}
              placeholder={this.props.t('portal.candidate.cv.experience.job-title.placeholder')}
              pattern=".+"
              required
              type="text"/>
          </FormGroup>
          <FormGroup className="half-width">
            <InputControl
              label={this.props.t('portal.candidate.cv.experience.company.label')}
              maxLength={70}
              value={this.props.experience.companyName}
              change={value => this.props.change({ companyName: value })}
              placeholder={this.props.t('portal.candidate.cv.experience.company.placeholder')}
              pattern=".+"
              required
              type="text"/>
          </FormGroup>
          <FormGroup className="half-width">
            <InputControl
              label={this.props.t('portal.candidate.cv.experience.location.label')}
              maxLength={70}
              value={this.props.experience.location}
              change={value => this.props.change({ location: value })}
              placeholder={this.props.t('portal.candidate.cv.experience.location.placeholder')}
              pattern=".+"
              required
              type="text"/>
          </FormGroup>
          <FormGroup className="half-width">
            <InputControl
              label={this.props.t('portal.candidate.cv.experience.website.label')}
              value={this.props.experience.website}
              change={value => this.props.change({ website: value })}
              placeholder={this.props.t('portal.candidate.cv.experience.website.placeholder')}
              pattern={websitePatter}
              errorText={this.props.t('portal.candidate.cv.experience.website.error')}
              type="text"/>
          </FormGroup>
          <div className="from-date">
            <DateControl
              required
              className="from-date-field"
              monthsCount={1}
              label={this.props.t('portal.candidate.cv.experience.date.from')}
              maxDate={moment().toDate()}
              showYearDropdown
              value={this.props.experience.fromDate}
              change={value => this.props.change({ fromDate: value })}/>
            {
              !this.props.experience.isCurrent && <DateControl
                required
                label={this.props.t('portal.candidate.cv.experience.date.to')}
                showYearDropdown
                maxDate={moment().toDate()}
                minDate={moment(this.props.experience.fromDate).toDate()}
                className="from-date-field"
                value={this.props.experience.toDate}
                change={value => this.props.change({ toDate: value })}
                monthsCount={1}/>
            }
          </div>
          <CheckboxControl
            value={this.props.experience.isCurrent}
            change={value => this.props.change({ isCurrent: value })}
            label={this.props.t('portal.candidate.cv.experience.currently-work')}/>
          <FormGroup className="full-width experience-description">
            <InputControl
              label={this.props.t('portal.candidate.cv.experience.description.label')}
              maxLength={200}
              rows={6}
              notes={`${this.props.experience.description.length} / 200`}
              value={this.props.experience.description}
              change={value => this.props.change({ description: value })}
              placeholder={this.props.t('portal.candidate.cv.experience.description.placeholder')}
              pattern=".+"
              required
              multiline
              type="text"/>
          </FormGroup>
        </div>
      </Card>
    );
  }

}
