// @flow
import moment from 'moment';

import { Setting } from './date.setting';

export class DateTime extends Setting {

  render () {
    return this.props.value ? moment(this.props.value).format(this.props.format) : null;
  }

}
