import React, { Fragment } from 'react';

import { Range } from 'rc-slider';
import { Trans } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { UnitDto } from 'rest/unit/unit.dto';
import { JobTitleDto } from 'rest/job-title/job-title.dto';

import { Button } from 'components/buttons';
import {
  DateControl,
  FormGroup,
  InputControl,
  CheckboxControl,
  MultiSelect,
  AutosuggestSelect
} from 'components/controls';

import { AddressDto } from 'rest/address/address.dto';
import { CandidatesParams } from 'rest/public/candidates/candidates.params';

import { Settings, dispatchToProps, stateToProps, workingTypes } from './default-filter.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class DefaultFilter extends Settings {

  componentDidMount () {
    this.props.getAllBranches();
    this.fetchUnits();
    this.fetchJobTitles();
  }

  fetchUnits (branchId: string = this.props.searchCandidates.branchId) {
    const query: UnitDto = new UnitDto();
    query.size = 300;
    query.branchId = [branchId];
    this.props.getAllUnits(query);
    this.props.saveSearchCandidatesBranch(branchId);
    this.fetchJobTitles('', branchId);
  }

  fetchJobTitles (unitId: string = this.props.searchCandidates.unitId, branchId: string) {
    const query: JobTitleDto = new JobTitleDto();
    query.size = 500;
    query.filterUnitIds = [unitId];
    query.branchIds = [this.props.searchCandidates.branchId || branchId];
    this.props.getAllJobTitles(query);
    this.props.saveSearchCandidatesUnit(unitId);
  }

  applyFilters () {
    const params: CandidatesParams = new CandidatesParams();
    params.unitId = this.props.searchCandidates.unitId;
    params.jobType = this.props.searchCandidates.jobType;
    params.branchId = this.props.searchCandidates.branchId;
    params.location = this.props.searchCandidates.location;
    params.minSalary = this.props.searchCandidates.minSalary;
    params.maxSalary = this.props.searchCandidates.maxSalary;
    params.jobTitleId = this.props.searchCandidates.jobTitleId;
    params.workingTime = this.props.searchCandidates.workingTime;
    params.availableFrom = this.props.searchCandidates.availableFrom;
    params.searchByPlaceId = this.props.searchCandidates.close;
    params.readyToRelocation = this.props.searchCandidates.readyToRelocation;
    params.professionalSkills = this.props.searchCandidates.professionalSkills;
    this.props.initCandidatesFilter(params);
    this.props.history.push('/portal/recruiter/candidates');
  }

  resetFilters () {
    const params: CandidatesParams = new CandidatesParams();
    params.professionalSkills = this.props.searchCandidates.professionalSkills;
    this.props.clearCandidatesSearch();
    this.props.initCandidatesFilter(params);
    this.props.history.push('/portal/recruiter/candidates');
  }

  suggestLocation (value: string) {
    const query: AddressDto = new AddressDto();
    query.line = value;
    this.props.searchSuggestedAddress('line', query);
  }

  render () {
    return (
      <Fragment>
        <h2 className="default-filter-title">
          <Trans i18nKey="portal.recruiter.candidates.filters.default.title">
            Default Filter
          </Trans>
        </h2>
        <PerfectScrollbar className="skills-container default-filter">
          <div className="general-filters">
            <FormGroup className="full-width">
              <MultiSelect
                label={this.props.t('portal.recruiter.candidates.filters.default.branch.label')}
                items={this.props.branches}
                defaultValue={this.props.searchCandidates.branchId}
                change={value => this.fetchUnits(value.id)}
                isMulti={false}
                placeholder={this.props.t('portal.recruiter.candidates.filters.default.branch.placeholder')}/>
            </FormGroup>
            <FormGroup className="full-width">
              <MultiSelect
                label={this.props.t('portal.recruiter.candidates.filters.default.unit.label')}
                items={this.props.units}
                defaultValue={this.props.searchCandidates.unitId}
                change={value => this.fetchJobTitles(value.id)}
                isMulti={false}
                placeholder={this.props.t('portal.recruiter.candidates.filters.default.unit.placeholder')}/>
            </FormGroup>
            <FormGroup className="full-width">
              <MultiSelect
                label={this.props.t('portal.recruiter.candidates.filters.default.job-title.label')}
                items={this.props.jobTitles}
                defaultValue={this.props.searchCandidates.jobTitleId}
                change={value => this.props.saveSearchCandidatesJobTitle(value.id)}
                isMulti={false}
                placeholder={this.props.t('portal.recruiter.candidates.filters.default.job-title.placeholder')}/>
            </FormGroup>
            <FormGroup className="location">
              <AutosuggestSelect
                required
                label={this.props.t('portal.recruiter.candidates.filters.default.location.label')}
                items={this.props.address.line}
                change={(value: string) => this.props.saveSearchCandidatesLocation(value)}
                search={(value: string) => this.suggestLocation(value)}
                bindValue="description"
                defaultValue={this.props.searchCandidates.location}
                placeholder={this.props.t('portal.candidate.job-offers.filters.default.location.placeholder')}/>
              <CheckboxControl
                label={this.props.t('portal.recruiter.candidates.filters.default.close-to-me')}
                value={this.props.searchCandidates.close}
                change={value => this.props.saveSearchCandidatesClose(value)}
                className="accent"/>
            </FormGroup>
            <div className="salary-range">
              <FormGroup className="salary-field-width min-salary">
                <InputControl
                  step={50}
                  minLength={1}
                  notes="from"
                  type="number"
                  label={this.props.t('portal.recruiter.candidates.filters.default.salary')}
                  value={this.props.searchCandidates.minSalary}
                  change={value => this.props.saveSearchCandidatesSalaryMinimum(value)}
                  required
                  stickyNotes/>
              </FormGroup>
              <FormGroup className="salary-field-width max-salary">
                <InputControl
                  step={50}
                  label=" "
                  pattern="\d"
                  maxLength={8}
                  minLength={1}
                  type="number"
                  notes="till"
                  value={this.props.searchCandidates.maxSalary}
                  change={value => this.props.saveSearchCandidatesSalaryMaximum(value)}
                  required
                  stickyNotes/>
              </FormGroup>
            </div>
            <FormGroup className="full-width">
              <Range
                min={1}
                max={10000}
                value={[this.props.searchCandidates.minSalary, this.props.searchCandidates.maxSalary]}
                onChange={value => {
                  this.props.saveSearchCandidatesSalaryMinimum(value[0]);
                  this.props.saveSearchCandidatesSalaryMaximum(value[1]);
                }}
                className="salary-slider accent"
                allowCross={false}/>
            </FormGroup>
          </div>
          <div className="additional-filter">
            <FormGroup className="full-width">
              <InputControl
                type="number"
                label={this.props.t('portal.recruiter.candidates.filters.default.working-time.label')}
                value={this.props.searchCandidates.workingTime}
                change={value => this.props.saveSearchCandidatesWorkingTime(value)}
                placeholder={this.props.t('portal.recruiter.candidates.filters.default.working-time.placeholder')}/>
            </FormGroup>
            <FormGroup className="full-width">
              <DateControl
                monthsCount={1}
                value={this.props.searchCandidates.availableFrom}
                change={value => this.props.saveSearchCandidatesAvailableFrom(value)}
                label={this.props.t('portal.recruiter.candidates.filters.default.available-from')}/>
            </FormGroup>
            <FormGroup className="full-width">
              <MultiSelect
                label={this.props.t('portal.recruiter.candidates.filters.default.working-type.label')}
                items={workingTypes}
                defaultValue={this.props.searchCandidates.jobType}
                change={value => this.props.saveSearchCandidatesWorkingType(value.id)}
                isMulti={false}
                placeholder={this.props.t('portal.recruiter.candidates.filters.default.working-type.placeholder')}/>
            </FormGroup>
            <FormGroup className="full-width">
              <div className="checkbox-label">
                <Trans i18nKey="portal.recruiter.candidates.filters.default.relocation.label">
                  Relocation
                </Trans>
              </div>
              <CheckboxControl
                label={this.props.t('portal.recruiter.candidates.filters.default.relocation.placeholder')}
                value={this.props.searchCandidates.readyToRelocation}
                change={value => this.props.saveSearchCandidatesRelocate(value)}
                className="accent"/>
            </FormGroup>
          </div>
        </PerfectScrollbar>
        <FormGroup className="full-width form-buttons default-filter-buttons">
          <Button
            recruiter
            onClick={() => this.applyFilters()}
            className="apply">
            <Trans i18nKey="portal.recruiter.candidates.filters.buttons.apply">
              Apply Filters
            </Trans>
          </Button>
          <Button
            recruiter
            outlined
            onClick={() => this.resetFilters()}
            className="reset">
            <Trans i18nKey="portal.recruiter.candidates.filters.buttons.reset">
              Clear Filters
            </Trans>
          </Button>
        </FormGroup>
      </Fragment>
    );
  }

}
