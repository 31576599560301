import { Component } from 'react';

import './date.scss';
import './date.responsive.scss';

type Props = {
  date: string,
  index: number,
  previousDate: string
};

export class Settings extends Component<Props> {

  static defaultProps = {
    date: null,
    index: 0,
    previousDate: null
  };

}
