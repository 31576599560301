import { Component } from 'react';

import { TransProps } from 'react-i18next';

import './apply-actions-button.scss';
import './apply-actions-button.responsive.scss';

export type Props = {
  t: TransProps,
  applyAction: () => void,
  interactAction: () => void,
  isUserLoggedIn: boolean,
  guestRedirectLink: string,
  isJobOfferMatched: boolean,
  isJobOfferApplied: boolean,
  isMobileJobOffer?: boolean
};

export class Settings extends Component<Props> {

  static defaultProps: Props = {
    guestRedirectLink: '',
    applyAction: () => {},
    isUserLoggedIn: false,
    interactAction: () => {},
    isJobOfferMatched: false,
    isJobOfferApplied: false,
    isMobileJobOffer: false
  }

}
