import axios, { AxiosPromise } from 'axios';

import { List } from 'rest/list/list.dto';
import { SkillDto } from 'rest/skills/skills.dto';

export async function getSkillsRest (): AxiosPromise<List<SkillDto>> {
  try {
    return await axios.get('/skills', {
      params: {
        size: 1000
      }
    });
  } catch (error) {
    throw error;
  }
}
