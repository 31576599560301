import axios, { AxiosPromise } from 'axios';

import { CompanyDto } from './companies.dto';

export async function updateCompany (companyId: string, data: CompanyDto): AxiosPromise<CompanyDto> {
  try {
    return await axios.put(`/companies/${companyId}`, data);
  } catch (error) {
    throw error;
  }
}

export async function getCompanyById (companyId: string): AxiosPromise<CompanyDto> {
  try {
    return await axios.get(`/companies/${companyId}`);
  } catch (error) {
    throw error;
  }
}

export async function getPublicCompany (companyId: string): AxiosPromise<CompanyDto> {
  try {
    return await axios.get(`/public/companies/${companyId}`);
  } catch (error) {
    throw error;
  }
}
