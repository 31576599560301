export const CANDIDATE_SIGN_IN_REQUEST = 'CANDIDATE_SIGN_IN_REQUEST';
export const CANDIDATE_SIGN_IN_SUCCESS = 'CANDIDATE_SIGN_IN_SUCCESS';
export const CANDIDATE_SIGN_IN_FAILURE = 'CANDIDATE_SIGN_IN_FAILURE';
export const CANDIDATE_SIGN_IN_NEED_CONFIRM = 'CANDIDATE_SIGN_IN_NEED_CONFIRM';

export const SOCIAL_URL_REQUEST = 'SOCIAL_URL_REQUEST';
export const SOCIAL_URL_SUCCESS = 'SOCIAL_URL_SUCCESS';
export const SOCIAL_URL_FAILURE = 'SOCIAL_URL_FAILURE';

export const CANDIDATE_SIGN_IN_CLEAR_STATE = 'CANDIDATE_SIGN_IN_CLEAR_STATE';
