import * as actions from './invite.constants';

import { CalendarInviteDto } from 'rest/companies/candidate-invites/candidate-invites-invite.dto';
import { createCalendarInvite } from 'rest/companies/candidate-invites/candidate-invites.rest';

import { getCandidateById } from 'services/portal/recruiter/candidate/all/candidates.actions';

const createInviteRequest = () => ({
  type: actions.CALENDAR_INVITE_REQUEST
});

const createInviteSuccess = () => ({
  type: actions.CALENDAR_INVITE_SUCCESS
});

const createInviteFailure = (error: string) => ({
  type: actions.CALENDAR_INVITE_FAILURE,
  error
});

export const createInvite = (companyId: number, invite: CalendarInviteDto, candidateId: string) => {
  return dispatch => {
    dispatch(createInviteRequest());
    return createCalendarInvite(companyId, invite)
      .then(response => {
        dispatch(createInviteSuccess(response.data));
        dispatch(getCandidateById(candidateId));
      })
      .catch(error => dispatch(createInviteFailure(error)));
  };
};
