export class AskQuestionRequestDto {

  name: string;
  files: File[] = [];
  email: string;
  question: string;

  constructor (data?: AskQuestionRequestDto) {
    if (data) {
      this.name = data.name;
      this.files = data.files;
      this.email = data.email;
      this.question = data.question;
    }
  }

}
