export const SEARCH_BRAND_SKILLS = 'SEARCH_BRAND_SKILLS';
export const SEARCH_BRAND_BRANCH = 'SEARCH_BRAND_BRANCH';
export const SEARCH_BRAND_UNIT = 'SEARCH_BRAND_UNIT';
export const SEARCH_BRAND_JOB_TITLE = 'SEARCH_BRAND_JOB_TITLE';
export const SEARCH_BRAND_LOCATION = 'SEARCH_BRAND_LOCATION';
export const SEARCH_BRAND_COMPANY_NAME = 'SEARCH_BRAND_COMPANY_NAME';
export const SEARCH_BRAND_SALARY_MIN = 'SEARCH_BRAND_SALARY_MIN';
export const SEARCH_BRAND_SALARY_MAX = 'SEARCH_BRAND_SALARY_MAX';
export const SEARCH_BRAND_WORKING_TIME = 'SEARCH_BRAND_WORKING_TIME';
export const SEARCH_BRAND_PERIOD_OF_PUBLISH = 'SEARCH_BRAND_PERIOD_OF_PUBLISH';
export const SEARCH_BRAND_AVAILABLE_FROM = 'SEARCH_BRAND_AVAILABLE_FROM';
export const SEARCH_BRAND_JOB_TYPE = 'SEARCH_BRAND_JOB_TYPE';
export const SEARCH_BRAND_CLOSE_TO_ME = 'SEARCH_BRAND_CLOSE_TO_ME';
export const SEARCH_BRAND_RESET = 'SEARCH_BRAND_RESET';
export const SEARCH_BRAND_SKILLS_RESET = 'SEARCH_BRAND_SKILLS_RESET';
export const SEARCH_BRAND_ID = 'SEARCH_BRAND_ID';
