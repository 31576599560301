export class NotificationSettingDto {

  newMessageChat: boolean;
  systemUpdates: boolean;
  updateFollowing: boolean;

  constructor (data?: NotificationSettingDto) {
    if (data) {
      this.systemUpdates = data.systemUpdates;
      this.newMessageChat = data.newMessageChat;
      this.updateFollowing = data.updateFollowing;
    }
  }

}
