import { Component } from 'react';

import './modal.scss';

type Props = {
  closePath: string,
  modalClassName: string,
  wrapperClassName: string,
  closeAction?: () => void
}

type State = {
  closeProcess: boolean
}

export class Setting extends Component<Props, State> {

  static defaultProps = {
    closePath: '',
    modalClassName: '',
    wrapperClassName: '',
    closeAction: () => {}
  };

  state = {
    closeProcess: false
  };

}
