import axios, { AxiosPromise } from 'axios';

import { PurchasedStatsDto } from './stats.dto';

export async function getPurchasedCandidatesStats (companyId: string): AxiosPromise<PurchasedStatsDto> {
  try {
    return await axios.get(`/companies/${companyId}/purchased-candidates/stats`);
  } catch (error) {
    throw error;
  }
}
