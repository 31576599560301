import React from 'react';
import type { RouterHistory } from 'react-router-dom';

import type { Location } from 'common/types/location';

import { FailedPayment } from 'containers/portal/recruiter/account/settings';

type Props = {
  location: Location,
  history: RouterHistory
};

export const TimeoutPaymentPage = (props: Props) => {
  const { history, location } = props;

  return (
    <div className="payment-page-container">
      <FailedPayment
        status="timeout"
        history={history}
        location={location}
      />
    </div>
  );
};
