// @flow
import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';

import InfiniteScroll from 'react-infinite-scroller';

import { connectStore } from 'middleware/connect';

import { ListPlaceholder, Spinner } from 'components/elements';
import { ElementsList, CandidateRow } from 'components/elements-list';

import { CandidateDto } from 'rest/candidates/candidate.dto';
import { RecruiterFollowParams } from 'rest/recruiter/follow/follow.params';

import { stateToProps, dispatchToProps, Setting } from './followers.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class FollowersCandidate extends Setting {

  componentWillUnmount () {
    this.props.resetAllCandidateFollowers();
  }

  openCandidateProfile (selectedCandidate: string) {
    this.props.history.push(`/portal/recruiter/follow/followers/${selectedCandidate}/profile`);
  }

  loadCandidateList (page: number = 0) {
    const params = new RecruiterFollowParams();
    params.page = page;
    params.unitId = this.props.unitId;
    this.props.requestFollowersCandidates(this.props.currentCompanyId, params);
  }

  render () {
    return (
      <Fragment>
        {this.props.currentCompanyId && (
          <InfiniteScroll
            useWindow={false}
            threshold={100}
            pageStart={-1}
            hasMore={this.props.hasMoreItems}
            loadMore={() => this.loadCandidateList(this.props.currentPage)}>
            {this.props.candidates.length ? (
              <ElementsList>
                {this.props.candidates.map((candidate: CandidateDto) => (
                  <CandidateRow
                    onClick={() => this.openCandidateProfile(candidate.id)}
                    key={candidate.id}
                    candidate={candidate}>
                  </CandidateRow>
                ))}
              </ElementsList>) : <ListPlaceholder
              title={this.props.t('portal.recruiter.follows.list.followers.placeholder.title')}>
              <Trans i18nKey="portal.recruiter.follows.list.followers.placeholder.content">
                When a candidate starts following you, we`ll list their profile here.
              </Trans>
            </ListPlaceholder>}
          </InfiniteScroll>)}
        <Spinner show={this.props.fetching}/>
      </Fragment>
    );
  }

}
