// @flow
import * as actions from './favorite.constants';

import { Action, Dispatch } from 'redux';

import { List } from 'rest/list/list.dto';

import { FavoriteParams } from 'rest/candidates/current/favorite/favorite.params';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';
import { LocationsDto } from 'rest/companies/job-offers-matches/matched-candidates/locations/locations.dto';
import { getFavoriteStatistic } from 'services/portal/candidate/jobs/favorite/stats/stats.actions';

import {
  getFavoriteJobsRest,
  addJobToFavoriteRest,
  deleteJobFromFavoriteRest,
  getLocationsFavoritesCompanies
} from 'rest/candidates/current/favorite/favorite.rest';

const getFavoriteJobsRequest = (): Action => ({
  type: actions.GET_FAVORITE_JOBS_REQUEST
});

const getFavoriteJobsSuccess = (data: List<JobOffersResponseDto>): Action => ({
  type: actions.GET_FAVORITE_JOBS_SUCCESS,
  payload: data
});

const getFavoriteJobsFailure = (): Action => ({
  type: actions.GET_FAVORITE_JOBS_FAILURE
});

const getFavoriteJobsLocationsRequest = (): Action => ({
  type: actions.GET_FAVORITE_JOB_OFFERS_LOCATIONS_REQUEST
});

const getFavoriteJobsLocationsSuccess = (locations: LocationsDto[] = []): Action => ({
  type: actions.GET_FAVORITE_JOB_OFFERS_LOCATIONS_SUCCESS,
  locations
});

const getFavoriteJobsLocationsFailure = (): Action => ({
  type: actions.GET_FAVORITE_JOB_OFFERS_LOCATIONS_FAILURE
});

const addJobToFavoriteRequest = (): Action => ({
  type: actions.ADD_JOBS_TO_FAVORITE_REQUEST
});

const addJobToFavoriteSuccess = (): Action => ({
  type: actions.ADD_JOBS_TO_FAVORITE_SUCCESS
});

const addJobToFavoriteFailure = (): Action => ({
  type: actions.ADD_JOBS_TO_FAVORITE_FAILURE
});

const deleteJobFromFavoriteRequest = (): Action => ({
  type: actions.DELETE_JOBS_FROM_FAVORITE_REQUEST
});

const deleteJobFromFavoriteSuccess = (jobOfferId: string): Action => ({
  type: actions.DELETE_JOBS_FROM_FAVORITE_SUCCESS,
  payload: jobOfferId
});

const deleteJobFromFavoriteFailure = (): Action => ({
  type: actions.DELETE_JOBS_FROM_FAVORITE_FAILURE
});

const changeFavoriteFilterSuccess = (params: FavoriteParams): Action => ({
  type: actions.CHANGE_FAVORITES_FILTERS_SUCCESS,
  params
});

const resetFavoriteJobsSuccess = (): Action => ({
  type: actions.RESET_FAVORITES_SUCCESS
});

export function resetFavoriteJobs () {
  return (dispatch: Dispatch) => dispatch(resetFavoriteJobsSuccess());
}

export function changeFavoriteFilter (params: FavoriteParams) {
  return (dispatch: Dispatch) => dispatch(changeFavoriteFilterSuccess(params));
}

export function getFavoriteJobs (params: FavoriteParams = new FavoriteParams()) {
  return (dispatch: Dispatch) => {
    dispatch(getFavoriteJobsRequest());
    return getFavoriteJobsRest(params)
      .then(response => dispatch(getFavoriteJobsSuccess(response.data)))
      .catch(() => dispatch(getFavoriteJobsFailure()));
  };
}

export function getFavoriteJobsLocations () {
  return (dispatch: Dispatch) => {
    dispatch(getFavoriteJobsLocationsRequest());
    return getLocationsFavoritesCompanies()
      .then(response => dispatch(getFavoriteJobsLocationsSuccess(response.data)))
      .catch(() => dispatch(getFavoriteJobsLocationsFailure()));
  };
}

export function addJobToFavorite (jobOfferId: string, candidateId: string) {
  return (dispatch: Dispatch) => {
    dispatch(addJobToFavoriteRequest());
    return addJobToFavoriteRest(jobOfferId)
      .then(() => {
        dispatch(addJobToFavoriteSuccess());
        dispatch(getFavoriteStatistic(candidateId));
      })
      .catch(() => dispatch(addJobToFavoriteFailure()));
  };
}

export function deleteJobFromFavorite (jobOfferId: string, candidateId: string) {
  return (dispatch: Dispatch) => {
    dispatch(deleteJobFromFavoriteRequest());
    return deleteJobFromFavoriteRest(jobOfferId)
      .then(() => {
        dispatch(deleteJobFromFavoriteSuccess(jobOfferId));
        dispatch(getFavoriteStatistic(candidateId));
      })
      .catch(() => dispatch(deleteJobFromFavoriteFailure()));
  };
}
