import React from 'react';

import { Trans } from 'react-i18next';

import { LinkButton } from 'components/buttons';

import { Settings } from './placeholder.setting';

export class PlaceholderList extends Settings {

  render () {
    return (
      <div className="list-placeholder mobile-empty-jobs-list">
        <div className="title">
          <Trans i18nKey="portal.guest.recruiter.candidates.placeholder.title">
            Job offer not found
          </Trans>
        </div>
        <div className="description">
          <Trans i18nKey="portal.guest.recruiter.candidates.placeholder.description">
            We didn`t find a job offer for your search. Try running new search.
          </Trans>
        </div>
        <div className="actions">
          <LinkButton
            link="/"
            outlined
            default>
            <Trans i18nKey="portal.guest.recruiter.candidates.placeholder.action">
              Go back to search
            </Trans>
          </LinkButton>
        </div>
      </div>
    );
  }

}
