// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';

import type { Dispatch } from 'redux';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { RecruiterResponseDto } from 'rest/recruiter/recruiter.response.dto';
import { removeCandidatePreviewLink } from 'services/portal/recruiter/account/current/current.actions';

import './details.scss';
import './details.responsive.scss';

export type Props = {
  t?: TransProps,
  currentUser: RecruiterResponseDto,
  updateRecruiterInfoSuccess: boolean,
  updateCompanyInfoSuccess: boolean,
  inviteTeamMembersSuccess: boolean,
  removeCandidatePreviewLink: () => void
};

export type State = {
  activeTab: number,
  redirectLink: string
}

export class Settings extends Component<Props, State> {

  static defaultProps = {
    removeCandidatePreviewLink: () => {},
    currentUser: new RecruiterResponseDto()
  };

  state: State = {
    activeTab: 0,
    redirectLink: ''
  };

}

export const stateToProps = (state: GlobalState) => ({
  currentUser: state.portal.recruiter.account.current.currentUser,
  updateRecruiterInfoSuccess: state.portal.recruiter.account.current.updateRecruiterInfoSuccess,
  updateCompanyInfoSuccess: state.portal.recruiter.account.companies.updateCompanyInfoSuccess,
  inviteTeamMembersSuccess: state.portal.recruiter.account.teamMembers.inviteTeamMembersSuccess
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  removeCandidatePreviewLink
}, dispatch);
