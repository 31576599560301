import * as actions from './job-title.constants';

import type { JobTitleState } from './job-title.type';

import { JobTitleResponseDto } from 'rest/candidates/cv/job-title.response.dto';

const initialState: JobTitleState = {
  content: '',
  cvData: new JobTitleResponseDto()
};

export default function (state: JobTitleState = initialState, action): JobTitleState {
  switch (action.type) {
    case actions.UPDATE_JOB_TITLE_SUCCESS:
    case actions.UPDATE_JOB_TITLE_REQUEST:
      return {
        ...state,
        content: action.data.jobTitle
      };

    case actions.UPDATE_JOB_TITLE_FAILURE:
      return {
        ...state,
        content: ''
      };

    case actions.GET_JOB_TITLE_SUCCESS:
      return {
        ...state,
        cvData: new JobTitleResponseDto(action.payload),
        content: action.payload.jobTitle
      };

    default:
      return state;
  }
}
