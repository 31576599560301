import { Action } from 'redux';

import { LocationsDto } from 'rest/companies/job-offers-matches/matched-candidates/locations/locations.dto';
import { MatchedCandidatesDto } from 'rest/companies/job-offers-matches/matched-candidates/matched-candidates.dto';

import * as actions from './candidates.constants';

import type { MatchedCandidatesType } from './candidates.type';

const initialState: MatchedCandidatesType = {
  location: new LocationsDto(),
  fetching: false,
  locations: [],
  candidates: [],
  currentPage: 0,
  hasMoreItems: true
};

export default function (state: MatchedCandidatesType = initialState, action: Action): MatchedCandidatesType {
  switch (action.type) {
    case actions.GET_ALL_MATCHED_CANDIDATES_REQUEST:
      return {
        ...state,
        fetching: true,
        hasMoreItems: false
      };

    case actions.GET_ALL_MATCHED_CANDIDATES_SUCCESS:
      return {
        ...state,
        fetching: false,
        candidates: [
          ...state.candidates,
          ...action.payload.content.map(candidate => new MatchedCandidatesDto(candidate))
        ],
        currentPage: action.payload.number + 1,
        hasMoreItems: action.payload.content.length > 0
      };

    case actions.GET_ALL_MATCHED_CANDIDATES_FAILURE:
      return {
        ...state,
        fetching: false,
        hasMoreItems: false
      };

    case actions.GET_MATCHED_CANDIDATES_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.locations.map(location => new LocationsDto(location))
      };

    case actions.CHANGE_MATCHED_CANDIDATES_LOCATIONS_SUCCESS:
      return {
        ...state,
        location: new LocationsDto(action.location),
        candidates: [],
        currentPage: 0,
        hasMoreItems: true
      };

    case actions.GET_ALL_MATCHED_CANDIDATES_RESET:
      return {
        ...state,
        location: new LocationsDto(),
        fetching: false,
        locations: [],
        candidates: [],
        currentPage: 0,
        hasMoreItems: true
      };

    case actions.DELETE_MATCHED_CANDIDATES_SUCCESS:
      return {
        ...state,
        candidates: state.candidates.filter(candidate => candidate.id !== action.id)
      };

    default:
      return state;
  }
}
