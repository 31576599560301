import axios, { AxiosPromise } from 'axios';

import { RequirementsStatsDto } from './requirements-stats.dto';
import { RequirementsStatsParams } from './requirements-stats.params';

export async function getRequirementsStats (recruiterId: string, params: RequirementsStatsParams)
  : AxiosPromise<RequirementsStatsDto> {
  try {
    return await axios.get(`/recruiters/${recruiterId}/requirements-stats`, { params });
  } catch (error) {
    throw error;
  }
}
