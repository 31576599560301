// @flow
import React, { Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';

import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';

import { connectStore } from 'middleware/connect';

import { Spinner } from 'components/elements';
import { SocialButtons, Button } from 'components/buttons';
import { FormGroup, InputControl, CheckboxControl } from 'components/controls';

import { CandidateDto } from 'rest/candidates/candidate.dto';

import type { Props } from './sign-in-form.setting';
import { Setting, dispatchToProps, stateToProps } from './sign-in-form.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class CandidateSignInForm extends Setting {

  componentDidMount () {
    this.props.getSocialUrl('google');
    this.props.getSocialUrl('facebook');
  }

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.redirect !== this.state.redirect) {
      this.setState({ redirect: nextProps.redirect });
    }
  }

  componentDidUpdate (prevProps: Props) {
    if (!prevProps.error && this.props.error) {
      toast.error(this.props.t('auth.candidate.notifications.incorrect'));
    }
  }

  componentWillUnmount () {
    this.props.clearSignInState();
  }

  saveField (field: string, value: string, isError: boolean = false) {
    this.setState({ [field]: value });
    this.errorCollector(field, isError);
  }

  errorCollector (errorName: string, collect: boolean) {
    const errorCollection: Set<string> = new Set(this.state.errorCollection);
    collect ? errorCollection.add(errorName) : errorCollection.delete(errorName);
    this.setState({ errorCollection });
  }

  candidateSignIn (event: Event) {
    event.preventDefault();
    if (!this.state.errorCollection.size) {
      const candidateData: CandidateDto = new CandidateDto(this.state);
      this.props.candidateSignIn(candidateData);
    }
  }

  render () {
    const { t } = this.props;
    const emailError = this.state.errorCollection.has('email');
    return (
      <Fragment>
        {
          this.props.currentCandidate && this.props.currentCandidate.fullyRegistered &&
          <Redirect to="/portal/candidate/balance-board"/>
        }
        {this.state.redirect && !this.props.fetching && <Redirect to={this.state.unauthorizedUserUrl}/>}
        {this.props.needConfirm && <Redirect to="/auth/candidate/sign-up/confirm"/>}
        <div className="sign-in-container">
          <form onSubmit={this.candidateSignIn.bind(this)}>
            <FormGroup>
              <SocialButtons
                google={this.props.googleLink}
                facebook={this.props.facebookLink}/>
            </FormGroup>
            <FormGroup>
              <InputControl
                value={this.state.email}
                error={emailError}
                change={value => this.saveField('email', value)}
                label={t('auth.sign-in.candidate.email.label')}
                title={t('auth.sign-in.candidate.email.title')}
                placeholder={t('auth.sign-in.candidate.email.placeholder')}
                type="email"/>
            </FormGroup>
            <FormGroup>
              <InputControl
                required
                validate={false}
                value={this.state.password}
                change={value => this.saveField('password', value)}
                label={t('auth.sign-in.candidate.password.label')}
                title={t('auth.sign-in.candidate.password.title')}
                placeholder={t('auth.sign-in.candidate.password.placeholder')}
                type="password"/>
            </FormGroup>
            <FormGroup>
              <div className="forgot-link-holder">
                <CheckboxControl
                  value={this.state.rememberMe}
                  change={value => this.saveField('rememberMe', value)}
                  label={t('auth.sign-in.candidate.remember')}/>
                <Link
                  to="/auth/candidate/reset"
                  className="forgot-link">
                  <Trans i18nKey="auth.sign-in.candidate.forgotPassword">
                    Forgot your password?
                  </Trans>
                </Link>
              </div>
            </FormGroup>
            <FormGroup>
              <Button
                candidate
                type="submit"
                fullWidth>
                <Trans i18nKey="auth.sign-in.candidate.button">
                  Sign In
                </Trans>
              </Button>
            </FormGroup>
          </form>
        </div>
        <Spinner show={this.props.fetching}/>
      </Fragment>
    );
  }

}
