/* eslint padded-blocks: ["error", "never"] */
import React, { Component } from 'react';
import '../styles/semantic.min.css';
import '../styles/styles.css';
import axios from 'axios';
import { getUserId } from 'middleware/auth';
export class GDPRCandidateSkills extends Component {
  constructor() {
    super();
    this.state = {
      data: {},
      candidate: {},
      proffessionalSkills: [],
      languageSkills: [],
      softwareSkills: [],
      documents: [],
      qualifications: [],
      softSkills: [],
      emptySkills: false,
      address: {},
      photos: []

    };
  }
  componentDidMount() {
    axios('/candidates/' + getUserId() + '/skills')
      .then(response => {
        this.setState({
          data: response.data,
          emptySkills: response.data.emptySkills,
          proffessionalSkills: response.data.professionalSkills,
          languageSkills: response.data.languageSkills,
          softwareSkills: response.data.softSkills,
          documents: response.data.documents,
          qualifications: response.data.qualifications,
          softSkills: response.data.softSkills
        });
      });
    axios('/candidates/current')
      .then(response => {
        this.setState({
          candidate: response.data,
          address: response.data.candidateAddress,
          photos: response.data.candidatePhotos
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  render() {
    return (
      <div className="gdpr-main">
        <div className="ui container main-content">
          <h1 className="ui header main-title"> Candidate skills </h1>
          <a href="/information/gdpr">
            <button className="ui button back-link">Back To Main</button>
          </a>
          <h4 className="ui header green"> Documents </h4>
          {this.state.documents.map(document => (
            <button className="ui basic button skill-item" key={document.id}>{document.name}</button>
          ))}
          <h4 className="ui header green">Professional Skills</h4>
          {this.state.proffessionalSkills.map(proffessionalSkill => (
            <button className="ui basic button skill-item" key={proffessionalSkill.id}>
              {proffessionalSkill.name}</button>
          ))}
          <h4 className="ui header green">Languages</h4>
          {this.state.languageSkills.map(languageSkill => (
            <button className="ui basic button skill-item" key={languageSkill.id}>
              {languageSkill.name}</button>
          ))}
          <h4 className="ui header green">Software</h4>
          {this.state.softwareSkills.map(softwareSkill => (
            <button className="ui basic button skill-item" key={softwareSkill.id}>{softwareSkill.name}</button>
          ))}
          <h4 className="ui header green">Soft Skills</h4>
          {this.state.softSkills.map(softSkill => (
            <button className="ui basic button skill-item" key={softSkill.id}>{softSkill.name}</button>
          ))}
        </div>
        <div className="ui container main-content">
          <h1 className="ui header main-title"> Profile Info </h1>
          <a href="/information/gdpr">
            <button className="ui button back-link">Back To Main</button>
          </a>
          <table className="ui striped green table">
            <thead>
              <tr><th colSpan={3}>
                Profile Info   </th>
              </tr></thead>
            <tbody>
              <tr>
                <td className="collapsing">
                  First Name   </td>
                <td>{this.state.candidate.firstName}</td>
              </tr>
              <tr>
                <td>
                  Last Name     </td>
                <td>{this.state.candidate.lastName}</td>
              </tr>
              <tr>
                <td>
                  Email     </td>
                <td>{this.state.candidate.email}</td>
              </tr>
              <tr>
                <td>
                  Is Active Profile     </td>
                <td>{this.state.candidate.profileActivity}</td>
              </tr>
              <tr>
                <td className="collapsing">
                  Birthday     </td>
                <td>{this.state.candidate.birthday}
                </td>
              </tr>
              <tr>
                <td className="collapsing">
                  Phone Number     </td>
                <td>{this.state.candidate.phoneNumber}</td>
              </tr>
              <tr>
                <td className="collapsing">
                  Candidate Photos     </td>
                <td>
                  {this.state.photos.map(photo => (
                    <a key={photo.id}>{photo.url}</a>
                  ))}
                </td>
              </tr>
              <tr>
                <td>
                  Updated Date     </td>
                <td>{this.state.candidate.updatedDate}
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  Available From     </td>
                <td>
                </td>
              </tr>
              <tr>
                <td>
                  Relocation     </td>
                <td>{this.state.candidate.willingToRelocate}</td>
              </tr>
              <tr>
                <td>
                  Selected Locale     </td>
                <td>{this.state.candidate.siteLocale}</td>
              </tr>
              <tr>
                <td>
                  Candidate Address     </td>
                <td>
                  {this.state.address.streetNumber} {this.state.address.street},
                  {this.state.address.city}, {this.state.address.country} {this.state.address.zipCode}
                </td>
              </tr>
              <tr>
                <td>
                  Candidate Skill Link     </td>
                <td><a href="#"> Skills </a></td>
              </tr>
              <tr>
                <td>
                  Candidate Job Requirement     </td>
                <td><a href="#"> Job Requirement </a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
