import React, { Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import PerfectScrollbar from 'react-perfect-scrollbar';

import cn from 'classnames';
import moment from 'moment';
import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';

import { Card, Page } from 'components/layouts';
import { Spinner } from 'components/elements';
import { Button } from 'components/buttons';

import { Modal } from 'containers/portal/modal-container';
import { NotificationList } from 'containers/portal/notifications';

import { AlertsDto } from 'rest/alerts/alerts.dto';

import { notificationsType } from 'services/portal/notifications/notifications.constants';

import { stateToProps, dispatchToProps, Settings } from './modal.setting';

@connectStore(stateToProps, dispatchToProps)
export class NotificationModal extends Settings {

  closeNotificationsModal () {
    this.props.hideModal();
    this.props.resetNotificationsList();
  }

  fetchNotifications (page: number = 0) {
    const alertsPagination: AlertsDto = new AlertsDto();
    alertsPagination.alertNotificationFilter = notificationsType.ALL;
    alertsPagination.size = 20;
    alertsPagination.page = page;
    this.props.getNotifications(alertsPagination);
  }

  render () {
    return (
      <Modal
        open={this.props.open}
        animation="slide"
        closeOnOverlay={() => this.closeNotificationsModal()}
        className="notifications-modal"
        overlay>
        {
          this.props.open ? <div className="notifications">
            <Page>
              <Card>
                <PerfectScrollbar className="notifications-content">
                  <Button
                    onClick={() => this.closeNotificationsModal()}
                    className="inline modal-btn">
                    <div className="close-notifications">&times;</div>
                    <Trans i18nKey="portal.notifications.back">
                      Back to Application
                    </Trans>
                  </Button>
                  <h2 className="caption">
                    <Trans i18nKey="portal.notifications.title">
                      My Notifications
                    </Trans>
                  </h2>
                  {
                    <Fragment>
                      <span className="label">
                        <Trans i18nKey="portal.notifications.status">
                          Latest
                        </Trans>
                      </span>
                      <InfiniteScroll
                        pageStart={-1}
                        useWindow={false}
                        threshold={150}
                        loader={<Spinner show={this.props.fetching} key={0}/>}
                        loadMore={(page: number) => this.fetchNotifications(page)}
                        hasMore={this.props.hasMoreItems}>
                        {
                          this.props.notifications.map((notification, index) => {
                            if (!notification.read) {
                              this.props.markNotificationAsRead(notification.alertId);
                            }
                            const notificationItemClasses = cn([
                              'notification-item',
                              { active: !notification.read }
                            ]);
                            return (
                              <li
                                key={index}
                                className={notificationItemClasses}>
                                <NotificationList
                                  closeModal={() => this.closeNotificationsModal()}
                                  avatarUrl="/images/account-setting/avatar_default.jpg"
                                  notification={notification}
                                  publishDate={moment(notification.date).parseZone().fromNow()}/>
                              </li>
                            );
                          })
                        }
                        {
                          !this.props.notifications.length > 0 && <p className="empty-list">
                            <Trans i18nKey="portal.notifications.empty">
                              No notifications
                            </Trans>
                          </p>
                        }
                      </InfiniteScroll>
                    </Fragment>
                  }
                </PerfectScrollbar>
              </Card>
            </Page>
          </div> : []
        }
      </Modal>
    );
  }

}
