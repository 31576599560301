import * as actions from './stats.constants';

import { StatsDto } from 'rest/stats/stats.dto';
import { getCalendarInvitesStats } from 'rest/companies/candidate-invites/candidate-invites.rest';

const getCalendarStatisticRequest = () => ({
  type: actions.GET_CALENDAR_INVITES_STATS_REQUEST
});

const getCalendarStatisticSuccess = (stats: StatsDto[] = [], total: number = 0) => ({
  type: actions.GET_CALENDAR_INVITES_STATS_SUCCESS,
  stats: stats,
  total: total
});

const getCalendarStatisticFailure = (error: string) => ({
  type: actions.GET_CALENDAR_INVITES_STATS_FAILURE,
  error
});

const resetCalendarStatisticSuccess = data => ({
  type: actions.RESET_CALENDAR_INVITES_STATS_SUCCESS,
  payload: data
});

export const resetCalendarInvitesStats = () => {
  return dispatch => {
    dispatch(resetCalendarStatisticSuccess());
  };
};

export const getCalendarStatistic = (companyId: string) => {
  return dispatch => {
    dispatch(getCalendarStatisticRequest());
    return getCalendarInvitesStats(companyId)
      .then(response => dispatch(getCalendarStatisticSuccess(response.data.inviteStatCountDtos, response.data.total)))
      .catch(error => dispatch(getCalendarStatisticFailure(error)));
  };
};
