export const GET_COMPANY_JOB_OFFERS_REQUEST = 'GET_COMPANY_JOB_OFFERS_REQUEST';
export const GET_COMPANY_JOB_OFFERS_SUCCESS = 'GET_COMPANY_JOB_OFFERS_SUCCESS';
export const GET_COMPANY_JOB_OFFERS_FAILURE = 'GET_COMPANY_JOB_OFFERS_FAILURE';

export const CHANGE_JOB_OFFER_STATUS_SUCCESS = 'CHANGE_JOB_OFFER_STATUS_SUCCESS';

export const DELETE_JOB_OFFER_REQUEST = 'DELETE_JOB_OFFER_REQUEST';
export const DELETE_JOB_OFFER_SUCCESS = 'DELETE_JOB_OFFER_SUCCESS';
export const DELETE_JOB_OFFER_FAILURE = 'DELETE_JOB_OFFER_FAILURE';

export const RESET_JOB_OFFERS_SUCCESS = 'RESET_JOB_OFFERS_SUCCESS';
