// @flow
import axios, { AxiosPromise } from 'axios';

import { JobOfferMatchedDto } from './match.dto';
import { SkillsDto } from 'rest/skill/skills.dto';

export async function checkJobOfferMatch (jobOfferId: string, data: SkillsDto): AxiosPromise<JobOfferMatchedDto> {
  try {
    return await axios.post(`/job-offers/${jobOfferId}/match`, data);
  } catch (error) {
    throw error;
  }
}
