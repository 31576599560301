import React, { Fragment } from 'react';

import { Trans } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { Button } from 'components/buttons';
import { CheckboxControl, Dropdown, FormGroup, InputControl } from 'components/controls';

import { SearchSkillDto } from 'rest/skill/search-skill.dto';
import { JobOffersParams } from 'rest/public/job-offers/job-offers.params';

import { dispatchToProps, Settings, stateToProps } from './skills-filter.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class SkillsFilter extends Settings {

  componentDidMount () {
    this.props.getAllUnits();
    this.props.getAllBranches();
    this.fetchSkillsList();
  }

  applyFilters () {
    const params = new JobOffersParams();
    params.unitId = this.props.searchJobOffers.unitId;
    params.jobType = this.props.searchJobOffers.jobType;
    params.company = this.props.searchJobOffers.companyName;
    params.branchId = this.props.searchJobOffers.branchId;
    params.location = this.props.searchJobOffers.location;
    params.minSalary = this.props.searchJobOffers.minSalary;
    params.maxSalary = this.props.searchJobOffers.maxSalary;
    params.jobTitleId = this.props.searchJobOffers.jobTitleId;
    params.workingTime = this.props.searchJobOffers.workingTime;
    params.availableFrom = this.props.searchJobOffers.availableFrom;
    params.publishedFrom = this.props.searchJobOffers.publishedFrom;
    params.professionalSkills = this.props.searchJobOffers.professionalSkills;
    this.props.initJobsFilter(params);
    this.props.history.push('/portal/candidate/jobs');
  }

  resetFilters () {
    const params = new JobOffersParams(this.props.searchJobOffers);
    params.professionalSkills = [];
    this.props.resetSearchResult();
    this.props.clearJobsSearchSkills();
    this.props.initJobsFilter(params);
    this.props.history.push('/portal/candidate/jobs');
  }

  changeFilterSkills (title, value) {
    this.props.resetSearchResult();
    this.setState({ [title]: value ? value.id : null }, () => this.fetchSkillsList());
  }

  searchSkills (query: string) {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      query: query,
      typing: false,
      typingTimeout: setTimeout(() => {
        this.props.resetSearchResult();
      }, 500)
    });
  }

  toggleSkill (skill, checked: boolean) {
    const skills = this.props.searchJobOffers.professionalSkills;
    if (checked) {
      skills.splice(skills.indexOf(skill.id), 0, skill.id);
    } else {
      skills.splice(skills.indexOf(skill.id), 1);
    }
    this.props.saveJobsSkillsFilter(skills);
  }

  fetchSkillsList () {
    const params = new SearchSkillDto();
    params.q = this.state.query;
    params.unitIds = this.state.unitId && [ this.state.unitId ];
    params.branchIds = this.state.branchId && [ this.state.branchId ];
    params.page = this.props.currentPage;
    this.props.searchSkills(params);
  }

  mergeSelectedSkillsWithFetched (skills = [], savedSkills = []) {
    return skills.map(skill => {
      skill.selected = savedSkills.indexOf(skill.id) >= 0;
      return skill;
    });
  }

  initNotFoundSkills () {
    return !this.props.skills.length ? <p className="empty">
      <Trans i18nKey="portal.candidate.job-offers.filters.skills.empty">
        No Search Items
      </Trans>
    </p> : null;
  }

  initSkillsList (skills, savedSkills) {
    return this.mergeSelectedSkillsWithFetched(skills, savedSkills).map((skill, index) => (
      <li
        key={index}
        className="skill-item">
        <CheckboxControl
          label={skill.name}
          value={skill.selected}
          change={(checked: boolean) => { skill.selected = checked; this.toggleSkill(skill, checked); }}
          className="primary"/>
      </li>
    ));
  }

  render () {
    return (
      <Fragment>
        <form className="skills-filter mobile-filter">
          <h2 className="title">
            <Trans i18nKey="portal.candidate.job-offers.filters.skills.title">
              Skills filter
            </Trans>
          </h2>
          <div className="search-skills">
            <FormGroup className="full-width search-container">
              <InputControl
                type="text"
                value={this.state.query}
                change={(value: string) => this.searchSkills(value)}
                maxLength={1000}
                placeholder={this.props.t('portal.candidate.job-offers.filters.skills.search')}
                className="input-control-search"/>
            </FormGroup>
            <FormGroup className="full-width filter-buttons">
              <Dropdown
                title={this.props.t('portal.candidate.job-offers.filters.skills.branch.title')}
                items={this.props.branches}
                value={this.state.branchId}
                change={value => this.changeFilterSkills('branchId', value)}
                defaultValue={this.props.t('portal.candidate.job-offers.filters.skills.branch.default')}
                allowUnSelect/>
              <Dropdown
                title={this.props.t('portal.candidate.job-offers.filters.skills.unit.title')}
                items={this.props.units}
                value={this.state.unitId}
                change={value => this.changeFilterSkills('unitId', value)}
                defaultValue={this.props.t('portal.candidate.job-offers.filters.skills.unit.default')}
                allowUnSelect
                right/>
            </FormGroup>
          </div>
          <PerfectScrollbar className="skills-container">
            <InfiniteScroll
              hasMore={this.props.hasMoreItems}
              loadMore={() => this.fetchSkillsList()}
              useWindow={false}
              threshold={150}
              initialLoad={true}>
              <ul className="skills-list">
                {this.initSkillsList(this.props.skills, this.props.searchJobOffers.professionalSkills)}
                {this.initNotFoundSkills()}
              </ul>
            </InfiniteScroll>
          </PerfectScrollbar>
          <FormGroup className="full-width form-buttons mobile-filter-buttons">
            <Button
              onClick={() => this.applyFilters()}
              className="primary apply">
              <Trans i18nKey="portal.candidate.job-offers.filters.buttons.apply">
                Apply Filters
              </Trans>
              <span className="mobile-count"> ({this.props.jobOffersFilter &&
                this.props.jobOffersFilter.filterCount > 0 ? this.props.jobOffersFilter.filterCount : '0'})
              </span>
            </Button>
            <Button
              onClick={() => this.resetFilters()}
              className="primary outlined reset">
              <div className="mobile-filter-buttons-hide">
                <Trans i18nKey="portal.candidate.job-offers.filters.buttons.reset">
                  Clear Filters
                </Trans>
              </div>
              <div className="mobile-filter-buttons-show">
                <Trans i18nKey="portal.candidate.job-offers.filters.buttons.mobile.reset">
                  Clear All
                </Trans>
              </div>
            </Button>
          </FormGroup>
        </form>
      </Fragment>
    );
  }

}
