import axios from 'axios';

import { PersonalDataDto } from './personal-data.dto';

export async function createPersonalDataRequest(data: PersonalDataDto): Promise<> {
  try {
    let gdpr = {
      currentUser: await axios.get('/candidates/current'),
      occupationReq: await axios.get(`/candidates/${data.userId}/occupation-requirements`),
      candidateSkills: await axios.get(`/candidates/${data.userId}/skills`),
      noOfMatchedJobs: await axios.get(`/candidates/${data.userId}/matched-joboffers?new=true`),
      applicationTemplates: await axios.get(`/candidates/${data.userId}/applications-template?size=10&page=0&status=`),
      applicationsSent: await axios.get(`/candidates/${data.userId}/applications/stats`),
      candidateCV: await axios.get(`/candidates/${data.userId}/cv`),
      candidateEducation: await axios.get(`/candidates/${data.userId}/educations`),
      candidateWorkExp: await axios.get(`/candidates/${data.userId}/work-experiences`),
      candidateFollowing: await axios.get(`/candidates/${data.userId}/stats/connections?followingType=FOLLOWED`),
      candidateFollowers: await axios.get(`/candidates/${data.userId}/stats/connections?followingType=FOLLOWERS`)
    };
    console.log('GDPR ==>', gdpr);
    return await axios.post('/personal-data', data);
  } catch (error) {
    throw error;
  }
}
