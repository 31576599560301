// @flow
import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import { Card } from 'components/layouts';
import { Modal } from 'components/dialogs';

import { CandidateProfileView, ProfileContainer, ProfileHeader } from 'containers/portal/recruiter/calendar';

export class CandidateProfilePage extends Component {

  render () {
    return (
      <Modal closePath="/portal/recruiter/calendar">
        <ProfileContainer>
          <Card className="profile-header">
            <Route
              path="/portal/recruiter/calendar/:inviteId/profile/:candidateId"
              component={ProfileHeader}/>
          </Card>
          <Card className="profile-view profile-details-modal">
            <Route
              path="/portal/recruiter/calendar/:inviteId/profile/:candidateId"
              exact
              component={CandidateProfileView}/>
          </Card>
        </ProfileContainer>
      </Modal>
    );
  }

}
