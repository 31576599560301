import React, { Fragment } from 'react';

import Tooltip from 'rc-tooltip';
import { Trans } from 'react-i18next';

import cn from 'classnames';

import { connectTranslation } from 'middleware/connect';

import { Button, LinkButton } from 'components/buttons';

import { Settings } from './apply-actions-button.setting';

@connectTranslation()
export class JobOfferApplyActions extends Settings {

  render () {
    const applyBtnContainer = cn([ 'apply-btn-container', {
      'mobile-job-offer-apply-buttons': this.props.isMobileJobOffer
    } ]);

    const skillsBtn = cn([ 'full-width', 'interact-button', {
      'mobile-job-offer-skill-button': this.props.isMobileJobOffer
    } ]);

    return (
      <Fragment>
        <Tooltip
          offsetY={20}
          destroyTooltipOnHide
          overlayClassName={!this.props.isJobOfferMatched ? 'apply-blocked-info' : 'hidden'}
          overlay={!this.props.isJobOfferApplied
            ? this.props.t('portal.candidate.job-offers.preview.more-skills')
            : this.props.t('portal.candidate.job-offers.preview.already-applied')}
          placement="top">
          <div className={applyBtnContainer}>
            {
              this.props.isUserLoggedIn ? <Button
                className={`full-width interact-button ${this.props.isJobOfferApplied ? 'applied' : ''}`}
                candidate={this.props.isJobOfferMatched}
                disabled={!this.props.isJobOfferMatched}
                grey={!this.props.isJobOfferMatched}
                onClick={() => this.props.applyAction()}>
                {this.props.isJobOfferApplied ? <Trans i18nKey="portal.candidate.job-offers.preview.applied">
                  Applied
                </Trans> : <Trans i18nKey="portal.candidate.job-offers.preview.apply">
                  Apply
                </Trans>}
              </Button> : <LinkButton
                candidate={this.props.isJobOfferMatched}
                grey={!this.props.isJobOfferMatched}
                disabled={!this.props.isJobOfferMatched}
                link={this.props.guestRedirectLink}
                className="full-width">
                <Trans i18nKey="portal.candidate.job-offers.preview.apply">
                  Apply
                </Trans>
              </LinkButton>
            }
            {this.props.children}
          </div>
        </Tooltip>
        <Button
          candidate
          outlined
          onClick={() => this.props.interactAction()}
          className={skillsBtn}>
          <Trans i18nKey="portal.candidate.job-offers.preview.interact-button">
            Interact with Skills
          </Trans>
        </Button>
      </Fragment>
    );
  }

}
