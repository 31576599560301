import { Component } from 'react';

import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';

import { TransProps } from 'react-i18next';

import moment from 'moment';

import { EventDataDto } from 'rest/alerts/alerts.event-data.dto';

import { acceptContact, declineContact } from 'services/portal/candidate/anonymous/anonymous.actions';

export type Props = {
  publishDate: string,
  eventData: EventDataDto[],
  t?: TransProps,
  alertId?: string,
  request?: boolean,
  accepted?: boolean,
  acceptContact?: (recruiterId: string) => void,
  declineContact?: (recruiterId: string) => void
}

type State = {
  accepted: boolean,
  requested: boolean
}

export class Setting extends Component<Props, State> {

  static defaultProps: Props = {
    eventData: [],
    publishDate: moment().parseZone().fromNow(),
    alertId: '',
    request: false,
    accepted: false,
    acceptContact: () => {},
    declineContact: () => {}
  };

  state: State = {
    accepted: this.props.accepted,
    requested: false
  };

}

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  acceptContact,
  declineContact
}, dispatch);
