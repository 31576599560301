// @flow
import { Component } from 'react';

import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import {
  disableOnBoardingVideo as disableRecruiterVideo
} from 'services/portal/recruiter/account/current/current.actions';
import {
  disableOnBoardingVideo as disableCandidateVideo
} from 'services/portal/candidate/account/current/current.actions';

import './on-boarding-modal.scss';

export type Props = {
  isRecruiter: boolean,
  showRecruiterVideo: boolean,
  showCandidateVideo: boolean,
  disableRecruiterVideo: () => void,
  disableCandidateVideo: () => void
}

export type State = {
  open: boolean
}

export class Setting extends Component<Props> {

  static defaultProps: Props = {
    isRecruiter: false,
    showRecruiterVideo: false,
    showCandidateVideo: false,
    disableRecruiterVideo: () => {},
    disableCandidateVideo: () => {}
  };

  state: State = {
    open: false
  }

}

export const stateToProps = (state: GlobalState) => {
  // console.log(state)
  return {
    isRecruiter: !!state.portal.recruiter.account.current.currentUser.id,
    showRecruiterVideo: state.portal.recruiter.account.current.currentUser.showOnBoardingVideo,
    showCandidateVideo: state.portal.candidate.account.current.candidateData.showOnBoardingVideo
  };
};

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  disableRecruiterVideo,
  disableCandidateVideo
}, dispatch);
