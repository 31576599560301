// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { CompanyDto } from 'rest/companies/companies.dto';
import { ImageResponseDto } from 'rest/images/images.response';
import { CurrentRecruiterDto } from 'rest/recruiter/current/current.dto';

import { showModal } from 'services/portal/modal/modal.actions';
import { getMatchStatistic } from 'services/portal/recruiter/match/stats/stats.actions';
import { getUnreadConversation } from 'services/portal/chats/conversations/conversations.actions';

import './menu.scss';

export type Props = {
  t?: TransProps,
  company: CompanyDto,
  newMatchedCandidateCount: number,
  currentUser: CurrentRecruiterDto
}

export type State = {
  chatInterval: number,
  matchedInterval: number,
  user: CurrentRecruiterDto,
  avatar: ImageResponseDto
}

export class Settings extends Component<Props, State> {

  static defaultProps: Props = {
    getCurrentCompanyRecruiter: () => {},
    currentUser: new CurrentRecruiterDto()
  };

  state: State = {
    chatInterval: 0,
    matchedInterval: 0,
    user: new CurrentRecruiterDto(),
    avatar: new ImageResponseDto()
  }

}

export const stateToProps = (state: GlobalState) => ({
  company: state.portal.recruiter.account.companies.company,
  currentUser: state.portal.recruiter.account.current.currentUser,
  unreadMessages: state.portal.chats.conversations.unreadMessages,
  newMatchedCandidateCount: state.portal.recruiter.match.jobOffersStats.newMatchedCandidateCount
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  showModal,
  getMatchStatistic,
  getUnreadConversation
}, dispatch);
