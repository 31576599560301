export class ApplicationResponseDto {

  id: string;
  body: string;
  date: string;
  subject: string;
  templateType: string;

  constructor (data?: ApplicationResponseDto) {
    if (data) {
      this.id = data.id;
      this.body = data.body;
      this.date = data.date;
      this.subject = data.subject;
      this.templateType = data.templateType;
    }
  }

}
