// @flow
import React, { Children, cloneElement, Fragment } from 'react';

import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';
import { signOut as logOut } from 'middleware/auth';
import { resetUserType } from 'middleware/user-type';

import { Header } from 'containers/portal';

import { Button } from 'components/buttons';

import { Setting, dispatchToProps } from './sidebar.setting';
import { MenuVideoModal } from 'components/dialogs';
@connectStore(null, dispatchToProps)
export class Sidebar extends Setting {

  signOut () {
    if (this.props.history) {
      logOut();
      resetUserType();
      this.props.clearCurrentRecruiter();
      this.props.clearCurrentCandidate();
      this.props.history.push('/');
    }
  }

  render () {
    const pathName = this.props.history.location.pathname.indexOf('/candidate') > -1
      ? '/candidate/settingsVideo'
      : '/recruiter/settingsVideo';
    return (
      <Fragment>
        <aside className="profile-sidebar">
          <Header/>
          <div className="mobile-sidebar-close">
            <Button
              onClick={this.props.hideModal}
              className="profile-sidebar-caption inline">
              <Trans i18nKey="portal.common.back-to-app">
                Go to Application
              </Trans>
              <div className="profile-sidebar-close">&times;</div>
            </Button>
            <div className="portal-sidebar-mobile-title">
              <Trans i18nKey="portal.common.mobile.title">
                Settings
              </Trans>
            </div>
          </div>
          <nav>
            <ul className="profile-menu-list">
              {
                Children.map(this.props.children, (child, index) => cloneElement(child, {
                  className: index === this.props.activeTabIndex ? 'active' : ''
                }))
              }
              <li
                className="signout"
                onClick={() => this.signOut()}>
                <Trans i18nKey="portal.recruiter.profile.menu.8">
                  Sign Out
                </Trans>
              </li>
            </ul>
          </nav>
        </aside>
        <MenuVideoModal locationPath= {pathName}/>
      </Fragment>
    );
  }

}
