import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Button } from 'components/buttons';
import { PhotoGallery } from 'components/photo-gallery/photo-gallery';

import { CompanyDto } from 'rest/companies/companies.dto';

import { Settings } from './company-info.setting';

@connectTranslation()
export class JobOfferCompanyInfo extends Settings {

  render () {
    const company: CompanyDto = this.props.company;
    return (
      <div className="job-offer-company-info-preview mobile-job-offer-company-info-preview">
        {
          company.restricted && !this.props.hideCompanyInfo
            ? <div className="restricted">
              <span className="cost-job-offer">
                {this.props.t('portal.candidate.job-offers.preview.info.free-company')}
              </span>
              <img
                src="/images/recruiter/blured.png"
                className="restricted-company"/>
            </div>
            : <div className="user-content">
              {
                company.restricted && !this.props.hideCompanyInfo
                  ? <img src="/images/recruiter/blured.png"/>
                  : <div className="job-address">
                    {
                      company.address.city && <div className="address">
                        <h4 className="name">{company.name}</h4>
                        <div>{company.address.street} {company.address.streetNumber}, {company.address.zipCode}</div>
                        <div>{company.address.city}, {company.address.country}</div>
                      </div>
                    }
                    {
                      !this.props.hideCompanyInfo &&
                      <Fragment>
                        <div className="logo-container">
                          <div className="hotel-logo">
                            <p className="hotel-logo-caption">
                              <Trans i18nKey="portal.candidate.job-offers.preview.company.about">
                                Find out about vacancies in this company
                              </Trans>
                            </p>
                            <div className="logo-wrapper">
                              {
                                company.logo.url ? <img src={company.logo.url} alt={company.name}/> : ''
                              }
                            </div>
                            {
                              !company.restricted ? <Button
                                candidate
                                fullWidth
                                onClick={() => this.props.toggleCompanyFollow(company.follow)}
                                outlined>
                                <img src="/images/shared/primary-add-user-icon.png"/>
                                {company.follow
                                  ? this.props.t('portal.candidate.job-offers.preview.company.follow.remove')
                                  : this.props.t('portal.candidate.job-offers.preview.company.follow.add')}
                              </Button> : ''
                            }
                          </div>
                        </div>
                        {
                          company.photos && company.photos.length > 0 && <PhotoGallery photos={company.photos}/>
                        }
                        {
                          company.description && <div className="job-employer-description">
                            <h4 className="title">
                              <Trans i18nKey="portal.candidate.job-offers.preview.company.description">
                                Description of business
                              </Trans>
                            </h4>
                            <p className="description">{company.description}</p>
                          </div>
                        }
                      </Fragment>
                    }
                  </div>
              }
            </div>
        }
      </div>
    );
  }

}
