// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { StatsDto } from 'rest/stats/stats.dto';
import { CandidateDto } from 'rest/candidates/candidate.dto';
import { JobOffersParams } from 'rest/public/job-offers/job-offers.params';

import { initJobsFilter } from 'services/portal/candidate/jobs/job-offers/job-offers.actions';
import type { SearchJobOffersType } from 'services/portal/candidate/jobs/search/search.type';

import {
  resetJobOffersStats,
  getJobOffersStatistic
} from 'services/portal/candidate/jobs/job-offers/stats/stats.actions';

import { saveSearchJobsBranch } from 'services/portal/candidate/jobs/search/search.actions';

type Props = {
  t: TransProps,
  total: number,
  stats: StatsDto[],
  currentUser: CandidateDto,
  searchJobOffers: SearchJobOffersType,
  initJobsFilter: (filter: JobOffersParams) => void,
  resetJobOffersStats: () => void,
  saveSearchJobsBranch: (branchId: string) => void,
  getJobOffersStatistic: () => void
}

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  total: state.portal.candidate.jobs.jobOffersStats.totalElements,
  stats: state.portal.candidate.jobs.jobOffersStats.stats,
  currentUser: state.portal.candidate.account.current.candidateData,
  searchJobOffers: state.portal.candidate.jobs.searchJobOffers
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  initJobsFilter,
  resetJobOffersStats,
  saveSearchJobsBranch,
  getJobOffersStatistic
}, dispatch);
