// @flow
import React from 'react';

import cn from 'classnames';
import Slider from 'react-rangeslider';

import { Settings } from './range-control.setting';

export class RangeControl extends Settings {

  changeCurrentValue (value: number) {
    if (!this.props.disabled) {
      this.props.change(value);
      this.setState({ value });
    }
  }

  render () {
    const sliderClasses = cn(['range-control-container',
      { disabled: this.props.disabled }
    ]);
    return (
      <div className={sliderClasses}>
        <Slider
          min={this.props.min}
          max={this.props.max}
          value={this.props.value}
          tooltip={false}
          onChange={value => this.changeCurrentValue(value)}
          handleLabel={this.props.value.toString()}/>
      </div>
    );
  }

}
