export class ApplyJobOfferDto {

  body: string;
  subject: string;
  jobOfferId: string;
  templateId: string;

  constructor (data?: ApplyJobOfferDto) {
    if (data) {
      this.body = data.body;
      this.subject = data.subject;
      this.jobOfferId = data.jobOfferId;
      this.templateId = data.templateId;
    }
  }

}
