import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { hideModal } from 'services/portal/modal/modal.actions';

import './modal.scss';
import './modal.responsive.scss';

export type Props = {
  open: boolean,
  className?: string,
  overlay?: boolean,
  onOpen?: () => void,
  hideModal: () => void,
  closeOnOverlay: () => void,
  animation: 'fade' | 'slide',
  hideModalAvailable: boolean
}

type State = {
  isModalOpen: boolean
}

export class Setting extends Component<Props, State> {

  static defaultProps: Props = {
    open: false,
    overlay: false,
    className: '',
    animation: 'slide',
    onOpen: () => {},
    closeOnOverlay: () => {},
    hideModalAvailable: true
  };

  state: State = {
    isModalOpen: this.props.open
  };

}

export const stateToProps = (state: GlobalState) => ({
  modalType: state.portal.modal.modalType
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  hideModal
}, dispatch);
