import * as actions from './stats.constants';

import { StatsDto } from 'rest/stats/stats.dto';

import type { JobOffersStatsType } from './stats.type';

const initialState: JobOffersStatsType = {
  stats: [],
  fetching: [],
  totalElements: 0
};

export default (state = initialState, action): JobOffersStatsType => {
  switch (action.type) {

    case actions.GET_JOB_OFFERS_STATS_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case actions.GET_JOB_OFFERS_STATS_SUCCESS:
      return {
        ...state,
        stats: action.stats.map(stat => new StatsDto(stat)),
        fetching: false,
        totalElements: action.total
      };

    case actions.GET_JOB_OFFERS_STATS_FAILURE:
      return {
        ...state,
        stats: [],
        fetching: false,
        totalElements: action.total
      };

    case actions.RESET_JOB_OFFERS_STATS_SUCCESS:
      return {
        ...state,
        stats: [],
        fetching: false,
        totalElements: 0
      };

    default:
      return state;
  }
};
