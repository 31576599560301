// @flow
import React from 'react';

import moment from 'moment';
import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';
import { getDateDuration } from 'middleware/get-duration';

import { WorkExperienceDto } from 'rest/candidates/work-experience/work-experience.dto';

import { Settings, stateToProps, dispatchToProps } from './work-experience.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class WorkExperience extends Settings {

  componentDidMount () {
    this.props.getCandidateEducations(this.props.candidateId);
    this.props.getCandidateWorkExperiences(this.props.candidateId);
  }

  render () {
    return (
      <div className="education-section-container">
        {
          this.props.experiences.length > 0 ? this.props.experiences.map((item: WorkExperienceDto, index: number) => (
            <div
              key={index}
              className="education-item">
              <div className="left-info">
                <p>{item.toDate ? moment(item.toDate).format('ll') : 'Now'} - </p>
                <p>{moment(item.fromDate).format('ll')}</p>
                <p className="duration">
                  {getDateDuration(item.fromDate, item.toDate || new Date(), this.props.t)}
                </p>
              </div>
              <div className="right-info">
                <h4 className="degree">{item.jobTitle}</h4>
                <p className="school">{item.description}</p>
                <a
                  href={item.website}
                  className="school link">
                  {item.website}
                </a>
                <p className="school">{item.location}</p>
              </div>
            </div>
          )) : <div className="experience-empty-section">
            <h5 className="empty-section-title">
              <Trans i18nKey="portal.recruiter.candidates.profile.experience.empty">
                Work Experience is empty
              </Trans>
            </h5>
          </div>
        }
        <hr className="divider"/>
        <h5 className="education-section-title">
          <Trans i18nKey="portal.recruiter.candidates.profile.experience.education">
            Education
          </Trans>
        </h5>
        {
          (this.props.educations || []).length > 0 && this.props.educations.map((item, index: number) => {
            const educationDuration: string = getDateDuration(
              item.fromDate, item.toDate || new Date(), this.props.t
            );
            return (
              <div
                key={index}
                className="education-item">
                <div className="left-info">
                  <p>{item.isCurrent ? 'Now' : moment(item.toDate).format('ll')} -</p>
                  <p>{moment(item.fromDate).format('ll')}</p>
                  <p className="duration">
                    {educationDuration}
                  </p>
                </div>
                <div className="right-info">
                  <h4 className="degree">{item.degree}</h4>
                  <p className="school">{item.school}</p>
                </div>
              </div>
            );
          })
        }
      </div>
    );
  }

}
