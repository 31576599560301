import { Component } from 'react';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';
import { JobRequirementsResponseDto } from 'rest/candidates/job-requirements/job-requirements.response.dto';

import './personal-info-section.scss';
import './personal-info-section.responsive.scss';

type Props = {
  t?: TransProps,
  jobTitle: string,
  currentCandidate: CurrentCandidateDto,
  balanceBoard: JobRequirementsResponseDto
}

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  jobTitle: state.portal.candidate.cv.jobTitle.cvData.jobTitle,
  fetching: state.portal.candidate.account.current.fetching,
  balanceBoard: state.portal.candidate.balanceBoard.balanceBoard,
  currentCandidate: state.portal.candidate.account.current.candidateData
});
