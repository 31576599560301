export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';

export const READ_NOTIFICATION_REQUEST = 'READ_NOTIFICATION_REQUEST';
export const READ_NOTIFICATION_SUCCESS = 'READ_NOTIFICATION_SUCCESS';
export const READ_NOTIFICATION_FAILURE = 'READ_NOTIFICATION_FAILURE';

export const GET_NOTIFICATIONS_COUNT_REQUEST = 'GET_NOTIFICATIONS_COUNT_REQUEST';
export const GET_NOTIFICATIONS_COUNT_SUCCESS = 'GET_NOTIFICATIONS_COUNT_SUCCESS';
export const GET_NOTIFICATIONS_COUNT_FAILURE = 'GET_NOTIFICATIONS_COUNT_FAILURE';

export const RESET_NOTIFICATIONS_LIST_SUCCESS = 'RESET_NOTIFICATIONS_LIST_SUCCESS';

export const notificationsType = {
  ALL: 'ALL',
  READ: 'READ',
  UNREAD: 'UNREAD'
};
