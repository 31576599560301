export class ChatMessageAttachmentDto {

  link: string;
  size: number;
  nameFile: string;

  constructor (data?: ChatMessageAttachmentDto) {
    if (data) {
      this.link = data.link;
      this.size = data.size;
      this.nameFile = data.nameFile;
    }
  }

}
