import axios, { AxiosPromise } from 'axios';

import { PaginationDto } from 'middleware/dto/pagination';

import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';
import { JobOffersStatsResponseDto } from 'rest/companies/job-offers/job-offers-stats.dto';

export async function getCompanyJobOffers (companyId: string, status?: string = '', params: PaginationDto)
  : AxiosPromise<JobOffersResponseDto> {
  try {
    return await axios.get(`/companies/${companyId}/job-offers?status=${status}`, {
      params
    });
  } catch (error) {
    throw error;
  }
}

export async function getCompanyJobOffersStats (companyId: string)
  : AxiosPromise<JobOffersStatsResponseDto> {
  try {
    return await axios.get(`/companies/${companyId}/job-offers/status-stats`);
  } catch (error) {
    throw error;
  }
}
