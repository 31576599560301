// @flow
import React from 'react';

import type { Props, State } from './photo-gallery.setting';

import { ImageDto } from 'rest/images/image.dto';

import { Setting } from './photo-gallery.setting';

export class PhotoGallery extends Setting {

  componentWillReceiveProps (nextProps: Props, state: State) {
    const nextPhotoUrl: string = nextProps.photos[0] ? nextProps.photos[0].url : '';
    if (this.props.photos[0] && this.props.photos[0].url !== nextPhotoUrl) {
      this.setState({ activePhotoUrl: nextPhotoUrl });
    }
  }

  setActive (activePhotoIndex: number, activePhotoUrl: string) {
    this.setState({ activePhotoUrl, activePhotoIndex });
  }

  render () {
    return this.props.photos.length ? (
      <div className="gallery-container">
        <div className="primary-photo-wrapper">
          <img
            src={this.state.activePhotoUrl}
            alt="primary candidate photo"/>
        </div>
        {this.props.photos.length > 1 && (
          <div className="gallery-thumbnails">
            {this.props.photos.map((photo: ImageDto, index: number) => {
              if (index < 3) {
                return (
                  <div
                    key={photo.imageId}
                    onClick={() => this.setActive(index, photo.url)}
                    className={'thumbnail-photo-wrapper' + (index === this.state.activePhotoIndex ? ' active' : '')}>
                    <img
                      src={photo.url}
                      alt="thumbnail-photo"/>
                  </div>
                );
              }
            })}
          </div>
        )}
      </div>
    ) : null;
  }

}
