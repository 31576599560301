import { MatchedCandidatesDto } from './matched-candidates/matched-candidates.dto';

export class JobOffersMatchesDto {

  jobType: string;
  jobOfferId: number;
  branchName: string;
  jobTitleName: string;
  publishedDate: string;
  newCandidateCount: number;
  matchedCandidates: MatchedCandidatesDto[] = [];
  jobOfferMatchPoints: number;
  matchedCandidatesCount: number;

  constructor (data?: JobOffersMatchesDto) {
    if (data) {
      this.jobType = data.jobType;
      this.jobOfferId = data.jobOfferId;
      this.branchName = data.branchName;
      this.jobTitleName = data.jobTitleName;
      this.publishedDate = data.publishedDate;
      this.newCandidateCount = data.newCandidateCount;
      this.matchedCandidates = data.matchedCandidates.map(candidate => new MatchedCandidatesDto(candidate));
      this.jobOfferMatchPoints = data.jobOfferMatchPoints;
      this.matchedCandidatesCount = data.matchedCandidatesCount;
    }
  }

}
