// @flow
import * as actions from './lifestyles.constants';

import { Action } from 'redux';

import type { LifeStyleStateType } from './lifestyles.type';

import { LifeStyleItemDto } from 'rest/candidates/lifestyles/lifestyle-item.dto';

const maxLifeStyleField: number = 7;

const initialState: LifeStyleStateType = {
  fetching: false,
  createSuccess: false,
  activities: [],
  filteredActivities: []
};

export default function (state: LifeStyleStateType = initialState, action: Action): LifeStyleStateType {
  switch (action.type) {
    case actions.GET_LIFESTYLE_REQUEST:
      return {
        ...state,
        fetching: true,
        createSuccess: false,
        activities: Array(maxLifeStyleField).fill(new LifeStyleItemDto())
      };

    case actions.GET_LIFESTYLE_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.CREATE_LYFESTYLE_SUCCESS:
      return {
        ...state,
        createSuccess: true
      };

    case actions.GET_LIFESTYLE_SUCCESS: {
      const fetchedActivities = action.lifestyle.map(item => new LifeStyleItemDto(item));
      return {
        ...state,
        fetching: false,
        filteredActivities: fetchedActivities,
        activities: [...fetchedActivities, ...state.activities]
      };
    }

    default:
      return state;
  }
}
