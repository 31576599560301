// @flow
import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import { Modal } from 'components/dialogs';
import { ProfileContainer } from 'containers/portal/recruiter/candidates';

export class ProfilePage extends Component<null> {

  render () {
    return (
      <Modal closePath={`/portal/recruiter/favorites/${this.props.match.params.url}`}>
        <Route
          path="/portal/recruiter/favorites/:url/:candidateId/profile"
          component={ProfileContainer}/>
      </Modal>
    );
  }

}
