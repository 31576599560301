// @flow
import React from 'react';

import cn from 'classnames';

import { Trans } from 'react-i18next';

import { getCookiesAccept, setCookiesAccept } from 'middleware/cookies-policy/index';

import { Setting } from './cookies-policy-modal.setting';

export class CookiesPolicyModal extends Setting {

  componentDidMount () {
    this.setState({
      cookiesOpen: !getCookiesAccept()
    });
  }

  handleAccept = () => {
    this.setState({
      cookiesOpen: false
    });
    setCookiesAccept();
  };

  render () {
    const modalClasses = cn([ 'cookies-modal', {
      'flex': this.state.cookiesOpen
    } ]);

    return (
      <div className={modalClasses}>
        <div className="cookies-modal-container">
          <span className="cookies-modal-inscription">
            <Trans i18nKey='portal.cookie-policy.inscription'>
              We use cookies to improve your experience
          and deliver personalized content. By using excellent-jobs, your agree to our
            </Trans>
            <a href="/information/privacy-policy#Cookies">
              <Trans i18nKey='portal.cookie-policy.cookie-policy'>
                Cookie Policy.
              </Trans>
            </a>
          </span>
          <a
            className="btn accept-cookies-button"
            onClick={this.handleAccept}>
            <Trans i18nKey='portal.cookie-policy.button'>
              Accept
            </Trans>
          </a>
        </div>
      </div>
    );
  }

}
