// @flow
import React from 'react';

import type { DropdownType } from 'components/controls/dropdown/dropdown.type';

import type { Props } from './application-dropdown.setting';
import { Settings } from './application-dropdown.setting';

export class ApplicationListDropdown extends Settings {

  static getDerivedStateFromProps (nextProps: Props) {
    if (nextProps.value && nextProps.value !== '') {
      return { selectedItem: nextProps.items.find(elem => elem.id === nextProps.value) || null };
    }
    return null;
  }

  closeDropdown () {
    this.setState({ listOpen: false });
  }

  selectItem (selectedItem: DropdownType) {
    this.setState({ selectedItem }, () => this.props.change(selectedItem));
    this.closeDropdown();
  }

  createNewTemplate () {
    const newTemplateItem = { name: 'New Template' };
    this.setState({ selectedItem: newTemplateItem });
    this.props.createNewApplication();
    this.closeDropdown();
  }

  render () {
    return (
      <div className="application-dropdown-container">
        {
          this.props.label ? <label className="application-dropdown-label">
            <span className="form-label-title">{this.props.label}</span>
          </label> : null
        }
        {
          this.state.listOpen && <div
            className="outside"
            onClick={() => this.closeDropdown()}
          />
        }
        <div className="application-dropdown-filter">
          <div
            className="application-dropdown-label"
            onClick={() => this.setState(prevState => ({ listOpen: !prevState.listOpen }))}>
            <span className="application-dropdown-label-value">
              {this.state.selectedItem ? this.state.selectedItem.name : this.props.value}
            </span>
          </div>
          {
            this.state.listOpen && <div className="application-dropdown-options">
              <ul className="application-dropdown-list">
                {
                  this.props.items.map((item: DropdownType, index: number) => (
                    <li
                      key={index}
                      onClick={() => this.selectItem(item)}>
                      <span>{item.name}</span>
                    </li>
                  ))
                }
                <li
                  className="create-application-action"
                  onClick={() => this.createNewTemplate()}>
                  <span>Create new Letter</span>
                </li>
              </ul>
            </div>
          }
        </div>
      </div>
    );
  }

}
