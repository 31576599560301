// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { CurrentRecruiterDto } from 'rest/recruiter/current/current.dto';

import { sendPersonalDataRequest } from 'services/portal/personal-data/personal-data.actions';

import './history.scss';

export type Props = {
  success: boolean,
  errorText: string,
  sendPersonalDataRequest?: () => void,
  currentUser?: CurrentRecruiterDto
}

export class Setting extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  success: state.portal.personalData.success,
  errorText: state.portal.personalData.errorText,
  currentUser: state.portal.recruiter.account.current.currentUser
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  sendPersonalDataRequest
}, dispatch);
