// @flow
import { Component } from 'react';

import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import {
  disableBalanceDashboardVideo
} from 'services/demoMenuVideo/recruiter/demoMenuVideo.actions';

import {
  disableCandidateDashboardVideo
} from 'services/demoMenuVideo/candidate/demoMenuVideo.actions';

import './menu-video-modal.scss';

export type Props = {
  isRecruiter: boolean,
  showRecruiterVideo: boolean,
  showCandidateVideo: boolean,
  disableRecruiterVideo: () => void,
  disableCandidateVideo: () => void
}

export type State = {
  open: boolean
}

export class Setting extends Component<Props> {

  static defaultProps: Props = {
    isRecruiter: false,
    showRecruiterVideo: false,
    showCandidateVideo: false,
    disableRecruiterVideo: () => {},
    disableCandidateVideo: () => {}
  };

  state: State = {
    open: false
  }

}

export const stateToProps = (state: GlobalState) => {
  return {
    isRecruiter: !!state.portal.recruiter.account.current.currentUser.id,
    showRecruiterVideo: state.portal.recruiter.account.current.currentUser.showOnBoardingVideo,
    showCandidateVideo: state.portal.candidate.account.current.candidateData.showOnBoardingVideo,
    showBalanceBoardVideo: state.demoMenuVideo.demoMenuVideo.onloadBalanaceBoardVideo,
    showBalanceBoardReUseVideo: state.demoMenuVideo.demoMenuVideo.onloadBalanaceBoardReUseVideo,
    showJobOffersVideo: state.demoMenuVideo.demoMenuVideo.onloadjobOffersVideo,
    showMatchVideo: state.demoMenuVideo.demoMenuVideo.onloadMatchVideo,
    showChatVideo: state.demoMenuVideo.demoMenuVideo.onloadChatVideo,
    showCalendarVideo: state.demoMenuVideo.demoMenuVideo.onloadCalendarVideo,
    showFavoritesVideo: state.demoMenuVideo.demoMenuVideo.onloadFavoritesVideo,
    showFollowingVideo: state.demoMenuVideo.demoMenuVideo.onloadFollowingVideo,
    showStatisticsVideo: state.demoMenuVideo.demoMenuVideo.onloadStatisticsVideo,
    showCandidatesVideo: state.demoMenuVideo.demoMenuVideo.onloadCandidatesVideo,
    showBalanceBoardCandidateVideo: state.demoMenuVideo.demoMenuVideoCadidate.onloadBalanaceBoardVideo,
    showBalanceBoardReUseCandidateVideo: state.demoMenuVideo.demoMenuVideoCadidate.onloadBalanaceBoardReUseVideo,
    showApplicationCandidateVideo: state.demoMenuVideo.demoMenuVideoCadidate.onloadApplicationVideo,
    showMatchCandidateVideo: state.demoMenuVideo.demoMenuVideoCadidate.onloadMatchVideo,
    showChatCandidateVideo: state.demoMenuVideo.demoMenuVideoCadidate.onloadChatVideo,
    showCvCandidateVideo: state.demoMenuVideo.demoMenuVideoCadidate.onloadCvVideo,
    showFavoritesCandidateVideo: state.demoMenuVideo.demoMenuVideoCadidate.onloadFavoritesVideo,
    showFollowingCandidateVideo: state.demoMenuVideo.demoMenuVideoCadidate.onloadFollowingVideo,
    showJobCandidateVideo: state.demoMenuVideo.demoMenuVideoCadidate.onloadJobSearchVideo,
    showMyBlogCandidateVideo: state.demoMenuVideo.demoMenuVideoCadidate.onloadMyBlogVideo,
    showSettingsVideo: state.demoMenuVideo.demoMenuVideo.onLoadSettingDemoVideo,
    showCandidateSettingsVideo: state.demoMenuVideo.demoMenuVideoCadidate.onLoadSettingDemoVideo
  };
};

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  disableBalanceDashboardVideo,
  disableCandidateDashboardVideo
}, dispatch);
