import React from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';
import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';

import { Settings, dispatchToProps, stateToProps } from './job-offer-header.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class JobOfferHeader extends Settings {

  componentDidMount () {
    this.props.getJobOfferWithSkills(this.props.match.params.jobOfferId);
  }

  render () {
    return (
      <div className="match-candidates-job-offer-info">
        <div className="navigation">
          <Link to="/portal/recruiter/match">
            <Trans i18nKey="portal.recruiter.match.preview.back">
              Back to job offer list
            </Trans>
          </Link>
        </div>
        <div className="title">
          {this.props.jobOffer.jobTitleName || this.props.t('portal.recruiter.match.preview.no-information')}
        </div>
        <div className="information">
          {
            this.props.jobOffer.branchName && <span className="badge branch">
              {this.props.jobOffer.branchName}
            </span>
          }
          {
            this.props.jobOffer.unitName && <span className="badge unit">
              {this.props.jobOffer.unitName}
            </span>
          }
          {
            this.props.jobOffer.publishedDate && <span className="badge published">
              <Trans i18nKey="portal.recruiter.match.preview.published">
                Published
              </Trans>
              &nbsp;
              {moment(this.props.jobOffer.publishedDate).format('LL')}
            </span>
          }
          {
            this.props.jobOffer.matchedPoints && <span className="badge points">
              {this.props.jobOffer.matchedPoints} %
            </span>
          }
        </div>
      </div>
    );
  }

}
