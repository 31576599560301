// @flow
import React from 'react';

import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Setting } from './customers.setting';

@connectTranslation()
export class Customers extends Setting {

  render () {
    return (
      <div className="realize-section-container">
        {/* <div className="realize-section-title">
          <Trans i18nKey="information.pricing.realizeSection.title" />
        </div>
        <ul className="realize-section-list">
          <li>
            <Trans i18nKey="information.pricing.realizeSection.list.0" />
          </li>
          <li>
            <Trans i18nKey="information.pricing.realizeSection.list.1" />
          </li>
          <li>
            <Trans i18nKey="information.pricing.realizeSection.list.2" />
          </li>
        </ul> */}
        <div className="realize-section-description">
          <span className="realize-section-description-element">
            <Trans i18nKey="information.pricing.realizeSection.description.0" />
          </span>
          <span className="realize-section-description-element">
            <Trans i18nKey="information.pricing.realizeSection.description.1" />
          </span>
          <span className="realize-section-description-element">
            <Trans i18nKey="information.pricing.realizeSection.description.2" />
          </span>
          <span className="realize-section-description-element">
            <Trans i18nKey="information.pricing.realizeSection.description.3" />
          </span>
        </div>
        <div className="realize-section-button">
          <a href={ 'mailto:excellent-jobs@gastronomie-journal.de' } className="accent btn" recruiter>
            <Trans i18nKey="information.pricing.realizeSection.button" />
          </a>
        </div>
      </div>
    );
  }

}
