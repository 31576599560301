import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { LifeStyleDto } from 'rest/candidates/lifestyles/lifestyles.dto';
import { LifeStyleItemDto } from 'rest/candidates/lifestyles/lifestyle-item.dto';
import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';

import { createCandidateLifeStyle } from 'services/portal/candidate/cv/lifestyles/lifestyles.actions';

import './daily.scss';
import './daily.responsive.scss';

export type Props = {
  t?: TransProps,
  createSuccess: boolean,
  activities: LifeStyleDto,
  currentCandidate: CurrentCandidateDto
};

type State = {
  createSuccess: boolean,
  maxPointsCount: number,
  activities: LifeStyleItemDto[]
};

export class Settings extends Component<Props, State> {

  static defaultProps = {
    currentCandidate: new CurrentCandidateDto()
  };

  state: State = {
    activities: [],
    maxPointsCount: 24,
    createSuccess: false
  };

}

export const stateToProps = (state: GlobalState) => ({
  activities: state.portal.candidate.cv.lifestyle.activities,
  createSuccess: state.portal.candidate.cv.lifestyle.createSuccess,
  currentCandidate: state.portal.candidate.account.current.candidateData
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  createCandidateLifeStyle
}, dispatch);
