import React, { Fragment } from 'react';
import { Redirect, Route as ReactRoute } from 'react-router-dom';

import { getUserType } from 'middleware/user-type';
import { setUnauthorizedUserRedirectPage } from 'middleware/unauthorized-user-redirect';
import { isLoggedIn } from '../auth';
import { Setting } from './setting';

export class Route extends Setting {

  render () {
    const userType = getUserType();
    if (!isLoggedIn && this.props.type && userType !== this.props.type && !this.props.path.includes('auth')) {
      setUnauthorizedUserRedirectPage(window.location.pathname);
    }
    return (
      <Fragment>
        {
          (isLoggedIn)
            ? <ReactRoute
              path={this.props.path}
              exact={this.props.exact}
              component={this.props.component}/>
            : <Redirect to={`/auth`}/>
        }
      </Fragment>
    );
  }

}
