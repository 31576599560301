import * as actions from './demoMenuVideo.constants';

import type { CurrentRecruiterState } from './demoMenuVideo.type';

const initialState: CurrentRecruiterState = {
  onloadBalanaceBoardVideo: true,
  onloadBalanaceBoardReUseVideo: true,
  onloadCandidatesVideo: true,
  onloadjobOffersVideo: true,
  onloadMatchVideo: true,
  onloadChatVideo: true,
  onloadCalendarVideo: true,
  onloadFavoritesVideo: true,
  onloadFollowingVideo: true,
  onloadStatisticsVideo: true,
  onLoadSettingDemoVideo: true
};

export default (state = initialState, action): CurrentRecruiterState => {
  switch (action.type) {
    case actions.DISABLE_ON_BALANCE_BOARD_VIDEO:
      return {
        ...state,
        onloadBalanaceBoardVideo: false
      };
    case actions.DISABLE_ON_BALANCE_BOARD_REUSE_VIDEO:
      return {
        ...state,
        onloadBalanaceBoardReUseVideo: false
      };
    case actions.DISABLE_ON_CANDIDATES_VIDEO:
      return {
        ...state,
        onloadCandidatesVideo: false
      };
    case actions.DISABLE_ON_JOBOFFERS_VIDEO:
      return {
        ...state,
        onloadjobOffersVideo: false
      };
    case actions.DISABLE_ON_MATCH_VIDEO:
      return {
        ...state,
        onloadMatchVideo: false
      };
    case actions.DISABLE_ON_CHAT_VIDEO:
      return {
        ...state,
        onloadChatVideo: false
      };
    case actions.DISABLE_ON_CALENDAR_VIDEO:
      return {
        ...state,
        onloadCalendarVideo: false
      };
    case actions.DISABLE_ON_FAVORITES_VIDEO:
      return {
        ...state,
        onloadFavoritesVideo: false
      };
    case actions.DISABLE_ON_FOLLOWING_VIDEO:
      return {
        ...state,
        onloadFollowingVideo: false
      };
    case actions.DISABLE_ON_STATISTICS_VIDEO:
      return {
        ...state,
        onloadStatisticsVideo: false
      };
    case actions.DISABLE_ON_SETTINGS_VIDEO:
      return {
        ...state,
        onLoadSettingDemoVideo: false
      };
    default:
      return state;
  }
};
