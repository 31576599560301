// @flow
import { Action, Dispatch } from 'redux';

import * as actions from './sign-in.constants';

import { setToken } from 'middleware/auth';
import { setUserType } from 'middleware/user-type';
import { initRestParams } from 'middleware/rest';
import { getLocalization } from 'middleware/localization';
import { getUnauthorizedUserRedirectPage } from 'middleware/unauthorized-user-redirect';
import { resetUnauthorizedUserRedirectPage } from 'middleware/unauthorized-user-redirect';

import { CandidateDto } from 'rest/candidates/candidate.dto';
import { signInCandidate } from 'rest/candidates/candidate.rest';
import { getSocialAuthUrl } from 'rest/auth/auth.rest';
import { changeCurrentCandidateLocale } from 'rest/candidates/current/current.rest';

import { getCurrentCandidate } from 'services/portal/candidate/account/account.actions';
import { setNameAndLastNameAndEmail } from '../../../../middleware/auth';

const candidateSinInRequest = (): Action => ({
  type: actions.CANDIDATE_SIGN_IN_REQUEST
});

const candidateSignInSuccess = (payload): Action => ({
  type: actions.CANDIDATE_SIGN_IN_SUCCESS,
  payload: payload
});

const candidateSignInFailure = (error): Action => ({
  type: actions.CANDIDATE_SIGN_IN_FAILURE,
  error
});

const candidateSignInNeedConfirm = (): Action => ({
  type: actions.CANDIDATE_SIGN_IN_NEED_CONFIRM
});

const socialUrlRequest = (): Action => ({
  type: actions.SOCIAL_URL_REQUEST
});

const socialUrlSuccess = (url: string, social: string): Action => ({
  type: actions.SOCIAL_URL_SUCCESS,
  url,
  social
});

const socialUrlFailure = (): Action => ({
  type: actions.SOCIAL_URL_FAILURE
});

export const clearSignInState = (): Action => ({
  type: actions.CANDIDATE_SIGN_IN_CLEAR_STATE
});

function signInErrorHandler (dispatch: Dispatch, errorStatus: number, email: string, error) {
  if (error && errorStatus) {
    if (errorStatus === 403) {
      dispatch(candidateSignInNeedConfirm());
      localStorage.setItem('emailToConfirm', email);
    } else {
      dispatch(candidateSignInFailure(error ? error.error_description : 'something was wrong'));
    }
  }
}

function signInCandidateHandler (dispatch: Dispatch, data) {
  setToken(data.access_token, data.refresh_token);
  setNameAndLastNameAndEmail(data.firstName, data.lastName, data.email);
  setUserType('candidate');
  initRestParams();
  return dispatch(getCurrentCandidate())
    .then(user => {
      changeCurrentCandidateLocale(getLocalization());
      const payload = { fullyRegistered: user.fullyRegistered, unauthorizedUserUrl: getUnauthorizedUserRedirectPage() };
      resetUnauthorizedUserRedirectPage();
      dispatch(candidateSignInSuccess(payload));
    });
}

export function candidateSignIn (data: CandidateDto) {
  return (dispatch: Dispatch) => {
    dispatch(candidateSinInRequest());
    return signInCandidate(data)
      .then(response => dispatch(signInCandidateHandler(dispatch, response.data)))
      .catch(error => signInErrorHandler(dispatch, error.status, data.email, error.data));
  };
}

export function getSocialUrl (type: string) {
  return (dispatch: Dispatch) => {
    dispatch(socialUrlRequest());
    return getSocialAuthUrl(type)
      .then(response => dispatch(socialUrlSuccess(response.data, type)))
      .catch(() => dispatch(socialUrlFailure()));
  };
}
