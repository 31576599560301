// @flow
import React from 'react';

import { connectStore } from 'middleware/connect';

import { CounterWidget } from 'components/widgets';

import { Settings, stateToProps, dispatchToProps } from './match.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class MatchCircle extends Settings {

  componentDidMount () {
    this.loadMatchStatistic(this.props.currentUser.companyId);
  }

  componentWillUnmount () {
    this.props.resetMatchStats();
  }

  componentWillUpdate (props) {
    if (!this.props.currentUser.companyId) {
      this.loadMatchStatistic(props.currentUser.companyId);
    }
  }

  loadMatchStatistic (companyId: string) {
    if (companyId) {
      this.props.getMatchStatistic(companyId);
    }
  }

  render () {
    return (
      <CounterWidget
        title={this.props.t('portal.recruiter.match.circle.title')}
        value={this.props.total}
        circleTitle={this.props.t('portal.recruiter.match.circle.value')}/>
    );
  }

}
