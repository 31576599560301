// @flow
import React from 'react';

import { Trans } from 'react-i18next';
import ReactCrop from 'react-image-crop';

import { Button } from '../../buttons';

import { Settings } from './image-crop.setting';

export class ImageCrop extends Settings {

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop)
      .then(croppedImageUrl => {
        if (croppedImageUrl) {
          this.setState({ croppedImageUrl });
        }
      });
  };

  onCropChange = crop => {
    this.setState({ crop: crop });
  };

  async makeClientCrop (crop) {
    let croppedImageBlob;
    if (this.imageRef && crop.width && crop.height) {
      croppedImageBlob = await this.getCroppedImg(
        this.imageRef,
        crop,
        this.props.file.name
      );
      window.URL.revokeObjectURL(this.fileUrl);
      this.fileUrl = window.URL.createObjectURL(croppedImageBlob);
      return this.fileUrl;
    }
  }

  getCroppedImg (image, crop, fileName): File {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        resolve(blob);
      }, 'image/jpeg');
    });
  }

  saveImg () {
    this.getCroppedImg(this.imageRef, this.state.crop, this.props.file.name)
      .then(file => this.props.save(file));
  }

  render () {
    const { file } = this.props;
    const { crop, croppedImageUrl } = this.state;
    return (
      <div className="image-crop-modal">
        {file && (
          <div className="image-crop-container">
            <div className="cropping-img">
              <div className="cropping-img-module">
                <ReactCrop
                  src={file}
                  crop={crop}
                  onChange={this.onCropChange}
                  onComplete={this.onCropComplete}
                  onImageLoaded={this.onImageLoaded}/>
              </div>
            </div>
            {croppedImageUrl && (
              <div className="cropped-img">
                {this.props.showSquareIcon && (
                  <img alt="Crop" className="cropped-img-square" src={croppedImageUrl}/>
                )}
                {this.props.showRoundIcon && (
                  <img alt="Crop" className="cropped-img-round" src={croppedImageUrl}/>
                )}
              </div>
            )}
            {croppedImageUrl && (
              <div className="button-container">
                <Button
                  type="button"
                  className="btn accent"
                  onClick={() => this.saveImg(croppedImageUrl)}>
                  <Trans i18nKey="portal.candidate.account.buttons.save">
                    Save
                  </Trans>
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

}
