import * as actions from './software.constants';

import type { SoftwareState } from './software.type';

const initialState: SoftwareState = {
  query: '',
  fetching: false,
  softwareList: [],
  searchSoftware: [],
  hasMoreItems: true
};

export default (state = initialState, action): SoftwareState => {
  switch (action.type) {

    case actions.GET_SOFTWARE_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case actions.GET_SOFTWARE_FAILURE:
    case actions.SEARCH_SOFTWARE_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.GET_SOFTWARE_SUCCESS:
      return {
        ...state,
        fetching: false,
        softwareList: action.software
      };

    case actions.SEARCH_SOFTWARE_REQUEST:
      return {
        ...state,
        fetching: true,
        query: action.query,
        hasMoreItems: false,
        searchSoftware: action.query === state.query ? [...state.searchSoftware] : []
      };

    case actions.SEARCH_SOFTWARE_SUCCESS:
      return {
        ...state,
        fetching: false,
        searchSoftware: [ ...state.searchSoftware, ...action.software ],
        hasMoreItems: action.software.length > 0
      };

    case actions.SEARCH_SOFTWARE_RESET:
      return {
        ...state,
        searchSoftware: [],
        hasMoreItems: true
      };

    default:
      return state;
  }
};
