import { Component } from 'react';
import type { RouterHistory } from 'react-router-dom';

import { TransProps } from 'react-i18next';

import './profile-modal.scss';
import './profile-modal.responsive.scss';

type Props = {
  t?: TransProps,
  open: boolean,
  history: RouterHistory
};

type State = {
  activeNavLink: number,
  mobileView: boolean
}

export class Settings extends Component<Props, State> {

  static defaultProps = {
    open: false
  };

  state = {
    activeNavLink: 0,
    mobileView: false
  }

}
