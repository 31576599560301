export const GET_BLOCKED_USERS_REQUEST = 'GET_BLOCKED_USERS_REQUEST';
export const GET_BLOCKED_USERS_SUCCESS = 'GET_BLOCKED_USERS_SUCCESS';
export const GET_BLOCKED_USERS_FAILURE = 'GET_BLOCKED_USERS_FAILURE';

export const UNBLOCK_USER_REQUEST = 'UNBLOCK_USER_REQUEST';
export const UNBLOCK_USER_SUCCESS = 'UNBLOCK_USER_SUCCESS';
export const UNBLOCK_USER_FAILURE = 'UNBLOCK_USER_FAILURE';

export const BLOCK_USER_REQUEST = 'BLOCK_USER_REQUEST';
export const BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS';
export const BLOCK_USER_FAILURE = 'BLOCK_USER_FAILURE';
