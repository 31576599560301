// @flow
import { Component } from 'react';

import './languages-switcher.scss';

export type Props = {
  items: string[],
  value: string,
  change: (value: string) => void
};

export type State = {
  activeElement: number,
  listOpen: boolean,
  selectedItem: string,
}

export class Setting extends Component<Props, State> {

  static defaultProps: Props = {
    items: [],
    change: () => {},
    value: ''
  };

  state: State = {
    listOpen: false,
    activeElement: 0,
    selectedItem: ''
  };

}
