import * as actions from './skills.constants';

import type { SkillsState } from './skills.type';

const initialState: SkillsState = {
  query: '',
  skillsList: [],
  fetching: false,
  currentPage: 0,
  searchSkills: [],
  hasMoreItems: true,
  balanceBoardSkills: {}
};

export default (state = initialState, action): SkillsState => {
  switch (action.type) {

    case actions.GET_SKILLS_REQUEST:
      return {
        ...state,
        fetching: true,
        balanceBoardSkills: []
      };

    case actions.GET_SKILLS_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.GET_SKILLS_SUCCESS: {
      const balanceBoardSkills = { ...state.balanceBoardSkills };
      balanceBoardSkills[action.params.jobTitleId] = action.skills;
      return {
        ...state,
        fetching: false,
        balanceBoardSkills,
        skillsList: action.skills
      };
    }

    case actions.SEARCH_SKILLS_REQUEST:
      return {
        ...state,
        fetching: true,
        query: action.query,
        hasMoreItems: false,
        searchSkills: action.query === state.query ? [...state.searchSkills] : []
      };

    case actions.SEARCH_SKILLS_SUCCESS:
      return {
        ...state,
        fetching: false,
        searchSkills: [ ...state.searchSkills, ...action.payload.content ],
        currentPage: action.payload.number + 1,
        hasMoreItems: action.payload.content.length > 0
      };

    case actions.SEARCH_SKILLS_RESET:
      return {
        ...state,
        searchSkills: [],
        currentPage: 0,
        hasMoreItems: true
      };

    default:
      return state;
  }
};
