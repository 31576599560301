/* eslint padded-blocks: ["error", "never"] */
import React, { Component } from 'react';
import '../styles/semantic.min.css';
import '../styles/styles.css';
import axios from 'axios';
import { getUserId } from 'middleware/auth';
export class GDPRCandidatePosts extends Component {
  constructor() {
    super();
    this.state = {
      posts: []
    };
  }
  componentDidMount() {
    axios.get('/posts', {
      params: {
        candidateId: getUserId()
      }
    }).then(response => {
      this.setState({
        posts: response.data.content
      });
    })
      .catch(function (error) {
        console.log(error);
      });
  }
  render() {
    return (
      <div className="gdpr-main">
        <div className="ui container main-content">
          <h1 className="ui header main-title"> Candidate`s posts </h1>
          <a href="/information/gdpr">
            <button className="ui button back-link">Back To Main</button>
          </a>
          <h5 className="ui header"> Total Elements: {this.state.posts.length}</h5>
          <table className="ui single green line table">
            <thead>
              <tr>
                <th> Title </th>
                <th> Article </th>
                <th> Post Photos </th>
                <th> Create Date </th>
                <th> Is pActive </th>
              </tr>
              {this.state.posts.map(post => (
                <tr key={post.id}>
                  <td>{post.title}</td>
                  <td>{post.article}</td>
                  <td>
                    {post.postPhotos.map(photo => (
                      <a key={photo.id}>{photo.url}</a>
                    ))}
                  </td>
                  <td>{post.createDate}</td>
                  <td>true</td>
                </tr>
              ))}
            </thead>
            <tbody>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
