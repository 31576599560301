// @flow
import { Component } from 'react';

import { SelectModel } from './select.model';

import './select.scss';
import './select.responsive.scss';

export type Props = {
  label: string,
  items?: SelectModel[],
  error?: boolean,
  errorText?: string,
  isMulti?: boolean,
  change: () => void,
  placeholder?: string,
  defaultValue?: string | SelectModel[],
  maxSelectSize?: number,
  required?: boolean
};

type State = {
  selectedValue: SelectModel[],
  mobileView: boolean
}

export class Setting extends Component<Props, State> {

  static defaultProps: Props = {
    label: '',
    items: [],
    error: false,
    errorText: '',
    isMulti: true,
    placeholder: 'Select...',
    maxSelectSize: 5,
    defaultValue: '',
    change: () => {},
    mobileView: false,
    required: false
  };

  state: State = {
    selectedValue: []
  }

}
