import { combineReducers } from 'redux';

import skills from './skills/skills.reducer';
import general from './general/general.reducer';
import process from './process/process.reducer';
import checkMatch from './match/match.reducer';

export default combineReducers({
  skills,
  process,
  general,
  checkMatch
});
