import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import type { Match } from 'common/types/match';
import type { GlobalState } from 'common/types/global-state';

import moment from 'moment';
import { TransProps } from 'react-i18next';

import { ListDto } from 'middleware/dto/list';
import { BranchDto } from 'rest/branch/branch.dto';
import { JobTitleDto } from 'rest/job-title/job-title.dto';
import { SalaryRangeDto } from 'rest/salary-range/salary-range.dto';
import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';
import { JobRequirementsDto } from 'rest/candidates/job-requirements/job-requirements.dto';
import { JobRequirementsResponseDto } from 'rest/candidates/job-requirements/job-requirements.response.dto';

import { getAllUnits } from 'services/portal/units/units.actions';
import { getAllJobTitles } from 'services/portal/job-titles/job-titles.actions';
import { getAllBranches } from 'services/portal/recruiter/account/account.actions';
import {
  getCandidateBalanceBoard,
  createCandidateBalanceBoard,
  updateCandidateBalanceBoard
} from 'services/portal/candidate/balance-board/balance-board.actions';

import './general-info.scss';
import './general-info.responsive.scss';

export const jobTypes = [
  {
    id: 'Employee',
    selected: false
  },
  {
    id: 'Temporary',
    selected: false
  },
  {
    id: 'Trainee',
    selected: false
  }
];

export type Props = {
  t: TransProps,
  match: Match,
  units: ListDto[],
  next: () => void,
  skillsUpdate: boolean,
  getAllUnits: () => void,
  jobTitles: JobTitleDto[],
  branches: BranchDto[],
  getAllBranches: () => void,
  getAllJobTitles: () => void,
  currentCandidate: CurrentCandidateDto,
  balanceBoard: JobRequirementsResponseDto,
  getCandidateBalanceBoard: (candidateId: string) => void,
  updateCandidateBalanceBoard: (candidateId: string, data: JobRequirementsDto) => void,
  createCandidateBalanceBoard: (candidateId: string, data: JobRequirementsDto) => void
};

type State = {
  jobTypes: string[],
  units: ListDto[],
  salaryError: boolean,
  relocation: boolean,
  branches: ListDto[],
  jobTitles: ListDto[],
  availableFrom: moment,
  salary: SalaryRangeDto
}

export class Settings extends Component<Props, State> {

  static defaultProps = {
    skillsUpdate: false,
    next: () => {},
    save: () => {},
    getAllUnits: () => {},
    getAllBranches: () => {},
    getAllJobTitles: () => {},
    getRecruiterCompanyById: () => {},
    getCandidateBalanceBoard: () => {},
    updateCandidateBalanceBoard: () => {},
    createCandidateBalanceBoard: () => {}
  };

  state: State = {
    units: [],
    branches: [],
    jobTitles: [],
    relocation: true,
    salaryError: false,
    jobTypes: jobTypes,
    availableFrom: moment(),
    salary: new SalaryRangeDto({
      min: null,
      max: null,
      salaryType: 'MONTH'
    }),
    workingHoursPerMonth: 40
  }

}

export const stateToProps = (state: GlobalState) => ({
  units: state.portal.units.unitsList,
  fetching: state.portal.jobTitles.fetching,
  jobTitles: state.portal.jobTitles.jobTitlesList,
  branches: state.portal.recruiter.account.companies.branches,
  balanceBoard: state.portal.candidate.balanceBoard.balanceBoard,
  skillsUpdate: state.portal.candidate.balanceBoard.skillsUpdate,
  currentCandidate: state.portal.candidate.account.current.candidateData
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getAllUnits,
  getAllBranches,
  getAllJobTitles,
  getCandidateBalanceBoard,
  updateCandidateBalanceBoard,
  createCandidateBalanceBoard
}, dispatch);
