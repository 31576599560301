import { Component } from 'react';

import { UsersAlertsResponseDto } from 'rest/alerts/alerts.response.dto';

import './list.scss';
import './list.responsive.scss';

type Props = {
  closeModal: () => void,
  publishDate: string,
  notification: UsersAlertsResponseDto
};

export class Setting extends Component<Props> {

  static defaultProps = {
    closeModal: () => {},
    publishDate: '',
    notification: new UsersAlertsResponseDto()
  };

}
