// @flow
import React from 'react';

import { Trans } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { Card } from 'components/layouts';
import { Modal } from 'components/dialogs';
import { LinkButton } from 'components/buttons';
import { Tab, Tabs, TabHeader } from 'components/tabs';

import { JobOfferStatus } from 'rest/job-offers/job-offers.status.dto';

import { GeneralInfoTab } from './../general-info/general-info';
import { PriorityMatchTab } from './../priority-match/priority-match';
import { SkillsRequirementsTab } from './../skills-requirements/skills-requirements';

import type { Props } from './modal.setting';
import { Settings, dispatchToProps, stateToProps } from './modal.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class EditJobOfferModal extends Settings {

  componentWillUnmount () {
    this.props.resetJobOfferSkills();
    this.props.deleteSavedJobOfferData();
    setTimeout(() => this.changeJobOfferStatus(null, this.state.status), 500);
  }

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.currentJobOffer && this.props.currentJobOffer !== nextProps.currentJobOffer) {
      this.setState(() => {
        this.changeJobOfferStatus(nextProps.currentJobOffer.jobOfferStatus, 'DRAFT');
        return {
          status: nextProps.currentJobOffer.jobOfferStatus
        };
      });
    }
  }

  changeJobOfferStatus (prevStatus: string, newStatus: string) {
    if (prevStatus !== newStatus) {
      const jobOfferStatus = new JobOfferStatus();
      jobOfferStatus.status = newStatus;
      this.props.updateJobOfferStatus(
        this.props.currentUser.companyId,
        this.props.match.params.jobOfferId,
        jobOfferStatus
      );
    }
  }

  render () {
    const backLink: string = `/portal/recruiter/${this.props.match.params.path}`;
    return (
      <Modal closePath={backLink}>
        <div className="create-job-offer-modal">
          <Card>
            <PerfectScrollbar className="content">
              <LinkButton
                inline
                className="back-link"
                link={backLink}>
                <Trans i18nKey="portal.recruiter.balance-board.existing.back-to-list">
                  Back to job offer list
                </Trans>
              </LinkButton>
              <Tabs
                activeTabIndex={this.state.activeTab}
                activeTabChange={activeTab => this.setState({ activeTab })}>
                <Tab label={this.props.t('portal.recruiter.balance-board.job-offer.general.header')}>
                  <TabHeader
                    title={this.props.t('portal.recruiter.balance-board.job-offer.general.title')}
                    subTitle={this.props.t('portal.recruiter.balance-board.job-offer.general.description')}/>
                  <GeneralInfoTab
                    type={this.props.match.params.type}
                    jobOfferId={this.props.match.params.jobOfferId}
                    next={() => this.setState({ activeTab: this.state.activeTab + 1 })}/>
                </Tab>
                <Tab label={this.props.t('portal.recruiter.balance-board.job-offer.skills.header')}>
                  <SkillsRequirementsTab
                    jobOfferId={this.props.match.params.jobOfferId}
                    next={() => this.setState({ activeTab: this.state.activeTab + 1 })}
                    prev={() => this.setState({ activeTab: this.state.activeTab - 1 })}/>
                </Tab>
                <Tab label={this.props.t('portal.recruiter.balance-board.job-offer.priority.header')}>
                  <TabHeader title={this.props.t('portal.recruiter.balance-board.job-offer.priority.title')}/>
                  <PriorityMatchTab
                    type={this.props.match.params.type}
                    jobOfferId={this.props.match.params.jobOfferId}
                    prev={() => this.setState({ activeTab: this.state.activeTab - 1 })}
                    closeJobOffer={() => this.props.history.push('/portal/recruiter/job-offers')}/>
                </Tab>
              </Tabs>
            </PerfectScrollbar>
          </Card>
        </div>
      </Modal>
    );
  }

}
