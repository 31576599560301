import * as actions from './balance-board.constants';

import { Action, Dispatch } from 'redux';

import {
  getBalanceBoard,
  createBalanceBoard,
  updateBalanceBoard
} from 'rest/candidates/job-requirements/job-requirements.rest';

import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';
import { JobRequirementsDto } from 'rest/candidates/job-requirements/job-requirements.dto';

const createBalanceBoardRequest = (): Action => ({
  type: actions.CREATE_BALANCE_BOARD_REQUEST
});

const createBalanceBoardSuccess = (balanceBoard: JobOffersResponseDto): Action => ({
  type: actions.CREATE_BALANCE_BOARD_SUCCESS,
  balanceBoard
});

const createBalanceBoardFailure = (): Action => ({
  type: actions.CREATE_BALANCE_BOARD_FAILURE
});

const getBalanceBoardRequest = (): Action => ({
  type: actions.GET_BALANCE_BOARD_REQUEST
});

const getBalanceBoardSuccess = (balanceBoard: JobOffersResponseDto): Action => ({
  type: actions.GET_BALANCE_BOARD_SUCCESS,
  balanceBoard
});

const getBalanceBoardFailure = (error: string): Action => ({
  type: actions.GET_BALANCE_BOARD_FAILURE,
  error
});

const updateBalanceBoardRequest = (): Action => ({
  type: actions.UPDATE_BALANCE_BOARD_REQUEST
});

const updateBalanceBoardSuccess = (balanceBoard: JobOffersResponseDto): Action => ({
  type: actions.UPDATE_BALANCE_BOARD_SUCCESS,
  balanceBoard
});

const updateBalanceBoardFailure = (): Action => ({
  type: actions.UPDATE_BALANCE_BOARD_FAILURE
});

const resetBalanceBoardSuccess = (): Action => ({
  type: actions.RESET_BALANCE_BOARD_SUCCESS
});

export function resetBalanceBoard () {
  return dispatch => {
    dispatch(resetBalanceBoardSuccess());
  };
}

function createBalanceBoardFromGuest (dispatch, candidateId: string) {
  const data = JSON.parse(localStorage.getItem('ej_guest-bb'));
  return (dispatch: Dispatch) => {
    return createBalanceBoard(candidateId, data)
      .then(() => {
        localStorage.removeItem('ej_guest-bb');
        localStorage.removeItem('ej_active_job_offer_link');
        return dispatch(createBalanceBoardSuccess(data));
      })
      .catch(() => dispatch(createBalanceBoardFailure()));
  };
}

export function createCandidateBalanceBoard (candidateId: string, data: JobRequirementsDto) {
  return dispatch => {
    dispatch(createBalanceBoardRequest());
    return createBalanceBoard(candidateId, data)
      .then(() => dispatch(createBalanceBoardSuccess(data)))
      .catch(() => dispatch(createBalanceBoardFailure()));
  };
}

export function getCandidateBalanceBoard (candidateId: string, guestApply: boolean = false) {
  return dispatch => {
    dispatch(getBalanceBoardRequest());
    return getBalanceBoard(candidateId)
      .then(response => dispatch(getBalanceBoardSuccess(response.data)))
      .catch(() => {
        if (guestApply) {
          return dispatch(createBalanceBoardFromGuest(dispatch, candidateId));
        } else {
          return dispatch(getBalanceBoardFailure());
        }
      });
  };
}

export function updateCandidateBalanceBoard (candidateId: string, data: JobRequirementsDto) {
  return dispatch => {
    dispatch(updateBalanceBoardRequest());
    return updateBalanceBoard(candidateId, data)
      .then(() => dispatch(updateBalanceBoardSuccess(data)))
      .catch(() => dispatch(updateBalanceBoardFailure()));
  };
}
