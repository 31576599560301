// @flow
import React from 'react';

import { Setting } from './pwa.setting';
import { Trans } from 'react-i18next';

export class Pwa extends Setting {

  render () {
    return (
      <div className="pwa-container">
        <div className="pwa-left-container">
          <div className="pwa-title">
            <Trans i18nKey="information.works.pwa.title">
              Progressive Web Application. For candidates only.
            </Trans>
          </div>
          <div className="pwa-description">
            <Trans i18nKey="information.works.pwa.description">
              You can install our app on your mobile without bothering with an app store to find and download it.
            </Trans>
          </div>
          <div className="pwa-steps">
            <div className="pwa-step">
              <Trans i18nKey="information.works.pwa.points.0">
                When you’re on our website, click the browser menu icon in the corner.
              </Trans>
            </div>
            <div className="pwa-step">
              <Trans i18nKey="information.works.pwa.points.1">
                Select “<strong>Install ExcellentJobs</strong>” to install it.
              </Trans>
            </div>
            <div className="pwa-step">
              <Trans i18nKey="information.works.pwa.points.2">
                Click “<strong>Install</strong>” when the permission to install dialog appears.
              </Trans>
            </div>
            <div className="pwa-step">
              <Trans i18nKey="information.works.pwa.points.3">
                Start using ExcellentJobs application on your mobile phone.
              </Trans>
            </div>
          </div>
        </div>
        <div className="pwa-right-container">
          <img
            src="/images/information/pwa.png"
            alt="This pwa"/>
        </div>
      </div>
    );
  }

}
