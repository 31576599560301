import { CandidateDto } from 'rest/candidates/candidate.dto';

import * as actions from './candidates.constants';
import type { AllCandidateType } from './candidates.type';

const initialState: AllCandidateType = {
  buyLink: '',
  fetching: false,
  candidate: new CandidateDto(),
  candidates: null,
  currentPage: 0,
  hasMoreItems: true,
  totalElements: 0,
  candidatesFilter: null
};

export default function (state: AllCandidateType = initialState, action): AllCandidateType {
  switch (action.type) {
    case actions.GET_CANDIDATE_BY_ID_REQUEST:
    case actions.GET_ALL_CANDIDATES_REQUEST:
      return {
        ...state,
        fetching: true,
        hasMoreItems: false
      };

    case actions.BUY_CANDIDATE_DETAILS_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case actions.GET_CANDIDATE_BY_ID_SUCCESS:
      return {
        ...state,
        fetching: false,
        candidate: new CandidateDto(action.payload)
      };

    case actions.GET_ALL_CANDIDATES_SUCCESS: {
      const candidates: CandidateDto = state.candidates || [];
      return {
        ...state,
        fetching: false,
        candidates: [...candidates, ...action.payload.content.map(candidate => new CandidateDto(candidate))],
        currentPage: action.payload.number + 1,
        hasMoreItems: action.payload.content.length > 0,
        totalElements: action.payload.totalElements
      };
    }

    case actions.BUY_CANDIDATE_DETAILS_SUCCESS:
      return {
        ...state,
        buyLink: action.payload,
        fetching: false
      };

    case actions.GET_CANDIDATE_BY_ID_FAILURE:
    case actions.GET_ALL_CANDIDATES_FAILURE:
    case actions.BUY_CANDIDATE_DETAILS_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.SET_CANDIDATES_FILTER:
      return {
        ...state,
        candidates: [],
        currentPage: 0,
        candidatesFilter: action.filter,
        hasMoreItems: true
      };

    case actions.RESET_CANDIDATES_FILTER:
      return {
        ...state,
        candidates: null,
        currentPage: 0,
        candidatesFilter: null,
        hasMoreItems: true
      };

    case actions.CHANGE_BY_ID_CANDIDATE:
      return {
        ...state,
        candidate: action.candidate
      };

    case actions.RESET_CANDIDATES_LIST_SUCCESS:
      return {
        ...initialState
      };

    default:
      return state;
  }
}
