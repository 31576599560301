import * as actions from './skills.constants';

import { customSkillsMerge } from 'middleware/custom-skills-merge';

import { Dispatch, Action } from 'redux';

import {
  createCandidateSkills,
  updateCandidateSkills,
  getCandidateSkills
} from 'rest/candidates/skills/skills.rest';

import { SkillsDto } from 'rest/skill/skills.dto';
import { GuestApplyDto } from 'rest/candidates/skills/guest-apply.dto';
import { CandidateSkillsDto } from 'rest/candidates/skills/skills.dto';

const getCandidateSkillsRequest = (): Action => ({
  type: actions.GET_CANDIDATE_SKILLS_REQUEST
});

const getCandidateSkillsSuccess = (data: CandidateSkillsDto): Action => ({
  type: actions.GET_CANDIDATE_SKILLS_SUCCESS,
  payload: data
});

const getCandidateSkillsFailure = (): Action => ({
  type: actions.GET_CANDIDATE_SKILLS_FAILURE
});

const createCandidateSkillsRequest = (): Action => ({
  type: actions.CREATE_CANDIDATE_SKILLS_REQUEST
});

const createCandidateSkillsSuccess = (data): Action => ({
  type: actions.CREATE_CANDIDATE_SKILLS_SUCCESS,
  payload: data
});

const createCandidateSkillsFailure = (): Action => ({
  type: actions.CREATE_CANDIDATE_SKILLS_FAILURE
});

const updateCandidateSkillsRequest = (): Action => ({
  type: actions.UPDATE_CANDIDATE_SKILLS_REQUEST
});

const updateCandidateSkillsSuccess = (skills: CandidateSkillsDto): Action => ({
  type: actions.UPDATE_CANDIDATE_SKILLS_SUCCESS,
  skills
});

const updateCandidateSkillsFailure = (): Action => ({
  type: actions.UPDATE_CANDIDATE_SKILLS_FAILURE
});

const resetCandidateSkillsSuccess = (): Action => ({
  type: actions.RESET_CANDIDATE_SKILLS_SUCCESS
});

function saveGuestInteractionSkillsSuccess (dispatch: Dispatch, params: GuestApplyDto): Action {
  localStorage.setItem('ej_active_job_offer_link', params.jobOfferLink);
  localStorage.setItem('ej_guest-bb', JSON.stringify(params.balanceBoard));
  localStorage.setItem('ej_guest_candidate_skills', JSON.stringify(params.candidateSkills));
  return dispatch(getCandidateSkillsSuccess(params.candidateSkills));
}

export function resetCandidateSkills () {
  return (dispatch: Dispatch) => {
    dispatch(resetCandidateSkillsSuccess());
  };
}

function updateSkillsFromGuestApply (dispatch: Dispatch, candidateId: string, existingSkills: SkillsDto) {
  const guestApplySkills = JSON.parse(localStorage.getItem('ej_guest_candidate_skills'));
  const skills = customSkillsMerge(guestApplySkills, existingSkills.emptySkills ? {} : existingSkills);
  dispatch(updateCandidateSkillsById(candidateId, skills));
  return localStorage.removeItem('ej_guest_candidate_skills');
}

export function saveGuestInteractionSkills (params: GuestApplyDto) {
  return (dispatch: Dispatch) => {
    dispatch(saveGuestInteractionSkillsSuccess(dispatch, params));
  };
}

export function getCandidateSkillsById (candidateId: string, guestApply: boolean = false) {
  return (dispatch: Dispatch) => {
    dispatch(getCandidateSkillsRequest());
    return getCandidateSkills(candidateId)
      .then(response => {
        if (guestApply) {
          return dispatch(updateSkillsFromGuestApply(dispatch, candidateId, response.data));
        } else {
          return dispatch(getCandidateSkillsSuccess(response.data));
        }
      })
      .catch(() => dispatch(getCandidateSkillsFailure()));
  };
}

export function createCandidateSkillsById (candidateId: string, skills: CandidateSkillsDto) {
  return (dispatch: Dispatch) => {
    dispatch(createCandidateSkillsRequest());
    return createCandidateSkills(candidateId, skills)
      .then(response => dispatch(createCandidateSkillsSuccess(response.data)))
      .catch(() => dispatch(createCandidateSkillsFailure()));
  };
}

export function updateCandidateSkillsById (candidateId: string, skills: CandidateSkillsDto) {
  return (dispatch: Dispatch) => {
    dispatch(updateCandidateSkillsRequest());
    return updateCandidateSkills(candidateId, skills)
      .then(() => dispatch(updateCandidateSkillsSuccess(skills)))
      .catch(() => dispatch(updateCandidateSkillsFailure()));
  };
}
