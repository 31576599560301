import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';

import { EditJobOfferModalPreview } from 'containers/portal/recruiter/job-offer';

import { JobOffersListPage } from './list';
import { JobOfferModalPage } from './modal';
import { EditJobOfferModalPage } from './edit';

export class JobOffersPage extends Component {

  render () {
    return (
      <Fragment>
        <Route
          path="/portal/recruiter/job-offers"
          component={JobOffersListPage}/>
        <Route
          path="/portal/recruiter/job-offers/create"
          exact
          component={JobOfferModalPage}/>
        <Route
          path="/portal/recruiter/job-offers/:jobOfferId/preview"
          exact
          component={EditJobOfferModalPreview}/>
        <Route
          path="/portal/recruiter/job-offers/:jobOfferId/edit"
          exact
          component={EditJobOfferModalPage}/>

      </Fragment>
    );
  }

}
