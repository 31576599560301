// @flow
import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import type { Props } from './preview-item.setting';

import { Progress } from 'components/elements';

import { Setting, stateToProps, dispatchToProps } from './preview-item.setting';

@connect(stateToProps, dispatchToProps)
export class PreviewImageItem extends Setting {

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.imageInfo !== this.props.imageInfo) {
      this.props.finishImageLoad(nextProps.imageInfo);
    }
  }

  readImagePreview (file: File) {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.setState({
          imagePreview: reader.result
        }, () => this.props.uploadFile(file, 'image'));
      };
    }
  }

  render () {
    if (!this.state.imagePreview && this.props.image && !this.props.image.imageId) {
      this.readImagePreview(this.props.image);
    }
    const previewItemClasses = cn([{
      'preview-item': true,
      loading: this.props.loading
    }]);
    return (
      <div className={previewItemClasses}>
        <div
          className={`preview-item-img ${this.props.image.primary || !this.props.onClick ? 'active' : ''}`}
          onClick={this.props.onClick ? () => this.props.onClick(this.props.image) : null}>
          <img
            src={this.state.imagePreview || this.props.image.url}
            alt="Uploaded image"
          />
          {
            this.props.loading ? <Progress
              percent={45}
              className="tiny"
            /> : !!this.props.size && <span className="preview-item-loaded">{this.props.size} MB</span>
          }
        </div>
        <span
          onClick={this.props.closeIconClick}
          className="preview-item-close-icon">
          <img
            src="/images/shared/cancel-s.svg"
            alt="close"/>
        </span>
      </div>
    );
  }

}
