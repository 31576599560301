import React from 'react';
import { NavLink } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';

import { Card } from 'components/layouts';
import { modalTypes } from 'services/portal/modal/modal.constants';

import type { Props } from './menu.setting';

import { Settings, stateToProps, dispatchToProps } from './menu.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class Menu extends Settings {

  fetchBadgeMenuData (companyId: string) {
    this.initChatCounter();
    this.initNewMatchedCandidateCount();
    this.props.getUnreadConversation();
    this.props.getMatchStatistic(companyId);
  }

  componentWillUnmount () {
    clearInterval(this.state.chatInterval);
    clearInterval(this.state.matchedInterval);
  }

  componentWillReceiveProps (nextProps: Props) {
    const currentUser = nextProps.currentUser;
    if (currentUser && currentUser !== this.state.user) {
      this.setState({
        user: currentUser,
        avatar: currentUser.recruiterPhoto && currentUser.recruiterPhoto.url
      });
      this.fetchBadgeMenuData(nextProps.currentUser.companyId);
    }
  }

  initNewMatchedCandidateCount () {
    const timerTenMinutes: number = 600000;
    const matchedInterval: number = setInterval(() =>
      this.props.getMatchStatistic(this.props.currentUser.companyId), timerTenMinutes);
    this.setState({ matchedInterval });
  }

  initChatCounter () {
    const timer: number = 30000;
    const chatInterval: number = setInterval(() => this.props.getUnreadConversation(), timer);
    this.setState({ chatInterval });
  }

  render () {
    return (
      <Card>
        <div className="recruiter-profile-nav-container">
          <div className="profile-nav-header">
            <div className="person-container"
              onClick={() => this.props.showModal(modalTypes.COMPANY_PROFILE_MODAL)}>
              <div className="avatar-wrapper">
                <img
                  src={this.state.avatar ? this.state.avatar : '/images/shared/userpick.jpg'}
                  alt="avatar"/>
              </div>
              <div className="name-wrapper">
                <h3 className="user-name">
                  {
                    `${this.state.user.firstName} ${this.state.user.lastName}`
                  }
                </h3>
                <span className="user-role">
                  {this.props.company ? this.props.company.name : this.props.t('portal.recruiter.company')}
                </span>
              </div>
            </div>
            <div className="setting-container-link">
              <button
                onClick={() => this.props.showModal(modalTypes.COMPANY_PROFILE_MODAL)}
                className="icon-settings" />
            </div>
          </div>
          <div className="profile-nav-body">
            <div className="profile-nav-links recruiter">
              <NavLink
                to="/portal/recruiter/candidates"
                className="profile-nav-link"
                activeClassName="active">
                <div className="tile">
                  <span className="icon-search"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.recruiter.menu.candidates">
                    Candidate List
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                to="/portal/recruiter/balance-board"
                className="profile-nav-link"
                activeClassName="active">
                <div className="tile">
                  <span className="icon-balance-board"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.recruiter.menu.balance-board">
                    Balance Board
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                to="/portal/recruiter/job-offers"
                className="profile-nav-link"
                activeClassName="active">
                <div className="tile">
                  <span className="icon-applications"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.recruiter.menu.job-offers">
                    Job Offers
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                to="/portal/recruiter/match"
                className="profile-nav-link"
                activeClassName="active">
                <div className="tile">
                  <span className="icon-match"/>
                  {
                    this.props.newMatchedCandidateCount > 0 &&
                    <span className="badge">{this.props.newMatchedCandidateCount}</span>
                  }
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.recruiter.menu.match">
                    Match
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                to="/portal/recruiter/chat"
                className="profile-nav-link"
                activeClassName="active">
                <div className="tile">
                  <span className="icon-chat"/>
                  {
                    this.props.unreadMessages > 0 && <span className="badge">{this.props.unreadMessages}</span>
                  }
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.recruiter.menu.chat">
                    Chat
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                to="/portal/recruiter/calendar"
                className="profile-nav-link"
                activeClassName="active">
                <div className="tile">
                  <span className="icon-calendar"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.recruiter.menu.calendar">
                    Calendar
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                to="/portal/recruiter/favorites"
                className="profile-nav-link"
                activeClassName="active">
                <div className="tile">
                  <span className="icon-favorites"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.recruiter.menu.favorites">
                    Favorites
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                to="/portal/recruiter/follow"
                className="profile-nav-link"
                activeClassName="active">
                <div className="tile">
                  <span className="icon-follow"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.recruiter.menu.follow">
                    Follow
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                to="/portal/recruiter/statistics"
                className="profile-nav-link"
                activeClassName="active">
                <div className="tile">
                  <span className="icon-statistic"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.recruiter.menu.statistics">
                    Statistics
                  </Trans>
                </div>
              </NavLink>
              <NavLink
                to={`/portal/recruiter/${this.props.match.params.pageKey}/ask-me`}
                className="profile-nav-link"
                activeClassName="active">
                <div className="tile">
                  <span className="icon-ask-me"/>
                </div>
                <div className="tile-title">
                  <Trans i18nKey="portal.recruiter.menu.ask-me">
                    Ask Me
                  </Trans>
                </div>
              </NavLink>
              {/* <OnBoardingModal/> */}
            </div>
          </div>
        </div>
      </Card>
    );
  }

}
