// @flow
import React, { Fragment } from 'react';

import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { Tab, Tabs } from 'components/tabs';
import { SkillsList } from 'components/elements';
import { Button, IconButton, LinkButton } from 'components/buttons';
import { ModalAsideColumn, ModalMainColumn } from 'components/layouts/modal';

import { JobOfferCompanyInfo } from './company-info/company-info';
import { ApplyModal } from 'containers/portal/candidate/applications';
import { PreviewJobOfferInfo } from './job-offer-info/job-offer-info';
import { JobOfferApplyActions } from './apply-actions-button/apply-actions-button';
import { InteractSkillsList } from './interact-skills-list/interact-skills-list';
import { Modal, ModalCaption } from 'containers/portal/modal-container/index';

import { CompanyDto } from 'rest/companies/companies.dto';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

import type { Props } from './preview.setting';
import { dispatchToProps, Settings, stateToProps } from './preview.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class JobOfferModalPreview extends Settings {

  static getDerivedStateFromProps (props: Props) {
    return {
      company: props.company,
      jobOffer: props.jobOffer
    };
  }

  componentDidMount () {
    this.props.getCandidateSkillsById(this.props.currentCandidate.id);
  }

  componentDidUpdate (props: Props) {
    if (props.currentCandidate.id !== this.props.currentCandidate.id) {
      this.props.getCandidateSkillsById(props.currentCandidate.id);
    }
  }

  toggleCompanyFollow (companyId: string, followed: boolean) {
    const company = this.state.company;
    company.follow = !followed;
    if (followed) {
      this.setState({ company: company },
        () => this.props.deleteCompanyFromFollow(companyId, this.props.candidateId));
      toast.success(this.props.t('portal.candidate.notifications.following-company-delete'));
    } else {
      this.setState({ company: company },
        () => this.props.addCompanyToFollow(companyId, this.props.candidateId));
      toast.success(this.props.t('portal.candidate.notifications.following-company-add'));
    }
  }

  toggleJobOfferFavorite (jobOfferId: string, favorite: boolean) {
    const jobOffer = this.state.jobOffer;
    jobOffer.favorite = !favorite;
    if (favorite) {
      this.setState({ jobOffer: jobOffer },
        () => this.props.deleteJobFromFavorite(jobOfferId, this.props.candidateId));
      toast.success(this.props.t('portal.candidate.notifications.favorite-delete'));
    } else {
      this.setState({ jobOffer: jobOffer },
        () => this.props.addJobToFavorite(jobOfferId, this.props.candidateId));
      toast.success(this.props.t('portal.candidate.notifications.favorite-add'));
    }
  }

  closeDescription () {
    this.setState({ isDescriptionOpened: false });
  }

  closeInteraction () {
    this.setState({ interactWithSkills: false });
  }

  render () {
    const company: CompanyDto = this.state.company;
    const jobOfferSkills = this.props.jobOfferSkills;
    const jobOffer: JobOffersResponseDto = this.state.jobOffer;
    const isGuestUser: boolean = !this.props.recruiterId && !this.props.candidateId;

    return (
      <div className="job-offer-modal mobile-job-offer-modal">
        <div className="modal-columns-container mobile-modal-columns-container">
          <PerfectScrollbar className="mobile-jobs-list">
            <div className="back-btn-container">
              <LinkButton
                inline
                link={this.props.backLink}>
                <Trans i18nKey="portal.recruiter.match.preview.back">
                  Back to match list
                </Trans>
                <div className="mobile-close-btn">&times;</div>
              </LinkButton>
            </div>
            <div className={`modal-columns ${this.state.interactWithSkills ? 'blur' : ''}`}>
              <ModalMainColumn>
                <PreviewJobOfferInfo
                  jobOffer={jobOffer}
                  openDescription={() => this.setState({ isDescriptionOpened: true })}/>
                {
                  !this.state.interactWithSkills && <div className="full-info-container">
                    <Tabs
                      candidate
                      headClassName="mobile-job-offer">
                      <Tab
                        label={this.props.t('portal.candidate.job-offers.preview.tabs.competencies.title')}>
                        <div className="competencies-wrapper">
                          {
                            jobOfferSkills.isSkillNotEmpty && <Fragment>
                              <SkillsList
                                recruiter
                                skills={jobOfferSkills}
                                className="mobile-table mobile-table-list"/>
                              <div className="mobile-interact-button">
                                <Button
                                  candidate
                                  outlined
                                  onClick={() => this.setState({ interactWithSkills: true })}
                                  className="full-width interact-button">
                                  <Trans i18nKey="portal.candidate.job-offers.preview.mobile.interact-button">
                                    Interact with Skills
                                  </Trans>
                                </Button>
                              </div>
                            </Fragment>
                          }
                        </div>
                      </Tab>
                      <Tab label={this.props.t('portal.candidate.job-offers.preview.tabs.job-description.title')}>
                        <h4 className="company-description-caption">
                          <Trans i18nKey="portal.candidate.job-offers.preview.tabs.job-description.description">
                            Description of the job offer
                          </Trans>
                        </h4>
                        <div className="job-description">
                          {jobOffer.jobDescription
                            ? jobOffer.jobDescription
                            : this.props.t('portal.candidate.job-offers.preview.tabs.job-description.no-information')}
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                }
              </ModalMainColumn>
              {
                this.state.interactWithSkills && <div className="full-info-container">
                  <Tabs
                    candidate
                    headClassName="mobile-job-offer">
                    <Tab label={this.props.t('portal.candidate.job-offers.preview.tabs.competencies.title')}>
                      <div className="competencies-wrapper">
                        {
                          jobOfferSkills.isSkillNotEmpty && <Fragment>
                            <div className="mobile-job-offer-skills-list-hide">
                              <InteractSkillsList
                                jobOffer={jobOffer}
                                jobOfferPath={this.props.locationPath}
                                cancelAction={() => this.closeInteraction()}
                                jobOfferSkills={jobOfferSkills}
                                className="mobile-table-interact"/>
                            </div>
                            <div className="mobile-job-offer-skills-list-show">
                              <Modal
                                overlay
                                animation="fade"
                                open={this.state.interactWithSkills}
                                hideModalAvailable={false}
                                hideModal={() => this.closeInteraction()}
                                className="mobile-modal-job-offer">
                                <ModalCaption
                                  hideModal={() => this.closeInteraction()}
                                  closeOnOverlay={() => this.closeInteraction()}
                                  title={this.props.t('portal.candidate.job-offers.preview.mobile.modal.interaction')}/>
                                <PerfectScrollbar className="mobile-interact">
                                  <InteractSkillsList
                                    jobOffer={jobOffer}
                                    jobOfferPath={this.props.locationPath}
                                    cancelAction={() => this.closeInteraction()}
                                    jobOfferSkills={jobOfferSkills}
                                    className="mobile-table-interact"/>
                                </PerfectScrollbar>
                              </Modal>
                            </div>
                          </Fragment>
                        }
                      </div>
                    </Tab>
                    <Tab label={this.props.t('portal.candidate.job-offers.preview.tabs.job-description.title')}>
                      <h4 className="company-description-caption">
                        <Trans i18nKey="portal.candidate.job-offers.preview.tabs.job-description.description">
                          Description of the job offer
                        </Trans>
                      </h4>
                      <div className="job-description">
                        {
                          jobOffer.jobDescription
                            ? jobOffer.jobDescription
                            : this.props.t('portal.candidate.job-offers.preview.tabs.job-description.no-information')
                        }
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              }
              {
                company.branchId && <ModalAsideColumn>
                  <div className="actions-buttons">
                    <JobOfferApplyActions
                      guestRedirectLink="/auth/candidate/sign-up"
                      isJobOfferMatched={(jobOffer.matched && !jobOffer.candidateApplied) || this.props.jobOfferMatched}
                      isJobOfferApplied={jobOffer.candidateApplied}
                      applyAction={() => this.setState({ isApplyModalOpen: true })}
                      interactAction={() => this.setState({ interactWithSkills: true })}
                      isUserLoggedIn={this.props.recruiterId || this.props.candidateId}
                      isMobileJobOffer>
                      <IconButton
                        outlined
                        candidate
                        disabled={isGuestUser}
                        title={jobOffer.favorite ? <span>
                          <Trans i18nKey="portal.candidate.job-offers.preview.tabs.job-description.favorite.remove">
                            Remove from favorite
                          </Trans>
                        </span> : <span>
                          <Trans i18nKey="portal.candidate.job-offers.preview.tabs.job-description.favorite.add">
                            Add to favorite
                          </Trans>
                        </span>}
                        onClick={() => this.toggleJobOfferFavorite(jobOffer.jobOfferId, jobOffer.favorite)}
                        icon={jobOffer.favorite ? 'icon-favorites-on primary' : 'icon-favorites-off'}/>
                    </JobOfferApplyActions>
                  </div>
                  <div className="mobile-company-info-hide">
                    <JobOfferCompanyInfo
                      company={this.state.company}
                      hideCompanyInfo={this.state.interactWithSkills}
                      toggleCompanyFollow={isFollowed => this.toggleCompanyFollow(jobOffer.companyId, isFollowed)}/>
                  </div>
                  {
                    this.state.isDescriptionOpened && <div className="mobile-company-info-show">
                      <Modal
                        overlay
                        animation="fade"
                        open={this.state.isDescriptionOpened}
                        hideModalAvailable={false}
                        hideModal={() => this.closeDescription()}
                        className="mobile-modal-job-offer">
                        <ModalCaption
                          hideModal={() => this.closeDescription()}
                          closeOnOverlay={() => this.closeDescription()}
                          title={this.props.t('portal.candidate.job-offers.preview.mobile.modal.company')}/>
                        <PerfectScrollbar className="content">
                          <JobOfferCompanyInfo
                            company={this.state.company}
                            hideCompanyInfo={this.state.interactWithSkills}
                            toggleCompanyFollow={
                              isFollowed => this.toggleCompanyFollow(jobOffer.companyId, isFollowed)
                            }/>
                        </PerfectScrollbar>
                      </Modal>
                    </div>
                  }
                </ModalAsideColumn>
              }
            </div>
            <ApplyModal
              jobOfferId={jobOffer.jobOfferId}
              hideModal={() => this.setState({ isApplyModalOpen: false })}
              open={this.state.isApplyModalOpen}/>
          </PerfectScrollbar>
        </div>
      </div>
    );
  }

}
