import React from 'react';

import type { Props } from './checkbox-control.setting';
import { Setting } from './checkbox-control.setting';

export class CheckboxControl extends Setting {

  componentDidUpdate (prevProps: Props) {
    if (this.props.value !== prevProps.value) {
      this.setState({ checked: this.props.value });
    }
  }

  change () {
    this.props.change(!this.state.checked);
    this.setState({ checked: !this.state.checked });
  }

  render () {
    return (
      <label className="checkbox-inline form-label">
        <label className="checkbox-label">
          <input
            checked={this.state.checked}
            onChange={this.change.bind(this)}
            className="form-control"
            type="checkbox"/>
          <span className={this.props.className}/>
        </label>
        <span className={`checkbox-label-text ${this.props.className}`}>
          {this.props.label || this.props.children}
        </span>
      </label>
    );
  }

}
