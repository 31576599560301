// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { PaginationDto } from 'middleware/dto/pagination';

import { CandidateDto } from 'rest/candidates/candidate.dto';
import { ApplyJobOfferDto } from 'rest/job-offers/apply/apply.dto';
import { ApplicationResponseDto } from 'rest/candidates/applications-template/applications-template.response.dto';

import { applyToJobOffer } from 'services/portal/candidate/apply/apply.actions';
import {
  getCandidateApplications,
  createCandidateApplication
} from 'services/portal/candidate/applications/templates/templates.actions';

import './apply-modal.scss';
import './apply-modal.responsive.scss';

export type Props = {
  t?: TransProps,
  open: boolean,
  jobOfferId: string,
  hideModal: () => void,
  currentCandidate?: CandidateDto,
  templates?: ApplicationResponseDto[],
  getCandidateApplications: (candidateId: string, params: PaginationDto) => void,
  applyToJobOffer: (candidateId: string, jobOfferId: string, data: ApplyJobOfferDto) => void
}

type State = {
  body: string,
  subject: string,
  templateId: string,
  saveNewTemplate: boolean,
  createNewTemplate: boolean
}

export class Setting extends Component<Props, State> {

  static defaultProps = {
    jobOfferId: '',
    hideModal: () => {},
    getCandidateApplications: () => {},
    currentCandidate: new CandidateDto()
  };

  state: State = {
    body: '',
    subject: '',
    templateId: '',
    saveNewTemplate: true,
    createNewTemplate: false
  };

  maxTemplateCharLength: number = 1000;

}

export const stateToProps = (state: GlobalState) => ({
  templates: state.portal.candidate.applications.templates.content,
  currentCandidate: state.portal.candidate.account.current.candidateData
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  applyToJobOffer,
  getCandidateApplications,
  createCandidateApplication
}, dispatch);
