import { Action, Dispatch } from 'redux';

import { AnonymousParams } from 'rest/candidates/anonymous/anonymous.params';
import { contactAnonymousById, acceptContactById, declineContactById } from 'rest/candidates/anonymous/anonymous.rest';

import { getCandidateById } from 'services/portal/recruiter/candidate/all/candidates.actions';

import * as actions from './anonymous.constants';

const contactAnonymousRequest = (): Action => ({
  type: actions.CONTACT_ANONYMOUS_REQUEST
});

const contactAnonymousSuccess = (): Action => ({
  type: actions.CONTACT_ANONYMOUS_SUCCESS
});

const contactAnonymousFailure = (): Action => ({
  type: actions.CONTACT_ANONYMOUS_FAILURE
});

const acceptContactRequest = (): Action => ({
  type: actions.ACCEPT_CONTACT_REQUEST
});

const acceptContactSuccess = (): Action => ({
  type: actions.ACCEPT_CONTACT_SUCCESS
});

const acceptContactFailure = (): Action => ({
  type: actions.ACCEPT_CONTACT_FAILURE
});

const declineContactRequest = (): Action => ({
  type: actions.DECLINE_CONTACT_REQUEST
});

const declineContactSuccess = (): Action => ({
  type: actions.DECLINE_CONTACT_SUCCESS
});

const declineContactFailure = (): Action => ({
  type: actions.DECLINE_CONTACT_FAILURE
});

export const contactAnonymous = (candidateId: string) => (dispatch: Dispatch) => {
  dispatch(contactAnonymousRequest());
  return contactAnonymousById(candidateId)
    .then(() => {
      dispatch(contactAnonymousSuccess());
      dispatch(getCandidateById(candidateId));
    })
    .catch(() => dispatch(contactAnonymousFailure()));
};

export const acceptContact = (recruiterId: string, alertId: string) => (dispatch: Dispatch) => {
  dispatch(acceptContactRequest());
  const params = new AnonymousParams();
  params.alertId = alertId;
  return acceptContactById(recruiterId, params)
    .then(() => dispatch(acceptContactSuccess()))
    .catch(() => dispatch(acceptContactFailure()));
};

export const declineContact = (recruiterId: string, alertId: string) => (dispatch: Dispatch) => {
  dispatch(declineContactRequest());
  const params = new AnonymousParams();
  params.alertId = alertId;
  return declineContactById(recruiterId, params)
    .then(() => dispatch(declineContactSuccess()))
    .catch(() => dispatch(declineContactFailure()));
};
