// @flow
import axios, { AxiosPromise } from 'axios';

import { PostDto } from './post.dto';
import { PaginationDto } from 'middleware/dto';
import { PostsResponseDto } from './posts.response.dto';

export async function getCandidatePosts (params: PaginationDto): AxiosPromise<PostsResponseDto> {
  try {
    return await axios.get('/posts', {
      params
    });
  } catch (error) {
    throw error;
  }
}

export async function getCandidatePostById (candidateId: string): AxiosPromise<PostsResponseDto> {
  try {
    return await axios.get(`/posts/${candidateId}`);
  } catch (error) {
    throw error;
  }
}

export async function updateCandidatePost (postId: string, data: PostDto): Promise<string> {
  try {
    return await axios.put(`/posts/${postId}`, data);
  } catch (error) {
    throw error;
  }
}

export async function createCandidatePost (data: PostDto): AxiosPromise<string> {
  try {
    return await axios.post('/posts', data);
  } catch (error) {
    throw error.response;
  }
}

export async function deleteCandidatePost (postId: string): AxiosPromise<string> {
  try {
    return await axios.delete(`/posts/${postId}`);
  } catch (error) {
    throw error.response;
  }
}
