// @flow
import React from 'react';

import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';

import { connectStore } from 'middleware/connect';

import { Button } from 'components/buttons';

import { Modal, ModalCaption } from 'containers/portal/modal-container';

import type { Props } from './delete-member-modal.setting';
import { Setting, dispatchToProps } from './delete-member-modal.setting';

@connectStore(null, dispatchToProps, true)
export class DeleteMemberModal extends Setting {

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.deleteCompanyMemberSuccess !== this.state.deleteCompanyMemberSuccess && this.props.open) {
      this.props.hideModal();
    }
  }

  deleteTeamMember () {
    this.props.deleteRecruiterTeamMember(this.props.companyId, this.props.member.id);
    toast.success(this.props.t('portal.recruiter.notifications.recruiter-invite-delete'));
  }

  render () {
    const name = this.props.member.userInitials;
    return (
      <Modal
        overlay
        animation="fade"
        open={this.props.open}
        className="invites-modal"
        hideModalAvailable={false}
        hideModal={() => this.props.hideModal()}
        closeOnOverlay={() => this.props.hideModal()}>
        <ModalCaption
          hideModal={() => this.props.hideModal()}
          title={this.props.t('portal.recruiter.profile.team.delete.title')}/>
        <div className="invites-content">
          <p className="delete-user-description">
            <Trans i18nKey="portal.recruiter.profile.team.delete.description">
              User {{ name }} will be removed from the company profile.
            </Trans>
          </p>
          <p className="confirm-delete">
            <Trans i18nKey="portal.recruiter.profile.team.delete.confirm">
              Are you sure you want to delete?
            </Trans>
          </p>
          <Button
            onClick={() => this.deleteTeamMember()}
            recruiter>
            <Trans i18nKey="portal.recruiter.profile.team.delete.buttons.yes">
              Yes, Delete
            </Trans>
          </Button>
          <Button
            onClick={this.props.hideModal}
            grey>
            <Trans i18nKey="portal.recruiter.profile.team.delete.buttons.no">
              No, Leave
            </Trans>
          </Button>
        </div>
      </Modal>
    );
  }

}
