// @flow
import { Action, Dispatch } from 'redux';

import * as actions from './current.constants';

import { signOut, setToken } from 'middleware/auth';
import { setUserType, resetUserType } from 'middleware/user-type';

import { CandidateDto } from 'rest/candidates/candidate.dto';
import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';

import {
  changeCurrentCandidateLocale,
  getCurrentCandidate as getCurrent,
  disableOnBoardingVideo as disableVideo
} from 'rest/candidates/current/current.rest';
import { updateCandidate, socialRegister, activateCandidate, deleteCandidate } from 'rest/candidates/candidate.rest';

import { setLocalization } from 'middleware/localization';
import { initRestParams } from 'middleware/rest';
import { initMomentSettings } from 'middleware/moment';

import { getCandidateBalanceBoard } from 'services/portal/candidate/balance-board/balance-board.actions';
import { getCandidateSkillsById } from 'services/portal/candidate/skills/skills.actions';
import { setNameAndLastNameAndEmail } from '../../../../../middleware/auth';

const getCurrentCandidateRequest = (): Action => ({
  type: actions.GET_CURRENT_CANDIDATE_REQUEST
});

export const getCurrentCandidateSuccess = (candidate?: CandidateDto): Action => ({
  type: actions.GET_CURRENT_CANDIDATE_SUCCESS,
  candidate
});

const getCurrentCandidateFailure = (error: string): Action => ({
  type: actions.GET_CURRENT_CANDIDATE_FAILURE,
  error
});

const updateCurrentCandidateRequest = (): Action => ({
  type: actions.UPDATE_CURRENT_CANDIDATE_REQUEST
});

const updateCurrentCandidateSuccess = (candidate: CandidateDto): Action => ({
  type: actions.UPDATE_CURRENT_CANDIDATE_SUCCESS,
  candidate
});

const updateCurrentCandidateFailure = (): Action => ({
  type: actions.UPDATE_CURRENT_CANDIDATE_FAILURE
});

const candidateRegisterRequest = (): Action => ({
  type: actions.CANDIDATE_REGISTER_REQUEST
});

const candidateRegisterSuccess = (payload): Action => ({
  type: actions.CANDIDATE_REGISTER_SUCCESS,
  payload
});

const candidateRegisterFailure = (payload): Action => ({
  type: actions.CANDIDATE_REGISTER_FAILURE,
  payload
});

const candidateSocialRegisterRequest = (): Action => ({
  type: actions.CANDIDATE_SOCIAL_REGISTER_REQUEST
});

const candidateSocialRegisterSuccess = (): Action => ({
  type: actions.CANDIDATE_SOCIAL_REGISTER_SUCCESS
});

const candidateSocialRegisterFailure = (payload): Action => ({
  type: actions.CANDIDATE_SOCIAL_REGISTER_FAILURE,
  payload
});

const activateCandidateRequest = () => ({
  type: actions.ACTIVATE_CANDIDATE_REQUEST
});

const activateCandidateSuccess = () => ({
  type: actions.ACTIVATE_CANDIDATE_SUCCESS
});

const activateCandidateFailure = (error: string) => ({
  type: actions.ACTIVATE_CANDIDATE_FAILURE,
  error
});

const deleteCandidateRequest = () => ({
  type: actions.DELETE_CANDIDATE_REQUEST
});

const deleteCandidateSuccess = () => ({
  type: actions.DELETE_CANDIDATE_SUCCESS
});

const deleteCandidateFailure = (error: string) => ({
  type: actions.DELETE_CANDIDATE_FAILURE,
  error
});

const changeCurrentCandidateLocaleSuccess = () => ({
  type: actions.CHANGE_CURRENT_CANDIDATE_LOCALE_SUCCESS
});

const clearCurrentCandidateSuccess = () => ({
  type: actions.CLEAR_CURRENT_CANDIDATE_SUCCESS
});

const disableOnBoardingVideoRequest = () => ({
  type: actions.DISABLE_ON_BOARDING_VIDEO_REQUEST
});

const disableOnBoardingVideoSuccess = () => ({
  type: actions.DISABLE_ON_BOARDING_VIDEO_SUCCESS
});

const disableOnBoardingVideoFailure = (error: string) => ({
  type: actions.DISABLE_ON_BOARDING_VIDEO_FAILURE,
  error
});

function activateSocialCandidate (dispatch: Dispatch) {
  getCurrent()
    .then(response => {
      dispatch(activateCurrentCandidate(response.data.id));
      return localStorage.removeItem('socialDetails');
    });
}

function resolveDeleteCandidateSuccess (dispatch: Dispatch) {
  dispatch(deleteCandidateSuccess());
  signOut();
  return dispatch(clearCurrentCandidate());
}

function getCurrentCandidateHandler (dispatch: Dispatch, response) {
  const balanceBoard = JSON.parse(localStorage.getItem('ej_guest-bb'));
  const candidateSkills = JSON.parse(localStorage.getItem('ej_guest_candidate_skills'));
  if (balanceBoard && candidateSkills) {
    dispatch(getCandidateBalanceBoard(response.id, true));
    dispatch(getCandidateSkillsById(response.id, true));
  }
  return dispatch(getCurrentCandidateSuccess(response));
}

export function clearCurrentCandidate () {
  return (dispatch: Dispatch) => dispatch(clearCurrentCandidateSuccess());
}

function currentCandidateErrorHandler (error) {
  return dispatch => {
    if (error && error.status === 422) {
      signOut();
      initRestParams();
      dispatch(clearCurrentCandidate());
      return dispatch(getCurrentCandidateFailure(error ? error.message : ''));
    }
  };
}

export function getCurrentCandidate () {
  return (dispatch: Dispatch) => {
    dispatch(getCurrentCandidateRequest());
    return getCurrent()
      .then(response => {
        dispatch(getCurrentCandidateHandler(dispatch, response.data));
        return response.data;
      })
      .catch(error => dispatch(currentCandidateErrorHandler(error.response ? error.response.data : '')));
  };
}

export const changeCurrentLocalization = (lang: string, reload = true) => {
  return (dispatch: Dispatch) => {
    dispatch(changeCurrentCandidateLocaleSuccess());
    return changeCurrentCandidateLocale(lang)
      .then(() => {
        setLocalization(lang);
        initRestParams();
        initMomentSettings();
        if (reload) {
          window.location.reload();
        }
      });
  };
};

export function registerCandidate (candidateId: string, data: CandidateDto) {
  return (dispatch: Dispatch) => {
    dispatch(candidateRegisterRequest());
    return updateCandidate(candidateId, data)
      .then(data => dispatch(candidateRegisterSuccess(data)))
      .catch(error => dispatch(candidateRegisterFailure(error)));
  };
}

export function registerFromSocial (data: CandidateDto) {
  return (dispatch: Dispatch) => {
    dispatch(candidateSocialRegisterRequest());
    return socialRegister(data)
      .then(response => {
        if (response.data) {
          setToken(response.data.accessToken, response.data.refreshToken);
          setUserType('candidate');
          dispatch(candidateSocialRegisterSuccess());
          return activateSocialCandidate(dispatch);
        }
      })
      .catch(error => dispatch(candidateSocialRegisterFailure(error)));
  };
}

export function updateCurrentCandidate (id: string, data: CurrentCandidateDto) {
  return (dispatch: Dispatch) => {
    setNameAndLastNameAndEmail(data.firstName, data.lastName, data.email);
    console.log('Setting name and last name and email');
    dispatch(updateCurrentCandidateRequest());
    return updateCandidate(id, data)
      .then(() => dispatch(updateCurrentCandidateSuccess(data)))
      .catch(() => dispatch(updateCurrentCandidateFailure()));
  };
}

export const activateCurrentCandidate = (candidateId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(activateCandidateRequest());
    return activateCandidate(candidateId)
      .then(() => dispatch(dispatch(activateCandidateSuccess())))
      .catch(error => dispatch(activateCandidateFailure(error)));
  };
};

export const deleteCurrentCandidate = (candidateId: string) => {
  return (dispatch: Dispatch) => {
    resetUserType();
    dispatch(deleteCandidateRequest());
    return deleteCandidate(candidateId)
      .then(() => dispatch(resolveDeleteCandidateSuccess(dispatch)))
      .catch(error => dispatch(deleteCandidateFailure(error)));
  };
};

export const disableOnBoardingVideo = () => {
  return (dispatch: Dispatch) => {
    dispatch(disableOnBoardingVideoRequest());
    return disableVideo()
      .then(() => dispatch(disableOnBoardingVideoSuccess()))
      .catch(error => dispatch(disableOnBoardingVideoFailure(error)));
  };
};
