import React from 'react';

import { Settings } from './spinner.setting';

export class Spinner extends Settings {

  render () {
    return this.props.show ? <div className="loader-container"/> : null;
  }

}
