import * as actions from './following.constants';

import { Action, Dispatch } from 'redux';

import { getAllJobOfferRest } from 'rest/public/job-offers/job-offers.rest';
import { getFollowingStatistic } from 'services/portal/candidate/jobs/follow/stats/stats.actions';
import {
  addCompanyToFollowRest,
  deleteCompanyFromFollowRest,
  getLocationsFollowedCompanies
} from 'rest/candidates/current/follow/follow.rest';

import { JobOffersParams } from 'rest/public/job-offers/job-offers.params';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';
import { LocationsDto } from 'rest/companies/job-offers-matches/matched-candidates/locations/locations.dto';

const addCompanyToFollowRequest = (): Action => ({
  type: actions.ADD_COMPANY_TO_FOLLOWING_REQUEST
});

const addCompanyToFollowSuccess = (): Action => ({
  type: actions.ADD_COMPANY_TO_FOLLOWING_SUCCESS
});

const addCompanyToFollowFailure = (): Action => ({
  type: actions.ADD_COMPANY_TO_FOLLOWING_FAILURE
});

const deleteCompanyFromFollowRequest = (): Action => ({
  type: actions.DELETE_COMPANY_FROM_FOLLOWING_REQUEST
});

const deleteCompanyFromFollowSuccess = (data): Action => ({
  type: actions.DELETE_COMPANY_FROM_FOLLOWING_SUCCESS,
  payload: data
});

const deleteCompanyFromFollowFailure = (): Action => ({
  type: actions.DELETE_COMPANY_FROM_FOLLOWING_FAILURE
});

const getCompaniesFollowedLocationsRequest = (): Action => ({
  type: actions.GET_FOLLOWING_COMPANIES_LOCATIONS_REQUEST
});

const getCompaniesFollowedLocationsSuccess = (locations: LocationsDto = []): Action => ({
  type: actions.GET_FOLLOWING_COMPANIES_LOCATIONS_SUCCESS,
  locations
});

const getCompaniesFollowedLocationsFailure = (error: string): Action => ({
  type: actions.GET_FOLLOWING_COMPANIES_LOCATIONS_FAILURE,
  error
});

const getFollowedJobOffersRequest = (): Action => ({
  type: actions.GET_FOLLOWING_JOB_OFFERS_REQUEST
});

const getFollowedJobOffersSuccess = (data: JobOffersResponseDto): Action => ({
  type: actions.GET_FOLLOWING_JOB_OFFERS_SUCCESS,
  payload: data
});

const getFollowedJobOffersFailure = (): Action => ({
  type: actions.GET_FOLLOWING_JOB_OFFERS_FAILURE
});

const resetJobOffersFollowsSuccess = (): Action => ({
  type: actions.RESET_FOLLOWING_JOB_OFFER_SUCCESS
});

const changeFollowFilterSuccess = (params: JobOffersParams = new JobOffersParams()): Action => ({
  type: actions.CHANGE_FOLLOWING_FILTERS_SUCCESS,
  params
});

export function changeFollowingFilter (params: JobOffersParams) {
  return (dispatch: Dispatch) => dispatch(changeFollowFilterSuccess(params));
}

export function resetAllJobOffersFollowing () {
  return (dispatch: Dispatch) => {
    dispatch(resetJobOffersFollowsSuccess());
  };
}

export function addCompanyToFollow (companyId: string, candidateId: string) {
  return (dispatch: Dispatch) => {
    dispatch(addCompanyToFollowRequest());
    return addCompanyToFollowRest(companyId)
      .then(() => {
        dispatch(addCompanyToFollowSuccess());
        dispatch(getFollowingStatistic(candidateId));
      })
      .catch(() => dispatch(addCompanyToFollowFailure()));
  };
}

export function deleteCompanyFromFollow (companyId: string, candidateId: string) {
  return (dispatch: Dispatch) => {
    dispatch(deleteCompanyFromFollowRequest());
    return deleteCompanyFromFollowRest(companyId)
      .then(() => {
        dispatch(deleteCompanyFromFollowSuccess(companyId));
        dispatch(getFollowingStatistic(candidateId));
      })
      .catch(() => dispatch(deleteCompanyFromFollowFailure()));
  };
}

export function getFollowingJobOffers (params: JobOffersParams = new JobOffersParams()) {
  params.followingType = 'FOLLOWED';
  params.extraFields = ['favorite'];
  return (dispatch: Dispatch) => {
    dispatch(getFollowedJobOffersRequest());
    return getAllJobOfferRest(params)
      .then(response => dispatch(getFollowedJobOffersSuccess(response.data)))
      .catch(() => dispatch(getFollowedJobOffersFailure()));
  };
}

export function getFollowingCompaniesLocations () {
  return (dispatch: Dispatch) => {
    dispatch(getCompaniesFollowedLocationsRequest());
    return getLocationsFollowedCompanies()
      .then(response => dispatch(getCompaniesFollowedLocationsSuccess(response.data)))
      .catch(error => dispatch(getCompaniesFollowedLocationsFailure(error.response.data.message)));
  };
}
