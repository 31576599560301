import React, { Fragment, Component } from 'react';

import { ToastContainer } from 'react-toastify';

import { ToastifyClose } from './../close/close';

import 'react-toastify/dist/ReactToastify.min.css';

import './container.scss';

export class ToastifyContainer extends Component {

  render () {
    return (
      <Fragment>
        <ToastContainer
          position="bottom-left"
          closeButton={<ToastifyClose/>}
          hideProgressBar={true}/>
      </Fragment>
    );
  }

}
