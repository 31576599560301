import { Component } from 'react';

import './control.scss';
import './control.responsive.scss';

type Props = {
  children?: Node
};

export class Setting extends Component<Props> {}
