// @flow
import { combineReducers } from 'redux';

import cv from './cv/cv.reducer';
import jobs from './jobs/jobs.reducer';
import blog from './blog/blog.reducer';
import match from './match/match.reducer';
import skills from './skills/skills.reducer';
import account from './account/account.reducer';
import applications from './applications/applications.reducer';
import balanceBoard from './balance-board/balance-board.reducer';
import applicationsStats from './applications/stats/stats.reducer';

export default combineReducers({
  cv,
  jobs,
  blog,
  match,
  skills,
  account,
  applications,
  balanceBoard,
  applicationsStats
});
