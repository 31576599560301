import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { requestSkill, resetSuggestedSkill } from 'services/portal/skills/request/request.actions';

import './suggest-skill-modal.scss';

export type Props = {
  t: TransProps,
  open: boolean,
  errorCode: number,
  candidate: boolean,
  recruiter: boolean,
  skillType: string,
  close: () => void,
  className: string
}

type State = {
  name: string,
  errorCode: number,
  showSuccessMessage: boolean
}

export class Settings extends Component<Props, State> {

  static defaultProps: Props = {
    open: false,
    errorCode: NaN,
    candidate: false,
    recruiter: false,
    close: () => {},
    className: ''
  };

  state = {
    name: '',
    errorCode: NaN,
    showSuccessMessage: false
  }

}

export const stateToProps = (state: GlobalState) => ({
  errorCode: state.portal.requestSkill.error,
  fetching: state.portal.requestSkill.fetching,
  requestedSuccess: state.portal.requestSkill.requestedSuccess
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  requestSkill,
  resetSuggestedSkill
}, dispatch);
