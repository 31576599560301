import { Component } from 'react';

import { SkillItemDto } from 'rest/skill/skill-item.dto';

type Props = {
  skill: SkillItemDto,
  hideSkillLevel?: boolean,
  change: (value: string) => void
};

export class Settings extends Component<Props> {

  static defaultProps: Props = {
    hideSkillLevel: false,
    change: () => {}
  };

}
