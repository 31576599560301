export const GET_CONVERSATION_REQUEST = 'GET_CONVERSATION_REQUEST';
export const GET_CONVERSATION_SUCCESS = 'GET_CONVERSATION_SUCCESS';
export const GET_CONVERSATION_FAILURE = 'GET_CONVERSATION_FAILURE';

export const GET_CONVERSATIONS_REQUEST = 'GET_CONVERSATIONS_REQUEST';
export const GET_CONVERSATIONS_SUCCESS = 'GET_CONVERSATIONS_SUCCESS';
export const GET_CONVERSATIONS_FAILURE = 'GET_CONVERSATIONS_FAILURE';

export const RESET_CONVERSATIONS_SUCCESS = 'RESET_CONVERSATIONS_SUCCESS';

export const CREATE_CONVERSATIONS_REQUEST = 'CREATE_CONVERSATIONS_REQUEST';
export const CREATE_CONVERSATIONS_SUCCESS = 'CREATE_CONVERSATIONS_SUCCESS';

export const GET_UNREAD_CONVERSATION_SUCCESS = 'GET_UNREAD_CONVERSATION_SUCCESS';
export const RESET_UNREAD_CONVERSATION_SUCCESS = 'RESET_UNREAD_CONVERSATION_SUCCESS';

export const BLOCK_CONVERSATION_SUCCESS = 'BLOCK_CONVERSATION_SUCCESS';
