// @flow
import { connect } from 'react-redux';
import React, { Fragment } from 'react';

import InfiniteScroll from 'react-infinite-scroller';

import { Spinner } from 'components/elements';
import { ElementsList, JobOfferRow } from 'components/elements-list';

import { JobOfferDto } from 'rest/job-offers/job-offers.dto';
import { JobOffersParams } from 'rest/public/job-offers/job-offers.params';

import { PlaceholderList } from './../placeholder/placeholder';

import { stateToProps, dispatchToProps, Setting } from './list.setting';

@connect(stateToProps, dispatchToProps)
export class JobOffersList extends Setting {

  componentWillUnmount () {
    this.props.resetJobOffersList();
  }
  loadJobOffersList (page: number = 0) {
    const params = new JobOffersParams(this.props.jobOffersFilter);
    if (typeof this.props.history.location.state !== 'undefined' && this.props.history.location.state.company) {
      params.company = this.props.history.location.state.company;
    }
    params.page = page;
    this.props.getJobOffers(params);
  }

  openJobOffer (selectedJobOfferId: string) {
    this.props.history.push(`/portal/guest/jobs/${selectedJobOfferId}/preview`);
  }

  initJobOffersList (): HTMLElement {
    return this.props.jobOffers.map((jobOffer: JobOfferDto, index) => (
      <JobOfferRow
        key={index}
        onClick={() => this.openJobOffer(jobOffer.jobOfferId)}
        jobOffer={jobOffer}
        searchedCompany={this.props.history.location.state ? this.props.history.location.state.company : false}
      >
      </JobOfferRow>
    ));
  }

  initEmptyList () : HTMLElement {
    return !this.props.jobOffers.length ? (
      <PlaceholderList/>
    ) : null;
  }

  render () {
    return (
      <Fragment>
        <InfiniteScroll
          useWindow={false}
          threshold={100}
          pageStart={0}
          hasMore={this.props.hasMoreItems}
          loadMore={() => this.loadJobOffersList(this.props.currentPage)}>
          <ElementsList>{this.initJobOffersList()}</ElementsList>
          { this.initEmptyList() }
        </InfiniteScroll>
        <Spinner show={this.props.fetching}/>
      </Fragment>
    );
  }

}
