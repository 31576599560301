// @flow
import React, { Component } from 'react';

import { FreePayment } from 'containers/portal/recruiter/account/settings';

export class FreePaymentPage extends Component {

  render () {
    return (
      <div className="payment-page-container">
        <FreePayment/>
      </div>
    );
  }

}
