import * as actions from './stats.constants';

import { getCompanyJobOffersStats } from 'rest/companies/job-offers/job-offers.rest';
import { StatsDto } from 'rest/stats/stats.dto';

const getCompanyJobOffersStatsRequest = () => ({
  type: actions.GET_COMPANY_JOB_OFFERS_STATS_REQUEST
});

const getCompanyJobOffersStatsSuccess = (stats: StatsDto[] = [], total: number = 0) => ({
  type: actions.GET_COMPANY_JOB_OFFERS_STATS_SUCCESS,
  stats: stats,
  total: total
});

const getCompanyJobOffersStatsFailure = (error: string) => ({
  type: actions.GET_COMPANY_JOB_OFFERS_STATS_FAILURE,
  error
});

const resetCompanyJobOffersStatsSuccess = data => ({
  type: actions.RESET_COMPANY_JOB_OFFERS_STATS_SUCCESS,
  payload: data
});

export const resetJobOffersStatistic = () => {
  return dispatch => {
    dispatch(resetCompanyJobOffersStatsSuccess());
  };
};

export const getAllCompanyJobOffersStats = (companyId: string) => {
  return dispatch => {
    dispatch(getCompanyJobOffersStatsRequest());
    return getCompanyJobOffersStats(companyId)
      .then(response => {
        dispatch(getCompanyJobOffersStatsSuccess(response.data.jobOfferStatCountDtos, response.data.total));
      })
      .catch(error => dispatch(getCompanyJobOffersStatsFailure(error)));
  };
};
