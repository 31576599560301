import React, { Fragment, Component } from 'react';
import { Route } from 'react-router-dom';

import { MatchedJobsListPage } from './list';
import { MatchedJobOfferPreviewPage } from './preview';

export class MatchPage extends Component {

  render () {
    return (
      <Fragment>
        <Route
          path="/portal/candidate/match"
          component={MatchedJobsListPage}/>
        <Route
          path="/portal/candidate/match/:jobOfferId/preview"
          exact
          component={MatchedJobOfferPreviewPage}/>
      </Fragment>
    );
  }

}
