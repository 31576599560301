import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { SkillItemDto } from 'rest/skill/skill-item.dto';

import {
  publishNewJobOffer,
  updateCompanyJobOffer,
  getCompanyJobOfferSkills,
  updateCompanyJobOfferSkills
} from 'services/portal/recruiter/job-offer/job-offer.actions';

import { getAllCompanyJobOffers } from 'services/portal/recruiter/account/job-offer/job-offer.actions';

import './priority-match.scss';

export type Props = {
  t?: TransProps,
  prev: () => void,
  jobOfferId?: string
};

type State = {
  count: number,
  active: number,
  matchPoints: number,
  minPointsError: boolean,
  maxPointsError: boolean,
  isMatchModalOpen: boolean,
  documents: SkillItemDto[],
  createSkillSuccess: boolean,
  requiredSkillsCount: boolean,
  createNewJobOffer: () => void,
  languageSkills: SkillItemDto[],
  softwareSkills: SkillItemDto[],
  professionalSkills: SkillItemDto[]
}

export class Settings extends Component<Props, State> {

  static defaultProps: Props = {
    prev: () => {}
  };

  state: State = {
    count: 0,
    active: 1,
    matchPoints: 75,
    documents: [],
    softwareSkills: [],
    languageSkills: [],
    minPointsError: false,
    maxPointsError: false,
    requiredSkillsCount: 0,
    isMatchModalOpen: false,
    professionalSkills: [],
    createSkillSuccess: false
  };

  maxPointsCount: number = 100;
  minRequiredSkillCount: number = 1;
  maxRequiredSkillsCount: number = 3;

}

export const stateToProps = (state: GlobalState) => ({
  skills: state.portal.recruiter.jobOffer.skills.content,
  jobOffer: state.portal.recruiter.jobOffer.general.currentJobOffer,
  processJobOfferId: state.portal.recruiter.jobOffer.process.jobOffer.id,
  companyId: state.portal.recruiter.account.current.currentUser.companyId,
  createSkillSuccess: state.portal.recruiter.jobOffer.process.createSkillSuccess,
  publishJobOfferSuccess: state.portal.recruiter.jobOffer.process.publishJobOfferSuccess,
  updateJobOfferSuccess: state.portal.recruiter.jobOffer.skills.updateJobOfferSuccess
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  publishNewJobOffer,
  getAllCompanyJobOffers,
  updateCompanyJobOffer,
  getCompanyJobOfferSkills,
  updateCompanyJobOfferSkills
}, dispatch);
