import React from 'react';

import moment from 'moment';
import * as fileSize from 'filesize';
import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { ChatParticipantDto } from 'rest/chats/chat-participant.dto';

import { ChatAvatar } from './../avatar/avatar';

import { Settings } from './message.setting';

@connectTranslation()
export class ChatMessage extends Settings {

  getParticipant (userId: string) {
    return this.props.participants.find(participant => participant.userId === userId);
  }

  render () {
    const user = this.getParticipant(this.props.message.userId) || new ChatParticipantDto();

    return (
      <div className={`message ${this.props.className}`}>
        <div className="mobile-chat-avatar-wrapper">
          <ChatAvatar participant={user}/>
        </div>
        <div className="body">
          <div className="name">
            {user.fullName}
            <div className="mobile-time">{moment(this.props.message.date).parseZone().fromNow()}</div>
          </div>
          <div className="text">
            {this.props.message.message}
            {
              this.props.message.attachment.size && <div className="attachment">
                <a
                  href={this.props.message.attachment.link}
                  className="file">
                  <span className="title">
                    {this.props.message.attachment.nameFile}
                  </span>
                  <div className="size">
                    {this.props.message.attachment.size ? fileSize(this.props.message.attachment.size) : ''}
                  </div>
                </a>
              </div>
            }
          </div>
        </div>
        <div className="date-time">
          <div className="time mobile-time-hide">{moment(this.props.message.date).parseZone().fromNow()}</div>
          <div className="status">
            {
              this.props.message.read && <Trans i18nKey="portal.recruiter.chat.list.status.read">
                Seen
              </Trans>
            }
            {
              !this.props.message.read && <Trans i18nKey="portal.recruiter.chat.list.status.unread">
                Unseen
              </Trans>
            }
          </div>
        </div>
      </div>
    );
  }

}
