import React from 'react';

import Select from 'react-select';

import { SelectModel } from './select.model';

import type { Props } from './select.setting';
import { Setting } from './select.setting';

export class MultiSelect extends Setting {

  componentDidMount () {
    this.listenWindowWidth();
    window.addEventListener('resize', () => this.listenWindowWidth());
  }

  componentWillReceiveProps (nextProps: Props) {
    const selectedValue = this.props.isMulti ? this.initMultiSelect(nextProps) : this.initSingleSelect(nextProps);
    this.setState({ selectedValue });
  }

  componentWillUnmount () {
    window.removeEventListener('resize', () => this.listenWindowWidth());
  }

  listenWindowWidth () {
    this.setState({ mobileView: window.innerWidth < 993 });
  }

  initMultiSelect (nextProps: Props): SelectModel[] {
    if (nextProps.defaultValue.length) {
      return nextProps.defaultValue.map(item => new SelectModel(item));
    } else {
      return [];
    }
  }

  initSingleSelect (nextProps: Props): SelectModel {
    if (nextProps.defaultValue && nextProps.items.length) {
      const item = nextProps.items.find(item => item.id === nextProps.defaultValue);
      return new SelectModel(item);
    } else {
      return null;
    }
  }

  selectItem (selectedValue: SelectModel) {
    this.setState({ selectedValue });
    this.props.change(selectedValue || new SelectModel());
  }

  selectItems (selectedValue) {
    if (selectedValue.length <= this.props.maxSelectSize) {
      this.setState({ selectedValue });
      this.props.change(selectedValue);
    }
  }

  render () {
    const { selectedValue } = this.state;
    const {
      label, isMulti, maxSelectSize, placeholder, mobileView, error, errorText, required, defaultValue
    } = this.props;

    const options = this.props.items.map(item => new SelectModel(item));
    return (
      <label className="form-label">
        <span className="form-label-title">{label}</span>
        {
          error ? <span className="form-label-error">
            {errorText || 'Field is required'}
          </span> : null
        }
        <Select
          name="colors"
          isMulti={isMulti}
          maxSelectSize={maxSelectSize}
          classNamePrefix="select"
          options={options}
          isClearable={true}
          value={selectedValue}
          className="multi-select-container"
          placeholder={placeholder}
          onChange={value => isMulti ? this.selectItems(value) : this.selectItem(value)}
          isSearchable={!mobileView}
        />
        <input
          tabIndex={-1}
          required={required}
          defaultValue={defaultValue}
        />
      </label>
    );
  }

}
