import { PaginationDto } from 'middleware/dto/pagination';

export class ApplicationsTemplateParams extends PaginationDto {

  status: string;

  constructor (data?: ApplicationsTemplateParams) {
    super(data);
    if (data) {
      this.status = data.status;
    }
  }

}
