import { ImageDto } from 'rest/images/image.dto';
import { UserAddressDto } from 'rest/address-dto/address';
import { NotificationSettingDto } from './notification-setting.dto';

export class CurrentCandidateDto {

  id: string;
  lang: string;
  password: string;
  email: string;
  lastName: string;
  firstName: string;
  anonymous: boolean;
  phoneNumber: string;
  birthday: string;
  directContact: boolean;
  profileActivity: boolean;
  acceptNotification: boolean;
  showOnBoardingVideo: boolean;
  candidatePhotos: ImageDto[];
  blockNotification: NotificationSettingDto;
  candidateAddress: UserAddressDto = new UserAddressDto();

  constructor (data?: CurrentCandidateDto) {
    if (data) {
      this.id = data.id;
      this.lang = data.lang;
      this.email = data.email;
      this.birthday = data.birthday;
      this.lastName = data.lastName;
      this.firstName = data.firstName;
      this.anonymous = data.anonymous;
      this.phoneNumber = data.phoneNumber;
      this.directContact = data.directContact;
      this.profileActivity = data.profileActivity;
      this.acceptNotification = data.acceptNotification;
      this.showOnBoardingVideo = data.showOnBoardingVideo;
      this.candidatePhotos = [new ImageDto(data)];
      this.candidateAddress = new UserAddressDto(data);
    }
  }

}
