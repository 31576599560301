export const CREATE_JOB_OFFER_SKILLS_REQUEST = 'CREATE_JOB_OFFER_SKILLS_REQUEST';
export const CREATE_JOB_OFFER_SKILLS_SUCCESS = 'CREATE_JOB_OFFER_SKILLS_SUCCESS';
export const CREATE_JOB_OFFER_SKILLS_FAILURE = 'CREATE_JOB_OFFER_SKILLS_FAILURE';

export const GET_JOB_OFFER_SKILLS_REQUEST = 'GET_JOB_OFFER_SKILLS_REQUEST';
export const GET_JOB_OFFER_SKILLS_SUCCESS = 'GET_JOB_OFFER_SKILLS_SUCCESS';
export const GET_JOB_OFFER_SKILLS_FAILURE = 'GET_JOB_OFFER_SKILLS_FAILURE';

export const UPDATE_JOB_OFFER_SKILLS_REQUEST = 'UPDATE_JOB_OFFER_SKILLS_REQUEST';
export const UPDATE_JOB_OFFER_SKILLS_SUCCESS = 'UPDATE_JOB_OFFER_SKILLS_SUCCESS';
export const UPDATE_JOB_OFFER_SKILLS_FAILURE = 'UPDATE_JOB_OFFER_SKILLS_FAILURE';

export const RESET_JOB_OFFER_SKILLS = 'RESET_JOB_OFFER_SKILLS';
