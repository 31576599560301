import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { SearchDto, ListDto } from 'middleware/dto';

import { searchSkills, resetSearchResult } from 'services/portal/skills/skills.actions';

import './skills.scss';
import './skills.responsive.scss';

export type Props = {
  t: TransProps,
  open: boolean,
  skills: ListDto[],
  className?: string,
  close: () => void,
  candidate?: boolean,
  recruiter?: boolean,
  hasMoreItems: boolean,
  searchSkills: ListDto[],
  resetSearchResult: () => void,
  search: (params: SearchDto) => void,
  addNewItems: (items: ListDto[]) => void
}

type State = {
  jobTitleId: string,
  searchItem: string,
  selectedItems: ListDto[]
}

export class Settings extends Component<Props, State> {

  static defaultProps: Props = {
    open: false,
    className: '',
    close: () => {},
    search: () => {},
    candidate: false,
    recruiter: false,
    addNewItems: () => {},
    resetSearchResult: () => {}
  };

  state: State = {
    searchItem: '',
    selectedItems: [],
    jobTitleId: ''
  };

}

export const stateToProps = (state: GlobalState) => ({
  fetching: state.portal.skills.fetching,
  skills: state.portal.skills.searchSkills,
  hasMoreItems: state.portal.skills.hasMoreItems
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetSearchResult,
  search: searchSkills
}, dispatch);
