import { Component } from 'react';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { WorkExperienceDto } from 'rest/candidates/work-experience/work-experience.dto';

import './../education-section/education-section.scss';
import './../education-section/education-section.responsive.scss';

type Props = {
  t: TransProps,
  experiences: WorkExperienceDto[]
}

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  experiences: state.portal.candidate.cv.experience.fetchedContent
});
