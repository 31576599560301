import axios from 'axios';

import { CandidateDto } from 'rest/candidates/candidate.dto';

export async function getRecruiterBlackList (recruiterId: string): Promise<CandidateDto[]> {
  try {
    return await axios.get(`/recruiters/${recruiterId}/blacklist`);
  } catch (error) {
    throw error;
  }
}

export async function blockCandidate (recruiterId: string, candidateId: string) {
  try {
    return await axios.post(`/recruiters/${recruiterId}/blacklist?candidateId=${candidateId}`);
  } catch (error) {
    throw error;
  }
}

export async function unblockCandidate (recruiterId: string, candidateId: string) {
  try {
    return await axios.delete(`/recruiters/${recruiterId}/blacklist/${candidateId}`);
  } catch (error) {
    throw error;
  }
}
