import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import moment from 'moment';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { ListDto } from 'middleware/dto/list';
import { CompanyDto } from 'rest/companies/companies.dto';
import { JobTitleDto } from 'rest/job-title/job-title.dto';
import { SalaryRangeDto } from 'rest/job-offers/job-offers.salary-range.dto';
import { JobTypeDto } from 'components/controls/switch-control/switch-control.job-type.dto';

import { RecruiterResponseDto } from 'rest/recruiter/recruiter.response.dto';
import { getAllUnits } from 'services/portal/units/units.actions';
import { getAllJobTitles } from 'services/portal/job-titles/job-titles.actions';
import { getRecruiterCompanyById } from 'services/portal/recruiter/account/account.actions';
import { createNewJobOffer, saveCurrentJobTitle } from 'services/portal/recruiter/job-offer/process/process.actions';

import {
  resetCurrentJobOffer,
  updateCompanyJobOffer,
  getCompanyJobOfferById
} from 'services/portal/recruiter/job-offer/general/general.actions';

import './general-info.scss';

export type Props = {
  t: TransProps,
  unitId: string,
  units: ListDto[],
  next: () => void,
  company: CompanyDto,
  jobOfferId: string,
  getAllUnits: () => void,
  jobTitles: JobTitleDto[],
  getAllJobTitles: () => void,
  currentUser: RecruiterResponseDto,
  getRecruiterCompanyById: (companyId: string) => void,
  resetCurrentJobOffer: () => void
};

type State = {
  unitId: string,
  jobType: string,
  unitError: boolean,
  redirect: boolean,
  jobTitleId: string,
  salaryError: boolean,
  availableFrom: moment,
  jobTitleError: boolean,
  maxSalaryRange: number,
  jobDescription: string,
  referenceNumber: string,
  salaryRange: SalaryRangeDto,
  workingHoursPerMonth: number
}

export class Settings extends Component<Props, State> {

  static defaultProps = {
    unitId: '',
    next: () => {},
    jobOfferId: null,
    getAllUnits: () => {},
    getAllJobTitles: () => {},
    getRecruiterCompanyById: () => {},
    company: new CompanyDto(),
    currentUser: new RecruiterResponseDto()
  };

  state: State = {
    unitId: '',
    redirect: false,
    jobTitleId: '',
    unitError: false,
    salaryError: false,
    jobDescription: '',
    referenceNumber: '',
    jobTitleError: false,
    availableFrom: moment(),
    workingHoursPerMonth: 40,
    jobType: new JobTypeDto({}),
    salaryRange: new SalaryRangeDto({
      min: null,
      max: null,
      salaryType: 'MONTH',
      workingHoursPerMonth: 40
    })
  };

}

export const stateToProps = (state: GlobalState) => ({
  units: state.portal.units.unitsList,
  jobTitles: state.portal.jobTitles.jobTitlesList,
  company: state.portal.recruiter.account.companies.company,
  fetching: state.portal.recruiter.jobOffer.process.fetching,
  jobOffer: state.portal.recruiter.jobOffer.process.jobOffer,
  currentJobOffer: state.portal.recruiter.jobOffer.general.currentJobOffer,
  jobOfferId: state.portal.recruiter.jobOffer.process.jobOffer.id,
  currentUser: state.portal.recruiter.account.current.currentUser,
  redirect: state.portal.recruiter.jobOffer.process.createJobOfferSuccess
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getAllUnits,
  getAllJobTitles,
  createNewJobOffer,
  saveCurrentJobTitle,
  resetCurrentJobOffer,
  updateCompanyJobOffer,
  getCompanyJobOfferById,
  getRecruiterCompanyById
}, dispatch);
