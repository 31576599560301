import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { Trans, TransProps } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { SignUpForm } from 'containers/auth/recruiter/sign-up/sign-up-form';
import { AuthBody, AuthFooter } from 'containers/auth/layouts';

type Props = {
  t: TransProps
}

@connectTranslation()
export class SignUpPage extends Component<Props> {

  render () {
    return (
      <div className="sign-up-page">
        <AuthBody title={this.props.t('auth.sign-up.title')}>
          <SignUpForm/>
        </AuthBody>
        <AuthFooter>
          <span>
            <Trans i18nKey="auth.sign-up.footer.text">
              Already have an account?
            </Trans>
          </span>
          <Link
            className="accent sign-up-link"
            to="/auth/recruiter">
            <Trans i18nKey="auth.sign-up.footer.link">
              Sign In
            </Trans>
          </Link>
        </AuthFooter>
      </div>
    );
  }

}
