// @flow
import * as actions from './skills.constants';

import { Action } from 'redux';

import { SkillsContainerDto } from 'rest/skills/skills-container.dto';

import type { CandidateSkillsType } from './skills.type';

const initialState: CandidateSkillsType = {
  fetching: false,
  createSkillSuccess: false,
  updateSkillsSuccess: false,
  content: new SkillsContainerDto()
};

export default function (state: CandidateSkillsType = initialState, action: Action) {
  switch (action.type) {
    case actions.UPDATE_CANDIDATE_SKILLS_REQUEST:
    case actions.GET_CANDIDATE_SKILLS_REQUEST:
    case actions.CREATE_CANDIDATE_SKILLS_REQUEST:
      return {
        ...state,
        fetching: true,
        createSkillSuccess: false,
        updateSkillsSuccess: false
      };

    case actions.GET_CANDIDATE_SKILLS_SUCCESS:
      return {
        ...state,
        fetching: false,
        content: new SkillsContainerDto(action.payload)
      };

    case actions.CREATE_CANDIDATE_SKILLS_SUCCESS:
      return {
        ...state,
        fetching: false,
        createSkillSuccess: true
      };

    case actions.UPDATE_CANDIDATE_SKILLS_SUCCESS:
      return {
        ...state,
        fetching: false,
        updateSkillsSuccess: true,
        content: new SkillsContainerDto(action.skills)
      };

    case actions.UPDATE_CANDIDATE_SKILLS_FAILURE:
    case actions.GET_CANDIDATE_SKILLS_FAILURE:
    case actions.CREATE_CANDIDATE_SKILLS_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.RESET_CANDIDATE_SKILLS_SUCCESS:
      return { ...initialState };

    default:
      return state;
  }
}
