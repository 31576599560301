// @flow
import React from 'react';

import moment from 'moment';
import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Card } from 'components/layouts';
import { Button } from 'components/buttons';
import { CheckboxControl, DateControl, FormGroup, InputControl } from 'components/controls';

import { Settings } from './study-place.setting';

@connectTranslation()
export class StudyPlaceCard extends Settings {

  render () {
    return (
      <Card className="study-place-container mobile-study-place-container">
        <div className="study-place-caption">
          <h4>
            <Trans i18nKey="portal.candidate.cv.education.place">Study place</Trans> {this.props.number}
          </h4>
          <div className="mobile-delete-btn-hide">
            <Button
              onClick={() => this.props.remove()}
              outlined
              candidate>
              <Trans i18nKey="portal.candidate.cv.education.delete">
                Delete
              </Trans>
            </Button>
          </div>
          <div className="mobile-delete-btn-show">
            <Button
              grey
              onClick={() => this.props.remove()}
              className="delete-btn">
              <img
                src="/images/shared/basket.svg"
                alt="delete"/>
            </Button>
          </div>
        </div>
        <div className="study-form-content">
          <FormGroup className="full-width study-place-form-field">
            <InputControl
              label={this.props.t('portal.candidate.cv.education.school.label')}
              maxLength={70}
              value={this.props.education.school}
              change={value => this.props.change({ school: value })}
              placeholder={this.props.t('portal.candidate.cv.education.school.placeholder')}
              pattern=".+"
              required
              type="text"/>
          </FormGroup>
          <FormGroup className="half-width study-place-form-field">
            <InputControl
              label={this.props.t('portal.candidate.cv.education.degree.label')}
              maxLength={70}
              value={this.props.education.degree}
              change={value => this.props.change({ degree: value })}
              placeholder={this.props.t('portal.candidate.cv.education.degree.placeholder')}
              pattern=".+"
              required
              type="text"/>
          </FormGroup>
          <div className="from-date">
            <DateControl
              className="from-date-field"
              required
              label={this.props.t('portal.candidate.cv.education.date.from')}
              showYearDropdown
              maxDate={moment().toDate()}
              value={this.props.education.fromDate}
              change={value => this.props.change({ fromDate: value })}
              monthsCount={1}/>
            {
              !this.props.education.isCurrent && <DateControl
                required
                label={this.props.t('portal.candidate.cv.education.date.to')}
                showYearDropdown
                minDate={moment(this.props.education.fromDate).toDate()}
                maxDate={moment().toDate()}
                className="from-date-field"
                value={this.props.education.toDate}
                change={value => this.props.change({ toDate: value })}
                monthsCount={1}/>
            }
          </div>
          <div className="currently-study-field">
            <CheckboxControl
              value={this.props.education.isCurrent}
              change={value => this.props.change({ isCurrent: value })}
              label={this.props.t('portal.candidate.cv.education.currently-study')}/>
          </div>
        </div>
      </Card>
    );
  }

}
