export const CREATE_BALANCE_BOARD_REQUEST = 'CREATE_BALANCE_BOARD_REQUEST';
export const CREATE_BALANCE_BOARD_SUCCESS = 'CREATE_BALANCE_BOARD_SUCCESS';
export const CREATE_BALANCE_BOARD_FAILURE = 'CREATE_BALANCE_BOARD_FAILURE';

export const GET_BALANCE_BOARD_REQUEST = 'GET_BALANCE_BOARD_REQUEST';
export const GET_BALANCE_BOARD_SUCCESS = 'GET_BALANCE_BOARD_SUCCESS';
export const GET_BALANCE_BOARD_FAILURE = 'GET_BALANCE_BOARD_FAILURE';

export const UPDATE_BALANCE_BOARD_REQUEST = 'UPDATE_BALANCE_BOARD_REQUEST';
export const UPDATE_BALANCE_BOARD_SUCCESS = 'UPDATE_BALANCE_BOARD_SUCCESS';
export const UPDATE_BALANCE_BOARD_FAILURE = 'UPDATE_BALANCE_BOARD_FAILURE';

export const RESET_BALANCE_BOARD_SUCCESS = 'RESET_BALANCE_BOARD_SUCCESS';
