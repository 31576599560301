// @flow
import { Component } from 'react';

import './input-control.scss';
import './input-control.responsive.scss';

export type Props = {
  min?: number,
  max?: number,
  icon?: boolean,
  type: string,
  cols?: number,
  rows?: number,
  value?: string,
  label?: string,
  error?: boolean,
  notes?: string,
  title?: string,
  step?: number,
  change: (value: string, error: boolean) => void,
  pattern?: string,
  required?: boolean,
  errorText?: string,
  readonly?: boolean,
  minLength?: number,
  maxLength?: number,
  multiline?: boolean,
  placeholder?: string,
  validate?: boolean,
  stickyNotes?: boolean,
  autocomplete?: boolean,
  onPaste?: () => void,
  className?: string
};

export type State = {
  error: boolean,
  value: string,
  isEdit: boolean,
  passwordVisibility: boolean
}

export class Setting extends Component<Props, State> {

  state = {
    error: this.props.error,
    value: this.props.value,
    isEdit: false,
    passwordVisibility: false
  };

  static defaultProps: Props = {
    min: null,
    max: null,
    icon: false,
    cols: 30,
    rows: 8,
    type: '',
    value: '',
    label: '',
    notes: '',
    step: null,
    title: '',
    change: () => {},
    error: false,
    pattern: null,
    required: false,
    multiline: false,
    readonly: false,
    errorText: '',
    minLength: 0,
    maxLength: 250,
    placeholder: '',
    validate: true,
    stickyNotes: false,
    autocomplete: true,
    onPaste: () => {},
    className: ''
  };

}
