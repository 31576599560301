// @flow
import { Component } from 'react';

import type { DropdownType } from './dropdown.type';

import './dropdown.scss';
import './dropdown.responsive.scss';

export type Props = {
  title?: string,
  label?: string,
  icon?: boolean,
  value: string,
  error?: boolean,
  errorText?: string,
  disabled?: boolean,
  item?: DropdownType,
  defaultValue?: string,
  allowUnSelect?: boolean,
  items: DropdownType[],
  change: (item: DropdownType) => void,
  right?: boolean
};

export type State = {
  listOpen: boolean,
  selectedItem: ?DropdownType
}

export class Settings extends Component<Props, State> {

  static defaultProps: Props = {
    title: '',
    items: [],
    error: false,
    errorText: '',
    disabled: false,
    allowUnSelect: false,
    defaultValue: 'Select',
    change: (item: DropdownType) => {},
    right: false
  };

  state: State = {
    listOpen: false,
    selectedItem: this.props.item ? this.props.item : null
  };

}
