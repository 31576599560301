import React from 'react';

import cn from 'classnames';

import { ProgressSetting } from './progress.setting';

export class Progress extends ProgressSetting {

  render () {
    const { className, percent } = this.props;
    const fillWidthStyle = {
      width: `${percent}%`
    };

    return (
      <div className={cn(['placeholder.js', className])}>
        <div
          style={fillWidthStyle}
          className="bar"/>
      </div>
    );
  }

}
