import React, { Fragment } from 'react';

import * as fileSize from 'filesize';

import cn from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { uploadFile } from 'middleware/upload-file';
import { connectStore } from 'middleware/connect';

import { Button } from 'components/buttons';
import { InputControl } from 'components/controls';

import { MessagesRequestDto } from 'rest/chats/messages/messages.request.dto';

import { ChatDate } from './../date/date';
import { ChatMessage } from './../message/message';

import { dispatchToProps, Settings, stateToProps } from './channel.setting';
import { Trans } from 'react-i18next';

@connectStore(stateToProps, dispatchToProps, true)
export class ChatChannel extends Settings {

  componentWillMount () {
    this.initMessages(this.props.match.params.id);
    this.props.resetUnreadConversation();
  }

  componentWillUnmount () {
    this.props.resetMessages();
    clearInterval(this.state.interval);
  }

  componentWillReceiveProps (props) {
    if (this.props.match.params.id !== props.match.params.id) {
      clearInterval(this.state.interval);
      this.initMessages(props.match.params.id);
    }

    if (props.messages.length && props.unreadMessages > 0) {
      this.loadNewMessages(this.props.match.params.id);
    }

    if (this.props.messageComing !== props.messageComing && props.messageComing) {
      this.props.resetMessage();
      this.initConversationScroll();
    }

    if (props.messages.length && props.messages.slice(-1)[0].id !== this.state.lastReadMessage) {
      const lastMessageId = props.messages.slice(-1)[0].id;
      this.setState({ lastReadMessage: lastMessageId });
      this.props.resetUnreadMessages(this.props.match.params.id, lastMessageId);
    }
  }

  changeFileState (file: File) {
    uploadFile(file, (base64, error) => {
      if (error) {
        return this.setState({ fileSizeError: error });
      }
      this.setState({
        fileSizeError: false,
        attachment: file
      });
    });
  }

  initMessages (conversationId: string) {
    this.props.resetMessages();
    this.initChatUpdate();
    this.props.loadMessages(conversationId, 0);
  }

  loadNewMessages (conversationId: string) {
    this.props.loadMessages(conversationId, 0);
  }

  initChatUpdate () {
    this.props.loadUnreadMessages(this.props.match.params.id);
    const interval = setInterval(() => this.props.loadUnreadMessages(this.props.match.params.id), 5000);
    this.setState({ interval });
  }

  initConversationScroll () {
    const scroll = this.messagesEnd.scrollHeight - this.messagesEnd.clientHeight;
    this.messagesEnd.scrollTop = scroll > 0 ? scroll : 0;
  }

  resetChannelControl () {
    this.setState({
      message: '',
      attachment: null
    });
  }

  createMessage () {
    const message: MessagesRequestDto = new MessagesRequestDto();
    message.files = this.state.attachment;
    message.message = this.state.message;
    this.props.createMessage(this.props.match.params.id, message);
    this.resetChannelControl();
  }

  render () {
    const blocked: boolean = this.props.conversation && this.props.conversation.blocked;
    const attachmentClassPresent: boolean = this.state.attachment || this.state.fileSizeError;
    const panelClasses: string = cn({
      'conversation-controls-panel': true,
      'attachment': attachmentClassPresent,
      'disabled': blocked
    });

    return (
      <div className={`conversation mobile-conversation ${attachmentClassPresent ? 'attachment' : ''}`}>
        <PerfectScrollbar containerRef={element => {
          this.messagesEnd = element;
        }}>
          <InfiniteScroll
            useWindow={false}
            className="conversation-messages"
            threshold={100}
            pageStart={0}
            initialLoad={false}
            isReverse={true}
            hasMore={this.props.hasMoreItems}
            loadMore={() => this.props.loadMessages(this.props.match.params.id, this.props.currentPage)}>
            {
              this.props.messages.map((message, index) => (
                <Fragment key={index}>
                  <ChatDate
                    date={message.date}
                    index={index}
                    previousDate={this.props.messages[index - 1] ? this.props.messages[index - 1].date : null}/>
                  <ChatMessage
                    message={message}
                    participants={this.props.conversation ? this.props.conversation.participants : []}
                    className="mobile-message"/>
                </Fragment>
              ))
            }
            {
              blocked && <div className="block-message">
                <div className="lock-avatar avatar">
                  <img src="/images/recruiter/chat/lock-icon.svg"/>
                </div>
                <h4 className="text">
                  <Trans i18nKey="portal.recruiter.chat.list.blocked">
                    So sorry, this conversation was blocked
                  </Trans>
                </h4>
              </div>
            }
          </InfiniteScroll>
        </PerfectScrollbar>
        <form className={panelClasses}>
          <div className="upload-file">
            <label className="upload-button">
              <img
                src="/images/shared/attach.svg"
                alt="close"/>
              <input
                type="file"
                onChange={event => this.changeFileState(event.target.files[0])}/>
            </label>
          </div>
          <div className="message-input">
            <InputControl
              type="text"
              value={this.state.message}
              change={(value: string) => this.setState({ message: value })}
              maxLength={1000}
              placeholder={this.props.t('portal.recruiter.chat.list.message.placeholder')}
              className="mobile-message-input"/>
            {
              this.state.fileSizeError && <p className="error-text">{this.state.fileSizeError}</p>
            }
            {
              (this.state.attachment && !this.state.fileSizeError) && <div className="attachment">
                <div className="file">
                  <span className="title">
                    {this.state.attachment.name}
                  </span>
                  <img
                    className="close"
                    src="/images/shared/close-icon.svg"
                    onClick={() => this.setState({ attachment: null })}
                    alt="close"/>
                </div>
                <div className="size">
                  {fileSize(this.state.attachment.size)}
                </div>
              </div>
            }
          </div>
          <div className="send-message">
            <Button
              type="button"
              recruiter
              disabled={this.state.message.length < 1}
              className="send-message-button"
              onClick={() => this.createMessage()}>
              <div className="button-send-text">
                <Trans i18nKey="portal.recruiter.chat.list.button">
                  Send
                </Trans>
              </div>
              <img
                className="button-send-icon"
                src="/images/candidate/chat/send.svg"
                alt="Send"/>
            </Button>
          </div>
        </form>
      </div>
    );
  }

}
