import React, { Component } from 'react';

import { Modal } from 'components/dialogs';

import { CreatePostModal } from 'containers/portal/candidate/posts';

export class CreatePostPage extends Component {

  render () {
    return (
      <Modal closePath="/portal/candidate/posts">
        <div className="create-post-modal-page">
          <CreatePostModal />
        </div>
      </Modal>
    );
  }

}
