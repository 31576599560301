// @flow
import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';

import type { Match } from 'common/types/match';
import type { GlobalState } from 'common/types/global-state';

import { List } from 'rest/list/list.dto';
import { CandidateDto } from 'rest/candidates/candidate.dto';
import { RecruiterFavoriteParams } from 'rest/recruiter/favorites/favorite.params';

import { showModal } from 'services/portal/modal/modal.actions';
import { ModalPropsType } from 'services/portal/modal/modal-props-type';

import {
  requestFavoriteCandidates,
  resetAllCandidateFavorites,
  deletingCandidateFromFavorite
} from 'services/portal/recruiter/favorites/favorites.actions';

import './favorite.scss';

type Props = {
  match: Match,
  unitId: string,
  history: History,
  fetching: boolean,
  showModal: (id: string, params: ModalPropsType) => void,
  jobTitleId: string,
  candidates: List<CandidateDto>,
  currentPage: number,
  hasMoreItems: boolean,
  currentRecruiterId: string,
  requestFavoriteCandidates: (params: RecruiterFavoriteParams) => void,
  resetAllCandidateFavorites: () => void,
  deletingCandidateFromFavorite: (params: RecruiterFavoriteParams) => void
};

export class Setting extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  unitId: state.portal.recruiter.favorites.unitId,
  fetching: state.portal.recruiter.favorites.fetching,
  jobTitleId: state.portal.recruiter.favorites.jobTitleId,
  candidates: state.portal.recruiter.favorites.content,
  currentPage: state.portal.recruiter.favorites.currentPage,
  hasMoreItems: state.portal.recruiter.favorites.hasMoreItems,
  currentRecruiterId: state.portal.recruiter.account.current.currentUser.id
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  showModal,
  requestFavoriteCandidates,
  resetAllCandidateFavorites,
  deletingCandidateFromFavorite
}, dispatch);
