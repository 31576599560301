import * as actions from './sign-up.constants';

import type { SignUpState } from './sign-up.type';

const initialState: SignUpState = {
  errorText: '',
  fetching: false,
  signUpSuccess: false
};

export default (state = initialState, action): SignUpState => {
  switch (action.type) {
    case actions.RECRUITER_SIGN_UP_REQUEST:
      return {
        ...state,
        errorText: '',
        fetching: true
      };

    case actions.RECRUITER_SIGN_UP_SUCCESS:
      return {
        ...state,
        errorText: '',
        fetching: false,
        signUpSuccess: true
      };

    case actions.RECRUITER_SIGN_UP_FAILURE:
      return {
        ...state,
        errorText: action.error,
        fetching: false,
        signUpSuccess: false
      };

    default:
      return state;
  }
};
