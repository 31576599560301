import React from 'react';

import {
  RequestSkillDecisionTemplate,
  RequestSoftwareDecisionTemplate,
  RequestDocumentDecisionTemplate
} from 'containers/portal/notifications/templates';

import { findEvent } from 'middleware/notifications/find-event';

import { Setting } from './request-skills.setting';
import * as eventNames from './request-skills.constants';

export class RequestSkillsTemplate extends Setting {

  hideModal = () => {
    this.props.hideModal();
  };

  render () {
    const { eventData, publishDate } = this.props;
    switch (findEvent(eventData, eventNames.SKILL_TYPE)) {
      case eventNames.PROFESSIONAL_SKILL:
        return <RequestSkillDecisionTemplate
          eventData={eventData}
          hideModal={this.hideModal}
          publishDate={publishDate}/>;
      case eventNames.SOFTWARE:
        return <RequestSoftwareDecisionTemplate
          eventData={eventData}
          hideModal={this.hideModal}
          publishDate={publishDate}/>;
      case eventNames.DOCUMENTS:
        return <RequestDocumentDecisionTemplate
          eventData={eventData}
          hideModal={this.hideModal}
          publishDate={publishDate}/>;
      default:
        return null;
    }
  }

}
