import { Component } from 'react';

import './cv-section-container.scss';
import './cv-section-container.responsive.scss';

type Props = {
  title: string,
  showEditIcon: boolean,
  redirectLink: string
}

export class Settings extends Component<Props> {

  static defaultProps = {
    showEditIcon: false
  }

}
