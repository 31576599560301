import { Component } from 'react';

import './image.scss';
import './image.responsive.scss';

type Props = {
  imageSrc: string
};

export class Setting extends Component<Props> {}
