/* eslint padded-blocks: ["error", "never"] */
import React, { Component } from 'react';
import '../styles/semantic.min.css';
import '../styles/styles.css';

export class GDPRCandidateSendApplication extends Component {
  render () {
    return (
      <div className="gdpr-main">
        <div className="ui container main-content">
          <h1 className="ui header main-title"> Candidate`s send applications </h1>
          <a href="/information/gdpr">
            <button className="ui button back-link">Back To Main</button>
          </a>
          <h5 className="ui header"> Total Elements:  2</h5>
          <table className="ui single blue line table">
            <thead>
              <tr>
                <th> Job Offer Link </th>
                <th> Subject </th>
                <th> body </th>
                <th> Sent Date </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <a href="https://www.excellent-jobs.com/portal/candidate/jobs/5f18831c59a1190001b78a89/preview">
                    Job Offer Link</a>
                </td>
                <td>New application at Excellent Jobs</td>
                <td>Dear Mr/Ms Selenyi
                    I`d like to apply for your job offer . Please see my profile at
                    Excellent Jobs
                    https://www.excellent-jobs.com/portal/recruiter/candidates/5f4e793edfc47500018854ea/profile
                    Sincerely,
                    Usman</td>
                <td>        2020-09-01T16:57:33.032
                </td>
              </tr>
              <tr>
                <td>
                  <a href="https://www.excellent-jobs.com/portal/candidate/jobs/5d495e2046e0fb000191f791/preview">
                    Job Offer Link</a>
                </td>
                <td>New application at Excellent Jobs</td>
                <td>Dear Mr/Ms Martens
                    I`d like to apply for your job offer . Please see my profile at
                    Excellent Jobs
                    https://www.excellent-jobs.com/portal/recruiter/candidates/5f4e793edfc47500018854ea/profile
                    Sincerely,
                    Usman</td>
                <td>        2020-09-01T17:01:36.007
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
