import React from 'react';

import { Setting } from './container.setting';

export class NavigationContainer extends Setting {

  render () {
    const { children } = this.props;
    return (
      <div className={`nav-widgets ${this.props.className}`}>
        {
          children.map((item, index) => (
            <div
              key={index}
              className={item.props.className ? `nav-widget ${item.props.className}` : 'nav-widget'}>
              {item.props.children}
            </div>
          ))
        }
      </div>
    );
  }

}
