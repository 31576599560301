import * as actions from './current.constants';

import type { CurrentRecruiterState } from './current.type';

import { RecruiterDto } from 'rest/recruiter/recruiter.dto';

const initialState: CurrentRecruiterState = {
  errorText: '',
  fetching: false,
  currentUser: new RecruiterDto(),
  changePasswordSuccess: false,
  activateRecruiterSuccess: false,
  updateRecruiterInfoSuccess: false
};

export default (state = initialState, action): CurrentRecruiterState => {
  switch (action.type) {
    case actions.CHANGE_PASSWORD_REQUEST:
    case actions.DISABLE_ON_BOARDING_VIDEO_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case actions.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        fetching: false,
        changePasswordSuccess: true
      };

    case actions.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        fetching: false,
        errorText: action.error,
        changePasswordSuccess: false
      };

    case actions.UPDATE_RECRUITER_FAILURE:
      return {
        ...state,
        fetching: false,
        updateRecruiterInfoSuccess: false
      };

    case actions.UPDATE_RECRUITER_REQUEST:
      return {
        ...state,
        fetching: true,
        updateRecruiterInfoSuccess: false
      };

    case actions.GET_CURRENT_RECRUITER_SUCCESS:
      return {
        ...state,
        fetching: false,
        currentUser: action.recruiter
      };

    case actions.UPDATE_RECRUITER_SUCCESS:
      return {
        ...state,
        fetching: false,
        currentUser: {
          ...state.currentUser,
          ...action.recruiter
        },
        updateRecruiterInfoSuccess: true
      };
    case actions.ACTIVATE_RECRUITER_REQUEST:
      return {
        ...state,
        fetching: true
      };
    case actions.ACTIVATE_RECRUITER_SUCCESS:
      return {
        ...state,
        fetching: false,
        activateRecruiterSuccess: true
      };
    case actions.ACTIVATE_RECRUITER_FAILURE:
      return {
        ...state,
        fetching: false,
        errorText: action.error,
        activateRecruiterSuccess: false
      };
    case actions.CLEAR_CURRENT_RECRUITER_SUCCESS:
      return { ...initialState };

    case actions.DISABLE_ON_BOARDING_VIDEO_SUCCESS:
      return {
        ...state
      };

    case actions.DISABLE_ON_BOARDING_VIDEO_FAILURE:
      return {
        ...state,
        fetching: false
      };

    default:
      return state;
  }
};
