import { Component } from 'react';

import { chatConfig } from './match-chart.config';

import './match-chart.scss';

type Props = {
  data: number[],
  total: number,
  current: number,
  changeRate: () => void
}

export class Settings extends Component<Props> {

  static defaultProps = {
    data: [],
    total: 0,
    current: 1,
    changeRate: (): void => {}
  };

  state = {
    config: chatConfig(this.props.data, this.props.current),
    current: this.props.current,
    marks: {
      1: '1',
      10: '10',
      20: '20',
      30: '30',
      40: '40',
      50: '50',
      60: '60',
      70: '70',
      80: '80',
      90: '90',
      100: '100'
    }
  };

}
