import * as actions from './demoMenuVideo.constants';

const disabledBalanceBoardVideoswitch = data => ({
  type: actions.DISABLE_ON_BALANCE_BOARD_VIDEO,
  payload: data
});

const disabledBalanceBoardReUseVideo = data => ({
  type: actions.DISABLE_ON_BALANCE_BOARD_REUSE_VIDEO,
  payload: data
});

const disabledCandidatesVideo = data => ({
  type: actions.DISABLE_ON_CANDIDATES_VIDEO,
  payload: data
});

const disabledjobOffersVideo = data => ({
  type: actions.DISABLE_ON_JOBOFFERS_VIDEO,
  payload: data
});

const disabledMatchVideo = data => ({
  type: actions.DISABLE_ON_MATCH_VIDEO,
  payload: data
});

const disabledChatVideo = data => ({
  type: actions.DISABLE_ON_CHAT_VIDEO,
  payload: data
});

const disabledCalendarVideo = data => ({
  type: actions.DISABLE_ON_CALENDAR_VIDEO,
  payload: data
});

const disabledFavoritesVideo = data => ({
  type: actions.DISABLE_ON_FAVORITES_VIDEO,
  payload: data
});

const disabledFollowingVideo = data => ({
  type: actions.DISABLE_ON_FOLLOWING_VIDEO,
  payload: data
});

const disabledStatisticsVideo = data => ({
  type: actions.DISABLE_ON_STATISTICS_VIDEO,
  payload: data
});

const disabledSettingsVideo = data => ({
  type: actions.DISABLE_ON_SETTINGS_VIDEO,
  payload: data
});

export const disableBalanceDashboardVideo = page => {
  return dispatch => {
    if (page.indexOf('balance-board') > -1) {
      dispatch(disabledBalanceBoardVideoswitch(true));
    } else if (page.indexOf('reuseOffers') > -1) {
      dispatch(disabledBalanceBoardReUseVideo(true));
    } else if (page.indexOf('job-offers') > -1) {
      dispatch(disabledjobOffersVideo(true));
    } else if (page.indexOf('match') > -1) {
      dispatch(disabledMatchVideo(true));
    } else if (page.indexOf('chat') > -1) {
      dispatch(disabledChatVideo(true));
    } else if (page.indexOf('calendar') > -1) {
      dispatch(disabledCalendarVideo(true));
    } else if (page.indexOf('favorites') > -1) {
      dispatch(disabledFavoritesVideo(true));
    } else if (page.indexOf('follow') > -1) {
      dispatch(disabledFollowingVideo(true));
    } else if (page.indexOf('candidates') > -1) {
      dispatch(disabledCandidatesVideo(true));
    } else if (page.indexOf('statistics') > -1) {
      dispatch(disabledStatisticsVideo(true));
    } else if (page.indexOf('settingsVideo') > -1) {
      dispatch(disabledSettingsVideo(true));
    }
  };
};
