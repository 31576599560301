import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { ListDto } from 'middleware/dto/list';

import { getAllLanguages } from 'services/portal/languages/languages.actions';

import './languages.scss';
import './languages.responsive.scss';

export type Props = {
  t: TransProps,
  open: boolean,
  close: () => void,
  className?: string,
  candidate?: boolean,
  recruiter?: boolean,
  languages?: ListDto[],
  getAllLanguages?: () => void,
  addNewItems: (items: ListDto[]) => void
}

type State = {
  languages: ListDto[],
  searchItem: string,
  selectedItems: ListDto[]
}

export class Settings extends Component<Props, State> {

  static defaultProps: Props = {
    open: false,
    className: '',
    languages: [],
    candidate: false,
    recruiter: false,
    close: () => {},
    addNewItems: () => {},
    getAllLanguages: () => {}
  };

  state: State = {
    searchItem: '',
    selectedItems: [],
    languages: this.props.languages
  }

}

export const stateToProps = (state: GlobalState) => ({
  fetching: state.portal.languages.fetching,
  languages: state.portal.languages.languagesList
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getAllLanguages
}, dispatch);
