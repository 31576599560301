// @flow
import axios, { AxiosPromise } from 'axios';

import { WorkExperienceDto } from './work-experience.dto';

export async function getWorkExperience (candidateId: string): AxiosPromise<WorkExperienceDto[]> {
  try {
    return await axios.get(`/candidates/${candidateId}/work-experiences`);
  } catch (error) {
    throw error;
  }
}

export async function createWorkExperience (candidateId: string, data: WorkExperienceDto) {
  try {
    return await axios.post(`/candidates/${candidateId}/work-experiences`, data);
  } catch (error) {
    throw error;
  }
}

export async function updateWorkExperience (candidateId: string, experienceId: string, data: WorkExperienceDto) {
  try {
    return await axios.put(`/candidates/${candidateId}/work-experiences/${experienceId}`, data);
  } catch (error) {
    throw error;
  }
}

export async function deleteWorkExperience (candidateId: string, experienceId: string) {
  try {
    return await axios.delete(`/candidates/${candidateId}/work-experiences/${experienceId}`);
  } catch (error) {
    throw error;
  }
}
