import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Card } from 'components/layouts';
import { Footer } from 'containers/portal';
import { Button } from 'components/buttons';

export class SuccessBuyCandidatePage extends Component {

  render () {
    return (
      <div className="payment-page-container">
        <div className="success-payment-container">
          <Card>
            <div className="success-payment-content">
              <h2>Congratulations!</h2>
              <p className="success-text">Payment was successful</p>
              <p className="payment-description">
                You will find your candidate profile in the Favorites (&quot;Purchased Tab&quot;)
              </p>
              <div className="success-payment-action">
                <Link to="/portal/recruiter/favorites">
                  <Button fullWidth recruiter>
                    Go to Favorites
                  </Button>
                </Link>
              </div>
            </div>
          </Card>
          <Footer className="success-payment-footer"/>
        </div>
      </div>
    );
  }

}
