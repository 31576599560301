// @flow
import React from 'react';

import moment from 'moment';

import { connectStore } from 'middleware/connect';

import { StatisticsContainer, StatisticsItem } from 'components/widgets/statistics';

import { Settings, stateToProps, dispatchToProps } from './posts.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class PostsSidebar extends Settings {

  openPostPreview (postId: string) {
    this.props.history.push(`/portal/candidate/posts/preview/${postId}`);
  }

  render () {
    return (
      <StatisticsContainer title={`${this.props.t('portal.candidate.blog.sidebar.title')} (${this.props.total})`}>
        {
          this.props.posts.map((post, index) => (
            <StatisticsItem
              key={index}
              title={post.title}
              onClick={() => this.openPostPreview(post.id)}
              candidate
              description={moment(post.createDate).parseZone().fromNow()}/>
          ))
        }
      </StatisticsContainer>
    );
  }

}
