// @flow
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Trans, TransProps } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { LinkButton } from 'components/buttons';
import { PortletContainer, PortletHeader, PortletBody } from 'components/portlet';

import { CandidatesList } from 'containers/portal/recruiter/candidates';
import { ActiveFilterButton } from 'containers/portal/recruiter/candidates/search';

type Props = {
  t: TransProps
}

@connectTranslation()
export class CandidatesListPage extends Component<Props> {

  render () {
    return (
      <PortletContainer>
        <PortletHeader title={this.props.t('portal.recruiter.candidates.list.title')}>
          <ActiveFilterButton/>
          <LinkButton
            link="/portal/recruiter/candidates/search"
            recruiter>
            <Trans i18nKey="portal.recruiter.candidates.list.button">
              Filters
            </Trans>
          </LinkButton>
        </PortletHeader>
        <PortletBody>
          <Route
            path="/portal/recruiter/candidates"
            component={CandidatesList}/>
        </PortletBody>
      </PortletContainer>
    );
  }

}
