import React from 'react';

import { Setting } from './table-body.setting';

export class TableBody extends Setting {

  render () {
    return (
      <tbody className={this.props.className}>
        {this.props.children}
      </tbody>
    );
  }

}
