/* eslint padded-blocks: ["error", "never"] */
import React, { Component } from 'react';
import axios from 'axios';
import '../styles/semantic.min.css';
import '../styles/styles.css';
export class GDPRCandidateProfileInfo extends Component {
  constructor() {
    super();
    this.state = {
      data: {},
      address: {},
      photos: []
    };
  }
  componentDidMount() {
    axios('/candidates/current')
      .then(response => {
        this.setState({
          data: response.data,
          address: response.data.candidateAddress,
          photos: response.data.candidatePhotos
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  render() {
    return (
      <div className="gdpr-main">
        <div className="ui container main-content">
          <h1 className="ui header main-title"> Profile Info </h1>
          <a href="/information/gdpr">
            <button className="ui button back-link">Back To Main</button>
          </a>
          <table className="ui striped green table">
            <thead>
              <tr><th colSpan={3}>
                Profile Info   </th>
              </tr></thead>
            <tbody>
              <tr>
                <td className="collapsing">
                  First Name   </td>
                <td>{this.state.data.firstName}</td>
              </tr>
              <tr>
                <td>
                  Last Name     </td>
                <td>{this.state.data.lastName}</td>
              </tr>
              <tr>
                <td>
                  Email     </td>
                <td>{this.state.data.email}</td>
              </tr>
              <tr>
                <td>
                  Is Active Profile     </td>
                <td>{this.state.data.profileActivity}</td>
              </tr>
              <tr>
                <td className="collapsing">
                  Birthday     </td>
                <td>{this.state.data.birthday}
                </td>
              </tr>
              <tr>
                <td className="collapsing">
                  Phone Number     </td>
                <td>{this.state.data.phoneNumber}</td>
              </tr>
              <tr>
                <td className="collapsing">
                  Candidate Photos     </td>
                <td>
                  {this.state.photos.map(photo => (
                    <a key={photo.id}>{photo.url}</a>
                  ))}
                </td>
              </tr>
              <tr>
                <td>
                  Updated Date     </td>
                <td>{this.state.data.updatedDate}
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  Available From     </td>
                <td>{this.state.data.active}
                </td>
              </tr>
              <tr>
                <td>
                  Relocation     </td>
                <td>{this.state.data.willingToRelocate}</td>
              </tr>
              <tr>
                <td>
                  Selected Locale     </td>
                <td>{this.state.data.siteLocale}</td>
              </tr>
              <tr>
                <td>
                  Candidate Address     </td>
                <td>
                  {this.state.address.streetNumber} {this.state.address.street},
                  {this.state.address.city}, {this.state.address.country} {this.state.address.zipCode}
                </td>
              </tr>
              <tr>
                <td>
                  Candidate Skill Link     </td>
                <td><a href="#"> Skills </a></td>
              </tr>
              <tr>
                <td>
                  Candidate Job Requirement     </td>
                <td><a href="#"> Job Requirement </a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
