import * as actions from './documents.constants';

import { ListDto } from 'middleware/dto/list';
import { PaginationDto, SearchDto } from 'middleware/dto';

import { getDocuments, searchDocuments as search } from 'rest/documents/document.rest';

const getDocumentsRequest = () => ({
  type: actions.GET_DOCUMENTS_REQUEST
});

const getDocumentsSuccess = (documents: ListDto[]) => ({
  type: actions.GET_DOCUMENTS_SUCCESS,
  documents
});

const getDocumentsFailure = (error: string) => ({
  type: actions.GET_DOCUMENTS_FAILURE,
  error
});

const searchDocumentsRequest = (query: string) => ({
  type: actions.SEARCH_DOCUMENTS_REQUEST,
  query
});

const searchDocumentsSuccess = (documents: ListDto[]) => ({
  type: actions.SEARCH_DOCUMENTS_SUCCESS,
  documents
});

const searchDocumentsFailure = (error: string) => ({
  type: actions.SEARCH_DOCUMENTS_FAILURE,
  error
});

export const resetSearch = () => ({
  type: actions.SEARCH_DOCUMENTS_RESET
});

export const resetSearchResult = () => {
  return dispatch => {
    dispatch(resetSearch());
  };
};

export const getAllDocuments = (params: PaginationDto) => {
  return dispatch => {
    dispatch(getDocumentsRequest());
    return getDocuments(params)
      .then(response => dispatch(getDocumentsSuccess(response.data.content)))
      .catch(error => dispatch(getDocumentsFailure(error)));
  };
};

export const searchDocuments = (params: SearchDto) => {
  return dispatch => {
    dispatch(searchDocumentsRequest(params.q));
    return search(params)
      .then(response => dispatch(searchDocumentsSuccess(response.data.content)))
      .catch(error => dispatch(searchDocumentsFailure(error)));
  };
};
