import { Component } from 'react';

import { SkillItemDto } from 'rest/skill/skill-item.dto';

import './priority-match.scss';

export type Props = {
  prev: () => void,
  next: () => void,
  fetching: boolean
};

type State = {
  count: number,
  active: number,
  matchPoints: number,
  minPointsError: boolean,
  maxPointsError: boolean,
  skillsCountError: boolean,
  documents: SkillItemDto[],
  isMatchModalOpen: boolean,
  requiredSkillsCount: boolean,
  errorCollection: Set<string>,
  createNewJobOffer: () => void,
  languageSkills: SkillItemDto[],
  softwareSkills: SkillItemDto[],
  createSkillSuccess: boolean,
  professionalSkills: SkillItemDto[]
}

export class Settings extends Component<Props, State> {

  static defaultProps: Props = {
    prev: () => {},
    closeJobOffer: () => {},
    createJobOfferFlow: () => {}
  };

  state: State = {
    count: 0,
    active: 1,
    matchPoints: 75,
    documents: [],
    softwareSkills: [],
    languageSkills: [],
    minPointsError: false,
    maxPointsError: false,
    isMatchModalOpen: false,
    requiredSkillsCount: 0,
    professionalSkills: [],
    skillsCountError: false,
    errorCollection: new Set()
  };

  maxPointsCount: number = 100;
  minRequiredSkillCount: number = 1;
  maxRequiredSkillsCount: number = 3;

}
