import React from 'react';

import { Card } from 'components/layouts';

import { Setting } from './form.setting';

export class AuthFormContainer extends Setting {

  render () {
    return (
      <Card>
        <div className="auth-form-container">
          { this.props.children }
        </div>
      </Card>
    );
  }

}
