import * as actions from './education.constants';

import {
  getEducations,
  createEducation,
  updateEducation,
  deleteEducation
} from 'rest/candidates/educations/educations.rest';

import { EducationsDto } from 'rest/candidates/educations/educations.dto';

const createEducationRequest = () => ({
  type: actions.CREATE_EDUCATION_REQUEST
});

const createEducationSuccess = () => ({
  type: actions.CREATE_EDUCATION_SUCCESS
});

const createEducationFailure = () => ({
  type: actions.CREATE_EDUCATION_FAILURE
});

const getEducationRequest = () => ({
  type: actions.GET_EDUCATION_REQUEST
});

const getEducationSuccess = (educations: EducationsDto[]) => ({
  type: actions.GET_EDUCATION_SUCCESS,
  educations
});

const getEducationFailure = (error: string) => ({
  type: actions.GET_EDUCATION_FAILURE,
  error
});

const updateEducationRequest = () => ({
  type: actions.UPDATE_EDUCATION_REQUEST
});

const updateEducationSuccess = (educations: EducationsDto) => ({
  type: actions.UPDATE_EDUCATION_SUCCESS,
  educations
});

const updateEducationFailure = () => ({
  type: actions.UPDATE_EDUCATION_FAILURE
});

const deleteEducationRequest = () => ({
  type: actions.DELETE_EDUCATION_REQUEST
});

const deleteEducationSuccess = (educationId: string) => ({
  type: actions.DELETE_EDUCATION_SUCCESS,
  educationId
});

const deleteEducationFailure = () => ({
  type: actions.DELETE_EDUCATION_FAILURE
});

const addNewEducationSuccess = (education: EducationsDto) => ({
  type: actions.ADD_NEW_EDUCATION_SUCCESS,
  education
});

const deleteNewEducationSuccess = (index: number) => ({
  type: actions.DELETE_NEW_EDUCATION_SUCCESS,
  index
});

const changeEducationSuccess = (index, value) => ({
  type: actions.CHANGE_WORK_EDUCATION_SUCCESS,
  index,
  value
});

const resetEducationsSuccess = () => ({
  type: actions.RESET_EDUCATIONS_LIST_SUCCESS
});

export function changeEducation (index, education: EducationsDto) {
  return dispatch => dispatch(changeEducationSuccess(index, education));
}

export function pushEducation (education: EducationsDto) {
  return dispatch => dispatch(addNewEducationSuccess(education));
}

export function removeInternalEducation (index: number) {
  return dispatch => dispatch(deleteNewEducationSuccess(index));
}

export function resetEducations () {
  return dispatch => dispatch(resetEducationsSuccess());
}

export function createCandidateEducation (candidateId: string, data: EducationsDto) {
  return dispatch => {
    dispatch(createEducationRequest());
    return createEducation(candidateId, data)
      .then(() => {
        dispatch(createEducationSuccess(data));
        return dispatch(getCandidateEducations(candidateId));
      })
      .catch(() => dispatch(createEducationFailure()));
  };
}

export function getCandidateEducations (candidateId: string) {
  return dispatch => {
    dispatch(getEducationRequest());
    return getEducations(candidateId)
      .then(response => dispatch(getEducationSuccess(response.data)))
      .catch(() => dispatch(getEducationFailure()));
  };
}

export function updateCandidateEducation (candidateId: string, educationId: string, data: EducationsDto) {
  return dispatch => {
    dispatch(updateEducationRequest());
    return updateEducation(candidateId, educationId, data)
      .then(() => {
        dispatch(updateEducationSuccess(data));
        return dispatch(getCandidateEducations(candidateId));
      })
      .catch(() => dispatch(updateEducationFailure()));
  };
}

export function deleteCandidateEducation (candidateId: string, educationId: string) {
  return dispatch => {
    dispatch(deleteEducationRequest());
    return deleteEducation(candidateId, educationId)
      .then(() => dispatch(deleteEducationSuccess(educationId)))
      .catch(() => dispatch(deleteEducationFailure()));
  };
}
