// @flow
import React from 'react';

import cn from 'classnames';

import { Settings } from './payment-card.setting';

export class PaymentCard extends Settings {

  render () {
    const cardClasses = cn(['card', { selected: this.props.selected }]);
    return (
      <article className={cardClasses}>
        <h4 className="card-caption">{this.props.title}</h4>
        <div className="card-content">
          <ul className="card-feature-list">
            {this.props.children}
          </ul>
          <div className="price">
            <p>
              <span className="euro-sign">&#x20AC;</span>
              <span className="price-count">{this.props.price}</span>/ {this.props.priceCondition}
            </p>
          </div>
        </div>
        {
          this.props.selected && <span className="selected-title">(Selected)</span>
        }
      </article>
    );
  }

}
