// @flow
import React from 'react';

import moment from 'moment';

import { connectStore } from 'middleware/connect';

import { StatisticsContainer, StatisticsItem } from 'components/widgets/statistics';

import { JobOffersMatchesDto } from 'rest/candidates/job-offers-matches/job-offers-matches.dto';
import { JobOffersMatchesParams } from 'rest/candidates/job-offers-matches/job-offers-matches.params';

import { Settings, stateToProps, dispatchToProps } from './match.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class MatchSidebar extends Settings {

  componentDidMount () {
    const params = new JobOffersMatchesParams();
    params.size = 1000;
    this.props.resetNewMatchedJobOffersBubble();
    this.props.getNewMatchedJobOffersList(this.props.currentUser.id, params);
  }

  componentWillUnmount () {
    this.props.resetNewMatchedJobOffers();
  }

  openJobOffer (jobOfferId: string) {
    this.props.history.push(`/portal/candidate/match/${jobOfferId}/preview`);
  }

  render () {
    return (
      <StatisticsContainer title={`${this.props.t('portal.candidate.match.sidebar.title')} (${this.props.total})`}>
        {
          this.props.jobOffers.map((jobOffer: JobOffersMatchesDto, index: number) => (
            <StatisticsItem
              key={index}
              title={jobOffer.jobTitleName}
              value={moment(jobOffer.updatedDate).parseZone().fromNow() ||
              this.props.t('portal.candidate.match.sidebar.no-information')}
              onClick={() => this.openJobOffer(jobOffer.jobOfferId)}
              description={jobOffer.companyName}
              candidate/>
          ))
        }
      </StatisticsContainer>
    );
  }

}
