// @flow
import React, { Fragment } from 'react';

import { toast } from 'react-toastify';
import { Trans } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import { connectStore } from 'middleware/connect';

import { ListPlaceholder, Spinner } from 'components/elements';
import { ElementsList, JobOfferRow } from 'components/elements-list';

import { JobOffersMatchesDto } from 'rest/candidates/job-offers-matches/job-offers-matches.dto';
import { JobOffersMatchesParams } from 'rest/candidates/job-offers-matches/job-offers-matches.params';

import { stateToProps, dispatchToProps, Setting } from './jobs-list.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class MatchedJobsList extends Setting {

  componentWillUnmount () {
    this.props.resetAllMatchedJobOffers();
  }

  loadJobOffersList (page: number = 0) {
    const params = new JobOffersMatchesParams(this.props.matchFilters);
    params.page = page;
    params.extraFields = ['favorite'];
    this.props.getMatchedJobOffersList(this.props.currentCandidate.id, params);
  }

  toggleFavorite (jobOffer: JobOffersMatchesDto) {
    if (jobOffer.favorite) {
      this.props.deleteJobFromFavorite(jobOffer.jobOfferId, this.props.currentCandidate.id);
      toast.success(this.props.t('portal.candidate.notifications.favorite-delete'));
    } else {
      this.props.addJobToFavorite(jobOffer.jobOfferId, this.props.currentCandidate.id);
      toast.success(this.props.t('portal.candidate.notifications.favorite-add'));
    }
  }

  openJobOffer (selectedJobOfferId: string) {
    this.props.history.push(`/portal/candidate/match/${selectedJobOfferId}/preview`);
  }

  initJobOffersList (): HTMLElement {
    return this.props.jobOffers.map((jobOffer: JobOffersMatchesDto, index) => (
      <JobOfferRow
        key={index}
        isNew={jobOffer.isNew}
        action={() => this.toggleFavorite(jobOffer)}
        onClick={() => this.openJobOffer(jobOffer.jobOfferId)}
        jobOffer={jobOffer}>
      </JobOfferRow>
    ));
  }

  initEmptyList () : HTMLElement {
    return !this.props.jobOffers.length ? (
      <ListPlaceholder
        title={this.props.t('portal.candidate.match.list.placeholder.title')}>
        <Trans i18nKey="portal.candidate.match.list.placeholder.content">
          Try filling out your Balance Board with more skills to get qualified for jobs.
        </Trans>
      </ListPlaceholder>
    ) : null;
  }

  render () {
    return (
      <Fragment>
        {
          this.props.currentCandidate.id && <InfiniteScroll
            useWindow={false}
            threshold={100}
            pageStart={0}
            hasMore={this.props.hasMoreItems}
            loadMore={() => this.loadJobOffersList(this.props.currentPage)}>
            <ElementsList>{this.initJobOffersList()}</ElementsList>
            { this.initEmptyList() }
          </InfiniteScroll>
        }
        <Spinner show={this.props.fetching}/>
      </Fragment>
    );
  }

}
