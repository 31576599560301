import { Component } from 'react';

import moment from 'moment';

import { EventDataDto } from 'rest/alerts/alerts.event-data.dto';

type Props = {
  eventData: EventDataDto[],
  hideModal: (type: string) => void,
  publishDate: string
}

export class Setting extends Component<Props> {

  static defaultProps: Props = {
    eventData: [],
    hideModal: () => {},
    publishDate: moment().parseZone().fromNow()
  }

}
