// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { getCurrentCandidate } from 'services/portal/candidate/account/account.actions';
import { getPosts, resetPostList } from 'services/portal/candidate/blog/blog.actions';

import { PaginationDto } from 'middleware/dto';

import { PostsResponseDto } from 'rest/candidates/posts/posts.response.dto';
import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';

import './posts-list.scss';

type Props = {
  posts: PostsResponseDto[],
  fetching: false,
  history: History,
  fetching: boolean,
  getPosts: (query: PaginationDto) => void,
  currentPage: number,
  hasMoreItems: boolean,
  resetPostList: () => void,
  currentCandidate: CurrentCandidateDto,
  getCurrentCandidate: () => void,
}

export class Setting extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  posts: state.portal.candidate.blog.content,
  fetching: state.portal.candidate.blog.fetching,
  currentPage: state.portal.candidate.blog.currentPage,
  hasMoreItems: state.portal.candidate.blog.hasMoreItems,
  currentCandidate: state.portal.candidate.account.current.candidateData
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getPosts,
  resetPostList,
  getCurrentCandidate
}, dispatch);
