import { uniqueArray } from 'middleware/unique-array';

import { SkillsDto } from 'rest/skill/skills.dto';

export function customSkillsMerge (skills: SkillsDto, existingSkills: SkillsDto): SkillsDto {
  const data = {};
  for (let key in skills) {
    if (key && skills.hasOwnProperty(key) && Array.isArray(skills[key])) {
      const cache = existingSkills[key] ? existingSkills[key] : [];
      data[key] = uniqueArray([...skills[key], ...cache], 'skillId');
    }
  }
  return data;
}
