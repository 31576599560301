import { PaginationDto } from 'middleware/dto/pagination';

export class SearchDto extends PaginationDto {

  q: string;

  constructor (data?: SearchDto) {
    super(data);
    if (data) {
      this.q = data.q;
    }
  }

}
