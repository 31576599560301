export class ListDto {

  id: string;
  name: string;
  description: string;

  constructor (data?: ListDto) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.description = data.description;
    }
  }

}
