import { combineReducers } from 'redux';

import signIn from './sign-in/sign-in.reducer';
import signUp from './sign-up/sign-up.reducer';
import social from './social/social.reducer';

export default combineReducers({
  signIn,
  signUp,
  social
});
