import * as actions from './stats.constants';

import { StatsDto } from 'rest/stats/stats.dto';

import type { FavoritesStatsType } from './stats.type';

const initialState: FavoritesStatsType = {
  stats: [],
  fetching: false,
  totalElements: 0
};

export default (state = initialState, action): FavoritesStatsType => {
  switch (action.type) {

    case actions.GET_FAVORITES_STATS_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case actions.GET_FAVORITES_STATS_SUCCESS:
      return {
        ...state,
        stats: action.stats.map(stat => new StatsDto(stat)),
        fetching: false,
        totalElements: action.total
      };

    case actions.GET_FAVORITES_STATS_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.RESET_FAVORITES_STATS_SUCCESS:
      return {
        ...state,
        stats: [],
        fetching: false,
        totalElements: 0
      };

    default:
      return state;
  }
};
