import { Component } from 'react';

import './body.scss';
import './body.responsive.scss';

type Props = {
  children?: Node,
  className?: string
};

export class Setting extends Component<Props> {

  static defaultProps: Props = {
    className: ''
  };

}
