export class JobTitleDto {

  jobTitle: string;

  constructor (data?: JobTitleDto) {
    if (data) {
      this.jobTitle = data.jobTitle;
    }
  }

}
