// @flow
import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import { TransProps } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { PortletContainer, PortletHeader, PortletBody } from 'components/portlet';

import { JobOffersList } from 'containers/portal/guest/candidate';
import { ActiveFilterButton } from 'containers/portal/candidate/jobs/search';

type Props = {
  t: TransProps
}

@connectTranslation()
export class JobsListPage extends Component<Props> {

  render () {
    return (
      <PortletContainer>
        <PortletHeader title={this.props.t('portal.candidate.job-offers.list.title')}>
          <div className="mobile-md-hide">
            <ActiveFilterButton/>
          </div>
        </PortletHeader>
        <PortletBody className="mobile-portlet-list">
          <Route
            path="/portal/guest/jobs"
            component={JobOffersList}/>
        </PortletBody>
      </PortletContainer>
    );
  }

}
