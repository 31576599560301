export const CREATE_JOB_OFFER_REQUEST = 'CREATE_JOB_OFFER_REQUEST';
export const CREATE_JOB_OFFER_SUCCESS = 'CREATE_JOB_OFFER_SUCCESS';
export const CREATE_JOB_OFFER_FAILURE = 'CREATE_JOB_OFFER_FAILURE';

export const CREATE_JOB_OFFER_SKILLS_REQUEST = 'CREATE_JOB_OFFER_SKILLS_REQUEST';
export const CREATE_JOB_OFFER_SKILLS_SUCCESS = 'CREATE_JOB_OFFER_SKILLS_SUCCESS';
export const CREATE_JOB_OFFER_SKILLS_FAILURE = 'CREATE_JOB_OFFER_SKILLS_FAILURE';

export const PUBLISH_JOB_OFFER_REQUEST = 'PUBLISH_JOB_OFFER_REQUEST';
export const PUBLISH_JOB_OFFER_SUCCESS = 'PUBLISH_JOB_OFFER_SUCCESS';
export const PUBLISH_JOB_OFFER_FAILURE = 'PUBLISH_JOB_OFFER_FAILURE';

export const GET_JOB_OFFER_MATCHING_RATE_REQUEST = 'GET_JOB_OFFER_MATCHING_RATE_REQUEST';
export const GET_JOB_OFFER_MATCHING_RATE_SUCCESS = 'GET_JOB_OFFER_MATCHING_RATE_SUCCESS';
export const GET_JOB_OFFER_MATCHING_RATE_FAILURE = 'GET_JOB_OFFER_MATCHING_RATE_FAILURE';

export const DELETE_SAVED_JOB_OFFER_DATA_SUCCESS = 'DELETE_SAVED_JOB_OFFER_DATA_SUCCESS';
export const SAVE_CURRENT_JOB_TITLE_SUCCESS = 'SAVE_CURRENT_JOB_TITLE_SUCCESS';

export const SEND_JOB_OFFER_REQUEST = 'SEND_JOB_OFFER_REQUEST';
export const SEND_JOB_OFFER_SUCCESS = 'SEND_JOB_OFFER_SUCCESS';
export const SEND_JOB_OFFER_FAILURE = 'SEND_JOB_OFFER_FAILURE';
