export const countriesDELocalization = {
  'de': 'Deutschland',
  'at': 'Österreich',
  'ch': 'Schweiz',
  'lu': 'Luxemburg',
  'af': 'Afghanistan',
  'eg': 'Ägypten',
  'ax': 'Åland',
  'al': 'Albanien',
  'dz': 'Algerien',
  'as': 'Amerikanisch-Samoa',
  'ad': 'Andorra',
  'ao': 'Angola',
  'ai': 'Anguilla',
  'aq': 'Antarktis',
  'ag': 'Antigua/Barbuda',
  'gq': 'Äquatorialguinea',
  'ar': 'Argentinien',
  'am': 'Armenien',
  'aw': 'Aruba',
  'az': 'Aserbaidschan',
  'et': 'Äthiopien',
  'au': 'Australien',
  'bs': 'Bahamas',
  'bh': 'Bahrain',
  'bd': 'Bangladesh',
  'bb': 'Barbados',
  'be': 'Belgien',
  'bz': 'Belize',
  'bj': 'Benin',
  'bm': 'Bermuda',
  'bt': 'Bhutan',
  'bo': 'Bolivien',
  'ba': 'Bosnien/Herzegowina',
  'bw': 'Botsuana',
  'bv': 'Bouvetinsel',
  'br': 'Brasilien',
  'bn': 'Brunei Darussalam',
  'bg': 'Bulgarien',
  'bf': 'Burkina Faso',
  'bi': 'Burundi',
  'cv': 'Cabo Verde',
  'cl': 'Chile',
  'cn': 'China',
  'ck': 'Cookinseln',
  'cr': 'Costa Rica',
  'cu': 'Cuba',
  'dm': 'Dominica',
  'do': 'Dominikanische Republik',
  'dj': 'Dschibuti',
  'dk': 'Dänemark',
  'ec': 'Ecuador',
  'sv': 'El Salvador',
  'ci': 'Elfenbeinküste',
  'er': 'Eritrea',
  'ee': 'Estland',
  'fk': 'Falklandinseln',
  'fj': 'Fidschi',
  'fi': 'Finnland',
  'fr': 'Frankreich',
  'gf': 'Französisch-Guayana',
  'pf': 'Französisch-Polynesien',
  'tf': 'Französische Südpolarterritorien',
  'fo': 'Färöer',
  'ga': 'Gabun',
  'gm': 'Gambia',
  'ge': 'Georgien',
  'gh': 'Ghana',
  'gi': 'Gibraltar',
  'gd': 'Grenada',
  'gr': 'Griechenland',
  'gb': 'Großbritannien',
  'gl': 'Grönland',
  'gp': 'Guadeloupe',
  'gu': 'Guam',
  'gt': 'Guatemala',
  'gg': 'Guernsey',
  'gw': 'Guinea-Bissau',
  'gn': 'Guinea',
  'gy': 'Guyana',
  'ht': 'Haiti',
  'hm': 'Heard und McDonaldinseln',
  'hn': 'Honduras',
  'hk': 'Hong Kong',
  'in': 'Indien',
  'id': 'Indonesien',
  'iq': 'Irak',
  'ir': 'Iran',
  'ie': 'Irland',
  'is': 'Island',
  'im': 'Isle Of Man',
  'il': 'Israel',
  'it': 'Italien',
  'jm': 'Jamaika',
  'jp': 'Japan',
  'ye': 'Jemen',
  'je': 'Jersey',
  'jo': 'Jordanien',
  'vi': 'Jungferninseln, Amerikanische',
  'vg': 'Jungferninseln, Britische',
  'ky': 'Kaimaninseln',
  'kh': 'Kambodscha',
  'cm': 'Kamerun',
  'ca': 'Kanada',
  'kz': 'Kasachstan',
  'ke': 'Kenia',
  'kg': 'Kirgisistan',
  'ki': 'Kiribati',
  'cc': 'Kokosinseln',
  'co': 'Kolumbien',
  'km': 'Komoren',
  'cg': 'Kongo',
  'hr': 'Kroatien',
  'kw': 'Kuwait',
  'la': 'Laos',
  'ls': 'Lesotho',
  'lv': 'Lettland',
  'lb': 'Libanon',
  'lr': 'Liberia',
  'ly': 'Libyen',
  'li': 'Liechtenstein',
  'lt': 'Litauen',
  'mo': 'Macao',
  'mg': 'Madagaskar',
  'mw': 'Malawi',
  'my': 'Malaysia',
  'mv': 'Maldiven',
  'ml': 'Mali',
  'mt': 'Malta',
  'mh': 'Marshallinseln',
  'mq': 'Martinique',
  'mr': 'Mauretanien',
  'mu': 'Mauritius',
  'yt': 'Mayotte',
  'mk': 'Mazedonien',
  'mx': 'Mexiko',
  'fm': 'Mikronesien',
  'md': 'Moldawien',
  'mc': 'Monaco',
  'mn': 'Mongolei',
  'me': 'Montenegro',
  'ms': 'Montserrat',
  'ma': 'Morokko',
  'mz': 'Mosambik',
  'mm': 'Myanmar',
  'na': 'Namibia',
  'nr': 'Nauru',
  'np': 'Nepal',
  'nc': 'Neukaledonien',
  'nz': 'Neuseeland',
  'ni': 'Nicaragua',
  'nl': 'Niederlande',
  'an': 'Niederländische Antillen',
  'ng': 'Nigeria',
  'ne': 'Niger',
  'nu': 'Niue',
  'kp': 'Nordkorea',
  'nf': 'Norfolkinsel',
  'no': 'Norwegen',
  'mp': 'Nördliche Marianen',
  'om': 'Oman',
  'pk': 'Pakistan',
  'pw': 'Palau',
  'ps': 'Palestina',
  'pa': 'Panama',
  'pg': 'Papua-Neuguinea',
  'py': 'Paraguay',
  'pe': 'Peru',
  'ph': 'Philippinen',
  'pn': 'Pitcairninseln',
  'pl': 'Polen',
  'pt': 'Portugal',
  'pr': 'Puerto Rico',
  'qa': 'Qatar',
  'rw': 'Ruanda',
  'ro': 'Rumänien',
  'ru': 'Russland',
  're': 'Réunion',
  'sb': 'Salomonen',
  'zm': 'Sambia',
  'ws': 'Samoa',
  'sm': 'San Marino',
  'sa': 'Saudi-Arabien',
  'se': 'Schweden',
  'sn': 'Senegal',
  'rs': 'Serbien',
  'sc': 'Seychellen',
  'sl': 'Sierra Leone',
  'zw': 'Simbabwe',
  'sg': 'Singapur',
  'sk': 'Slowakische Republik',
  'si': 'Slowenien',
  'so': 'Somalia',
  'es': 'Spanien',
  'lk': 'Sri Lanka',
  'bl': 'St. Barthélemy',
  'sh': 'St. Helena',
  'kn': 'St. Kitts/Nevis',
  'lc': 'St. Lucia',
  'mf': 'St. Martin',
  'pm': 'St. Pierre/Miquelon',
  'vc': 'St. Vincent/Die Grenadinen',
  'sd': 'Sudan',
  'sr': 'Surinam',
  'sj': 'Svalbard/Jan Mayen',
  'sz': 'Swasiland',
  'sy': 'Syrien',
  'st': 'São Tomé/Príncipe',
  'za': 'Südafrika',
  'gs': 'Südgeorgien/Südlichen Sandwichinseln',
  'kr': 'Südkorea',
  'tj': 'Tadschikistan',
  'tw': 'Taiwan',
  'tz': 'Tansania',
  'th': 'Thailand',
  'tl': 'Timor-Leste',
  'tg': 'Togo',
  'tk': 'Tokelau',
  'to': 'Tonga',
  'tt': 'Trinidad und Tobago',
  'td': 'Tschad',
  'cz': 'Tschechoslowakei',
  'tn': 'Tunisien',
  'tm': 'Turkmenistan',
  'tc': 'Turks- und Caicosinseln',
  'tv': 'Tuvalu',
  'tr': 'Türkei',
  'ug': 'Uganda',
  'ua': 'Ukraine',
  'hu': 'Ungarn',
  'um': 'United States Minor Islands',
  'uy': 'Uruguay',
  'us': 'USA',
  'uz': 'Usbekistan',
  'vu': 'Vanuatu',
  'va': 'Vatikanstadt',
  've': 'Venezuela',
  'ae': 'Vereinigte Arabische Emirate',
  'vn': 'Vietnam',
  'wf': 'Wallis/Futuna',
  'cx': 'Weihnachtsinsel',
  'by': 'Weißrussland',
  'eh': 'Westsahara',
  'cf': 'Zentralafrikanische Republik',
  'cy': 'Zypern'
};
