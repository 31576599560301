// @flow
import React from 'react';
import { connect } from 'react-redux';

import { CounterWidget } from 'components/widgets';

import { Settings, stateToProps } from './chat.setting';

@connect(stateToProps)
export class ChatCircle extends Settings {

  render () {
    return (
      <CounterWidget
        title="Chat"
        value={this.props.conversations}
        circleTitle={this.props.conversations !== 1 ? 'Conversations' : 'Conversation'}/>
    );
  }

}
