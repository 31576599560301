// @flow
import React from 'react';

import moment from 'moment';

import { connectTranslation } from 'middleware/connect';

import { Chips } from 'components/layouts';

import { Setting } from './application-row.setting';

@connectTranslation()
export class ApplicationRow extends Setting {

  render () {
    const templateType: string = this.props.template.templateType;
    return (
      <tr
        onClick={() => this.props.onClick()}
        className="application-row">
        <td>
          <div className="item-cell">
            <div className="item-title">
              {this.props.template.subject}
            </div>
            <div className="item-text">
              {templateType === 'EJ'
                ? templateType + ' ' + this.props.t('portal.candidate.applications.list.template')
                : templateType}
            </div>
          </div>
        </td>
        {
          this.props.fullInfo && <td>
            <div className="item-cell mobile-application-sent-letter-cell">
              <div className="item-title">
                {this.props.template.jobTitle
                  ? this.props.template.jobTitle.name
                  : this.props.t('portal.candidate.applications.list.no-job-title')}
              </div>
              <div>{this.props.template.companyName}</div>
            </div>
          </td>
        }
        <td>
          <div className="branch-cell">
            <Chips>{moment(this.props.template.date).format('ll')}</Chips>
          </div>
        </td>
      </tr>
    );
  }

}
