import React from 'react';

import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';

import { LinkButton } from 'components/buttons';

import { Setting, stateToProps } from './ask-me.setting';

@connectStore(stateToProps, null, true)
export class AskMeTemplate extends Setting {

  findGermanLocale () {
    if (this.props.eventData.length && this.props.eventData[3]) {
      const locale = this.props.eventData[3].value.find(item => item.locale === 'GE');
      return locale && locale.message;
    }
  }

  initEnglishMessage () {
    return this.props.eventData.length && this.props.eventData[1] ? this.props.eventData[1].value : '';
  }

  initGermanMessage () {
    return this.findGermanLocale() || this.initEnglishMessage();
  }

  render () {
    const redirectLink = this.props.eventData && this.props.eventData[2] && this.props.eventData[2].value;
    return (
      <div className="notification-item-content flexible">
        <div className="logo-container">
          <img src="/images/notifications/userpick.svg" alt="logo"/>
        </div>
        <div className="content">
          <h3>
            <Trans i18nKey="portal.notifications.new-ask-me.title">
              New Ask Me content!
            </Trans>
          </h3>
          <p dangerouslySetInnerHTML={{
            __html: this.state.localization === 'en' ? this.initEnglishMessage() : this.initGermanMessage()
          }}/>
          <p>
            <Trans
              i18nKey="portal.notifications.new-ask-me.description.content">
              Your can see new ask me content
            </Trans>
            {
              this.props.candidate.id
                ? <LinkButton
                  onClick={() => this.props.hideModal()}
                  link={`/portal/candidate/${redirectLink[0] ? redirectLink[0].value : ''}/ask-me`}
                  inline>
                  <Trans i18nKey="portal.notifications.new-ask-me.description.here">
                    here.
                  </Trans>
                </LinkButton>
                : <LinkButton
                  onClick={() => this.props.hideModal()}
                  link={`/portal/recruiter/${redirectLink[0] ? redirectLink[0].value : ''}/ask-me`}
                  inline>
                  <Trans i18nKey="portal.notifications.new-ask-me.description.here">
                    here.
                  </Trans>
                </LinkButton>
            }
          </p>
          <span className="publish-date">{this.props.publishDate}</span>
        </div>
      </div>
    );
  }

}
