// @flow
import { Component } from 'react';

import { TransProps } from 'react-i18next';

type Props = {
  t: TransProps
}

export class Settings extends Component<Props> {}
