// @flow
import React, { Fragment } from 'react';

import moment from 'moment';

import { connectTranslation } from 'middleware/connect';
import { Button, IconButton } from 'components/buttons';

import { PhotoGallery } from 'components/photo-gallery/photo-gallery';

import { CandidateDto } from 'rest/candidates/candidate.dto';
import { Setting } from './candidate-info.setting';
import { Trans } from 'react-i18next';

@connectTranslation()
export class PreviewCandidateInfo extends Setting {

  render () {
    const candidate: CandidateDto = this.props.candidate;
    const isCandidateHasPhoto: boolean = candidate.candidatePhotos[0] && candidate.candidatePhotos[0].url;

    return (
      <div className="preview-candidate-info-container">
        {
          !this.props.restricted && <div className="actions-buttons">
            <Button
              recruiter
              className="invite-button"
              disabled={candidate.candidateInviteStatus}
              onClick={() => this.props.inviteAction()}>
              {candidate.candidateInviteStatus
                ? (candidate.candidateInviteStatus === 'INVITED'
                  ? <Trans i18nKey="portal.recruiter.calendar.status.invited">
                    Invited
                  </Trans>
                  : candidate.candidateInviteStatus === 'EMPLOYED'
                    ? <Trans i18nKey="portal.recruiter.calendar.status.accepted">
                      Accepted
                    </Trans> : <Trans i18nKey="portal.recruiter.calendar.status.interviewed">
                      Interviewed
                    </Trans>)
                : this.props.t('portal.recruiter.candidates.profile.info.invite')}
            </Button>
            <IconButton
              outlined
              recruiter
              title={candidate.favorite
                ? <span>
                  <Trans i18nKey="portal.recruiter.candidates.profile.info.favorite.remove">
                    Remove from favorite
                  </Trans>
                </span>
                : <span>
                  <Trans i18nKey="portal.recruiter.candidates.profile.info.favorite.add">
                    Add to favorite
                  </Trans>
                </span>}
              onClick={() => this.props.favoritesToggle(candidate)}
              icon={candidate.favorite ? 'icon-favorites-on' : 'icon-favorites-off'}/>
          </div>
        }
        {
          isCandidateHasPhoto
            ? <PhotoGallery photos={candidate.candidatePhotos}/>
            : <div className="fake">
              <span className="restricted-activity">
                <Trans
                  i18nKey={
                    `portal.recruiter.candidates.profile.info.${candidate.anonymous ? 'anonymous' : 'no-picture'}`
                  }>
                  No profile picture
                </Trans>
              </span>
            </div>
        }
        {
          !this.props.restricted ? <Fragment>
            <div className="main-info">
              <h3> {candidate.fullName} {candidate.birthday ? `(${candidate.age})` : ''} </h3>
              <span className="last-online">
                {
                  candidate.lastActivity
                    ? <span>
                      <Trans i18nKey="portal.recruiter.candidates.profile.info.online.used">
                        Was online
                      </Trans>
                      &nbsp;
                      {moment(candidate.lastActivity).parseZone().fromNow()}
                    </span>
                    : this.props.t('portal.recruiter.candidates.profile.info.online.never')
                }
              </span>
            </div>
            <div className="set-contact-btns">
              <Button
                onClick={() => this.props.startCandidateChat(candidate.id)}
                outlined
                disabled={!candidate.directContact}
                recruiter>
                <img src="/images/candidate/preview/conversation-icon.svg"/>
                <Trans i18nKey="portal.recruiter.candidates.profile.info.chat">
                  Chat
                </Trans>
              </Button>
              <Button
                outlined
                onClick={() => this.props.followToggle(candidate)}
                recruiter>
                <img src="/images/candidate/preview/user-add-icon.svg"/>
                {candidate.follow
                  ? this.props.t('portal.recruiter.candidates.profile.info.follow.remove')
                  : this.props.t('portal.recruiter.candidates.profile.info.follow.add')}
              </Button>
            </div>
            <div className="contact-info">
              {
                (candidate.email && candidate.directContact) && <div className="contact-item">
                  <div className="contact-label">
                    <Trans i18nKey="portal.recruiter.candidates.profile.info.email">
                      Email Address
                    </Trans>
                  </div>
                  <div className="contact-data">{candidate.email}</div>
                </div>
              }
              {
                (candidate.phoneNumber && candidate.directContact) && <div className="contact-item">
                  <div className="contact-label">
                    <Trans i18nKey="portal.recruiter.candidates.profile.info.phone">
                      Phone Number
                    </Trans>
                  </div>
                  <div className="contact-data">{candidate.phoneNumber}</div>
                </div>
              }
              {
                candidate.candidateAddress && <div className="contact-item">
                  <div className="contact-label">
                    <Trans i18nKey="portal.recruiter.candidates.profile.info.location">
                      Location
                    </Trans>
                  </div>
                  <div className="contact-data">
                    {candidate.candidateAddress.country}, {candidate.candidateAddress.city}
                  </div>
                </div>
              }
            </div>
            <div className="extra-actions">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={this.props.cvLink}
                className="btn grey preview-link">
                <Trans i18nKey="portal.recruiter.candidates.profile.info.cv">
                  See Candidate CV (pdf)
                </Trans>
              </a>
            </div>
          </Fragment> : <div className="blured-info half">
            <span className="restricted-activity">
              {
                candidate.lastActivity
                  ? <span>
                    <Trans i18nKey="portal.recruiter.candidates.profile.info.online.used">
                      Was online
                    </Trans> {moment(candidate.lastActivity).parseZone().fromNow()}
                  </span>
                  : this.props.t('portal.recruiter.candidates.profile.info.online.never')
              }
            </span>
          </div>
        }
      </div>
    );
  }

}
