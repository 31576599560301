// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

export class Settings extends Component {}

export const stateToProps = (state: GlobalState) => ({
  total: state.portal.candidate.blog.totalElements
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);
