// @flow
import * as actions from './stats.constants';

import type { ApplicationsStatsType } from './stats.type';

import { SentApplicationsStatsDto } from 'rest/candidates/applications/applications.stats.dto';

const initialState: ApplicationsStatsType = {
  templates: new SentApplicationsStatsDto(),
  sentApplications: new SentApplicationsStatsDto()
};

export default function (state: ApplicationsStatsType = initialState, action): ApplicationsStatsType {
  switch (action.type) {
    case actions.GET_TEMPLATES_STATS_SUCCESS:
      return {
        ...state,
        templates: new SentApplicationsStatsDto(action.content)
      };

    case actions.GET_SENT_TEMPLATES_STATS_SUCCESS:
      return {
        ...state,
        sentApplications: new SentApplicationsStatsDto(action.content)
      };

    case actions.RESET_APPLICATION_STATS_SUCCESS:
      return {
        ...initialState
      };

    default:
      return state;
  }
}
