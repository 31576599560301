import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { getLocalization } from 'middleware/localization';

import en from './locales/en';
import de from './locales/de';
import fr from './locales/fr';
import ru from './locales/ru';
import uk from './locales/uk';
import sp from './locales/sp';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en
      },
      de: {
        translation: de
      },
      fr: {
        translation: fr
      },
      ru: {
        translation: ru
      },
      uk: {
        translation: uk
      },
      sp: {
        translation: sp
      }
    },
    lng: getLocalization(),
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });
export default i18n;
