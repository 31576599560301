export class AddressDto {

  city: string;
  line: string;
  street: string;
  country: string;

  constructor (data?: AddressDto) {
    if (data) {
      this.city = data.city;
      this.line = data.line;
      this.street = data.street;
      this.country = data.country;
    }
  }

}
