export class AuthOutDto {

  code: string;
  type: string;
  state: string;

  constructor (data?: AuthOutDto) {
    if (data) {
      this.code = data.code;
      this.type = data.type;
      this.state = data.state;
    }
  }

}
