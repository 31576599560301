import React from 'react';

import moment from 'moment';
import { Trans } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { Chips } from 'components/layouts';
import { Spinner } from 'components/elements';
import { RadioControl } from 'components/controls';
import { Button, LinkButton } from 'components/buttons';

import { Modal, ModalCaption } from 'containers/portal/modal-container';

import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

import type { Props } from './existing-list-modal.setting';
import { Setting, stateToProps, dispatchToProps } from './existing-list-modal.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class ExistingJobOffersListModal extends Setting {

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.open !== this.props.open) {
      this.props.getAllCompanyJobOffers(this.props.currentUser.companyId);
      this.setState({ activeJobOfferId: nextProps.jobOffers[0] ? nextProps.jobOffers[0].jobOfferId : '' });
    }
  }

  componentWillUnmount () {
    this.props.resetJobOffers();
  }

  changeSelectedState (jobOfferId: string) {
    this.setState({ activeJobOfferId: jobOfferId });
  }

  render () {
    return (
      <Modal
        overlay
        animation="fade"
        open={this.props.open}
        className="existing-job-offers-modal"
        closeOnOverlay={() => this.props.hideModal()}
        hideModal={() => this.props.hideModal()}>
        <ModalCaption
          hideModal={event => this.props.hideModal(event)}
          title={this.props.t('portal.recruiter.balance-board.existing.title')}/>
        <div className="job-offers-list-content">
          <PerfectScrollbar className="content">
            <div>
              <p>
                <Trans i18nKey="portal.recruiter.balance-board.existing.description">
                  You`ll create new job offer based on existing one.
                </Trans>
              </p>
              <ul className="existing-job-offers-list">
                {
                  this.props.jobOffers.map((jobOffer: JobOffersResponseDto, index) => {
                    const checked: boolean = this.state.activeJobOfferId === jobOffer.jobOfferId;
                    return (
                      <li
                        onClick={() => this.changeSelectedState(jobOffer.jobOfferId)}
                        className={`existing-job-offers-item ${checked ? 'active' : ''}`}
                        key={index}>
                        <div className="job-title">
                          <RadioControl checked={checked}/>
                          <div className="content">
                            <h4>{ jobOffer.jobTitleName}</h4>
                            <span>{ moment(jobOffer.updatedDate).parseZone().fromNow()}</span>
                          </div>
                        </div>
                        <div className="branches">
                          <Chips>{ jobOffer.branchName }</Chips>
                          <Chips>{ jobOffer.unitName }</Chips>
                        </div>
                      </li>
                    );
                  })
                }
              </ul>
              {
                !this.props.jobOffers.length && <p className="empty-list">
                  <Trans i18nKey="portal.recruiter.balance-board.existing.empty">
                    Empty job offer list
                  </Trans>
                </p>
              }
            </div>
          </PerfectScrollbar>
          <div className="actions">
            <LinkButton
              recruiter
              medium
              onClick={() => this.props.hideModal()}
              link={`/portal/recruiter/balance-board/${this.state.activeJobOfferId}/re-use`}>
              <Trans i18nKey="portal.recruiter.balance-board.existing.buttons.create">
                Start Creating
              </Trans>
            </LinkButton>
            <Button
              grey
              onClick={() => this.props.hideModal()}>
              <Trans i18nKey="portal.recruiter.balance-board.existing.buttons.cancel">
                Cancel
              </Trans>
            </Button>
          </div>
        </div>
        <Spinner show={this.props.fetching}/>
      </Modal>
    );
  }

}
