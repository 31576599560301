import React from 'react';

import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Chips } from 'components/layouts';
import { Button } from 'components/buttons';
import { DateTime, ShowSalaryRange } from 'components/elements';

import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

import { Settings } from './job-offer-info.setting';

@connectTranslation()
export class PreviewJobOfferInfo extends Settings {

  render () {
    const jobOffer: JobOffersResponseDto = this.props.jobOffer;
    const isWorkingTimePresent: boolean = jobOffer.salaryRange && jobOffer.salaryRange.workingHoursPerMonth;

    return (
      <div className="general-job-offer-modal-preview mobile-general-job-offer-modal-preview">
        <div className="head-job-offer">
          <h4 className="job-title">{jobOffer.jobTitleName}</h4>
          <div className="additional-info">
            <div className="branches">
              {
                jobOffer.branchName && <Chips>{jobOffer.branchName}</Chips>
              }
              {
                jobOffer.unitName && <Chips>{jobOffer.unitName}</Chips>
              }
            </div>
          </div>
          <div className="mobile-company-description-btn">
            <Button
              candidate
              outlined
              onClick={() => this.props.openDescription()}>
              <Trans i18nKey="portal.candidate.job-offers.preview.mobile.company-description">
                See company description
              </Trans>
            </Button>
          </div>
        </div>
        <ul className="job-summary-info">
          {
            jobOffer.referenceNumber && <li>
              <b>
                <Trans i18nKey="portal.recruiter.balance-board.preview.reference">
                  Reference Number:
                </Trans>
              </b>
              <span>{jobOffer.referenceNumber}</span>
            </li>
          }
          <li>
            <b>
              <Trans i18nKey="portal.candidate.job-offers.preview.info.working">
                Working time:
              </Trans>
            </b>
            <span>
              {isWorkingTimePresent &&
              `${jobOffer.salaryRange.workingHoursPerMonth}
              ${this.props.t('portal.candidate.job-offers.preview.info.hours')}`}
              {!isWorkingTimePresent && this.props.t('portal.candidate.job-offers.preview.info.no-information')}
            </span>
          </li>
          <li>
            <b>
              <Trans i18nKey="portal.candidate.job-offers.preview.info.job-type">
                Job type:
              </Trans>
            </b>
            <span>
              {
                jobOffer.jobType === 'Employee'
                  ? <Trans i18nKey="portal.recruiter.job-offers.list.job-type.full-time">
                    Full-Time
                  </Trans>
                  : jobOffer.jobType === 'Temporary'
                    ? <Trans i18nKey="portal.recruiter.job-offers.list.job-type.temporary">
                      Temporary
                    </Trans> : <Trans i18nKey="portal.recruiter.job-offers.list.job-type.trainee">
                      Trainee
                    </Trans>
              }
            </span>
          </li>
          {(!jobOffer.salaryRange || (!jobOffer.salaryRange.min && !jobOffer.salaryRange.max))
            ? ''
            : <li>
              <b>
                <Trans i18nKey="portal.candidate.job-offers.preview.info.salary-range">
                  Salary range:
                </Trans>
              </b>
              <ShowSalaryRange salaryRange={jobOffer.salaryRange}/>
            </li>
          }
          <li>
            <b>
              <Trans i18nKey="portal.candidate.job-offers.preview.info.available-from">
                Available from:
              </Trans>
            </b>
            {
              jobOffer.availableFrom
                ? <span><DateTime value={jobOffer.availableFrom}/></span>
                : <span>
                  <Trans i18nKey="portal.candidate.job-offers.preview.info.no-information">
                    No information
                  </Trans>
                </span>
            }
          </li>
        </ul>
      </div>
    );
  }

}
