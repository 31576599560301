import React, { Fragment, Component } from 'react';
import { Route } from 'react-router-dom';

import { MatchJobOffersListPage } from './list';
import { MatchCandidatesListPage } from './candidates';

export class MatchCandidatesPage extends Component {

  render () {
    return (
      <Fragment>
        <Route
          path="/portal/recruiter/match"
          component={MatchJobOffersListPage}/>
        <Route
          path="/portal/recruiter/match/:id/candidates"
          component={MatchCandidatesListPage}/>
      </Fragment>
    );
  }

}
