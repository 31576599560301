// @flow
import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import { Modal } from 'components/dialogs';

import { PreviewContainer } from 'containers/portal/recruiter/job-offer';
import type { Match } from 'common/types/match';

type Props = {
  match: Match
}
export class JobOfferPreviewPage extends Component<Props> {

  render () {
    return (
      <Modal closePath={`/portal/candidate/follow/${this.props.match.params['type']}`}>
        <Route
          path="/portal/candidate/:url/:type/:jobOfferId/preview"
          exact
          component={PreviewContainer}/>
      </Modal>
    );
  }

}
