import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import moment from 'moment';

import { ListDto } from 'middleware/dto/list';
import { JobTitleDto } from 'rest/job-title/job-title.dto';
import { SalaryRangeDto } from 'rest/job-offers/job-offers.salary-range.dto';
import { RecruiterResponseDto } from 'rest/recruiter/recruiter.response.dto';
import { JobTypeDto } from 'components/controls/switch-control/switch-control.job-type.dto';

import { getAllUnits } from 'services/portal/units/units.actions';
import { getAllJobTitles } from 'services/portal/job-titles/job-titles.actions';

import './general-info.scss';

type Props = {
  units: ListDto[],
  prev: () => void,
  next: () => void,
  save: () => void,
  getAllUnits: () => void,
  jobTitles: JobTitleDto[],
  getAllJobTitles: () => void,
  currentUser: RecruiterResponseDto
};

type State = {
  unitId: string,
  jobType: string,
  companyId: string,
  unitError: boolean,
  jobTitleId: string,
  recruiterId: string,
  salaryError: boolean,
  availableFrom: moment,
  jobTitleError: boolean,
  jobDescription: string,
  referenceNumber: string,
  salaryRangeError: boolean,
  salaryRange: SalaryRangeDto,
  errorCollection: Set<string>,
  workingHoursPerMonth: number
}

export class Settings extends Component<Props, State> {

  static defaultProps = {
    prev: () => {},
    next: () => {},
    save: () => {},
    getAllUnits: () => {},
    getAllJobTitles: () => {},
    currentUser: new RecruiterResponseDto()
  };

  state: State = {
    unitId: '',
    companyId: '',
    jobTitleId: '',
    recruiterId: '',
    unitError: false,
    salaryError: false,
    jobDescription: '',
    referenceNumber: '',
    jobTitleError: false,
    salaryRangeError: false,
    availableFrom: moment(),
    workingHoursPerMonth: 40,
    errorCollection: new Set(),
    jobType: new JobTypeDto({}),
    salaryRange: new SalaryRangeDto({
      min: null,
      max: null,
      salaryType: 'MONTH'
    })
  }

}

export const stateToProps = (state: GlobalState) => ({
  units: state.portal.units.unitsList,
  jobTitles: state.portal.jobTitles.jobTitlesList
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getAllUnits,
  getAllJobTitles
}, dispatch);
