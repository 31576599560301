// @flow
import React from 'react';

import cn from 'classnames';

import moment from 'moment';
import DatePicker from 'react-datepicker';

import { DateCustomInput } from './date-custom-input/date-custom-input';

import { Setting } from './date-control.setting';

export class DateControl extends Setting {

  componentDidMount () {
    this.listenWindowWidth();
    window.addEventListener('resize', () => this.listenWindowWidth());
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.value !== this.state.value) {
      this.setState({ value: nextProps.value });
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', () => this.listenWindowWidth());
  }

  listenWindowWidth () {
    this.setState({ mobileView: window.innerWidth < 993 });
  }

  changeCurrentDate (date: string) {
    this.props.change(date);
    this.setState({ value: date });
  }

  render () {
    const datepickerClasess: string = cn({
      'date-picker': true,
      recruiter: this.props.recruiter,
      [this.props.className]: true
    });
    return (
      <div className={datepickerClasess}>
        <label className="form-label">
          <span className="form-label-title">{this.props.label ? this.props.label : null}</span>
          <span className="notes">{this.props.notes ? this.props.notes : null}</span>
          <DatePicker
            showWeekNumbers
            scrollableYearDropdown
            selected={new Date(this.state.value)}
            maxDate={this.props.maxDate || moment().add(5, 'year').toDate()}
            minDate={this.props.minDate}
            popperClassName="popper-container"
            className="date-control-container"
            dateFormat={this.props.dateFormat}
            monthsShown={this.props.monthsCount}
            onChange={date => this.changeCurrentDate(date)}
            showYearDropdown={true}
            yearDropdownItemNumber={100}
            placeholderText="Click to select a date"
            customInput={<DateCustomInput/>}/>
        </label>
      </div>
    );
  }

}
