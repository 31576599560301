// @flow
import React from 'react';

import { Trans } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { uniqueArray } from 'middleware/unique-array';
import { connectStore } from 'middleware/connect';
import { PaginationDto } from 'middleware/dto';

import { ListPlaceholder, Spinner } from 'components/elements';

import { PostsResponseDto } from 'rest/candidates/posts/posts.response.dto';

import { PostItem } from './post-item/post-item';

import { Setting, stateToProps, dispatchToProps } from './posts-list.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class PostsList extends Setting {

  componentWillUnmount () {
    this.props.resetPostList();
  }

  fetchPosts (page: number = 0) {
    const query = new PaginationDto();
    query.page = page;
    query.size = 10;
    query.candidateId = this.props.currentCandidate ? this.props.currentCandidate.id : '';
    this.props.getPosts(query);
  }

  render () {
    return (
      <div className="post-list-container">
        {
          this.props.currentCandidate.id ? <PerfectScrollbar className="overflow-modal-content">
            <InfiniteScroll
              pageStart={-1}
              hasMore={this.props.hasMoreItems}
              loadMore={() => this.fetchPosts(this.props.currentPage)}
              useWindow={false}
              threshold={150}>
              <ul className="post-list">
                {
                  uniqueArray(this.props.posts, 'id').map((post: PostsResponseDto, index: number) => (
                    <PostItem
                      action={() => this.props.history.push(`/portal/candidate/posts/preview/${post.id}`)}
                      key={post.id || index}
                      post={post}/>
                  ))
                }
                {
                  !this.props.posts.length > 0 && <ListPlaceholder
                    title={this.props.t('portal.candidate.blog.list.placeholder.title')}>
                    <Trans i18nKey="portal.candidate.blog.list.placeholder.content">
                      Share your professional pictures and content to catch recruiters` attention.
                    </Trans>
                  </ListPlaceholder>
                }
              </ul>
            </InfiniteScroll>
          </PerfectScrollbar> : null
        }
        <Spinner show={this.props.fetching}/>
      </div>
    );
  }

}
