// @flow
import React from 'react';
import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Button } from 'components/buttons';

import { Modal, ModalCaption } from 'containers/portal/modal-container';

import { Setting } from './delete-modal.setting';

@connectTranslation()
export class DeletePostModal extends Setting {

  delete () {
    this.props.deleteAction();
    this.props.hideModal();
  }

  render () {
    return (
      <Modal
        overlay
        animation="fade"
        open={this.props.open}
        hideModalAvailable={false}
        className="mobile-delete-post"
        hideModal={() => this.props.hideModal()}
        closeOnOverlay={() => this.props.hideModal()}>
        <ModalCaption
          hideModal={() => this.props.hideModal()}
          title={this.props.t('portal.candidate.blog.delete.title')}/>
        <div className="delete-modal-content">
          <p className="confirm-delete">
            <Trans i18nKey="portal.candidate.blog.delete.description">
              Are you sure you want to delete this post?
            </Trans>
          </p>
          <div className="delete-action">
            <Button
              onClick={() => this.delete()}
              candidate>
              <Trans i18nKey="portal.candidate.blog.delete.buttons.yes">
                Yes, Delete
              </Trans>
            </Button>
            <Button
              onClick={() => this.props.hideModal()}
              grey>
              <Trans i18nKey="portal.candidate.blog.delete.buttons.no">
                No, Leave
              </Trans>
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

}
