// @flow
import React from 'react';
import { connect } from 'react-redux';

import { NotificationModal } from 'containers/portal/notifications';
import { RecruiterProfileModal } from 'containers/portal/recruiter/profile';
import { CandidateProfileModal } from 'containers/portal/candidate/profile';

import { modalTypes } from 'services/portal/modal/modal.constants';

import { Settings, stateToProps } from './container.setting';

@connect(stateToProps)
export class ModalContainer extends Settings {

  render () {
    return (
      <div>
        <RecruiterProfileModal
          history={this.props.history}
          open={this.props.modalType === modalTypes.COMPANY_PROFILE_MODAL}/>
        <CandidateProfileModal
          history={this.props.history}
          open={this.props.modalType === modalTypes.CANDIDATE_PROFILE_MODAL}/>
        <NotificationModal open={this.props.modalType === modalTypes.NOTIFICATIONS_MODAL}/>
      </div>
    );
  }

}
