// @flow
import React, { Component } from 'react';

import './avatar.scss';
import './avatar.responsive.scss';
import 'react-image-crop/lib/ReactCrop.scss';

type Props = {
  crop: Object,
  error?: boolean,
  accept?: string,
  className: string,
  loading?: boolean,
  errorText?: string,
  multiple?: boolean,
  showRoundIcon?: boolean,
  showSquareIcon?: boolean,
  previewImageUrl?: string,
  uploadButtonName?: string,
  uploadButtonType?: string,
  clearImageState: () => void,
  change: (file: File) => void,
}

type State = {
  file: string
}

export class Settings extends Component<Props, State> {

  static defaultProps = {
    error: false,
    errorText: '',
    loading: false,
    multiple: false,
    previewImageUrl: '',
    showRoundIcon: true,
    showSquareIcon: true,
    clearImageState: () => {},
    uploadButtonType: 'accent',
    change: (file: File) => {},
    accept: 'image/png, image/jpeg',
    uploadButtonName: 'Upload New Picture',
    crop: {
      unit: '%',
      width: 100,
      height: 100,
      locked: true,
      aspect: 1
    }
  };

  state: State = {
    file: ''
  };

  inputUpload: any = React.createRef();

}
