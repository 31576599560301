import { ImageDto } from '../images/image.dto';

export class RecruiterInfoDto {

  email: string;
  token: string;
  password: string;
  lastName: string;
  firstName: string;
  phoneNumber: string;
  recruiterPhoto: ImageDto;

  constructor (data?: RecruiterInfoDto) {
    if (data) {
      this.email = data.email;
      this.token = data.token;
      this.password = data.password;
      this.lastName = data.lastName;
      this.firstName = data.firstName;
      this.phoneNumber = data.phoneNumber;
      this.recruiterPhoto = new ImageDto(data);
    }
  }

}
