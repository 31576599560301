import axios, { AxiosPromise } from 'axios';
import { SkillsDto } from 'rest/skill/skills.dto';

import { JobOfferDto } from './job-offers.dto';
import { JobOfferStatus } from './job-offers.status.dto';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

export async function createJobOffer (data: JobOfferDto) {
  try {
    return await axios.post('/job-offers', data);
  } catch (error) {
    throw error;
  }
}

export async function createJobOfferSkills (jobOfferId: string, data: SkillsDto) {
  try {
    return await axios.post(`/job-offers/${jobOfferId}/skills`, data);
  } catch (error) {
    throw error;
  }
}

export async function getJobOfferById (jobOfferId: string) {
  try {
    return await axios.get(`/job-offers/${jobOfferId}`);
  } catch (error) {
    throw error;
  }
}

export async function deleteJobOffer (jobOfferId: string) {
  try {
    return await axios.delete(`/job-offers/${jobOfferId}`);
  } catch (error) {
    throw error;
  }
}

export async function publishJobOffer (jobOfferId: string) {
  try {
    return await axios.put(`/job-offers/${jobOfferId}/publish`);
  } catch (error) {
    throw error;
  }
}

export async function updateJobOffer (jobOfferId: string, data: JobOfferDto) {
  try {
    return await axios.put(`/job-offers/${jobOfferId}`, data);
  } catch (error) {
    throw error;
  }
}

export async function updateCompanyJobOfferStatus (jobOfferId: string, params: JobOfferStatus):
  AxiosPromise<JobOffersResponseDto> {
  try {
    return await axios.put(`/job-offers/${jobOfferId}/status`, {}, { params });
  } catch (error) {
    throw error;
  }
}

export async function sendJobOfferEmail (jobOfferId: string) {
  try {
    return await axios.post(`/job-offers/${jobOfferId}/send`);
  } catch (error) {
    throw error;
  }
}
