export class WorkExperienceDto {

  toDate: string;
  website: string;
  fromDate: string;
  jobTitle: string;
  location: string;
  isCurrent: boolean;
  companyName: string;
  description: string;

  constructor (data?: WorkExperienceDto) {
    if (data) {
      this.toDate = data.toDate;
      this.website = data.website;
      this.fromDate = data.fromDate;
      this.jobTitle = data.jobTitle;
      this.location = data.location;
      this.isCurrent = data.isCurrent;
      this.companyName = data.companyName;
      this.description = data.description;
    }
  }

  get experienceDate (): string {
    if (this.isCurrent) {
      return null;
    }
    return this.toDate;
  }

}
