// @flow
import React from 'react';

import moment from 'moment';

import { connectTranslation } from 'middleware/connect';

import { CounterWidget } from 'components/widgets';

import { Settings } from './calendar.setting';

@connectTranslation()
export class CalendarCircle extends Settings {

  render () {
    return (
      <CounterWidget
        title={this.props.t('portal.recruiter.calendar.circle.title')}
        value={moment().format('MMM D')}/>
    );
  }

}
