// @flow
import React, { Fragment } from 'react';

import moment from 'moment';
import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';
import { setData } from 'middleware/payment-step';

import { Button, LinkButton } from 'components/buttons';
import { Spinner } from 'components/elements';

import PromoCode from 'containers/portal/recruiter/promo-code/promo-code';
import { PremiumInfoModal } from './premium-info-modal/premium-info-modal';
import { SepaPaymentModal } from 'containers/portal/recruiter/sepa-payment-modal/sepa-payment-modal';
import {
  PaymentDetailsInvoiceInfo
} from 'containers/portal/recruiter/payment-details-invoice-info/payment-details-invoice-info';
import { PaymentOptions } from 'containers/portal/recruiter/account/settings/payment/payment-options/payment-options';
import { CompanyOrderDto } from 'rest/companies/order/order.dto';
import { SubscriptionDto } from 'rest/companies/subscription/subscription.dto';

import type { Props } from './payment-details.setting';
import { dispatchToProps, Setting, stateToProps } from './payment-details.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class PaymentDetails extends Setting {

  componentDidMount () {
    if (this.props.currentUser) {
      this.props.fetchCompanyOrders(this.props.currentUser.companyId, 'COMPLETED');
      this.props.getCompanyPremiumCostRequest(this.props.currentUser.companyId);
    }
    this.props.getCurrentCompanyRecruiter();
    // this.changeSubscription();
    this.handleVatIdChange(this.props.company.vatId);
  }

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.currentUser !== this.props.currentUser) {
      this.props.getRecruiterCompanyById(this.props.currentUser.companyId);
    }
    if (nextProps.redirectLink !== this.state.redirectLink) {
      this.setState({ redirectLink: nextProps.redirectLink });
    }
  }

  changeSubscriptionMethod (value: string) {
    this.setState({
      paymentMethod: value
    }, () => this.changeSubscription());
  }

  togglePaymentInfoModal () {
    this.setState({ isPremiumInfoModalOpen: !this.state.isPremiumInfoModalOpen });
  }

  changeSubscription () {
    const subscriptionData: SubscriptionDto = new SubscriptionDto(this.state);
    subscriptionData.subscriptionPlan = 'PREMIUM';
    subscriptionData.paymentSepaDetails = {
      userName: '',
      userEmail: '',
      iban: ''
    };

    this.props.subscribeRecruiterCompany(this.props.currentUser.companyId, subscriptionData);
  }

  handleChangePromoCode = value => {
    this.setState({
      promoCode: value,
      promoCodeIsChange: true,
      promoCodeIsFailed: false
    });
  };

  applyPromoCode = () => {
    const { currentUser: { companyId }, subscribeRecruiterCompany } = this.props;
    const { promoCode, paymentMethod, paymentRedirectUrls } = this.state;

    const CompanySubscriptionPlan: SubscriptionDto = new SubscriptionDto({
      promoCode,
      paymentMethod,
      paymentRedirectUrls,
      subscriptionPlan: 'PREMIUM'
    });

    subscribeRecruiterCompany(companyId, CompanySubscriptionPlan)
      .then(res => {
        const { amount, months } = res.link;
        const promoCodeIsValid = months > 0;

        if (!promoCodeIsValid) {
          this.setState({
            promoCodeIsFailed: true
          });
          return;
        }

        if (months === 999) {
          this.setState({
            subscriptionPlan: 'FREE'
          });
          return;
        }

        this.setState({
          amount,
          months,
          promoCodeIsValid,
          promoCodeIsChange: false
        });
      })
      .catch(() =>
        this.setState({ promoCodeIsFailed: true }));
  };

  handleSepaPaymentForm = () => {
    this.setState({
      isSepaModalOpen: !this.state.isSepaModalOpen
    });
  };

  handleEditableState = () => {
    this.setState({
      isCompanyInfoEditable: !this.state.isCompanyInfoEditable
    }, () => this.changeSubscription());
  };

  handleOnSubmit = e => {
    if (e) e.preventDefault();
    const { pathname } = this.props.history.location;
    const { paymentMethod, promoCodeIsFailed, amount } = this.state;

    setData('ej_payment_step_link', {
      path: pathname,
      activeTab: 4,
      showModal: true
    });
    if (!promoCodeIsFailed && amount === 0) {
      this.changeSubscription();
      setTimeout(() => {
        this.props.getRecruiterCompanyById(this.props.currentUser.companyId);
      }, 2000);
      setTimeout(() => {
        this.props.getRecruiterCompanyById(this.props.currentUser.companyId);
      }, 3000);
    }
    paymentMethod === 'SEPA_DIRECT_DEBIT' && this.handleSepaPaymentForm();
  };

  handleVatIdChange = (vatId: number) => {
    this.setState({ vatId: vatId });
  };

  render () {
    const { currentUser, company, ordersList, t } = this.props;
    console.log(this.props);
    const {
      paymentMethod, isSepaModalOpen, isPremiumInfoModalOpen, redirectLink,
      paymentRedirectUrls, isCompanyInfoEditable, promoCode, promoCodeIsFailed,
      promoCodeIsChange, promoCodeIsValid, months, amount
    } = this.state;

    const subscriptionPlan = company ? company.subscriptionPlan : '';
    const premiumOrdersList = ordersList.filter(item => item.amountValue);
    const isOrdersListNotEmpty = ordersList.length > 0 && premiumOrdersList.length > 0;
    const subPlanIsFree = subscriptionPlan === 'FREE';
    const subscriptionPrice: number = subPlanIsFree ? 0 : amount;
    const paymentMethodDetails: string = subPlanIsFree
      ? 'Free/Basic'
      : (promoCodeIsValid
        ? `Premium Plan with special offer for the first ${months} month${months > 1 ? 's' : ''}`
        : 'Premium Plan');

    return (
      <div className="payment-details-container">
        <div className="payment-details-container py-5">
          <p className="payment-details-subtitle">
            <Trans i18nKey="portal.recruiter.profile.payment.plan">
              Subscription Plan
            </Trans>
          </p>
          <h4 className="payment-details-title">{subscriptionPlan}</h4>
          <Fragment>
            {paymentMethod === 'SOFORT'
              ? <LinkButton
                className="large"
                link={redirectLink}
                external={true}
                outlined
                recruiter
                disabled={isCompanyInfoEditable || !this.state.vatId}
                onClick={this.handleOnSubmit}>
                {subscriptionPlan !== 'PREMIUM'
                  ? <Trans i18nKey="portal.recruiter.profile.payment.buttons.upgrade">
                    Upgrade Plan
                  </Trans>
                  : <Trans i18nKey="portal.recruiter.profile.payment.buttons.prolong">
                    Prolong
                  </Trans>
                }
              </LinkButton>
              : <Button
                type="submit"
                large
                outlined
                recruiter
                disabled={isCompanyInfoEditable || !this.state.vatId}
                onClick={this.handleOnSubmit}>
                {subscriptionPlan !== 'PREMIUM'
                  ? <Trans i18nKey="portal.recruiter.profile.payment.buttons.upgrade">
                    Upgrade Plan
                  </Trans>
                  : <Trans i18nKey="portal.recruiter.profile.payment.buttons.prolong">
                    Prolong
                  </Trans>
                }
              </Button>}
          </Fragment>
          {!this.state.vatId && <div className="error-vat-id">
            <Trans i18nKey="portal.recruiter.profile.payment.no-vat-id">
              Please specify VAT ID in change info section below
            </Trans>
          </div>}
        </div>
        <div className="payment-details-container py-2">
          <PaymentOptions
            disabled={isCompanyInfoEditable}
            defaultOption={paymentMethod}
            changeOptions={value => this.changeSubscriptionMethod(value)}/>
          <PaymentDetailsInvoiceInfo
            disabled={false}
            redirectLink={redirectLink}
            paymentMethod={paymentMethod}
            editable={isCompanyInfoEditable}
            handleVatIdChange={this.handleVatIdChange}
            handleEditableState={this.handleEditableState}
          />
          <PromoCode
            t={t}
            value={promoCode}
            isValid={promoCodeIsValid}
            isFailed={promoCodeIsFailed}
            isChange={promoCodeIsChange}
            isCompanyInfoEditable={isCompanyInfoEditable}
            handleChange={this.handleChangePromoCode}
            applyPromoCode={this.applyPromoCode}
          />
          <div className={`payment-footer${isCompanyInfoEditable ? ' disabled' : ''}`}>
            <div className="subscription-plan">
              {t('portal.guest.recruiter.job-offer.payment.subscription')}:
              <span>{paymentMethodDetails}</span>
              <span>&euro;{subscriptionPrice}</span>
            </div>
          </div>
        </div>
        <div className="payment-details-container py-5">
          {
            isOrdersListNotEmpty ? <ul className="orders-list">
              {
                premiumOrdersList.map((item: CompanyOrderDto) => (
                  <li
                    className="orders-list-item"
                    key={item.id}>
                    <p>{moment(item.date).format('LL')}</p>
                    <p className="currency">
                      <span className="order-info-text">{item.durationMonths}</span>
                      <span title={t('portal.recruiter.profile.payment.duration')}>
                        {t('portal.candidate.cv.list.experience.months')}
                      </span>
                    </p>
                    <div className="order-info">
                      <p className="currency">&euro; {item.amountValue / 100}</p>
                      <p className="pdf-link">
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={item.link}>PDF</a>
                      </p>
                    </div>
                  </li>
                ))
              }
            </ul> : <p className="empty-list">
              <Trans i18nKey="portal.recruiter.profile.payment.no-invoice">
                No invoice history
              </Trans>
            </p>
          }
        </div>
        <SepaPaymentModal
          isOpen={isSepaModalOpen}
          closeModal={this.handleSepaPaymentForm}
          companyId={currentUser.companyId}
          currentUser={currentUser}
          promoCode={promoCode}
          paymentMethod={paymentMethod}
          paymentRedirectUrls={paymentRedirectUrls}
        />
        <PremiumInfoModal
          close={() => this.togglePaymentInfoModal()}
          open={isPremiumInfoModalOpen}/>
        <Spinner show={this.props.fetching}/>
      </div>
    );
  }

}
