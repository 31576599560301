// @flow
import React from 'react';

import { connectStore } from 'middleware/connect';

import { CounterWidget } from 'components/widgets';

import { Settings, stateToProps } from './favorites.setting';

@connectStore(stateToProps, null, true)
export class FavoritesCircle extends Settings {

  render () {
    return (
      <CounterWidget
        title={this.props.t('portal.candidate.favorites.circle.title')}
        value={this.props.total}
        circleTitle={this.props.t('portal.candidate.favorites.circle.value')}/>
    );
  }

}
