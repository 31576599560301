import React, { Component } from 'react';

import { Page } from 'components/layouts';

import { SettingsDetails } from 'containers/portal/recruiter/account/settings';

export class RecruiterAccountPage extends Component {

  render () {
    const { match } = this.props;

    return (
      <div className="account-page-container">
        <div className="account-settings-page">
          <Page autoHeight>
            <SettingsDetails { ...{ match } }/>
          </Page>
        </div>
      </div>
    );
  }

}
