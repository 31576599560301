// @flow
import { Component } from 'react';

import { TransProps } from 'react-i18next';

import { JobTypeDto } from './switch-control.job-type.dto';

import './switch-control.scss';
import './switch-control.resposnive.scss';

export type Props = {
  t: TransProps,
  label: string,
  notes: string,
  items: string[],
  className: string,
  value: string,
  change: (jobType: JobTypeDto) => void
}

export type State = {
  selectedItem: JobTypeDto,
  jobTypes: JobTypeDto
}

export class Setting extends Component<Props, State> {

  static defaultProps = {
    label: '',
    notes: '',
    items: [],
    change: () => {},
    className: '',
    value: ''
  };

  state: State = {
    jobTypes: null,
    selectedItem: this.props.items[0] || null
  };

}
