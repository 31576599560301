import { Component } from 'react';

import './poster.scss';
import './poster.responsive.scss';

type Props = {
  redirectPath: string
}

export class Settings extends Component<Props> {}
