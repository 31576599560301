import React, { Fragment, Component } from 'react';
import { Route } from 'react-router-dom';

import { InvitationListPage } from './list';
import { CandidateProfilePage } from './profile';

export class CalendarPage extends Component {

  render () {
    return (
      <Fragment>
        <Route
          path="/portal/recruiter/calendar"
          component={InvitationListPage}/>
        <Route
          path="/portal/recruiter/calendar/:inviteId/profile/:candidateId"
          component={CandidateProfilePage}/>
      </Fragment>
    );
  }

}
