// @flow
import React, { Fragment } from 'react';

import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';

import { Button } from 'components/buttons';

import { InitResetPasswordDto } from 'rest/account/reset-password/init/init.dto';

import { Setting, stateToProps, dispatchToProps } from './init-reset.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class InitResetContainer extends Setting {

  initResetPassword () {
    const data = new InitResetPasswordDto(this.props);
    this.props.initResetPassword(data);
  }

  render () {
    return (
      <Fragment>
        <p className="reset-instructions">
          <Trans i18nKey="auth.reset.init.description">
            We sent a link with instructions to
          </Trans>
          <span className="email"> {this.props.email}</span>
        </p>
        <div className="init-password-footer">
          <span>
            <Trans i18nKey="auth.reset.init.footer">
              Link with instructions didn’t come?
            </Trans>
          </span>
          <Button
            onClick={this.initResetPassword.bind(this)}
            className={`send-again-button ${this.props.buttonType} inline`}>
            <Trans i18nKey="auth.reset.init.link">
              Send Again
            </Trans>
          </Button>
        </div>
      </Fragment>
    );
  }

}
