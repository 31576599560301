import { PaginationDto } from 'middleware/dto';

export class SearchSkillDto extends PaginationDto {

  q: string;
  exclude: boolean;
  unitIds: number[];
  branchIds: number[];
  jobTitleId: string;

  constructor (data?: SearchSkillDto) {
    super(data);
    if (data) {
      this.q = data.q;
      this.unitIds = data.unitIds;
      this.exclude = data.exclude;
      this.branchIds = data.branchIds;
      this.jobTitleId = data.jobTitleId;
    }
  }

}
