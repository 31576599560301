import * as actions from './stats.constants';

import { StatsDto } from 'rest/stats/stats.dto';

import type { FollowsStatsType } from './stats.type';

const initialState: FollowsStatsType = {
  fetching: false,
  followingStats: [],
  followersStats: [],
  totalFollowingElements: 0,
  totalFollowersElements: 0
};

export default (state = initialState, action): FollowsStatsType => {
  switch (action.type) {

    case actions.GET_FOLLOWING_STATS_REQUEST:
    case actions.GET_FOLLOWERS_STATS_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case actions.GET_FOLLOWING_STATS_SUCCESS:
      return {
        ...state,
        fetching: false,
        followingStats: action.stats.map(stat => new StatsDto(stat)),
        totalFollowingElements: action.total
      };

    case actions.GET_FOLLOWERS_STATS_SUCCESS:
      return {
        ...state,
        fetching: false,
        followersStats: action.stats.map(stat => new StatsDto(stat)),
        totalFollowersElements: action.total
      };

    case actions.GET_FOLLOWING_STATS_FAILURE:
    case actions.GET_FOLLOWERS_STATS_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.RESET_FOLLOWERS_STATS_SUCCESS:
      return {
        ...state,
        fetching: false,
        followingStats: [],
        followersStats: [],
        totalFollowingElements: 0,
        totalFollowersElements: 0
      };

    default:
      return state;
  }
};
