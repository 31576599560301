import { ImageDto } from 'rest/images/image.dto';

export class PostDto {

  title: string;
  article: string;
  postPhotos: ImageDto[];

  constructor (data?: PostDto) {
    if (data) {
      this.title = data.title;
      this.article = data.article;
      this.postPhotos = data.postPhotos;
    }
  }

}
