// @flow
import axios, { AxiosPromise } from 'axios';

import { LifeStyleDto } from './lifestyles.dto';

export async function getLifeStyle (candidateId: string): AxiosPromise<LifeStyleDto> {
  try {
    return await axios.get(`/candidates/${candidateId}/lifestyles`);
  } catch (error) {
    throw error;
  }
}

export async function createLifeStyle (candidateId: string, data: LifeStyleDto): AxiosPromise<string> {
  try {
    return await axios.put(`/candidates/${candidateId}/lifestyles`, data);
  } catch (error) {
    throw error;
  }
}
