import { PaginationDto } from 'middleware/dto';

export class BrandsDto extends PaginationDto {

  lang: string;

  constructor (data?: BrandsDto) {
    super(data);
    if (data) {
      this.lang = data.lang;
    }
  }

}
