import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { Trans, TransProps } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import type { Match } from 'common/types/match';

import { AuthBody, AuthFooter } from 'containers/auth/layouts';
import { ConfirmResetPasswordForm } from 'containers/auth/reset';

type Props = {
  t: TransProps,
  match: Match
}

@connectTranslation()
export class ConfirmResetPasswordPage extends Component<Props> {

  render () {
    return (
      <div className="confirm-reset-password-page">
        <AuthBody title={this.props.t('auth.reset.confirm.title')}>
          <ConfirmResetPasswordForm
            buttonType="candidate"
            redirectUrl="/auth/candidate/reset/success"
            match={this.props.match}/>
        </AuthBody>
        <AuthFooter>
          <span>
            <Trans i18nKey="auth.reset.confirm.footer">
              Confirmation code didn’t come?
            </Trans>
          </span>
          <Link
            className="sign-up-link"
            to="/auth/candidate/reset">
            <Trans i18nKey="auth.reset.confirm.link">
              Send Again
            </Trans>
          </Link>
        </AuthFooter>
      </div>
    );
  }

}
