import React from 'react';

import { Trans } from 'react-i18next';
import { stateToProps } from './poster.setting';
import { connectTranslation } from 'middleware/connect';
import { connectStore } from 'middleware/connect';
import { LinkButton, Button } from 'components/buttons';
import { ExistingJobOffersListModal } from './../existing-list-modal/existing-list-modal';
import { Settings } from './poster.setting';
import { MenuVideoModal } from 'components/dialogs';
import { getLocalization } from 'middleware/localization';
const language = getLocalization();
@connectTranslation()
@connectStore(stateToProps)
export class BalanceBoardPoster extends Settings {

    state = {
      isExistingOffersListModalOpen: false,
      showVideo: false,
      showOnce: false
    }

    componentDidUpdate (prevProps) {
      if (prevProps.showBalanceBoardReUseVideo !== this.props.showBalanceBoardReUseVideo &&
       !this.props.showBalanceBoardReUseVideo) {
        this.setState({ isExistingOffersListModalOpen: true, showVideo: false });
      }
    }
    openExistingJobOffersListModal = () => {
      if (this.props.showBalanceBoardReUseVideo && !this.state.showOnce && language === 'de') {
        this.setState({ showVideo: true, showOnce: true });
      } else this.setState({ isExistingOffersListModalOpen: true, showVideo: false });
    }

    closeExistingJobOffersListModal = () => {
      this.setState({ isExistingOffersListModalOpen: false });
    }

    showOffersPopUpOnVideoClose = status => {
      if (!status) this.setState({ isExistingOffersListModalOpen: true });
    }

    render () {
      return (
        <div className="balance-board-poster">
          <div className="title">
            <Trans i18nKey="portal.recruiter.balance-board.list.title">
              Start creating new job offer here
            </Trans>
          </div>
          <div className="poster">
            <img src="/images/recruiter/balance-board/illustration.svg"/>
          </div>
          <div className="actions">
            <LinkButton
              link="/portal/recruiter/balance-board/create"
              recruiter>
              <Trans i18nKey="portal.recruiter.balance-board.list.buttons.create">
               Create New Offer
              </Trans>
            </LinkButton>
            <Button
              onClick={() => this.openExistingJobOffersListModal()}
              outlined
              recruiter>
              <Trans i18nKey="portal.recruiter.balance-board.list.buttons.re-use">
                Reuse Existing Offer
              </Trans>
            </Button>
          </div>
          <ExistingJobOffersListModal
            hideModal={() => this.closeExistingJobOffersListModal()}
            open={this.state.isExistingOffersListModalOpen}/>
          {
            this.state.showVideo &&
             <MenuVideoModal locationPath= {this.props.location.pathname}
               isExistingOffersListModalOpen= {this.state.showVideo}
               showOffersPopUpOnVideoClose= {this.showOffersPopUpOnVideoClose} />
          }
        </div>
      );
    }

}
