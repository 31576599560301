import React, { Fragment } from 'react';

import { Trans } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { connectStore } from 'middleware/connect';

import { Button } from 'components/buttons';
import { CheckboxControl, Dropdown, FormGroup, InputControl } from 'components/controls';

import { SearchSkillDto } from 'rest/skill/search-skill.dto';
import { CandidatesParams } from 'rest/public/candidates/candidates.params';

import { Settings, dispatchToProps, stateToProps } from './skills-filter.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class SkillsFilter extends Settings {

  componentDidMount () {
    this.props.getAllUnits();
    this.props.getAllBranches();
    this.fetchSkillsList();
  }

  applyFilters () {
    const params = new CandidatesParams();
    params.unitId = this.props.searchCandidates.unitId;
    params.location = this.props.searchCandidates.location;
    params.branchId = this.props.searchCandidates.branchId;
    params.jobTitleId = this.props.searchCandidates.jobTitleId;
    params.professionalSkills = this.props.searchCandidates.professionalSkills;
    this.props.initCandidatesFilter(params);
    this.props.history.push('/portal/recruiter/candidates');
  }

  resetFilters () {
    const params = new CandidatesParams(this.props.searchCandidates);
    params.professionalSkills = [];
    this.props.resetSearchResult();
    this.props.clearCandidatesSearchSkills();
    this.props.initCandidatesFilter(params);
    this.props.history.push('/portal/recruiter/candidates');
  }

  changeFilterSkills (title, value) {
    this.props.resetSearchResult();
    this.setState({ [title]: value ? value.id : null }, () => this.fetchSkillsList());
  }

  searchSkills (query: string) {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      query: query,
      typing: false,
      typingTimeout: setTimeout(() => {
        this.props.resetSearchResult();
      }, 500)
    });
  }

  toggleSkill (skill, checked: boolean) {
    const skills = this.props.searchCandidates.professionalSkills;
    if (checked) {
      skills.splice(skills.indexOf(skill.id), 0, skill.id);
    } else {
      skills.splice(skills.indexOf(skill.id), 1);
    }
    this.props.saveCandidatesSkillsFilter(skills);
  }

  fetchSkillsList () {
    const params = new SearchSkillDto();
    params.q = this.state.query;
    params.unitIds = this.state.unitId && [ this.state.unitId ];
    params.branchIds = this.state.branchId && [ this.state.branchId ];
    params.page = this.props.currentPage;
    this.props.searchSkills(params);
  }

  mergeSelectedSkillsWithFetched (skills = [], savedSkills = []) {
    return skills.map(skill => {
      skill.selected = savedSkills.indexOf(skill.id) >= 0;
      return skill;
    });
  }

  initNotFoundSkills () {
    return !this.props.skills.length ? <p className="empty">
      <Trans i18nKey="portal.recruiter.candidates.filters.skills.empty">
        No Search Items
      </Trans>
    </p> : null;
  }

  initSkillsList (skills, savedSkills) {
    return this.mergeSelectedSkillsWithFetched(skills, savedSkills).map((skill, index) => (
      <li
        key={index}
        className="skill-item">
        <CheckboxControl
          label={skill.name}
          value={skill.selected}
          change={(checked: boolean) => { skill.selected = checked; this.toggleSkill(skill, checked); }}
          className="accent"/>
      </li>
    ));
  }

  render () {
    return (
      <Fragment>
        <form className="skills-filter">
          <h2 className="title">
            <Trans i18nKey="portal.recruiter.candidates.filters.skills.title">
              Skills Filter
            </Trans>
          </h2>
          <div className="search-skills">
            <FormGroup className="full-width search-container">
              <InputControl
                type="text"
                value={this.state.query}
                change={(value: string) => this.searchSkills(value)}
                maxLength={1000}
                placeholder={this.props.t('portal.recruiter.candidates.filters.skills.search')}/>
            </FormGroup>
            <FormGroup className="full-width filter-buttons">
              <Dropdown
                title={this.props.t('portal.recruiter.candidates.filters.skills.branch.title')}
                items={this.props.branches}
                value={this.state.branchId}
                change={value => this.changeFilterSkills('branchId', value)}
                defaultValue={this.props.t('portal.recruiter.candidates.filters.skills.branch.default')}
                allowUnSelect/>
              <Dropdown
                title={this.props.t('portal.recruiter.candidates.filters.skills.unit.title')}
                items={this.props.units}
                value={this.state.unitId}
                change={value => this.changeFilterSkills('unitId', value)}
                defaultValue={this.props.t('portal.recruiter.candidates.filters.skills.unit.default')}
                allowUnSelect/>
            </FormGroup>
          </div>
          <PerfectScrollbar className="skills-container">
            <InfiniteScroll
              hasMore={this.props.hasMoreItems}
              loadMore={() => this.fetchSkillsList()}
              useWindow={false}
              threshold={150}>
              <ul className="skills-list">
                { this.initSkillsList(this.props.skills, this.props.searchCandidates.professionalSkills) }
                { this.initNotFoundSkills() }
              </ul>
            </InfiniteScroll>
          </PerfectScrollbar>
          <FormGroup className="full-width form-buttons">
            <Button
              onClick={() => this.applyFilters()}
              className="accent apply">
              <Trans i18nKey="portal.recruiter.candidates.filters.buttons.apply">
                Apply Filters
              </Trans>
            </Button>
            <Button
              onClick={() => this.resetFilters()}
              className="accent outlined reset">
              <Trans i18nKey="portal.recruiter.candidates.filters.buttons.reset">
                Clear Filters
              </Trans>
            </Button>
          </FormGroup>
        </form>
      </Fragment>
    );
  }

}
