// @flow
import React from 'react';

import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Card } from 'components/layouts';

import { Setting } from './infobox.setting';
@connectTranslation()
export class Infobox extends Setting {

  render () {
    return (
      <Card className="infobox-container">
        <div className="title-container" onClick={() => this.setState({ isOpen: !this.state.isOpen })}>
          <div className="title">
            <Trans i18nKey="landing.infobox.title">
              Excellent-Jobs bringt Unternehmen und Mitarbeiter zusammen
            </Trans>
          </div>
          <div className="title-arrow">
            {this.state.isOpen ? <i className="icon-opened"/> : <i className="icon-closed"/>}
          </div>
        </div>
        {this.state.isOpen &&
          <div className="content">
            <div className="text">
              <Trans i18nKey="landing.infobox.text">
                Für Unternehmen wird es gerade im Gastgewerbe immer schwieriger geeignetes Personal zu finden. Schon
                jetzt fehlt es in Betrieben an wichtigen Fachkräften, die im Service, der Küche oder auch dem
                Housekeeping eingesetzt werden. Besonders brisant ist die Lage in Regionen mit einem stark
                ausgeprägten Tourismus, in denen zumindest zeitweise ein hoher Bedarf an Mitarbeitern
                vorhanden ist. Dabei beobachten Unternehmen nicht nur die aktuelle Situation kritisch,
                sondern schätzen auch die Zukunft eher schwierig ein. Grund ist die Entwicklung der
                Nachwuchskräfte. Viele Ausbildungsplätze bleiben im Gastgewerbe schon heute
                unbesetzt, weil es an geeigneten und motivierten Mitarbeitern fehlt. Excellent-Jobs
                hat es sich zum Ziel gemacht, Unternehmen bei der Mitarbeitersuche zu helfen und
                Nachwuchskräften neue Stellenanzeigen zu präsentieren.
              </Trans>
            </div>
            <div className="subtitle">
              <Trans i18nKey="landing.infobox.title1">
                Wir machen die Jobsuche leicht
              </Trans>
            </div>
            <div className="text">
              <Trans i18nKey="landing.infobox.text1.text1">
                Das Gastgewerbe ist auf die Fähigkeiten und Möglichkeiten der Mitarbeiter angewiesen,
                die in den Betrieben arbeiten. Service und Gastfreundschaft lassen sich bis heute nicht
                von Maschinen umsetzen, sondern werden von Menschenhand gemacht. Genau das lässt das
                Gastgewerbe aber bis heute eine Branche mit Zukunft sein. Trotz der voranschreitenden
                Digitalisierung und den immer weiter wachsenden technischen Möglichkeiten ist die
                Nachfrage nach geeigneten Mitarbeitern groß. Dabei möchten Unternehmen für sämtliche
                Betriebsbereiche neues Personal finden.
              </Trans>
            </div>
            <div className="text">
              <Trans i18nKey="landing.infobox.text1.text2">
                Um Unternehmen, aber auch Fachkräfte bestmöglich unterstützen zu können, haben wir für
                Excellent-Jobs ein eigenes CV Template online bereitgestellt. Unser Ziel ist es von Anfang
                an, Unternehmen und Fachkräfte zusammenzubringen. Dabei bieten wir für beide Seiten
                attraktive Möglichkeiten.
              </Trans>
            </div>

            <div className="subtitle">
              <Trans i18nKey="landing.infobox.title2">
                Mitarbeiter suchen mit Excellent-Jobs: Es kommt auf das richtige Match an
              </Trans>
            </div>
            <div className="text">
              <Trans i18nKey="landing.infobox.text2">
                Sie haben freie Hoteljobs oder möchten Ihre Restaurantjobs neu vergeben? Dann setzen
                Sie auf uns! Als Unternehmen aus dem Gastgewerbe können Sie bei uns Ihre aktuellen
                Stellenangebote einstellen. Sie können aber auch aktiv nach neuen Mitarbeitern
                suchen. So steht hinter unserem CV Free Template ein einzigartiger Skill-Analyzer,
                der Sie bei der Auswahl potenzieller Bewerber unterstützt. Das Matching-System
                ermittelt aus den bei uns hinterlegten Bewerberprofilen die potenziellen Mitarbeiter,
                die laut unserem innovativen Matching-System am besten zu Ihnen passen.
              </Trans>
            </div>

            <div className="subtitle">
              <Trans i18nKey="landing.infobox.title3">
                Kostenloses Einstellen Ihrer Stellenanzeigen
              </Trans>
            </div>
            <div className="text">
              <Trans i18nKey="landing.infobox.text3">
                Wir möchten Sie fair und umfassend bei der Suche nach Kandidaten für Ihre
                Stellenanzeigen unterstützen. Bei uns müssen Sie nur dann für die Vermittlung
                zahlen, wenn Sie durch ein erfolgreiches Match einen geeigneten Bewerber gefunden
                haben. Natürlich ist es möglich, dass die Anfrage eines Unternehmens auch einmal
                unbeantwortet bleibt. Doch auch in diesem Fall lohnt es sich an Excellent-Jobs
                festzuhalten. Bei uns registrieren sich regelmäßig neue Fachkräfte. Nutzen Sie
                diese Chance und bringen Sie einfach noch einmal eine neue Anfrage auf den Weg.
                Vielleicht haben Sie bereits beim nächsten Match Glück.
              </Trans>
            </div>

            <div className="subtitle">
              <Trans i18nKey="landing.infobox.title4">
                Profitieren Sie mit einem Premium-Account von weiteren Vorteilen
              </Trans>
            </div>
            <div className="text">
              <Trans i18nKey="landing.infobox.text4">
                Als Unternehmen können Sie sich bei uns natürlich zum einen für einen Basis-Account
                entscheiden, zum anderen können Sie aber auch einen Premium-Account wählen. Der
                Premium-Account bietet Ihnen die Möglichkeit, auf weitere wichtige Funktionen
                zurückzugreifen. Wurde ein Match erfolgreich beendet, können Sie beispielsweise
                direkt mit den potenziellen Mitarbeitern Kontakt aufnehmen oder mit ihnen chatten.
                Sie können aber auch den Fachkräften bei uns bequem folgen und sich über deren
                Lebensläufe informieren. Damit lässt sich von Anfang an die Auswahl der Mitarbeiter
                gezielt einschränken, sodass eine effiziente Auswahl möglich ist.
              </Trans>
            </div>

            <div className="subtitle">
              <Trans i18nKey="landing.infobox.title5">
                 Arbeit suchen und Traumjob finden mit Excellent-Jobs
              </Trans>
            </div>
            <div className="text">
              <Trans i18nKey="landing.infobox.text5.text1">
                Wir möchten, dass ihr gemeinsam mit uns einen Job findet, der nicht nur zu euch passt,
                sondern der euch Spaß macht. Aus diesem Grund bieten wir euch als Kandidat eine Vielzahl
                an Angeboten und Funktionen, auf die Sie flexibel zurückgreifen können.
              </Trans>
            </div>
            <div className="text">
              <Trans i18nKey="landing.infobox.text5.text2">
                So könnt ihr euch flexibel und schnell verschiedene Stellenangebote bei uns ansehen und
                das ganz ohne Registrierung. Habt ihr Stellenanzeigen gefunden, auf die ihr euch bewerben
                möchtet, können ihr über Excellent-Jobs direkt eure Bewerbung versenden und diese im
                System hinterlegen. Auch wenn ihr für einen Job Anpassungen an eurer Bewerbung oder
                dem Lebenslauf vornehmen möchtet, ist das problemlos möglich und kinderleicht umsetzbar.
              </Trans>
            </div>
            <div className="text">
              <Trans i18nKey="landing.infobox.text5.text3">
                Ein Klick reicht aus und die Bewerbungen werden in den Unternehmen direkt den richtigen
                Ansprechpartnern zugestellt. So können ihr unnötige Zeitverzögerungen vermeiden. Wenn
                ihr das Interesse eines potenziellen Arbeitgebers mit eurer Bewerbung geweckt haben,
                können ihr direkt mit dem zuständigen Ansprechpartner chatten. Möchtet ihr einen
                positiven Eindruck hinterlassen, könnt ihr bei uns einen Lebenslauf erstellen und für
                die Arbeitgeber zugänglich machen. Natürlich ist der Lebenslauf zu 100 Prozent
                werbefrei, sodass ihr diesen auch direkt für eure Bewerbung nutzen können.
              </Trans>
            </div>

            <div className="subtitle">
              <Trans i18nKey="landing.infobox.title6">
                Für jedes Unternehmen die richtige Wahl
              </Trans>
            </div>
            <div className="text">
              <Trans i18nKey="landing.infobox.text6">
                Ganz gleich, ob Sie nur einen kleinen Landgasthof betreiben oder Sie mehrere Hotels
                managen: Bei uns finden Sie eine Lösung, die zu Ihnen passt. Wenn Sie Stellenangebote
                für mehrere Hotels bei uns veröffentlichen möchten, sprechen Sie uns an. Gerne
                unterbreiten wir Ihnen für diesen Fall Sonderkonditionen, mit denen Sie zu besonders
                attraktiven Preisen nach Ihren neuen Mitarbeitern suchen können. Sie können bei uns
                Stellenangebote für die verschiedensten Fachbereiche und Jobs eintragen. So lassen
                sich nicht nur Küchen- und Servicemitarbeiter auf diesem Weg finden, sondern auch
                neue Geschäftsführer oder Animateure.
              </Trans>
            </div>

            <div className="subtitle">
              <Trans i18nKey="landing.infobox.title7">
                Wir bauen unser Angebot aus
              </Trans>
            </div>
            <div className="text">
              <Trans i18nKey="landing.infobox.text7">
                Wir möchten Menschen, die Arbeit suchen und Unternehmen, die diese anbieten,
                so einfach und vor allem auch effizient wie möglich zusammenbringen. Aus
                diesem Grund haben wir uns dazu entschlossen, unser Angebot kontinuierlich
                auszubauen. Excellent-Jobs soll Ihnen in Zukunft nicht nur als Webseite zur
                Verfügung stehen, sondern auch als App. Damit können Sie auch ausgehend vom
                Smartphone oder dem Tablet kinderleicht nach neuen Stellen suchen und sich über
                aktuelle Jobs informieren. Auch für Hotelbesitzer bietet die App einen größtmöglichen
                Handlungsspielraum. Die App gewährt Ihnen einen exzellenten Überblick über die
                aktuellen Nachrichten, aber auch über mögliche neue Kandidaten, die sich bei Ihnen
                für einen Job bewerben möchten. Weiterhin können Sie sich rasch einen Überblick
                über Ihre Favoriten verschaffen, neue Bewerbungen einsehen und die Kandidaten
                prüfen, denen Sie folgen. Über die App können Sie natürlich auch Ihre
                persönlichen Einstellungen verbessern.
              </Trans>
            </div>

            <div className="subtitle">
              <Trans i18nKey="landing.infobox.title8">
                Gastgewerbe gilt als personalintensive Branche
              </Trans>
            </div>
            <div className="text">
              <Trans i18nKey="landing.infobox.text8.text1">
                Das Gastgewerbe ist eine besonders personalintensive Branche. Der Ruf und Erfolg
                der Gastronomiebetriebe hängen erheblich von der Kompetenz und Motivation der
                Mitarbeiter ab. Doch gerade gute Mitarbeiter sind in der Branche nicht leicht
                zu finden. Dabei werden Fachkräfte in allen Bereichen der Unternehmen gesucht.
                Besonders angespannt ist die Situation in vielen Küchen der Betriebe. Hier fehlt
                es teilweise sowohl an Nachwuchs als auch an Mitarbeitern für die höheren Positionen.
                Doch auch im Service sowie in den Betriebsleitungen sind die Folgen des
                Personalmangels langsam zu spüren und allgegenwärtig.
              </Trans>
            </div>
            <div className="text">
              <Trans i18nKey="landing.infobox.text8.text2">
                Dabei ist die allgemeine Entwicklung im Gastgewerbe ausgesprochen positiv. Durch
                das in Deutschland weiter vorzufindende positive Konsumklima können sich auch
                die Betriebe des Gastgewerbes keineswegs über sinkende Umsatzzahlen beschweren.
                Mit den wachsenden Gästezahlen nimmt natürlich auch der Bedarf an Mitarbeitern weiter zu.
              </Trans>
            </div>
            <div className="text">
              <Trans i18nKey="landing.infobox.text8.text3">
                Doch dem Gastgewerbe fällt es schwer, im Nachwuchsbereich zu punkten. Seit mehr als
                zehn Jahren nimmt die Zahl der Schulabgänger stetig ab. Immer mehr junge Menschen
                entscheiden sich zudem für ein Studium, wodurch gleichzeitig das Interesse gegenüber
                den klassischen Ausbildungsberufen zurückgeht. Die Betriebe rechnen deswegen weiter
                damit, dass die Zahl der Bewerber unaufhaltsam sinkt. Der Bedarf an Fachkräften wird
                aber sicherlich weiter steigen, was insbesondere auf den Aspekt zurückzuführen ist,
                dass die Nachfrage im Gastgewerbe weiter hoch ist. Für die Unternehmen wird es zu
                einer enormen Herausforderung, die Arbeits- und Fachkräfte zu sichern.
              </Trans>
            </div>
            <div className="text">
              <Trans i18nKey="landing.infobox.text8.text4">
                Als einer der Schlüssel auf dem Bewerbermarkt gilt mittlerweile das Ausbildungsangebot.
                So sollten Betriebe aus dem Gastgewerbe unaufhaltsam darum bemüht sein, Auszubildende
                zu generieren, die sie dann durch attraktive Arbeitsbedingungen an die Unternehmen
                binden. Branchenkenner sehen aber vor allem auch im internationalen Arbeitsmarkt
                eine wichtige Chance.
              </Trans>
            </div>

            <div className="subtitle">
              <Trans i18nKey="landing.infobox.title9">
                Vereinbarkeit von Beruf und Familie wird zur Herausforderung
              </Trans>
            </div>
            <div className="text">
              <Trans i18nKey="landing.infobox.text9.text1">
                Ein Punkt, in dem das Gastgewerbe erheblicher Kritik ausgesetzt ist, ist die
                Vereinbarkeit von Beruf und Familie. Gerade die Arbeitszeiten in Hotel- und
                Gaststättenwesen machen es schwer, Familie und Job unter einen Hut zu bekommen.
              </Trans>
            </div>
            <div className="text">
              <Trans i18nKey="landing.infobox.text9.text2">
                Hier sind vor allem die Arbeitgeber selbst gefragt. Sie sind dazu angehalten,
                Arbeitsbedingungen zu schaffen, die eine Beschäftigung für Familien attraktiv
                machen. Ansätze hierfür gibt es viele und die Schaffung eines familienfreundlichen
                Arbeitsklimas zahlt sich für die Arbeitgeber doppelt aus. Häufig wird so nämlich die
                Begeisterung für die Unternehmen und die Arbeit im Gastgewerbe an die folgenden
                Generationen weitergegeben, was wiederum mehr junge Menschen zu einer Ausbildung bewegt.
              </Trans>
            </div>

            <div className="subtitle">
              <Trans i18nKey="landing.infobox.title10">
                Arbeitgeber müssen neue Ansätze verfolgen
              </Trans>
            </div>
            <div className="text">
              <Trans i18nKey="landing.infobox.text10">
                Um in Zukunft geeignete Bewerber zu finden und die Stellen im Unternehmen neu
                zu besetzen, brauchen Arbeitgeber vor allem Mut, um neue Ansätze zu verfolgen.
                Diese beginnen bei der Gestaltung der Arbeitsplätze, gehen über die Aufstiegs- und
                Verdienstmöglichkeiten bis hin zu einer neuen Form der Flexibilität. Eine weitere
                Herausforderung, die es gerade in klassischen Urlaubsregionen gibt, ist der Mangel
                an bezahlbarem Wohnraum. Selbst wenn potenzielle Bewerber dazu bereit sind, für den
                neuen Job durch die halbe Bundesrepublik zu ziehen, ist diese Entscheidung allein nicht
                ausreichend für die erfolgreiche Umsetzung. Allzu oft fehlt es dann an Wohnmöglichkeiten.
                Hier können Arbeitgeber mit geeigneten Unterkünften für die Mitarbeiter entgegenwirken und
                zudem zusätzliche Anreize schaffen, denn lange Anreisewege werden so vermieden.
              </Trans>
            </div>
          </div>
        }
      </Card>
    );
  }

}
