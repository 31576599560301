// @flow
import * as actions from './search.constants';

import { Action, Dispatch } from 'redux';

const candidatesSkills = (skills: string[]): Action => ({
  type: actions.SEARCH_CANDIDATES_SKILLS,
  skills: skills
});

const candidatesBranch = (branchId: string): Action => ({
  type: actions.SEARCH_CANDIDATES_BRANCH,
  branchId: branchId
});

const candidatesUnit = (unitId: string): Action => ({
  type: actions.SEARCH_CANDIDATES_UNIT,
  unitId: unitId
});

const candidatesJobTitle = (jobTitleId: string): Action => ({
  type: actions.SEARCH_CANDIDATES_JOB_TITLE,
  jobTitleId: jobTitleId
});

const candidatesLocation = (location: string): Action => ({
  type: actions.SEARCH_CANDIDATES_LOCATION,
  location: location
});

const candidatesSalaryMinimum = (minSalary: number): Action => ({
  type: actions.SEARCH_CANDIDATES_SALARY_MIN,
  minSalary
});

const candidatesSalaryMaximum = (maxSalary: number): Action => ({
  type: actions.SEARCH_CANDIDATES_SALARY_MAX,
  maxSalary
});

const candidatesWorkingTime = (workingTime: number): Action => ({
  type: actions.SEARCH_CANDIDATES_WORKING_TIME,
  workingTime: workingTime
});

const candidatesAvailableFrom = (availableFrom: string): Action => ({
  type: actions.SEARCH_CANDIDATES_AVAILABLE_FROM,
  availableFrom: availableFrom
});

const candidatesWorkingType = (jobType: string): Action => ({
  type: actions.SEARCH_CANDIDATES_WORKING_TYPE,
  jobType
});

const candidatesRelocate = (readyToRelocation: boolean): Action => ({
  type: actions.SEARCH_CANDIDATES_RELOCATE,
  readyToRelocation
});

const candidatesClose = (close: boolean): Action => ({
  type: actions.SEARCH_CANDIDATES_CLOSE,
  close
});

const resetCandidatesFilter = (): Action => ({
  type: actions.SEARCH_CANDIDATES_RESET
});

const resetCandidatesSkillsFilter = (): Action => ({
  type: actions.SEARCH_CANDIDATES_SKILLS_RESET
});

export function clearCandidatesSearch () {
  return (dispatch: Dispatch) => {
    dispatch(resetCandidatesFilter());
  };
}

export function clearCandidatesSearchSkills () {
  return (dispatch: Dispatch) => {
    dispatch(resetCandidatesSkillsFilter());
  };
}

export function saveCandidatesSkillsFilter (skills: string[]) {
  return (dispatch: Dispatch) => {
    dispatch(candidatesSkills(skills));
  };
}

export function saveSearchCandidatesBranch (branchId: string) {
  return (dispatch: Dispatch) => {
    dispatch(candidatesBranch(branchId));
  };
}

export function saveSearchCandidatesUnit (unitId: string) {
  return (dispatch: Dispatch) => {
    dispatch(candidatesUnit(unitId));
  };
}

export function saveSearchCandidatesJobTitle (jobTitleId: string) {
  return (dispatch: Dispatch) => {
    dispatch(candidatesJobTitle(jobTitleId));
  };
}

export function saveSearchCandidatesLocation (location: string) {
  return (dispatch: Dispatch) => {
    dispatch(candidatesLocation(location));
  };
}

export function saveSearchCandidatesSalaryMinimum (salaryMinimum: number) {
  return (dispatch: Dispatch) => {
    dispatch(candidatesSalaryMinimum(salaryMinimum ? parseFloat(salaryMinimum) : null));
  };
}

export function saveSearchCandidatesSalaryMaximum (salaryMaximum: number) {
  return (dispatch: Dispatch) => {
    dispatch(candidatesSalaryMaximum(salaryMaximum ? parseFloat(salaryMaximum) : null));
  };
}

export function saveSearchCandidatesWorkingTime (workingTime: number) {
  return (dispatch: Dispatch) => {
    dispatch(candidatesWorkingTime(workingTime));
  };
}

export function saveSearchCandidatesAvailableFrom (availableFrom: string) {
  return (dispatch: Dispatch) => {
    dispatch(candidatesAvailableFrom(availableFrom));
  };
}

export function saveSearchCandidatesWorkingType (workingType: string) {
  return (dispatch: Dispatch) => {
    dispatch(candidatesWorkingType(workingType));
  };
}

export function saveSearchCandidatesRelocate (relocate: boolean) {
  return (dispatch: Dispatch) => {
    dispatch(candidatesRelocate(relocate));
  };
}

export function saveSearchCandidatesClose (close: boolean) {
  return (dispatch: Dispatch) => {
    dispatch(candidatesClose(close));
  };
}
