import { PaginationDto } from 'middleware/dto/pagination';

export class JobOffersMatchesParams extends PaginationDto {

  new: boolean;
  placeId: string;
  branchId: string;

  constructor (data?: JobOffersMatchesParams) {
    super(data);
    if (data) {
      this.new = data.new;
      this.placeId = data.placeId;
      this.branchId = data.branchId;
    }
  }

}
