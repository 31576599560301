import axios from 'axios';

import { FinishResetPasswordDto } from './finish.dto';

export async function finishResetUserPassword (data: FinishResetPasswordDto): Promise<FinishResetPasswordDto> {
  try {
    return await axios.post('/account/reset-password/finish', data);
  } catch (error) {
    if (error.response.data.status === 500) {
      throw error.response.data.title;
    }
  }
}
