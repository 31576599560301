import axios from 'axios';
import { AxiosPromise } from 'axios';

import { AskMeParams } from './askme.params';
import { AskMeResponseDto } from './askme.response.dto';

export async function getAskMeContent (pageKey: string, params: AskMeParams): AxiosPromise<AskMeResponseDto> {
  try {
    return await axios.get(`/public/askme/${pageKey}`, { params });
  } catch (error) {
    throw error;
  }
}
