import { Component } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { hideModal } from 'services/portal/modal/modal.actions';

import { CompanyDto } from 'rest/companies/companies.dto';
import { CandidateDto } from 'rest/candidates/candidate.dto';
import { SkillsContainerDto } from 'rest/skills/skills-container.dto';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

import {
  addCompanyToFollow,
  deleteCompanyFromFollow
} from 'services/portal/candidate/jobs/follow/following/following.actions';

import { getCandidateSkillsById } from 'services/portal/candidate/skills/skills.actions';
import { addJobToFavorite, deleteJobFromFavorite } from 'services/portal/candidate/jobs/favorite/favorite.actions';

import './preview.scss';
import './preview.responisve.scss';

export type Props = {
  t: TransProps,
  open: boolean,
  backLink: string,
  jobOfferId: string,
  recruiterId: string,
  candidateId: string,
  company: CompanyDto,
  locationPath: string,
  jobOfferMatched: boolean,
  currentCandidate: CandidateDto,
  jobOffer: JobOffersResponseDto,
  jobOfferSkills: SkillsContainerDto,

  hideModal: (id: string) => void,
  addJobToFavorite: (id: string) => void,
  addCompanyToFollow: (id: string) => void,
  deleteJobFromFavorite: (id: string) => void,
  deleteCompanyFromFollow: (id: string) => void,
  getCandidateSkillsById: (id: string) => void,
};

type State = {
  company: CompanyDto,
  jobOffer: JobOffersResponseDto,
  isApplyModalOpen: boolean,
  interactWithSkills: boolean,
  isDescriptionOpened?: boolean
}

export class Settings extends Component<Props, State> {

  static defaultProps = {
    open: false,
    backLink: '',
    jobOfferId: '',
    company: new CompanyDto(),
    currentCandidate: CandidateDto,
    jobOffer: new JobOffersResponseDto(),
    jobOfferSkills: new SkillsContainerDto(),

    hideModal: () => {},
    addCompanyToFollow: () => {},
    addJobToFavorite: () => {},
    deleteJobFromFavorite: () => {},
    deleteCompanyFromFollow: () => {}
  };

  state: State = {
    company: new CompanyDto(),
    jobOffer: new JobOffersResponseDto(),
    isApplyModalOpen: false,
    interactWithSkills: false,
    isDescriptionOpened: false
  }

}

export const stateToProps = (state: GlobalState) => ({
  company: state.portal.candidate.jobs.jobOffers.jobOfferCompany,
  recruiterId: state.portal.recruiter.account.current.currentUser.id,
  jobOfferMatched: state.portal.recruiter.jobOffer.checkMatch.matched,
  candidateId: state.portal.candidate.account.current.candidateData.id,
  currentCandidate: state.portal.candidate.account.current.candidateData
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  hideModal,
  addJobToFavorite,
  addCompanyToFollow,
  deleteJobFromFavorite,
  getCandidateSkillsById,
  deleteCompanyFromFollow
}, dispatch);
