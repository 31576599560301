import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { EducationsDto } from 'rest/candidates/educations/educations.dto';
import { WorkExperienceDto } from 'rest/candidates/work-experience/work-experience.dto';

import { getCandidateWorkExperiences } from 'services/portal/candidate/cv/work-experience/work-experience.actions';
import { getCandidateEducations } from 'services/portal/candidate/cv/education/education.actions';

import './work-experience.scss';

type Props = {
  t: TransProps,
  candidateId: string,
  educations?: EducationsDto[],
  experiences?: WorkExperienceDto[]
}

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  educations: state.portal.candidate.cv.education.content,
  experiences: state.portal.candidate.cv.experience.content
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getCandidateEducations,
  getCandidateWorkExperiences
}, dispatch);
