// @flow
import { Dispatch } from 'redux';

import * as actions from './sign-up.constants';

import { setToken } from 'middleware/auth';
import { setUserType } from 'middleware/user-type';

import { RecruiterDto } from 'rest/recruiter/recruiter.dto';
import { RecruiterSignUpDto } from 'rest/recruiter/recruiter.signup.dto';

import { createRecruiter } from 'rest/recruiter/recruiter.rest';

import { activateCompanyRecruiter } from 'services/portal/recruiter/account/current/current.actions';

const recruiterSinUpRequest = () => ({
  type: actions.RECRUITER_SIGN_UP_REQUEST
});

const recruiterSignUpSuccess = () => ({
  type: actions.RECRUITER_SIGN_UP_SUCCESS
});

const recruiterSignUpFailure = error => ({
  type: actions.RECRUITER_SIGN_UP_FAILURE,
  error
});

function errorHandler (dispatch: Dispatch, error) {
  if (error.status === 409 || error.status === 400) {
    dispatch(recruiterSignUpFailure(error.message));
  } else {
    dispatch(recruiterSignUpFailure('An error has occurred'));
  }
}

function successHandler (dispatch: Dispatch, data, email: string, guest: boolean) {
  localStorage.setItem('emailToConfirm', email);
  if (guest) {
    setUserType('recruiter');
  }
  if (data.accessToken) {
    setToken(data.accessToken, data.refreshToken);
    setUserType('recruiter');
    dispatch(activateCompanyRecruiter(data.recruiterId));
  }
  return dispatch(recruiterSignUpSuccess());
}

export const recruiterSignUp = (data: RecruiterDto, guest: boolean): RecruiterSignUpDto => {
  return dispatch => {
    dispatch(recruiterSinUpRequest());
    return createRecruiter(data)
      .then(response => successHandler(dispatch, response.data, data.email, guest))
      .catch(error => errorHandler(dispatch, error));
  };
};
