import { Component } from 'react';

import moment from 'moment';

type Props = {
  publishDate: string
}

export class Setting extends Component<Props> {

  static defaultProps: Props = {
    publishDate: moment().parseZone().fromNow()
  }

}
