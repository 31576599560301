export class LocationsDto {

  city: string;
  count: number;
  country: string;
  cityPlaceId: string;
  countryPlaceId: string;

  constructor (data?: LocationsDto) {
    if (data) {
      this.city = data.city;
      this.count = data.count;
      this.country = data.country;
      this.cityPlaceId = data.cityPlaceId;
      this.countryPlaceId = data.countryPlaceId;
    }
  }

  get id (): string {
    return `${this.country}, ${this.city}`;
  }

  get name (): string {
    return `${this.country}, ${this.city}`;
  }

}
