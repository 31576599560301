import React from 'react';

import { Trans } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import { connectStore } from 'middleware/connect';

import { ElementsList } from 'components/elements-list';

import { MatchedCandidatesDto } from 'rest/companies/job-offers-matches/matched-candidates/matched-candidates.dto';

import {
  MatchedCandidatesParams
} from 'rest/companies/job-offers-matches/matched-candidates/matched-candidates.params';

import { CandidateRow } from './../candidate-row/candidate-row';

import { Settings, dispatchToProps, stateToProps } from './candidates-list.setting';
import { toast } from 'react-toastify';

@connectStore(stateToProps, dispatchToProps, true)
export class CandidatesList extends Settings {

  componentDidMount () {
    if (this.props.currentUser.companyId) {
      this.props.saveMatchedJobOfferAsViewed(this.props.currentUser.companyId, this.props.match.params.jobOfferId);
      this.props.getMatchedCandidatesLocation(this.props.currentUser.companyId, this.props.match.params.jobOfferId);
    }
  }

  componentWillUpdate (props) {
    if (!this.props.currentUser.companyId && props.currentUser.companyId) {
      this.props.saveMatchedJobOfferAsViewed(props.currentUser.companyId, this.props.match.params.jobOfferId);
      this.props.getMatchedCandidatesLocation(props.currentUser.companyId, this.props.match.params.jobOfferId);
    }
  }

  loadJobOffersList (page: number = 0) {
    const params = new MatchedCandidatesParams();
    params.page = page;
    params.city = this.props.location.city;
    params.country = this.props.location.country;
    this.props.getMatchedCandidates(this.props.currentUser.companyId, this.props.match.params.jobOfferId, params);
  }

  openCandidateProfile (id: string) {
    this.props.history.push(`/portal/recruiter/match/${this.props.match.params.jobOfferId}/candidates/${id}`);
  }

  deleteCandidate (id: string) {
    this.props.deleteMatchedCandidate(this.props.currentUser.companyId, this.props.match.params.jobOfferId, id);
    toast.success(this.props.t('portal.recruiter.notifications.matched-candidate-delete'));
  }

  initCandidatesList (): HTMLElement {
    return this.props.candidates.map((candidate: MatchedCandidatesDto, index) => (
      <CandidateRow
        key={index}
        onClick={(id: string) => this.openCandidateProfile(id)}
        onDelete={(id: string) => this.deleteCandidate(id)}
        candidate={candidate}>
      </CandidateRow>
    ));
  }

  initEmptyList () : HTMLElement {
    return !this.props.candidates.length ? (
      <div className="empty-placeholder">
        <Trans i18nKey="portal.recruiter.match.preview.empty">
          Candidates list is empty
        </Trans>
      </div>
    ) : null;
  }

  render () {
    return (
      <div className="candidates-matched-list">
        {
          this.props.currentUser.companyId && <InfiniteScroll
            useWindow={false}
            threshold={100}
            pageStart={0}
            hasMore={this.props.hasMoreItems}
            loadMore={() => this.loadJobOffersList(this.props.currentPage)}>
            <ElementsList>{this.initCandidatesList()}</ElementsList>
            {this.initEmptyList()}
          </InfiniteScroll>
        }
      </div>
    );
  }

}
