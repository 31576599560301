// @flow
import React, { Component } from 'react';

import { SocialAuthRouting } from 'containers/auth/candidate/social/social';

type Props = {
  location: Location
};

export class SocialPage extends Component<Props> {

  render () {
    return <SocialAuthRouting locationSearch={this.props.location.search}/>;
  }

}
