export const CHANGE_JOB_OFFER_STATUS_REQUEST = 'CHANGE_JOB_OFFER_STATUS_REQUEST';
export const CHANGE_JOB_OFFER_STATUS_SUCCESS = 'CHANGE_JOB_OFFER_STATUS_SUCCESS';
export const CHANGE_JOB_OFFER_STATUS_FAILURE = 'CHANGE_JOB_OFFER_STATUS_FAILURE';

export const UPDATE_JOB_OFFER_REQUEST = 'UPDATE_JOB_OFFER_REQUEST';
export const UPDATE_JOB_OFFER_SUCCESS = 'UPDATE_JOB_OFFER_SUCCESS';
export const UPDATE_JOB_OFFER_FAILURE = 'UPDATE_JOB_OFFER_FAILURE';

export const DELETE_JOB_OFFER_REQUEST = 'DELETE_JOB_OFFER_REQUEST';
export const DELETE_JOB_OFFER_SUCCESS = 'DELETE_JOB_OFFER_SUCCESS';
export const DELETE_JOB_OFFER_FAILURE = 'DELETE_JOB_OFFER_FAILURE';

export const GET_JOB_OFFER_BY_ID_REQUEST = 'GET_JOB_OFFER_BY_ID_REQUEST';
export const GET_JOB_OFFER_BY_ID_SUCCESS = 'GET_JOB_OFFER_BY_ID_SUCCESS';
export const GET_JOB_OFFER_BY_ID_FAILURE = 'GET_JOB_OFFER_BY_ID_FAILURE';

export const RESET_CURRENT_JOB_OFFER = 'RESET_CURRENT_JOB_OFFER';
