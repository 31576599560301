export const CHAT_CREATE = 'CHAT_CREATE';
export const ASK_ME_CONTENT = 'ASK_ME_CONTENT';
export const JOB_OFFER_UPDATE = 'JOB_OFFER_UPDATE';
export const JOB_OFFER_CREATE = 'JOB_OFFER_CREATE';
export const CANDIDATE_INVITE = 'CANDIDATE_INVITE';
export const ADMIN_NOTIFICATION = 'ADMIN_NOTIFICATION';
export const NEW_MATCHED_CANDIDATE = 'NEW_MATCHED_CANDIDATE';
export const REQUEST_SKILL_DECISION = 'REQUEST_SKILL_DECISION';
export const ADD_NEW_SKILL_TO_SYSTEM = 'ADD_NEW_SKILL_TO_SYSTEM';
export const SUBSCRIPTION_PLAN_EXPIRES = 'SUBSCRIPTION_PLAN_EXPIRES';

export const ANONYMOUS_CANDIDATE_REQUEST = 'ANONYMOUS_CANDIDATE_REQUEST';
export const ANONYMOUS_CANDIDATE_DECLINED = 'ANONYMOUS_CANDIDATE_DECLINED';
export const ANONYMOUS_CANDIDATE_ACCEPTED = 'ANONYMOUS_CANDIDATE_ACCEPTED';

export const ANONYMOUS_RECRUITER_DECLINED = 'ANONYMOUS_RECRUITER_DECLINED';
export const ANONYMOUS_RECRUITER_ACCEPTED = 'ANONYMOUS_RECRUITER_ACCEPTED';
