// @flow
import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';

import type { Props, State } from './change-password.setting';

import { connectStore } from 'middleware/connect';

import { Button } from 'components/buttons';
import { Spinner } from 'components/elements';
import { FormGroup, InputControl } from 'components/controls';

import { ChangePasswordDto } from 'rest/account/change-password/change-password.dto';

import { Settings, stateToProps, dispatchToProps } from './change-password.setting';
import { toast } from 'react-toastify';

@connectStore(stateToProps, dispatchToProps, true)
export class ChangePasswordPage extends Settings {

  componentWillReceiveProps (nextProps: Props, prevState: State) {
    if (nextProps.errorText !== prevState.errorText) {
      this.setState({ errorText: nextProps.errorText });
    }
  }

  changePasswordState (field: string, value: string, isError: boolean = false) {
    this.setState({
      [field]: value,
      errorText: ''
    });
    this.errorCollector(field, isError);
  }

  errorCollector (errorName: string, collect: boolean) {
    const errorCollection: Set<string> = new Set(this.state.errorCollection);
    collect ? errorCollection.add(errorName) : errorCollection.delete(errorName);
    this.setState({
      errorCollection
    });
  }

  clearState () {
    this.setState({
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      errorCollection: new Set()
    });
  }

  changePassword (event: Event) {
    event.preventDefault();
    if (!this.state.errorCollection.size) {
      const data = new ChangePasswordDto(this.state);
      this.props.changePassword(data)
        .then(response => {
          if (!response.error) {
            toast.success('Your password have been changed');
            this.setState({ errorText: '' });
            this.clearState();
          }
        });
    }
  }

  render () {
    return (
      <Fragment>
        <form
          onSubmit={this.changePassword.bind(this)}
          className="change-password-form">
          <FormGroup className="half-width">
            <InputControl
              required
              title={this.props.t('portal.candidate.profile.password.past.title')}
              validate={false}
              label={this.props.t('portal.candidate.profile.password.past.label')}
              value={this.state.oldPassword}
              change={(value: string, error: boolean): void =>
                this.changePasswordState('oldPassword', value, error)}
              placeholder={this.props.t('portal.candidate.profile.password.past.placeholder')}
              error={this.state.errorText}
              errorText={this.state.errorText}
              type="password"/>
          </FormGroup>
          <FormGroup className="half-width">
            <InputControl
              required
              minLength={6}
              maxLength={32}
              icon
              title={this.props.t('portal.candidate.profile.password.new.title')}
              label={this.props.t('portal.candidate.profile.password.new.label')}
              value={this.state.newPassword}
              change={(value: string, error: boolean): void =>
                this.changePasswordState('newPassword', value, error)}
              placeholder={this.props.t('portal.candidate.profile.password.new.placeholder')}
              notes={this.props.t('portal.candidate.profile.password.new.notes')}
              errorText={this.props.t('portal.candidate.profile.password.new.error')}
              type="password"/>
          </FormGroup>
          <FormGroup className="half-width">
            <InputControl
              required
              title={this.props.t('portal.candidate.profile.password.repeat.title')}
              label={this.props.t('portal.candidate.profile.password.repeat.label')}
              minLength={6}
              maxLength={32}
              icon
              pattern={this.state.newPassword}
              value={this.state.confirmNewPassword}
              change={(value: string, error: boolean): void =>
                this.changePasswordState('confirmNewPassword', value, error)}
              placeholder={this.props.t('portal.candidate.profile.password.repeat.placeholder')}
              errorText={this.props.t('portal.candidate.profile.password.repeat.error')}
              type="password"/>
          </FormGroup>
          <FormGroup className="form-btn-submit">
            <Button
              className={`medium ${this.props.buttonType}`}
              type="submit">
              <Trans i18nKey="portal.candidate.profile.buttons.save">
                Save Changes
              </Trans>
            </Button>
          </FormGroup>
        </form>
        <Spinner show={this.props.fetching}/>
      </Fragment>
    );
  }

}
