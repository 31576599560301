// @flow
import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';
import type { SocialState } from 'services/auth/candidate/social/social.type';

import { AuthOutDto } from 'rest/auth/auth.out';

import { trySignIn } from 'services/auth/candidate/social/social.action';

export type Props = {
  error: boolean,
  trySignIn: (auth: AuthOutDto) => void,
  dataSuccess: boolean,
  tokenSuccess: boolean,
  locationSearch: string
};

type State = {
  dataSuccess: string,
  jobOfferLink: string
}

export class Setting extends Component<Props, State> {

  static defaultProps: Props = {
    error: false,
    trySignIn: () => {},
    dataSuccess: false,
    tokenSuccess: false,
    locationSearch: ''
  };

  state: State = {
    dataSuccess: '',
    jobOfferLink: ''
  }

}

export const stateToProps = (state: GlobalState): SocialState => ({
  error: state.auth.candidate.social.error,
  dataSuccess: state.auth.candidate.social.dataSuccess,
  tokenSuccess: state.auth.candidate.social.tokenSuccess
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  trySignIn
}, dispatch);
