import axios, { AxiosPromise } from 'axios';

import { LocationsDto } from 'rest/companies/job-offers-matches/matched-candidates/locations/locations.dto';

export async function addCompanyToFollowRest (companyId: string): AxiosPromise {
  try {
    return await axios.post(`/candidates/current/company/following/${companyId}`);
  } catch (error) {
    throw error;
  }
}

export async function deleteCompanyFromFollowRest (companyId: string): AxiosPromise {
  try {
    return await axios.delete(`/candidates/current/company/following/${companyId}`);
  } catch (error) {
    throw error;
  }
}

export async function getLocationsFollowedCompanies (): AxiosPromise<LocationsDto[]> {
  try {
    return await axios.get('/candidates/current/company/following/locations');
  } catch (error) {
    throw error;
  }
}
