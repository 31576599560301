import axios, { AxiosPromise } from 'axios';

import { CandidateSkillsDto } from 'rest/candidates/skills/skills.dto';

export async function createCandidateSkills (candidateId: string, data: CandidateSkillsDto):
  AxiosPromise<CandidateSkillsDto> {
  try {
    return await axios.post(`/candidates/${candidateId}/skills`, data);
  } catch (error) {
    throw error;
  }
}

export async function updateCandidateSkills (candidateId: string, data: CandidateSkillsDto): AxiosPromise<string> {
  try {
    return await axios.put(`/candidates/${candidateId}/skills`, data);
  } catch (error) {
    throw error;
  }
}

export async function getCandidateSkills (candidateId: string): AxiosPromise<CandidateSkillsDto> {
  try {
    return await axios.get(`/candidates/${candidateId}/skills`);
  } catch (error) {
    throw error.response;
  }
}
