// @flow
import React from 'react';

import { connectTranslation } from 'middleware/connect';

import { CounterWidget } from 'components/widgets';

import { Settings } from './statistics.setting';

@connectTranslation()
export class StatisticsCircle extends Settings {

  render () {
    return (
      <CounterWidget
        title={this.props.t('portal.recruiter.statistics.circle.title')}
        value=""
        circleTitle={this.props.t('portal.recruiter.statistics.circle.value')}/>
    );
  }

}
