import * as actions from './images.constants';

import { ImageResponseDto } from 'rest/images/images.response';

import { uploadImage } from 'rest/images/images.rest';

const uploadFileRequest = (imageType: string) => ({
  type: actions.UPLOAD_FILE_REQUEST,
  imageType
});

const uploadFileSuccess = (fileInfo: ImageResponseDto, imageType: string) => ({
  type: actions.UPLOAD_FILE_SUCCESS,
  fileInfo,
  imageType
});

const uploadFileFailure = (error: string, imageType: string) => ({
  type: actions.UPLOAD_FILE_FAILURE,
  imageType,
  error
});

export const uploadFile = (data: File, type: string) => {
  return dispatch => {
    dispatch(uploadFileRequest(type));
    return uploadImage(data)
      .then(fileInfo => dispatch(uploadFileSuccess(fileInfo.data, type)))
      .catch(error => dispatch(uploadFileFailure(error, type)));
  };
};
