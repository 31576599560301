// @flow
import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';

import { TransProps } from 'react-i18next';
import type { GlobalState } from 'common/types/global-state';

import { CandidateDto } from 'rest/candidates/candidate.dto';

import { clearSignInState, candidateSignIn, getSocialUrl } from 'services/auth/candidate/auth.action';

import './sign-in-form.scss';
import './sign-in-form.responsive.scss';

export type Props = {
  error: boolean,
  t: TransProps,
  redirect?: boolean,
  fetching?: boolean,
  googleLink?: string,
  needConfirm?: boolean,
  facebookLink?: string,
  getSocialUrl: () => void,
  redirectPortal?: boolean,
  unauthorizedUserUrl?: string;
  clearSignInState?: () => void,
  currentCandidate:CandidateDto,
  getSocialUrl?: (social: string) => void,
  candidateSignIn?: (data: CandidateDto) => void
};

export type State = {
  email: string,
  password: string,
  redirect: boolean,
  rememberMe: boolean,
  unauthorizedUserUrl: string,
  errorCollection: Set<string>
}

export class Setting extends Component<Props, State> {

  static defaultProps = {
    fetching: false,
    googleLink: '',
    needConfirm: false,
    facebookLink: '',
    redirect: false,
    getSocialUrl: () => {},
    candidateSignIn: () => {},
    clearSignInState: () => {},
    unauthorizedUserUrl: '/portal/candidate/account'
  };

  state: State = {
    email: '',
    password: '',
    errorText: '',
    rememberMe: true,
    errorCollection: new Set(),
    unauthorizedUserUrl: '/portal/candidate/account',
    redirect: false
  };

}

export const stateToProps = (state: GlobalState) => ({
  error: state.auth.candidate.signIn.error,
  fetching: state.auth.candidate.signIn.fetching,
  redirect: state.auth.candidate.signIn.signInSuccess,
  googleLink: state.auth.candidate.signIn.googleLink,
  needConfirm: state.auth.candidate.signIn.needConfirm,
  facebookLink: state.auth.candidate.signIn.facebookLink,
  redirectPortal: state.auth.candidate.signIn.redirectPortal,
  unauthorizedUserUrl: state.auth.recruiter.signIn.unauthorizedUserUrl,
  currentCandidate: state.portal.candidate.account.current.candidateData
});

export const dispatchToProps = (dispatch: Dispatch): Props => bindActionCreators({
  getSocialUrl,
  candidateSignIn,
  clearSignInState
}, dispatch);
