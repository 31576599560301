// @flow
import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { CandidateDto } from 'rest/candidates/candidate.dto';
import { CandidatesParams } from 'rest/public/candidates/candidates.params';
import { RecruiterFollowParams } from 'rest/recruiter/follow/follow.params';

import { saveSearchCandidatesBranch } from 'services/portal/recruiter/candidate/search/search.actions';
import { getAllCandidates, resetCandidatesList } from 'services/portal/recruiter/candidate/all/candidates.actions';

import {
  addingCandidateToFavorite,
  deletingCandidateFromFavorite
} from 'services/portal/recruiter/favorites/favorites.actions';

import './candidates-list.scss';

type Props = {
  history: History,
  fetching: boolean,
  candidates: CandidateDto[],
  currentPage: number,
  hasMoreItems: boolean,
  candidatesFilter: CandidatesParams,
  getAllCandidates: (params: CandidatesParams) => void,
  currentRecruiterId: string,
  resetCandidatesList: () => void,
  addingCandidateToFollow: (params: RecruiterFollowParams) => void,
  saveSearchCandidatesBranch: () => void,
  deletingCandidateFromFollow: (params: RecruiterFollowParams) => void,
};

export class Setting extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  fetching: state.portal.recruiter.candidate.allCandidates.fetching,
  candidates: state.portal.recruiter.candidate.allCandidates.candidates,
  currentPage: state.portal.recruiter.candidate.allCandidates.currentPage,
  hasMoreItems: state.portal.recruiter.candidate.allCandidates.hasMoreItems,
  candidatesFilter: state.portal.recruiter.candidate.allCandidates.candidatesFilter,
  currentRecruiterId: state.portal.recruiter.account.current.currentUser.id
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getAllCandidates,
  resetCandidatesList,
  saveSearchCandidatesBranch,
  addToFavorite: addingCandidateToFavorite,
  removeFromFavorite: deletingCandidateFromFavorite
}, dispatch);
