import React from 'react';

import { Link, Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';
import { isLoggedIn, getUserType } from 'middleware/auth';

import { Setting } from './container.setting';

@connectTranslation()
export class AuthContainer extends Setting {

  render () {
    return (
      <>
      {isLoggedIn() === true && <Redirect to={
        getUserType() === 'candidate'
          ? '/portal/candidate/balance-board'
          : '/portal/recruiter/balance-board' } />}
      <div className="auth-wrapper">
        <div>
          <header>
            <div className="auth-container">
              <div className={`logo-container
              ${this.props.hideLogo ? 'hidden' : ''}
              ${this.props.welcomeText ? 'auth-home-page' : ''}`}>
                <Link to="/">
                  <img
                    src="/images/shared/logo.svg"
                    alt="Excellent Jobs"/>
                </Link>
                {
                  this.props.welcomeText && <div className="header-title">
                    <Trans i18nKey="auth.title">
                      Welcome!
                    </Trans>
                  </div>
                }
                {
                  this.props.welcomeText && <div className="header-information">
                    <Trans i18nKey="auth.information">
                      What would you like to do?
                    </Trans>
                  </div>
                }
              </div>
            </div>
          </header>
          <div className="main">
            <div className={`auth-container ${this.props.fullWidth && 'auth-full-width-container'}`}>
              { this.props.children }
            </div>
          </div>
        </div>
        <footer>
          <div className="auth-container auth-footer-container">
            <div className="footer-content">
              <div className="copy">
                <Trans i18nKey="portal.footer.copyright">
                  Excellent jobs 2019. All rights reserved.
                </Trans>
              </div>
              <ul className="agreements">
                <li>
                  <Link to="/information/privacy-policy">
                    <Trans i18nKey="portal.footer.privacy">
                      Privacy Policy
                    </Trans>
                  </Link>
                </li>
                <li>
                  <Link to="/information/privacy-policy">
                    <Trans i18nKey="portal.footer.terms">
                      Terms & Conditions
                    </Trans>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      </div>
      </>
    );
  }

}
