import * as actions from './stats.constants';

import { StatsDto } from 'rest/stats/stats.dto';
import { getCandidatesStats } from 'rest/public/candidates/candidates.rest';

const getCandidatesStatisticRequest = () => ({
  type: actions.GET_CANDIDATES_STATS_REQUEST
});

const getCandidatesStatisticSuccess = (stats: StatsDto[] = [], total: number = 0) => ({
  type: actions.GET_CANDIDATES_STATS_SUCCESS,
  stats: stats,
  total: total
});

const getCandidatesStatisticFailure = (error: string) => ({
  type: actions.GET_CANDIDATES_STATS_FAILURE,
  error
});

const resetCandidatesStatisticSuccess = data => ({
  type: actions.RESET_CANDIDATES_STATS_SUCCESS,
  payload: data
});

export const resetCandidatesStats = () => {
  return dispatch => {
    dispatch(resetCandidatesStatisticSuccess());
  };
};

export const getCandidatesStatistic = (companyId: string) => {
  return dispatch => {
    dispatch(getCandidatesStatisticRequest());
    return getCandidatesStats(companyId)
      .then(response => dispatch(getCandidatesStatisticSuccess(response.data.details, response.data.count)))
      .catch(error => dispatch(getCandidatesStatisticFailure(error)));
  };
};
