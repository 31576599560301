import { ImageDto } from '../images/image.dto';

export class RecruiterDto {

  id = '';
  lang: string;
  email: string;
  password: string;
  birthday: string;
  lastName: string;
  firstName: string;
  rememberMe: string;
  phoneNumber: string;
  recruiterImage: ImageDto;
  acceptNotification: true;

  constructor (data?: RecruiterDto) {
    if (data) {
      this.id = data.id;
      this.lang = data.lang;
      this.email = data.email;
      this.lastName = data.lastName;
      this.password = data.password;
      this.birthday = data.birthday;
      this.firstName = data.firstName;
      this.rememberMe = data.rememberMe;
      this.phoneNumber = data.phoneNumber;
      this.acceptNotification = data.acceptNotification;

      if (data.imageId) {
        this.recruiterImage = new ImageDto(data);
      }
    }
  }

}
