import React from 'react';

import { Setting } from './header.setting';

export class PortletHeader extends Setting {

  render () {
    return (
      <div className={`portlet-head ${this.props.className}`}>
        {
          !!this.props.title && <div className="portlet-title">
            <h2>{this.props.title}</h2>
          </div>
        }
        {this.props.children}
      </div>
    );
  }

}
