// @flow

import type { CustomAction } from './../../../action.type';

import * as actions from './sign-in.constants';

import type { SignInState } from './sign-in.type';

const initialState: SignInState = {
  email: '',
  error: false,
  fetching: false,
  errorText: '',
  googleLink: '',
  needConfirm: false,
  facebookLink: '',
  redirectPortal: false,
  signInSuccess: false,
  unauthorizedUserUrl: ''
};

export default (state: SignInState = initialState, action: CustomAction): SignInState => {
  switch (action.type) {
    case actions.CANDIDATE_SIGN_IN_REQUEST:
      return {
        ...state,
        error: false,
        fetching: true
      };

    case actions.CANDIDATE_SIGN_IN_SUCCESS:
      return {
        ...state,
        error: false,
        fetching: false,
        signInSuccess: true,
        redirectPortal: true,
        unauthorizedUserUrl: action.payload.unauthorizedUserUrl
      };

    case actions.CANDIDATE_SIGN_IN_FAILURE:
      return {
        ...state,
        error: true,
        redirectPortal: false,
        errorText: action.error,
        fetching: false,
        signInSuccess: false
      };

    case actions.CANDIDATE_SIGN_IN_NEED_CONFIRM:
      return {
        ...state,
        error: true,
        email: action.email,
        needConfirm: true
      };

    case actions.SOCIAL_URL_REQUEST:
      return {
        ...state,
        fetching: true,
        signInSuccess: false
      };

    case actions.SOCIAL_URL_SUCCESS:
      return {
        ...state,
        fetching: false,
        [action.social + 'Link']: action.url
      };

    case actions.SOCIAL_URL_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.CANDIDATE_SIGN_IN_CLEAR_STATE:
      return { ...initialState };

    default:
      return state;
  }
};
