import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import cn from 'classnames';

import { Setting } from './link-button.setting';

export class LinkButton extends Setting {

  showInternalLink (className: string): HTMLElement {
    return <Link
      to={this.props.link}
      disabled={this.props.disabled}
      onClick={() => this.props.onClick()}
      className={className}>
      {this.props.children}
    </Link>;
  }

  showExternalLink (className: string): HTMLElement {
    return <a
      href={this.props.link}
      onClick={() => this.props.onClick()}
      target="_blank" rel="noopener noreferrer"
      className={className}>
      {this.props.children}
    </a>;
  }

  render () {
    const className = cn({
      [this.props.className]: this.props.className,
      'grey': this.props.grey,
      'inline': this.props.inline,
      'accent': this.props.recruiter,
      'default': this.props.default,
      'primary': !this.props.recruiter && !this.props.secondary,
      'outlined': this.props.outlined,
      'disabled': this.props.disabled,
      'btn': true
    });

    return (
      <Fragment>
        { this.props.external ? this.showExternalLink(className) : this.showInternalLink(className) }
      </Fragment>
    );
  }

}
