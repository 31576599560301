// @flow
import React from 'react';
import { Trans } from 'react-i18next';

import { Button } from 'components/buttons';
import { Modal } from 'containers/portal/modal-container';
import { BetaIntroStep1, BetaIntroStep2 } from './beta-intro-body';

import { connectStore } from 'middleware/connect';
import { Settings, dispatchToProps, stateToProps } from './beta-intro.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class BetaIntroModal extends Settings {

  goNext = () => {
    const { closeModal } = this.props;
    const { activeStep } = this.state;

    if (activeStep === 2) {
      closeModal();
      return;
    }

    this.setState({
      activeStep: activeStep + 1
    });
  };

  handleActiveTab = activeTab => {
    this.setState({ activeTab });
  };

  render () {
    const { isOpen, closeModal, t } = this.props;
    const { activeStep, activeTab } = this.state;

    return (
      <Modal
        overlay
        animation="fade"
        open={isOpen}
        hideModalAvailable={false}
        hideModal={closeModal}
        closeOnOverlay={closeModal}
        className="beta-intro-modal">

        <header className="beta-intro-header">
          <span className="beta-intro-header-logo">
            <img className="logo" src="./images/landing/exsellent-jobs-logo.svg"
              alt="Exsellent-jobs logo" title="Exsellent-jobs"/>
            <span className="modal-label">Beta</span>
          </span>
        </header>

        <main className="beta-intro-body">
          {activeStep === 1 &&
            <BetaIntroStep1 className="beta-intro-step1"/>}
          {activeStep === 2 &&
            <BetaIntroStep2 className="beta-intro-step2" handleActiveTab={this.handleActiveTab} t={t}/>}
        </main>

        <footer className="beta-intro-controls">
          <Button
            large
            className={activeTab ? ' primary' : ' accent'}
            type="button"
            onClick={this.goNext}>
            <Trans i18nKey={`landing.beta-intro.step${activeStep}.button`}>
              Become a tester
            </Trans>
          </Button>
        </footer>
      </Modal>
    );
  }

}
