// @flow
import React from 'react';

import { connectStore } from 'middleware/connect';

import { StatisticsContainer, StatisticsItem } from 'components/widgets/statistics';

import { Settings, stateToProps, dispatchToProps } from './calendar.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class CalendarSidebar extends Settings {

  componentDidMount () {
    this.loadCalendarInvitesStatistic(this.props.currentUser.companyId);
  }

  componentWillUpdate (props) {
    if (!this.props.currentUser.companyId) {
      this.loadCalendarInvitesStatistic(props.currentUser.companyId);
    }
  }

  componentWillUnmount () {
    this.props.resetCalendarInvitesStats();
  }

  loadCalendarInvitesStatistic (companyId: string) {
    if (companyId) {
      this.props.getCalendarStatistic(companyId);
    }
  }

  render () {
    return (
      <StatisticsContainer title={this.props.t('portal.recruiter.calendar.sidebar.title')}>
        <StatisticsItem
          title={this.props.t('portal.recruiter.calendar.sidebar.items.all')}
          value={this.props.total}
          active={!this.props.invitesStatus}
          onClick={() => this.props.changeInvitesStatus()}
          recruiter/>
        {
          this.props.stats.map((stat, index) => (
            <StatisticsItem
              key={index}
              title={`${stat.name.toLowerCase()}`}
              value={stat.count}
              active={this.props.invitesStatus === stat.name}
              onClick={() => this.props.changeInvitesStatus(stat.name)}
              recruiter/>
          ))
        }
      </StatisticsContainer>
    );
  }

}
