import * as actions from './documents.constants';

import type { DocumentsState } from './documents.type';

const initialState: DocumentsState = {
  query: '',
  fetching: false,
  documentsList: [],
  hasMoreItems: true,
  searchDocuments: []
};

export default (state = initialState, action): DocumentsState => {
  switch (action.type) {

    case actions.GET_DOCUMENTS_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case actions.GET_DOCUMENTS_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        documentsList: action.documents
      };

    case actions.SEARCH_DOCUMENTS_REQUEST:
      return {
        ...state,
        fetching: true,
        query: action.query,
        hasMoreItems: false,
        searchDocuments: action.query === state.query ? [...state.searchDocuments] : []
      };

    case actions.SEARCH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        searchDocuments: [ ...state.searchDocuments, ...action.documents ],
        hasMoreItems: action.documents.length > 0
      };

    case actions.SEARCH_DOCUMENTS_RESET:
      return {
        ...state,
        hasMoreItems: true,
        searchDocuments: []
      };

    default:
      return state;
  }
};
