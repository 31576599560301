import React from 'react';

import { Settings } from './avatar.setting';

export class ChatAvatar extends Settings {

  render () {
    return (
      <div className="avatar-container">
        <div className="avatar">
          <img src={this.props.participant.photoUrl}/>
        </div>
        <span className={`status ${this.props.participant.online ? 'online' : ''}`}/>
      </div>
    );
  }

}
