import React, { Component } from 'react';

import { TransProps } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { AuthBody } from 'containers/auth/layouts';
import { AuthImage } from 'containers/auth';
import { InitResetContainer } from 'containers/auth/reset';

type Props = {
  t: TransProps
}

@connectTranslation()
export class InitResetPasswordPage extends Component<Props> {

  render () {
    return (
      <div className="init-reset-password-page">
        <AuthImage imageSrc="/images/auth/rocket.svg"/>
        <AuthBody title={this.props.t('auth.reset.init.title')}>
          <InitResetContainer buttonType="accent"/>
        </AuthBody>
      </div>
    );
  }

}
