import * as actions from './modal.constants';

import type { ModalState } from './modal.type';

import { ModalPropsType } from './modal-props-type';

const initialState: ModalState = {
  modalType: '',
  modalProps: new ModalPropsType()
};

export default (state = initialState, action): ModalState => {
  switch (action.type) {
    case actions.SHOW_MODAL:
      return {
        ...state,
        modalType: action.modalType,
        modalProps: action.modalProps
      };

    case actions.HIDE_MODAL:
      return {
        ...state,
        modalType: '',
        modalProps: new ModalPropsType()
      };

    default:
      return state;
  }
};
