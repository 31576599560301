export class RequestSkillDto {

  name: string;
  skillType: string;

  constructor (data?: RequestSkillDto) {
    if (data) {
      this.name = data.name;
      this.skillType = data.skillType;
    }
  }

}
