import React, { Component } from 'react';

import { Container } from 'components/layouts';

import { Inside, Platform } from 'containers/information';

export class AboutUsPage extends Component {

  render () {
    return (
      <div className="about-us-container">
        <Container>
          <Platform/>
          <Inside/>
        </Container>
      </div>
    );
  }

}
