import React from 'react';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { uploadFile } from 'services/portal/images/images.actions';
import { createPost, resetCreationPost } from 'services/portal/candidate/blog/blog.actions';

import { ImageDto } from 'rest/images/image.dto';
import { PostDto } from 'rest/candidates/posts/post.dto';
import { ImageResponseDto } from 'rest/images/images.response';

import './modal.scss';
import './modal.responsive.scss';

export type Props = {
  t: TransProps,
  imageFetching: boolean,
  createPostSuccess: boolean,
  imageInfo: ImageResponseDto,
  createPost: (data: PostDto) => void,
  resetCreationPost: () => void,
  uploadFile: (file: File, type: string) => void
}

type State = {
  title: string,
  article: string,
  postPhotos: ImageDto[],
  createPostSuccess: boolean
}

export class Settings extends Component<Props> {

  static defaultProps: Props = {
    createPost: () => {},
    uploadFile: () => {},
    resetCreationPost: () => {}
  };

  state: State = {
    title: '',
    article: '',
    postPhotos: [],
    previewPhotos: [],
    imageSizeError: '',
    createPostSuccess: false
  };

  maxPhotosCount: number = 3;
  inputUpload = React.createRef();

}

export const stateToProps = (state: GlobalState) => ({
  imageInfo: state.portal.images.imageInfo,
  posts: state.portal.candidate.blog.posts,
  imageFetching: state.portal.images.imageLoading,
  createPostSuccess: state.portal.candidate.blog.createPostSuccess
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  createPost,
  uploadFile,
  resetCreationPost
}, dispatch);
