import * as actions from './process.constants';

import type { ProcessJobOfferState } from './process.type';

import { JobOfferDto } from 'rest/job-offers/job-offers.dto';

const initialState: ProcessJobOfferState = {
  jobTitleId: '',
  fetching: false,
  totalMatched: 0,
  matchingRate: [],
  createSkillSuccess: false,
  jobOffer: new JobOfferDto(),
  createJobOfferSuccess: false,
  publishJobOfferSuccess: false
};

export default (state = initialState, action): ProcessJobOfferState => {
  switch (action.type) {

    case actions.GET_JOB_OFFER_MATCHING_RATE_REQUEST:
      return {
        ...state,
        totalMatched: 0,
        matchingRate: []
      };

    case actions.GET_JOB_OFFER_MATCHING_RATE_FAILURE:
      return {
        ...state,
        totalMatched: 0,
        matchingRate: []
      };

    case actions.GET_JOB_OFFER_MATCHING_RATE_SUCCESS:
      return {
        ...state,
        totalMatched: action.payload.total,
        matchingRate: action.payload.matchedPointsCandidate
      };

    case actions.SAVE_CURRENT_JOB_TITLE_SUCCESS:
      return {
        ...state,
        jobTitleId: action.jobTitleId
      };

    case actions.CREATE_JOB_OFFER_REQUEST:
      return {
        ...state,
        fetching: true,
        createSkillSuccess: false
      };

    case actions.CREATE_JOB_OFFER_SKILLS_SUCCESS:
      return {
        ...state,
        fetching: false,
        createSkillSuccess: true
      };

    case actions.CREATE_JOB_OFFER_FAILURE:
      return {
        ...state,
        fetching: false
      };

    case actions.CREATE_JOB_OFFER_SUCCESS:
      return {
        ...state,
        fetching: false,
        jobOffer: action.jobOffer,
        createJobOfferSuccess: true
      };

    case actions.PUBLISH_JOB_OFFER_SUCCESS:
      return {
        ...state,
        fetching: false,
        createSkillSuccess: false,
        publishJobOfferSuccess: true
      };

    case actions.DELETE_SAVED_JOB_OFFER_DATA_SUCCESS:
      return { ...initialState };

    default:
      return state;
  }
};
