// @flow
import React from 'react';

import moment from 'moment';

import { ShowSalaryRange } from 'components/elements';

import { Setting } from './candidate-row.setting';
import { connectTranslation } from 'middleware/connect';

@connectTranslation()
export class CandidateRow extends Setting {

  render () {
    const candidate = this.props.candidate;

    return (
      <tr
        onClick={() => this.props.onClick()}
        className="candidate-row">
        <td className="candidate-avatar">
          <div className="cell">
            <div className="avatar-wrapper">
              <img
                src={candidate.primaryPhotoUrl}
                alt=""/>
            </div>
          </div>
        </td>
        <td>
          <div className="cell">
            <div><strong>{candidate.fullName}</strong></div>
            {candidate.candidateAddress &&
            <div>{candidate.candidateAddress.city}, {candidate.candidateAddress.country}</div>}
          </div>
        </td>
        <td>
          <div className="cell">
            <div><strong>
              {candidate.jobTitles.length
                ? candidate.jobTitles[0].name
                : this.props.t('portal.recruiter.favorites.list.no-information')}
            </strong></div>
            <div>
              {this.props.t('portal.recruiter.favorites.list.updated')}
              &nbsp;
              {moment(candidate.updatedDate).parseZone().fromNow() ||
              this.props.t('portal.recruiter.favorites.list.no-information')}
            </div>
          </div>
        </td>
        <td>
          <div className="cell">
            <div>
              <strong>
                {candidate.branches.length
                  ? candidate.branches[0].name
                  : this.props.t('portal.recruiter.favorites.list.no-information')}
              </strong>
            </div>
            <ShowSalaryRange salaryRange={candidate.salaryResponse}/>
          </div>
        </td>
        <td className="actions">
          <div className="cell">
            <div>{this.props.children}</div>
          </div>
        </td>
      </tr>
    );
  }

}
