import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { ListDto, PaginationDto } from 'middleware/dto';

import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';

import { getAllSoftware } from 'services/portal/software/software.actions';
import { getAllLanguages } from 'services/portal/languages/languages.actions';
import { getAllDocuments } from 'services/portal/documents/documents.actions';
import {
  getCandidateSkillsById,
  updateCandidateSkillsById
} from 'services/portal/candidate/skills/skills.actions';

import './requirements.responsive.scss';

export type Props = {
  t: TransProps,
  fetching: boolean,
  prev: () => void,
  next: () => void,
  software: ListDto[],
  languages: ListDto[],
  documents: ListDto[],
  currentCandidate: CurrentCandidateDto,
  getAllSoftware: (params: PaginationDto) => void,
  getAllLanguages: (params: PaginationDto) => void,
  getAllDocuments: (params: PaginationDto) => void,
};

type State = {
  redirect: boolean,
  software: ListDto[],
  languages: ListDto[],
  documents: ListDto[],
  skillType: string,
  isAddSoftwareModalOpen: boolean,
  isAddLanguagesModalOpen: boolean,
  isAddDocumentsModalOpen: boolean,
  isSuggestNewSkillModalOpen: boolean
}

export class Settings extends Component<Props, State> {

  static defaultProps: Props = {
    software: [],
    languages: [],
    documents: [],
    prev: () => {},
    next: () => {},
    getAllSkills: () => {},
    getAllSoftware: () => {},
    getAllLanguages: () => {},
    getAllDocuments: () => {}
  };

  state: State = {
    redirect: false,
    skillType: '',
    isAddSkillsModalOpen: false,
    isAddSoftwareModalOpen: false,
    isAddDocumentsModalOpen: false,
    isAddLanguagesModalOpen: false,
    isSuggestNewSkillModalOpen: false,
    software: this.props.software,
    languages: this.props.languages,
    documents: this.props.documents
  };

}

export const stateToProps = (state: GlobalState) => ({
  documents: state.portal.documents.documentsList,
  fetching: state.portal.candidate.skills.fetching,
  softwareSkills: state.portal.software.softwareList,
  languageSkills: state.portal.languages.languagesList,
  candidateSkills: state.portal.candidate.skills.content,
  redirect: state.portal.candidate.skills.updateSkillsSuccess,
  currentCandidate: state.portal.candidate.account.current.candidateData
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getAllSoftware,
  getAllLanguages,
  getAllDocuments,
  getSkills: getCandidateSkillsById,
  updateSkills: updateCandidateSkillsById
}, dispatch);
