export const GET_JOB_OFFERS_REQUEST = 'GET_JOB_OFFERS_REQUEST';
export const GET_JOB_OFFERS_SUCCESS = 'GET_JOB_OFFERS_SUCCESS';
export const GET_JOB_OFFERS_FAILURE = 'GET_JOB_OFFERS_FAILURE';

export const GET_JOB_OFFER_REQUEST = 'GET_JOB_OFFER_REQUEST';
export const GET_JOB_OFFER_SUCCESS = 'GET_JOB_OFFER_SUCCESS';
export const GET_JOB_OFFER_FAILURE = 'GET_JOB_OFFER_FAILURE';

export const MARK_JOB_OFFER_AS_VIEWED_REQUEST = 'MARK_JOB_OFFER_AS_VIEWED_REQUEST';

export const SET_JOBS_FILTER = 'SET_JOBS_FILTER';
export const RESET_JOBS_FILTER = 'RESET_JOBS_FILTER';

export const RESET_JOB_OFFERS_LIST_SUCCESS = 'RESET_JOB_OFFERS_LIST_SUCCESS';

export const MARK_JOB_OFFER_AS_APPLIED_SUCCESS = 'MARK_JOB_OFFER_AS_APPLIED_SUCCESS';

export const RESET_JOB_OFFER_SUCCESS = 'RESET_JOB_OFFER_SUCCESS';
