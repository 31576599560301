// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { recruiterSignIn } from 'services/auth/recruiter/auth.action';

import { RecruiterDto } from 'rest/recruiter/recruiter.dto';

import './sign-in-form.scss';
import './sign-in-form.responsive.scss';

export type Props = {
  error: boolean,
  errorText?: string,
  redirect?: boolean,
  fetching?: boolean,
  needConfirm?: boolean,
  unauthorizedUserUrl?: string;
  recruiterSignIn?: (data: RecruiterDto) => void
};

export type State = {
  email: string,
  password: string,
  errorText: string,
  redirect: boolean,
  rememberMe: boolean,
  unauthorizedUserUrl: string,
  errorCollection: Set<string>
}

export class Setting extends Component<Props, State> {

  static defaultProps = {
    needConfirm: false,
    unauthorizedUserUrl: '/portal/recruiter/account',
    recruiterSignIn: () => {}
  };

  state: State = {
    email: '',
    password: '',
    errorText: '',
    rememberMe: true,
    errorCollection: new Set(),
    unauthorizedUserUrl: '/portal/recruiter/account',
    redirect: false
  };

}

export const stateToProps = (state: GlobalState) => ({
  error: state.auth.recruiter.signIn.error,
  fetching: state.auth.recruiter.signIn.fetching,
  errorText: state.auth.recruiter.signIn.errorText,
  redirect: state.auth.recruiter.signIn.signInSuccess,
  needConfirm: state.auth.recruiter.signIn.needConfirm,
  unauthorizedUserUrl: state.auth.recruiter.signIn.unauthorizedUserUrl
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  recruiterSignIn
}, dispatch);
