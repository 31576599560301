import { Component } from 'react';

import typeof { Children } from 'react';

import './placeholder.scss';
import './placeholder.responsive.scss';

type Props = {
  title: string,
  children: Children,
  className?: string
}

export class Settings extends Component<Props> {

  static defaultProps = {
    title: '',
    className: ''
  }

}
