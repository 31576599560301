// @flow
import React, { Fragment } from 'react';

import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Setting } from './show-salary-range.setting';

@connectTranslation()
export class ShowSalaryRange extends Setting {

  render () {
    return (
      <Fragment>
        {
          (this.props.salaryRange && this.props.salaryRange.min && this.props.salaryRange.max) && <span>
            € {this.props.salaryRange.min.toLocaleString()} - {this.props.salaryRange.max.toLocaleString()}
          </span>
        }
        {
          (this.props.salaryRange && this.props.salaryRange.min && !this.props.salaryRange.max) && <span>
            {this.props.t('portal.recruiter.balance-board.preview.salary.from')}
            {this.props.salaryRange.min.toLocaleString()}
          </span>
        }
        {
          (this.props.salaryRange && !this.props.salaryRange.min && this.props.salaryRange.max) && <span>
            {this.props.t('portal.recruiter.balance-board.preview.salary.till')}
            {this.props.salaryRange.max.toLocaleString()}
          </span>
        }
        {
          (!this.props.salaryRange || (!this.props.salaryRange.min && !this.props.salaryRange.max)) && <span>
            <Trans i18nKey="portal.common.salary.no-information">
              No information
            </Trans>
          </span>
        }
      </Fragment>
    );
  }

}
