// @flow
import React, { Fragment } from 'react';

import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';

import { connectStore } from 'middleware/connect';
import { setData } from 'middleware/job-offer-storage';

import { Button } from 'components/buttons';
import { FormGroup } from 'components/controls';

import { SkillItem } from './skill-item/skill-item';

import { SkillsDto } from 'rest/skill/skills.dto';
import { SkillItemDto } from 'rest/skill/skill-item.dto';

import type { Props } from './knowledge-level.setting';
import { Settings, stateToProps, dispatchToProps } from './knowledge-level.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class KnowledgeLevelTab extends Settings {

  componentDidMount () {
    this.props.getCandidateSkillsById(this.props.currentCandidate.id);
  }

  componentWillReceiveProps (nextProps: Props) {
    if (this.state !== this.props.candidateSkills) {
      this.setState({ ...this.props.candidateSkills });
    }
  }

  changeSkillLevelState (field: string, value: string, skillId: number) {
    const skills = this.state[field].map(item => {
      if (item.id === skillId) {
        return {
          ...item,
          skillLevel: value.toUpperCase()
        };
      }
      return item;
    });
    this.setState({ [field]: skills });
  }

  saveBalanceBoard (event: Event) {
    event.preventDefault();
    const skillsData = new SkillsDto(this.state);
    setData('skillsBalanceBoard', skillsData);
    if (this.props.match.params.action === 'create') {
      this.props.createCandidateSkillsById(this.props.currentCandidate.id, skillsData);
      toast.success(this.props.t('portal.candidate.notifications.bb-created'));
    } else {
      this.props.updateCandidateSkillsById(this.props.currentCandidate.id, skillsData);
      toast.success(this.props.t('portal.candidate.notifications.bb-updated'));
    }
    this.setState({ redirect: true });
  }

  render () {
    const skillList: string[] = [
      'professionalSkills',
      'languageSkills',
      'softwareSkills',
      'documents',
      'softSkills'
    ];
    return (
      <Fragment>
        {
          this.state.redirect && <Redirect to="/portal/candidate/balance-board"/>
        }
        <form
          onSubmit={this.saveBalanceBoard.bind(this)}
          className="priority-match-form knowledge-level-form mobile-knowledge-level-form">
          <div className="row sticky skill-name">
            <div className="column">
              <Trans i18nKey="portal.candidate.balance-board.edit.tabs.knowledge.name">
                Skill Name
              </Trans>
            </div>
            <div className="column level">
              <Trans i18nKey="portal.candidate.balance-board.edit.tabs.knowledge.level">
                Knowledge Level
              </Trans>
            </div>
          </div>
          {
            skillList.map(section => {
              return (this.state[section] || []).map((item: SkillItemDto, index: number) => (
                <SkillItem
                  key={index}
                  hideSkillLevel={section === 'documents'}
                  change={value => this.changeSkillLevelState(section, value, item.skillId)}
                  skill={item}/>
              ));
            })
          }
          <FormGroup className="full-width form-buttons">
            <Button
              grey
              type="button"
              onClick={() => this.props.prev()}>
              <Trans i18nKey="portal.candidate.balance-board.edit.buttons.back">
                Back
              </Trans>
            </Button>
            <Button
              candidate
              medium
              type="submit">
              {
                this.props.match.params.action === 'create'
                  ? this.props.t('portal.candidate.balance-board.edit.buttons.save')
                  : this.props.t('portal.candidate.balance-board.edit.buttons.update')
              }
            </Button>
          </FormGroup>
        </form>
      </Fragment>
    );
  }

}
