// @flow
import React from 'react';

import { connectTranslation } from 'middleware/connect';

import { Container } from 'components/layouts';

import { Setting } from './comment.setting';

import gastronomiejournal from './gastronomiejournal.jpg';

@connectTranslation()
export class Comment extends Setting {

  render() {
    return (
      <div className="comment-container">
        <Container className="comment-content">
          <div>
            <img
              src={gastronomiejournal}
              style={{ width: '350px', borderRadius: '5px' }}
            />
          </div>
        </Container>
      </div>
    );
  }

}
