import * as actions from './job-offers.constants';

import axios from 'axios';

import { Action, Dispatch } from 'redux';

import { getPublicCompany } from 'rest/companies/companies.rest';
import { markJobOfferAsViewed } from 'rest/job-offers/viewed/viewed.rest';
import { getAllJobOfferRest, getJobOfferRest, getJobOfferSkillRest } from 'rest/public/job-offers/job-offers.rest';

import { CompanyDto } from 'rest/companies/companies.dto';
import { SkillsContainerDto } from 'rest/skills/skills-container.dto';
import { JobOfferViewedDto } from 'rest/job-offers/viewed/viewed.dto';
import { JobOffersParams } from 'rest/public/job-offers/job-offers.params';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

const getJobOffersRequest = (): Action => ({
  type: actions.GET_JOB_OFFERS_REQUEST
});

const getJobOffersSuccess = (data): Action => ({
  type: actions.GET_JOB_OFFERS_SUCCESS,
  payload: data
});

const getJobOffersFailure = (): Action => ({
  type: actions.GET_JOB_OFFERS_FAILURE
});

const getJobOfferRequest = (): Action => ({
  type: actions.GET_JOB_OFFER_REQUEST
});

const markJobOffeAsViewedRequest = (): Action => ({
  type: actions.MARK_JOB_OFFER_AS_VIEWED_REQUEST
});

const markJobOfferAsAppliedSuccess = (): Action => ({
  type: actions.MARK_JOB_OFFER_AS_APPLIED_SUCCESS
});

const getJobOfferSuccess = (
  jobOffer: JobOffersResponseDto,
  jobOfferSkills: SkillsContainerDto,
  company: CompanyDto): Action => {
  return {
    type: actions.GET_JOB_OFFER_SUCCESS,
    company,
    payload: jobOffer,
    jobOfferSkills
  };
};

const getJobOfferFailure = (): Action => ({
  type: actions.GET_JOB_OFFER_FAILURE
});

const setCandidatesFilters = (filter: JobOffersParams): Action => ({
  type: actions.SET_JOBS_FILTER,
  filter: filter
});

const resetJobOfferFilters = (): Action => ({
  type: actions.RESET_JOBS_FILTER
});

const resetJobOfferListSuccess = (): Action => ({
  type: actions.RESET_JOB_OFFERS_LIST_SUCCESS
});

const resetJobOfferSuccess = (): Action => ({
  type: actions.RESET_JOB_OFFER_SUCCESS
});

export function resetJobOffersList () {
  return (dispatch: Dispatch) => {
    dispatch(resetJobOfferListSuccess());
  };
}

export function markJobOfferAsApplied () {
  return (dispatch: Dispatch) => {
    dispatch(markJobOfferAsAppliedSuccess());
  };
}

export function resetJobOffer () {
  return (dispatch: Dispatch) => {
    dispatch(resetJobOfferSuccess());
  };
}

export function initJobsFilter (filter: JobOffersParams) {
  return (dispatch: Dispatch) => {
    dispatch(setCandidatesFilters(filter));
  };
}

export function clearJobsFilter () {
  return (dispatch: Dispatch) => {
    dispatch(resetJobOfferFilters());
  };
}

export function getJobOffers (params: JobOffersParams = new JobOffersParams()) {
  return (dispatch: Dispatch) => {
    dispatch(getJobOffersRequest());
    return getAllJobOfferRest(params)
      .then(response => dispatch(getJobOffersSuccess(response.data)))
      .catch(() => dispatch(getJobOffersFailure()));
  };
}

export function getJobOfferWithSkills (id: string) {
  return (dispatch: Dispatch) => {
    dispatch(getJobOfferRequest());
    return axios
      .all([getJobOfferRest(id), getJobOfferSkillRest(id)])
      .then(axios.spread((jobOffer, skills) => loadJobOfferCompany(jobOffer.data, skills.data)(dispatch)))
      .catch(() => dispatch(getJobOfferFailure()));
  };
}

function loadJobOfferCompany (jobOffer: JobOffersResponseDto, jobOfferSkills: SkillsContainerDto) {
  return (dispatch: Dispatch) => {
    dispatch(getJobOfferRequest());
    return getPublicCompany(jobOffer.companyId)
      .then(company => dispatch(getJobOfferSuccess(jobOffer, jobOfferSkills, company.data)))
      .catch(() => dispatch(getJobOfferFailure()));
  };
}

export function markJobOfferAsViewedStats (jobOfferId: string, data: JobOfferViewedDto) {
  return (dispatch: Dispatch) => {
    dispatch(markJobOffeAsViewedRequest());
    return markJobOfferAsViewed(jobOfferId, data);
  };
}
