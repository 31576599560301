// @flow
import React from 'react';

import { Trans } from 'react-i18next';

import { getData } from 'middleware/job-offer-storage';
import { connectTranslation } from 'middleware/connect';
import { isObjectEmpty } from 'middleware/is-object-empty';

import { Card } from 'components/layouts';
import { Tab, Tabs, TabHeader } from 'components/tabs';

import { PersonalInfo } from './personal-info/personal-info';
import { CompanyInfoTab } from './company-Info/company-info';
import { GeneralInfoTab } from './general-info/general-info';
import { PriorityMatchTab } from './priority-match/priority-match';
import { SkillsRequirementsTab } from './skills-requirements/skills-requirements';

import { SkillsDto } from 'rest/skill/skills.dto';
import { CompanyDto } from 'rest/companies/companies.dto';
import { JobOfferDto } from 'rest/job-offers/job-offers.dto';

import { Settings } from './job-offer.setting';

@connectTranslation()
export class CreateJobOfferGuest extends Settings {

  render () {
    const skills: SkillsDto = getData('ej_skills_job_offer');
    const generalInfo: JobOfferDto = getData('ej_general_job_offer');
    const isCompanyPresent: CompanyDto = getData('ej_company_info');
    const isSkillsTabActive: boolean = generalInfo && !isObjectEmpty(generalInfo);
    const isPersonalInfoTabActive: boolean = skills ? skills.matchPoints > 0 : false;
    const isPaymentStepActive: boolean = this.paymentStepIndex === this.state.activeMainTab;
    const isPriorityTabActive: boolean = skills ? skills.professionalSkills.length > 0 : false;
    return (
      <Card className="guest-job-offer-container">
        <h3 className="title">
          <Trans i18nKey="portal.recruiter.guest.job-offer.title">
            Glad to see you with us!
          </Trans>
        </h3>
        <Tabs
          headClassName="linear-tabs"
          activeTabIndex={this.state.activeMainTab}
          activeTabChange={activeTab => this.setState({ activeMainTab: activeTab })}>
          <Tab
            disabled={isPaymentStepActive}
            label={this.props.t('portal.guest.recruiter.job-offer.company-info.header')}>
            <TabHeader
              title={this.props.t('portal.guest.recruiter.job-offer.company-info.title')}
              subTitle={this.props.t('portal.guest.recruiter.job-offer.company-info.description')}/>
            <CompanyInfoTab next={() => this.setState({ activeMainTab: this.state.activeMainTab + 1 })}/>
          </Tab>
          <Tab
            disabled={!isCompanyPresent || isPaymentStepActive}
            label={this.props.t('portal.guest.recruiter.job-offer.header')}>
            <Tabs
              headClassName="linear-tabs sub-tabs-layout"
              activeTabIndex={this.state.activeSubTab}
              activeTabChange={activeTab => this.setState({ activeSubTab: activeTab })}>
              <Tab
                disabled={!isCompanyPresent}
                label={this.props.t('portal.guest.recruiter.job-offer.general.header')}>
                <TabHeader
                  title={this.props.t('portal.guest.recruiter.job-offer.general.title')}
                  subTitle={this.props.t('portal.guest.recruiter.job-offer.general.description')}/>
                <GeneralInfoTab
                  prev={() => this.setState({ activeMainTab: this.state.activeMainTab - 1 })}
                  next={() => this.setState({ activeSubTab: this.state.activeSubTab + 1 })}/>
              </Tab>
              <Tab
                disabled={!isSkillsTabActive}
                label={this.props.t('portal.guest.recruiter.job-offer.skills.header')}>
                <SkillsRequirementsTab
                  next={() => this.setState({ activeSubTab: this.state.activeSubTab + 1 })}
                  prev={() => this.setState({ activeSubTab: this.state.activeSubTab - 1 })}/>
              </Tab>
              <Tab
                disabled={!isPriorityTabActive}
                label={this.props.t('portal.guest.recruiter.job-offer.priority.header')}>
                <TabHeader title="Matching Setup"/>
                <PriorityMatchTab
                  next={() => this.setState({ activeMainTab: this.state.activeMainTab + 1 })}
                  prev={() => this.setState({ activeSubTab: this.state.activeSubTab - 1 })}/>
              </Tab>
            </Tabs>
          </Tab>
          <Tab
            disabled={!isPersonalInfoTabActive || isPaymentStepActive}
            label={this.props.t('portal.guest.recruiter.job-offer.personal-info.header')}>
            <TabHeader
              title={this.props.t('portal.guest.recruiter.job-offer.personal-info.title')}
              subTitle={this.props.t('portal.guest.recruiter.job-offer.personal-info.description')}/>
            <PersonalInfo
              pushState={this.props.history.push}
              next={() => this.setState({ activeMainTab: this.state.activeMainTab + 1 })}
              prev={() => this.setState({ activeSubTab: this.state.activeSubTab - 1 })}/>
          </Tab>
          <Tab
            disabled
            label={this.props.t('portal.guest.recruiter.job-offer.payment.header')}>
          </Tab>
        </Tabs>
      </Card>
    );
  }

}
