import React from 'react';
import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';

import { Button } from 'components/buttons';
import { FormGroup, InputControl, AutosuggestSelect } from 'components/controls';
import { Settings, dispatchToProps, stateToProps } from './payment-details-invoice-info.setting';
import { InvoiceAddressList, InvoiceAddressListItem } from './invoice-address-list';

import { AddressDto } from 'rest/address/address.dto';
import { UserAddressDto } from 'rest/address-dto/address';
import { CompanyDto } from 'rest/companies/companies.dto';

@connectStore(stateToProps, dispatchToProps, true)
export class PaymentDetailsInvoiceInfo extends Settings {

  componentDidMount () {
    this.loadSavedData();
  }

  loadSavedData = () => {
    const { company: { name, address, vatId } } = this.props;

    this.setState({
      name,
      vatId,
      ...address
    });
  };

  handleCancel = () => {
    this.loadSavedData();
    this.props.handleEditableState();
  };

  handleOnSubmit = e => {
    if (e) e.preventDefault();

    const { handleEditableState, handleVatIdChange } = this.props;
    const { vatId } = this.state;

    if (!vatId) {
      this.setState({ vatIdError: true });
      return;
    }

    handleVatIdChange(vatId);
    handleEditableState();
    this.saveDraft();
  };

  suggestCountry (value) {
    const query: AddressDto = new AddressDto();
    query.city = '';
    query.street = '';
    query.country = value;
    this.props.searchSuggestedAddress('country', query);
  }

  suggestStreet (value) {
    const query: AddressDto = new AddressDto(this.state);
    query.street = value;
    this.props.searchSuggestedAddress('street', query);
  }

  suggestCity (value) {
    const query: AddressDto = new AddressDto(this.state);
    query.street = '';
    query.city = value;
    this.props.searchSuggestedAddress('city', query);
  }

  saveDraft = e => {
    const { company, updateRecruiterCompany } = this.props;
    const { name, vatId } = this.state;

    const companyData: CompanyDto = {
      ...company,
      vatId,
      name,
      address: {
        ...new UserAddressDto(this.state)
      }
    };

    updateRecruiterCompany(company.id, companyData);
  };

  render () {
    const { disabled, t, address, editable, paymentMethod, handleEditableState } = this.props;
    const { vatId, vatIdError, name, city, country, street, streetNumber, zipCode } = this.state;
    const paymentMethodDescription = paymentMethod === 'SOFORT' ? ' Sofort' : ' Sepa Direct';

    return (
      <div className='payment-invoice-details'>
        {editable && !disabled
          ? <form
            className="company-info-form"
            onSubmit={this.handleOnSubmit}>
            <FormGroup className="half-width">
              <InputControl
                type="text"
                name="vatId"
                value={vatId}
                label={t('portal.guest.recruiter.job-offer.payment.invoice-details.vat-id.label')}
                placeholder={t('portal.guest.recruiter.job-offer.payment.' +
                  'invoice-details.vat-id.placeholder')}
                error={vatIdError && !vatId}
                errorText={t('portal.guest.recruiter.job-offer.payment.invoice-details.vat-id.error')}
                change={vatId => this.setState({ vatId })}/>
            </FormGroup>
            <FormGroup className="half-width">
              <InputControl
                required
                name="name"
                value={name}
                label={t('portal.guest.recruiter.job-offer.payment.invoice-details.company-name.label')}
                placeholder={t('portal.guest.recruiter.job-offer.payment.' +
                  'invoice-details.company-name.placeholder')}
                change={name => this.setState({ name })}
                type="text"/>
            </FormGroup>
            <div className="form-group-row">
              <FormGroup className="half-width">
                <AutosuggestSelect
                  required
                  label={t('portal.guest.recruiter.job-offer.payment.invoice-details.country.label')}
                  items={address.country}
                  placeholder={t('portal.guest.recruiter.job-offer.payment' +
                    '.invoice-details.country.placeholder')}
                  defaultValue={country}
                  change={value => this.setState({ country: value })}
                  search={value => this.suggestCountry(value) }/>
              </FormGroup>
              <FormGroup className="half-width">
                <AutosuggestSelect
                  label={t('portal.guest.recruiter.job-offer.payment.invoice-details.city.label')}
                  required
                  items={address.city}
                  defaultValue={city}
                  change={value => this.setState({ city: value })}
                  search={value => this.suggestCity(value) }
                  placeholder={t('portal.guest.recruiter.job-offer.payment' +
                    '.invoice-details.city.placeholder')}/>
              </FormGroup>
            </div>
            <FormGroup className="half-width">
              <InputControl
                required
                label={t('portal.guest.recruiter.job-offer.payment.invoice-details.zip.label')}
                value={zipCode}
                placeholder={t('portal.guest.recruiter.job-offer.payment.invoice-details.zip.placeholder')}
                errorText={t('portal.guest.recruiter.job-offer.payment.invoice-details.zip.error')}
                change={zipCode => this.setState({ zipCode })}
                type="text"/>
            </FormGroup>
            <div className="form-group-row">
              <FormGroup className="half-width">
                <AutosuggestSelect
                  required
                  defaultValue={street}
                  items={address.street}
                  label={t('portal.guest.recruiter.job-offer.payment.invoice-details.street.label')}
                  placeholder={t('portal.guest.recruiter.job-offer.payment' +
                    '.invoice-details.street.placeholder')}
                  change={value => this.setState({ street: value })}
                  search={value => this.suggestStreet(value)}/>
              </FormGroup>
              <FormGroup className="half-width billing-number">
                <InputControl
                  required
                  label={t('portal.guest.recruiter.job-offer.payment' +
                    '.invoice-details.building-number.label')}
                  value={streetNumber}
                  placeholder={t('portal.guest.recruiter.job-offer.payment' +
                    '.invoice-details.building-number.placeholder')}
                  errorText={t('portal.guest.recruiter.job-offer.payment' +
                    '.invoice-details.building-number.error')}
                  change={streetNumber => this.setState({ streetNumber })}
                  type="text"/>
              </FormGroup>
            </div>
            <div className="change-payment-actions">
              <Button
                large
                className="btn accent"
                type="submit">
                <Trans i18nKey="portal.recruiter.profile.payment.buttons.confirm">
                  Confirm
                </Trans>
              </Button>
              <Button
                type="button"
                className="accent grey flex-basis-auto"
                onClick={this.handleCancel}
                grey>
                <Trans i18nKey="portal.recruiter.profile.payment.buttons.cancel">
                  Cancel
                </Trans>
              </Button>
            </div>
            <p className="agreement">
              <Trans i18nKey="portal.recruiter.profile.payment.redirect">
                By clicking ‘Confirm Payment’ you’ll be redirected to the
                {{ paymentMethodDescription }} website for payment
              </Trans>
            </p>
          </form>
          : <div className="company-info-default">
            <h5 className="company-info-default-title m-0">
              Invoice details
            </h5>
            <InvoiceAddressList>
              <InvoiceAddressListItem
                title={t('portal.guest.recruiter.job-offer.payment.invoice-details.vat-id.title')}>
                <span className="invoice-address-list-info">
                  &nbsp;{vatId || t('portal.guest.recruiter.job-offer.payment.invoice-details.vat-id.no-vat-id')}
                </span>
              </InvoiceAddressListItem>
              <InvoiceAddressListItem title="Hotel name:" i18nKey="hotel">
                <span className="invoice-address-list-info">&nbsp;{name}</span>
              </InvoiceAddressListItem>
              <InvoiceAddressListItem title="Address:" i18nKey="address">
                <span className="invoice-address-list-info">&nbsp;{country},</span>
                <span className="invoice-address-list-info">&nbsp;{city},</span>
                <span className="invoice-address-list-info">&nbsp;{zipCode},</span>
                <span className="invoice-address-list-info">&nbsp;{street},</span>
                <span className="invoice-address-list-info">&nbsp;{streetNumber}</span>
              </InvoiceAddressListItem>
            </InvoiceAddressList>
            <Button
              recruiter
              type="button"
              className="accent outlined large"
              onClick={handleEditableState}>
              <Trans i18nKey="portal.guest.recruiter.job-offer.payment.invoice-details.buttons.change-info">
                Change Info
              </Trans>
            </Button>
          </div>
        }
      </div>
    );
  }

}
