import { Component } from 'react';

import { TransProps } from 'react-i18next';

import './job-offer.scss';

type Props = {
  t?: TransProps
};

type State = {
  activeMainTab: number,
  activeSubTab: number
}

export class Settings extends Component<Props, State> {

  state: State = {
    activeMainTab: 0,
    activeSubTab: 0
  };

}
