// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import type { GlobalState } from 'common/types/global-state';

import { JobOfferStatus } from 'rest/job-offers/job-offers.status.dto';
import { CurrentRecruiterDto } from 'rest/recruiter/current/current.dto';
import { JobOffersResponseDto } from 'rest/public/job-offers/job-offers.response.dto';

import { updateJobOfferStatus } from 'services/portal/recruiter/account/job-offer/job-offer.actions';

import './job-offer-row.scss';

export type Props = {
  history: History,
  jobOffer: JobOffersResponseDto,
  currentUser?: CurrentRecruiterDto,
  onClick: (jobOffer: JobOffersResponseDto) => void,
  updateJobOfferStatus?: (companyId: string, jobOfferId: string, status: JobOfferStatus) => void
};

export type State = {
  isMenuOpen: boolean,
  isDeleteModalOpen: boolean
}

export class Setting extends Component<Props, State> {

  static defaultProps = {
    onClick: () => {},
    updateJobOfferStatus: () => {},
    jobOffer: new JobOffersResponseDto()
  };

  state: State = {
    isMenuOpen: false,
    isDeleteModalOpen: false
  };

}

export const stateToProps = (state: GlobalState) => ({
  currentUser: state.portal.recruiter.account.current.currentUser
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  updateJobOfferStatus
}, dispatch);
