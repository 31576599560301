// @flow
import axios from 'axios';

import type { ApiData } from 'common/types/api-data';
import type { SocialLinkInDto } from 'rest/auth/social-link.dto';

import { AuthDto } from './auth.dto';
import { AuthOutDto } from './auth.out';

export async function getSocialAuthUrl (type: string): Promise<SocialLinkInDto> {
  try {
    return await axios.get(`/auth/${type}`);
  } catch (error) {
    throw error;
  }
}

export async function socialLogin (params: AuthOutDto): Promise<ApiData<AuthDto>> {
  try {
    return await axios.post(`/auth/${params.type}`, params);
  } catch (error) {
    throw error;
  }
}
