// @flow
import React from 'react';

import { connectStore } from 'middleware/connect';

import { StatisticsContainer, StatisticsItem } from 'components/widgets/statistics';

import { CandidatesParams } from 'rest/public/candidates/candidates.params';

import { Settings, stateToProps, dispatchToProps } from './candidates.setting';

@connectStore(stateToProps, dispatchToProps, true)
export class CandidatesSidebar extends Settings {

  componentDidMount () {
    this.props.getCandidatesStatistic();
  }

  componentWillUpdate (props) {
    if (!this.props.currentUser.companyId && props.currentUser.companyId) {
      this.props.getCandidatesStatistic();
    }
  }

  componentWillUnmount () {
    this.props.resetCandidatesStats();
  }

  changeBranch (branchId?: string = '') {
    const params: CandidatesParams = new CandidatesParams(this.props.searchCandidates);
    params.branchId = branchId;
    this.props.saveSearchCandidatesBranch(branchId);
    this.props.initCandidatesFilter(params);
  }

  render () {
    return (
      <StatisticsContainer title={this.props.t('portal.recruiter.candidates.sidebar.title')}>
        <StatisticsItem
          title={this.props.t('portal.recruiter.candidates.sidebar.items')}
          active={!this.props.searchCandidates.branchId}
          onClick={() => this.changeBranch()}
          recruiter/>
        {
          this.props.stats.map((stat, index) => (
            <StatisticsItem
              key={index}
              title={`${stat.name.toLowerCase()}`}
              value={stat.count}
              active={this.props.searchCandidates.branchId === stat.id}
              onClick={() => this.changeBranch(stat.id)}
              recruiter/>
          ))
        }
      </StatisticsContainer>
    );
  }

}
