// @flow
import React from 'react';

import { connectTranslation } from 'middleware/connect';

import { StatisticsContainer, StatisticsItem } from 'components/widgets/statistics';

import { Settings } from './statistics.setting';

@connectTranslation()
export class StatisticsSidebar extends Settings {

  render () {
    return (
      <StatisticsContainer title={this.props.t('portal.recruiter.statistics.sidebar.title')}>
        <StatisticsItem
          title={this.props.t('portal.recruiter.statistics.sidebar.items')}
          value=""
          recruiter/>
      </StatisticsContainer>
    );
  }

}
