import axios from 'axios';

import { BranchDto } from './branch.dto';
import { BranchResponseDto } from './branch.response.dto';

export async function getBranches (params: BranchDto): Promise<BranchResponseDto[]> {
  try {
    return await axios.get(`/branches`, {
      params
    });
  } catch (error) {
    throw error;
  }
}
