// @flow
import { Component } from 'react';

import './social-buttons.scss';
import './social-buttons.responsive.scss';

type Props = {
  google: string;
  facebook: string;
}

export class Setting extends Component<Props> {

  static defaultProps = {
    google: '',
    facebook: ''
  };

}
