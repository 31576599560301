import axios from 'axios';

import { PaginationDto } from 'middleware/dto/pagination';

import { JobOffersMatchesDto } from './job-offers-matches.dto';

export async function getMatchedJobOffers (companyId: string, params: PaginationDto): Promise<JobOffersMatchesDto> {
  try {
    return await axios.get(`/companies/${companyId}/job-offers-matches`, { params });
  } catch (error) {
    throw error;
  }
}

export async function viewMatchedJobOffers (companyId: string, jobOfferId: string): Promise<JobOffersMatchesDto> {
  try {
    return await axios.post(`/companies/${companyId}/job-offers-matches/${jobOfferId}/view`);
  } catch (error) {
    throw error;
  }
}
