// @flow
import { Component } from 'react';
import { TransProps } from 'react-i18next';

import { CandidateDto } from 'rest/candidates/candidate.dto';

import './candidate-row.scss';

type Props = {
  t: TransProps,
  candidate: CandidateDto,
  onClick: () => void,
  children: any
};

export class Setting extends Component<Props> {

  static defaultProps: Props = {
    candidate: new CandidateDto(),
    onClick: () => {},
    children: null
  };

}
