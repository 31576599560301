export class AnonymousParams {

  alertId: string;

  constructor (data?: AnonymousParams) {
    if (data) {
      this.alertId = data.alertId;
    }
  }

}
