/* eslint padded-blocks: ["error", "never"] */
import React, { Component } from 'react';
import '../styles/semantic.min.css';
import '../styles/styles.css';
import axios from 'axios';

export class GDPRChat extends Component {
  constructor() {
    super();
    this.state = {
      data: {},
      length: 0,
      participants: [],
      content: []
    };
  }
  componentDidMount() {
    axios('/chats')
      .then(response => {
        this.setState({
          data: response.data,
          length: response.data.content.length,
          content: response.data.content,
          participants: response.data.content.participants
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  render() {
    return (
      <div className="gdpr-main">
        <div className="ui container main-content">
          <h1 className="ui header main-title"> Chats </h1>
          <a href="/information/gdpr">
            <button className="ui button back-link">Back To Main</button>
          </a>
          <h5 className="ui header"> Total Elements: {this.state.length}</h5>
          <table className="ui single purple line table">
            <thead>
              <tr>

                <th> Full Name </th>
                <th> Created Date </th>
                <th> Last Activity Date </th>
              </tr>
              {this.state.content.map(row => (
                <tr key={row.id}>
                  <td>{row.participants[0].firstName}, {row.participants[0].lastName}</td>
                  <td>{row.createDate}</td>
                  <td>{row.createDate}</td>
                </tr>
              ))}
            </thead>
            <tbody>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
