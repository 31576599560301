import * as actions from './current.constants';

import { Dispatch } from 'redux';

import { signOut } from 'middleware/auth';
import { resetUserType } from 'middleware/user-type';
import { initRestParams } from 'middleware/rest';
import { setLocalization } from 'middleware/localization';
import { initMomentSettings } from 'middleware/moment';

import { RecruiterDto } from 'rest/recruiter/recruiter.dto';
import { ChangePasswordDto } from 'rest/account/change-password/change-password.dto';
import { CurrentRecruiterDto } from 'rest/recruiter/current/current.dto';

import { changeUserPassword } from 'rest/account/change-password/change-password.rest';
import { deleteRecruiter, updateRecruiter, activateRecruiter } from 'rest/recruiter/recruiter.rest';
import {
  getCurrentRecruiter,
  changeCurrentRecruiterLocale,
  disableOnBoardingVideo as disableVideo
} from 'rest/recruiter/current/current.rest';

const getCurrentRecruiterRequest = () => ({
  type: actions.GET_CURRENT_RECRUITER_REQUEST
});

const getCurrentRecruiterSuccess = (recruiter: CurrentRecruiterDto) => ({
  type: actions.GET_CURRENT_RECRUITER_SUCCESS,
  recruiter
});

const getCurrentRecruiterFailure = (error: string) => ({
  type: actions.GET_CURRENT_RECRUITER_FAILURE,
  error
});

const updateRecruiterRequest = () => ({
  type: actions.UPDATE_RECRUITER_REQUEST
});

const updateRecruiterSuccess = (recruiter: RecruiterDto) => ({
  type: actions.UPDATE_RECRUITER_SUCCESS,
  recruiter
});

const updateRecruiterFailure = (error: string) => ({
  type: actions.UPDATE_RECRUITER_FAILURE,
  error
});

const changePasswordRequest = () => ({
  type: actions.CHANGE_PASSWORD_REQUEST
});

const changePasswordSuccess = () => ({
  type: actions.CHANGE_PASSWORD_SUCCESS
});

const changePasswordFailure = (error: string) => ({
  type: actions.CHANGE_PASSWORD_FAILURE,
  error
});

const activateRecruiterRequest = () => ({
  type: actions.ACTIVATE_RECRUITER_REQUEST
});

const activateRecruiterSuccess = () => ({
  type: actions.ACTIVATE_RECRUITER_SUCCESS
});

const activateRecruiterFailure = (error: string) => ({
  type: actions.ACTIVATE_RECRUITER_FAILURE,
  error
});

const clearCurrentRecruiterSuccess = () => ({
  type: actions.CLEAR_CURRENT_RECRUITER_SUCCESS
});

const setCandidatePreviewLinkSuccess = () => ({
  type: actions.SET_CANDIDATE_PREVIEW_LINK_SUCCESS
});

const changeCurrentRecruiterLocaleSuccess = () => ({
  type: actions.CHANGE_CURRENT_RECRUITER_LOCALE
});

const removeCandidatePreviewLinkSuccess = () => ({
  type: actions.REMOVE_CANDIDATE_PREVIEW_LINK
});

export function removeCandidatePreviewLink () {
  return dispatch => dispatch(removeCandidatePreviewLinkSuccess());
}

export function clearCurrentRecruiter () {
  return dispatch => dispatch(clearCurrentRecruiterSuccess());
}

const deleteRecruiterRequest = () => ({
  type: actions.DELETE_RECRUITER_REQUEST
});

const deleteRecruiterSuccess = () => ({
  type: actions.DELETE_RECRUITER_SUCCESS
});

const deleteRecruiterFailure = (error: string) => ({
  type: actions.DELETE_RECRUITER_FAILURE,
  error
});

const disableOnBoardingVideoRequest = () => ({
  type: actions.DISABLE_ON_BOARDING_VIDEO_REQUEST
});

const disableOnBoardingVideoSuccess = () => ({
  type: actions.DISABLE_ON_BOARDING_VIDEO_SUCCESS
});

const disableOnBoardingVideoFailure = (error: string) => ({
  type: actions.DISABLE_ON_BOARDING_VIDEO_FAILURE,
  error
});

export function setCandidatePreviewLink (link: string) {
  return dispatch => {
    localStorage.setItem('ej_candidate_preview_link', link);
    return dispatch(setCandidatePreviewLinkSuccess());
  };
}

export const activateCompanyRecruiter = (recruiterId: string) => {
  return dispatch => {
    dispatch(activateRecruiterRequest());
    return activateRecruiter(recruiterId)
      .then(() => {
        dispatch(removeCandidatePreviewLink());
        return dispatch(activateRecruiterSuccess());
      })
      .catch(error => dispatch(activateRecruiterFailure(error)));
  };
};

export const getCurrentCompanyRecruiter = () => {
  return dispatch => {
    dispatch(getCurrentRecruiterRequest());
    return getCurrentRecruiter()
      .then(response => dispatch(getCurrentRecruiterSuccess(response.data)))
      .catch(error => dispatch(getCurrentRecruiterFailure(error)));
  };
};

export const changeCandidateLocalization = (lang: string) => {
  return dispatch => {
    dispatch(changeCurrentRecruiterLocaleSuccess());
    return changeCurrentRecruiterLocale(lang)
      .then(() => {
        setLocalization(lang);
        initRestParams();
        initMomentSettings();
        window.location.reload();
      });
  };
};

export const updateCurrentRecruiter = (recruiterId: string, data: RecruiterDto) => {
  return dispatch => {
    dispatch(updateRecruiterRequest());
    return updateRecruiter(recruiterId, data)
      .then(() => dispatch(updateRecruiterSuccess(data)))
      .catch(error => dispatch(updateRecruiterFailure(error)));
  };
};

export const changePassword = (data: ChangePasswordDto) => {
  return dispatch => {
    dispatch(changePasswordRequest());
    return changeUserPassword(data)
      .then(response => dispatch(changePasswordSuccess(response)))
      .catch(error => dispatch(changePasswordFailure(error)));
  };
};

function resolveDeleteRecruiter (dispatch: Dispatch) {
  dispatch(deleteRecruiterSuccess());
  signOut();
  return dispatch(clearCurrentRecruiter());
}

export const deleteCurrentRecruiter = (recruiterId: string) => {
  return (dispatch: Dispatch) => {
    resetUserType();
    dispatch(deleteRecruiterRequest());
    return deleteRecruiter(recruiterId)
      .then(() => dispatch(resolveDeleteRecruiter(dispatch)))
      .catch(error => dispatch(deleteRecruiterFailure(error)));
  };
};

export const disableOnBoardingVideo = () => {
  return (dispatch: Dispatch) => {
    dispatch(disableOnBoardingVideoRequest());
    return disableVideo()
      .then(() => dispatch(disableOnBoardingVideoSuccess()))
      .catch(error => dispatch(disableOnBoardingVideoFailure(error)));
  };
};
