// @flow
import React, { Component } from 'react';

import { RegistrationVideo } from 'components/video';
import PropTypes from 'prop-types';
import { Modal } from 'containers/portal/modal-container';
import './registration-modal.scss';
export class RegistrationModal extends Component {

  static get propTypes() {
    return {
      recruiter: PropTypes.bool
    };
  }
  state = {
    open: false
  }

  componentDidMount () {
    this.setState({
      open: true
    });
  }

  handleOnBoardingModal = () => {
    this.setState({
      open: false
    });
  };

  render () {
    return (
      <Modal
        className="menu-video-modal"
        overlay
        animation="fade"
        open= {this.state.open}
        hideModalAvailable={false}
        hideModal={this.handleOnBoardingModal}
        closeOnOverlay={this.handleOnBoardingModal}>
        <div style={{ position: 'absolute', zIndex: 500, marginLeft: '70%', marginTop: '-5%', cursor: 'pointer' }}
          onClick={this.handleOnBoardingModal} >
          <a className="dontShowMeagainCSS">überspringen Sie das Video</a></div>
        <RegistrationVideo style={{ position: 'relative' }}
          onBoarding
          onBoardingVideo= {true}
          recruiter={this.props.recruiter}/>
      </Modal>
    );
  }

}
