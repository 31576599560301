import React, { Fragment, Component } from 'react';
import { Route } from 'react-router-dom';

import { JobsListPage } from './list';
import { SearchJobsPage } from './search';
import { JobOfferPreviewPage } from './preview';

export class JobsPage extends Component {

  render () {
    return (
      <Fragment>
        <Route
          path="/portal/candidate/jobs"
          component={JobsListPage}/>
        <Route
          path="/portal/candidate/jobs/search"
          component={SearchJobsPage}/>
        <Route
          path="/portal/candidate/jobs/:jobOfferId/preview"
          exact
          component={JobOfferPreviewPage}/>
      </Fragment>
    );
  }

}
