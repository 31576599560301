// @flow
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { StatsDto } from 'rest/stats/stats.dto';
import { CurrentRecruiterDto } from 'rest/recruiter/current/current.dto';

import {
  resetFavoriteStatistic,
  getFavoriteUnitStatistic,
  getFavoriteJobTitlesStatistic
} from 'services/portal/recruiter/favorites/stats/stats.actions';

import { changeCandidateFavoritesFilter } from 'services/portal/recruiter/favorites/favorites.actions';
import { getPurchasedCandidateStatistic } from 'services/portal/recruiter/account/purchased/stats/stats.actions';

type Props = {
  t: TransProps,
  unitId: string,
  unitStats: StatsDto[],
  jobTitleId: string,
  totalUnits: number,
  currentUser: CurrentRecruiterDto,
  totalJobTitles: number,
  jobTitlesStats: StatsDto[],
  totalPurchasedCandidates: number,
  resetFavoriteStatistic: () => void,
  getFavoriteUnitStatistic: (recruiterId: string) => void,
  getFavoriteJobTitlesStatistic: (recruiterId: string) => void,
  getPurchasedCandidateStatistic: (recruiterId: string) => void,
  changeCandidateFavoritesFilter: (unitId?: string, jobTitleId?: string) => void
}

export class Settings extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  unitId: state.portal.recruiter.favorites.unitId,
  unitStats: state.portal.recruiter.favoritesStats.unitStats,
  jobTitleId: state.portal.recruiter.favorites.jobTitleId,
  totalUnits: state.portal.recruiter.favoritesStats.totalUnitElements,
  currentUser: state.portal.recruiter.account.current.currentUser,
  jobTitlesStats: state.portal.recruiter.favoritesStats.jobTitlesStats,
  totalJobTitles: state.portal.recruiter.favoritesStats.totalJobTitlesElements,
  totalPurchasedCandidates: state.portal.recruiter.account.purchasedStats.totalPurchasedCandidates
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetFavoriteStatistic,
  getFavoriteUnitStatistic,
  getFavoriteJobTitlesStatistic,
  changeCandidateFavoritesFilter,
  getPurchasedCandidateStatistic
}, dispatch);
