import axios from 'axios';

import { ConfirmDto } from './confirm.dto';
import { ConfirmationEmailDto } from './confirmation-email.dto';

export async function confirmUserRegistration (data: ConfirmDto): Promise<ConfirmDto> {
  try {
    return await axios.post('/account/confirm', data);
  } catch (error) {
    throw error;
  }
}

export async function resendConfirmationEmail (data: ConfirmationEmailDto): Promise<ConfirmationEmailDto> {
  try {
    return await axios.post('/account/confirmation-email/resend', data);
  } catch (error) {
    throw error;
  }
}
