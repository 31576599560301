import React, { Fragment } from 'react';

import { Setting } from './tabs-content.setting';

export class TabsContent extends Setting {

  render () {
    const { activeTab } = this.props;
    const children = Array.isArray(this.props.children) ? this.props.children : [this.props.children];
    return (
      <Fragment>
        {
          children[activeTab] && children[activeTab]
        }
      </Fragment>
    );
  }

}
