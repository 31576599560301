import React from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { Setting } from './filter.setting';

export class NavigationFilter extends Setting {

  getTotalCounter (): number {
    return this.props.items.reduce((sum, item) => sum + item[this.props.counterKey], 0);
  }

  toggleSelectedItem (selectedItem) {
    const { change } = this.props;
    this.setState({ selectedItem }, () => change && change(selectedItem));
  }

  render () {
    let { totalTitle, items } = this.props;
    let { totalCounter, selectedItem } = this.state;
    return (
      <PerfectScrollbar>
        <ul className="nav-filter-list">
          {items.length ? <li
            onClick={() => this.toggleSelectedItem()}>
            <h5>{totalTitle}</h5>
            {totalCounter && <span className="counter">{totalCounter}</span>}
          </li> : null}
          {items && items.map((item, index) => (
            <li
              onClick={() => this.toggleSelectedItem(item)}
              className={selectedItem === item ? 'active' : ''}
              key={index}>
              <h5>{item.name}</h5>
              {
                item.count && <span className="counter">{item.count}</span>
              }
            </li>
          ))}
          {!items.length && (
            <li>
              <h5>There are no items</h5>
            </li>
          )}
        </ul>
      </PerfectScrollbar>
    );
  }

}
