// @flow
import { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';

import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { List } from 'rest/list/list.dto';
import { CandidateDto } from 'rest/candidates/candidate.dto';
import { RecruiterFollowParams } from 'rest/recruiter/follow/follow.params';

import {
  requestFollowersCandidates,
  resetAllCandidateFollowers
} from 'services/portal/recruiter/follow/followers/followers.actions';

import './followers.scss';

type Props = {
  t?: TransProps,
  unitId: string,
  history: History,
  fetching: boolean,
  candidates: List<CandidateDto>,
  currentPage: number,
  hasMoreItems: boolean,
  currentCompanyId: string,

  requestFollowersCandidates: (params: RecruiterFollowParams) => void,
  resetAllCandidateFollowers: () => void
};

export class Setting extends Component<Props> {}

export const stateToProps = (state: GlobalState) => ({
  unitId: state.portal.recruiter.follow.followers.unitId,
  fetching: state.portal.recruiter.follow.followers.fetching,
  candidates: state.portal.recruiter.follow.followers.content,
  currentPage: state.portal.recruiter.follow.followers.currentPage,
  hasMoreItems: state.portal.recruiter.follow.followers.hasMoreItems,
  currentCompanyId: state.portal.recruiter.account.current.currentUser.companyId
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetAllCandidateFollowers,
  requestFollowersCandidates
}, dispatch);
