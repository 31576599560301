import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { Match } from 'common/types/match';
import type { GlobalState } from 'common/types/global-state';

import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';
import { ApplicationResponseDto } from 'rest/candidates/applications-template/applications-template.response.dto';

import { getSentLetterById } from 'services/portal/candidate/applications/sent-templates/sent-templates.actions';

import './sent-letter-preview.scss';
import './../preview/preview.responsive.scss';

export type Props = {
  t: TransProps,
  match: Match,
  history: History,
  currentCandidate: CurrentCandidateDto,
  currentLetter: ApplicationResponseDto,
  getSentLetterById: (candidateId: string) => void,
}

export class Settings extends Component<Props> {

  static defaultProps: Props = {
    getSentLetterById: () => {},
    currentLetter: new ApplicationResponseDto()
  };

}

export const stateToProps = (state: GlobalState) => ({
  currentLetter: state.portal.candidate.applications.sentTemplates.currentLetter,
  currentCandidate: state.portal.candidate.account.current.candidateData
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getSentLetterById
}, dispatch);
