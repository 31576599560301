import { Component } from 'react';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { TransProps } from 'react-i18next';

import type { GlobalState } from 'common/types/global-state';

import { ListDto, PaginationDto } from 'middleware/dto';

import { CurrentCandidateDto } from 'rest/candidates/current/candidate.current.dto';
import { JobRequirementsResponseDto } from 'rest/candidates/job-requirements/job-requirements.response.dto';

import { getAllSkills } from 'services/portal/skills/skills.actions';
import {
  getCandidateSkillsById,
  updateCandidateSkillsById
} from 'services/portal/candidate/skills/skills.actions';

import './skills.scss';
import './skills.responsive.scss';

export type Props = {
  t: TransProps,
  fetching: boolean,
  prev: () => void,
  next: () => void,
  skills: ListDto[],
  skillsSet: ListDto[][],
  currentCandidate: CurrentCandidateDto,
  balanceBoard: JobRequirementsResponseDto,
  getAllSkills: (params: PaginationDto) => void,
  getCandidateSkillsById: (candidateId: string) => void,
};

type State = {
  redirect: boolean,
  collapse: boolean,
  skillType: string,
  skillsSet: ListDto[],
  activeKey: string[],
  activeTabIndex: number,
  isAddSkillsModalOpen: boolean,
  isSuggestNewSkillModalOpen: boolean
}

export class Settings extends Component<Props, State> {

  static defaultProps: Props = {
    skillsSet: [],
    fetching: false,
    prev: () => {},
    next: () => {},
    getAllSkills: () => {},
    getCandidateSkillsById: () => {}
  };

  state: State = {
    redirect: false,
    skillsSet: [],
    skillType: '',
    activeKey: ['0'],
    activeTabIndex: 0,
    skills: this.props.skills,
    isAddSkillsModalOpen: false,
    isSuggestNewSkillModalOpen: false
  };

}

export const stateToProps = (state: GlobalState) => ({
  skills: state.portal.skills.balanceBoardSkills,
  candidateSkills: state.portal.candidate.skills.content,
  redirect: state.portal.candidate.skills.updateSkillsSuccess,
  balanceBoard: state.portal.candidate.balanceBoard.balanceBoard,
  currentCandidate: state.portal.candidate.account.current.candidateData,
  fetching: state.portal.candidate.skills.fetching || state.portal.skills.fetching
});

export const dispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getAllSkills,
  getCandidateSkillsById,
  updateSkills: updateCandidateSkillsById
}, dispatch);
