// @flow
import React from 'react';

import { connectTranslation } from 'middleware/connect';

import { Dropdown, FormGroup, InputControl } from 'components/controls';

import type { Props } from './salary-range.setting';
import { Settings } from './salary-range.setting';

@connectTranslation()
export class SalaryRange extends Settings {

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.defaultValue) {
      this.setState({
        salaryRange: nextProps.defaultValue
      });
    }
  }

  changeSalaryState (field: string, value: string) {
    this.setState({
      salaryRange: {
        ...this.state.salaryRange,
        [field]: value
      }
    }, () => this.props.change(this.state.salaryRange, this.validateSalaryRange()));
  }

  validateSalaryRange (): boolean {
    const minSalary = parseInt(this.state.salaryRange.min, 10);
    const maxSalary = parseInt(this.state.salaryRange.max, 10);
    return minSalary > maxSalary;
  }

  render () {
    const salaryPattern: string = '^\\d*(\\.|,)?\\d{1,2}$';
    return (
      <div className={`salary-range-container ${this.props.className}`}>
        <FormGroup className="salary-field-width min-salary">
          <InputControl
            stickyNotes
            validate
            pattern={salaryPattern}
            notes={this.props.t('portal.recruiter.balance-board.job-offer.general.salary.min.note')}
            maxLength={8}
            label={this.props.t('portal.recruiter.balance-board.job-offer.general.salary.label')}
            errorText={this.props.t('portal.recruiter.balance-board.job-offer.general.salary.min.error')}
            required={this.props.required}
            error={this.validateSalaryRange()}
            value={this.state.salaryRange.min}
            title={this.props.t('portal.recruiter.balance-board.job-offer.general.salary.title')}
            change={(value: string): void => this.changeSalaryState('min', value)}
            type="text"
            className="mobile-salary-range"/>
        </FormGroup>
        <FormGroup className="salary-field-width max-salary">
          <InputControl
            validate
            stickyNotes
            pattern={salaryPattern}
            notes={this.props.t('portal.recruiter.balance-board.job-offer.general.salary.max.note')}
            required={this.props.required}
            errorText={this.props.t('portal.recruiter.balance-board.job-offer.general.salary.max.error')}
            value={this.state.salaryRange.max}
            error={this.props.maxSalaryRange ? this.state.salaryRange.max > this.props.maxSalaryRange : false}
            change={(value: string): void => this.changeSalaryState('max', value)}
            type="text"
            className="mobile-salary-range"/>
        </FormGroup>
        <FormGroup className="salary-field-width salary-type">
          <Dropdown
            change={value => this.changeSalaryState('salaryType', value.id)}
            value={this.state.salaryRange.salaryType}
            items={[
              {
                id: 'MONTH',
                name: this.props.t('portal.common.salary.monthly')
              },
              {
                id: 'HOUR',
                name: this.props.t('portal.common.salary.hour')
              }
            ]}
            icon/>
        </FormGroup>
      </div>
    );
  }

}
