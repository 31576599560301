/* eslint padded-blocks: ["error", "never"] */
/* eslint object-curly-spacing: ["off"] */
import React, { Component } from 'react';
import axios from 'axios';
import '../styles/semantic.min.css';
import '../styles/styles.css';

export class GDPRCandidateJobOffers extends Component {
  constructor() {
    super();
    this.state = {
      favorites: []
    };
  }
  componentDidMount() {
    axios('/candidates/current/job-offer-favorites')
      .then(({data}) => {
        this.setState({
          favorites: data ? (data.content || []) : []
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  render() {
    return (
      <div className="gdpr-main">
        <div className="ui container main-content">
          <h1 className="ui header main-title">
            Favorite job offers
          </h1>
          <a href="/information/gdpr">
            <button className="ui button back-link">Back To Main</button>
          </a>
          <h5 className="ui header"> Total Elements:  {(this.state.favorites || []).length}</h5>
          <table className="ui single green line table">
            <thead>
              <tr>
                <th> Job Offer Link </th>
              </tr>
            </thead>
            <tbody>
              {this.state.favorites.map(row => (
                <tr key={row.id}>
                  <td>
                    <a href={`portal/candidate/jobs/${row.id}/preview`}>
                      {row.jobTitleName}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
