import { Action, Dispatch } from 'redux';

import axios, { AxiosResponse } from 'axios';

import { List } from 'rest/list/list.dto';

import { SkillDto } from 'rest/skills/skills.dto';
import { CandidateDto } from 'rest/candidates/candidate.dto';
import { CandidatesParams } from 'rest/public/candidates/candidates.params';
import { CandidatesBuyDto } from 'rest/companies/candidates/candidates-buy.dto';
import { PaymentRedirectUrlsDto } from 'rest/companies/payment-redirect-urls.dto';

import { getSkillsRest } from 'rest/skills/skills.rest';
import { getCandidatesList } from 'rest/public/candidates/candidates.rest';
import { getCandidateByIdRest } from 'rest/candidates/candidate.rest';
import { buyCandidateDetailsRest } from 'rest/companies/candidates/candidates-buy.rest';

import * as actions from './candidates.constants';

const getAllCandidateRequest = (): Action => ({
  type: actions.GET_ALL_CANDIDATES_REQUEST
});

const getAllCandidateSuccess = (data): Action => ({
  type: actions.GET_ALL_CANDIDATES_SUCCESS,
  payload: data
});

const getAllCandidateFailure = (): Action => ({
  type: actions.GET_ALL_CANDIDATES_FAILURE
});

const getCandidateByIdRequest = (): Action => ({
  type: actions.GET_CANDIDATE_BY_ID_REQUEST
});

const getCandidateByIdSuccess = (data: List<CandidateDto>): Action => ({
  type: actions.GET_CANDIDATE_BY_ID_SUCCESS,
  payload: data
});

const getCandidateByIdFailure = (): Action => ({
  type: actions.GET_CANDIDATE_BY_ID_FAILURE
});

const buyCandidateDetailsRequest = (): Action => ({
  type: actions.BUY_CANDIDATE_DETAILS_REQUEST
});

const buyCandidateDetailsSuccess = (data: CandidatesBuyDto): Action => ({
  type: actions.BUY_CANDIDATE_DETAILS_SUCCESS,
  payload: data.paymentRedirectDetails
});

const buyCandidateDetailsFailure = (): Action => ({
  type: actions.BUY_CANDIDATE_DETAILS_FAILURE
});

const setCandidatesFilters = (filter: CandidatesParams): Action => ({
  type: actions.SET_CANDIDATES_FILTER,
  filter: filter
});

const resetCandidatesFilters = (): Action => ({
  type: actions.RESET_CANDIDATES_FILTER
});

const changeCandidateSuccess = (candidate: CandidateDto): Action => ({
  type: actions.CHANGE_BY_ID_CANDIDATE,
  candidate: candidate
});

const resetCandidatesSuccess = (): Action => ({
  type: actions.RESET_CANDIDATES_LIST_SUCCESS
});

export function resetCandidatesList () {
  return (dispatch: Dispatch) => {
    dispatch(resetCandidatesSuccess());
  };
}

export function initCandidatesFilter (filter: CandidatesParams) {
  return (dispatch: Dispatch) => {
    dispatch(setCandidatesFilters(filter));
  };
}

export function clearCandidatesFilter () {
  return (dispatch: Dispatch) => {
    dispatch(resetCandidatesFilters());
  };
}

export function getAllCandidates (params: CandidatesParams) {
  return (dispatch: Dispatch) => {
    dispatch(getAllCandidateRequest());
    return getCandidatesList(params)
      .then((response: AxiosResponse<List<CandidateDto>>) => dispatch(getAllCandidateSuccess(response.data)))
      .catch(() => dispatch(getAllCandidateFailure()));
  };
}

export function changeCurrentCandidate (candidate: CandidateDto) {
  return (dispatch: Dispatch) => {
    dispatch(changeCandidateSuccess(candidate));
  };
}

export function getCandidateById (candidateId: string, withSkills = false) {
  return (dispatch: Dispatch) => {
    dispatch(getCandidateByIdRequest());
    if (withSkills) {
      return axios
        .all([getCandidateByIdRest(candidateId), getSkillsRest()])
        .then(axios.spread((response: AxiosResponse<CandidateDto>, skills: AxiosResponse<List<SkillDto>>) => {
          mergeCandidateSkill(new CandidateDto(response.data), skills.data.content, dispatch);
        }))
        .catch(() => dispatch(getCandidateByIdFailure()));
    } else {
      return getCandidateByIdRest(candidateId)
        .then((response: AxiosResponse<CandidateDto>) => dispatch(getCandidateByIdSuccess(response.data)))
        .catch(() => dispatch(getCandidateByIdFailure()));
    }
  };
}

export function buyCandidateDetails (companyId: string, candidateId: string, redirectUrls: PaymentRedirectUrlsDto) {
  return (dispatch: Dispatch) => {
    dispatch(buyCandidateDetailsRequest());
    return buyCandidateDetailsRest(companyId, candidateId, redirectUrls)
      .then(response => dispatch(buyCandidateDetailsSuccess(response.data)))
      .catch(() => dispatch(buyCandidateDetailsFailure()));
  };
}

function mergeCandidateSkill (candidate: CandidateDto, skills: SkillDto[], dispatch: Dispatch) {
  Object.keys(candidate.candidateSkillsContainer).forEach(key => {
    candidate.candidateSkillsContainer[key] = candidate.candidateSkillsContainer[key].map((skill: SkillDto) => {
      skill.name = (skills.find(original => {
        if (original.id === skill.skillId) {
          return original;
        }
      }) || new SkillDto()).name;
      return skill;
    });
  });
  dispatch(getCandidateByIdSuccess(candidate));
}
