// @flow
import React from 'react';

import cn from 'classnames';

import Slider from 'react-rangeslider';

import { getLabelKeyByValue } from './get-label-key-by-value';

import type { Props, State } from './knowledge-level.setting';
import { Settings } from './knowledge-level.setting';

export class KnowledgeLevel extends Settings {

  static getDerivedStateFromProps (nextProps: Props, nextState: State) {
    const value = nextProps.value;
    return value && value !== '' ? { value: getLabelKeyByValue(nextState.labels, value) } : null;
  }

  changeCurrentValue (value: number) {
    this.props.change(this.labels[value]);
    this.setState({ value });
  }

  render () {
    const classes = cn([
      'knowledge-level-container',
      {
        'disabled': this.props.disabled,
        'primary': this.props.candidate,
        'hidden-name': !this.props.showSkillName
      }
    ]);
    return (
      <div className={classes}>
        <Slider
          min={1}
          max={4}
          value={this.state.value}
          labels={this.labels}
          tooltip={false}
          onChange={value => this.changeCurrentValue(value)}/>
      </div>
    );
  }

}
