// @flow
export class List<T> {

  size: number;
  last = false;
  first: boolean;
  number: number;
  content: T[] = [];
  totalPages: number;
  totalElements: number;
  numberOfElements: number;

  constructor (data?: List<T>) {
    if (data) {
      this.size = data.size;
      this.last = data.last;
      this.first = data.first;
      this.number = data.number;
      this.content = data.content || [];
      this.totalPages = data.totalPages;
      this.totalElements = data.totalElements;
      this.numberOfElements = data.numberOfElements;
    }
  }

}
