import React from 'react';
import { Link } from 'react-router-dom';

import { Trans } from 'react-i18next';

import { connectTranslation } from 'middleware/connect';

import { Container } from 'components/layouts';

import { Settings } from './footer.setting';

@connectTranslation()
export class Footer extends Settings {

  render () {
    return (
      <footer className={this.props.className ? this.props.className : ''}>
        <Container>
          <div className="footer-content">
            <div className="copy">
              <Trans i18nKey="portal.footer.copyright">
                  Excellent jobs 2019. All rights reserved.
              </Trans>
            </div>
            <ul className="agreements">
              <li>
                <Link to="/information/privacy-policy">
                  <Trans i18nKey="portal.footer.privacy">
                    Privacy Policy
                  </Trans>
                </Link>
              </li>
              <li>
                <Link to="/information/privacy-policy">
                  <Trans i18nKey="portal.footer.terms">
                    Terms & Conditions
                  </Trans>
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </footer>
    );
  }

}
