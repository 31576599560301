// @flow
import React from 'react';

import { Trans } from 'react-i18next';

import { connectStore } from 'middleware/connect';

import { Button } from 'components/buttons';

import { CandidateDto } from 'rest/candidates/candidate.dto';

import { Setting, stateToProps, dispatchToProps } from './black-list.setting';
import { toast } from 'react-toastify';

@connectStore(stateToProps, dispatchToProps)
export class RecruiterBlackList extends Setting {

  componentDidMount () {
    this.props.getCurrentRecruiterBlackList(this.props.currentUser.id);
  }

  unBlockCandidate (candidate: CandidateDto) {
    this.props.unblockUserCandidate(this.props.currentUser.id, candidate.id);
    toast.success(this.props.t('portal.recruiter.notifications.candidate-unblocked'));
  }

  render () {
    return (
      <div className="black-list-container">
        <ul className="black-list">
          {
            this.props.blackList.length > 0 ? this.props.blackList.map(candidate => {
              const photos = candidate.candidatePhotos;
              const avatar = photos && photos.length > 0 && photos.find(photo => photo.primary);
              const avatarUrl: string = avatar ? avatar.url : '/images/shared/userpick.jpg';
              return (
                <li
                  key={candidate.id}
                  className="black-list-item">
                  <div className="member-info">
                    <div className="avatar">
                      <img
                        src={avatarUrl}
                        alt="avatar"/>
                    </div>
                    <div>
                      <p className="member-name">
                        {candidate.firstName} {candidate.lastName}
                      </p>
                      <p className="member-type">{ candidate.email }</p>
                    </div>
                  </div>
                  <Button
                    onClick={() => this.unBlockCandidate(candidate)}
                    className="inline">
                    <Trans i18nKey="portal.recruiter.profile.block.unblock">
                      Unblock
                    </Trans>
                  </Button>
                </li>
              );
            }) : <p className="empty-list">
              <Trans i18nKey="portal.recruiter.profile.block.no-candidates">
                No blocked candidates
              </Trans>
            </p>
          }
        </ul>
      </div>
    );
  }

}
